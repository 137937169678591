import React from 'react';
import styled from 'styled-components';
import {ArrowDown, LaunchLink} from 'components/Ui/Icon';
import {
  useSalesforceAccountRecordTypeByAccountQuery,
  SalesforceAccount,
} from 'api';
import {FieldValue} from './FieldValue';

interface Props {
  salesforceAccount: SalesforceAccount;
  url: string;
}

export default ({salesforceAccount, url}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const addition = salesforceAccount?.addition || {};

  const {data: {salesforceAccountRecordTypeByAccount = {}} = {}} =
    useSalesforceAccountRecordTypeByAccountQuery({
      variables: {
        salesforceAccountId: salesforceAccount.id,
      },
      skip: !salesforceAccount,
    });

  const salesforceAccountFields =
    salesforceAccountRecordTypeByAccount?.salesforceAccountFields || [];

  const salesforceAccountVisibleFields = React.useMemo(() => {
    const visibleFields = salesforceAccountFields.filter(
      (salesforceAccountField) => salesforceAccountField.isDisplay === true,
    );
    return visibleFields;
  }, [salesforceAccountFields]);

  const filteredSalesforceAccountFields = React.useMemo(() => {
    const filteredSalesforceAccountFields = showMore
      ? salesforceAccountVisibleFields
      : salesforceAccountVisibleFields.slice(0, 5);

    return filteredSalesforceAccountFields;
  }, [salesforceAccountVisibleFields, showMore]);

  return (
    <Account>
      <Header open={open} onClick={() => setOpen(!open)}>
        <Wrapper>
          <h2>{salesforceAccount?.name}</h2>
        </Wrapper>
        <ArrowDown />
      </Header>
      {open && (
        <>
          <Section />
          {salesforceAccount?.salesforceAccountRecordType && (
            <>
              <SalesforceField>
                <Label>レコードタイプ：</Label>
                <Type>
                  {salesforceAccount?.salesforceAccountRecordType?.name}
                </Type>
              </SalesforceField>
              {salesforceAccount?.salesforceOwner && (
                <SalesforceField>
                  <Label>所有者： </Label>
                  <Type>
                    {salesforceAccount.salesforceOwner.lastName}{' '}
                    {salesforceAccount.salesforceOwner.firstName}
                  </Type>
                </SalesforceField>
              )}
              <Section />
            </>
          )}
          <Fields>
            {filteredSalesforceAccountFields.map((field, i) => (
              <FieldValue
                key={i}
                salesforceAccount={salesforceAccount}
                field={field}
              />
            ))}
          </Fields>
          {salesforceAccountVisibleFields.length > 5 && (
            <ShowMore
              showMore={showMore}
              onClick={() => setShowMore(!showMore)}>
              <ArrowDown color={'#68b5fb'} />
              <span>
                {showMore ? '一部の項目を表示' : 'すべての項目を表示'}
              </span>
            </ShowMore>
          )}

          <LinkToSalesforce
            href={`${url}/lightning/r/Account/${salesforceAccount?.salesforceAccountId}/view`}
            target="_blank">
            <LaunchLink color={'#68b5fb'} />
            <span>Salesforceで見る</span>
          </LinkToSalesforce>
        </>
      )}
    </Account>
  );
};

const Account = styled.div`
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  margin-bottom: 16px;

  font-weight: 500;
`;

const Header = styled.div<{open: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > svg {
    height: 1rem;
    width: auto;
    transform: rotate(${({open}) => (open ? '180deg' : '0deg')});
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 34px;
    letter-spacing: 0.15px;
    color: #495058;
    margin-bottom: 0px;
  }
`;

const Section = styled.hr`
  border: 1px solid #e1e6eb;
`;

const SalesforceField = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: #899098;
`;

const Type = styled.div`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #495058;
`;

const Fields = styled.div`
  margin-top: 16px;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 16px;
  margin-bottom: 16px;

  & > div {
    width: 60%;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #495058;
  }

  & > div:first-child {
    width: 40%;
  }
`;

const LinkToSalesforce = styled.a`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;

  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #68b5fb;
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
`;

const ShowMore = styled.div<{showMore: boolean}>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: pointer;

  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #68b5fb;
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    transform: rotate(${({showMore}) => (showMore ? '180deg' : '0deg')});
  }
`;
