import gql from 'graphql-tag';

export default gql`
  fragment telephoneNumberFragment on TelephoneNumber {
    id
    name
    tel
    telephoneNumber
  }
`;
