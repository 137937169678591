import gql from 'graphql-tag';
import {
  automaticMailFragment,
  automaticMailSendFragment,
  workflowFragment,
  stepFragment,
} from '../../fragments';

export default gql`
  fragment automaticMailSendMailThreadFragment on AutomaticMailSendMailThread {
    id
    updatedAt
    createdAt
    automaticMailSend {
      ...automaticMailSendFragment
    }
    automaticMail {
      ...automaticMailFragment
    }
    workflow {
      ...workflowFragment
    }
    step {
      ...stepFragment
    }
  }
  ${automaticMailFragment}
  ${automaticMailSendFragment}
  ${workflowFragment}
  ${stepFragment}
`;
