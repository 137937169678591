import React from 'react';
import {Breadcrumb} from 'components/antd';
import Title from '../Title';
import Tab from '../Tab';
import Filter from './Filter';
import Content from './Content';

export default () => {
  const [filterVisible, setFilterVisible] = React.useState(false);

  const toggleFilter = () => setFilterVisible(!filterVisible);

  return (
    <div className="p-8 bg-c-bg h-[calc(100vh_-_60px)] overflow-scroll">
      <Breadcrumb>
        <Breadcrumb.Item>リード一覧</Breadcrumb.Item>
      </Breadcrumb>
      <Title title="リード一覧" />
      <Tab />
      <div className="flex mt-4 gap-4">
        {filterVisible && <Filter toggleFilter={toggleFilter} />}
        <Content toggleFilter={toggleFilter} />
      </div>
    </div>
  );
};
