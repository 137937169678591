import React from 'react';

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.75"
      y="0.75"
      width="16.5"
      height="16.5"
      rx="2.25"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M4.5 9.00005L7.5 11.7L13.5 6.30005"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
