import React from 'react';
import {AutomaticMail, ManualMail, StepCall, StepTask} from 'components/Ui/Icon';
import {WorkflowTemplateStep} from 'api';

const iconComponents: {[key: string]: any} = {
  automatic_mail: AutomaticMail,
  manual_mail: ManualMail,
  call: StepCall,
  task: StepTask
};

const Icon = ({step}: {step: WorkflowTemplateStep; active?: boolean}) => {
  const IconComponent = iconComponents[step.stepType];

  return <IconComponent active={true} width="32px" height="32px" />;
};

export default Icon;
