import React from 'react';
import styled from 'styled-components';
import {Row} from 'react-table';
import {PreleadProject, useProjectGroupPreleadCustomizeItemsQuery} from 'api';
import {useParams} from 'react-router';

interface Props {
  row: Row<PreleadProject>;
  value: any;
}

const ProjectGroupCustomizeItemSelectCell = ({row, value}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const projectGroupPreleadCustomizeItemId = value;

  const {projectId} = useParams<{
    projectId: string;
  }>();

  const {data: {projectGroupPreleadCustomizeItems = []} = {}} =
    useProjectGroupPreleadCustomizeItemsQuery({
      variables: {uuid: projectId},
    });

  const customizeItem = React.useMemo(
    () =>
      preleadProject.prelead.projectGroupCustomizeItemSelects.find(
        (item) =>
          item.projectGroupPreleadCustomizeItemId ===
          projectGroupPreleadCustomizeItemId,
      ),
    [preleadProject, projectGroupPreleadCustomizeItemId],
  );

  const projectGroupPreleadCustomizeItem = React.useMemo(
    () =>
      projectGroupPreleadCustomizeItems.find(
        (item) => item.id === projectGroupPreleadCustomizeItemId,
      ),
    [projectGroupPreleadCustomizeItems, projectGroupPreleadCustomizeItemId],
  );

  const tags = React.useMemo(
    () =>
      customizeItem && projectGroupPreleadCustomizeItem
        ? projectGroupPreleadCustomizeItem.selectOptions.filter((option) =>
            customizeItem?.projectGroupPreleadCustomizeItemSelectOptionIds.includes(
              option.id,
            ),
          )
        : [],
    [customizeItem, projectGroupPreleadCustomizeItem],
  );

  return (
    <Container>
      {tags.map((tag) => (
        <Tag key={tag.id}>{tag.label}</Tag>
      ))}
    </Container>
  );
};

export default ProjectGroupCustomizeItemSelectCell;

const Container = styled.div`
  display: flex;
  gap: 3px;
`;

const Tag = styled.span`
  padding: 1px 8px;
  background: #f5f5f5;
  border-radius: 2px;
  font-size: 9px;
  letter-spacing: 0.15px;
`;
