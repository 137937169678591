import React from 'react';
import {TimeScaleSearch} from './TimeScale';
import {MonthRangeInput} from './MonthRangeInput';
import {MonthInput} from './MonthInput';
import {DayRangeInput} from './DayRangeInput';
import {ReportSearchType} from './types';
import FormSelect from './FormSelect';

interface Props {
  search?: Array<ReportSearchType>;
}

export const Search: React.FC<Props> = ({search} = {}) => {
  if (!search) {
    return <></>;
  }
  return (
    <div className="flex h-16 gap-6 border-b border-c-border bg-c-bg-light px-8 items-center">
      {search.includes('timeScale') && <TimeScaleSearch />}
      {search.includes('monthRange') && <MonthRangeInput />}
      {search.includes('month') && <MonthInput />}
      {search.includes('dayRange') && <DayRangeInput />}
      {search.includes('formSelect') && <FormSelect />}
    </div>
  );
};
