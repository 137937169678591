import React from 'react';
import Input from './Input';

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div>
            <h2 className="text-base m-0 font-bold">役職</h2>
            <p className="m-0 text-sm">
              重要度を高める役職のキーワードを入力してください。
            </p>
          </div>
          <Input />
        </div>
      </div>
    </div>
  );
};
