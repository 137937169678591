import React from 'react';
import styled from 'styled-components';
import Menu from './Menu';

const ContextMenu = ({children}: {children: any}) => {
  return <Container>{children}</Container>;
};

export default ContextMenu;

ContextMenu.Menu = Menu;

const Container = styled.div`
  padding: 10px 24px;
`;
