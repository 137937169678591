import {ReportChart} from '../../../components/Chart';
import {StringParam, useQueryParams} from 'use-query-params';
import useMonthAxis from '../../../hooks/useMonthAxis';
import {useProspectReportProspectPoolUserDailyCallReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const axis = useMonthAxis(query.month);
  const {data: {prospectReportProspectPoolUserDailyCallReports = []} = {}} =
    useProspectReportProspectPoolUserDailyCallReportsQuery({
      variables: {
        month: query.month,
      },
      fetchPolicy: 'cache-and-network',
    });

  const data = () => {
    return prospectReportProspectPoolUserDailyCallReports.map((report) => {
      return {
        label: report.name,
        color: report.color,
        groupId: 'main',
        values: report.values,
      };
    });
  };
  return {
    axis,
    data: data(),
  };
};

export default useChartData;
