import React from 'react';
import useApproachModalContext from './useApproachModalContext';

const ProjectSelect = () => {
  const {projectId, setProjectId, projects} = useApproachModalContext();

  return (
    <select
      value={projectId}
      onChange={(e) => setProjectId(e.target.value)}
      className="form-select border-c-border rounded text-sm w-full">
      <option value="">未選択</option>
      {projects.map((project) => (
        <option key={project.id} value={project.uuid}>
          {project.name}
        </option>
      ))}
    </select>
  );
};

export default ProjectSelect;
