import React, {useState} from 'react';
import WorkflowProspectPoolStatusIcon from './Icon/WorkflowProspectPoolStatusIcon';
import Steps from './Steps';
import {ProspectPool} from 'api';
import {useLocation} from 'react-router-dom';
import ApplyWorkflowModal from './ApplyWorkflowModal';
import useClientUser from 'hooks/useClientUser';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {isMember} = useClientUser();
  const [displayApplyWorkflowModal, setDisplayApplyWorkflowModal] =
    useState(false);
  const [displaySteps, setDisplaySteps] = useState(false);
  const [workflowProspectPoolId, setWorkflowProspectPoolId] = useState(null);

  const location = useLocation<{
    workflowprospectPoolId: string;
  }>();

  React.useEffect(() => {
    if (location?.state?.workflowprospectPoolId) {
      setDisplaySteps(true);
      setWorkflowProspectPoolId(location?.state?.workflowprospectPoolId);
    }
  }, [location?.state]);

  const disableApplyWorkflowButton = React.useMemo(
    () =>
      !!prospectPool?.workflowProspectPools?.find(
        (workflowProspectPool) => workflowProspectPool.status === 'open',
      ),
    [prospectPool],
  );

  if (!prospectPool) return null;

  const WorkflowList = () => (
    <>
      <div className="flex items-center py-5 border-b border-solid border-c-border justify-end">
        {isMember && (
          <button
            onClick={() => setDisplayApplyWorkflowModal(true)}
            disabled={disableApplyWorkflowButton}
            className="transition-all bg-c-primary cursor-pointer text-white h-8 rounded px-4 flex items-center justify-center hover:opacity-50 disabled:opacity-50">
            ワークフローを適用
          </button>
        )}
      </div>
      {prospectPool.workflowProspectPools.map((workflowProspectPool) => (
        <div
          className="flex items-center py-5 border-b border-solid border-c-border"
          key={workflowProspectPool.id}
          onClick={() => {
            setDisplaySteps(true);
            setWorkflowProspectPoolId(workflowProspectPool.id);
          }}>
          <WorkflowProspectPoolStatusIcon
            workflowProspectPool={workflowProspectPool}
          />
          <span className="ml-3 text-base font-medium text-c-base w-[412px] overflow-hidden whitespace-nowrap cursor-pointer hover:text-c-primary">
            {workflowProspectPool.workflow.name}
          </span>
        </div>
      ))}
      <ApplyWorkflowModal
        prospectPool={prospectPool}
        isOpen={displayApplyWorkflowModal}
        closeModal={() => setDisplayApplyWorkflowModal(false)}
      />
    </>
  );

  return displaySteps ? (
    <Steps
      workflowProspectPoolId={workflowProspectPoolId}
      setDisplaySteps={setDisplaySteps}
      prospectPool={prospectPool}
    />
  ) : (
    <WorkflowList />
  );
};
