import React, {forwardRef, HTMLAttributes, CSSProperties, useMemo} from 'react';
import DashboardItem from './DashboardItem';
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/20/solid';
import {Cog6ToothIcon} from '@heroicons/react/24/outline';
import DragHandle from './Handle';
import {useDashboardItems} from '../useDashboardItems';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({id, withOpacity, isDragging, style, ...props}, ref) => {
    const items = useDashboardItems();
    const item = useMemo(() => items.find((c) => c.id === id), [id, items]);

    const inlineStyles: CSSProperties = useMemo(
      () => ({
        opacity: withOpacity ? '0.5' : '1',
        transformOrigin: '50% 50%',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        width: item.options.variant === 'double' ? '792px' : '384px',
        ...style,
      }),
      [item, style],
    );

    return (
      <div
        ref={ref}
        className="py-4 pl-8 pr-6 h-80 rounded bg-white flex flex-col relative"
        style={inlineStyles}
        {...props}>
        <DragHandle id={id} />
        <DashboardItem item={item} />
      </div>
    );
  },
);

export default Item;
