import React, {useState} from 'react';
import styled from 'styled-components';
import {ProspectPool} from 'api';
import {Row} from 'react-table';
import {DotMenuSide, User} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import AssigneeModal from './AssigneeModal';

interface Props {
  row: Row<ProspectPool>;
}

const MenuCell = ({row}: Props) => {
  const [visible, setVisible] = useState(false);
  const [assigneeVisible, setAssigneeVisible] = useState(false);

  const Content = () => {
    return (
      <>
        <PopoverContent>
          <div
            onClick={() => {
              setAssigneeVisible(true);
              setVisible(false);
            }}>
            <User width="12" height="12" color="#899098" />
            <span>担当者を変更</span>
          </div>
        </PopoverContent>
      </>
    );
  };

  return (
    <>
      <Popover
        placement="bottomRight"
        content={Content}
        trigger="click"
        visible={visible}
        onVisibleChange={(e) => setVisible(e)}>
        <div style={{cursor: 'pointer'}}>
          <DotMenuSide color={visible ? '#68b5fb' : '#899098'} />
        </div>
      </Popover>
      <AssigneeModal
        prospectPool={row.original}
        isModalVisible={assigneeVisible}
        setIsModalVisible={setAssigneeVisible}
      />
    </>
  );
};

const PopoverContent = styled.div`
  width: 140px;
  font-size: 14px;
  color: #899098;
  font-weight: normal;
  padding: 4px 6px;
  cursor: pointer;
  > div {
    padding: 4px 8px;
    &:hover {
      background: #f3f5f7;
    }
    > span {
      margin-left: 4px;
    }
  }
`;

export default MenuCell;
