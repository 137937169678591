import React from 'react';
import {Action} from 'api';
import {AutomaticMail, ManualMail, StepCall, Sms, StepTask} from 'components/Ui/Icon';
import {Row} from 'react-table';

interface Props {
  row: Row<Action>;
}

const iconComponents: {[key: string]: any} = {
  'Action::AutomaticMail': AutomaticMail,
  'Action::ManualMail': ManualMail,
  'Action::Call': StepCall,
  'Action::Task': StepTask,
  'Action::Sms': Sms,
};

const IconCell = ({row}: Props) => {
  const action = row.original;
  const IconComponent = iconComponents[action.actionableType];

  return <IconComponent active />;
};

export default IconCell;
