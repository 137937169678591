import React from 'react';
import useCondition from '../../../useCondition';
import Header from '../../Header';
import Footer from '../Footer';
import Field from './Field';

const HubspotDealFilter = () => {
  const {condition, setCondition} = useCondition();

  return (
    <>
      <Header title="HubSpot取引" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {condition?.hubspotDealSearchConditions?.map(
              (hubspotDealSearchCondition, index) => (
                <Field
                  key={index}
                  searchCondition={hubspotDealSearchCondition}
                  setSearchCondition={(hubspotDealSearchCondition) => {
                    const searchConditions = [
                      ...condition.hubspotDealSearchConditions,
                    ];
                    searchConditions[index] = hubspotDealSearchCondition;
                    setCondition({
                      hubspotDealSearchConditions: searchConditions,
                    });
                  }}
                  onDelete={() => {
                    const searchConditions = [
                      ...condition.hubspotDealSearchConditions,
                    ];
                    searchConditions.splice(index, 1);
                    setCondition({
                      hubspotDealSearchConditions: searchConditions,
                    });
                  }}
                />
              ),
            )}
            <button
              className="cursor-pointer w-32 h-8 flex items-center justify-center rounded bg-white border border-c-border text-light"
              onClick={() =>
                setCondition({
                  hubspotDealSearchConditions: [
                    ...(condition.hubspotDealSearchConditions || []),
                    {},
                  ],
                })
              }>
              + 条件を追加
            </button>
          </div>
        </div>
      </div>
      <Footer onClear={() => setCondition({hubspotDealSearchConditions: []})} />
    </>
  );
};

export default HubspotDealFilter;
