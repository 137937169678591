import React from 'react';
import styled from 'styled-components';
import {Route, Switch} from 'react-router-dom';
import Main from './Main';
import Block from './Block';

export default () => (
  <Container>
    <Switch>
      <Route
        path="/mail_campaigns/:mailCampaignId/mail/blocks/:blockId"
        component={Block}
      />
      <Route path="/mail_campaigns/:mailCampaignId/mail" component={Main} />
    </Switch>
  </Container>
);

const Container = styled.div`
  width: 320px;
  margin-right: 30px;
  overflow: visible;
`;
