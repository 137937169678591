import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowSalesforceDealSelectSearchConditionMutation,
  useWorkflowQuery,
  SalesforceDealFieldSelectOption,
  useSalesforceDealFieldQuery,
} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  deal_field_id: string;
};

const DealSelectOption: React.FC<{condition: SelectSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {
    data: {
      salesforceDealField: {salesforceDealFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceDealFieldQuery({
    variables: {dealFieldId: condition.deal_field_id},
    skip: !condition.deal_field_id,
  });

  const [updateWorkflowSalesforceDealSelectSearchCondition] =
    useUpdateWorkflowSalesforceDealSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const dealFieldSelectOptions = () => {
    return salesforceDealFieldSelectOptions.map(
      (item: SalesforceDealFieldSelectOption) => {
        return {
          value: item.id,
          label: item.label,
        };
      },
    );
  };

  return (
    <Select
      options={dealFieldSelectOptions() || []}
      disabled={!isConditionUpdatable || !condition.deal_field_id || !isMember}
      style={{width: 200}}
      value={condition.field_select_option_id ?? ''}
      onChange={(value: string) => {
        updateWorkflowSalesforceDealSelectSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              dealFieldId: condition.deal_field_id,
              fieldType: condition.field_type,
              fieldSelectOptionId: value,
            },
          },
        });
      }}
    />
  );
};

export default DealSelectOption;
