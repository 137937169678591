import React from 'react';
import {
  ProspectPool,
  ProspectActivity,
  ZoomPhoneWebhookCallProspectActivity,
} from 'api';
import ZoomPhoneActivity from './ZoomPhoneActivity';
import CallActivity from './CallActivity';

interface ZoomPhoneWebhookCallActivity extends ProspectActivity {
  resource: ZoomPhoneWebhookCallProspectActivity;
}

interface Props {
  prospectPool: ProspectPool;
  prospectActivity: ZoomPhoneWebhookCallActivity;
}

const ZoomPhoneWebhookCallActivityComponent = ({
  prospectActivity,
  prospectPool,
}: Props) => {
  return prospectActivity.resource.stage ? (
    <CallActivity
      prospectActivity={prospectActivity}
      prospectPool={prospectPool}
    />
  ) : (
    <ZoomPhoneActivity
      prospectActivity={prospectActivity}
      prospectPool={prospectPool}
    />
  );
};

export default ZoomPhoneWebhookCallActivityComponent;
