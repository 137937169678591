import React from 'react';
import {Activity, MiitelCallPreleadActivity} from 'api';
import MiitelCall from './MiitelCall';

interface ActivityType extends Activity {
  resource: MiitelCallPreleadActivity;
}

interface Props {
  activity: ActivityType;
}

const MiitelCallActivityComponent = ({activity}: Props) => (
  <div className="ml-[-30px] relative border border-solid border-c-border rounded bg-white">
    <MiitelCall activity={activity} />
  </div>
);

export default MiitelCallActivityComponent;
