import React from 'react';

function Icon() {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_18078_175766)">
        <path
          d="M14.4907 4.97363V3.94732C14.4907 3.23679 13.8431 2.60521 13.1145 2.60521H6.80023L5.66689 0.789422C5.50499 0.631528 5.26213 0.473633 5.01927 0.473633H1.94308C1.13356 0.473633 0.566895 1.10521 0.566895 1.81574V11.3684C0.566895 12.0789 1.21451 12.7105 1.94308 12.7105H5.90975"
          stroke="#BDC4CB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8954 10.1842H8.66211"
          stroke="#BDC4CB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6289 7.42102L15.4622 10.1842L12.6289 12.9473"
          stroke="#BDC4CB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18078_175766">
          <rect width="17" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
