import React from 'react';

import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/20/solid';

import {ReportTable, ReportTableData} from './types';

export default (table: ReportTable) => {
  const headers = table?.headers ?? [];
  const data = table?.data ?? [];

  const columnHelper = createColumnHelper<ReportTableData>();
  const columns = [
    columnHelper.accessor('label', {
      id: 'label',
      cell: ({getValue, row, table}) => {
        return (
          <div className="flex gap-2 items-center">
            {table.getCanSomeRowsExpand() ? (
              row.getCanExpand() ? (
                row.getIsExpanded() ? (
                  <ChevronDownIcon
                    className="w-4 cursor-pointer"
                    onClick={row.getToggleExpandedHandler()}
                  />
                ) : (
                  <ChevronRightIcon
                    className="h-4 cursor-pointer"
                    onClick={row.getToggleExpandedHandler()}
                  />
                )
              ) : (
                <div className="w-4" />
              )
            ) : null}
            {row.original.color ? (
              <span
                className="w-[14px] h-[14px] rounded-full"
                style={{
                  backgroundColor: row.original.color,
                }}
              />
            ) : null}
            {getValue()}
          </div>
        );
      },
      header: () =>
        headers.length === 0 ? null : typeof headers[0] === 'string' ? (
          headers[0]
        ) : (
          <div {...headers[0].headerProps}>{headers[0].header}</div>
        ),
    }),
    ...headers.slice(1).map((header, i) =>
      columnHelper.accessor('values', {
        cell: ({getValue}) => {
          const values = getValue();
          const value = values[i];
          // nullの場合は'-'を表示
          if (value === null) {
            return '-';
          }
          if (typeof value === 'number') {
            return value.toLocaleString();
          }
          return value;
        },
        header: () =>
          typeof header === 'string' ? (
            header
          ) : (
            <div {...header.headerProps}>{header.header}</div>
          ),
      }),
    ),
  ];

  return useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    enableExpanding: true,
    initialState: {
      expanded: true,
    },
    debugColumns: false,
  });
};
