import React from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {
  Form,
  FormBlockFieldCustomizeItem,
  usePoolProspectCustomizeItemsQuery,
} from 'api';
import {
  Bars3BottomLeftIcon,
  HashtagIcon,
  TagIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import usePools from 'hooks/usePools';

const icons: {[key: string]: any} = {
  text: <Bars3BottomLeftIcon />,
  number: <HashtagIcon />,
  date: <CalendarIcon />,
  select: <TagIcon />,
};

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {defaultPool} = usePools();
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({
      variables: {
        poolId: defaultPool?.uuid,
      },
      skip: !defaultPool,
    });

  const customizeItemIds = React.useMemo(
    () =>
      form?.formPages
        ? form.formPages
            .map((page) => page.formBlocks)
            .flat()
            .filter((block) => block.fieldType === 'customize_item')
            .map(
              (block) =>
                (block.formBlockable as FormBlockFieldCustomizeItem)
                  .poolProspectCustomizeItem.id,
            )
        : [],
    [form],
  );

  const blocks = React.useMemo(
    () =>
      poolProspectCustomizeItems.map((item) => ({
        blockType: 'customizeItemField',
        dataType: item.dataType,
        poolProspectCustomizeItemId: item.id,
        label: item.name,
        icon: icons[item.dataType],
        disabled: customizeItemIds.includes(item.id),
      })),
    [poolProspectCustomizeItems, customizeItemIds],
  );

  return (
    <div className="my-4">
      <h4 className="font-bold text-base">カスタマイズ項目</h4>
      <Container
        groupName="formBlocks"
        behaviour="copy"
        getChildPayload={(i) => blocks[i]}>
        {blocks.map((b) =>
          b.disabled ? (
            <div
              key={b.poolProspectCustomizeItemId}
              className="draggable-item flex px-4 h-10 mt-1 border border-c-border rounded-sm items-center bg-white text-c-lighter gap-3">
              <span className="w-3">{b.icon}</span>
              <span>{b.label}</span>
            </div>
          ) : (
            <Draggable key={b.poolProspectCustomizeItemId}>
              <div className="draggable-item cursor-move flex px-4 h-10 mt-1 border border-c-border rounded-sm items-center bg-white gap-3">
                <span className="w-3">{b.icon}</span>
                <span>{b.label}</span>
              </div>
            </Draggable>
          ),
        )}
      </Container>
    </div>
  );
};
