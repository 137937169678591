import React from 'react';
import styled from 'styled-components';
import {SketchPicker} from 'react-color';
import {colord} from 'colord';
import {Input} from 'components/antd';

interface Props {
  color: string;
  onChange: (color: string) => void;
}

export default ({color, onChange}: Props) => {
  const [value, setValue] = React.useState(color || 'transparent');
  const [showPicker, setShowPicker] = React.useState(false);

  const onClick = () => setShowPicker(!showPicker);
  const onClose = () => setShowPicker(false);

  React.useEffect(() => {
    if (value === 'transparent') {
      onChange('transparent');
    } else {
      const c = colord(value).alpha(1).toHex();
      colord(c).isValid() && onChange(c);
    }
  }, [value]);

  return (
    <Container>
      <Input
        value={value}
        type="text"
        onChange={(e) => setValue(e.target.value)}
      />
      <ColorBackground onClick={onClick}>
        <Color color={color} />
      </ColorBackground>
      {showPicker ? (
        <Popover>
          <Cover onClick={onClose} />
          <SketchPicker
            color={color}
            onChange={(c) => setValue(c.hex)}
            disableAlpha
            presetColors={[
              'TRANSPARENT',
              '#D0021B',
              '#F5A623',
              '#F8E71C',
              '#8B572A',
              '#7ED321',
              '#417505',
              '#BD10E0',
              '#9013FE',
              '#4A90E2',
              '#50E3C2',
              '#B8E986',
              '#000000',
              '#4A4A4A',
              '#9B9B9B',
              '#FFFFFF',
            ]}
          />
        </Popover>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    width: 100px;
  }

  div[title='TRANSPARENT'] {
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
      linear-gradient(135deg, #ccc 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #ccc 75%),
      linear-gradient(135deg, transparent 75%, #ccc 75%) !important;
    background-size: 10px 10px !important;
    background-position: 0 0, 5px 0, 5px -5px, 0px 5px !important;
  }
`;

const ColorBackground = styled.div`
  outline: solid 1px #dae0e5;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(135deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(135deg, transparent 75%, #ccc 75%) !important;
  background-size: 10px 10px;
  background-position: 0 0, 5px 0, 5px -5px, 0px 5px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
`;

const Color = styled.div<{color: string}>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({color}) => color};
`;

const Popover = styled.div`
  position: absolute;
  z-index: 6;
  top: -240px;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
