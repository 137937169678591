import React from 'react';
import {Switch, Route} from 'react-router';
import List from './List';
import CollectedFormUrl from './collectedFormUrlId';

export default () => (
  <div className="p-8 bg-c-bg h-[calc(100vh-60px)] overflow-scroll">
    <Switch>
      <Route path="/form/collected_form_urls/:collectedFormUrlId" component={CollectedFormUrl} />
      <Route component={List} />
    </Switch>
  </div>
);

