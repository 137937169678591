import gql from 'graphql-tag';

export default gql`
  fragment preleadContractFragment on PreleadContract {
    id
    clientId
    plan
    planText
    startedDate
    expiredDate
    downloadable
    fixable
    projectLimit
    userLimit
    csvUploadable
    telLogDownloadable
    userRoleCanListPreleadMaster
    canUseLead
    downloadLimit
  }
`;
