import gql from 'graphql-tag';

export default gql`
  fragment userMailActivityFragment on UserMailActivity {
    from
    to
    cc
    bcc
    title
    body
    bodyHtml
    createdAt
    mailAttachments {
      uuid
      filename
      extension
    }
  }
`;
