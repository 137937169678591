import React from 'react';
import {Input, Form, DatePicker, Select} from 'components/antd';
import {
  useSalesforceDealRecordTypesQuery,
  useSalesforceUsersQuery,
  useSalesforceOpportunityStagesQuery,
  useSalesforceDealFieldInputtablesQuery,
} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';
import {Field} from './Field';
import useProspectContext from '../../../useProspectContext'

interface Props {
  formik: any;
  addition: {[key: string]: any};
  setAddition: Function;
}

export const Deal: React.FC<Props> = ({formik, addition, setAddition}) => {
  const {poolId} = useProspectContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  const {data: {salesforceOpportunityStages = []} = {}} =
    useSalesforceOpportunityStagesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const selectedRecordType = salesforceDealRecordTypes.find(
    (recordType) =>
      recordType.id === formik.values.salesforceDeal.salesforceDealRecordTypeId,
  );

  const {data: {salesforceDealFieldInputtables = []} = {}} =
    useSalesforceDealFieldInputtablesQuery({
      variables: {recordTypeId: selectedRecordType?.id},
      skip: !selectedRecordType,
    });

  return (
    <>
      <Form.Item
        label="商談名"
        required
        validateStatus={formik.errors.salesforceDeal?.name && 'error'}
        help={formik.errors.salesforceDeal?.name}>
        <Input
          name={'SalesforceDeal.name'}
          value={formik.values.salesforceDeal?.name}
          onChange={(e) =>
            formik.setFieldValue('salesforceDeal.name', e.target.value)
          }
        />
      </Form.Item>

      <Form.Item
        label="所有者"
        required
        validateStatus={
          formik.errors.salesforceDeal?.salesforceUserId && 'error'
        }
        help={formik.errors.salesforceDeal?.salesforceUserId}>
        <Select
          value={formik.values.salesforceDeal?.salesforceUserId}
          showSearch
          optionFilterProp="children"
          onChange={(value) =>
            formik.setFieldValue('salesforceDeal.salesforceUserId', value)
          }>
          {salesforceUsers.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="フェーズ"
        required
        validateStatus={
          formik.errors.salesforceDeal?.salesforceOpportunityStageId && 'error'
        }
        help={formik.errors.salesforceDeal?.salesforceOpportunityStageId}>
        <Select
          value={formik.values.salesforceDeal?.salesforceOpportunityStageId}
          onChange={(value) =>
            formik.setFieldValue(
              'salesforceDeal.salesforceOpportunityStageId',
              value,
            )
          }>
          {salesforceOpportunityStages.map((stage) => (
            <Select.Option key={stage.id} value={stage.id}>
              {stage.masterLabel}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="完了予定日"
        required
        validateStatus={formik.errors.salesforceDeal?.closeDate && 'error'}
        help={formik.errors.salesforceDeal?.closeDate}>
        <DatePicker
          value={formik.values.salesforceDeal?.closeDate}
          locale={locale}
          style={{width: '200px'}}
          onChange={(date) =>
            formik.setFieldValue('salesforceDeal.closeDate', date)
          }
        />
      </Form.Item>

      {salesforceDealRecordTypes.length > 0 && (
        <Form.Item label="レコードタイプ">
          <Select
            value={selectedRecordType?.id || null}
            onChange={(value: string) => {
              formik.setFieldValue(
                'salesforceDeal.salesforceDealRecordTypeId',
                value,
              );
              setAddition({});
            }}>
            {salesforceDealRecordTypes.map((recordType) => (
              <Select.Option key={recordType.id} value={recordType.id}>
                {recordType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {salesforceDealFieldInputtables.map((field, i: number) => (
        <Field
          key={i}
          addition={addition}
          setAddition={setAddition}
          field={field}
        />
      ))}
    </>
  );
};
