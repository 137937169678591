import React from 'react';
import styled from 'styled-components';
import {Action} from 'api';
import {Link} from 'react-router-dom';

const OpenItem = (action: Action) => {
  if (action?.actionableType === 'Action::Call') {
    return (
      <OpenLink
        to={{
          pathname: location?.pathname?.replace('workflow', 'tel'),
          search: location?.search,
          state: {
            isNew: true,
            actionUuid: action?.uuid,
          },
        }}>
        <span>{action?.statusText}</span>
      </OpenLink>
    );
  } else if (action?.actionableType === 'Action::ManualMail') {
    return (
      <OpenLink
        to={{
          pathname: location.pathname?.replace('workflow', 'mail'),
          search: location?.search,
          state: {
            isNew: true,
            actionUuid: action?.uuid,
          },
        }}>
        <span>{action?.statusText}</span>
      </OpenLink>
    );
  } else {
    return (
      <Open>
        <span>{action?.statusText}</span>
      </Open>
    );
  }
};

export default ({action}: {action: Action}) => {
  if (!action) return null;

  if (action.status === 'open') {
    return OpenItem(action);
  } else {
    return (
      <Close>
        <span>{action.statusText}</span>
      </Close>
    );
  }
};

const Status = styled.div`
  width: 63px;
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 10px;
  border-radius: 4px;
  text-align: center;

  > span {
    display: inline-block;
    margin: 0 auto;
  }
`;

const Open = styled(Status)`
  color: #55aa68;
  background: #f5fdf7;
`;

const Close = styled(Status)`
  color: #83888d;
  background: #eaecee;
`;

const OpenLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 63px;
  height: 20px;
  border-radius: 4px;
  text-align: center;
  font-size: 10px;

  color: #55aa68;
  background: #f5fdf7;
  cursor: pointer;

  > span {
    display: inline-block;
    margin: 0 auto;
  }

  &:hover {
    color: #55aa68;
  }
`;
