import React from 'react';
import {useProjectQuery} from 'api';
import {Setting} from 'components/Ui/Icon';
import {Popover, Button} from 'components/antd';
import {useParams} from 'react-router';
import styled from 'styled-components';
import ShareSelect from './ShareSelect';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const {isMember} = useClientUser();
  const {
    data: {
      project = {},
      project: {offeredClients: projectOfferedClients = []} = {},
    } = {},
  } = useProjectQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'cache-and-network',
    skip: !projectId,
  });

  return (
    <Container>
      <Title>
        <h3>依頼先</h3>
        {isMember && (
          <Popover
            content={() => (
              <ShareSelect
                project={project}
                onClose={() => setPopoverVisible(false)}
              />
            )}
            trigger="click"
            visible={isPopoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            placement="bottomRight">
            {project.status === 'open' && (
              <Button type="text">
                <Setting />
              </Button>
            )}
          </Popover>
        )}
      </Title>

      {projectOfferedClients.length > 0 ? (
        projectOfferedClients.map((projectOfferedClient) => (
          <Name key={projectOfferedClient.id}>{projectOfferedClient.name}</Name>
        ))
      ) : (
        <Name>未設定</Name>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #bdc4cb;
    flex: 1;
  }

  button {
    padding: 0;
    &:hover {
      background: transparent;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    stroke: #899098;
  }
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
