import React from 'react';
import moment from 'moment';
import useMergeModalContext from '../useMergeModalContext';
import {useProspectPoolQuery} from 'api';
import Row from './Row';

const Table = () => {
  const {mergeFrom, mergeTo} = useMergeModalContext();

  const {data: {prospectPool: prospectPoolFrom} = {}} = useProspectPoolQuery({
    variables: {uuid: mergeFrom},
    skip: !mergeFrom,
  });

  const {data: {prospectPool: prospectPoolTo} = {}} = useProspectPoolQuery({
    variables: {uuid: mergeTo},
    skip: !mergeTo,
  });

  return (
    <table className="table-fixed border-collapse w-full border border-c-border font-normal">
      <thead className="bg-c-bg-light">
        <tr>
          <th className="border border-c-border px-4 py-2 w-1/4"></th>
          <th className="border border-c-border px-4 py-2 w-1/4 text-center">
            名寄せ元
          </th>
          <th className="border border-c-border px-4 py-2 w-1/4 text-center">
            名寄せ先
          </th>
          <th className="border border-[#0085FF] bg-[#0085FF] px-4 py-2 w-1/4 text-center text-white">
            結果
          </th>
        </tr>
      </thead>
      <tbody>
        <Row
          name="名前"
          from={
            prospectPoolFrom
              ? `${prospectPoolFrom?.prospect?.lastName} ${prospectPoolFrom?.prospect?.firstName}`
              : null
          }
          to={
            prospectPoolTo
              ? `${prospectPoolTo?.prospect?.lastName} ${prospectPoolTo?.prospect?.firstName}`
              : null
          }
        />
        <Row
          name="会社名"
          from={prospectPoolFrom?.prospect?.account?.name}
          to={prospectPoolTo?.prospect?.account?.name}
        />
        <Row
          name="URL"
          from={prospectPoolFrom?.prospect?.account?.webSite}
          to={prospectPoolTo?.prospect?.account?.webSite}
        />
        <Row
          name="メールアドレス"
          from={prospectPoolFrom?.prospect?.email}
          to={prospectPoolTo?.prospect?.email}
        />
        <Row
          name="電話番号"
          from={prospectPoolFrom?.prospect?.telephoneNumber}
          to={prospectPoolTo?.prospect?.telephoneNumber}
        />
        <Row
          name="部署"
          from={prospectPoolFrom?.prospect?.section}
          to={prospectPoolTo?.prospect?.section}
        />
        <Row
          name="役職"
          from={prospectPoolFrom?.prospect?.sectionPosition}
          to={prospectPoolTo?.prospect?.sectionPosition}
        />
        <Row
          name="最新リードソース"
          from={prospectPoolFrom?.leadSource?.name}
          to={prospectPoolTo?.leadSource?.name}
        />
        <Row
          name="初回リードソース"
          from={prospectPoolFrom?.originalLeadSource?.name}
          to={prospectPoolTo?.originalLeadSource?.name}
        />
        <Row
          name="最新流入日"
          from={
            prospectPoolFrom?.latestInflowDate
              ? moment(prospectPoolFrom?.latestInflowDate).format('YYYY/MM/DD')
              : null
          }
          to={
            prospectPoolTo?.latestInflowDate
              ? moment(prospectPoolTo?.latestInflowDate).format('YYYY/MM/DD')
              : null
          }
        />
        <Row
          name="初回流入日"
          from={
            prospectPoolFrom?.originalInflowDate
              ? moment(prospectPoolFrom?.originalInflowDate).format(
                  'YYYY/MM/DD',
                )
              : null
          }
          to={
            prospectPoolTo?.originalInflowDate
              ? moment(prospectPoolTo?.originalInflowDate).format('YYYY/MM/DD')
              : null
          }
        />
        <Row
          name="リード担当者"
          from={
            prospectPoolFrom?.user
              ? `${prospectPoolFrom?.user.lastName} ${prospectPoolFrom?.user.firstName}`
              : null
          }
          to={
            prospectPoolTo?.user
              ? `${prospectPoolTo?.user.lastName} ${prospectPoolTo?.user.firstName}`
              : null
          }
        />
      </tbody>
    </table>
  );
};

export default Table;
