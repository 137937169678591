import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {PlusSquare} from 'components/Ui/Icon';
import {usePreleadProjectQuery} from 'api';
import {Popover} from 'components/antd';
import NewAccountPreleadModal from './NewAccountPrelead';

export default () => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isNewAccountPreleadModalVisible, setIsNewAccountPreleadModalVisible] =
    useState(false);

  const {data: {preleadProject: {prelead = null, project = null} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    });

  const pool = project?.projectGroup?.pool;

  const url = pool?.poolSalesforceSetting.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  const salesforceAccountPrelead = prelead?.salesforceAccountPrelead;

  return (
    <>
      {prelead && (
        <Container>
          {salesforceAccountPrelead ? (
            <Label
              href={`${url}/lightning/r/Account/${salesforceAccountPrelead.salesforceAccountIdString}/view`}
              target="_blank">
              <span>取引先</span>
            </Label>
          ) : (
            <Popover
              placement="rightTop"
              trigger="click"
              visible={isPopoverVisible}
              onVisibleChange={() => setIsPopoverVisible(!isPopoverVisible)}
              content={
                <PopoverBox>
                  <div
                    onClick={() => {
                      setIsPopoverVisible(false);
                      setIsNewAccountPreleadModalVisible(true);
                    }}>
                    <span>取引先を作成</span>
                  </div>
                </PopoverBox>
              }>
              <Add>
                <PlusSquare />
              </Add>
            </Popover>
          )}
          <NewAccountPreleadModal
            visible={isNewAccountPreleadModalVisible}
            onClose={() => setIsNewAccountPreleadModalVisible(false)}
            prelead={prelead}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Add = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const Label = styled.a`
  position: relative;

  width: 78px;
  height: 24px;

  background: #e9f5ff;
  border-radius: 2px;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    letter-spacing: 0.15px;

    color: #68b5fb;
  }
`;

const PopoverBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  div {
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
    }

    span {
      font-weight: 500;
      margin-left: 16px;
      font-size: 12px;
      line-height: 17px;
      color: #495058;

      &:hover {
        color: #68b5fb;
      }
    }
  }
`;
