import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
      <g>
        <path
          d="M9.62793 8.7373L11.7731 8.82146C12.2249 8.83919 12.6259 9.14852 12.7991 9.61297L13.4334 11.3141C13.581 11.71 13.5413 12.1617 13.3275 12.5183L12.5148 13.8739C12.996 14.6229 14.3047 16.378 15.6904 17.4053L16.7237 16.7158C16.9863 16.5406 17.3008 16.4886 17.5979 16.5712L19.652 17.1422C20.1984 17.2941 20.5665 17.8622 20.5233 18.4865L20.3915 20.3931C20.3452 21.0621 19.8401 21.5871 19.2405 21.5116C11.3265 20.5139 6.68561 8.73729 9.62793 8.7373Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default Icon;
