import gql from 'graphql-tag';
import {prospectTelStatusFragment} from '..';

export default gql`
  fragment callProspectActivityFragment on CallProspectActivity {
    id
    telStatus
    telStatusText
    stage
    stageText
    recordedAt
    comment
    commentHtml
    hubspotCallIdString
    hubspotContactIdString
    clientProspectTelStatus {
      ...prospectTelStatusFragment
    }
  }
  ${prospectTelStatusFragment}
`;
