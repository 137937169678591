import gql from 'graphql-tag';

export default gql`
  fragment prospectPoolUserDailyReportFragment on ProspectPoolUserDailyReport {
    date
    callsCount
    dealsCount
    manualMailSendsCount
  }
`;
