import {StringParam, useQueryParams, withDefault} from 'use-query-params';

const searchParamsDef = {
  status: withDefault(StringParam, 'sent'),
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);

  return {
    query,
    setQuery,
  };
};

export default useSearchParams;
