import React from 'react';
import useCondition from '../../../useCondition';
import {useSalesforceContactRecordTypesQuery} from 'api';
import SearchCondition from './SearchCondition';

export default () => {
  const {condition} = useCondition();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery();

  const recordType = salesforceContactRecordTypes.find(
    (recordType) => recordType.id === condition?.salesforceContactRecordTypeId,
  );

  if (!recordType) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">取引先責任者</h4>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-c-light">レコードタイプ：</span>
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {recordType.name}
        </span>
      </div>
      {condition.salesforceContactSearchConditions.length > 0 &&
        condition.salesforceContactSearchConditions.map(
          (searchCondition, i) => (
            <SearchCondition key={i} searchCondition={searchCondition} />
          ),
        )}
    </div>
  );
};
