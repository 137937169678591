import React from 'react';
import useSearchParams from '../useSearchParams';

const useFilterProjectActive = () => {
  const {query} = useSearchParams();
  const filterProjectActive = React.useMemo(
    () =>
      query.projectFilters.some((filter) => filter?.status_values?.length > 0),
    [query.projectFilters],
  );
  return {
    filterProjectActive,
  };
};

export default useFilterProjectActive;
