import React from 'react';
import {Switch, Route} from 'react-router-dom';
// @ts-ignore
import NewForm from './form/NewForm';
// @ts-ignore
import UpdateForm from './form/UpdateForm';
import TeamUserList from './user';

export default () => (
  <Switch>
    <Route path="/settings/teams/new" component={NewForm} />
    <Route path="/settings/teams/:teamId/users" component={TeamUserList} />
    <Route path="/settings/teams/:teamId/base" component={UpdateForm} />
  </Switch>
);
