import React from 'react';
import ListHeader from '../../ListHeader';
import {Container, Draggable, DropResult} from 'react-smooth-dnd';
import {
  useAllCollectedFormUrlsQuery,
  useUpdateCollectedFormUrlMutation,
} from 'api';
import ListItem from './Item';
import NewModal from './NewModal';
import SearchAction from './SearchAction';
import useSearchParams from '../useSearchParams';

export default () => {
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [urls, setUrls] = React.useState([]);
  const {searchWord} = useSearchParams();

  const {data: {allCollectedFormUrls: {collectedFormUrls = []} = {}} = {}} =
    useAllCollectedFormUrlsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        searchWord: searchWord,
      },
    });

  const [updateCollectedFormUrl] = useUpdateCollectedFormUrlMutation({
    refetchQueries: ['allCollectedFormUrls'],
  });

  const onDrop = (result: DropResult) => {
    const sourceIndex = result.removedIndex;
    const destIndex = result.addedIndex;

    const items = [...urls];
    const [removed] = items.splice(sourceIndex, 1);
    items.splice(destIndex, 0, removed);
    setUrls(items);

    updateCollectedFormUrl({
      variables: {
        collectedFormUrlUuid: removed.uuid,
        attributes: {
          position: destIndex,
        },
      },
    });
  };

  React.useEffect(() => {
    if (collectedFormUrls) {
      setUrls(collectedFormUrls);
    }
  }, [collectedFormUrls]);

  return (
    <div>
      <ListHeader
        newButtonText="+ タグ連携フォームを作成"
        onNewButtonClick={() => setNewModalVisible(true)}
      />
      <SearchAction />
      <div className="flex flex-col gap-2">
        <Container
          lockAxis="y"
          dragHandleSelector=".dragHandle"
          onDrop={onDrop}>
          {collectedFormUrls.map((collectedFormUrl) => (
            <Draggable key={collectedFormUrl.id}>
              <ListItem collectedFormUrl={collectedFormUrl} />
            </Draggable>
          ))}
        </Container>
      </div>
      <NewModal
        visible={newModalVisible}
        onClose={() => setNewModalVisible(false)}
      />
    </div>
  );
};
