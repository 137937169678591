import gql from 'graphql-tag';
import {preleadStatusFragment} from '..';

export default gql`
  fragment statusChangeActivityFragment on StatusChangeActivity {
    status
    statusText
    clientPreleadStatus {
      ...preleadStatusFragment
    }
    channel
  }
  ${preleadStatusFragment}
`;
