import gql from 'graphql-tag';

export default gql`
  fragment formBlockSubmitButtonFragment on FormBlockSubmitButton {
    id
    buttonText
    buttonColor
    textColor
    createdAt
    updatedAt
  }
`;

