import React from 'react';
import {Task} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';
import moment from 'moment';
import {Alert} from 'components/Ui/Icon';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;
  const isExpired = moment().isAfter(moment(task.dueDate), 'day');

  return (
    <Container isExpired={isExpired}>
      {task.dueDate && moment(task.dueDate).format('YYYY/MM/DD')}
      {isExpired && <Alert />}
    </Container>
  );
};

const Container = styled.div<{isExpired: boolean}>`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: ${({isExpired}) => isExpired && '#EB5757'};

  svg {
    width: 15px;
    height: 15px;
    margin-left: 4px;
    path {
      stroke: #eb5757;
      stroke-width: 2.5px;
    }
  }
`;

export default Cell;
