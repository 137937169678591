import {Select} from 'antd';
import {Prefecture, useAllPrefecturesQuery} from 'api';
import React from 'react';
import styled from 'styled-components';
import useSearchParams from '../../useSearchParams';

const PrefecturesFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {data: {allPrefectures = []} = {}} = useAllPrefecturesQuery({});

  return (
    <Select
      mode="multiple"
      allowClear
      value={query.prefectureIds || []}
      onChange={(value) => setQuery({page: 1, prefectureIds: value})}>
      {allPrefectures.map((option: Prefecture) => (
        <StatusSelect.Option
          value={option.id}
          key={option.id}
          label={option.name}>
          {option.name}
        </StatusSelect.Option>
      ))}
    </Select>
  );
};

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

export default PrefecturesFilter;
