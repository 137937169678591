import React from 'react';

function Icon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="13" fill="#F3F5F7" />
      <path
        d="M9.25 13L12.1667 15.9167M16.75 13H9.25H16.75ZM9.25 13L12.1667 10.0833L9.25 13Z"
        stroke="#899098"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
