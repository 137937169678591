import React from 'react';
// @ts-ignore
import AuthContainer from './auth';
import {useCurrentUserQuery} from 'api';
import styled from 'styled-components';
import {Route, Switch} from 'react-router';
import List from './list';
import Block from './block';
import Top from './Top';
import Settings from './settings';
import Garage from './garage';
import Report from './report';
import Reports from './reports';
import Pool from './pools';
import Form from './form';
import Tasks from './tasks';
import Approaches from './approaches';
import Downloads from './downloads';
import Header from './Header';
import '../../globals.css';
import MailCampaign from './mail_campaigns';
import Maintenance from './maintenance';
import Dashboard from './dashboard';

export default () => {
  const {data: {currentUser = null} = {}, loading} = useCurrentUserQuery();

  if (loading) return null;
  if (!currentUser) return <AuthContainer />;

  return (
    <Switch>
      <Route>
        <Container>
          <Header />
          <Main>
            <Switch>
              <Route path="/list" component={List} />
              <Route path="/block" component={Block} />
              <Route path="/garage" component={Garage} />
              <Route path="/report" component={Report} />
              <Route path="/reports" component={Reports} />
              <Route path="/settings" component={Settings} />
              <Route path="/pools" component={Pool} />
              <Route path="/form" component={Form} />
              <Route path="/mail_campaigns" component={MailCampaign} />
              <Route path="/downloads" component={Downloads} />
              <Route path="/tasks" component={Tasks} />
              <Route path="/approaches" component={Approaches} />
              <Route path="/maintenance" component={Maintenance} />
              <Route path="/dashboard" component={Dashboard} />
              <Route component={Top} />
            </Switch>
          </Main>
        </Container>
      </Route>
    </Switch>
  );
};

const Container = styled.div`
  display: flex;
  background: #ffffff;
  height: 100%;
  flex-direction: column;
`;

const Main = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
`;
