import React from 'react';
import {
  LeadSource,
  OriginalLeadSource,
  InflowDate,
  Stage,
  Tag,
  ExcludedTag,
  CustomizeItem,
  MailAddress,
  MonthsSinceAction,
  User,
  TrackedAt,
  ClientPhaseProspect,
} from './Condition';
import useCondition from '../useCondition';

const LeadFilters = () => {
  const {numberOfLeadFiltersInUse} = useCondition();

  if (numberOfLeadFiltersInUse === 0) {
    return null;
  }

  return (
    <div className="p-6 pb-2 bg-white rounded flex items-start gap-4 border border-c-border">
      <h3 className="m-0 font-bold text-base w-20">リード</h3>
      <div className="flex-1 flex flex-col gap-4">
        <LeadSource />
        <OriginalLeadSource />
        <InflowDate />
        <User />
        <ClientPhaseProspect />
        <Stage />
        <Tag />
        <ExcludedTag />
        <CustomizeItem />
        <MailAddress />
        <MonthsSinceAction />
        <TrackedAt />
      </div>
    </div>
  );
};

export default LeadFilters;
