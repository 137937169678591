import React from 'react';
import styled from 'styled-components';

type Props = {
  page:
    | 'group'
    | 'projects'
    | 'projectReport'
    | 'newProject'
    | 'projectGroups'
    | 'website'
    | 'share'
    | 'garage'
    | 'mail'
    | 'block'
    | 'report';
};

export const Container: React.FC<Props> = ({page, children}) => {
  const style = {
    group: {
      display: 'grid',
      gridTemplateAreas: `"header" "body"`,
      gridTemplateRows: '70px calc(100vh - 130px)',
      padding: 32,
      position: 'relative',
    },
    projects: {
      display: 'grid',
      gridTemplateAreas: `"body"`,
      gridTemplateRows: 'calc(100vh - 60px)',
      padding: 32,
      position: 'relative',
    },
    projectReport: {
      display: 'grid',
      gridTemplateAreas: `"header" "menu" "body"`,
      gridTemplateRows: '70px 50px calc(100vh - 120px)',
      padding: 32,
      position: 'relative',
    },
    newProject: {
      display: 'grid',
      gridTemplateAreas: `"header" "action" "body"`,
      gridTemplateRows: '70px 70px calc(100vh - 200px)',
      padding: 0,
      position: 'relative',
    },
    projectGroups: {
      display: 'grid',
      gridTemplateAreas: `"header" "title" "menu" "body"`,
      gridTemplateRows: '70px 50px 62px calc(100vh - 242px)',
      padding: 32,
    },
    website: {
      display: 'grid',
      gridTemplateAreas: `"header" "action" "websiteMenu" "body"`,
      gridTemplateRows: '70px 70px 53px calc(100vh - 253px)',
      padding: 0,
    },
    share: {
      display: 'grid',
      gridTemplateAreas: `"header" "body"`,
      gridTemplateRows: '70px calc(100vh - 70px)',
      padding: 32,
      position: 'relative',
    },
    mail: {
      display: 'grid',
      gridTemplateAreas: `"header" "menu" "body"`,
      gridTemplateRows: '70px 50px calc(100vh - 180px)',
      padding: 32,
      position: 'relative',
    },
    garage: {
      display: 'grid',
      gridTemplateAreas: `"header" "main"`,
      gridTemplateRows: '70px calc(100vh - 130px)',
      padding: 32,
      position: 'relative',
    },
    block: {
      padding: 32,
    },
    report: {
      display: 'grid',
      gridTemplateAreas: `"header" "body"`,
      gridTemplateRows: '70px calc(100vh - 130px)',
      padding: 32,
      position: 'relative',
    },
  };
  const value = style[page];
  return <Box {...value}>{children}</Box>;
};

type StyledProps = {
  display?: string;
  gridTemplateAreas?: any;
  gridTemplateRows?: string;
  padding: number;
  position?: string;
};

const Box = styled.div<StyledProps>`
  display: ${(props) => props.display};
  grid-template-areas: ${(props) => props.gridTemplateAreas};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  padding-left: ${(props) => props.padding}px;
  background: #f3f5f7;
  position: ${(props) => props.position};
`;
