import {SalesforceDeal} from 'api';
import moment from 'moment';
import React from 'react';
import {useHistory, useLocation} from 'react-router';

interface Props {
  deal: SalesforceDeal;
}

export default ({deal}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const active = location.pathname.startsWith(`/deals/${deal.id}`);
  const onClick = () => {
    history.push(`/deals/${deal.id}`);
  };
  return (
    <button
      onClick={onClick}
      style={{backgroundColor: active ? '#F9FCFF' : ''}}
      className={`flex w-full cursor-pointer flex-col items-start gap-1 rounded-sm border p-3 hover:bg-[#F9FCFF] ${
        active ? 'bg-[#F9FCFF} border-c-primary' : 'border-c-border bg-white'
      }`}
    >
      <span className="flex h-4 items-center justify-center rounded-sm bg-[#EEF0F2] px-2 text-xs text-c-light">
        {deal?.salesforceOpportunityStage?.masterLabel}
      </span>
      <p className="m-0 w-full truncate text-left text-sm font-bold">
        {deal.name}
      </p>
      <div className="flex w-full gap-2 truncate text-xs text-c-light">
        <span>{moment(deal.createdAt).format('YYYY/MM/DD')}</span>
      </div>
    </button>
  );
};
