import gql from 'graphql-tag';
import salesforceLeadFragment from '../salesforce/salesforceLead';

export default gql`
  fragment salesforceLeadCreateProspectActivityFragment on SalesforceLeadCreateProspectActivity {
    salesforceLead {
      ...salesforceLeadFragment
    }
  }
  ${salesforceLeadFragment}
`;
