import gql from 'graphql-tag';

export default gql`
  fragment preleadProjectFragment on PreleadProject {
    id
    projectId
    preleadId
    uuid
    serialNumber
    status
    statusText
    clientPreleadStatusId
    mailStatus
    mailStatusText
    mailCount
    mailUpdatedAt
    responseStatus
    responseStatusText
    clickCount
    clickUpdatedAt
    telStatus
    telCount
    telUpdatedAt
    campaignCount
    isDuplicate
    isDuplicateByRule
    isUrl
    isMultiple
    data
    memo
    memoHtml
    clientTelStatusId
    userId
    mailCampaignSentDate
  }
`;
