import gql from 'graphql-tag';

export default gql`
  fragment gmailMessageFragment on GmailMessage {
    id
    uuid
    from
    fromAddress
    to
    toAddresses
    cc
    ccAddresses
    subject
    bodyPlain
    bodyHtml
    sendAt
    snippet
  }
`;
