import React from 'react';

const Audio = ({file}: {file: string}) => {
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (ref?.current) {
      const audio = ref.current as any;
      audio.volume = 0.2;
    }
  }, [ref]);

  return (
    <audio
      controls
      ref={ref}
      src={file}
      style={{width: '100%', margin: '0.5rem 0'}}
    />
  );
};

export default Audio;
