import React from 'react';
import useChartData from './useChartData';
import useTableData from './useTableData';
import {BarChart} from '../../components/Chart';
import {GroupTable} from '../../components/Table';
import {Button} from '../../components/Button';
import {ButtonGroup} from '../../components/ButtonGroup';
import {useHistory} from 'react-router';

export default () => {
  const chart = useChartData();
  const table = useTableData();
  const history = useHistory();

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <ButtonGroup>
          <Button
            onClick={() =>
              history.push(`/reports/deal/lead_source${location.search}`)
            }>
            実績
          </Button>
          <Button
            active
            onClick={() =>
              history.push(`/reports/deal/lead_source_quota${location.search}`)
            }>
            目標/実績
          </Button>
        </ButtonGroup>
      </div>
      <div className="border border-c-border">
        <div className="h-20 border-b border-c-border flex">
          <div className="border-r border-c-border px-6 flex flex-col justify-center">
            <div className="text-base">リードタイム</div>
            <div className="text-xl font-bold flex gap-2 items-baseline">
              平均<em className="text-3xl not-italic">20日</em>
            </div>
          </div>
        </div>
        <BarChart
          chart={chart}
          legend={false}
          barProps={{barSize: 'thin'}}
          className="h-[540px]"
        />
        <div className="p-8 border-t border-c-border">
          <GroupTable table={table} />
        </div>
      </div>
    </div>
  );
};
