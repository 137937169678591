import React from 'react';

function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E1E6EB" />
      <path
        d="M11.6663 11.9999C12.4027 11.9999 12.9997 11.403 12.9997 10.6666C12.9997 9.93021 12.4027 9.33325 11.6663 9.33325C10.93 9.33325 10.333 9.93021 10.333 10.6666C10.333 11.403 10.93 11.9999 11.6663 11.9999Z"
        fill="#2693FF"
        stroke="#2693FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9997 14H10.333V22.6667H12.9997V14Z"
        fill="#2693FF"
        stroke="#2693FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.667 22.6667H18.3337V18C18.3337 17.2636 18.9306 16.6667 19.667 16.6667C20.4034 16.6667 21.0003 17.2636 21.0003 18V22.6667H23.667V18C23.667 15.7909 21.8761 14 19.667 14C17.4579 14 15.667 15.7909 15.667 18V22.6667Z"
        fill="#2693FF"
        stroke="#2693FF"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
