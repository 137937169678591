import React from 'react';
import {useParams} from 'react-router-dom';
import {Breadcrumb} from 'components/antd';
import useClientUser from 'hooks/useClientUser';
import Title from '../../Title';
import Tab from '../../Tab';
import Pagination from '../../Pagination';
import {useAccountGroupsQuery} from 'api';
import useSearchParams from './useSearchParams';
import Item from './Item';
import Search from './Search';
import NewModal from './NewModal';

export default () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {page, perPage, setQuery, searchWord} = useSearchParams();
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {isMember} = useClientUser();

  const {
    data: {accountGroups: {accountGroups = [], pagination = {}} = {}} = {},
  } = useAccountGroupsQuery({
    variables: {
      uuid: poolId,
      page: page,
      perPage: perPage,
      searchWord,
    },
  });

  return (
    <div className="p-8 bg-c-bg h-[calc(100vh_-_60px)] overflow-scroll">
      <Breadcrumb>
        <Breadcrumb.Item>リード一覧</Breadcrumb.Item>
      </Breadcrumb>
      <Title title="リード一覧" />
      <Tab />
      <div className="mt-4 flex items-center justify-between">
        <Search />
        {isMember && (
          <button
            onClick={() => setIsOpen(true)}
            className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32">
            + リストを作成
          </button>
        )}
      </div>
      <div className="flex flex-col gap-1 py-4">
        {accountGroups.map((accountGroup) => (
          <Item key={accountGroup.uuid} accountGroup={accountGroup} />
        ))}
      </div>
      <div className="flex items-center justify-center">
        <Pagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={perPage}
          onChange={(page) => setQuery({page})}
        />
      </div>
      <NewModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    </div>
  );
};
