import React from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';

type Props = {
  children?: React.ReactNode;
  htmlType?: 'submit' | 'button';
  type?: 'primary' | 'ghost' | 'dashed';
  buttonType?: 'default' | 'outlined' | 'primary' | 'ghost';
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactNode;
  style?: any;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export default ({buttonType, children, ...props}: Props) => {
  if (buttonType === 'outlined') {
    return <OutlinedButton {...props}>{children}</OutlinedButton>;
  } else if (buttonType === 'ghost') {
    return <GhostButton {...props}>{children}</GhostButton>;
  } else {
    return <DefaultButton {...props}>{children}</DefaultButton>;
  }
};

const ButtonTemplate = styled(Button)`
  padding: 0;
  border-radius: 4px;
`;

const DefaultButton = styled(ButtonTemplate)`
  background: #68b5fb;

  &:hover,
  &:active {
    background: #65aae8;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    &:hover {
      background: #f5f5f5;
    }
  }

  span {
    color: #fff;
  }
`;

const GhostButton = styled(ButtonTemplate)`
  background: #f3f5f7;

  &:hover,
  &:active {
    background: #e2e6ea;
    border: 1px solid transparent;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    &:hover {
      background: #f5f5f5;
    }
  }

  span {
    color: #899098;
  }
`;

const OutlinedButton = styled(ButtonTemplate)`
  color: #68b5fb;
  background: #fff;
  border: 1px solid #68b5fb;
  border-radius: 4px;

  &:hover {
    background: #ebf5ff;
    border: 1px solid #68b5fb;
  }
`;
