import React from 'react';
import {Workflow, AutomaticMailStep, CallStep} from 'api';
import {Info} from 'components/Ui/Icon';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const count = React.useMemo(() => {
    const total =
      workflow.prospectsStatusOpenCount + workflow.prospectsStatusClosedCount;
    const open = workflow.prospectsStatusOpenCount;
    const closed = workflow.prospectsStatusClosedCount;
    const deal = workflow.steps?.reduce(
      (acc, step) => acc + step.dealsCount,
      0,
    );
    const dealRate = deal && total ? (deal / total) * 100 : 0;

    return {
      total,
      open,
      closed,
      deal,
      dealRate,
    };
  }, [workflow]);

  return (
    <>
      <span className="text-xs text-c-lighter">全体</span>
      <ul className="flex gap-8">
        <li className="flex flex-col gap-2 w-16">
          <span className="text-sm text-c-light">
            リード
            <span className="[&>svg]:h-3 [&>svg]:mb-[-2px] ml-1 group relative">
              <Info />
              <span className="whitespace-nowrap rounded bg-[rgba(0,0,0,.9)] p-2 text-white absolute -top-[70px] left-1/2 -translate-x-1/2 before:content-[''] before:absolute before:-translate-x-1/2 before:left-1/2 before:top-full before:border-4 before:border-transparent before:border-t-black opacity-0 group-hover:opacity-80 transition pointer-events-none">
                <ul className="flex flex-col gap-2 text-sm">
                  <li className="flex items-center">
                    <span className="bg-white w-[6px] h-[6px] mr-1 rounded-full border border-c-light" />
                    合計
                  </li>
                  <li className="flex items-center">
                    <span className="bg-c-green w-[6px] h-[6px] mr-1 rounded-full" />
                    開始
                  </li>
                  <li className="flex items-center">
                    <span className="bg-c-lighter w-[6px] h-[6px] mr-1 rounded-full" />
                    終了
                  </li>
                </ul>
              </span>
            </span>
          </span>
          <div className="flex items-center gap-1 min-w-min">
            <span className="bg-white w-[6px] h-[6px] mr-1 rounded-full border border-c-light" />
            <span className="text-xl font-bold">
              {(count.total || 0).toLocaleString()}
            </span>
          </div>
          <div className="flex items-center gap-1 min-w-min">
            <span className="bg-c-green w-[6px] h-[6px] mr-1 rounded-full" />
            <span className="text-xl font-bold">
              {(count.open || 0).toLocaleString()}
            </span>
          </div>
          <div className="flex items-center gap-1 min-w-min">
            <span className="bg-c-lighter w-[6px] h-[6px] mr-1 rounded-full" />
            <span className="text-xl font-bold">
              {(count.closed || 0).toLocaleString()}
            </span>
          </div>
        </li>
        <li className="flex flex-col gap-2 w-16">
          <span className="text-sm text-c-light">商談</span>
          <span className="text-xl font-bold">
            {(count.deal || 0).toLocaleString()}
          </span>
          <span className="font-bold">
            ({(count.dealRate || 0).toFixed(1)}{' '}
            <span className="text-xs">%</span>)
          </span>
        </li>
      </ul>
    </>
  );
};
