import gql from 'graphql-tag';

export default gql`
  fragment workflowSearchConditionFragment on WorkflowSearchCondition {
    leadSources
    originalLeadSources
    collectedFormUrls
    forms
    ranks
    stages
    subCategories {
      ...subCategoryFragment
    }
    listingMarkets
    employeeNumber
    capitalFund
    tags
    excludedTags
    cities {
      ...cityFragment
    }
    isEmail
    monthsSinceAction
    hasSalesforceDeal
    hasSalesforceLead
    hasSalesforceContact
    salesforceDealRecordTypeId
    salesforceDealSearchConditions
    hubspotDealSearchConditions
    salesforceLeadRecordTypeId
    salesforceLeadFieldSelectStatusIds
    salesforceLeadOwnerIds
    salesforceLeadSearchConditions
    salesforceContactRecordTypeId
    salesforceContactOwnerIds
    salesforceContactSearchConditions
    prospectCustomizeItemSearchConditions
    users
    inflowDateType
    inflowDateFrom
    inflowDateTo
    trackedAtFrom
    trackedAtTo
    clientPhaseProspects
  }
`;
