import React from 'react';
import {Form, useUpdateFormMutation} from 'api';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import debounce from 'helpers/debounce';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [updateForm] = useUpdateFormMutation({refetchQueries: ['form']});

  const {register, watch, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        formFontSize: Yup.number().required(),
      }),
    ),
    defaultValues: {
      formFontSize: form.formFontSize || 14,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: any) => {
    updateForm({
      variables: {
        uuid: form.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-1">
      <label className="tracking-wide font-medium text-base text-c-light">
        文字サイズ
      </label>
      <select
        {...register('formFontSize')}
        className="form-select border-c-border rounded text-base w-full">
        {[12, 13, 14, 15, 16, 17, 18, 19, 20].map((size) => (
          <option value={size} key={size}>
            {size}px
          </option>
        ))}
      </select>
    </div>
  );
};
