import gql from 'graphql-tag';

export default gql`
  fragment blockGroupMemberFragment on BlockGroupMember {
    id
    uuid
    blockGroupId
    address
    corpNumber
    companyId
    webSite
    domain
    name
    shortName
    subdomain
    tel
    telephoneNumber
    createdAt
    updatedAt
  }
`;
