import React from 'react';
import {Input} from 'components/antd';
import useSearchParams from './useSearchParams';
import {Lense} from '../../../../components/Ui/Icon';

export default () => {
  const {
    query: {searchWord},
    setQuery,
  } = useSearchParams();

  const setSearchWord = (e: any) => {
    setQuery({
      searchWord: e.target.value,
    });
  };

  return (
    <Input
      type="search"
      name="search"
      placeholder="検索"
      prefix={<Lense />}
      onBlur={setSearchWord}
      onPressEnter={setSearchWord}
      defaultValue={searchWord}
      className="flex items-center border rounded mb-5 h-10 md:w-48 bg-white border-bdc4cb focus:outline-none focus:bg-white"
    />
  );
};
