import React from 'react';
import {Select} from 'components/antd';
import useMailTemplate from 'hooks/useMailTempate';

interface Props {
  onSelect: (value: string) => void;
}

export default ({onSelect}: Props) => {
  const {templates, templateTitle} = useMailTemplate();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '15px',
      }}>
      <label
        style={{
          display: 'inline-block',
          paddingLeft: '15px',
        }}>
        本文
      </label>
      <Select
        style={{
          display: 'block',
          margin: '0 15px 0 auto',
          width: '360px',
        }}
        placeholder="メールテンプレート"
        onSelect={onSelect}
        optionFilterProp="children"
        showSearch>
        {templates.map((template) => (
          <Select.Option key={template.uuid} value={template.uuid}>
            {templateTitle(template)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
