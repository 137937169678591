import React from 'react';
import {HubspotDeal} from 'api';

interface Props {
  hubspotDeal: HubspotDeal;
}

export default ({hubspotDeal}: Props) => (
  <>{hubspotDeal.hubspotDealPipeline?.label}</>
);
