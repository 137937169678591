import gql from 'graphql-tag';
import gmailMessageFragment from '../gmail/message';

export default gql`
  fragment gmailImportMailMessageFragment on GmailImportMailMessage {
    id
    updatedAt
    createdAt
    gmailMessage {
      ...gmailMessageFragment
    }
  }
  ${gmailMessageFragment}
`;
