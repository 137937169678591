import React from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {SalesforceModalContext} from './useSalesforceModalContext';
import Header from './Header';
import Tabs from './Tabs';
import {ProspectPool} from 'api';
import Content from './Content';
import {MemoryRouter, useHistory} from 'react-router-dom';

export type SalesforceModalTabs = 'deals' | 'contact' | 'lead' | 'account';

interface Props {
  visible: boolean;
  onClose: () => void;
  poolId: string;
  prospectPool: ProspectPool;
  defaultTab?: SalesforceModalTabs;
}

const Modal = ({poolId, visible, onClose, prospectPool, defaultTab}: Props) => {
  const initialPath = defaultTab ? `/${defaultTab}` : '/deals';
  const history = useHistory();

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="relative flex h-[95vh] w-full max-w-3xl transform flex-col overflow-hidden rounded bg-c-bg text-left align-middle shadow-xl transition-all">
                <SalesforceModalContext.Provider
                  value={{
                    browserHistory: history,
                    onClose,
                    poolId,
                    prospectPool,
                  }}>
                  <MemoryRouter initialEntries={[initialPath]}>
                    <Header prospectPool={prospectPool} />
                    <Tabs prospectPool={prospectPool} />
                    <Content prospectPool={prospectPool} />
                  </MemoryRouter>
                </SalesforceModalContext.Provider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
