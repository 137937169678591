import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {Form} from 'api';
import TextBlock from '../../../../form/Toolbox/blocks/blockId/Text';
import ButtonBlock from '../../../../form/Toolbox/blocks/blockId/Button';
import ImageBlock from '../../../../form/Toolbox/blocks/blockId/Image';

interface Props {
  form: Form;
}

const blockComponents: {[key: string]: any} = {
  FormBlockText: TextBlock,
  FormBlockButton: ButtonBlock,
  FormBlockImage: ImageBlock,
};

export default ({form}: Props) => {
  const {blockId} = useParams<{blockId: string}>();
  const formBlock = React.useMemo(
    () =>
      form
        ? form.formPages
            .flatMap((page) => page.formBlocks)
            .find((block) => block.uuid === blockId)
        : null,
    [form, blockId],
  );

  if (!formBlock) return null;

  const BlockComponent = blockComponents[formBlock.formBlockable.__typename];

  if (!BlockComponent) return null;

  return (
    <div className="py-4 flex flex-col gap-4">
      <Link to={`/form/forms/${form.uuid}/done/blocks`} className="text-base">
        〈 戻る
      </Link>
      <BlockComponent block={formBlock} key={formBlock.id} />
    </div>
  );
};
