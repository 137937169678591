import React from 'react';

const Icon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#88C9D8" />
    <path
      d="M19.6667 10.9166H10.3333C10.0112 10.9166 9.75 11.1778 9.75 11.5V18.5C9.75 18.8221 10.0112 19.0833 10.3333 19.0833H19.6667C19.9888 19.0833 20.25 18.8221 20.25 18.5V11.5C20.25 11.1778 19.9888 10.9166 19.6667 10.9166Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6668 11.2084L15.0002 15.5834L10.3335 11.2084"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
