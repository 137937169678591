import gql from 'graphql-tag';

export default gql`
  fragment hubspotNoteProspectActivityFragment on HubspotNoteProspectActivity {
    id
    comment
    commentHtml
    stage
    stageText
    recordedAt
    hubspotNoteIdString
    hubspotContactIdString
    createdAt
  }
`;

