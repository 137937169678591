import React from 'react';
import {Row} from 'react-table';
import {PreleadProject} from 'api';

interface Props {
  row: Row<PreleadProject>;
  value: any;
}

const ProjectGroupCustomizeItemNumberCell = ({row, value}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const projectGroupPreleadCustomizeItemId = value;

  const customizeItem = React.useMemo(
    () =>
      preleadProject.prelead.projectGroupCustomizeItemNumbers.find(
        (item) =>
          item.projectGroupPreleadCustomizeItemId ===
          projectGroupPreleadCustomizeItemId,
      ),
    [preleadProject, projectGroupPreleadCustomizeItemId],
  );

  return customizeItem?.value?.toLocaleString() || null;
};

export default ProjectGroupCustomizeItemNumberCell;
