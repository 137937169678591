import React, {useState} from 'react';
import styled from 'styled-components';
import {useLeadSourcesQuery, useUpdateLeadSourcePositionMutation} from 'api';
import usePools from 'hooks/usePools';
import {Handle} from 'components/Ui/Icon';
import Edit from './Edit';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

export default () => {
  const [updateModalLeadSource, setUpdateModalLeadSource] = useState(null);
  const [leadSourceList, setLeadSourceList] = React.useState([]);

  const {selectedPool} = usePools();
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    variables: {uuid: selectedPool.uuid},
    skip: !selectedPool,
    fetchPolicy: 'cache-and-network',
  });

  const [updateLeadSourcePosition] = useUpdateLeadSourcePositionMutation();

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...leadSourceList];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      setLeadSourceList(items);
      updateLeadSourcePosition({
        variables: {
          id: removed.id,
          uuid: selectedPool.uuid,
          position: destIndex,
        },
      });
    },
    [leadSourceList],
  );

  React.useEffect(() => {
    setLeadSourceList(leadSources);
  }, [leadSources]);

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {leadSourceList.map((leadSource, index) => (
                <Draggable
                  key={leadSource.id}
                  draggableId={leadSource.id}
                  index={index}>
                  {(provided, snapshot) => (
                    <DraggableItem
                      ref={provided.innerRef}
                      onClick={() => setUpdateModalLeadSource(leadSource)}
                      {...provided.draggableProps}
                      className={snapshot.isDragging ? 'dragging' : ''}>
                      <HandleWrapper {...provided.dragHandleProps}>
                        <Handle />
                      </HandleWrapper>
                      <LeadSourceStatus>
                        <span>{leadSource.name}</span>
                      </LeadSourceStatus>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Edit
        leadSource={updateModalLeadSource}
        selectedPool={selectedPool}
        onClose={() => setUpdateModalLeadSource(null)}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 19px;
`;

const Item = styled.div`
  min-height: 48px;
  width: 100%;
  box-shadow: 0 0 1px #444b52;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const Name = styled.div`
  max-width: 93%;
  font-size: 12px;
`;

const DraggableItem = styled.div`
  min-height: 48px;
  box-shadow: 0 0 1px #444b52;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: solid 3px #fff;
  cursor: pointer;

  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    border-left: solid 3px #68b4fb;
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;

const LeadSourceStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  span {
    margin-left: 8px;
    width: 275px;
  }
`;
