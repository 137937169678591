import React from 'react';
import styled from 'styled-components';
import {ProspectPool} from 'api';
import useJobListing from 'hooks/useJobListing';
import moment from 'moment';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {prospect} = prospectPool || {};
  const {account} = prospect || {};

  if (!account) return null;

  const {aggregatedJobListingsByMedia} = useJobListing(
    account?.company?.crawlerJobListingPages,
  );

  return (
    <>
      {aggregatedJobListingsByMedia?.map((media) => {
        if (media.jobInformations?.length > 0) {
          return (
            <Media key={media.id}>
              <MediaName>{media.name}</MediaName>
              {media.jobInformations.map((info: any) => (
                <React.Fragment key={info.id}>
                  <label className="pl-2 text-c-light">
                    {info.date && moment(info.date).format('YYYY/MM/DD')}
                  </label>
                  <JobTitle key={info.id} href={info.url} target="_blank">
                    {info.title || info.url}
                  </JobTitle>
                </React.Fragment>
              ))}
              <BorderBottom />
            </Media>
          );
        }
      })}
    </>
  );
};

const Media = styled.div`
  padding-bottom: 10px;
`;

const MediaName = styled.div`
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.15px;
  color: #222426;
`;

const JobTitle = styled.a`
  display: block;
  padding-left: 7px;
  margin-bottom: 8px;
  font-size: 11px;
  letter-spacing: 0.15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BorderBottom = styled.div`
  border-bottom: 0.5px solid #bdc4cb;
  margin-bottom: 20px;
  padding-top: 20px;
`;
