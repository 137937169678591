import React from 'react';
import {Container, DropResult} from 'react-smooth-dnd';
import {
  CollectedFormUrl,
  CollectedFormUrlField,
  useCollectedFormUrlFieldsQuery,
  useCreateCollectedFormUrlFieldMutation,
  useUpdateCollectedFormUrlFiedByIdMutation,
  useUpdateCollectedFormUrlFieldByIdMutation,
} from 'api';
import Field from './Field';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const [fields, setFields] = React.useState<CollectedFormUrlField[]>([]);
  const {data: {collectedFormUrlFields = []} = {}} =
    useCollectedFormUrlFieldsQuery({
      variables: {uuid: collectedFormUrl.uuid},
    });

  const [create] = useCreateCollectedFormUrlFieldMutation({
    variables: {
      collectedFormUrlUuid: collectedFormUrl.uuid,
      attributes: {},
    },
    refetchQueries: ['collectedFormUrlFields'],
  });

  const [update] = useUpdateCollectedFormUrlFieldByIdMutation({
    refetchQueries: ['collectedFormUrlFields'],
  });

  const onDrop = (result: DropResult) => {
    const sourceIndex = result.removedIndex;
    const destIndex = result.addedIndex;

    const items = [...fields];
    const [removed] = items.splice(sourceIndex, 1);
    items.splice(destIndex, 0, removed);
    setFields(items);

    update({
      variables: {
        id: removed.id,
        attributes: {
          position: destIndex,
        },
      },
    });
  };

  React.useEffect(() => {
    if (collectedFormUrlFields) {
      setFields(collectedFormUrlFields);
    }
  }, [collectedFormUrlFields]);

  return (
    <div className="flex flex-col gap-1 w-full">
      <Container
        lockAxis="y"
        dragClass="dragHandle"
        onDrop={onDrop}
        render={(ref) => (
          <>
            <div className="flex font-bold border-b border-b-c-border">
              <div className="w-9 p-2" />
              <div className="grid grid-cols-3 w-full">
                <div className="p-2">フォーム項目名</div>
                <div className="p-2">フィールド名（name属性）</div>
                <div className="p-2">LEADPAD項目（任意）</div>
              </div>
              <div className="w-12 p-2" />
            </div>
            <div ref={ref} className="flex flex-col">
              {collectedFormUrlFields.map((field) => (
                <Field key={field.id} collectedFormUrlField={field} />
              ))}
            </div>
          </>
        )}
      />
      <button
        onClick={() => create()}
        className="my-2 w-32 h-8 border border-c-border rounded bg-white cursor-pointer hover:bg-c-bg transition-all">
        + 項目を追加
      </button>
    </div>
  );
};
