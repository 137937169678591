import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import Workflow from './Workflow';
import TrackedAt from './TrackedAt';
import TelStatus from './TelStatus';
import LastTelActivity from './LastTelActivity';
import MailCampaign from './MailCampaign';
import ExistTelActivity from './ExistTelActivity';

type Option = {
  label: string;
  value: string;
  isUnselected: boolean;
};

const ActionFilters = () => {
  const {setFilterType} = useFilterModalContext();
  const {query} = useSearchParams();

  const options: Option[] = [
    {
      label: 'ワークフロー',
      value: 'workflow',
      isUnselected:
        !query.isTargetAllWorkflows &&
        (!query?.workflowIds || !query?.workflowIds.length),
    },
    {
      label: 'メールキャンペーン',
      value: 'mailCampaign',
      isUnselected: query.isTargetAllMailCampaigns
        ? !query.isTargetAllMailCampaigns
        : !query?.mailCampaignId,
    },
    {
      label: '最新コールログ',
      value: 'lastTelActivity',
      isUnselected:
        !query?.telUpdatedDateFrom &&
        !query?.telUpdatedDateTo &&
        !query.lastTelUserIds.length,
    },
    {
      label: '最新TELステータス',
      value: 'telStatus',
      isUnselected: !query?.telStatuses || !query.telStatuses.length,
    },
    {
      label: '最新Webサイト訪問期間',
      value: 'trackedAt',
      isUnselected: !query.trackedAtFrom && !query.trackedAtTo,
    },
    {
      label: 'コールログ登録有無',
      value: 'existTelActivity',
      isUnselected: query?.isExistTelActivity === null,
    },
  ];

  const unselectedOptions = options.filter((option) => option.isUnselected);

  return (
    <div className="p-6 bg-white rounded flex items-start gap-4">
      <h3 className="m-0 font-bold text-base w-20">アクション</h3>
      <div className="flex-1 flex flex-col gap-4">
        <Workflow />
        <MailCampaign />
        <LastTelActivity />
        <ExistTelActivity />
        <TelStatus />
        <TrackedAt />
        <Menu as="div" className="relative text-left inline-block">
          <Menu.Button className="cursor-pointer bg-white border border-c-border rounded text-sm flex items-center justify-center h-7 px-4">
            + 検索条件を追加
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute left-0 border border-c-border w-[10rem] rounded bg-white shadow-lg focus:outline-none z-10">
              <div className="px-1 py-1 ">
                {unselectedOptions.map((option, index) => (
                  <Menu.Item key={index}>
                    <button
                      onClick={() => setFilterType(option.value)}
                      className="cursor-pointer bg-white text-left p-2 w-full hover:bg-[#F1F8FF] hover:text-c-primary">
                      {option.label}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default ActionFilters;
