import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowHubspotDealSelectSearchConditionMutation,
  useWorkflowQuery,
  useHubspotDealPropertyDefinitionEnumSelectOptionsQuery,
  HubspotDealPropertyDefinitionEnumSelectOption,
} from 'api';
import {Select} from 'components/antd';
import {SelectSearchCondition} from './hubspotDealTypes';
import useClientUser from 'hooks/useClientUser';

const DealSelectOption: React.FC<{condition: SelectSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {data: {hubspotDealPropertyDefinitionEnumSelectOptions = []} = {}} =
    useHubspotDealPropertyDefinitionEnumSelectOptionsQuery({
      variables: {
        hubpostDealPropertyDefinitionId: condition.deal_property_definition_id,
      },
      skip: !condition.deal_property_definition_id,
    });

  const [updateWorkflowHubspotDealSelectSearchCondition] =
    useUpdateWorkflowHubspotDealSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const dealFieldSelectOptions = () => {
    return hubspotDealPropertyDefinitionEnumSelectOptions.map(
      (item: HubspotDealPropertyDefinitionEnumSelectOption) => {
        return {
          value: item.id,
          label: item.label,
        };
      },
    );
  };

  return (
    <Select
      options={dealFieldSelectOptions() || []}
      disabled={
        !isConditionUpdatable ||
        !condition.deal_property_definition_id ||
        !isMember
      }
      style={{minWidth: 280, maxWidth: 600}}
      showSearch
      optionFilterProp="label"
      value={condition.deal_property_definition_select_option_id}
      onChange={(value: string) => {
        updateWorkflowHubspotDealSelectSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              dealPropertyDefinitionId: condition.deal_property_definition_id,
              dealPropertyDefinitionFieldType:
                condition.deal_property_definition_field_type,
              dealPropertyDefinitionPropertyType:
                condition.deal_property_definition_property_type,
              dealPropertyDefinitionSelectOptionId: value,
            },
          },
        });
      }}
    />
  );
};

export default DealSelectOption;
