import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Checkbox} from 'components/antd';
import {
  useMailCampaignQuery,
  useUpdateMailCampaignPreleadSearchConditionMutation,
  useCurrentClientQuery,
} from 'api';
import StatusEclipse from 'components/Ui/StatusEclipse';
import mailColor from 'helpers/mailColor';
import preleadCategoryColor from 'helpers/preleadCategoryColor';

const mailStatusOptions = [
  {text: '送信済み', value: ['sent', 'mail'], color: mailColor('sent')},
  {text: '未送信', value: ['unsent'], color: mailColor('unsent')},
  {
    text: 'エラー',
    value: [
      'invalid',
      'shortage',
      'notfound',
      'charover',
      'recruit',
      'cannotaccept',
      'unknown',
      'duplicate',
      'block',
      'nourl',
    ],
    color: mailColor('invalid'),
  },
];

export default () => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });
  const {data: {currentClient: {preleadStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const [updateMailCampaign] =
    useUpdateMailCampaignPreleadSearchConditionMutation({
      refetchQueries: ['preleadProjectsByMailCampaign'],
    });

  return (
    <Container>
      <h3>条件選択</h3>
      <h4>フォーム送信ステータス</h4>
      <MailCheckboxGroup>
        {mailStatusOptions.map((mailStatus) => (
          <Checkbox
            disabled={mailCampaign?.status !== 'draft'}
            value={mailStatus.value}
            key={mailStatus.text}
            checked={mailStatus.value.every((v) =>
              mailCampaign.searchCondition?.mailStatuses?.includes(v),
            )}
            onChange={(e: any) =>
              updateMailCampaign({
                variables: {
                  uuid: mailCampaignId,
                  searchCondition: {
                    projectId: mailCampaign.searchCondition?.projectId,
                    projectIds: mailCampaign.searchCondition?.projectIds,
                    mailStatuses: e.target.checked
                      ? [
                          ...new Set(
                            [
                              ...(mailCampaign.searchCondition?.mailStatuses ||
                                []),
                              e.target.value,
                            ].flat(),
                          ),
                        ]
                      : mailCampaign.searchCondition?.mailStatuses.filter(
                          (status) => !e.target.value.includes(status),
                        ),
                    preleadStatusIds:
                      mailCampaign.searchCondition?.preleadStatusIds,
                  },
                },
              })
            }>
            <StatusEclipse color={mailStatus.color} /> {mailStatus.text}
          </Checkbox>
        ))}
      </MailCheckboxGroup>
      <h4>プレリードステータス</h4>
      <CheckboxGroup
        value={mailCampaign?.searchCondition?.preleadStatusIds || []}
        disabled={mailCampaign?.status !== 'draft'}
        onChange={(values: string[]) =>
          updateMailCampaign({
            variables: {
              uuid: mailCampaignId,
              searchCondition: {
                projectId: mailCampaign.searchCondition?.projectId,
                projectIds: mailCampaign.searchCondition?.projectIds,
                mailStatuses: mailCampaign.searchCondition?.mailStatuses,
                preleadStatusIds: values,
              },
            },
          })
        }>
        <Checkbox value={'none'} key={'pending'}>
          <StatusMark color={preleadCategoryColor('pending')} /> 未
        </Checkbox>
        {preleadStatuses.map((status) => (
          <Checkbox value={status.id} key={status.id}>
            <StatusMark color={preleadCategoryColor(status.category)} />{' '}
            {status.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
  width: 224px;
  background: #fafbfb;

  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #899098;
  }

  h4 {
    font-size: 12px;
    font-weight: normal;
    color: #899098;
    margin-top: 1rem;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-checkbox {
    top: 0;
  }
`;

const MailCheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-checkbox {
    top: 0;
  }
`;

const StatusMark = styled.span<{color: string}>`
  display: inline-block;
  vertical-align: middle;
  height: 10px;
  width: 10px;
  border-radius: 2px;
  top: -1px;
  position: relative;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid #bdc4cb'};
`;
