import React from 'react';
import styled from 'styled-components';
import {Checkbox, Button, Select, DatePicker} from 'components/antd';
import {Cross, Lense} from 'components/Ui/Icon';
import useSearchParams from './useSearchParams';
import {taskStatuses, taskPriorities} from 'helpers/task';
import {useUsersByClientAllQuery} from 'api';
import moment from 'moment';

interface Props {
  toggleFilter: () => void;
}

export default ({toggleFilter}: Props) => {
  const {query, setQuery} = useSearchParams();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const dateFormat = 'YYYY/MM/DD';

  return (
    <Container>
      <header>
        <h3>フィルター</h3>
        <Button ghost icon={<Cross />} onClick={toggleFilter} />
      </header>
      <section>
        <h4>担当者</h4>
        <Select
          style={{width: '190px'}}
          dropdownMatchSelectWidth={false}
          mode="multiple"
          allowClear
          placeholder={<Lense />}
          value={query.assignees || []}
          onChange={(value) => setQuery({page: 1, assignees: value})}>
          <Select.Option value="none">未設定</Select.Option>
          {users.map((user) => (
            <Select.Option value={user.id} key={user.id}>
              {user.lastName} {user.firstName}
            </Select.Option>
          ))}
        </Select>
      </section>
      <section>
        <h4>期限</h4>
        <DatePicker
          style={{width: '86px', padding: '6px 4px'}}
          placeholder=""
          value={query.dueDate?.from && moment(query.dueDate.from, dateFormat)}
          format={dateFormat}
          onChange={(_, value) => {
            setQuery({
              dueDate: {from: value, to: query.dueDate?.to || ''},
            });
          }}
        />
        <span> 〜 </span>
        <DatePicker
          style={{width: '86px', padding: '6px 4px'}}
          placeholder=""
          value={query.dueDate?.to && moment(query.dueDate.to, dateFormat)}
          format={dateFormat}
          onChange={(_, value) => {
            setQuery({
              dueDate: {from: query.dueDate?.from || '', to: value},
            });
          }}
        />
      </section>
      <section>
        <h4>ステータス</h4>
        <Checkbox.Group
          value={query.statuses}
          options={taskStatuses.map((status) => ({
            label: status.name,
            value: status.value,
          }))}
          onChange={(values: string[]) => setQuery({statuses: values, page: 1})}
        />
      </section>
      <section>
        <h4>アクション</h4>
        <Checkbox.Group
          value={query.types}
          options={[
            {label: '電話', value: 'tel'},
            {label: '手動メール', value: 'mail'},
            {label: 'その他', value: 'other'},
          ]}
          onChange={(values: string[]) => setQuery({types: values})}
        />
      </section>
      <section>
        <h4>優先度</h4>
        <Checkbox.Group
          value={query.priorities}
          options={taskPriorities.map((priority) => ({
            label: priority.name,
            value: priority.value,
          }))}
          onChange={(values: string[]) =>
            setQuery({priorities: values, page: 1})
          }
        />
      </section>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  width: 240px;
  margin-right: 1rem;

  h3,
  h4 {
    color: #899098;
    font-size: 12px;
  }

  h3 {
    font-weight: bold;
    margin: 0;
  }

  header {
    display: flex;
    align-items: center;

    h3 {
      flex: 1;
    }

    button {
      &:hover {
        svg {
          path {
            stroke: #68b5fb !important;
          }
        }
      }
    }
  }

  section {
    margin: 1rem 0;

    label {
      width: 100%;
      margin-bottom: 0.5em;
    }
  }

  .ant-picker-suffix {
    display: none;
  }
  .ant-select-selection-placeholder {
    svg {
      position: relative;
      top: 2px;
    }
  }
`;
