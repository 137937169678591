import React from 'react';
import AddIcon from './AddIcon';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery} from 'api';
import SearchCondition from './SearchCondition';
import {CustomizeItemSearchConditionType} from './CustomizeItemSearchConditionType';

export default () => {
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {workflow: {searchCondition = {}} = {}} = {}} = useWorkflowQuery(
    {
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    },
  );
  const prospectCustomizeItemSearchConditions =
    searchCondition?.prospectCustomizeItemSearchConditions || [];

  return (
    <div className="flex mb-7">
      <div className="w-28 font-medium">項目</div>
      <div className="flex flex-col gap-2">
        {prospectCustomizeItemSearchConditions.map(
          (
            prospectCustomizeItemSearchCondition: CustomizeItemSearchConditionType,
            index: number,
          ) => {
            return (
              <div key={index} className="flex flex-row gap-2">
                <SearchCondition
                  prospectCustomizeItemSearchCondition={
                    prospectCustomizeItemSearchCondition
                  }
                  index={index}
                />
              </div>
            );
          },
        )}
        <AddIcon />
      </div>
    </div>
  );
};
