import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import {StatusEclipse} from 'components/Ui';
import useSearchParams from '../../useSearchParams';
import responseColor from 'helpers/responseColor';

const options = [
  {text: '未選択', value: 'no_response', color: ''},
  {text: '返信あり', value: 'reply', color: '#2D9CDB'},
  {text: 'アポ獲得', value: 'appoint', color: '#27ae60'},
  {text: 'お断り・クレーム', value: 'refusal', color: '#EB5757'},
];

const ResponseStatusesFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.responseStatuses || []}
      onChange={(value) => setQuery({page: 1, responseStatuses: value})}>
      {options.map((option) => (
        <StatusSelect.Option
          value={option.value}
          key={option.value}
          label={
            <React.Fragment>
              <StatusEclipse color={responseColor(option.value)} />
              &nbsp; {option.text}
            </React.Fragment>
          }>
          <StatusEclipse color={responseColor(option.value)} />
          &nbsp; {option.text}
        </StatusSelect.Option>
      ))}
    </Select>
  );
};

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

export default ResponseStatusesFilter;
