import React from 'react';
import styled from 'styled-components';
import {ProjectCallTelStatus} from 'api';
import {Tel} from 'components/Ui/Icon';

interface Props {
  allProjectsTelStatusCallReport: ProjectCallTelStatus;
}

export default React.memo(
  ({
    allProjectsTelStatusCallReport: {
      call = {},
      reception = {},
      contact = {},
      appoint = {},
      document = {},
      other = {},
      callNg = {},
      receptionNg = {},
      contactNg = {},
    } = {},
    allProjectsTelStatusCallReport = {},
  }: Props) => {
    const callCount = React.useMemo(() => {
      const totalCount = (object: any): any =>
        Object.values(object).reduce(
          (total: number, value: number) => total + value,
          0,
        );

      let count = 0;

      count += totalCount(call);
      count += totalCount(reception);
      count += totalCount(contact);
      count += totalCount(appoint);
      count += totalCount(document);
      count += totalCount(other);
      count += totalCount(callNg);
      count += totalCount(receptionNg);
      count += totalCount(contactNg);

      return count;
    }, [allProjectsTelStatusCallReport]);

    return (
      <Container>
        <div>
          <Tel />
        </div>
        <div>
          <span>コール件数</span>
          <span>
            <em>{callCount && callCount.toLocaleString()}</em>件
          </span>
        </div>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  width: 460px;
  height: 134px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  padding: 40px 36px;

  > div:nth-child(1) {
    margin-right: 25px;
    width: 54px;
    height: 54px;
    display: flex;
    background: #f8f5ff;
    border-radius: 6px;

    svg {
      margin: auto;
      path {
        stroke: #8966ef;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;

    > span:nth-child(1) {
      font-weight: bold;
      font-size: 14px;
      color: #899098;
    }

    > span:nth-child(2) {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #495058;

      em {
        margin-right: 6px;
        font-style: normal;
        font-size: 30px;
        line-height: 43px;
      }
    }
  }
`;
