import gql from 'graphql-tag';
import {
  callNgReasonFragment,
  contactNgReasonFragment,
  receptionNgReasonFragment,
  telStatusFragment,
} from '../../fragments';
import zoomPhoneWebhookCallFragment from '../zoomPhone/webhookCall';
import zoomPhoneWebhookEventRecordingFragment from '../zoomPhone/webhookEventRecording';

export default gql`
  fragment zoomPhoneWebhookCallActivityFragment on ZoomPhoneWebhookCallActivity {
    id
    body
    bodyPlainText
    telStatus
    telStatusText
    clientTelStatus {
      ...telStatusFragment
    }
    callNgReason {
      ...callNgReasonFragment
    }
    receptionNgReason {
      ...receptionNgReasonFragment
    }
    contactNgReason {
      ...contactNgReasonFragment
    }
    zoomPhoneWebhookCall {
      ...zoomPhoneWebhookCallFragment
      recordings {
        ...zoomPhoneWebhookEventRecordingFragment
      }
    }
  }
  ${zoomPhoneWebhookCallFragment}
  ${zoomPhoneWebhookEventRecordingFragment}
  ${telStatusFragment}
  ${callNgReasonFragment}
  ${receptionNgReasonFragment}
  ${contactNgReasonFragment}
`;
