import React from 'react';
import styled from 'styled-components';
import {Content, useUpdateContentMutation} from 'api';
import {Input} from 'components/antd';
import {useParams} from 'react-router';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {id} = useParams<{id: string}>();
  const [memo, setMemo] = React.useState(content.memo);
  const [updateContent] = useUpdateContentMutation();
  const updateMemo = React.useCallback(
    () => updateContent({variables: {id, attributes: {memo: memo}}}),
    [memo],
  );

  return (
    <Container>
      <h4>メモ</h4>
      <Input.TextArea
        name="memo"
        value={memo}
        onChange={(e) => setMemo(e.target.value)}
        onBlur={updateMemo}
      />
    </Container>
  );
};

const Container = styled.div`
  border-bottom: solid 1px #e1e6eb;
  display: flex;
  flex-direction: column;
  height: 100%;

  textarea {
    flex: 1;
  }
`;
