import {useCurrentClientQuery, useProjectWithClientQuery} from 'api';
import React from 'react';

const useProject = (uuid: string) => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  const {data: {project = {}} = {}} = useProjectWithClientQuery({
    variables: {uuid: uuid},
    skip: !uuid,
  });

  const isOwnProject = React.useMemo(
    () => currentClient?.id === project?.clientId,
    [currentClient, project],
  );

  const isProjectClientPreleadFixable = React.useMemo(
    () => project?.client?.currentPreleadContract?.fixable,
    [project],
  );

  return {
    isOwnProject,
    isProjectClientPreleadFixable,
  };
};

export default useProject;
