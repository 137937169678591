import React from 'react';

function Icon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4507 2.03833H5.54443V19.9568H16.4507V2.03833Z"
        fill="#71BABF"
      />
      <path
        d="M5.54443 17.2314V19.9614H16.4553V5.11536L5.54443 17.2314Z"
        fill="#4EA9AF"
      />
      <path
        d="M8.66221 3.98755H7.10547V5.54429H8.66221V3.98755Z"
        fill="#B8DCDF"
      />
      <path
        d="M11.776 3.98755H10.2192V5.54429H11.776V3.98755Z"
        fill="#B8DCDF"
      />
      <path
        d="M14.8937 3.98755H13.3369V5.54429H14.8937V3.98755Z"
        fill="#B8DCDF"
      />
      <path
        d="M8.66221 7.10559H7.10547V8.66233H8.66221V7.10559Z"
        fill="#B8DCDF"
      />
      <path
        d="M11.776 7.10559H10.2192V8.66233H11.776V7.10559Z"
        fill="#B8DCDF"
      />
      <path
        d="M14.8937 7.10559H13.3369V8.66233H14.8937V7.10559Z"
        fill="#B8DCDF"
      />
      <path
        d="M8.66221 10.2191H7.10547V11.7759H8.66221V10.2191Z"
        fill="#B8DCDF"
      />
      <path
        d="M11.776 10.2191H10.2192V11.7759H11.776V10.2191Z"
        fill="#B8DCDF"
      />
      <path
        d="M14.8937 10.2191H13.3369V11.7759H14.8937V10.2191Z"
        fill="#B8DCDF"
      />
      <path
        d="M8.66221 13.3373H7.10547V14.894H8.66221V13.3373Z"
        fill="#B8DCDF"
      />
      <path d="M11.776 13.3373H10.2192V14.894H11.776V13.3373Z" fill="#B8DCDF" />
      <path
        d="M14.8937 13.3373H13.3369V14.894H14.8937V13.3373Z"
        fill="#B8DCDF"
      />
      <path
        d="M13.3369 16.8433H8.66211V19.9613H13.3369V16.8433Z"
        fill="#B8DCDF"
      />
      <path
        d="M11.3925 16.8434H10.6118V19.9614H11.3925V16.8434Z"
        fill="#4EA9AF"
      />
    </svg>
  );
}

export default Icon;
