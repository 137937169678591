import React, {useState, Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  ProspectPool,
  useCreateHubspotDealMutation,
  useHubspotDealPipelinesQuery,
} from 'api';
import {Addition} from './Addition';
import useProspectContext from '../../useProspectContext'

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  prospectPool: ProspectPool;
}

const NewDealModal = ({isOpen, closeModal, prospectPool}: Props) => {
  const {poolId} = useProspectContext();

  const handleClose = () => {
    setAddition({});
    closeModal();
  };

  const [addition, setAddition] = useState({});
  const [createDeal, {loading}] = useCreateHubspotDealMutation({
    onCompleted: handleClose,
    refetchQueries: ['prospectPool', 'hubspotContactByProspectPool'],
  });
  const {data: {hubspotDealPipelines = []} = {}} = useHubspotDealPipelinesQuery(
    {
      variables: {poolId},
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      name: `${prospectPool.prospect?.account.name} - 新しい取引`,
      pipelineId: '',
      stageId: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      pipelineId: Yup.string().required('必須項目です'),
      stageId: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values, {resetForm}) =>
      createDeal({
        variables: {
          uuid: prospectPool.uuid,
          attributes: values,
          addition: addition,
        },
      }).then(() => {
        setAddition({});
        resetForm();
      }),
  });

  const stageOptions = React.useMemo(
    () =>
      hubspotDealPipelines
        .find((pipeline) => pipeline.hubspotId === formik.values.pipelineId)
        ?.stages.map((stage) => ({
          label: stage.label,
          value: stage.id,
        })),
    [formik.values.pipelineId, hubspotDealPipelines],
  );

  React.useEffect(() => {
    formik.setFieldValue('stageId', '');
  }, [formik.values.pipelineId]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-lg transform rounded bg-white text-left align-middle transition-all">
                <form onSubmit={formik.handleSubmit}>
                  <Dialog.Title
                    as="h4"
                    className="h-10 flex items-center px-4 bg-c-bg text-c-base rounded-t">
                    取引を作成
                  </Dialog.Title>
                  <div className="p-4 flex flex-col gap-4 mb-4">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block">
                        <span>取引名</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('name')}
                        />
                      </label>
                      <label className="block">
                        <span>パイプライン</span>
                        <select
                          {...formik.getFieldProps('pipelineId')}
                          className="form-select text-sm block w-full mt-1 rounded border-c-border shadow-sm">
                          <option value="">選択してください</option>
                          {hubspotDealPipelines.map((pipeline) => (
                            <option
                              key={pipeline.id}
                              value={pipeline.hubspotId}>
                              {pipeline.label}
                            </option>
                          ))}
                        </select>
                      </label>
                      <label className="block">
                        <span>取引ステージ</span>
                        <select
                          disabled={!formik.values.pipelineId}
                          {...formik.getFieldProps('stageId')}
                          className="form-select text-sm block w-full mt-1 rounded border-c-border shadow-sm">
                          <option value="">選択してください</option>
                          {stageOptions?.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </label>
                      <Addition addition={addition} setAddition={setAddition} />
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-4 gap-2 border-t border-c-border rounded-b">
                    <button
                      data-modal-toggle="defaultModal"
                      type="button"
                      onClick={handleClose}
                      className="hover:opacity-50 bg-white border border-c-border rounded-sm py-2 px-4 cursor-pointer">
                      キャンセル
                    </button>
                    <button
                      type="submit"
                      disabled={loading || !formik.isValid}
                      className="text-white bg-c-primary hover:opacity-50 rounded-sm py-2 px-4 cursor-pointer disabled:bg-c-bg disabled:text-c-light">
                      登録
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewDealModal;
