import React from 'react';
import {Transition} from '@headlessui/react';
import useMergeModalContext from '../useMergeModalContext';
import Finder from './Finder';

const Search = () => {
  const {pageType, setPageType, setMergeFrom, setMergeTo} =
    useMergeModalContext();

  const show = pageType?.startsWith('search/') || false;

  const onChange = (value: string) => {
    const target = pageType?.replace('search/', '') || '';
    if (target === 'from') {
      setMergeFrom(value);
    }
    if (target === 'to') {
      setMergeTo(value);
    }
    setPageType(null);
  };

  return (
    <Transition
      show={show}
      enter="transition-all duration-150"
      enterFrom="left-full"
      enterTo="left-0"
      leave="transition-all duration-150"
      leaveFrom="left-0"
      leaveTo="left-full"
      className="w-full h-full flex flex-col absolute top-0 bg-c-bg"
      as="div">
      <Finder onChange={onChange} />
    </Transition>
  );
};

export default Search;
