import React from 'react';

const Icon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="9.41177" fill="#F4F0FF" />
    <path
      d="M18 18C18 16.8954 18.8954 16 20 16H34C35.1046 16 36 16.8954 36 18V32C36 33.1046 35.1046 34 34 34H20C18.8954 34 18 33.1046 18 32V18Z"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 22H36"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 46C18 44.8954 18.8954 44 20 44H34C35.1046 44 36 44.8954 36 46V60C36 61.1046 35.1046 62 34 62H20C18.8954 62 18 61.1046 18 60V46Z"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 50H36"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 18C46 16.8954 46.8954 16 48 16H62C63.1046 16 64 16.8954 64 18V32C64 33.1046 63.1046 34 62 34H48C46.8954 34 46 33.1046 46 32V18Z"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 22H64"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 46C46 44.8954 46.8954 44 48 44H62C63.1046 44 64 44.8954 64 46V60C64 61.1046 63.1046 62 62 62H48C46.8954 62 46 61.1046 46 60V46Z"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 50H64"
      stroke="#8966EF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
