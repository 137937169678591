import React from 'react';
import {useSalesforceContactFieldQuery} from 'api';

interface Props {
  contactFieldId: string;
  value: string;
  onChange: (value: string) => void;
}

const SalesforceContactSelectOption = ({
  contactFieldId,
  value,
  onChange,
}: Props) => {
  const {
    data: {
      salesforceContactField: {salesforceContactFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceContactFieldQuery({
    variables: {contactFieldId},
    skip: !contactFieldId,
  });

  return (
    <select
      className="form-select border-c-border rounded text-base flex-1 h-[44px]"
      onChange={(e) => onChange(e.target.value)}
      value={value}>
      <option value="" />
      {salesforceContactFieldSelectOptions.map((option) => (
        <option value={option.id} key={option.id}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SalesforceContactSelectOption;
