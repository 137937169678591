import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import {useCollectedFormUrlFieldsQuery, CollectedFormUrl} from 'api';

interface Props {
  onClick: (variable: string) => void;
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl, onClick}: Props) => {
  const {data: {collectedFormUrlFields = []} = {}} =
    useCollectedFormUrlFieldsQuery({
      variables: {uuid: collectedFormUrl.uuid},
    });

  if (collectedFormUrlFields.length === 0) return null;

  return (
    <Menu as="div" className="absolute bottom-2 right-2 inline-block text-left">
      <Menu.Button className="text-c-primary border border-c-border rounded bg-white cursor-pointer w-8 flex items-center justify-center">
        {'{ }'}
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="w-48 absolute right-0 bottom-7 divide-y rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {collectedFormUrlFields.map((field) => (
            <Menu.Item key={field.id}>
              <button
                onClick={() => onClick(`{{${field.name}}}`)}
                className="border-none w-full py-1 px-4 text-sm cursor-pointer bg-white text-left flex items-center gap-2 hover:bg-[#F1F8FF]">
                <span className="text-c-light">{field.name}</span>
                <span>{field.label}</span>
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
