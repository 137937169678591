import gql from 'graphql-tag';

export default gql`
  fragment approachFragment on Approach {
    id
    uuid
    status
    statusText
    priority
    priorityText
    startDate
    dueDate
    dueDay
    userId
    approachType
    approachTypeText
    approachableId
    approachableType
  }
`;
