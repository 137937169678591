import gql from 'graphql-tag';

export default gql`
  fragment companySnsFragment on Company {
    facebookUrls
    instagramUrls
    youtubeUrls
    twitterUrls
    lineUrls
    linkedinUrls
  }
`;
