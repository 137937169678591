import React from 'react';
import {Switch, Route} from 'react-router';
import Contents from './Contents';
import Content from './Content';
import MailForm from './mail_form';
import Document from './document';
import Website from './website';
import MailTemplate from './mail_template';
import TelScript from './tel_script';
import Audio from './audio';

export default () => (
  <Switch>
    <Route path="/garage/contents/:id/mail_form" component={MailForm} />
    <Route path="/garage/contents/:id/document" component={Document} />
    <Route path="/garage/contents/:id/website" component={Website} />
    <Route path="/garage/contents/:id/mail_template" component={MailTemplate} />
    <Route path="/garage/contents/:id/tel_script" component={TelScript} />
    <Route path="/garage/contents/:id/audio" component={Audio} />
    <Route path="/garage/contents/new" component={Contents} />
    <Route path="/garage/contents/:id" component={Content} />
    <Route component={Contents} />
  </Switch>
);
