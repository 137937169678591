import React from 'react';
import styled from 'styled-components';
import MailInfo from './MailInfo';
import NextAction from './NextAction';
import StatusInfo from './StatusInfo';
import TelInfo from './TelInfo';

export default () => {
  return (
    <Container>
      <NextAction />
      <StatusInfo />
      <MailInfo />
      <TelInfo />
    </Container>
  );
};

const Container = styled.div``;
