import {atom} from 'recoil';

export default atom<{text: string; value: string}[]>({
  key: 'preleadMasterFilterOptionsAtom',
  default: [
    // {text: 'グループ', value: 'projectGroupIds'},
    {text: '営業リスト', value: 'projectIds'},
    // {text: 'タグ', value: 'tagIds'}, // プレリードのタグをプールごとに管理するようにしたため、一旦非表示にする。
    {text: '最終リマインド', value: 'remind'},
    {text: '最終企業担当者', value: 'assigneeIds'},
    {text: 'コメント回数', value: 'commentCounts'},
    {text: 'メールキャンペーン名', value: 'mailCampaignIds'},
    {text: 'メールキャンペーン回数', value: 'mailCampaignSendsCounts'},
    {text: 'メールキャンペーン開封回数', value: 'mailCampaignOpenCounts'},
    {text: 'メールキャンペーン開封日時', value: 'mailCampaignOpenedAt'},
    {text: 'メールキャンペーンクリック回数', value: 'mailCampaignClickCounts'},
    {text: 'メールキャンペーンクリック日時', value: 'mailCampaignClickedAt'},
    {text: 'プレリードステータス', value: 'preleadStatusIds'},
    {text: 'フォーム送信ステータス', value: 'mailStatuses'},
    {text: 'フォーム送信数', value: 'mailCounts'},
    {text: 'フォーム送信期間', value: 'mailTerm'},
    {text: 'TELステータス', value: 'telStatusIds'},
    {text: 'TEL更新ユーザー', value: 'telUserIds'},
    {text: 'TEL件数', value: 'telCounts'},
    {text: 'TEL更新日', value: 'telTerm'},
    {text: 'フォーム送信クリック回数', value: 'clickCounts'},
    {text: 'フォーム送信クリック日時', value: 'clickTerm'},
    {text: 'プレスリリース公開', value: 'pressReleaseSince'},
    {text: 'ToDo最新更新日付', value: 'taskDueDate'},
    {text: 'ToDo最新更新担当者', value: 'taskUserIds'},
    {text: '都道府県', value: 'prefectureIds'},
    {text: '従業員数', value: 'employeeNumber'},
    {text: '上場区分', value: 'listingMarkets'},
    {text: '売上', value: 'sales'},
    {text: '資本金', value: 'capitalFund'},
    {text: '事業所数', value: 'officesNumber'},
    {text: '決算月', value: 'accountClosingMonths'},
    {text: '設立年月', value: 'establishedYearMonth'},
    {text: '業種', value: 'categoryIds'},
    {text: '最新Webサイト訪問期間', value: 'trackedAt'},
  ],
});
