import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {useMainCategoriesQuery, MainCategory, SubCategory} from 'api';
import {Check} from 'components/Ui/Icon';
import {HashLink} from 'react-router-hash-link';
import useSearchCondition from '../useSearchCondition';
import BASE_CLASSIFICATIONS from 'helpers/classifications';
import useProjectPath from 'hooks/useProjectPath';

interface Props {
  activeMainCategory: string;
}

const SecondMenu = ({activeMainCategory}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {query} = useSearchCondition();
  const {createPath} = useProjectPath();

  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery({});

  const classifications = useMemo(() => {
    return BASE_CLASSIFICATIONS(mainCategories);
  }, [mainCategories]);

  const isSelected = React.useCallback(
    (classification: any) => {
      const subCategories = classification?.categories
        ?.map((mainCategory: MainCategory) => mainCategory.subCategories)
        .flat();
      const subCategoryIds = subCategories.map(
        (subCategory: SubCategory) => subCategory.id,
      );
      for (const subCategoryId of subCategoryIds) {
        if (query.subCategoryIds?.includes(subCategoryId)) return true;
      }

      return false;
    },
    [query],
  );

  return (
    <Container>
      <MenuGroup>
        {classifications.map((classification, i) => (
          <Menu
            key={`classification-${i}`}
            to={createPath(
              `projects/${projectId}/search/category${location.search}#classification-${i}`,
            )}
            current={`classification-${i}` === activeMainCategory ? 1 : 0}>
            {classification.name}
            {isSelected(classification) && (
              <SelectLabel>
                <Check />
              </SelectLabel>
            )}
          </Menu>
        ))}
      </MenuGroup>
    </Container>
  );
};

export {SecondMenu};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid #e2e6ea;

  a {
    text-align: left;
    width: 100%;
    height: 50px;
    background: transparent;
    font-size: 12px;
    color: #bdc4cb;
    box-shadow: none;
  }
`;

const MenuGroup = styled.div`
  overflow-y: scroll;
`;

const Menu = styled(HashLink)<{current?: number}>`
  &&& {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 25px;
    height: 50px;
    font-size: 14px;
    color: #222426;
    white-space: pre;
    background: ${(props) => (props.current ? '#E2E6EA;' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }
  }
`;

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
  height: 20px;
`;
