import Check from './Check';
import ProjectCount from './ProjectCount';
import Mail from './Mail';
import MailCount from './MailCount';
import Tel from './Tel';
import TelCount from './TelCount';
import Status from './Status';
import Nayose from './Nayose';
import Comment from './Comment';
import Name from './Name';
import Alert from './Alert';
import Click from './Click';
import ClickUpdatedAt from './ClickUpdatedAt';
import MainCategory from './MainCategory';
import SubCategory from './SubCategory';
import Contact from './Contact';
import Tag from './Tag';
import ProjectGroup from './projectGroup';
import UserName from './User';
import Remind from './Remind';
import Prefecture from './Prefecture';
import CustomizeItemText from './CustomizeItemText';
import CustomizeItemNumber from './CustomizeItemNumber';
import CustomizeItemDate from './CustomizeItemDate';
import CustomizeItemSelect from './CustomizeItemSelect';
import TelUser from './TelUser';
import TelUpdatedAt from './TelUpdatedAt';

export default {
  Check,
  ProjectCount,
  Mail,
  MailCount,
  Tel,
  TelCount,
  Status,
  Nayose,
  Comment,
  Name,
  Alert,
  Click,
  ClickUpdatedAt,
  MainCategory,
  SubCategory,
  Contact,
  Tag,
  ProjectGroup,
  UserName,
  Remind,
  Prefecture,
  CustomizeItemText,
  CustomizeItemNumber,
  CustomizeItemDate,
  CustomizeItemSelect,
  TelUser,
  TelUpdatedAt,
};
