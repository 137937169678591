import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, ManualMailBounceProspectActivity} from 'api';
import {ManualMail} from 'components/Ui/Icon';

interface ManualMailBounceActivity extends ProspectActivity {
  resource: ManualMailBounceProspectActivity;
}

interface Props {
  prospectActivity: ManualMailBounceActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      manualMail: {action},
    },
  } = prospectActivity;

  return (
    <>
      <ManualMail active={true} width="32" height="32" />
      <Content>
        <ContentHeader>
          <h3>{action?.step?.name}</h3>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </ContentHeader>
        <Wrapper>バウンス</Wrapper>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: #68b5fb;
    font-weight: bold;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const Wrapper = styled.span`
  color: #899098;
`;
