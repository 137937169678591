const taskPriorities = [
  {name: '高', value: 'high', color: '#eb5757'},
  {name: '中', value: 'normal', color: '#ffc600'},
  {name: '低', value: 'low', color: '#56a948'},
];

const taskStatuses = [
  {name: '未実施', value: 'not_started'},
  {name: '開始', value: 'in_progress'},
  {name: '完了', value: 'completed'},
  {name: '期限切れ', value: 'expired'},
  {name: '保留', value: 'icebox'},
];
const defaultStatuses = [
  'not_started',
  'in_progress',
  'expired',
  'icebox',
];

export {taskPriorities, taskStatuses, defaultStatuses};
