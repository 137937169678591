import React from 'react';
import {useProspectPoolsByPreleadQuery, ProspectPool} from 'api';
import {ProspectPoolNameComponent as Name} from './Item/ProspectPoolName';
import {LastActionComponent as LastAction} from './Item/LastAction';
import {ProspectPoolLeadSourceComponent as LeadSource} from './Item/ProspectPoolLeadSource';
import {ProspectPoolTagComponent as Tag} from './Item/Tag';
import CreateProspectModal from './CreateProspectModal';
import usePreleadContext from '../usePreleadContext'

export default () => {
  const {preleadId} = usePreleadContext()
  const [isOpen, setIsOpen] = React.useState(false);
  if (!preleadId) return null;

  const {data: {prospectPoolsByPrelead = []} = {}} =
    useProspectPoolsByPreleadQuery({
      variables: {uuid: preleadId},
      skip: !preleadId,
      fetchPolicy: 'cache-and-network',
    });

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-end w-full flex-row pt-3.5 pb-[18px] border-b border-[#E1E6EB]">
        <button
          className="rounded bg-[#68B5FB] text-white font-medium text-sm h-8 px-4 cursor-pointer"
          onClick={() => setIsOpen(true)}>
          リードを登録
        </button>
      </div>
      {prospectPoolsByPrelead.map((prospectPool: ProspectPool, i) => (
        <div key={i} className="border-b border-[#E1E6EB] py-5">
          <Name key={i} prospectPool={prospectPool} />
          <LeadSource key={i} prospectPool={prospectPool} />
          <LastAction key={i} prospectPool={prospectPool} />
          <Tag key={i} prospectPool={prospectPool} />
        </div>
      ))}
      <CreateProspectModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
    </div>
  );
};
