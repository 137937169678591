import React from 'react';
import styled from 'styled-components';
import {Input, Modal} from 'components/antd';
import {WorkflowTemplateAutomaticMail} from 'api';
import {Quill as ReactQuill} from 'components/Ui';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  automaticMail: WorkflowTemplateAutomaticMail;
}

export default ({visible, setVisible, automaticMail}: Props) => {
  if (!automaticMail) return null;

  return (
    <AutomaticMailModal
      title={'メール詳細'}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={600}
      style={{top: '20px', height: '95vh', overflow: 'hidden'}}>
      <Field>
        <label>件名</label>
        <Input
          id="subject"
          name="subject"
          bordered={false}
          value={automaticMail.subject}
          readOnly
        />
      </Field>
      <Body>
        <BodyHeader>
          <label>本文</label>
        </BodyHeader>
        <ReactQuill
          theme="snow"
          value={automaticMail.bodyHtml}
          bounds={'#quill-container'}
          readOnly
        />
      </Body>
    </AutomaticMailModal>
  );
};

const Field = styled.div`
  min-height: 45px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e1e6eb;

  label {
    width: 50px;
  }

  > *:nth-child(2) {
    flex: 1;
  }

  &:first-child {
    > div {
      cursor: pointer;
      font-weight: 700;
    }
    > div:last-child {
      margin-left: 10px;
    }
  }
`;

const Body = styled.div`
  padding-bottom: 5px;
  border-bottom: solid 1px #e1e6eb;
  position: relative;
  margin-bottom: 41px;

  textarea {
    padding: 0;
    border: none;
  }

  .ql-container.ql-snow {
    min-height: 200px;
    border: none;

    .ql-editor {
      overflow-y: scroll;
      resize: vertical;
      padding: 0;
      height: 70vh;

      &::before {
        left: 0;
      }
    }
  }
  .ql-toolbar {
    display: none;
  }
`;

const BodyHeader = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AutomaticMailModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 42px;
  }
`;
