import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import icon from './avatar.svg';

export default () => {
  return <StyoedIcon />;
};

const StyoedIcon = styled(icon)``;
