import React from 'react';
import {useSalesforceDealFieldQuery} from 'api';

interface Props {
  fieldId: string;
  value: string;
  onChange: (value: string) => void;
}

const SalesforceDealSelectOption = ({fieldId, value, onChange}: Props) => {
  const {
    data: {
      salesforceDealField: {salesforceDealFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceDealFieldQuery({
    variables: {dealFieldId: fieldId},
    skip: !fieldId,
  });

  return (
    <select
      className="form-select border-c-border rounded text-base flex-1 h-[44px]"
      onChange={(e) => onChange(e.target.value)}
      value={value}>
      <option value="" />
      {salesforceDealFieldSelectOptions.map((option) => (
        <option value={option.id} key={option.id}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SalesforceDealSelectOption;
