import gql from 'graphql-tag';

export default gql`
  fragment sendgridMailSendFragment on SendgridMailSend {
    id
    isBounced
    isClicked
    isOpened
    sendableType
    sendableId
    xMessageId
  }
`;
