import React, {useState} from 'react';
import styled from 'styled-components';
import {Mail} from 'components/Ui/Icon';
import {useParams} from 'react-router';
import {useProjectSummaryQuery} from 'api';
import {StatusEclipse} from 'components/Ui';
import mailColor from 'helpers/mailColor';
import ArrowDown from 'components/Icon/ArrowDown';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = null} = {}} = useProjectSummaryQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'cache-and-network',
  });

  const [openMailStatusDropDown, setOpenMailStatusDropDown] = useState(false);

  return (
    <Container>
      <TitleWrapper>
        <Title>
          <Mail />
          総フォーム送信件数
          <span>{project?.mailCount.toLocaleString()}</span>
        </Title>
      </TitleWrapper>

      <Body>
        <Item>
          <Name>
            <StatusEclipse color={mailColor('unsent')} />
            未送信
          </Name>
          <Value>{project?.mailStatusUnsentCount.toLocaleString()}</Value>
        </Item>
        <Item>
          <Name>
            <StatusEclipse color={mailColor('mail')} />
            メール送信済み
          </Name>
          <Value>{project?.mailStatusMailCount.toLocaleString()}</Value>
        </Item>

        <Item>
          <Name>
            <StatusEclipse color={mailColor('sent')} />
            フォーム送信済み
          </Name>
          <Value>{project?.mailStatusSentCount.toLocaleString()}</Value>
        </Item>

        <Item>
          <NameAccordion
            onClick={() => setOpenMailStatusDropDown(!openMailStatusDropDown)}
            isOpen={openMailStatusDropDown}>
            <StatusEclipse color={mailColor('cannotsend')} />
            エラー
            <ArrowDown />
          </NameAccordion>
          <Value>
            {(
              project?.mailStatusNotfoundCount +
              project?.mailStatusCharoverCount +
              project?.mailStatusInvalidCount +
              project?.mailStatusShortageCount +
              project?.mailStatusRecruitCount +
              project?.mailStatusCannotacceptCount
            ).toLocaleString()}
          </Value>
        </Item>

        <MailStatusDropDown isOpen={openMailStatusDropDown}>
          <DropDownItem>
            <Name>見つからない</Name>
            <Value>{project?.mailStatusNotfoundCount.toLocaleString()}</Value>
          </DropDownItem>
          <DropDownItem>
            <Name>文字数オーバー</Name>
            <Value>{project?.mailStatusCharoverCount.toLocaleString()}</Value>
          </DropDownItem>
          <DropDownItem>
            <Name>項目エラー</Name>
            <Value>{project?.mailStatusInvalidCount.toLocaleString()}</Value>
          </DropDownItem>
          <DropDownItem>
            <Name>項目不足</Name>
            <Value>{project?.mailStatusShortageCount.toLocaleString()}</Value>
          </DropDownItem>
          <DropDownItem>
            <Name>不適切</Name>
            <Value>
              {(
                project?.mailStatusRecruitCount +
                project?.mailStatusCannotacceptCount
              ).toLocaleString()}
            </Value>
          </DropDownItem>
        </MailStatusDropDown>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const TitleWrapper = styled.div`
  border-bottom: 1px solid #e2e6ea;
`;

const Title = styled.span`
  width: 378px;
  font-weight: bold;
  font-size: 12px;
  color: #bdc4cb;
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 5px;
  svg {
    margin-right: 6px;
    width: 15px;
    height: 15px;
  }
  span {
    margin-left: auto;
  }
`;

const Body = styled.div`
  width: 378px;
`;

const Item = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 15px;
  }
`;

const Name = styled.span`
  margin-right: 15px;
  width: 165px;
  font-weight: bold;
  font-size: 14px;
  color: #bdc4cb;
  padding: 0 4px;
`;

const NameAccordion = styled(Name)<{isOpen: boolean}>`
  &:hover {
    border-radius: 5px;
    background-color: #e8eaea;
  }
  svg:last-child {
    width: 10px;
    height: 6px;
    margin-left: 6px;
    margin-bottom: 2px;
    transform: ${({isOpen}) =>
      isOpen ? 'rotateX(180deg)' : 'rotateX(360deg)'};

    path {
      fill: #bdc4cb;
    }
  }
`;

const Value = styled.span`
  margin-left: auto;
  text-align: right;
`;

const MailStatusDropDown = styled.div<{isOpen: boolean}>`
  margin-left: 25px;
  display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
`;

const DropDownItem = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;

  span:last-child {
    margin-left: auto;
  }
`;
