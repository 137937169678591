import React from 'react';

export default () => (
  <svg
    width="38"
    height="36"
    viewBox="0 0 38 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 7.5H28M18 12.5H28M24.25 17.5H28M15.5 27.5H30.5C31.8807 27.5 33 26.3808 33 25V5C33 3.61929 31.8807 2.5 30.5 2.5H15.5C14.1193 2.5 13 3.61929 13 5V25C13 26.3808 14.1193 27.5 15.5 27.5Z"
      stroke="#8DD888"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.72622 5.41732C4.71117 5.41678 4.6961 5.4165 4.68103 5.4165C4.06833 5.4165 3.4782 5.5599 2.96194 5.88757C2.44546 6.21538 2.0791 6.67929 1.83724 7.19837C1.37308 8.19456 1.33793 9.44703 1.51549 10.7119C1.87621 13.2815 3.21471 16.5868 5.28604 19.8776C9.42077 26.4467 16.8035 33.4579 26.2795 34.5597L26.2795 34.5598C28.4606 34.8132 30.1251 33.0565 30.2737 31.0781L30.2737 31.078L30.5719 27.1002L30.5719 27.1002C30.7132 25.2151 29.5086 23.4641 27.6652 22.9913L27.6651 22.9913L23.0189 21.7998L23.0187 21.7998C22.0164 21.5429 20.9569 21.7057 20.0759 22.248C20.0759 22.248 20.0758 22.248 20.0758 22.248L18.4486 23.2494C16.1112 21.4771 13.9147 18.9139 12.7278 17.343L14.0971 15.2362C14.0971 15.2362 14.0972 15.2362 14.0972 15.2361C14.8042 14.1484 14.9336 12.7767 14.4475 11.5742C14.4475 11.5742 14.4475 11.5742 14.4475 11.5742L13.0128 8.02498L11.8539 8.49344L13.0128 8.02498C12.4416 6.61194 11.107 5.64822 9.5784 5.59291C9.5784 5.59291 9.57839 5.59291 9.57839 5.59291L4.72622 5.41732ZM29.0272 30.9845C28.9224 32.3804 27.78 33.4757 26.4238 33.3181L29.3254 27.0068L29.0272 30.9845Z"
      fill="#8DD888"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
