import React from 'react';

function Icon() {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M6.00117 3.70352C5.40117 3.70352 4.95117 3.21602 4.95117 2.65352C4.95117 2.09102 5.43867 1.60352 6.00117 1.60352C6.56367 1.60352 7.05117 2.09102 7.05117 2.65352C7.05117 3.21602 6.60117 3.70352 6.00117 3.70352Z"
          fill="#8966EF"
          stroke="#8966EF"
          strokeMiterlimit="10"
        />
        <path
          d="M1.9875 6.44082C1.3875 6.44082 0.9375 5.95332 0.9375 5.39082C0.9375 4.82832 1.425 4.34082 1.9875 4.34082C2.55 4.34082 3.0375 4.82832 3.0375 5.39082C3.0375 5.95332 2.5875 6.44082 1.9875 6.44082Z"
          fill="#8966EF"
          stroke="#8966EF"
          strokeMiterlimit="10"
        />
        <path
          d="M6.00117 9.10391C5.40117 9.10391 4.95117 8.61641 4.95117 8.05391C4.95117 7.49141 5.43867 7.00391 6.00117 7.00391C6.56367 7.00391 7.05117 7.49141 7.05117 8.05391C7.05117 8.61641 6.60117 9.10391 6.00117 9.10391Z"
          fill="#8966EF"
          stroke="#8966EF"
          strokeMiterlimit="10"
        />
        <path
          d="M2.88867 4.82832L5.36367 3.21582"
          stroke="#8966EF"
          strokeMiterlimit="10"
        />
        <path
          d="M2.77539 5.87891L5.17539 7.45391"
          stroke="#8966EF"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="9"
            height="9"
            fill="white"
            transform="translate(0 0.853516)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
