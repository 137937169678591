import React from 'react';
import {useRecoilState} from 'recoil';
import checkedPreleadProjectsAtom from '../checkedPreleadProjectsAtom';
import {Block} from 'components/Ui/Icon';
import styled from 'styled-components';
import {useBlockPreleadProjectsMutation} from 'api';

export default () => {
  const [checkedPreleadProjects, setCheckedPreleadProjects] = useRecoilState(
    checkedPreleadProjectsAtom,
  );

  const [blockPreleadProjects, {loading}] = useBlockPreleadProjectsMutation({
    refetchQueries: ['projects', 'currentUser'],
    onCompleted: () => {
      setCheckedPreleadProjects([]);
    },
  });

  const handleClick = () => {
    if (!loading && checkedPreleadProjects.length > 0) {
      blockPreleadProjects({
        variables: {
          uuids: checkedPreleadProjects,
        },
      });
    }
  };

  return checkedPreleadProjects.length > 0 ? (
    <Action onClick={handleClick}>
      <Block color="#f46868" />
      <span>ブロック</span>
    </Action>
  ) : null;
};

const Action = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #f46868;

  svg {
    margin-right: 5px;
    position: relative;
    top: -1px;
  }

  span {
    font-size: 12px;
  }
`;
