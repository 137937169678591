import React from 'react';

function Icon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 1.5L8.68747 9.31253M16.5 1.5L1.5 6.5L8.68747 9.31253L16.5 1.5ZM16.5 1.5L11.5 16.5L8.68747 9.31253L16.5 1.5Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
