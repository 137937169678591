import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useCondition from '../../../useCondition';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {usePoolQuery} from 'api';
import {getTagFontColor} from 'helpers/tagColors';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  const {data: {pool: {prospectTags = []} = {}} = {}} = usePoolQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (!condition?.excludedTags || !condition.excludedTags.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">タグ（含まない）</h4>
        <button
          onClick={() => setFilterType('excludedTag')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setCondition({excludedTags: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {prospectTags
          .filter((tag) => condition.excludedTags.includes(tag.id))
          .map((tag) => (
            <span
              color={tag.color}
              key={tag.id}
              className="flex px-[7px] py-[3px] rounded-sm"
              style={{background: tag.color}}>
              <span
                className="text-sm font-bold leading-4 tracking-[0.15px]"
                style={{color: getTagFontColor(tag.color)}}>
                {tag.name}
              </span>
            </span>
          ))}
      </div>
    </div>
  );
};
