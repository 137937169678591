import React from 'react';
import moment from 'moment';
import {useTable, Cell} from 'react-table';
import {ClientDownload, useClientDownloadsQuery} from 'api';
// @ts-ignore
import useSearchParams from './useSearchParams';
import {ArrowDownTrayIcon, ArrowPathIcon} from '@heroicons/react/20/solid';
import Pagination from '../form/forms/formId/posts/Pagination';
import {saveAs} from 'file-saver';

const statusColors: {[key: string]: string} = {
  pending: 'bg-c-bg text-c-light',
  processing: 'bg-[#ECF6FF] text-[#68B5FB]',
  completed: 'bg-[#E4F6EB] text-[#4FCE82]',
  failed: 'bg-c-bg text-c-light',
};

export default () => {
  const {page, setPage} = useSearchParams();

  const {
    data: {clientDownloads: {clientDownloads, pagination = {}} = {}} = {},
    refetch,
    loading,
  } = useClientDownloadsQuery({
    variables: {
      page: page,
    },
    fetchPolicy: 'cache-and-network',
  });

  const downloadableType = (downloadableType: string) => {
    if (downloadableType === 'Client::DownloadProspect') {
      return 'リード';
    } else if (downloadableType === 'Client::DownloadProject') {
      return '営業リスト';
    } else if (downloadableType === 'Client::DownloadMailCampaign') {
      return 'メール配信一覧';
    } else if (
      downloadableType === 'Client::DownloadMailCampaignProspectResult'
    ) {
      return 'メール配信送信結果';
    }
  };

  const columns: any = React.useMemo(
    () => [
      {
        id: 'status',
        Header: 'ステータス',
        Cell: ({row}: Cell<ClientDownload>) => (
          <span
            className={`flex items-center justify-center h-6 w-24 rounded-sm text-xs ${
              statusColors[row.original.status]
            }`}>
            {row.original.statusText}
          </span>
        ),
        style: {width: '120px'},
      },
      {
        id: 'filename',
        Header: 'ファイル名',
        accessor: (row: ClientDownload) => row.downloadable.filename,
        style: {width: '300px'},
      },
      {
        id: 'downloadableType',
        Header: '種別',
        accessor: (row: ClientDownload) =>
          downloadableType(row.downloadableType),
        style: {width: '120px'},
      },
      {
        id: 'count',
        Header: '件数',
        accessor: (row: ClientDownload) =>
          row.status === 'completed' ? row.downloadable.count : '-',
        style: {width: '80px'},
      },
      {
        id: 'user',
        Header: '実行ユーザー',
        accessor: (row: ClientDownload) =>
          `${row.user.lastName} ${row.user.firstName}`,
        style: {width: '180px'},
      },
      {
        id: 'date',
        Header: '実行日時',
        accessor: (row: ClientDownload) =>
          moment(row.createdAt).format('YYYY/MM/DD HH:mm'),
        style: {width: '120px'},
      },
      {
        id: 'download',
        Header: '',
        Cell: ({row}: Cell<ClientDownload>) => (
          <div className="flex justify-center items-center">
            {row.original.status === 'completed' && (
              <button
                className="cursor-pointer bg-transparent border-none outline-none"
                onClick={() =>
                  fetch(String(row.original.downloadable.file))
                    .then((res) => res.blob())
                    .then((blob) =>
                      saveAs(blob, row.original.downloadable.filename),
                    )
                }>
                <ArrowDownTrayIcon className="w-5 h-5 text-c-primary" />
              </button>
            )}
            {row.original.status !== 'completed' && (
              <ArrowDownTrayIcon className="w-5 h-5 text-gray-200" />
            )}
          </div>
        ),
        style: {width: '80px'},
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<ClientDownload>({columns, data: clientDownloads || []});

  return (
    <div>
      <button
        title="更新"
        onClick={() => refetch()}
        disabled={loading}
        className="flex items-center justify-center bg-transparent cursor-pointer mb-4">
        <ArrowPathIcon
          className={`w-5 h-5 text-c-light ${loading ? 'animate-spin' : ''}`}
        />
      </button>
      <table
        {...getTableProps()}
        className="table-fixed text-sm w-full border-separate mb-4">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    className="py-2 pr-4 text-left border-b border-c-border"
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-2 pr-4 text-left border-b border-c-border"
                      key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        pageSize={20}
        current={pagination.currentPage || 1}
        total={pagination.totalCount || 0}
        onChange={(page) => setPage(page)}
      />
    </div>
  );
};
