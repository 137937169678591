import styled from 'styled-components';
import {Radio} from 'components/antd';

export default styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #68b4fb;

    &:after {
      background-color: #68b4fb;
    }
  }
`;
