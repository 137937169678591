import {JobListingPage} from 'api';

const MAX_JOB_INFORMATION_COUNT = 5;

const useJobListing = (pages: JobListingPage[]) => {
  const aggregatedJobListingsByMedia = pages?.reduce((aggregatedJobMedia, current) => {
    const media = aggregatedJobMedia.find(
      (m) => m.websiteId === current.websiteId,
    );

    if (media) {
      if (media && media.jobInformations.length < MAX_JOB_INFORMATION_COUNT) {
        media.jobInformations = [...media.jobInformations, current];
      }
    } else {
      aggregatedJobMedia.push({
        id: `job-${current?.websiteId}`,
        websiteId: current.websiteId,
        name: current.websiteName,
        jobInformations: [current],
      });
    }
    return aggregatedJobMedia;
  }, []);

  return {
    aggregatedJobListingsByMedia,
  };
};

export default useJobListing;
