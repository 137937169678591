import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Input, Select, Button} from 'components/antd';
import Modal from 'react-modal';
import {Cross} from 'components/Ui/Icon';
import useProjectGroups from 'hooks/useProjectGroups';
import {Quill} from 'components/Ui';
import {
  useCreateProjectMutation,
  useFixProjectMutation,
  Project,
  useFixProjectByWebsitePageMutation,
  useFixProjectByTechnologyMutation,
} from 'api';
import {useHistory} from 'react-router-dom';
import useProjectPath from 'hooks/useProjectPath';

type Props = {
  isNewProjectModalOpen: boolean;
  setIsNewProjectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  project?: Project;
  searchParams?: {[key: string]: any};
  searchType?: string;
};

export default ({
  isNewProjectModalOpen,
  setIsNewProjectModalOpen,
  project,
  searchParams,
  searchType,
}: Props) => {
  const {accessibleProjectGroups, selectedProjectGroup = {}} =
    useProjectGroups();

  const [projectGroupId, setProjectGroupId] = useState(
    project?.projectGroupId || selectedProjectGroup.id,
  );
  const [projectName, setProjectName] = useState(project?.name);
  const [memo, setMemo] = useState(project?.memo);
  const [memoHtml, setMemoHtml] = useState(project?.memoHtml);

  const history = useHistory();
  const {createPath} = useProjectPath();

  const [createProject, {loading}] = useCreateProjectMutation({
    variables: {
      attributes: {
        projectGroupId,
        name: projectName,
        memo,
        memoHtml,
        status: 'open',
      },
    },
    onCompleted: ({createProject: {project}}) => {
      history.push(`/list/projects/${project.uuid}/preleads`);
    },
  });

  const [fixProject] = useFixProjectMutation({
    variables: {
      uuid: project?.uuid,
      search: searchParams,
      attributes: {
        projectGroupId,
        name: projectName,
        memo,
        memoHtml,
      },
    },
    onCompleted: () => {
      history.push(
        createPath(`projects/${project.uuid}/search_condition?status=open`),
      );
    },
  });

  const [fixProjectByWebsitePage] = useFixProjectByWebsitePageMutation({
    variables: {
      uuid: project?.uuid,
      search: searchParams,
      attributes: {
        projectGroupId,
        name: projectName,
        memo,
        memoHtml,
      },
    },
    onCompleted: () =>
      history.push(
        createPath(`projects/${project.uuid}/search_condition?status=open`),
      ),
  });

  const [fixProjectByTechnology] = useFixProjectByTechnologyMutation({
    variables: {
      uuid: project?.uuid,
      search: searchParams,
      attributes: {
        projectGroupId,
        name: projectName,
        memo,
        memoHtml,
      },
    },
    onCompleted: () =>
      history.push(
        createPath(`projects/${project.uuid}/search_condition?status=open`),
      ),
  });

  const onClose = () => {
    setIsNewProjectModalOpen(false);
  };

  useEffect(() => {
    setProjectGroupId(project?.projectGroupId || selectedProjectGroup.id);
    if (!project) {
      setProjectName('');
      setMemo('');
      setMemoHtml('');
    }
  }, [project, selectedProjectGroup]);

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 3,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '864px',
      maxHeight: '600px',
      padding: '0',
      borderRadius: '10px',
    },
  };

  return (
    <Modal
      isOpen={isNewProjectModalOpen}
      onRequestClose={() => onClose()}
      style={customStyles}
      contentLabel="Example Modal">
      <Header>
        <div>営業リストを新規作成</div>
        <CrossIconWrapper onClick={() => onClose()}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <Main>
        {location.pathname.startsWith(`/list/projects`) && (
          <Item>
            <div>
              グループ<span>*</span>
            </div>
            <Select
              size="large"
              placeholder="グループ未設定"
              style={{width: '100%'}}
              showSearch
              optionFilterProp="children"
              value={projectGroupId}
              onChange={(id: string) => setProjectGroupId(id)}>
              {accessibleProjectGroups.map((projectGroup) => (
                <Select.Option key={projectGroup.id} value={projectGroup.id}>
                  {projectGroup.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        )}

        <Item>
          <div>
            リスト名<span>*</span>
          </div>
          <Input
            size="large"
            placeholder="新規リスト名"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </Item>

        <Item>
          <div>メモ</div>

          <Quill
            theme="snow"
            value={memoHtml}
            onChange={(content, delta, source, editor) => {
              setMemoHtml(content);
              setMemo(editor.getText());
            }}
            modules={{
              toolbar: [
                [
                  {header: [1, 2, 3, false]},
                  'bold',
                  'italic',
                  'underline',
                  'code-block',
                  'link',
                ],
              ],
            }}
            bounds={'#quill-container'}
          />
        </Item>
      </Main>

      <Footer>
        <div>
          <Button
            disabled={!projectGroupId || !projectName || loading}
            type="primary"
            onClick={() => {
              if (project) {
                if (searchType === 'website_page') {
                  fixProjectByWebsitePage();
                } else if (searchType === 'technology') {
                  fixProjectByTechnology();
                } else {
                  fixProject();
                }
              } else {
                createProject();
              }
            }}
            style={{width: '138px'}}>
            作成
          </Button>
        </div>
      </Footer>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  align-items: center;
  margin-bottom: 37px;
`;

const Item = styled.div`
  margin-top: 20px;
  width: 768px;
  position: relative;

  > div:first-child {
    font-weight: 500;
    font-size: 12px;
    color: #899098;
    margin-bottom: 5px;

    > span {
      color: #ff4d4f;
    }
  }

  .ql-toolbar {
    border: 1px solid #e1e6eb;
    border-radius: 4px;
    position: relative;
    z-index: 1;
  }

  .ql-container {
    border-right: 1px solid #e1e6eb !important;
    border-bottom: 1px solid #e1e6eb !important;
    border-left: 1px solid #e1e6eb !important;
    border-radius: 4px;
    top: -4px;
  }
`;

const Header = styled.div`
  height: 50px;
  background: #f3f5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 46px;

  font-weight: 500;
  font-size: 14px;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: -20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  > div {
    display: flex;
    flex-direction: row-reverse;
    width: 768px;
  }
`;
