import React from 'react';

const Icon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.65122 13.8521L7.62322 3.00379C8.17657 1.99873 9.82342 1.99873 10.3768 3.00379L16.3488 13.8521C16.8332 14.7321 16.0948 15.75 14.972 15.75H3.02797C1.90516 15.75 1.16677 14.7321 1.65122 13.8521Z"
      stroke="#EB5757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 6.75V9.75"
      stroke="#EB5757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 12.7645V12.7499"
      stroke="#EB5757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
