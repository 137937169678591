import gql from 'graphql-tag';

export default gql`
  fragment trackingUrlFragment on TrackingUrl {
    id
    title
    url
    urlNormalized
    updatedAt
    createdAt
  }
`;
