import React, {useState} from 'react';
import styled from 'styled-components';
import {Modal, Select} from 'components/antd';
import {Action, useUsersByClientAllQuery, useUpdateAssigneeMutation} from 'api';
import {
  AutomaticMail,
  ManualMail,
  StepCall,
  Sms,
  StepTask,
} from 'components/Ui/Icon';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  action: Action;
}

const iconComponents: {[key: string]: any} = {
  'Action::AutomaticMail': AutomaticMail,
  'Action::ManualMail': ManualMail,
  'Action::Call': StepCall,
  'Action::Task': StepTask,
  'Action::Sms': Sms,
};

const UpdateAsigneeModalComponent = ({visible, setVisible, action}: Props) => {
  const [assigneeId, setAssigneeId] = useState(action.userId);
  const IconComponent = iconComponents[action.actionableType];
  const prospect = action.workflowProspectPool?.prospectPool?.prospect;

  const [updateAssignee] = useUpdateAssigneeMutation({
    variables: {uuid: action.uuid, userId: assigneeId},
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const onOk = () => {
    updateAssignee();
    setVisible(false);
  };

  const onClose = React.useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <UpdateAsigneeModal
      title="ワークフロー担当者を変更"
      visible={visible}
      width={400}
      onCancel={() => onClose()}
      cancelText="キャンセル"
      onOk={onOk}
      okText="保存">
      <div>
        <Title>アクション</Title>
        <ActionDetail>
          <IconComponent active width="30" height="30" />
          <div>
            <ProspectName>
              {prospect?.lastName + ' ' + prospect?.firstName}
            </ProspectName>
            <ProspectAccountName>{prospect?.account?.name}</ProspectAccountName>
          </div>
        </ActionDetail>
        <SelectAsignee>
          <Title>ワークフロー担当者</Title>
          <Select
            style={{width: '100%'}}
            value={assigneeId}
            onChange={(value) => setAssigneeId(value)}>
            {users.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.lastName} {user.firstName}
              </Select.Option>
            ))}
          </Select>
        </SelectAsignee>
      </div>
    </UpdateAsigneeModal>
  );
};

const UpdateAsigneeModal = styled(Modal)`
  .ant-modal-title {
    text-align: left;
  }
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-footer {
    border: none;
    padding: 0px 22px 10px 0px;
  }
  .ant-btn {
    padding: 0;
    width: 92px;
    height: 32px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #bdc4cb;
    &-primary {
      font-weight: 700;
      color: white;
      text-shadow: none;
    }
  }
`;

const Title = styled.p`
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: #495058;
`;

const ActionDetail = styled.div`
  display: flex;
  > div {
    margin-left: 4px;
  }
`;

const ProspectName = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #495058;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProspectAccountName = styled.div`
  text-align: left;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #495058;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SelectAsignee = styled.div`
  margin-top: 32px;
`;

export default UpdateAsigneeModalComponent;
