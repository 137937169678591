import gql from 'graphql-tag';

export default gql`
  fragment salesforceLeadLeadpadFieldFragment on SalesforceLeadLeadpadField {
    id
    leadpadField
    leadpadFieldText
    fieldType
    position
  }
`;
