import React from 'react';
import usePools from 'hooks/usePools';
import ListHeader from '../ListHeader';

export default () => {
  const {defaultPool} = usePools();
  const trackingCode = `<!-- Start of Leadpad Embed Code -->
<script>
(function(d, t, id) {
    var js = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
    js.src = '//cdn.leadpad.io/lp.js';
    js.defer = true;
    js.setAttribute('data-lp-id', id);
    s.parentNode.insertBefore(js, s);
}(document, 'script', '${defaultPool?.uuid}'));
</script>
<!-- End of Leadpad Embed Code -->`;

  const handleCopy = () => {
    navigator.clipboard.writeText(trackingCode);
  };

  if (!defaultPool) return null;

  return (
    <div className="p-8 bg-c-bg h-[calc(100vh-60px)] overflow-scroll">
      <ListHeader newButtonText={null} onNewButtonClick={null} />
      <div className="bg-white rounded p-8">
        <p className="text-c-light">
          {
            'このトラッキングコードをコピーして、サイトの各ページの</body>タグの直前に貼り付けます。'
          }
        </p>
        <textarea
          className="resize-none h-80 w-full max-w-3xl bg-c-bg border border-c-border rounded px-8 py-6 block"
          defaultValue={trackingCode}
          readOnly
        />
        <button
          className="flex items-center justify-center h-9 w-24 rounded bg-c-primary text-white hover:opacity-90 transition-all cursor-pointer mt-2"
          onClick={() => handleCopy()}>
          コピー
        </button>
      </div>
    </div>
  );
};
