import React from 'react';

const Icon = ({color = '#BDC4CB'}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.0013 12.8346C10.223 12.8346 12.8346 10.223 12.8346 7.0013C12.8346 3.77964 10.223 1.16797 7.0013 1.16797C3.77964 1.16797 1.16797 3.77964 1.16797 7.0013C1.16797 10.223 3.77964 12.8346 7.0013 12.8346Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 3.51058V3.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 5.83203V10.4987"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
