import {
  useProjectGroupsQuery,
  useCurrentUserQuery,
  useProjectQuery,
  ProjectGroup,
} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';
import useClientUser from './useClientUser';

const useProjectGroups = () => {
  const {projectGroupId: projectGroupUuid, projectId: projectUuid} =
    useParams<{projectGroupId: string; projectId: string}>();
  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery({});
  const {
    data: {currentUser = {}},
  } = useCurrentUserQuery();
  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectUuid},
    skip: !projectUuid,
  });
  const {isAdmin} = useClientUser();

  const defaultProjectGroup = React.useMemo(
    () =>
      projectGroups.find(
        (projectGroup) => projectGroup.projectGroupType === 'default',
      ),
    [projectGroups],
  );

  const selectedProjectGroup =
    projectGroups.find(
      (projectGroup) => projectGroup.uuid === projectGroupUuid,
    ) || defaultProjectGroup;

  const accessibleProjectGroups = React.useMemo(
    () =>
      isAdmin
        ? projectGroups
        : projectGroups.filter(
            (projectGroup) =>
              !projectGroup.isPrivate ||
              projectGroup.users.some((user) => user.id === currentUser.id),
          ),
    [isAdmin, projectGroups],
  );

  const isProjectGroupAccessible = React.useCallback(
    (projectGroup: ProjectGroup) =>
      !projectGroup?.isPrivate ||
      projectGroup?.uuid === project?.projectGroup?.uuid,
    [project],
  );

  return {
    defaultProjectGroup,
    selectedProjectGroup,
    accessibleProjectGroups,
    isProjectGroupAccessible,
  };
};

export default useProjectGroups;
