import gql from 'graphql-tag';
import manualMailFragment from '../manualMail';

export default gql`
  fragment manualMailMailThreadFragment on ManualMailMailThread {
    id
    members
    updatedAt
    createdAt
    manualMail {
      ...manualMailFragment
    }
  }
  ${manualMailFragment}
`;
