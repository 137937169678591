import React from 'react';
import {useParams} from 'react-router-dom';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useProjectWithCampaignsQuery} from 'api';

const ClickedCampaignIdsFilter = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query, setQuery} = useSearchParams();

  const {
    data: {project: {campaigns = []} = {}} = {},
  } = useProjectWithCampaignsQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.clickedCampaignIds || []}
      onChange={(value) => setQuery({page: 1, clickedCampaignIds: value})}>
      {campaigns.map((campaign) => (
        <Select.Option
          value={campaign.id}
          key={campaign.id}
          label={campaign.name}>
          {campaign.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ClickedCampaignIdsFilter;
