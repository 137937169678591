import React from 'react';
import {Approach} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Approach>;
}

const Cell = ({row}: Props) => {
  const approach = row.original;

  if (approach.priority === 'high') {
    return <span className="text-[#eb5757]">High</span>;
  }

  if (approach.priority === 'normal') {
    return <span className="text-[#ffc600]">Middle</span>;
  }

  if (approach.priority === 'low') {
    return <span className="text-[#d5ebd1]">Low</span>;
  }

  return '';
};

export default Cell;
