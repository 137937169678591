import React from 'react';
import styled from 'styled-components';
import {
  ProjectUserTelStatus,
  useAllProjectsTelStatusCallWeeklyReportsQuery,
} from 'api';
import useSearchParams from '../useSearchParams';
import moment from 'moment';
import DownloadModal from './ConversionRateReportDownloadModal';
import {ArrowRight, Download} from 'components/Ui/Icon';
import {Button} from 'components/antd';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const {query, searchParams, setQuery} = useSearchParams();
  const {data: {allProjectsTelStatusCallWeeklyReports = []} = {}} =
    useAllProjectsTelStatusCallWeeklyReportsQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'network-only',
    });

  const callCount = React.useCallback((report: ProjectUserTelStatus) => {
    let count = 0;

    count += totalCount(report.call);
    count += totalCount(report.reception);
    count += totalCount(report.contact);
    count += totalCount(report.appoint);
    count += totalCount(report.document);
    count += totalCount(report.other);
    count += totalCount(report.callNg);
    count += totalCount(report.receptionNg);
    count += totalCount(report.contactNg);

    return count;
  }, []);

  const contactCount = React.useCallback((report: ProjectUserTelStatus) => {
    let count = 0;

    count += totalCount(report.contact);
    count += totalCount(report.appoint);
    count += totalCount(report.document);
    count += totalCount(report.contactNg);

    return count;
  }, []);

  const totalCount = (object: any): any =>
    Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );

  const conversionRate = (before: number, after: number) => {
    if (!before || !after) return 0;

    return Math.round((after / before) * 1000) / 10;
  };

  if (allProjectsTelStatusCallWeeklyReports.length === 0) return null;

  return (
    <Container>
      <div>
        <h2>ステータス・カテゴリ別</h2>
        <div>
          <Display>
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
            <Button
              type="text"
              className="left"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>
            <Button
              type="text"
              onClick={() =>
                setQuery({
                  date: moment(query.date)
                    .add(1, 'months')
                    .format('YYYY-MM-DD'),
                })
              }>
              <ArrowRight />
            </Button>

            <Button
              className="today"
              onClick={() => setQuery({date: moment().format('YYYY-MM-DD')})}>
              今月
            </Button>
          </Display>

          <DownloadButton onClick={() => setModalOpen(true)}>
            <Download />
            ダウンロード
          </DownloadButton>
        </div>
      </div>
      <Table>
        <div className="column far-left">
          <div className="far-top">
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
          </div>

          <div className="category">
            <span>コール数</span>
          </div>
          <div className="category">
            <span>コール → キーマン接触</span>
          </div>
          <div className="category">
            <span>キーマン接触 → 資料送付</span>
          </div>
          <div className="category">
            <span>キーマン接触 → アポ獲得</span>
          </div>
        </div>

        {allProjectsTelStatusCallWeeklyReports.map((report: any) => (
          <div
            className={
              'column ' +
              (moment(report.countDate).weekday() % 6 == 0 && 'weekend')
            }
            key={report.countDate}>
            <div
              className={
                'far-top ' +
                (moment(report.countDate).isSame(new Date(), 'isoWeek') &&
                  'this-week')
              }>
              <span>W{moment(report.countDate).isoWeek()}</span>
              <span>
                {moment(report.countDate).startOf('isoWeek').format('MM/DD')} -{' '}
                {moment(report.countDate).startOf('isoWeek').format('MM/DD')}
              </span>
            </div>

            <div>
              <div>
                {!!callCount(report) && <span>{callCount(report)}</span>}
              </div>
            </div>

            <div>
              <div className="top">
                {!!callCount(report) && <span>{contactCount(report)}</span>}
              </div>
              <div className="bottom">
                {!!callCount(report) && (
                  <span>
                    {conversionRate(callCount(report), contactCount(report))}%
                  </span>
                )}
              </div>
            </div>

            <div>
              <div className="top">
                {!!callCount(report) && (
                  <span>{totalCount(report.document)}</span>
                )}
              </div>
              <div className="bottom">
                {!!callCount(report) && (
                  <span>
                    {conversionRate(
                      contactCount(report),
                      totalCount(report.document),
                    )}
                    %
                  </span>
                )}
              </div>
            </div>

            <div>
              <div className="top">
                {!!callCount(report) && (
                  <span>{totalCount(report.appoint)}</span>
                )}
              </div>
              <div className="bottom">
                {!!callCount(report) && (
                  <span>
                    {conversionRate(
                      contactCount(report),
                      totalCount(report.appoint),
                    )}
                    %
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </Table>

      {isModalOpen && (
        <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    > h2 {
      margin: auto auto auto 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: #495058;
    }

    > div {
      position: relative;
      margin: auto 0 auto auto;
      display: flex;
    }
  }
`;

const Display = styled.div`
  margin-left: 10px;

  > span {
    font-size: 12px;
    line-height: 17px;
    color: #495058;
  }

  > button {
    padding: 5.6px 6px;

    svg {
      path {
        stroke: #899098;
      }
    }

    &.left {
      svg {
        transform: rotate(180deg);
      }
    }

    &.today {
      margin-left: 10px;
      padding: 5.6px 10px;
      border: 1px solid #e1e6eb;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
`;

const DownloadButton = styled(Button)`
  position: absolute;
  top: -50px;
  right: 0px;

  display: flex;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  > svg {
    margin-right: 6px;
  }

  > span {
    margin: auto 0;
    font-weight: bold;
    font-size: 12px;
    color: #495058;
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  overflow: scroll;
  // height: 303px;
  border-bottom: none;
  border-right: none;

  .column {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e1e6eb;

    > div {
      width: 160px;
      min-height: 72px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      flex-direction: column;
      align-items: center;

      &:first-child {
        min-height: 43px;
        > span {
          margin: 0 auto;
        }

        &.far-top {
          top: 0;
          z-index: 3;
          position: sticky;
          background: #ffffff;
          border-bottom: 1px solid #e1e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin: auto;
          }

          &.this-week {
            border-top: 2px solid #495058;

            span {
              color: #495058;
              font-weight: bold;
            }
          }
        }
      }

      &:nth-child(2) {
        border-top: none;
      }

      > div {
        display: flex;
        width: 100%;
        height: 100%;
        color: #899098;

        &.top {
        }

        &.bottom {
          background: #fbfcfd;
          span {
            font-weight: bold;
            color: #495058;
          }
        }

        > span {
          margin: auto 6px auto auto;
        }
      }

      &.category {
        > span {
          color: #495058;
          font-weight: bold;
        }
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 220px;
        display: flex;
        align-items: center;
        padding: 0 40px;

        background: #fff;

        &.category {
          padding: 0 26px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        > span {
          margin: auto auto auto 0;
          font-size: 13px;
        }
      }
    }
  }
`;
