import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import StatusReport from './status';
import UserReport from './user';
import PriorityReport from './priority';
import CategoryReport from './category';

export default () => {
  return (
    <Layout
      title="ToDo分析"
      tabs={[
        {
          label: '全体',
          to: '/reports/todo/status',
        },
        {
          label: '担当者別',
          to: '/reports/todo/user',
        },
        {
          label: '優先度別',
          to: '/reports/todo/priority',
        },
        {
          label: '種別',
          to: '/reports/todo/category',
        },
      ]}
      search={['timeScale']}>
      <Switch>
        <Route path="/reports/todo/status" component={StatusReport} />
        <Route path="/reports/todo/user" component={UserReport} />
        <Route path="/reports/todo/priority" component={PriorityReport} />
        <Route path="/reports/todo/category" component={CategoryReport} />
        <Redirect to="/reports/todo/status" />
      </Switch>
    </Layout>
  );
};
