import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import MONTHS from 'helpers/months';

const AccountClosingMonthsFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      mode="multiple"
      allowClear
      value={query.accountClosingMonths || []}
      onChange={(value: Array<string>) =>
        setQuery({accountClosingMonths: value})
      }>
      {MONTHS.map((month) => (
        <Select.Option value={month} key={month}>
          {month}月
        </Select.Option>
      ))}
    </Select>
  );
};

export default AccountClosingMonthsFilter;
