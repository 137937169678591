import React from 'react';
import {City, SubCategory, ListingMarket, EmployeeNumber, CapitalFund} from './Condition';
import useCondition from '../useCondition';

const CompanyFilters = () => {
  const {numberOfCompanyFiltersInUse} = useCondition();

  if (numberOfCompanyFiltersInUse === 0) {
    return null;
  }

  return (
    <div className="p-6 pb-2 bg-white rounded flex items-start gap-4 border border-c-border">
      <h3 className="m-0 font-bold text-base w-20">企業情報</h3>
      <div className="flex-1 flex flex-col gap-4">
        <City />
        <SubCategory />
        <ListingMarket />
        <EmployeeNumber />
        <CapitalFund />
      </div>
    </div>
  );
};

export default CompanyFilters;
