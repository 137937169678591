import gql from 'graphql-tag';

export default gql`
  fragment hubspotEmailProspectActivityFragment on HubspotEmailProspectActivity {
    id
    subject
    bodyText
    bodyHtml
    from
    to
    stage
    stageText
    recordedAt
    hubspotEmailIdString
    hubspotContactIdString
  }
`;
