import React from 'react';
import {
  useAccountScoreSettingQuery,
  useLeadSourcesQuery,
  useProspectScoreSettingQuery,
  useUpdateClientProspectScoreSettingLeadSourcesMutation,
} from 'api';
import CheckboxGroup from './CheckboxGroup';

export default () => {
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const [update] = useUpdateClientProspectScoreSettingLeadSourcesMutation({});

  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const optionLeadSources = [
    ...leadSources.map((leadSource) => ({
      text: leadSource.name,
      value: leadSource.id,
      keyword: leadSource.name,
    })),
  ];

  return (
    <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
      <div className="bg-white p-6 rounded flex flex-col gap-2">
        <CheckboxGroup
          filter
          options={optionLeadSources}
          values={prospectScoreSetting?.leadSourceIds ?? []}
          onChange={(leadSourceIds: any) => {
            update({
              variables: {
                leadSourceIds,
              },
            });
          }}
        />
      </div>
    </div>
  );
};
