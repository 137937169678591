import ProspectEmail from './ProspectEmail';
import ProspectName from './ProspectName';
import AccountName from './AccountName';
import SendAt from './SendAt';
import Step from './Step';
import Status from './Status';

export default {
  ProspectEmail,
  ProspectName,
  AccountName,
  SendAt,
  Step,
  Status,
};
