import React from 'react';
import useCondition from '../../useCondition';
import BASE_CLASSIFICATIONS from 'helpers/classifications';
import {useMainCategoriesQuery} from 'api';

export default () => {
  const {condition} = useCondition();

  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery({});

  const classifications = React.useMemo(() => {
    return BASE_CLASSIFICATIONS(mainCategories);
  }, [mainCategories]);

  // カテゴリーの表示をまとめる
  const items = React.useMemo(() => {
    if (condition?.subCategories.length === 0) return [];

    const subCategoryIds = condition?.subCategories.map(
      (subCategory) => subCategory.id,
    );

    const mainCategories = classifications
      .flatMap((classification) =>
        classification.categories.filter((mainCategory) =>
          mainCategory.subCategories.every((subCategory) =>
            subCategoryIds?.includes(subCategory.id),
          ),
        ),
      )
      .filter(Boolean);

    const subCategories = condition?.subCategories.filter(
      (subCategory) =>
        !mainCategories
          .map((mainCategory) => mainCategory.id)
          .includes(subCategory.mainCategoryId),
    );

    return [
      ...mainCategories.map((category) => `${category.displayName}すべて`),
      ...subCategories.map((subCategory) => subCategory.displayName),
    ];
  }, [condition, classifications]);

  if (!condition?.subCategories || !condition?.subCategories.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">業種</h4>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {items.map((item, i) => (
          <span
            key={i}
            className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};
