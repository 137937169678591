import React from 'react';
import Modal from './Modal';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  useCreateApproachGroupPreleadMutation,
  usePreleadsQuery,
  ApproachGroupPreleadAttributes,
} from 'api';
import useSearchParams from '../../../useSearchParams';
import UsersSelect from './UsersSelect';

const priorities = [
  {name: 'High', value: 'high', color: '#eb5757'},
  {name: 'Normal', value: 'normal', color: '#ffc600'},
  {name: 'Low', value: 'low', color: '#56a948'},
];

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default ({visible, onClose}: Props) => {
  const {page, perPage, searchParams} = useSearchParams();

  const {data: {preleads: {pagination = {}} = {}} = {}} = usePreleadsQuery({
    variables: {
      page: page,
      perPage: perPage,
      search: searchParams,
    },
    fetchPolicy: 'cache-only',
  });

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().required('必須項目です'),
        priority: Yup.string().required('必須項目です'),
        approachType: Yup.string().required('必須項目です'),
        userIds: Yup.array().of(Yup.string()).nullable(),
        startDate: Yup.date().nullable(),
        dueDate: Yup.date().nullable(),
      }),
    ),
    defaultValues: {
      title: '',
      priority: 'normal',
      userIds: [],
      startDate: undefined,
      dueDate: undefined,
      approachType: 'call',
    },
    mode: 'onChange',
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const [create, {loading}] = useCreateApproachGroupPreleadMutation({
    onCompleted: () => {
      handleClose();
    },
  });

  const onSubmit = (values: ApproachGroupPreleadAttributes) =>
    create({
      variables: {
        attributes: {
          ...values,
          searchCondition: searchParams,
        },
      },
    });

  const userIds = watch('userIds');

  return (
    <Modal title="アプローチ一括作成" visible={visible} onClose={handleClose}>
      <div className="flex flex-col px-8 py-4">
        <div className="bg-c-bg h-8 flex items-center px-4 gap-2 rounded mb-6">
          <span className="text-sm">
            現在フィルタリングしている企業の アプローチ
            <strong className="font-bold text-red-500 text-base mx-1">
              {(pagination?.totalCount || 0).toLocaleString()}件
            </strong>
            が一括で作成されます。
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="flex flex-col gap-1">
            <input
              type="text"
              placeholder="タイトル"
              className="form-input p-2 w-full border-c-border rounded-sm text-sm focus:outline-none"
              {...register('title')}
            />
            {errors?.title && (
              <div className="text-red-500">{errors.title.message}</div>
            )}
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="action" className="w-24 mr-4 text-c-light">
              アクション種類
            </label>
            <select
              {...register('approachType')}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              <option value="call">電話</option>
              <option value="manual_mail">メール</option>
              <option value="task">タスク</option>
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label className="w-24 mr-4 text-c-light">日付</label>
            <div className="flex items-center gap-2">
              <label className="text-c-light">開始日</label>
              <input
                type="date"
                {...register('startDate')}
                className="w-36 mr-3 form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="text-c-light">終了日</label>
              <input
                type="date"
                {...register('dueDate')}
                className="w-36 mr-3 form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
              />
            </div>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="priority" className="w-24 mr-4 text-c-light">
              優先度
            </label>
            <select
              {...register('priority')}
              className="w-36 block form-select appearance-none bg-white border-c-border p-2 rounded-sm focus:outline-none text-sm text-c-light">
              {priorities.map((priority) => (
                <option key={priority.value} value={priority.value}>
                  {priority.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center mt-5">
            <label htmlFor="userIds" className="w-24 mr-4 text-c-light">
              アプローチ担当者
            </label>
            <UsersSelect
              onChange={(userIds) => setValue('userIds', userIds)}
              userIds={userIds}
            />
          </div>
          <div className="flex justify-end my-5">
            <button
              type="button"
              onClick={handleClose}
              className="cursor-pointer bg-white border border-c-border text-c-light text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light mr-3">
              キャンセル
            </button>
            <button
              type="submit"
              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-24 disabled:bg-c-bg disabled:text-c-light disabled:cursor-default"
              disabled={loading}>
              作成
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
