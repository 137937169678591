import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';

const TelTermFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {remind} = query;
  const {RangePicker} = DatePicker;
  const format = 'YYYY-MM-DD HH:00';

  return (
    <RangePicker
      showTime={{
        format: 'HH',
        defaultValue: [moment('09:00', 'HH:mm'), moment('09:00', 'HH:mm')],
      }}
      disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23, 24]}
      format={format}
      value={
        remind?.from && remind?.to
          ? [moment(remind.from, format), moment(remind.to, format)]
          : null
      }
      locale={locale}
      onChange={(value) => {
        if (value) {
          setQuery({
            remind: {
              from: value[0].format(format),
              to: value[1].format(format),
            },
          });
        } else {
          setQuery({
            remind: null,
          });
        }
      }}
    />
  );
};

export default TelTermFilter;
