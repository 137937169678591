import usePools from 'hooks/usePools';

const useGlobalMenu = () => {
  const pathname = location.pathname;
  const {defaultPool} = usePools();
  const actionPattern = [
    `/approaches`,
    `/tasks`,
    `/pools/${defaultPool?.uuid}/action`,
    `/pools/${defaultPool?.uuid}/workflows`,
  ];

  const prospectPattern = [
    `/pools/${defaultPool?.uuid}/prospects`,
    `/pools/${defaultPool?.uuid}/accounts`,
  ];

  const reportsPattern = [
    '/reports',
    `/pools/${defaultPool?.uuid}/prospect_report`,
  ];

  const projectPattern = [
    '/list/projects',
    '/list/preleads',
    `/list/groups`,
    '/report',
    '/list/share/request',
    '/list/share/offered',
    '/block',
    `/pools/${defaultPool?.uuid}/account_groups`,
  ];

  const campaignPattern = ['/mail_campaigns', '/garage'];

  const formPattern = [
    `/form/forms`,
    `/form/collected_form_urls`,
    `/form/tracking_code`,
  ];

  const settingPattern = [
    '/settings/teams',
    '/settings/users',
    '/settings/pools',
    '/settings/status/prelead_status',
    '/settings/externals',
    '/settings/share',
    '/settings/plan',
    '/downloads',
    '/maintenance',
  ];

  const getMenuCategory = () => {
    if (actionPattern.some((pattern) => pathname.startsWith(pattern))) {
      return 'action';
    } else if (
      prospectPattern.some((pattern) => pathname.startsWith(pattern))
    ) {
      return 'prospect';
    } else if (reportsPattern.some((pattern) => pathname.startsWith(pattern))) {
      return 'reports';
    } else if (projectPattern.some((pattern) => pathname.startsWith(pattern))) {
      return 'project';
    } else if (
      campaignPattern.some((pattern) => pathname.startsWith(pattern))
    ) {
      return 'campaign';
    } else if (formPattern.some((pattern) => pathname.startsWith(pattern))) {
      return 'form';
    } else if (settingPattern.some((pattern) => pathname.startsWith(pattern))) {
      return 'setting';
    } else {
      return null;
    }
  };
  const menuCategory = getMenuCategory();

  return {
    menuCategory,
  };
};

export default useGlobalMenu;
