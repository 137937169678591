import React from 'react';
import styled from 'styled-components';
import useSearchParams from 'views/client/list/mailCampaignSends/useSearchParams';

const ClickedAt = () => {
  const {query, setQuery} = useSearchParams();
  return (
    <label
      className={`mt-2 font-bold disabled:text-c-lighter ${query.mailCampaignUuid ? 'text-c-base' : 'text-c-lighter'}`}>
      最新クリック日
      <CustomInput>
        <input
          type="date"
          disabled={!query.mailCampaignUuid}
          value={query.clickedFrom}
          onChange={(e: any) =>
            setQuery({
              clickedFrom: e.target.value,
            })
          }
          className="form-input appearance-none w-32 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-lighter disabled:cursor-not-allowed relative"
        />

        <span className="mx-2">~</span>
        <input
          type="date"
          disabled={!query.mailCampaignUuid}
          value={query.clickedTo}
          onChange={(e: any) =>
            setQuery({
              clickedTo: e.target.value,
            })
          }
          className="form-input appearance-none w-32 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-lighter disabled:cursor-not-allowed"
        />
      </CustomInput>
    </label>
  );
};

const CustomInput = styled.div`
  /* カレンダーアイコンを非表示にし、inputをクリックでカレンダーを表示させる */
  input[type='date'] {
    position: relative;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;
export default ClickedAt;
