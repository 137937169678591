import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';

const MailCampaignClickedAtFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <DatePicker
        placeholder="開始日"
        value={
          query.mailCampaignClickedAtFrom &&
          moment(query.mailCampaignClickedAtFrom)
        }
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, mailCampaignClickedAtFrom: value?.format()});
        }}
        className="w-36"
      />
      <span className="mx-1 mt-2">~</span>
      <DatePicker
        placeholder="終了日"
        value={
          query.mailCampaignClickedAtTo && moment(query.mailCampaignClickedAtTo)
        }
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, mailCampaignClickedAtTo: value?.format()});
        }}
        className="w-36"
      />
    </>
  );
};

export default MailCampaignClickedAtFilter;
