import gql from 'graphql-tag';

export default gql`
  fragment slackSettingFragment on SlackSetting {
    id
    clientId
    apiToken
    channel
    isActive
    notifyPreleadProjectsCreated
    notifyBlockListImported
    notifyMailStatusChanged
    notifyTelStatusChanged
    notifyPreleadProjectStatusChanged
    notifyCommented
    notifyPreleadContactCreated
    notifyMailClicked
    notifyMailStatusSummary
    notifyTelStatusSummary
    notifyPressReleaseSummary
  }
`;
