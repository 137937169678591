import React from 'react';
import styled from 'styled-components';
import {Button} from 'components';
import {
  useSearchCompaniesQuery,
  useSearchCountQuery,
  useProjectQuery,
  useUpdateProjectSearchConditionMutation,
} from 'api';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import SearchModal from '../category/SearchModal';
import {useQueryParam, NumberParam} from 'use-query-params';
import useSearchCondition from '../useSearchCondition';
import useContract from 'hooks/useContract';
import {Tooltip} from 'components/antd';
import useProjectPath from 'hooks/useProjectPath';
import {NewProjectModal} from 'components/Ui';

export default ({pageKeywordsRef, pageNegativeKeywordsRef}: any) => {
  const history = useHistory();
  const location = useLocation();
  const {createPath} = useProjectPath();
  const {projectId} = useParams<{projectId: string}>();
  const [open, setOpen] = React.useState(false);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] =
    React.useState(false);
  const [page] = useQueryParam('page', NumberParam);
  const {searchParams, resetQuery} = useSearchCondition();
  const {data: {searchCount = 0} = {}} = useSearchCountQuery({
    variables: {
      search: searchParams,
    },
  });

  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
  });

  const {isPreleadFixable, isProjectAddable} = useContract();

  const [updateSearchCondition] = useUpdateProjectSearchConditionMutation({
    variables: {
      uuid: projectId,
      search: searchParams,
    },
    onCompleted: () =>
      history.push(
        createPath(
          `projects/${projectId}/search_condition?status=${project.status}`,
        ),
      ),
  });

  const {
    data: {searchCompanies: {companies = [], pagination = {}} = {}} = {},
    refetch,
  } = useSearchCompaniesQuery({
    variables: {
      page,
      search: searchParams,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <ClearButton
        buttonType="default"
        onClick={() => {
          if (confirm('すべてクリアしてよろしいですか？')) {
            resetQuery();
            pageKeywordsRef.current.setState({value: null});
            pageNegativeKeywordsRef.current.setState({value: null});
          }
        }}>
        クリア
      </ClearButton>

      <DefaultButton
        buttonType="default"
        onClick={() => {
          if (location.search.match(/&specificCompanyId=/)) {
            const companyId = location.search
              .split('&specificCompanyId=')[1]
              .split('&')[0];

            history.push(
              createPath(
                `projects/${project.uuid}/specific_companies/${companyId}`,
              ),
            );
          } else {
            history.push(
              createPath(
                `projects/${project.uuid}/search_condition?status=${project.status}`,
              ),
            );
          }
        }}>
        戻る
      </DefaultButton>

      <DefaultButton
        buttonType="default"
        onClick={() => {
          setOpen(true);
          refetch();
        }}>
        プレビュー
      </DefaultButton>

      <DefaultButton
        buttonType="default"
        onClick={() => updateSearchCondition()}>
        保存
      </DefaultButton>

      {isPreleadFixable && isProjectAddable ? (
        <ActionButton
          buttonType="default"
          disabled={searchCount > 10000}
          onClick={() => setIsNewProjectModalOpen(true)}>
          確定
        </ActionButton>
      ) : isPreleadFixable && !isProjectAddable ? (
        <Tooltip
          title="営業リスト数が上限に達しました。"
          color={`#EB5757`}
          placement="top">
          <DisabledActionButton>確定する</DisabledActionButton>
        </Tooltip>
      ) : null}

      <SearchModal
        open={open}
        setOpen={setOpen}
        companies={companies}
        pagination={pagination}
      />
      <NewProjectModal
        isNewProjectModalOpen={isNewProjectModalOpen}
        setIsNewProjectModalOpen={setIsNewProjectModalOpen}
        project={project}
        searchParams={searchParams}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const DefaultButton = styled(Button)`
  margin: auto 8px auto 0;
  height: 36px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e2e6ea;
  padding: 0 15px;

  span {
    font-weight: bold;
    font-size: 12px;
    color: #222426;
  }
`;

const ActionButton = styled(DefaultButton)`
  background: #68b5fb;

  span {
    color: #ffffff;
  }

  &:hover {
    background: #65aae8;
  }
`;

const ClearButton = styled(DefaultButton)`
  background: #ffffff;
  box-shadow: none;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 2px;

  span {
    color: #bdc4cb;
  }
`;

const DisabledActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px 13px 0 0;
  width: 100px;
  height: 36px;
  font-weight: bold;
  background: #e2e6ea;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: #ffffff;
`;
