import React from 'react';
import {useFormik} from 'formik';
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import * as Yup from 'yup';
import {Dialog, Transition} from '@headlessui/react';

interface Props {
  visible: boolean;
  onClose: () => void;
  title?: string;
  onSubmit: (values: any) => void;
  options: {value: string; text: string; required?: boolean}[];
  loading: boolean;
}

const upload_size_limit = 10000000; // 10MB

const CsvUploadModal = ({
  visible,
  onClose,
  onSubmit,
  options,
  loading,
  title = 'ファイルのアップロード',
}: Props) => {
  const [csv, setCsv] = React.useState(null);
  const uploadRef = React.useRef(null);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      file: Yup.mixed().required(),
      rowCount: Yup.number().required(),
      headers: Yup.array()
        .of(Yup.string())
        .test({
          name: 'includes-required',
          message: '',
          test: (values: string[]) =>
            options
              .filter((option) => option.required)
              .every((option) => values.includes(option.value)),
        })
        .required(),
    }),
    initialValues: {
      rowCount: 0,
      headers: [],
      file: null,
    },
    onSubmit,
  });

  const onClick = () => {
    uploadRef.current.click();
  };

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0) return;

      const file = e.target.files[0];

      if (file.size > upload_size_limit) {
        alert(
          'アップロードしようとしているファイルの容量が10MBを超えています。\n1ファイル10MBまでのアップロードが可能です。',
        );
        return null;
      }
      formik.setFieldValue('file', file);

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const codes = new Uint8Array(e.target.result);
        const encoding = Encoding.detect(codes);
        let unicodeString;
        if (encoding === 'UNICODE') {
          unicodeString = new TextDecoder().decode(codes);
        } else {
          unicodeString = Encoding.convert(codes, {
            to: 'UNICODE',
            from: encoding as Encoding.Encoding,
            type: 'string',
          });
        }
        Papa.parse(unicodeString, {
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            setCsv(result.data);
            formik.setFieldValue('headers', new Array(result.data[0].length));
            formik.setFieldValue('rowCount', result.data.length);
          },
        });
      };
      reader.readAsArrayBuffer(file);
    },
    [],
  );

  React.useEffect(() => {
    setCsv(null);
    formik.resetForm();
  }, [visible]);

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 text-center font-bold">
                  {title}
                </Dialog.Title>
                <div className="font-medium text-sm text-c-light text-center my-4">
                  <div>
                    {options
                      .filter((option) => option.required)
                      .map((option) => option.text)
                      .join(',')}
                    は必須項目です。
                  </div>
                  <div>
                    必須項目が入力されていない行はアップロード対象外になります。
                  </div>
                </div>
                <div className="my-4 flex justify-center">
                  <input
                    type="file"
                    onChange={onChange}
                    ref={uploadRef}
                    hidden
                  />
                  <button
                    onClick={onClick}
                    className="cursor-pointer text-base border border-c-border shadow bg-white hover:text-c-primary hover:border-c-primary transition-all rounded flex items-center justify-center h-9 w-32 focus:outline-none">
                    Click to Upload
                  </button>
                </div>
                {csv && (
                  <>
                    {csv[0].map((header: string, index: number) => (
                      <div
                        key={index}
                        className="grid grid-cols-2 py-2 border-t border-c-border">
                        <div className="w-full flex items-center">{header}</div>
                        <div className="w-full flex items-center">
                          <select
                            className="w-full form-select border-c-border rounded-sm focus:outline-none text-sm"
                            value={formik.values.headers[index]}
                            onChange={(e) => {
                              const value = e.target.value;
                              let headers = formik.values.headers;
                              headers[index] = value;
                              formik.setFieldValue('headers', headers);
                            }}>
                            <option value="" />
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.required && '[必須] '}
                                {option.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ))}
                    <div className="pt-4 border-t border-c-border flex justify-end">
                      <button
                        disabled={!formik.isValid || !csv || loading}
                        type="button"
                        onClick={() => onSubmit(formik.values)}
                        className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32 disabled:bg-c-bg disabled:text-c-light">
                        登録
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CsvUploadModal;
