import React from 'react';
import styled from 'styled-components';
import {useUpdateTaskMutation, Task} from 'api';
import {Select} from 'components/antd';
import {taskStatuses} from 'helpers/task';

interface Props {
  task: Task;
}

export default ({task}: Props) => {
  const [updateTask] = useUpdateTaskMutation();

  return (
    <Select
      value={task.statusText}
      onChange={(value) =>
        updateTask({
          variables: {uuid: task.uuid, attributes: {status: value}},
        })
      }>
      {taskStatuses.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.name}
        </Select.Option>
      ))}
    </Select>
  );
};
