import gql from 'graphql-tag';

export default gql`
  fragment campaignCompanyFragment on CampaignCompany {
    id
    status
    formStatus
    formStatusText
    name
    url
    contactUrl
    email
    checkedAt
  }
`;
