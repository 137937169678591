import React from 'react';
import {Activity} from 'api';
import TelStatusAndCommentActivity from './TelStatusAndCommentActivity';
import MailCampaignMailSendActivity from './MailCampaignMailSendActivity';
import TelStatusChangeActivity from './TelStatusChangeActivity';
import TelCommentActivity from './TelCommentActivity';
import ZoomPhoneWebhookCallActivity from './ZoomPhoneWebhookCallActivity';

interface Props {
  activity: Activity;
}

const activityComponents: {[key: string]: any} = {
  TelStatusAndCommentActivity: TelStatusAndCommentActivity,
  MailCampaignMailSendActivity: MailCampaignMailSendActivity,
  TelStatusChangeActivity: TelStatusChangeActivity,
  TelCommentActivity: TelCommentActivity,
  ZoomPhoneWebhookCallActivity: ZoomPhoneWebhookCallActivity,
};

const ProspectTimelineItem = ({activity}: Props) => {
  const ActivityComponent = activityComponents[activity.resource.__typename];

  return <ActivityComponent activity={activity} />;
};

export default ProspectTimelineItem;
