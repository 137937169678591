import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useFormik} from 'formik';
import Panel from './Panel';
import Title from './Title';
import {
  Content,
  useUpdateContentMailTemplateMutation,
  ContentMailTemplateAttributes,
} from 'api';
import Form from './Form';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {id} = useParams<{id: string}>();
  const [updateMailTemplate] = useUpdateContentMailTemplateMutation();

  const _content = React.useMemo(() => content, []);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      title: _content?.mailTemplate?.title || '',
      body: _content?.mailTemplate?.body || '',
      bodyHtml: _content?.mailTemplate?.bodyHtml || '',
      isShare: _content?.mailTemplate?.isShare,
    },
    onSubmit: (values: ContentMailTemplateAttributes) =>
      updateMailTemplate({variables: {id, attributes: values}}),
  });

  return (
    <Container>
      <Title content={content} formik={formik} />
      <Main>
        <Content>
          <Form formik={formik} />
          <Panel />
        </Content>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Main = styled.div`
  background: #fff;
  margin-top: 1.5rem;
  border-radius: 4px;
`;

const Content = styled.div`
  display: flex;
  min-height: 480px;
`;
