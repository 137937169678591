import moment from 'moment';
import React from 'react';
function weekStartDates(startDate: any, endDate: any) {
  const array = [];
  let current = startDate;
  while (current <= endDate) {
    array.push(current);
    current = moment(current).startOf('week').add('1', 'week');
  }

  return array;
}

function useWeekHeaders(queryMonth: string) {
  const splited = queryMonth?.split('-');
  const year = splited?.length > 1 ? Number(splited[0]) : moment().year();
  const month = splited?.length > 1 ? Number(splited[1]) : moment().month();
  const startDate = moment([year, month - 1]);
  const endDate = moment(startDate).endOf('month');
  const startDates = weekStartDates(startDate, endDate);

  const headers = startDates.map((startDate, index) => {
    const displayStartDate = moment(startDate).format('YYYY/MM/DD');
    const displayEndDate =
      endDate < moment(startDate).endOf('week')
        ? endDate.format('YYYY/MM/DD')
        : moment(startDate).endOf('week').format('YYYY/MM/DD');
    return {
      header: (
        <div className="flex items-center justify-center flex-col" key={index}>
          <div>
            {displayStartDate} ~ {displayEndDate}
          </div>
          <div>{moment(startDate).isoWeek()}週</div>
        </div>
      ),
    };
  });
  return ['', '合計', ...headers];
}

export default useWeekHeaders;
