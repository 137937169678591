import React from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import Table from './Table';
import Detail from './Detail';
import Summary from './Summary';
import Filter from './Filter';
import Tab from 'components/Action/Tab';

export default () => {
  const {path} = useRouteMatch();

  return (
    <div className="p-8 h-[calc(100vh-60px)] overflow-scroll bg-c-bg">
      <h1 className="text-2xl font-bold mb-4">アクション</h1>
      <div className="w-full p-5 bg-white rounded flex flex-col gap-2">
        <Tab />
        <Filter />
        <Summary />
        <Table />
      </div>
      <Switch>
        <Route path={`${path}/:approachId`}>
          <Detail />
        </Route>
      </Switch>
    </div>
  );
};
