import React from 'react';
import {
  HubspotDealPropertyDefinition,
  useHubspotDealPropertyDefinitionEnumSelectOptionsQuery,
} from 'api';

interface Props {
  definition: HubspotDealPropertyDefinition;
  value?: string;
}

export default ({definition, value}: Props) => {
  const {data: {hubspotDealPropertyDefinitionEnumSelectOptions = []} = {}} =
    useHubspotDealPropertyDefinitionEnumSelectOptionsQuery({
      variables: {
        hubpostDealPropertyDefinitionId: definition?.id,
      },
      skip: !definition,
    });

  const selectedOption = hubspotDealPropertyDefinitionEnumSelectOptions?.find(
    (option) => option.id === value,
  );

  if (!selectedOption) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-wrap items-center gap-1">
      <span className="h-6 flex items-center justify-center text-sm border border-c-border rounded-sm bg-c-bg px-2">
        {selectedOption.label}
      </span>
    </div>
  );
};
