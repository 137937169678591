import React from 'react';
import useSearchParams from '../useSearchParams';
import {useCalledUsersQuery} from 'api';
import {Popover} from 'components/antd';
import {PopoverContent, CheckBoxStyled, Box, FilterContent} from './style';

const AssigneeFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {calledUsers: users = []} = {}} = useCalledUsersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const userChecked = (id: string) => query.userIds.some((user) => user === id);

  const onChangeUser = React.useCallback((value: string) => {
    const newUserIds = query.userIds;
    const target = newUserIds.find((project) => {
      return project === value;
    });
    if (target) {
      newUserIds.splice(newUserIds.indexOf(target), 1);
    } else {
      newUserIds.push(value);
    }
    setQuery({userIds: newUserIds});
  }, []);

  const userName = React.useCallback(
    (userId: string) => {
      const user = users.find((user) => user.id === userId);

      return `${user?.lastName} ${user?.firstName}`;
    },
    [users],
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <PopoverContent>
          {users.length === 0 && <span>データがありません。</span>}
          {users?.map((user) => (
            <div key={user.id}>
              <CheckBoxStyled
                value={user.id}
                checked={userChecked(user.id)}
                onChange={(e) => onChangeUser(e.target.value)}>
                {`${user.lastName} ${user.firstName}`}
              </CheckBoxStyled>
            </div>
          ))}
        </PopoverContent>
      }>
      <Box>
        コール担当者
        {query.userIds.length > 0 && (
          <Popover
            placement="bottom"
            content={
              <FilterContent>
                {query.userIds?.map((userId) => (
                  <div key={userId}>
                    <span>{userName(userId)}</span>
                  </div>
                ))}
              </FilterContent>
            }>
            {query.userIds.length > 0 && <span>{query.userIds.length}</span>}
          </Popover>
        )}
      </Box>
    </Popover>
  );
};

export default AssigneeFilter;
