import React from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {
  Bars3BottomLeftIcon,
  CursorArrowRippleIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline';

const normalBlocks = [
  {
    blockType: 'button',
    label: 'ボタン',
    icon: <CursorArrowRippleIcon />,
  },
  {
    blockType: 'text',
    label: 'テキスト',
    icon: <Bars3BottomLeftIcon />,
  },
  {
    blockType: 'image',
    label: '画像',
    icon: <PhotoIcon />,
  },
];

export default () => {
  return (
    <div className="my-4">
      <Container
        groupName="formBlocks"
        behaviour="copy"
        getChildPayload={(i) => normalBlocks[i]}>
        {normalBlocks.map((b) => (
          <Draggable key={b.blockType}>
            <div className="draggable-item cursor-move flex px-4 h-10 mt-1 border border-c-border rounded-sm items-center bg-white gap-3">
              <span className="w-3">{b.icon}</span>
              <span>{b.label}</span>
            </div>
          </Draggable>
        ))}
      </Container>
    </div>
  );
};
