import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Note} from 'components/Ui/Icon';
import {
  useProjectQuery,
  useUpdateProjectWithoutReturnObjectMutation,
} from 'api';
import {Button} from 'components/Ui';
import {Quill, QuillWrapper} from 'components/Ui';

type Props = {
  closeEditMode: () => void;
};

export default ({closeEditMode}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });
  if (!project) return null;

  const [updateProject, {loading}] =
    useUpdateProjectWithoutReturnObjectMutation({
      refetchQueries: ['project', 'currentUser'],
      onCompleted: closeEditMode,
    });

  const [memo, setMemo] = useState(project.memo);
  const [memoHtml, setMemoHtml] = useState(project.memoHtml);

  return (
    <Container>
      <ItemMemo>
        <Note />
        <QuillWrapperCustom>
          <Quill
            theme="snow"
            value={memoHtml}
            onChange={(content, delta, source, editor) => {
              setMemoHtml(content);
              setMemo(editor.getText());
            }}
            modules={{
              toolbar: [
                [{header: [1, 2, 3, false]}],
                ['bold', 'italic', 'underline'],
                ['code-block'],
                ['link'],
              ],
            }}
            formats={[
              'header',
              'list',
              'bold',
              'italic',
              'underline',
              'strike',
              'color',
              'background',
              'code-block',
              'link',
            ]}
            bounds={'#quill-container'}
          />
          <div id="quill-container" />
        </QuillWrapperCustom>
      </ItemMemo>
      <ButtonWrapper>
        <CustomButton
          buttonType="ghost"
          onClick={closeEditMode}
          loading={loading}
          disabled={loading}>
          キャンセル
        </CustomButton>
        <CustomButton
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={() =>
            updateProject({
              variables: {
                uuid: projectId,
                attributes: {
                  memo: memo,
                  memoHtml: memoHtml,
                },
              },
            })
          }>
          保存
        </CustomButton>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div``;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: 70px;
    height: 26px;
    font-size: 9px;
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const CustomButton = styled(Button)`
  width: 70px;
  height: 26px;
  font-size: 9px;
  &:first-child {
    margin-right: 8px;
  }
`;

const QuillWrapperCustom = styled(QuillWrapper)`
  flex-grow: 1;
`;
