import React from 'react';
import SlideIn from '../SlideIn';
import {ProspectPool} from 'api';
import DealToContactForm from './DealToContactForm';
import DealForm from './DealForm';

interface Props {
  prospectPool: ProspectPool;
}

const NewDeal = ({prospectPool}: Props) => {
  return (
    <SlideIn path="/deals/new">
      <div className="flex h-full w-full flex-col">
        {prospectPool.salesforceContact ? (
          <DealToContactForm prospectPool={prospectPool} />
        ) : (
          <DealForm prospectPool={prospectPool} />
        )}
      </div>
    </SlideIn>
  );
};

export default NewDeal;
