import gql from 'graphql-tag';

export default gql`
  fragment salesforceContactFragment on SalesforceContact {
    id
    contactId
    firstName
    lastName
    name
    salesforceAccountId
    addition
  }
`;
