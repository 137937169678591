import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_36729_204624)">
      <path
        d="M6.99996 12.8327C10.2216 12.8327 12.8333 10.221 12.8333 6.99935C12.8333 3.77769 10.2216 1.16602 6.99996 1.16602C3.7783 1.16602 1.16663 3.77769 1.16663 6.99935C1.16663 10.221 3.7783 12.8327 6.99996 12.8327Z"
        fill="#4FCE82"
        stroke="#4FCE82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.43335 7L6.12502 8.69167L9.56668 5.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_36729_204624">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
