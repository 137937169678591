import React from 'react';
import {Row} from 'react-table';
import {Prelead} from 'api';
import moment from 'moment';

interface Props {
  row: Row<Prelead>;
}

const TelUpdatedAtCell = ({row}: Props) => {
  const prelead: Prelead = row.original;

  return (
    <span>
      {prelead.telUpdatedAt &&
        moment(prelead.telUpdatedAt).format('YYYY年MM月DD日 HH時mm分')}
    </span>
  );
};

export default TelUpdatedAtCell;
