import React from 'react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {Salesforce} from 'components/Ui/Icon';
import {ProspectPool} from 'api';
import useSalesforceModalContext from './useSalesforceModalContext';

interface Props {
  prospectPool: ProspectPool | null;
}

const Header = ({prospectPool}: Props) => {
  const {onClose} = useSalesforceModalContext();

  return (
    <div className="flex h-12 flex-shrink-0 items-center gap-4 border-b border-c-border bg-white px-6">
      <Salesforce />
      <h2 className="m-0 flex-1 text-[18px] font-bold">
        {prospectPool?.prospect?.lastName} {prospectPool?.prospect?.firstName}
      </h2>
      <button
        className="h-6 w-6 cursor-pointer border-none bg-transparent p-0"
        onClick={() => onClose()}
      >
        <XMarkIcon className="h-6 w-6 text-c-lighter" />
      </button>
    </div>
  );
};

export default Header;
