import {Dialog} from '@headlessui/react';
import {Project, useClientDownloadProjectCountsQuery} from 'api';
import React from 'react';

interface Props {
  project: Project;
}
export default ({project}: Props) => {
  const {
    loading,
    data: {
      clientDownloadProjectCounts: {
        monthlyCount = null,
        downloadLimit = null,
      } = {},
    } = {},
  } = useClientDownloadProjectCountsQuery({
    fetchPolicy: 'network-only',
  });
  return (
    <>
      <div className="flex justify-between items-center">
        <Dialog.Title as="h3" className="text-lg leading-6 font-bold">
          CSVダウンロード - 営業リスト
        </Dialog.Title>

        {!!downloadLimit && !loading && (
          <span className="font-bold">
            {`ダウンロード件数: ${monthlyCount.toLocaleString()}/${downloadLimit.toLocaleString()}`}
          </span>
        )}
      </div>
      {!project.isDownloadable && (
        <span className="font-bold text-red-500">
          ＊月ごとにダウンロードできる件数を超えています
        </span>
      )}
    </>
  );
};
