import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="10" cy="10" r="10" fill="#8966EF" />
    <g clipPath="url(#clip0_28125_109819)">
      <path
        d="M7.125 5.375L7.125 13.625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.43744 7.25L10.5 7.25L10.5 5.75L8.43744 5.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4376 10.25L12.4126 10.25L12.4126 8.75L8.4376 8.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4375 13.25L14.0625 13.25L14.0625 11.75L8.4375 11.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_28125_109819">
        <rect
          width="9"
          height="9"
          fill="white"
          transform="translate(15 5) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
