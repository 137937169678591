import React from 'react';
import Menu from '../Menu';
import styled from 'styled-components';
import {sideMenuWidth} from 'helpers/sideMenu';
import Content from './Content';

export default () => {
  return (
    <div className="flex bg-white h-[calc(100vh-60px)]">
      <Menu />
      <Container>
        <Content />
      </Container>
    </div>
  );
};

const Container = styled.div`
  background: #fff;
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  height: calc(100vh - 130px);
  overflow-x: hidden;
  padding: 45px 70px 90px;
`;
