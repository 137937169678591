import {MainCategory} from 'api';

const CLASSIFICATIONS = (mainCategories: Array<MainCategory>) => {
  const filterCategory = (targetArray: string[]) => {
    const resArray: Array<MainCategory> = [];
    targetArray.map((target: string) => {
      mainCategories.map((mainCategory) => {
        if (target === mainCategory?.displayName) {
          resArray.push(mainCategory);
        }
      });
    });
    return resArray;
  };
  return [
    {
      name: '製造・機械',
      categories: filterCategory(['製造', '機械', '機械関連サービス']),
    },
    {
      name: '金融・不動産',
      categories: filterCategory(['金融', '不動産']),
    },
    {
      name: '建設・工事',
      categories: filterCategory(['建設・工事']),
    },
    {
      name: '運輸・物流',
      categories: filterCategory(['運輸・物流']),
    },
    {
      name: '電気製品',
      categories: filterCategory(['電気製品']),
    },
    {
      name: '自動車・乗り物',
      categories: filterCategory(['自動車・乗り物']),
    },
    {
      name: '食品',
      categories: filterCategory(['食品']),
    },
    {
      name: '商社',
      categories: filterCategory(['商社']),
    },
    {
      name: '小売・アパレル・生活用品',
      categories: filterCategory(['小売', 'アパレル・美容', '生活用品']),
    },
    {
      name: '人材・教育・コンサル',
      categories: filterCategory(['人材', '教育', 'コンサルティング']),
    },
    {
      name: 'IT・通信',
      categories: filterCategory(['IT', '通信機器', '通信']),
    },
    {
      name: 'マスコミ・広告',
      categories: filterCategory(['マスコミ', '広告']),
    },
    {
      name: 'エンタメ・旅行・外食',
      categories: filterCategory(['エンタメ', '外食']),
    },
    {
      name: 'ゲーム',
      categories: filterCategory(['ゲーム']),
    },
    {
      name: '医療・福祉',
      categories: filterCategory(['医療・福祉']),
    },
    {
      name: '電気・ガス・エネルギー',
      categories: filterCategory(['電気・ガス・熱供給・水道', 'エネルギー']),
    },
    {
      name: '化学',
      categories: filterCategory(['化学']),
    },
    {
      name: '農業・林業・鉱業・漁業',
      categories: filterCategory(['農業・林業', '鉱業', '漁業']),
    },
    {
      name: '行政・公務',
      categories: filterCategory(['公務']),
    },
    {
      name: 'その他サービス・学術・専門',
      categories: filterCategory(['学術研究・専門・技術', 'その他サービス']),
    },
    {
      name: 'その他',
      categories: filterCategory(['その他業界']),
    },
  ];
};

export default CLASSIFICATIONS;
