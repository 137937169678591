import {useAccountPoolQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';

export default () => {
  const {accountId} = useParams<{accountId: string}>();
  const {data: {accountPool: {account = {}} = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  return (
    <>
      <div className="text-c-light">業種</div>
      <div className="flex flex-wrap mb-4">
        {account.company?.subCategories?.map((category) => (
          <span
            className="mr-2 mb-0 mt-2 px-2.5 py-1.5 bg-c-bg rounded text-sm leading-[17px] text-c-base"
            key={category.id}>
            {category.displayName}
          </span>
        ))}
      </div>
    </>
  );
};
