import React from 'react';
import {Breadcrumb, Container, Header} from 'components/Ui';
import ReportTypeTabs from '../ReportTypeTabs';
import {Link, Route, Switch} from 'react-router-dom';
import ProjectReport from './project';
import {Body, Main, MainTabs, MainTabItem} from '../style';
import companyProfileReport from './companyProfile';

export default () => {
  return (
    <Container page="report">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <span className="current">レポート</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>

      <Body>
        <ReportTypeTabs />

        <Main>
          <MainTabs>
            <MainTabItem
              active={location.pathname.includes('report/mail/project')}>
              <Link to={`/report/mail/project${location.search}`}>
                営業リスト別
              </Link>
            </MainTabItem>
            <MainTabItem
              active={location.pathname.includes(
                'report/mail/company_profile',
              )}>
              <Link
                to={`/report/mail/company_profile/employee_range${location.search}`}>
                企業プロフィール別
              </Link>
            </MainTabItem>
          </MainTabs>
          <Switch>
            <Route path="/report/mail/project" component={ProjectReport} />
            <Route
              path="/report/mail/company_profile"
              component={companyProfileReport}
            />
          </Switch>
        </Main>
      </Body>
    </Container>
  );
};
