import React from 'react';
import useFilterModalContext from '../useFilterModalContext';

interface Props {
  onClear: () => void;
}

const Footer = ({onClear}: Props) => {
  const {setFilterType} = useFilterModalContext();

  return (
    <div className="flex items-center justify-between px-6 py-4 gap-4 bg-c-bg rounded-b">
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold"
        onClick={() => setFilterType(null)}>
        戻る
      </button>
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-transparent border-none text-c-primary font-bold"
        onClick={onClear}>
        条件をクリア
      </button>
    </div>
  );
};

export default Footer;
