import gql from 'graphql-tag';

export default gql`
  fragment formPageFragment on FormPage {
    id
    uuid
    slug
    pageType
    createdAt
    updatedAt
  }
`;
