import gql from 'graphql-tag';

export default gql`
  fragment formBlockTextFragment on FormBlockText {
    id
    text
    textHtml
    createdAt
    updatedAt
  }
`;


