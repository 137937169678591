import React from 'react';
import {useParams} from 'react-router-dom';
import useCondition from '../../../useCondition';
import {useSalesforceDealRecordTypesQuery} from 'api';
import SearchCondition from './SearchCondition';

export default () => {
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {condition} = useCondition();

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {
        poolId,
      },
      skip: !poolId,
    });

  const recordType = salesforceDealRecordTypes.find(
    (recordType) => recordType.id === condition?.salesforceDealRecordTypeId,
  );

  if (
    !condition?.hasSalesforceDeal &&
    !condition?.salesforceDealRecordTypeId &&
    !(condition?.salesforceDealSearchConditions?.length > 0)
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">商談</h4>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-c-light">レコードタイプ：</span>
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {recordType?.name}
        </span>
      </div>
      {condition.salesforceDealSearchConditions?.length > 0 &&
        condition.salesforceDealSearchConditions?.map((searchCondition, i) => (
          <SearchCondition key={i} searchCondition={searchCondition} />
        ))}
    </div>
  );
};
