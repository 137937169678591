import React, {useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {
  HubspotDealPropertyDefinition,
  HubspotPropertyDefinitionOption,
} from 'api';

interface Props {
  propertyDefinition: HubspotDealPropertyDefinition;
  handleUpdate: Function;
}

export const Checkbox: React.FC<Props> = ({
  propertyDefinition,
  handleUpdate,
}) => {
  const options = propertyDefinition.options || [];
  const [selectOptions, setSelectOptions] = useState<
    Array<HubspotPropertyDefinitionOption>
  >([]);

  const filteredOptions = React.useMemo(
    () => options.filter((option) => !selectOptions.includes(option)),
    [selectOptions, options],
  );

  if (options.length === 0) {
    return null;
  }

  const onChange = (options: Array<HubspotPropertyDefinitionOption>) => {
    setSelectOptions(options);
    const value = options.map((option) => option.value).join(';');
    return handleUpdate(propertyDefinition.name, value);
  };

  return (
    <div className="mb-4">
      <label>{propertyDefinition.labelText}</label>
      <Listbox value={selectOptions} onChange={onChange} multiple>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full min-h-[2.5rem] cursor-default px-2 py-2 bg-white border border-c-border focus:outline-none flex flex-wrap">
            {selectOptions.map((option) => (
              <span
                key={option.value}
                className="mb-1 mr-1 truncate inline-flex items-center justify-center px-2 py-2 text-sm font-medium leading-4 rounded-sm border border-c-border">
                {option.label}
                <XMarkIcon
                  className="w-4 h-4 ml-2 cursor-pointer hover:bg-[rgba(255,255,255,.3)] rounded-sm"
                  onClick={() =>
                    onChange(
                      selectOptions.filter(
                        (selectedOption) =>
                          selectedOption.value !== option.value,
                      ),
                    )
                  }
                />
              </span>
            ))}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 w-full border px-2 py-2 block border-c-border overflow-auto rounded-sm bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.map((option) => (
                <Listbox.Option
                  key={option.value}
                  value={option}
                  className="text-sm relative cursor-default select-none py-2 px-4">
                  {option.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
