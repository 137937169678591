import React from 'react';

const Icon = ({color = '#BDC4CB'}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.58821 7.86916V7.71484H8.74159H10.7355C10.8888 7.71484 10.8888 7.71484 11.0422 7.86916L12.5759 12.8074L14.1097 7.86916C14.1097 7.71484 14.2631 7.71484 14.4164 7.71484H16.2569H16.4103L16.5637 7.86916C16.717 9.10373 16.8704 10.3383 17.0238 11.5729C17.1772 12.8074 17.3305 14.042 17.3305 15.2766C17.3305 15.4309 17.1772 15.4309 17.0238 15.4309H15.4901C15.3367 15.4309 15.1833 15.2766 15.1833 15.1223L14.5698 11.2642L13.3428 15.4309C13.3428 15.4309 13.3428 15.5852 13.1894 15.5852H13.0361H11.6557H11.5023C11.5023 15.5852 11.5023 15.4309 11.3489 15.4309L10.2753 11.2642L9.81521 15.4309C9.81521 15.5852 9.66183 15.7395 9.50846 15.7395H7.97472C7.82134 15.7395 7.66797 15.5852 7.66797 15.4309L8.58821 7.86916Z"
      fill={color}
    />
    <path
      d="M7.36153 23.6128H21.472C22.5456 23.6128 23.3125 22.6869 23.3125 21.761V3.24248C23.3125 2.16223 22.3922 1.39062 21.472 1.39062H3.06705C1.99343 1.39062 1.22656 2.31655 1.22656 3.24248V17.44L7.36153 23.6128Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M7.51388 17.5898H1.37891L7.51388 23.7627V17.5898Z"
      fill={color}
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
