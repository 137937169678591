import React from 'react';

function Icon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3337 2L9.33366 14M2.66699 6H13.3337H2.66699ZM2.66699 10H13.3337H2.66699ZM6.66699 2L4.66699 14L6.66699 2Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
