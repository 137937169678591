import gql from 'graphql-tag';

export default gql`
  fragment csvFragment on Csv {
    id
    type
    status
    filename
    rowCount
    currentRowNumber
    errorRowNumber
    percent
  }
`;
