import React from 'react';
import {useQueryParams, StringParam} from 'use-query-params';
import SumTable from './Sum/Table';
import LeadSourceTable from './LeadSource/Table';

export default () => {
  const [query] = useQueryParams({
    column: StringParam,
  });

  return (
    <>{query.column === 'lead_source' ? <LeadSourceTable /> : <SumTable />}</>
  );
};
