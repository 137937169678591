import gql from 'graphql-tag';

export default gql`
  fragment hubspotDealCreateProspectActivityFragment on HubspotDealCreateProspectActivity {
    hubspotUrl
    hubspotDeal {
      id
      hubspotId
      dealname
    }
  }
`;
