import React from 'react';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const LatestTelCommentCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;

  return <span>{preleadProject.latestTelComment}</span>;
};

export default LatestTelCommentCell;
