import React from 'react';

function Icon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9746 6.6748V15.6748C15.9746 16.1248 15.6746 16.4248 15.2246 16.4248H3.22461C2.77461 16.4248 2.47461 16.1248 2.47461 15.6748V6.6748"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9746 6.6748L9.22461 11.9248L2.47461 6.6748"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.47461 6.6752L9.22461 1.2002L15.9746 6.6752"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
