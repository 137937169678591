import React from 'react';
import {useUsersByClientAllQuery} from 'api';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';

const AssigneeFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const optionUsers = [
    {text: '未設定', value: 'none'},
    ...users.map((user) => ({
      text: `${user?.lastName} ${user?.firstName}`,
      value: user.id,
      keyword: `${user?.lastName} ${user?.firstName}`,
    })),
  ];

  return (
    <>
      <Header title="リード担当者" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={optionUsers}
            values={query.assignees || []}
            onChange={(assignees) => setQuery({...query, assignees})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, assignees: []})} />
    </>
  );
};

export default AssigneeFilter;
