import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {Form} from 'api';
import StandardFieldBlocks from './StandardFieldBlocks';
import CustomizeItemFieldBlocks from './CustomizeItemFieldBlocks';
import OtherFieldBlocks from './OtherFieldBlocks';
import Block from './blockId';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  return (
    <Switch>
      <Route path="/form/forms/:formId/:page/blocks/:blockId">
        <Block form={form} />
      </Route>
      <Route path="/form/forms/:formId/:page/blocks">
        <StandardFieldBlocks form={form} />
        <CustomizeItemFieldBlocks form={form} />
        <OtherFieldBlocks form={form} />
      </Route>
    </Switch>
  );
};
