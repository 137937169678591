import {
  usePreleadProjectQuery,
  useUpdatePreleadTelephoneNumberMutation,
} from 'api';
import {useFormik} from 'formik';
import React from 'react';
import Modal from './Modal';
import {useParams} from 'react-router-dom';

interface Props {
  telephoneNumber: string;
  visible: boolean;
  onClose: () => void;
}

export default ({telephoneNumber, visible, onClose}: Props) => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const {data: {preleadProject: {prelead = {}} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      fetchPolicy: 'cache-only',
    });

  const [updatePreleadTelephoneNumber] =
    useUpdatePreleadTelephoneNumberMutation({
      onCompleted: onClose,
    });

  const preleadTelephoneNumber = prelead.telephoneNumbers?.find(
    (preleadTelephoneNumber) =>
      preleadTelephoneNumber?.telephoneNumber == telephoneNumber,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: preleadTelephoneNumber?.name,
      telephoneNumber: preleadTelephoneNumber?.telephoneNumber,
    },
    onSubmit: (values: any) => {
      updatePreleadTelephoneNumber({
        variables: {id: preleadTelephoneNumber?.id, attributes: values},
      });
    },
  });

  return (
    <Modal title="電話番号を編集" visible={visible} onClose={onClose}>
      <div className="flex flex-col">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-6 font-bold">
            <label htmlFor="name" className="text-c-light">
              ラベル <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formik.values.name}
              onChange={(e) => formik.setFieldValue('name', e.target.value)}
              className="form-input p-2 w-full border-c-border rounded-sm text-sm focus:outline-none"
              required
            />
          </div>
          <div className="mb-5 font-bold">
            <label htmlFor="tel" className="text-c-light">
              電話番号 <span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              value={formik.values.telephoneNumber}
              onChange={(e) =>
                formik.setFieldValue('telephoneNumber', e.target.value)
              }
              className="form-input p-2 w-full border-c-border rounded-sm text-sm focus:outline-none"
              required
            />
          </div>

          <div className="flex justify-end mt-8">
            <button
              type="button"
              onClick={onClose}
              className="cursor-pointer bg-white border border-c-border text-c-light text-sm hover:opacity-50 rounded items-center justify-center h-9 w-20 disabled:bg-c-bg disabled:text-c-light mr-3">
              キャンセル
            </button>
            <button
              type="submit"
              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-20 disabled:bg-c-bg disabled:text-c-light disabled:cursor-default"
              disabled={!formik.values.name || !formik.values.telephoneNumber}>
              登録
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
