import gql from 'graphql-tag';

export default gql`
  fragment blockGroupDownloadFragment on BlockGroupDownload {
    id
    uuid
    file
    createdAt
  }
`;
