import gql from 'graphql-tag';

export default gql`
  fragment salesforceApexClassFragment on SalesforceApexClass {
    id
    name
    methodType
    objectType
  }
`;
