import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 19V1H17V19H19H12.5M1 19H7.5M7.5 19V13.5H12.5V19M7.5 19H12.5M11.5 8.5V10.5H14V8.5H11.5ZM6 8.5V10.5H8.5V8.5H6ZM6 4V6H8.5V4H6ZM11.5 4V6H14V4H11.5Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Icon;
