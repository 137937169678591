import React, {useEffect} from 'react';
import {useParams, Switch, Route} from 'react-router';
import {useProjectGroupQuery} from 'api';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {Drawer} from 'components';
import Header from './Header';
import BasicInformation from './BasicInformation';
import Members from './Members';
import Setting from './Setting';

export default () => {
  const history = useHistory();
  const {projectGroupId} = useParams<{projectGroupId: string}>();
  const {data: {projectGroup = null} = {}} = useProjectGroupQuery({
    variables: {uuid: projectGroupId},
    skip: !projectGroupId || projectGroupId === 'new',
  });

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  const isOpen = !!projectGroup;
  const closeModal = () => history.push(`/list/groups${location.search}`);

  return (
    <Drawer isOpen={isOpen} onClose={closeModal} width={680}>
      <Container>
        <Header />
        <Body>
          <Tabs>
            <TabItem active={location.pathname.endsWith('/basic_information')}>
              <Link
                to={`/list/groups/${projectGroupId}/basic_information${location.search}`}>
                <span>基本情報</span>
              </Link>
            </TabItem>
            <TabItem active={location.pathname.endsWith('/members')}>
              <Link
                to={`/list/groups/${projectGroupId}/members${location.search}`}>
                <span>メンバー</span>
                <Count>{projectGroup ? projectGroup.users.length : 0}</Count>
              </Link>
            </TabItem>
            <TabItem active={location.pathname.endsWith('/setting')}>
              <Link
                to={`/list/groups/${projectGroupId}/setting${location.search}`}>
                <span>設定</span>
              </Link>
            </TabItem>
          </Tabs>
          <ContentWrapper>
            <Switch>
              <Route
                path="/list/groups/:projectGroupId/basic_information"
                component={BasicInformation}
              />
              <Route
                path="/list/groups/:projectGroupId/members"
                component={Members}
              />
              <Route
                path="/list/groups/:projectGroupId/setting"
                component={Setting}
              />
            </Switch>
          </ContentWrapper>
        </Body>
      </Container>
    </Drawer>
  );
};

const Container = styled.div``;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 50px;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #dadce0;
`;

const TabItem = styled.div<{active?: boolean}>`
  flex: 1;
  text-align: center;
  border-bottom: ${({active}) => (active ? '3px solid #495058' : '')};
  a {
    font-size: 12px;
    font-weight: bold;
    line-height: 35px;
    color: ${({active}) => (active ? '#222426' : '#BDC4CB')};
  }
`;

const Count = styled.span`
  margin-left: 4px;
  padding: 3px 7px;
  background: #e2e6ea;
  border-radius: 100px;
  font-weight: bold;
  font-size: 8px;
  text-align: center;
  color: #899098;
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;
