import React from 'react';
import {useParams} from 'react-router-dom';
import useCondition from '../../../useCondition';
import {useHubspotDealPropertyDefinitionsQuery} from 'api';
import {XMarkIcon} from '@heroicons/react/20/solid';
import Select from './Select';
import Checkbox from './Checkbox';

type MultiSelectSearchCondition = {
  deal_property_definition_checkbox_option_ids: string[];
  deal_property_definition_field_type: string;
  deal_property_definition_property_type: string;
  deal_property_definition_id: string;
};

type SelectSearchCondition = {
  deal_property_definition_select_option_id: string;
  deal_property_definition_field_type: string;
  deal_property_definition_property_type: string;
  deal_property_definition_id: string;
};

export type SearchCondition =
  | SelectSearchCondition
  | MultiSelectSearchCondition;

interface Props {
  searchCondition: SearchCondition;
  setSearchCondition: (condition: SearchCondition) => void;
  onDelete: () => void;
}

const Field = ({searchCondition, setSearchCondition, onDelete}: Props) => {
  const {condition} = useCondition();

  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {data: {hubspotDealPropertyDefinitions = []} = {}} =
    useHubspotDealPropertyDefinitionsQuery({
      variables: {
        poolId,
      },
      skip: !poolId,
    });

  const options = hubspotDealPropertyDefinitions
    .filter(
      (definition) =>
        ['checkbox', 'select'].includes(definition.fieldTypeString) &&
        definition.propertyTypeString === 'enumeration' &&
        definition.options.length !== 0,
    )
    .filter(
      (definition) =>
        definition.id === searchCondition.deal_property_definition_id ||
        !condition.hubspotDealSearchConditions
          .map(
            (condition: SearchCondition) =>
              condition.deal_property_definition_id,
          )
          .includes(definition.id),
    )
    .map((definition) => ({
      value: definition.id,
      label: definition.label,
      fieldtype: definition.fieldTypeString,
      propertyTypeString: definition.propertyTypeString,
    }));

  const selectedOption = searchCondition.deal_property_definition_id
    ? hubspotDealPropertyDefinitions.find(
        (definition) =>
          definition.id === searchCondition.deal_property_definition_id,
      )
    : null;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const definitionId = e.target.value;
    const definition = hubspotDealPropertyDefinitions.find(
      (definition) => definition.id === definitionId,
    );

    if (!definitionId) {
      setSearchCondition({
        deal_property_definition_id: '',
        deal_property_definition_field_type: '',
        deal_property_definition_property_type: '',
        deal_property_definition_select_option_id: '',
        deal_property_definition_checkbox_option_ids: [],
      });
      return;
    }

    if (
      definition.fieldTypeString === 'select' &&
      definition.propertyTypeString === 'enumeration'
    ) {
      setSearchCondition({
        deal_property_definition_id: definition.id,
        deal_property_definition_field_type: definition.fieldTypeString,
        deal_property_definition_property_type: definition.propertyTypeString,
        deal_property_definition_select_option_id: '',
      });
    }

    if (
      definition.fieldTypeString === 'checkbox' &&
      definition.propertyTypeString === 'enumeration'
    ) {
      setSearchCondition({
        deal_property_definition_id: definition.id,
        deal_property_definition_field_type: definition.fieldTypeString,
        deal_property_definition_property_type: definition.propertyTypeString,
        deal_property_definition_checkbox_option_ids: [],
      });
    }
  };

  return (
    <div className="flex gap-2">
      <select
        className="form-select border-c-border rounded text-base w-64 h-[44px]"
        onChange={onChange}
        value={searchCondition.deal_property_definition_id}>
        <option value="" />
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {searchCondition.deal_property_definition_field_type === 'select' && (
        <Select
          key={selectedOption?.id}
          definition={selectedOption}
          value={
            (searchCondition as SelectSearchCondition)
              .deal_property_definition_select_option_id
          }
          onChange={(value) =>
            setSearchCondition({
              ...searchCondition,
              deal_property_definition_select_option_id: value,
            })
          }
        />
      )}
      {searchCondition.deal_property_definition_field_type === 'checkbox' && (
        <Checkbox
          key={selectedOption?.id}
          definition={selectedOption}
          values={
            (searchCondition as MultiSelectSearchCondition)
              .deal_property_definition_checkbox_option_ids || []
          }
          onChange={(values) =>
            setSearchCondition({
              ...searchCondition,
              deal_property_definition_checkbox_option_ids: values,
            })
          }
        />
      )}
      <button
        onClick={onDelete}
        className="bg-white px-0 py-6 h-5 w-5 flex items-center justify-center rounded-full cursor-pointer">
        <XMarkIcon className="w-5 h-5 text-c-light" />
      </button>
    </div>
  );
};

export default Field;
