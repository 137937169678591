import React from 'react';
import styled from 'styled-components';
import {Content} from 'api';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    if (ref?.current) {
      const audio = ref.current as any;
      audio.volume = 0.2;
    }
  }, [ref]);

  return (
    <Container>
      <h4>{content.audio.fileName}</h4>
      <audio controls ref={ref} src={content.audio.file} />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-height: 600px;
  max-height: calc(100vh - 270px);
  display: flex;
  flex-direction: column;
  padding: 2rem;

  h4 {
    font-size: 1rem;
    font-weight: bold;
  }

  audio {
    width: 100%;
  }
`;
