import React from 'react';
import {Approach} from 'api';
import {Row} from 'react-table';
import {Link} from 'react-router-dom';

interface Props {
  row: Row<Approach>;
}

const Cell = ({row}: Props) => {
  const approach = row.original;

  if (approach.prospect) {
    return (
      <Link
        className="text-sm flex gap-2 truncate"
        to={`/approaches/${approach.uuid}/prospect/${approach.prospectPool.uuid}/approach${location.search}`}>
        {approach.prospect.lastName} {approach.prospect.firstName}
        <span className="text-c-lighter">
          {approach.prospect.account?.name}
        </span>
      </Link>
    );
  }

  if (approach.prelead) {
    return (
      <Link
        className="text-sm"
        to={`/approaches/${approach.uuid}/prelead/${approach.prelead.uuid}/approach${location.search}`}>
        {approach.prelead.name}
      </Link>
    );
  }

  return null;
};

export default Cell;
