import React from 'react';
import {Task} from 'api';
import styled from 'styled-components';
import {Route, Link, useParams} from 'react-router-dom';
import {Row} from 'react-table';
import Detail from '../detail';

interface Props {
  row: Row<Task>;
}

const TitleCell = ({row}: Props) => {
  const task = row.original;
  const {poolId} = useParams<{
    poolId: string;
  }>();

  return (
    <Container>
      <h4>
        <Link to={`/pools/${poolId}/action/tasks/${task.uuid}${location.search}`}>
          {task.title}
        </Link>
      </h4>
      <Route path={`/pools/:poolId/action/tasks/${task.uuid}`}>
        <Detail />
      </Route>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  h4 {
    font-size: 14px;
    font-weight: medium;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default TitleCell;
