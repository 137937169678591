import React from 'react';

function Icon() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.90562 12.7394L10.9999 18.3333L16.0942 12.7394C20.0014 8.44899 16.881 1.66666 10.9999 1.66666C5.1188 1.66666 1.99842 8.44899 5.90562 12.7394Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9998 10.8333C12.4142 10.8333 13.5608 9.71406 13.5608 8.33334C13.5608 6.95263 12.4142 5.83334 10.9998 5.83334C9.58543 5.83334 8.43884 6.95263 8.43884 8.33334C8.43884 9.71406 9.58543 10.8333 10.9998 10.8333Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
