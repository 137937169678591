import React from 'react';
import styled from 'styled-components';
import List from './List';
import Menu from '../Menu';

export default () => {
  return (
    <Body>
      <Menu />
      <List />
    </Body>
  );
};

const Body = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  background: #fff;
`;
