import gql from 'graphql-tag';

export default gql`
  fragment contentMailTemplateFragment on ContentMailTemplate {
    id
    uuid
    title
    body
    bodyHtml
    isShare
    createdAt
    updatedAt
  }
`;
