import React from 'react';
import {Approach, Prelead} from 'api';
import Name from './Name';
import Status from './Status';
import ApproachType from './ApproachType';
import Priority from './Priority';
import DueDate from './DueDate';
import Assignee from './Assignee';
import Modal from '../Modal';

interface Props {
  approach: Approach;
}

export default ({approach}: Props) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <div
        className="flex flex-col gap-1 py-4 border-b border-c-border cursor-pointer"
        onClick={() => setShowModal(true)}>
        <Name approach={approach} />
        <div className="flex items-center gap-2">
          <Status approach={approach} />
          <ApproachType approach={approach} />
          <Priority approach={approach} />
          <DueDate approach={approach} />
          <Assignee approach={approach} />
        </div>
      </div>
      <Modal
        visible={showModal}
        onClose={() => setShowModal(false)}
        approach={approach}
      />
    </>
  );
};
