import React from 'react';
import styled from 'styled-components';
import {Breadcrumb} from 'components/antd';
import Title from '../../Title';
import Detail from './detail';
import {Switch, Route} from 'react-router-dom';
import Filter from './Filter';
import Content from './Content';

export default () => {
  const [filterVisible, setFilterVisible] = React.useState(false);

  const toggleFilter = () => setFilterVisible(!filterVisible);

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>アクションボード</Breadcrumb.Item>
      </Breadcrumb>
      <Title title="アクションボード" />
      <Main>
        {filterVisible && <Filter toggleFilter={toggleFilter} />}
        <Content toggleFilter={toggleFilter} />
      </Main>
      <Switch>
        <Route path="/pools/:poolId/action/tasks/:taskId/prospect" />
        <Route path="/pools/:poolId/action/tasks/:taskId">
          <Detail />
        </Route>
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Main = styled.div`
  display: flex;
`;
