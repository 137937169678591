import {useCurrentMailCampaignContractQuery} from 'api';
import React from 'react';
import styled from 'styled-components';

export default ({title}: {title: string}) => {
  const {data: {currentMailCampaignContract = {}} = {}} =
    useCurrentMailCampaignContractQuery({
      fetchPolicy: 'cache-and-network',
    });

  const mailCampaignSendsUsage =
    currentMailCampaignContract?.mailCampaignSendsUsage || 0;
  const mailCampaignSendsLimit =
    currentMailCampaignContract?.mailCampaignSendsLimit || 0;

  return (
    <Container>
      <Title>{title}</Title>
      <ProgressbarContainer>
        <Meta>
          <Status>送信件数</Status>
          <ProgressNumber>
            {`${mailCampaignSendsUsage?.toLocaleString()} / ${mailCampaignSendsLimit?.toLocaleString()}`}
          </ProgressNumber>
        </Meta>
        <ProgressbarWrapper>
          <Progressbar
            width={(mailCampaignSendsUsage / mailCampaignSendsLimit) * 100}
            color="#8966EF"
          />
        </ProgressbarWrapper>
      </ProgressbarContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  margin: 1rem 0;
  font-size: 20px;
  font-weight: bold;
`;

const ProgressbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  margin-right: 18px;
  * {
    margin: auto 0;
  }
`;

const Meta = styled.div`
  display: flex;
  align-items: end;
`;

const Status = styled.div`
  margin-left: 2px;
  width: 60px;
  font-size: 12px;
  color: #495058;
`;

const ProgressNumber = styled.span`
  margin-left: auto;
  font-size: 12px;
  color: #495058;
`;

const ProgressbarWrapper = styled.span`
  margin-top: 5px;
  position: relative;
  height: 6px;
  background: #e2e6ea;
  border-radius: 10px;
`;

const Progressbar = styled.span<{
  width: number;
}>`
  position: absolute;
  width: ${({width}) => `${width}%`};
  height: 6px;
  background: #8966ef;
  border-radius: 10px;
`;
