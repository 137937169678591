declare global {
  interface Window {
    vapidPublicKey: string;
  }
}

const registerServiceWorker = () =>
  navigator.serviceWorker?.register('/serviceworker.js', {scope: '/'});

const updateServiceWorker = () =>
  navigator.serviceWorker
    ?.getRegistration()
    .then((registration) => registration?.update());

const getWebPushSubscription = () =>
  navigator.serviceWorker
    ?.getRegistration()
    .then((registration) => registration?.pushManager.getSubscription());

const subscribeWebPush = () =>
  navigator.serviceWorker?.getRegistration().then(async (registration) => {
    return registration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey,
      })
      .then((subscription) => subscription.toJSON());
  });

const unsubscribeWebPush = () =>
  navigator.serviceWorker?.getRegistration().then(async (registration) =>
    registration.pushManager.getSubscription().then(async (subscription) => {
      if (!subscription) {
        return;
      }
      return subscription.unsubscribe().then(() => subscription);
    }),
  );

export {
  registerServiceWorker,
  updateServiceWorker,
  getWebPushSubscription,
  subscribeWebPush,
  unsubscribeWebPush,
};
