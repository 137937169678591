import React from 'react';

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99976 11C8.76118 11 10.9998 8.76142 10.9998 6C10.9998 3.23858 8.76118 1 5.99976 1C3.23833 1 0.999756 3.23858 0.999756 6C0.999756 8.76142 3.23833 11 5.99976 11Z"
      stroke="#2693FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 1V6H11"
      stroke="#2693FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
