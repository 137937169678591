import React from 'react';
import {Link, useParams, useRouteMatch} from 'react-router-dom';

export default () => {
  const {collectedFormUrlId} = useParams<{
    collectedFormUrlId: string;
  }>();

  const match = useRouteMatch<{tab: string}>({
    path: '/form/collected_form_urls/:collectedFormUrlId/:tab',
  });

  const tab = match?.params?.tab || 'edit';

  return (
    <div className="flex items-center justify-center pt-4 border-b border-c-border">
      <ul className="font-bold flex flex-wrap -mb-px text-center">
        {/*
        <li className="mr-2">
          <Link
            to={`/form/collected_form_urls/${collectedFormUrlId}/collected_forms`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'collected_forms'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            受信
          </Link>
        </li>
        */}
        <li className="mr-2">
          <Link
            to={`/form/collected_form_urls/${collectedFormUrlId}/edit`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'edit'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            基本設定
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/collected_form_urls/${collectedFormUrlId}/fields`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'fields'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            フォーム項目
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/collected_form_urls/${collectedFormUrlId}/autoreply`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'autoreply'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            自動返信メール
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/collected_form_urls/${collectedFormUrlId}/notification`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'notification'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            通知メール
          </Link>
        </li>
      </ul>
    </div>
  );
};
