import React from 'react';
import styled from 'styled-components';
import {ProspectPool} from 'api';
import ProspectTimelineItem from 'components/ProspectTimelineItem';
import TaskEdit from 'components/Prospect/Task/EditModal';
import CallEdit from 'components/Prospect/Tel/Edit';
import CommentNew from 'components/Prospect/Comment/New';
import CommentEdit from 'components/Prospect/Comment/Edit';
import TaskCommentEdit from 'components/Prospect/TaskComment/Edit';
import {Refresh} from 'components/Ui/Icon';
import {Tel as TelIcon} from '../Workflow/Icon';
import {Link, useRouteMatch} from 'react-router-dom';

interface Props {
  prospectPool: ProspectPool;
  loading: boolean;
  refetch: () => void;
}

export default ({prospectPool, loading, refetch}: Props) => {
  const [isTaskEdit, setIsTaskEdit] = React.useState(false);
  const [isCallEdit, setIsCallEdit] = React.useState(false);
  const [task, setTask] = React.useState(null);
  const [call, setCall] = React.useState(null);
  const [isCommentNew, setIsCommentNew] = React.useState(false);
  const [isCommentEdit, setIsCommentEdit] = React.useState(false);
  const [comment, setComment] = React.useState(null);
  const [isTaskCommentEdit, setIsTaskCommentEdit] = React.useState(false);
  const [taskComment, setTaskComment] = React.useState(null);
  const {url} = useRouteMatch();
  const telTabUrl = url.replace(/timeline$/, 'tel');

  if (!prospectPool) return null;

  const List = () => (
    <>
      <div className="flex items-center justify-end gap-2">
        <div className="mr-auto">
          <Link
            to={`${telTabUrl}${location.search}`}
            className="flex items-center justify-center border border-[#E1E6EB] rounded w-8 h-8 [&>svg]:p-1">
            <TelIcon />
          </Link>
        </div>

        <button
          onClick={() => refetch()}
          disabled={loading}
          className="ant-btn">
          <Refresh spin={loading} />
        </button>
        <div className="ant-btn" onClick={() => setIsCommentNew(true)}>
          + コメントを登録
        </div>
      </div>
      <Timeline>
        {prospectPool?.prospectActivities?.map((prospectActivity) => (
          <ProspectTimelineItem
            key={prospectActivity.uuid}
            prospectPool={prospectPool}
            prospectActivity={prospectActivity}
            setIsTaskEdit={setIsTaskEdit}
            setTask={setTask}
            setIsCallEdit={setIsCallEdit}
            setCall={setCall}
            setIsCommentEdit={setIsCommentEdit}
            setComment={setComment}
            setIsTaskCommentEdit={setIsTaskCommentEdit}
            setTaskComment={setTaskComment}
          />
        ))}
      </Timeline>
    </>
  );

  return isTaskEdit ? (
    <TaskEdit
      visible={isTaskEdit}
      onClose={() => setIsTaskEdit(false)}
      task={task}
    />
  ) : isCallEdit ? (
    <CallEdit setIsCallEdit={setIsCallEdit} call={call} />
  ) : isCommentNew ? (
    <CommentNew setIsCommentNew={setIsCommentNew} prospectPool={prospectPool} />
  ) : isCommentEdit ? (
    <CommentEdit setIsCommentEdit={setIsCommentEdit} comment={comment} />
  ) : isTaskCommentEdit ? (
    <TaskCommentEdit
      setIsTaskCommentEdit={setIsTaskCommentEdit}
      taskComment={taskComment}
    />
  ) : (
    <List />
  );
};

const Timeline = styled.div`
  margin: 15px 0;
`;
