import gql from 'graphql-tag';

export default gql`
  fragment hubspotDealFragment on HubspotDeal {
    id
    hubspotId
    dealname
    hubspotUrl
    dealstage
    amount
    properties
  }
`;
