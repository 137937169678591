import React from 'react';
import {SalesforceDealField} from 'api';
import {SelectItem as Select} from './Select';
import {MultiSelectItem as MultiSelect} from './MultiSelect';
import {Checkbox, Date, Reference, Text} from '../FieldItem';

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
  field: SalesforceDealField;
}

export const Field = ({addition, setAddition, field}: Props) => {
  switch (field.fieldType) {
    case 'checkbox':
      return (
        <Checkbox addition={addition} setAddition={setAddition} field={field} />
      );
    case 'date':
      return (
        <Date addition={addition} setAddition={setAddition} field={field} />
      );
    case 'multi_select':
      return (
        <MultiSelect
          addition={addition}
          setAddition={setAddition}
          field={field}
        />
      );
    case 'select':
      return (
        <Select addition={addition} setAddition={setAddition} field={field} />
      );
    case 'reference':
      return (
        <Reference
          addition={addition}
          setAddition={setAddition}
          field={field}
        />
      );
    default:
      return (
        <Text addition={addition} setAddition={setAddition} field={field} />
      );
  }
};
