import React from 'react';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  isCustomDisplay?: boolean;
  isSubDisplay?: boolean;
  padding?: number;
  children: React.ReactNode;
  onClose: () => void;
  subClose?: () => void;
  width: number;
  style?: React.CSSProperties;
};

export default (props: Props) => {
  return (
    <>
      <Overlay isOpen={props.isOpen} onClick={props.onClose} />
      <DrawerModal {...props}>
        <div style={{display: props.isCustomDisplay ? 'flex' : 'block'}}>
          {props.isCustomDisplay && (
            <Group>
              <CustomButton onClick={props.onClose}> ×</CustomButton>
              <CustomButton onClick={props.subClose}>
                {props.isSubDisplay ? '>' : '<'}
              </CustomButton>
            </Group>
          )}
          <DrawerModalChild>{props.children}</DrawerModalChild>
        </div>
      </DrawerModal>
    </>
  );
};

const DrawerModal = styled.div<Props>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: ${({isOpen, width}: Props) => (isOpen ? width : 0)}px;
  transition: 200ms cubic-bezier(0.25, 0.1, 0.24, 1);
`;

const DrawerModalChild = styled.div`
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  height: 100vh;
`;

const Group = styled.div`
  display: inline-block;
  height: 60px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

const CustomButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: #bdc4cb;
  border-bottom: 1px solid #f3f5f7;
`;

const Overlay = styled.div<{isOpen: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props: {isOpen: boolean}) => (props.isOpen ? 0 : -1)};
`;
