import React from 'react';
import useChartData from './useChartData';
import {Chart} from '../../VerticalBarChart';
import DashboardItemContainer from '../../../Container';

export default () => {
  const chart = useChartData();

  return (
    <DashboardItemContainer
      title="ToDo分析"
      subTitle="月別"
      tags={['担当者別', '過去6ヶ月']}>
      <Chart chart={chart} legend={false} className="h-full w-full" />
    </DashboardItemContainer>
  );
};
