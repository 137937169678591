import {Button, Input, Popover} from 'antd';
import {
  PoolProspectCustomizeItem,
  Prospect,
  useCreateProspectCustomizeItemSelectMutation,
  useUpdateProspectCustomizeItemSelectPositionMutation,
} from 'api';
import {Handle, MoreVertical} from 'components/Ui/Icon';
import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import SelectEdit from './SelectEdit';
import useClientUser from 'hooks/useClientUser';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

interface Props {
  prospect: Prospect;
  poolProspectCustomizeItem: PoolProspectCustomizeItem;
  optionName: string;
  setOptionName: React.Dispatch<React.SetStateAction<string>>;
}

const SelectOption = ({
  prospect,
  poolProspectCustomizeItem,
  optionName,
  setOptionName,
}: Props) => {
  const {isAdmin} = useClientUser();
  const [createCustomizeItem] = useCreateProspectCustomizeItemSelectMutation();
  const [isEditPopoverVisible, setIsEditPopoverVisible] = useState(null);

  const customizeItem = prospect.customizeItemSelects.find(
    (item) => item.poolProspectCustomizeItemId === poolProspectCustomizeItem.id,
  );

  const options = customizeItem
    ? poolProspectCustomizeItem.selectOptions.filter(
        (option) =>
          customizeItem?.poolProspectCustomizeItemSelectOptionIds.includes(
            option.id,
          ),
      )
    : [];

  const filteredTags = useMemo(
    () =>
      poolProspectCustomizeItem?.selectOptions?.filter((tag) =>
        kanaToHira(tag.label).includes(kanaToHira(optionName)),
      ),
    [
      optionName,
      poolProspectCustomizeItem,
      customizeItem?.prospectCustomizeItemSelectOptions,
    ],
  );

  const alreadyNameExist = useMemo(
    () =>
      poolProspectCustomizeItem.selectOptions?.some(
        (option) => option.label === optionName,
      ),
    [poolProspectCustomizeItem.selectOptions, optionName],
  );

  const onChange = useCallback((e: any) => setOptionName(e.target.value), []);

  const onCreate = (optionLabel: string) => {
    createCustomizeItem({
      variables: {
        uuid: prospect.uuid,
        poolProspectCustomizeItemId: poolProspectCustomizeItem.id,
        value: optionLabel,
      },
      refetchQueries: ['poolProspectCustomizeItems'],
    });
  };

  const alreadyAdded = useCallback(
    (option) => options.some((selectOption) => selectOption.id === option.id),
    [poolProspectCustomizeItem?.selectOptions, options],
  );

  const [updateProspectCustomizeItemPosition] =
    useUpdateProspectCustomizeItemSelectPositionMutation();

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...filteredTags];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      updateProspectCustomizeItemPosition({
        variables: {
          poolCustomizeItemSelectOptionId: removed.id,
          position: destIndex,
        },
      });
    },
    [filteredTags],
  );

  return (
    <Container>
      <PaddingWrapper>
        <h3>{isAdmin ? '項目を選択または新規作成' : '項目を選択'}</h3>
        <InputBox style={{border: '1px solid #e1e6eb'}}>
          <InputWrapper>
            <TagInput
              type="search"
              name="search"
              bordered={false}
              placeholder={optionName}
              defaultValue={''}
              value={optionName}
              onChange={onChange}
              onPressEnter={(e) => {
                if (isAdmin) {
                  onCreate(optionName);
                  setOptionName('');
                } else {
                  e.preventDefault();
                  setOptionName('');
                }
              }}
            />
          </InputWrapper>
        </InputBox>
      </PaddingWrapper>

      <ScrollWrapper>
        <List>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredTags?.map((option, index) => (
                    <Draggable
                      key={option.id}
                      draggableId={option.id}
                      index={index}>
                      {(provided, snapshot) => (
                        <DraggableItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={snapshot.isDragging ? 'dragging' : ''}>
                          <HandleWrapper {...provided.dragHandleProps}>
                            <Handle />
                          </HandleWrapper>
                          <Tag
                            key={option.id}
                            onClick={() => {
                              onCreate(option.label);
                              setOptionName('');
                            }}
                            added={alreadyAdded(option)}>
                            <div>
                              <TagName>{option.label}</TagName>
                              <Popover
                                content={() => (
                                  <SelectEdit
                                    option={option}
                                    prospect={prospect}
                                    poolProspectCustomizeItem={
                                      poolProspectCustomizeItem
                                    }
                                  />
                                )}
                                trigger="click"
                                visible={isEditPopoverVisible === option.id}
                                onVisibleChange={(visible) =>
                                  setIsEditPopoverVisible(
                                    visible ? option.id : null,
                                  )
                                }
                                placement="bottomRight">
                                <Button
                                  type="text"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}>
                                  <MoreVertical />
                                </Button>
                              </Popover>
                            </div>
                          </Tag>
                        </DraggableItem>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </ScrollWrapper>
      {isAdmin && optionName && !alreadyNameExist && (
        <NewTag>
          <h3>新規作成</h3>
          <Tag
            onClick={() => {
              onCreate(optionName);
              setOptionName('');
            }}>
            <div>
              <TagName>{optionName}</TagName>
            </div>
          </Tag>
        </NewTag>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  h3 {
    font-size: 10px;
    color: #899098;
  }
`;

const PaddingWrapper = styled.div`
  padding: 10px 8px 0 8px;
`;

const InputBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 11px;
  width: 100%;
  min-height: 34px;
  padding: 6px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  &:hover {
    border: 1px solid #e1e6eb;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  width: auto;
  background: transparent;
  font-size: inherit;
  line-height: 20px;
  flex: 1 1 60px;
  min-width: 60px;
`;

const TagInput = styled(Input)`
  border: none;
  padding: 0px;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: #bdc4cb;
    }
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;

const ScrollWrapper = styled.div`
  max-height: 200px;
  overflow: scroll;
  margin-top: 10px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NewTag = styled.div`
  border-top: solid 1px #e1e6eb;
  padding-top: 4px;

  h3 {
    margin-bottom: 0px;
    padding: 0px 11px;
  }
`;

const TagName = styled.div`
  font-size: 14px;
  color: #495058;
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Tag = styled.div<{added?: boolean}>`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #f2f7fa;
  }

  background: ${({added}) => (added ? '#f2f7fa' : '#fff')};

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    padding: 0px 11px;

    ${TagName} {
      margin-left: 7px;
    }

    > button {
      margin-left: auto;
      padding: 0;
      svg {
        transform: rotate(90deg);
        width: 1rem;
        height: 1rem;
        stroke: #899098;
        visibility: visible;
      }
    }
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;

const DraggableItem = styled.div`
  border: none;
  min-height: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: solid 3px #fff;
  cursor: pointer;

  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    border-left: solid 3px #68b4fb;
  }
`;
export default SelectOption;
