import React, {useState} from 'react';
import styled from 'styled-components';
import {FileBlock} from 'components/Ui/Icon';
import Modal from 'react-modal';
import ModalContent from './ModalContent';

export default () => {
  const [IsOpen, setModalVisible] = useState(false);

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0)',
      zIndex: 100,
    },
    content: {
      top: '400px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '530px',
      height: '560px',
      padding: '0',
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
      borderRadius: '5px',
      overflow: 'hidden',
    },
  };

  return (
    <Container>
      <div onClick={() => setModalVisible(true)}>
        <FileBlock />
        ブロックグループ
      </div>

      <Modal
        isOpen={IsOpen}
        onRequestClose={() => {
          setModalVisible(false);
        }}
        style={customStyles}>
        <ModalContent setModalVisible={setModalVisible} />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  div:first-child {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }
  }
`;
