import gql from 'graphql-tag';

export default gql`
  fragment formPostOtherDateFragment on FormPostOtherDate {
    id
    value
    formBlockFieldOtherId
    createdAt
    updatedAt
  }
`;
