import React from 'react';
import styled from 'styled-components';
import Toolbar from './Toolbar';
import Mail from './Mail';

export default () => (
  <Container>
    <Toolbar />
    <Mail />
  </Container>
);

const Container = styled.div`
  margin-top: 25px;
  background: #fff;
  display: flex;
`;
