import React from 'react';
import moment from 'moment';
import {ProspectActivity, HubspotEmailProspectActivity} from 'api';
import {ManualMail, Hubspot} from 'components/Ui/Icon';

interface HubspotEmailActivity extends ProspectActivity {
  resource: HubspotEmailProspectActivity;
}

interface Props {
  prospectActivity: HubspotEmailActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      subject,
      from,
      to,
      bodyHtml,
      stageText,
      recordedAt,
      hubspotContactIdString,
    },
    user,
    action,
  } = prospectActivity;

  const [showAll, setShowAll] = React.useState(false);

  const isEmailLog = !from;

  return (
    <>
      <ManualMail active={true} width="32" height="32" />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-2 min-h-[32px]">
          {hubspotContactIdString && (
            <span className="bg-[#FFDBD5] h-5 px-2 text-xs text-[#FF7A59] rounded-sm flex items-center gap-1 [&>svg]:h-3 [&>svg]:w-auto">
              <Hubspot />
              コンタクト
            </span>
          )}
          <h3 className="flex-1 font-bold m-0">{action?.step?.name}</h3>
          <span className="text-c-light">
            {user && [user.lastName, user.firstName].join(' ')}
          </span>
          <time className="text-c-light">
            {moment(recordedAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </div>
        {isEmailLog ? (
          <div className="border border-c-border rounded p-4">
            <div dangerouslySetInnerHTML={{__html: bodyHtml}} />
          </div>
        ) : (
          <div className="border border-c-border rounded p-4">
            <div className="font-medium text-base mb-4">
              {subject || 'タイトルなし'}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <span className="bg-[#dceeff] text-xs text-[#2d9cdb] leading-5 px-4 rounded-sm">
                {stageText}
              </span>
              <button
                className="text-[#68b5fb] font-medium text-base cursor-pointer bg-transparent p-0"
                onClick={() => setShowAll(!showAll)}>
                {showAll ? '閉じる' : '内容を表示'}
              </button>
            </div>

            {showAll && (
              <div className="mt-4 [&_p]:m-0 [&_img]:max-w-full">
                <div className="border-y border-y-c-border py-2 mb-4">
                  <div className="mt-2">
                    <span className="inline-block text-c-light w-12 float-left">
                      From
                    </span>
                    <span className="block ml-12">{from}</span>
                  </div>
                  <div className="mt-2">
                    <span className="inline-block text-c-light w-12 float-left">
                      To
                    </span>
                    <span className="block ml-12">{to}</span>
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{__html: bodyHtml}} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
