import gql from 'graphql-tag';
import clientPhaseProspectFragment from '../client/phaseProspect';

export default gql`
  fragment clientPhaseProspectChangeProspectActivityFragment on ClientPhaseProspectChangeProspectActivity {
    clientPhaseProspect {
      ...clientPhaseProspectFragment
    }
  }
  ${clientPhaseProspectFragment}
`;
