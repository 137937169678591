import React from 'react';
import {useParams} from 'react-router-dom';
import {useMailThreadQuery} from 'api';
import GmailImport from './GmailImport';
import ManualMail from './ManualMail';
import ManualMailAction from './ManualMailAction';
import AutomaticMailSend from './AutomaticMailSend';

const mailThreadComponents: {[key: string]: any} = {
  GmailImportMailThread: GmailImport,
  ManualMailMailThread: ManualMail,
  ManualMailActionMailThread: ManualMailAction,
  AutomaticMailSendMailThread: AutomaticMailSend,
};

export default () => {
  const {threadId} = useParams<{threadId: string}>();

  const {data: {mailThread = {}} = {}} = useMailThreadQuery({
    variables: {
      uuid: threadId,
    },
    skip: !threadId,
    fetchPolicy: 'cache-and-network',
  });

  const MailThreadComponent = mailThread
    ? mailThreadComponents[mailThread.threadable?.__typename]
    : null;

  if (!MailThreadComponent) {
    return null;
  }

  return <MailThreadComponent mailThread={mailThread} />;
};
