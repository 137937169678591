import React from 'react';
import useSearchParams from '../../../../useSearchParams';
import Header from '../../Header';
import Footer from '../Footer';
import RecordType from './RecordType';
import CustomizeItem from './CustomizeItem';
import User from './User';

const FormFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="Salesforce商談" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <RecordType />
          <User />
          <CustomizeItem />
        </div>
      </div>
      <Footer
        onClear={() =>
          setQuery({
            ...query,
            salesforceDealRecordTypeId: '',
            salesforceDealUserId: '',
            salesforceDealSearchConditions: [],
          })
        }
      />
    </>
  );
};

export default FormFilter;
