import React from 'react';

function Icon() {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="73" height="73" rx="4" fill="#F3EFFF" />
      <g clipPath="url(#clip-dimension)">
        <path
          d="M32.4647 35.5653L19.3147 42.3899C17.885 43.1317 17.8986 45.1818 19.3378 45.9045L32.5683 52.5494C35.0425 53.7919 37.9581 53.7919 40.4324 52.5494L53.6626 45.9045C55.102 45.1815 55.1157 43.1317 53.686 42.3899L40.5357 35.5653C38.0057 34.2523 34.9949 34.2523 32.4647 35.5653Z"
          fill="#8966EF"
        />
        <path
          d="M32.4647 28.0079L19.3147 34.8328C17.885 35.5746 17.8986 37.6245 19.3378 38.3474L32.5683 44.9923C35.0425 46.2348 37.9581 46.2348 40.4324 44.9923L53.6626 38.3474C55.102 37.6245 55.1157 35.5746 53.686 34.8328L40.5357 28.0082C38.0057 26.6952 34.9949 26.6952 32.4647 28.0079Z"
          fill="#C0AAFF"
        />
        <path
          d="M40.4335 44.9894L49.7144 40.328L40.5368 35.5653C38.0068 34.2523 34.996 34.2523 32.4661 35.5653L23.2885 40.328L32.5694 44.9894C35.0436 46.2319 37.9592 46.2319 40.4335 44.9894Z"
          fill="#A180FE"
        />
        <path
          d="M32.4647 20.4432L19.3147 27.2678C17.885 28.0099 17.8986 30.0597 19.3378 30.7827L32.5683 37.4276C35.0425 38.6701 37.9581 38.6701 40.4324 37.4276L53.6626 30.7827C55.102 30.0597 55.1157 28.0099 53.686 27.2678L40.5357 20.4432C38.0057 19.1302 34.9949 19.1302 32.4647 20.4432Z"
          fill="#DACDFF"
        />
        <path
          d="M40.4335 37.4311L49.7144 32.7697L40.5368 28.0067C38.0068 26.6937 34.996 26.6937 32.4661 28.0067L23.2885 32.7697L32.5694 37.4311C35.0436 38.6736 37.9592 38.6736 40.4335 37.4311Z"
          fill="#CBB8FF"
        />
        <path
          d="M40.4335 37.4294L42.3115 36.4862L40.5368 35.5653C38.0069 34.2523 34.996 34.2523 32.4658 35.5653L30.6914 36.4862L32.5694 37.4294C35.0434 38.672 37.9593 38.672 40.4335 37.4294Z"
          fill="#C0AAFF"
        />
      </g>
      <defs>
        <clipPath id="clip-dimension">
          <rect
            width="36.5"
            height="36.5"
            fill="white"
            transform="translate(18.25 18.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
