import React from 'react';
import {useParams} from 'react-router-dom';
import {useMailCampaignQuery} from 'api';
import Tab from './Tab';
import Arrow from './Arrow';

export default () => {
  const {mailCampaignId} = useParams<{
    poolId: string;
    mailCampaignId: string;
  }>();

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
    fetchPolicy: 'cache-only',
  });

  if (!mailCampaign) return null;

  return (
    <div className="flex justify-between items-center border-b border-c-border">
      <span className="inline-block bg-c-bg px-2 py-1 rounded text-c-light text-sm">
        リード情報
      </span>
      {mailCampaign.status === 'draft' ? (
        <div className="flex gap-8">
          <Tab
            title="1.メール本文編集"
            to={`/mail_campaigns/${mailCampaignId}/mail${location.search}`}
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/mail`,
            )}
          />
          <Arrow />
          <Tab
            title="2.送信先設定"
            to={`/mail_campaigns/${mailCampaignId}/recipients${location.search}`}
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/recipients`,
            )}
          />
          <Arrow />
          <Tab
            title="3.送信設定"
            to={`/mail_campaigns/${mailCampaignId}/settings${location.search}`}
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/settings`,
            )}
          />
        </div>
      ) : (
        <div className="flex items-center gap-8">
          <Tab
            title="送信結果"
            to={`/mail_campaigns/${mailCampaignId}/results${location.search}`}
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/results`,
            )}
          />
          <Tab
            title="メール本文"
            to={`/mail_campaigns/${mailCampaignId}/mail${location.search}`}
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/mail`,
            )}
          />
          <Tab
            title="送信先設定"
            to={`/mail_campaigns/${mailCampaignId}/recipients${location.search}`}
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/recipients`,
            )}
          />
          <Tab
            title="送信設定"
            to={`/mail_campaigns/${mailCampaignId}/settings${location.search}`}
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/settings`,
            )}
          />
        </div>
      )}
      <div />
    </div>
  );
};
