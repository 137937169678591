import React from 'react';
import {PreleadProject} from 'api';
import moment from 'moment';

interface Props {
  row: any;
}

const MailCampaignCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;

  return (
    <>
      {preleadProject.mailCampaignSentDate &&
        moment(preleadProject.mailCampaignSentDate).format('YYYY年MM月DD日')}
    </>
  );
};

export default MailCampaignCell;
