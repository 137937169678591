import styled from 'styled-components';

const Container = styled.div`
  margin-left: -30px;
  position: relative;
  padding: 15px 15px 10px;
  border: 1px solid #e2e6ea;
  border-radius: 4px;
  background: #ffffff;
`;

const Project = styled.span`
  display: block;
  margin-bottom: 15px;
  font-size: 8px;
  color: #899098;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const MenuButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  > Button {
    margin: 0px 8px 0px 5px;
  }
`;

const StatusLabel = styled.span<{color: string}>`
  background-color: ${({color}) => color};
  border-radius: 20px;
  margin: 0 12px;
  padding: 0 16px;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  min-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > span:nth-child(2) {
    margin: 0 9px;
  }
`;

const TimeStamp = styled.span`
  margin-left: auto;
  width: 65px;
`;

const EditorWrapper = styled.div`
  margin: 10px 0px;
  color: rgba(0, 0, 0, 0.85);
`;

const CommentBody = styled.div`
  margin-top: 15px;
  color: #495058;
`;

const CommentEditBox = styled.div`
  margin-top: 20px;
  display: flex;

  svg {
    margin-right: 12px;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

export {
  Container,
  Project,
  Summary,
  BadgeWrapper,
  MenuButtonWrapper,
  StatusLabel,
  TimeStamp,
  EditorWrapper,
  CommentBody,
  CommentEditBox,
};
