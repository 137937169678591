import React, {Fragment, useState} from 'react';
import {Dialog, Transition, Listbox, Combobox} from '@headlessui/react';
import {useFormik} from 'formik';
import {DatePicker} from 'components/antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import * as Yup from 'yup';
import {
  usePoolQuery,
  useCreateProspectFromPreleadMutation,
  useUsersByClientAllQuery,
  useClientPhaseProspectsQuery,
  User,
} from 'api';
import {getTagFontColor} from 'helpers/tagColors';
import {XMarkIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import usePreleadContext from '../usePreleadContext';
import moment from 'moment';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const CreateProspectModal = ({isOpen, closeModal}: Props) => {
  const {preleadId} = usePreleadContext();
  const handleClose = () => {
    setTags([]);
    setUser(null);
    setUserName('');
    closeModal();
  };

  const {data: {pool: {prospectTags = [], leadSources = []} = {}} = {}} =
    usePoolQuery({});
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });
  const [tags, setTags] = useState([]);
  const [user, setUser] = useState<User>();
  const [userName, setUserName] = useState('');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const filteredUsers =
    userName === ''
      ? users
      : users?.filter((user) => {
          return `${user?.lastName} ${user?.firstName}`
            ?.toLowerCase()
            ?.includes(userName?.toLowerCase());
        });

  const [createProspect, {loading}] = useCreateProspectFromPreleadMutation({});

  const {data: {clientPhaseProspects = []} = {}} =
    useClientPhaseProspectsQuery();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      firstName: null,
      lastName: null,
      email: null,
      telephoneNumber: null,
      leadSourceId: null,
      section: null,
      sectionPosition: null,
      tagIds: [],
      assigneeId: null,
      inflowDate: moment().format('YYYY/MM/DD'),
      clientPhaseProspectId: null,
    },
    validationSchema: Yup.object().shape({
      lastName: Yup.string().required('必須項目です'),
      leadSourceId: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values, {resetForm}) => {
      createProspect({
        refetchQueries: ['prospectPoolsByPrelead', 'prelead'],
        variables: {
          preleadId: preleadId,
          attributes: {
            ...values,
            tagIds: tags,
          },
        },
      }).then(() => {
        resetForm();
        handleClose();
      });
    },
  });

  const filteredTags = React.useMemo(
    () => prospectTags.filter((tag) => !tags.includes(tag.id)),
    [tags, prospectTags],
  );

  const selectedTags = React.useMemo(
    () =>
      tags
        .map((tag) =>
          prospectTags.find((prospectTag) => prospectTag.id === tag),
        )
        .filter((tag) => tag),
    [tags, prospectTags],
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-lg transform rounded bg-white text-left align-middle transition-all py-10 px-14">
                <form onSubmit={formik.handleSubmit}>
                  <Dialog.Title
                    as="h1"
                    className="h-10 flex items-center px-4 text-c-base rounded-t font-bold text-[18px]">
                    リード登録
                  </Dialog.Title>
                  <div className="p-4 flex flex-col gap-4 mb-4">
                    <div className="grid grid-cols-1 gap-6">
                      <div className="flex flex-row gap-5">
                        <label className="block ">
                          <span>
                            <span className="inline-block mr-1 text-sm text-[#ff4d4f] leading-none">
                              *
                            </span>
                            姓
                          </span>
                          <input
                            type="text"
                            className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                            placeholder=""
                            {...formik.getFieldProps('lastName')}
                          />
                        </label>
                        <label className="block">
                          <span>名</span>
                          <input
                            type="text"
                            className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                            placeholder=""
                            {...formik.getFieldProps('firstName')}
                          />
                        </label>
                      </div>
                      <label className="block">
                        <span>メールアドレス</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('email')}
                        />
                      </label>
                      <label className="block">
                        <span>
                          <span className="inline-block mr-1 text-sm text-[#ff4d4f] leading-none">
                            *
                          </span>
                          リードソース
                        </span>
                        <select
                          {...formik.getFieldProps('leadSourceId')}
                          className="form-select text-sm block w-full mt-1 rounded border-c-border shadow-sm">
                          <option value="">選択してください</option>
                          {leadSources.map((leadSource) => (
                            <option key={leadSource.id} value={leadSource.id}>
                              {leadSource.name}
                            </option>
                          ))}
                        </select>
                      </label>
                      <label className="block">
                        <span>流入日</span>
                        <DatePicker
                          className="mt-1 py-3 block w-full rounded border-c-border shadow-sm"
                          format={'YYYY/MM/DD'}
                          locale={locale}
                          value={
                            moment(formik.values.inflowDate, 'YYYY/MM/DD') ||
                            null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              'inflowDate',
                              value.format('YYYY/MM/DD'),
                            )
                          }
                        />
                      </label>
                      <label className="block">
                        <span>フェーズ</span>
                        <select
                          {...formik.getFieldProps('clientPhaseProspectId')}
                          className="form-select text-sm block w-full mt-1 rounded border-c-border shadow-sm">
                          <option value="">選択してください</option>
                          {clientPhaseProspects.map((phase) => (
                            <option key={phase.id} value={phase.id}>
                              {phase.name}
                            </option>
                          ))}
                        </select>
                      </label>
                      <label className="block">
                        <span>電話</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('telephoneNumber')}
                        />
                      </label>
                      <label className="block">
                        <span>部署名</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('section')}
                        />
                      </label>
                      <label className="block">
                        <span>役職</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('sectionPosition')}
                        />
                      </label>
                      <label className="block">
                        <span>タグ</span>
                        <Listbox
                          value={tags}
                          onChange={(values) => {
                            setTags(values);
                          }}
                          multiple>
                          <div className="relative mt-1">
                            <Listbox.Button className="relative w-full min-h-[2.5rem] cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap">
                              {selectedTags.map((tag) => (
                                <span
                                  key={tag.id}
                                  className="truncate inline-flex items-center justify-center pl-2 pr-1 py-1 text-sm font-medium leading-4 rounded-sm"
                                  style={{
                                    backgroundColor: tag.color || 'black',
                                    color: getTagFontColor(tag.color),
                                  }}>
                                  {tag.name}
                                  <XMarkIcon
                                    className="w-4 h-4 ml-2 cursor-pointer hover:bg-[rgba(255,255,255,.3)] rounded-sm"
                                    onClick={() =>
                                      setTags(
                                        tags.filter(
                                          (selectedTag) =>
                                            selectedTag !== tag.id,
                                        ),
                                      )
                                    }
                                  />
                                </span>
                              ))}
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={React.Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0">
                              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                                {filteredTags.map((tag) => (
                                  <Listbox.Option
                                    key={tag.id}
                                    className={({active}) =>
                                      `relative cursor-default select-none py-2 px-4 ${
                                        active ? 'bg-c-bg' : 'white'
                                      }`
                                    }
                                    value={tag.id}>
                                    <div className="flex items-center gap-2">
                                      <span
                                        className="h-3 w-3 rounded-sm bg"
                                        style={{
                                          backgroundColor: tag.color || 'black',
                                        }}
                                      />
                                      <span className="truncate flex-1">
                                        {tag.name}
                                      </span>
                                    </div>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </label>
                      <label className="block">
                        <span>担当者</span>
                        <Combobox
                          value={user}
                          onChange={(user) => {
                            setUser(user);
                            formik.setFieldValue('assigneeId', user.id);
                          }}>
                          <Combobox.Button as={React.Fragment}>
                            <div
                              className="relative mt-1 h-[2.5rem]"
                              onClick={() => inputRef.current?.focus()}>
                              <Combobox.Input
                                onChange={(e) => setUserName(e.target.value)}
                                onFocus={() => buttonRef.current?.click()}
                                value={userName}
                                displayValue={(user: User) =>
                                  user && `${user.lastName} ${user.firstName}`
                                }
                                ref={inputRef}
                                className="relative w-full cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap"
                              />
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                              <Transition
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  <Combobox.Option
                                    className={({active}) =>
                                      `relative cursor-default select-none py-2 px-4 ${
                                        active ? 'bg-c-bg' : 'white'
                                      }`
                                    }
                                    value={(): any => {
                                      setUser(null);
                                      formik.setFieldValue('assigneeId', null);
                                    }}>
                                    <div className="flex items-center gap-2">
                                      <span className="truncate flex-1 h-5" />
                                    </div>
                                  </Combobox.Option>
                                  {filteredUsers.map((user) => (
                                    <Combobox.Option
                                      key={user.id}
                                      className={({active}) =>
                                        `relative cursor-default select-none py-2 px-4 ${
                                          active ? 'bg-c-bg' : 'white'
                                        }`
                                      }
                                      value={user}>
                                      <div className="flex items-center gap-2">
                                        <span className="truncate flex-1">
                                          {user.lastName} {user.firstName}
                                        </span>
                                      </div>
                                    </Combobox.Option>
                                  ))}
                                </Combobox.Options>
                              </Transition>
                            </div>
                          </Combobox.Button>
                        </Combobox>
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-4 gap-2">
                    <button
                      type="submit"
                      disabled={loading || !formik.isValid}
                      className="text-white bg-c-primary hover:opacity-50 rounded-sm py-2 px-4 cursor-pointer disabled:bg-c-bg disabled:text-c-light">
                      登録
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateProspectModal;
