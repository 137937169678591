import React from 'react';
import styled from 'styled-components';
import {StringParam, useQueryParam} from 'use-query-params';
import useSearchParams from './useSearchParams';
import useAllProjects from './useAllProjects';

export default () => {
  const [status, setStatus] = useQueryParam('status', StringParam);
  const {setQuery} = useSearchParams();
  const {openProjectsCount, draftProjectsCount, archivedProjectsCount} =
    useAllProjects();

  return (
    <Tabs>
      <TabItem
        active={status === 'open'}
        onClick={() => {
          if (status !== 'open') {
            setStatus('open');
            setQuery({page: 1});
          }
        }}>
        <div>
          <Name>Open</Name>
          <Count>{openProjectsCount}</Count>
        </div>
      </TabItem>
      <TabItem
        active={status === 'draft'}
        onClick={() => {
          if (status !== 'draft') {
            setStatus('draft');
            setQuery({page: 1});
          }
        }}>
        <div>
          <Name>Draft</Name>
          <Count>{draftProjectsCount}</Count>
        </div>
      </TabItem>
      <TabItem
        active={status === 'archived'}
        onClick={() => {
          if (status !== 'archived') {
            setStatus('archived');
            setQuery({page: 1});
          }
        }}>
        <div>
          <Name>アーカイブ</Name>
          <Count>{archivedProjectsCount}</Count>
        </div>
      </TabItem>
    </Tabs>
  );
};

const Tabs = styled.div`
  margin-bottom: 16.5px;
  display: flex;
  height: 56px;
  border-bottom: 1px solid #dadce0;
`;

const TabItem = styled.div<{active?: boolean}>`
  cursor: pointer;
  display: flex;
  width: 165px;
  height: 56px;
  border-bottom: ${({active}) => (active ? '4px solid #8966EF' : '')};
  a {
    color: ${({active}) => (active ? '#222426' : '#BDC4CB')};
  }

  > div {
    margin: auto;
    display: flex;
    align-items: center;
  }
`;

const Name = styled.a`
  margin-right: 5px;
  height: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #495058;
`;

const Count = styled.span`
  width: 30px;
  height: 15px;
  background: #e2e6ea;
  border-radius: 100px;
  font-weight: bold;
  font-size: 8px;
  text-align: center;
  color: #899098;
`;
