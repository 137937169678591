import React from 'react';
import {Switch, Route} from 'react-router-dom';
import styled from 'styled-components';
import TeamList from './TeamList';
import TeamContainer from './detail';
import Menu from '../Menu';

export default () => {
  return (
    <Body>
      <Menu />
      <Switch>
        <Route path="/settings/teams/:teamId" component={TeamContainer} />
        <Route path="/settings/teams" component={TeamList} />
      </Switch>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #fff;
  height: calc(100vh - 60px);
`;
