import {ProspectPool} from 'api';
import React from 'react';
import {Row} from 'react-table';

interface Props {
  row: Row<ProspectPool>;
}

const LatestLeadSource = ({row}: Props) => {
  const prospectPool = row.original;

  if (!prospectPool.leadSource) {
    return <></>;
  }

  return <div>{prospectPool.leadSource.name}</div>;
};

export default LatestLeadSource;
