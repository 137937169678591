import React from 'react';
import {Task} from 'api';

interface Props {
  task: Task;
}

const Priority = ({task}: Props) => (
  <div className="flex items-center text-xs gap-1">
    <span className="text-c-lighter">優先度:</span>
    {task.priority === 'high' && (
      <span className="text-[#eb5757]">{task.priorityText}</span>
    )}
    {task.priority === 'normal' && (
      <span className="text-[#ffc600]">{task.priorityText}</span>
    )}
    {task.priority === 'low' && (
      <span className="text-[#d5ebd1]">{task.priorityText}</span>
    )}
  </div>
);

export default Priority;
