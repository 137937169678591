import React from 'react';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {ReportGroupTable, ReportGroupTableData} from './types';

export default (table: ReportGroupTable) => {
  const headers = table?.headers ?? [];
  const data = table?.data ?? [];

  const columnHelper = createColumnHelper<ReportGroupTableData>();
  const columns = [
    columnHelper.group({
      id: 'label',
      header: () =>
        headers.length === 0 ? null : typeof headers[0] === 'string' ? (
          headers[0]
        ) : (
          <div {...headers[0].headerProps}>{headers[0].header}</div>
        ),
      columns: [
        columnHelper.accessor('label', {
          cell: ({getValue}) => {
            return <div className="flex gap-2 items-center">{getValue()}</div>;
          },
          header: null,
        }),
      ],
    }),
    ...headers.slice(1).map((header, i) =>
      columnHelper.group({
        id: `column-${i}`,
        header: () =>
          typeof header === 'string' ? (
            header
          ) : (
            <div {...header.headerProps}>{header.header}</div>
          ),
        columns: header.columns.map((subHeader, j) =>
          columnHelper.accessor('values', {
            id:
              j === 0
                ? 'subColumn-first'
                : j === header.columns.length - 1
                  ? 'subColumn-last'
                  : 'subColumn',
            cell: ({getValue}) => {
              const values = getValue();
              const value = values[i][j];
              // nullの場合は'-'を表示
              if (value === null) {
                return '-';
              }
              if (typeof value === 'number') {
                return value.toLocaleString();
              }
              return value;
            },
            header: () =>
              typeof subHeader === 'string' ? (
                subHeader
              ) : (
                <div {...subHeader.headerProps}>{subHeader.header}</div>
              ),
          }),
        ),
      }),
    ),
  ];

  return useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });
};
