import React from 'react';
import ContactRecordType from './ContactRecordType';
import ContactOwner from './ContactOwner';
import ContactSearchCondition from './ContactSearchCondition';
import {useWorkflowQuery} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();
  const {data: {workflow: {searchCondition = {}} = {}} = {}} = useWorkflowQuery(
    {
      variables: {uuid: workflowId},
    },
  );

  const [
    salesforceContactSearchConditions,
    setSalesforceContactSearchConditions,
  ] = React.useState([]);

  React.useEffect(() => {
    setSalesforceContactSearchConditions(
      searchCondition?.salesforceContactSearchConditions || [],
    );
  }, [searchCondition.salesforceContactSearchConditions]);

  return (
    <div className="flex flex-col gap-2">
      <ContactRecordType />
      <ContactOwner />
      <ContactSearchCondition
        setSalesforceContactSearchConditions={
          setSalesforceContactSearchConditions
        }
        salesforceContactSearchConditions={salesforceContactSearchConditions}
      />
    </div>
  );
};
