import React from 'react';
import {useUpdateTaskMutation, Task} from 'api';
import {DatePicker} from 'components/antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';

interface Props {
  task: Task;
}

export default ({task}: Props) => {
  const [updateTask] = useUpdateTaskMutation();

  return (
    <DatePicker
      value={task.dueDate ? moment(task.dueDate) : null}
      format="YYYY/MM/DD"
      locale={locale}
      onChange={(date) =>
        updateTask({
          variables: {uuid: task.uuid, attributes: {dueDate: date}},
        })
      }
    />
  );
};
