import {NumberParam, StringParam} from 'use-query-params';

const companiesQueryParamsDef = {
  page: NumberParam,
  uuid: StringParam,
  corpNumber: StringParam,
  name: StringParam,
  address: StringParam,
  telephoneNumber: StringParam,
  webSite: StringParam,
};

const companiesSearchParams = (query: any) => {
  return {
    corpNumber: query.corpNumber,
    name: query.name,
    address: query.address,
    telephoneNumber: query.telephoneNumber,
    webSite: query.webSite,
  };
};

const resetCompaniesSearchParams = {
  page: 1,
  uuid: '',
  corpNumber: '',
  name: '',
  address: '',
  telephoneNumber: '',
  webSite: '',
};

export {
  companiesQueryParamsDef,
  companiesSearchParams,
  resetCompaniesSearchParams,
};
