import React from 'react';
import {
  StatusNotStarted,
  StatusInProgress,
  StatusCompleted,
  StatusExpired,
  StatusIcebox,
} from 'components/Ui/Icon';

export default ({status}: {status: String}) => {
  switch (status) {
    case 'not_started':
      return <StatusNotStarted />;
    case 'in_progress':
      return <StatusInProgress />;
    case 'completed':
      return <StatusCompleted />;
    case 'expired':
      return <StatusExpired />;
    case 'icebox':
      return <StatusIcebox />;
  }
};
