import {colord} from 'colord';
import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();
  return {
    axis: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    data: [
      {
        label: '逆営業',
        color: getColor(0),
        groupId: 1,
        values: [100, 80, 90, 70, 60, 50, 40, 30, 20, 10, 5, 0],
      },
      {
        label: '逆営業(目標)',
        color: colord(getColor(0)).alpha(0.5).toHex(),
        groupId: 2,
        values: [100, 80, 90, 70, 60, 50, 40, 30, 20, 10, 5, 0],
      },
      {
        label: 'フォームマーケティング',
        color: getColor(1),
        groupId: 1,
        values: [20, 30, 40, 50, 60, 70, 80, 90, 80, 70, 60, 50],
      },
      {
        label: 'フォームマーケティング(目標)',
        color: colord(getColor(1)).alpha(0.5).toHex(),
        groupId: 2,
        values: [20, 30, 40, 50, 60, 70, 80, 90, 80, 70, 60, 50],
      },
      {
        label: 'ウェビナー',
        color: getColor(2),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'ウェビナー(目標)',
        color: colord(getColor(2)).alpha(0.5).toHex(),
        groupId: 2,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'オフラインイベント',
        color: getColor(3),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'オフラインイベント(目標)',
        color: colord(getColor(3)).alpha(0.5).toHex(),
        groupId: 2,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'アイミツSaaS',
        color: getColor(4),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'アイミツSaaS(目標)',
        color: colord(getColor(4)).alpha(0.5).toHex(),
        groupId: 2,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'リファラル（VC）',
        color: getColor(5),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'リファラル（VC）(目標)',
        color: colord(getColor(5)).alpha(0.5).toHex(),
        groupId: 2,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '【SF】資料ダウンロード',
        color: getColor(6),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '【SF】資料ダウンロード(目標)',
        color: colord(getColor(6)).alpha(0.5).toHex(),
        groupId: 2,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'LEADPADアカウント保有者',
        color: getColor(7),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: 'LEADPADアカウント保有者(目標)',
        color: colord(getColor(7)).alpha(0.5).toHex(),
        groupId: 2,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '掘り起こし',
        color: getColor(8),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '掘り起こし(目標)',
        color: colord(getColor(8)).alpha(0.5).toHex(),
        groupId: 2,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
    ],
  };
};

export default useChartData;
