import {
  useCurrentUserQuery,
  useCurrentClientQuery,
  useCountProjectsQuery,
  useAllClientUsersQuery,
} from 'api';

const useContract = () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();
  const {data: {allClientUsers: clientUsers = []} = {}} =
    useAllClientUsersQuery({
      fetchPolicy: 'cache-and-network',
    });
  const {data: {countProjects: {countOwnedOpenProjects} = {}} = {}} =
    useCountProjectsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const preleadContract = currentClient?.currentPreleadContract;
  const mailContract = currentClient?.currentMailContract;
  const isPreleadFixable =
    currentUser?.role === 'admin' || preleadContract?.fixable;
  const isPreleadDownloadable =
    currentUser?.role === 'admin' || preleadContract?.downloadable;
  const isUserAddable =
    (mailContract && !preleadContract) ||
    preleadContract?.userLimit === null ||
    preleadContract?.userLimit > clientUsers?.length;
  const isProjectAddable =
    currentUser?.role === 'admin' ||
    preleadContract?.projectLimit === null ||
    preleadContract?.projectLimit > countOwnedOpenProjects;
  const isCsvUploadable =
    currentUser?.role === 'admin' || preleadContract?.csvUploadable;
  const isTelLogDownloadable =
    currentUser?.role === 'admin' || preleadContract?.telLogDownloadable;

  return {
    preleadContract,
    mailContract,
    isPreleadFixable,
    isPreleadDownloadable,
    isUserAddable,
    isProjectAddable,
    isCsvUploadable,
    isTelLogDownloadable,
  };
};

export default useContract;
