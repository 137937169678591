import React from 'react';
import {useSalesforceDealFieldQuery} from 'api';

interface Props {
  dealFieldId: string;
  value?: string;
}

export default ({dealFieldId, value}: Props) => {
  const {
    data: {
      salesforceDealField: {salesforceDealFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceDealFieldQuery({
    variables: {dealFieldId},
    skip: !dealFieldId,
  });

  const selectedOption = salesforceDealFieldSelectOptions?.find(
    (option) => option.id === value,
  );

  if (!selectedOption) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-wrap items-center gap-1">
      <span className="h-6 flex items-center justify-center text-sm border border-c-border rounded-sm bg-c-bg px-2">
        {selectedOption.label}
      </span>
    </div>
  );
};
