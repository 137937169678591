import React from 'react';
import styled from 'styled-components';
import {Upload, Lense, Trash, PlusCircle} from 'components/Ui/Icon';
import {Input} from 'components/antd';
import UploadModal from './UploadModal';
import {StringParam, NumberParam, useQueryParams} from 'use-query-params';
import {useDestroyBlockGroupMembersMutation} from 'api';
import {useHistory, useParams} from 'react-router';
import {useRecoilState} from 'recoil';
import checkedBlockMembersAtom from './checkedBlockMembersAtom';

export default () => {
  const {blockGroupId} = useParams<{
    blockGroupId: string;
  }>();
  const [isUploadModalVisible, setUploadModalVisible] = React.useState(false);
  const history = useHistory();

  const [, setQuery] = useQueryParams({
    searchWord: StringParam,
    page: NumberParam,
  });

  const [checkedBlockMemberIds, setCheckedBlockMemberIds] = useRecoilState(
    checkedBlockMembersAtom,
  );

  const searchBlockGroupMembers = (arg: any) => {
    setQuery({
      searchWord: arg.target.value,
      page: 1,
    });
  };

  const [
    destroyBlockGroupMembers,
    {loading},
  ] = useDestroyBlockGroupMembersMutation({
    refetchQueries: ['blockGroup', 'blockGroupMembers'],
    onCompleted: () => {
      setCheckedBlockMemberIds([]);
    },
  });

  const clickDeleteButton = () => {
    if (
      !loading &&
      checkedBlockMemberIds.length > 0 &&
      confirm('削除してよろしいですか?')
    ) {
      destroyBlockGroupMembers({
        variables: {
          uuids: checkedBlockMemberIds,
        },
      });
    }
  };

  const handleClickNew = () => {
    history.push(
      `/block/block_groups/${blockGroupId}/block_members/new${location.search}`,
    );
  };

  return (
    <Container>
      <NavMenu>
        <NavItem>
          <span>ブロックリスト</span>
        </NavItem>
      </NavMenu>
      <ActionBox>
        <ActionItem onClick={clickDeleteButton}>
          <Trash />
          <span>削除</span>
        </ActionItem>
        <ActionItem onClick={() => setUploadModalVisible(true)}>
          <Upload />
          <span>アップロード</span>
        </ActionItem>
        <ActionItem onClick={handleClickNew}>
          <PlusCircle />
          <span>新規</span>
        </ActionItem>
        <Search
          type="search"
          name="search"
          placeholder="社名・住所"
          prefix={<Lense />}
          onPressEnter={(arg) => searchBlockGroupMembers(arg)}
        />
      </ActionBox>
      <UploadModal
        visible={isUploadModalVisible}
        onClose={() => setUploadModalVisible(false)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #e1e6eb;
  font-weight: bold;
  color: #bdc4cb;
`;

const NavMenu = styled.div``;

const NavItem = styled.div`
  display: inline-block;
  margin: 14px 0 0 32px;
  span {
    font-size: 14px;
  }
`;

const ActionBox = styled.div`
  display: flex;
  margin-left: auto;
`;

const ActionItem = styled.span`
  cursor: pointer;
  margin: auto 15px;

  svg {
    width: 20px;
    margin-right: 5px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
    font-size: 12px;
  }
`;

const Search = styled(Input)`
  margin: auto 10px auto 20px;
  width: 230px;
  height: 31px;
  background: #f3f5f7;
  border-radius: 80px;
  .ant-input {
    font-size: 14px;
    color: #bdc4cb;
    background: #f3f5f7;
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;
