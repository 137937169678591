import gql from 'graphql-tag';

export default gql`
  fragment poolSalesforceSettingFragment on PoolSalesforceSetting {
    id
    poolId
    isActive
    instanceUrl
    salesforceClientId
    username
  }
`;
