import React from 'react';
import {useParams} from 'react-router-dom';
import {useMailCampaignUrlsQuery, MailCampaignUrl} from 'api';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

const columnHelper = createColumnHelper<MailCampaignUrl>();

export default () => {
  const {mailCampaignId} = useParams<{
    mailCampaignId: string;
  }>();

  const {data: {mailCampaignUrls = []} = {}} = useMailCampaignUrlsQuery({
    variables: {
      uuid: mailCampaignId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !mailCampaignId,
  });

  const columns = [
    columnHelper.accessor('url', {
      header: () => 'URL',
      cell: ({getValue}) => (
        <a href={getValue()} target="_blank">
          {getValue()}
        </a>
      ),
    }),
    columnHelper.accessor('clickCount', {
      header: () => <div className="text-right">クリック</div>,
      cell: ({getValue}) => (
        <div className="text-right">{getValue().toLocaleString()}</div>
      ),
    }),
  ];

  const table = useReactTable({
    data: mailCampaignUrls,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full mb-4">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="text-left border-b border-b-c-border text-c-light text-sm py-1"
                style={{width: `${header.getSize()}px`}}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className="border-b border-b-c-border min-h-12 py-2 align-middle text-sm">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
