import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {PenWithLine, PlusSquare} from 'components/Ui/Icon';
import AddPopover from './AddPopover';
import {usePreleadProjectQuery} from 'api';
import moment from 'moment';
import {Button} from 'components/antd';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();
  const [isAddModalVisible, setAddModalVisible] = useState(false);

  const {data: {preleadProject: {reminder} = {}} = {}} = usePreleadProjectQuery(
    {
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    },
  );

  return (
    <Container>
      {reminder ? (
        <Content>
          <span
            className={
              moment().isSame(moment(reminder.remindAt), 'day') ? 'today' : ''
            }>
            {moment(reminder.remindAt).format('MM/DD(ddd) HH:mm')}
          </span>
          <span>{reminder.memo}</span>
          <Button type="text" onClick={() => setAddModalVisible(true)}>
            <PenWithLine />
          </Button>
        </Content>
      ) : (
        <AddButton>
          <div onClick={() => setAddModalVisible(true)}>
            <PlusSquare />
          </div>
        </AddButton>
      )}

      <AddPopover
        isModalVisible={isAddModalVisible}
        setModalVisible={setAddModalVisible}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;

  > span {
    font-size: 12px;
    line-height: 17px;
    color: #495058;
    white-space: break-spaces;

    &.today {
      color: #eb5757;
    }
  }

  > button {
    display: none;
    position: absolute;
    right: 0px;
    top: -2px;
  }

  &:hover {
    background: #f7f7f7;
    border-radius: 4px;

    > button {
      display: inline-block;
    }
  }
`;

const AddButton = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
`;
