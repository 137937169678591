import React from 'react';
import useSearchParams from '../../../../useSearchParams';
import Header from '../../Header';
import Footer from '../Footer';
import Select from './Select';
import FirstOpenedAt from './FirstOpenedAt';
import ClickedAt from './ClickedAt';

const MailCampaignFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="メール配信" />
      <div className="px-6 py-4 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="h-full bg-white px-6 py-3 rounded flex flex-col gap-2 overflow-y-scroll">
          <div className="text-c-base font-bold">メール配信</div>
          <div className="min-h-[16rem] p-2 overflow-y-scroll">
            <Select />
          </div>
          <div className="flex flex-col border-t border-c-border">
            <FirstOpenedAt />
          </div>
          <div className="flex flex-col border-t border-c-border">
            <ClickedAt />
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setQuery({
            ...query,
            mailCampaignUuid: '',
            firstOpenedFrom: '',
            firstOpenedTo: '',
            clickedFrom: '',
            clickedTo: '',
          })
        }
      />
    </>
  );
};

export default MailCampaignFilter;
