import React from 'react';
import {Form} from 'api';
import TitleInput from './Title';
import LogoInput from './Logo';
import LogoScaleInput from './LogoScale';
import FontSizeInput from './FontSize';
import BorderInput from './Border';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  return (
    <div className="py-4 flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-bold">タイトル・ロゴ編集</h2>
        <TitleInput form={form} />
        <LogoInput form={form} />
        <LogoScaleInput form={form} />
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-bold">スタイル編集</h2>
        <FontSizeInput form={form} />
        <BorderInput form={form} />
      </div>
    </div>
  );
};
