import React from 'react';

export const ProspectContext = React.createContext<{
  prospectId: string | null;
  poolId: string | null;
  onMove?: (prospectId: string) => void;
}>(null)

const useProspectContext = () => {
  const prospectContext = React.useContext(ProspectContext);

  return prospectContext;
};

export default useProspectContext;
