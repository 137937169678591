import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#E2E6EA" />
      <path
        d="M8 7.88783V8.30033V7.88783ZM10.05 7.88783V8.30033V7.88783ZM12 7.88783V8.30033V7.88783ZM8 9.81283V10.2253V9.81283ZM10.05 9.81283V10.2253V9.81283ZM12 9.81283V10.2253V9.81283ZM7 15.0837H13C13.55 15.0837 14 14.6712 14 14.167V6.83366C14 6.32949 13.55 5.91699 13 5.91699H7C6.45 5.91699 6 6.32949 6 6.83366V14.167C6 14.6712 6.45 15.0837 7 15.0837Z"
        stroke="#BDC4CB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4516 12.2871H8.60156V15.0829H11.4516V12.2871Z"
        stroke="#BDC4CB"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
