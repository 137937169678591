import gql from 'graphql-tag';

export default gql`
  fragment salesforceLeadRecordTypeFragment on SalesforceLeadRecordType {
    id
    name
    recordTypeId
    isMaster
  }
`;
