import React from 'react';
import {useHistory} from 'react-router-dom';

interface Props {
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
}

export const Footer: React.FC<Props> = ({onClick, label, disabled = false}) => {
  const history = useHistory();

  return (
    <div className="flex items-center justify-between gap-4 rounded-b bg-c-bg px-6 py-4">
      <button
        className="text-light flex h-10 w-32 cursor-pointer items-center justify-center rounded border border-c-border bg-white font-bold"
        onClick={() => history.goBack()}>
        戻る
      </button>
      {onClick && label && (
        <button
          className="text-light flex h-10 w-32 cursor-pointer items-center justify-center rounded border border-c-border bg-white font-bold disabled:opacity-50"
          disabled={disabled}
          onClick={onClick}>
          {label}
        </button>
      )}
    </div>
  );
};

export default Footer;
