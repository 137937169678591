import {ReportChart} from '../../components/Chart';
import useMonthAxis from '../../hooks/useMonthAxis';
import {useQueryParams, StringParam} from 'use-query-params';
import {useCollectedFormReportsQuery} from 'api';
import useColor from '../../hooks/useColor';

const useChartData = (): ReportChart => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {getColor} = useColor();

  const {data: {collectedFormReports = []} = {}} = useCollectedFormReportsQuery(
    {
      variables: {month: query.month},
    },
  );
  return {
    axis: [...useMonthAxis(query.month)],
    data: collectedFormReports.map((report) => {
      return {
        label: report.name,
        color: getColor(5),
        groupId: 1,
        values: report.values,
      };
    }),
  };
};

export default useChartData;
