import React from 'react';
import {FormBlock, FormPost, FormBlockFieldStandard} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldStandard;
}

interface Props {
  block: Block;
  post: FormPost;
}

export default ({block, post}: Props) => {
  const label =
    `${block.formBlockable.labelLastName || ''}${
      block.formBlockable.labelFirstName || ''
    }` || block.formBlockable.standardFieldTypeText;
  const {firstName, lastName} = post;

  return (
    <div className="w-full flex gap-4 items-center mb-3">
      <h4 className="text-c-light text-sm m-0 w-24">{label}</h4>
      <p className="m-0 text-base flex-1">{`${lastName} ${firstName}`}</p>
    </div>
  );
};
