import Check from './Check';
import Mail from './Mail';
import MailUpdatedAt from './MailUpdatedAt';
import Response from './Response';
import Tel from './Tel';
import LatestTel from './LatestTel';
import MailCampaign from './MailCampaign';
import Status from './Status';
import Nayose from './Nayose';
import Duplicate from './Duplicate';
import Comment from './Comment';
import User from './User';
import Name from './Name';
import Alert from './Alert';
import Click from './Click';
import ClickUpdatedAt from './ClickUpdatedAt';
import Tag from './Tag';
import MainCategory from './MainCategory';
import SubCategory from './SubCategory';
import TelUpdatedAt from './TelUpdatedAt';
import LatestTelUpdatedAt from './LatestTelUpdatedAt';
import UserOfLatestTelActivity from './UserOfLatestTelActivity';
import LatestTelComment from './LatestTelComment';
import LatestTelCommentRecordedAt from './LatestTelCommentRecordedAt';
import Remind from './Remind';
import LatestRemind from './LatestRemind';
import WebSite from './WebSite';
import Prefecture from './Prefecture';
import LatestTelUser from './LatestTelUser';
import CustomizeItemText from './CustomizeItemText';
import CustomizeItemNumber from './CustomizeItemNumber';
import CustomizeItemDate from './CustomizeItemDate';
import CustomizeItemSelect from './CustomizeItemSelect';
import ProjectGroupCustomizeItemText from './ProjectGroupCustomizeItemText';
import ProjectGroupCustomizeItemNumber from './ProjectGroupCustomizeItemNumber';
import ProjectGroupCustomizeItemDate from './ProjectGroupCustomizeItemDate';
import ProjectGroupCustomizeItemSelect from './ProjectGroupCustomizeItemSelect';
import ActivityMailCampaignMailOpensCount from './ActivityMailCampaignMailOpensCount';
import ActivityMailCampaignMailClicksCount from './ActivityMailCampaignMailClicksCount';

export default {
  Check,
  Mail,
  MailUpdatedAt,
  Response,
  Tel,
  LatestTel,
  MailCampaign,
  Status,
  Nayose,
  Duplicate,
  Comment,
  User,
  Name,
  Alert,
  Click,
  ClickUpdatedAt,
  Tag,
  MainCategory,
  SubCategory,
  TelUpdatedAt,
  LatestTelUpdatedAt,
  UserOfLatestTelActivity,
  LatestTelComment,
  LatestTelCommentRecordedAt,
  Remind,
  LatestRemind,
  WebSite,
  Prefecture,
  LatestTelUser,
  ActivityMailCampaignMailOpensCount,
  ActivityMailCampaignMailClicksCount,
  CustomizeItemText,
  CustomizeItemNumber,
  CustomizeItemDate,
  CustomizeItemSelect,
  ProjectGroupCustomizeItemText,
  ProjectGroupCustomizeItemNumber,
  ProjectGroupCustomizeItemDate,
  ProjectGroupCustomizeItemSelect,
};
