import React from 'react';
import {Approach} from 'api';

interface Props {
  approach: Approach;
}

const Assignee = ({approach}: Props) => {
  const {user} = approach;

  return (
    <div className="text-sm truncate flex-1">
      {user?.lastName} {user?.firstName}
    </div>
  );
};

export default ({approach}: Props) => (
  <div className="text-xs text-c-light flex items-center gap-1 flex-1 max-w-[10rem]">
    アプローチ担当者:
    <Assignee approach={approach} />
  </div>
);
