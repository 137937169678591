import React from 'react';
import styled from 'styled-components';

type Props = {
  padding?: number;
};

export const Header: React.FC<Props> = ({padding, children}) => {
  return <Head padding={padding}>{children}</Head>;
};

const Head = styled.div<{padding: number}>`
  background: #f3f5f7;
  padding-left: ${(props) => props.padding}px;
  span {
    font-weight: 500;
  }
  > span,
  a {
    &.name {
      color: #bdc4cb;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        color: #91ceff;
      }
    }
  }
`;
