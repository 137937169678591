import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery, useRemoveUserFromWorkflowMutation} from 'api';
import {UserBadge, Trash} from 'components/Ui/Icon';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {workflow: {users = []} = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
  });
  const [removeUserFromWorkflow] = useRemoveUserFromWorkflowMutation();

  if (users.length === 0) return null;

  return (
    <Container>
      {users.map((user) => (
        <User key={user.id}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <UserBadge />
            <Name>{[user.lastName, user.firstName].join(' ')}</Name>
          </div>
          {isMember && (
            <TrashIconWrapper
              onClick={() =>
                removeUserFromWorkflow({
                  variables: {
                    uuid: workflowId,
                    userId: user.id,
                  },
                })
              }>
              <Trash />
            </TrashIconWrapper>
          )}
        </User>
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 15px;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding-left: 3px;

  &:hover {
    background: #f9f9f9;
  }

  svg {
    margin-right: 13px;
  }
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const TrashIconWrapper = styled.div`
  cursor: pointer;
`;
