import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignBlockDividerFragment on MailCampaignBlockDivider {
    id
    bodyColor
    lineColor
    lineWidth
    paddingTop
    paddingBottom
    paddingLeft
    paddingRight
    createdAt
    updatedAt
  }
`;
