import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useAccountGroupQuery} from 'api';
import useClientUser from 'hooks/useClientUser';
import Title from './Title';
import Table from './Table';
import Search from './Search';
import UploadModal from './UploadModal';
import AddButton from './AddButton';
import MenuButton from './MenuButton';
import Progress from './Progress';

export default () => {
  const history = useHistory();
  const {isMember, isAdmin} = useClientUser();
  const [visible, setVisible] = React.useState(false);
  const {poolId, accountGroupId} = useParams<{
    poolId: string;
    accountGroupId: string;
  }>();

  const {
    data: {accountGroup = {}} = {},
    startPolling,
    stopPolling,
  } = useAccountGroupQuery({
    variables: {
      uuid: accountGroupId,
    },
  });

  React.useEffect(() => {
    if (accountGroup?.status !== 'normal') {
      startPolling(3000);
    } else {
      stopPolling();
    }
  }, [accountGroup, startPolling, stopPolling]);

  return (
    <div className="p-8 bg-c-bg h-[calc(100vh_-_60px)] overflow-scroll">
      <a onClick={() => history.push(`/pools/${poolId}/account_groups`)}>
        〈 一覧に戻る
      </a>
      <div className="my-4 flex">
        <Title accountGroup={accountGroup} />
      </div>
      <div className="flex flex-col bg-white rounded p-8">
        <div className="flex items-center justify-between">
          <Search />
          {accountGroup?.status !== 'normal' ? (
            <Progress accountGroup={accountGroup} />
          ) : (
            <div className="flex items-center gap-4">
              {isAdmin && <MenuButton accountGroup={accountGroup} />}
              {isMember && <AddButton accountGroup={accountGroup} />}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1 py-4">
          <Table accountGroup={accountGroup} />
        </div>
      </div>
      <UploadModal visible={visible} onClose={() => setVisible(false)} />
    </div>
  );
};
