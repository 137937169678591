import React from 'react';
import {Select} from 'components/antd';
import {Cross, Lense} from 'components/Ui/Icon';
import useSearchParams from './useSearchParams';
import {useAllAccountGroupsQuery} from 'api';
import {useParams} from 'react-router-dom';

interface Props {
  toggleFilter: () => void;
}

export default ({toggleFilter}: Props) => {
  const {query, setQuery} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();

  const {data: {allAccountGroups = []} = {}} = useAllAccountGroupsQuery({
    variables: {uuid: poolId},
  });

  return (
    <div className="p-5 bg-white rounded w-[240px]">
      <header className="flex items-center justify-between">
        <h3 className="font-medium m-0 text-c-light text-sm">フィルター</h3>
        <button
          className="cursor-pointer bg-transparent [&>svg]:relative [&>svg]:top-[2px] [&>svg>path]:stroke-c-primary"
          onClick={toggleFilter}>
          <Cross />
        </button>
      </header>
      <section className="my-4">
        <h4 className="text-c-light font-sm">リスト</h4>
        <select
          className="w-full form-select border-c-border rounded-sm focus:outline-none text-sm py-1"
          value={query.accountGroupId}
          onChange={(e) => setQuery({accountGroupId: e.target.value})}>
          <option value="" />
          {allAccountGroups.map((accountGroup) => (
            <option key={accountGroup.uuid} value={accountGroup.uuid}>
              {accountGroup.name}
            </option>
          ))}
        </select>
      </section>
    </div>
  );
};
