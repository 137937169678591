import moment from 'moment';
import {
  StringParam,
  DelimitedArrayParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const searchParamsDef = {
  userIds: withDefault(DelimitedArrayParam, []),
  date: withDefault(
    StringParam,
    moment().startOf('month').format('YYYY-MM-DD'),
  ),
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);

  return {
    query,
    setQuery,
  };
};

export default useSearchParams;
