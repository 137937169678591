import React from 'react';
import Menu from '../Menu';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import CompanyContent from './company';
import LeadContent from './lead';

export default () => {
  const location = useLocation();

  return (
    <div className="h-[calc(100vh-60px)] flex bg-white">
      <Menu />
      <div className="p-8 bg-c-bg w-full overflow-y-scroll">
        <div className="mb-4">
          <h1 className="text-xl mb-2 font-bold">顧客スコア</h1>
          <p className="text-base">
            各項目の重要度を設定して算出されるスコアを調整できます。
          </p>
          <ul className="font-bold flex flex-wrap -mb-px text-center text-base">
            <li className="mr-2">
              <Link
                to="/settings/score/company"
                className={`inline-block px-8 py-2 border-b-2 rounded-t-lg hover:text-current ${location.pathname.startsWith('/settings/score/company') ? 'border-c-primary text-c-base' : 'border-transparent text-c-lighter'}`}>
                企業
              </Link>
              <Link
                to="/settings/score/lead"
                className={`inline-block px-8 py-2 border-b-2 rounded-t-lg hover:text-current ${location.pathname.startsWith('/settings/score/lead') ? 'border-c-primary text-c-base' : 'border-transparent text-c-lighter'}`}>
                リード
              </Link>
            </li>
          </ul>
        </div>
        <Switch>
          <Route path="/settings/score/company" component={CompanyContent} />
          <Route path="/settings/score/lead" component={LeadContent} />
        </Switch>
      </div>
    </div>
  );
};
