import gql from 'graphql-tag';

export default gql`
  fragment approachGroupProspectFragment on ApproachGroupProspect {
    id
    title
    approachType
    priority
    startDate
    dueDate
  }
`;
