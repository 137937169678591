import gql from 'graphql-tag';

export default gql`
  fragment formPostOtherSelectFragment on FormPostOtherSelect {
    id
    formBlockFieldOtherId
    createdAt
    updatedAt
    formBlockFieldOtherOptions {
      id
      label
    }
  }
`;
