import React from 'react';
import {StringParam, useQueryParams} from 'use-query-params';

export const MonthInput: React.FC = () => {
  const [query, setQuery] = useQueryParams({
    month: StringParam,
    timeScale: StringParam,
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({
      month: e.target.value,
    });
  };

  return (
    <div className="flex items-center gap-4">
      <input
        type="month"
        onChange={onChange}
        value={query.month}
        className="border border-c-border rounded py-1 px-2"
      />
    </div>
  );
};
