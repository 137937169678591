import React from 'react';
import Modal from './Modal';
import {useParams} from 'react-router-dom';
import {
  AccountGroup,
  usePoolQuery,
  useCreateAccountGroupTagUpdateMutation,
} from 'api';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {getTagFontColor} from 'helpers/tagColors';

interface Props {
  visible: boolean;
  onClose: () => void;
  accountGroup: AccountGroup;
}
const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

const TagModal = ({visible, onClose, accountGroup}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const [tags, setTags] = React.useState([]);
  const [tagName, setTagName] = React.useState('');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const {data: {pool: {prospectTags = []} = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
    skip: !poolId,
  });

  const handleClose = () => {
    setTags([]);
    onClose();
  };
  const [createAccountGroupTagUpdate, {loading}] =
    useCreateAccountGroupTagUpdateMutation({
      onCompleted: handleClose,
      refetchQueries: ['accountGroup'],
    });

  const filteredTags = React.useMemo(
    () =>
      prospectTags
        ?.filter((prospectTag) => !tags.includes(prospectTag.id))
        ?.filter((prospectTag) =>
          kanaToHira(prospectTag.name).includes(kanaToHira(tagName)),
        ),
    [prospectTags, tagName, tags],
  );

  const selectedTags = React.useMemo(
    () =>
      tags
        .map((tag) =>
          prospectTags.find((prospectTag) => prospectTag.id === tag),
        )
        .filter((tag) => tag),
    [tags, prospectTags],
  );

  return (
    <Modal title="リードタグ一括追加" visible={visible} onClose={handleClose}>
      <div className="flex flex-col gap-4">
        <div className="flex">
          <div className="bg-c-bg h-8 flex items-center px-4 gap-2 rounded">
            <span className="text-sm">対象企業数:</span>
            <span className="text-base font-bold">
              {accountGroup?.accountCount?.toLocaleString()}
            </span>
          </div>
        </div>
        <Combobox value={tags} onChange={setTags} multiple>
          <div
            className="relative mt-1"
            onClick={() => inputRef.current?.focus()}>
            <div className="relative w-full min-h-[2.5rem] cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap">
              {selectedTags.map((tag) => (
                <span
                  key={tag.id}
                  className="truncate inline-flex items-center justify-center pl-2 pr-1 py-1 text-sm font-medium leading-4 rounded-sm"
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    backgroundColor: tag.color || 'black',
                    color: getTagFontColor(tag.color),
                  }}>
                  {tag.name}
                  <XMarkIcon
                    className="w-4 h-4 ml-2 cursor-pointer hover:bg-[rgba(255,255,255,.3)] rounded-sm"
                    onClick={() =>
                      setTags(
                        tags.filter((selectedTag) => selectedTag !== tag.id),
                      )
                    }
                  />
                </span>
              ))}
              <Combobox.Input
                className="flex-1 outline-none border-transparent focus:border-transparent focus:ring-0"
                onChange={(e) => setTagName(e.target.value)}
                onFocus={() => buttonRef.current?.click()}
                value={tagName}
                ref={inputRef}
              />
              <Combobox.Button className="invisible" ref={buttonRef} />
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </div>
            {filteredTags.length > 0 && (
              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredTags.map((tag) => (
                    <Combobox.Option
                      key={tag.id}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? 'bg-c-bg' : 'white'
                        }`
                      }
                      value={tag.id}>
                      <div className="flex items-center gap-2">
                        <span
                          className="h-3 w-3 rounded-sm bg"
                          style={{backgroundColor: tag.color || 'black'}}
                        />
                        <span className="truncate flex-1">{tag.name}</span>
                      </div>
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            )}
          </div>
        </Combobox>
        <div className="flex justify-end">
          <button
            disabled={tags.length === 0 || loading}
            type="button"
            onClick={() =>
              createAccountGroupTagUpdate({
                variables: {uuid: accountGroup.uuid, prospectTagIds: tags},
              })
            }
            className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32 disabled:bg-c-bg disabled:text-c-light">
            一括追加
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TagModal;
