import React from 'react';
import styled from 'styled-components';
import {Note} from 'components/Ui/Icon';
import {useParams} from 'react-router';
import {useProjectQuery} from 'api';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  return (
    <Container>
      <div>
        <Note />
      </div>
      <div>
        <span>プレリード件数</span>
        <span>
          <em>{project?.count.toLocaleString()}</em>件
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 284px;
  height: 134px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  padding: 40px 36px;

  > div:nth-child(1) {
    margin-right: 25px;
    width: 54px;
    height: 54px;
    display: flex;
    background: #f8f5ff;
    border-radius: 6px;

    svg {
      margin: auto;
      path {
        stroke: #8966ef;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;

    > span:nth-child(1) {
      font-weight: bold;
      font-size: 14px;
      color: #899098;
    }

    > span:nth-child(2) {
      font-weight: bold;
      font-size: 14px;
      color: #495058;

      em {
        margin-right: 6px;
        font-style: normal;
        font-size: 30px;
      }
    }
  }
`;
