import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignSearchConditionFragment on MailCampaignSearchCondition {
    leadSources
    originalLeadSources
    users
    collectedFormUrls
    forms
    stages
    ranks
    subCategories {
      ...subCategoryFragment
    }
    listingMarkets
    employeeNumber
    capitalFund
    tags
    excludedTags
    cities {
      ...cityFragment
    }
    projectId
    projectIds
    preleadStatusIds
    mailStatuses
    inflowDateType
    inflowDateFrom
    inflowDateTo
    clientPhaseProspects
    hubspotDealPipelineId
    hubspotDealPipelineStageIds
    salesforceLeadRecordTypeId
    salesforceLeadFieldSelectStatusIds
    prospectCustomizeItemSearchConditions
    salesforceContactRecordTypeId
    salesforceContactSearchConditions
  }
`;
