import React from 'react';
import styled from 'styled-components';
import {WorkflowProspectPool} from 'api';

export default ({
  workflowProspectPool,
}: {
  workflowProspectPool: WorkflowProspectPool;
}) => {
  if (workflowProspectPool.status === 'open') {
    return (
      <Open>
        <span>{workflowProspectPool.statusText}</span>
      </Open>
    );
  }

  return (
    <Close>
      <span>{workflowProspectPool.statusText}</span>
    </Close>
  );
};

const Status = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  padding: 1rem 20px;
  text-align: center;
  span {
    width: 30px;
  }
`;

const Open = styled(Status)`
  color: #55aa68;
  background: #f5fdf7;
`;

const Close = styled(Status)`
  color: #83888d;
  background: #eaecee;
`;
