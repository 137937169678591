import React from 'react';
import useSearchParams from './useSearchParams';

export default () => {
  const [value, setValue] = React.useState(undefined);
  const {setQuery, searchWord} = useSearchParams();

  const onSearch = (e: any) => {
    if (e.key === 'Enter') {
      setQuery({searchWord: value, page: 1});
    }
  };

  React.useEffect(() => {
    setValue(searchWord);
  }, [searchWord]);

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          aria-hidden="true"
          className="w-4 h-4 text-c-light"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </svg>
      </div>
      <input
        type="search"
        className="block w-80 p-2 pl-8 text-sm border border-c-border rounded bg-white"
        placeholder="検索"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        onKeyDown={onSearch}
      />
    </div>
  );
};
