import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import {Miitel} from 'components/Ui/Icon';
import {Button, Form, Input} from 'components/antd';
import {useFormik} from 'formik';
import {useUpdateMiitelSettingMutation, useCurrentClientQuery} from 'api';
import * as Yup from 'yup';
import {message} from 'components/antd';
import CircleCloseIcon from './CircleCloseIcon';

const validateSchema = Yup.object().shape({
  accessKey: Yup.string().required('必須項目です'),
  miitelCompanyId: Yup.string().required('必須項目です'),
});

export default () => {
  const {data: {currentClient: {miitelSetting = {}} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const [updateMiitelSetting, {loading}] = useUpdateMiitelSettingMutation({
    onCompleted: ({updateMiitelSetting: {miitelSetting}}) => {
      if (miitelSetting) {
        message.success('更新しました');
      }
    },
  });

  const changeMiitelActiveButton = () => {
    values.isActive = !values.isActive;
    updateMiitelSetting({
      variables: {
        attributes: {
          ...values,
        },
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      miitelCompanyId: miitelSetting?.miitelCompanyId || '',
      accessKey: miitelSetting?.accessKey || '',
      isActive: miitelSetting?.isActive || false,
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      updateMiitelSetting({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
    },
  });

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Body>
      <Menu />
      <Content>
        <TitleSection>
          <Miitel />
          <TitleWrapper>
            <Title>MiiTel</Title>
            <TitleNote>MiiTel連携の設定を行います。</TitleNote>
          </TitleWrapper>
          {values.isActive ? (
            <UnavailableButton onClick={changeMiitelActiveButton}>
              <CircleCloseIcon />
              無効にする
            </UnavailableButton>
          ) : (
            <AvailableButton onClick={changeMiitelActiveButton}>
              有効にする
            </AvailableButton>
          )}
        </TitleSection>

        <FormSection>
          <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 12}}
            onFinish={handleSubmit}>
            <Form.Item
              label="アクセスキー"
              required
              validateStatus={
                errors.accessKey && touched.accessKey ? 'error' : ''
              }
              help={
                errors.accessKey && touched.accessKey ? errors.accessKey : ''
              }>
              <Input
                name="accessKey"
                value={values.accessKey}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              label="カンパニーID"
              required
              validateStatus={
                errors.miitelCompanyId && touched.miitelCompanyId ? 'error' : ''
              }
              help={
                errors.miitelCompanyId && touched.miitelCompanyId ? errors.miitelCompanyId : ''
              }>
              <Input
                name="miitelCompanyId"
                value={values.miitelCompanyId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Space />

            <ButtonSection>
              <SaveButton
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={!formik.dirty || !isValid || loading}>
                保存
              </SaveButton>
            </ButtonSection>
          </Form>
        </FormSection>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 290px);
  height: fit-content;
  min-height: calc(100vh - 145px);
  padding: 45px 45px;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;

  padding-bottom: 22px;
  border-bottom: 1px solid #e2e6ea;
`;

const TitleWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
`;

const TitleNote = styled.div`
  margin-top: 5px;
  font-size: 14px;
`;

const AvailableButton = styled.button`
  background-color: #169e34;
  color: #fff;
  height: 39px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  margin: 0 0 0 auto;
  cursor: pointer;
`;

const UnavailableButton = styled(Button)`
  display: flex;
  align-items: center;
  border: none;
  margin: 0 0 0 auto;

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #69717a;
  }
`;

const FormSection = styled.div`
  margin-top: 25px;
  padding-bottom: 30px;

  form {
    margin-top: 30px;
    .ant-form-item {
      margin: 0;
      display: block;

      .ant-form-item-label {
        display: inline;
        label {
          height: 26px;
          font-weight: bold;
          font-size: 14px;
          color: #bdc4cb;
        }
      }
      .ant-form-item-control {
        max-width: 100% !important;
      }
      input {
        width: 600px;
        height: 48px;
        background: #f3f5f7;
        border: none;
      }
    }
  }
`;

const Space = styled.div`
  height: 20px;
`;

const ButtonSection = styled.div`
  margin-top: 40px;
  display: flex;
`;

const DefaultButton = styled(Button)`
  margin-right: 20px;
  width: 187px;
  height: 48px;
  border: none;
  border-radius: 4px;
  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

const SaveButton = styled(DefaultButton)`
  background: #68b5fb;
  &:hover {
    background: #65aae8;
  }
`;
