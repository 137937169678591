import React, {FC, useState, useCallback, useEffect} from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import Grid from './components/Grid';
import SortableItem from './components/SortableItem';
import Item from './components/Item';
import {useDashboardItems} from './useDashboardItems';

const Dashboard: FC = () => {
  const [items, setItems] = useState([]);
  const dashboardItems = useDashboardItems();

  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const {active, over} = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over!.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }, []);
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  useEffect(() => {
    setItems(dashboardItems.map((item) => item.id));
  }, [dashboardItems]);

  return (
    <div className="bg-c-bg">
      <h1 className="text-xl font-bold mx-8 mt-4">ダッシュボード</h1>
      <DndContext
        id="dashboard"
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Grid columns={5}>
            {items.map((id) => (
              <SortableItem key={id} id={id} />
            ))}
          </Grid>
        </SortableContext>
        <DragOverlay adjustScale style={{transformOrigin: '0 0 '}}>
          {activeId ? <Item id={activeId} isDragging /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default Dashboard;
