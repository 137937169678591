import gql from 'graphql-tag';
import {poolProspectCustomizeItemFragment} from '../../fragments';

export default gql`
  fragment formBlockFieldCustomizeItemFragment on FormBlockFieldCustomizeItem {
    id
    fieldType
    isRequired
    createdAt
    updatedAt
    poolProspectCustomizeItem {
      ...poolProspectCustomizeItemFragment
    }
  }
  ${poolProspectCustomizeItemFragment}
`;
