import React from 'react';
import {SalesforceUserSelector} from 'components/Prospect/SalesforceUserSelector';
import {useUpdateSalesforceContactOwnerMutation, SalesforceContact} from 'api';

interface Props {
  salesforceContact: SalesforceContact;
}

export default ({salesforceContact}: Props) => {
  const [updateOwner] = useUpdateSalesforceContactOwnerMutation({});
  return (
    <div className="flex gap-2 items-center">
      <div className="text-c-light text-xm mr-2">所有者</div>
      <div>
        <SalesforceUserSelector
          salesforceUser={salesforceContact?.salesforceOwner}
          onChange={(value) => {
            updateOwner({
              variables: {
                id: salesforceContact?.id,
                salesforceUserIdString: value,
              },
            });
          }}
        />
      </div>
    </div>
  );
};
