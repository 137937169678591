import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowHubspotDealMultiSelectSearchConditionMutation,
  useWorkflowQuery,
  useHubspotDealPropertyDefinitionEnumCheckboxOptionsQuery,
  HubspotDealPropertyDefinitionEnumCheckboxOption,
} from 'api';
import {Select} from 'components/antd';
import {MultiSelectSearchCondition} from './hubspotDealTypes';
import useClientUser from 'hooks/useClientUser';

const DealSelectOption: React.FC<{condition: MultiSelectSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {data: {hubspotDealPropertyDefinitionEnumCheckboxOptions = []} = {}} =
    useHubspotDealPropertyDefinitionEnumCheckboxOptionsQuery({
      variables: {
        hubpostDealPropertyDefinitionId: condition.deal_property_definition_id,
      },
      skip: !condition.deal_property_definition_id,
    });

  const [updateWorkflowHubspotDealMultiSelectSearchCondition] =
    useUpdateWorkflowHubspotDealMultiSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const dealFieldSelectOptions = () => {
    return hubspotDealPropertyDefinitionEnumCheckboxOptions.map(
      (item: HubspotDealPropertyDefinitionEnumCheckboxOption) => {
        return {
          value: item.id,
          label: item.label,
        };
      },
    );
  };

  return (
    <Select
      options={dealFieldSelectOptions() || []}
      disabled={
        !isConditionUpdatable ||
        !condition.deal_property_definition_id ||
        !isMember
      }
      style={{minWidth: 280, maxWidth: 600}}
      mode="multiple"
      showSearch
      optionFilterProp="label"
      value={condition.deal_property_definition_checkbox_option_ids ?? []}
      onChange={(values: string[]) => {
        updateWorkflowHubspotDealMultiSelectSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              dealPropertyDefinitionId: condition.deal_property_definition_id,
              dealPropertyDefinitionFieldType:
                condition.deal_property_definition_field_type,
              dealPropertyDefinitionPropertyType:
                condition.deal_property_definition_property_type,
              dealPropertyDefinitionCheckboxOptionIds: values,
            },
          },
        });
      }}
    />
  );
};

export default DealSelectOption;
