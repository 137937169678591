import React from 'react';
import styled from 'styled-components';
import {List, DotMenu, Trash, CheckGood, Download} from 'components/Ui/Icon';
import {useParams} from 'react-router-dom';
import {
  useBlockGroupMembersQuery,
  useCurrentClientQuery,
  useCurrentUserQuery,
  useDestroyBlockGroupMutation,
} from 'api';
import {useHistory} from 'react-router';
import {message, Popover} from 'components/antd';
import Tag from './tag';
import {ImportProgressBar} from 'components/Ui';
import ReflectModal from './ReflectModal';
import DownloadModal from './DownloadModal';

export default () => {
  const history = useHistory();
  const [isReflectModalVisible, setReflectModalVisible] = React.useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const {blockGroupId} = useParams<{blockGroupId: string}>();
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  const {
    data: {
      blockGroup = null,
      blockGroupMembers: {pagination: {totalCount = null} = {}} = {},
    } = {},
    refetch,
  } = useBlockGroupMembersQuery({
    variables: {
      uuid: blockGroupId,
    },
    skip: !blockGroupId,
    fetchPolicy: 'network-only',
  });

  const [destroyBlockGroup] = useDestroyBlockGroupMutation({
    refetchQueries: ['blockGroups'],
    onCompleted: () => {
      history.push(`/block/block_groups${location.search}`);
      message.info('営業リストを削除しました');
    },
  });

  const handleDestroyBlockGroup = () => {
    destroyBlockGroup({
      variables: {
        uuid: blockGroupId,
      },
    });
  };

  if (!blockGroup) return null;

  const {lastApplyBlockGroup, lastImportCsv} = blockGroup;

  if (lastApplyBlockGroup && lastApplyBlockGroup.status !== 'success') {
    return (
      <Container>
        <ImportProgressBar
          percent={lastApplyBlockGroup.percent}
          refetch={refetch}
        />
      </Container>
    );
  } else if (lastImportCsv && lastImportCsv.status !== 'success') {
    return (
      <Container>
        <ImportProgressBar percent={lastImportCsv.percent} refetch={refetch} />
      </Container>
    );
  } else if (blockGroup?.count && totalCount < blockGroup.count) {
    const percent = Math.floor((totalCount / blockGroup.count) * 100);
    return (
      // プレリードマスターからブロックグループを作った時とブロックグループメンバーを追加した時に表示するプログレスバー
      <Container>
        <ImportProgressBar percent={percent} refetch={refetch} />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <CountBox
          onClick={() =>
            history.push(`/block/block_groups/${blockGroupId}/block_members`)
          }>
          <div>
            <List />
          </div>
          <div>
            <Label>ブロック件数</Label>
            <Count>{blockGroup.count?.toLocaleString()}</Count>
          </div>
        </CountBox>

        <Popover
          placement="bottomRight"
          trigger="hover"
          content={
            <PopoverMenu>
              {currentClient.canApplyBlockGroup ||
              currentUser.role === 'admin' ? (
                <MenuItem
                  onClick={() => {
                    setReflectModalVisible(true);
                  }}>
                  <div>
                    <CheckGood />
                    <span>営業リストに反映</span>
                  </div>
                </MenuItem>
              ) : null}
              <MenuItem onClick={() => setDownloadModalOpen(true)}>
                <div>
                  <Download />
                  <span>ダウンロード</span>
                </div>
              </MenuItem>
              <DeleteButton
                onClick={() => {
                  if (confirm('ブロックグループを削除してよろしいですか？')) {
                    handleDestroyBlockGroup();
                  }
                }}>
                <div>
                  <Trash />
                  <span>削除</span>
                </div>
              </DeleteButton>
            </PopoverMenu>
          }>
          <DotIconWrapper>
            <DotMenu />
          </DotIconWrapper>
        </Popover>
      </Header>

      <Tag />

      <ReflectModal
        visible={isReflectModalVisible}
        onClose={() => setReflectModalVisible(false)}
      />

      {isDownloadModalOpen && (
        <DownloadModal
          blockGroup={blockGroup}
          isOpen={isDownloadModalOpen}
          setModal={setDownloadModalOpen}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 30%;
  padding-left: 50px;
`;

const Header = styled.div`
  display: flex;
  height: 70px;
  border-bottom: 1px solid #e1e6eb;
`;

const CountBox = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  > div:first-child {
    margin-right: 12px;
    margin-left: 15px;
    position: relative;
    top: 2px;

    svg {
      path {
        stroke: #68b4fb;
      }
    }
  }
`;

const Label = styled.span`
  display: block;
  transform: scale(0.7);
  transform-origin: left;
  font-weight: bold;
  font-size: 10px;
  color: #bdc4cb;
`;

const Count = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: #495058;
`;

const DotIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 44px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const PopoverMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #bdc4cb;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  height: 45px;
  cursor: pointer;

  div {
    display: flex;
    width: 100%;
    height: 100%;

    > svg {
      width: 18px;
      height: 18px;
      margin: auto 13px auto 10px;
    }

    > span {
      margin: auto 0;
      font-weight: bold;
      font-size: 12px;
      color: #495058;
    }

    &:hover {
      background: #f1f8ff;
      border-radius: 4px;
    }
  }
`;

const DeleteButton = styled(MenuItem)`
  border-top: 1px solid #e1e6eb;

  div {
    svg {
      path {
        stroke: #bdc4cb;
      }
    }
    span {
      color: #495058;
    }
  }
`;
