import React from 'react';
import styled from 'styled-components';
import {Prelead, PreleadContact} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const ContactCell = ({row}: Props) => {
  const prelead: Prelead = row.original;
  const preleadContacts: Array<PreleadContact> = prelead.preleadContacts;

  const preleadContactNames = React.useMemo(
    () =>
      preleadContacts?.map(
        (contact) => `${contact.lastName} ${contact.firstName}`,
      ),
    [preleadContacts],
  );

  return <Cell>{preleadContactNames?.join(',　')}</Cell>;
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

export default ContactCell;
