import React from 'react';
import {useParams, Switch, Route, Redirect} from 'react-router-dom';
import {useMailCampaignQuery, useProspectPoolsByMailCampaignQuery} from 'api';
import Mail from '../mail/Mail';
import Settings from '../settings';
import Recipients from './recipients';
import Results from './results';
import {useRecoilState} from 'recoil';
import totalCountAtom from '../totalCountAtom';
import Tabs from './Tabs';

const PER_PAGE = 20;

export default () => {
  const [page, setPage] = React.useState(1);
  const {mailCampaignId} = useParams<{
    poolId: string;
    mailCampaignId: string;
  }>();
  const [, setTotalCount] = useRecoilState(totalCountAtom);

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const {
    data: {
      prospectPoolsByMailCampaign: {prospectPools = [], pagination = {}} = {},
    } = {},
  } = useProspectPoolsByMailCampaignQuery({
    variables: {
      uuid: mailCampaign?.uuid,
      page,
      perPage: PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({prospectPoolsByMailCampaign: {pagination}}) =>
      setTotalCount(pagination.totalCount),
  });

  if (!mailCampaign) return null;

  return (
    <div className="p-4 rounded relative bg-white">
      <Tabs />
      <Switch>
        <Route path={`/mail_campaigns/:mailCampaignId/mail`} component={Mail} />
        <Route path={`/mail_campaigns/:mailCampaignId/recipients`}>
          <Recipients
            prospectPools={prospectPools}
            pagination={pagination}
            setPage={setPage}
            perPage={PER_PAGE}
          />
        </Route>
        <Route path={`/mail_campaigns/:mailCampaignId/settings`}>
          <Settings mailCampaign={mailCampaign} />
        </Route>
        <Route path={`/mail_campaigns/:mailCampaignId/results`}>
          <Results mailCampaign={mailCampaign} />
        </Route>
        <Redirect to={`/mail_campaigns/:mailCampaignId/mail`} />
      </Switch>
    </div>
  );
};
