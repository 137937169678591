import React from 'react';
import styled from 'styled-components';
import {Breadcrumb} from 'components/Ui';
import {Cross} from 'components/Ui/Icon';
import {Link, useParams} from 'react-router-dom';
import {useBlockGroupQuery} from 'api';
import BasicInformation from './basicInformation';
import SideMenu from './sideMenu';
import {useHistory} from 'react-router';

export default () => {
  const history = useHistory();

  const {blockGroupId} = useParams<{blockGroupId: string}>();

  const {data: {blockGroup = null} = {}} = useBlockGroupQuery({
    variables: {uuid: blockGroupId},
    fetchPolicy: 'cache-and-network',
    skip: !blockGroupId,
  });

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <CustomBreadcrumb separator=">">
            <CustomBreadcrumb.Item>
              <Link
                to="/block/block_groups"
                onClick={() =>
                  history.push(`/block/block_groups${location.search}`)
                }>
                ブロックグループ一覧
              </Link>
            </CustomBreadcrumb.Item>
            <CustomBreadcrumb.Item>
              <Name>{blockGroup?.name}</Name>
            </CustomBreadcrumb.Item>
          </CustomBreadcrumb>
        </HeaderTitle>
        <CrossIconWrapper
          onClick={() => {
            history.push(`/block/block_groups${location.search}`);
          }}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <Body>
        <BasicInformation />
        <SideMenu />
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
`;

const Header = styled.div`
  height: 50px;
  background: #f3f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomBreadcrumb = styled(Breadcrumb)`
  padding: 19px 0 19px 17px;
  background: #f3f5f7;
  font-size: 9px;
  line-height: 13px;
  letter-spacing: 0.15px;

  a {
    &:hover {
      color: #68b5fb;
    }
  }

  .ant-breadcrumb-link {
    color: #899098;
  }

  .ant-breadcrumb-link > span:last-child {
    font-weight: bold;
    color: #222426;
  }

  .ant-breadcrumb-separator {
    margin: 0 25px;
  }
`;

const HeaderTitle = styled.div`
  width: 100%;
`;

const CrossIconWrapper = styled.div`
  position: relative;
  right: 30px;
  svg {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
`;

const Body = styled.div`
  display: flex;
  padding: 35px 45px;
`;

const Name = styled.span`
  display: inline-block;
  width: 77%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 3px;
`;
