import React from 'react';
import moment from 'moment';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const TelUpdatedAtCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;

  return (
    <span>
      {preleadProject.telUpdatedAt &&
        moment(preleadProject.telUpdatedAt).format('YYYY年MM月DD日 HH時mm分')}
    </span>
  );
};

export default TelUpdatedAtCell;
