import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery, useUpdateWorkflowSearchConditionMutation} from 'api';
import {Radio} from 'components/Ui';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{
    workflowId: string;
  }>();

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <Item>
      <Label>メールアドレス</Label>
      <RadioGroup
        value={searchCondition?.isEmail}
        disabled={!isConditionUpdatable || !isMember}
        onChange={(e) =>
          updateWorkflowSearchCondition({
            variables: {
              uuid: workflowId,
              attributes: {
                isEmail: e.target.value,
              },
            },
          })
        }>
        <Radio value={null}>指定なし</Radio>
        <Radio value={true}>あり</Radio>
        <Radio value={false}>なし</Radio>
      </RadioGroup>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Label = styled.div`
  width: 115px;
  font-weight: 500;
  font-size: 14px;
`;

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
