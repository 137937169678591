import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import {useAllCollectedFormUrlsQuery} from 'api';

const CollectedFormUrlFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {allCollectedFormUrls: {collectedFormUrls = []} = {}} = {}} =
    useAllCollectedFormUrlsQuery({
      fetchPolicy: 'cache-and-network',
    });

  return (
    <>
      <Header title="タグ連携フォーム" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={collectedFormUrls.map((collectedFormUrl) => ({
              text: collectedFormUrl.title,
              value: collectedFormUrl.id,
              keyword: collectedFormUrl.title,
            }))}
            values={query.collectedFormUrlIds || []}
            onChange={(collectedFormUrlIds) =>
              setQuery({...query, collectedFormUrlIds})
            }
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, collectedFormUrlIds: []})} />
    </>
  );
};

export default CollectedFormUrlFilter;
