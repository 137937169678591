import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const PAGE_SIZE = 20;

const searchParamsDef = {
  page: NumberParam,
  status: StringParam,
  orderBy: StringParam,
  target: withDefault(StringParam, 'prospects'),
  searchWord: StringParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);

  return {
    query,
    setQuery,
    perPage: PAGE_SIZE,
  };
};

export default useSearchParams;
