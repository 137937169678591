import {useParams} from 'react-router-dom';
import {
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const projectsQueryParamsDef = {
  status: StringParam,
  page: NumberParam,
  searchWord: StringParam,
  tagIds: withDefault(DelimitedArrayParam, []),
  projectGroupIds: DelimitedArrayParam,
  fromDate: StringParam,
  toDate: StringParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(projectsQueryParamsDef);
  const {projectGroupId, requestClientId, offeredClientId} = useParams<{
    projectGroupId: string;
    requestClientId: string;
    offeredClientId: string;
  }>();

  const searchParams = {
    status: query.status,
    searchWord: query.searchWord,
    tagIds: query.tagIds,
    projectGroupId: projectGroupId,
    requestClientId: requestClientId,
    offeredClientId: offeredClientId,
    projectGroupIds: query.projectGroupIds,
    fromDate: query.fromDate,
    toDate: query.toDate,
  };

  return {
    query,
    setQuery,
    searchParams,
  };
};

export default useSearchParams;
