import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';

export default () => {
  const {query, setQuery} = useSearchParams();
  const {clickTerm} = query;
  const {RangePicker} = DatePicker;
  const format = 'YYYY-MM-DD';

  return (
    <RangePicker
      format={format}
      placeholder={['開始日', '終了日']}
      value={
        clickTerm?.from && clickTerm?.to
          ? [moment(clickTerm.from, format), moment(clickTerm.to, format)]
          : null
      }
      onChange={(value) => {
        if (value) {
          setQuery({
            clickTerm: {
              from: value[0].format(format),
              to: value[1].format(format),
            },
          });
        } else {
          setQuery({
            clickTerm: null,
          });
        }
      }}
    />
  );
};
