import React from 'react';
import useCondition from '../../../../useCondition';
import {useSalesforceLeadRecordTypeByRecordTypeIdQuery} from 'api';
import SelectOption from './SelectOption';

export type SelectSearchCondition = {
  field_select_option_id?: string;
  field_type: string;
  lead_field_id: string;
};

export type CheckboxSearchCondition = {
  value?: string;
  field_type: string;
  lead_field_id: string;
};

type SearchCondition = SelectSearchCondition | CheckboxSearchCondition;

interface Props {
  searchCondition: SearchCondition;
}

export default ({searchCondition}: Props) => {
  const {condition} = useCondition();

  const {
    data: {
      salesforceLeadRecordTypeByRecordTypeId: {salesforceLeadFields = []} = {},
    } = {},
  } = useSalesforceLeadRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceLeadRecordTypeId: condition?.salesforceLeadRecordTypeId,
    },
    skip: !condition.salesforceLeadRecordTypeId,
  });

  const leadField = salesforceLeadFields.find(
    (field) => field.id === searchCondition.lead_field_id,
  );

  if (!leadField) {
    return null;
  }

  return (
    <div className="flex gap-2">
      <span className="text-c-light h-6 flex items-center">
        {leadField.label}：
      </span>
      {searchCondition.field_type === 'select' && (
        <SelectOption
          leadFieldId={searchCondition.lead_field_id}
          value={
            (searchCondition as SelectSearchCondition).field_select_option_id
          }
        />
      )}
      {searchCondition.field_type === 'checkbox' && (
        <div className="flex-1 flex flex-wrap items-center gap-1">
          <span className="h-6 flex items-center justify-center text-sm border border-c-border rounded-sm bg-c-bg px-2">
            {
              {true: 'あり', false: 'なし'}[
                (searchCondition as CheckboxSearchCondition).value
              ]
            }
          </span>
        </div>
      )}
    </div>
  );
};
