import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery, useUpdateWorkflowSearchConditionMutation} from 'api';
import {Radio} from 'components/Ui';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{
    workflowId: string;
  }>();

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const elapsedMonths = [
    {displayName: 'なし', value: 0},
    {displayName: '1ヶ月以上', value: 1},
    {displayName: '2ヶ月以上', value: 2},
    {displayName: '3ヶ月以上', value: 3},
    {displayName: '6ヶ月以上', value: 6},
  ];

  return (
    <Item>
      <Label>アクション経過</Label>
      <RadioGroup
        value={searchCondition?.monthsSinceAction}
        disabled={!isConditionUpdatable || !isMember}
        onChange={(e) =>
          updateWorkflowSearchCondition({
            variables: {
              uuid: workflowId,
              attributes: {
                monthsSinceAction: e.target.value,
              },
            },
          })
        }>
        {elapsedMonths.map((elapsedMonth) => (
          <Radio value={elapsedMonth.value} key={elapsedMonth.value}>
            <span>{elapsedMonth.displayName}</span>
          </Radio>
        ))}
      </RadioGroup>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Label = styled.div`
  width: 115px;
  font-weight: 500;
  font-size: 14px;
`;

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
