import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Call from './call';
import Mail from './mail';

export default () => {
  return (
    <Switch>
      <Route path="/report/call" component={Call} />
      <Route path="/report/mail" component={Mail} />
    </Switch>
  );
};
