import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 16.75H16C16.8284 16.75 17.5 16.0784 17.5 15.25V7C17.5 6.17157 16.8284 5.5 16 5.5H9.25L7.97265 3.58398C7.83355 3.37533 7.59938 3.25 7.34861 3.25H4C3.17157 3.25 2.5 3.92157 2.5 4.75V15.25C2.5 16.0784 3.17157 16.75 4 16.75Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
