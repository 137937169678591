import gql from 'graphql-tag';
import {salesforceUserFragment} from '..';

export default gql`
  fragment salesforceEventFragment on SalesforceEvent {
    id
    activityDateTime
    endDateTime
    location
    description
    eventId
    subject
    salesforceUser {
      ...salesforceUserFragment
    }
  }
  ${salesforceUserFragment}
`;
