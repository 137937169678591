import React from 'react';
import LeadSource from './LeadSource';
import CollectedFormUrl from './CollectedFormUrl';
import Form from './Form';
import Stage from './Stage';
import IsEmail from './IsEmail';
import Tag from './Tag';
import ExcludedTag from './ExcludedTag';
import MonthsSinceAction from './MonthsSinceAction';
import ProspectCustomizeItem from './ProspectCustomizeItem';

export default () => {
  return (
    <div className="mt-9">
      <LeadSource />
      <CollectedFormUrl />
      <Form />
      <Stage />
      <IsEmail />
      <Tag />
      <ExcludedTag />
      <MonthsSinceAction />
      <ProspectCustomizeItem />
    </div>
  );
};
