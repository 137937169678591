import React from 'react';
import {HubspotCompany} from 'api';

interface Props {
  hubspotCompany: HubspotCompany;
}

export default ({hubspotCompany}: Props) => (
  <>
    {hubspotCompany.hubspotOwner?.lastName}{' '}
    {hubspotCompany.hubspotOwner?.firstName}
  </>
);
