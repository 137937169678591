import gql from 'graphql-tag';
import preleadProjectGroupCustomizeItemSelectOptionFragment from './projectGroupCustomizeItemSelectOption';

export default gql`
  fragment preleadProjectGroupCustomizeItemSelectFragment on PreleadProjectGroupCustomizeItemSelect {
    id
    preleadProjectGroupCustomizeItemSelectOptions {
      ...preleadProjectGroupCustomizeItemSelectOptionFragment
    }
    projectGroupPreleadCustomizeItemSelectOptionIds
    projectGroupPreleadCustomizeItemId
  }
  ${preleadProjectGroupCustomizeItemSelectOptionFragment}
`;
