import React from 'react';

function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E1E6EB" />
      <path
        d="M10.167 12.5001C10.167 11.2114 11.2117 10.1667 12.5003 10.1667H19.5003C20.789 10.1667 21.8337 11.2114 21.8337 12.5001V19.5001C21.8337 20.7887 20.789 21.8334 19.5003 21.8334H12.5003C11.2117 21.8334 10.167 20.7887 10.167 19.5001V12.5001Z"
        stroke="url(#paint0_linear_24302_198842)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9998 18.5927C17.4317 18.5927 18.5924 17.4319 18.5924 16.0001C18.5924 14.5682 17.4317 13.4075 15.9998 13.4075C14.568 13.4075 13.4072 14.5682 13.4072 16.0001C13.4072 17.4319 14.568 18.5927 15.9998 18.5927Z"
        stroke="url(#paint1_linear_24302_198842)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 12.5127V12.5"
        stroke="url(#paint2_linear_24302_198842)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_24302_198842"
          x1="16.0003"
          y1="10.1667"
          x2="16.0003"
          y2="21.8334"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F82571" />
          <stop offset="1" stopColor="#B226F4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_24302_198842"
          x1="15.9998"
          y1="13.4075"
          x2="15.9998"
          y2="18.5927"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F82571" />
          <stop offset="1" stopColor="#B226F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_24302_198842"
          x1="20"
          y1="12.5"
          x2="20"
          y2="12.5127"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F82571" />
          <stop offset="1" stopColor="#B226F4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
