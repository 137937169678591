import React from 'react';
import useSearchParams from '../../../../useSearchParams';
import Header from '../../Header';
import Footer from '../Footer';
import RecordType from './RecordType';
import Status from './Status';
import CustomizeItem from './CustomizeItem';

const FormFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="Salesforceリード" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <RecordType />
          <Status />
          <CustomizeItem />
        </div>
      </div>
      <Footer
        onClear={() =>
          setQuery({
            ...query,
            salesforceLeadRecordTypeId: '',
            salesforceLeadSearchConditions: [],
          })
        }
      />
    </>
  );
};

export default FormFilter;
