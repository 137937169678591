import gql from 'graphql-tag';

export default gql`
  fragment chatworkSettingFragment on ChatworkSetting {
    id
    clientId
    apiToken
    roomId
    isActive
    notifyPreleadProjectsCreated
    notifyBlockListImported
    notifyMailStatusChanged
    notifyTelStatusChanged
    notifyPreleadProjectStatusChanged
    notifyCommented
    notifyPreleadContactCreated
    notifyMailClicked
  }
`;
