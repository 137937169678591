import React from 'react';
import {CollectedFormUrl} from 'api';
import UrlEdit from './Url';
import LeadSourceEdit from './LeadSource';
import UsersEdit from './Users';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  return (
    <div className="flex flex-col gap-8">
      <UrlEdit collectedFormUrl={collectedFormUrl} />
      <LeadSourceEdit collectedFormUrl={collectedFormUrl} />
    </div>
  );
};
