import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {PostmarkBadge} from 'components/Ui/Icon';
import {Activity, LetterSendActivity} from 'api';
import useProjectName from './useProjectName';

interface LetterSend extends Activity {
  resource: LetterSendActivity;
}

interface Props {
  activity: LetterSend;
}

const LetterSendActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  return (
    <Container>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <PostmarkBadge />
        </BadgeWrapper>
        <Body>手紙を送付しました</Body>
        <TimeStamp>
          {moment(activity.createdAt).format('MM/DD HH:mm')}
        </TimeStamp>
      </Summary>
      <Content>
        <dl>
          <dt>送付日</dt>
          <dd>{moment(activity.resource.sendDate).format('YYYY年MM月DD日')}</dd>

          <dt>到着予定日</dt>
          <dd>
            {activity.resource.expectedArrivalDate &&
              moment(activity.resource.expectedArrivalDate).format(
                'YYYY年MM月DD日',
              )}
          </dd>
          <dt>差出人</dt>
          <dd>{activity.resource.sender}</dd>
          <dt>差出人の役職</dt>
          <dd>{activity.resource.senderPosition}</dd>
        </dl>
        <dl>
          <dt>手紙本文</dt>
          <dd>{activity.resource.body}</dd>
          <dt>備考</dt>
          <dd>{activity.resource.note}</dd>
        </dl>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  margin-left: -30px;
  position: relative;
  padding: 9px 20px 10px;
  border: 1px solid #e2e6ea;
  border-radius: 4px;
  background: #ffffff;
`;

const Summary = styled.div`
  margin-left: -3px;
  margin-top: 5px;
  display: flex;
  width: 480px;
  height: 30px;

  span {
    line-height: 30px;
  }
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const Body = styled.span`
  min-width: 0;
  max-width: 100%;
  margin-top: 4px;
  flex: auto;
`;

const TimeStamp = styled.span`
  margin-left: auto;
`;

const Project = styled.div`
  color: #899098;
`;

const Content = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  dl:first-of-type {
    line-height: 2;

    dt {
      float: left;
      font-weight: bold;
      color: #bcc3ca;
    }

    dd {
      margin-left: 200px;
      color: #495058;
    }
  }

  dl:last-of-type {
    dt {
      font-weight: bold;
      color: #bcc3ca;
      margin-bottom: 0.5rem;
    }

    dd {
      color: #495058;
      white-space: pre-wrap;
      margin-bottom: 1rem;
    }
  }
`;

export default LetterSendActivityComponent;
