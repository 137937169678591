import {ReportChart} from '../../components/Chart';

const useChartData = (): ReportChart => {
  return {
    axis: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    data: [
      {
        label: '合計',
        color: '#8966EF',
        groupId: 1,
        values: [100, 80, 90, 70, 60, 50, 40, 30, 20, 10, 5, 0],
      },
    ],
  };
};

export default useChartData;
