import React from 'react';
import NewModal from './NewModal';
import Item from './Item';
import usePreleadTasks from 'hooks/usePreleadTasks';

const Task: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const {preleadTasksAccessible} = usePreleadTasks();

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-end w-full flex-row pt-3.5 pb-[18px]">
        <button
          className="rounded font-medium text-sm h-8 px-4 cursor-pointer bg-white border border-c-border"
          onClick={() => setIsOpen(true)}>
          ToDoを登録
        </button>
      </div>
      <div className="border-t border-c-border">
        {preleadTasksAccessible.map((task) => (
          <Item key={task.id} task={task} />
        ))}
      </div>
      <NewModal visible={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};

export default Task;
