import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import BASE_CLASSIFICATIONS from 'helpers/classifications';
import {useMainCategoriesQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery({});

  const classifications = React.useMemo(() => {
    return BASE_CLASSIFICATIONS(mainCategories);
  }, [mainCategories]);

  // カテゴリーの表示をまとめる
  const items = React.useMemo(() => {
    if (query?.subCategories.length === 0) return [];

    const mainCategories = classifications
      .flatMap((classification) =>
        classification.categories.filter((mainCategory) =>
          mainCategory.subCategories.every(
            (subCategory) => query?.subCategories?.includes(subCategory.id),
          ),
        ),
      )
      .filter(Boolean);

    const mainCategoryIds = mainCategories.map((category) => category.id);

    // query?.subCategories? とidが一致しかつ [mainCategoryId が mainCategoryIds に含まれてないものを抽出
    const subCategories = classifications.flatMap((classification) =>
      classification.categories.flatMap((category) =>
        category.subCategories.filter(
          (subCategory) =>
            query?.subCategories?.includes(subCategory.id) &&
            !mainCategoryIds.includes(category.id),
        ),
      ),
    );

    return [
      ...mainCategories.map((category) => `${category.displayName}すべて`),
      ...subCategories.map((category) => `${category.displayName}`),
    ];
  }, [query, classifications]);

  if (!query?.subCategories || !query?.subCategories.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">業種</h4>
        <button
          onClick={() => setFilterType('subCategory')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              subCategories: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {items.map((item, i) => (
          <span
            key={i}
            className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};
