export const telStatusCategoryHash: {[key: string]: string} = {
  call: 'コール',
  reception: '受付',
  contact: 'キーマン接触',
  appoint: 'アポ獲得',
  document: '資料送付',
  other: 'その他',
  call_ng: 'コールNG',
  reception_ng: '受付NG',
  contact_ng: 'キーマン接触NG',
};
