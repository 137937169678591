import {
  DashboardItemTodoChartType,
  DashboardItemTodoChartOptions,
  DashboardItem,
} from '../../types';
import React from 'react';

import StatusChart from './Status';
import UserChart from './User';

const chartComponents: {
  [key in DashboardItemTodoChartType]?: React.FC<DashboardItemTodoChartOptions>;
} = {
  status: StatusChart,
  user: UserChart,
};

type Props = {
  item: DashboardItem<DashboardItemTodoChartOptions>;
};

const TodoChart: React.FC<Props> = ({item}) => {
  const ChartComponent = chartComponents[item.options.chartType];
  return <ChartComponent {...item.options} />;
};

export default TodoChart;
