import gql from 'graphql-tag';

export default gql`
  fragment mailContractFragment on MailContract {
    id
    clientId
    plan
    planText
    planType
    planTypeText
    startedAt
    expiredAt
    limit
    count
  }
`;
