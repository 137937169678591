import React from 'react';

function Icon() {
  return (
    <svg
      width="116"
      height="29"
      viewBox="0 0 116 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.0114 2.28271L62.6756 2.52104C62.4436 2.20516 62.1533 1.92516 61.807 1.68232L62.1281 1.44922C62.4703 1.69672 62.7639 1.97503 63.0114 2.28271ZM62.0482 2.68067L61.7321 2.89922C61.499 2.58404 61.2096 2.30334 60.8633 2.06035L61.1663 1.84718C61.5077 2.09468 61.8017 2.37285 62.0482 2.68067Z"
        fill="#231815"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.0114 2.28271L62.6756 2.52104C62.4436 2.20516 62.1533 1.92516 61.807 1.68232L62.1281 1.44922C62.4703 1.69672 62.7639 1.97503 63.0114 2.28271ZM62.0482 2.68067L61.7321 2.89922C61.499 2.58404 61.2096 2.30334 60.8633 2.06035L61.1663 1.84718C61.5077 2.09468 61.8017 2.37285 62.0482 2.68067Z"
        stroke="#231815"
        strokeWidth="0.23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.1713 0.963869C69.7979 1.44712 69.2867 1.95583 68.7347 2.48364V6.52461H68.3364V2.84628C67.6383 3.45341 66.9137 3.99182 66.1367 4.43949L65.875 4.03017C66.0979 3.89569 66.977 3.39988 67.9405 2.57781C68.7256 1.90916 69.3575 1.26123 69.8033 0.724609L70.1713 0.963869Z"
        fill="#231815"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.1713 0.963869C69.7979 1.44712 69.2867 1.95583 68.7347 2.48364V6.52461H68.3364V2.84628C67.6383 3.45341 66.9137 3.99182 66.1367 4.43949L65.875 4.03017C66.0979 3.89569 66.977 3.39988 67.9405 2.57781C68.7256 1.90916 69.3575 1.26123 69.8033 0.724609L70.1713 0.963869Z"
        stroke="#231815"
        strokeWidth="0.23"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="116"
        height="29">
        <path d="M0 28.9996H116V0.724609H0V28.9996Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.0352 6.52461H73.7512V0.724609H73.0352V6.52461Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.0352 6.52461H73.7512V0.724609H73.0352V6.52461Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4506 5.07422C52.5104 5.17964 52.5735 5.2963 52.6404 5.42332C52.7976 5.723 52.9137 5.97343 52.9895 6.17183L52.7797 6.52422C52.7095 6.31009 52.5992 6.0493 52.4485 5.74098C52.3869 5.61516 52.329 5.5011 52.2734 5.39827L52.4506 5.07422Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4506 5.07422C52.5104 5.17964 52.5735 5.2963 52.6404 5.42332C52.7976 5.723 52.9137 5.97343 52.9895 6.17183L52.7797 6.52422C52.7095 6.31009 52.5992 6.0493 52.4485 5.74098C52.3869 5.61516 52.329 5.5011 52.2734 5.39827L52.4506 5.07422Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.114 5.79958C87.114 5.6694 87.0683 5.55639 86.9762 5.46126C86.8831 5.36614 86.7726 5.31959 86.6437 5.31959C86.5121 5.31959 86.3993 5.36614 86.3068 5.46126C86.215 5.55639 86.1684 5.6694 86.1684 5.79958C86.1684 5.93325 86.215 6.04539 86.3068 6.13892C86.3993 6.23244 86.5121 6.27942 86.6437 6.27942C86.7726 6.27942 86.8831 6.23172 86.9762 6.13688C87.0683 6.04161 87.114 5.92903 87.114 5.79958ZM87.3579 5.79958C87.3579 5.99987 87.2874 6.17106 87.1472 6.31215C87.0068 6.45397 86.8394 6.52422 86.644 6.52422C86.4458 6.52422 86.2759 6.45397 86.1355 6.31215C85.9958 6.17106 85.9258 5.99987 85.9258 5.79958C85.9258 5.5993 85.9958 5.42737 86.1355 5.28629C86.2759 5.14505 86.4458 5.07422 86.644 5.07422C86.8394 5.07422 87.0068 5.14505 87.1472 5.28629C87.2874 5.42737 87.3579 5.5993 87.3579 5.79958Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.114 5.79958C87.114 5.6694 87.0683 5.55639 86.9762 5.46126C86.8831 5.36614 86.7726 5.31959 86.6437 5.31959C86.5121 5.31959 86.3993 5.36614 86.3068 5.46126C86.215 5.55639 86.1684 5.6694 86.1684 5.79958C86.1684 5.93325 86.215 6.04539 86.3068 6.13892C86.3993 6.23244 86.5121 6.27942 86.6437 6.27942C86.7726 6.27942 86.8831 6.23172 86.9762 6.13688C87.0683 6.04161 87.114 5.92903 87.114 5.79958ZM87.3579 5.79958C87.3579 5.99987 87.2874 6.17106 87.1472 6.31215C87.0068 6.45397 86.8394 6.52422 86.644 6.52422C86.4458 6.52422 86.2759 6.45397 86.1355 6.31215C85.9958 6.17106 85.9258 5.99987 85.9258 5.79958C85.9258 5.5993 85.9958 5.42737 86.1355 5.28629C86.2759 5.14505 86.4458 5.07422 86.644 5.07422C86.8394 5.07422 87.0068 5.14505 87.1472 5.28629C87.2874 5.42737 87.3579 5.5993 87.3579 5.79958Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3809 2.73497H30.6297C30.4606 3.60986 30.3181 4.26796 30.2017 4.70697C30.4392 4.81793 30.6924 4.96526 30.9601 5.15045C31.2414 4.52492 31.3809 3.71982 31.3809 2.73497ZM32.2236 6.26774L31.9258 6.61184C31.6837 6.32727 31.4109 6.05791 31.1093 5.80277C30.798 6.30296 30.312 6.78464 29.6505 7.24961L29.3594 6.88253C29.9809 6.50701 30.4497 6.05791 30.7659 5.53242C30.4063 5.26736 30.0612 5.06662 29.7285 4.93252C29.8671 4.47234 30.0314 3.73999 30.2212 2.73497H29.4824V2.30737H30.2993C30.4287 1.578 30.5045 1.05102 30.5264 0.724609L30.9532 0.777522C30.9145 1.12295 30.8324 1.63289 30.7069 2.30737H31.8022V2.57756C31.7943 3.78794 31.6319 4.73293 31.3163 5.41221C31.6752 5.69281 31.977 5.97722 32.2236 6.26774Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3809 2.73497H30.6297C30.4606 3.60986 30.3181 4.26796 30.2017 4.70697C30.4392 4.81793 30.6924 4.96526 30.9601 5.15045C31.2414 4.52492 31.3809 3.71982 31.3809 2.73497ZM32.2236 6.26774L31.9258 6.61184C31.6837 6.32727 31.4109 6.05791 31.1093 5.80277C30.798 6.30296 30.312 6.78464 29.6505 7.24961L29.3594 6.88253C29.9809 6.50701 30.4497 6.05791 30.7659 5.53242C30.4063 5.26736 30.0612 5.06662 29.7285 4.93252C29.8671 4.47234 30.0314 3.73999 30.2212 2.73497H29.4824V2.30737H30.2993C30.4287 1.578 30.5045 1.05102 30.5264 0.724609L30.9532 0.777522C30.9145 1.12295 30.8324 1.63289 30.7069 2.30737H31.8022V2.57756C31.7943 3.78794 31.6319 4.73293 31.3163 5.41221C31.6752 5.69281 31.977 5.97722 32.2236 6.26774Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3809 2.73497H30.6297C30.4606 3.60986 30.3181 4.26796 30.2017 4.70697C30.4392 4.81793 30.6924 4.96526 30.9601 5.15045C31.2414 4.52492 31.3809 3.71982 31.3809 2.73497ZM32.2236 6.26774L31.9258 6.61184C31.6837 6.32727 31.4109 6.05791 31.1093 5.80277C30.798 6.30296 30.312 6.78464 29.6505 7.24961L29.3594 6.88253C29.9809 6.50701 30.4497 6.05791 30.7659 5.53242C30.4063 5.26736 30.0612 5.06662 29.7285 4.93252C29.8671 4.47234 30.0314 3.73999 30.2212 2.73497H29.4824V2.30737H30.2993C30.4287 1.578 30.5045 1.05102 30.5264 0.724609L30.9532 0.777522C30.9145 1.12295 30.8324 1.63289 30.7069 2.30737H31.8022V2.57756C31.7943 3.78794 31.6319 4.73293 31.3163 5.41221C31.6752 5.69281 31.977 5.97722 32.2236 6.26774Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3809 2.73497H30.6297C30.4606 3.60986 30.3181 4.26796 30.2017 4.70697C30.4392 4.81793 30.6924 4.96526 30.9601 5.15045C31.2414 4.52492 31.3809 3.71982 31.3809 2.73497ZM32.2236 6.26774L31.9258 6.61184C31.6837 6.32727 31.4109 6.05791 31.1093 5.80277C30.798 6.30296 30.312 6.78464 29.6505 7.24961L29.3594 6.88253C29.9809 6.50701 30.4497 6.05791 30.7659 5.53242C30.4063 5.26736 30.0612 5.06662 29.7285 4.93252C29.8671 4.47234 30.0314 3.73999 30.2212 2.73497H29.4824V2.30737H30.2993C30.4287 1.578 30.5045 1.05102 30.5264 0.724609L30.9532 0.777522C30.9145 1.12295 30.8324 1.63289 30.7069 2.30737H31.8022V2.57756C31.7943 3.78794 31.6319 4.73293 31.3163 5.41221C31.6752 5.69281 31.977 5.97722 32.2236 6.26774Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0064 1.44922H32.3633V1.85702H34.2882C34.2882 1.85702 33.9658 2.452 33.4418 2.99872V3.98841H32.2227V4.41766H33.4418V6.55505C33.4418 6.71779 33.3626 6.79884 33.2044 6.79884H32.5516L32.6434 7.24922H33.2656C33.6392 7.24922 33.8258 7.05399 33.8258 6.66307V4.41766H35.0869V3.98841H33.8258V3.12283C34.3107 2.56522 34.6129 2.13345 34.8977 1.64208C34.9344 1.579 35.0064 1.44922 35.0064 1.44922Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0064 1.44922H32.3633V1.85702H34.2882C34.2882 1.85702 33.9658 2.452 33.4418 2.99872V3.98841H32.2227V4.41766H33.4418V6.55505C33.4418 6.71779 33.3626 6.79884 33.2044 6.79884H32.5516L32.6434 7.24922H33.2656C33.6392 7.24922 33.8258 7.05399 33.8258 6.66307V4.41766H35.0869V3.98841H33.8258V3.12283C34.3107 2.56522 34.6129 2.13345 34.8977 1.64208C34.9344 1.579 35.0064 1.44922 35.0064 1.44922Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.448 3.24993C42.1912 3.09592 42.6967 2.94325 42.9643 2.83487L42.8087 2.46695C42.5594 2.56888 42.0378 2.72169 41.2477 2.8757C41.1049 2.5941 40.9772 2.30858 40.8649 2.01812C41.4565 1.92325 41.8779 1.80976 42.1736 1.74522L42.0631 1.35823C41.7977 1.44139 41.3375 1.54827 40.7287 1.64314C40.6033 1.27101 40.5282 0.964487 40.502 0.724609L40.0811 0.7794C40.1189 1.01928 40.2036 1.32791 40.3332 1.70499C39.7408 1.7859 39.2616 1.83033 38.875 1.84729L38.9595 2.24268C39.3539 2.22122 39.8599 2.16943 40.4694 2.07861C40.5861 2.38394 40.7133 2.67365 40.8518 2.95076C39.858 3.13254 39.1332 3.2064 38.668 3.22336L38.7648 3.61785C39.2869 3.59549 40.0399 3.51578 41.0467 3.32529C41.2662 3.72894 41.4894 4.06248 41.7138 4.32563C41.4708 4.27624 41.232 4.25087 40.9943 4.25087C40.5536 4.25087 40.1952 4.33253 39.919 4.49586C39.6114 4.67809 39.458 4.93118 39.458 5.25842C39.458 6.1028 40.1606 6.52461 41.5647 6.52461C41.6766 6.52461 41.7914 6.52266 41.9086 6.5177V6.09184C41.8065 6.09469 41.7088 6.09559 41.6167 6.09559C40.4585 6.09559 39.8793 5.80993 39.8793 5.23816C39.8793 5.04751 39.9855 4.8932 40.1973 4.77506C40.4091 4.65692 40.6785 4.59853 41.0072 4.59853C41.4011 4.59853 41.7938 4.68229 42.1874 4.85042L42.4268 4.53639C42.0989 4.25087 41.7717 3.82201 41.448 3.24993Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.448 3.24993C42.1912 3.09592 42.6967 2.94325 42.9643 2.83487L42.8087 2.46695C42.5594 2.56888 42.0378 2.72169 41.2477 2.8757C41.1049 2.5941 40.9772 2.30858 40.8649 2.01812C41.4565 1.92325 41.8779 1.80976 42.1736 1.74522L42.0631 1.35823C41.7977 1.44139 41.3375 1.54827 40.7287 1.64314C40.6033 1.27101 40.5282 0.964487 40.502 0.724609L40.0811 0.7794C40.1189 1.01928 40.2036 1.32791 40.3332 1.70499C39.7408 1.7859 39.2616 1.83033 38.875 1.84729L38.9595 2.24268C39.3539 2.22122 39.8599 2.16943 40.4694 2.07861C40.5861 2.38394 40.7133 2.67365 40.8518 2.95076C39.858 3.13254 39.1332 3.2064 38.668 3.22336L38.7648 3.61785C39.2869 3.59549 40.0399 3.51578 41.0467 3.32529C41.2662 3.72894 41.4894 4.06248 41.7138 4.32563C41.4708 4.27624 41.232 4.25087 40.9943 4.25087C40.5536 4.25087 40.1952 4.33253 39.919 4.49585C39.6114 4.67809 39.458 4.93118 39.458 5.25842C39.458 6.1028 40.1606 6.52461 41.5647 6.52461C41.6766 6.52461 41.7914 6.52266 41.9086 6.5177V6.09184C41.8065 6.09469 41.7088 6.09559 41.6167 6.09559C40.4585 6.09559 39.8793 5.80993 39.8793 5.23816C39.8793 5.04751 39.9855 4.8932 40.1973 4.77506C40.4091 4.65692 40.6785 4.59853 41.0072 4.59853C41.4011 4.59853 41.7938 4.68229 42.1874 4.85042L42.4268 4.53639C42.0989 4.25087 41.7717 3.82201 41.448 3.24993Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.616 5.87805C49.1864 6.02171 48.7604 6.09286 48.339 6.09286C47.5826 6.09286 47.2049 5.90304 47.2049 5.52327C47.2049 5.21693 47.525 4.89925 48.1658 4.57114V5.17032H48.5559V4.39727C49.0479 4.18738 49.5705 4.02991 50.1244 3.92428L50.0069 3.50693C49.4775 3.64124 48.9919 3.8007 48.5497 3.98683C48.5211 3.33292 48.267 3.00603 47.7875 3.00603C47.4642 3.00603 47.1841 3.08944 46.9438 3.25595C47.2171 2.84857 47.4237 2.46772 47.564 2.11585C48.4277 2.0605 49.0186 1.99074 49.3374 1.90748L49.3004 1.48278C49.0186 1.57125 48.4877 1.64285 47.7068 1.69835C47.8058 1.36994 47.8636 1.05379 47.8804 0.752514L47.4709 0.724609C47.467 1.02129 47.4007 1.35476 47.2722 1.72626C46.5738 1.76367 46.092 1.76597 45.8281 1.73346L45.847 2.17825C46.0909 2.18837 46.51 2.1761 47.1051 2.14391C46.813 2.81132 46.4442 3.37401 46.0005 3.83152L46.2796 4.11393C46.3142 4.07315 46.3485 4.03283 46.3798 3.99342C46.5487 3.7892 46.7602 3.63189 47.012 3.52058C47.2522 3.41847 47.4899 3.37462 47.725 3.38842C48.0191 3.40697 48.1658 3.63189 48.1658 4.06303V4.15303C47.2646 4.58402 46.813 5.05318 46.813 5.55853C46.813 5.87805 46.9622 6.12383 47.2604 6.29478C47.5289 6.44856 47.8988 6.52461 48.3701 6.52461C48.8002 6.52461 49.2276 6.46159 49.6538 6.33725C49.6458 6.26258 49.6371 6.18562 49.6289 6.10742C49.616 5.98246 49.6118 5.90596 49.616 5.87805Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.616 5.87805C49.1864 6.02171 48.7604 6.09286 48.339 6.09286C47.5826 6.09286 47.2049 5.90304 47.2049 5.52327C47.2049 5.21693 47.525 4.89925 48.1658 4.57114V5.17032H48.5559V4.39727C49.0479 4.18738 49.5705 4.02991 50.1244 3.92428L50.0069 3.50693C49.4775 3.64124 48.9919 3.8007 48.5497 3.98683C48.5211 3.33291 48.267 3.00603 47.7875 3.00603C47.4642 3.00603 47.1841 3.08944 46.9438 3.25595C47.2171 2.84857 47.4237 2.46772 47.564 2.11585C48.4277 2.0605 49.0186 1.99074 49.3374 1.90748L49.3004 1.48278C49.0186 1.57125 48.4877 1.64285 47.7068 1.69835C47.8058 1.36994 47.8636 1.05379 47.8804 0.752514L47.4709 0.724609C47.467 1.02129 47.4007 1.35476 47.2722 1.72626C46.5738 1.76367 46.092 1.76597 45.8281 1.73346L45.847 2.17825C46.0909 2.18837 46.51 2.1761 47.1051 2.14391C46.813 2.81132 46.4442 3.37401 46.0005 3.83152L46.2796 4.11393C46.3142 4.07315 46.3485 4.03283 46.3798 3.99342C46.5487 3.7892 46.7602 3.63189 47.012 3.52058C47.2522 3.41847 47.4899 3.37462 47.725 3.38842C48.0191 3.40697 48.1658 3.63189 48.1658 4.06303V4.15303C47.2646 4.58402 46.813 5.05318 46.813 5.55853C46.813 5.87805 46.9622 6.12383 47.2604 6.29478C47.5289 6.44856 47.8988 6.52461 48.3701 6.52461C48.8002 6.52461 49.2276 6.46159 49.6538 6.33725C49.6458 6.26258 49.6371 6.18562 49.6289 6.10742C49.616 5.98246 49.6118 5.90596 49.616 5.87805Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.4333 3.0669C59.2841 3.60652 59.0267 4.37477 58.7512 4.8658C58.4595 5.39642 57.9493 6.18462 57.5888 6.52441L57.2852 6.16708C57.6294 5.87088 58.1362 5.10473 58.4343 4.57459C58.7143 4.08308 58.9563 3.35634 59.0709 2.89941L59.4333 3.0669Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.4333 3.0669C59.2841 3.60652 59.0267 4.37477 58.7512 4.8658C58.4595 5.39642 57.9493 6.18462 57.5888 6.52441L57.2852 6.16708C57.6294 5.87088 58.1362 5.10473 58.4343 4.57459C58.7143 4.08308 58.9563 3.35634 59.0709 2.89941L59.4333 3.0669Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.2966 6.2517L61.9044 6.52441C61.7824 6.15663 61.2102 4.8402 60.9239 4.331C60.6369 3.82085 60.378 3.43693 60.1484 3.17861L60.4457 2.89941C60.6949 3.18114 60.9661 3.56664 61.2584 4.05433C61.5399 4.52731 62.1301 5.84627 62.2966 6.2517Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.2966 6.2517L61.9044 6.52441C61.7824 6.15663 61.2102 4.8402 60.9239 4.331C60.6369 3.82085 60.378 3.43693 60.1484 3.17861L60.4457 2.89941C60.6949 3.18114 60.9661 3.56664 61.2584 4.05433C61.5399 4.52731 62.1301 5.84627 62.2966 6.2517Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.0835 1.44922C78.9377 2.11964 78.7656 3.16709 78.7656 4.58876C78.7656 5.46364 78.8987 6.10905 78.967 6.52422L79.3666 6.43918C79.2979 6.062 79.1643 5.43785 79.1643 4.56844C79.1643 3.16459 79.3354 2.15544 79.4817 1.54144L79.0835 1.44922Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.0835 1.44922C78.9377 2.11964 78.7656 3.16709 78.7656 4.58876C78.7656 5.46364 78.8987 6.10905 78.967 6.52422L79.3666 6.43918C79.2979 6.062 79.1643 5.43785 79.1643 4.56844C79.1643 3.16459 79.3354 2.15544 79.4817 1.54144L79.0835 1.44922Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.0515 2.71079L83.0622 2.1748C82.6272 2.29464 81.4444 2.38651 80.9141 2.35692L80.9416 2.8998C81.7504 2.8733 82.5078 2.81701 83.0515 2.71079Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.0515 2.71079L83.0622 2.1748C82.6272 2.29464 81.4444 2.38651 80.9141 2.35692L80.9416 2.8998C81.7504 2.8733 82.5078 2.81701 83.0515 2.71079Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.1899 5.98194C81.9085 5.95504 81.7046 5.90177 81.5788 5.82485C81.3657 5.69971 81.2694 5.48538 81.2889 5.18094C81.2913 5.1571 81.2942 5.13363 81.2967 5.10889L80.9315 5.07422C80.9254 5.11972 80.9215 5.16649 80.9183 5.21073C80.9054 5.41279 80.9218 5.58866 80.9689 5.74106C81.0514 6.10491 81.3089 6.33839 81.7402 6.43933C81.8863 6.47039 82.0402 6.49332 82.2035 6.50867C82.6819 6.55507 83.3054 6.4926 83.7783 6.38787V5.8402C83.3181 5.99242 82.7878 6.03973 82.1899 5.98194Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.1899 5.98194C81.9085 5.95504 81.7046 5.90177 81.5788 5.82485C81.3657 5.69971 81.2694 5.48538 81.2889 5.18094C81.2913 5.1571 81.2942 5.13363 81.2967 5.10889L80.9315 5.07422C80.9254 5.11972 80.9215 5.16649 80.9183 5.21073C80.9054 5.41279 80.9218 5.58866 80.9689 5.74106C81.0514 6.10491 81.3089 6.33839 81.7402 6.43933C81.8863 6.47039 82.0402 6.49332 82.2035 6.50867C82.6819 6.55507 83.3054 6.4926 83.7783 6.38787V5.8402C83.3181 5.99242 82.7878 6.03973 82.1899 5.98194Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.8994 4.01363C75.8321 4.12272 75.7823 4.17356 75.6691 4.34941C75.4061 4.23627 73.3041 3.3753 73.0352 3.22291L73.2783 2.89941C73.6134 3.06451 75.531 3.85523 75.8994 4.01363Z"
          fill="#231815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.8994 4.01363C75.8321 4.12272 75.7823 4.17356 75.6691 4.34941C75.4061 4.23627 73.3041 3.3753 73.0352 3.22291L73.2783 2.89941C73.6134 3.06451 75.531 3.85523 75.8994 4.01363Z"
          stroke="#231815"
          strokeWidth="0.23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.6039 16.9423C81.4962 16.5334 81.3305 15.8775 81.2692 15.6335L81.2501 15.5612C81.1881 15.332 81.0487 15.2246 80.8762 15.2246L79.7273 15.2269C79.5774 15.2246 79.5301 15.3273 79.5223 15.3401C79.4814 15.4178 79.4674 15.5057 79.4943 15.6117C79.5216 15.7264 79.7555 16.6169 79.8822 17.0703C79.9243 17.2198 80.0312 17.3996 80.3044 17.3996L81.3295 17.3981C81.4927 17.3981 81.5564 17.3333 81.5898 17.2749C81.6306 17.2031 81.6453 17.0933 81.6039 16.9423Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.093 15.5449C79.0419 15.3421 78.9036 15.2246 78.7236 15.2246L77.5783 15.2279C77.4483 15.2279 77.3993 15.2923 77.3739 15.3373C77.3314 15.4106 77.3199 15.5097 77.3456 15.6124C77.3745 15.7318 77.6065 16.6215 77.7321 17.0693C77.7753 17.2244 77.882 17.3996 78.153 17.3996L79.1828 17.3973C79.3317 17.3965 79.4039 17.3363 79.4378 17.2758C79.4851 17.1955 79.4951 17.0917 79.4561 16.9431C79.3298 16.4586 79.1149 15.6327 79.093 15.5449Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4943 27.1845C26.4956 28.1857 25.695 28.9986 24.7065 29C23.7182 29.0027 22.9149 28.1921 22.9141 27.1905C22.9117 26.1885 23.7131 25.376 24.701 25.375C25.6901 25.3726 26.4931 26.1837 26.4943 27.1845Z"
          fill="#00ABEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.8214 15.5137C28.1075 14.8398 27.787 14.1033 27.0951 13.8528C26.4053 13.6008 25.7301 13.9836 25.3759 14.6116C24.8026 15.6297 21.8302 21.2444 21.3321 22.1753C20.8331 23.106 20.2058 24.4363 19.6834 24.2334C19.2041 24.0478 18.6391 22.0069 18.3979 21.4244C17.9711 20.3883 17.1019 19.1596 15.6515 19.4718C14.5433 19.7099 13.9131 20.579 12.9106 22.497C12.3601 23.5501 12.0147 24.2452 11.5129 24.9264C10.4845 26.3266 9.90292 24.0264 9.56362 22.8369C9.10713 21.2399 8.27356 18.9803 6.45738 18.9834C5.44227 18.9843 4.36049 19.5779 3.01354 21.7498C2.22816 23.0179 0.390058 26.5657 0.11868 27.1988C-0.170107 27.8705 0.0782683 28.6005 0.753451 28.8896C1.42817 29.1767 2.09092 28.8777 2.48788 28.2627C3.77918 26.262 4.13403 25.4812 4.7946 24.5624C5.50196 23.5844 6.46344 22.7075 7.19908 25.0585C7.66164 26.5372 8.47096 28.9641 10.6498 28.9785C11.9608 28.9881 13.3179 27.8598 13.9539 26.7829C14.6712 25.5664 14.851 24.9181 15.3989 24.3309C15.6788 24.0317 16.0938 23.9237 16.4874 24.7926C16.8668 25.6318 17.6518 28.2872 19.0498 28.8662C21.0237 29.6821 22.8814 25.7031 23.5224 24.4346C24.0403 23.4119 27.5932 16.0509 27.8214 15.5137Z"
          fill="#00ABEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.5662 16.1025C43.4663 15.9978 43.3201 15.9492 43.1355 15.9492H43.1445C42.5633 15.9492 31.2031 15.9651 31.2031 15.9651C31.0988 15.9651 30.9848 16.0024 30.8916 16.1116C30.8307 16.1754 30.7891 16.3017 30.7891 16.4097L30.7901 17.9957C30.7901 18.2205 30.974 18.4193 31.2045 18.4193H31.2716C31.2716 18.4193 39.5988 18.4072 39.9749 18.4072H40.3288C40.5822 18.4072 40.6534 18.4551 40.7121 18.5365C40.8436 18.7171 40.7076 19.0048 40.6891 19.0748C40.1144 21.2533 38.8543 22.9377 37.0457 23.9472L36.9434 24.0044L36.8749 23.9056C36.4306 23.2675 36.0968 22.8261 35.7102 22.3158L35.6555 22.239C35.4842 22.0037 35.2688 21.8974 35.1191 21.8974H35.118L33.1027 21.9004C32.9895 21.9004 32.88 21.9469 32.8241 22.0843C32.7902 22.1729 32.7828 22.2993 32.8548 22.4147C34.5886 24.7749 35.7682 26.709 36.8255 28.7182C36.9112 28.8813 37.0632 28.9992 37.2767 28.9992H37.233H39.4626C39.5729 28.9992 39.651 28.9402 39.7074 28.8336C39.738 28.7699 39.777 28.6529 39.7069 28.5238C39.3835 27.8807 39.1594 27.4461 38.472 26.3822L38.3988 26.2676L38.506 26.1885C41.0611 24.3073 43.451 21.9094 43.677 16.4838C43.683 16.3439 43.6617 16.2127 43.5662 16.1025Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.7706 16.094C55.6684 15.9707 55.5564 15.9535 55.4495 15.9492C55.2215 15.9521 54.1387 15.9579 53.4786 15.9611C53.2662 15.9611 53.0686 16.1382 53.0082 16.2846C51.8817 18.9728 48.611 21.003 44.1151 21.7849C43.8256 21.8435 43.6857 22.0224 43.6797 22.2249L43.6826 23.8455C43.6797 23.9834 43.7412 24.0877 43.8141 24.1553C43.9434 24.2695 44.0732 24.2845 44.1769 24.2717C46.3503 23.9954 48.0241 23.547 49.3453 22.9132L49.5339 22.824L49.5409 28.5698C49.5409 28.8003 49.7139 28.9992 49.9675 28.9992H50.0553L51.6646 28.9976C51.8852 28.9976 52.0562 28.7823 52.0562 28.5967L52.0458 21.5883L52.1039 21.5472C53.8029 20.3399 55.2804 18.3651 55.8421 16.4359C55.8751 16.2891 55.8238 16.1626 55.7706 16.094Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.2222 21.6451L65.2289 21.5162L69.0443 21.5048C69.1355 21.5048 69.2486 21.4695 69.3369 21.3774C69.4027 21.312 69.4553 21.1859 69.4553 21.0901L69.4528 19.5497C69.4528 19.4207 69.4069 19.3285 69.3466 19.2589C69.2542 19.1556 69.1417 19.1208 69.037 19.1208H68.9948L65.2491 19.1232L65.246 16.3767C65.246 16.1883 65.1122 15.9492 64.8131 15.9492H64.7529L63.1287 15.9524C63.0098 15.9524 62.906 16.0008 62.8274 16.086C62.7665 16.1481 62.7169 16.2589 62.7169 16.3756L62.7203 19.1268L56.9972 19.1325C56.9066 19.1325 56.7874 19.1534 56.683 19.2635C56.6191 19.3285 56.5664 19.439 56.5664 19.5728L56.5692 21.1205C56.5692 21.3109 56.7208 21.5316 56.9939 21.5316H57.0487L62.7021 21.5197L62.6927 21.6649C62.4916 25.0182 60.9224 25.9743 57.9715 26.593C57.7139 26.6559 57.5679 26.7852 57.5679 27.0151L57.5699 28.6077C57.5711 28.7286 57.6184 28.8279 57.7008 28.8955C57.7806 28.9638 57.9081 29.0113 58.0123 28.9965C62.9548 28.2961 64.9703 26.1768 65.2222 21.6451Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.0171 19.2768C81.9505 19.2106 81.846 19.1608 81.7459 19.1608H81.6752L73.3529 19.1737L73.3474 16.3924C73.3474 16.2505 73.2921 16.1474 73.2285 16.0805C73.1398 15.9861 73.0218 15.9492 72.9071 15.9492H72.8939L71.3036 15.9531C71.0945 15.9531 70.8906 16.1259 70.8906 16.3842L70.9035 25.7047C70.9035 28.0238 71.791 28.9992 74.2602 28.9992H74.4575L81.9609 28.9851C82.1749 28.9851 82.3474 28.767 82.3474 28.5671L82.3449 26.9815C82.3449 26.7673 82.1838 26.5488 81.9307 26.5488L75.0774 26.5555H75.068C74.445 26.5555 73.987 26.5141 73.7203 26.2893C73.3869 26.0017 73.3539 25.5875 73.3606 24.9389L73.356 21.5884L81.7265 21.5755C81.9758 21.5755 82.1465 21.3724 82.1465 21.1405L82.144 19.5847C82.144 19.4277 82.0743 19.3369 82.0171 19.2768Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.8653 19.3752C94.8321 19.2814 94.8321 19.2012 94.8321 19.1783C94.8321 18.9166 95.1039 18.8496 95.2226 18.8496C95.4508 18.8496 95.5163 18.9867 95.6675 19.3626C95.7654 19.5919 95.9505 20.1182 95.9505 20.3349C95.9505 20.6656 95.625 20.7012 95.56 20.7012C95.3091 20.7012 95.2666 20.5633 95.0604 19.89C95.0281 19.8092 94.8869 19.4415 94.8653 19.3752ZM93.5846 19.4989C93.5635 19.4316 93.5419 19.339 93.5419 19.2597C93.5419 19.0097 93.8128 18.9391 93.9429 18.9391C94.1495 18.9391 94.2364 19.0987 94.3987 19.5115C94.5065 19.7968 94.6484 20.2772 94.6484 20.4381C94.6484 20.8028 94.2898 20.8028 94.2686 20.8028C94.1495 20.8028 94.0189 20.7451 93.9429 20.5633C93.8879 20.4025 93.8128 20.1399 93.758 19.9789C93.7364 19.9125 93.6173 19.5705 93.5846 19.4989ZM93.1621 20.3922C93.411 22.6548 93.7044 25.1939 95.2877 27.4909C95.4841 27.7636 95.592 27.9463 95.592 28.2088C95.592 28.6445 95.115 28.9996 94.7893 28.9996C94.4095 28.9996 94.1716 28.7365 93.6495 27.8439C92.0009 24.9884 91.7179 20.6075 91.7179 20.4025C91.7179 20.1851 91.7294 19.7746 92.3369 19.6825C93.0418 19.5705 93.1286 20.0827 93.1621 20.3922ZM89.7873 20.4025C89.7873 20.5633 89.5271 24.9664 87.8573 27.8573C87.302 28.8169 87.075 28.987 86.7281 28.987C86.3696 28.987 85.9258 28.6222 85.9258 28.2088C85.9258 27.9235 86.067 27.7187 86.2298 27.4909C87.8021 25.2062 88.0838 22.7026 88.3451 20.3922C88.3872 20.0599 88.4758 19.5705 89.181 19.6825C89.7446 19.7746 89.7873 20.1278 89.7873 20.4025Z"
          fill="#EE7800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.246 28.2491C103.246 28.5461 103.187 28.9996 102.428 28.9996C101.855 28.9996 101.598 28.76 101.598 28.2491V23.373C100.032 24.1235 98.0108 24.8185 97.4381 24.8185C96.7949 24.8185 96.668 24.2156 96.668 23.964C96.668 23.4874 96.9718 23.3953 97.3337 23.3022C100.419 22.5037 103.001 21.0011 104.006 20.1583L105.208 19.1438C105.524 18.8811 105.665 18.8496 105.816 18.8496C106.236 18.8496 106.693 19.1994 106.693 19.6703C106.693 19.9092 106.61 20.0773 106.329 20.3305C105.665 20.9209 104.672 21.6974 103.246 22.5038V28.2491Z"
          fill="#EE7800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.021 28.2195C111.021 28.4801 111.001 28.9996 110.297 28.9996C109.587 28.9996 109.555 28.5121 109.555 28.2195V19.6446C109.555 19.3832 109.565 18.8496 110.278 18.8496C110.991 18.8496 111.021 19.3399 111.021 19.6446V21.7515C112.416 22.2052 113.778 22.8172 115.08 23.5414C115.834 23.9721 115.999 24.141 115.999 24.5278C115.999 24.9244 115.742 25.4565 115.277 25.4565C115.038 25.4565 114.813 25.3077 114.389 25.0355C113.801 24.6514 112.942 24.1876 112.353 23.8931C111.692 23.5637 111.321 23.4393 111.021 23.3246V28.2195Z"
          fill="#EE7800"
        />
      </g>
    </svg>
  );
}

export default Icon;
