import React from 'react';
import styled from 'styled-components';
import {useTable, Cell} from 'react-table';
import {useQueryParam, NumberParam} from 'use-query-params';
import {useCurrentUserQuery, useOfferedClientsQuery, Team} from 'api';
import Pagination from 'components/Ui/Pagination';
import {BuildingEllipse} from 'components/Ui/Icon';
import {sideMenuWidth} from 'helpers/sideMenu';

export default () => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  if (!currentUser) return null;

  const {
    data: {offeredClients: {offeredClients = [], pagination = {}} = {}} = {},
  } = useOfferedClientsQuery({
    variables: {page: page || 1},
    fetchPolicy: 'cache-and-network',
  });

  const data = React.useMemo(() => offeredClients, [offeredClients]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: '依頼先',
        accessor: 'name',
        Cell: ({value}: Cell<Team>) => (
          <NameBox>
            <BuildingEllipse />
            <span>{value}</span>
          </NameBox>
        ),
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <Main>
      <Content>
        <Header>
          <Title>Share設定</Title>
        </Header>
        <Body>
          <table {...getTableProps}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, j) => (
                    <th {...column.getHeaderProps()} key={j}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`row-${i}`}>
                    {row.cells.map((cell, j) => {
                      return (
                        <td {...cell.getCellProps()} key={`cell-${j}`}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Body>
      </Content>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={50}
        onChange={(page) => setPage(page)}
      />
    </Main>
  );
};

const Main = styled.div`
  background: #fff;
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  height: calc(100vh - 130px);
  overflow-x: hidden;
`;

const Content = styled.div`
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  padding: 45px 70px 90px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 43px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.15px;

  color: #495058;
`;

const Body = styled.div`
  margin-top: 25px;

  table {
    width: 100%;

    thead {
      background: #f3f5f7;
      border-bottom: 1px solid #e1e6eb;

      th {
        padding: 12px 13px;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0.15px;

        color: #495058;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #e1e6eb;
      }

      td {
        padding: 16px 13px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.15px;

        a {
          color: #222426;
        }
      }
    }
  }
`;

const NameBox = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
