import React from 'react';
import styled from 'styled-components';
import {Form} from 'components/antd';
import {useFormik} from 'formik';
import useSearchParams from '../../useSearchParams';
import {InputNumber} from 'components';

const CapitalFundFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {capitalFund = {from: null, to: null}} = query;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      capitalFund,
    },
    onSubmit: (values) => {
      setQuery(values);
    },
  });

  return (
    <Container>
      <Form.Item>
        <StyledInputNumber
          min={1}
          value={formik.values.capitalFund?.from}
          onChange={(value) => formik.setFieldValue('capitalFund.from', value)}
          onBlur={() => formik.handleSubmit()}
        />
        <span> 万 〜 </span>
        <StyledInputNumber
          min={1}
          value={formik.values.capitalFund?.to}
          onChange={(value) => formik.setFieldValue('capitalFund.to', value)}
          onBlur={() => formik.handleSubmit()}
        />
        <span> 万</span>
      </Form.Item>
    </Container>
  );
};

const Container = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  border: 1px solid rgb(217, 217, 217) !important;
  width: 120px;
`;

export default CapitalFundFilter;
