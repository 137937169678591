import React from 'react';
import {useParams, Switch, Route, Redirect} from 'react-router-dom';
import {Form} from 'api';
import Tab from './Tab'
import Blocks from './blocks'
import Settings from './settings'

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {formId} = useParams<{
    formId: string;
  }>();

  return (
    <div className="w-[360px]">
      <Tab form={form} />
      <Switch>
        <Route path="/form/forms/:formId/form/settings">
           <Settings form={form} />
        </Route>
        <Route path="/form/forms/:formId/form/blocks">
           <Blocks form={form} />
        </Route>
        <Redirect to={`/form/forms/${formId}/form/settings`} />
      </Switch>
    </div>
  );
};
