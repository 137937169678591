import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import {Form, FormBlockFieldStandard} from 'api';

interface Props {
  onClick: (variable: string) => void;
  form: Form;
  setError: (error: string) => void;
}

export default ({form, onClick, setError}: Props) => {
  const options = form.formPages
    .flatMap((page) => page.formBlocks)
    .flatMap((block) => {
      const blockable = block.formBlockable;

      if (blockable.__typename === 'FormBlockFieldStandard') {
        if (blockable.standardFieldType === 'name') {
          return [blockable.standardFieldTypeText, '姓', '名'];
        }
        return blockable.standardFieldTypeText;
      }
    })
    .filter((option) => option);

  if (!options) return null;

  React.useEffect(() => {
    const body = form.autoreplyMailBodyHtml || '';
    const embeddedOptions = body
      .match(/{{(.*?)}}/g)
      ?.map((option) => option.replace(/\{/g, '').replace(/\}/g, ''));

    const diff = embeddedOptions?.filter(
      (option) => options.indexOf(option) == -1,
    );

    if (diff && diff.length > 0) {
      setError(`利用されていない値が埋め込まれています (${diff.join(', ')})`);
    } else {
      setError(null);
    }
  }, [form.autoreplyMailBodyHtml, options]);

  return (
    <Menu as="div" className="absolute bottom-2 right-2 inline-block text-left">
      <Menu.Button className="text-c-primary border border-c-border rounded bg-white cursor-pointer w-8 flex items-center justify-center">
        {'{ }'}
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="w-48 absolute right-0 bottom-7 divide-y rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {options.map((option) => (
            <Menu.Item key={option}>
              <button
                onClick={() => onClick(`{{${option}}}`)}
                className="border-none w-full py-1 px-4 text-sm cursor-pointer bg-white text-left hover:bg-[#F1F8FF]">
                <span>{`{{${option}}}`}</span>
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
