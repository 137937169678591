import gql from 'graphql-tag';
import automaticMailSendMailMessageFragment from './automaticMailSend';
import manualMailMailMessageFragment from './manualMail';
import manualMailActionMailMessageFragment from './manualMailAction';
import gmailImportMailMessageFragment from './gmailImport';

export default gql`
  fragment mailMessageFragment on MailMessage {
    id
    uuid
    sendAt
    updatedAt
    createdAt
    messageable {
      ... on AutomaticMailSendMailMessage {
        ...automaticMailSendMailMessageFragment
      }
      ... on ManualMailMailMessage {
        ...manualMailMailMessageFragment
      }
      ... on ManualMailActionMailMessage {
        ...manualMailActionMailMessageFragment
      }
      ... on GmailImportMailMessage {
        ...gmailImportMailMessageFragment
      }
    }
  }
  ${automaticMailSendMailMessageFragment}
  ${manualMailMailMessageFragment}
  ${manualMailActionMailMessageFragment}
  ${gmailImportMailMessageFragment}
`;
