import React from 'react';
import {useHistory} from 'react-router';
import * as Yup from 'yup';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Button} from 'components/antd';
import {Close} from 'components/Icon';
import {useFormik} from 'formik';
import {Form, Input, Select, message} from 'components/antd';
import {useInviteUserMutation} from 'api';
// @ts-ignore
import EmailLightIcon from './email-light.svg';

type Props = {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
};

const validateSchema = Yup.object().shape({
  email: Yup.string()
    .email('メールアドレス形式ではありません')
    .required('必須項目です'),
});

export default ({isOpen, setModal}: Props) => {
  const history = useHistory();
  const [inviteUser, {loading, data, error}, ,] = useInviteUserMutation({
    refetchQueries: ['usersByClient', 'allClientUsers'],
    onCompleted: ({inviteUser = {}, inviteUser: {clientUser = {}} = {}}) => {
      if (!error && !inviteUser.error) {
        setModal(false);
        message.success('招待しました');
        history.push(`/settings/users?active=${clientUser.isActive ? 0 : 1}`);
      }
      if (inviteUser.error) {
        message.info(inviteUser.error);
      }
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      clientRole: 'owner',
      teamIds: [],
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      inviteUser({
        variables: {user: values},
      });
    },
  });

  const {
    dirty,
    isValid,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          borderRadius: '4px',
          width: '466px',
          height: '357px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
        },
      }}
      contentLabel="modal">
      <ModalContent>
        <ModalHeader>
          <Button onClick={() => setModal(false)}>
            <Close />
          </Button>
        </ModalHeader>
        <ModalBody>
          <h1>新規招待</h1>
          <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 12}}
            onFinish={handleSubmit}>
            <Form.Item
              label="メールアドレス"
              required
              validateStatus={errors.email && touched.email ? 'error' : ''}
              help={errors.email && touched.email ? errors.email : ''}>
              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="新規ユーザーをメールアドレスから招待"
                prefix={<EmailLightIcon />}
              />
              {error && !errors.email && <Alert>{data.inviteUser.error}</Alert>}
            </Form.Item>
            <Form.Item
              label="権限"
              validateStatus={
                errors.clientRole && touched.clientRole ? 'error' : ''
              }
              help={
                errors.clientRole && touched.clientRole ? errors.clientRole : ''
              }>
              <Select
                showSearch
                value={values.clientRole}
                onChange={(value) => setFieldValue('clientRole', value)}
                onBlur={() => setFieldTouched('clientRole', true, true)}>
                <Select.Option value="owner">オーナー</Select.Option>
                <Select.Option value="member">メンバー</Select.Option>
                <Select.Option value="guest">ゲスト</Select.Option>
              </Select>
            </Form.Item>
            <ButtonWrapper>
              <CancelButton onClick={() => setModal(false)}>
                キャンセル
              </CancelButton>
              <Submit
                htmlType="submit"
                disabled={!isValid || loading || !dirty}
                loading={loading}>
                招待
              </Submit>
            </ButtonWrapper>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  height: 50px;
  display: flex;

  button {
    margin: auto 0 auto auto;
    box-shadow: none;
    border: none;
    display: flex;

    svg {
      margin: auto 0;
      height: 16px;
      width: 25px;

      path {
        fill: #bdc4cb;
      }
    }

    span {
      margin: auto 0;
      color: #bdc4cb;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 51px;

  h1 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 auto;
    padding-bottom: 14px;
    color: #495058;
  }

  &&& {
    .ant-form {
      .ant-form-item-has-error {
        .ant-input-affix-wrapper {
          border-color: #ba4047;
        }
      }

      .ant-row {
        display: block;
        margin: 0;

        .ant-col {
          width: 100%;
          max-width: 100%;
          margin: 0;
          flex: 0 1 100%;
          display: flex;
          justify-content: flex-start;

          &.ant-form-item-label {
            > label {
              height: 17px;
              font-size: 12px;
              color: #495058;
              margin-bottom: 8px;

              &:after {
                content: ':';
              }
            }
          }

          .ant-form-item-control-input {
            display: block;
            width: 100%;

            input[type='email'] {
              height: 30px;
              border-radius: 4px;
            }

            .ant-select-selector {
              height: 39px;

              .ant-select-selection-search {
                input {
                  height: 100%;
                }
              }

              .ant-select-selection-item {
                padding-top: 4px;
              }
            }

            .ant-input-affix-wrapper {
              align-items: center;

              .ant-input-prefix {
                height: 18px;
                width: 18px;
                margin-right: 10px;
              }
            }
          }

          .ant-form-item-explain.ant-form-item-explain-error {
            color: #ba4047;
            font-size: 14px;
          }
        }
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled.button`
  padding: 11px 14px;
  border: 1px solid #bdc4cb;
  border-radius: 4px;
  background: #fff;
  font-size: 12px;
  font-weight: bold;
  color: #899098;
  outline: 0;
  cursor: pointer;
  margin-right: 9px;
`;

const Submit = styled(Button)`
  display: block;
  padding: 11px 19px;
  border: none;
  border-radius: 4px;
  background: #68b5fb;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  outline: 0;
  height: auto;
  cursor: pointer;
`;

const Alert = styled.div`
  color: #ba4047;
  font-size: 14px;
`;
