import React from 'react';
import {FieldValue} from './FieldValue';
import {SalesforceAccount, SalesforceAccountField, usePoolQuery} from 'api';
import LinkIcon from '../LinkIcon';

interface Props {
  salesforceAccount: SalesforceAccount;
}

export default ({salesforceAccount}: Props) => {
  const fields =
    salesforceAccount?.salesforceAccountRecordType?.salesforceAccountFields?.filter(
      (field: SalesforceAccountField) => field?.isDisplay,
    ) || [];

  const {data: {pool: {poolSalesforceSetting = {}} = {}} = {}} = usePoolQuery(
    {},
  );

  const url = poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  if (!salesforceAccount) {
    return null;
  }

  return (
    <div
      className={`flex w-full flex-col rounded-sm border border-c-border bg-white px-8`}>
      <div className="flex h-12 justify-between gap-2 border-b border-b-c-border items-center">
        <div className="flex items-center gap-1 h-full">
          <h3 className="m-0 text-base font-bold">{salesforceAccount.name}</h3>
          <a
            target="_blank"
            className="h-full flex items-center"
            href={`${url}/lightning/r/Account/${salesforceAccount.salesforceAccountId}/view`}>
            <LinkIcon />
          </a>
        </div>
        {salesforceAccount?.salesforceOwner && (
          <div>
            <span className="text-c-light text-xm mr-2">所有者</span>
            <span>
              {salesforceAccount.salesforceOwner.lastName}{' '}
              {salesforceAccount.salesforceOwner.firstName}
            </span>
          </div>
        )}
      </div>
      <div className="flex h-12 items-center gap-2 border-b border-b-c-border">
        <span className="text-c-light">レコードタイプ</span>
        <div>{salesforceAccount.salesforceAccountRecordType?.name}</div>
      </div>
      <div className="flex flex-col gap-2 py-4">
        {fields.map((field, i) => (
          <FieldValue
            field={field}
            key={i}
            salesforceAccount={salesforceAccount}
          />
        ))}
      </div>
    </div>
  );
};
