import Name from './Name';
import AccountGroup from './AccountGroup';
import User from './User';
import Stats from './Stats';
import AccountStatus from './AccountStatus';

export default {
  Name,
  AccountGroup,
  User,
  Stats,
  AccountStatus,
};
