import {Button} from 'components/antd';
import {Download} from 'components/Ui/Icon';
import React from 'react';
import styled from 'styled-components';
import CallRateReport from './CallRateReport';
import ConversionRateReport from './ConversionRateReport';
import DownloadModal from './DownloadModal';
import StatusReport from './StatusReport';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <Container>
      <DownloadButton onClick={() => setModalOpen(true)}>
        <Download />
        ダウンロード
      </DownloadButton>
      <StatusReport />
      <div>
        <ConversionRateReport />
        <CallRateReport />
      </div>

      {isModalOpen && (
        <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;

  > div {
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-left: 60px;
    }
  }
`;

const DownloadButton = styled(Button)`
  position: absolute;
  top: -222px;
  right: 0px;

  display: flex;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  > svg {
    margin-right: 6px;
  }

  > span {
    margin: auto 0;
    font-weight: bold;
    font-size: 12px;
    color: #495058;
  }
`;
