import React from 'react';
import styled from 'styled-components';
import Stats from './Stats';
import List from './List';

export default () => (
  <>
    <Stats />
    <Container>
      <List />
    </Container>
  </>
);

const Container = styled.div`
  padding-top: 1rem;
`;
