import React from 'react';
import {FormBlock, FormBlockPersonalInformation} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockPersonalInformation;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;

  return (
    <div className="text-center text-sm font-bold [&_p]:m-0">
      <div dangerouslySetInnerHTML={{__html: blockable.textHtml}} />
    </div>
  );
};
