import {usePoolQuery, useUserSumReportQuery} from 'api';
import React from 'react';
import {Link} from 'react-router-dom';

const useUserSumReport = () => {
  const {data: {pool = {}} = {}} = usePoolQuery({});
  const {data: {userSumReport = null} = {}} = useUserSumReportQuery({});
  const sumValues = (values: number[]): number => {
    const total = values
      .filter(Boolean)
      .reduce((accumulator, currentValue) => accumulator + currentValue, null);
    return total;
  };

  const LinkProspects = userSumReport && sumValues(userSumReport.values) && (
    <Link to={`/pools/${pool?.uuid}/prospects`} key={`sum`} target="_blank">
      {sumValues(userSumReport.values)}
    </Link>
  );

  const LinksProspectsAssignee = userSumReport?.keyValueArray.map(
    ([userId, value]) =>
      value && (
        <Link
          to={`/pools/${pool?.uuid}/prospects?assignees=${userId || 'none'}`}
          target="_blank"
          key={userId}>
          {value}
        </Link>
      ),
  );

  const sumReport = React.useMemo(
    () =>
      userSumReport && {
        label: userSumReport.name,
        values: [LinkProspects, ...LinksProspectsAssignee],
      },
    [userSumReport, pool],
  );

  return sumReport;
};

export default useUserSumReport;
