import gql from 'graphql-tag';

export default gql`
  fragment formBlockImageFragment on FormBlockImage {
    id
    link
    image
    imageScale
    imageWidth
    createdAt
    updatedAt
  }
`;
