import React from 'react';
import moment from 'moment';
import {XMarkIcon} from '@heroicons/react/20/solid';
import {ProspectPool} from 'api';
import Salesforce from './Salesforce';
import HubSpot from './HubSpot';

interface Props {
  prospectPool: ProspectPool;
  onClear: () => void;
}

const Prospect = ({prospectPool, onClear}: Props) => {
  if (!prospectPool) {
    return (
      <div className="p-6 bg-white rounded flex flex-col gap-1 min-h-[160px]" />
    );
  }

  return (
    <div className="px-6 py-4 bg-white rounded flex flex-col gap-1 min-h-[160px] relative">
      <button
        onClick={onClear}
        className="p-0 h-5 w-5 flex items-center justify-center rounded-full border-none bg-c-lighter hover:bg-c-primary text-white cursor-pointer absolute right-4 top-4">
        <XMarkIcon className="w-3 h-3" />
      </button>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">名前</div>
        <div className="w-3/4 flex items-center gap-2">
          {prospectPool?.prospect?.lastName} {prospectPool?.prospect?.firstName}
          {prospectPool?.salesforceContactId && (
            <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a]">
              <Salesforce />
              取引先責任者
            </span>
          )}
          {prospectPool?.salesforceLeadId && (
            <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a]">
              <Salesforce />
              リード
            </span>
          )}
          {prospectPool.hubspotContact && (
            <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#ff7a59] bg-[#ff7a594d]">
              <HubSpot />
              コンタクト
            </span>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">会社名</div>
        <div className="w-3/4">{prospectPool.prospect.account.name}</div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">URL</div>
        <div className="w-3/4">
          <a href={prospectPool.prospect.account.webSite} target="_blank">
            {prospectPool.prospect.account.webSite}
          </a>
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">メールアドレス</div>
        <div className="w-3/4">{prospectPool.prospect.email}</div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">電話番号</div>
        <div className="w-3/4">{prospectPool.prospect.telephoneNumber}</div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">リードソース</div>
        <div className="w-3/4 flex items-center gap-8 text-sm">
          {prospectPool.leadSource?.name && (
            <div className="flex items-center gap-2">
              最新
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {prospectPool.leadSource?.name}
              </span>
            </div>
          )}
          {prospectPool.originalLeadSource?.name && (
            <div className="flex items-center gap-2 text-sm">
              初回
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {prospectPool.originalLeadSource?.name}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">流入日</div>
        <div className="w-3/4 flex items-center gap-8 text-sm">
          {prospectPool.latestInflowDate && (
            <div className="flex items-center gap-2">
              最新
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {moment(prospectPool.latestInflowDate).format('YYYY/MM/DD')}
              </span>
            </div>
          )}
          {prospectPool.originalInflowDate && (
            <div className="flex items-center gap-2 text-sm">
              初回
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {moment(prospectPool.originalInflowDate).format('YYYY/MM/DD')}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prospect;
