import React from 'react';
import {Route, Switch} from 'react-router-dom';
import List from './List';
import AccountGroup from './AccountGroup';

export default () => (
  <Switch>
    <Route
      path="/pools/:poolId/account_groups/:accountGroupId"
      component={AccountGroup}
    />
    <Route path="/pools/:poolId/account_groups" component={List} />
  </Switch>
);
