import {Input} from 'antd';
import {
  ClientPreleadCustomizeItemSelectOption,
  ClientPreleadCustomizeItem,
  useUpdateClientPreleadCustomizeItemSelectOptionMutation,
  useDestroyClientPreleadCustomizeItemSelectOptionMutation,
} from 'api';
import {message} from 'components/antd';
import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Trash} from 'components/Ui/Icon';

interface Props {
  option: ClientPreleadCustomizeItemSelectOption;
  clientPreleadCustomizeItem: ClientPreleadCustomizeItem;
}

export default ({option, clientPreleadCustomizeItem}: Props) => {
  const [optionName, setOptionName] = useState(option.label);
  const [updateCustomizeItem] =
    useUpdateClientPreleadCustomizeItemSelectOptionMutation();
  const [destroyCustomizeItem] =
    useDestroyClientPreleadCustomizeItemSelectOptionMutation();

  const alreadyNameExist = useMemo(
    () =>
      clientPreleadCustomizeItem?.selectOptions.some(
        (option) => option.label === optionName,
      ),
    [clientPreleadCustomizeItem?.selectOptions, optionName],
  );

  const handleChangeOptionName = () => {
    updateCustomizeItem({
      variables: {
        clientPreleadCustomizeItemId: clientPreleadCustomizeItem.id,
        clientPreleadCustomizeItemSelectOptionId: option.id,
        label: optionName,
      },
      refetchQueries: ['clientPreleadCustomizeItems'],
    });
  };

  const handleDeleteOption = () => {
    destroyCustomizeItem({
      variables: {
        clientPreleadCustomizeItemSelectOptionId: option.id,
      },
    });
  };

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <h3>セレクト項目を編集</h3>
      <TagInput
        type="title"
        name="option-edit"
        placeholder={optionName}
        value={optionName}
        onChange={(e: any) => setOptionName(e.target.value)}
        onBlur={() => {
          if (!optionName) return;
          if (!alreadyNameExist) {
            handleChangeOptionName();
          }
        }}
        onPressEnter={() => {
          if (!optionName) return;
          if (!alreadyNameExist) {
            handleChangeOptionName();
          } else {
            message.info('既に同じ名前の項目が存在します。');
          }
        }}
      />
      <div
        className="flex gap-1 items-center cursor-pointer mt-3"
        onClick={() => handleDeleteOption()}>
        <Trash width="14" height="14" />
        <div className="text-sm text-[#899098]">削除</div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 260px;
  padding: 13px 11px;
  h3 {
    margin-top: 5px;
    font-size: 10px;
    color: #899098;
  }
`;

const TagInput = styled(Input)`
  display: flex;
  align-items: center;
  margin-right: 11px;
  width: 100%;
  height: 34px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  box-sizing: border-box;
  border-radius: 2px;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: #bdc4cb;
    }
  }
  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;
