import React from 'react';
import {useUpdateCollectedFormUrlMutation, CollectedFormUrl} from 'api';
import ReactQuill from 'react-quill';
import Code from './Code';
import useDebounce from 'hooks/useDebounce';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const ref = React.useRef(null);
  const [updateCollectedFormUrl] = useUpdateCollectedFormUrlMutation();

  const [bodyHtml, setBodyHtml] = React.useState(
    collectedFormUrl.autoreplyMailBodyHtml ||
      collectedFormUrl.autoreplyMailBody ||
      '',
  );
  const debouncedValue = useDebounce<string>(bodyHtml, 500);

  React.useEffect(() => {
    ref.current &&
      debouncedValue !== collectedFormUrl.autoreplyMailBodyHtml &&
      updateCollectedFormUrl({
        variables: {
          collectedFormUrlUuid: collectedFormUrl.uuid,
          attributes: {
            autoreplyMailBodyHtml: debouncedValue,
            autoreplyMailBody: ref.current?.editor.getText(),
          },
        },
      });
  }, [collectedFormUrl, debouncedValue, ref]);

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-1 w-full">
        <label className="text-c-base font-bold">本文</label>
        <div className="relative">
          <div
            className={`
        relative
        [&_a]:text-c-primary
        [&_.ql-toolbar]:border-t-none
        [&_.ql-toolbar]:border-x-none
        [&_.ql-toolbar]:border-c-border
        [&_.ql-toolbar]:bg-white
        [&_.ql-toolbar]:rounded-t
        [&_.ql-container]:min-h-[100px]
        [&_.ql-container]:border-c-border
        [&_.ql-container]:bg-white
        [&_.ql-editor]:min-h-[100px]
        [&_.ql-editor]:rounded-b
        [&_.ql-editor]:${
          collectedFormUrl.sendAutoreplyMail ? 'bg-white' : 'bg-c-bg'
        }
        [&_.ql-editor]:${
          collectedFormUrl.sendAutoreplyMail ? 'text-c-base' : 'text-c-light'
        }
        [&_.ql-editor_a]:no-underline
      `}>
            <ReactQuill
              ref={ref}
              theme="snow"
              placeholder="本文を記入してください"
              readOnly={!collectedFormUrl.sendAutoreplyMail}
              modules={{
                toolbar: {
                  container: [['bold', 'italic', 'underline', 'link']],
                },
              }}
              value={bodyHtml}
              onChange={(value) =>
                setBodyHtml(
                  value === '<p><br></p>' || value === '\n' ? '' : value,
                )
              }
            />
          </div>
          <Code
            collectedFormUrl={collectedFormUrl}
            onClick={(value: string) => {
              if (ref.current?.editor.getSelection()) {
                ref.current?.editor.insertText(
                  ref.current?.editor.getSelection().index,
                  value,
                );
              }
            }}
          />
        </div>
        {collectedFormUrl.sendAutoreplyMail &&
        ['', '\n', '<p><br></p>'].includes(bodyHtml) ? (
          <div className="text-red-500">本文が入力されていません。</div>
        ) : null}
      </div>
    </div>
  );
};
