import React from 'react';
import {useParams} from 'react-router-dom';
import {useLeadSourcesQuery} from 'api';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';

const LeadSourceFilter = () => {
  const {poolId} = useParams<{poolId: string}>();
  const {query, setQuery} = useSearchParams();

  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    variables: {uuid: poolId},
    skip: !poolId,
    fetchPolicy: 'cache-and-network',
  });

  const optionLeadSources = [
    {text: '未設定', value: '0'},
    ...leadSources.map((leadSource) => ({
      text: leadSource.name,
      value: leadSource.id,
      keyword: leadSource.name,
    })),
  ];

  return (
    <>
      <Header title="最新リードソース" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={optionLeadSources}
            values={query.leadSourceIds || []}
            onChange={(leadSourceIds) => setQuery({...query, leadSourceIds})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, leadSourceIds: []})} />
    </>
  );
};

export default LeadSourceFilter;
