import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 9.99984L13.3334 13.3332M13.3334 6.6665L10.0001 9.99984L13.3334 6.6665ZM10.0001 9.99984L6.66675 13.3332L10.0001 9.99984ZM10.0001 9.99984L6.66675 6.6665L10.0001 9.99984Z"
        stroke="#B55D62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 18.3332C14.6025 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6025 1.6665 10.0001 1.6665C5.39771 1.6665 1.66675 5.39746 1.66675 9.99984C1.66675 14.6022 5.39771 18.3332 10.0001 18.3332Z"
        stroke="#B55D62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
