import React from 'react';
import styled from 'styled-components';
import {Pagination} from 'components/Ui';
import {Company, useCompaniesQuery} from 'api';
import {useQueryParams} from 'use-query-params';
import {Cell} from 'react-table';
import Table from './Table';
import {Radio} from 'components/antd';
import {
  companiesQueryParamsDef,
  companiesSearchParams,
} from './companiesQueryParams';
import {CubeLoading} from 'components/Ui/Icon';

interface Props {
  setCompanyId: React.Dispatch<React.SetStateAction<string>>;
}

export default ({setCompanyId}: Props) => {
  const [query, setQuery] = useQueryParams(companiesQueryParamsDef);

  const {
    data: {companies: {companies = [], pagination = {}} = {}} = {},
    loading,
  } = useCompaniesQuery({
    variables: {
      page: query.page,
      search: companiesSearchParams(query),
    },
  });

  const data = React.useMemo(
    () =>
      companies.map((company, i) => {
        return {
          index: i,
          id: company.id,
          uuid: company.uuid,
          name: company.name,
          address: company.address,
          telephoneNumber: company.telephoneNumber,
          webSite: company.webSite,
          corpNumber: company.corpNumber,
        };
      }),
    [companies],
  );

  const columns: any = React.useMemo(
    () => [
      {
        accessor: 'uuid',
        style: {
          width: '27px',
        },
        Cell: ({value, row}: Cell<Company>) => (
          <CustomRadio
            key={value}
            value={value}
            checked={query.uuid === value}
            onChange={() => {
              setQuery({uuid: value});
              setCompanyId(row.original.id);
            }}
          />
        ),
      },
      {
        Header: '会社名',
        accessor: 'name',
        style: {
          width: '280px',
        },
        Cell: ({value}: Cell<Company>) => <div>{value}</div>,
      },
      {
        Header: '住所',
        accessor: 'address',
        style: {
          width: '260px',
        },
        Cell: ({value}: Cell<Company>) => <div>{value}</div>,
      },
      {
        Header: '電話番号',
        accessor: 'telephoneNumber',
        style: {
          width: '90px',
        },
        Cell: ({value}: Cell<Company>) => <div>{value}</div>,
      },
      {
        Header: 'ウェブサイト(URL)',
        accessor: 'webSite',
        style: {
          width: '150px',
        },
        Cell: ({value}: Cell<Company>) => (
          <a href={value} target="_blank">
            {value?.replace(/https*:\/\//, '')}
          </a>
        ),
      },
      {
        Header: '法人番号',
        accessor: 'corpNumber',
        style: {
          width: '100px',
        },
        Cell: ({value}: Cell<Company>) => <div>{value}</div>,
      },
    ],
    [data, query],
  );

  return (
    <Container>
      {pagination.totalCount > 0 ? (
        <CountBox>
          <Count>{pagination.totalCount?.toLocaleString()}</Count>
          件見つかりました。 企業を選択してください。
        </CountBox>
      ) : (
        <CountBox>
          企業が見つかりませんでした。検索条件を変更してください。
        </CountBox>
      )}

      <TableWrapper>
        <Table columns={columns} data={data} />
        {loading && (
          <Loading>
            <CubeLoading />
          </Loading>
        )}
      </TableWrapper>
      <PaginationWrapper>
        <CustomPagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={20}
          onChange={(page) => {
            setQuery({page, uuid: ''});
          }}
        />
      </PaginationWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 40px;
`;

const CountBox = styled.div`
  display: inline-flex;
  padding: 8px;
  align-items: center;
  padding: 8px;
  background: #e2e6ea;
  border-radius: 4px;
  font-size: 13px;
  color: #495058;
`;

const Count = styled.span`
  margin: 0 5px;
  font-weight: bold;
  font-size: 13px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
`;

const CustomRadio = styled(Radio)`
  margin: 0;
`;

const PaginationWrapper = styled.div``;

const CustomPagination = styled(Pagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: #495058;
      background: #e2e6ea;
      border-radius: 100px;
    }
  }
`;

const Loading = styled.div`
  margin-top: 30px;
  text-align: center;

  svg {
    display: inline-block;
  }
`;
