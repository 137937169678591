import gql from 'graphql-tag';
import azureTranscriptionFileFragment from './transcriptionFile';

export default gql`
  fragment azureWebhookEventFragment on AzureWebhookEvent {
    id
    azureTranscriptionFiles {
      ...azureTranscriptionFileFragment
    }
  }
  ${azureTranscriptionFileFragment}
`;
