import React, {useRef} from 'react';
import styled from 'styled-components';
import linkifyHtml from 'linkify-html';
import {Input, Form, Popover, Button} from 'components/antd';
import {FormikProps} from 'formik';
import {ContentMailTemplateAttributes, useUploadMailImageMutation} from 'api';
import ContentsModal from '../mail_form/MessageForm/ContentsModal';
import {Quill as ReactQuill} from 'components/Ui';
import {Quill} from 'react-quill';

interface Props {
  formik: FormikProps<ContentMailTemplateAttributes>;
}

export default ({formik}: Props) => {
  const [contentModalVisible, setContentModalVisible] = React.useState(false);
  const [variablePopoverVisible, setVariablePopoverVisible] =
    React.useState(false);
  const ref = useRef(null);

  React.useEffect(() => {
    !formik.values.bodyHtml && ref.current?.editor.setText(formik.values.body);
  }, []);

  const [uploadMailImage] = useUploadMailImageMutation({
    onCompleted: ({uploadMailImage: {imageUrl} = {}}) => {
      insertToEditor(imageUrl);
    },
  });
  const insertToEditor = (url: string) => {
    const range = ref.current.editor.getSelection();
    ref.current.editor.insertEmbed(range.index, 'image', url);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadMailImage({variables: {image: file}});
      } else {
        alert('画像を選択して下さい。');
      }
    };
  };
  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = [
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
  ];
  Quill.register(fontSizeStyle, true);

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        handlers: {
          image: selectLocalImage,
        },
        container: [
          [
            {header: [1, 2, 3, false]},
            'bold',
            'italic',
            'underline',
            'code-block',
            'link',
            'image',
            {size: fontSizeStyle.whitelist},
          ],
        ],
      },
    };
  }, []);

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <Container>
      <div className="bg-white pb-3 rounded flex flex-col gap-2">
        <label>種別</label>
        <label className="flex items-center text-base">
          <input
            type="radio"
            className="form-radio h-5 w-5 border-c-border rounded-full text-c-primary"
            checked={formik.values.isShare}
            onChange={() => formik.setFieldValue('isShare', true)}
          />
          <span className="ml-3 text-black font-normal">共有</span>
        </label>
        <label className="flex items-center text-base">
          <input
            type="radio"
            className="form-radio h-5 w-5 border-c-border rounded-full text-c-primary"
            checked={!formik.values.isShare}
            onChange={() => formik.setFieldValue('isShare', false)}
          />
          <span className="ml-3 text-black font-normal">個人</span>
        </label>
      </div>
      <Form>
        <Form.Item
          label="件名"
          validateStatus={
            formik.errors.title && formik.touched.title ? 'error' : ''
          }
          help={
            formik.errors.title && formik.touched.title
              ? formik.errors.title
              : null
          }>
          <Input
            id="title"
            type="text"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          label="本文"
          validateStatus={
            formik.errors.body && formik.touched.body ? 'error' : ''
          }
          help={
            formik.errors.body && formik.touched.body
              ? formik.errors.body
              : null
          }>
          <div>
            <Header>
              <Count>文字数:{formik.values.body.length}</Count>
              <Popover
                content={
                  <PopoverContent>
                    <PopoverSelectButtonWrapper>
                      <PopoverSelectButton
                        onClick={() => {
                          if (ref.current?.editor.getSelection()) {
                            ref.current?.editor.insertText(
                              ref.current?.editor.getSelection().index,
                              '{{company}}',
                            );
                          } else {
                            const title: any = document.getElementById('title');
                            const value =
                              title.value.substr(0, title.selectionStart) +
                              '{{company}}' +
                              title.value.substr(title.selectionStart);
                            formik.setFieldValue('title', value);
                          }
                        }}>
                        <span>{'{{company}}'}</span>
                        <span>会社</span>
                      </PopoverSelectButton>
                    </PopoverSelectButtonWrapper>
                  </PopoverContent>
                }
                trigger="click"
                visible={variablePopoverVisible}
                placement="bottomRight"
                onVisibleChange={setVariablePopoverVisible}>
                <PopoverDisplayButton>{`{ }`}</PopoverDisplayButton>
              </Popover>
              <Popover
                content={
                  <ContentsModal
                    onClick={(text: string) =>
                      ref.current?.editor.insertText(
                        ref.current?.editor.getSelection().index,
                        text,
                        'link',
                        text,
                      )
                    }
                  />
                }
                trigger="click"
                visible={contentModalVisible}
                placement="bottomRight"
                onVisibleChange={setContentModalVisible}>
                <ContentButton>+</ContentButton>
              </Popover>
            </Header>
            <QuillWrapper onBlur={formik.handleBlur('body_html')}>
              <ReactQuill
                ref={ref}
                theme="snow"
                defaultValue={formik.values.bodyHtml}
                onChange={(content, delta, source, editor) => {
                  formik.setFieldValue('bodyHtml', content);
                  formik.setFieldValue('body', editor.getText());
                }}
                onBlur={() =>
                  formik.setFieldValue(
                    'bodyHtml',
                    convertUrlToLink(formik.values.bodyHtml),
                  )
                }
                modules={modules}
                formats={[
                  'header',
                  'list',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'color',
                  'background',
                  'code-block',
                  'link',
                  'image',
                  'size',
                ]}
                bounds={'#quill-container'}
              />
            </QuillWrapper>
          </div>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  label {
    font-size: 13px;
    color: #899098;
    font-weight: bold;
  }
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  color: #899098;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 0;
`;

const ContentButton = styled(Button)`
  &&& {
    padding: 0 10px;
    height: 30px;
  }
`;

const QuillWrapper = styled.div`
  border: 1px solid #e2e6ea;
  border-radius: 4px;

  .ql-container {
    border: none;
  }
  .ql-container.ql-snow {
    min-height: 300px;

    .ql-editor {
      min-height: 300px;
      overflow-y: scroll;
      resize: vertical;
    }
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }
  .ql-snow .ql-picker.ql-size {
    width: 50px;
    .ql-picker-label {
      padding-left: 0;
    }
    .ql-picker-options {
      width: 50px;
    }
  }
`;

const PopoverDisplayButton = styled(Button)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const PopoverSelectButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span: first-child {
    color: #899098;
    margin-right: 10px;
  }
  span: last-child {
    color: #495058;
  }
`;

const PopoverSelectButton = styled(Button)`
  padding: 0;
  padding-left: 10px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;
