import React from 'react';
import LeadCondition from './Lead';
import FormCondition from './Form';
import CompanyCondition from './Company';
import HubspotCondition from './Hubspot';
import SalesforceCondition from './Salesforce';

const Conditions = () => (
  <>
    <LeadCondition />
    <FormCondition />
    <CompanyCondition />
    <HubspotCondition />
    <SalesforceCondition />
  </>
);

export default Conditions;
