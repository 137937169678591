import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {usePreleadProjectQuery} from 'api';
import moment from 'moment';

export default () => {
  const {preleadProjectId} =
    useParams<{
      preleadProjectId: string;
    }>();

  const {data: {preleadProject: {prelead = null} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    });

  const isPressReleaseWithinOneWeek =
    prelead?.prLastPublishedAt &&
    moment(prelead.prLastPublishedAt).isAfter(moment().subtract(1, 'week'));

  return (
    <Container>
      {isPressReleaseWithinOneWeek && <span>一週間以内</span>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    color: #eb5757;
  }
`;
