import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Select, Form, Input} from 'components/antd';
import {
  useUpdatePreleadStatusMutation,
  useDestroyPreleadStatusMutation,
  PreleadStatus,
} from 'api';
import StatusEclipse from 'components/Ui/StatusEclipse';
import {preleadStatusCategoryOptions} from './preleadStatusCategoryOptions';

interface Props {
  preleadStatus: PreleadStatus;
  onClose: () => void;
}

const Update = ({preleadStatus, onClose}: Props) => {
  const [updatePreleadStatus] = useUpdatePreleadStatusMutation({
    onCompleted: () => onClose(),
  });

  const [destroyPreleadStatus] = useDestroyPreleadStatusMutation({
    variables: {id: preleadStatus?.id},
    refetchQueries: ['currentClient'],
    onCompleted: ({destroyPreleadStatus: {error}}) => {
      error ? alert(error) : onClose();
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: preleadStatus?.name,
      category: preleadStatus?.category,
      description: preleadStatus?.description,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      category: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) =>
      updatePreleadStatus({
        variables: {
          id: preleadStatus.id,
          attributes: {
            ...values,
          },
        },
      }),
  });

  return (
    <Modal
      destroyOnClose
      visible={Boolean(preleadStatus)}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>ステータスの編集・削除</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="ステータス名" required>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="ステータスのカテゴリ" required>
            <Select
              value={formik.values.category}
              onSelect={(value: any) => formik.setFieldValue('category', value)}
              disabled={preleadStatus?.isImmutable}
              dropdownMatchSelectWidth={false}
              dropdownClassName="prelead-status-dropdown"
              size="large"
              optionLabelProp="label">
              {preleadStatusCategoryOptions.map((option) => (
                <Select.Option
                  value={option.value}
                  key={option.value}
                  disabled={option.isImmutable}
                  label={
                    <>
                      <StatusEclipse color={option.color} />
                      &nbsp; {option.text}
                    </>
                  }>
                  <StatusEclipse color={option.color} />
                  &nbsp; {option.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="ステータスの説明">
            <Input.TextArea
              name="description"
              autoSize={{minRows: 3}}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <div>
              <Button
                danger
                onClick={() => destroyPreleadStatus()}
                disabled={preleadStatus?.isImmutable}
                size="large">
                削除
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{marginLeft: '8px'}}
                disabled={!formik.isValid}>
                更新
              </Button>
            </div>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Update;
