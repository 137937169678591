import React from 'react';
import styled from 'styled-components';
import {useParams, Link, Switch, Route, Redirect} from 'react-router-dom';
import {useMailCampaignQuery, usePreleadProjectsByMailCampaignQuery} from 'api';
import Mail from '../mail/SimpleMail';
import Settings from '../settings';
import Recipients from './recipients';
import {useRecoilState} from 'recoil';
import List from './list';
import totalCountAtom from '../totalCountAtom';

const PER_PAGE = 20;

export default () => {
  const [page, setPage] = React.useState(1);
  const {mailCampaignId} = useParams<{
    mailCampaignId: string;
  }>();
  const [, setTotalCount] = useRecoilState(totalCountAtom);

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const {
    data: {
      preleadProjectsByMailCampaign: {
        preleadProjects = [],
        pagination = {},
      } = {},
    } = {},
  } = usePreleadProjectsByMailCampaignQuery({
    variables: {
      uuid: mailCampaign?.uuid,
      page,
      perPage: PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({preleadProjectsByMailCampaign: {pagination}}) =>
      setTotalCount(pagination.totalCount),
  });

  if (!mailCampaign) return null;

  return (
    <Container>
      <Header>
        <TargetType>営業リスト</TargetType>
        <Tabs>
          <TabItem
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/mail`,
            )}>
            <Link
              to={`/mail_campaigns/${mailCampaignId}/mail${location.search}`}>
              1.メール本文編集
            </Link>
          </TabItem>
          <Arrow>&#8250;</Arrow>
          <TabItem
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/list`,
            )}>
            <Link
              to={`/mail_campaigns/${mailCampaignId}/list${location.search}`}>
              2.送信先設定
            </Link>
          </TabItem>
          <Arrow>&#8250;</Arrow>
          <TabItem
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/recipients`,
            )}>
            <Link
              style={
                mailCampaign.searchCondition?.projectIds
                  ? {}
                  : {pointerEvents: 'none'}
              }
              to={`/mail_campaigns/${mailCampaignId}/recipients${location.search}`}>
              3.条件
            </Link>
          </TabItem>
          <Arrow>&#8250;</Arrow>
          <TabItem
            active={location.pathname.startsWith(
              `/mail_campaigns/${mailCampaignId}/settings`,
            )}>
            <Link
              to={`/mail_campaigns/${mailCampaignId}/settings${location.search}`}>
              4.送信設定
            </Link>
          </TabItem>
        </Tabs>
        <div />
      </Header>
      <Switch>
        <Route path={`/mail_campaigns/:mailCampaignId/mail`} component={Mail} />
        <Route path={`/mail_campaigns/:mailCampaignId/list`} component={List} />
        <Route path={`/mail_campaigns/:mailCampaignId/recipients`}>
          <Recipients
            preleadProjects={preleadProjects}
            pagination={pagination}
            setPage={setPage}
            perPage={PER_PAGE}
          />
        </Route>
        <Route path={`/mail_campaigns/:mailCampaignId/settings`}>
          <Settings mailCampaign={mailCampaign} />
        </Route>
        <Redirect to={`/mail_campaigns/:mailCampaignId/mail`} />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 30px;
  background: #fff;
  border-radius: 4px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
`;

const TargetType = styled.span`
  display: inline-block;
  background: #fafbfb;
  padding: 4px 8px;
  border-radius: 4px;
  color: #899098;
  font-size: 12px;
`;

const Tabs = styled.div`
  display: flex;
`;

const TabItem = styled.div<{active?: boolean}>`
  border-bottom: solid 2px;
  border-color: ${({active}) => (active ? '#495058' : 'transparent')};
  padding: 0;
  margin-right: 2rem;

  a {
    font-size: 14px;
    font-weight: bold;
    line-height: 3rem;
    color: ${({active}) => (active ? '#495058' : '#BDC4CB')};
    display: inline-block;
  }
`;

const Arrow = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  color: #bdc4cb;
  margin-right: 2rem;
  font-size: 2em;
`;
