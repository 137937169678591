import moment from 'moment';

function useMonthAxis(queryMonth: string): string[] {
  const splited = queryMonth?.split('-');
  const year = splited?.length > 1 ? Number(splited[0]) : moment().year();
  const month = splited?.length > 1 ? Number(splited[1]) : moment().month();
  const startDate = moment([year, month - 1]);
  const endDate = moment(startDate).endOf('month');

  const axis: string[] = [];

  let day = moment(startDate);

  while (day <= endDate) {
    const dayOfWeek = day.format('dd');
    const formattedDate = `${day.date()}(${dayOfWeek})`;
    axis.push(formattedDate);
    day.add(1, 'days');
  }

  return axis;
}

export default useMonthAxis;
