import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {
  useCurrentClientQuery,
  useProjectWithPreleadMasterSearchConditionQuery,
  useSearchProjectsByIdsQuery,
  useTelStatusesByIdsQuery,
} from 'api';
import {Filter} from 'components/Ui/Icon';
import preleadColor from 'helpers/preleadColor';
import mailColor from 'helpers/mailColor';
import telCategoryColor from 'helpers/telCategoryColor';
import {StatusEclipse} from 'components/Ui';
import {preleadStatusOptions, mailStatusOptions} from './statuses';
import {
  Container,
  Category,
  CategoryTitle,
  SearchConditions,
  Item,
  Title,
  TagBox,
  TagItem,
} from './styles';
import preleadCategoryColor from 'helpers/preleadCategoryColor';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {
    data: {
      project: {
        preleadMasterSearchCondition: {projectFilters, projectMode},
      } = {},
    } = {},
  } = useProjectWithPreleadMasterSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  const projectIds = projectFilters.map((projectFilter: any) =>
    Number(projectFilter.project_id),
  );

  const telStatusIds = React.useMemo(
    () =>
      projectFilters
        .filter(
          (projectFilter: any) =>
            projectFilter.status_type === 'client_tel_status_id',
        )
        .map((projectFilter: any) => Number(projectFilter.status_values))
        .flat(),
    [projectFilters],
  );

  const {data: {currentClient: {preleadStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const {data: {telStatusesByIds = []} = {}} = useTelStatusesByIdsQuery({
    variables: {
      ids: telStatusIds,
    },
    skip: !telStatusIds,
  });

  const preleadStatus = React.useCallback(
    (preleadStatusId: number): any =>
      preleadStatuses.find(
        (preleadStatus) => Number(preleadStatus.id) === preleadStatusId,
      ),
    [preleadStatuses],
  );

  const telStatus = React.useCallback(
    (telStatusId: number): any =>
      telStatusesByIds.find(
        (telStatus) => Number(telStatus.id) === telStatusId,
      ),
    [telStatusesByIds],
  );

  const {data: {searchProjectsByIds: {projects: projects = []} = {}} = {}} =
    useSearchProjectsByIdsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        ids: projectIds,
      },
      skip: projectIds.length === 0,
    });

  const statusTypeName = (status: string) => {
    if (status === 'status') {
      return 'プレリードステータス';
    } else if (status === 'client_prelead_status_id') {
      return 'プレリードステータス';
    } else if (status === 'mail_status') {
      return 'フォーム送信ステータス';
    } else if (status === 'client_tel_status_id') {
      return 'TELステータス';
    }
  };

  const projectName = (id: any) => {
    return projects.filter((project) => project.id === id)[0]?.name;
  };

  const upperCaseMode = () => {
    if (projectMode === '&') return 'AND';
    if (projectMode === '|') return 'OR';
  };

  if (!projectFilters) return null;

  return (
    <Container>
      {projectFilters.length > 0 && (
        <Category>
          <CategoryTitle>
            <Filter />
            所属営業リストフィルタ条件
          </CategoryTitle>
          <SearchConditions>
            {projectFilters.map((filter: any, index: number) => (
              <SearchCondition key={index}>
                <Item>
                  <Title>
                    <span>{upperCaseMode()}：</span>営業リスト名
                  </Title>
                  <TagBox>
                    <TagItem>{projectName(filter.project_id)}</TagItem>
                  </TagBox>
                </Item>
                <Item>
                  <Title>
                    <span></span>
                    {statusTypeName(filter.status_type)}
                  </Title>
                  {filter.status_type === 'status' && (
                    <TagBox>
                      {filter.status_values.map((status: string) => (
                        <TagItem key={status}>
                          <StatusEclipse color={preleadColor(status)} />
                          {preleadStatusOptions[status]}
                        </TagItem>
                      ))}
                    </TagBox>
                  )}
                  {filter.status_type === 'client_prelead_status_id' && (
                    <TagBox>
                      {filter.status_values.map((preleadStatusId: string) => (
                        <TagItem key={preleadStatusId}>
                          <StatusEclipse
                            color={preleadCategoryColor(
                              preleadStatus(Number(preleadStatusId))?.category,
                            )}
                          />
                          {preleadStatusId === 'none'
                            ? '未'
                            : preleadStatus(Number(preleadStatusId))?.name}
                        </TagItem>
                      ))}
                    </TagBox>
                  )}
                  {filter.status_type === 'mail_status' && (
                    <TagBox>
                      {filter.status_values.map((status: string) => (
                        <TagItem key={status}>
                          <StatusEclipse color={mailColor(status)} />
                          {mailStatusOptions[status]}
                        </TagItem>
                      ))}
                    </TagBox>
                  )}
                  {filter.status_type === 'client_tel_status_id' && (
                    <TagBox>
                      {filter.status_values.map((telStatusId: string) => (
                        <TagItem key={telStatusId}>
                          <StatusEclipse
                            color={telCategoryColor(
                              telStatus(Number(telStatusId))?.category,
                            )}
                          />
                          {telStatusId === 'none'
                            ? '未'
                            : telStatus(Number(telStatusId))?.name}
                        </TagItem>
                      ))}
                    </TagBox>
                  )}
                </Item>
              </SearchCondition>
            ))}
          </SearchConditions>
        </Category>
      )}
    </Container>
  );
};

const SearchCondition = styled.div`
  margin-bottom: 15px;

  &:first-child {
    ${Title} {
      span {
        display: none;
      }
    }
  }
`;
