import React from 'react';
import {ChevronLeftIcon} from '@heroicons/react/20/solid';
import {useHistory} from 'react-router';

interface Props {
  label: string;
}

const Back = ({label}: Props) => {
  const history = useHistory();
  return (
    <button
      onClick={() => history.goBack()}
      className="flex cursor-pointer items-center gap-2 bg-transparent p-0 text-c-light"
    >
      <ChevronLeftIcon className="h-6 w-6" />
      <span className="text-base font-bold">{label}</span>
    </button>
  );
};

export default Back;
