import gql from 'graphql-tag';

export default gql`
  fragment automaticMailSendFragment on AutomaticMailSend {
    id
    uuid
    status
    sendAt
    subject
    bodyText
    bodyHtml
    cc
    bcc
    to
  }
`;
