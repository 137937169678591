import React from 'react';
import DealMultiSelectOption from './DealMultiSelectOption';
import DealSelectOption from './DealSelectOption';
import {
  SearchCondition,
  MultiSelectSearchCondition,
  SelectSearchCondition,
} from './hubspotDealTypes';

const DealFieldOption: React.FC<{condition: SearchCondition}> = ({
  condition,
}) => {
  if (
    condition.deal_property_definition_field_type === 'checkbox' &&
    condition.deal_property_definition_property_type === 'enumeration'
  ) {
    return (
      <DealMultiSelectOption
        condition={condition as MultiSelectSearchCondition}
      />
    );
  } else if (
    condition.deal_property_definition_field_type === 'select' &&
    condition.deal_property_definition_property_type === 'enumeration'
  ) {
    return <DealSelectOption condition={condition as SelectSearchCondition} />;
  } else {
    return <></>;
  }
};

export default DealFieldOption;
