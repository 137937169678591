import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import Total from './total';
import Category from './category';
import Employee from './employee';
// import useSearchParams from '../hooks/useSearchParams';

export default () => {
  // const {dateFrom, dateTo} = useSearchParams();

  return (
    <Layout
      title="タグ連携フォーム分析"
      tabs={[
        {
          label: '合計',
          to: '/reports/collected_form/total',
        },
        {
          label: '業種別',
          to: '/reports/collected_form/category',
        },
        {
          label: '従業員別',
          to: '/reports/collected_form/employee',
        },
      ]}
      search={['timeScale', 'formSelect']}>
      <Switch>
        <Route path="/reports/collected_form/total" component={Total} />
        <Route path="/reports/collected_form/category" component={Category} />
        <Route path="/reports/collected_form/employee" component={Employee} />
        <Redirect to="/reports/collected_form/total" />
      </Switch>
    </Layout>
  );
};
