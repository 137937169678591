import React from 'react';
import Setting from './Setting';
import User from './User';
import GlobalMenu from './GlobalMenu';
import LeadpadIcon from './LeadpadIcon';

export default () => {
  return (
    <div className="justify-between flex cursor-pointer border-[#e1e6eb] border-solid border-b-[1px] items-center">
      <LeadpadIcon />
      <GlobalMenu />
      <div className="flex justify-end items-center ml-auto">
        <Setting />
        <User />
      </div>
    </div>
  );
};
