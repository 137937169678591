import React from 'react';
import {useParams} from 'react-router-dom';
import useCondition from '../../../../useCondition';
import useFilterModalContext from '../../../useFilterModalContext';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {
  useSalesforceContactRecordTypesQuery,
  useSalesforceUsersQuery,
} from 'api';
import SearchCondition from './SearchCondition';

export default () => {
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {
      poolId: poolId,
    },
    skip: !poolId,
  });

  const recordType = salesforceContactRecordTypes.find(
    (recordType) => recordType.id === condition?.salesforceContactRecordTypeId,
  );

  const owners =
    salesforceUsers
      .filter((owner) =>
        condition?.salesforceContactOwnerIds?.includes(owner.id),
      )
      .map((user) => ({
        id: user.id,
        label: user.name,
      })) || [];

  if (
    !condition.hasSalesforceContact &&
    !condition.salesforceContactRecordTypeId &&
    !(condition.salesforceContactOwnerIds?.length > 0) &&
    !(condition.salesforceContactSearchConditions?.length > 0)
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">取引先責任者</h4>
        <button
          onClick={() => setFilterType('salesforceContact')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setCondition({
              hasSalesforceContact: false,
              salesforceContactRecordTypeId: '',
              salesforceContactOwnerIds: [],
              salesforceContactSearchConditions: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-c-light">レコードタイプ：</span>
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {recordType?.name}
        </span>
      </div>
      {owners.length > 0 && (
        <div className="flex gap-2">
          <span className="text-c-light h-6 flex items-center">所有者：</span>
          <div className="flex-1 flex flex-wrap items-center gap-1">
            {owners.map((owner) => (
              <span
                key={owner.id}
                className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {owner.label}
              </span>
            ))}
          </div>
        </div>
      )}
      {condition.salesforceContactSearchConditions?.length > 0 &&
        condition.salesforceContactSearchConditions?.map(
          (searchCondition, i) => (
            <SearchCondition key={i} searchCondition={searchCondition} />
          ),
        )}
    </div>
  );
};
