import gql from 'graphql-tag';

export default gql`
  fragment preleadProjectReminderFragment on Reminder {
    id
    uuid
    preleadProjectId
    memo
    remindAt
  }
`;
