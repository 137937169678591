import React from 'react';

export default () => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1.5L6 10.5M1 4.5H9H1ZM1 7.5H9H1ZM4 1.5L2.5 10.5L4 1.5Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
