import React from 'react';
import {FormBlock, FormBlockImage} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockImage;
}

interface Props {
  block: Block;
}

const Img = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 21L16 10L21 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></path>
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></rect>
    <circle
      cx="8.5"
      cy="8.5"
      r="1.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></circle>
  </svg>
);

export default ({block}: Props) => {
  const blockable = block.formBlockable;

  return blockable.image ? (
    <div className="flex justify-center">
      <img
        src={blockable.image}
        className="max-w-full"
        style={{
          width: `${blockable.imageWidth * blockable.imageScale}px`,
        }}
      />
    </div>
  ) : (
    <div className="cursor-pointer bg-[#E1E6EB] w-full min-h-[4rem] rounded flex items-center justify-center text-[#BDC4CB]">
      <Img />
    </div>
  );
};
