import gql from 'graphql-tag';

export default gql`
  fragment formBlockButtonFragment on FormBlockButton {
    id
    buttonText
    link
    buttonColor
    textColor
    createdAt
    updatedAt
  }
`;
