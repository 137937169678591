import React from 'react';

const Icon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#9576EF" />
    <path
      d="M11.5002 20.8333H18.5002C19.1445 20.8333 19.6668 20.311 19.6668 19.6666V13.7332C19.6668 13.4238 19.5439 13.127 19.3251 12.9082L15.9252 9.50834C15.7064 9.28954 15.4097 9.16663 15.1003 9.16663H11.5002C10.8558 9.16663 10.3335 9.68896 10.3335 10.3333V19.6666C10.3335 20.311 10.8558 20.8333 11.5002 20.8333Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5835 9.45837V13.25H19.0835"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6665 17.9166H16.7498"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6665 15.5834H16.7498"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6665 13.25H13.2498"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
