import gql from 'graphql-tag';

export default gql`
  fragment hubspotContactCreateProspectActivityFragment on HubspotContactCreateProspectActivity {
    hubspotUrl
    hubspotContact {
      id
      firstName
      lastName
      hubspotId
    }
  }
`;
