import React from 'react';
import {ArrowDown, LaunchLink} from 'components/Ui/Icon';
import {HubspotDeal, useHubspotDealPropertyDefinitionsQuery} from 'api';
import Property from './Property';
import useProspectContext from '../../../useProspectContext'

interface Props {
  hubspotDeal: HubspotDeal;
}

export default ({hubspotDeal}: Props) => {
  const [open, setOpen] = React.useState(false);

  const {poolId} = useProspectContext();

  const {data: {hubspotDealPropertyDefinitions = []} = {}} =
    useHubspotDealPropertyDefinitionsQuery({
      variables: {poolId},
      skip: !poolId,
    });

  return (
    <div className="w-full py-2 px-4 border border-c-border font-medium rounded">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setOpen(!open)}>
        <h2 className="font-bold text-base leading-8 flex-1 m-0">
          {hubspotDeal.dealname}
        </h2>
        <ArrowDown
          className={open ? 'rotate-180 w-4 h-auto' : 'rotate-0 w-4 h-auto'}
        />
      </div>
      {open && (
        <>
          <table className="table-auto w-full">
            <tbody>
              {hubspotDealPropertyDefinitions
                .filter((propertyDefinition) => propertyDefinition.isDisplay)
                .map((propertyDefinition) => (
                  <tr key={propertyDefinition.id}>
                    <td className="py-2 text-sm">
                      {propertyDefinition.labelText}
                    </td>
                    <td className="py-2 pl-4 text-sm">
                      <Property
                        propertyDefinition={propertyDefinition}
                        hubspotDeal={hubspotDeal}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <a
            href={hubspotDeal.hubspotUrl}
            target="_blank"
            className="flex items-center gap-1 [&>svg]:w-3">
            <LaunchLink color={'#68b5fb'} />
            <span>Hubspotで見る</span>
          </a>
        </>
      )}
    </div>
  );
};
