import React from 'react';
import styled from 'styled-components';
import {Workflow} from 'api';

export default ({workflow}: {workflow: Workflow}) => {
  if (workflow.status === 'open') {
    return (
      <Open>
        <em />
        <span>{workflow.statusText}</span>
      </Open>
    );
  } else if (workflow.status === 'applying' || workflow.status === 'removing') {
    return (
      <Processing>
        <em />
        <span>{workflow.statusText}</span>
      </Processing>
    );
  }

  return (
    <Draft>
      <em />
      <span>{workflow.statusText}</span>
    </Draft>
  );
};

const Status = styled.div`
  display: flex;
  align-items: center;
  width: 73px;
  height: 32px;
  border-radius: 4px;
  padding: 1rem 0;

  em {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin: 0.5rem;
  }

  span {
    flex: 1;
  }
`;

const Draft = styled(Status)`
  color: #83888d;
  background: #eaecee;

  em {
    background: #83888d;
  }
`;
const Open = styled(Status)`
  color: #55aa68;
  background: #f5fdf7;

  em {
    background: #55aa68;
  }
`;
const Processing = styled(Status)`
  color: #2f80ed;
  background: #e5eefa;

  em {
    background: #2f80ed;
  }
`;
