import gql from 'graphql-tag';
import zoomPhoneWebhookRecordingFragment from './webhookEventRecording';

export default gql`
  fragment zoomPhoneWebhookCallFragment on ZoomPhoneWebhookCall {
    id
    status
    statusText
    callerName
    calleeName
    recordings {
      ...zoomPhoneWebhookEventRecordingFragment
    }
  }
  ${zoomPhoneWebhookRecordingFragment}
`;
