import React from 'react';
import {SalesforceContactField, SalesforceDealField} from 'api';
import {Input, Form} from 'components/antd';

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
  field: SalesforceContactField | SalesforceDealField;
}

export const DateItem = ({addition, setAddition, field}: Props) => {
  return (
    <Form.Item label={field.label} required={field.isRequired}>
      <Input
        type="date"
        value={addition[`${field.fieldName}`]}
        style={{width: '200px'}}
        onChange={(e) => {
          let newAddition: {[key: string]: any} = Object.assign({}, addition);
          newAddition[`${field.fieldName}`] = e.target.value;
          setAddition(newAddition);
        }}
      />
    </Form.Item>
  );
};
