import React from 'react';
import {MailMessage} from 'api';
import GmailImport from './GmailImport';
import ManualMail from './ManualMail';
import ManualMailAction from './ManualMailAction';
import AutomaticMailSend from './AutomaticMailSend';

const mailMessageComponents: {[key: string]: any} = {
  GmailImportMailMessage: GmailImport,
  ManualMailMailMessage: ManualMail,
  ManualMailActionMailMessage: ManualMailAction,
  AutomaticMailSendMailMessage: AutomaticMailSend,
};

interface Props {
  mailMessage: MailMessage;
  defaultOpen: boolean;
}

export default ({mailMessage, defaultOpen}: Props) => {
  const MailMessageComponent =
    mailMessageComponents[mailMessage.messageable.__typename];
  const [open, setOpen] = React.useState(defaultOpen);

  return (
    <MailMessageComponent
      mailMessage={mailMessage}
      open={open}
      setOpen={setOpen}
    />
  );
};
