const employeeRangeOptions = [
  {label: '0 ~ 100人', value: '0to100'},
  {label: '1 ~ 20人', value: '1to20'},
  {label: '21 ~ 50人', value: '21to50'},
  {label: '51 ~ 100人', value: '51to100'},
  {label: '101 ~ 500人', value: '101to500'},
  {label: '501 ~ 1000人', value: '501to1000'},
  {label: '1001 ~ 5000人', value: '1001to5000'},
  {label: '5001 ~ 10000人', value: '5001to10000'},
  {label: '10001人 ~', value: '10001to'},
  {label: '従業員数不明', value: 'unknown'},
];

export default employeeRangeOptions;
