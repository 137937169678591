import gql from 'graphql-tag';

export default gql`
  fragment clientFragment on Client {
    id
    uuid
    name
    domain
    isDisplayCount
    slackActive
    chatworkActive
    hubspotActive
    salesforceActive
    sensesActive
    miitelActive
    googleActive
    zoomPhoneActive
    canApplyBlockGroup
  }
`;
