import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9997 8.49986C14.9997 4.63398 11.8657 1.5 7.99986 1.5C4.13398 1.5 1 4.63398 1 8.49986C1 11.7825 3.26011 14.5371 6.30897 15.2936V10.639H4.8656V8.49986H6.30897V7.57812C6.30897 5.19565 7.38723 4.09135 9.72631 4.09135C10.1698 4.09135 10.935 4.17843 11.2481 4.26522V6.20419C11.0829 6.18683 10.7959 6.17815 10.4395 6.17815C9.29175 6.17815 8.84824 6.61298 8.84824 7.74332V8.49986H11.1347L10.7418 10.639H8.84824V15.4485C12.3143 15.0299 15 12.0787 15 8.49986H14.9997Z"
      fill="#0866FF"
    />
    <path
      d="M10.7415 10.639L11.1343 8.49988H8.84787V7.74334C8.84787 6.613 9.29139 6.17817 10.4391 6.17817C10.7955 6.17817 11.0825 6.18685 11.2477 6.20421V4.26525C10.9347 4.17817 10.1694 4.09137 9.72594 4.09137C7.38686 4.09137 6.30861 5.19567 6.30861 7.57814V8.49988H4.86523V10.639H6.30861V15.2937C6.85011 15.4281 7.41654 15.4997 7.99949 15.4997C8.28649 15.4997 8.56956 15.4821 8.84759 15.4485V10.639H10.7412H10.7415Z"
      fill="white"
    />
  </svg>
);

export default Icon;
