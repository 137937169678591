import React from 'react';
import styled from 'styled-components';
import {PrefectureType} from 'api';
import Cities from './Cities';

export default ({
  prefectureTypes,
  prefectureTypeNames,
  setCurrentPrefectureType,
}: {
  prefectureTypes: PrefectureType[];
  prefectureTypeNames: string[];
  setCurrentPrefectureType: (prefectureType: string) => void;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [sectionPositions, setSectionPositions] = React.useState([]);

  React.useLayoutEffect(() => {
    const sectionPositions = Array.prototype.slice
      .call(ref.current?.getElementsByTagName('section'))
      .map((section: any) => ({
        id: section.id,
        top: section.offsetTop - ref.current?.offsetTop,
        bottom:
          section.offsetTop - ref.current?.offsetTop + section.clientHeight,
        height: section.clientHeight,
      }));

    setSectionPositions(sectionPositions);
  }, [prefectureTypes]);

  const handleScroll = () => {
    const activeSection = sectionPositions.find((position: any) => {
      const scroll = ref.current?.scrollTop;
      return position.top <= scroll && position.bottom > scroll;
    });
    activeSection && setCurrentPrefectureType(activeSection.id);
  };

  return (
    <Container
      ref={ref}
      id="area-filter-modal-content"
      onScroll={handleScroll}
      style={
        sectionPositions.length > 0
          ? {
              paddingBottom: `calc(640px - ${
                sectionPositions[sectionPositions.length - 1].height
              }px)`,
            }
          : {}
      }>
      {prefectureTypeNames?.map((prefectureTypeName) =>
        prefectureTypes?.map((prefectureType) => {
          if (prefectureTypeName === prefectureType.name) {
            return (
              <Cities
                key={prefectureType.id}
                prefectureType={prefectureType}
                cities={prefectureType.cities}
              />
            );
          }
        }),
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  min-width: 400px;
  border-right: 1px solid #f3f3f5;
  overflow-y: scroll;
`;
