import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  if (!query?.types || !query.types.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">ToDo種別</h4>
        <button
          onClick={() => setFilterType('taskType')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setQuery({...query, types: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap gap-1">
        {[
          {label: '電話', value: 'tel'},
          {label: '手動メール', value: 'mail'},
          {label: 'その他', value: 'other'},
        ]
          .filter((approachType) => query.types.includes(approachType.value))
          .map((approachType) => (
            <span
              key={approachType.value}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {approachType.label}
            </span>
          ))}
      </div>
    </div>
  );
};
