import gql from 'graphql-tag';
import manualMailFragment from '../manualMail';

export default gql`
  fragment manualMailGmailSendProspectActivityFragment on ManualMailGmailSendProspectActivity {
    id
    stage
    stageText
    createdAt
    manualMail {
      ...manualMailFragment
      mailAttachments {
        uuid
        filename
        extension
      }
    }
  }
  ${manualMailFragment}
`;
