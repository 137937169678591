import React, {useState} from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'components/antd';
import {
  useWorkflowTemplateCategoriesQuery,
  useWorkflowTemplateWorkflowsByCategoryQuery,
  useWorkflowTemplateWorkflowsQuery,
  WorkflowTemplateWorkflow,
} from 'api';
import StepsComponents from './StepsComponent';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setSelectModal: (selectModal: boolean) => void;
  setNewModal: (newModal: boolean) => void;
  setSelectTemplate: (template: WorkflowTemplateWorkflow) => void;
}

const TemplateModalComponent = ({
  visible,
  setVisible,
  setSelectModal,
  setNewModal,
  setSelectTemplate,
}: Props) => {
  const targets = [
    {name: 'all_prospects', displayName: 'すべてのリードが対象'},
    {
      name: 'only_new_prospects',
      displayName: '新規に追加登録されたリードのみ対象',
    },
    {name: 'only_existing_prospects', displayName: '登録済みリードのみ対象'},
  ];
  const {data: {workflowTemplateWorkflows} = {}} =
    useWorkflowTemplateWorkflowsQuery();
  const {data: {workflowTemplateCategories = []} = {}} =
    useWorkflowTemplateCategoriesQuery({
      onCompleted: () => setCurrentCategory(workflowTemplateCategories[0]),
    });

  const [currentCategory, setCurrentCategory] = useState(
    workflowTemplateCategories[0],
  );
  const [displayStepWorkflowId, setDisplayStepWorkflowId] = useState([]);

  const {data: {workflowTemplateWorkflowsByCategory} = {}} =
    useWorkflowTemplateWorkflowsByCategoryQuery({
      variables: {workflowTemplateCategoryId: currentCategory?.id},
      skip: !currentCategory,
    });

  return (
    <TemplateModal
      title={'テンプレートを選択'}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={960}>
      <LeftWrapper>
        <span style={{color: '#899098', marginBottom: '12px'}}>カテゴリ</span>
        <div>
          {workflowTemplateCategories?.map((category) => (
            <Category
              current={currentCategory?.id === category.id}
              key={category.id}
              onClick={() => setCurrentCategory(category)}>
              <span>{category.name}</span>
              <span>
                {workflowTemplateWorkflows?.filter(
                  (workflow) =>
                    workflow?.workflowTemplateCategoryId == category?.id,
                )?.length || 0}
              </span>
            </Category>
          ))}
        </div>
      </LeftWrapper>

      <RightWrapper>
        {workflowTemplateWorkflowsByCategory?.map((workflow) => (
          <Content key={workflow.uuid}>
            <h3>{workflow.name}</h3>
            <span>{workflow.memo}</span>
            <p style={{marginTop: '10px'}}>
              対象:{' '}
              {
                targets.find((target) => workflow.target === target.name)
                  .displayName
              }
            </p>
            <Stats>
              <div>
                <span>ステップ数</span>
                <span>{workflow.stepCount}</span>
              </div>
              <div>
                <span>自動メール</span>
                <span>{workflow.automaticMailStepCount}</span>
              </div>
              <div>
                <span>期間</span>
                <span>
                  {workflow.dueDay}
                  <span
                    style={{
                      fontSize: 10,
                      color: '#899098',
                      fontWeight: 'normal',
                      marginLeft: 3,
                    }}>
                    日
                  </span>
                </span>
              </div>
            </Stats>
            {displayStepWorkflowId.includes(workflow.id) && (
              <StepsComponents workflow={workflow} />
            )}
            <BottomContent>
              <div
                style={{color: '#68b5fb', cursor: 'pointer'}}
                onClick={() =>
                  setDisplayStepWorkflowId(
                    displayStepWorkflowId.includes(workflow.id)
                      ? displayStepWorkflowId.filter((id) => id !== workflow.id)
                      : [...displayStepWorkflowId, workflow.id],
                  )
                }>
                <span>
                  {displayStepWorkflowId.includes(workflow.id)
                    ? 'ステップ詳細を閉じる'
                    : 'ステップ詳細を確認'}
                </span>
                <span
                  style={{
                    marginLeft: '5px',
                    display: 'inline-block',
                    transform: displayStepWorkflowId.includes(workflow.id)
                      ? 'scale(1.0, 0.7) rotate(90deg)'
                      : 'scale(1.0, 0.7) rotate(-90deg)',
                  }}>
                  ＜
                </span>
              </div>
              <Button
                style={{marginTop: 'auto'}}
                htmlType="button"
                type="primary"
                onClick={() => {
                  setSelectTemplate(workflow);
                  setSelectModal(false);
                  setVisible(false);
                  setNewModal(true);
                }}>
                このテンプレートを使用
              </Button>
            </BottomContent>
          </Content>
        ))}
      </RightWrapper>
    </TemplateModal>
  );
};

const TemplateModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #bdc4cb;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  h3 {
    color: #495058;
    font-weight: bold;
    font-size: 16px;
  }
  p {
    padding: 5px;
    background: #f3f5f7;
    margin-right: auto;
  }
`;

const Category = styled.div<{current: boolean}>`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: ${({current}) => (current ? '#F5FAFF' : '')};
  font-weight: ${({current}) => (current ? 'bold' : '')};
  cursor: pointer;
  border-radius: 4px;
`;

const Stats = styled.div`
  display: flex;
  padding-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #e1e6eb;
  div {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    span:first-child {
      color: #899098;
      font-size: 10px;
    }
    span:last-child {
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    align-items: center;
  }
`;

export default TemplateModalComponent;
