import React from 'react';
import {useRecoilState} from 'recoil';
import selectCompanyPreleadProjectAtom from '../selectCompanyPreleadProjectAtom';
import {Modal} from 'components/antd';
import Form from './Form';

export default () => {
  const [
    selectCompanyPreleadProject,
    setSelectCompanyPreleadProject,
  ] = useRecoilState(selectCompanyPreleadProjectAtom);

  return (
    selectCompanyPreleadProject && (
      <Modal
        title={null}
        visible={Boolean(selectCompanyPreleadProject)}
        width={1000}
        onCancel={() => setSelectCompanyPreleadProject(null)}
        footer={null}>
        <Form onCompleted={() => setSelectCompanyPreleadProject(null)} />
      </Modal>
    )
  );
};
