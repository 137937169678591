import React from 'react';
import {
  Calendar,
  HashTag,
  SettingRectangle,
  Text as TextIcon,
} from 'components/Ui/Icon';
import {Item} from '../Styles';
import {CustomizeItem, useCurrentClientQuery} from 'api';
import InputNumber from './InputNumber';
import InputText from './InputText';
import InputDate from './InputDate';
import styled from 'styled-components';

const dataTypeIcon: {[key: string]: JSX.Element} = {
  text: <TextIcon />,
  number: <HashTag />,
  date: <Calendar />,
};

const Input = (customizeItem: CustomizeItem) => {
  switch (customizeItem.dataType) {
    case 'text':
      return <InputText customizeItem={customizeItem} />;
    case 'number':
      return <InputNumber customizeItem={customizeItem} />;
    case 'date':
      return <InputDate customizeItem={customizeItem} />;
  }
};

export default () => {
  const {data: {currentClient: {customizeItems = []} = {}} = {}} =
    useCurrentClientQuery({});

  return (
    <>
      {customizeItems.map((customizeItem) => (
        <Item key={customizeItem.id}>
          <div>
            {dataTypeIcon[customizeItem.dataType]}
            <span>{customizeItem.name}</span>
          </div>
          <div>{Input(customizeItem)}</div>
        </Item>
      ))}
      <ButtomSection>
        <div onClick={() => window.open(`/settings/customize_item`)}>
          <SettingRectangle />
        </div>
      </ButtomSection>
    </>
  );
};

const ButtomSection = styled.div`
  display: flex;
  > div {
    margin-top: 12px;
    margin-left: auto;
    cursor: pointer;
  }
`;
