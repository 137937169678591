import React from 'react';
import {Breadcrumb} from 'components/Ui';
import {Link, useParams, useHistory} from 'react-router-dom';
import {
  useProjectQuery,
  usePreleadProjectQuery,
  ProjectGroup,
  useCurrentClientQuery,
} from 'api';
import useProjectPath from 'hooks/useProjectPath';
import useProjectGroups from 'hooks/useProjectGroups';

export default () => {
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {
    projectId,
    preleadProjectId,
    projectGroupId,
    requestClientId,
    offeredClientId,
  } = useParams<{
    projectId: string;
    preleadProjectId: string;
    projectGroupId: string;
    requestClientId: string;
    offeredClientId: string;
  }>();

  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    onCompleted: () => {
      if (project?.status === 'deleted' || project === null) {
        history.push(createPath(`projects`));
      }
    },
  });

  const {data: {preleadProject = null} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    fetchPolicy: 'cache-and-network',
    skip: !preleadProjectId,
  });

  const {accessibleProjectGroups: projectGroups} = useProjectGroups();

  const {
    data: {
      currentClient: {offeredClients = [], requestActiveClients = []} = {},
    } = {},
  } = useCurrentClientQuery();

  const projectGroup: ProjectGroup = React.useMemo(
    () =>
      projectGroups.find(
        (projectGroup) => projectGroup.uuid === projectGroupId,
      ),
    [projectGroupId, projectGroups],
  );

  const offeredClient = React.useMemo(
    () => offeredClients.find((client) => client.uuid === offeredClientId),
    [offeredClients],
  );
  const requestClient = React.useMemo(
    () =>
      requestActiveClients.find((client) => client.uuid === requestClientId),
    [requestClientId],
  );

  if (!project) {
    return null;
  }

  return (
    <Breadcrumb separator=">">
      {!location.pathname.startsWith('/list/projects') && (
        <>
          <Breadcrumb.Item>
            <Link
              className="name"
              to={
                projectGroup
                  ? `/list/groups`
                  : offeredClient
                  ? `/list/share/request`
                  : `/list/share/offered`
              }>
              {projectGroup && 'グループ'}
              {offeredClient && '依頼先'}
              {requestClient && '依頼元'}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {projectGroup ? (
              <Link to={createPath('basic_information')}>
                {projectGroup.name}
              </Link>
            ) : (
              <Link to={createPath('projects')}>
                {offeredClient && offeredClient.name}
                {requestClient && requestClient.name}
              </Link>
            )}
          </Breadcrumb.Item>
        </>
      )}
      <Breadcrumb.Item>
        <Link className="name" to={createPath('projects')}>
          営業リスト一覧
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link
          className="name"
          to={createPath(`projects/${project.uuid}/project_summary`)}>
          {project.name}
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        {preleadProject?.prelead ? (
          <Link
            className="name"
            to={createPath(`projects/${project.uuid}/preleads`)}>
            プレリード
          </Link>
        ) : (
          <span>プレリード</span>
        )}
      </Breadcrumb.Item>
      {preleadProject?.prelead && (
        <Breadcrumb.Item>
          <span className="current">{preleadProject?.prelead.name}</span>
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};
