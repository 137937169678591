import React from 'react';
import moment from 'moment';
import {useForm} from 'react-hook-form';
import {ChevronDownIcon, CheckIcon} from '@heroicons/react/20/solid';
import {prospectStages} from 'helpers/prospectStages';
import {Menu, Transition} from '@headlessui/react';
import {Quill, QuillWrapper} from 'components/Ui';
import {
  Action,
  ApproachProspect,
  ProspectPool,
  Approach,
  useActionsByProspectPoolQuery,
  useCurrentClientQuery,
  useCreateCallProspectActivityMutation,
  CallProspectActivityAttributes,
} from 'api';

interface Props {
  prospectPool: ProspectPool;
  approach: Approach;
  onCompleted: () => void;
}

const TelForm = ({prospectPool, approach, onCompleted}: Props) => {
  const isAction = approach?.approachableType === 'Action';

  const {data: {currentClient: {prospectTelStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const currentStage = prospectStages.find((stage) => {
    return stage.name === prospectPool?.stage;
  });

  const {data: {actionsByProspectPool = []} = {}} =
    useActionsByProspectPoolQuery({
      variables: {
        prospectPoolUuid: prospectPool?.uuid,
      },
      fetchPolicy: 'cache-and-network',
    });

  const [create] = useCreateCallProspectActivityMutation({
    onCompleted: onCompleted,
    refetchQueries: ['approachCounts', 'actions'],
  });

  const status = approach.status;

  const actionStartDate = isAction
    ? actionsByProspectPool.find(
        (action) => action.uuid === (approach?.approachable as Action).uuid,
      )?.startDate
    : null;

  const dateTimeFormat = 'YYYY-MM-DD HH:mm';
  const dateFormat = 'YYYY-MM-DD';

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: {isSubmitted},
  } = useForm({
    defaultValues: {
      stage: currentStage?.id,
      recordedAt: moment().startOf('hour').format(dateTimeFormat).toString(),
      comment: '',
      commentHtml: '',
      isCompleted: false,
      nextActionDate: actionStartDate
        ? moment(actionStartDate).format(dateFormat).toString()
        : null,
      clientProspectTelStatusId: null,
    },
    mode: 'onChange',
  });

  const values = watch();
  const {isCompleted} = values;

  const onSubmit = (
    values: {
      isCompleted: boolean;
      nextActionDate: string;
    } & CallProspectActivityAttributes,
  ) => {
    const {isCompleted, nextActionDate, ...attributes} = values;

    create({
      variables: {
        uuid: prospectPool?.uuid,
        actionUuid: isAction ? (approach?.approachable as Action).uuid : null,
        approachUuid: approach?.uuid,
        isCompleted: isCompleted,
        nextActionDate: nextActionDate || null,
        attributes: attributes,
      },
    });
  };

  React.useEffect(() => {
    reset({
      ...values,
      nextActionDate: actionStartDate
        ? moment(actionStartDate).format(dateFormat).toString()
        : null,
    });
  }, [actionStartDate]);

  return (
    <form className="p-6 flex flex-col gap-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-1">
        <label className="text-c-light">アプローチ名</label>
        <span className="text-base">
          {approach?.approachable?.__typename === 'Action' &&
            (approach?.approachable as Action)?.step?.name}
          {approach?.approachable?.__typename === 'ApproachProspect' &&
            (approach?.approachable as ApproachProspect)?.approachGroupProspect
              ?.title}
        </span>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex flex-col gap-1">
          <label className="text-c-light">ステージ</label>
          <select
            className="form-select border-c-border rounded text-sm w-full"
            {...register('stage', {valueAsNumber: true})}>
            {prospectStages.map((stage) => (
              <option key={stage.id} value={stage.id}>
                {stage.displayName}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-c-light">TELステータス</label>
          <select
            className="form-select border-c-border rounded text-sm w-full"
            {...register('clientProspectTelStatusId')}>
            <option value="">未選択</option>
            {prospectTelStatuses.map((telStatus) => (
              <option key={telStatus.id} value={telStatus.id}>
                {telStatus.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex flex-col gap-1">
          <label className="text-c-light">日時</label>
          <input
            type="datetime-local"
            {...register('recordedAt')}
            className="form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
          />
        </div>
        {isAction && (
          <div className="flex flex-col gap-1">
            <label className="text-c-light">ネクストアクション日</label>
            <input
              type="date"
              disabled={isCompleted}
              {...register('nextActionDate')}
              className="form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
            />
          </div>
        )}
      </div>
      <div>
        <QuillWrapper
          style={{
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: 0,
          }}
          className="
            mb-8
            [&_.ql-toolbar]:absolute
            [&_.ql-toolbar]:w-full
            [&_.ql-toolbar]:bottom-0
            [&_.ql-toolbar]:left-0
            [&_.ql-toolbar]:translate-y-full
            [&_.ql-editor]:max-h-80
        ">
          <Quill
            placeholder="コメント"
            theme="snow"
            onChange={(content, delta, source, editor) => {
              setValue('commentHtml', content);
              setValue('comment', editor.getText());
            }}
            modules={{
              toolbar: [
                {header: [1, 2, 3, false]},
                'bold',
                'italic',
                'underline',
                'code-block',
                'link',
              ],
            }}
            bounds={'#quill-container'}
          />
          <div id="quill-container" />
        </QuillWrapper>
      </div>
      <div className="flex items-center justify-end">
        <button
          type="submit"
          disabled={isSubmitted}
          className={`bg-[#61CF8D] text-white text-base font-medium py-2 w-72 flex items-center justify-center border-none cursor-pointer disabled:bg-c-bg disabled:text-c-light ${
            status === 'open' ? 'rounded-l' : 'rounded'
          }`}>
          {isCompleted
            ? 'アプローチを完了して登録'
            : 'アプローチを完了しないでログのみ登録'}
        </button>
        {status === 'open' && (
          <Menu as="div" className="relative inline-block text-left z-30">
            <Menu.Button className="bg-[#61CF8D] text-white text-base font-medium py-2 w-8 border-l border-white rounded-r cursor-pointer">
              <ChevronDownIcon className="w-4 h-4 relative top-1" />
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute right-0 w-80 origin-top-right rounded-b bg-white shadow-lg focus:outline-none z-30">
                <Menu.Item>
                  <div
                    className={`hover:bg-c-bg py-4 pl-2 pr-4 w-full flex items-center gap-2 ${
                      isCompleted ? 'bg-white' : 'bg-c-bg'
                    }`}>
                    <CheckIcon
                      className={`w-4 h-4 ${
                        isCompleted ? 'text-transparent' : 'text-[#61CF8D]'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setValue('isCompleted', false)}
                      className="cursor-pointer bg-transparent text-left text-base text-medium w-full hover:bg-c-bg flex flex-col gap-2 leading-none">
                      アプローチを完了しないでログのみ登録
                      <span className="text-xs">
                        アプローチは完了せずアプローチ一覧に残ります
                      </span>
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className={`hover:bg-c-bg py-4 pl-2 pr-4 w-full flex items-center gap-2 ${
                      isCompleted ? 'bg-c-bg' : 'bg-white'
                    }`}>
                    <CheckIcon
                      className={`w-4 h-4 ${
                        isCompleted ? 'text-[#61CF8D]' : 'text-transparent'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setValue('isCompleted', true)}
                      className="cursor-pointer bg-transparent text-left text-base text-medium w-full hover:bg-c-bg flex flex-col gap-2 leading-none">
                      アプローチを完了して登録
                      <span className="text-xs">
                        アプローチは完了しアプローチ一覧から削除されます。
                      </span>
                    </button>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </form>
  );
};

export default TelForm;
