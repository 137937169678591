import React from 'react';
import styled from 'styled-components';

interface Props {
  key: string;
  label: string;
  value: string;
}

export default ({key, label, value}: Props) => {
  const REGEXP_URL = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g;

  const autoLink = (str: string) => {
    const regexpMakeLink = (url: string) =>
      `<a href="${url}" target="_blank">${url}</a>`;
    return str.replace(REGEXP_URL, regexpMakeLink);
  };

  const isUrl = (str: string) => REGEXP_URL.test(str);

  return (
    <Wrapper key={key}>
      <h4>{label}</h4>
      {isUrl(value) ? (
        <p
          dangerouslySetInnerHTML={{
            __html: autoLink(value),
          }}
        />
      ) : (
        <p>{value}</p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  h4,
  p {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    width: calc(100% - 100px);
  }
  h4 {
    width: 100px;
    color: #899098;
    font-size: 12px;
  }
  a {
    cursor: pointer;
  }
`;
