import React from 'react';
import {useTable, useBlockLayout, useResizeColumns, Row} from 'react-table';
import {Prelead, usePreleadsQuery, usePreleadMasterColumnsQuery} from 'api';
import Header from './Header';
import Cell from './Cell';
import useSearchParams from './useSearchParams';
import {useRecoilState} from 'recoil';
import checkedPreleadsAtom from './checkedPreleadsAtom';
import moment from 'moment';
import {useParams} from 'react-router';
import toJaNum from 'helpers/toJaNum';

const baseColumns = [
  {
    id: 'check',
    accessor: 'check',
    width: 36,
    Header: Header.Check,
    Cell: Cell.Check,
    align: 'center',
    sticky: true,
  },
];

export const columnDef: {[key: string]: any} = {
  status: {
    accessor: 'statusText',
    width: 36,
    Cell: Cell.Status,
    align: 'center',
    sticky: true,
  },
  company_name: {accessor: 'name', Cell: Cell.Name, sticky: true},
  project_group: {accessor: 'group', Cell: Cell.ProjectGroup},
  user: {accessor: 'userId', Cell: Cell.UserName},
  remind: {accessor: 'remindAt', Cell: Cell.Remind, width: 120},
  // tag: {accessor: 'tag', width: 170, Cell: Cell.Tag}, // プレリードのタグをプールごとに管理するようにしたため、一旦非表示にする。
  nayose: {accessor: 'company', width: 56, Cell: Cell.Nayose, align: 'center'},
  project_count: {
    accessor: 'projectCount',
    Cell: Cell.ProjectCount,
    width: 100,
    align: 'right',
  },
  comment: {
    accessor: 'preleadProject.activityCount',
    width: 80,
    Cell: Cell.Comment,
    align: 'center',
  },
  updated_at: {
    accessor: (row: any) =>
      row.activityUpdatedAt
        ? moment(row.activityUpdatedAt).format('YYYY.MM.DD')
        : null,
    width: 80,
    align: 'center',
  },
  pr_last_published_at: {
    accessor: 'prLastPublishedAt',
    width: 80,
    Cell: Cell.Alert,
    align: 'center',
  },
  click_count: {
    accessor: 'preleadProject.clickCount',
    width: 90,
    Cell: Cell.Click,
    align: 'right',
  },
  click_updated_at: {
    accessor: 'preleadProject.clickUpdatedAt',
    width: 170,
    Cell: Cell.ClickUpdatedAt,
  },
  mail_status_count: {
    accessor: 'preleadProject.mailStatus',
    Cell: Cell.MailCount,
    align: 'right',
  },
  mail_status: {accessor: 'mailStatusText', Cell: Cell.Mail},
  tel_status_count: {
    accessor: 'preleadProject.telStatus',
    Cell: Cell.TelCount,
    align: 'right',
  },
  tel_status: {accessor: 'preleadProject.clientTelStatus', Cell: Cell.Tel},
  tel_user: {accessor: 'telUserId', Cell: Cell.TelUser},
  tel_updated_at: {accessor: 'telUpdatedAt', Cell: Cell.TelUpdatedAt},
  contact: {accessor: 'contact', Cell: Cell.Contact},
  telephone_number: {accessor: 'telephoneNumber'},
  web_site: {
    accessor: (row: any) => (
      <a href={row.webSite} target="_blank">
        {row.webSite}
      </a>
    ),
  },
  mail_address: {accessor: 'mailAddress'},
  corp_number: {accessor: 'corpNumber'},
  main_category: {
    accessor: 'mainCategoryIds',
    Cell: Cell.MainCategory,
    align: 'left',
  },
  sub_category: {
    accessor: 'subCategoryIds',
    Cell: Cell.SubCategory,
    align: 'left',
  },
  employee_number: {
    accessor: (row: any) => row.employeeNumber?.toLocaleString(),
    align: 'right',
  },
  capital_fund: {
    accessor: (row: any) => row.capitalFund && `${toJaNum(row.capitalFund)}円`,
    align: 'right',
  },
  offices_number: {
    accessor: (row: any) =>
      row.officesNumber && Number(row.officesNumber).toLocaleString(),
    align: 'right',
  },
  account_closing_month: {
    accessor: (row: any) =>
      row.accountClosingMonth && `${row.accountClosingMonth}月`,
    align: 'left',
  },
  listing_market: {accessor: 'listingMarketText'},
  sales: {
    accessor: (row: any) => row.sales && `${toJaNum(row.sales)}円`,
    align: 'right',
  },
  established_year_month: {
    accessor: (row: any) =>
      row.establishedYearMonth &&
      /^[0-9]{4}-[0-1]?[0-9]$/.test(row.establishedYearMonth)
        ? moment(row.establishedYearMonth, 'YYYY-MM').format('YYYY年MM月')
        : row.establishedYearMonth,
  },
  representative_person: {accessor: 'representativePerson'},
  prefecture: {
    Cell: Cell.Prefecture,
  },
  address: {accessor: 'address'},
  client_prelead_customize_item_text: {
    Cell: Cell.CustomizeItemText,
  },
  client_prelead_customize_item_number: {
    Cell: Cell.CustomizeItemNumber,
    align: 'right',
  },
  client_prelead_customize_item_date: {
    Cell: Cell.CustomizeItemDate,
  },
  client_prelead_customize_item_select: {
    Cell: Cell.CustomizeItemSelect,
  },
};

const usePreleadTable = () => {
  const {preleadId} = useParams<{
    preleadId: string;
  }>();
  const {page, perPage, searchParams, setQuery} = useSearchParams();

  const [, setCheckedPreleads] = useRecoilState(checkedPreleadsAtom);

  const {
    data: {preleads: {preleads = [], pagination = {}} = {}} = {},
    loading,
  } = usePreleadsQuery({
    variables: {
      search: searchParams,
      page,
      perPage,
    },
    onCompleted: () => setCheckedPreleads([]),
  });

  const paginationData = React.useMemo(() => {
    return {
      total: pagination.totalCount,
      current: pagination.currentPage,
      pageSize: perPage,
      onChange: (page: number) => setQuery({page}),
    };
  }, [pagination]);

  const {data: {preleadMasterColumns} = {}} = usePreleadMasterColumnsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const defaultColumn = {
    minWidth: 30,
    width: 150,
    maxWidth: 400,
  };

  const columns = React.useMemo(() => {
    const masterColumns =
      preleadMasterColumns
        ?.filter(
          (preleadMasterColumn) => columnDef[preleadMasterColumn.dataType],
        )
        .map((preleadMasterColumn) => {
          const column = columnDef[preleadMasterColumn.dataType];

          if (preleadMasterColumn.clientPreleadCustomizeItemId) {
            return {
              ...column,
              id: preleadMasterColumn.id,
              Header: preleadMasterColumn.title ?? '',
              accessor: () => preleadMasterColumn.clientPreleadCustomizeItemId,
            };
          }

          return {
            ...column,
            id: preleadMasterColumn.id,
            Header: preleadMasterColumn.title,
            accessor: column.accessor,
          };
        }) || [];

    return [...baseColumns, ...masterColumns];
  }, [preleadMasterColumns]);

  const {setHiddenColumns, ...restProps} = useTable(
    {
      columns,
      defaultColumn,
      data: preleads,
    },
    useBlockLayout,
    useResizeColumns,
  );

  React.useEffect(() => {
    const hiddenColumnIds = preleadMasterColumns
      ?.filter((column) => !column.isDisplay)
      .map((column) => column.id);

    if (hiddenColumnIds) {
      setHiddenColumns(hiddenColumnIds);
    }
  }, [columns]);

  return {
    setHiddenColumns,
    getRowProps: (row: Row<Prelead>) => {
      if (row.original.uuid === preleadId) {
        return {
          className: 'active',
        };
      }
      return {};
    },
    ...restProps,
    ...paginationData,
    loading: loading,
  };
};

export default usePreleadTable;
