import React from 'react';
import debounce from 'helpers/debounce';
import {FormBlock, FormBlockImage, useUpdateFormBlockImageMutation} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockImage;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable
  const [scale, setScale] = React.useState(blockable.imageScale);
  const [update] = useUpdateFormBlockImageMutation();

  const onSubmit = debounce(() => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: {
          imageScale: scale,
        },
      },
    });
  }, 500);

  React.useEffect(() => {
    onSubmit();
  }, [scale]);

  return (
    <div className="flex flex-col gap-1">
      <label className="tracking-wide font-medium text-base text-c-light">
        画像サイズ
      </label>
      <input
        id="default-range"
        type="range"
        value={scale * 100}
        min="1"
        max="100"
        step="1"
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        onChange={(e) => setScale(Number(e.target.value) / 100)}
      />
    </div>
  );
};
