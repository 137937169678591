import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import ProjectGroupFilter from './ProjectGroup';
import ProjectFilter from './Project';
import StatusesFilter from './Statuses';
import MailStatusesFilter from './MailStatuses';
import MailCountsFilter from './MailCounts';
import ClickTerm from './ClickTerm';
import MailTermFilter from './MailTerm';
import TelStatusesFilter from './TelStatuses';
import TelCountsFilter from './TelCounts';
import TelTermFilter from './TelTerm';
import ClickCountsFilter from './ClickCounts';
import useSearchParams from '../../useSearchParams';
import PressReleaseSinceFilter from './PressReleaseSince';
import TagIdsFilter from './TagIds';
import EmployeeNumberFilter from './EmployeeNumber';
import ListingMarketsFilter from './ListingMarkets';
import CapitalFund from './CapitalFund';
import OfficesNumberFilter from './OfficesNumber';
import AccountClosingMonthsFilter from './AccountClosingMonths';
import EstablishedYearMonthFilter from './EstablishedYearMonth';
import CategoryFilter from './Category';
import CommentCountsFilter from './CommentCounts';
import SalesFilter from './Sales';
import Remind from './Remind';
import AssigneesFilter from './Assignees';
import PrefecturesFilter from './Prefectures';
import CustomizeItemFilter from './CustomizeItem';
import TelUserFilter from './TelUsers';
import MailCampaignsFilter from './MailCampaigns';
import MailCampaignOpenCountsFilter from './MailCampaignOpenCounts';
import MailCampaignClickCountsFilter from './MailCampaignClickCounts';
import MailCampaignSendsCountsFilter from './MailCampaignSendsCounts';
import TrackedAt from './TrackedAt';
import TaskDueDate from './TaskDueDate';
import TaskUser from './TaskUser';
import MailCampaignOpenedAt from './MailCampaignOpenedAt';
import MailCampaignClickedAtFilter from './MailCampaignClickedAt';

const filterComponents: {[key: string]: any} = {
  projectGroupIds: ProjectGroupFilter,
  projectIds: ProjectFilter,
  preleadStatusIds: StatusesFilter,
  mailStatuses: MailStatusesFilter,
  mailCounts: MailCountsFilter,
  mailTerm: MailTermFilter,
  telStatusIds: TelStatusesFilter,
  telCounts: TelCountsFilter,
  telTerm: TelTermFilter,
  clickCounts: ClickCountsFilter,
  clickTerm: ClickTerm,
  pressReleaseSince: PressReleaseSinceFilter,
  tagIds: TagIdsFilter,
  employeeNumber: EmployeeNumberFilter,
  listingMarkets: ListingMarketsFilter,
  capitalFund: CapitalFund,
  officesNumber: OfficesNumberFilter,
  accountClosingMonths: AccountClosingMonthsFilter,
  establishedYearMonth: EstablishedYearMonthFilter,
  categoryIds: CategoryFilter,
  commentCounts: CommentCountsFilter,
  sales: SalesFilter,
  remind: Remind,
  assigneeIds: AssigneesFilter,
  prefectureIds: PrefecturesFilter,
  telUserIds: TelUserFilter,
  mailCampaignIds: MailCampaignsFilter,
  mailCampaignOpenCounts: MailCampaignOpenCountsFilter,
  mailCampaignClickCounts: MailCampaignClickCountsFilter,
  mailCampaignSendsCounts: MailCampaignSendsCountsFilter,
  trackedAt: TrackedAt,
  taskDueDate: TaskDueDate,
  taskUserIds: TaskUser,
  mailCampaignOpenedAt: MailCampaignOpenedAt,
  mailCampaignClickedAt: MailCampaignClickedAtFilter,
};

const Filter = ({filter}: {filter: string}) => {
  if (!filter) {
    return <BlankFilter />;
  }

  if (filter.startsWith('c.')) {
    return <CustomizeItemFilter filter={filter} />;
  }

  const FilterComponent =
    ['telFrom', 'telTo'].indexOf(filter) > 0
      ? filterComponents['telTerm']
      : filterComponents[filter];
  return <FilterComponent />;
};

export default ({
  filter,
  filters,
  index,
  onChange,
  availableOptions,
}: {
  filter: string;
  filters: string[];
  index: number;
  onChange: (filter: string) => void;
  availableOptions: {text: string; value: string}[];
}) => {
  const {query, setQuery} = useSearchParams();

  return (
    <Container>
      <div>
        {index === 0 && <span>Where</span>}
        {/*{index === 1 && (*/}
        {/*  <Select*/}
        {/*    value={query.mode}*/}
        {/*    onChange={(value: string) => setQuery({mode: value})}*/}
        {/*    dropdownMatchSelectWidth>*/}
        {/*    <Select.Option value="and" key="and" label="AND">*/}
        {/*      AND*/}
        {/*    </Select.Option>*/}
        {/*    <Select.Option value="or" key="or" label="OR">*/}
        {/*      OR*/}
        {/*    </Select.Option>*/}
        {/*  </Select>*/}
        {/*)}*/}
        {index >= 1 && <span>{query.mode.toUpperCase()}</span>}
      </div>
      <Select
        value={filter}
        onChange={onChange}
        style={{width: '250px'}}
        disabled={!query.mode && index > 0}
        placeholder="選択してください"
        dropdownMatchSelectWidth>
        {availableOptions.map((option) => (
          <Select.Option
            value={option.value}
            key={option.value}
            label={option.text}>
            {option.text}
          </Select.Option>
        ))}
      </Select>
      <Filter filter={filter} />
      <svg
        onClick={() => {
          if (filters.length == 2) {
            setQuery({mode: 'and'});
          }
          onChange(null);
        }}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.916016 9.08366L9.08268 0.916992M0.916016 0.916992L9.08268 9.08366L0.916016 0.916992Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  font-size: 12px;
  align-items: flex-start;
  margin-bottom: 12px;

  > *:first-child {
    width: 75px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;

    span {
      color: #899098;
    }

    div {
      flex: 1;
    }
  }

  > *:nth-child(2) {
    width: 180px;
    margin-right: 0.5rem;
  }

  > *:nth-child(3) {
    flex: 1;
  }

  > svg:last-child {
    height: 32px;
    margin: 0 0.5rem;
    cursor: pointer;
  }
`;

const BlankFilter = styled.div`
  flex: 1;
  height: 32px;
  background: #f5f5f5;
  border-radius: 2px;
`;
