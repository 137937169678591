import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {useProjectWithPreleadMasterSearchConditionQuery} from 'api';
import Sort from './Sort';
import Filter from './Filter';
import ProjectFilter from './ProjectFilter';
import SearchWord from './SearchWord';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {
    data: {project: preleadMasterSearchCondition = null} = {},
  } = useProjectWithPreleadMasterSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  if (!preleadMasterSearchCondition) return null;

  return (
    <Container>
      <Sort />
      <Filter />
      <ProjectFilter />
      <SearchWord />
    </Container>
  );
};

const Container = styled.div``;
