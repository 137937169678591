import React from 'react';
import styled from 'styled-components';
import {useUpdateWorkflowMutation, Workflow} from 'api';
import {Input} from 'components/antd';
import {useParams} from 'react-router';
import useClientUser from 'hooks/useClientUser';
import RunButton from './RunButton';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const {isAdmin, isMember} = useClientUser();

  const {workflowId} = useParams<{workflowId: string}>();

  const [name, setName] = React.useState(workflow.name);

  const [updateWorkflow] = useUpdateWorkflowMutation();

  const updateName = (e: any) => {
    if (e.target.value) {
      updateWorkflow({
        variables: {
          uuid: workflowId,
          attributes: {
            name: e.target.value,
          },
        },
      });
    } else {
      alert('ワークフロー名は必須です。');
      setName(workflow.name);
    }
  };

  return (
    <Container>
      <div>
        <Name
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={updateName}
          onPressEnter={updateName}
          disabled={!isMember}
        />
      </div>
      {isAdmin && <RunButton workflow={workflow} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  & > div {
    display: flex;
    align-items: center;
    &:first-child {
      width: 100%;
    }
    div:first-child {
      margin-right: 15px;
    }
  }
`;

const Name = styled(Input)`
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
  padding-left: 0;
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
