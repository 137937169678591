import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#E2E6EA" />
      <path
        d="M12.6667 11.5003V10.3337C12.6667 9.68933 13.189 9.16699 13.8333 9.16699H16.1667C16.811 9.16699 17.3333 9.68933 17.3333 10.3337V11.5003M9.75 11.5003H20.25H9.75ZM10.9167 11.5003V19.667C10.9167 20.3113 11.439 20.8337 12.0833 20.8337H17.9167C18.561 20.8337 19.0833 20.3113 19.0833 19.667V11.5003H10.9167Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.167 14.417V17.917"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.833 14.417V17.917"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
