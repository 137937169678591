import React from 'react';
import {AccountPool, Account} from 'api';
import {Row} from 'react-table';
import LinkIcon from './LinkIcon';
import CheckIcon from './CheckIcon';
import {Link, useParams} from 'react-router-dom';

interface Props {
  row: Row<AccountPool>;
}

const NameCell = ({row}: Props) => {
  const accountPool: AccountPool = row.original;
  const account: Account = accountPool.account;
  const {poolId: poolId} = useParams<{poolId: string}>();

  return (
    <div className="flex flex-col gap-1 justify-center h-full">
      <h4 className="text-base font-medium m-0 leading-none">
        <Link to={`/pools/${poolId}/accounts/${accountPool.uuid}`}>
          {account.name}
        </Link>
        {account.companyId && <CheckIcon className="ml-1 relative top-[2px]" />}
      </h4>
      <a
        target="_blank"
        href={account.webSite}
        className="text-xs text-c-light">
        {account.address || '住所不明'}
        {account.webSite && (
          <LinkIcon className="ml-1 relative text-[#BDC4CB] top-[3px]" />
        )}
      </a>
    </div>
  );
};

export default NameCell;
