import React from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';
import {ContentDocument} from 'components/Ui/Icon';
import {Content} from 'api';

interface Props {
  onClick: (text: string) => void;
  content: Content;
}

export default ({onClick, content}: Props) => (
  <Item key={content.id}>
    <Thumbnail>
      <ContentDocument />
    </Thumbnail>
    <div>
      <h4>{content.title}</h4>
      <a href={content.document?.url?.url} target="_blank">
        {content.document?.url?.url}
      </a>
    </div>
    <Button
      type="primary"
      shape="round"
      onClick={() => onClick(content.document?.url?.url)}>
      追加
    </Button>
  </Item>
);

const Item = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #e2e6ea;

  > div {
    flex: 1;
    margin: 0 1rem;

    h4 {
      margin: 0;
    }
  }
`;

const Thumbnail = styled.span`
  height: 36px;
  width: 36px;
  border: solid 1px #e2e6ea;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
  }
`;
