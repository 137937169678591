import gql from 'graphql-tag';

export default gql`
  fragment approachGroupPreleadFragment on ApproachGroupPrelead {
    id
    title
    approachType
    priority
    startDate
    dueDate
  }
`;

