import React from 'react';
import styled from 'styled-components';
import {Activity} from 'api';
import CommentActivity from './Comment';
import MailCommentActivity from './MailComment';
import MailSendActivity from './MailSend';
import MailFormSendActivity from './MailFormSend';
import MailClickReportActivity from './MailClickReport';
import TelCommentActivity from './TelComment';
import StatusChangeActivity from './StatusChange';
import ResponseStatusChangeActivity from './ResponseStatusChange';
import ResponseStatusAndCommentActivity from './ResponseStatusAndComment';
import TelStatusChangeActivity from './TelStatusChange';
import TelStatusAndCommentActivity from './TelStatusAndComment';
import PreleadContactCreateActivity from './PreleadContactCreate';
import PreleadProjectReminderCreateActivity from './PreleadProjectReminderCreate';
import SalesforceLeadCreateActivity from './SalesforceLeadCreate';
import HubspotDealCreateActivity from './HubspotDealCreate';
import HubspotContactCreateActivity from './HubspotContactCreate';
import SensesDealCreateActivity from './SensesDealCreate';
import SensesContactCreateActivity from './SensesContactCreate';
import LetterSendActivity from './LetterSend';
import LetterStatusChangeActivity from './LetterStatusChange';
import PreleadCompanyUpdateActivity from './PreleadCompanyUpdate';
import MailFormVersionUrlClickActivity from './MailFormVersionUrlClick';
import MailFormVersionPreleadProjectUrlClickActivity from './MailFormVersionPreleadProjectUrlClick';
import UserMailActivity from './UserMail';
import AssignUserActivity from './AssignUser';
import ExtMailFormSendActivity from './ExtMailFormSend';
import MiitelCallActivity from './MiitelCall';
import ZoomPhoneWebhookCallActivity from './ZoomPhoneWebhookCall';
import MailCampaignMailSendActivity from './MailCampaignMailSend';
import MailCampaignMailOpenActivity from './MailCampaignMailOpen';
import MailCampaignMailClickActivity from './MailCampaignMailClick';
import MailCampaignMailBounceActivity from './MailCampaignMailBounce';
import UnsubscribeMailCampaignActivity from './UnsubscribeMailCampaign';
import UnsubscribePreleadActivity from './UnsubscribePrelead';
import SubscribePreleadActivity from './SubscribePrelead';
import ProspectCreateActivity from './ProspectCreate';
import MiitelCallPreleadActivity from './MiitelCallPrelead';

const activityComponents: {[key: string]: any} = {
  CommentActivity: CommentActivity,
  MailCommentActivity: MailCommentActivity,
  MailSendActivity: MailSendActivity,
  MailFormSendActivity: MailFormSendActivity,
  MailClickReportActivity: MailClickReportActivity,
  TelCommentActivity: TelCommentActivity,
  StatusChangeActivity: StatusChangeActivity,
  ResponseStatusChangeActivity: ResponseStatusChangeActivity,
  ResponseStatusAndCommentActivity: ResponseStatusAndCommentActivity,
  TelStatusChangeActivity: TelStatusChangeActivity,
  TelStatusAndCommentActivity: TelStatusAndCommentActivity,
  PreleadContactCreateActivity: PreleadContactCreateActivity,
  PreleadProjectReminderCreateActivity: PreleadProjectReminderCreateActivity,
  SalesforceLeadCreateActivity: SalesforceLeadCreateActivity,
  HubspotDealCreateActivity: HubspotDealCreateActivity,
  HubspotContactCreateActivity: HubspotContactCreateActivity,
  SensesDealCreateActivity: SensesDealCreateActivity,
  SensesContactCreateActivity: SensesContactCreateActivity,
  LetterSendActivity: LetterSendActivity,
  LetterStatusChangeActivity: LetterStatusChangeActivity,
  PreleadCompanyUpdateActivity: PreleadCompanyUpdateActivity,
  MailFormVersionUrlClickActivity: MailFormVersionUrlClickActivity,
  MailFormVersionPreleadProjectUrlClickActivity:
    MailFormVersionPreleadProjectUrlClickActivity,
  UserMailActivity: UserMailActivity,
  AssignUserActivity: AssignUserActivity,
  ExtMailFormSendActivity: ExtMailFormSendActivity,
  MiitelCallActivity: MiitelCallActivity,
  ZoomPhoneWebhookCallActivity: ZoomPhoneWebhookCallActivity,
  MailCampaignMailSendActivity: MailCampaignMailSendActivity,
  MailCampaignMailOpenActivity: MailCampaignMailOpenActivity,
  MailCampaignMailClickActivity: MailCampaignMailClickActivity,
  MailCampaignMailBounceActivity: MailCampaignMailBounceActivity,
  UnsubscribeMailCampaignActivity: UnsubscribeMailCampaignActivity,
  UnsubscribePreleadActivity: UnsubscribePreleadActivity,
  SubscribePreleadActivity: SubscribePreleadActivity,
  ProspectCreateActivity: ProspectCreateActivity,
  MiitelCallPreleadActivity: MiitelCallPreleadActivity,
};

interface Props {
  activity: Activity;
}

const TimelineItem = ({activity}: Props) => {
  const ActivityComponent = activityComponents[activity.resource.__typename];

  return (
    <Container>
      <ActivityComponent activity={activity} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 30px 0 0 0;
  margin-left: 30px;
  color: #899098;
  word-break: break-word;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    display: block;
    width: 1px;
    content: '';
    background-color: #e1e6eb;
  }
`;

export default TimelineItem;
