import React from 'react';
import {useWorkflowQuery} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {workflowId} = useParams<{workflowId: string}>();

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
  });

  const totalDays = React.useMemo(() => {
    return workflow.steps.reduce((acc, step) => {
      if (step.isImmediately) return acc;
      if (!step.startDay) return acc;
      acc += step.startDay;
      return acc;
    }, 0);
  }, [workflow]);

  return (
    <ul className="w-full flex gap-4 h-20 items-center">
      <li className="flex gap-1 leading-none items-end">
        <span className="text-xl font-bold">{workflow?.steps?.length}</span>
        <span className="text-sm">ステップ</span>
      </li>
      <li className="border-r border-c-border h-4" />
      <li className="flex gap-1 leading-none items-end">
        <span className="text-xl font-bold">{totalDays || 0}</span>
        <span className="text-sm">日間</span>
      </li>
    </ul>
  );
};
