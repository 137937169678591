import React from 'react';
import {useParams} from 'react-router-dom';
import {useSalesforceLeadRecordTypesQuery} from 'api';
import useSearchParams from '../../../../useSearchParams';

const RecordType = () => {
  const {query, setQuery} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();
  const {data: {salesforceLeadRecordTypes = []} = {}} =
    useSalesforceLeadRecordTypesQuery({
      variables: {
        poolId: poolId,
      },
    });

  return (
    <div className="flex flex-col gap-2">
      <div className="text-c-base font-bold">レコードタイプ</div>
      <select
        className="form-select border-c-border rounded text-base w-full"
        onChange={(e) => {
          setQuery({
            ...query,
            salesforceLeadRecordTypeId: e.target.value,
            salesforceLeadFieldSelectStatusIds: [],
            salesforceLeadSearchConditions: [],
          });
        }}
        value={query?.salesforceLeadRecordTypeId}>
        <option value="" />
        {salesforceLeadRecordTypes.map((salesforceLeadRecordType) => (
          <option
            value={salesforceLeadRecordType.id}
            key={salesforceLeadRecordType.id}>
            {salesforceLeadRecordType.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RecordType;
