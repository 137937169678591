import React from 'react';
import styled from 'styled-components';
import {useParams, Route, Switch, Link} from 'react-router-dom';
import ConversionRate from './conversionRate';
import CallRate from './callRate';
import CallCount from './CallCount';
import PreleadCount from './PreleadCount';
import CallReport from './callReport';
import PreleadReport from './preleadReport';
import projectRoutePaths from 'helpers/projectRoutePaths';
import useProjectPath from 'hooks/useProjectPath';
import AllPreleadCount from './AllPreleadCount';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();

  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('/call')}>
          <Link to={createPath(`projects/${projectId}/tel_reports/all/call`)}>
            コール数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/prelead')}>
          <Link
            to={createPath(`projects/${projectId}/tel_reports/all/prelead`)}>
            プレリード数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/conversion_rate')}>
          <Link
            to={createPath(
              `projects/${projectId}/tel_reports/all/conversion_rate`,
            )}>
            転換率
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/call_rate')}>
          <Link
            to={createPath(`projects/${projectId}/tel_reports/all/call_rate`)}>
            対コール率
          </Link>
        </TabItem>
      </Tabs>

      <CountSection>
        <AllPreleadCount />
        <CallCount />
        <PreleadCount />
      </CountSection>

      <Switch>
        <Route
          path={projectRoutePaths('projects/:projectId/tel_reports/all/call')}
          component={CallReport}
        />
        <Route
          path={projectRoutePaths(
            'projects/:projectId/tel_reports/all/prelead',
          )}
          component={PreleadReport}
        />
        <Route
          path={projectRoutePaths(
            'projects/:projectId/tel_reports/all/conversion_rate',
          )}
          component={ConversionRate}
        />
        <Route
          path={projectRoutePaths(
            'projects/:projectId/tel_reports/all/call_rate',
          )}
          component={CallRate}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  width: 1200px;
  background: #ffffff;
  padding: 30px;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? '#222426' : '#BDC4CB')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid #495058' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;

const CountSection = styled.div`
  padding: 18px 0;
  display: flex;
  gap: 1rem;
  border-top: 1px solid #e1e6eb;
`;
