const COLORS = [
  '#2693FF',
  '#FFD545',
  '#9DD89E',
  '#0DC8F1',
  '#FF3700',
  '#8966EF',
  '#F99984',
];

const getColor = (i: number) => {
  const index = i % COLORS.length;
  return COLORS[index];
};

const useColor = () => {
  return {
    getColor,
  };
};

export default useColor;
