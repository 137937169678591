import React from 'react';

interface Props {
  state: string;
  setState: Function;
}

export const Tab: React.FC<Props> = ({state, setState}) => {
  return (
    <div className="mb-6 flex items-end justify-between">
      <ul className="font-bold flex flex-wrap -mb-px text-center">
        <li className="mr-2">
          <div
            onClick={() => setState('deal')}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg cursor-default ${
              state === 'deal'
                ? 'text-c-base'
                : 'text-c-lighter border-transparent'
            }`}>
            商談
          </div>
        </li>
        <li className="mr-2">
          <div
            onClick={() => setState('contact')}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg cursor-default ${
              state === 'contact'
                ? 'text-c-base'
                : 'text-c-lighter border-transparent'
            }`}>
            取引先責任者
          </div>
        </li>
      </ul>
    </div>
  );
};
