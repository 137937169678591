import React from 'react';
import styled from 'styled-components';
import {usePreleadQuery} from 'api';
import TimelineItem from 'components/TimelineItem';
import usePreleadContext from '../usePreleadContext'

export default () => {
  const {preleadId} = usePreleadContext()

  if (!preleadId) return null;

  const {data: {prelead: {mailActivities = []} = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  return (
    <Container>
      {mailActivities.map((activity) => (
        <TimelineItem key={activity.uuid} activity={activity} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
