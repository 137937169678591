import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import DailyActionReport from './daily';
import usePools from 'hooks/usePools';

const Action = () => {
  const {defaultPool} = usePools();

  return (
    <div className="pl-7 bg-[#F3F5F7] overflow-scroll h-[calc(100vh-60px)]">
      <h1 className="text-[#495058] text-xl font-bold pt-7 mb-0">レポート</h1>
      <div
        className="bg-white border border-gray-300 border-t-0 border-l-0 rounded-br-md rounded-bl-md mt-4
      ">
        <div className="flex w-full h-10 bg-gray-100">
          <div
            className={
              'bg-white flex w-[150px] border-t border-r border-gray-300 rounded-t-md'
            }>
            <Link
              className="text-[#222426] font-bold flex items-center justify-center flex-1 text-sm"
              to={`/pools/${defaultPool?.uuid}/prospect_report/action/daily${location.search}`}>
              アクション
            </Link>
          </div>
        </div>
        <div className="px-7 py-7">
          <Switch>
            <Route
              path={`/pools/${defaultPool?.uuid}/prospect_report/action/daily`}
              component={DailyActionReport}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Action;
