import React from 'react';

import {Cell, Header, flexRender} from '@tanstack/react-table';

import {ReportTable, ReportTableData} from './types';

import useTable from './useTable';

type Props = {
  table: ReportTable;
};

export const Table = ({table}: Props) => {
  const reactTable = useTable(table);
  const getColumnHeaderClass = (
    header: Header<ReportTableData, unknown>,
  ): string => {
    const baseClasses = [
      'p-3',
      'bg-c-bg',
      'text-sm',
      'font-bold',
      'text-c-base',
      'border-y',
      'border-y-[#BDC4CB]',
    ];
    if (header.column.id === 'label') {
      return [
        ...baseClasses,
        'sticky',
        'text-left',
        'left-0',
        'w-60',
        'min-w-[15rem]',
        'z-10',
        'border-r',
        'border-r-c-border',
      ].join(' ');
    }
    return [...baseClasses, 'text-right', 'min-w-[64px]'].join(' ');
  };

  const getCellClass = (cell: Cell<ReportTableData, unknown>): string => {
    let baseClasses = ['p-3', 'bg-white', 'text-sm', 'text-c-base'];

    if (cell.row.getCanExpand() && cell.row.depth === 0) {
      baseClasses = [...baseClasses, 'border-t', 'border-t-[#BDC4CB]'];
    } else {
      baseClasses = [...baseClasses, 'border-t', 'border-t-c-border'];
    }

    if (cell.column.id === 'label') {
      if (cell.row.depth > 0) {
        return [
          ...baseClasses,
          'sticky',
          'left-0',
          'w-60',
          'min-w-[15rem]',
          'border-r',
          'border-r-c-border',
          'pl-16',
          'text-c-light',
        ].join(' ');
      }

      return [
        ...baseClasses,
        'sticky',
        'left-0',
        'w-48',
        'min-w-[12rem]',
        'border-r',
        'border-c-border',
      ].join(' ');
    }

    return [...baseClasses, 'text-right'].join(' ');
  };

  if (table === null) {
    return null;
  }

  return (
    <div className="overflow-auto w-auto">
      <table className="border-separate border-spacing-0 w-auto">
        <thead className="sticky top-0">
          {reactTable.getHeaderGroups().map((headerGroup, i) => (
            <tr key={i}>
              {headerGroup.headers.map((header, j) => (
                <th
                  key={j}
                  className={getColumnHeaderClass(header)}
                  colSpan={header.colSpan}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {reactTable.getRowModel().rows.map((row, i) => (
            <tr key={i} className="border-b border-1 border-gray-300">
              {row.getVisibleCells().map((cell, j) => (
                <td
                  key={j}
                  className={getCellClass(cell)}
                  {...cell.row.original.cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
