import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignSendFragment on MailCampaignSend {
    id
    bodyHtml
    subject
    to
    updatedAt
    createdAt
    activityMailCampaignMailOpenCount
    activityMailCampaignMailClickCount
    clickedAt
    firstOpenedAt
  }
`;
