import React from 'react';
import debounce from 'helpers/debounce';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  useUpdateFormMutation,
  Form,
  FormAttributes,
  useCurrentClientQuery,
} from 'api';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  const url = `${location.protocol}//${location.host.replace(
    `${currentClient?.domain}.`,
    'app.',
  )}/forms/${form.uuid}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <div className="flex flex-col gap-8">
      <p>
        Webサイトにリンクを埋め込む形式です。独立したフォームページを生成します。SNSへのシェアなども可能です。
      </p>
      <div className="flex flex-col gap-1">
        <p className="text-c-base text-sm m-0">ページのURL</p>
        <textarea
          className="w-full bg-c-bg border border-c-border rounded p-4 h-14"
          defaultValue={url}
          readOnly
        />
        <button
          className="flex items-center justify-center h-9 w-24 rounded bg-c-primary text-white hover:opacity-90 transition-all cursor-pointer mt-2"
          onClick={() => handleCopy()}>
          コピー
        </button>
      </div>
    </div>
  );
};
