import React from 'react';
import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input, Button, Alert, Divider} from 'components/antd';
import styled from 'styled-components';
import {useSendChangePasswordTokenMutation} from 'api';
import LeadpadIcon from '../../auth/login/LeadpadIcon';
import Mail from 'components/Icon/Mail';

const validateSchema = Yup.object().shape({
  email: Yup.string().trim().required('必須項目です'),
});
export default () => {
  const history = useHistory();
  const [sendChangePasswordToken, {data, loading}] =
    useSendChangePasswordTokenMutation();

  const formik = useFormik({
    validationSchema: validateSchema,
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      const {data} = await sendChangePasswordToken({variables: values});
      if (!data?.sendChangePasswordToken.error) {
        history.push('/forgot_password/send_mail');
      }
    },
  });

  return (
    <Form onFinish={formik.handleSubmit} className="bg-[#F3F5F7]">
      <Container>
        <Content>
          <Header>
            <LeadpadIcon />
            <p className="text-[26px] mb-2">パスワード再設定</p>
            <p className="text-[14px] font-normal text-[#495058]">
              ご登録されているメールアドレスにパスワード再設定のURLをメールでお送りいたします。
            </p>
          </Header>
          <div className="w-[384px] mx-auto">
            {data &&
              data.sendChangePasswordToken &&
              data.sendChangePasswordToken.error && (
                <>
                  <Alert
                    message="Error"
                    description={data.sendChangePasswordToken.error}
                    type="error"
                    showIcon
                  />
                  <Divider />
                </>
              )}

            <Form.Item
              required
              className="mb-0"
              validateStatus={
                formik.errors.email && formik.touched.email ? 'error' : ''
              }
              help={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : ''
              }>
              <Input
                className="w-full py-2.5 rounded"
                placeholder="メールアドレス"
                name="email"
                prefix={<Mail />}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>

            <div>
              <Button
                htmlType="submit"
                type="primary"
                className="py-3 h-auto rounded"
                loading={loading}
                style={{width: '100%'}}
                disabled={!formik.isValid || loading}>
                送信
              </Button>
            </div>
          </div>
        </Content>
      </Container>
    </Form>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  &&& {
    margin-top: -100px;
    padding: 40px;
    box-sizing: content-box;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 40px;
`;
