import React from 'react';
import Lead from './Lead';
import Action from './Action';
import Form from './Form';
import Company from './Company';
import Header from '../Header';
import Footer from './Footer';
import Salesforce from './Salesforce';
import {useCurrentClientQuery} from 'api';

const ListPage = () => {
  const {data: {currentClient: {salesforceSetting = {}} = {}} = {}} =
    useCurrentClientQuery({});
  return (
    <div className="w-full h-full flex flex-col">
      <Header title="検索条件" />
      <div className="p-6 bg-c-bg rounded-b h-full overflow-y-scroll flex-1 flex flex-col gap-2">
        <Lead />
        <Company />
        <Action />
        <Form />
        {salesforceSetting?.isActive && <Salesforce />}
      </div>
      <Footer />
    </div>
  );
};

export default ListPage;
