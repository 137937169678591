import {useParams} from 'react-router-dom';
import {Activity} from 'api';

const useProjectName = (activity: Activity) => {
  const {projectId} = useParams<{projectId: string}>();

  const getProjectName = () => {
    if (projectId !== activity?.project?.uuid) {
      return activity?.project?.name;
    } else {
      return null;
    }
  };

  const projectName = getProjectName();

  return {projectName};
};

export default useProjectName;
