import React from 'react';
import styled from 'styled-components';

const Icon = ({color = '#899098', spin = false}) => (
  <Svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    className={spin ? 'rotate' : ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.29504 2.75002H1.04504V0.500015"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.64499 2C2.32546 1.23903 3.24443 0.732044 4.25105 0.562266C5.25766 0.392489 6.29209 0.570014 7.1845 1.0657C8.07691 1.56138 8.77427 2.34575 9.16208 3.29005C9.54989 4.23435 9.60511 5.28245 9.31867 6.26227C9.03224 7.24209 8.42116 8.0954 7.58577 8.68211C6.75039 9.26882 5.74034 9.55406 4.72146 9.491C3.70258 9.42794 2.73542 9.02032 1.97876 8.33507C1.2221 7.64982 0.720919 6.72766 0.557495 5.72"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

const Svg = styled.svg`
  &.rotate {
    animation: rotation 1s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-359deg);
      }
    }
  }
`;

export default Icon;
