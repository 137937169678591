import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useWorkflowQuery,
  useSalesforceLeadRecordTypeByRecordTypeIdQuery,
  SalesforceLeadField,
  useUpdateWorkflowSalesforceLeadSearchConditionMutation,
} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';
import {SearchCondition} from './Types';

const LeadField: React.FC<{condition: SearchCondition}> = ({condition}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const [updateWorkflowSalesforceLeadSearchCondition] =
    useUpdateWorkflowSalesforceLeadSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const {
    data: {
      salesforceLeadRecordTypeByRecordTypeId: {salesforceLeadFields = []} = {},
    } = {},
  } = useSalesforceLeadRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceLeadRecordTypeId: searchCondition?.salesforceLeadRecordTypeId,
    },
    skip: !searchCondition.salesforceLeadRecordTypeId,
  });

  const leadFieldOptions = () => {
    return salesforceLeadFields
      ?.filter(
        (item: SalesforceLeadField) =>
          (item.fieldType === 'select' || item.fieldType === 'checkbox') &&
          item.fieldName.endsWith('__c'),
      )
      ?.map((item: SalesforceLeadField) => {
        return {
          value: item.id,
          label: item.label,
          fieldtype: item.fieldType,
        };
      });
  };

  const alreadyAdded = (value: string): boolean => {
    const fields = searchCondition?.salesforceLeadSearchConditions?.map(
      (item: SearchCondition) => item.lead_field_id,
    );
    return fields?.includes(value);
  };

  const findFieldType = (leadFieldId: string): string => {
    const field = salesforceLeadFields.find(
      (salesforceLeadField: SalesforceLeadField) =>
        salesforceLeadField.id === leadFieldId,
    );
    return field?.fieldType;
  };

  return (
    <Select
      style={{width: 200}}
      className="mr-2"
      disabled={!isConditionUpdatable || !isMember}
      value={condition.lead_field_id || ''}
      onChange={(value: string) => {
        updateWorkflowSalesforceLeadSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              leadFieldId: value,
              fieldType: findFieldType(value),
            },
          },
          refetchQueries: ['workflow'],
        });
      }}>
      {leadFieldOptions().map((option) => (
        <Select.Option
          key={option.value}
          value={option.value}
          hidden={alreadyAdded(option.value)}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LeadField;
