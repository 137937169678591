import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, StopWorkflowProspectActivity} from 'api';
import {Link, useRouteMatch} from 'react-router-dom';

interface StopWorkflowAcvitity extends ProspectActivity {
  resource: StopWorkflowProspectActivity;
}

interface Props {
  prospectActivity: StopWorkflowAcvitity;
}

const StopWorkflowActivityComponent = ({prospectActivity}: Props) => {
  const {resource: {workflow = {}} = {}} = prospectActivity;
  const {url} = useRouteMatch();

  return (
    <>
      <Icon />
      <Content>
        <span>
          ワークフロー停止：
          <Link to={`${url.replace('timeline', 'workflow')}${location.search}`}>
            {workflow.name}
          </Link>
        </span>
        <time>
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 455px;
  margin-top: -4px;
  margin-left: 3px;

  span:first-child {
    flex: 1;
    margin: 0;
    color: #495058;
    font-weight: 500;
    width: 375px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;

export default StopWorkflowActivityComponent;
