import gql from 'graphql-tag';

export default gql`
  fragment prospectFragment on Prospect {
    id
    uuid
    firstName
    lastName
    section
    sectionPosition
    telephoneNumber
    tel
    email
  }
`;
