import React from 'react';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  FormBlock,
  FormBlockSubmitButton,
  FormBlockSubmitButtonAttributes,
  useUpdateFormBlockSubmitButtonMutation,
} from 'api';
import {useForm} from 'react-hook-form';
import debounce from 'helpers/debounce';

interface Block extends FormBlock {
  formBlockable: FormBlockSubmitButton;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const [update] = useUpdateFormBlockSubmitButtonMutation();

  const {register, watch, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        buttonText: Yup.string().required(),
        buttonColor: Yup.string().optional(),
        textColor: Yup.string().optional(),
      }),
    ),
    defaultValues: {
      buttonText: block.formBlockable.buttonText,
      buttonColor: block.formBlockable.buttonColor || '#68b5fb',
      textColor: block.formBlockable.textColor || '#ffffff',
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: FormBlockSubmitButtonAttributes) => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-bold">送信ボタンの編集</h2>
      <div className="flex flex-col gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          ボタンテキスト
        </label>
        <input
          className="form-input text-base block w-full border border-c-border rounded"
          {...register('buttonText')}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          ボタンの色
        </label>
        <input
          className="bg-white text-base block w-full border border-c-border rounded"
          type="color"
          {...register('buttonColor')}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          テキストの色
        </label>
        <input
          className="bg-white text-base block w-full border border-c-border rounded"
          type="color"
          {...register('textColor')}
        />
      </div>
    </div>
  );
};
