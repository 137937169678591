import React from 'react';
import {Switch, Route} from 'react-router-dom';
import SignupContainer from './signup';
import Complete from './signup/complete';
import LoginContainer from './login';
import ForgotPasswordContainer from './forgot_password';
import ForgotPasswordSendMailContainer from './forgot_password/send_mail';
import ForgotPasswordSendPasswordContainer from './forgot_password/send_password';
import ForgotPasswordCompleteContainer from './forgot_password/complete';

export default () => (
  <Switch>
    <Route path="/registration/complete" component={Complete} />
    <Route path="/registration/:token" component={SignupContainer} />
    <Route
      path="/forgot_password/send_mail"
      component={ForgotPasswordSendMailContainer}
    />
    <Route
      path="/forgot_password/complete"
      component={ForgotPasswordCompleteContainer}
    />
    <Route
      path="/forgot_password/:token"
      component={ForgotPasswordSendPasswordContainer}
    />
    <Route path="/forgot_password" component={ForgotPasswordContainer} />
    <Route path="/" component={LoginContainer} />
  </Switch>
);
