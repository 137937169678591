import React, {useState} from 'react';
import styled from 'styled-components';
import {Cross} from 'components/Ui/Icon';
import {Alert, Form, Input} from 'components/antd';
import {useParams, useHistory} from 'react-router';
import {useBlockGroupMemberQuery, useUpdateBlockGroupMemberMutation} from 'api';
import {Button} from 'components/Ui';
import {useFormik} from 'formik';

export default () => {
  const history = useHistory();
  const {blockGroupId, blockGroupMemberId} = useParams<{
    blockGroupId: string;
    blockGroupMemberId: string;
  }>();
  const [updatedError, setUpdatedError] = useState(false);
  const {data: {blockGroupMember = null} = {}} = useBlockGroupMemberQuery({
    variables: {uuid: blockGroupMemberId},
    skip: !blockGroupMemberId,
  });

  const closeDetail = () =>
    history.push(
      `/block/block_groups/${blockGroupId}/block_members${location.search}`,
    );

  const [updateBlockGroupMember, {loading}] = useUpdateBlockGroupMemberMutation(
    {
      onCompleted: ({updateBlockGroupMember: {isExisted}}) => {
        if (isExisted) {
          setUpdatedError(true);
        } else {
          closeDetail();
        }
      },
      refetchQueries: ['blockGroupMembers'],
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: blockGroupMember?.name || '',
      webSite: blockGroupMember?.webSite || '',
      telephoneNumber: blockGroupMember?.telephoneNumber || '',
      corpNumber: blockGroupMember?.corpNumber || '',
      address: blockGroupMember?.address || '',
    },
    onSubmit: (values) => {
      updateBlockGroupMember({
        variables: {
          uuid: blockGroupMemberId,
          attributes: values,
        },
      });
    },
  });

  const {values, isValid, handleChange, handleSubmit} = formik;

  if (!blockGroupMember) return null;

  return (
    <Container>
      <Header>
        <Title>ブロックリスト編集</Title>
        <CloseButton onClick={closeDetail}>
          <Cross />
        </CloseButton>
      </Header>
      <CustomForm onFinish={handleSubmit}>
        {updatedError && (
          <>
            <Alert message="既に同じ企業が存在します。" type="error" />
            <br />
          </>
        )}
        <CustomForm.Item>
          <Label>会社名</Label>
          <CustomInput
            key={blockGroupMemberId}
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </CustomForm.Item>
        <CustomForm.Item>
          <Label>URL</Label>
          <CustomInput
            key={blockGroupMemberId}
            name="webSite"
            value={values.webSite}
            onChange={handleChange}
          />
        </CustomForm.Item>
        <CustomForm.Item>
          <Label>TEL</Label>
          <CustomInput
            key={blockGroupMemberId}
            name="telephoneNumber"
            value={values.telephoneNumber}
            onChange={handleChange}
          />
        </CustomForm.Item>
        <CustomForm.Item>
          <Label>法人番号</Label>
          <CustomInput
            key={blockGroupMemberId}
            name="corpNumber"
            value={values.corpNumber}
            onChange={handleChange}
          />
        </CustomForm.Item>
        <CustomForm.Item>
          <Label>住所</Label>
          <CustomInput
            key={blockGroupMemberId}
            name="address"
            value={values.address}
            onChange={handleChange}
          />
        </CustomForm.Item>
        <ButtonWrapper>
          <CustomButton
            htmlType="submit"
            loading={loading}
            disabled={!formik.dirty || !isValid || loading}>
            保存
          </CustomButton>
        </ButtonWrapper>
      </CustomForm>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 605px;
  height: 100%;
  background: #ffffff;
  border-left: 1px solid #e2e6ea;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 82px;
  border-bottom: 1px solid #e2e6ea;
`;

const Title = styled.div`
  margin-left: 40px;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #899098;
`;

const CloseButton = styled.span`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 25px;

  svg {
    margin: 9px;
  }
`;

const CustomForm = styled(Form)`
  padding: 34px 34px 0 58px;
  .ant-form-item {
    margin-bottom: 30px;
  }
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #899098;
`;

const CustomInput = styled(Input)`
  margin-top: 6px;
  font-size: 14px;
  line-height: 26px;
  border: 0.5px solid #bdc4cb;
  border-radius: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  button {
    margin-left: auto;
  }
`;

const CustomButton = styled(Button)`
  width: 125px;
  height: 45px;
  font-weight: bold;
  font-size: 14px;
`;
