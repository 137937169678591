import gql from 'graphql-tag';
import salesforceDealFragment from '../salesforce/salesforceDeal';

export default gql`
  fragment salesforceDealProspectActivityFragment on SalesforceDealProspectActivity {
    id
    salesforceDeal {
      ...salesforceDealFragment
    }
  }
  ${salesforceDealFragment}
`;
