import React from 'react';
import styled from 'styled-components';
import {
  Home,
  User,
  Users,
  Tel,
  World,
  Parliament,
  Location,
  Facebook,
  Flag,
  Twitter,
  Line,
  YouTube,
  LinkedIn,
  Instagram,
  Mail,
  Offices,
  Zipcode,
  Calendar,
  BarChart,
  LineChart,
  Residence,
  Fax,
  BuildingDomestic,
  Factory,
  BuildingOverseas,
  StoreOverseas,
  FactoryOverseas,
  Store,
} from 'components/Ui/Icon';
import {SubCategory, useCompanyQuery} from 'api';
import toJaNum from 'helpers/toJaNum';

type Props = {
  companyId: string;
};

export default ({companyId}: Props) => {
  const {data: {company = null} = {}} = useCompanyQuery({
    variables: {uuid: companyId},
    skip: !companyId,
  });

  const establishedYearMonthText = (establishedYearMonth: string) => {
    if (!establishedYearMonth) return '';

    const date_text = `${establishedYearMonth.replace('-', '年')}月`;

    return date_text;
  };

  if (!company) return null;

  return (
    <Container>
      <Item>
        <Home />
        <Name>社名</Name>
        <Value>{company.name}</Value>
      </Item>
      <Item>
        <IconWrapper>
          <Zipcode />
        </IconWrapper>
        <Name>郵便番号</Name>
        <Value>{company.zipCode}</Value>
      </Item>
      <Item>
        <Location />
        <Name>住所</Name>
        <Value>{company.address}</Value>
      </Item>
      <Item>
        <Tel />
        <Name>代表電話</Name>
        <Value>{company.telephoneNumber}</Value>
      </Item>
      {company.companyTelephoneNumbers?.map((telephoneNumber) => (
        <Item key={telephoneNumber.name}>
          <Tel />
          <Name>{telephoneNumber.name}</Name>
          <Value>{telephoneNumber.telephoneNumber}</Value>
        </Item>
      ))}
      <Item>
        <Fax />
        <Name>代表FAX</Name>
        <Value>{company.faxNumber}</Value>
      </Item>
      {company.companyFaxNumbers?.map((faxNumber) => (
        <Item key={faxNumber.name}>
          <Fax />
          <Name>{faxNumber.name}</Name>
          <Value>{faxNumber.faxNumber}</Value>
        </Item>
      ))}
      <Item>
        <Mail />
        <Name>代表メール</Name>
        <Value>{company.mailAddress}</Value>
      </Item>
      {company.companyMailAddresses?.map((mailAddress) => (
        <Item key={mailAddress.name}>
          <Mail />
          <Name>{mailAddress.name}</Name>
          <Value>{mailAddress?.mailAddress}</Value>
        </Item>
      ))}
      <Item>
        <World />
        <Name>ウェブサイト</Name>
        <Value>
          <a href={company.webSite} target="_blank">
            {company.webSite}
          </a>
        </Value>
      </Item>
      <Item>
        <User />
        <Name>代表者名</Name>
        <Value>{company.representativePerson}</Value>
      </Item>
      <Item>
        <Users />
        <Name>従業員数</Name>
        <Value>
          {company.employeeNumber &&
            `${company.employeeNumber?.toLocaleString()}人`}
        </Value>
      </Item>
      <Item>
        <Parliament />
        <Name>資本金</Name>
        <Value>
          {company.capitalFund &&
            `${toJaNum(company.capitalFund?.toLocaleString())}円`}
        </Value>
      </Item>
      <Item>
        <Offices />
        <Name>事務所数</Name>
        <Value>{company.officesNumber}</Value>
      </Item>
      <Item>
        <BuildingDomestic />
        <Name>国内支店・拠点数</Name>
        <Value>
          {company.officesDomesticBranchesNumber &&
            company.officesDomesticBranchesNumber}
        </Value>{' '}
      </Item>
      <Item>
        <BuildingOverseas />
        <Name>海外支店・拠点数</Name>
        <Value>
          {company.officesForeignBranchesNumber &&
            company.officesForeignBranchesNumber}
        </Value>
      </Item>
      <Item>
        <Store />
        <Name>国内店舗数</Name>
        <Value>
          {company.officesDomesticStoresNumber &&
            company.officesDomesticStoresNumber}
        </Value>
      </Item>
      <Item>
        <StoreOverseas />
        <Name>海外店舗数</Name>
        <Value>
          {company.officesForeignStoresNumber &&
            company.officesForeignStoresNumber}
        </Value>
      </Item>
      <Item>
        <Factory />
        <Name>国内工場数</Name>
        <Value>
          {company.officesDomesticFactoriesNumber &&
            company.officesDomesticFactoriesNumber}
        </Value>
      </Item>
      <Item>
        <FactoryOverseas />
        <Name>海外工場数</Name>
        <Value>
          {company.officesForeignFactoriesNumber &&
            company.officesForeignFactoriesNumber}
        </Value>
      </Item>
      <Item>
        <Calendar />
        <Name>決算月</Name>
        <Value>
          {company.accountClosingMonth && `${company.accountClosingMonth}月`}
        </Value>
      </Item>
      <Item>
        <LineChart />
        <Name>上場区分</Name>
        <Value>{company.listingMarketText}</Value>
      </Item>
      <Item>
        <BarChart />
        <Name>売上</Name>
        <Value>
          {company.sales && `${toJaNum(company.sales?.toString())}円`}
        </Value>
      </Item>
      <Item>
        <Flag />
        <Name>設立年月</Name>
        <Value>{establishedYearMonthText(company.establishedYearMonth)}</Value>
      </Item>
      <Item>
        <Residence />
        <Name>業種</Name>
      </Item>
      <TagBox>
        {company.subCategories?.map((subCategory: SubCategory) => (
          <TagItem key={subCategory.id}>{subCategory.displayName}</TagItem>
        ))}
      </TagBox>

      <SnsBox>
        {company.facebookUrls?.map((facebookUrl, i) => (
          <SnsLink key={i} href={facebookUrl} target="_blank">
            <Facebook />
          </SnsLink>
        ))}
        {company.twitterUrls?.map((twitterUrl, i) => (
          <SnsLink key={i} href={twitterUrl} target="_blank">
            <Twitter />
          </SnsLink>
        ))}
        {company.lineUrls?.map((lineUrl, i) => (
          <SnsLink key={i} href={lineUrl} target="_blank">
            <Line />
          </SnsLink>
        ))}
        {company.youtubeUrls?.map((youtubeUrl, i) => (
          <SnsLink key={i} href={youtubeUrl} target="_blank">
            <YouTube />
          </SnsLink>
        ))}
        {company.linkedinUrls?.map((linkedinUrl, i) => (
          <SnsLink key={i} href={linkedinUrl} target="_blank">
            <LinkedIn />
          </SnsLink>
        ))}
        {company.instagramUrls?.map((instagramUrl, i) => (
          <SnsLink key={i} href={instagramUrl} target="_blank">
            <Instagram />
          </SnsLink>
        ))}
      </SnsBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  &:nth-child(1) {
    margin-top: 0;
  }
  margin-top: 15px;
  display: flex;
  svg {
    width: 22px;
    margin-right: 25px;
  }
`;

const Name = styled.span`
  margin-right: 15px;
  width: 105px;
  font-weight: bold;
  color: #bdc4cb;
`;

const Value = styled.span`
  display: inline-block;
  word-break: break-all;
  width: 150px;
`;

const TagBox = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
`;

const TagItem = styled.span`
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #495058;
`;

const SnsBox = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  svg {
    margin-right: 20px;
    vertical-align: bottom;
  }
`;

const SnsLink = styled.a`
  margin: 5px 0;
`;

const IconWrapper = styled.div`
  margin-top: 3px;
`;
