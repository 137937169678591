import React, {useState} from 'react';
import {AccountGroupAccount} from 'api';
import {Popover} from 'antd';
import styled from 'styled-components';
import {DotMenu} from 'components/Ui/Icon';
import DestroyConfirmation from '../DestroyConfirmation';

interface Props {
  accountGroupAccount: AccountGroupAccount;
}

export default ({accountGroupAccount}: Props) => {
  const [destroyModalVisible, setDestroyModalVisible] = React.useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const Content = () => (
    <Container>
      <Action
        onClick={() => {
          setIsPopoverVisible(false);
          setDestroyModalVisible(true);
        }}>
        削除
      </Action>
    </Container>
  );

  return (
    <>
      <Popover
        placement="left"
        content={<Content />}
        trigger="click"
        onVisibleChange={(visible) => setIsPopoverVisible(visible)}
        visible={isPopoverVisible}>
        <IconWrapper>
          <DotMenu />
        </IconWrapper>
      </Popover>

      <DestroyConfirmation
        visible={destroyModalVisible}
        onClose={() => setDestroyModalVisible(false)}
        accountGroupAccount={accountGroupAccount}
      />
    </>
  );
};

const IconWrapper = styled.div`
  cursor: pointer;
  svg {
    transform: rotate(90deg);
    position: relative;
    top: 3px;
    left: 12px;
  }
`;

const Container = styled.div`
  width: 150px;
  cursor: pointer;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-left: 18px;
  width: 100%;
  height: 50px;
  font-weight: 500;

  &:hover {
    background-color: #f2f7fa;
  }
`;
