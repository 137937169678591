import React from 'react';
import styled from 'styled-components';
import {Modal, Form, Select} from 'components/antd';
import {
  useSalesforceContactRecordTypesQuery,
  useUpdateSalesforceContactFieldMutation,
  SalesforceContactField,
} from 'api';
import useProspectContext from '../../useProspectContext'

interface Props {
  visible: boolean;
  onClose: () => void;
}

const SettingModalComponent = ({visible, onClose}: Props) => {
  const {poolId} = useProspectContext();
  const [onlyMasterRecordType, setOnlyMasterRecordType] = React.useState(false);
  const [selectedRecordTypeId, setSelectedRecordTypeId] = React.useState(null);
  const [updateSalesforceContactField] =
    useUpdateSalesforceContactFieldMutation();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery({
      variables: {poolUuid: poolId},
    });

  const onRecordTypeSelect = (id: string) => setSelectedRecordTypeId(id);

  React.useEffect(() => {
    const masterRecordType = salesforceContactRecordTypes.find(
      (recordType) => recordType.isMaster === true,
    );
    if (masterRecordType && salesforceContactRecordTypes.length === 1) {
      setOnlyMasterRecordType(true);
      setSelectedRecordTypeId(masterRecordType.id);
    }
  }, [salesforceContactRecordTypes]);

  const salesforceContactFields = React.useMemo(() => {
    if (!selectedRecordTypeId) {
      return [];
    }

    const selectedRecordType = salesforceContactRecordTypes.find(
      (recordType) => recordType.id === selectedRecordTypeId,
    );

    return selectedRecordType.salesforceContactFields;
  }, [selectedRecordTypeId, salesforceContactRecordTypes]);

  const Item = (field: SalesforceContactField, index: number) => {
    return (
      <Row key={index}>
        <div>{field.label}</div>
        <div>取引先責任者属性</div>
        <div>
          <input
            type="checkbox"
            checked={field.isDisplay}
            onChange={(e) => {
              updateSalesforceContactField({
                variables: {
                  id: field.id,
                  isDisplay: e.target.checked,
                },
              });
            }}
          />
        </div>
      </Row>
    );
  };

  return (
    <Modal
      title="Salesforce取引先責任者項目の設定"
      visible={visible}
      onCancel={onClose}
      width={'55vw'}
      footer={null}>
      <Form>
        <Form.Item>
          {!onlyMasterRecordType && (
            <>
              <div>レコードタイプ</div>
              <Select
                onChange={(value: string) => {
                  onRecordTypeSelect(value);
                }}>
                {salesforceContactRecordTypes.map((recordType) => (
                  <Select.Option key={recordType.id} value={recordType.id}>
                    {recordType.name}
                  </Select.Option>
                ))}
              </Select>
            </>
          )}
          <Content>
            <Row>
              <Title>項目名</Title>
              <Title>セクション</Title>
              <Title>表示</Title>
            </Row>
            {salesforceContactFields.map(
              (field: SalesforceContactField, i: number) => Item(field, i),
            )}
          </Content>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Content = styled.div`
  margin-top: 1.5rem;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
`;

const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  & > div:first-child {
    width: 64%;
  }
  & > div:nth-child(2) {
    width: 32%;
  }
  & > div:last-child {
    width: 4%;
    text-align: center;
    > input {
      display: inline-block;
      cursor: pointer;
    }
  }
`;

export default SettingModalComponent;
