import React from 'react';
import styled from 'styled-components';
import SentCount from '../SentCount';
import PreleadCount from '../PreleadCount';
import ClickCount from '../ClickCount';
import MailStatusReport from './MailStatusReport';
import SentResultReport from './SentResultReport';
import SentMailStatusReport from './SentMailStatusReport';
import {useParams} from 'react-router';
import {
  useProjectMailStatusCountReportsQuery,
  useProjectResponseStatusReportQuery,
} from 'api';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {
    data: {
      projectMailStatusSummaryReport = {},
      projectMailStatusCountReports = [],
    } = {},
  } = useProjectMailStatusCountReportsQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'network-only',
  });

  const {data: {projectResponseStatusReport = {}} = {}} =
    useProjectResponseStatusReportQuery({
      variables: {
        projectUuid: projectId,
      },
    });

  const mailCount = projectMailStatusSummaryReport?.mailCount || 0;
  const clickCount = projectMailStatusSummaryReport?.clickCount || 0;

  return (
    <>
      <CountSection>
        <PreleadCount />
        <SentCount mailCount={mailCount} />
        <ClickCount mailCount={mailCount} clickCount={clickCount} />
      </CountSection>

      <Container>
        <SentResultReport
          projectMailStatusSummaryReport={projectMailStatusSummaryReport}
          projectMailStatusCountReports={projectMailStatusCountReports}
        />
        <div>
          <MailStatusReport
            mailCount={mailCount}
            projectResponseStatusReport={projectResponseStatusReport}
          />
          <SentMailStatusReport
            mailCount={mailCount}
            projectResponseStatusReport={projectResponseStatusReport}
          />
        </div>
      </Container>
    </>
  );
};

const CountSection = styled.div`
  margin-bottom: 37px;
  display: flex;

  > div:not(:first-child) {
    margin-left: 10px;
  }
`;

const Container = styled.div`
  display: flex;

  > div:last-child {
    margin-left: 52px;

    > div:first-child {
      margin-bottom: 59px;
    }
  }
`;
