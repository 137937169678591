import React from 'react';

function Icon() {
  return (
    <svg
      width="128"
      height="15"
      viewBox="0 0 128 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M96.596 14.8179H90.0433C89.8204 14.8124 89.6088 14.7243 89.454 14.5725C89.2993 14.4207 89.214 14.2175 89.2164 14.0067C89.2164 11.8239 90.8703 10.6734 92.6488 9.86217C94.5211 9.0067 95.4572 8.65316 95.4572 7.64968C95.4572 6.80903 94.568 6.41064 93.3198 6.41064C92.3677 6.36364 91.427 6.62367 90.6516 7.14814C90.5316 7.22409 90.3905 7.2648 90.2461 7.26514C90.1411 7.27093 90.036 7.25664 89.937 7.22313C89.838 7.18961 89.7472 7.13756 89.67 7.07009C89.5928 7.00262 89.5308 6.92111 89.4877 6.83045C89.4446 6.73979 89.4213 6.64184 89.4191 6.54246C89.4191 5.96721 89.9808 5.61329 90.9326 5.24454C91.7259 4.96331 92.566 4.81848 93.4132 4.81689C95.7068 4.81689 97.3293 5.80497 97.3293 7.61926C97.3293 9.13832 96.2371 9.97878 94.287 10.8935C92.5863 11.6901 91.3695 12.2654 91.3695 13.224H96.596C96.8174 13.2262 97.0287 13.3113 97.1837 13.4607C97.3388 13.61 97.4248 13.8113 97.423 14.0206C97.4225 14.2292 97.3357 14.4294 97.1812 14.5784C97.0267 14.7273 96.8167 14.8133 96.596 14.8179Z"
        fill="#009DE8"
      />
      <path
        d="M103.461 14.9948C100.544 14.9948 98.9053 12.6054 98.9053 9.90643C98.9053 7.20742 100.544 4.81689 103.461 4.81689C106.379 4.81689 108.017 7.20625 108.017 9.90643C108.017 12.6066 106.379 14.9948 103.461 14.9948ZM103.461 6.41064C101.932 6.41064 100.778 7.7973 100.778 9.90643C100.778 12.0156 101.933 13.402 103.461 13.402C104.99 13.402 106.145 12.0156 106.145 9.90643C106.145 7.7973 104.99 6.41064 103.461 6.41064Z"
        fill="#009DE8"
      />
      <path
        d="M116.91 14.8179H110.357C110.134 14.8124 109.922 14.7243 109.768 14.5725C109.613 14.4207 109.527 14.2175 109.53 14.0067C109.53 11.8239 111.184 10.6734 112.962 9.86217C114.835 9.0067 115.771 8.65316 115.771 7.64968C115.771 6.80903 114.881 6.41064 113.633 6.41064C112.681 6.36369 111.74 6.62371 110.965 7.14814C110.845 7.2241 110.704 7.26481 110.559 7.26514C110.454 7.27093 110.349 7.25664 110.25 7.22313C110.151 7.18961 110.06 7.13756 109.983 7.07009C109.906 7.00262 109.844 6.92111 109.801 6.83045C109.758 6.73979 109.735 6.64184 109.732 6.54246C109.732 5.96721 110.294 5.61329 111.246 5.24454C112.039 4.96331 112.879 4.81848 113.727 4.81689C116.02 4.81689 117.643 5.80497 117.643 7.61926C117.643 9.13832 116.551 9.97878 114.6 10.8935C112.9 11.6901 111.683 12.2654 111.683 13.224H116.91C117.131 13.2262 117.342 13.3113 117.497 13.4607C117.652 13.61 117.738 13.8113 117.736 14.0206C117.736 14.2292 117.649 14.4294 117.495 14.5784C117.34 14.7273 117.13 14.8133 116.91 14.8179Z"
        fill="#009DE8"
      />
      <path
        d="M125.959 12.3547V14.0659C125.959 14.2967 125.862 14.518 125.689 14.6811C125.516 14.8443 125.282 14.936 125.038 14.936C124.794 14.936 124.56 14.8443 124.387 14.6811C124.215 14.518 124.118 14.2967 124.118 14.0659V12.3547H119.25C119.145 12.3587 119.04 12.3426 118.942 12.3074C118.843 12.2721 118.754 12.2184 118.678 12.1496C118.602 12.0807 118.542 11.9981 118.501 11.9066C118.46 11.8152 118.438 11.7168 118.438 11.6175C118.447 11.4009 118.541 11.1953 118.704 11.0422L124.305 5.20132C124.398 5.09624 124.515 5.01247 124.648 4.95628C124.78 4.90009 124.924 4.87293 125.069 4.87683C125.678 4.87683 125.959 5.2604 125.959 5.74713V10.88H127.191C127.296 10.8764 127.401 10.8928 127.499 10.9282C127.597 10.9636 127.686 11.0174 127.762 11.0862C127.837 11.155 127.897 11.2375 127.938 11.3288C127.979 11.4201 128 11.5182 128 11.6174C128 11.7165 127.979 11.8147 127.938 11.9059C127.897 11.9972 127.837 12.0797 127.762 12.1485C127.686 12.2173 127.597 12.2711 127.499 12.3065C127.401 12.3419 127.296 12.3584 127.191 12.3547H125.959ZM124.118 10.88V7.4727L120.872 10.88H124.118Z"
        fill="#009DE8"
      />
      <path
        d="M85.3638 3.8886C85.2307 3.51439 85.0261 2.91574 84.9496 2.69266L84.9267 2.62597C84.897 2.53345 84.8353 2.45301 84.7516 2.39746C84.6679 2.3419 84.567 2.31443 84.4648 2.31943L83.0444 2.32118C82.9962 2.31589 82.9474 2.3227 82.9029 2.34091C82.8584 2.35912 82.8198 2.3881 82.7909 2.42493C82.7643 2.46049 82.7473 2.50163 82.7412 2.5448C82.7352 2.58798 82.7403 2.6319 82.7562 2.67277C82.7903 2.77632 83.0791 3.58927 83.236 4.00365C83.2743 4.10213 83.3466 4.18551 83.4412 4.24026C83.5359 4.29502 83.6473 4.31794 83.7575 4.30532L85.0246 4.30337C85.2264 4.30337 85.3054 4.24487 85.3462 4.19105C85.3757 4.14605 85.3927 4.09478 85.3958 4.04194C85.3989 3.9891 85.3878 3.93637 85.3638 3.8886Z"
        fill="black"
      />
      <path
        d="M82.1309 2.61515C82.0997 2.5254 82.0379 2.44796 81.9552 2.39501C81.8725 2.34207 81.7735 2.31661 81.6738 2.32265L80.2533 2.3246C80.2056 2.3192 80.1572 2.32562 80.113 2.34323C80.0687 2.36083 80.03 2.38902 80.0007 2.42503C79.9738 2.46116 79.9565 2.5029 79.9503 2.54669C79.9441 2.59049 79.9492 2.63505 79.9652 2.67658C80.0017 2.78519 80.2888 3.59816 80.4444 4.00688C80.4823 4.10563 80.5546 4.18924 80.6494 4.24399C80.7443 4.29874 80.856 4.32136 80.9662 4.30816L82.242 4.3062C82.3003 4.31326 82.3596 4.30689 82.4147 4.28764C82.4698 4.26839 82.5192 4.23682 82.5587 4.19564C82.5898 4.15108 82.6082 4.09968 82.6121 4.04643C82.6161 3.99317 82.6054 3.93985 82.5812 3.89163C82.4246 3.44976 82.1579 2.6951 82.1309 2.61515Z"
        fill="black"
      />
      <path
        d="M27.7227 13.2599C27.7233 13.5816 27.6229 13.8963 27.4342 14.1641C27.2456 14.4318 26.9772 14.6407 26.663 14.7643C26.3488 14.8879 26.0029 14.9207 25.669 14.8584C25.3351 14.7961 25.0282 14.6417 24.7872 14.4146C24.5462 14.1874 24.3818 13.8978 24.3149 13.5824C24.2479 13.267 24.2814 12.9398 24.4112 12.6424C24.5409 12.345 24.761 12.0906 25.0436 11.9114C25.3263 11.7322 25.6588 11.6363 25.9991 11.6357C26.4555 11.6351 26.8934 11.8059 27.2167 12.1104C27.5399 12.415 27.7219 12.8285 27.7227 13.2599Z"
        fill="#009DE8"
      />
      <path
        d="M28.751 1.70411C28.8226 1.55148 28.8598 1.38646 28.8604 1.21951C28.8609 1.05256 28.8248 0.887316 28.7542 0.734265C28.6837 0.581214 28.5802 0.443692 28.4505 0.330417C28.3208 0.217143 28.1675 0.130589 28.0006 0.0762422C27.2881 -0.16946 26.5907 0.205138 26.2243 0.820953C25.6321 1.8178 22.5611 7.31762 22.0464 8.22945C21.5318 9.14127 20.883 10.4449 20.3438 10.2465C19.8488 10.064 19.2646 8.06526 19.0154 7.49449C18.5742 6.48048 17.6767 5.27537 16.1783 5.58191C15.0331 5.81474 14.3823 6.66632 13.3462 8.54594C12.7773 9.57808 12.4206 10.2584 11.9022 10.9263C10.8391 12.2985 10.2393 10.0441 9.88797 8.8788C9.41622 7.31411 8.55585 5.10104 6.67916 5.10357C5.63046 5.10494 4.51327 5.68546 3.12153 7.81409C2.31005 9.05567 0.410876 12.5331 0.130548 13.1523C0.0537726 13.301 0.00985572 13.463 0.00147696 13.6283C-0.00690181 13.7936 0.0204344 13.9588 0.0818154 14.1138C0.143197 14.2687 0.237336 14.4103 0.358492 14.5298C0.479648 14.6492 0.62528 14.7441 0.786502 14.8086C1.48351 15.0904 2.16855 14.7977 2.578 14.1947C3.91239 12.2352 4.27874 11.47 4.96109 10.5677C5.69193 9.61025 6.68514 8.75049 7.44506 11.0538C7.92341 12.5033 8.75944 14.8811 11.0103 14.8954C12.3645 14.9043 13.767 13.7985 14.4233 12.7443C15.1647 11.5525 15.3516 10.9158 15.9168 10.3407C16.2056 10.0482 16.6346 9.94156 17.041 10.7931C17.4329 11.616 18.2442 14.2179 19.6885 14.785C21.7277 15.5845 23.6469 11.686 24.3091 10.4427C24.8439 9.44041 28.5146 2.22945 28.7504 1.70314"
        fill="#009DE8"
      />
      <path
        d="M45.3296 2.81187C45.269 2.76086 45.1982 2.72181 45.1214 2.697C45.0446 2.67218 44.9634 2.66211 44.8824 2.66737H44.8919C44.2879 2.66835 32.4909 2.68258 32.4909 2.68258C32.4296 2.68208 32.3689 2.69415 32.313 2.71795C32.2572 2.74175 32.2074 2.77672 32.1672 2.82045C32.0975 2.89902 32.0597 2.99851 32.0606 3.10106L32.0616 4.5856C32.0644 4.69168 32.111 4.79252 32.1914 4.86656C32.2718 4.9406 32.3796 4.98198 32.4919 4.98185H32.5618L41.6004 4.97054H41.9675C42.2307 4.97054 42.3042 5.01558 42.3659 5.09241C42.5024 5.26187 42.3609 5.53155 42.3419 5.59571C41.745 7.63698 40.4366 9.21533 38.558 10.1609L38.452 10.2139L38.3808 10.1221C37.9196 9.52382 37.5728 9.11061 37.1716 8.63228L37.1147 8.5611C37.0522 8.47252 36.97 8.39792 36.8738 8.34255C36.7776 8.28717 36.6697 8.25235 36.5578 8.24052H36.5567L34.4635 8.24344C34.4023 8.23976 34.3414 8.25442 34.2896 8.28533C34.2377 8.31625 34.1975 8.36185 34.1747 8.41562C34.1533 8.46578 34.1451 8.52006 34.1507 8.57387C34.1562 8.62767 34.1754 8.67941 34.2067 8.72471C35.7446 10.5874 37.1236 12.5626 38.3307 14.6317C38.3751 14.7124 38.4425 14.7798 38.5252 14.8263C38.6079 14.8728 38.7027 14.8966 38.7989 14.8949H41.068C41.1218 14.895 41.1744 14.8803 41.2195 14.8526C41.2646 14.8249 41.3003 14.7854 41.3221 14.7389C41.3485 14.6947 41.3624 14.6448 41.3624 14.594C41.3624 14.5431 41.3485 14.4932 41.3221 14.449C40.9428 13.753 40.5145 13.082 40.0399 12.4405L39.9638 12.3342L40.0754 12.2603C42.7285 10.4971 45.21 8.25066 45.4451 3.16775C45.4527 3.10393 45.4464 3.03933 45.4266 2.9779C45.4068 2.91647 45.3739 2.85951 45.33 2.8105"
        fill="black"
      />
      <path
        d="M58.0799 2.64274C58.0384 2.59757 57.9866 2.56183 57.9285 2.5382C57.8703 2.51456 57.8073 2.50363 57.7441 2.50623C57.506 2.50896 56.3758 2.51423 55.6869 2.51657C55.5836 2.52254 55.4839 2.5541 55.3976 2.60808C55.3114 2.66206 55.2417 2.73656 55.1955 2.82409C54.0198 5.37159 50.6039 7.2945 45.9099 8.03609C45.6068 8.09264 45.4612 8.26112 45.4561 8.45359L45.4589 9.98825C45.4575 10.0438 45.4691 10.099 45.4927 10.1499C45.5163 10.2009 45.5515 10.2462 45.5957 10.2829C45.647 10.3256 45.7074 10.3575 45.7728 10.3764C45.8381 10.3953 45.907 10.4009 45.9748 10.3927C48.2439 10.1308 49.9922 9.70569 51.3722 9.10567L51.5682 9.02026L51.5758 14.4665C51.5757 14.5211 51.5873 14.5753 51.6098 14.6257C51.6323 14.676 51.6653 14.7216 51.7069 14.7597C51.7484 14.7978 51.7976 14.8275 51.8516 14.8472C51.9055 14.8669 51.9631 14.8762 52.021 14.8744H52.113L53.7931 14.8721C53.8989 14.8681 53.9993 14.827 54.0748 14.7569C54.1504 14.6868 54.1957 14.5927 54.2019 14.4928L54.1914 7.85006L54.2518 7.81106C56.0258 6.66659 57.5689 4.79477 58.1552 2.96683C58.1691 2.91101 58.1697 2.85294 58.1568 2.79689C58.1439 2.74084 58.1179 2.68824 58.0807 2.64293"
        fill="black"
      />
      <path
        d="M67.5689 7.95155L67.5759 7.82812L71.5388 7.81778C71.5956 7.81722 71.6516 7.80608 71.7038 7.785C71.756 7.76392 71.8032 7.73331 71.8429 7.69493C71.9175 7.62006 71.9613 7.5225 71.9667 7.41979L71.9642 5.94304C71.9653 5.8889 71.9549 5.8351 71.9336 5.78485C71.9122 5.7346 71.8804 5.68894 71.8399 5.65059C71.7995 5.61223 71.7513 5.58198 71.6982 5.56164C71.6451 5.54129 71.5883 5.53127 71.531 5.53217H71.4875H67.5961L67.5936 2.89965C67.594 2.84441 67.5824 2.78967 67.5597 2.73878C67.537 2.68788 67.5035 2.64189 67.4614 2.60359C67.4193 2.56529 67.3694 2.53548 67.3147 2.51598C67.26 2.49648 67.2017 2.48769 67.1433 2.49015H67.0815L65.3933 2.49269C65.3346 2.49208 65.2764 2.50318 65.2225 2.52525C65.1686 2.54733 65.1203 2.57988 65.0806 2.6208C65.0071 2.69639 64.9663 2.79525 64.9661 2.8979L64.9696 5.53568L59.0239 5.54192C58.9634 5.54018 58.9031 5.55038 58.847 5.57187C58.7908 5.59335 58.74 5.62566 58.6978 5.66673C58.6181 5.74678 58.5746 5.85317 58.5767 5.96313L58.5792 7.44689C58.5806 7.50019 58.5931 7.55271 58.6161 7.6014C58.6391 7.65009 58.672 7.694 58.7131 7.73059C58.7541 7.76718 58.8024 7.79572 58.8551 7.81457C58.9079 7.83342 58.9641 7.8422 59.0204 7.8404H59.0776L64.95 7.83007L64.9405 7.9693C64.732 11.1841 63.1014 12.1014 60.0361 12.695C59.769 12.7552 59.6168 12.8789 59.6168 13.099L59.6192 14.6265C59.618 14.6792 59.6297 14.7314 59.6533 14.7792C59.6769 14.8269 59.7119 14.8689 59.7554 14.9018C59.846 14.9722 59.9616 15.0071 60.0786 14.9993C65.2128 14.3277 67.3065 12.2956 67.5687 7.94999"
        fill="black"
      />
      <path
        d="M85.1572 5.62798C85.0812 5.5602 84.9814 5.52132 84.8769 5.51878H84.8039L76.2053 5.53067L76.2001 2.89815C76.2014 2.84372 76.1913 2.78959 76.1702 2.73891C76.1492 2.68823 76.1177 2.642 76.0776 2.60292C76.0341 2.56195 75.9822 2.52981 75.9251 2.50845C75.868 2.4871 75.8069 2.47697 75.7455 2.47871H75.7319L74.0885 2.48104C74.032 2.48135 73.9762 2.4922 73.9241 2.51297C73.8721 2.53373 73.8249 2.56401 73.7853 2.60206C73.7457 2.64011 73.7144 2.68518 73.6933 2.73469C73.6722 2.78419 73.6616 2.83716 73.6621 2.89055L73.6757 11.7142C73.6757 13.9103 74.5922 14.8342 77.1428 14.8342H77.347L85.0991 14.8209C85.2059 14.8137 85.306 14.7692 85.3799 14.6959C85.4538 14.6227 85.4962 14.5261 85.4988 14.4249L85.4959 12.9234C85.4964 12.8699 85.4857 12.8168 85.4644 12.7672C85.4432 12.7176 85.4118 12.6724 85.372 12.6343C85.3323 12.5962 85.285 12.5658 85.2329 12.545C85.1807 12.5242 85.1247 12.5133 85.0681 12.5129L77.9873 12.5197H77.9778C77.3344 12.5197 76.8606 12.4807 76.5855 12.2676C76.2408 11.9946 76.2072 11.6022 76.2142 10.988L76.2096 7.81609L84.8575 7.804C84.9148 7.80458 84.9716 7.7943 85.0247 7.77375C85.0777 7.7532 85.1258 7.72282 85.1662 7.68438C85.2066 7.64595 85.2384 7.60025 85.2598 7.54999C85.2812 7.49973 85.2917 7.44593 85.2907 7.39177L85.2882 5.92048C85.2893 5.86577 85.2782 5.81145 85.2556 5.76105C85.2331 5.71066 85.1995 5.66532 85.1572 5.62798Z"
        fill="black"
      />
    </svg>
  );
}

export default Icon;
