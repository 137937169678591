import React from 'react';
import styled from 'styled-components';
import {Plus, Setting as SettingIcon} from 'components/Ui/Icon';
import {
  usePoolQuery,
  useSalesforceContactsByProspectQuery,
  useSalesforceAccountsByAccountQuery,
  ProspectPool,
} from 'api';
import DealItem from './DealItem';
import ContactItem from './ContactItem';
import AccountItem from './AccountItem';
import LeadItem from './LeadItem';
import NewDealModal from './NewDeal';
import NewDealToContactModal from './NewDealToContact';
import DealSettingModal from './DealSetting';
import AccountSettingModal from './AccountSetting';
import ContactSettingModal from './ContactSetting';
import useClientUser from 'hooks/useClientUser';
import useProspectContext from '../useProspectContext'

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {isMember} = useClientUser();
  const [active, setActive] = React.useState('deal');
  const [isNewDealModalVisible, setIsNewDealModalVisible] =
    React.useState(false);
  const [isDealSettingModalVisible, setIsDealSettingModalVisible] =
    React.useState(false);
  const [isAccountSettingModalVisible, setIsAccountSettingModalVisible] =
    React.useState(false);
  const [isContactSettingModalVisible, setIsContactSettingModalVisible] =
    React.useState(false);

  const {poolId} = useProspectContext();
  const prospect = prospectPool?.prospect;
  const account = prospect?.account;

  const salesforceDeals = prospectPool?.salesforceDeals;

  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const {data: {salesforceContactsByProspect = []} = {}} =
    useSalesforceContactsByProspectQuery({
      variables: {prospectId: prospect?.id},
      skip: !prospect,
    });

  const {data: {salesforceAccountsByAccount = []} = {}} =
    useSalesforceAccountsByAccountQuery({
      variables: {accountId: account?.id},
      skip: !account,
    });

  const salesforceContactId = prospectPool?.salesforceContactId;
  const salesforceSetting = pool?.poolSalesforceSetting;

  const url = salesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  const dealModalComponent = (prospectPool: ProspectPool) => {
    if (prospectPool.salesforceContact) {
      return (
        <NewDealToContactModal
          prospectPool={prospectPool}
          visible={isNewDealModalVisible}
          onClose={() => setIsNewDealModalVisible(false)}
        />
      );
    } else {
      return (
        <NewDealModal
          prospectPool={prospectPool}
          visible={isNewDealModalVisible}
          onClose={() => setIsNewDealModalVisible(false)}
        />
      );
    }
  };

  return (
    <>
      <Contents>
        <Tabs>
          <TabItem active={active === 'deal'} onClick={() => setActive('deal')}>
            <span>商談</span>
          </TabItem>
          <TabItem
            active={active === 'account'}
            onClick={() => setActive('account')}>
            <span>取引先</span>
          </TabItem>
          <TabItem
            active={active === 'contact'}
            onClick={() => setActive('contact')}>
            <span>取引先責任者</span>
          </TabItem>
          <TabItem active={active === 'lead'} onClick={() => setActive('lead')}>
            <span>リード</span>
          </TabItem>
        </Tabs>
        {active === 'deal' &&
          salesforceDeals.map((salesforceDeal) => (
            <DealItem
              key={salesforceDeal.id}
              salesforceDeal={salesforceDeal}
              url={url}
            />
          ))}
        {active === 'deal' && (
          <Menu>
            <AddDeal onClick={() => setIsNewDealModalVisible(true)}>
              <Plus />
              <span>商談を追加</span>
            </AddDeal>
            {isMember && (
              <Setting onClick={() => setIsDealSettingModalVisible(true)}>
                <SettingIcon />
              </Setting>
            )}
          </Menu>
        )}
        {active === 'contact' &&
          salesforceContactsByProspect.map((salesforceContact) => (
            <ContactItem
              key={salesforceContact.id}
              salesforceContact={salesforceContact}
              connected={salesforceContactId === salesforceContact.id}
              url={url}
            />
          ))}
        {active === 'contact' && (
          <Menu>
            {isMember && (
              <Setting onClick={() => setIsContactSettingModalVisible(true)}>
                <SettingIcon />
              </Setting>
            )}
          </Menu>
        )}
        {active === 'account' &&
          salesforceAccountsByAccount.map((salesforceAccount) => (
            <AccountItem
              key={salesforceAccount.id}
              salesforceAccount={salesforceAccount}
              url={url}
            />
          ))}
        {active === 'account' && (
          <Menu>
            {isMember && (
              <Setting onClick={() => setIsAccountSettingModalVisible(true)}>
                <SettingIcon />
              </Setting>
            )}
          </Menu>
        )}
        {active === 'lead' && prospectPool?.salesforceLead && (
          <LeadItem url={url} salesforceLead={prospectPool.salesforceLead} />
        )}
      </Contents>
      {isNewDealModalVisible && dealModalComponent(prospectPool)}
      {isDealSettingModalVisible && (
        <DealSettingModal
          visible={isDealSettingModalVisible}
          onClose={() => setIsDealSettingModalVisible(false)}
        />
      )}
      {isAccountSettingModalVisible && (
        <AccountSettingModal
          visible={isAccountSettingModalVisible}
          onClose={() => setIsAccountSettingModalVisible(false)}
        />
      )}
      {isContactSettingModalVisible && (
        <ContactSettingModal
          visible={isContactSettingModalVisible}
          onClose={() => setIsContactSettingModalVisible(false)}
        />
      )}
    </>
  );
};

const Contents = styled.div`
  margin-bottom: 2rem;
  overflow-y: scroll;
  max-height: 50vh;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 1rem;
  border: 1px solid #e1e6eb;
  border-radius: 4px 4px 0px 0px;
  border-bottom: none;

  & > div:nth-child(n + 2) {
    border-left: 1px solid #e1e6eb;
  }
`;

const TabItem = styled.div<{active?: boolean}>`
  border-bottom: ${({active}) => (active ? 'none' : '1px solid #e1e6eb;')};
  width: 25%;
  height: 40px;
  background: ${({active}) => (active ? '' : '#fafbfb')};
  text-align: center;
  display: flex
  align-items: center;
  justify-content: center;
  > span {
    margin-top: 13px;
    display: block;
    color: #495058;
    font-style: normal;
    font-weight: ${({active}) => (active ? '700' : '500')};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
  }
  cursor: pointer;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  & > :nth-child(2) {
    margin-left: 8px;
  }
`;

const AddDeal = styled.div`
  width: 100px;
  height: 32px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #899098;
  cursor: pointer;

  & > span {
    margin-left: 2px;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
  }

  & > svg {
    height: 1rem;
    width: auto;
  }

  & > svg > path {
    stroke-width: 2;
  }
`;

const Setting = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg {
    height: 1rem;
    width: auto;
  }
`;
