import React from 'react';
import {Transition} from '@headlessui/react';
import AssigneeFilter from './Assignee';
import TaskTypeFilter from './TaskType';
import PriorityFilter from './Priority';
import Header from '../Header';
import useFilterModalContext from '../useFilterModalContext';

const filterComponents: {[key: string]: any} = {
  assignee: AssigneeFilter,
  taskType: TaskTypeFilter,
  priority: PriorityFilter,
};

const SkeletonFilter = () => (
  <>
    <Header title="" />
    <div className="bg-c-bg flex-1 rounded-b" />
  </>
);

const Filter = () => {
  const {filterType} = useFilterModalContext();
  const FilterComponent = filterComponents[filterType];

  return (
    <Transition
      show={!!FilterComponent}
      enter="transition-all duration-150"
      enterFrom="left-full"
      enterTo="left-0"
      leave="transition-all duration-150"
      leaveFrom="left-0"
      leaveTo="left-full"
      className="w-full h-full flex flex-col absolute top-0"
      as="div">
      {FilterComponent ? <FilterComponent /> : <SkeletonFilter />}
    </Transition>
  );
};

export default Filter;
