import React from 'react';
import {FormBlock, FormBlockFieldOther} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;

  return (
    <div className="w-full">
      <div className="flex gap-1 mb-2 items-center">
        <label className="tracking-wide font-bold">{blockable.title}</label>
        {blockable.isRequired && <span className="text-red-500">*</span>}
        <span className="text-xs h-[18px] px-1 border border-c-border leading-none rounded-sm bg-[#F5FAFE] text-c-lighter flex items-center">
          その他の項目
        </span>
      </div>
      <textarea
        className="form-textarea h-32 block w-full border rounded"
        placeholder={blockable.placeholder}
        disabled
      />
      {blockable.helpText && (
        <div className="text-c-light text-sm whitespace-pre-wrap">
          {blockable.helpText}
        </div>
      )}
    </div>
  );
};
