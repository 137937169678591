import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useOpenWorkflowsWithStepsQuery, ProspectPool} from 'api';
import Workflow from './Workflow';
import useProspectContext from '../../useProspectContext';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  prospectPool: ProspectPool;
}

const ApplyWorkflowModal = ({isOpen, closeModal, prospectPool}: Props) => {
  const {poolId} = useProspectContext();
  const {data: {openWorkflows = []} = {}} = useOpenWorkflowsWithStepsQuery({
    variables: {
      poolUuid: poolId,
    },
    skip: !poolId,
  });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-3xl transform rounded bg-white text-left align-middle transition-all">
                <Dialog.Title
                  as="h4"
                  className="h-10 flex items-center px-4 bg-c-bg text-c-base">
                  ワークフローを適用
                </Dialog.Title>
                <div className="p-4 flex flex-col gap-4">
                  {openWorkflows.map((workflow) => (
                    <Workflow
                      key={workflow.uuid}
                      workflow={workflow}
                      prospectPool={prospectPool}
                      onApply={closeModal}
                    />
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ApplyWorkflowModal;
