import React from 'react';
import styled from 'styled-components';
import {Checkbox, Radio} from 'components/antd';
import useSearchCondition from '../useSearchCondition';

const monthPressReleaseOptions = [
  {label: '未選択', value: ''},
  {label: '1ヶ月以内', value: '1'},
  {label: '3ヶ月以内', value: '3'},
  {label: '6ヶ月以内', value: '6'},
  {label: '12ヶ月以内', value: '12'},
];

const countPressReleaseOptions = [
  {label: '未選択', value: ''},
  {label: '1回以上', value: '1'},
  {label: '5回以上', value: '5'},
  {label: '10回以上', value: '10'},
  {label: '30回以上', value: '30'},
];

const salesRangeOptions = [
  {label: '0 ~ 10億', value: '0to10'},
  {label: '10億 ~ 50億', value: '10to50'},
  {label: '50億 ~ 100億', value: '50to100'},
  {label: '100億 ~ 500億', value: '100to500'},
  {label: '500億 ~ 1000億', value: '500to1000'},
  {label: '1000億', value: '1000to'},
];

const profitRateRangeOptions = [
  {label: '0 ~ 50%', value: '0to50'},
  {label: '50% ~ 100%', value: '50to100'},
  {label: '100% ~ 200%', value: '100to200'},
  {label: '200%以上', value: '200to'},
  {label: '0 ~ -50%', value: '-50to0'},
  {label: '-50% ~ -100%', value: '-100to-50'},
  {label: '-100% ~ -200%', value: '-200to-100'},
  {label: '-200%以下', value: 'to-200'},
];

interface Values {
  [key: string]: string | boolean | string[];
}

export default () => {
  const {query, setQuery} = useSearchCondition();

  const handleChange = (values: Values) => {
    setQuery({...values, page: 1});
  };

  return (
    <FilterContainer>
      <div>
        <Title>プレスリリース</Title>
        <Label>掲載期間</Label>
        <Radio.Group
          options={monthPressReleaseOptions}
          value={query.monthPressRelease}
          onChange={(e) => handleChange({monthPressRelease: e.target.value})}
        />
        <Label>掲載回数</Label>
        <Radio.Group
          options={countPressReleaseOptions}
          value={query.countPressRelease}
          onChange={(e) => handleChange({countPressRelease: e.target.value})}
        />
      </div>
      <Separator />
      <div>
        <Title>決算</Title>
        <Label>売上</Label>
        <SalesRangeWrapper>
          <Checkbox.Group
            options={salesRangeOptions}
            value={query.salesRanges}
            onChange={(checkedValue: string[]) =>
              handleChange({salesRanges: checkedValue})
            }
          />
        </SalesRangeWrapper>
        <WideCheckboxWrapper>
          <Checkbox
            checked={query.profitUp3}
            onChange={(e) => handleChange({profitUp3: e.target.checked})}>
            純利益三期連続UP
          </Checkbox>
          <Checkbox
            checked={query.profitDown3}
            onChange={(e) => handleChange({profitDown3: e.target.checked})}>
            純利益三期連続DOWN
          </Checkbox>
        </WideCheckboxWrapper>
        <Label>純利益前期比較</Label>
        <MultiRowWrapper>
          <Checkbox.Group
            options={profitRateRangeOptions}
            value={query.profitRateRanges}
            onChange={(checkedValue: string[]) =>
              handleChange({profitRateRanges: checkedValue})
            }
          />
        </MultiRowWrapper>
      </div>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 743px;
    padding: 1rem 1rem 1rem 1rem;
  }

  .ant-checkbox-group,
  .ant-radio-group {
    display: flex;
    flex-direction: row;
  }

  .ant-radio-wrapper {
    width: 100px;
    margin: 10px 10px 20px 10px;
  }

  .ant-checkbox-wrapper {
    width: 120px;
    margin: 5px 10px;
  }
`;

const SalesRangeWrapper = styled.div`
  .ant-checkbox-group {
    width: 580px;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
`;

const WideCheckboxWrapper = styled.div`
  .ant-checkbox-wrapper {
    width: 160px;
    margin: 10px 20px 20px 10px;
  }
`;

const MultiRowWrapper = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 640px;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: #899098;
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: #222426;
  margin-left: 5px;
`;

const Separator = styled.span`
  display: block;
  border-top: 1px solid #e2e6ea;
`;
