import React from 'react';
import {useUsersByClientAllQuery} from 'api';
import useSearchParams from '../../../useSearchParams';
import CheckboxGroup from './CheckboxGroup';
import Header from '../Header';
import Footer from './Footer';

const AssigneeFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  return (
    <>
      <Header title="ToDo担当者" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={users.map((user) => ({
              text: `${user.lastName} ${user.firstName}`,
              value: user.id,
              keyword: user.lastName + user.firstName,
            }))}
            values={query.assignees || []}
            onChange={(values: string[]) =>
              setQuery({...query, assignees: values, page: 1})
            }
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, assignees: []})} />
    </>
  );
};

export default AssigneeFilter;
