import React, {useCallback} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Select, Form, Input} from 'components/antd';
import {useAddCustomizeItemMutation, useCurrentClientQuery} from 'api';
import {Calendar, HashTag, Text as TextIcon} from 'components/Ui/Icon';

const customizeItemsOptions = [
  {text: 'テキスト', value: 'text', icon: TextIcon},
  {text: '数字', value: 'number', icon: HashTag},
  {text: '日付', value: 'date', icon: Calendar},
];

interface Props {
  visible: boolean;
  onClose: () => void;
}

const New = ({visible, onClose}: Props) => {
  const [addCustomizeItem] = useAddCustomizeItemMutation({
    refetchQueries: ['currentClient'],
    onCompleted: () => onClose(),
  });

  const {data: {currentClient: {customizeItems = []} = {}} = {}} =
    useCurrentClientQuery({});

  const isLimit = useCallback(
    (dataType: string) =>
      customizeItems.filter(
        (customizeItem) => customizeItem.dataType === dataType,
      ).length === (dataType === 'text' ? 5 : 3),
    [customizeItems],
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      dataType: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      dataType: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values, {resetForm}) => {
      addCustomizeItem({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
      resetForm();
    },
  });

  return (
    <Modal
      destroyOnClose
      visible={visible}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <GlobalStyle />
      <ModalContent>
        <h2>項目追加</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="項目名" required>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="データ形式" required>
            <Select
              value={formik.values.dataType}
              onSelect={(value: any) => formik.setFieldValue('dataType', value)}
              dropdownMatchSelectWidth={false}
              dropdownClassName="customize-item-dropdown"
              size="large"
              optionLabelProp="label">
              {customizeItemsOptions.map((option) => {
                if (isLimit(option.value)) return null;
                return (
                  <Select.Option
                    value={option.value}
                    key={option.value}
                    label={
                      <>
                        <option.icon />
                        &nbsp; {option.text}
                      </>
                    }>
                    <option.icon />
                    &nbsp; {option.text}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!formik.isValid}>
              追加
            </Button>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GlobalStyle = createGlobalStyle`

  .customize-item-dropdown {

    .ant-select-item-option-content{
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        width: 20px;
      }
    }
  }
`;

export default New;
