import React from 'react';

function Icon() {
  return (
    <svg
      width="26"
      height="17"
      viewBox="0 0 26 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9999 9.54167H18.9249V15.1417C18.9249 15.3627 19.0127 15.5746 19.1689 15.7309C19.3252 15.8872 19.5372 15.975 19.7582 15.975H23.4499C23.6709 15.975 23.8828 15.8872 24.0391 15.7309C24.1954 15.5746 24.2832 15.3627 24.2832 15.1417V7.04167C24.2832 6.9123 24.2531 6.78471 24.1952 6.669C24.1374 6.55328 24.0534 6.45263 23.9499 6.37501L16.7832 1L13.0332 3.80833"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.04167V15.1667C1 15.3877 1.08778 15.5996 1.24406 15.7559C1.40034 15.9122 1.61232 16 1.83333 16H5.52499C5.74601 16 5.95798 15.9122 6.11426 15.7559C6.27054 15.5996 6.35833 15.3877 6.35833 15.1667V9.54167H10.6417V15.1417C10.6417 15.3627 10.7295 15.5746 10.8857 15.7309C11.042 15.8872 11.254 15.975 11.475 15.975H15.1667C15.3877 15.975 15.5996 15.8872 15.7559 15.7309C15.9122 15.5746 16 15.3627 16 15.1417V7.04167C16 6.9123 15.9699 6.78471 15.912 6.669C15.8542 6.55328 15.7702 6.45263 15.6667 6.37501L8.5 1L1.33333 6.37501C1.22983 6.45263 1.14582 6.55328 1.08797 6.669C1.03011 6.78471 1 6.9123 1 7.04167Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
