import React, {useCallback} from 'react';
import useSearchParams from '../useSearchParams';
import {useProjectGroupsQuery} from 'api';
import {Popover} from 'components/antd';
import {PopoverContent, CheckBoxStyled, Box, FilterContent} from './style';

const GroupFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery({});

  const projectGroupsChecked = (id: string) =>
    query.projectGroupIds.some((projectGroup) => projectGroup === id);

  const onChangeProjectGroup = useCallback((value: string) => {
    const newProjectGroups = query.projectGroupIds;
    const target = newProjectGroups.find((projectGroup) => {
      return projectGroup === value;
    });
    if (target) {
      newProjectGroups.splice(newProjectGroups.indexOf(target), 1);
    } else {
      newProjectGroups.push(value);
    }
    setQuery({projectGroupIds: newProjectGroups, projectIds: []});
  }, []);

  const projectGroupName = useCallback(
    (projectGroupId: string) =>
      projectGroups.find((projectGroup) => projectGroup.id === projectGroupId)
        ?.name,
    [projectGroups],
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <PopoverContent>
          {projectGroups.length === 0 && <span>データがありません。</span>}
          {projectGroups?.map((projectGroup) => (
            <div key={projectGroup.id}>
              <CheckBoxStyled
                value={projectGroup.id}
                checked={projectGroupsChecked(projectGroup.id)}
                onChange={(e) => onChangeProjectGroup(e.target.value)}>
                {projectGroup.name}
              </CheckBoxStyled>
            </div>
          ))}
        </PopoverContent>
      }>
      <Box>
        グループ
        {query.projectGroupIds.length > 0 && (
          <Popover
            placement="bottom"
            content={
              <FilterContent>
                {query.projectGroupIds?.map((projectGroupId) => (
                  <div key={projectGroupId}>
                    <span>{projectGroupName(projectGroupId)}</span>
                  </div>
                ))}
              </FilterContent>
            }>
            {query.projectGroupIds.length > 0 && (
              <span>{query.projectGroupIds.length}</span>
            )}
          </Popover>
        )}
      </Box>
    </Popover>
  );
};

export default GroupFilter;
