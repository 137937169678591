import {ReportChart} from '../../components/Chart';
import {StringParam, useQueryParams} from 'use-query-params';
import useMonthAxis from '../../hooks/useMonthAxis';
import {useProspectReportProspectPoolUserDailyCallUserReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const axis = useMonthAxis(query.month);
  const {data: {prospectReportProspectPoolUserDailyCallUserReports = []} = {}} =
    useProspectReportProspectPoolUserDailyCallUserReportsQuery({
      variables: {month: query.month},
      skip: !query.month,
      fetchPolicy: 'cache-and-network',
    });

  const data = () => {
    return prospectReportProspectPoolUserDailyCallUserReports.map((report) => {
      return {
        label: report.name,
        groupId: 'main',
        values: report.values,
      };
    });
  };
  return {
    axis,
    data: data(),
  };
};

export default useChartData;
