import React from 'react';
import {PencilSquareIcon, CheckIcon} from '@heroicons/react/20/solid';
import {Menu, Transition} from '@headlessui/react';
import {
  useAddOrRemoveAccountPoolAccountTagMutation,
  useAccountPoolQuery,
  useAccountTagsQuery,
  AccountTag,
} from 'api';
import {useParams} from 'react-router-dom';

const TagSelect = () => {
  const {poolId, accountId} = useParams<{
    poolId: string;
    accountId: string;
  }>();

  const {data: {accountPool = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const [addOrRemove] = useAddOrRemoveAccountPoolAccountTagMutation({});

  const {data: {accountTags = []} = {}} = useAccountTagsQuery({
    variables: {
      uuid: poolId,
    },
  });
  const selectedTags: AccountTag[] = accountPool.accountTags;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-c-light">タグ</div>
          <div className="flex gap-2">
            {selectedTags?.map((tag) => {
              return (
                <span
                  key={tag.id}
                  style={{background: tag.color}}
                  className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white">
                  {tag.name}
                </span>
              );
            })}
          </div>
        </div>
        <Menu.Button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
          <PencilSquareIcon className="w-5 h-5 text-c-primary" />
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute origin-top-right rounded bg-white shadow z-10 w-[calc(100%-3rem)] right-12">
          {accountTags?.map((accountTag) => (
            <Menu.Item key={accountTag.id}>
              {({active}) => (
                <div
                  onClick={() => {
                    addOrRemove({
                      variables: {
                        id: accountPool?.id,
                        tagId: accountTag.id,
                      },
                    });
                  }}
                  className={`${
                    active ? 'bg-c-bg' : ''
                  } w-full flex items-center gap-2 py-1 px-2 cursor-pointer`}>
                  <CheckIcon
                    className={`w-4 h-4 ${selectedTags.map((tag) => tag.id).includes(accountTag.id) ? 'text-c-primary' : 'text-transparent'}`}
                  />
                  <div className="flex flex-col">
                    <h4 className="text-sm font-normal m-0">
                      <span
                        style={{background: accountTag.color}}
                        className="px-3 py-1 rounded text-sm leading-none font-bold tracking-tight text-white">
                        {accountTag.name}
                      </span>
                    </h4>
                  </div>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default TagSelect;
