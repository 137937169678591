import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import LoadingIcon from './loading.svg';

export default () => {
  return <StyledLoading />;
};

const StyledLoading = styled(LoadingIcon)`
  width: 40px;
  height: 40px;
`;
