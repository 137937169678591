import React from 'react';
import {Approach, Action, ApproachProspect} from 'api';

interface Props {
  approach: Approach;
}

const Name = ({approach}: Props) => {
  const approachable = approach.approachable;

  if (approach.approachableType === 'Action') {
    return (
      <>
        <h4 className="text-base font-medium leading-none m-0">
          {(approachable as Action).step.name}
        </h4>
        <span className="text-xs text-c-light">
          {(approachable as Action).workflow.name}
        </span>
      </>
    );
  }

  if (approach.approachableType === 'Approach::Prospect') {
    return (
      <h4 className="text-base font-medium leading-none">
        {(approachable as ApproachProspect).approachGroupProspect.title}
      </h4>
    );
  }

  return null;
};

export default Name;
