import React from 'react';
import moment from 'moment';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const LatestTelUpdatedAtCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  return (
    <span>
      {preleadProject.prelead.telUpdatedAt &&
        moment(preleadProject.prelead.telUpdatedAt).format(
          'YYYY年MM月DD日 HH時mm分',
        )}
    </span>
  );
};

export default LatestTelUpdatedAtCell;
