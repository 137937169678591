import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#9B51E0" />
      <path
        d="M12.0835 13.0833H16.7502"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0835 15.4167H14.4168"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0833 10.75H10.9167C10.2723 10.75 9.75 11.2723 9.75 11.9167V17.75C9.75 18.3944 10.2723 18.9167 10.9167 18.9167H12.6667L14.7937 21.0437C14.9077 21.1577 15.0923 21.1577 15.2063 21.0437L17.3333 18.9167H19.0833C19.7277 18.9167 20.25 18.3944 20.25 17.75V11.9167C20.25 11.2723 19.7277 10.75 19.0833 10.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
