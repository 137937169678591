import React from 'react';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import Header from '../Header';
import {useProjectQuery} from 'api';
import UserReport from './userReport';
import TelReport from './allReport';
import CompanyProfileReport from './companyProfileReport';
import projectRoutePaths from 'helpers/projectRoutePaths';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId} = useParams<{
    projectId: string;
  }>();
  const history = useHistory();
  const {createPath} = useProjectPath();

  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    onCompleted: () => {
      if (project?.status === 'deleted' || project === null) {
        history.push(createPath(`projects`));
      }
    },
  });

  if (!project) return null;

  return (
    <Container>
      <Header />
      <Body>
        <Tabs>
          <TabItem active={location.pathname.includes('tel_reports/all')}>
            <Link to={createPath(`projects/${projectId}/tel_reports/all/call`)}>
              全体
            </Link>
          </TabItem>
          <TabItem active={location.pathname.includes('tel_reports/user')}>
            <Link
              to={createPath(`projects/${projectId}/tel_reports/user/call`)}>
              担当者別 / 消化率
            </Link>
          </TabItem>
          <TabItem
            active={location.pathname.includes('tel_reports/company_profile')}>
            <Link
              to={createPath(
                `projects/${projectId}/tel_reports/company_profile/category`,
              )}>
              企業プロフィール別
            </Link>
          </TabItem>
        </Tabs>

        <Switch>
          <Route
            path={projectRoutePaths('projects/:projectId/tel_reports/all')}
            component={TelReport}
          />
          <Route
            path={projectRoutePaths('projects/:projectId/tel_reports/user')}
            component={UserReport}
          />
          <Route
            path={projectRoutePaths(
              'projects/:projectId/tel_reports/company_profile',
            )}
            component={CompanyProfileReport}
          />
        </Switch>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  background: #f3f5f7;
`;

const Body = styled.div`
  min-width: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f3f5f7;
  padding-left: 32px;
  height: calc(100vh - 216px);
  overflow: scroll;
`;

const Tabs = styled.div`
  margin-top: 30px;
  display: flex;
  border-radius: 4px;
`;

const TabItem = styled.div<{active?: boolean}>`
  height: 45px;
  width: 135px;
  display: flex;
  background: ${({active}) => (active ? '#FFFFFF' : '#ECF0F3')};

  a {
    color: ${({active}) => (active ? '#222426' : '#BDC4CB')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    font-size: 14px;
  }
`;
