import React from 'react';
import {Approach} from 'api';
import {Button, Tooltip} from 'components/antd';
import {approachTypeMenu} from './index';

interface Props {
  approach: Approach;
  approachTypeMenus: approachTypeMenu[];
}

export default ({approach, approachTypeMenus}: Props) => {
  const approachType = approachTypeMenus.find(
    (item) => item.key === approach.approachType,
  );

  return (
    <Tooltip title={approachType.label} color="#4d4c4c">
      <Button
        className="flex justify-center items-center cursor-pointer w-[30px] h-[30px] p-1 [&>svg]:h-[17px] [&>svg]:w-[25px] rounded-[4px] ml-auto"
        icon={approachType.icon}
        onClick={approachType.action}
      />
    </Tooltip>
  );
};
