import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Arrow from './Arrow';
import {ProspectPool} from 'api';
import Salesforce from './Salesforce';
import HubSpot from './HubSpot';

interface Props {
  targetCount: number;
  prospectPool: ProspectPool;
  uuid: string;
}

export default ({targetCount, prospectPool, uuid}: Props) => {
  return (
    <tr>
      <td className="border-b border-c-border px-8 py-4 font-normal break-words whitespace-pre-wrap">
        <div className="flex items-center">
          <div className="flex-1 flex items-baseline justify-center gap-2">
            <em className="not-italic text-[28px] font-bold">
              {targetCount.toLocaleString()}
            </em>
          </div>
          <Arrow />
        </div>
      </td>
      <td
        className={`border-b border-c-border py-4 font-normal break-words whitespace-pre-wrap text-sm`}>
        <div className="flex flex-col gap-1 min-h-[160px] relative border-r border-c-border px-8">
          <div className="flex items-center">
            <div className="w-1/4 truncate text-c-light">名前</div>
            <div className="w-3/4 truncate flex items-center gap-2">
              {prospectPool?.prospect?.lastName}{' '}
              {prospectPool?.prospect?.firstName}
              {prospectPool?.salesforceContactId && (
                <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a]">
                  <Salesforce />
                  取引先責任者
                </span>
              )}
              {prospectPool?.salesforceLeadId && (
                <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a]">
                  <Salesforce />
                  リード
                </span>
              )}
              {prospectPool.hubspotContact && (
                <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#ff7a59] bg-[#ff7a594d]">
                  <HubSpot />
                  コンタクト
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/4 truncate text-c-light">会社名</div>
            <div className="w-3/4 truncate">
              {prospectPool?.prospect?.account?.name}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/4 truncate text-c-light">URL</div>
            <div className="w-3/4 truncate">
              <a
                href={prospectPool?.prospect?.account?.webSite}
                target="_blank">
                {prospectPool?.prospect?.account?.webSite}
              </a>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/4 truncate text-c-light">メールアドレス</div>
            <div className="w-3/4 truncate">
              {prospectPool?.prospect?.email}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/4 truncate text-c-light">電話番号</div>
            <div className="w-3/4 truncate">
              {prospectPool?.prospect?.telephoneNumber}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/4 truncate text-c-light">リードソース</div>
            <div className="w-3/4 truncate flex items-center gap-4">
              {prospectPool?.leadSource?.name && (
                <div className="flex items-center gap-2">
                  最新
                  <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                    {prospectPool?.leadSource?.name}
                  </span>
                </div>
              )}
              {prospectPool?.originalLeadSource?.name && (
                <div className="flex items-center gap-2 text-sm">
                  初回
                  <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                    {prospectPool?.originalLeadSource?.name}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/4 truncate text-c-light">流入日</div>
            <div className="w-3/4 truncate flex items-center gap-4">
              {prospectPool?.latestInflowDate && (
                <div className="flex items-center gap-2">
                  最新
                  <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                    {moment(prospectPool.latestInflowDate).format('YYYY/MM/DD')}
                  </span>
                </div>
              )}
              {prospectPool?.originalInflowDate && (
                <div className="flex items-center gap-2 text-sm">
                  初回
                  <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                    {moment(prospectPool.originalInflowDate).format(
                      'YYYY/MM/DD',
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className="border-b border-c-border py-4 font-normal break-words">
        <div className="w-full flex items-center justify-center">
          <Link
            to={`/maintenance/${uuid}${location.search}`}
            className="px-8 h-8 text-sm rounded border border-c-primary bg-c-primary text-white flex items-center justify-center">
            詳細
          </Link>
        </div>
      </td>
    </tr>
  );
};
