import gql from 'graphql-tag';
import preleadFragment from '../prelead/prelead';
import projectFragment from '../project/project';
import {projectGroupFragment} from '..';

export default gql`
  fragment taskPreleadFragment on TaskPrelead {
    id
    createdAt
    prelead {
      ...preleadFragment
    }
    project {
      ...projectFragment
      projectGroup {
        ...projectGroupFragment
      }
    }
  }
  ${preleadFragment}
  ${projectFragment}
  ${projectGroupFragment}
`;
