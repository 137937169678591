import React from 'react';
import debounce from 'helpers/debounce';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  useUpdateFormMutation,
  Form,
  FormAttributes,
  useCurrentClientQuery,
} from 'api';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();
  const [update] = useUpdateFormMutation();

  const url = `${location.protocol}//${location.host.replace(
    `${currentClient?.domain}.`,
    'app.',
  )}/forms/${form.uuid}`;

  const code = `<div style="width:100%;height:${
    form.iframeHeight || '900'
  }px;"><iframe src="${url}" frameBorder="0" width="100%" height="100%"></iframe></div>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
  };

  const {register, watch, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        iframeHeight: Yup.number().nullable(),
      }),
    ),
    values: {
      iframeHeight: form.iframeHeight,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: FormAttributes) => {
    update({
      variables: {
        uuid: form.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-8">
      <p>
        Webサイトにiframeを埋め込み表示する形式です。サイズは自動で設定されます。HTMLのコードを生成します。
      </p>
      <div className="flex flex-col gap-1 w-[480px]">
        <div className="text-c-base text-sm m-0">
          高さを指定する場合は入力してください
        </div>
        <div className="flex items-center gap-2 w-32">
          <input
            className="form-input rounded border-c-border text-base"
            type="number"
            {...register('iframeHeight', {
              setValueAs: (value) => (value === '' ? null : Number(value)),
            })}
          />
          px
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-c-base text-sm m-0">埋め込みコード</p>
        <textarea
          className="w-full bg-c-bg border border-c-border rounded p-4 h-32"
          value={code}
          readOnly
        />
        <button
          className="flex items-center justify-center h-9 w-24 rounded bg-c-primary text-white hover:opacity-90 transition-all cursor-pointer mt-2"
          onClick={() => handleCopy()}>
          コピー
        </button>
      </div>
    </div>
  );
};
