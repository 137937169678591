import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignBlockButtonFragment on MailCampaignBlockButton {
    id
    text
    link
    bodyColor
    textColor
    buttonColor
    paddingTop
    paddingBottom
    paddingLeft
    paddingRight
    createdAt
    updatedAt
  }
`;
