import React from 'react';
import {Tel} from './Icon';
import {ProspectPool} from 'api';
import moment from 'moment';

type Props = {
  prospectPool: ProspectPool;
};

const Call: React.FC<Props> = ({prospectPool}) => {
  return (
    <div className="px-4 my-4 flex flex-col gap-1 border-r border-c-border">
      <div className="flex flex-col">
        <div className="text-sm text-c-light truncate">
          最新コールアクション
        </div>
      </div>
      <div className="flex flex-col gap-1 leading-tight">
        <div className="text-sm truncate">
          {prospectPool.telUpdatedDate
            ? moment(prospectPool.telUpdatedDate).format('YYYY/MM/DD')
            : '-'}
        </div>
        <div className="text-sm truncate">
          {prospectPool.lastTelUser?.lastName}{' '}
          {prospectPool.lastTelUser?.firstName}
        </div>
        <div>
          {prospectPool.lastClientProspectTelStatus ? (
            <span className="rounded-sm font-bold inline-flex items-center gap-1 text-xs h-5 px-2 bg-[#E6F5E9] text-[#27AE60]">
              <Tel />
              {prospectPool.lastClientProspectTelStatus.name}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Call;
