import React from 'react';
import styled from 'styled-components';
import {Action} from 'api';
import moment from 'moment';
import {Tel, Mail, Task} from './Icon';

interface Props {
  lastAction: Action;
}

export default ({lastAction}: Props) => {
  let color: string;
  if (lastAction.actionableType == 'Action::AutomaticMail') {
    color = '#88C9D8';
  } else if (lastAction.actionableType == 'Action::ManualMail') {
    color = '#F99985';
  } else if (lastAction.actionableType == 'Action::Call') {
    color = '#8DD888';
  } else if (lastAction.actionableType == 'Action::Task') {
    color = '#8DD888';
  }

  const Icon = () => {
    switch (lastAction.actionableType) {
      case 'Action::AutomaticMail':
        return <Mail />;
      case 'Action::ManualMail':
        return <Mail />;
      case 'Action::Call':
        return <Tel />;
      case 'Action::Task':
        return <Task />;
    }
  };

  return (
    <LastAction color={color}>
      <div>
        <Icon />
        {lastAction.completedAt &&
          (moment().diff(moment(lastAction.completedAt), 'hours') < 24 ? (
            <span>
              {moment().diff(moment(lastAction.completedAt), 'hours')}時間前
            </span>
          ) : (
            <span>
              {moment().diff(moment(lastAction.completedAt), 'days')}日前
            </span>
          ))}
      </div>
    </LastAction>
  );
};

const LastAction = styled.div<{color: string}>`
  div {
    height: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;

    > span {
      margin-left: 4px;
      font-size: 12px;
    }
  }
`;
