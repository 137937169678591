import React from 'react';
import {MailMessage} from 'api';
import Message from './Message';

interface Props {
  mailMessages: MailMessage[];
}

export default ({mailMessages = []}: Props) => (
  <>
    {mailMessages.map((message, index) => (
      <Message
        key={message.id}
        mailMessage={message}
        defaultOpen={mailMessages.length == index + 1}
      />
    ))}
  </>
);
