import React from 'react';
import styled from 'styled-components';
import Menu from '../../Menu';
import Title from './Title';
import HeadSection from './HeadSection';
import {Switch, Route} from 'react-router-dom';
import Account from './Account';
import Lead from './Lead';
import Linkage from './Linkage';
import SyncTarget from './SyncTarget';
import Todo from './Todo';
import Task from './Task';

export default () => {
  return (
    <Body>
      <Menu />
      <Content>
        <Title />
        <HeadSection />
        <Switch>
          <Route
            component={SyncTarget}
            path="/settings/externals/salesforce/sync_target"
          />
          <Route
            component={Account}
            path="/settings/externals/salesforce/account"
          />
          <Route
            component={Lead}
            path="/settings/externals/salesforce/lead"
          />
          <Route component={Todo} path="/settings/externals/salesforce/todo" />
          <Route component={Task} path="/settings/externals/salesforce/task" />
          <Route component={Linkage} path="/settings/externals/salesforce" />
        </Switch>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 290px);
  // height: fit-content;
  height: calc(100vh - 60px);
  padding: 45px 45px;
  overflow: scroll;
`;
