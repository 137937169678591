import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66634 5.24984V5.99984V5.24984ZM10.083 5.24984V5.99984V5.24984ZM13.333 5.24984V5.99984V5.24984ZM6.66634 8.74984V9.49984V8.74984ZM10.083 8.74984V9.49984V8.74984ZM13.333 8.74984V9.49984V8.74984ZM4.99967 18.3332H14.9997C15.9163 18.3332 16.6663 17.5832 16.6663 16.6665V3.33317C16.6663 2.4165 15.9163 1.6665 14.9997 1.6665H4.99967C4.08301 1.6665 3.33301 2.4165 3.33301 3.33317V16.6665C3.33301 17.5832 4.08301 18.3332 4.99967 18.3332Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.417 13.2494H7.66699V18.3327H12.417V13.2494Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
