import React from 'react';
import styled from 'styled-components';
import {Input, Modal} from 'components/antd';
import {Button, Radio} from 'components/Ui';
import {
  BlockGroup,
  useAddProjectToBlockGroupMutation,
  useAllBlockGroupsQuery,
  usePreleadProjectsQuery,
} from 'api';
import {useParams} from 'react-router';
import {Folder, Note} from 'components/Ui/Icon';
import useSearchParams from '../useSearchParams';
const {TextArea} = Input;

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const [selectedBlockGroupId, setSelectedBlockGroupId] = React.useState(null);
  const [blockGroupName, setBlockGroupName] = React.useState('');
  const [blockGroupMemo, setBlockGroupMemo] = React.useState('');
  const {searchParams, page, perPage} = useSearchParams();

  const {data: {allBlockGroups: {blockGroups = []} = {}} = {}} =
    useAllBlockGroupsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const {
    data: {
      preleadProjects: {pagination: preleadProjectsPagination = {}} = {},
    } = {},
  } = usePreleadProjectsQuery({
    variables: {
      uuid: projectId,
      search: searchParams,
      page,
      perPage,
    },
    fetchPolicy: 'cache-only',
  });

  const handleClose = () => {
    setSelectedBlockGroupId(null);
    setModalVisible(false);
    setBlockGroupName('');
    setBlockGroupMemo('');
  };

  const [addProjectToBlockGroup, {loading}] = useAddProjectToBlockGroupMutation(
    {
      onCompleted: handleClose,
      refetchQueries: ['project', 'allBlockGroups', 'currentUser'],
    },
  );

  const handleSubmit = () => {
    addProjectToBlockGroup({
      variables: {
        uuid: projectId,
        search: searchParams,
        attributes: {
          uuid: selectedBlockGroupId,
          name: blockGroupName,
          memo: blockGroupMemo,
        },
      },
    });
  };

  return (
    <ReflectModal
      destroyOnClose
      title={<Title>ブロックグループに追加</Title>}
      maskClosable={false}
      visible={isModalVisible}
      width={540}
      centered
      bodyStyle={{paddingTop: '24px', padding: 0}}
      footer={[
        <CustomButton
          key="submit"
          onClick={handleSubmit}
          loading={loading}
          disabled={!selectedBlockGroupId && !blockGroupName}>
          保存
        </CustomButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <div>
          フィルタリングされている状態でブロックグループが作成できます。
        </div>

        <CountBox>
          <span>現在のフィルタリング件数:</span>
          <Count>
            {preleadProjectsPagination.totalCount?.toLocaleString()}
          </Count>
        </CountBox>

        <BlockGroupList>
          {blockGroups?.map((blockGroup: BlockGroup) => (
            <BlockGroupBox key={blockGroup.uuid}>
              <CustomRadio
                checked={selectedBlockGroupId === blockGroup.uuid}
                onChange={() => setSelectedBlockGroupId(blockGroup.uuid)}>
                <BlockGroupName bold={selectedBlockGroupId === blockGroup.uuid}>
                  {blockGroup.name}
                </BlockGroupName>
                <BlockGroupCount
                  bold={selectedBlockGroupId === blockGroup.uuid}>
                  {blockGroup.count.toLocaleString()}件
                </BlockGroupCount>
              </CustomRadio>
            </BlockGroupBox>
          ))}
        </BlockGroupList>

        <NewBlockGroup>
          <CustomRadio
            checked={!selectedBlockGroupId}
            onChange={() => setSelectedBlockGroupId(null)}>
            <BlockGroupName bold={!selectedBlockGroupId}>
              新規ブロックグループを作成
            </BlockGroupName>
          </CustomRadio>

          <InputSection>
            <Item>
              <Folder />
              <NameInput
                disabled={selectedBlockGroupId}
                name="name"
                placeholder="新規ブロックグループ名"
                value={blockGroupName}
                onChange={(e) => setBlockGroupName(e.target.value)}
              />
            </Item>
            <ItemMemo>
              <Note />
              <MemoInput
                disabled={selectedBlockGroupId}
                name="memo"
                autoSize={{minRows: 1, maxRows: 6}}
                value={blockGroupMemo}
                onChange={(e) => setBlockGroupMemo(e.target.value)}
              />
            </ItemMemo>
          </InputSection>
        </NewBlockGroup>
      </ModalContent>
    </ReflectModal>
  );
};

const ReflectModal = styled(Modal)`
  &&& {
    .ant-modal-header {
      border: none;
      padding: 16px 24px 13px 24px !important;
    }
    .ant-modal-body {
      padding: 0px 24px !important;
    }
  }
`;

const CustomButton = styled(Button)`
  width: 86px;
  height: 24px;

  span {
    font-weight: bold;
    font-size: 10px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #222426;
`;

const CountBox = styled.div`
  margin: 6px 0 23px 0;
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 30px;
  padding: 8px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 10px;
`;

const Count = styled.span`
  margin-left: 7px;
  font-weight: bold;
  font-size: 13px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlockGroupList = styled.div`
  overflow: scroll;
  height: 160px;
  border-bottom: 1px solid #f3f5f7;
  padding-bottom: 16px;
`;

const BlockGroupBox = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const CustomRadio = styled(Radio)`
  width: 280px;
`;

const BlockGroupName = styled.div<{bold?: boolean}>`
  display: inline-block;
  width: 200px;
  font-weight: ${({bold}) => (bold ? 'bold' : '')};
`;

const BlockGroupCount = styled.div<{bold?: boolean}>`
  display: inline-block;
  float: right;
  font-weight: ${({bold}) => (bold ? 'bold' : '')};
`;

const NewBlockGroup = styled.div`
  height: 200px;
  padding: 15px 0 19px 0;
`;

const InputSection = styled.div`
  padding: 15px 0 19px 40px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  width: 100%;
  min-height: 26px;
  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const NameInput = styled(Input)`
  width: 420px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: #e2e6ea;
  border-radius: 4px;
  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const MemoInput = styled(TextArea)`
  width: 420px;
  height: 75px !important;
  word-break: break-all;
  border-color: #e2e6ea;
  border-radius: 4px;
  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;
