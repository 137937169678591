import React from 'react';
import {MailCampaignBlock, MailCampaignBlockHeader} from 'api';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockHeader;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const {image, imageScale, imageAlt} = block.blockable;

  const imageSrc = image?.length > 0 ? image : sampleLogo;

  const style = {
    backgroundColor: block.blockable?.bodyColor,
    paddingTop: block.blockable?.paddingTop || 0,
    paddingBottom: block.blockable?.paddingBottom || 0,
    paddingLeft: block.blockable?.paddingLeft || 0,
    paddingRight: block.blockable?.paddingRight || 0,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '50px',
  };

  const imageStyle = {
    width: image?.length > 0 ? `${imageScale * 100}%` : '150px',
    innerHeight: 'auto',
    maxWidth: '100%',
  };

  return (
    <div style={style}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img
          src={imageSrc}
          alt={imageAlt}
          style={imageStyle}
          draggable={false}
        />
      </div>
    </div>
  );
};

const sampleLogo =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKgAAAAqCAMAAAAQ7eBVAAAAwFBMVEVJUFj////S09X9/f12fIFQV16coKS7vsD29/fp6epNVFxiaG9udHry8vPX2NlpbnRLUlr19fWNkpZtcnlgZm3DxchVXGP7+/vq6+za292orK9YXmX5+fne3+Csr7KDh4xdY2qxtLeVmZ6JjpLg4uLU1dfQ0dTLzc90eX9bYWjt7e6/wcOkp6ugpKd9goh6f4VTWmHv7/CGio9rcXZmbHPm6Oi4u761uLuBhYpxd3zNz9HHycuRlZmanaHj4+TW19lks0yaAAADo0lEQVRYw+2UyXajMBBF6yEwGIcEHDB2e56neHY89vD/f9WSkLBDO4v0ok8vuBufKuHSpVSCcnJycnJycnJy/opK2R5FhVIad0xzQgmWaZrrW/rJJXo1JeepTIamuSPN1NS80F0BusNddkeX7soizbo8N8atSZpwz62LcdxWHniuqhB4Zf3oCGBq9xeA9W5pg9d7hiTefBPFvwE2aQrQtHTqCQDdmNQYOGz4qhLfiyrRSeJw1JBxc0lZ6h4Q/OKycV+JOwDsVBTvdRKcmBbV2F8WPQVQeOfkHzEUg72IX4tQNLaUoQw4bdo3gTFJuuBsOkpUt9SvIRWNCoXFGGD7P0WbBcnkoWi9CQSt5bLVAJqWKM/Fi2+r9pwBEY+tERAf2quFB8zCjOgbz015xWM/WVkHaMyAH6koAjEwfdxE5ZBUgW1W9BY8FH1jcOTEtgMwUWQOzEpyxTPEhkv+gGzLrggcMqX6YiLe2iVfxT2g1mN4XqeiWPALN8yK1oD+F0UNoJvM+4F7uOQ7wHeZsPbyxwbGli7106UPuO8QNIxdEnKBQmUDttKXicGbUi8Guxe1TjEw+VM0KEoeilb4TmeSbIHamvYApmRFRQl/bbF3+j+vQx8JL/Hd+PZjOGvxalcluomArlsEs7OXqfb5ZXosWgVKJDGBQUUu+mQZ0Oc2EE2QlICgRBmsp95hwJKN/THvebl8BLyXRLQ4acDjks3lR1FmhA9Ei7bkoajb1CdNP4CLSyGAV7LswWAQy0EaA9205bNK9nt/WpG/LjA0RHs9aN6UKEUA43XMVLQWRVGrzet8cUbF68rBmzaT8u/AnLe0Ujk7stkLoCr76F+BYabUlWHAF9sMjvo2Kd7XSnTfgPibFk2PR4lGrxJLikYlSedeNFmvE00CYLxz3SdeoyEOrMwQt/bkmjVgxOPODBiYll+KGHDKiC7ErW+1qsCFn84GiAqchQPWVqI053Xbn4rGjiRMLlNVcrwXdST80P0DAG/4HKjzokpNNMSoOTwtL28vBoLnoQfg6lOGCAmzCe8rwETvpdxQi5YCjN3PRDVSVDO/iWp6PLKODBJHjWLdUAlvq6YngIRFFmWxyr88Flftjjh5w1DNWI6M0ZRCw7BF18W3a2cYLVc+YlLKd0PDj7afBgVSvKSptpy95XjDmHedpJv3eH/Zxk5v+Hk+Y7zrJ58e4NbDTp3+DX49DOv3FtaUb+7fP9AJpxbl5OTk5OTk5Pxn/AbKRUhv2pG4LAAAAABJRU5ErkJggg==';
