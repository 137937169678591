import React from 'react';
import useCondition from '../../useCondition';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import {
  useHubspotDealPipelinesQuery,
  useHubspotDealPipelineStagesQuery,
} from 'api';

const HubspotDealFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {hubspotDealPipelines = []} = {}} =
    useHubspotDealPipelinesQuery();

  const {data: {hubspotDealPipelineStages = []} = {}} =
    useHubspotDealPipelineStagesQuery({
      variables: {
        hubspotDealPipelineId: condition?.hubspotDealPipelineId,
      },
      skip: !condition?.hubspotDealPipelineId,
    });

  const onChangePipeline = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCondition({
      hubspotDealPipelineId: e.target.value,
      hubspotDealPipelineStageIds: [],
    });

  const onChangeStages = (stages: string[]) =>
    setCondition({
      hubspotDealPipelineStageIds: stages,
    });

  return (
    <>
      <Header title="HubSpot取引" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">パイプライン</div>
            <select
              className="form-select border-c-border rounded text-base w-full"
              onChange={onChangePipeline}
              value={condition?.hubspotDealPipelineId}>
              <option value="" />
              {hubspotDealPipelines.map((pipeline) => (
                <option value={pipeline.id} key={pipeline.id}>
                  {pipeline.label}
                </option>
              ))}
            </select>
          </div>
          {condition?.hubspotDealPipelineId && (
            <div className="flex flex-col gap-2">
              <div className="text-c-base font-bold">ステージ</div>
              <CheckboxGroup
                options={hubspotDealPipelineStages.map((stage) => ({
                  text: stage.label,
                  value: stage.id,
                }))}
                values={condition.hubspotDealPipelineStageIds || []}
                onChange={onChangeStages}
              />
            </div>
          )}
        </div>
      </div>
      <Footer
        onClear={() =>
          setCondition({
            hubspotDealPipelineId: '',
            hubspotDealPipelineStageIds: [],
          })
        }
      />
    </>
  );
};

export default HubspotDealFilter;
