import React from 'react';

const Icon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
    <path
      d="M14.2498 14.4167C15.5385 14.4167 16.5832 13.372 16.5832 12.0833C16.5832 10.7947 15.5385 9.75 14.2498 9.75C12.9612 9.75 11.9165 10.7947 11.9165 12.0833C11.9165 13.372 12.9612 14.4167 14.2498 14.4167Z"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1665 20.25V17.9167C10.1665 17.2723 10.6888 16.75 11.3332 16.75H17.1665C17.8109 16.75 18.3332 17.2723 18.3332 17.9167V20.25"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.0835 12.6667V16.1667M18.3335 14.4167H21.8335"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
