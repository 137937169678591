import React from 'react';
import styled from 'styled-components';
import {Project} from 'api';
import {useHistory} from 'react-router';
import useProjectPath from 'hooks/useProjectPath';
import {Button, Popover} from 'components/antd';

type Props = {
  setIsCsvUploadModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCsvUploadWithInitializeDataModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsPopOverVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreatePreleadModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isPopoverVisible: boolean;
  project: Project;
};

export default ({
  setIsCsvUploadModalVisible,
  setIsCsvUploadWithInitializeDataModalVisible,
  setIsPopOverVisible,
  setIsCreatePreleadModalVisible,
  isPopoverVisible,
  project,
}: Props) => {
  const {createPath} = useProjectPath();
  const history = useHistory();

  if (Object.keys(project).length === 0) return null;

  return (
    <Title>
      <div>{project.name}</div>
      <Popover
        content={
          <PopoverContent>
            <PopoverItem
              onClick={() => {
                setIsPopOverVisible(false);
                setIsCreatePreleadModalVisible(true);
              }}>
              <div>個別登録</div>
            </PopoverItem>
            {(project.projectType === 'upload' || !project.projectType) && (
              <PopoverItem
                onClick={() => {
                  setIsPopOverVisible(false);
                  setIsCsvUploadModalVisible(true);
                }}>
                <div>CSVアップロード</div>
              </PopoverItem>
            )}
            {(project.projectType === 'upload' || !project.projectType) && (
              <PopoverItem
                onClick={() => {
                  setIsPopOverVisible(false);
                  setIsCsvUploadWithInitializeDataModalVisible(true);
                }}>
                <div>CSVアップロード(TELログ)</div>
              </PopoverItem>
            )}
            {!project.projectType &&
              !project.projectSearchCondition?.company && (
                <PopoverItem
                  onClick={() => {
                    setIsPopOverVisible(false);
                    history.push(
                      createPath(`projects/${project.uuid}/search/category`),
                    );
                  }}>
                  <div>企業データベースから追加</div>
                </PopoverItem>
              )}
            {!project.projectType && (
              <PopoverItem
                onClick={() => {
                  setIsPopOverVisible(false);
                  history.push(
                    createPath(`projects/${project.uuid}/specific_companies`),
                  );
                }}>
                <div>類似企業から追加</div>
              </PopoverItem>
            )}
          </PopoverContent>
        }
        trigger="click"
        visible={isPopoverVisible}
        onVisibleChange={(visible) => setIsPopOverVisible(visible)}
        placement="leftTop">
        <Button type="primary" style={{width: '160px', marginRight: '15px'}}>
          + プレリード を追加
        </Button>
      </Popover>
    </Title>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 42px;

  > div:first-child {
    font-size: 24px;
    font-weight: bold;
    color: #495058;
    line-height: 35px;
    margin-bottom: 8px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PopoverContent = styled.div`
  padding: 12px 0;
`;

const PopoverItem = styled.div`
  width: 252px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  cursor: pointer;

  &:hover {
    background: #f3f9ff;
    color: #68b5fb;
  }
`;
