import React from 'react';
import styled from 'styled-components';
import {StatusEclipse} from 'components/Ui';
import _ from 'lodash';
import {ArrowRight} from 'components/Ui/Icon';
import {ProjectMailStatusSummary, ProjectMailStatusCount} from 'api';
interface Props {
  projectMailStatusSummaryReport: ProjectMailStatusSummary;
  projectMailStatusCountReports: ProjectMailStatusCount[];
}

export default React.memo(
  ({projectMailStatusSummaryReport, projectMailStatusCountReports}: Props) => {
    const [displayCategories, setDisplayCategories] = React.useState([]);

    const parentCategory: any = [
      {
        status: 'mail',
        label: '送信済み',
        color: '#2D9CDB',
        count: projectMailStatusSummaryReport?.mailCount,
      },
      {
        status: 'error',
        label: '送信不可',
        color: '#BDC4CB',
        count: projectMailStatusSummaryReport?.errorCount,
      },
      {
        status: 'unsent',
        label: '未送信',
        color: '',
        count: projectMailStatusSummaryReport?.unsentCount,
      },
    ];

    const categorizedMailStatuses = (status: string) =>
      projectMailStatusCountReports.filter((mailStatus) =>
        status === 'mail'
          ? mailStatus.mailStatus === 'mail' || mailStatus.mailStatus === 'sent'
          : mailStatus.mailStatus !== 'mail' &&
            mailStatus.mailStatus !== 'sent' &&
            mailStatus.mailStatus !== 'unsent',
      );

    return (
      <Container>
        <h2>送信結果</h2>
        <Table>
          <div className="column far-left">
            {parentCategory.map((category: any) => (
              <React.Fragment key={category.status}>
                <div
                  className={
                    category.status !== 'unsent'
                      ? 'category openable'
                      : 'category'
                  }
                  onClick={() => {
                    if (displayCategories.includes(category.status)) {
                      setDisplayCategories(
                        displayCategories.filter(
                          (displayCategory) =>
                            displayCategory != category.status,
                        ),
                      );
                    } else {
                      setDisplayCategories([
                        ...displayCategories,
                        category.status,
                      ]);
                    }
                  }}>
                  {category.status !== 'unsent' && (
                    <Open
                      category={category.status}
                      displayCategories={displayCategories}>
                      <ArrowRight />
                    </Open>
                  )}
                  <StatusEclipse color={category.color} />
                  <span>{category.label}</span>
                </div>
                {category.status !== 'unsent' &&
                  displayCategories.includes(category.status) &&
                  categorizedMailStatuses(category.status).map((mailStatus) => (
                    <div key={mailStatus.mailStatus}>
                      <span>{mailStatus.mailStatusText}</span>
                    </div>
                  ))}
              </React.Fragment>
            ))}
          </div>

          <div className="column">
            {parentCategory.map((category: any) => (
              <React.Fragment key={category.status}>
                <div className="category">
                  <span>{category.count}</span>
                </div>
                {category.status !== 'unsent' &&
                  displayCategories.includes(category.status) &&
                  categorizedMailStatuses(category.status).map((mailStatus) => (
                    <div key={mailStatus.mailStatus}>
                      <span>{mailStatus.count}</span>
                    </div>
                  ))}
              </React.Fragment>
            ))}
          </div>
        </Table>
      </Container>
    );
  },
);

const Container = styled.div`
  > h2 {
    font-weight: bold;
    font-size: 20px;
    color: #495058;
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  width: 407px;

  .column {
    flex: 1;

    > div {
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;
      display: flex;
      align-items: center;

      > span {
        margin: 0 9px 0 auto;
        color: #899098;
      }
      &.category > span {
        color: #495058;
        font-weight: bold;
      }
    }

    &:last-child > div {
      border-right: none;
    }

    &.far-left > div {
      width: 272px;
      padding: 0 40px;

      &.category {
        padding: 0 13px;
        svg {
          margin-right: 5px;
        }
        span {
          font-weight: bold;
        }
      }

      &.openable {
        cursor: pointer;
      }

      > span {
        margin: 0;
        font-size: 13px;
      }
    }

    > div:last-child {
      padding-left: 25px;
    }
  }
`;

const Open = styled.div<{category: string; displayCategories: any}>`
  width: 13px;
  height: 13px;

  > svg {
    ${({category, displayCategories}) =>
      displayCategories.includes(category) && 'transform: rotate(90deg);'}
    width: 13px;
    height: 13px;
    fill: #bdc4cb;

    path {
      width: 13px;
      height: 13px;
    }
  }
`;
