import React from 'react';

const Icon = () => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.75 3.50004C1.75 2.85571 2.27233 2.33337 2.91667 2.33337H11.0833C11.7277 2.33337 12.25 2.85571 12.25 3.50004V11.6667C12.25 12.3111 11.7277 12.8334 11.0833 12.8334H2.91667C2.27233 12.8334 1.75 12.3111 1.75 11.6667V3.50004Z"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.75 5.83337H12.25"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33337 1.16663V3.49996"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66663 1.16663V3.49996"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4993 15.25C16.5703 15.25 18.2493 13.5711 18.2493 11.5C18.2493 9.42893 16.5703 7.75 14.4993 7.75C12.4282 7.75 10.7493 9.42893 10.7493 11.5C10.7493 13.5711 12.4282 15.25 14.4993 15.25Z"
      fill="#BDC4CB"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8491 11.4999L13.9366 12.5874L16.1491 10.3749"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
