import gql from 'graphql-tag';

export default gql`
  fragment hubspotDealPropertyDefinitionFragment on HubspotDealPropertyDefinition {
    id
    archivable
    calculated
    description
    externalOptions
    fieldTypeString
    propertyTypeString
    formField
    groupName
    hidden
    hubspotDefined
    label
    name
    options {
      label
      value
      hidden
      displayOrder
    }
    readOnlyDefinition
    readOnlyValue
    isDisplay
    isRequired
    labelText
    createdAt
    updatedAt
  }
`;
