import React from 'react';
import UserSelect from './UserSelect';

export default () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-c-base font-bold">所有者</div>
      <UserSelect />
    </div>
  );
};
