import React from 'react';
import {useProspectPoolsByAccountPoolQuery} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {accountId} = useParams<{
    accountId: string;
  }>();
  const {data: {prospectPoolsByAccountPool = []} = {}} =
    useProspectPoolsByAccountPoolQuery({
      variables: {
        uuid: accountId,
      },
    });

  return (
    <span className="text-base">{`全${prospectPoolsByAccountPool.length}件`}</span>
  );
};
