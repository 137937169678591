import React from 'react';
import styled from 'styled-components';
import {Lense} from 'components/Ui/Icon';
import {Input, Tooltip} from 'components/antd';
import useSearchParams from '../useSearchParams';

export default () => {
  const {query, setQuery} = useSearchParams();

  const searchProjects = (e: any) => {
    setQuery({
      searchWord: e.target.value,
      page: 1,
    });
  };

  return (
    <Tooltip
      placement="top"
      title="社名・住所・電話番号・URL・メールアドレス"
      color={'rgba(0, 0, 0, 0.8)'}
      overlayInnerStyle={{width: '190px'}}>
      <Search
        type="search"
        name="search"
        prefix={<Lense />}
        onPressEnter={searchProjects}
        defaultValue={query.searchWord}
      />
    </Tooltip>
  );
};

const Search = styled(Input)`
  width: 230px;
  height: 31px;
  border-radius: 80px;
  .ant-input {
    font-size: 14px;
  }

  &:focus-within {
    background-color: white;
  }

  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;
