import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {
  useSalesforceLeadRecordTypesQuery,
  SalesforceLeadField,
  useSalesforceLeadRecordTypeByRecordTypeIdQuery,
} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();

  const {data: {salesforceLeadRecordTypes = []} = {}} =
    useSalesforceLeadRecordTypesQuery({
      variables: {
        poolId: poolId,
      },
    });

  if (!query?.salesforceLeadRecordTypeId) {
    return null;
  }

  const targetSalesforceLeadRecordType = () => {
    return salesforceLeadRecordTypes?.find(
      (salesforceLeadRecordType) =>
        salesforceLeadRecordType.id === query.salesforceLeadRecordTypeId,
    );
  };

  const customizeItemById = (id: string): SalesforceLeadField => {
    return targetSalesforceLeadRecordType()?.salesforceLeadFields.find(
      (field) => field.id === id,
    );
  };

  const salesforceLeadStatuses = () => {
    return (
      targetSalesforceLeadRecordType()?.salesforceLeadFields?.find(
        (salesforceLeadField: SalesforceLeadField) =>
          salesforceLeadField.fieldName === 'Status',
      )?.salesforceLeadFieldSelectOptions || []
    );
  };

  const selectedLeadStatauses = salesforceLeadStatuses()?.filter((item) =>
    query.salesforceLeadFieldSelectStatusIds.includes(item.id),
  );

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">Salesforce リード</h4>
        <button
          onClick={() => setFilterType('salesforceLead')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              salesforceLeadRecordTypeId: '',
              salesforceLeadSearchConditions: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1 flex-col">
        <div className="flex items-center gap-2">
          <span className="text-c-light">レコードタイプ：</span>
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {targetSalesforceLeadRecordType()?.name}
          </span>
        </div>
      </div>
      <div className="flex gap-1 flex-col">
        {selectedLeadStatauses?.length > 0 && (
          <div className="flex items-center gap-2">
            <span className="text-c-light">状況：</span>
            {selectedLeadStatauses?.map((status) => {
              return (
                <span
                  className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2"
                  key={status.id}>
                  {status.label}
                </span>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex flex-wrap flex-col gap-2">
        {query?.salesforceLeadSearchConditions.map((item, index) => {
          return (
            <div key={index} className="flex gap-3">
              {customizeItemById(item['lead_field_id'])?.fieldType ===
                'checkbox' && (
                <>
                  <div className="text-[#899098] text-sm min-w-28 text-center truncate">
                    {(item['value'] === 'true' || item['value'] === 'false') &&
                      `${customizeItemById(item['lead_field_id'])?.label}:`}
                  </div>
                  <div className="flex flex-wrap gap-1">
                    {(item['value'] === 'true' ||
                      item['value'] === 'false') && (
                      <div
                        key={index}
                        className="min-w-16 h-5 bg-[#F3F5F7] border border-[#E2E6EA] text-center truncate text-sm px-2 rounded-sm">
                        {item['value'] === 'true' ? 'あり' : 'なし'}
                      </div>
                    )}
                  </div>
                </>
              )}
              {customizeItemById(item['lead_field_id'])?.fieldType ===
                'select' && (
                <>
                  <div className="text-[#899098] text-sm min-w-28 text-center truncate">
                    {item['field_select_option_id'] &&
                      `${customizeItemById(item['lead_field_id'])?.label}:`}
                  </div>
                  <div className="flex flex-wrap gap-1">
                    {
                      <div
                        key={index}
                        className="min-w-16 h-5 bg-[#F3F5F7] border border-[#E2E6EA] text-center truncate text-sm px-2 rounded-sm">
                        {
                          customizeItemById(
                            item['lead_field_id'],
                          )?.salesforceLeadFieldSelectOptions?.find(
                            (option) =>
                              option.id === item['field_select_option_id'],
                          )?.label
                        }
                      </div>
                    }
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
