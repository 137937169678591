import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useProjectUserPreleadTelStatusReportsQuery} from 'api';
import {useTable} from 'react-table';
import {StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';
import useSearchParams from '../useSearchParams';
import {Download} from 'components/Ui/Icon';
import DownloadModal from './PreleadReportDownloadModal';
import {DownloadButton} from '../../style';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const {searchParams} = useSearchParams();
  const {data: {projectUserPreleadTelStatusReports = []} = {}} =
    useProjectUserPreleadTelStatusReportsQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'cache-and-network',
    });

  const totalCountPerStatus = (status: string) =>
    projectUserPreleadTelStatusReports.reduce(
      (total, report: any) => total + report[status],
      0,
    );

  const totalCountRowData = useMemo(() => {
    const call = totalCountPerStatus('call');
    const reception = totalCountPerStatus('reception');
    const contact = totalCountPerStatus('contact');
    const document = totalCountPerStatus('document');
    const other = totalCountPerStatus('other');
    const appoint = totalCountPerStatus('appoint');
    const callNg = totalCountPerStatus('callNg');
    const receptionNg = totalCountPerStatus('receptionNg');
    const contactNg = totalCountPerStatus('contactNg');

    return {
      isTotalCountRowData: true,
      call: call,
      reception: reception,
      contact: contact,
      document: document,
      other: other,
      appoint: appoint,
      callNg: callNg,
      receptionNg: receptionNg,
      contactNg: contactNg,
      total:
        call +
        reception +
        contact +
        document +
        other +
        appoint +
        callNg +
        receptionNg +
        contactNg,
    };
  }, [projectUserPreleadTelStatusReports]);

  const data: any = useMemo(
    () =>
      projectUserPreleadTelStatusReports.map((report: any) => {
        return {
          userName: `${report.lastName ? report.lastName + ' ' : ''}${
            report.firstName || ''
          }`,
          call: report.call,
          reception: report.reception,
          contact: report.contact,
          document: report.document,
          other: report.other,
          appoint: report.appoint,
          callNg: report.callNg,
          receptionNg: report.receptionNg,
          contactNg: report.contactNg,
          total: report.total,
        };
      }),
    [projectUserPreleadTelStatusReports],
  );

  data.unshift(totalCountRowData);

  const columns: any = useMemo(
    () => [
      {
        Header: '',
        id: 'userName',
        accessor: (row: any) =>
          row.isTotalCountRowData ? (
            <UserNameCell>
              <div>コール件数</div>
              <div>{row.total}</div>
            </UserNameCell>
          ) : (
            <UserNameCell>
              <div>{row.userName}</div>
              <div>{row.total}</div>
            </UserNameCell>
          ),
      },
      {
        id: 'call',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('call')} />
            コール
          </>
        ),
        accessor: (row: any) => <CountCell>{row.call}</CountCell>,
      },
      {
        id: 'reception',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('reception')} />
            受付
          </>
        ),
        accessor: (row: any) => <CountCell>{row.reception}</CountCell>,
      },
      {
        id: 'contact',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('contact')} />
            キーマン接触
          </>
        ),
        accessor: (row: any) => <CountCell>{row.contact}</CountCell>,
      },
      {
        id: 'document',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('document')} />
            資料送付
          </>
        ),
        accessor: (row: any) => <CountCell>{row.document}</CountCell>,
      },
      {
        id: 'other',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('other')} />
            その他
          </>
        ),
        accessor: (row: any) => <CountCell>{row.other}</CountCell>,
      },
      {
        id: 'appoint',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('appoint')} />
            アポ獲得
          </>
        ),
        accessor: (row: any) => <CountCell>{row.appoint}</CountCell>,
      },
      {
        id: 'callNg',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('call_ng')} />
            コールNG
          </>
        ),
        accessor: (row: any) => <CountCell>{row.callNg}</CountCell>,
      },
      {
        id: 'receptionNg',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('reception_ng')} />
            受付NG
          </>
        ),
        accessor: (row: any) => <CountCell>{row.receptionNg}</CountCell>,
      },
      {
        id: 'contactNg',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('contact_ng')} />
            キーマン接触NG
          </>
        ),
        accessor: (row: any) => <CountCell>{row.contactNg}</CountCell>,
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <>
      <Title>カテゴリ別</Title>
      <DownloadButton onClick={() => setModalOpen(true)}>
        <Download />
        ダウンロード
      </DownloadButton>
      <TableWrapper>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <th {...column.getHeaderProps()} key={j}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps()} key={j}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>

        {isModalOpen && (
          <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
        )}
      </TableWrapper>
    </>
  );
};

const Title = styled.div`
  margin-bottom: 17px;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #495058;
`;

const TableWrapper = styled.div`
  border: 1px solid #e1e6eb;
  height: 610px;
  overflow: scroll;
  position: relative;
  top: -40px;
`;

const Table = styled.table`
  width: 1560px;
  border-bottom: 1px solid #e1e6eb;

  thead {
    background: #ffffff;
    position: sticky;
    top: 0;
    z-index: 3;

    tr {
      th {
        &:first-child {
          border-right: double 3px #e1e6eb;
          background: #ffffff;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 3;
        }

        border-right: 1px solid #e1e6eb;
        height: 45px;
        width: 145px;
        font-weight: bold;
        font-size: 13px;

        &:first-child {
          width: 258px;
        }

        svg {
          margin-right: 6px;
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        border-bottom: double 3px #e1e6eb;
        background: #ffffff;
        position: sticky;
        top: 45px;
        z-index: 2;

        td:first-child {
          background: #ffffff;
          position: sticky;
          top: 45px;
          z-index: 2;
        }
      }

      &:nth-child(n + 3) {
        border-right: 1px solid #e1e6eb;
        border-top: 1px solid #e1e6eb;
      }

      td {
        &:first-child {
          border-right: double 3px #e1e6eb;
          background: #ffffff;
          position: sticky;
          left: 0;
          z-index: 1;
        }

        border-right: 1px solid #e1e6eb;
        height: 45px;
        font-weight: 500;
        font-size: 12px;
        color: #495058;
      }
    }
  }
`;

const CountCell = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  padding-right: 10px;
`;

const UserNameCell = styled.div`
  margin-left: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;

  div:first-child {
    display: inline-block;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 13px;
    color: #495058;
  }
`;
