import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
      <g clipPath="url(#clip0_16152:218250)">
        <path
          d="M14.5 20.6875C17.2614 20.6875 19.5 18.4489 19.5 15.6875C19.5 12.9261 17.2614 10.6875 14.5 10.6875C11.7386 10.6875 9.5 12.9261 9.5 15.6875C9.5 18.4489 11.7386 20.6875 14.5 20.6875Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.1875 12.4375C7.25 11.5 7.25 10.0625 8.1875 9.125C9.125 8.1875 10.5625 8.1875 11.5 9.125L8.1875 12.4375Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 9.125C18.4375 8.1875 19.875 8.1875 20.8125 9.125C21.75 10.0625 21.75 11.5 20.8125 12.4375L17.5 9.125Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 13.1875V15.625L15.75 16.875"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 20.75L18 19.25"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 20.75L11 19.25"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16152:218250">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
