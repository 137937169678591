import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import MailFormButton from './MailFormButton';
import DocumentButton from './DocumentButton';
import MailTemplateButton from './MailTemplateButton';

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '890px',
    padding: '0',
  },
};

export default () => {
  const history = useHistory();
  const closeModal = () => history.push(`/garage/contents`);

  return (
    <Modal
      isOpen
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="modal">
      <Container>
        <h3>新規作成</h3>
        <Content>
          <MailTemplateButton />
          <MailFormButton />
          <DocumentButton />
        </Content>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 50px 80px;

  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 16px;

  > * {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    border: solid 1px #bdc4cb;
    background: #fff;
    margin: 1rem;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      width: 3rem;
      height: auto;
      margin: 2rem;
    }

    p {
      text-align: center;
      font-weight: bold;
    }

    .inactive {
      display: block;
    }
    .active {
      display: none;
    }

    &:hover {
      background-color: #f5faff;

      p {
        color: #68b5fb;
      }

      .inactive {
        display: none;
      }
      .active {
        display: block;
      }
    }
  }
`;
