import React from 'react';
import AutomaticMailForm from './AutomaticMail';
import CallForm from './Call';
import ManualMail from './ManualMail';
import Task from './Task';

interface Props {
  stepType: string;
  onFinish: () => void;
}

const Form = ({stepType, onFinish}: Props) => {
  if (stepType === 'automaticMail') {
    return <AutomaticMailForm onFinish={onFinish} />;
  } else if (stepType === 'manualMail') {
    return <ManualMail onFinish={onFinish} />;
  } else if (stepType === 'call') {
    return <CallForm onFinish={onFinish} />;
  } else if (stepType === 'task') {
    return <Task onFinish={onFinish} />;
  }

  return null;
};

export default Form;
