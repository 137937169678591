import React from 'react';
import Deal from './Deal';
import Account from './Account';
import Contact from './Contact';
import Lead from './Lead';
import {Switch, Route} from 'react-router-dom';
import {ProspectPool} from 'api';
import SlideInPages from './SlideInPages';

interface Props {
  prospectPool: ProspectPool;
}

const Content = ({prospectPool}: Props) => {
  return (
    <div className="relative w-full flex-1 overflow-scroll">
      <div className="w-full px-8 py-6">
        <Switch>
          <Route path={['/deals/:dealId', '/deals']}>
            <Deal prospectPool={prospectPool} />
          </Route>
          <Route path={['/account']}>
            <Account prospectPool={prospectPool} />
          </Route>
          <Route path={['/contact']}>
            <Contact prospectPool={prospectPool} />
          </Route>
          <Route path={['/lead']}>
            <Lead prospectPool={prospectPool} />
          </Route>
        </Switch>
      </div>
      <SlideInPages prospectPool={prospectPool} />
    </div>
  );
};

export default Content;
