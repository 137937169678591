import Status from './Status';
import Name from './Name';
import Count from './Count';
import Summary from './Summary';
import CreatedAt from './CreatedAt';
import Remind from './Remind';
import MailCampaign from './MailCampaign';

export default {
  Status,
  Name,
  Remind,
  Count,
  Summary,
  CreatedAt,
  MailCampaign,
};
