import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import Tag from './Tag';
import Contact from './Contact';
import Integration from './Integration';
import PressRelease from './PressRelease';
import {Item} from './Styles';
import Reminder from './Reminder';
import Assignee from './Assignee';
import Memo from './Memo';
import useProject from 'hooks/useProject';
import ToSalesforce from './ToSalesforce';
import {useProjectQuery} from 'api';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();
  if (!preleadProjectId) return null;

  const {projectId} = useParams<{projectId: string}>();

  const {isOwnProject} = useProject(projectId);

  const {data: {project} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const pool = project?.projectGroup?.pool;
  const salesforceSetting = pool?.poolSalesforceSetting;

  return (
    <Container>
      {isOwnProject && (
        <Item>
          <div>
            <span>企業担当者</span>
          </div>
          <div>
            <Assignee />
          </div>
        </Item>
      )}
      <Item>
        <div>
          <span>プレスリリース</span>
        </div>
        <div>
          <PressRelease />
        </div>
      </Item>
      <Item>
        <div>
          <span>メモ</span>
        </div>
        <div>
          <Memo />
        </div>
      </Item>
      <Item>
        <div>
          <span>API</span>
        </div>
        <div>
          <Integration />
        </div>
      </Item>
      {salesforceSetting?.isActive && (
        <Item>
          <div>
            <span>Salesforce</span>
          </div>
          <div>
            <ToSalesforce />
          </div>
        </Item>
      )}
      <Item>
        <div>
          <span>タグ</span>
        </div>
        <div>
          <Tag />
        </div>
      </Item>
      <Item>
        <div>
          <span>連絡先</span>
        </div>
        <div>
          <Contact />
        </div>
      </Item>
      <Item>
        <div>
          <span>リマインド</span>
        </div>
        <div>
          <Reminder />
        </div>
      </Item>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
