import React from 'react';
import ListItem from './Item';
import NewModal from './NewModal';
import {useFormsQuery} from 'api';
import ListHeader from '../../ListHeader';
import SearchAction from './SearchAction';
import useSearchParams from '../useSearchParams';

export default () => {
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const {searchWord} = useSearchParams();
  const {data: {forms: {forms = []} = {}} = {}} = useFormsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      searchWord: searchWord,
    },
  });

  return (
    <div>
      <ListHeader
        newButtonText="+ フォームを作成"
        onNewButtonClick={() => setNewModalVisible(true)}
      />
      <SearchAction />
      <div className="flex flex-col gap-2">
        {forms.map((form) => (
          <ListItem key={form.uuid} form={form} />
        ))}
      </div>
      <NewModal
        visible={newModalVisible}
        onClose={() => setNewModalVisible(false)}
      />
    </div>
  );
};
