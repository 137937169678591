import gql from 'graphql-tag';

export default gql`
  fragment approachGroupPreleadProjectFragment on ApproachGroupPreleadProject {
    id
    title
    approachType
    priority
    startDate
    dueDate
  }
`