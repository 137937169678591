import gql from 'graphql-tag';

export default gql`
  fragment formBlockPersonalInformationFragment on FormBlockPersonalInformation {
    id
    text
    textHtml
    createdAt
    updatedAt
  }
`;
