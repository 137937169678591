import React from 'react';
import styled from 'styled-components';
import ProjectGroupList from './ProjectGrouplList';
import NewProjectGroup from './NewProjectGroup';
import Detail from './Detail';
import {Header, Container, Breadcrumb} from 'components/Ui';
import {useHistory} from 'react-router-dom';
import {Button} from 'components/antd';
import {ConnectButton} from 'components/Ui';

export default () => {
  const history = useHistory();

  const handleClickNew = () => {
    history.push(`/list/groups/new${location.search}`);
  };

  return (
    <Container page="group">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <span className="current">グループ</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Body>
        <div className="flex">
          <h1>グループ一覧</h1>
          <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <ConnectButton />
            <Button type="primary" onClick={handleClickNew}>
              ＋ 新規作成
            </Button>
          </div>
        </div>
        <ProjectGroupList />
      </Body>
      <NewProjectGroup />
      <Detail />
    </Container>
  );
};

const Body = styled.div`
  grid-area: body;
  display: flex;
  flex-direction: column;
  background: #f3f5f7;
  padding: 0px 32px 12px 0px;
  overflow: scroll;

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align:middle
  }

  .flex > h1{
    margin 1rem 0 1rem 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #222426;
  }
`;
