import gql from 'graphql-tag';

export default gql`
  fragment jsonFragment on Json {
    id
    type
    status
    filename
    currentRowNumber
    rowCount
    createdAtText
    errorRowNumber
  }
`;
