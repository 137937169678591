import {useParams} from 'react-router-dom';
import {
  useWorkflowQuery,
  WorkflowSearchConditionAttributes,
  useUpdateWorkflowSearchConditionMutation,
  useWorkflowProspectPoolCountsByStatusQuery,
} from 'api';

export default () => {
  const {workflowId} = useParams<{
    workflowId: string;
  }>();

  const {data: {workflow: {searchCondition = {}, status}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      skip: !workflowId,
    });

  const {data: {workflowProspectPoolCountsByStatus: {total = 0} = {}} = {}} =
    useWorkflowProspectPoolCountsByStatusQuery({
      variables: {
        uuid: workflowId,
      },
      fetchPolicy: 'cache-and-network',
    });

  const [update] = useUpdateWorkflowSearchConditionMutation({
    refetchQueries: ['workflowProspectPoolCountsByStatus'],
  });

  const isUpdatable = status === 'draft';

  const setCondition = (condition: WorkflowSearchConditionAttributes) =>
    update({
      variables: {
        uuid: workflowId,
        attributes: condition,
      },
    });

  const clearCondition = () =>
    setCondition({
      leadSources: [],
      originalLeadSources: [],
      inflowDateType: 'latest',
      inflowDateFrom: null,
      inflowDateTo: null,
      stages: [],
      ranks: [],
      tags: [],
      excludedTags: [],
      prospectCustomizeItemSearchConditions: [],
      isEmail: null,
      monthsSinceAction: null,
      collectedFormUrls: [],
      forms: [],
      cities: [],
      subCategories: [],
      listingMarkets: [],
      employeeNumber: {},
      capitalFund: {},
      hasSalesforceLead: false,
      hasSalesforceContact: false,
      hasSalesforceDeal: false,
      salesforceLeadRecordTypeId: null,
      salesforceLeadOwnerIds: [],
      salesforceLeadSearchConditions: [],
      salesforceLeadFieldSelectStatusIds: [],
      salesforceContactRecordTypeId: null,
      salesforceContactOwnerIds: [],
      salesforceContactSearchConditions: [],
      salesforceDealRecordTypeId: null,
      salesforceDealSearchConditions: [],
      hubspotDealSearchConditions: [],
      users: [],
      clientPhaseProspects: [],
      trackedAtFrom: null,
      trackedAtTo: null,
    });

  const numberOfLeadFiltersInUse = [
    searchCondition.leadSources?.length > 0,
    searchCondition.originalLeadSources?.length > 0,
    searchCondition.stages?.length > 0,
    searchCondition.ranks?.length > 0,
    searchCondition.tags?.length > 0,
    searchCondition.excludedTags?.length > 0,
    searchCondition.prospectCustomizeItemSearchConditions?.length > 0,
    searchCondition.isEmail !== null,
    searchCondition.monthsSinceAction !== null,
    searchCondition.users?.length > 0,
    searchCondition.inflowDateFrom || searchCondition.inflowDateTo,
    searchCondition.trackedAtFrom || searchCondition.trackedAtTo,
    searchCondition.clientPhaseProspects?.length > 0,
  ].filter(Boolean).length;

  const numberOfFormFiltersInUse = [
    searchCondition.collectedFormUrls?.length > 0,
    searchCondition.forms?.length > 0,
  ].filter(Boolean).length;

  const numberOfCompanyFiltersInUse = [
    searchCondition.cities?.length > 0,
    searchCondition.subCategories?.length > 0,
    searchCondition.listingMarkets?.length > 0,
    searchCondition.employeeNumber?.from || searchCondition?.employeeNumber?.to,
    searchCondition.capitalFund?.from || searchCondition?.capitalFund?.to,
  ].filter(Boolean).length;

  const numberOfHubspotFiltersInUse = [
    searchCondition.hubspotDealSearchConditions?.length > 0,
  ].filter(Boolean).length;

  const numberOfSalesforceFiltersInUse = [
    searchCondition.salesforceLeadRecordTypeId ||
      searchCondition.hasSalesforceLead ||
      searchCondition.salesforceLeadFieldSelectStatusIds?.length > 0 ||
      searchCondition.salesforceLeadOwnerIds?.length > 0 ||
      searchCondition.salesforceLeadSearchConditions?.length > 0,
    searchCondition.salesforceContactRecordTypeId ||
      searchCondition.hasSalesforceContact ||
      searchCondition.salesforceContactOwnerIds?.length > 0 ||
      searchCondition.salesforceContactSearchConditions?.length > 0,
    searchCondition.salesforceDealRecordTypeId ||
      searchCondition.hasSalesforceDeal ||
      searchCondition.salesforceDealSearchConditions?.length > 0,
  ].filter(Boolean).length;

  const numberOfFiltersInUse =
    numberOfLeadFiltersInUse +
    numberOfFormFiltersInUse +
    numberOfCompanyFiltersInUse +
    numberOfHubspotFiltersInUse +
    numberOfSalesforceFiltersInUse;

  return {
    count: total,
    condition: searchCondition,
    isUpdatable,
    setCondition,
    clearCondition,
    numberOfFiltersInUse,
    numberOfLeadFiltersInUse,
    numberOfFormFiltersInUse,
    numberOfCompanyFiltersInUse,
    numberOfHubspotFiltersInUse,
    numberOfSalesforceFiltersInUse,
  };
};
