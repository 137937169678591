import React from 'react';
import {useMaintenanceProspectPoolCountsQuery} from 'api';
import {Button, Tooltip} from 'antd';
import Info from 'components/Ui/Icon/Info';

export default () => {
  const {data: {maintenanceProspectPoolCounts} = {}} =
    useMaintenanceProspectPoolCountsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const toolTipTitle = `
    重複判定で検出された名寄せ対象の件数です。
    名寄せ対象から除外されたリードは含まれていません。

    【名寄せ対象外】
    ・Salesforce取引先責任者→Salesforceリード
    ・Salesforce取引先責任者→LEADPAD
    ・Salesforceリード→LEADPAD
    ・Salesforceレコードタイプが異なるリード`;

  return (
    <div className="border border-c-border rounded py-4 px-6 flex gap-6">
      <div className="flex-1">
        <div className="flex items-center">
          <div className="mr-4 min-w-[142px]">
            <div className="h-8 text-neutral-400 text-sm">
              名寄せ対象
              <Tooltip
                overlayStyle={{
                  whiteSpace: 'pre-line',
                }}
                overlayInnerStyle={{
                  width: '324px',
                }}
                title={toolTipTitle}
                color={'rgb(0 0 0 / 50%)'}
                placement="top">
                <Button type="link" shape="circle" icon={<Info />} />
              </Tooltip>
            </div>
            <div className="text-zinc-600 text-[32px] font-bold inline-block mr-2">
              {maintenanceProspectPoolCounts?.targetFromCount.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
