import Action from './Action';
import Priority from './Priority';
import Assignee from './Assignee';
import DueDate from './DueDate';
import ApproachType from './ApproachType';
import Target from './Target';
import LastProspectActivity from './LastProspectActivity';

export default {
  Action,
  Priority,
  Assignee,
  DueDate,
  ApproachType,
  Target,
  LastProspectActivity,
};
