import React from 'react';

const Icon = ({color = '#BDC4CB'}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0833 6.41797H2.91667C2.27233 6.41797 1.75 6.9403 1.75 7.58464V11.668C1.75 12.3123 2.27233 12.8346 2.91667 12.8346H11.0833C11.7277 12.8346 12.25 12.3123 12.25 11.668V7.58464C12.25 6.9403 11.7277 6.41797 11.0833 6.41797Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.08203 6.41798V4.08464C4.08203 2.4738 5.38787 1.16797 6.9987 1.16797C8.60951 1.16797 9.91536 2.4738 9.91536 4.08464V6.41798"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
