import Status from './Status';
import Title from './Title';
import Date from './Date';
import Action from './Action'

export default {
  Status,
  Title,
  Date,
  Action,
};
