import React from 'react';
import {FormBlock, FormBlockFieldOther} from 'api';
import InputField from './Input';
import CheckboxField from './Checkbox';
import RadioField from './Radio';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
}

const otherFieldComponents: {[key: string]: any} = {
  input: InputField,
  textarea: InputField,
  checkbox: CheckboxField,
  radio: RadioField,
};

export default ({block}: Props) => {
  const blockable = block.formBlockable;
  const FieldComponent = otherFieldComponents[blockable.fieldType];

  if (!FieldComponent) return null;

  return <FieldComponent block={block} />;
};
