import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import useMonthHeaders from '../../hooks/useMonthHeaders';
import {useQueryParams, StringParam} from 'use-query-params';
import {useFormReportsQuery, useFormSumReportQuery} from 'api';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const headers: ReportTableHeader[] = [...useMonthHeaders(query.month)];
  const {data: {formSumReport = null} = {}} = useFormSumReportQuery({
    variables: {month: query.month},
    fetchPolicy: 'cache-and-network',
  });
  const {data: {formReports = []} = {}} = useFormReportsQuery({
    variables: {month: query.month},
    fetchPolicy: 'cache-and-network',
  });
  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const data: ReportTableData[] = [formSumReport, ...formReports]
    .filter(Boolean)
    .map((report) => {
      return {
        label: report.name,
        values: [sum(report.values), ...report.values],
      };
    });

  return {
    data,
    headers,
  };
};

export default useTableData;
