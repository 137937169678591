import gql from 'graphql-tag';

export default gql`
  fragment preleadProjectCreateProspectActivityFragment on PreleadProjectCreateProspectActivity {
    id
    preleadProject {
      uuid
      project {
        name
        uuid
      }
    }
  }
`;
