import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Select, Form, Input} from 'components/antd';
import {useCreateTelStatusMutation} from 'api';
import StatusEclipse from 'components/Ui/StatusEclipse';
import {telStatusCategoryOptions} from './telStatusCategoryOptions';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const New = ({visible, onClose}: Props) => {
  const [createTelStatus] = useCreateTelStatusMutation({
    refetchQueries: ['currentClient'],
    onCompleted: () => onClose(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      category: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      category: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values, {resetForm}) => {
      createTelStatus({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
      resetForm();
    },
  });

  return (
    <Modal
      destroyOnClose
      visible={visible}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>ステータスの新規作成</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="ステータス名" required>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="ステータスのカテゴリ" required>
            <Select
              value={formik.values.category}
              onSelect={(value: any) => formik.setFieldValue('category', value)}
              dropdownMatchSelectWidth={false}
              dropdownClassName="tel-status-dropdown"
              size="large"
              optionLabelProp="label">
              {telStatusCategoryOptions.map((option) => (
                <Select.Option
                  value={option.value}
                  key={option.value}
                  disabled={option.isImmutable}
                  label={
                    <>
                      <StatusEclipse color={option.color} />
                      &nbsp; {option.text}
                    </>
                  }>
                  <StatusEclipse color={option.color} />
                  &nbsp; {option.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="ステータスの説明">
            <Input.TextArea
              name="description"
              autoSize={{minRows: 3}}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!formik.isValid}>
              作成
            </Button>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default New;
