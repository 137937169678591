import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {
  Modal,
  Input,
  Form,
  DatePicker,
  Select,
  Radio,
  Button,
} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useProjectQuery,
  useCreateSalesforceAccountPreleadMutation,
  useSalesforceUsersQuery,
  useSalesforceAccountsQuery,
  SalesforceAccount,
  Prelead,
} from 'api';
import AccountSelect from './AccountSelect';

interface Props {
  visible: boolean;
  onClose: () => void;
  prelead: Prelead;
}

const NewAccountPreleadModalComponent = ({
  visible,
  onClose,
  prelead,
}: Props) => {
  const [showAccountSelect, setShowAccountSelect] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const {projectId} = useParams<{projectId: string}>();

  const {data: {project} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const pool = project?.projectGroup?.pool;

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: pool?.uuid},
    skip: !pool,
  });

  const [create, {data, loading}] = useCreateSalesforceAccountPreleadMutation({
    onCompleted: (data: any) => {
      if (data.createSalesforceAccountPrelead.success) {
        formik.resetForm();
        onClose();
      }
    },
    refetchQueries: ['preleadProject'],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      createAccount: true,
      accountName: prelead?.name || '',
      salesforceUserId: salesforceUsers.length > 0 ? salesforceUsers[0].id : '',
      salesforceAccountId: '',
      preleadId: prelead.id,
      projectId: project.id,
    },
    validationSchema: Yup.object().shape({
      createAccount: Yup.boolean(),
      accountName: Yup.string().when('createAccount', {
        is: true,
        then: (schema) =>
          schema.required(
            '新規取引先を入力、もしくは既存の取引先を選択してください',
          ),
      }),
      salesforceAccountId: Yup.string().when('createAccount', {
        is: false,
        then: (schema) =>
          schema.required(
            '新規取引先を入力、もしくは既存の取引先を選択してください',
          ),
      }),
      salesforceUserId: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) => {
      const copy = Object.assign({}, values);
      if (copy.createAccount) {
        copy.salesforceAccountId = null;
      } else {
        copy.accountName = null;
      }
      delete copy.createAccount;

      create({
        variables: {
          preleadUuid: prelead.uuid,
          projectGroupUuid: project?.projectGroup.uuid,
          attributes: copy,
        },
      });
    },
  });

  const {data: {salesforceAccounts: {pagination = {totalCount: 0}} = {}} = {}} =
    useSalesforceAccountsQuery({
      variables: {
        uuid: pool?.uuid,
        page: 1,
        perPage: 20,
        search: {keyword: formik.values.accountName},
      },
    });

  const onOk = React.useCallback(() => formik.submitForm(), []);

  const onCancel = React.useCallback(() => {
    formik.resetForm();
    onClose();
  }, []);

  const onAccountSelect = React.useCallback(
    (salesforceAccount: SalesforceAccount) => {
      formik.setFieldValue('createAccount', false);
      formik.setFieldValue('salesforceAccountId', salesforceAccount.id);
      setSelectedAccount(salesforceAccount);
      setShowAccountSelect(false);
    },
    [],
  );

  return (
    <Modal
      title="取引先を作成"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText="キャンセル"
      width={580}
      okText="登録"
      okButtonProps={{
        loading,
        disabled: !formik.isValid,
      }}
      maskClosable
      {...(showAccountSelect ? {footer: null} : {})}>
      {showAccountSelect ? (
        <AccountSelect
          onClose={() => setShowAccountSelect(false)}
          onSelect={onAccountSelect}
          keyword={formik.values.accountName}
          pool={pool}
        />
      ) : (
        <Form layout="vertical">
          <Form.Item label="取引先">
            <Radio.Group {...formik.getFieldProps('createAccount')}>
              <Form.Item
                style={{marginBottom: '0.5rem'}}
                validateStatus={formik.errors.accountName && 'error'}
                help={formik.errors.accountName}>
                <label>
                  <Radio value={true} />
                  新規作成
                </label>
                <Input
                  {...formik.getFieldProps('accountName')}
                  style={{margin: '5px 0 0 24px'}}
                />
              </Form.Item>
              <Form.Item
                style={{marginBottom: '0.5rem'}}
                validateStatus={formik.errors.salesforceAccountId && 'error'}
                help={formik.errors.salesforceAccountId}>
                <label>
                  <Radio value={false} />
                  既存を選択
                </label>
                <Button onClick={() => setShowAccountSelect(true)} type="link">
                  候補{pagination.totalCount}件
                </Button>
                {selectedAccount && (
                  <SelectedAccount>
                    <div>
                      <strong>{selectedAccount.name}</strong>
                      <span>{selectedAccount.website}</span>
                    </div>
                    <div>
                      <span>
                        {`${selectedAccount.billingState || ''}${
                          selectedAccount.billingCity || ''
                        }${selectedAccount.billingStreet || ''}`.trim() ||
                          '住所不明'}
                      </span>
                      <span>{selectedAccount.phone || '電話番号不明'}</span>
                    </div>
                  </SelectedAccount>
                )}
              </Form.Item>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="所有者"
            validateStatus={formik.errors.salesforceUserId && 'error'}
            help={formik.errors.salesforceUserId}>
            <Select
              value={formik.values.salesforceUserId}
              onChange={(value) =>
                formik.setFieldValue('salesforceUserId', value)
              }>
              {salesforceUsers.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

const SelectedAccount = styled.div`
  font-size: 12px;
  color: #899098;
  padding: 5px 0 5px 24px;

  strong,
  span {
    margin-right: 1rem;
  }

  strong {
    font-size: 14px;
    color: #495058;
  }

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default NewAccountPreleadModalComponent;
