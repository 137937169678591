import React from 'react';
import ReportItem from './ReportItem';
import {usePoolQuery, useCurrentUserQuery} from 'api';
import moment from 'moment';

interface Report {
  title: string;
  path: string;
  category: string;
}

const currentMonth = moment().local().format('YYYY-MM');

const reports: Report[] = [
  {
    title: '商談作成履歴',
    path: `/reports/deal/lead_source?timeScale=day&month=${currentMonth}`,
    category: 'リード',
  },
  {
    title: 'リード状況分析',
    path: `/reports/lead/lead_source`,
    category: 'リード',
  },
  {
    title: 'リードコール分析',
    path: `/reports/call/status?timeScale=day&month=${currentMonth}`,
    category: 'リード',
  },
];

const notImplementedReports: Report[] = [
  {
    title: 'リード登録履歴',
    path: `/reports/lead_registration/lead_source?timeScale=day&month=${currentMonth}`,
    category: 'リード',
  },
  {
    title: 'フォーム分析',
    path: `/reports/form/total?timeScale=day&month=${currentMonth}`,
    category: 'フォーム',
  },
  {
    title: 'タグ連携フォーム分析',
    path: `/reports/collected_form/total?timeScale=day&month=${currentMonth}`,
    category: 'フォーム',
  },
  {
    title: 'ToDo分析',
    path: `/reports/todo/status?timeScale=month`,
    category: 'アクション',
  },
  {
    title: 'メンテナンス履歴',
    path: `/reports/maintenance/total?timeScale=month`,
    category: 'リード',
  },
];

const AllReports = () => {
  const {data: {pool = {}} = {}} = usePoolQuery({});
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  const oldActionReport: Report = {
    title: 'アクション',
    path: `/pools/${pool?.uuid}/prospect_report/action/daily`,
    category: 'リード',
  };

  const allReports = [
    ...reports,
    ...(currentUser?.role === 'admin' ? notImplementedReports : []),
    oldActionReport,
  ];

  return (
    <div className="p-8 bg-c-bg h-[calc(100vh-60px)] overflow-scroll">
      <h1 className="my-4 text-xl font-bold">レポート</h1>
      <div className="flex flex-col gap-2">
        {allReports.map((report, index) => {
          return (
            <ReportItem
              key={index}
              title={report.title}
              path={report.path}
              category={report.category}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AllReports;
