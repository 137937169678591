import React from 'react';
import {Select} from 'components/antd';
import useMailTemplate from 'hooks/useMailTempate';

interface Props {
  onSelect: (variable: string) => void;
  style: React.CSSProperties;
}

export default ({onSelect, style}: Props) => {
  const {templates, templateTitle} = useMailTemplate();

  return (
    <Select
      style={style}
      placeholder="メールテンプレート"
      optionFilterProp="children"
      showSearch
      onSelect={(value: any) => {
        const template = templates.find((template) => template.uuid === value);
        onSelect(template.mailTemplate.bodyHtml);
      }}>
      {templates.map((template) => (
        <Select.Option key={template.uuid} value={template.uuid}>
          {templateTitle(template)}
        </Select.Option>
      ))}
    </Select>
  );
};
