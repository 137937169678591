import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowSearchConditionMutation,
  useWorkflowQuery,
  useSalesforceContactRecordTypesQuery,
  SalesforceContactRecordType,
} from 'api';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {workflowId} = useParams<{
    workflowId: string;
  }>();

  const {isMember} = useClientUser();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery({});
  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <div className="flex">
      <div className="font-medium text-base leading-8 tracking-[.15px] text-[#495058] pr-2 w-28">
        レコードタイプ
      </div>
      <div className="z-30 w-[40%]">
        <select
          disabled={!isConditionUpdatable || !isMember}
          value={searchCondition['salesforceContactRecordTypeId']}
          onChange={(e) =>
            updateWorkflowSearchCondition({
              variables: {
                uuid: workflowId,
                attributes: {
                  salesforceContactRecordTypeId: e.target.value,
                  salesforceContactSearchConditions: [],
                },
              },
            })
          }
          className="form-select border-c-border rounded text-base w-full">
          <option value={null} />
          {salesforceContactRecordTypes.map(
            (salesforceContactRecordType: SalesforceContactRecordType) => {
              return (
                <option
                  key={salesforceContactRecordType.id}
                  value={salesforceContactRecordType.id}>
                  {salesforceContactRecordType.name}
                </option>
              );
            },
          )}
        </select>
      </div>
    </div>
  );
};
