import React from 'react';
import ReportTable from './ReportTable';
import Menu from './Menu';
import Filter from './Filter';
import SelectedItem from './SelectedItem';

export default () => {
  return (
    <>
      <Menu />
      <Filter />
      <SelectedItem />
      <ReportTable />
    </>
  );
};
