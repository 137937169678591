import React from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
// @ts-ignore
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import {Button, Upload, Modal, Select} from 'components/antd';
import {UploadOutlined} from '@ant-design/icons';
import {
  useImportProspectMutation,
  usePoolProspectCustomizeItemsQuery,
} from 'api';
import {useParams} from 'react-router-dom';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const upload_size_limit = 10000000; // 10MB

const UploadModalComponent = ({visible, onClose}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const [csv, setCsv] = React.useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rowCount: 0,
      headers: [],
    },
    onSubmit: (values: any) =>
      importProspect({
        variables: {
          uuid: poolId,
          attributes: values,
        },
      }),
  });

  const handleClose = () => {
    setCsv(null);
    formik.resetForm();
    onClose();
  };

  const [importProspect, {loading}] = useImportProspectMutation({
    refetchQueries: ['pool'],
    onCompleted: () => handleClose(),
  });

  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({
      variables: {
        poolId: poolId,
      },
    });

  const options = React.useMemo(() => {
    const opts = [
      {value: '', text: ''},
      {value: 'last_name', text: '[必須] 姓 (ラストネーム)'},
      {value: 'first_name', text: '名 (ファーストネーム)'},
      {value: 'email', text: '[必須] メールアドレス'},
      {value: 'account_name', text: '[必須] 会社名'},
      {value: 'telephone_number', text: '電話番号'},
      {value: 'user_name', text: 'リード担当者'},
      {value: 'address', text: '住所'},
      {value: 'account_telephone_number', text: '会社電話番号'},
      {value: 'web_site', text: 'URL'},
      {value: 'section', text: '部署名'},
      {value: 'section_position', text: '役職'},
      {value: 'corp_number', text: '法人番号'},
      {value: 'lead_source', text: '最新リードソース'},
      {value: 'original_lead_source', text: '初回リードソース'},
      {value: 'latest_inflow_date', text: '最新流入日'},
      {value: 'original_inflow_date', text: '初回流入日'},
      {value: 'tel_status', text: 'TELステータス'},
      {value: 'tel_comment', text: 'TELコメント'},
      {value: 'activity_user_name', text: 'ユーザー名'},
      {value: 'tel_recorded_at', text: '記録日時'},
      {value: 'tag_names', text: 'タグ'},
      {value: 'uuid', text: 'UUID'},
      {value: 'is_unsubscribed', text: '配信停止'},
    ];

    poolProspectCustomizeItems.forEach((customizeItem) =>
      opts.push({
        value: `pool_prospect_customize_item_id_${customizeItem.id}`,
        text: customizeItem.name,
      }),
    );

    return opts;
  }, [poolProspectCustomizeItems]);

  const checkHeaders = () =>
    ['last_name', 'account_name', 'email'].every((header) =>
      formik.values.headers.includes(header),
    );

  return (
    <UploadModal
      destroyOnClose
      title="TELログ登録"
      maskClosable={false}
      visible={visible}
      width={640}
      centered
      bodyStyle={{height: '70vh'}}
      footer={[
        <CancelButton size="large" key="cancel" onClick={handleClose}>
          キャンセル
        </CancelButton>,
        <OkButton
          size="large"
          key="submit"
          type="primary"
          disabled={!formik.isValid || !csv || !checkHeaders()}
          onClick={() => formik.handleSubmit(formik.values)}
          loading={loading}>
          登録
        </OkButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <UploadDescription>
          <div>姓・メールアドレス・会社名は必須項目です。</div>
          <div>
            必須項目が入力されていない行はアップロード対象外になります。
          </div>
          <div>
            「タグ」はカンマ区切りで入力すると、別のタグとして登録されます
          </div>
        </UploadDescription>
        <Upload
          name={'file'}
          accept=".csv"
          showUploadList={false}
          customRequest={() => false}
          onChange={(info) => {
            const file = info.file.originFileObj;
            if (file.size > upload_size_limit) {
              alert(
                'アップロードしようとしているファイルの容量が10MBを超えています。\n1ファイル10MBまでのアップロードが可能です。',
              );
              return null;
            }
            formik.setFieldValue('file', file);
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const codes = new Uint8Array(e.target.result);
              const encoding = Encoding.detect(codes);
              let unicodeString;
              if (encoding === 'UNICODE') {
                unicodeString = new TextDecoder().decode(codes);
              } else {
                unicodeString = Encoding.convert(codes, {
                  to: 'unicode',
                  from: encoding,
                  type: 'string',
                });
              }
              Papa.parse(unicodeString, {
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: (result: any) => {
                  setCsv(result.data);
                  formik.setFieldValue(
                    'headers',
                    new Array(result.data[0].length),
                  );
                  formik.setFieldValue('rowCount', result.data.length);
                },
              });
            };
            reader.readAsArrayBuffer(file);
          }}>
          <UploadButton size="large">
            <UploadOutlined />
            Click to Upload
          </UploadButton>
        </Upload>

        {csv && (
          <>
            <ListRow>
              <div className="column">CSVの項目</div>
              <div>システム項目</div>
            </ListRow>
            {csv[0].map((header: string, index: number) => (
              <ListRow key={index}>
                <div className="column">{header}</div>
                <div className="value">
                  <Select
                    style={{width: '100%'}}
                    value={formik.values.headers[index]}
                    onChange={(value) => {
                      let headers = formik.values.headers;
                      headers[index] = value;
                      formik.setFieldValue('headers', headers);
                    }}>
                    {options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </ListRow>
            ))}
          </>
        )}
      </ModalContent>
    </UploadModal>
  );
};

const UploadModal = styled(Modal)`
  &&& {
    .ant-modal-body {
      overflow: scroll;
      padding: 24px 48px;
    }
    .ant-modal-title {
      text-align: center;
      font-weight: bold;
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    font-size: 14px;
  }

  .ant-upload {
    margin-bottom: 2rem;
  }
`;

const UploadDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #899098;
  text-align: center;

  > div:last-child {
    margin-bottom: 20px;
  }
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  border-top: 1px solid #e2e6ea;

  > div {
    &:first-child {
      width: 250px;
    }
    &:last-child {
      flex: 1;
    }
  }

  &:first-child {
    font-weight: bold;
    > div {
      text-align: center;
    }
  }

  &:last-child {
    border-bottom: 1px solid #e2e6ea;
  }
`;

const UploadButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const OkButton = styled(Button)`
  &&& {
    border: none;
    border-radius: 4px;
  }
`;

export default UploadModalComponent;
