import React from 'react';
import {Form} from 'components/antd';
import {SalesforceContactField, SalesforceContactFieldSelectOption} from 'api';
import {Listbox, Transition} from '@headlessui/react';
import _ from 'lodash';
import {Close, DownArror} from './Icon';

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
  field: SalesforceContactField;
}

export const MultiSelectItem = ({addition, setAddition, field}: Props) => {
  const options = field.salesforceContactFieldSelectOptions;
  const selectedOptions = React.useMemo(() => {
    const itemsString = addition[`${field.fieldName}`];
    if (!itemsString) {
      return [];
    }
    const selectValues: Array<String> = itemsString.split(';') || [];

    let selectedOptions: Array<SalesforceContactFieldSelectOption> = [];

    selectValues.map((select) => {
      const option = options.find((option) => {
        return option.value === select;
      });
      selectedOptions.push(option);
    });

    return selectedOptions;
  }, [addition]);

  const valueString = (options: Array<SalesforceContactFieldSelectOption>) =>
    _.map(options, 'value').join(';');

  return (
    <Form.Item label={field.label} required={field.isRequired}>
      <div className="h-9 z-20 min-w-[320px] w-auto min-h-9">
        <Listbox
          value={selectedOptions}
          multiple
          onChange={(values) => {
            let newAddition: {[key: string]: any} = Object.assign({}, addition);
            newAddition[`${field.fieldName}`] = valueString(values);
            setAddition(newAddition);
          }}>
          <div className="relative flex-1 w-full h-full">
            <Listbox.Button className="relative px-3 pr-10 text-left focus:outline-none flex items-center border rounded w-full h-full bg-white border-[#d9d9d9]">
              <div className="flex gap-1 items-center">
                {selectedOptions.map((option) => {
                  return (
                    <div
                      key={option?.id}
                      className="bg-[#f5f5f5] border border-[#f0f0f0] px-1 h-6 flex gap-1 items-center">
                      <div>{option?.label}</div>
                      {
                        <div
                          className="flex items-center"
                          onClick={() => {
                            let newOptions = selectedOptions.filter(
                              (selectedOption) =>
                                selectedOption.id !== option.id,
                            );
                            let newAddition: {[key: string]: any} =
                              Object.assign({}, addition);
                            newAddition[`${field.fieldName}`] =
                              valueString(newOptions);
                            setAddition(newAddition);
                          }}>
                          <Close />
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
              <span
                className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2
              `}>
                <DownArror />
              </span>
            </Listbox.Button>
            <Transition
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-10">
                {options
                  .filter(
                    (option: SalesforceContactFieldSelectOption) =>
                      !selectedOptions.includes(option),
                  )
                  .map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({active}) =>
                        `relative select-none py-2 px-3 cursor-pointer ${
                          active ? 'bg-gray-100' : 'bg-white'
                        }`
                      }
                      value={option}>
                      {`${option?.label}`}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </Form.Item>
  );
};
