import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useMainCategoriesQuery} from 'api';
import styled from 'styled-components';

const CategoryFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery();

  const subCategories = mainCategories.find(
    (mainCategory) => mainCategory.id === query.categoryIds?.main,
  )?.subCategories;

  return (
    <Container>
      <MainCategorySelect
        dropdownMatchSelectWidth={false}
        allowClear
        placeholder="業種メイン"
        value={query.categoryIds?.main}
        onChange={(value: string) => {
          if (value === undefined) {
            setQuery({categoryIds: null});
          } else {
            setQuery({categoryIds: {main: value}});
          }
        }}>
        {mainCategories.map((mainCategory) => (
          <Select.Option value={mainCategory.id} key={mainCategory.id}>
            {mainCategory.displayName}
          </Select.Option>
        ))}
      </MainCategorySelect>
      <SubCategorySelect
        dropdownMatchSelectWidth={false}
        allowClear
        mode="multiple"
        placeholder="業種サブ"
        value={query.categoryIds?.sub?.split('-')}
        disabled={!query.categoryIds?.main}
        onChange={(value: Array<string>) => {
          if (value.length === 0) {
            setQuery({categoryIds: {main: query.categoryIds.main}});
          } else {
            const ids = value.join('-');
            setQuery({categoryIds: {main: query.categoryIds.main, sub: ids}});
          }
        }}>
        {subCategories?.map((subCategory) => (
          <Select.Option value={subCategory.id} key={subCategory.id}>
            {subCategory.displayName}
          </Select.Option>
        ))}
      </SubCategorySelect>
    </Container>
  );
};

export default CategoryFilter;

const Container = styled.div``;

const MainCategorySelect = styled(Select)`
  width: 120px;
  margin-right: 9px;
`;

const SubCategorySelect = styled(Select)`
  width: 165px;
`;
