import React from 'react';
import styled from 'styled-components';
import {Modal, Form as UIForm, Button, Select, message} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';
import {useTestMailCampaignMutation, useUsersByClientAllQuery} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const TestModalComponent = ({visible, onClose}: Props) => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });
  const [testMailCampaign] = useTestMailCampaignMutation({
    onCompleted: () => {
      onClose();
      message.info('テストメールを送信しました');
    },
  });
  const formik = useFormik({
    initialValues: {
      to: [],
    },
    validationSchema: Yup.object().shape({
      to: Yup.array().of(Yup.string().email()).required(),
    }),
    onSubmit: (values) =>
      confirm(
        `${formik.values.to}にテストメールを送信します。\nよろしいですか？`,
      ) && testMailCampaign({variables: {...values, uuid: mailCampaignId}}),
  });

  return (
    <Modal
      title="テストメールを送信　"
      visible={visible}
      width={680}
      footer={null}
      onCancel={onClose}
      maskClosable={false}>
      <Form onFinish={formik.handleSubmit}>
        <Select
          mode="tags"
          style={{width: '100%'}}
          onChange={(value) => formik.setFieldValue('to', value)}>
          {users.map((user) => (
            <Select.Option key={user.id} value={user.email}>
              {user.email}
            </Select.Option>
          ))}
        </Select>
        <Button htmlType="submit" type="primary">
          送信する
        </Button>
      </Form>
    </Modal>
  );
};

const Form = styled(UIForm)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`;

export default TestModalComponent;
