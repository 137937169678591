import gql from 'graphql-tag';

export default gql`
  fragment manualMailFragment on ManualMail {
    id
    from
    fromEmail
    fromName
    to
    cc
    bcc
    subject
    bodyText
    bodyHtml
  }
`;
