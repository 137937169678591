import gql from 'graphql-tag';
import formBlockFieldCustomizeItemFragment from './blockFieldCustomizeItem';

export default gql`
  fragment formPostCustomizeItemSelectFragment on FormPostCustomizeItemSelect {
    id
    formBlockFieldCustomizeItemId
    formBlockFieldCustomizeItem {
      ...formBlockFieldCustomizeItemFragment
    }
    poolProspectCustomizeItemSelectOptions {
      id
      label
    }
    createdAt
    updatedAt
  }
  ${formBlockFieldCustomizeItemFragment}
`;
