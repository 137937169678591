import React from 'react';

const Icon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#F5F1FF" />
    <path
      d="M19.6667 10.916H10.3333C10.0112 10.916 9.75 11.1772 9.75 11.4993V18.4994C9.75 18.8215 10.0112 19.0827 10.3333 19.0827H19.6667C19.9888 19.0827 20.25 18.8215 20.25 18.4994V11.4993C20.25 11.1772 19.9888 10.916 19.6667 10.916Z"
      stroke="#8966EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6666 11.209L14.9999 15.584L10.3333 11.209"
      stroke="#8966EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
