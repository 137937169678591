import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {useWebsitesQuery, Website} from 'api';
import {Check} from 'components/Ui/Icon';
import {HashLink} from 'react-router-hash-link';
import useSearchCondition from '../useSearchCondition';
import useProjectPath from 'hooks/useProjectPath';

interface Props {
  activePageType: string;
}

const SecondMenu = ({activePageType}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {query} = useSearchCondition();
  const {createPath} = useProjectPath();

  const {data: {websites = []} = {}} = useWebsitesQuery({
    variables: {
      search: {
        pageTypes: [
          'recruit',
          'recruitnew',
          'parttime',
          'press',
          'service',
          'welfare',
        ],
      },
    },
  });

  const isSelected = (pageType: string) =>
    websites.find(
      (website: Website) =>
        query.websiteIds?.includes(website.id) && website.pageType === pageType,
    );

  return (
    <Container>
      <MenuGroup>
        <Menu
          to={createPath(
            `projects/${projectId}/search/website/multiple${location.search}#recruit`,
          )}
          current={activePageType === 'recruit' ? 1 : 0}>
          求人（中途）
          {isSelected('recruit') && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          to={createPath(
            `projects/${projectId}/search/website/multiple${location.search}#recruitnew`,
          )}
          current={activePageType === 'recruitnew' ? 1 : 0}>
          求人（新卒）
          {isSelected('recruitnew') && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          to={createPath(
            `projects/${projectId}/search/website/multiple${location.search}#parttime`,
          )}
          current={activePageType === 'parttime' ? 1 : 0}>
          求人（アルバイト）
          {isSelected('parttime') && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          to={createPath(
            `projects/${projectId}/search/website/multiple${location.search}#press`,
          )}
          current={activePageType === 'press' ? 1 : 0}>
          プレスリリース
          {isSelected('press') && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          to={createPath(
            `projects/${projectId}/search/website/multiple${location.search}#service`,
          )}
          current={activePageType === 'service' ? 1 : 0}>
          サービス・プロダクト
          {isSelected('service') && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          to={createPath(
            `projects/${projectId}/search/website/multiple${location.search}#welfare`,
          )}
          current={activePageType === 'welfare' ? 1 : 0}>
          介護・福祉
          {isSelected('welfare') && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
      </MenuGroup>
    </Container>
  );
};

export {SecondMenu};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid #e2e6ea;

  a {
    text-align: left;
    width: 100%;
    height: 50px;
    background: transparent;
    font-size: 12px;
    color: #bdc4cb;
    box-shadow: none;
  }
`;

const MenuGroup = styled.div`
  overflow-y: scroll;
`;

const Menu = styled(HashLink)<{current?: number}>`
  &&& {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 14px;
    padding-left: 25px;
    color: #222426;
    white-space: pre;
    background: ${(props) => (props.current ? '#E2E6EA;' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }
  }
`;

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
  height: 20px;
`;
