import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Check} from 'components/Ui/Icon';
import {HashLink} from 'react-router-hash-link';
import useSearchCondition from '../useSearchCondition';
import useProjectPath from 'hooks/useProjectPath';
import technologies from './technologies';

interface Props {
  activeCategory: string;
}

const SecondMenu = ({activeCategory}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {query} = useSearchCondition();
  const {createPath} = useProjectPath();

  const isSelected = React.useCallback(
    (category: any) => {
      const values = category.technologies.map((c: any) => c.value);
      for (const value of values) {
        if (query.technologies?.includes(value)) return true;
      }

      return false;
    },
    [query],
  );

  return (
    <Container>
      <MenuGroup>
        {technologies.map((category) => (
          <Menu
            key={category.value}
            to={createPath(
              `projects/${projectId}/search/technology${location.search}#${category.value}`,
            )}
            current={category.value === activeCategory ? 1 : 0}>
            {category.text}
            {isSelected(category) && (
              <SelectLabel>
                <Check />
              </SelectLabel>
            )}
          </Menu>
        ))}
      </MenuGroup>
    </Container>
  );
};

export {SecondMenu};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid #e2e6ea;

  a {
    text-align: left;
    width: 100%;
    height: 50px;
    background: transparent;
    font-size: 12px;
    color: #bdc4cb;
    box-shadow: none;
  }
`;

const MenuGroup = styled.div`
  overflow-y: scroll;
`;

const Menu = styled(HashLink)<{current?: number}>`
  &&& {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 25px;
    height: 50px;
    font-size: 14px;
    color: #222426;
    white-space: pre;
    background: ${(props) => (props.current ? '#E2E6EA;' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }
  }
`;

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
  height: 20px;
`;
