import {
  DashboardItemDealChartType,
  DashboardItemDealChartOptions,
  DashboardItem,
} from '../../types';
import React from 'react';

import LeadSourceChart from './LeadSource';

const chartComponents: {
  [key in DashboardItemDealChartType]?: React.FC<DashboardItemDealChartOptions>;
} = {
  lead_source: LeadSourceChart,
};

type Props = {
  item: DashboardItem<DashboardItemDealChartOptions>;
};

const DealChart: React.FC<Props> = ({item}) => {
  const ChartComponent = chartComponents[item.options.chartType];

  return <ChartComponent {...item.options} />;
};

export default DealChart;
