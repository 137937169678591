import gql from 'graphql-tag';

export default gql`
  fragment hubspotCompanyFragment on HubspotCompany {
    id
    hubspotId
    name
    address
    domain
    telephoneNumber
    webSite
    zipCode
    hubspotUrl
    properties
  }
`;
