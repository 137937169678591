import {PreleadTag, useProjectQuery} from 'api';
import useProject from './useProject';
import {useParams} from 'react-router-dom';
import useProjectGroups from './useProjectGroups';

const usePreleadTags = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {
    data: {
      project = {},
      project: {preleadTags = [], projectGroup = {}} = {},
    } = {},
  } = useProjectQuery({
    variables: {uuid: projectId},
  });
  const {isOwnProject} = useProject(project.uuid);
  const {isProjectGroupAccessible} = useProjectGroups();
  const filterPreleadTagsAccessible = (preleadTags: PreleadTag[]) =>
    preleadTags.filter((tag) =>
      isOwnProject
        ? isProjectGroupAccessible(tag.projectGroup)
        : tag.projectGroup.uuid === projectGroup.uuid,
    );
  const preleadTagsAccessible = filterPreleadTagsAccessible(preleadTags);

  return {
    filterPreleadTagsAccessible,
    preleadTagsAccessible,
  };
};

export default usePreleadTags;
