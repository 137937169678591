import React from 'react';
import {Form, useUpdateFormMutation} from 'api';
import debounce from 'helpers/debounce';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [scale, setScale] = React.useState(form.formLogoScale);
  const [updateForm] = useUpdateFormMutation({refetchQueries: ['form']});

  const onSubmit = debounce(() => {
    updateForm({
      variables: {
        uuid: form.uuid,
        attributes: {
          formLogoScale: scale,
        },
      },
    });
  }, 500);

  React.useEffect(() => {
    onSubmit();
  }, [scale]);

  return (
    <div className="flex flex-col gap-1">
      <label className="tracking-wide font-medium text-base text-c-light">
        ロゴ画像サイズ
      </label>
      <input
        id="default-range"
        type="range"
        value={scale * 100}
        min="1"
        max="100"
        step="1"
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        onChange={(e) => setScale(Number(e.target.value) / 100)}
      />
    </div>
  );
};
