import React from 'react';
import useCondition from '../../../useCondition';
import {useSalesforceContactRecordTypeByRecordTypeIdQuery} from 'api';
import {XMarkIcon} from '@heroicons/react/20/solid';
import ContactSelectOption from './ContactSelectOption';
import ContactMultiSelectOption from './ContactMultiSelectOption';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  contact_field_id: string;
  index: number;
};

type MultiSelectSearchCondition = {
  values: string[];
  field_type: string;
  contact_field_id: string;
  index: number;
};

type SearchCondition = SelectSearchCondition | MultiSelectSearchCondition;

interface Props {
  searchCondition: SearchCondition;
  setSearchCondition: (condition: SearchCondition) => void;
  onDelete: () => void;
}

const SalesforceContactField = ({
  searchCondition,
  setSearchCondition,
  onDelete,
}: Props) => {
  const {condition} = useCondition();

  const {
    data: {
      salesforceContactRecordTypeByRecordTypeId: {
        salesforceContactFields = [],
      } = {},
    } = {},
  } = useSalesforceContactRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceContactRecordTypeId: condition?.salesforceContactRecordTypeId,
    },
    skip: !condition.salesforceContactRecordTypeId,
  });

  const targetContactFields = salesforceContactFields
    .filter(
      (item) =>
        (item.fieldType === 'select' || item.fieldType === 'multi_select') &&
        item.fieldName.endsWith('__c'),
    )
    .filter(
      (item) =>
        !condition.salesforceContactSearchConditions?.some(
          (cond) =>
            cond.contact_field_id === item.id &&
            searchCondition.contact_field_id !== item.id,
        ),
    );

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const contactField = targetContactFields.find(
      (item) => item.id === e.target.value,
    );

    if (!contactField) {
      setSearchCondition({
        field_select_option_id: '',
        field_type: '',
        contact_field_id: '',
        index: searchCondition.index,
      });
      return;
    }

    if (contactField.fieldType === 'select') {
      setSearchCondition({
        field_select_option_id: '',
        field_type: contactField.fieldType,
        contact_field_id: contactField.id,
        index: searchCondition.index,
      });
    }

    if (contactField.fieldType === 'multi_select') {
      setSearchCondition({
        values: [],
        field_type: contactField.fieldType,
        contact_field_id: contactField.id,
        index: searchCondition.index,
      });
    }
  };

  return (
    <div className="flex gap-2">
      <select
        className="form-select border-c-border rounded text-base w-64 h-[44px]"
        onChange={onChange}
        value={searchCondition.contact_field_id}>
        <option value="" />
        {targetContactFields.map((field) => (
          <option value={field.id} key={field.id}>
            {field.label}
          </option>
        ))}
      </select>
      {searchCondition.field_type === 'select' && (
        <ContactSelectOption
          contactFieldId={searchCondition.contact_field_id}
          value={
            (searchCondition as SelectSearchCondition).field_select_option_id
          }
          onChange={(value) =>
            setSearchCondition({
              ...searchCondition,
              field_select_option_id: value,
            })
          }
        />
      )}
      {searchCondition.field_type === 'multi_select' && (
        <ContactMultiSelectOption
          contactFieldId={searchCondition.contact_field_id}
          values={(searchCondition as MultiSelectSearchCondition).values}
          onChange={(values) =>
            setSearchCondition({...searchCondition, values})
          }
        />
      )}
      <button
        onClick={onDelete}
        className="bg-white px-0 py-6 h-5 w-5 flex items-center justify-center rounded-full cursor-pointer">
        <XMarkIcon className="w-5 h-5 text-c-light" />
      </button>
    </div>
  );
};

export default SalesforceContactField;
