import gql from 'graphql-tag';

export default gql`
  fragment companyHistoryFragment on CompanyHistory {
    id
    companyId
    statusFrom
    statusTo
    userId
    user {
      id
    }
  }
`;
