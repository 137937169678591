import gql from 'graphql-tag';

export default gql`
  fragment formPostFragment on FormPost {
    uuid
    lastName
    firstName
    email
    accountName
    accountTelephoneNumber
    telephoneNumber
    address
    webSite
    section
    sectionPosition
    params
    createdAt
    updatedAt
  }
`;
