import gql from 'graphql-tag';

export default gql`
  fragment applyBlockGroupFragment on ApplyBlockGroup {
    id
    status
    rowCount
    currentRowNumber
    percent
  }
`;
