import React from 'react';
import {Route, Switch} from 'react-router';
import ExternalList from './ExternalList';
import Hubspot from './Hubspot';
import Slack from './Slack';
import Salesforce from './Salesforce';
import Chatwork from './Chatwork';
import Miitel from './Miitel';
import Google from './Google';
import ZoomPhone from './ZoomPhone';

export default () => {
  return (
    <Switch>
      <Route path="/settings/externals/hubspot" component={Hubspot} />
      <Route path="/settings/externals/salesforce" component={Salesforce} />
      <Route path="/settings/externals/salesforce/*" component={Salesforce} />
      <Route path="/settings/externals/slack" component={Slack} />
      <Route path="/settings/externals/chatwork" component={Chatwork} />
      <Route path="/settings/externals/miitel" component={Miitel} />
      <Route path="/settings/externals/google" component={Google} />
      <Route path="/settings/externals/zoom_phone" component={ZoomPhone} />
      <Route exact path="/settings/externals" component={ExternalList} />
    </Switch>
  );
};
