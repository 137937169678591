import gql from 'graphql-tag';

export default gql`
  fragment clientDownloadProjectFragment on ClientDownloadProject {
    id
    file
    filename
    columnIds
    count
    createdAt
  }
`;
