import React from 'react';
import styled from 'styled-components';
import {Switch, Route, useHistory, useParams} from 'react-router-dom';
import Prospect from './Prospect';
import Salesforce from './Salesforce';
import Account from './Account';
import Hubspot from './Hubspot';
import {useCurrentClientQuery} from 'api';

export default () => {
  const history = useHistory();
  const {
    data: {
      currentClient: {salesforceSetting = {}, hubspotSetting = {}} = {},
    } = {},
  } = useCurrentClientQuery();

  const {poolId, workflowId} =
    useParams<{poolId: string; workflowId: string}>();
  return (
    <Container>
      <Tabs>
        {hubspotSetting?.isActive && (
          <TabItem
            onClick={() =>
              history.push(
                `/pools/${poolId}/workflows/${workflowId}/search_conditions/hubspot`,
              )
            }
            isActive={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/search_conditions/hubspot`,
            )}>
            HubSpot
          </TabItem>
        )}
        {salesforceSetting?.isActive && (
          <TabItem
            onClick={() =>
              history.push(
                `/pools/${poolId}/workflows/${workflowId}/search_conditions/salesforce`,
              )
            }
            isActive={location.pathname.startsWith(
              `/pools/${poolId}/workflows/${workflowId}/search_conditions/salesforce`,
            )}>
            Salesforce
          </TabItem>
        )}
        <TabItem
          onClick={() =>
            history.push(
              `/pools/${poolId}/workflows/${workflowId}/search_conditions/prospect`,
            )
          }
          isActive={location.pathname.startsWith(
            `/pools/${poolId}/workflows/${workflowId}/search_conditions/prospect`,
          )}>
          リード情報
        </TabItem>
        <TabItem
          onClick={() =>
            history.push(
              `/pools/${poolId}/workflows/${workflowId}/search_conditions/account`,
            )
          }
          isActive={location.pathname.startsWith(
            `/pools/${poolId}/workflows/${workflowId}/search_conditions/account`,
          )}>
          企業情報
        </TabItem>
      </Tabs>
      <Switch>
        {hubspotSetting?.isActive && (
          <Route
            path="/pools/:poolId/workflows/:workflowId/search_conditions/hubspot"
            component={Hubspot}
          />
        )}
        {salesforceSetting?.isActive && (
          <Route
            path="/pools/:poolId/workflows/:workflowId/search_conditions/salesforce"
            component={Salesforce}
          />
        )}
        <Route
          path="/pools/:poolId/workflows/:workflowId/search_conditions/prospect"
          component={Prospect}
        />
        <Route
          path="/pools/:poolId/workflows/:workflowId/search_conditions/account"
          component={Account}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 25px;
`;

const Tabs = styled.div`
  display: flex;
  gap: 18px;
`;

const TabItem = styled.div<{isActive: boolean}>`
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: ${({isActive}) => (isActive ? '3px solid #495058' : '')};
  color: ${({isActive}) => (isActive ? '#495058' : '#BDC4CB')};
`;
