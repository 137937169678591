import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useCondition from '../../../useCondition';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  if (condition?.isEmail === null || condition?.isEmail === undefined) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">メールアドレス</h4>
        <button
          onClick={() => setFilterType('mailAddress')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setCondition({isEmail: null})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {condition?.isEmail ? 'メールアドレスあり' : 'メールアドレスなし'}
        </span>
      </div>
    </div>
  );
};
