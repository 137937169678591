import React from 'react';
import {Prelead, useClientAllProjectsQuery} from 'api';
import {Row} from 'react-table';

const ProjectGroupCell = ({row}: {row: Row<Prelead>}) => {
  const prelead: Prelead = row.original;

  const projectIds = React.useMemo(() => {
    const projectIds = prelead.preleadProjects?.map(
      (preleadProject) => preleadProject.projectId,
    );
    return Array.from(new Set(projectIds));
  }, [prelead]);

  const {data: {clientAllProjects: {projects: allProjects = []} = {}} = {}} =
    useClientAllProjectsQuery({
      variables: {
        search: {
          status: 'open',
        },
      },
    });

  const projectGroups = React.useMemo(
    () =>
      Array.from(
        new Set(
          allProjects
            .filter((project) => projectIds.includes(project.id))
            .map((project) => project.projectGroup.name),
        ),
      ).join(', '),
    [projectIds, allProjects],
  );

  return <span>{projectGroups}</span>;
};

export default ProjectGroupCell;
