import React from 'react';
import {useUpdateAccountGroupNameMutation, AccountGroup} from 'api';

interface Props {
  accountGroup: AccountGroup;
}

export default ({accountGroup}: Props) => {
  const [updateAccountGroupName] = useUpdateAccountGroupNameMutation();
  const [name, setName] = React.useState(accountGroup?.name || '');

  const updateName = () => {
    if (name) {
      updateAccountGroupName({
        variables: {
          uuid: accountGroup.uuid,
          name,
        },
      });
    } else {
      alert('リスト名は必須です');
    }
  };

  React.useEffect(() => {
    setName(accountGroup?.name);
  }, [accountGroup]);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      updateName();
    }
  };

  return (
    <input
      value={name || ''}
      onChange={(e) => setName(e.target.value)}
      onBlur={updateName}
      onKeyDown={onKeyDown}
      className="flex-1 text-xl font-bold border-transparent bg-transparent rounded"
    />
  );
};
