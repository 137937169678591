import gql from 'graphql-tag';

export default gql`
  fragment clientShareFragment on ClientShare {
    id
    uuid
    requestClientId
    offeredClientId
  }
`;
