import gql from 'graphql-tag';

export default gql`
  fragment contentDocumentFragment on ContentDocument {
    id
    uuid
    file
    fileName
    fileContentType
    fileSize
    status
    memo
    createdAt
    updatedAt
    publishedAt
  }
`;
