import React from 'react';
import {createColumnHelper} from '@tanstack/react-table';
import moment from 'moment';
import ProspectTelStatusColumns from './ProspectTelStatusColumnDef';

const columns = () => {
  const columnHelper = createColumnHelper<any>();
  return [
    columnHelper.accessor('date', {
      header: () => (
        <div className="h-20 w-20 flex justify-center items-center bg-[#FAFBFB] border-r border-y border-gray-300 p-0 m-0"></div>
      ),
      cell: (info) => (
        <div
          className={`${
            info.getValue() &&
            moment(info.getValue()).format('ddd') == '土' &&
            'text-[#5598F2]'
          }
            ${
              info.getValue() &&
              moment(info.getValue()).format('ddd') == '日' &&
              'text-[#E2836F]'
            }
          `}>
          {info.getValue()
            ? `${moment(info.getValue()).format('DD (ddd)')}`
            : '合計'}
        </div>
      ),
    }),
    columnHelper.accessor('dealsCount', {
      header: () => (
        <div className="h-20 w-20 flex justify-center items-center bg-[#FAFBFB] border-r border-y  border-gray-300 p-0">
          商談
        </div>
      ),
      cell: (info) => <div>{info.getValue() || 0}</div>,
    }),
    columnHelper.accessor('callsCount', {
      header: () => (
        <div className="h-20 w-20 flex justify-center items-center bg-[#FAFBFB] border-y border-gray-300 p-0">
          総架電数
        </div>
      ),
      cell: (info) => <div>{info.getValue() || 0}</div>,
    }),
    ...ProspectTelStatusColumns(),
    columnHelper.accessor('manualMailSendsCount', {
      header: () => (
        <div className="h-20 w-20 flex justify-center items-center bg-[#FAFBFB] border-y border-x border-gray-300 p-0">
          手動メール
        </div>
      ),
      cell: (info) => <div>{info.getValue() || 0}</div>,
    }),
  ];
};

export default columns;
