import React from 'react';
import {FieldValue} from './FieldValue';
import {SalesforceDeal, usePoolQuery, SalesforceDealField} from 'api';
import LinkIcon from '../LinkIcon';

interface Props {
  deal: SalesforceDeal;
}

export default ({deal}: Props) => {
  const {data: {pool: {poolSalesforceSetting = {}} = {}} = {}} = usePoolQuery(
    {},
  );

  const url = poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );
  const salesforceDealFields =
    deal?.salesforceDealRecordType?.salesforceDealFields?.filter(
      (field: SalesforceDealField) => field?.isDisplay,
    ) || [];

  if (!deal) {
    return null;
  }

  return (
    <div
      className={`flex w-full flex-col rounded-sm border border-c-border bg-white px-8`}>
      <div className="flex h-12 gap-2 border-b border-b-c-border justify-between items-center">
        <div className="flex items-center gap-1 h-full">
          <h3 className="m-0 text-base font-bold">{deal?.name}</h3>
          <div className="flex h-4 items-center justify-center rounded-sm bg-[#EEF0F2] px-2 text-xs text-c-light">
            {deal?.salesforceOpportunityStage?.masterLabel}
          </div>
          <a
            target="_blank"
            className="h-full flex items-center"
            href={`${url}/lightning/r/Opportunity/${deal.salesforceOpportunityIdString}/view`}>
            <LinkIcon />
          </a>
        </div>
        {deal?.salesforceUser && (
          <div>
            <span className="text-c-light text-xm mr-2">商談所有者</span>
            <span>{deal?.salesforceUser?.name}</span>
          </div>
        )}
      </div>
      <div className="flex h-12 items-center gap-2 border-b border-b-c-border">
        <span className="text-c-light">レコードタイプ</span>
        <div>{deal?.salesforceDealRecordType?.name}</div>
      </div>
      <div className="flex flex-col gap-2 py-4">
        {salesforceDealFields.map((field, i) => (
          <FieldValue field={field} key={i} salesforceDeal={deal} />
        ))}
      </div>
    </div>
  );
};
