import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99967 18.3332H14.9997C15.9202 18.3332 16.6663 17.587 16.6663 16.6665V8.1902C16.6663 7.74816 16.4908 7.32425 16.1782 7.01168L11.3212 2.15466C11.0086 1.8421 10.5847 1.6665 10.1427 1.6665H4.99967C4.0792 1.6665 3.33301 2.4127 3.33301 3.33317V16.6665C3.33301 17.587 4.0792 18.3332 4.99967 18.3332Z"
        stroke="#219653"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.833 2.0835V7.50016H15.833"
        stroke="#219653"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15L8 11"
        stroke="#219653"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11L8 15"
        stroke="#219653"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
