import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
// // @ts-ignore
// import listIcon from './list.svg';
// // @ts-ignore
// import reportIcon from './report.svg';
// // @ts-ignore
// import fileIcon from './file.svg';
// @ts-ignore
// import settingsIcon from './settings.svg';

type IconTypes = 'campaign' | 'report' | 'template' | 'settings';

type Props = {
  to: string;
  menu: string;
  icon?: IconTypes;
};
export default ({to, menu}: Props) => {
  // const findIcon = (iconType: IconTypes) => {
  //   switch (iconType) {
  //     // case 'campaign':
  //     //   return listIcon;
  //     // case 'report':
  //     //   return reportIcon;
  //     // case 'template':
  //     //   return fileIcon;
  //     case 'settings':
  //       return settingsIcon;
  //     default:
  //       return null;
  //   }
  // };

  return (
    <Icon>
      <Link to={to}>
        {/* icon && <img src={findIcon(icon)} /> */}
        {menu}
      </Link>
    </Icon>
  );
};

const Icon = styled.div`
  a {
    line-height: 30px;
    color: #6e7489;

    &:hover {
      color: #000;
    }
  }
  img {
    display: block;
    width: 21px;
    margin: 0 auto;
  }
`;
