import React from 'react';
import {Switch, Route} from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../Menu';
import UserList from './UserList';

export default () => {
  return (
    <Body>
      <Menu />
      <Switch>
        <Route path="/settings/users" component={UserList} />
      </Switch>
    </Body>
  );
};

const Body = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  background: #fff;
`;
