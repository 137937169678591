import React from 'react';
import {Menu, Transition} from '@headlessui/react';

interface Props extends React.ComponentProps<'button'> {
  options: {label: string; onClick: () => void}[];
}

export default ({options, ...props}: Props) => {
  return (
    <Menu as="div" className="relative inline-block text-left z-30">
      <Menu.Button {...props} />
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 mt-2 w-[10rem] origin-top-right rounded bg-white shadow-lg focus:outline-none">
          <div className="px-1 py-1 ">
            {options.map((option, index) => (
              <Menu.Item key={index}>
                <button
                  onClick={option.onClick}
                  className="cursor-pointer bg-white text-left p-2 w-full hover:bg-[#F1F8FF] hover:text-c-primary">
                  {option.label}
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
