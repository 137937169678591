import React from 'react';
import {useUpdateFormTitleMutation, Form} from 'api';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [updateFormTitle] = useUpdateFormTitleMutation();
  const [title, setTitle] = React.useState(form.title || '');

  const updateTitle = () => {
    if (title) {
      updateFormTitle({
        variables: {
          uuid: form.uuid,
          title,
        },
      });
    } else {
      alert('タイトルは必須です');
    }
  };

  React.useEffect(() => {
    setTitle(form?.title);
  }, [form]);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      updateTitle();
    }
  };

  return (
    <input
      value={title || ''}
      onChange={(e) => setTitle(e.target.value)}
      onBlur={updateTitle}
      onKeyDown={onKeyDown}
      className="flex-1 text-xl font-bold border-transparent bg-transparent rounded"
    />
  );
};
