const countOptions = [
  {text: '5回以上', value: '5'},
  {text: '4回', value: '4'},
  {text: '3回', value: '3'},
  {text: '2回', value: '2'},
  {text: '1回', value: '1'},
  {text: '0回', value: '0'},
];

export default countOptions;
