import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';

const JobListingsSinceFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      allowClear
      value={query.jobListingsSince}
      onChange={(value) => setQuery({page: 1, jobListingsSince: value})}
      style={{width: '100%'}}>
      <Select.Option value="1m">一ヶ月以内</Select.Option>
    </Select>
  );
};

export default JobListingsSinceFilter;
