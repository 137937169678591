import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {
  useHubspotContactPropertyDefinitionsQuery,
  useUpdateHubspotContactPropertyDefinitionMutation,
} from 'api';
import useProspectContext from '../../useProspectContext'

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const ContactPropertyModal = ({isOpen, closeModal}: Props) => {
  const {poolId} = useProspectContext();

  const [updatePropertyDefinition] =
    useUpdateHubspotContactPropertyDefinitionMutation();

  const {data: {hubspotContactPropertyDefinitions = []} = {}} =
    useHubspotContactPropertyDefinitionsQuery({
      variables: {poolId},
      skip: !poolId,
    });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-sm transform rounded bg-white text-left align-middle transition-all">
                <Dialog.Title
                  as="h4"
                  className="h-10 flex items-center px-4 bg-c-bg text-c-base rounded-t">
                  項目の設定
                </Dialog.Title>
                <div className="p-4 flex flex-col gap-4 mb-4 max-h-80 overflow-scroll">
                  <table className="table-auto w-full">
                    <thead>
                      <tr>
                        <th>項目</th>
                        <th className="text-center w-8">表示</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hubspotContactPropertyDefinitions.map((property) => (
                        <tr key={property.id}>
                          <td className="py-1">
                            <label htmlFor={property.id} className="w-full">
                              {property.labelText}
                            </label>
                          </td>
                          <td className="text-center py-1">
                            <input
                              type="checkbox"
                              id={property.id}
                              className="cursor-pointer"
                              value={property.id}
                              checked={property.isDisplay}
                              onChange={(e) =>
                                updatePropertyDefinition({
                                  variables: {
                                    id: property.id,
                                    isDisplay: e.target.checked,
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ContactPropertyModal;
