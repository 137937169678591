import React from 'react';
import {Activity, ZoomPhoneWebhookCallActivity} from 'api';
import ZoomPhone from './ZoomPhone';
import {useParams} from 'react-router-dom';
import ZoomPhonePrelead from './ZoomPhonePrelead';

interface ActivityType extends Activity {
  resource: ZoomPhoneWebhookCallActivity;
}

interface Props {
  activity: ActivityType;
}

const ZoomPhoneActivityComponent = ({activity}: Props) => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();

  return (
    <>
      {preleadProjectId ? (
        <div className="ml-[-30px] relative border border-solid border-c-border rounded bg-white">
          <ZoomPhone activity={activity} />
        </div>
      ) : (
        <div className="ml-[-30px] relative border border-solid border-c-border rounded bg-white">
          <ZoomPhonePrelead activity={activity} />
        </div>
      )}
    </>
  );
};

export default ZoomPhoneActivityComponent;
