import React from 'react';
import moment from 'moment';
import {Task} from 'api';

interface Props {
  task: Task;
}

const Due = ({task}: Props) => (
  <div className="flex items-center text-xs gap-1">
    <span className="text-c-lighter">日付:</span>
    <span className="text-c-base">
      {moment(task.dueDate).format('YYYY/MM/DD')}
    </span>
  </div>
);

export default Due;
