import gql from 'graphql-tag';

export default gql`
  fragment hubspotContactFragment on HubspotContact {
    id
    hubspotId
    firstName
    lastName
    hubspotUrl
    section
    sectionPosition
    properties
  }
`;
