import React from 'react';
import {Approach} from 'api';

interface Props {
  approach: Approach;
}

const Priority = ({approach}: Props) => {
  if (approach.priority === 'high') {
    return <span className="text-[#eb5757]">High</span>;
  }

  if (approach.priority === 'normal') {
    return <span className="text-[#ffc600]">Middle</span>;
  }

  if (approach.priority === 'low') {
    return <span className="text-[#d5ebd1]">Low</span>;
  }

  return null;
};

export default ({approach}: Props) => (
  <div className="text-xs text-c-light flex items-center gap-1">
    優先度:
    <Priority approach={approach} />
  </div>
);
