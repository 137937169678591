import React from 'react';
import styled from 'styled-components';
import {Radio} from 'components/antd';
import {
  useContentTelScriptsQuery,
  ProjectContentsAttributes,
  ContentTelScript,
} from 'api';
import {FormikProps} from 'formik';

interface Props {
  formik: FormikProps<ProjectContentsAttributes>;
  contentTelScript?: ContentTelScript;
}

export default ({formik, contentTelScript}: Props) => {
  const {data: {contentTelScripts = []} = {}} = useContentTelScriptsQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <h3>TELスクリプト</h3>
      <CurrentContent>
        {contentTelScript ? (
          <>
            設定中:
            <span>{contentTelScript?.content.title}</span>
          </>
        ) : (
          '未設定'
        )}
      </CurrentContent>
      <List>
        {contentTelScripts.map((content) => (
          <label key={content.id}>
            <Radio
              name="telScriptUuid"
              value={content.telScript.uuid}
              checked={formik.values.telScriptUuid === content.telScript.uuid}
              onChange={formik.handleChange}
            />
            <span>{content?.title}</span>
          </label>
        ))}
        <hr />
        <label>
          <Radio
            name="telScriptUuid"
            checked={!formik.values.telScriptUuid}
            onChange={() => formik.setFieldValue('telScriptUuid', '')}
          />
          未設定
        </label>
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 240px;
  overflow: scroll;
  white-space: nowrap;

  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #899098;
    margin-bottom: 1rem;
    padding-left: 10px;
  }
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const CurrentContent = styled.div`
  height: 36px;
  background: #f3f5f7;
  padding: 0 1rem;
  color: #90959a;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  line-height: 3;

  em {
    display: inline-block;
    font-style: normal;
    color: #fff;
    background: #bdc4cb;
    border-radius: 2px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
    padding: 0 5px;
    margin: 0 0.5rem;
  }
`;

const List = styled.div`
  flex: 1;

  label {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 12px;
    font-weight: bold;

    em {
      display: inline-block;
      font-style: normal;
      color: #fff;
      background: #899098;
      border-radius: 2px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      font-weight: bold;
      padding: 0 5px;
      margin: 0 0.5rem;
    }

    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  hr {
    border: none;
    border-top: solid 1px #bdc4ca;
  }
`;
