import React, {useMemo} from 'react';
import {useAllProjectsFormMailReportsByEmployeeRangeQuery} from 'api';
import employeeRangeOptions from 'helpers/employeeRangeOptions';
import Table from '../Table';

export default () => {
  const {data: {allProjectsFormMailReportsByEmployeeRange = []} = {}} =
    useAllProjectsFormMailReportsByEmployeeRangeQuery({
      fetchPolicy: 'cache-and-network',
    });

  const data: any = useMemo(
    () =>
      allProjectsFormMailReportsByEmployeeRange.map((report) => {
        return {
          name: employeeRangeOptions.find(
            (employeeRangeOption) =>
              employeeRangeOption.value === report.employeeRange,
          ).label,
          mailCount: report.mailCount,
          clickCount: report.clickCount,
          clickRate: report.clickRate,
          replyCount: report.replyCount,
          replyRate: report.replyRate,
          appointCount: report.appointCount,
          appointRate: report.appointRate,
        };
      }),
    [allProjectsFormMailReportsByEmployeeRange],
  );
  return <Table data={data} />;
};
