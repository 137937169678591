import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
      <path
        d="M11.25 12.25H17.25"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 15.25H14.25"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 9.25H9.75C8.92157 9.25 8.25 9.92157 8.25 10.75V18.25C8.25 19.0784 8.92157 19.75 9.75 19.75H12L14.7348 22.4848C14.8813 22.6313 15.1187 22.6313 15.2652 22.4848L18 19.75H20.25C21.0784 19.75 21.75 19.0784 21.75 18.25V10.75C21.75 9.92157 21.0784 9.25 20.25 9.25Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
