import {ReportTable} from '../../../components/Table/types';
import React from 'react';
import {Table as TableComponent} from '../../../components/Table';
import useTableData from './useTableData';

const Table = () => {
  const table: ReportTable = useTableData();
  return <TableComponent table={table} />;
};

export default Table;
