import gql from 'graphql-tag';
import {prospectTelStatusFragment} from '..';

export default gql`
  fragment hubspotCallProspectActivityFragment on HubspotCallProspectActivity {
    id
    comment
    commentHtml
    stage
    telStatusText
    stageText
    recordedAt
    hubspotCallIdString
    hubspotContactIdString
    clientProspectTelStatus {
      ...prospectTelStatusFragment
    }
  }
  ${prospectTelStatusFragment}
`;
