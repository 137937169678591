import React from 'react';
import styled from 'styled-components';
import {PrefectureType} from 'api';
import {Checkbox} from 'antd';
import _ from 'lodash';
import {Check} from 'components/Ui/Icon';

interface Props {
  currentRegion: any;
  prefectureTypes: PrefectureType[];
  currentPrefectureType: PrefectureType;
  selectedPrefectureTypeIds: string[];
  setSelectedPrefectureTypeIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCityIds: string[];
  setSelectedCityIds: React.Dispatch<React.SetStateAction<string[]>>;
  handleCheckOnPrefectureType: (e: any, prefectureType: PrefectureType) => void;
}

export default ({
  currentRegion,
  prefectureTypes,
  currentPrefectureType,
  selectedPrefectureTypeIds,
  setSelectedPrefectureTypeIds,
  selectedCityIds,
  setSelectedCityIds,
  handleCheckOnPrefectureType,
}: Props) => {
  const currentRegionPrefectureTypeAllIds: string[] = [];
  let currentRegionCityAllIds: string[] = [];
  prefectureTypes?.forEach((prefectureType) => {
    currentRegionPrefectureTypeAllIds.push(prefectureType.id);
    currentRegionCityAllIds = currentRegionCityAllIds.concat(
      prefectureType.cities.map((city) => city.id),
    );
  });

  const isRegionChecked = () =>
    !(
      selectedPrefectureTypeIds.length === 0 ||
      currentRegionPrefectureTypeAllIds.length === 0
    ) &&
    _.difference(currentRegionPrefectureTypeAllIds, selectedPrefectureTypeIds)
      .length === 0;

  const handleCheckOnRegion = (e: any) => {
    if (e.target.checked) {
      setSelectedCityIds([...selectedCityIds, ...currentRegionCityAllIds]);
      setSelectedPrefectureTypeIds([
        ...selectedPrefectureTypeIds,
        ...currentRegionPrefectureTypeAllIds,
      ]);
    } else {
      setSelectedCityIds(
        _.difference(selectedCityIds, currentRegionCityAllIds),
      );
      setSelectedPrefectureTypeIds(
        _.difference(
          selectedPrefectureTypeIds,
          currentRegionPrefectureTypeAllIds,
        ),
      );
    }
  };

  const handleClickMenu = (prefectureType: PrefectureType) => {
    const scrollContainer = document.getElementById(
      'area-filter-modal-content',
    );
    const section = document.getElementById(prefectureType.name);
    const top = section.offsetTop - scrollContainer.offsetTop;
    scrollContainer.scrollTop = top;
  };

  const isPrefectureSelected = (prefectureType: PrefectureType) => {
    const prefectureTypeCityIds = prefectureType.cities.map((city) => city.id);
    return (
      prefectureTypeCityIds.some((cityId) =>
        selectedCityIds.includes(cityId),
      ) || selectedPrefectureTypeIds.includes(prefectureType.id)
    );
  };

  return (
    <Container>
      {Object.keys(currentRegion).length > 0 && (
        <>
          <Checkbox
            checked={isRegionChecked()}
            onChange={(e) => handleCheckOnRegion(e)}
            style={{marginTop: '26px', fontSize: '14px', width: '160px'}}>
            {currentRegion.name}すべて
          </Checkbox>
          <hr />
        </>
      )}
      <MenuGroup>
        {currentRegion.prefectureTypes?.map((prefectureTypeName: any) =>
          prefectureTypes?.map(
            (prefectureType) =>
              prefectureTypeName === prefectureType.name && (
                <Menu
                  key={prefectureTypeName}
                  onClick={() => handleClickMenu(prefectureType)}
                  current={
                    prefectureType.name === currentPrefectureType?.name ? 1 : 0
                  }
                  id={`prefecture-${prefectureTypeName}`}>
                  <Checkbox
                    name={prefectureType.id}
                    checked={selectedPrefectureTypeIds.includes(
                      prefectureType.id,
                    )}
                    key={prefectureType.id}
                    onChange={(e) =>
                      handleCheckOnPrefectureType(e, prefectureType)
                    }
                    style={{width: '16px', margin: '0 12px 0 0'}}
                  />
                  {prefectureTypeName}
                  {isPrefectureSelected(prefectureType) && (
                    <SelectLabel>
                      <Check />
                    </SelectLabel>
                  )}
                </Menu>
              ),
          ),
        )}
      </MenuGroup>
    </Container>
  );
};

const Container = styled.div`
  width: 250px;
  border-right: solid 1px #e2e6ea;

  hr {
    width: 80%;
    border: 1px solid #e2e6ea;
  }
`;

const MenuGroup = styled.div`
  overflow-y: scroll;
`;

const Menu = styled.div<{current?: number}>`
  &&& {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222426;
    white-space: pre;
    padding: 0 0 0 25px;
    height: 50px;
    background: ${(props) => (props.current ? '#E2E6EA;' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }
    svg {
      position: relative;
      top: 2px;
    }
  }
`;

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
`;
