import React, {useState} from 'react';
import {useUpdateTaskMutation, Task} from 'api';
import {Input} from 'components/antd';

interface Props {
  task: Task;
}

export default ({task}: Props) => {
  const [updateTask] = useUpdateTaskMutation();
  const [taskTitle, setTaskTitle] = useState(task.title);

  const handleSubmit = () => {
    if (taskTitle) {
      updateTask({
        variables: {uuid: task.uuid, attributes: {title: taskTitle}},
      });
    } else {
      alert('タイトルを入力してください');
      setTaskTitle(task.title);
    }
  };

  return (
    <Input
      value={taskTitle}
      onChange={(e) => setTaskTitle(e.target.value)}
      onBlur={() => handleSubmit()}
      onPressEnter={() => handleSubmit()}
    />
  );
};
