import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = [
    '2024年',
    '合計',
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  const data: ReportTableData[] = [
    {
      label: '合計',
      values: [687, 34, 22, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      cellProps: {
        style: {
          borderBottom: 'double 3px #899098',
        },
      },
    },
    {
      label: '製造・機械',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '製造',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '機械',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '金融・不動産',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '金融',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '不動産',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '建設・工事',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '建設',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '工事',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '運輸・物流',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '運輸',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '物流',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '電気製品',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '電気製品',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '自動車・乗り物',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '自動車',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '乗り物',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '食品',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '食品',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '商社',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '商社',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
    {
      label: '小売・アパレル・生活用品',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      subRows: [
        {
          label: '小売',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: 'アパレル',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
        {
          label: '生活用品',
          values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
        },
      ],
    },
  ];

  return {
    data,
    headers,
  };
};

export default useTableData;
