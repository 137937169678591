import gql from 'graphql-tag';

export default gql`
  fragment projectFragment on Project {
    id
    uuid
    clientId
    projectGroupId
    serialNumber
    projectType
    name
    count
    countFixed
    countDuplicate
    countUnique
    countUrl
    countMultiple
    memo
    memoHtml
    isCategory
    isCity
    isPrefectureType
    isCompanyAttribute
    isCompanyScale
    isExtra
    isPress
    isMedia
    isFiscal
    isSns
    position
    status
    statusText
    preleadCreateStatus
    contentMailFormVersionId
    contentAudioId
    contentTelScriptId
    createdAt
    updatedAt
    numberOfClicksInAWeek
    numberOfJobListingsInAMonth
    numberOfMailsInAWeek
    numberOfPressReleasesInAWeek
    numberOfPressReleasesInThePreviousDay
    mailCampaignSentAt
  }
`;
