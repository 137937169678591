import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input, Select} from 'components/antd';
import {
  useCreateProspectTelStatusMutation,
  useProspectTelStatusCategoriesQuery,
  useCurrentUserQuery,
} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const New = ({visible, onClose}: Props) => {
  const [createProspectStatus] = useCreateProspectTelStatusMutation({
    refetchQueries: ['currentClient'],
    onCompleted: () => onClose(),
  });

  const {data: {prospectTelStatusCategories = []} = {}} =
    useProspectTelStatusCategoriesQuery();
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      clientProspectTelStatusCategoryId: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values, {resetForm}) => {
      createProspectStatus({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
      resetForm();
    },
  });

  return (
    <Modal
      destroyOnClose
      visible={visible}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>ステータスの新規作成</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="種別">
            <Select
              value={formik.values.clientProspectTelStatusCategoryId}
              onChange={(e) =>
                formik.setFieldValue('clientProspectTelStatusCategoryId', e)
              }
              dropdownMatchSelectWidth={false}
              size="large">
              <Select.Option
                value={null}
                key="null"
                label="未選択"
                style={{height: '24px'}}>
                <div className="flex items-center text-[#bfbfbf]">
                  <span
                    className="w-[14px] h-[14px] rounded-full inline-block mr-2"
                    style={{
                      backgroundColor: '#bfbfbf',
                    }}
                  />
                  未選択
                </div>
              </Select.Option>
              {prospectTelStatusCategories.map((category) => (
                <Select.Option
                  value={category.id}
                  key={category.id}
                  label={category.name}
                  style={{height: '24px'}}>
                  <div className="flex items-center">
                    <span
                      className="w-[14px] h-[14px] rounded-full inline-block mr-2"
                      style={{
                        backgroundColor: category.color,
                      }}
                    />
                    {category.name}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="ステータス名" required>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="ステータスの説明">
            <Input.TextArea
              name="description"
              autoSize={{minRows: 3}}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!formik.isValid}>
              作成
            </Button>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default New;
