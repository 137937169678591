import React from 'react';
import {FormBlock, FormPost, FormBlockFieldStandard} from 'api';
import NameField from './Name';
import EmailField from './Email';
import AccountNameField from './AccountName';
import AccountTelephoneNumberField from './AccountTelephoneNumber';
import AddressField from './Address';
import SectionField from './Section';
import SectionPositionField from './SectionPosition';
import TelephoneNumberField from './TelephoneNumber';
import WebSiteField from './WebSite';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldStandard;
}

interface Props {
  block: Block;
  post: FormPost;
}

const standardFieldComponents: {[key: string]: any} = {
  name: NameField,
  email: EmailField,
  account_name: AccountNameField,
  account_telephone_number: AccountTelephoneNumberField,
  address: AddressField,
  section: SectionField,
  section_position: SectionPositionField,
  telephone_number: TelephoneNumberField,
  web_site: WebSiteField,
};

export default ({block, post}: Props) => {
  const blockable = block.formBlockable;
  const FieldComponent = standardFieldComponents[blockable.standardFieldType];

  if (!FieldComponent) return null;

  return <FieldComponent block={block} post={post} />;
};
