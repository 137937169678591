import React from 'react';
import useCondition from '../../../useCondition';
import useFilterModalContext from '../../useFilterModalContext';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import moment from 'moment';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();
  const dateFormat = 'YYYY/MM/DD';

  if (!condition?.trackedAtFrom && !condition?.trackedAtTo) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">最新Webサイト訪問期間</h4>
        <button
          onClick={() => setFilterType('trackedAt')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setCondition({
              trackedAtFrom: '',
              trackedAtTo: '',
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          <>
            {condition?.trackedAtFrom &&
              moment(condition.trackedAtFrom).format(dateFormat)}
            ~
            {condition?.trackedAtTo &&
              moment(condition.trackedAtTo).format(dateFormat)}
          </>
        </span>
      </div>
    </div>
  );
};
