import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'components';
import {
  useUpdateProjectSearchConditionOnWebsitePageMutation,
  useWebsitePagesQuery,
  useProjectQuery,
} from 'api';
import {useHistory, useParams} from 'react-router';
import useSearchCondition from '../../useSearchCondition';
import useContract from 'hooks/useContract';
import {Input, Tooltip} from 'components/antd';
import {Lense} from 'components/Ui/Icon';
import useProjectPath from 'hooks/useProjectPath';
import {NewProjectModal} from 'components/Ui';

export default () => {
  const ref = useRef(null);
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {projectId} = useParams<{projectId: string}>();
  const {websiteType} = useParams<{
    websiteType: string;
  }>();
  const {mediaSearchParams, page, perPage, setQuery, resetQuery} =
    useSearchCondition();
  const {data: {websitePages: {uniqueCompanyCount} = {}} = {}} =
    useWebsitePagesQuery({
      variables: {
        websiteType: websiteType,
        search: mediaSearchParams,
        page,
        perPage,
      },
    });
  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] =
    React.useState(false);

  const {isPreleadFixable, isProjectAddable} = useContract();

  const [updateSearchCondition] =
    useUpdateProjectSearchConditionOnWebsitePageMutation({
      variables: {
        uuid: projectId,
        search: mediaSearchParams,
      },
      onCompleted: () =>
        history.push(
          createPath(
            `projects/${projectId}/search_condition?status=${project.status}`,
          ),
        ),
    });

  // 営業リスト詳細から検索に戻った時に、検索ワードが入力された状態にする。
  useEffect(() => {
    ref.current?.setState({
      value: mediaSearchParams.searchWord,
    });
  });

  return (
    <Container>
      <Search
        type="search"
        name="search"
        placeholder="例）正社員 -派遣社員 or東京勤務"
        prefix={<Lense />}
        onBlur={(e: any) => {
          setQuery({
            searchWord: e.target.value,
            page: 1,
          });
        }}
        onPressEnter={(e: any) => {
          setQuery({
            searchWord: e.target.value,
            page: 1,
          });
        }}
        defaultValue={mediaSearchParams.searchWord}
        ref={ref}
      />

      <ClearButton
        buttonType="default"
        onClick={() => {
          if (confirm('すべてクリアしてよろしいですか？')) {
            resetQuery();
            ref.current?.setState({value: null});
          }
        }}>
        クリア
      </ClearButton>

      <DefaultButton
        buttonType="default"
        onClick={() => updateSearchCondition()}>
        保存
      </DefaultButton>

      {isPreleadFixable && isProjectAddable ? (
        <ActionButton
          buttonType="default"
          disabled={uniqueCompanyCount > 10000}
          onClick={() => setIsNewProjectModalOpen(true)}>
          確定
        </ActionButton>
      ) : isPreleadFixable && !isProjectAddable ? (
        <Tooltip
          title="営業リスト数が上限に達しました。"
          color={`#EB5757`}
          placement="top">
          <DisabledActionButton>確定</DisabledActionButton>
        </Tooltip>
      ) : null}

      <NewProjectModal
        isNewProjectModalOpen={isNewProjectModalOpen}
        setIsNewProjectModalOpen={setIsNewProjectModalOpen}
        project={project}
        searchParams={mediaSearchParams}
        searchType="website_page"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const DefaultButton = styled(Button)`
  margin: auto 8px auto 0;
  height: 36px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e2e6ea;

  span {
    font-weight: bold;
    font-size: 12px;
    color: #222426;
  }
`;

const ActionButton = styled(DefaultButton)`
  background: #68b5fb;

  span {
    color: #ffffff;
  }

  &:hover {
    background: #65aae8;
  }
`;

const ClearButton = styled(DefaultButton)`
  background: #ffffff;
  box-shadow: none;
  border: none;
  margin-right: 32px;
  padding: 0 2px;

  span {
    color: #bdc4cb;
  }
`;

const DisabledActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px 13px 0 0;
  width: 100px;
  height: 36px;
  font-weight: bold;
  background: #e2e6ea;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: #ffffff;
`;

const Search = styled(Input)`
  margin: auto 20px auto 35px;
  width: 230px;
  height: 31px;
  border-radius: 80px;
  .ant-input {
    font-size: 12px;
  }

  &:focus-within {
    background-color: white;
  }

  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;
