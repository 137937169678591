import React from 'react';

function Icon() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.31689 8.54167V16.6667C3.31689 17.1269 3.69909 17.5 4.17055 17.5H7.95104C8.42251 17.5 8.8047 17.1269 8.8047 16.6667V11.0714H13.1949V16.6667C13.1949 17.1269 13.5771 17.5 14.0486 17.5H17.8291C18.3006 17.5 18.6827 17.1269 18.6827 16.6667V8.54167C18.6827 8.27937 18.5562 8.03237 18.3413 7.875L10.9998 2.5L3.65836 7.875C3.4434 8.03237 3.31689 8.27937 3.31689 8.54167Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
