import gql from 'graphql-tag';

export default gql`
  fragment hubspotOwnerFragment on HubspotOwner {
    id
    hubspotId
    firstName
    lastName
    email
  }
`;
