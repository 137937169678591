import React, {Fragment} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Dialog, Transition} from '@headlessui/react';
import {prospectStages} from 'helpers/prospectStages';
import {Quill, QuillWrapper} from 'components/Ui';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {
  ProspectPool,
  ProspectActivity,
  useActionsByProspectPoolQuery,
  useUpdateZoomPhoneWebhookCallProspectActivityMutation,
  useCurrentClientQuery,
} from 'api';
import {ArrowDown} from 'components/Ui/Icon';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  prospectPool: ProspectPool;
  prospectActivity: ProspectActivity;
}

const UpdateModal = ({
  isOpen,
  closeModal,
  prospectPool,
  prospectActivity,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const {data: {actionsByProspectPool = []} = {}} =
    useActionsByProspectPoolQuery({
      variables: {
        prospectPoolUuid: prospectPool?.uuid,
        status: 'open',
      },
      skip: !prospectPool,
      fetchPolicy: 'cache-and-network',
    });

  const {data: {currentClient: {prospectTelStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const openCallActions = actionsByProspectPool.filter(
    (action) => action.actionableType === 'Action::Call',
  );
  const currentStageValue = () => {
    return prospectStages.find((stage) => {
      return stage.name === prospectPool?.stage;
    });
  };
  const [updateZoomPhoneWebhookCallProspectActivity, {loading}] =
    useUpdateZoomPhoneWebhookCallProspectActivityMutation({
      onCompleted: () => closeModal(),
      refetchQueries: ['prospectPool'],
    });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      actionUuid: '',
      clientProspectTelStatusId: null,
      nextActionDate: '',
      stage: currentStageValue()?.id,
      isCompleted: false,
      recordedAt: prospectActivity.createdAt,
      comment: '',
      commentHtml: '',
    },
    validationSchema: Yup.object().shape({
      actionUuid: Yup.string(),
      stage: Yup.number(),
      isCompleted: Yup.boolean(),
      recordedAt: Yup.date().required('必須項目です'),
      nextActionDate: Yup.date(),
      commentHtml: Yup.string(),
    }),
    onSubmit: ({isCompleted, nextActionDate, ...values}) =>
      updateZoomPhoneWebhookCallProspectActivity({
        variables: {
          uuid: prospectPool?.uuid,
          actionUuid: values.actionUuid,
          resourceId: prospectActivity.resourceId,
          nextActionDate: nextActionDate === '' ? null : nextActionDate,
          isCompleted: isCompleted,
          attributes: {
            comment: values.comment,
            commentHtml: values.commentHtml,
            stage: Number(values.stage),
            recordedAt: values.recordedAt,
            clientProspectTelStatusId: values.clientProspectTelStatusId,
          },
        },
      }),
  });

  React.useEffect(() => {
    const actionStartDate: Date = actionsByProspectPool.find(
      (action) => action.uuid === formik.values.actionUuid,
    )?.startDate;

    if (actionStartDate) {
      formik.setFieldValue('nextActionDate', actionStartDate);
    }
  }, [formik.values.actionUuid, actionsByProspectPool]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-xl transform rounded bg-white text-left align-middle transition-all">
                <Dialog.Title
                  as="h4"
                  className="h-10 flex items-center px-4 bg-c-bg text-c-base">
                  ログの登録
                </Dialog.Title>
                <form className="p-4" onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-c-light text-sm font-bold mb-2">
                      ステップ
                    </label>
                    <div className="inline-block relative w-full">
                      <select
                        value=""
                        className="block appearance-none w-full bg-white border border-c-border px-4 py-2 pr-8 rounded leading-tight focus:outline-none text-sm"
                        {...formik.getFieldProps('actionUuid')}>
                        <option value="">未選択</option>
                        {openCallActions.map((action) => (
                          <option key={action.uuid} value={action.uuid}>
                            {action.step.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-6 grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        TELステータス
                      </label>
                      <div className="inline-block relative w-full">
                        <select
                          className="block form-select appearance-none w-full bg-white border border-c-border px-4 py-2 pr-8 rounded leading-tight focus:outline-none text-sm"
                          {...formik.getFieldProps(
                            'clientProspectTelStatusId',
                          )}>
                          <option value="">未選択</option>
                          {prospectTelStatuses.map((telStatus) => (
                            <option key={telStatus.id} value={telStatus.id}>
                              {telStatus.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        ステージ
                      </label>
                      <div className="inline-block relative w-full">
                        <select
                          className="block form-select appearance-none w-full bg-white border border-c-border px-4 py-2 pr-8 rounded leading-tight text-sm"
                          {...formik.getFieldProps('stage')}>
                          <option value="" disabled>
                            未選択
                          </option>
                          {prospectStages.map((stage) => (
                            <option key={stage.id} value={stage.id}>
                              {stage.displayName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6 grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        日時
                      </label>
                      <div className="inline-block relative w-full">
                        <input
                          type="text"
                          disabled
                          {...formik.getFieldProps('recordedAt')}
                          value={moment(formik.values.recordedAt).format(
                            'YYYY-MM-DD HH:mm',
                          )}
                          className="block form-input appearance-none w-full bg-white border-c-border px-4 py-2 pr-8 rounded leading-tight focus:outline-none disabled:bg-c-bg disabled:text-c-light text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        ネクストアクション日
                      </label>
                      <div className="inline-block relative w-full">
                        <input
                          type="date"
                          disabled={
                            formik.values.isCompleted ||
                            !formik.values.actionUuid
                          }
                          className="block form-input appearance-none w-full bg-white border-c-border px-4 py-2 rounded leading-tight focus:outline-none text-sm disabled:bg-c-bg disabled:text-c-light"
                          {...formik.getFieldProps('nextActionDate')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-16">
                    <QuillContainer>
                      <QuillWrapper
                        style={{
                          borderRight: 'none',
                          borderBottom: 'none',
                          borderLeft: 'none',
                          borderRadius: 0,
                        }}>
                        <Quill
                          placeholder="コメント"
                          theme="snow"
                          onChange={(content, delta, source, editor) => {
                            formik.setFieldValue('commentHtml', content);
                            formik.setFieldValue('comment', editor.getText());
                          }}
                          modules={{
                            toolbar: [
                              {header: [1, 2, 3, false]},
                              'bold',
                              'italic',
                              'underline',
                              'code-block',
                              'link',
                            ],
                          }}
                          bounds={'#quill-container'}
                        />
                        <div id="quill-container" />
                      </QuillWrapper>
                    </QuillContainer>
                  </div>
                  {formik.values.actionUuid ? (
                    <div className="mb-4 flex justify-end relative">
                      <button
                        type="submit"
                        disabled={!formik.isValid || loading}
                        className="inline-flex items-center py-2 px-4 font-medium text-white bg-c-primary rounded-l hover:opacity-75 cursor-pointer text-sm">
                        {formik.values.isCompleted
                          ? 'ステップを完了して登録'
                          : 'ステップを完了しないでログのみ登録'}
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex items-center py-2 px-2 text-sm text-white bg-c-primary rounded-r cursor-pointer border-l hover:opacity-75 border-[rgba(255,255,255,0.5)] [&>svg]:w-4"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <ArrowDown color="white" />
                      </button>
                      <div
                        className={`absolute right-0 top-[42px] z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
                          isMenuOpen ? '' : 'hidden'
                        }`}>
                        <div>
                          <div
                            className="text-gray-700 block px-8 py-2 cursor-pointer text-left hover:bg-gray-100"
                            onClick={() => {
                              formik.setFieldValue('isCompleted', false);
                              setIsMenuOpen(false);
                            }}>
                            <h3 className="text-sm">
                              ステップを完了しないでログのみ登録
                            </h3>
                            <p className="text-xs">
                              アクションは完了せずアクションボードに残ります
                            </p>
                          </div>
                          <div
                            className="text-gray-700 block px-8 py-2 cursor-pointer text-left hover:bg-gray-100"
                            onClick={() => {
                              formik.setFieldValue('isCompleted', true);
                              setIsMenuOpen(false);
                            }}>
                            <h3>ステップを完了して登録</h3>
                            <p className="text-xs">
                              アクションは完了しアクションボードから削除されます
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mb-4 flex justify-end relative">
                      <button
                        type="submit"
                        disabled={!formik.isValid || loading}
                        className="inline-flex items-center justify-center h-10 px-6 font-medium text-white bg-c-primary rounded hover:opacity-75 cursor-pointer text-sm">
                        登録
                      </button>
                    </div>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const QuillContainer = styled.div`
  .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
  }

  .ql-editor {
    max-height: 300px;
  }
`;

export default UpdateModal;
