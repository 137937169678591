import React from 'react';
import {Action} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';

interface Props {
  row: Row<Action>;
}

const Cell = ({row}: Props) => {
  const action = row.original;
  const {user} = action;

  return (
    <Container>
      <div>
        {user?.lastName} {user?.firstName}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default Cell;
