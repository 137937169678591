import React from 'react';
import {useParams} from 'react-router-dom';
import {useSalesforceDealRecordTypesQuery} from 'api';
import useSearchParams from '../../../../useSearchParams';

const RecordType = () => {
  const {query, setQuery} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();
  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {
        poolId: poolId,
      },
    });

  return (
    <div className="flex flex-col gap-2">
      <div className="text-c-base font-bold">レコードタイプ</div>
      <select
        className="form-select border-c-border rounded text-base w-full"
        onChange={(e) => {
          setQuery({
            ...query,
            salesforceDealRecordTypeId: e.target.value,
          });
        }}
        value={query?.salesforceDealRecordTypeId}>
        <option value="" />
        {salesforceDealRecordTypes.map((salesforceDealRecordType) => (
          <option
            value={salesforceDealRecordType.id}
            key={salesforceDealRecordType.id}>
            {salesforceDealRecordType.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RecordType;
