import gql from 'graphql-tag';

export default gql`
  fragment contractFragment on Contract {
    id
    clientId
    month
    isValid
    mailPlanType
    mailPlanTypeText
    mailPlan
    mailPlanText
    mailLimit
    mailCount
    listPlan
    listPlanText
    listLimit
    listCount
    preleadPlan
    preleadPlanText
  }
`;
