import React, {FC} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import Item, {ItemProps} from './Item';

const SortableItem: FC<ItemProps> = (props) => {
  const {isDragging, setNodeRef, transform, transition} = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      id={props.id}
      {...props}
    />
  );
};

export default SortableItem;
