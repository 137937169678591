import React, {ComponentProps} from 'react';

interface Props extends ComponentProps<'button'> {
  active?: boolean;
}

export const Button: React.FC<Props> = ({
  onClick,
  children,
  active = false,
}) => {
  return (
    <button
      className={`flex h-8 px-4 cursor-pointer items-center justify-center rounded border border-c-border font-bold ${active ? ' bg-white text-c-base' : 'bg-c-bg text-c-light'}`}
      onClick={onClick}>
      {children}
    </button>
  );
};
