import React from 'react';
import {MaintenanceProspectPool} from 'api';
import Prospect from './Prospect';

type Props = {
  maintenanceProspectPool: MaintenanceProspectPool;
};

const TargetProspect = ({maintenanceProspectPool}: Props) => {
  return (
    <div className="flex flex-col">
      <div className="flex-1 overflow-y-scroll bg-white flex flex-col p-5">
        <h1 className="w-full text-base font-normal">名寄せ先</h1>
        <Prospect
          key={maintenanceProspectPool.id}
          prospectPool={maintenanceProspectPool.prospectPool}
        />
      </div>
    </div>
  );
};

export default TargetProspect;
