import React from 'react';
import {Transition} from '@headlessui/react';
import LeadSourceFilter from './LeadSource';
import OriginalLeadSourceFilter from './OriginalLeadSource';
import StageFilter from './Stage';
import TagFilter from './Tag';
import ExcludedTagFilter from './ExcludedTag';
import MailAddressFilter from './MailAddress';
import MonthsSinceActionFilter from './MonthsSinceAction';
import FormFilter from './Form';
import CollectedFormUrlFilter from './CollectedFormUrl';
import CustomizeItemFilter from './CustomizeItem';
import CityFilter from './City';
import SubCategoryFilter from './SubCategory';
import ListingMarketFilter from './ListingMarket';
import EmloyeeNumberFilter from './EmployeeNumber';
import CapitalFundFilter from './CapitalFund';
import SalesforceLeadFilter from './SalesforceLead';
import SalesforceContactFilter from './SalesforceContact';
import SalesfroceDealFilter from './SalesforceDeal';
import HubspotDealFilter from './HubspotDeal';
import Header from '../Header';
import UserFilter from './User';
import useFilterModalContext from '../useFilterModalContext';
import InflowDateFilter from './InflowDate';
import TrackedAtFilter from './TrackedAt';
import ClientPhaseProspectFilter from './ClientPhaseProspect';

const filterComponents: {[key: string]: any} = {
  leadSource: LeadSourceFilter,
  originalLeadSource: OriginalLeadSourceFilter,
  stage: StageFilter,
  tag: TagFilter,
  excludedTag: ExcludedTagFilter,
  mailAddress: MailAddressFilter,
  monthsSinceAction: MonthsSinceActionFilter,
  form: FormFilter,
  collectedFormUrl: CollectedFormUrlFilter,
  customizeItem: CustomizeItemFilter,
  city: CityFilter,
  subCategory: SubCategoryFilter,
  listingMarket: ListingMarketFilter,
  employeeNumber: EmloyeeNumberFilter,
  capitalFund: CapitalFundFilter,
  salesforceLead: SalesforceLeadFilter,
  salesforceContact: SalesforceContactFilter,
  salesforceDeal: SalesfroceDealFilter,
  hubspotDeal: HubspotDealFilter,
  user: UserFilter,
  inflowDate: InflowDateFilter,
  trackedAt: TrackedAtFilter,
  clientPhaseProspect: ClientPhaseProspectFilter,
};

const SkeletonFilter = () => (
  <>
    <Header title="" />
    <div className="bg-c-bg flex-1 rounded-b" />
  </>
);

const Filter = () => {
  const {filterType} = useFilterModalContext();
  const FilterComponent = filterComponents[filterType];

  return (
    <Transition
      show={!!FilterComponent}
      enter="transition-all duration-150"
      enterFrom="left-full"
      enterTo="left-0"
      leave="transition-all duration-150"
      leaveFrom="left-0"
      leaveTo="left-full"
      className="w-full h-full flex flex-col absolute top-0"
      as="div">
      {FilterComponent ? <FilterComponent /> : <SkeletonFilter />}
    </Transition>
  );
};

export default Filter;
