import gql from 'graphql-tag';
import azureRecognizedPhraseFragment from './recognizedPhrase';

export default gql`
  fragment azureTranscriptionFileFragment on AzureTranscriptionFile {
    id
    azureRecognizedPhrases {
      ...azureRecognizedPhraseFragment
    }
  }
  ${azureRecognizedPhraseFragment}
`;
