import React from 'react';
import styled from 'styled-components';
import {ArrowDown, Setting} from 'components/Ui/Icon';
import {Button} from 'components/antd';
import {useParams} from 'react-router';
import {
  usePreleadProjectQuery,
  useProjectGroupPreleadCustomizeItemsQuery,
} from 'api';
import useProject from 'hooks/useProject';
import useClientUser from 'hooks/useClientUser';
import NewModal from './NewModal';
import Item from './Item';
import EditModal from './EditModal';

export default () => {
  const [open, setOpen] = React.useState(false);
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();
  const {isOwnProject} = useProject(projectId);
  const {isAdmin} = useClientUser();

  const {data: {projectGroupPreleadCustomizeItems = []} = {}} =
    useProjectGroupPreleadCustomizeItemsQuery({
      variables: {uuid: projectId},
    });

  const {data: {preleadProject = {}} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
  });

  return (
    <Container>
      <Header open={open} onClick={() => setOpen(!open)}>
        <span>{`グループ項目 : ${preleadProject.project?.projectGroup?.name}`}</span>
        <ArrowDown />
      </Header>
      {open && (
        <Contents>
          <Items>
            {projectGroupPreleadCustomizeItems
              .filter(
                (item) => item.isDisplay === true && item.isDeleted === false,
              )
              .map((item) => (
                <Item
                  preleadProject={preleadProject}
                  projectGroupPreleadCustomizeItem={item}
                  key={item.id}
                />
              ))}
          </Items>
          {isOwnProject && isAdmin && (
            <CustomItemOptions>
              <Button size="small" onClick={() => setNewModalVisible(true)}>
                + 項目を追加
              </Button>
              <Button size="small" onClick={() => setEditModalVisible(true)}>
                <Setting width="18" height="18" />
              </Button>
            </CustomItemOptions>
          )}
        </Contents>
      )}
      {newModalVisible && (
        <NewModal visible={newModalVisible} setVisible={setNewModalVisible} />
      )}
      {editModalVisible && (
        <EditModal
          visible={editModalVisible}
          setVisible={setEditModalVisible}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #dadce0;
  padding: 0 22px;
`;

const CustomItemOptions = styled.div`
  display: flex;
  gap: 5px;
`;

const Header = styled.div<{open: boolean}>`
  display: flex;
  width: 100%;
  height: 42px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > span {
    color: #bdc4cb;
  }

  & > svg {
    height: 1rem;
    width: auto;
    transform: rotate(${({open}) => (open ? '180deg' : '0deg')});
  }
`;

const Contents = styled.div`
  margin-bottom: 2rem;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
