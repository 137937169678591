import React from 'react';
import useChartData from './useChartData';
import useTableData from './useTableData';
import {BarChart} from '../../../components/Chart';
import {Table} from '../../../components/Table';

export default () => {
  const chart = useChartData();
  const table = useTableData();

  return (
    <div className="flex flex-col gap-8">
      {chart && (
        <BarChart
          chart={chart}
          className="h-[540px] border border-c-border"
          barProps={{barSize: 'thin'}}
        />
      )}
      <Table table={table} />
    </div>
  );
};
