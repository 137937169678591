import React from 'react';
import {Menu} from '@headlessui/react';

type DropdownItem = {
  label: string;
  value: string;
};

interface Props {
  value?: string;
  items: DropdownItem[];
  onSelect: (value: string) => void;
}

export const Dropdown = ({value, items, onSelect}: Props) => {
  const buttonText =
    items.find((item) => item.value === value)?.label || '選択してださい';

  return (
    <Menu as="div" className="relative inline-block text-left w-auto">
      <Menu.Button className="inline-flex form-select border-c-border rounded-sm focus:outline-none text-sm h-8 py-0 items-center">
        {buttonText}
      </Menu.Button>
      <Menu.Items className="absolute left-1 min-w-[10rem] w-auto origin-top-left rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        {items.map((item) => (
          <Menu.Item key={item.value}>
            <button
              onClick={() => onSelect(item.value)}
              className="group flex w-full text-left items-center py-2 rounded px-2 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-4 &>svg]:mr-2">
              {item.label}
            </button>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};
