import React from 'react';
import {FormBlock, FormBlockFieldCustomizeItem} from 'api';
import TextField from './Text';
import NumberField from './Number';
import DateField from './Date';
import SelectField from './Select';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldCustomizeItem;
}

interface Props {
  block: Block;
}

const customizeItemFieldComponents: {[key: string]: any} = {
  text: TextField,
  number: NumberField,
  date: DateField,
  select: SelectField,
};

export default ({block}: Props) => {
  const blockable = block.formBlockable;
  const FieldComponent =
    customizeItemFieldComponents[blockable.poolProspectCustomizeItem.dataType];

  if (!FieldComponent) return null;

  return <FieldComponent block={block} />;
};
