import React from 'react';

function Icon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19" r="19" fill="#F3F5F7" />
      <path
        d="M19 18C21.2091 18 23 16.2091 23 14C23 11.7909 21.2091 10 19 10C16.7909 10 15 11.7909 15 14C15 16.2091 16.7909 18 19 18Z"
        stroke="#BDC4CB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 28V24C11 22.8954 11.8954 22 13 22H25C26.1046 22 27 22.8954 27 24V28"
        stroke="#BDC4CB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
