import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Form} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useCreateSalesforceDealMutation,
  useSalesforceUsersQuery,
  useSalesforceOpportunityStagesQuery,
  ProspectPool,
  useCurrentUserQuery,
} from 'api';
import {Contact} from './Contact';
import {Deal} from './Deal';
import {Tab} from './Tab';
import useSalesforceModalContext from '../../../useSalesforceModalContext';
import Back from '../../Back';
import Footer from '../../Footer';

interface Props {
  prospectPool: ProspectPool;
}

interface FormikValues {
  salesforceDeal: {
    name: string;
    salesforceUserId: string;
    salesforceOpportunityStageId: string;
    closeDate: moment.Moment;
    salesforceDealRecordTypeId: string;
  };
  salesforceContact: {
    salesforceContactRecordTypeId: string;
  };
  deal: {
    userId: string;
    date: moment.Moment;
  };
}

const DealForm = ({prospectPool = {}}: Props) => {
  const [tab, setTab] = React.useState('deal');

  const [dealAddition, setDealAddition] = React.useState({});
  const [contactAddition, setContactAddition] = React.useState({});

  const {poolId, browserHistory} = useSalesforceModalContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  const {data: {salesforceOpportunityStages = []} = {}} =
    useSalesforceOpportunityStagesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const {data: {currentUser = null} = {}} = useCurrentUserQuery({});

  const [create, {loading, data}] = useCreateSalesforceDealMutation({
    onCompleted: (data) => {
      if (!data.createSalesforceDeal.error) {
        setContactAddition({});
        setDealAddition({});
        formik.resetForm();
        prospectPool = data?.createSalesforceDeal?.prospectPool;
        browserHistory.push(
          `/pools/${poolId}/prospects/${prospectPool?.uuid}/timeline`,
        );
      }
    },
    refetchQueries: ['prospectPools'],
  });

  const today = React.useMemo(() => moment(), []);

  const initialValues: FormikValues = {
    salesforceDeal: {
      name: '',
      salesforceUserId: salesforceUsers.length > 0 ? salesforceUsers[0].id : '',
      salesforceOpportunityStageId:
        salesforceOpportunityStages.length > 0
          ? salesforceOpportunityStages[0].id
          : '',
      closeDate: today,
      salesforceDealRecordTypeId: '',
    },
    salesforceContact: {
      salesforceContactRecordTypeId: '',
    },
    deal: {
      userId: currentUser?.id ?? '',
      date: today,
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      salesforceDeal: Yup.object().shape({
        name: Yup.string().required('必須項目です'),
        salesforceUserId: Yup.string().required('必須項目です'),
        salesforceOpportunityStageId: Yup.string().required('必須項目です'),
        closeDate: Yup.date().required('必須項目です'),
      }),
      deal: Yup.object().shape({
        date: Yup.date().required('必須項目です'),
      }),
    }),
    onSubmit: (values) => {
      const attributes = {
        uuid: prospectPool.uuid,
        salesforceContactAttributes: {
          ...values.salesforceContact,
          addition: {...contactAddition},
        },
        salesforceDealAttributes: {
          ...values.salesforceDeal,
          addition: {...dealAddition},
        },
        dealAttributes: {
          ...values.deal,
        },
      };
      create({
        variables: {
          ...attributes,
        },
      });
    },
  });

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative w-full flex-1 overflow-y-auto px-8 py-6">
        <Back label="商談" />
        <div className="mt-4 w-full rounded bg-white p-4">
          {data?.createSalesforceDeal?.error && (
            <Error>{data?.createSalesforceDeal?.error}</Error>
          )}
          <Tab state={tab} setState={setTab} />
          <Form layout="vertical">
            {tab == 'deal' ? (
              <Deal
                formik={formik}
                addition={dealAddition}
                setAddition={setDealAddition}
              />
            ) : (
              <Contact
                formik={formik}
                addition={contactAddition}
                setAddition={setContactAddition}
              />
            )}
          </Form>
        </div>
      </div>
      <Footer
        onClick={() => formik.submitForm()}
        label="登録"
        disabled={loading || !formik.isValid || !formik.dirty}
      />
    </div>
  );
};

const Error = styled.div`
  color: red;
  margin-bottom: 8px;
`;

export default DealForm;
