import React, {useState} from 'react';
import styled from 'styled-components';
import {Checkbox} from 'components/Ui';
import {Button, Form, Input, Upload} from 'components/antd';
import {useFormik} from 'formik';

import * as Yup from 'yup';
import {message} from 'components/antd';
import CircleCloseIcon from '../CircleCloseIcon';
import {
  useCurrentClientQuery,
  useTestSalesforceOAuthMutation,
  useUpdateSalesforceSettingMutation,
} from 'api';

const validateSchema = Yup.object().shape({
  salesforceClientId: Yup.string().required('必須項目です'),
  username: Yup.string().required('必須項目です'),
});

export default () => {
  const [testing, setTesting] = useState(false);
  const [testSuccess, setTestSuccess] = useState(false);
  const [alreadyTested, setAlreadyTested] = useState(false);

  const {data: {currentClient: {salesforceSetting = {}} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const [updateSalesforceSettingMutation, {loading}] =
    useUpdateSalesforceSettingMutation({
      onCompleted: () => {
        if (testing) {
          testIntegration({});
        } else {
          message.success('更新しました');
        }
      },
    });

  const updateSalesforceSetting = () => {
    updateSalesforceSettingMutation({
      variables: {
        attributes: {
          ...values,
        },
      },
    });
  };

  const [testIntegration, {loading: testLoading}] =
    useTestSalesforceOAuthMutation({
      onCompleted: ({testSalesforceOAuth: {success}}) => {
        setTesting(false);
        setAlreadyTested(true);
        setTestSuccess(success);
      },
    });

  const clickTestButton = () => {
    setTesting(true);
    handleSubmit();
  };

  const changeSalesforceActiveButton = () => {
    values.isActive = !values.isActive;
    updateSalesforceSettingMutation({
      variables: {
        attributes: {
          isActive: values.isActive,
        },
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isActive: salesforceSetting?.isActive || false,
      salesforceClientId: salesforceSetting?.salesforceClientId || '',
      username: salesforceSetting?.username || '',
      privateKeyFile: null,
      leadStatus: salesforceSetting?.leadStatus || '',
      leadSource: salesforceSetting?.leadSource || '',
      preleadUrlFieldName: salesforceSetting?.preleadUrlFieldName || '',
      addTelephoneNumber: salesforceSetting?.addTelephoneNumber || false,
      addWebSite: salesforceSetting?.addWebSite || false,
      addEmployeeNumber: salesforceSetting?.addEmployeeNumber || false,
      addSales: salesforceSetting?.addSales || false,
      addCategory: salesforceSetting?.addCategory || false,
    },
    validationSchema: validateSchema,
    onSubmit: () => {
      updateSalesforceSetting();
    },
  });

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const isFormValid =
    isValid && (salesforceSetting.isPrivateKeyExist || values.privateKeyFile);

  return (
    <>
      <TitleSection>
        <TitleWrapper>
          <Title>Salesforce</Title>
          <TitleNote>Salesforce連携の設定を行います。</TitleNote>
        </TitleWrapper>
        {values.isActive ? (
          <UnavailableButton onClick={changeSalesforceActiveButton}>
            <CircleCloseIcon />
            無効にする
          </UnavailableButton>
        ) : (
          <AvailableButton onClick={changeSalesforceActiveButton}>
            有効にする
          </AvailableButton>
        )}
      </TitleSection>

      <FormWrapper>
        <FormNote>Salesforce連携に必要な各種項目を設定してください。</FormNote>
        <Form
          labelCol={{span: 4}}
          wrapperCol={{span: 12}}
          onFinish={handleSubmit}>
          <FormSection>
            <h2>認証情報</h2>
            <Form.Item
              label="コンシューマ鍵"
              required
              validateStatus={
                errors.salesforceClientId && touched.salesforceClientId
                  ? 'error'
                  : ''
              }
              help={
                errors.salesforceClientId && touched.salesforceClientId
                  ? errors.salesforceClientId
                  : ''
              }>
              <Input
                name="salesforceClientId"
                value={values.salesforceClientId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              label="ユーザ名"
              required
              validateStatus={
                errors.username && touched.username ? 'error' : ''
              }
              help={errors.username && touched.username ? errors.username : ''}>
              <Input
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item label="秘密鍵" required>
              <Upload
                disabled={loading}
                customRequest={() => false}
                showUploadList={false}
                iconRender={() => null}
                onChange={(info) => {
                  const file = info.file.originFileObj;
                  setFieldValue('privateKeyFile', file);
                }}>
                <Button>アップロード</Button>{' '}
                {values.privateKeyFile?.name ||
                  (salesforceSetting?.isPrivateKeyExist
                    ? '設定済み'
                    : '未設定')}
              </Upload>
            </Form.Item>
          </FormSection>

          <FormSection>
            <h2>リード項目設定値</h2>
            <Form.Item label="ステータス">
              <Input
                name="leadStatus"
                value={values.leadStatus}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item label="リードソース">
              <Input
                name="leadSource"
                value={values.leadSource}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
          </FormSection>

          <FormSection>
            <h2>カスタム項目</h2>
            <Form.Item label="プレリードURL用フィールド名">
              <Input
                name="preleadUrlFieldName"
                value={values.preleadUrlFieldName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
          </FormSection>

          <FormSection>
            <h2>追加設定項目</h2>
            <Form.Item>
              <CheckboxGroup>
                <div>
                  <Checkbox
                    name="addTelephoneNumber"
                    checked={values.addTelephoneNumber}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'addTelephoneNumber',
                        e.target.checked,
                      );
                    }}>
                    電話番号
                  </Checkbox>
                  <Checkbox
                    name="addWebSite"
                    checked={values.addWebSite}
                    onChange={(e) => {
                      formik.setFieldValue('addWebSite', e.target.checked);
                    }}>
                    URL
                  </Checkbox>
                  <Checkbox
                    name="addEmployeeNumber"
                    checked={values.addEmployeeNumber}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'addEmployeeNumber',
                        e.target.checked,
                      );
                    }}>
                    従業員数
                  </Checkbox>
                </div>

                <div>
                  <Checkbox
                    name="addSales"
                    checked={values.addSales}
                    onChange={(e) => {
                      formik.setFieldValue('addSales', e.target.checked);
                    }}>
                    売上
                  </Checkbox>
                  <Checkbox
                    name="addCategory"
                    checked={values.addCategory}
                    onChange={(e) => {
                      formik.setFieldValue('addCategory', e.target.checked);
                    }}>
                    業種
                  </Checkbox>
                </div>
              </CheckboxGroup>
            </Form.Item>
          </FormSection>
          <ButtonSection>
            <SaveButton
              htmlType="submit"
              loading={loading}
              disabled={
                !formik.dirty || !isFormValid || loading || testLoading
              }>
              保存
            </SaveButton>
            <TestButton
              type="primary"
              loading={testLoading}
              disabled={!isFormValid || loading || testLoading}
              onClick={clickTestButton}>
              テスト送信
            </TestButton>
            {alreadyTested &&
              (testSuccess ? (
                <TestSuccess>success!</TestSuccess>
              ) : (
                <TestError>error</TestError>
              ))}
          </ButtonSection>
        </Form>
      </FormWrapper>
    </>
  );
};

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 22px;
  border-bottom: 1px solid #e2e6ea;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.15px;
  margin-bottom: 0;
`;

const TitleNote = styled.div`
  font-size: 14px;
`;

const AvailableButton = styled.button`
  background-color: #169e34;
  color: #fff;
  height: 39px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  margin: 0 0 0 auto;
  cursor: pointer;
`;

const UnavailableButton = styled(Button)`
  display: flex;
  align-items: center;
  border: none;
  margin: 0 0 0 auto;

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #69717a;
  }
`;

const HeadSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #e2e6ea;
  padding: 13px 20px;
  margin-top: 14px;

  > span {
    font-weight: bold;
  }

  > button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #69717a;
    font-size: 14px;
    font-weight: bold;

    svg {
      margin-right: 6px;
    }
  }
`;

const FormWrapper = styled.div`
  margin-top: 25px;
  padding-bottom: 30px;

  form {
    margin-top: 30px;
    .ant-form-item {
      margin: 0;
      display: block;
      // height: 95px;

      .ant-form-item-label {
        display: inline;
        label {
          height: 26px;
          font-weight: bold;
          font-size: 14px;
          color: #bdc4cb;
        }
      }
      .ant-form-item-control {
        max-width: 100% !important;
      }
      input {
        width: 600px;
        height: 48px;
        background: #f3f5f7;
        border: none;
      }
    }
  }
`;

const FormSection = styled.div`
  margin-bottom: 50px;
  h2 {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #495058;
  }
`;

const FormNote = styled.div`
  font-size: 14px;
`;

const CheckboxGroup = styled.div`
  div {
    display: flex;
    &:first-child {
      margin-top: 10px;
    }
    &:last-child {
      margin-top: 13px;
    }

    .ant-checkbox-wrapper {
      // width: 160px;
      margin-right: 28px;
      line-height: 20px;
    }
    label {
      width: 80px;
    }
  }
`;

const ButtonSection = styled.div`
  margin-top: 40px;
  display: flex;
`;

const DefaultButton = styled(Button)`
  margin-right: 20px;
  width: 187px;
  height: 48px;
  border-radius: 4px;
  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

const SaveButton = styled(DefaultButton)``;

const TestButton = styled(DefaultButton)``;

const TestSuccess = styled.div`
  margin: auto 0;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #108429;
`;

const TestError = styled(TestSuccess)`
  color: #c90e19;
`;
