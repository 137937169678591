import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import useSearchParams from '../../../useSearchParams';
import {useClientPreleadCustomizeItemsQuery} from 'api';

interface Props {
  filter: string;
  id: string;
  value: string[];
}

const CustomizeItemSelectFilter = ({filter, id, value}: Props) => {
  const {setQuery} = useSearchParams();
  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery();

  const clientPreleadCustomizeItem = clientPreleadCustomizeItems.find(
    (item) => item.id === id,
  );

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={value || []}
      onChange={(ids) => setQuery({[filter]: ids})}>
      {clientPreleadCustomizeItem?.selectOptions.map((tag) => (
        <TagSelect.Option
          title={tag.label}
          value={tag.id}
          key={tag.id}
          label={tag.label}>
          {tag.label}
        </TagSelect.Option>
      ))}
    </Select>
  );
};

const TagSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

export default CustomizeItemSelectFilter;
