import React from 'react';
import Filter from './Filter';
import Table from './Table';

export default () => {
  return (
    <div className="p-8 min-h-screen bg-c-bg overflow-y-scroll">
      <h1 className="flex text-xl font-bold">企業 - メール配信実績</h1>
      <div className="mb-4">
        <Filter />
      </div>
      <Table />
    </div>
  );
};
