import {Plus, Setting} from 'components/Ui/Icon';
import useClientUser from 'hooks/useClientUser';
import React from 'react';
import Card from './Card';
import {ProspectPool} from 'api';
import Detail from './Detail';
import {useHistory, useParams} from 'react-router';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {isMember} = useClientUser();
  const {dealId} = useParams<{dealId: string}>();
  const history = useHistory();

  const salesforceDeals = prospectPool?.salesforceDeals;
  const selectedDeal = salesforceDeals?.find(
    (deal) => String(deal.id) === dealId,
  );

  return (
    <>
      <div className="flex w-full flex-col gap-6">
        <div className="flex items-center justify-between">
          <button
            onClick={() => history.push('/deals/new')}
            className="flex h-8 w-24 cursor-pointer items-center justify-center gap-[2px] rounded border border-c-border bg-white text-c-light"
          >
            <Plus className="h-4 w-auto" />
            <span className="font-sm">商談を追加</span>
          </button>
          {isMember && (
            <button
              onClick={() => history.push('/deals/settings')}
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded border border-c-border bg-white text-c-light"
            >
              <Setting className="h-4 w-auto" />
            </button>
          )}
        </div>
        <div className="grid grid-cols-3 gap-x-4 gap-y-2">
          {salesforceDeals?.map((deal) => (
            <Card key={deal.id} deal={deal} />
          ))}
        </div>
        <Detail deal={selectedDeal} />
      </div>
    </>
  );
};
