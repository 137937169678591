import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowSalesforceContactSelectSearchConditionMutation,
  useWorkflowQuery,
  SalesforceContactFieldSelectOption,
  useSalesforceContactFieldQuery,
} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  contact_field_id: string;
};

const ContactSelectOption: React.FC<{condition: SelectSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {
    data: {
      salesforceContactField: {salesforceContactFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceContactFieldQuery({
    variables: {contactFieldId: condition.contact_field_id},
    skip: !condition.contact_field_id,
  });

  const [updateWorkflowSalesforceContactSelectSearchCondition] =
    useUpdateWorkflowSalesforceContactSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const contactFieldSelectOptions = () => {
    return salesforceContactFieldSelectOptions.map(
      (item: SalesforceContactFieldSelectOption) => {
        return {
          value: item.id,
          label: item.label,
        };
      },
    );
  };

  return (
    <Select
      options={contactFieldSelectOptions() || []}
      disabled={
        !isConditionUpdatable || !condition.contact_field_id || !isMember
      }
      style={{width: 200}}
      value={condition.field_select_option_id ?? ''}
      onChange={(value: string) => {
        updateWorkflowSalesforceContactSelectSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              contactFieldId: condition.contact_field_id,
              fieldType: condition.field_type,
              fieldSelectOptionId: value,
            },
          },
        });
      }}
    />
  );
};

export default ContactSelectOption;
