import moment from 'moment';
import React from 'react';
import {
  NumberParam,
  ArrayParam,
  useQueryParams,
  StringParam,
  ObjectParam,
  DelimitedArrayParam,
  withDefault,
} from 'use-query-params';

const PAGE_SIZE = 20;

const searchParamsDef = {
  assignees: DelimitedArrayParam,
  dueDate: withDefault(ObjectParam, {
    from: moment().format('YYYY/MM/DD'),
    to: moment().format('YYYY/MM/DD'),
  }),
  statuses: withDefault(ArrayParam, []),
  priorities: ArrayParam,
  types: ArrayParam,
  page: NumberParam,
  orderOfDueDate: withDefault(StringParam, 'asc'),
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  const searchParams = {
    assignees: query.assignees,
    dueDate: query.dueDate,
    statuses: query.statuses,
    priorities: query.priorities,
    types: query.types,
  };

  React.useEffect(() => {
    setQuery({
      ...query,
    });
  }, []);

  let numberOfFiltersInUse = 0;
  if (query.assignees?.length > 0) numberOfFiltersInUse++;
  if (query.priorities?.length > 0) numberOfFiltersInUse++;
  if (query.types?.length > 0) numberOfFiltersInUse++;

  return {
    query,
    setQuery,
    page,
    perPage: PAGE_SIZE,
    searchParams,
    numberOfFiltersInUse,
  };
};

export default useSearchParams;
