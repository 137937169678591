import {useEffect, useMemo} from 'react';
import {FormikProps} from 'formik';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {
  Content,
  ContentWebsiteAttributes,
  useUpdateContentWebsiteMutation,
} from 'api';
import useDebounce from 'hooks/useDebounce';

export default (
  content: Content,
  formik: FormikProps<ContentWebsiteAttributes>,
) => {
  const {id} = useParams<{id: string}>();
  const [update] = useUpdateContentWebsiteMutation();

  const debouncedValue = useDebounce<ContentWebsiteAttributes>(
    formik.values,
    1000,
  );

  useEffect(() => {
    update({
      variables: {id, attributes: debouncedValue},
    });
  }, [debouncedValue]);

  const saving = useMemo(
    () => !_.isEqual({url: content.website.url}, formik.values),
    [content.website.url, formik.values],
  );

  return {saving};
};
