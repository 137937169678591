import React from 'react';
import styled from 'styled-components';
import {
  ProspectPool,
  useMailThreadsByProspectPoolQuery,
  useImportMailProspectPoolMutation,
} from 'api';
import ListItem from './Item';
import {Pagination} from 'components/antd';
import {Button} from 'components/antd';
import Form from '../Form';
import {Refresh} from 'components/Ui/Icon';

interface Props {
  prospectPool: ProspectPool;
  page: number;
  setPage: (page: number) => void;
}

export default ({prospectPool, page, setPage}: Props) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const {
    data: {
      mailThreadsByProspectPool: {mailThreads = [], pagination = {}} = {},
    } = {},
  } = useMailThreadsByProspectPoolQuery({
    variables: {
      uuid: prospectPool?.uuid,
      page,
      perPage: 10,
    },
    skip: !prospectPool,
    fetchPolicy: 'cache-and-network',
  });
  const [importMail, {loading}] = useImportMailProspectPoolMutation({
    variables: {uuid: prospectPool.uuid},
    refetchQueries: ['mailThreadsByProspectPool'],
  });

  return (
    <Container>
      <Header>
        <Button
          type="text"
          onClick={() => importMail()}
          disabled={loading}
          icon={<Refresh spin={loading} />}
        />
        <hr />
        <Pagination
          size="small"
          simple
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount || 0}
          current={pagination.currentPage || 1}
          pageSize={10}
          onChange={(page: number) => setPage(page)}
          style={{flex: '1'}}
        />
        <Button onClick={() => setModalVisible(true)}>+ Eメール作成</Button>
      </Header>
      {mailThreads.map((mailThread) => (
        <ListItem mailThread={mailThread} key={mailThread.id} />
      ))}
      <Form
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        prospectPool={prospectPool}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  border-bottom: solid 1px #e2e6ea;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  hr {
    height: 16px;
    width: 1px;
    border-width: 0;
    color: #e1e6eb;
    background-color: #e1e6eb;
    margin: 0 0.5rem;
  }
`;
