import React from 'react';
import moment from 'moment';
import {
  ProspectActivity,
  ProspectPool,
  ZoomPhoneWebhookCallProspectActivity,
} from 'api';
import {Call, DotMenuSide, Edit} from 'components/Ui/Icon';
import {ZoomPhoneBadge} from 'components/Ui/Icon';
import ZoomPhone from './ZoomPhone';
import {Menu, Transition} from '@headlessui/react';
import ReUpdateModal from './ReUpdateModal';
import linkifyHtml from 'linkify-html';

interface CallActivity extends ProspectActivity {
  resource: ZoomPhoneWebhookCallProspectActivity;
}

interface Props {
  prospectActivity: CallActivity;
  prospectPool: ProspectPool;
}

export default ({prospectActivity, prospectPool}: Props) => {
  const {resource, user, action} = prospectActivity;
  const {stageText, commentHtml, clientProspectTelStatus} = resource;
  const {callerName, calleeName} = resource.zoomPhoneWebhookCall;
  const [isOpen, setIsOpen] = React.useState(false);
  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <Call />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-4 min-h-[32px]">
          <h3 className="flex-1 text-c-primary font-bold">
            {action?.step?.name}
          </h3>
          <span className="text-c-light">
            {user?.lastName} {user?.firstName}
          </span>
          <time className="text-c-light">
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex w-full justify-center bg-white px-2 py-1 text-sm focus:outline-none cursor-pointer">
              <DotMenuSide />
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute right-1 w-20 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  <button
                    className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                    onClick={() => {
                      setIsOpen(true);
                    }}>
                    <Edit />
                    編集
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="border border-c-border rounded">
          <div className="p-4 flex items-center gap-4">
            {clientProspectTelStatus?.name && (
              <span className="text-[#27ae60] text-xs h-5 px-2 bg-[#e6f5ea] rounded-sm leading-5">
                {clientProspectTelStatus?.name}
              </span>
            )}
            <span className="text-[#2d9cdb] text-xs h-5 px-2 bg-[#dceeff] rounded-sm leading-5">
              {stageText}
            </span>
          </div>
          {commentHtml && (
            <div className="px-4 [&_p]:mb-0">
              <div
                dangerouslySetInnerHTML={{
                  __html: convertUrlToLink(commentHtml),
                }}
              />
            </div>
          )}
          <div className="border-t border-solid border-c-border mt-4">
            <div className="px-4">
              <div className="flex items-center w-full h-16 border-b border-c-border gap-3">
                <div className="z-10 flex w-8 h-auto">
                  <ZoomPhoneBadge />
                </div>
                <span className="flex flex-1 items-center">
                  {callerName || calleeName}
                </span>
              </div>
            </div>
            <ZoomPhone prospectActivity={prospectActivity} />
          </div>
        </div>
      </div>
      <ReUpdateModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        prospectPool={prospectPool}
        prospectActivity={prospectActivity}
      />
    </>
  );
};
