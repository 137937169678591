import React from 'react';
import {AccountGroup} from 'api';
import UploadModal from './UploadModal';
import Dropdown from './Dropdown';
import AddModalComponent from './AddModal';

interface Props {
  accountGroup: AccountGroup;
}

export default ({accountGroup}: Props) => {
  const [csvModalVisible, setCsvModalVisible] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Dropdown
        disabled={accountGroup.status !== 'normal'}
        options={[
          {label: 'CSVアップロード', onClick: () => setCsvModalVisible(true)},
          {label: '個別追加', onClick: () => setVisible(true)},
        ]}
        className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 min-w-32 px-4 disabled:bg-c-bg disabled:text-c-light">
        {accountGroup.status === 'importing'
          ? 'インポート処理中...'
          : '+ 企業を追加'}
      </Dropdown>
      <UploadModal
        visible={csvModalVisible}
        onClose={() => setCsvModalVisible(false)}
      />
      <AddModalComponent visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
