import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useMainCategoriesQuery, MainCategory} from 'api';
import {ParentCategory} from './ParentCategory';
import BASE_CLASSIFICATIONS from 'helpers/classifications';

interface Props {
  setActiveMainCategory: (mainCategoryId: string) => void;
}

const Content = ({setActiveMainCategory}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [categoryPositions, setCategoryPositions] = React.useState([]);
  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery({
    onCompleted: () => {
      const categoryPositions = Array.prototype.slice
        .call(ref.current?.getElementsByTagName('section'))
        .map((mainCategory: any) => ({
          id: mainCategory.id,
          top: mainCategory.offsetTop - ref.current?.offsetTop,
          bottom:
            mainCategory.offsetTop -
            ref.current?.offsetTop +
            mainCategory.clientHeight,
          height: mainCategory.clientHeight,
        }));
      setCategoryPositions(categoryPositions);
    },
  });

  const classifications = useMemo(() => {
    return BASE_CLASSIFICATIONS(mainCategories);
  }, [mainCategories]);

  const handleScroll = () => {
    const activeSection = categoryPositions.find((position: any) => {
      const scroll = ref.current?.scrollTop;
      return position.top <= scroll && position.bottom > scroll;
    });
    setActiveMainCategory(activeSection ? activeSection.id : 1);
  };

  return (
    <Container>
      {mainCategories?.length > 0 && (
        <Classifications
          onScroll={handleScroll}
          ref={ref}
          style={
            categoryPositions.length > 0
              ? {
                  paddingBottom: `calc(100vh - 140px - ${
                    categoryPositions[categoryPositions.length - 1].height
                  }px)`,
                }
              : {}
          }>
          {classifications?.map((classification, i) => {
            return (
              <ClassificationSection
                key={`classification-${i}`}
                id={`classification-${i}`}>
                <Wrapper>
                  <Square />
                  <Title>{classification?.name}</Title>
                </Wrapper>
                {classification.categories.map((mainCategory: MainCategory) => (
                  <ParentCategory
                    key={mainCategory?.id}
                    mainCategory={mainCategory}
                  />
                ))}
              </ClassificationSection>
            );
          })}
        </Classifications>
      )}
    </Container>
  );
};

export {Content};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 400px;
  padding-left: 30px;
  border-right: 1px solid #f3f3f5;
`;

const Classifications = styled.div`
  overflow-y: scroll;
`;

const ClassificationSection = styled.section`
  overflow-y: scroll;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.15px;
  color: #222426;
  position: relative;
`;

const Square = styled.div`
  background: #222426;
  content: '';
  height: 18px;
  width: 4px;
  margin-right: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
