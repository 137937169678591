import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import {useMainCategoryReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();

  const {data: {mainCategoryReports = []} = {}} = useMainCategoryReportsQuery(
    {},
  );

  const mainCategoryNames = mainCategoryReports
    .filter((report) => !!report.values[0])
    .map((report) => report.name);

  const mainCategoryValues = mainCategoryReports
    .filter((report) => !!report.values[0])
    .map((report) => report.values)
    .flat();

  return {
    axis: mainCategoryNames,
    data: [
      {
        label: 'リード数',
        color: getColor(5),
        values: mainCategoryValues,
      },
    ],
  };
};

export default useChartData;
