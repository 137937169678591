import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input, Button, Divider, Alert, message} from 'components/antd';
import {useChangePasswordMutation} from 'api';

const validateSchema = Yup.object().shape({
  currentPassword: Yup.string().required('必須項目です'),
  password: Yup.string()
    .required('必須項目です')
    .min(6, '6文字以上で設定してください'),
  passwordConfirmation: Yup.string()
    .trim()
    .required('必須項目です')
    .oneOf([Yup.ref('password'), null], 'パスワードが一致していません'),
});

export default () => {
  const [changePassword, {data, loading}] = useChangePasswordMutation({
    onCompleted: (data) => {
      if (!data?.changePassword?.error) {
        message.success('パスワードが変更されました');
        formik.resetForm();
      }
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      changePassword({
        variables: {password: values},
      });
    },
  });

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Body>
      <Menu />
      <Content>
        <TitleSection>
          <TitleWrapper>
            <Title>パスワード変更</Title>
            <TitleNote>パスワードは6文字以上で指定してください。</TitleNote>
          </TitleWrapper>
        </TitleSection>

        <Divider />

        <FormSection>
          <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 12}}
            onFinish={handleSubmit}>
            {data?.changePassword?.error && (
              <Alert type="error" message={data.changePassword.error} />
            )}
            <Form.Item
              label="現在のパスワード"
              required
              validateStatus={
                errors.currentPassword && touched.currentPassword ? 'error' : ''
              }
              help={
                errors.currentPassword && touched.currentPassword
                  ? errors.currentPassword
                  : ''
              }>
              <Input
                type="password"
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              label="新パスワード"
              required
              validateStatus={
                errors.password && touched.password ? 'error' : ''
              }
              help={errors.password && touched.password ? errors.password : ''}>
              <Input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              label="新パスワード(確認)"
              required
              validateStatus={
                errors.passwordConfirmation && touched.passwordConfirmation
                  ? 'error'
                  : ''
              }
              help={
                errors.passwordConfirmation && touched.passwordConfirmation
                  ? errors.passwordConfirmation
                  : ''
              }>
              <Input
                type="password"
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <UIButton
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={!isValid || loading}>
              変更
            </UIButton>
          </Form>
        </FormSection>
      </Content>
    </Body>
  );
};

const Body = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  background: #fff;
`;

const Content = styled.div`
  background: #fff;
  width: 700px;
  padding: 45px 45px;
`;

const TitleSection = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
`;

const TitleNote = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;

const FormSection = styled.div`
  form {
    margin-top: 30px;
    .ant-form-item {
      margin: 0;
      display: block;

      .ant-form-item-label {
        display: inline;
        label {
          height: 26px;
          font-weight: bold;
          font-size: 14px;
          color: #899098;
        }
      }
      .ant-form-item-required {
        &:after {
          content: none !important;
        }
      }
      input {
        width: 600px;
        height: 48px;
        background: #f3f5f7;
        border: none;
      }
    }
  }
`;

const UIButton = styled(Button)`
  width: 187px;
  height: 48px;
  margin-top: 40px;
  background-color: #68b5fb;
  border-radius: 4px;
  border-style: none;
`;
