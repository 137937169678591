import React from 'react';
import useSearchParams from '../useSearchParams';

export default () => {
  const {query, setQuery} = useSearchParams();
  return (
    <div className="h-9 w-36">
      <input
        type="month"
        className="w-full h-full px-3 py-px text-gray-700 bg-white border focus:outline-none border-[#E1E6EB] block rounded cursor-pointer"
        value={`${query.year}-${query.month.toString().padStart(2, '0')}`}
        onChange={(e: any) => {
          const splitValue: string[] = e.target.value.split('-');
          setQuery({
            year: parseInt(splitValue[0]) || new Date().getFullYear(),
            month: parseInt(splitValue[1]) || new Date().getMonth() + 1,
          });
        }}
        required
      />
    </div>
  );
};
