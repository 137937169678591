import React from 'react';
import {Workflow, AutomaticMailStep} from 'api';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const count = React.useMemo(() => {
    const sent = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep)
          .automaticMailSendsSentCount;
      }
      return acc;
    }, 0);
    const openedCount = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep)
          .automaticMailSendsOpenedCount;
      }
      return acc;
    }, 0);
    const clickedCount = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep)
          .automaticMailSendsClickedCount;
      }
      return acc;
    }, 0);
    const bouncedCount = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep)
          .automaticMailSendsBouncedCount;
      }
      return acc;
    }, 0);

    const openedRate = openedCount && sent ? (openedCount / sent) * 100 : 0;
    const clickedRate = clickedCount && sent ? (clickedCount / sent) * 100 : 0;
    const bouncedRate = bouncedCount && sent ? (bouncedCount / sent) * 100 : 0;

    return {
      bouncedCount,
      openedRate,
      clickedRate,
      bouncedRate,
    };
  }, [workflow]);

  return (
    <>
      <span className="text-xs text-c-lighter">自動メール</span>
      <ul className="flex gap-8 h-full">
        <li className="flex flex-col gap-2 w-16">
          <span className="text-sm text-c-light">開封率</span>
          <div>
            <span className="text-xl font-bold mr-1">
              {(count.openedRate || 0).toFixed(1)}
            </span>
            <span className="text-xs">%</span>
          </div>
        </li>
        <li className="flex flex-col gap-2 w-16">
          <span className="text-sm text-c-light">クリック率</span>
          <div>
            <span className="text-xl font-bold mr-1">
              {(count.clickedRate || 0).toFixed(1)}
            </span>
            <span className="text-xs">%</span>
          </div>
        </li>
        <li className="border-r border-c-border" />
        <li className="flex flex-col gap-2 w-16">
          <span className="text-sm text-c-light">バウンス</span>
          <span className="text-xl font-bold mr-1">
            {(count.bouncedCount || 0).toLocaleString()}
          </span>
          <span className="font-bold">
            ({(count.bouncedRate || 0).toFixed(1)}
            <span className="text-xs">%</span>)
          </span>
        </li>
      </ul>
    </>
  );
};
