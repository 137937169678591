import React from 'react';
import {
  SalesforceAccount,
  SalesforceAccountField,
  useUpdateSalesforceAccountFieldValueMutation,
} from 'api';

interface Props {
  field: SalesforceAccountField;
  salesforceAccount: SalesforceAccount;
}

const Date = ({field, salesforceAccount}: Props) => {
  const value = salesforceAccount.addition[`${field.fieldName}`];
  const [erorrMessage, setErrorMessage] = React.useState('');
  const [updateFieldValue] = useUpdateSalesforceAccountFieldValueMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceAccountFieldValue.error);
    },
  });
  return (
    <>
      <input
        defaultValue={value}
        className="w-max"
        type="date"
        onBlur={(e) => {
          updateFieldValue({
            variables: {
              salesforceAccountId: salesforceAccount.id,
              attributes: {fieldName: field.fieldName, value: e.target.value},
            },
          });
        }}
      />
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </>
  );
};

export default Date;
