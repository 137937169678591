import React from 'react';
import styled from 'styled-components';
import {PrefectureType} from 'api';
import {Checkbox} from 'components/antd';
import _ from 'lodash';

interface Props {
  currentRegion: any;
  setCurrentPrefectureType: React.Dispatch<
    React.SetStateAction<PrefectureType>
  >;
  prefectureTypes: PrefectureType[];
  selectedPrefectureTypeIds: string[];
  setSelectedPrefectureTypeIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCityIds: string[];
  setSelectedCityIds: React.Dispatch<React.SetStateAction<string[]>>;
  handleCheckOnPrefectureType: (e: any, prefectureType: PrefectureType) => void;
}

export default ({
  currentRegion,
  setCurrentPrefectureType,
  prefectureTypes,
  selectedPrefectureTypeIds,
  setSelectedPrefectureTypeIds,
  selectedCityIds,
  setSelectedCityIds,
  handleCheckOnPrefectureType,
}: Props) => {
  const [sectionPositions, setSectionPositions] = React.useState([]);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const sectionPositions = Array.prototype.slice
      .call(ref.current?.getElementsByTagName('section'))
      .map((section: any) => ({
        id: section.id,
        top: section.offsetTop - ref.current?.offsetTop,
        bottom:
          section.offsetTop - ref.current?.offsetTop + section.clientHeight,
        height: section.clientHeight,
      }));

    setSectionPositions(sectionPositions);
  }, [prefectureTypes]);

  const handleScroll = () => {
    const activeSection = sectionPositions.find((position: any) => {
      const scroll = ref.current?.scrollTop;
      return position.top <= scroll && position.bottom > scroll;
    });
    activeSection &&
      setCurrentPrefectureType(
        prefectureTypes.find(
          (prefectureType) => prefectureType.name === activeSection.id,
        ),
      );
  };

  const handleCheckOnCity = (e: any, prefectureType: PrefectureType) => {
    if (e.target.checked) {
      setSelectedCityIds([...selectedCityIds, e.target.name]);
      if (
        _.difference(
          prefectureType.cities.map((city) => city.id),
          selectedCityIds,
        ).length === 1
      ) {
        setSelectedPrefectureTypeIds([
          ...selectedPrefectureTypeIds,
          prefectureType.id,
        ]);
      }
    } else {
      setSelectedCityIds(_.difference(selectedCityIds, [e.target.name]));
      setSelectedPrefectureTypeIds(
        _.difference(selectedPrefectureTypeIds, [prefectureType.id]),
      );
    }
  };

  return (
    <Container
      ref={ref}
      id="area-filter-modal-content"
      onScroll={handleScroll}
      style={
        sectionPositions.length > 0
          ? {
              paddingBottom: `calc(640px - ${
                sectionPositions[sectionPositions.length - 1].height
              }px)`,
            }
          : {}
      }>
      {currentRegion.prefectureTypes?.map((prefectureTypeName: any) =>
        prefectureTypes?.map(
          (prefectureType) =>
            prefectureTypeName === prefectureType.name && (
              <CitiesWrapper id={prefectureType.name} key={prefectureType.name}>
                <Checkbox
                  checked={selectedPrefectureTypeIds.includes(
                    prefectureType.id,
                  )}
                  onChange={(e) =>
                    handleCheckOnPrefectureType(e, prefectureType)
                  }>
                  {prefectureType.name}
                </Checkbox>
                <Cities>
                  {prefectureType.cities.map((city) => (
                    <Checkbox
                      name={city.id}
                      checked={selectedCityIds.includes(city.id)}
                      key={city.id}
                      onChange={(e) => handleCheckOnCity(e, prefectureType)}>
                      {city.name}
                    </Checkbox>
                  ))}
                </Cities>
              </CitiesWrapper>
            ),
        ),
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 470px;
  border-right: 1px solid #f3f3f5;
  overflow-y: scroll;
`;

const CitiesWrapper = styled.section`
  padding: 10px 0;
  &&& {
    .ant-checkbox-wrapper {
      width: 70%;
    }
  }
`;

const Cities = styled.div`
  padding: 10px 20px 20px;
  display: flex;
  flex-wrap: wrap;

  &&& {
    .ant-checkbox-wrapper {
      margin-left: 0px;
      width: 120px;
      margin: 5px 0;
    }
  }
`;
