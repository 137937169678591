import React from 'react';
import {Prelead} from 'api';

interface Props {
  row: any;
}

const ActivityMailCampaignMailClicksCountCell = ({row}: Props) => {
  const prelead: Prelead = row.original.prelead;

  return <>{prelead.activityMailCampaignMailClicksCount}</>;
};

export default ActivityMailCampaignMailClicksCountCell;
