import gql from 'graphql-tag';

export default gql`
  fragment importProspectFragment on ImportProspect {
    id
    status
    rowCount
    currentRowNumber
    errorRowNumber
    percent
  }
`;
