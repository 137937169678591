import React, {useMemo} from 'react';
import {SalesforceLead, SalesforceLeadField} from 'api';
import Checkbox from './Checkbox';
import Input from './Input';
import Date from './Date';

interface Props {
  field: SalesforceLeadField;
  salesforceLead: SalesforceLead;
}

export const FieldValue: React.FC<Props> = ({field, salesforceLead}) => {
  const Value = useMemo(() => {
    const value = salesforceLead.addition[`${field.fieldName}`];

    if (field.fieldType === 'checkbox') {
      return <Checkbox field={field} salesforceLead={salesforceLead} />;
    } else if (field.fieldType === 'address') {
      const hash = value;
      if (!hash) {
        return null;
      }
      const address = `${hash?.state}${hash?.city}${hash?.street}`;
      return <div>{address}</div>;
    } else if (field.fieldType === 'text' && field.fieldName.endsWith('__c')) {
      return <Input field={field} salesforceLead={salesforceLead} />;
    } else if (field.fieldType === 'date' && field.fieldName.endsWith('__c')) {
      return <Date field={field} salesforceLead={salesforceLead} />;
    } else {
      return <div>{value}</div>;
    }
  }, [field, salesforceLead.addition]);

  return (
    <div className="flex px-4 align-middle text-[#495058]">
      <div className="w-2/5 text-sm ">{field.label}</div>
      <div className="w-3/5">{Value}</div>
    </div>
  );
};
