import React from 'react';
import useSearchParams from '../../../useSearchParams';
import CheckboxGroup from './CheckboxGroup';
import Header from '../Header';
import Footer from './Footer';

const options = [
  {
    value: 'tel',
    text: '電話',
  },
  {
    value: 'mail',
    text: '手動メール',
  },
  {
    value: 'other',
    text: 'その他',
  },
];

const TaskTypeFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="ToDo種別" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            options={options.map((type) => ({
              text: type.text,
              value: type.value,
              keyword: type.text,
            }))}
            values={query.types || []}
            onChange={(values: string[]) =>
              setQuery({...query, types: values, page: 1})
            }
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, types: []})} />
    </>
  );
};

export default TaskTypeFilter;
