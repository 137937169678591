import {useCurrentClientQuery, useCurrentUserQuery} from 'api';
import {useMemo} from 'react';

const useClientUser = () => {
  const {data: {currentUser = {}, currentUser: {clientUser = {}} = {}} = {}} =
    useCurrentUserQuery();

  const {data: {currentClient: {actionContract = {}} = {}} = {}} =
    useCurrentClientQuery();

  const isAdmin = useMemo(
    () => currentUser?.role === 'admin' || clientUser?.role === 'owner',
    [currentUser],
  );

  const isMember = useMemo(
    () => isAdmin || clientUser?.role === 'member',
    [currentUser],
  );

  const canListTask = useMemo(() => {
    if (currentUser?.role === 'admin') {
      return true;
    }
    if (actionContract.userRoleCanTaskList === 'owner') {
      return isAdmin;
    }
    if (actionContract.userRoleCanTaskList === 'member') {
      return isMember;
    }

    return true;
  }, [actionContract]);

  const canListApproach = useMemo(() => {
    if (currentUser?.role === 'admin') {
      return true;
    }
    if (actionContract.userRoleCanApproachList === 'owner') {
      return isAdmin;
    }
    if (actionContract.userRoleCanApproachList === 'member') {
      return isMember;
    }

    return true;
  }, [actionContract]);

  return {
    isAdmin,
    isMember,
    canListTask,
    canListApproach,
  };
};

export default useClientUser;
