import React from 'react';
import {useMailCampaignUrlsQuery} from 'api';

import useSearchParams from '../../../../useSearchParams';

const UrlCheckboxGroup = () => {
  const {query, setQuery} = useSearchParams();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    const values = query.mailCampaignUrlIds || [];
    const mailCampaignUrlIds = checked
      ? [...values, value]
      : values.filter((v) => v !== value);
    setQuery({
      ...query,
      mailCampaignUrlIds,
      isBouncedMailCampaignSend: false,
      isUnsubscribedMailCampaignSend: false,
    });
  };
  const {data: {mailCampaignUrls = []} = {}} = useMailCampaignUrlsQuery({
    variables: {
      uuid: query.mailCampaignId,
    },
    skip: !query.mailCampaignId,
  });

  if (!query.mailCampaignId) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-2 mt-3 overflow-y-scroll p-1">
      {mailCampaignUrls.map((url) => (
        <label key={url.id} className="flex items-center text-base">
          <input
            type="checkbox"
            value={url.id}
            className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light"
            checked={query.mailCampaignUrlIds?.includes(url.id)}
            onChange={handleChange}
            disabled={!query.mailCampaignId}
          />
          <span className="ml-2">{url.url}</span>
        </label>
      ))}
    </div>
  );
};

export default UrlCheckboxGroup;
