import React from 'react';

const TelIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_39425_179514)">
        <path
          d="M1.47729 0.810547L3.07851 0.875733C3.41577 0.88946 3.71507 1.12906 3.84433 1.48879L4.31778 2.80645C4.42798 3.11308 4.39832 3.46294 4.23875 3.73913L3.63215 4.7891C3.99129 5.36929 4.96816 6.72866 6.00252 7.52437L6.77379 6.99036C6.96981 6.85463 7.20451 6.81434 7.42631 6.8783L8.95958 7.32063C9.36737 7.43829 9.64215 7.87824 9.60994 8.36186L9.51151 9.83859C9.47693 10.3568 9.09994 10.7634 8.65241 10.7049C2.74518 9.93219 -0.718947 0.810532 1.47729 0.810547Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_39425_179514">
          <rect
            width="11"
            height="11"
            fill="white"
            transform="translate(0.233398 0.232422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TelIcon;
