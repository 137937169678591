import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import telCategoryColor from 'helpers/telCategoryColor';
import {StatusEclipse} from 'components/Ui';
import {TelStatus, useCurrentClientQuery} from 'api';
import useSearchParams from '../../useSearchParams';

const TelStatusesFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {data: {currentClient: {telStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.telStatusIds || []}
      onChange={(value) => setQuery({page: 1, telStatusIds: value})}>
      <StatusSelect.Option
        value="none"
        label={
          <React.Fragment>
            <StatusEclipse />
            &nbsp; 未
          </React.Fragment>
        }>
        <StatusEclipse />
        &nbsp; 未
      </StatusSelect.Option>
      {telStatuses.map((option: TelStatus) => (
        <StatusSelect.Option
          value={option.id}
          key={option.id}
          label={
            <React.Fragment>
              <StatusEclipse color={telCategoryColor(option.category)} />
              &nbsp; {option.name}
            </React.Fragment>
          }>
          <StatusEclipse color={telCategoryColor(option.category)} />
          &nbsp; {option.name}
        </StatusSelect.Option>
      ))}
    </Select>
  );
};

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

export default TelStatusesFilter;
