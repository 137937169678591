import React from 'react';
import styled from 'styled-components';
import {useParams, useHistory} from 'react-router-dom';
import {Button} from 'components/antd';
import {Cross} from 'components/Ui/Icon';
import {Drawer} from 'components';
import Content from './Content';

export default () => {
  const history = useHistory();
  const {poolId, taskId} = useParams<{
    poolId: string;
    taskId: string;
  }>();

  const isOpen = React.useMemo(() => !!taskId, [taskId]);

  const close = () => history.push(`/pools/${poolId}/action/tasks${location.search}`);

  const escFunction = React.useCallback((event) => {
    if (event.keyCode === 27) {
      close();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  if (!taskId) return null;

  return (
    <Drawer isOpen={isOpen} onClose={close} width={600}>
      <Container>
        <CloseButton type="text" onClick={close}>
          <Cross />
        </CloseButton>
        <Content />
      </Container>
    </Drawer>
  );
};

const Container = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  height: 100vh;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  flex-direction: column;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  display: grid;
  place-items: center;
`;
