import React from 'react';
import {ProspectPool} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';

interface Props {
  row: Row<ProspectPool>;
}

const NameCell = ({row}: Props) => {
  const prospectPool = row.original;

  return (
    <Container>
      <div>
        <h4>
          {prospectPool.prospect.lastName} {prospectPool.prospect.firstName}
        </h4>
        <span>{prospectPool.prospect.account.name}</span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  h4 {
    font-size: 14px;
    font-weight: medium;
    margin: 0;
  }

  span {
    font-size: 10px;
  }
`;

export default NameCell;
