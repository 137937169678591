import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M1.94737 9.84211L2.89474 9.21053L4.78947 7.94737M1.94737 9.84211L1 10.4737V19H19V6.68421L13.3158 10.4737V6.68421L6.68421 10.4737V6.68421L5.73684 7.31579L4.78947 7.94737M1.94737 9.84211V3.84211M4.78947 7.94737V3.84211M1.94737 3.84211V1H4.78947V3.84211M1.94737 3.84211H4.78947"
          stroke="#BDC4CB"
          strokeWidth="1.5"
        />
        <ellipse cx="15.123" cy="7" rx="6" ry="6" fill="white" />
        <path
          d="M15.1224 10.3327C16.9633 10.3327 18.4557 8.8403 18.4557 6.99935C18.4557 5.1584 16.9633 3.66602 15.1224 3.66602C13.2814 3.66602 11.7891 5.1584 11.7891 6.99935C11.7891 8.8403 13.2814 10.3327 15.1224 10.3327Z"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.123 7H18.4564"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.123 3.73438C15.9478 4.57607 16.4564 5.72883 16.4564 7.00037C16.4564 8.27191 15.9478 9.42467 15.123 10.2663"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1224 3.73438C14.2976 4.57607 13.7891 5.72883 13.7891 7.00037C13.7891 8.27191 14.2976 9.42467 15.1224 10.2663"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
