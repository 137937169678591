import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.94737 9.84211L2.89474 9.21053L4.78947 7.94737M1.94737 9.84211L1 10.4737V19H19V6.68421L13.3158 10.4737V6.68421L6.68421 10.4737V6.68421L5.73684 7.31579L4.78947 7.94737M1.94737 9.84211V3.84211M4.78947 7.94737V3.84211M1.94737 3.84211V1H4.78947V3.84211M1.94737 3.84211H4.78947"
        stroke="#BDC4CB"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Icon;
