import React from 'react';
import styled from 'styled-components';
import {getTagFontColor} from 'helpers/tagColors';
import {Prelead} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

export default ({row}: Props) => {
  const prelead: Prelead = row.original;

  return (
    <Container>
      {prelead.preleadTags?.map((preleadTag: any) => (
        <PreleadTag key={preleadTag.id} color={preleadTag.color}>
          <span>{preleadTag.name}</span>
        </PreleadTag>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const PreleadTag = styled.span<{color?: string}>`
  margin-right: 3px;
  padding: 1px 8px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;

  span {
    font-size: 9px;
    letter-spacing: 0.15px;
    color: ${({color}) => getTagFontColor(color)};
  }
`;
