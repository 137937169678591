import gql from 'graphql-tag';

export default gql`
  fragment preleadContactFragment on PreleadContact {
    id
    uuid
    projectId
    firstName
    lastName
    section
    sectionPosition
    telephoneNumber
    email
    salesforceLeadId
    hubspotContactId
    sensesContactId
  }
`;
