import React from 'react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import Category from './Category';

export default () => {
  return (
    <div className="flex flex-col gap-4">
      <Category />
      <div className="bg-white p-4 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 items-start">
            <div>
              <h2 className="text-base m-0 font-bold">従業員数</h2>
              <p className="m-0 text-sm">
                選択した従業員数の重要度を高めてスコアを算出します。
              </p>
            </div>
            <div className="flex items-center gap-2">
              <select className="form-select border-c-border rounded text-sm h-8 pt-[3px] w-32">
                <option>100</option>
                <option>200</option>
                <option>300</option>
                <option>400</option>
              </select>
              <span>人</span>
              <span>〜</span>
              <select className="form-select border-c-border rounded text-sm h-8 pt-[3px] w-32">
                <option>100</option>
                <option>200</option>
                <option>300</option>
                <option>400</option>
              </select>
              <span>人</span>
            </div>
          </div>
        </div>
        <div className="flex gap-6">
          <div className="w-48">
            <h3 className="bg-[#F2F8FF] text-[#004C7E] text-sm leading-5 px-2 rounded inline-block mb-4">
              受注分析
            </h3>
            <ul className="flex flex-col gap-2 text-sm w-full">
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
            </ul>
          </div>
          <div className="border-l border-l-c-border" />
          <div className="w-48">
            <h3 className="bg-[#FFF4F6] text-[#95002D] text-sm leading-5 px-2 rounded inline-block mb-4">
              商談分析
            </h3>
            <ul className="flex flex-col gap-2 text-sm w-full">
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
            </ul>
          </div>
          <div className="border-l border-l-c-border" />
          <div className="w-48">
            <h3 className="bg-[#FAF2D2] text-[#7E5300] text-sm leading-5 px-2 rounded inline-block mb-4">
              リード分析
            </h3>
            <ul className="flex flex-col gap-2 text-sm w-full">
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
              <li className="flex items-center justify-between">
                <span>5001〜10000人</span>
                <span>24件</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 items-start">
            <div>
              <h2 className="text-base m-0 font-bold">求人・メディア</h2>
              <p className="m-0 text-sm">
                選択した求人・メディア種別の重要度を高めてスコアを算出します。また、重視するキーワードを設定できます。
              </p>
            </div>
            <div className="flex flex-col gap-2 text-sm">
              <label className="flex items-center text-base">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded border-c-border"
                />
                <span className="ml-2">この項目を必須にする</span>
              </label>
              <label className="flex items-center text-base">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded border-c-border"
                />
                <span className="ml-2">この項目を必須にする</span>
              </label>
              <label className="flex items-center text-base">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded border-c-border"
                />
                <span className="ml-2">この項目を必須にする</span>
              </label>
              <label className="flex items-center text-base">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded border-c-border"
                />
                <span className="ml-2">この項目を必須にする</span>
              </label>
              <label className="flex items-center text-base">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded border-c-border"
                />
                <span className="ml-2">この項目を必須にする</span>
              </label>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-bold m-0">重要キーワード設定</h3>
            <div className="p-2 border border-c-border rounded-sm flex gap-2">
              <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
                システム開発 <XMarkIcon className="h-4 w-4" />
              </span>
              <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
                システム開発 <XMarkIcon className="h-4 w-4" />
              </span>
              <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
                システム開発 <XMarkIcon className="h-4 w-4" />
              </span>
              <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
                システム開発 <XMarkIcon className="h-4 w-4" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
