import React from 'react';
import {useParams, useHistory, Switch, Route} from 'react-router';
import Edit from './Edit';
import New from './New';

export default () => {
  const history = useHistory();
  const {blockGroupId} = useParams<{
    blockGroupId: string;
  }>();

  const closeDetail = () =>
    history.push(
      `/block/block_groups/${blockGroupId}/block_members${location.search}`,
    );

  const escFunction = React.useCallback((event) => {
    if (event.keyCode === 27) {
      closeDetail();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Switch>
      <Route
        path="/block/block_groups/:blockGroupId/block_members/new"
        component={New}
      />
      <Route
        path="/block/block_groups/:blockGroupId/block_members/:blockGroupMemberId"
        component={Edit}
      />
    </Switch>
  );
};
