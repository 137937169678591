import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectQuery} from 'api';
import {
  projectStatusBgColor,
  projectStatusFontColor,
} from 'helpers/projectStatusColor';
import {Input} from 'components/antd';
import {useUpdateProjectWithoutReturnObjectMutation} from 'api';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const [companyName, setCompanyName] = useState(null);
  const [updateProject, {loading}] =
    useUpdateProjectWithoutReturnObjectMutation({
      refetchQueries: ['project', 'currentUser'],
    });

  React.useEffect(() => {
    setCompanyName(project?.name);
  }, [project]);

  const handleChangeCompanyName = (value: string) => {
    setCompanyName(value);
  };

  const updateName = React.useCallback(
    (e) => {
      if (e.target.value) {
        updateProject({
          variables: {uuid: projectId, attributes: {name: e.target.value}},
        });
      } else {
        alert('営業リスト名は必須です。');
      }
    },
    [projectId],
  );

  if (!project && !loading) return null;
  return (
    <Container>
      <StatusWrapper>
        <StatusEllipse status={project.status}>
          <span>{project.statusText}</span>
        </StatusEllipse>
      </StatusWrapper>
      <Item>
        <CompanyName
          value={companyName}
          onChange={(e) => handleChangeCompanyName(e.target.value)}
          onBlur={updateName}
          onPressEnter={updateName}
        />
      </Item>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const StatusWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const StatusEllipse = styled.div<{status?: string}>`
  display: flex;
  width: 65px;
  height: 28px;
  background: #e2e6ea;
  border-radius: 100px;
  text-align: center;
  background: ${({status}) => projectStatusBgColor(status)};

  span {
    margin: auto;

    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: ${({status}) => projectStatusFontColor(status)};
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const CompanyName = styled(Input)`
  font-size: 18px;
  line-height: 26px;
  border-color: transparent;

  &:hover {
    border-color: #e2e6ea;
  }
`;
