import React from 'react';
import Setting from './Setting';

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <Setting />
      <div className="flex gap-6">
        <div className="w-48">
          <h3 className="bg-[#F2F8FF] text-[#004C7E] text-sm leading-5 px-2 rounded inline-block mb-4">
            受注分析
          </h3>
          <ul className="flex flex-col gap-2 text-sm w-full">
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
          </ul>
        </div>
        <div className="border-l border-l-c-border" />
        <div className="w-48">
          <h3 className="bg-[#FFF4F6] text-[#95002D] text-sm leading-5 px-2 rounded inline-block mb-4">
            商談分析
          </h3>
          <ul className="flex flex-col gap-2 text-sm w-full">
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
          </ul>
        </div>
        <div className="border-l border-l-c-border" />
        <div className="w-48">
          <h3 className="bg-[#FAF2D2] text-[#7E5300] text-sm leading-5 px-2 rounded inline-block mb-4">
            リード分析
          </h3>
          <ul className="flex flex-col gap-2 text-sm w-full">
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
            <li className="flex items-center justify-between">
              <span>5001〜10000人</span>
              <span>24件</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
