import React from 'react';
import {FormBlock, FormPost, FormBlockFieldStandard} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldStandard;
}

interface Props {
  block: Block;
  post: FormPost;
}

export default ({block, post}: Props) => {
  const label = block.formBlockable.standardFieldTypeText;
  const value = post.webSite;

  return (
    <div className="w-full flex flex-col gap-1">
      <label className="text-c-light text-sm m-0">{label}</label>
      <p className="m-0 text-base">{value}</p>
    </div>
  );
};
