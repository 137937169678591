import gql from 'graphql-tag';

export default gql`
  fragment formBlockFieldOtherFragment on FormBlockFieldOther {
    id
    dataType
    fieldType
    isRequired
    title
    placeholder
    helpText
    createdAt
    updatedAt
    formBlockFieldOtherOptions {
      id
      label
      status
    }
  }
`;
