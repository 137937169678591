import gql from 'graphql-tag';

export default gql`
  fragment hubspotDealPropertyDefinitionEnumCheckboxOptionFragment on HubspotDealPropertyDefinitionEnumCheckboxOption {
    id
    displayOrder
    hidden
    label
    value
  }
`;
