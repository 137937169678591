import React, {useRef} from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const CapitalFundFilter = () => {
  const {query, setQuery} = useSearchParams();
  const refFrom = useRef(null);
  const refTo = useRef(null);

  const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({
      ...query,
      capitalFund: {
        ...query.capitalFund,
        from: e.target.value,
      },
    });

  const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({
      ...query,
      capitalFund: {
        ...query.capitalFund,
        to: e.target.value,
      },
    });

  return (
    <>
      <Header title="資本金" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <div className="text-c-base font-bold">資本金</div>
          <div className="flex items-center">
            <input
              min={1}
              type="number"
              defaultValue={query.capitalFund?.from}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeFrom(e)
              }
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light relative"
              ref={refFrom}
            />
            <span className="ml-1">万円</span>
            <span className="mx-2">~</span>
            <input
              min={1}
              type="number"
              defaultValue={query.capitalFund?.to}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onChangeTo(e)}
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
              ref={refTo}
            />
            <span className="ml-1">万円</span>
          </div>
        </div>
      </div>
      <Footer
        onClear={() => {
          setQuery({...query, capitalFund: {}});
          refFrom.current.value = '';
          refTo.current.value = '';
        }}
      />
    </>
  );
};

export default CapitalFundFilter;
