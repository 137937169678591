import React from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  useCurrentClientQuery,
  useCurrentUserQuery,
  useUpdateProspectTelStatusPositionMutation,
} from 'api';
import {Handle} from 'components/Ui/Icon';
import New from './New';
import Update from './Update';
import {Button} from 'components/antd';
import {PlusOutlined} from '@ant-design/icons';

export default () => {
  const [updateModalItem, setUpdateModalItem] = React.useState(null);
  const [telStatues, setTelStatues] = React.useState([]);
  const [newModalVisible, setNewModalVisible] = React.useState(false);

  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  const [updateProspectTelStatusPosition] =
    useUpdateProspectTelStatusPositionMutation();

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const items = [...telStatues];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      setTelStatues(items);
      updateProspectTelStatusPosition({
        variables: {id: removed.id, position: destIndex},
      });
    },
    [telStatues],
  );

  React.useEffect(() => {
    setTelStatues(currentClient?.prospectTelStatuses);
  }, [currentClient]);

  return (
    <div>
      <PageHeader>
        <div>
          <h3>リードのTELステータスを編集、新規追加することができます。</h3>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setNewModalVisible(true);
          }}>
          新規作成
        </Button>
      </PageHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {telStatues?.map((telStatus, index) => (
                <Draggable
                  key={telStatus.id}
                  draggableId={telStatus.id}
                  index={index}>
                  {(provided, snapshot) => (
                    <DraggableItem
                      ref={provided.innerRef}
                      onClick={() => setUpdateModalItem(telStatus)}
                      {...provided.draggableProps}
                      className={snapshot.isDragging ? 'dragging' : ''}>
                      <div className="w-8">
                        <HandleWrapper {...provided.dragHandleProps}>
                          <Handle />
                        </HandleWrapper>
                      </div>
                      <div className="w-32 flex items-center">
                        <span
                          className="w-[14px] h-[14px] rounded-full inline-block mr-2"
                          style={{
                            backgroundColor:
                              telStatus?.prospectTelStatusCategory?.color ||
                              '#bfbfbf',
                          }}
                        />
                        {telStatus?.prospectTelStatusCategory?.name || '未設定'}
                      </div>
                      <div className="w-48">
                        <TelStatus>
                          <span>{telStatus.name}</span>
                        </TelStatus>
                      </div>
                      <Description>{telStatus.description}</Description>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <New
        visible={newModalVisible}
        onClose={() => setNewModalVisible(false)}
      />
      <Update
        prospectTelStatus={updateModalItem}
        onClose={() => setUpdateModalItem(null)}
      />
    </div>
  );
};

const DraggableItem = styled.div`
  min-height: 48px;
  box-shadow: 0 0 1px #444b52;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: solid 3px #fff;
  cursor: pointer;

  &.dragging {
    background-color: #f3f9ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    border-left: solid 3px #68b4fb;
  }
`;

const HandleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 30px;
    height: 24px;
  }
`;

const TelStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  span {
    margin-left: 8px;
    width: 275px;
  }
`;

const Description = styled.div`
  color: #899098;
  padding: 15px 15px 15px 0;
`;

const PageHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;

  > div {
    flex: 1;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;
