import React from 'react';
import TimelineItem from './TimelineItem';
import {useActivitiesByProspectPoolQuery, Activity, ProspectPool} from 'api';
import {Link} from 'react-router-dom';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {
    data: {
      activitiesByProspectPool: {
        activities = [],
        totalActivitiesCount = 0,
        prelead = {},
      } = {},
    } = {},
  } = useActivitiesByProspectPoolQuery({
    variables: {
      uuid: prospectPool.uuid,
    },
  });

  return (
    <>
      <div className="flex flex-row items-center gap-1 mb-4">
        <h3 className="mb-0">企業タイムライン</h3>
        <div className="bg-[#EEF0F2] text-[#899098] rounded-xl text-[10px] px-[7px]">
          {totalActivitiesCount}
        </div>
      </div>
      <div className="flex flex-col gap-[1px]">
        <div className="flex flex-col gap-7">
          {activities.map((activity: Activity) => {
            return <TimelineItem activity={activity} key={activity.id} />;
          })}
        </div>
        {activities.length > 0 && (
          <Link
            to={`/list/preleads/${prelead?.uuid}/timeline?searchWord=${prelead?.name}`}
            className="mt-4 text-[#68B5FB] text-sm font-medium "
            target="_blank">
            <div className="flex flex-row gap-2 items-center">
              <span>もっと見る</span>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_39425_179543)">
                  <path
                    d="M3.33398 8.33398L6.66732 5.00065L3.33398 1.66732"
                    stroke="#68B5FB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_39425_179543">
                    <rect
                      width="10"
                      height="10"
                      fill="white"
                      transform="translate(0 10) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </Link>
        )}
      </div>
    </>
  );
};
