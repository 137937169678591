import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignBlockTitleFragment on MailCampaignBlockTitle {
    id
    bodyColor
    textColor
    textHtml
    paddingTop
    paddingBottom
    paddingLeft
    paddingRight
    createdAt
    updatedAt
  }
`;
