import React from 'react';
import {ProspectPool} from 'api';
import {ArrowDown} from 'components/Ui/Icon';
import Hubspot from './Hubspot';

type Props = {
  prospectPool: ProspectPool;
};

export default ({prospectPool}: Props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="border-b border-c-border">
      <div
        onClick={() => setOpen(!open)}
        className="flex h-10 items-center cursor-pointer gap-4">
        <div className={`font-bold ${open ? 'text-c-base' : 'text-c-lighter'}`}>
          Hubspot
        </div>
        <div className="ml-auto">
          <ArrowDown
            className={`h-4 w-auto ${open ? 'rotate-180' : 'rotate-0'}`}
          />
        </div>
      </div>
      {open && <Hubspot prospectPool={prospectPool} />}
    </div>
  );
};
