import React from 'react';
import styled from 'styled-components';
import {Plus} from 'components/Ui/Icon';
import {useHistory} from 'react-router';

const NewButton = () => {
  const history = useHistory();

  const handleClickNew = () => {
    history.push('/garage/contents/new');
  };

  return (
    <Container>
      <New onClick={handleClickNew}>
        <Plus color="#fff" />
      </New>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const New = styled.div`
  cursor: pointer;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #68b5fb;
  &:hover {
    background-color: #2693ff;
  }
`;

export default NewButton;
