import React from 'react';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  FormBlock,
  FormBlockImage,
  useUpdateFormBlockImageMutation,
  FormBlockImageAttributes,
} from 'api';
import {useForm} from 'react-hook-form';
import debounce from 'helpers/debounce';
import FileInput from './FileInput';
import ScaleInput from './ScaleInput';

interface Block extends FormBlock {
  formBlockable: FormBlockImage;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const [update] = useUpdateFormBlockImageMutation();

  const {register, watch, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        link: Yup.string().optional(),
      }),
    ),
    defaultValues: {
      link: block.formBlockable.link,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: FormBlockImageAttributes) => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-bold">画像の編集</h2>
      <FileInput block={block} />
      <ScaleInput block={block} />
      <div className="flex flex-col gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          リンク
        </label>
        <input
          className="form-input text-base block w-full border border-c-border rounded"
          {...register('link')}
        />
      </div>
    </div>
  );
};
