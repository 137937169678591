import React from 'react';

const Icon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#8DD888" />
    <g clipPath="url(#clip0_19609_198802)">
      <path
        d="M10.3947 9.63159L12.2335 9.70373C12.6207 9.71892 12.9644 9.98407 13.1129 10.3822L13.6566 11.8403C13.7831 12.1796 13.749 12.5668 13.5658 12.8724L12.8692 14.0344C13.2816 14.6764 14.4034 16.1807 15.5912 17.0613L16.4768 16.4703C16.7019 16.3201 16.9715 16.2756 17.2262 16.3463L18.9868 16.8358C19.4551 16.966 19.7707 17.4529 19.7337 17.9881L19.6206 19.6223C19.5809 20.1957 19.148 20.6457 18.6341 20.581C11.8507 19.7258 7.87275 9.63158 10.3947 9.63159Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19609_198802">
        <rect width="12" height="12" fill="white" transform="translate(9 9)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
