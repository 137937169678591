import React from 'react';
import {prospectStages} from 'helpers/prospectStages';
import useCondition from '../../useCondition';

export default () => {
  const {condition} = useCondition();

  if (!condition?.stages || !condition.stages.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">ステージ</h4>
      </div>
      <div className="flex gap-1">
        {prospectStages
          .filter((stage) => condition.stages.includes(stage.name))
          .map((stage) => (
            <span
              key={stage.name}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {stage.displayName}
            </span>
          ))}
      </div>
    </div>
  );
};
