import React from 'react';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {Dialog, Transition} from '@headlessui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCreateClientDownloadMailCampaignMutation} from 'api';
import * as Yup from 'yup';

interface Props {
  visible: boolean;
  onClose: () => void;
  target: string;
}

const validationSchema = Yup.object().shape({
  filename: Yup.string().required('必須項目です'),
});

const DownloadModal = ({visible, onClose, target}: Props) => {
  const [completed, setCompleted] = React.useState(false);

  const [createClientMailCampaignDownload] =
    useCreateClientDownloadMailCampaignMutation({
      onCompleted: () => setCompleted(true),
    });

  const filenameByTarget = `メール配信一覧-${target === 'prospects' ? 'リード' : '営業リスト'}-${moment().format('YYYYMMDD')}`;

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      filename: filenameByTarget,
    },
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = methods;

  const onSubmit = (data: {filename: string}) => {
    createClientMailCampaignDownload({
      variables: {
        filename: data.filename + '.csv',
        target: target,
      },
    });
  };

  React.useEffect(() => {
    reset({
      filename: filenameByTarget,
    });
  }, [visible]);

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {completed ? (
                  <div className="flex flex-col items-center my-8 gap-8 text-base">
                    <h2 className="text-lg font-bold">
                      ダウンロードファイルを作成しています
                    </h2>
                    <ul className="list-disc">
                      <li>
                        処理完了後、ファイルをダウンロードいただけます（完了後、メールで通知します）
                      </li>
                      <li>
                        件数が多い場合、完了までに時間がかかる場合があります
                      </li>
                    </ul>
                    <a
                      href="/downloads"
                      className="bg-c-primary text-white leading-10 h-10 px-6 rounded">
                      ダウンロード履歴を確認
                    </a>
                  </div>
                ) : (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold">
                      CSVダウンロード - メール配信一覧
                    </Dialog.Title>
                    <FormProvider {...methods}>
                      <form
                        className="flex flex-col gap-4 my-4"
                        onSubmit={handleSubmit(onSubmit)}>
                        <ul className="text-sm list-disc list-inside">
                          <li>
                            処理完了後、[ダウンロード履歴]
                            からファイルをダウンロードいただけます（完了後、メールで通知します）
                          </li>
                          <li>
                            件数が多い場合、完了までに時間がかかる場合があります
                          </li>
                        </ul>
                        <div className="flex flex-col gap-6">
                          <div className="flex flex-col gap-2">
                            <label className="text-base font-bold m-0">
                              ファイル名を入力
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                type="text"
                                className="form-input text-base block w-[240px] border border-c-border rounded placeholder-c-lighter"
                                {...register('filename')}
                              />
                              <span className="text-lg">.csv</span>
                            </div>
                            {errors.filename && (
                              <div className="text-red-500">
                                {errors.filename.message}
                              </div>
                            )}
                          </div>

                          <div className="flex flex-row-reverse">
                            <button
                              type="submit"
                              className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32 disabled:bg-c-bg disabled:text-c-light">
                              ダウンロード
                            </button>
                          </div>
                        </div>
                      </form>
                    </FormProvider>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DownloadModal;
