import React from 'react';
import styled from 'styled-components';
import Table from './Table';

export default () => {
  return (
    <Container>
      <Table />
    </Container>
  );
};

const Container = styled.div``;
