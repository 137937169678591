import React from 'react';
import {Form} from 'api';
import UsersEdit from './Users';
import LeadSourceEdit from './LeadSource';
import TagsEdit from './Tags';
import Analytics from './Analytics';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  return (
    <div className="flex-1 flex flex-col gap-8">
      <UsersEdit form={form} />
      <hr className="border-b border-c-border w-full" />
      <LeadSourceEdit form={form} />
      <hr className="border-b border-c-border w-full" />
      <TagsEdit form={form} />
      <hr className="border-b border-c-border w-full" />
      <Analytics form={form} />
    </div>
  );
};
