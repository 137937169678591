import React from 'react';
import {
  MaintenanceProspectPool,
  useUpdateMaintenanceProspectPoolIsTargetMutation,
  ProspectPool,
} from 'api';

import Add from '../Icon/Add';
import Arrow from '../Icon/Arrow';
import Stop from '../Icon/Stop';

type Props = {
  maintenanceProspectPool: MaintenanceProspectPool;
  prospectPool: ProspectPool;
  handleUpdateMaintenanceProspectPool?: (prospectPoolToId: string) => void;
  updateMaintenanceProspectPoolToLoading?: boolean;
};

export default ({
  maintenanceProspectPool,
  prospectPool,
  handleUpdateMaintenanceProspectPool,
  updateMaintenanceProspectPoolToLoading,
}: Props) => {
  const [updateMaintenanceProspectPoolIsTarget, {loading}] =
    useUpdateMaintenanceProspectPoolIsTargetMutation({
      variables: {
        id: maintenanceProspectPool.id,
        isTarget: maintenanceProspectPool.isTarget ? false : true,
      },
      refetchQueries: [
        'maintenances',
        'maintenanceProspectPools',
        'maintenanceProspectPoolCounts',
      ],
    });

  return (
    <div className="px-4 py-4 flex justify-center items-center relative border border-[#BDC4CB] border-t-0">
      <button
        className="w-40 h-8 text-sm flex items-center justify-center gap-2 mx-2 rounded text-c-light border border-c-border shadow-sm bg-white cursor-pointer"
        onClick={() => handleUpdateMaintenanceProspectPool(prospectPool.id)}
        disabled={updateMaintenanceProspectPoolToLoading}>
        <Arrow />
        名寄せ先にする
      </button>
      {maintenanceProspectPool.canMerge && (
        <button
          className="w-40 h-8 text-sm flex items-center justify-center gap-2 mx-2 rounded text-c-light border border-c-border shadow-sm bg-white cursor-pointer"
          onClick={() => updateMaintenanceProspectPoolIsTarget()}
          disabled={loading}>
          {maintenanceProspectPool.isTarget ? <Stop /> : <Add />}
          {maintenanceProspectPool.isTarget
            ? '名寄せ対象外にする'
            : '名寄せ対象にする'}
        </button>
      )}
    </div>
  );
};
