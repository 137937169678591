import React from 'react';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';

interface Props {
  options: {value: string; text: string; keyword?: string}[];
  values: string[];
  onChange: (values: string[]) => void;
  filter?: boolean;
  disabled?: boolean;
}

const CheckboxGroup = ({
  options,
  values,
  onChange,
  filter,
  disabled,
}: Props) => {
  const [keyword, setKeyword] = React.useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    if (checked) {
      onChange([...values, value]);
    } else {
      onChange(values.filter((v) => v !== value));
    }
  };

  const filteredOptions =
    keyword === ''
      ? options
      : options.filter((option) =>
          (option.keyword || option.text)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(keyword.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <div className="flex flex-col gap-2">
      {filter && (
        <div
          className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2 ${
            disabled && 'bg-c-bg'
          }`}>
          <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
          <input
            className="h-8 text-sm border-c-border flex-1 outline-none"
            onChange={(e) => setKeyword(e.target.value)}
            disabled={disabled}
          />
        </div>
      )}
      {filteredOptions.map((option) => (
        <label
          key={option.value}
          className="flex items-center text-base cursor-pointer">
          <input
            type="checkbox"
            value={option.value}
            className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light cursor-pointer"
            checked={values?.includes(option.value)}
            onChange={handleChange}
            disabled={disabled}
          />
          <span className="ml-2">{option.text}</span>
        </label>
      ))}
    </div>
  );
};

export default CheckboxGroup;
