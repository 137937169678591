import React from 'react';
import {FormBlock, FormBlockFieldOther} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;
  const {formBlockFieldOtherOptions} = blockable;

  return (
    <div className="w-full">
      <div className="flex gap-1 mb-2 items-center">
        <label className="tracking-wide font-bold">{blockable.title}</label>
        {blockable.isRequired && <span className="text-red-500">*</span>}
        <span className="text-xs h-[18px] px-1 border border-c-border leading-none rounded-sm bg-[#F5FAFE] text-c-lighter flex items-center">
          その他の項目
        </span>
      </div>
      <div className="flex flex-col gap-1">
        {formBlockFieldOtherOptions.map(
          (option) =>
            option.status === 'open' && (
              <label key={option.id} className="flex items-center">
                <input
                  type="radio"
                  className="form-radio h-5 w-5 rounded"
                  disabled
                />
                <span className="ml-2">{option.label}</span>
              </label>
            ),
        )}
      </div>
    </div>
  );
};
