import {MainCategory, useProjectWithSearchConditionQuery} from 'api';
import {Residence} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  TagBox,
  TagItem,
} from './styles';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = {}} = {}} = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });
  const {projectSearchCondition} = project;
  if (!projectSearchCondition) return null;

  const Tags = (mainCategory: MainCategory) => {
    const subCategories = projectSearchCondition.subCategories.filter(
      (subCategory) => subCategory.mainCategoryId === mainCategory.id,
    );
    return subCategories.map((subCategory) => (
      <TagItem key={subCategory.id}>{subCategory.displayName}</TagItem>
    ));
  };

  return (
    <Container>
      {project.isCategory && (
        <Category>
          <CategoryTitle>
            <Residence />
            業種
          </CategoryTitle>
          {projectSearchCondition.mainCategories.map((mainCategory) => (
            <ChildCategory key={mainCategory.id}>
              <ChildCategoryTitleA>
                {mainCategory.displayName}
              </ChildCategoryTitleA>
              <TagBox>{Tags(mainCategory)}</TagBox>
            </ChildCategory>
          ))}
        </Category>
      )}
    </Container>
  );
};
