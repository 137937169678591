import React from 'react';

function Icon() {
  return (
    <svg
      width="125"
      height="36"
      viewBox="0 0 125 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <rect width="125" height="36" fill="url(#froma-pattern0)" />
      <defs>
        <pattern
          id="froma-pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1">
          <use
            xlinkHref="#froma-image0"
            transform="translate(0 -0.00381264) scale(0.00196078 0.00680828)"
          />
        </pattern>
        <image
          id="froma-image0"
          width="510"
          height="148"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAf4AAACUCAMAAABFqbufAAABMlBMVEUAAADrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyzrYyx/iYunAAAAZXRSTlMAQOBQ0IAwcGAg8LCQEKDABcQsyAT7+TgDHChGzOh7+DwMZXQBYhSMmPSoB10CLn26dfYIfHoq4up/G7eEvjaz7OsVElK/5UqFr98NnkHL2Qrx/TSGW9PyKcMja70epX6am+Mhtk09r7wAAAMnSURBVHgB7dnldhs7GEbhbzDMzMzYlJnb01Nm5ur+L6FcWbUMakxjaz+/Bzyz13rjJAIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA1RCrSgUC8oP8ID/ID/KD/C1nKiznBPlbV6TKSclPfvKTn/zkJz/5yZ9t5O9OHKyTv0Xzd4iDlPzkJ79G/gybCP4gv4f529Qf1c9/qb2gq+T3In8o2UV+8pP/NPl9zp+Qn/zkJz/5yU9+8pPfl/yTURRtt7e3L7aR38P8lSA/+clPfvKTP/jpmNJmgoIW5LuewFDJYdpR+W41qNBELfPPB27G5LfhwMmg/GQ+/rzYtoKc+bx/+D6WSilHqXwXVOUwq06kKtRWy/ydyo2ezR7lpMue3k6x/adyzuX9yycmf4vnT0eUFveT3zV/BTKUvz9W2uiukN+v/InKOS7k9yu/Of1D4+T3K785/QeTQn6/8if2M5Lfm/zm9F/fIb9f+c3p3/gsDc5P/vNRAaes/P1RKRed85vTvyiNzk/+XikgNPK76HbNb07/2eWK81f+Lx+z68megsbzDnvYY9s185e5e7c1llpfa+c3p7/zjGQsv+NhnWLrIL9D/j2V81TI71f+wVGl3ZImyk/+C+0l7DvlH55W2uUrzZSf/N2V/+I3q3KuyWHzk389cdCXtfzm9L+TQ+cnfyoOkozlN6f/Rg/5PctvTv9NIb9f+c3p/ygNzU/+T/XOb07//7cbm5/84T/nv1NZfnP670qz5Sd/b0X5zenvlubNT/6hbttQmfzj95R2/0ET5yd/IrakTP4jSns0JuT3K//knNKeSLPnP2i3LRXNT/7xIaU922rW/E7Ib+U3p38kFfL7ld+c/j1p7vzkn4ltMyXyPx9S2vQw+f36vd8090LI72/+JSG/v/lf7pDf3/wbr4T8/uZ/LeT3N//+cqvnJ/+bovk730p980+F2oAUN+Z6mLOJMnd/H2of5G+bobZSy/wD4R9rUsCA/dHXQheb+S9f25bSVsLfvoiGGuUH+UF+kB/kB/lBfpAfHuUHAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOAb2XKc9kIOfWgAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}

export default Icon;
