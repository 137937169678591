import {ProspectPool, useActivitiesByProspectPoolQuery} from 'api';
import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {data: {activitiesByProspectPool: {prelead = {}} = {}} = {}} =
    useActivitiesByProspectPoolQuery({
      variables: {
        uuid: prospectPool.uuid,
      },
    });

  return (
    <div className="pb-3">
      <h3 className="text-sm leading-4 text-gray-400">LEADPAD企業情報</h3>
      {prelead ? (
        <Link
          to={`/list/preleads/${prelead?.uuid}/timeline?searchWord=${prelead?.name}`}
          className="mt-4 text-[#68B5FB] text-sm font-medium "
          target="_blank">
          {prelead.name}
        </Link>
      ) : (
        '-'
      )}
    </div>
  );
};
