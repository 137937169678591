import React from 'react';
import styled from 'styled-components';
import {Setting} from 'components/Ui/Icon';
import {useLocation, useHistory} from 'react-router-dom';

export default () => {
  const location = useLocation();
  const history = useHistory();

  const isActive = () => {
    return [
      '/settings/password',
      '/settings/notifications',
      '/settings/mails',
    ].some((path) => location.pathname === path);
  };

  return (
    <SettingIconWrapper
      onClick={() => history.push('/settings/password')}
      isActive={isActive()}>
      <Setting />
    </SettingIconWrapper>
  );
};

const SettingIconWrapper = styled.div<{isActive: boolean}>`
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;

    path {
      stroke: ${({isActive}) => (isActive ? '#8966EF' : '#899098')};
    }
  }
`;
