import React from 'react';
import {
  SalesforceContactField,
  SalesforceDealField,
  useSalesforceUsersQuery,
} from 'api';
import {Select, Form} from 'components/antd';
import useProspectContext from '../../../useProspectContext'

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
  field: SalesforceContactField | SalesforceDealField;
}

export const ReferenceItem = ({addition, setAddition, field}: Props) => {
  const {poolId} = useProspectContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  return (
    field?.referenceType === 'user' && (
      <Form.Item label={field.label} required={field.isRequired}>
        <Select
          value={addition[`${field.fieldName}`]}
          onChange={(value) => {
            let newAddition: {[key: string]: any} = Object.assign({}, addition);
            newAddition[`${field.fieldName}`] = value;
            setAddition(newAddition);
          }}
          showSearch
          optionFilterProp="children">
          {salesforceUsers.map((user) => (
            <Select.Option key={user.id} value={user.userId}>
              {user.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )
  );
};
