import React from 'react';

const Icon = ({
  width = '40px',
  height = '40px',
  active,
}: {
  width: string;
  height: string;
  active: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="16.2452"
      cy="16"
      rx="16.2452"
      ry="16"
      fill={active ? 'rgba(141,216,136,1)' : 'rgba(141,216,136,.3)'}
    />
    <g clipPath="url(#clip0_25877_214324)">
      <path
        d="M11.8056 9.73682L13.9837 9.82098C14.4425 9.8387 14.8496 10.148 15.0254 10.6125L15.6694 12.3137C15.8193 12.7095 15.779 13.1612 15.5619 13.5178L14.7368 14.8734C15.2253 15.6224 16.5541 17.3775 17.9611 18.4048L19.0102 17.7154C19.2768 17.5401 19.5961 17.4881 19.8978 17.5707L21.9834 18.1418C22.5381 18.2937 22.9119 18.8617 22.868 19.486L22.7342 21.3926C22.6871 22.0617 22.1743 22.5867 21.5656 22.5111C13.5303 21.5135 8.81822 9.7368 11.8056 9.73682Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_25877_214324">
        <rect
          width="14.2146"
          height="14"
          fill="white"
          transform="translate(10.1533 9)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
