import React from 'react';

function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E1E6EB" />
      <path
        d="M23.2008 15.1265C23.2008 11.8899 19.9562 9.25679 15.9675 9.25679C11.9795 9.25679 8.73438 11.8899 8.73438 15.1265C8.73438 18.028 11.3077 20.458 14.7836 20.9175C15.0192 20.9683 15.3398 21.0728 15.4209 21.2741C15.4939 21.457 15.4686 21.7435 15.4442 21.9282C15.4442 21.9282 15.3594 22.4386 15.3411 22.5473C15.3096 22.7302 15.1957 23.2625 15.9675 22.9372C16.7396 22.6119 20.1332 20.4845 21.6507 18.7378H21.6505C22.6987 17.5883 23.2008 16.4218 23.2008 15.1265Z"
        fill="#06C755"
      />
      <path
        d="M14.498 13.5625H13.9906C13.9128 13.5625 13.8496 13.6256 13.8496 13.7031V16.8548C13.8496 16.9324 13.9128 16.9954 13.9906 16.9954H14.498C14.5758 16.9954 14.6389 16.9324 14.6389 16.8548V13.7031C14.6389 13.6256 14.5758 13.5625 14.498 13.5625Z"
        fill="white"
      />
      <path
        d="M17.9899 13.5625H17.4826C17.4048 13.5625 17.3417 13.6256 17.3417 13.7031V15.5755L15.8974 13.625C15.8941 13.62 15.8903 13.6153 15.8864 13.6107L15.8855 13.6099C15.8827 13.6067 15.8799 13.6039 15.877 13.6011C15.8762 13.6003 15.8753 13.5996 15.8744 13.5988C15.872 13.5966 15.8695 13.5945 15.8669 13.5926C15.8658 13.5914 15.8645 13.5906 15.8633 13.5896C15.8608 13.588 15.8584 13.5862 15.8559 13.5846C15.8545 13.5837 15.8532 13.5828 15.8516 13.5822C15.8491 13.5806 15.8466 13.5791 15.8441 13.5779C15.8426 13.5772 15.8412 13.5763 15.8397 13.5758C15.8371 13.5745 15.8344 13.5733 15.8317 13.5723C15.83 13.5718 15.8286 13.5712 15.8271 13.5706C15.8243 13.5697 15.8215 13.5687 15.8186 13.568C15.8171 13.5675 15.8154 13.5672 15.8138 13.5666C15.811 13.5661 15.8084 13.5654 15.8057 13.5648C15.8038 13.5645 15.8017 13.5643 15.7998 13.5641C15.7973 13.5636 15.7948 13.5634 15.7923 13.5632C15.7899 13.5629 15.7876 13.5629 15.7851 13.5627C15.7833 13.5627 15.7819 13.5625 15.7801 13.5625H15.2729C15.1951 13.5625 15.1318 13.6256 15.1318 13.7031V16.8548C15.1318 16.9324 15.1951 16.9954 15.2729 16.9954H15.7801C15.858 16.9954 15.9211 16.9324 15.9211 16.8548V14.9829L17.3672 16.9359C17.3772 16.95 17.3895 16.9615 17.403 16.9707C17.4034 16.971 17.404 16.9714 17.4044 16.9718C17.4073 16.9736 17.4102 16.9754 17.4131 16.9771C17.4145 16.9779 17.4157 16.9784 17.4171 16.9791C17.4192 16.9804 17.4216 16.9815 17.4238 16.9825C17.4261 16.9834 17.4282 16.9844 17.4307 16.9854C17.4321 16.9859 17.4335 16.9865 17.4349 16.9869C17.4382 16.9882 17.4413 16.9891 17.4444 16.9901C17.4451 16.9901 17.4458 16.9904 17.4465 16.9905C17.458 16.9936 17.4701 16.9954 17.4826 16.9954H17.9899C18.0679 16.9954 18.1308 16.9324 18.1308 16.8548V13.7031C18.1308 13.6256 18.0679 13.5625 17.9899 13.5625Z"
        fill="white"
      />
      <path
        d="M13.2751 16.2061H11.8965V13.7032C11.8965 13.6254 11.8335 13.5623 11.7558 13.5623H11.2483C11.1705 13.5623 11.1074 13.6254 11.1074 13.7032V16.8543V16.8546C11.1074 16.8925 11.1225 16.9267 11.1468 16.952C11.1474 16.9527 11.1479 16.9533 11.1487 16.954C11.1494 16.9547 11.1501 16.9553 11.1508 16.956C11.1762 16.9804 11.2103 16.9954 11.2482 16.9954H11.2483H13.2751C13.3529 16.9954 13.4157 16.9321 13.4157 16.8543V16.347C13.4157 16.2692 13.3529 16.2061 13.2751 16.2061Z"
        fill="white"
      />
      <path
        d="M20.7917 14.3516C20.8695 14.3516 20.9323 14.2886 20.9323 14.2107V13.7033C20.9323 13.6256 20.8695 13.5623 20.7917 13.5623H18.7649H18.7646C18.7266 13.5623 18.6924 13.5776 18.667 13.6021C18.6665 13.6027 18.6658 13.6031 18.6653 13.6036C18.6645 13.6045 18.6638 13.6053 18.6631 13.6061C18.639 13.6314 18.624 13.6655 18.624 13.7032V13.7033V16.8545V16.8546C18.624 16.8925 18.6391 16.9267 18.6634 16.952C18.664 16.9527 18.6647 16.9535 18.6653 16.954C18.6659 16.9547 18.6667 16.9554 18.6674 16.956C18.6927 16.9802 18.7269 16.9954 18.7646 16.9954H18.7649H20.7917C20.8695 16.9954 20.9323 16.9321 20.9323 16.8545V16.347C20.9323 16.2693 20.8695 16.2061 20.7917 16.2061H19.4133V15.6734H20.7917C20.8695 15.6734 20.9323 15.6103 20.9323 15.5325V15.0252C20.9323 14.9474 20.8695 14.8842 20.7917 14.8842H19.4133V14.3516H20.7917Z"
        fill="white"
      />
    </svg>
  );
}

export default Icon;
