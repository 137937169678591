import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity} from 'api';

interface Props {
  prospectActivity: ProspectActivity;
}

export default ({prospectActivity}: Props) => {
  const {createdAt} = prospectActivity;

  return (
    <>
      <Icon />
      <Content>
        <span>自動メールの配信停止を解除</span>
        <time>{moment(createdAt).format('YYYY/MM/DD HH:mm')}</time>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex: 1;
  margin-top: -4px;
  margin-left: 3px;

  span:first-child {
    flex: 1;
    margin: 0;
    color: #495058;
    font-weight: 500;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const Icon = styled.span`
  height: 10px;
  width: 10px;
  background: #c4c4c4;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
  z-index: 1;
`;
