import {Task, useProjectQuery, usePreleadTasksQuery, TaskPrelead} from 'api';
import useProject from './useProject';
import {useParams} from 'react-router-dom';
import useProjectGroups from './useProjectGroups';

const usePreleadTasks = () => {
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();
  const {data: {preleadTasks = []} = {}} = usePreleadTasksQuery({
    variables: {
      uuid: preleadProjectId,
    },
  });

  const {data: {project: {projectGroup = {}} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
  });

  const {isOwnProject} = useProject(projectId);
  const {isProjectGroupAccessible} = useProjectGroups();
  const filterPreleadTasksAccessible = (tasks: Task[]) => {
    return tasks.filter((task: Task) => {
      const taskable: TaskPrelead = task.taskable as TaskPrelead;
      if (!taskable.project) {
        return true;
      }
      const taskProjectGroup = taskable.project.projectGroup;
      return isOwnProject
        ? isProjectGroupAccessible(taskProjectGroup)
        : taskProjectGroup?.uuid === projectGroup.uuid;
    });
  };
  const preleadTasksAccessible = filterPreleadTasksAccessible(preleadTasks);

  return {
    filterPreleadTasksAccessible,
    preleadTasksAccessible,
  };
};

export default usePreleadTasks;
