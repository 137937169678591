import React, {useState} from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import AddUserIcon from './AddUserIcon';
import List from './List';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {isMember} = useClientUser();

  return (
    <Container>
      <Note>※ToDoで設定されている担当者は上書きされません。</Note>
      {isMember && (
        <IconWrapper onClick={() => setIsModalVisible(true)}>
          <AddUserIcon />
        </IconWrapper>
      )}
      <List />

      <Modal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const Note = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

const IconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 20px;
`;
