import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery, useUpdateWorkflowSearchConditionMutation} from 'api';

type Props = {
  index: number;
};

export default ({index}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();
  const [update] = useUpdateWorkflowSearchConditionMutation({
    refetchQueries: ['workflowProspectPoolCountsByStatus'],
  });
  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );
  if (!isConditionUpdatable) {
    return <></>;
  }

  return (
    <CrossWrapper>
      <svg
        onClick={() => {
          const oldSearchConditions =
            searchCondition.prospectCustomizeItemSearchConditions;
          const newSearchConditions = oldSearchConditions.filter(
            (_, oldIndex) => oldIndex !== index,
          );
          update({
            variables: {
              uuid: workflowId,
              attributes: {
                prospectCustomizeItemSearchConditions: newSearchConditions,
              },
            },
          });
        }}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.916016 9.08366L9.08268 0.916992M0.916016 0.916992L9.08268 9.08366L0.916016 0.916992Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CrossWrapper>
  );
};

const CrossWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
