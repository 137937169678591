import React from 'react';
import {Select} from 'components/antd';
import {
  useWorkflowQuery,
  usePoolProspectCustomizeItemsQuery,
  useUpdateWorkflowSearchConditionMutation,
} from 'api';
import {useParams} from 'react-router-dom';
import {CustomizeItemSelectSearchConditionType} from '../CustomizeItemSearchConditionType';

interface Props {
  prospectCustomizeItemSearchCondition: CustomizeItemSelectSearchConditionType;
  index: number;
}

export default ({prospectCustomizeItemSearchCondition, index}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});

  const prospectCustomizeItemSelectOptions = (id: string) => {
    const item = poolProspectCustomizeItems.find((item) => item.id === id);
    return item?.selectOptions || [];
  };

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });
  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const [update] = useUpdateWorkflowSearchConditionMutation({
    refetchQueries: ['workflowProspectPoolCountsByStatus'],
  });
  if (!prospectCustomizeItemSearchCondition.customize_item_id) {
    return <></>;
  }

  return (
    <Select
      style={{minWidth: 400}}
      mode="multiple"
      disabled={!isConditionUpdatable}
      className="mr-2"
      onChange={(values) => {
        const oldSearchCondition =
          searchCondition.prospectCustomizeItemSearchConditions;
        update({
          variables: {
            uuid: workflowId,
            attributes: {
              prospectCustomizeItemSearchConditions: [
                ...oldSearchCondition.slice(0, index),
                {
                  customize_item_id:
                    prospectCustomizeItemSearchCondition.customize_item_id,
                  data_type: prospectCustomizeItemSearchCondition.data_type,
                  values: values,
                },

                ...oldSearchCondition.slice(index + 1),
              ],
            },
          },
        });
      }}
      value={prospectCustomizeItemSearchCondition?.values || []}>
      {prospectCustomizeItemSelectOptions(
        prospectCustomizeItemSearchCondition?.customize_item_id,
      ).map((option) => {
        return (
          <Select.Option key={option.id} value={option.id}>
            {option.label}
          </Select.Option>
        );
      })}
    </Select>
  );
};
