import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useParams} from 'react-router';
import {Form} from 'api';
import Tab from './Tab';
import Blocks from './blocks';
import Settings from './settings';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {formId} = useParams<{
    formId: string;
  }>();

  return (
    <div className="w-[360px]">
      <Tab form={form} />
      <Switch>
        <Route path="/form/forms/:formId/:page/settings">
          <Settings form={form} />
        </Route>
        <Route path="/form/forms/:formId/:page/blocks">
          <Blocks form={form} />
        </Route>
        <Redirect to={`/form/forms/${formId}/done/blocks`} />
      </Switch>
    </div>
  );
};
