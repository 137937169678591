import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxisProps,
  XAxisProps,
} from 'recharts';
import useColor from 'views/client/reports/hooks/useColor';
import {ReportChart} from 'views/client/reports/components/Chart/types';

const barWidth = {
  thin: 18,
  normal: 36,
};

interface Props extends React.ComponentProps<'div'> {
  chart: ReportChart;
  legend?: boolean;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
}

export const Chart: React.FC<Props> = ({
  chart,
  legend,
  yAxisProps,
  xAxisProps,
  ...props
}) => {
  const {getColor} = useColor();
  const data = React.useMemo(() => {
    const transformedData: any[] = [];

    chart.axis.forEach((axisValue, index) => {
      const entry: any = {name: axisValue};

      chart.data.forEach((dataItem) => {
        entry[dataItem.label] = dataItem.values[index];
      });

      transformedData.push(entry);
    });

    return transformedData;
  }, []);

  const bars = React.useMemo(
    () =>
      chart.data
        ? chart.data.map((d, i) => ({
            dataKey: d.label,
            stackId: d.groupId,
            fill: d.color || getColor(i),
          }))
        : [],
    [chart.data],
  );

  return (
    <div {...props}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart layout="vertical" data={data} margin={{}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" {...xAxisProps} />
          <YAxis dataKey="name" type="category" {...yAxisProps} />
          <Tooltip />
          {legend === false ? null : <Legend />}
          {bars.map((b, i) => (
            <Bar
              key={i}
              dataKey={b.dataKey}
              stackId={b.stackId}
              fill={b.fill}
              barSize={12}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
