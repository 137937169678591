import React from 'react';
import {DatePicker} from 'components/antd';
import {useWorkflowQuery, useUpdateWorkflowSearchConditionMutation} from 'api';
import {useParams} from 'react-router-dom';
import {CustomizeItemDateSearchConditionType} from '../CustomizeItemSearchConditionType';
import moment from 'moment';

interface Props {
  prospectCustomizeItemDateSearchCondition: CustomizeItemDateSearchConditionType;
  index: number;
}

export default ({prospectCustomizeItemDateSearchCondition, index}: Props) => {
  const format = 'YYYY/MM/DD';
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });
  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const [update] = useUpdateWorkflowSearchConditionMutation({
    refetchQueries: ['workflowProspectPoolCountsByStatus'],
  });
  if (!prospectCustomizeItemDateSearchCondition.customize_item_id) {
    return <></>;
  }
  const oldSearchCondition =
    searchCondition.prospectCustomizeItemSearchConditions;

  return (
    <div className="flex gap-2 items-center">
      <DatePicker
        disabled={!isConditionUpdatable}
        className="cursor-pointer"
        allowClear
        format={format}
        onChange={(value) =>
          update({
            variables: {
              uuid: workflowId,
              attributes: {
                prospectCustomizeItemSearchConditions: [
                  ...oldSearchCondition.slice(0, index),
                  {
                    customize_item_id:
                      prospectCustomizeItemDateSearchCondition.customize_item_id,
                    data_type:
                      prospectCustomizeItemDateSearchCondition.data_type,
                    from: value,
                    to: prospectCustomizeItemDateSearchCondition.to,
                  },
                  ...oldSearchCondition.slice(index + 1),
                ],
              },
            },
          })
        }
        value={
          prospectCustomizeItemDateSearchCondition?.from
            ? moment(prospectCustomizeItemDateSearchCondition?.from)
            : null
        }
      />
      <div>~</div>
      <DatePicker
        allowClear
        format={'YYYY-MM-DD'}
        className="cursor-pointer"
        disabled={!isConditionUpdatable}
        onChange={(value) => {
          update({
            variables: {
              uuid: workflowId,
              attributes: {
                prospectCustomizeItemSearchConditions: [
                  ...oldSearchCondition.slice(0, index),
                  {
                    customize_item_id:
                      prospectCustomizeItemDateSearchCondition.customize_item_id,
                    data_type:
                      prospectCustomizeItemDateSearchCondition.data_type,
                    from: prospectCustomizeItemDateSearchCondition.from,
                    to: value,
                  },
                  ...oldSearchCondition.slice(index + 1),
                ],
              },
            },
          });
        }}
        value={
          prospectCustomizeItemDateSearchCondition?.to
            ? moment(prospectCustomizeItemDateSearchCondition?.to)
            : null
        }
      />
    </div>
  );
};
