import React from 'react';
import {
  Approach,
  Action,
  ApproachProspect,
  ApproachPrelead,
  ApproachPreleadProject,
} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Approach>;
}

const Cell = ({row}: Props) => {
  const approach = row.original;

  const approachable = approach.approachable;

  return (
    <div className="flex items-center gap-2">
      <span className="border border-c-border rounded-sm h-5 px-1 text-xs min-w-[3rem]">
        {approach.prospect && 'リード'}
        {approach.prelead && '企業'}
      </span>
      <h4 className="flex items-center truncate font-medium text-xs m-0">
        {approach.approachableType === 'Action' && (
          <>
            {(approachable as Action).workflow.name}
            <br />
            {(approachable as Action).step.name}
          </>
        )}
        {approach.approachableType === 'Approach::Prospect' &&
          (approachable as ApproachProspect).approachGroupProspect.title}
        {approach.approachableType === 'Approach::Prelead' &&
          (approachable as ApproachPrelead).approachGroupPrelead.title}
        {approach.approachableType === 'Approach::PreleadProject' &&
          (approachable as ApproachPreleadProject).approachGroupPreleadProject
            .title}
      </h4>
    </div>
  );
};

export default Cell;
