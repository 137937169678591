import React from 'react';

export default () => (
  <svg
    width="49"
    height="36"
    viewBox="0 0 49 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29 7.5H39M29 12.5H39M35.25 17.5H39M26.5 27.5H41.5C42.8807 27.5 44 26.3808 44 25V5C44 3.61929 42.8807 2.5 41.5 2.5H26.5C25.1193 2.5 24 3.61929 24 5V25C24 26.3808 25.1193 27.5 26.5 27.5Z"
      stroke="#F99985"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8146 25.8628L31.9598 28.0638V24.9904V18.6123V15.9064L29.8994 17.6605L26.5022 20.553L25.4841 21.4197L26.4173 22.3772L29.8146 25.8628Z"
      fill="#F99985"
      stroke="white"
      strokeWidth="2.5"
    />
    <path
      d="M8.68323 22.3772L9.61703 21.4192L8.59786 20.5525L5.19649 17.6601L3.13672 15.9085V18.6123V24.9944V28.0678L5.28186 25.8669L8.68323 22.3772Z"
      fill="#F99985"
      stroke="white"
      strokeWidth="2.5"
    />
    <path
      d="M31.9593 28.1592V27.6505L31.6041 27.2864L26.5164 22.0701L25.7008 21.2338L24.8113 21.9911L19.0064 26.933C19.0061 26.9333 19.0057 26.9336 19.0053 26.9339C18.6439 27.2394 18.1307 27.4332 17.548 27.4332C16.9667 27.4332 16.457 27.2403 16.0945 26.9337C16.0943 26.9334 16.094 26.9332 16.0937 26.933L10.2843 21.9908L9.39483 21.2341L8.57947 22.0701L3.49187 27.2864L3.13672 27.6505V28.1592V31.3685C3.13672 32.8493 4.3483 34.0242 5.80978 34.0242H29.2862C30.7477 34.0242 31.9593 32.8492 31.9593 31.3685V28.1592Z"
      fill="#F99985"
      stroke="white"
      strokeWidth="2.5"
    />
    <path
      d="M31.5194 16.9848L31.9593 16.6104V16.0328V14.6271C31.9593 13.1418 30.7429 11.9756 29.2862 11.9756H5.80978C4.35299 11.9756 3.13672 13.1418 3.13672 14.6271V16.0328V16.6104L3.57664 16.9848L15.9869 27.5452C15.9874 27.5456 15.9878 27.546 15.9883 27.5464C16.4551 27.945 17.035 28.1012 17.5479 28.1012C18.061 28.1012 18.641 27.9449 19.1078 27.5462C19.1082 27.5458 19.1086 27.5455 19.109 27.5452L31.5194 16.9848Z"
      fill="#F99985"
      stroke="white"
      strokeWidth="2.5"
    />
  </svg>
);
