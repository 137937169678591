import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery} from 'api';
import {Button} from 'components/antd';
import DealField from './DealField';
import DealFieldOption from './DealFieldOption';
import Close from './Close';
import {SearchCondition} from './HubspotDealTypes';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const [hubspotDealSearchConditions, setHubspotDealSearchConditions] =
    React.useState([]);

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  React.useEffect(() => {
    setHubspotDealSearchConditions(
      searchCondition?.hubspotDealSearchConditions || [],
    );
  }, [searchCondition.hubspotDealSearchConditions]);

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <>
      <div className="flex mt-2">
        <Label>条件項目</Label>
        <div className="flex flex-col gap-1">
          {hubspotDealSearchConditions?.map(
            (condition: SearchCondition, index: number) => {
              return (
                <div className="flex" key={index}>
                  <DealField condition={condition} />
                  <DealFieldOption condition={condition} />
                  {isConditionUpdatable && (
                    <Close
                      hubspotDealSearchConditions={hubspotDealSearchConditions}
                      setHubspotDealSearchConditions={
                        setHubspotDealSearchConditions
                      }
                      index={index}
                    />
                  )}
                </div>
              );
            },
          )}
          <AddButton
            disabled={!isConditionUpdatable || !isMember}
            onClick={() =>
              setHubspotDealSearchConditions([
                ...hubspotDealSearchConditions,
                {} as SearchCondition,
              ])
            }>
            + 条件を追加
          </AddButton>
        </div>
      </div>
    </>
  );
};

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: #495058;
  padding-right: 8px;
  width: 110px;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
  width: 110px;
`;
