import React from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {Form} from 'api';
import {
  Bars3BottomLeftIcon,
  HashtagIcon,
  TagIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';

const otherFieldBlocks = [
  {
    blockType: 'otherField',
    dataType: 'text',
    fieldType: 'input',
    label: 'テキスト',
    defaultTitle: 'テキスト項目',
    icon: <Bars3BottomLeftIcon />,
  },
  {
    blockType: 'otherField',
    dataType: 'number',
    fieldType: 'input',
    label: '数値',
    defaultTitle: '数値項目',
    icon: <HashtagIcon />,
  },
  {
    blockType: 'otherField',
    dataType: 'date',
    fieldType: 'input',
    label: '日付',
    defaultTitle: '日付項目',
    icon: <CalendarIcon />,
  },
  {
    blockType: 'otherField',
    dataType: 'select',
    fieldType: 'checkbox',
    label: 'チェックボックス',
    defaultTitle: 'チェックボックス項目',
    icon: <TagIcon />,
  },
  {
    blockType: 'otherField',
    dataType: 'select',
    fieldType: 'radio',
    label: 'ラジオボタン',
    defaultTitle: 'ラジオボタン項目',
    icon: <TagIcon />,
  },
  {
    blockType: 'otherField',
    dataType: 'text',
    fieldType: 'textarea',
    label: 'テキストエリア',
    defaultTitle: 'テキストエリア項目',
    icon: <Bars3BottomLeftIcon />,
  },
];

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  return (
    <div className="my-4">
      <h4 className="font-bold text-base">その他の項目</h4>
      <Container
        groupName="formBlocks"
        behaviour="copy"
        getChildPayload={(i) => otherFieldBlocks[i]}>
        {otherFieldBlocks.map((b) => (
          <Draggable key={`${b.dataType}_${b.fieldType}`}>
            <div className="draggable-item cursor-move flex px-4 h-10 mt-1 border border-c-border rounded-sm items-center bg-white gap-3">
              <span className="w-3">{b.icon}</span>
              <span>{b.label}</span>
            </div>
          </Draggable>
        ))}
      </Container>
    </div>
  );
};
