import React from 'react';
import {Link, useParams, useRouteMatch} from 'react-router-dom';

export default () => {
  const {formId} = useParams<{
    formId: string;
  }>();

  const match = useRouteMatch<{tab: string}>({
    path: '/form/forms/:formId/:tab',
  });

  const tab = match?.params?.tab || 'form';

  return (
    <div className="flex items-center justify-center pt-4 border-b border-c-border">
      <ul className="font-bold flex flex-wrap -mb-px text-center">
        <li className="mr-2">
          <Link
            to={`/form/forms/${formId}/posts`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'posts'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            受信
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/forms/${formId}/form`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'form'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            フォーム
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/forms/${formId}/done`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'done'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            完了ページ
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/forms/${formId}/autoreply`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'autoreply'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            自動返信メール
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/forms/${formId}/edit`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'edit'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            設定
          </Link>
        </li>
        <li className="mr-2">
          <Link
            to={`/form/forms/${formId}/publish`}
            className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
              tab === 'publish'
                ? 'border-c-base text-c-base'
                : 'border-transparent text-c-lighter'
            }`}>
            公開
          </Link>
        </li>
      </ul>
    </div>
  );
};
