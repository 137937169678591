import React from 'react';
import {useUpdateCollectedFormUrlMutation, CollectedFormUrl} from 'api';
import useDebounce from 'hooks/useDebounce';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const [updateCollectedFormUrl] = useUpdateCollectedFormUrlMutation();

  const [value, setValue] = React.useState(
    collectedFormUrl.notificationMailNote,
  );
  const debouncedValue = useDebounce<string>(value, 500);

  React.useEffect(() => {
    debouncedValue !== collectedFormUrl.notificationMailNote &&
      updateCollectedFormUrl({
        variables: {
          collectedFormUrlUuid: collectedFormUrl.uuid,
          attributes: {
            notificationMailNote: debouncedValue,
          },
        },
      });
  }, [collectedFormUrl, debouncedValue]);

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-1 w-full">
        <label className="text-c-base font-bold">通知メールコメント</label>
        <div className="relative">
          <textarea
            className="form-input border-c-border rounded text-base w-full disabled:bg-c-bg disabled:text-c-light"
            rows={5}
            placeholder="伝えたいコメントを入力できます"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
