import React from 'react';
import {Route, Switch} from 'react-router-dom';
import List from './List';
import Workflow from './Workflow';

export default () => (
  <Switch>
    <Route path="/pools/:poolId/workflows/:workflowId" component={Workflow} />
    <Route path="/pools/:poolId/workflows" component={List} />
  </Switch>
);
