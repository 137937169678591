import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26">
      <path
        fill="#4285F4"
        fillRule="evenodd"
        d="M21.64 13.205c0-.639-.057-1.252-.164-1.841H13v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
        clipRule="evenodd"></path>
      <path
        fill="#34A853"
        fillRule="evenodd"
        d="M13 22c2.43 0 4.467-.806 5.956-2.18l-2.909-2.259c-.805.54-1.836.86-3.047.86-2.344 0-4.328-1.584-5.036-3.711H4.957v2.332A8.997 8.997 0 0013 22z"
        clipRule="evenodd"></path>
      <path
        fill="#FBBC05"
        fillRule="evenodd"
        d="M7.964 14.71A5.41 5.41 0 017.682 13c0-.593.102-1.17.282-1.71V8.958H4.957A8.997 8.997 0 004 13c0 1.452.348 2.827.957 4.042l3.007-2.332z"
        clipRule="evenodd"></path>
      <path
        fill="#EA4335"
        fillRule="evenodd"
        d="M13 7.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C17.462 4.891 15.426 4 13 4a8.997 8.997 0 00-8.043 4.958l3.007 2.332C8.672 9.163 10.656 7.58 13 7.58z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default Icon;
