import React from 'react';
import styled from 'styled-components';
import {Input, Form, Popover, Button} from 'components/antd';
import {FormikProps} from 'formik';
import {ContentMailFormAttributes} from 'api';
import ContentsModal from './ContentsModal';

interface Props {
  formik: FormikProps<ContentMailFormAttributes>;
}

export default ({formik}: Props) => {
  const [messageType, setMessageType] = React.useState('long');
  const [contentModalVisible, setContentModalVisible] = React.useState(false);

  return (
    <Container>
      <Form>
        <Form.Item
          label="件名"
          required
          validateStatus={
            formik.errors.title && formik.touched.title ? 'error' : ''
          }
          help={
            formik.errors.title && formik.touched.title
              ? formik.errors.title
              : null
          }>
          <Input
            type="text"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <Form.Item
          label="本文"
          required
          validateStatus={
            formik.errors.message1 && formik.touched.message1 ? 'error' : ''
          }
          help={
            formik.errors.message1 && formik.touched.message1
              ? formik.errors.message1
              : null
          }>
          <div>
            <Menu>
              <MenuItems>
                <MenuItem
                  active={messageType === 'long'}
                  onClick={() => setMessageType('long')}>
                  長文バージョン
                </MenuItem>
                <MenuItem
                  active={messageType === 'short'}
                  onClick={() => setMessageType('short')}>
                  短文バージョン
                </MenuItem>
                <MenuItem
                  active={messageType === 'mail'}
                  onClick={() => setMessageType('mail')}>
                  Emailバージョン
                </MenuItem>
              </MenuItems>
            </Menu>
            {messageType === 'long' && (
              <>
                <Header>
                  <Count>文字数:{formik.values.message1.length}</Count>
                  <Notice>{'※{会社名}で会社名が挿入されます'}</Notice>
                  <Popover
                    content={
                      <ContentsModal
                        onClick={(text: string) =>
                          formik.setFieldValue(
                            'message1',
                            formik.values.message1 + '\n' + text,
                          )
                        }
                      />
                    }
                    trigger="click"
                    visible={contentModalVisible}
                    placement="bottomRight"
                    onVisibleChange={setContentModalVisible}>
                    <ContentButton>+</ContentButton>
                  </Popover>
                </Header>
                <Input.TextArea
                  name="message1"
                  value={formik.values.message1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={15}
                />
              </>
            )}
            {messageType === 'short' && (
              <>
                <Header>
                  <Count>文字数:{formik.values.message2.length}</Count>
                  <Notice>{'※{会社名}で会社名が挿入されます'}</Notice>
                  <Popover
                    content={
                      <ContentsModal
                        onClick={(text: string) =>
                          formik.setFieldValue(
                            'message2',
                            formik.values.message2 + '\n' + text,
                          )
                        }
                      />
                    }
                    trigger="click"
                    visible={contentModalVisible}
                    placement="bottomRight"
                    onVisibleChange={setContentModalVisible}>
                    <ContentButton>+</ContentButton>
                  </Popover>
                </Header>
                <Input.TextArea
                  name="message2"
                  value={formik.values.message2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={15}
                />
              </>
            )}
            {messageType === 'mail' && (
              <>
                <Header>
                  <Count>文字数:{formik.values.messageMail.length}</Count>
                  <Notice>{'※{会社名}で会社名が挿入されます'}</Notice>
                  <Popover
                    content={
                      <ContentsModal
                        onClick={(text: string) =>
                          formik.setFieldValue(
                            'messageMail',
                            formik.values.messageMail + '\n' + text,
                          )
                        }
                      />
                    }
                    trigger="click"
                    visible={contentModalVisible}
                    placement="bottomRight"
                    onVisibleChange={setContentModalVisible}>
                    <ContentButton>+</ContentButton>
                  </Popover>
                </Header>
                <Input.TextArea
                  name="messageMail"
                  value={formik.values.messageMail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={15}
                />
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  label {
    font-size: 13px;
    color: #899098;
    font-weight: bold;
  }
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  color: #899098;
  flex: 1;
`;

const Menu = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const MenuItems = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  background-color: #f3f5f7;
  border-radius: 12px;
`;

const MenuItem = styled.div<{active?: boolean}>`
  cursor: pointer;
  background-color: ${({active}) => (active ? '#DADCDE' : 'transparent')};
  margin: 0;
  height: 24px;
  line-height: 24px;
  padding: 0 1rem;
  border-radius: 12px;
  font-size: 10px;
  font-weight: ${({active}) => (active ? '700' : '400')};
  color: ${({active}) => (active ? '#495058' : '#899098')};

  &:first-child::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 12px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 3px;
`;

const Notice = styled.span`
  font-size: 12px;
  margin: 0 1rem;
`;

const ContentButton = styled(Button)`
  &&& {
    padding: 0 10px;
    height: 30px;
  }
`;
