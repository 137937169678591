import React from 'react';
import moment from 'moment';
import {ProspectPool, useCurrentClientQuery} from 'api';

type Props = {
  prospectPool: ProspectPool;
};

export default ({prospectPool}: Props) => {
  const {data: {currentClient: {currentTrackingContract = {}} = {}} = {}} =
    useCurrentClientQuery();

  if (!currentTrackingContract?.isActive) return null;

  return (
    <div className="flex items-center gap-2 flex-wrap mt-2 mb-4">
      <div className="text-sm h-6 p-2 flex items-center gap-1 border border-c-border rounded">
        <span className="text-c-light">Web訪問日</span>
        <span className="bg-c-bg rounded-full px-2 leading-none font-bold text-xs flex h-4 items-center">
          {prospectPool?.trackedAt
            ? moment(prospectPool?.trackedAt).format('YYYY/MM/DD')
            : '-'}
        </span>
      </div>
    </div>
  );
};
