import React from 'react';
import styled from 'styled-components';
import {Search} from 'components/Ui/Icon';
import {Prelead} from 'api';

interface Props {
  prelead: Prelead | null;
}

const SearchLink = ({prelead}: Props) => {
  return (
    <SearchAnchorWrapper>
      <Search />
      {prelead && prelead.corpNumber && (
        <span>
          <SearchAnchor
            href={`https://www.google.com/search?q=${prelead.corpNumber}`}
            target="_blank">
            法人番号
          </SearchAnchor>
        </span>
      )}
      {prelead && prelead.name && (
        <span>
          <SearchAnchor
            href={`https://www.google.com/search?q=${prelead.name}`}
            target="_blank">
            会社名
          </SearchAnchor>
        </span>
      )}
      {prelead && prelead.address && (
        <span>
          <SearchAnchor
            href={`https://www.google.com/search?q=${prelead.address}`}
            target="_blank">
            住所
          </SearchAnchor>
        </span>
      )}
      {prelead && prelead.representativePerson && (
        <span>
          <SearchAnchor
            href={`https://www.google.com/search?q=${prelead.representativePerson}`}
            target="_blank">
            代表者
          </SearchAnchor>
        </span>
      )}
    </SearchAnchorWrapper>
  );
};

const SearchAnchorWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 14px;
  }
  span {
    margin-left: 6px;
  }
`;

const SearchAnchor = styled.a`
  color: #899098;
  font-size: 10px;
  text-decoration: underline;
`;

export default SearchLink;
