import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const TrackedAtFilter = () => {
  const {query, setQuery} = useSearchParams();

  const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({...query, trackedAtFrom: e.target.value});

  const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({...query, trackedAtTo: e.target.value});

  return (
    <>
      <Header title="最新Webサイト訪問期間" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <input
              type="date"
              value={query.trackedAtFrom}
              onChange={onChangeFrom}
              className="form-input appearance-none w-32 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
            />
            <span>~</span>
            <input
              type="date"
              value={query.trackedAtTo}
              onChange={onChangeTo}
              className="form-input appearance-none w-32 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
            />
          </div>
        </div>
      </div>
      <Footer
        onClear={() => setQuery({...query, trackedAtFrom: '', trackedAtTo: ''})}
      />
    </>
  );
};

export default TrackedAtFilter;
