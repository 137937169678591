import {StringParam, NumberParam, useQueryParams} from 'use-query-params';

const PAGE_SIZE = 10;

const searchParamsDef = {
  page: NumberParam,
  searchWord: StringParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page, searchWord} = query;

  const setPage = (page: number) => setQuery({page});

  return {
    query,
    setQuery,
    page,
    perPage: PAGE_SIZE,
    setPage,
    searchWord
  };
};

export default useSearchParams;
