import React from 'react';
import styled from 'styled-components';
import {Modal, Input, Button, Form} from 'components/antd';
import {useParams} from 'react-router';
import * as Yup from 'yup';
// import {message} from 'components/antd';
import {useFormik} from 'formik';
import {useCreatePreleadContactMutation} from 'api';

const validateSchema = Yup.object().shape({
  lastName: Yup.string().required('必須項目です'),
  telephoneNumber: Yup.string()
    .matches(/^[0-9-]+$/, {
      message: '電話番号は数字または数字とハイフンの形式で入力してください。',
    })
    .nullable(),
});

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const [createPreleadContact, {loading}] = useCreatePreleadContactMutation({
    refetchQueries: ['preleadContacts', 'preleadProject'],
    onCompleted: () => {
      resetForm();
      setModalVisible(false);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: null,
      lastName: null,
      section: null,
      sectionPosition: null,
      email: null,
      telephoneNumber: null,
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      createPreleadContact({
        variables: {
          preleadProjectUuid: preleadProjectId,
          attributes: values,
        },
      });
    },
  });

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <AddModal
      title="連絡先を追加"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <SaveButton
          key={'submit'}
          loading={loading}
          disabled={!isValid || loading || !values.lastName}
          onClick={() => handleSubmit()}>
          保存
        </SaveButton>,
      ]}
      style={{
        // top: '30px',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-59%, 23%)',
      }}>
      <FormSection>
        <Form
          labelCol={{span: 4}}
          wrapperCol={{span: 12}}
          onFinish={handleSubmit}>
          <NameBox>
            <Form.Item
              label="姓"
              required
              validateStatus={
                errors.lastName && touched.lastName ? 'error' : ''
              }
              // help={errors.lastName && touched.lastName ? errors.lastName : ''}
            >
              <Input
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              label="名"
              validateStatus={
                errors.firstName && touched.firstName ? 'error' : ''
              }
              // help={errors.firstName && touchederrors.firstName ? errors.firstName : ''}
            >
              <Input
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
          </NameBox>

          <Form.Item label="メールアドレス">
            <Input
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>

          <FormItem
            label="電話番号"
            help={errors.telephoneNumber}
            validateStatus={errors.telephoneNumber && 'error'}>
            <Input
              name="telephoneNumber"
              value={values.telephoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormItem>

          <Form.Item label="部署名">
            <Input
              name="section"
              value={values.section}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>

          <Form.Item label="役職">
            <Input
              name="sectionPosition"
              value={values.sectionPosition}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Form>
      </FormSection>
    </AddModal>
  );
};

const AddModal = styled(Modal)`
  .ant-modal-content {
    width: 330px;
    border-radius: 5px;
    padding: 14px 15px 17px 15px;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 14px;
    color: #495058;
  }

  .ant-modal-header {
    padding: 0;
    border: none;
  }

  .ant-modal-body {
    padding: 0;
    margin-bottom: 18px;
  }

  .ant-modal-footer {
    padding: 0;
    border: none;
  }
`;

const DefaultButton = styled(Button)`
border-radius: 3px;
padding: 6px 23px;
`;

const SaveButton = styled(DefaultButton)`
  background: #68b5fb;

  &:hover {
    background: #65aae8;
  }

  span {
    color: #fff;
  }
`;

const FormSection = styled.div`
  form {
    .ant-form-item {
      margin: 0;
      display: block;
      // height: 95px;

      .ant-form-item-label {
        display: inline;
        label {
          font-size: 10px;
          color: #899098;
        }
      }
      input {
        width: 301px;
        height: 32px;

        background: #ffffff;
        border: 1px solid #bdc4cb;
        border-radius: 2px;

        &:focus {
          background: #f3f5f7;
          border: 1px solid #68b4fb;
        }
      }
    }
  }
`;

const NameBox = styled.div`
  display: flex;
  > div:last-child {
    margin-left: auto;
  }
  input {
    width: 144px !important;
    height: 32px;
  }
`;

const FormItem = styled(Form.Item)`
  .ant-col-12 {
    max-width: 100%;
  }
`;
