import React from 'react';
import {CheckedProspectPoolsContext} from '../CheckedProspectPoolContext';
import {ProspectPool} from 'api';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {checkedProspectPoolIds, setCheckedProspectPoolIds} = React.useContext(
    CheckedProspectPoolsContext,
  );

  const onCheck = () => {
    const ids = !checkedProspectPoolIds.includes(prospectPool.id)
      ? [...checkedProspectPoolIds, prospectPool.id]
      : checkedProspectPoolIds?.filter(
          (checkedProspectPoolId) => checkedProspectPoolId !== prospectPool.id,
        );
    setCheckedProspectPoolIds(ids);
  };

  return (
    <div className="flex items-center justify-center ml-5">
      <input
        type="checkbox"
        checked={checkedProspectPoolIds?.includes(prospectPool.id)}
        onChange={onCheck}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 outline-none cursor-pointer"
      />
    </div>
  );
};
