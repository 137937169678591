import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import {
  usePreleadQuery,
  useUpdatePreleadUserMutation,
  useUsersByClientAllQuery,
} from 'api';
import usePreleadContext from '../../usePreleadContext';

export default () => {
  const {preleadId} = usePreleadContext();

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const [updatePreleadUser] = useUpdatePreleadUserMutation();

  const preleadUserUuid = users.find((user) => user.id === prelead.userId)
    ?.uuid;

  return (
    <Container>
      <Select
        value={preleadUserUuid || ''}
        showSearch
        optionFilterProp="children"
        onChange={(value) =>
          updatePreleadUser({
            variables: {uuid: prelead?.uuid, userUuid: value},
          })
        }>
        <Select.Option value="">担当者なし</Select.Option>
        {users.map((user) => (
          <Select.Option value={user.uuid} key={user.uuid}>
            {user.lastName} {user.firstName}
          </Select.Option>
        ))}
      </Select>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 200px;

  .ant-select {
    width: 100%;
    margin-top: -7px;
  }
`;
