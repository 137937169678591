import React from 'react';
import {AccountPool} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<AccountPool>;
}

const StatusCell = ({row}: Props) => {
  const clientAccountStatusName = row?.original?.clientAccountStatus?.name;

  return (
    <div className="flex gap-1 pl-4 h-full border-l border-c-border items-center">
      {clientAccountStatusName && (
        <div className="px-2 h-5 rounded-full font-bold text-[10px] bg-[#DCEEFF] text-[#005388]">
          {clientAccountStatusName}
        </div>
      )}
    </div>
  );
};
export default StatusCell;
