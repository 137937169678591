import React from 'react';
import {Approach, ProspectPool} from 'api';
import Name from './Name';
import Status from './Status';
import ApproachType from './ApproachType';
import Priority from './Priority';
import DueDate from './DueDate';
import Assignee from './Assignee';
import Modal from '../Modal';
import MailModal from '../MailModal';
import ActionButton from './ActionButton';
import Menu from './Menu';
import {ContentMail, ContentTelScript, Comment} from 'components/Ui/Icon';

interface Props {
  prospectPool: ProspectPool;
  approach: Approach;
}

export type approachTypeMenu = {
  key: string;
  icon: React.ReactNode;
  label: string;
  action: () => void;
};

export default ({prospectPool, approach}: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [showMailModal, setShowMailModal] = React.useState(false);
  const [defaultFormType, setDefaultFormType] = React.useState('call');

  const approachTypeMenus: approachTypeMenu[] = [
    {
      key: 'manual_mail',
      icon: <ContentMail color="#F99985" />,
      label: 'メール作成',
      action: () => {
        setShowMailModal(true);
      },
    },
    {
      key: 'call',
      icon: <ContentTelScript color="#8DD888" />,
      label: 'TELログ登録',
      action: () => {
        setDefaultFormType('call');
        setShowModal(true);
      },
    },
    {
      key: 'task',
      icon: (
        <svg
          width="41"
          height="36"
          viewBox="0 0 41 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24 7.16667H34M24 12.3333H34M30.25 17.5H34M21.5 27.8333H36.5C37.8807 27.8333 39 26.6768 39 25.25V4.58333C39 3.1566 37.8807 2 36.5 2H21.5C20.1193 2 19 3.1566 19 4.58333V25.25C19 26.6768 20.1193 27.8333 21.5 27.8333Z"
            stroke="#F8D55C"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.75 34.375H22.25C23.7688 34.375 25 33.1438 25 31.625V17.6391C25 16.9097 24.7103 16.2103 24.1945 15.6945L16.1805 7.68046C15.6647 7.16473 14.9653 6.875 14.236 6.875H5.75C4.23122 6.875 3 8.10622 3 9.625V31.625C3 33.1438 4.23122 34.375 5.75 34.375Z"
            fill="#F8D55C"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3748 7.5625V16.5H23.6248"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.04881 24.7494L11.7988 27.2244L17.2988 22.2744"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      label: 'タスクログ登録',
      action: () => {
        setDefaultFormType('task');
        setShowModal(true);
      },
    },
    {
      key: 'comment',
      icon: <Comment />,
      label: 'コメント登録',
      action: () => {
        setDefaultFormType('comment');
        setShowModal(true);
      },
    },
  ];

  const addMailLogApproachTypeMenus = [...approachTypeMenus];
  addMailLogApproachTypeMenus.splice(1, 0, {
    key: 'manual_mail_log',
    icon: <ContentMail color="#F99985" />,
    label: 'メールログ登録',
    action: () => {
      setDefaultFormType('manual_mail');
      setShowModal(true);
    },
  });

  React.useEffect(() => {
    setDefaultFormType(approach?.approachType);
  }, [approach]);

  return (
    <>
      <div className="flex flex-col gap-1 py-4 border-b border-c-border">
        <div
          className="cursor-pointer"
          onClick={() => {
            setDefaultFormType(approach?.approachType);
            setShowModal(true);
          }}>
          <Name approach={approach} />
        </div>
        <div className="flex justify-between">
          <div>
            <div className="flex items-center mb-1">
              <div className="min-w-[58px] mr-1">
                <Status approach={approach} />
              </div>
              <div className="min-w-[62px]">
                <ApproachType approach={approach} />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="min-w-[72px] mr-3">
                  <Priority approach={approach} />
                </div>
                <div className="min-w-[102px] mr-3">
                  <DueDate approach={approach} />
                </div>
                <div className="min-w-[125px] mr-3">
                  <Assignee approach={approach} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <ActionButton
              approach={approach}
              approachTypeMenus={approachTypeMenus}
            />
            <Menu approachTypeMenus={addMailLogApproachTypeMenus} />
          </div>
        </div>
      </div>
      <Modal
        visible={showModal}
        onClose={() => setShowModal(false)}
        prospectPool={prospectPool}
        approach={approach}
        defaultFormType={defaultFormType}
      />
      <MailModal
        visible={showMailModal}
        onClose={() => setShowMailModal(false)}
        approach={approach}
        prospectPool={prospectPool}
      />
    </>
  );
};
