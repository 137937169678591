import gql from 'graphql-tag';
export default gql`
  fragment taskCommentProspectActivityFragment on TaskCommentProspectActivity {
    id
    comment
    commentHtml
    stage
    stageText
    recordedAt
    createdAt
  }
`;
