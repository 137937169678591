import React from 'react';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormBlock, FormBlockText, useUpdateFormBlockTextMutation} from 'api';
import {useForm, Controller} from 'react-hook-form';
import ReactQuill from 'react-quill';
import debounce from 'helpers/debounce';

interface Block extends FormBlock {
  formBlockable: FormBlockText;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const [update] = useUpdateFormBlockTextMutation();

  const {control, watch, setValue, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        text: Yup.string().required(),
        textHtml: Yup.string().required(),
      }),
    ),
    defaultValues: {
      text: block.formBlockable.text,
      textHtml: block.formBlockable.textHtml || block.formBlockable.text,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: any) => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-lg font-bold">冒頭概要説明文の編集</h2>
      <label className="tracking-wide font-medium text-base text-c-light">
        テキスト
      </label>
      <div
        className="
        relative
        [&_a]:text-c-primary
        [&_.ql-toolbar]:border-t-none
        [&_.ql-toolbar]:border-x-none
        [&_.ql-toolbar]:border-c-border
        [&_.ql-toolbar]:bg-white
        [&_.ql-toolbar]:rounded-t
        [&_.ql-container]:min-h-[100px]
        [&_.ql-container]:border-c-border
        [&_.ql-container]:bg-white
        [&_.ql-editor]:min-h-[100px]
        [&_.ql-editor]:rounded-b
        [&_.ql-editor_a]:no-underline
      ">
        <Controller
          name="textHtml"
          control={control}
          render={({field}) => (
            <ReactQuill
              {...field}
              theme="snow"
              placeholder="本文を記入してください"
              modules={{
                toolbar: {
                  container: [
                    [
                      'bold',
                      'italic',
                      'underline',
                      'link',
                      {align: ['', 'center']},
                    ],
                  ],
                },
              }}
              onChange={(content, delta, source, editor) => {
                field.onChange(content);
                setValue('text', editor.getText());
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
