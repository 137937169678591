import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  SalesforceEventCreateProspectActivity,
  usePoolQuery,
} from 'api';
import {useParams} from 'react-router-dom';
import {ProspectChatBadge, SalesforceIcon} from 'components/Ui/Icon';

interface SalesforceEventActivity extends ProspectActivity {
  resource: SalesforceEventCreateProspectActivity;
}

interface Props {
  prospectActivity: SalesforceEventActivity;
}

export default ({prospectActivity}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const {
    resource: {
      salesforceEvent: {
        activityDateTime,
        endDateTime,
        eventId,
        salesforceUser,
        subject,
        location,
        description,
      },
    },
  } = prospectActivity;
  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const url = pool?.poolSalesforceSetting?.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  return (
    <>
      <ProspectChatBadge />
      <Content>
        <ContentHeader>
          <a href={`${url}/lightning/r/Event/${eventId}/view`} target="_blank">
            <h3>{subject}</h3>
          </a>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </ContentHeader>
        <ContentBox>
          <IconBox>
            <Icon>
              <SalesforceIcon />
            </Icon>
            <IconText>行動</IconText>
          </IconBox>
          <Fields>
            <Field>
              <FieldKey>開始</FieldKey>
              <FieldValue>
                {activityDateTime &&
                  moment(activityDateTime).format('YYYY/MM/DD HH:mm')}
              </FieldValue>
            </Field>
            <Field>
              <FieldKey>終了</FieldKey>
              <FieldValue>
                {endDateTime && moment(endDateTime).format('YYYY/MM/DD HH:mm')}
              </FieldValue>
            </Field>
            <Field>
              <FieldKey>割り当て先</FieldKey>
              <FieldValue>
                {salesforceUser &&
                  `${salesforceUser?.lastName} ${salesforceUser.firstName}`}
              </FieldValue>
            </Field>
            <Field>
              <FieldKey>場所</FieldKey>
              <FieldValue>{location}</FieldValue>
            </Field>
            <FieldColumn>
              <FieldKey>説明</FieldKey>
              <FieldValue>{description}</FieldValue>
            </FieldColumn>
          </Fields>
        </ContentBox>
      </Content>
    </>
  );
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 9px;
`;

const FieldColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Field = styled.div`
  display: flex;
  gap: 12px;
`;

const FieldKey = styled.div`
  color: #bdc4cb;
  font-family: 'Noto Sans JP';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
`;

const FieldValue = styled.div`
  color: #495058;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
`;

const IconBox = styled.div`
  border-radius: 2px;
  background: rgba(3, 155, 229, 0.1);
  width: 49px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  gap: 4px;
  line-height: 20px;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`;

const IconText = styled.div`
  color: rgba(69, 160, 218, 0.9);
  text-align: center;
  font-family: 'Noto Sans JP';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 30px;
  margin-top: 6px;

  h3 {
    flex: 1;
    margin: 0;
    font-weight: bold;
    color: #68b5fb;
  }

  span,
  time {
    margin: 5px 0px;
    color: #899098;
    font-size: 10px;
  }
`;

const ContentBox = styled.div`
  border: solid 1px #e2e6ea;
  border-radius: 4px;
  padding: 15px;
  white-space: pre-wrap;
`;
