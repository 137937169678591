import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import Form from './Form';
import {useHistory} from 'react-router';
import {useCreateTeamMutation} from 'api';

export default () => {
  const history = useHistory();
  const [createTeam, {loading}] = useCreateTeamMutation({
    onCompleted: () => history.push('/settings/teams'),
  });

  return (
    <Container>
      <Form onSubmit={createTeam} loading={loading} />
    </Container>
  );
};

const Container = styled.div`
  padding: 28px 70px 45px;
`;
