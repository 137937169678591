import React from 'react';
import Item from './Item';
import {usePreleadQuery} from 'api';
import {useParams} from 'react-router-dom';
import NewModal from './NewModal';

const Task: React.FC = () => {
  const {preleadId} = useParams<{
    preleadId: string;
  }>();
  const [isOpen, setIsOpen] = React.useState(false);

  const {data: {prelead: {tasks = []} = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
  });
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-end w-full flex-row pt-3.5 pb-[18px]">
        <button
          className="rounded font-medium text-sm h-8 px-4 cursor-pointer bg-white border border-c-border"
          onClick={() => setIsOpen(true)}>
          ToDoを登録
        </button>
      </div>

      <div className="border-t border-c-border">
        {tasks.map((task) => (
          <Item key={task.id} task={task} />
        ))}
      </div>
      <NewModal visible={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};

export default Task;
