import React from 'react';
import {useParams} from 'react-router-dom';
import {PreleadProject, useCurrentClientQuery, useProjectQuery} from 'api';

interface Props {
  row: any;
  value: any;
}

const WebSiteCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const {projectId} = useParams<{
    projectId: string;
  }>();
  const {data: {currentClient} = {}} = useCurrentClientQuery();
  const {data: {project} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const href =
    project?.clientId === currentClient?.id
      ? `/prelead_projects/ext?project_id=${projectId}&prelead_project_id=${preleadProject.uuid}`
      : preleadProject?.prelead.webSite;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {preleadProject?.prelead.webSite}
    </a>
  );
};

export default WebSiteCell;
