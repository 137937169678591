import gql from 'graphql-tag';

export default gql`
  fragment clientUserGoogleSettingFragment on ClientUserGoogleSetting {
    id
    isActive
    firstName
    lastName
    email
  }
`;
