import React from 'react';
import useCondition from '../../../useCondition';
import useFilterModalContext from '../../useFilterModalContext';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {
  useHubspotDealPipelinesQuery,
  useHubspotDealPipelineStagesQuery,
} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  const {data: {hubspotDealPipelines = []} = {}} =
    useHubspotDealPipelinesQuery();

  const {data: {hubspotDealPipelineStages = []} = {}} =
    useHubspotDealPipelineStagesQuery({
      variables: {
        hubspotDealPipelineId: condition?.hubspotDealPipelineId,
      },
      skip: !condition?.hubspotDealPipelineId,
    });

  const pipeline = hubspotDealPipelines.find(
    (pipeline) => pipeline.id === condition?.hubspotDealPipelineId,
  );

  const stages = hubspotDealPipelineStages.filter((stage) =>
    condition?.hubspotDealPipelineStageIds.includes(stage.id),
  );

  if (!condition?.hubspotDealPipelineId) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">取引</h4>
        <button
          onClick={() => setFilterType('hubspotDeal')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setCondition({
              hubspotDealPipelineId: '',
              hubspotDealPipelineStageIds: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-c-light">パイプライン：</span>
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {pipeline?.label}
        </span>
      </div>
      {stages.length > 0 && (
        <div className="flex gap-2">
          <span className="text-c-light h-6 flex items-center">ステージ：</span>
          <div className="flex-1 flex flex-wrap items-center gap-1">
            {stages.map((stage) => (
              <span
                key={stage.id}
                className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {stage.label}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
