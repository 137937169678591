import {AccountSignal} from 'api';
import moment from 'moment';
import React from 'react';

interface Props {
  signal: AccountSignal;
}

export default ({signal}: Props) => {
  return (
    <>
      <span className="text-gray-700">
        {moment(signal.date).format('YYYY/MM/DD')}
      </span>
      <span className="px-2.5 py-1 bg-c-bg rounded text-sm leading-[17px] text-c-base whitespace-nowrap">
        求人
      </span>
      <span className="truncate w-[615px]">
        <a href={signal.signalable.url} target="_blank">
          {signal.signalable?.title || signal.signalable.url}
        </a>
      </span>
    </>
  );
};
