import React from 'react';
import {useRecoilState} from 'recoil';
import checkedPreleadsAtom from '../checkedPreleadsAtom';
import {usePreleadsQuery} from 'api';
import useSearchParams from '../useSearchParams';

const CheckHeader = () => {
  const {page, perPage, searchParams} = useSearchParams();

  const [checkedPreleads, setCheckedPreleads] = useRecoilState(
    checkedPreleadsAtom,
  );

  const {data: {preleads: {preleads = []} = {}} = {}} = usePreleadsQuery({
    variables: {
      search: searchParams,
      page,
      perPage,
    },
  });

  const preleadIds = React.useMemo(
    () => preleads.map((prelead) => prelead.uuid),
    [preleads],
  );

  const checked = React.useMemo(
    () => preleadIds.every((id) => checkedPreleads.includes(id)),
    [checkedPreleads, preleadIds],
  );

  const handleChange = React.useCallback(
    (e: any) => {
      if (e.target.checked) {
        setCheckedPreleads(preleadIds);
      } else {
        setCheckedPreleads([]);
      }
    },
    [preleadIds],
  );

  return <input type="checkbox" checked={checked} onChange={handleChange} />;
};

export default CheckHeader;
