import React from 'react';
import {DotMenuSide} from 'components/Ui/Icon';
import Dropdown from './Dropdown';
import TagModal from './TagModal';
import AssignModal from './AssignModal';
import ApproachModal from './ApproachModal';

export default () => {
  const [tagModalVisible, setTagModalVisible] = React.useState(false);
  const [approachModalVisible, setApproachModalVisible] = React.useState(false);
  const [assignModalVisible, setAssignModalVisible] = React.useState(false);

  return (
    <>
      <Dropdown
        options={[
          {
            label: 'リードタグ一括追加',
            onClick: () => setTagModalVisible(true),
          },
          {
            label: 'リード担当者一括変更',
            onClick: () => setAssignModalVisible(true),
          },
          {
            label: 'アプローチ一括登録',
            onClick: () => setApproachModalVisible(true),
          },
        ]}
        className="cursor-pointer bg-transparent text-white border-none text-sm hover:bg-[#E9F4FF] rounded-full flex items-center justify-center h-8 w-8 disabled:bg-c-bg disabled:text-c-light">
        <DotMenuSide color={'#899098'} />
      </Dropdown>
      <TagModal
        visible={tagModalVisible}
        onClose={() => setTagModalVisible(false)}
      />
      <ApproachModal
        visible={approachModalVisible}
        onClose={() => setApproachModalVisible(false)}
      />
      <AssignModal
        visible={assignModalVisible}
        onClose={() => setAssignModalVisible(false)}
      />
    </>
  );
};
