import React from 'react';
import useSearchParams from '../../../useSearchParams';
import CheckboxGroup from './CheckboxGroup';
import Header from '../Header';
import Footer from './Footer';
import {taskPriorities} from 'helpers/task';

const PriorityFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="優先度" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            options={taskPriorities.map((priority) => ({
              text: priority.name,
              value: priority.value,
              keyword: priority.name,
            }))}
            values={query.priorities || []}
            onChange={(values: string[]) =>
              setQuery({...query, priorities: values, page: 1})
            }
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, priorities: []})} />
    </>
  );
};

export default PriorityFilter;
