import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {useProspectMailCampaignsQuery, useMailCampaignUrlsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {prospectMailCampaigns: {mailCampaigns = []} = {}} = {}} =
    useProspectMailCampaignsQuery({});

  const {data: {mailCampaignUrls = []} = {}} = useMailCampaignUrlsQuery({
    variables: {
      uuid: query.mailCampaignId,
    },
    skip: !query.mailCampaignId,
  });

  if (
    query.isTargetAllMailCampaigns
      ? !query.isTargetAllMailCampaigns
      : !query?.mailCampaignId
  ) {
    return null;
  }

  const findMailCampaign = mailCampaigns.find(
    (mailCampaign) => mailCampaign.uuid === query.mailCampaignId,
  );

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">メールキャンペーン</h4>
        <button
          onClick={() => setFilterType('mailCampaign')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              mailCampaignId: '',
              mailCampaignUrlIds: [],
              isTargetAllMailCampaigns: false,
              mailCampaignOpenedFromDate: '',
              mailCampaignOpenedToDate: '',
              mailCampaignClickedFromDate: '',
              mailCampaignClickedToDate: '',
              isBouncedMailCampaignSend: false,
              isUnsubscribedMailCampaignSend: false,
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap gap-1">
        {findMailCampaign && (
          <span
            key={findMailCampaign.id}
            className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {findMailCampaign.title}
          </span>
        )}
      </div>
      {(query.mailCampaignOpenedFromDate || query.mailCampaignOpenedToDate) && (
        <div>
          <span className="text-c-light">開封日：</span>
          {query.mailCampaignOpenedFromDate && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.mailCampaignOpenedFromDate}
            </span>
          )}
          <span className="text-c-light px-2">~</span>
          {query.mailCampaignOpenedToDate && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.mailCampaignOpenedToDate}
            </span>
          )}
        </div>
      )}
      {(query.mailCampaignClickedFromDate ||
        query.mailCampaignClickedToDate) && (
        <div>
          <span className="text-c-light">クリック日：</span>
          {query.mailCampaignClickedFromDate && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.mailCampaignClickedFromDate}
            </span>
          )}
          <span className="text-c-light px-2">~</span>
          {query.mailCampaignClickedToDate && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.mailCampaignClickedToDate}
            </span>
          )}
        </div>
      )}
      {query.mailCampaignUrlIds?.length > 0 && (
        <div className="mt-3 flex gap-1 flex-wrap">
          {mailCampaignUrls
            .filter((mailCampaignUrl) =>
              query.mailCampaignUrlIds.includes(mailCampaignUrl.id),
            )
            .map((mailCampaignUrl) => {
              return (
                <span
                  key={mailCampaignUrl.id}
                  className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                  {mailCampaignUrl.url}
                </span>
              );
            })}
        </div>
      )}
      {query.isBouncedMailCampaignSend && (
        <div className="flex flex-wrap gap-1">
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            バウンス
          </span>
        </div>
      )}
      {query.isUnsubscribedMailCampaignSend && (
        <div className="flex flex-wrap gap-1">
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            配信停止
          </span>
        </div>
      )}
    </div>
  );
};
