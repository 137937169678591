import React from 'react';
import styled from 'styled-components';
import {Route, Switch} from 'react-router';
import Projects from './projects';
import {useCurrentUserQuery, useCurrentClientQuery} from 'api';
import ProjectGroups from './projectGroups';
import Shares from './share';
import projectRoutePaths from 'helpers/projectRoutePaths';
import {RecoilRoot} from 'recoil';
import Preleads from './preleads';
import MailCampaignSends from './mailCampaignSends';

export default () => {
  const {
    data: {currentUser = {}},
  } = useCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });
  const {data: {currentClient: {currentPreleadContract} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  if (currentUser?.role !== 'admin' && !currentPreleadContract) {
    return (
      <P>
        お客様のアカウントは現在、LEADPADのプランに契約しておりません。
        <br />
        お手数ですが、担当者までお問い合わせください。
      </P>
    );
  }

  return (
    <Switch>
      <Route
        path={projectRoutePaths('projects')}
        component={Projects}
        key={'projects'}
      />

      <Route path={['/list/share']} component={Shares} key={'share'} />

      <Route
        path={['/list/groups/:projectGroupId', '/list/groups']}
        component={ProjectGroups}
        key={'projectGroups'}
      />

      <Route
        path={['/list/preleads/:preleadId', '/list/preleads']}
        key={'prelead'}>
        <RecoilRoot>
          <Preleads />
        </RecoilRoot>
      </Route>

      <Route
        path={'/list/mail_campaign_sends'}
        key={'mail_campaign_sends'}
        component={MailCampaignSends}
      />
    </Switch>
  );
};

const P = styled.p`
  display: grid;
  place-items: center;
  height: 100vh;
  font-size: 20px;
  text-align: center;
`;
