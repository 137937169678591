import React from 'react';

const Icon = () => (
  <svg
    width="46"
    height="38"
    viewBox="0 0 46 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 30.1667L18.5 8L28 30.1667M12.3928 22.2502H24.6072"
      stroke="#899098"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.4996 30.4163L34.9996 17.583L31.4638 25.8331H38.5354"
      stroke="#899098"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
