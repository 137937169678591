import React from 'react';
import useSearchParams from '../../../useSearchParams';
import {DatePicker} from 'components/antd';
import moment from 'moment';

interface Props {
  filter: string;
  value: {start: string; end: string};
}

const CustomizeItemDateFilter = ({filter, value}: Props) => {
  const {setQuery} = useSearchParams();
  const {RangePicker} = DatePicker;
  const format = 'YYYY-MM-DD';

  return (
    <RangePicker
      format={format}
      placeholder={['開始日', '終了日']}
      value={
        value?.start && value?.end
          ? [moment(value.start, format), moment(value.end, format)]
          : null
      }
      onChange={(value) => {
        if (value) {
          setQuery({
            [filter]: {
              start: value[0].format(format),
              end: value[1].format(format),
            },
          });
        } else {
          setQuery({
            [filter]: null,
          });
        }
      }}
    />
  );
};

export default CustomizeItemDateFilter;
