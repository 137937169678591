import React from 'react';
import {Approach} from 'api';
import {
  PhoneIcon,
  EnvelopeIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';

interface Props {
  approach: Approach;
}

const ApproachType = ({approach}: Props) => {
  const className =
    'flex items-center justify-center gap-1 rounded-sm h-5 text-xs px-2';

  switch (approach.approachType) {
    case 'call':
      return (
        <div className={`${className} text-[#8DD888] bg-[#E2F5E1]`}>
          <PhoneIcon className="w-3" color="#8DD888;" />
          {approach.approachTypeText}
        </div>
      );
    case 'manual_mail':
      return (
        <div className={`${className} text-[#F99985] bg-[#FFEEEB]`}>
          <EnvelopeIcon className="w-3" />
          {approach.approachTypeText}
        </div>
      );
    case 'task':
      return (
        <div className={`${className} text-[#FFC600] bg-[#FEF5D6]`}>
          <DocumentCheckIcon className="w-3" />
          {approach.approachTypeText}
        </div>
      );
  }

  return null;
};

export default ApproachType;
