import React from 'react';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';
import {useProspectPoolsQuery} from 'api';
import useProspectContext from '../../../useProspectContext';
import Pagination from './Pagination';
import Prospect from './Prospect';
import useMergeModalContext from '../useMergeModalContext';
import Header from '../Header';

const PER_PAGE = 10;

interface Props {
  onChange: (uuid: string) => void;
}

const Finder = ({onChange}: Props) => {
  const [page, setPage] = React.useState<number>(1);
  const [keyword, setKeyword] = React.useState<string>('');
  const [prospectPoolUuid, setProspectPoolUuid] = React.useState<string>('');
  const {poolId} = useProspectContext();
  const {setPageType} = useMergeModalContext();

  const {
    data: {prospectPools: {pagination = {}, prospectPools = []} = {}} = {},
  } = useProspectPoolsQuery({
    variables: {
      uuid: poolId,
      page: page,
      perPage: PER_PAGE,
      search: {searchWord: keyword, statuses: ['active']},
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <Header title="リードを検索" count={pagination.totalCount || 0} />
      <div className="flex-1 px-6 py-4 flex flex-col gap-4 h-[calc(100%-80px)]">
        <div
          className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2 bg-white`}>
          <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
          <input
            className="h-8 text-sm border-c-border flex-1 outline-none"
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <div className="flex flex-col flex-1 overflow-scroll">
          {prospectPools.map((prospectPool) => (
            <Prospect
              prospectPool={prospectPool}
              key={prospectPool.id}
              onClick={() => {
                setProspectPoolUuid(prospectPool.uuid);
              }}
              prospectPoolUuid={prospectPoolUuid}
            />
          ))}
        </div>
        <div className="flex items-center justify-between bg-c-bg rounded-b">
          <button
            className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold"
            onClick={() => setPageType(null)}>
            戻る
          </button>
          <Pagination
            pageSize={PER_PAGE}
            current={pagination.currentPage || 1}
            total={pagination.totalCount || 0}
            onChange={(page) => setPage(page)}
          />
          <button
            className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-c-primary border border-c-primary text-white font-bold disabled:opacity-50"
            disabled={!prospectPoolUuid}
            onClick={() => onChange(prospectPoolUuid)}>
            リードを選択
          </button>
        </div>
      </div>
    </>
  );
};

export default Finder;
