import React from 'react';
import styled from 'styled-components';
import {useHistory, useParams, useRouteMatch} from 'react-router';
import {
  MailCampaignBlock,
  useCopyMailCampaignBlockMutation,
  useDestroyMailCampaignBlockMutation,
  useMailCampaignQuery,
} from 'api';
import {Button} from 'components/antd';
import {Copy, Trash} from 'components/Ui/Icon';
import BlockHeader from './Header';
import BlockTitle from './Title';
import BlockText from './Text';
import BlockImage from './Image';
import BlockButton from './Button';
import BlockDivier from './Divider';
import BlockFooter from './Footer';
import blockUnsubscribe from './Unsubscribe';

const blockComponents: {[key: string]: any} = {
  MailCampaignBlockHeader: BlockHeader,
  MailCampaignBlockTitle: BlockTitle,
  MailCampaignBlockText: BlockText,
  MailCampaignBlockImage: BlockImage,
  MailCampaignBlockButton: BlockButton,
  MailCampaignBlockDivider: BlockDivier,
  MailCampaignBlockFooter: BlockFooter,
  MailCampaignBlockUnsubscribe: blockUnsubscribe,
};

const Handle = () => (
  <svg
    width="6"
    height="18"
    viewBox="0 0 6 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2ZM1 6C1.55228 6 2 5.55228 2 5C2 4.44772 1.55228 4 1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6ZM2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9ZM1 14C1.55228 14 2 13.5523 2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14ZM2 17C2 17.5523 1.55228 18 1 18C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16C1.55228 16 2 16.4477 2 17ZM5 2C5.55228 2 6 1.55228 6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2ZM6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5ZM5 10C5.55228 10 6 9.55229 6 9C6 8.44771 5.55228 8 5 8C4.44772 8 4 8.44771 4 9C4 9.55229 4.44772 10 5 10ZM6 13C6 13.5523 5.55228 14 5 14C4.44772 14 4 13.5523 4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13ZM5 18C5.55228 18 6 17.5523 6 17C6 16.4477 5.55228 16 5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18Z"
      fill="white"
    />
  </svg>
);

interface Props {
  block: MailCampaignBlock;
}

export default ({block}: Props) => {
  const {mailCampaignId} = useParams<{
    mailCampaignId: string;
  }>();
  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const match = useRouteMatch<{
    poolId: string;
    mailCampaignId: string;
    blockId: string;
  }>({
    path: `/mail_campaigns/:mailCampaignId/mail/blocks/:blockId`,
  });

  const [destroyBlock] = useDestroyMailCampaignBlockMutation({
    variables: {uuid: block.uuid},
    onCompleted: () => history.push(`/mail_campaigns/${mailCampaignId}/mail`),
  });

  const onClickDelete = React.useCallback(
    (e: any) => {
      e.stopPropagation();
      destroyBlock();
    },
    [destroyBlock],
  );

  const [copyBlock] = useCopyMailCampaignBlockMutation({
    variables: {uuid: block.uuid},
  });

  const onClickCopy = React.useCallback(
    (e: any) => {
      e.stopPropagation();
      copyBlock();
    },
    [copyBlock],
  );

  const history = useHistory();

  const BlockComponent = blockComponents[block.blockable.__typename];

  const onClick = (e: any) => {
    if (mailCampaign.status === 'draft') {
      e.stopPropagation();
      history.push(
        `/mail_campaigns/${mailCampaignId}/mail/blocks/${block.uuid}`,
      );
    }
  };

  const isActive =
    match?.params.blockId === block.uuid &&
    block.blockable.__typename != 'MailCampaignBlockUnsubscribe';

  return (
    <Block
      onClick={onClick}
      className={isActive ? 'active' : ''}
      draft={mailCampaign.status === 'draft'}>
      <div className="dragHandle">
        <Handle />
      </div>
      <BlockContent>
        <BlockComponent block={block} />
      </BlockContent>
      {isActive && (
        <>
          <RemoveButton
            icon={<Trash color="#fff" />}
            size="small"
            onClick={onClickDelete}
          />
          <div className="w-6 h-6 bg-[#495058] hover:bg-[#000] absolute rounded-sm top-[-12px] right-11 cursor-pointer">
            <button className="bg-transparent" onClick={onClickCopy}>
              <Copy
                className="relative w-[0.8rem] top-[3px]"
                color="#FFFFFF"
                width="16"
                height="16"
              />
            </button>
          </div>
        </>
      )}
    </Block>
  );
};

const Block = styled.div<{draft?: boolean}>`
  display: flex;
  width: 100%;
  background-color: transparent;
  cursor: ${({draft}) => (draft ? 'pointer' : 'not-allowed')};
  border-spacing: 0;
  position: relative;

  .dragHandle {
    width: 15px;
    background-color: #68b5fb;
    position: relative;
    left: -15px;
    outline: 2px solid #68b5fb;
    boxshadow: 0px 0px 4px 1px #68b5fb;
    visibility: hidden;
    cursor: move;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.active {
    outline: 2px solid #68b5fb;
    boxshadow: 0px 0px 4px 1px #68b5fb;
    z-index: 1;

    .dragHandle {
      visibility: visible;
    }
  }

  &:hover:not(.active) {
    outline: 1px solid #68b5fb;
    boxshadow: 0px 0px 4px 1px #68b5fb;
    z-index: 1;
  }
`;

const BlockContent = styled.div`
  flex: 1;
  margin-left: -15px !important;
`;

const RemoveButton = styled(Button)`
  background-color: #495058;
  border: none;
  position: absolute;
  top: -12px;
  right: 1rem;
  border-radius: 4px;

  svg {
    width: 0.8rem;
    position: relative;
    top: 1px;
  }

  &:hover {
    background-color: #000;
  }
`;
