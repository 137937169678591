import {useCurrentUserQuery, useProjectGroupQuery} from 'api';
import React from 'react';

const useProjectGroup = (uuid: string) => {
  const {
    data: {currentUser = {}},
  } = useCurrentUserQuery();

  const {data: {projectGroup: {users = []} = {}, projectGroup = {}} = {}} = useProjectGroupQuery({
    variables: {uuid: uuid},
    skip: !uuid,
  });

  const isCurrentUserAssingedToProjectGroup = React.useMemo(
    () => users.some((user) => user.id === currentUser.id),
    [currentUser, users],
  );

  const isProjectGroupAccessible = React.useMemo(
    () => isCurrentUserAssingedToProjectGroup || !projectGroup.isPrivate,
    [isCurrentUserAssingedToProjectGroup, projectGroup],
  );

  return {
    isProjectGroupAccessible,
  };
};

export default useProjectGroup;
