import React from 'react';
import {Task} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;

  switch (task.status) {
    case 'not_started':
      return (
        <div className="flex">
          <div className="flex items-center rounded-sm text-[#0DC8F1] bg-[#DCF9FF] h-5 text-xs px-2">
            {task.statusText}
          </div>
        </div>
      );
    case 'in_progress':
      return (
        <div className="flex">
          <div className="flex items-center rounded-sm text-[#2693FF] bg-[#D7EBFF] h-5 text-xs px-2">
            {task.statusText}
          </div>
        </div>
      );
    case 'completed':
      return (
        <div className="flex">
          <div className="flex items-center rounded-sm text-[#4FCE82] bg-[#DDF4E6] h-5 text-xs px-2">
            {task.statusText}
          </div>
        </div>
      );
    case 'expired':
      return (
        <div className="flex">
          <div className="flex items-center rounded-sm text-[#899098] bg-[#EAEEF2] h-5 text-xs px-2">
            {task.statusText}
          </div>
        </div>
      );
    case 'icebox':
      return (
        <div className="flex">
          <div className="flex items-center rounded-sm text-[#899098] bg-[#EAEEF2] h-5 text-xs px-2">
            {task.statusText}
          </div>
        </div>
      );
  }

  return null;
};

export default Cell;
