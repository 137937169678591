import React from 'react';
import {Form, Select} from 'components/antd';
import {
  useSalesforceContactRecordTypesQuery,
  useSalesforceContactFieldInputtablesQuery,
} from 'api';
import {Field} from './Field';
import useProspectContext from '../../../useProspectContext'

interface Props {
  formik: any;
  addition: {[key: string]: any};
  setAddition: Function;
}

export const Contact: React.FC<Props> = ({formik, addition, setAddition}) => {
  const {poolId} = useProspectContext();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery({
      variables: {poolUuid: poolId},
      skip: !poolId,
    });

  const selectedRecordType = salesforceContactRecordTypes.find(
    (recordType) =>
      recordType.id ===
      formik.values.salesforceContact.salesforceContactRecordTypeId,
  );

  const {data: {salesforceContactFieldInputtables = []} = {}} =
    useSalesforceContactFieldInputtablesQuery({
      variables: {recordTypeId: selectedRecordType?.id},
      skip: !selectedRecordType,
    });

  return (
    <>
      {salesforceContactRecordTypes.length > 0 && (
        <Form.Item label="レコードタイプ">
          <Select
            value={selectedRecordType?.id || null}
            onChange={(value: string) => {
              formik.setFieldValue(
                'salesforceContact.salesforceContactRecordTypeId',
                value,
              );
              setAddition({});
            }}>
            {salesforceContactRecordTypes.map((recordType) => (
              <Select.Option key={recordType.id} value={recordType.id}>
                {recordType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <>
        {salesforceContactFieldInputtables.map((field, i: number) => (
          <Field
            key={i}
            addition={addition}
            setAddition={setAddition}
            field={field}
          />
        ))}
      </>
    </>
  );
};
