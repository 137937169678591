import React from 'react';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {useSalesforceUsersQuery} from 'api';
import useSearchParams from '../../../../useSearchParams';
import {useParams} from 'react-router-dom';

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

const SalesforceDealUsersSelect = () => {
  const {query, setQuery} = useSearchParams();
  const [keyword, setKeyword] = React.useState('');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {poolId} = useParams<{poolId: string}>();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId},
  });

  const filteredOptions =
    salesforceUsers?.filter((user) =>
      kanaToHira(`${user.lastName} ${user.firstName}`).includes(
        kanaToHira(keyword),
      ),
    ) || [];

  const selectOptions = () => {
    if (keyword != selectedUserFullName()) {
      return filteredOptions;
    } else {
      return salesforceUsers;
    }
  };

  const selectedUser = () => {
    return salesforceUsers.find(
      (user) => user.id === query.salesforceDealUserId,
    );
  };

  const selectedUserFullName = () => {
    return [selectedUser()?.lastName, selectedUser()?.firstName]
      .filter((v) => !!v)
      .join(' ');
  };

  return (
    <Combobox
      value={selectedUser}
      onChange={(user) => {
        setKeyword('');
        setQuery({...query, salesforceDealUserId: user?.id});
      }}>
      <div
        className="relative flex-1"
        onClick={() => inputRef.current?.focus()}>
        <div className="relative w-full min-h-[2.5rem] cursor-default rounded bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap">
          <Combobox.Button className="hidden" ref={buttonRef} />
          <Combobox.Input
            className="flex-1 outline-none border-transparent focus:border-transparent focus:ring-0"
            onChange={(e) => setKeyword(e.target.value)}
            onFocus={() => buttonRef.current?.click()}
            value={keyword}
            ref={inputRef}
            displayValue={() => selectedUser() && selectedUserFullName()}
          />
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </div>
        {selectOptions().length > 0 && (
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Combobox.Options className="absolute mt-1 max-h-60 w-full z-10 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <Combobox.Option value={null}></Combobox.Option>
              {selectOptions().map((option) => (
                <Combobox.Option
                  key={option.id}
                  className={({active}) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? 'bg-c-bg' : 'white'
                    }`
                  }
                  value={option}>
                  <div className="flex items-center gap-2">
                    <span className="h-3 w-3 rounded-sm bg" />
                    <span className="truncate flex-1">
                      {option?.lastName} {option?.firstName}
                    </span>
                  </div>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        )}
      </div>
    </Combobox>
  );
};

export default SalesforceDealUsersSelect;
