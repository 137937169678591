import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {useFormik, FormikErrors} from 'formik';
// @ts-ignore
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import {Button, Upload, Modal, Select, Tooltip} from 'components/antd';
import {UploadOutlined} from '@ant-design/icons';
import {Project, useCurrentClientQuery, useImportCsvMutation} from 'api';
import useContract from 'hooks/useContract';
import useProjectPath from 'hooks/useProjectPath';

interface Props {
  project: Project;
  visible: boolean;
  onClose: () => void;
}

const upload_size_limit = 10000000; // 10MB

const UploadContactsModalComponent = ({project, visible, onClose}: Props) => {
  const history = useHistory();
  const {createPath} = useProjectPath();
  const [csv, setCsv] = React.useState(null);

  const validate = (values: any) => {
    let errors: FormikErrors<{fields: string}> = {};

    let haveNoLastName = false;
    if (
      !['company_name', 'contact_last_name'].every((field) =>
        values.fields?.includes(field),
      )
    ) {
      haveNoLastName = true;
    }

    let haveNoFullName = false;
    if (
      !['company_name', 'contact_full_name'].every((field) =>
        values.fields?.includes(field),
      )
    ) {
      haveNoFullName = true;
    }

    if (haveNoLastName && haveNoFullName) {
      errors.fields = 'Required';
    }

    return errors;
  };

  const handleClose = () => {
    setCsv(null);
    onClose();
  };

  const [importCsv, {loading}] = useImportCsvMutation({
    onCompleted: () => {
      history.push(
        createPath(`projects/${project.uuid}/csv_summary?status=open`),
      );
      handleClose();
    },
    refetchQueries: ['project'],
  });

  const {data: {currentClient: {customizeItems = []} = {}} = {}} =
    useCurrentClientQuery({});

  const options = useMemo(() => {
    const options = [
      {value: '', text: ''},
      {value: 'corp_number', text: '企業：法人番号'},
      {value: 'company_name', text: '企業：会社名'},
      {value: 'web_site', text: '企業：URL'},
      {value: 'address', text: '企業：住所'},
      {value: 'telephone_number', text: '企業：電話番号'},
      {value: 'contact_full_name', text: '連絡先：姓 + 名'},
      {value: 'contact_last_name', text: '連絡先：姓'},
      {value: 'contact_first_name', text: '連絡先：名'},
      {value: 'contact_email', text: '連絡先：メールアドレス'},
      {value: 'contact_telephone_number', text: '連絡先：電話番号'},
      {value: 'contact_section', text: '連絡先：部署名'},
      {value: 'contact_section_position', text: '連絡先：役職'},
    ];

    customizeItems.forEach((customizeItem) =>
      options.push({
        value: customizeItem.columnName,
        text: customizeItem.name,
      }),
    );

    return options;
  }, [customizeItems]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      importType: 'form',
    },
    validate,
    onSubmit: (values: any) => {
      importCsv({
        variables: {
          file: values.file,
          attributes: {
            type: 'Import::ColdLead',
            rowCount: csv.length,
            fields: values.fields,
            projectId: project.uuid,
          },
        },
      });
    },
  });

  const {isProjectAddable} = useContract();

  return (
    <UploadModal
      destroyOnClose
      title={<Title>連絡先のアップロード</Title>}
      maskClosable={false}
      visible={visible}
      width={640}
      centered
      bodyStyle={{height: '70vh'}}
      footer={[
        <CancelButton size="large" key="cancel" onClick={handleClose}>
          キャンセル
        </CancelButton>,
        <OkButton
          size="large"
          key="submit"
          type="primary"
          disabled={!formik.isValid || !csv}
          onClick={() => formik.handleSubmit(formik.values)}
          loading={loading}>
          登録
        </OkButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <UploadDescription>
          <div>
            アップロードしたCSVの列の内容を、営業リストの項目に合わせて保存しましょう。
          </div>
          <div>1ファイル10MBまでのアップロードが可能です。</div>
        </UploadDescription>

        {isProjectAddable || project.status === 'open' ? (
          <Upload
            name={'file'}
            accept=".csv"
            showUploadList={false}
            customRequest={() => false}
            onChange={(info) => {
              const file = info.file.originFileObj;
              if (file.size > upload_size_limit) {
                alert(
                  'アップロードしようとしているファイルの容量が10MBを超えています。\n1ファイル10MBまでのアップロードが可能です。',
                );
                return null;
              }
              formik.setFieldValue('file', file);
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const codes = new Uint8Array(e.target.result);
                const encoding = Encoding.detect(codes);
                let unicodeString;
                if (encoding === 'UNICODE') {
                  unicodeString = new TextDecoder().decode(codes);
                } else {
                  unicodeString = Encoding.convert(codes, {
                    to: 'unicode',
                    from: encoding,
                    type: 'string',
                  });
                }
                Papa.parse(unicodeString, {
                  dynamicTyping: true,
                  skipEmptyLines: true,
                  complete: (result: any) => {
                    setCsv(result.data);
                    const size = result.data[0].length;
                    formik.setFieldValue('fields', new Array(size));
                  },
                });
              };
              reader.readAsArrayBuffer(file);
            }}>
            <UploadButton size="large">
              <UploadOutlined />
              Click to Upload
            </UploadButton>
          </Upload>
        ) : (
          <Tooltip
            title="営業リスト数が上限に達しました。"
            color={`#EB5757`}
            placement="top">
            <UploadButton size="large" disabled>
              <UploadOutlined />
              Click to Upload
            </UploadButton>
          </Tooltip>
        )}

        {csv && (
          <ListWrapper>
            <ListRow>
              <div className="column">CSVの項目</div>
              <div>システム項目</div>
            </ListRow>
            {csv[0].map((header: string, index: number) => (
              <ListRow key={header}>
                <div className="column">{header}</div>
                <div className="value">
                  <Select
                    style={{width: '250px', height: '30px'}}
                    onChange={(value) => {
                      let fields = formik.values.fields;
                      fields[index] = value;
                      formik.setFieldValue('fields', fields);
                    }}>
                    {options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </ListRow>
            ))}
          </ListWrapper>
        )}
      </ModalContent>
    </UploadModal>
  );
};

const UploadModal = styled(Modal)`
  &&& {
    .ant-modal-body {
      overflow: scroll;
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    font-size: 14px;
  }

  .ant-upload {
    margin-bottom: 2rem;
  }
`;

const UploadDescription = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #495058;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 24px 24px 0 !important;
`;

const ListWrapper = styled.div`
  border: solid 1px #e2e6ea;
  border-radius: 8px;
  padding: 1rem 1.5rem;
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid #e2e6ea;

  > div {
    &:first-child {
      width: 250px;
    }
    &:last-child {
      flex: 1;
    }
  }

  &:first-child {
    font-weight: bold;
    > div {
      text-align: center;
    }
  }

  &:last-child {
    border: none;
  }
`;

const UploadButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const OkButton = styled(Button)`
  &&& {
    border: none;
    border-radius: 4px;
  }
`;

export default UploadContactsModalComponent;
