import React from 'react';
import useChartData from './useChartData';
import {Chart} from '../../BarChart';
import DashboardItemContainer from '../../../Container';
import {DashboardItemDealChartOptions} from '../../../types';

export default (options: DashboardItemDealChartOptions) => {
  const chart = useChartData(options);

  return (
    <DashboardItemContainer
      title="商談作成"
      subTitle="月別"
      tags={['リードソース', '過去6ヶ月']}>
      <Chart chart={chart} legend={false} className="h-full w-full" />
    </DashboardItemContainer>
  );
};
