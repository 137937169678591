import React from 'react';
import styled from 'styled-components';
import {Input, Form} from 'components/antd';
import {FormikProps} from 'formik';
import {ContentWebsiteAttributes} from 'api';

interface Props {
  formik: FormikProps<ContentWebsiteAttributes>;
}

export default ({formik}: Props) => (
  <Container>
    <Form labelCol={{span: 4}} wrapperCol={{span: 20}} layout="horizontal">
      <Form.Item
        label="URL"
        validateStatus={formik.errors.url && formik.touched.url ? 'error' : ''}
        help={
          formik.errors.url && formik.touched.url ? formik.errors.url : null
        }>
        <Input
          type="text"
          name="url"
          value={formik.values.url}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
    </Form>
  </Container>
);

const Container = styled.div`
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  label {
    font-size: 13px;
    color: #899098;
    font-weight: bold;
  }
`;
