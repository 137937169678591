import React from 'react';
import {usePoolQuery, useUpdateSalesforceTaskTypeMutation} from 'api';
import {Listbox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';

type TaskType = {
  name: string;
  value?: string;
};

export default () => {
  const {data: {pool: {salesforceTaskSubjects = []} = {}} = {}} =
    usePoolQuery();
  const [update] = useUpdateSalesforceTaskTypeMutation({});

  const taskTypes: TaskType[] = [
    {name: '電話', value: 'tel'},
    {name: 'メール', value: 'mail'},
    {name: 'その他', value: 'other'},
  ];

  const selectedItem = (value: string) => {
    return taskTypes.find((taskType) => taskType.value === value);
  };

  return (
    <div>
      <div className="flex border-b border-b-[#F3F5F7]">
        <div className="text-[#899098] w-96 px-6 text-sm pb-2">Salesforce</div>
        <div className="text-[#899098] w-96 px-6 text-sm pb-2">LEADPAD</div>
      </div>
      {salesforceTaskSubjects.map((subject, index) => {
        return (
          <div className="flex border-b border-b-[#F3F5F7] py-4" key={index}>
            <div className="text-[#222426] w-96 px-6 text-base">
              {subject.label}
            </div>
            <div className="text-[#222426] w-96 px-6 text-base">
              <Listbox
                value={subject.taskType}
                onChange={(value: any) => {
                  update({
                    variables: {
                      id: subject.id,
                      taskType: value,
                    },
                  });
                }}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm min-h-[37.59px]">
                    <span className="block truncate">
                      {selectedItem(subject.taskType)?.name || ''}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20 ">
                      {taskTypes.map((taskType, taskTypeIdx) => (
                        <Listbox.Option
                          key={taskTypeIdx}
                          className={({active}) =>
                            `min-h-[37.59px]  relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-gray-100' : 'bg-white'
                            }`
                          }
                          value={taskType.value}>
                          {({selected}) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}>
                                {taskType.name}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        );
      })}
    </div>
  );
};
