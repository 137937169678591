import gql from 'graphql-tag';

export default gql`
  fragment extMailFormSendActivityFragment on ExtMailFormSendActivity {
    body
    contentMailFormVersion {
      id
      uuid
      version
      mailForm {
        content {
          uuid
          title
        }
      }
    }
  }
`;
