import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowSalesforceLeadSelectSearchConditionMutation,
  useWorkflowQuery,
  SalesforceLeadFieldSelectOption,
  useSalesforceLeadFieldQuery,
} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';
import {SelectSearchCondition} from './Types';

const LeadSelectOption: React.FC<{condition: SelectSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {
    data: {
      salesforceLeadField: {salesforceLeadFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceLeadFieldQuery({
    variables: {leadFieldId: condition.lead_field_id},
    skip: !condition.lead_field_id,
  });

  const [updateWorkflowSalesforceLeadSelectSearchCondition] =
    useUpdateWorkflowSalesforceLeadSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const leadFieldSelectOptions = () => {
    return salesforceLeadFieldSelectOptions.map(
      (item: SalesforceLeadFieldSelectOption) => {
        return {
          value: item.id,
          label: item.label,
        };
      },
    );
  };

  return (
    <Select
      options={leadFieldSelectOptions() || []}
      disabled={!isConditionUpdatable || !condition.lead_field_id || !isMember}
      style={{width: 200}}
      value={condition.field_select_option_id ?? ''}
      onChange={(value: string) => {
        updateWorkflowSalesforceLeadSelectSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              leadFieldId: condition.lead_field_id,
              fieldType: condition.field_type,
              fieldSelectOptionId: value,
            },
          },
        });
      }}
    />
  );
};

export default LeadSelectOption;
