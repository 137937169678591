import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18.3333 5H8.33333C6.49238 5 5 6.49238 5 8.33333V31.6667C5 33.5077 6.49238 35 8.33333 35H31.6667C33.5077 35 35 33.5077 35 31.6667V21.6667"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8335 19.1667L29.1668 5.83339C30.5475 4.45267 32.7862 4.45267 34.1668 5.83339C35.5475 7.21411 35.5475 9.45267 34.1668 10.8334L20.8335 24.1667L13.3335 26.6667L15.8335 19.1667Z"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
