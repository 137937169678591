import React from 'react';
import {ProspectPool} from 'api';
import styled from 'styled-components';
import {getTagFontColor} from 'helpers/tagColors';

interface Props {
  prospectPool: ProspectPool;
}

export const ProspectPoolTagComponent = ({prospectPool}: Props) => {
  const prospectTags = prospectPool?.prospectTags || [];

  return (
    <Tags>
      {prospectTags.map((tag) => (
        <Tag color={tag.color} key={tag.id}>
          <span>{tag.name}</span>
        </Tag>
      ))}
    </Tags>
  );
};

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.25rem;
`;

const Tag = styled.span<{color?: string}>`
  margin-right: 6px;
  margin-bottom: 5px;
  display: flex;
  padding: 3px 7px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;
  span {
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: ${({color}) => getTagFontColor(color)};
  }
`;
