import React from 'react';
import {useParams, useHistory} from 'react-router';
import {useFormik, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {Form, Input, Button, Alert} from 'components/antd';
import styled from 'styled-components';
import {useUserByTokenQuery, useSignupMutation} from 'api';
import {Redirect} from 'react-router-dom';

interface FormValues {
  lastName: string;
  firstName: string;
  userName: string;
  password: string;
  passwordConfirmation: string;
}

const validateSchema = Yup.object().shape({
  lastName: Yup.string().required('必須項目です'),
  firstName: Yup.string().required('必須項目です'),
  userName: Yup.string().required('必須項目です'),
  password: Yup.string()
    .required('必須項目です')
    .min(6, '6文字以上で入力してください'),
  passwordConfirmation: Yup.string().required('必須項目です'),
});

export default () => {
  const {token} = useParams<{token: string}>();
  const history = useHistory();
  const {data: {userByToken = {}} = {}} = useUserByTokenQuery({
    variables: {token: token},
  });
  const [signup, {loading, data}] = useSignupMutation({
    onCompleted: () => history.push(`/registration/complete`),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validateSchema,
    initialValues: {
      lastName: '',
      firstName: '',
      userName: '',
      password: '',
      passwordConfirmation: '',
    },
    validate: (values: FormValues) => {
      let errors: FormikErrors<{passwordConfirmation: string}> = {};

      if (values.password !== values.passwordConfirmation) {
        errors.passwordConfirmation = 'パスワードが一致していません';
      }

      return errors;
    },
    onSubmit: (values: FormValues) => {
      signup({
        variables: {
          token: token,
          user: {
            lastName: values.lastName,
            firstName: values.firstName,
            userName: values.userName,
          },
          password: {
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
          },
        },
      });
    },
  });

  if (!userByToken) return <Redirect to={'/'} />;

  return (
    <Form onFinish={formik.handleSubmit}>
      <Container>
        <Content>
          <Header>LEADPAD</Header>
          {data && data.signup && data.signup.error && (
            <Alert
              message="Error"
              description={data.signup.error}
              type="error"
              showIcon
            />
          )}

          <Form.Item label="email">
            <div>{userByToken.email}</div>
          </Form.Item>

          <Form.Item
            label="姓"
            required
            validateStatus={
              formik.errors.lastName && formik.touched.lastName ? 'error' : ''
            }
            help={
              formik.errors.lastName && formik.touched.lastName
                ? formik.errors.lastName
                : ''
            }>
            <Input
              placeholder="姓"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <Form.Item
            label="名"
            required
            validateStatus={
              formik.errors.firstName && formik.touched.firstName ? 'error' : ''
            }
            help={
              formik.errors.firstName && formik.touched.firstName
                ? formik.errors.firstName
                : ''
            }>
            <Input
              placeholder="名"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <Form.Item
            label="ユーザー表示名"
            required
            validateStatus={
              formik.errors.userName && formik.touched.userName ? 'error' : ''
            }
            help={
              formik.errors.userName && formik.touched.userName
                ? formik.errors.userName
                : ''
            }>
            <Input
              placeholder="ユーザー表示名"
              name="userName"
              value={formik.values.userName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <Form.Item
            label="パスワード"
            required
            validateStatus={
              formik.errors.password && formik.touched.password ? 'error' : ''
            }
            help={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : ''
            }>
            <Input
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <Form.Item
            label="パスワード(確認)"
            required
            validateStatus={
              formik.errors.passwordConfirmation &&
              formik.touched.passwordConfirmation
                ? 'error'
                : ''
            }
            help={
              formik.errors.passwordConfirmation &&
              formik.touched.passwordConfirmation
                ? formik.errors.passwordConfirmation
                : ''
            }>
            <Input
              name="passwordConfirmation"
              type="password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <div>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={!formik.isValid || loading}>
              登録
            </Button>
          </div>
        </Content>
      </Container>
    </Form>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  &&& {
    width: 500px;
    margin-top: -100px;
    padding: 40px;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 40px;
`;
