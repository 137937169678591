import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useCondition from '../../../useCondition';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {useAllCollectedFormUrlsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  const {data: {allCollectedFormUrls: {collectedFormUrls = []} = {}} = {}} =
    useAllCollectedFormUrlsQuery({
      fetchPolicy: 'cache-and-network',
    });

  if (!condition?.collectedFormUrls || !condition.collectedFormUrls.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">タグ連携フォーム</h4>
        <button
          onClick={() => setFilterType('collectedFormUrl')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setCondition({collectedFormUrls: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {collectedFormUrls
          .filter((collectedFormUrl) =>
            condition.collectedFormUrls.includes(collectedFormUrl.id),
          )
          .map((collectedFormUrl) => (
            <span
              key={collectedFormUrl.id}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {collectedFormUrl.title}
            </span>
          ))}
      </div>
    </div>
  );
};
