import React from 'react';

interface Props {
  name: string;
  from: React.ReactNode | string | null;
  to: React.ReactNode | string | null;
}

const selectedStyle = 'bg-[#F4FEF8]';
const unselectedStyle = 'bg-[#FCF1F1]';
const blankStyle = 'bg-transparent';

const TableRow = ({name, from, to}: Props) => {
  const selected = to ? 'to' : from ? 'from' : null;

  const fromStyle = React.useMemo(() => {
    if (selected === 'from') {
      return selectedStyle;
    }
    return from ? unselectedStyle : blankStyle;
  }, [from, to, selected]);

  const toStyle = React.useMemo(() => {
    if (selected === 'to') {
      return selectedStyle;
    }
    return to ? unselectedStyle : blankStyle;
  }, [from, to, selected]);

  const result = selected === 'from' ? from : to;

  return (
    <tr className="group">
      <td className="border border-c-border px-4 py-2 font-normal break-words w-1/4 whitespace-pre-wrap">
        {name}
      </td>
      <td
        className={`border border-c-border px-4 py-2 font-normal break-words w-1/4 whitespace-pre-wrap ${fromStyle}`}>
        {from}
      </td>
      <td
        className={`border border-c-border border-r-[#0085FF] px-4 py-2 font-normal break-words w-1/4 whitespace-pre-wrap ${toStyle}`}>
        {to}
      </td>
      <td className="border border-c-border border-r-[#0085FF] group-last-of-type:border-b-[#0085FF] px-4 py-2 font-normal break-words w-1/4">
        {result}
      </td>
    </tr>
  );
};

export default TableRow;
