import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  ManualMailClickProspectActivity,
  useDestroyManualMailClickProspectActivityMutation,
} from 'api';
import {ManualMail} from 'components/Ui/Icon';
import {DotMenuSide, Trash} from 'components/Ui/Icon';
import {Menu, Dropdown} from 'components/antd';

interface ManualMailClickActivity extends ProspectActivity {
  resource: ManualMailClickProspectActivity;
}

interface Props {
  prospectActivity: ManualMailClickActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      manualMail: {action},
      url,
    },
  } = prospectActivity;

  const [destroy] = useDestroyManualMailClickProspectActivityMutation({
    refetchQueries: ['prospectPool'],
  });

  return (
    <>
      <ManualMail width={'32px'} height={'32px'} active={true} />
      <Content>
        <ContentHeader>
          <h3>{action?.step?.name}</h3>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
          <MenuContainer>
            <Dropdown
              overlay={
                <Menu>
                  <MenuItem
                    onClick={() =>
                      confirm('本当に削除しますか？') &&
                      destroy({
                        variables: {
                          uuid: prospectActivity.uuid,
                        },
                      })
                    }>
                    <Trash />
                    削除
                  </MenuItem>
                </Menu>
              }
              trigger={['click']}>
              <span>
                <DotMenuSide />
              </span>
            </Dropdown>
          </MenuContainer>
        </ContentHeader>
        <Wrapper>
          <a href={url} target="_blank">
            {url}
          </a>
        </Wrapper>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: #68b5fb;
    font-weight: bold;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  color: #899098;
  width: 100％;

  > a {
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

const MenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
    svg {
      width: 15px;
      heighht: auto;
      margin-right: 0.5rem;
    }
    &:hover {
      color: #68b5fb;
      svg {
        path {
          stroke: #68b5fb;
        }
      }
    }
  }
`;
