import React from 'react';
import {Approach, Action} from 'api';
import {Row} from 'react-table';
import {LastProspectActivity} from 'components/Cell';

interface Props {
  row: Row<Approach>;
}

const Cell = ({row}: Props) => {
  const approach = row.original;
  const lastProspectActivity = approach.prospectPool?.lastProspectActivity;

  if (!lastProspectActivity) return null;

  return <LastProspectActivity lastProspectActivity={lastProspectActivity} />;
};

export default Cell;
