import React from 'react';
import {useParams} from 'react-router-dom';
import {useTable} from 'react-table';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';
import Cell from './Cell';
import {usePoolTasksQuery, Task} from 'api';
import useSearchParams from './useSearchParams';
import {ArrowUp} from 'components/Ui/Icon';

export default () => {
  const {poolId} = useParams<{poolId: string}>();
  const {page, perPage, setQuery, searchParams, query} = useSearchParams();
  const {data: {poolTasks: {tasks = [], pagination = {}} = {}} = {}} =
    usePoolTasksQuery({
      variables: {
        uuid: poolId,
        search: searchParams,
        page,
        perPage,
        orderOfDueDate: query.orderOfDueDate,
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns: any = React.useMemo(
    () => [
      {
        id: 'status',
        Header: '',
        Cell: Cell.Status,
        style: {
          width: '50px',
        },
      },
      {
        id: 'title',
        Header: 'タイトル',
        Cell: Cell.Title,
      },
      {
        id: 'prospect',
        Header: 'リード',
        Cell: Cell.Prospect,
      },
      {
        id: 'priority',
        Header: '優先度',
        Cell: Cell.Priority,
        style: {
          width: '64px',
        },
      },
      {
        id: 'due',
        Header: (
          <DueDateHeader>
            <div>日付</div>
            <ArrowIconWrapper
              onClick={() => setQuery({orderOfDueDate: 'desc'})}
              style={{transform: 'rotate(180deg)'}}
              isActive={query.orderOfDueDate === 'desc'}>
              <ArrowUp />
            </ArrowIconWrapper>
            <ArrowIconWrapper
              onClick={() => setQuery({orderOfDueDate: 'asc'})}
              isActive={query.orderOfDueDate === 'asc'}>
              <ArrowUp />
            </ArrowIconWrapper>
          </DueDateHeader>
        ),
        Cell: Cell.DueDate,
        style: {
          width: '130px',
        },
      },
      {
        id: 'description',
        Header: '説明',
        Cell: Cell.Description,
      },
      {
        id: 'asignee',
        Header: '担当者',
        Cell: Cell.Assignee,
      },
    ],
    [query.orderOfDueDate],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<Task>({columns, data: tasks || []});

  return (
    <Container>
      <ContentsTable {...getTableProps()} cellSpacing="0" cellPadding="0">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // className: column.className,
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </ContentsTable>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={perPage}
        onChange={(page) => setQuery({page})}
      />
    </Container>
  );
};

const DueDateHeader = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 7px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentsTable = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 0.5rem 1rem;
    margin: 0px;
  }

  thead {
    tr {
      th {
        padding: 8px 1rem;
        font-size: 10px;
        color: #899098;
        text-align: left;
        border-bottom: solid 1px #e1e6eb;

        &:first-child {
          padding: 3px 1rem 3px 0;
        }
      }
    }
  }

  tbody {
    font-size: 13px;

    tr {
      box-sizing: border-box;
      background: #fff;

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: solid 1px #e1e6eb;
      }
      td:first-child {
        padding: 0;
      }
    }
  }
`;

const ArrowIconWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  cursor: pointer;

  svg {
    path {
      stroke: ${({isActive}) => isActive && '#899098'};
    }
  }
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: #495058;
      background: #e2e6ea;
      border-radius: 100px;
    }
  }
`;
