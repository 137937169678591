import React from 'react';
import moment from 'moment';
import {ProspectActivity, HubspotContactCreateProspectActivity} from 'api';

interface HubspotContactCreateActivity extends ProspectActivity {
  resource: HubspotContactCreateProspectActivity;
}

interface Props {
  prospectActivity: HubspotContactCreateActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {hubspotUrl},
  } = prospectActivity;

  return (
    <>
      <span className="h-[10px] w-[10px] bg-[#c4c4c4] rounded-full ml-[10px] mr-[15px] z-10" />
      <div className="flex flex-1 w-[455px] mt-[-4px] ml-[3px]">
        <a
          className="flex-1 m-0 font-medium w-[375px] overflow-hidden overflow-ellipsis whitespace-nowrap"
          href={hubspotUrl}
          target="_blank">
          Hubspot
        </a>
        <time className="text-c-light">
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </div>
    </>
  );
};
