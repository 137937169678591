import React from 'react';
import styled from 'styled-components';
import {ProspectPool} from 'api';
import {StringParam, useQueryParam} from 'use-query-params';
import Lead from './Lead';
import Company from './Company';
import Job from './Job';
import PressRelease from './PressRelease';
import Fiscal from './Fiscal';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const [information] = useQueryParam('information', StringParam);
  if (!information) return null;

  return (
    <Container>
      <TabbedContent>
        {information === 'lead' && <Lead prospectPool={prospectPool} />}
        {information === 'company' && <Company prospectPool={prospectPool} />}
        {information === 'jobs' && <Job prospectPool={prospectPool} />}
        {information === 'pressrelease' && (
          <PressRelease prospectPool={prospectPool} />
        )}
        {information === 'fiscal' && <Fiscal prospectPool={prospectPool} />}
      </TabbedContent>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  height: 100vh;
  border-left: 1px solid #e2e6ea;
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.25);
`;

const TabbedContent = styled.div`
  height: calc(100vh - 63px);
  overflow-y: scroll;
  padding: 15px 8px 15px 23px;
`;
