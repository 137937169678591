import React from 'react';
import useSearchParams from '../useSearchParams';
import {
  useProjectTelStatusCallReportsQuery,
  useProjectPreleadTelStatusReportsQuery,
  useClientAllProjectsQuery,
} from 'api';
import {Popover} from 'components/antd';
import {PopoverContent, CheckBoxStyled, Box, FilterContent} from './style';

const ProjectFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {projectTelStatusCallReports = []} = {}} =
    useProjectTelStatusCallReportsQuery();

  const pooledProjectTelStatusCallReports = React.useMemo(() => {
    if (query.projectGroupIds.length === 0) return projectTelStatusCallReports;

    return projectTelStatusCallReports.filter((report) =>
      query.projectGroupIds.includes(report.projectGroupId),
    );
  }, [projectTelStatusCallReports, query.projectGroupIds]);

  const projectChecked = (id: string) =>
    query.projectIds.some((project) => project === id);

  const onChangeProject = React.useCallback((value: string) => {
    const newProjects = query.projectIds;
    const target = newProjects.find((project) => {
      return project === value;
    });
    if (target) {
      newProjects.splice(newProjects.indexOf(target), 1);
    } else {
      newProjects.push(value);
    }
    setQuery({projectIds: newProjects});
  }, []);

  const {data: {projectPreleadTelStatusReports = []} = {}} =
    useProjectPreleadTelStatusReportsQuery();

  const pooledProjectPreleadTelStatusReports = React.useMemo(() => {
    if (query.projectGroupIds.length === 0)
      return projectPreleadTelStatusReports;

    return projectPreleadTelStatusReports.filter((report) =>
      query.projectGroupIds.includes(report.projectGroupId),
    );
  }, [projectPreleadTelStatusReports, query.projectGroupIds]);

  const {data: {clientAllProjects: {projects = []} = {}} = {}} =
    useClientAllProjectsQuery({
      variables: {
        search: {
          statuses: ['open', 'archived'],
        },
      },
    });

  const pooledProjects = React.useMemo(() => {
    if (query.projectGroupIds.length === 0) return projects;

    return projects.filter((project) =>
      query.projectGroupIds.includes(project.projectGroupId),
    );
  }, [projects, query.projectGroupIds]);

  const projectName = React.useCallback(
    (projectId: string) =>
      projects.find((project) => project.id === projectId)?.name,
    [projects],
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={
        <PopoverContent>
          {location.pathname === '/report/call/project/call' ? (
            <>
              {pooledProjectTelStatusCallReports.length === 0 && (
                <span>データがありません。</span>
              )}
              {pooledProjectTelStatusCallReports?.map((report) => (
                <div key={report.projectId}>
                  <CheckBoxStyled
                    value={report.projectId}
                    checked={projectChecked(report.projectId)}
                    onChange={(e) => onChangeProject(e.target.value)}>
                    {report.projectName}
                  </CheckBoxStyled>
                </div>
              ))}
            </>
          ) : location.pathname === '/report/call/project/prelead' ? (
            <>
              {pooledProjectPreleadTelStatusReports.length === 0 && (
                <span>データがありません。</span>
              )}
              {pooledProjectPreleadTelStatusReports?.map((report) => (
                <div key={report.projectId}>
                  <CheckBoxStyled
                    value={report.projectId}
                    checked={projectChecked(report.projectId)}
                    onChange={(e) => onChangeProject(e.target.value)}>
                    {report.projectName}
                  </CheckBoxStyled>
                </div>
              ))}
            </>
          ) : (
            <>
              {pooledProjects.length === 0 && <span>データがありません。</span>}
              {pooledProjects?.map((project) => (
                <div key={project.id}>
                  <CheckBoxStyled
                    value={project.id}
                    checked={projectChecked(project.id)}
                    onChange={(e) => onChangeProject(e.target.value)}>
                    {project.name}
                  </CheckBoxStyled>
                </div>
              ))}
            </>
          )}
        </PopoverContent>
      }>
      <Box>
        営業リスト
        {query.projectIds.length > 0 && (
          <Popover
            placement="bottom"
            content={
              <FilterContent>
                {query.projectIds?.map((projectId) => (
                  <div key={projectId}>
                    <span>{projectName(projectId)}</span>
                  </div>
                ))}
              </FilterContent>
            }>
            {query.projectIds.length > 0 && (
              <span>{query.projectIds.length}</span>
            )}
          </Popover>
        )}
      </Box>
    </Popover>
  );
};

export default ProjectFilter;
