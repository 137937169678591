import React from 'react';
import {Approach} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Approach>;
}

const Cell = ({row}: Props) => {
  const approach = row.original;
  const {user} = approach;

  return user ? (
    <div className="text-sm truncate">
      {user?.lastName} {user?.firstName}
    </div>
  ) : null;
};

export default Cell;
