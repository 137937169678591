import React from 'react';
import styled from 'styled-components';
import {useRecoilValue} from 'recoil';
import totalCountAtom from '../totalCountAtom';
import {useCurrentMailCampaignContractQuery} from 'api';

interface Props {
  title: string;
}

export default ({title}: Props) => {
  const count = useRecoilValue(totalCountAtom);
  const {data: {currentMailCampaignContract = {}} = {}} =
    useCurrentMailCampaignContractQuery({
      fetchPolicy: 'cache-and-network',
    });
  const availableCount =
    (currentMailCampaignContract?.mailCampaignSendsLimit || 0) -
    (currentMailCampaignContract?.mailCampaignSendsUsage || 0);

  return (
    <>
      <Container>
        {title}
        <Count>
          <span>送信先</span> {count ? count.toLocaleString() : 0}
          <div>（ 残り {availableCount.toLocaleString()} ）</div>
        </Count>
      </Container>
      <SendsUsage></SendsUsage>
    </>
  );
};
const Container = styled.h1`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Count = styled.div`
  span {
    font-weight: 500;
    font-size: 14px;
    margin-right: 8px;
  }
  div {
    font-size: 12px;
    font-weight: 500;
    color: #899098;
    display: flex;
    justify-content: end;
  }
`;

const SendsUsage = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
