import React from 'react';
import useCondition from '../../../useCondition';
import Header from '../../Header';
import Footer from '../Footer';
import {useSalesforceContactRecordTypesQuery} from 'api';
import ContactField from './ContactField';

const SalesforceContactFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery();

  const onChangeRecordType = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCondition({
      salesforceContactRecordTypeId: e.target.value,
      salesforceContactSearchConditions: [],
    });

  return (
    <>
      <Header title="Salesforce取引先責任者" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">レコードタイプ</div>
            <select
              className="form-select border-c-border rounded text-base w-full"
              onChange={onChangeRecordType}
              value={condition?.salesforceContactRecordTypeId}>
              <option value="" />
              {salesforceContactRecordTypes.map((recordType) => (
                <option value={recordType.id} key={recordType.id}>
                  {recordType.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">条件項目</div>
            {condition.salesforceContactSearchConditions?.map(
              (searchCondition, index) => (
                <ContactField
                  key={searchCondition.id}
                  searchCondition={searchCondition}
                  setSearchCondition={(searchCondition) => {
                    const searchConditions = [
                      ...condition.salesforceContactSearchConditions,
                    ];
                    searchConditions[index] = searchCondition;
                    setCondition({
                      salesforceContactSearchConditions: searchConditions,
                    });
                  }}
                  onDelete={() => {
                    const searchConditions = [
                      ...condition.salesforceContactSearchConditions,
                    ];
                    searchConditions.splice(index, 1);
                    setCondition({
                      salesforceContactSearchConditions: searchConditions,
                    });
                  }}
                />
              ),
            )}
            <button
              className="cursor-pointer w-32 h-8 flex items-center justify-center rounded bg-white border border-c-border text-light"
              onClick={() =>
                setCondition({
                  salesforceContactSearchConditions: [
                    ...condition.salesforceContactSearchConditions,
                    {},
                  ],
                })
              }>
              + 条件を追加
            </button>
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setCondition({
            salesforceContactRecordTypeId: '',
            salesforceContactSearchConditions: [],
          })
        }
      />
    </>
  );
};

export default SalesforceContactFilter;
