import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import {DotMenuSide, Copy, Trash} from 'components/Ui/Icon';
import {Form, useCopyFormMutation, useDestroyFormMutation} from 'api';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [copy] = useCopyFormMutation({
    variables: {
      uuid: form.uuid,
    },
    refetchQueries: ['forms'],
  });

  const [destroy] = useDestroyFormMutation({
    variables: {
      uuid: form.uuid,
    },
    refetchQueries: ['forms'],
  });

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full justify-center bg-white px-2 py-1 text-sm focus:outline-none cursor-pointer">
          <DotMenuSide />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-1 w-36 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              <button
                className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1"
                onClick={() => {
                  confirm('コピーしてもよろしいですか？') && copy();
                }}>
                <Copy />
                コピー
              </button>
            </Menu.Item>
            {form.postCount === 0 && (
              <Menu.Item>
                <button
                  className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1"
                  onClick={() => {
                    confirm('削除してもよろしいですか？') && destroy();
                  }}>
                  <Trash />
                  削除
                </button>
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
