import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {
  useProspectReportProspectPoolUserWeeklyCallSumReportQuery,
  useProspectReportProspectPoolUserWeeklyCallReportsQuery,
} from 'api';
import {StringParam, useQueryParams} from 'use-query-params';
import useWeekHeaders from '../../../hooks/useWeekHeaders';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {
    data: {prospectReportProspectPoolUserWeeklyCallSumReport = null} = {},
  } = useProspectReportProspectPoolUserWeeklyCallSumReportQuery({
    variables: {
      month: query.month,
    },
    skip: !query.month,
  });

  const {data: {prospectReportProspectPoolUserWeeklyCallReports = []} = {}} =
    useProspectReportProspectPoolUserWeeklyCallReportsQuery({
      variables: {month: query.month},
      skip: !query.month,
    });

  const headers: ReportTableHeader[] = useWeekHeaders(query.month);
  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const callData = () => {
    const allReports = [
      prospectReportProspectPoolUserWeeklyCallSumReport,
      ...prospectReportProspectPoolUserWeeklyCallReports,
    ].filter(Boolean);
    return allReports.map((report) => {
      const reportValuesSum = sum(report.values);
      return {
        label: report.name,
        values: [reportValuesSum, ...report.values],
        color: report.color,
        subRows: report.prospectTelStatuses?.map((subReport) => {
          const subReportValuesSum = sum(subReport.values);
          return {
            label: subReport?.name,
            values: [subReportValuesSum, ...subReport.values],
          };
        }),
      };
    });
  };

  return {
    data: [...callData()],
    headers,
  };
};

export default useTableData;
