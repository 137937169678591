import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import RadioButtonGroup from './RadioButtonGroup';
import useCondition from '../../useCondition';

const MonthsSinceActionFilter = () => {
  const {condition, setCondition} = useCondition();

  return (
    <>
      <Header title="アクション経過" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <RadioButtonGroup
            name="monthsSinceAction"
            options={[
              {text: '1ヶ月以上', value: '1'},
              {text: '2ヶ月以上', value: '2'},
              {text: '3ヶ月以上', value: '3'},
              {text: '6ヶ月以上', value: '6'},
            ]}
            value={
              condition.monthsSinceAction === null
                ? null
                : condition.monthsSinceAction.toString()
            }
            onChange={(value) =>
              setCondition({
                monthsSinceAction: value === null ? null : parseInt(value, 10),
              })
            }
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({monthsSinceAction: null})} />
    </>
  );
};

export default MonthsSinceActionFilter;
