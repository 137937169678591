import gql from 'graphql-tag';

export default gql`
  fragment clientDownloadProspectFragment on ClientDownloadProspect {
    id
    file
    filename
    fields
    count
    createdAt
  }
`;
