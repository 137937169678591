import React from 'react';
import {Step, CallStep} from 'api';

interface CallStepType extends Step {
  steppable: CallStep;
}

interface Props {
  step: CallStepType;
}

const CallStats = ({step}: Props) => {
  const callStep = step.steppable;
  const leadCount = callStep.actionsCount;
  const callCount = callStep.calledCount;
  const callRate =
    callCount > 0 ? Math.floor((callCount / leadCount) * 100) : 0;
  const dealsCount = step.dealsCount;
  const dealRate =
    callCount > 0 ? Math.floor((dealsCount / callCount) * 100) : 0;

  return (
    <ul className="flex gap-1 justify-evenly">
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">リード</span>
        <span className="text-base font-bold leading-none">
          {(callStep.actionsCount || 0).toLocaleString()}
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">コール</span>
        <span className="text-base font-bold leading-none">{callCount}</span>
        <span className="text-xs text-c-light leading-none">
          ( {callRate.toFixed(1)} <span className="text-xs">%</span> )
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">商談</span>
        <span className="text-base font-bold leading-none">
          {(step.dealsCount || 0).toLocaleString()}
        </span>
        <span className="text-xs text-c-light leading-none">
          ( {dealRate.toFixed(1)}% )
        </span>
      </li>
      <li className="border-r border-c-border" />
    </ul>
  );
};

export default CallStats;
