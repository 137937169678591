import React from 'react';
import {useTable, useBlockLayout, useResizeColumns} from 'react-table';
import {useWebsiteByWebsiteTypeQuery, useWebsitePagesQuery} from 'api';
import {useParams} from 'react-router';
import useSearchCondition from '../../useSearchCondition';

const useWebsitePageTable = () => {
  const {websiteType} =
    useParams<{
      websiteType: string;
    }>();
  const {mediaSearchParams, setQuery, page, perPage} = useSearchCondition();

  const {data: {websiteByWebsiteType: website} = {}} =
    useWebsiteByWebsiteTypeQuery({
      variables: {
        websiteType: websiteType,
      },
    });

  const {
    data: {websitePages: {websitePages = [], pagination = {}} = {}} = {},
    loading,
  } = useWebsitePagesQuery({
    variables: {
      websiteType: websiteType,
      search: mediaSearchParams,
      page,
      perPage,
    },
  });

  const paginationData = React.useMemo(() => {
    return {
      total: pagination.totalCount,
      current: pagination.currentPage,
      pageSize: perPage,
      onChange: (page: number) => setQuery({page}),
    };
  }, [pagination]);

  const defaultColumn = {
    minWidth: 30,
    width: 150,
    maxWidth: 400,
  };

  const columns: any[] = React.useMemo(() => {
    if (!website?.fields) return [];

    return website.fields
      .filter((field) => field !== 'corp_number')
      .map((field) => {
        return {
          id: field,
          Header: field,
          accessor: `payload.${field}`,
          width: 170,
        };
      });
  }, [website]);

  const {setHiddenColumns, ...restProps} = useTable(
    {
      columns,
      defaultColumn,
      data: websitePages,
    },
    useBlockLayout,
    useResizeColumns,
  );

  return {
    setHiddenColumns,
    ...restProps,
    ...paginationData,
    loading: loading,
  };
};

export default useWebsitePageTable;
