import {
  usePoolQuery,
  useLeadSourceUserReportsQuery,
  LeadSourceUserReport,
} from 'api';
import React from 'react';
import {Link} from 'react-router-dom';

const useLeadSourceUserReports = () => {
  const {data: {pool = {}} = {}} = usePoolQuery({});
  const {data: {leadSourceUserReports = []} = {}} =
    useLeadSourceUserReportsQuery({});
  const sumValues = (values: number[]): number => {
    const total = values
      .filter(Boolean)
      .reduce((accumulator, currentValue) => accumulator + currentValue, null);
    return total;
  };

  const LinkProspectsLeadSource = (report: LeadSourceUserReport) =>
    sumValues(report.values) && (
      <Link
        to={`/pools/${pool?.uuid}/prospects?leadSourceIds=${report.id ?? 0}`}
        target="_blank"
        key={`sum_${report.id}`}>
        {sumValues(report.values)}
      </Link>
    );

  const LinksProspectsAssigneeLeadSource = (report: LeadSourceUserReport) =>
    report.keyValueArray.map(
      ([userId, value]) =>
        value && (
          <Link
            to={`/pools/${pool?.uuid}/prospects?assignees=${userId || 'none'}&leadSourceIds=${report.id ?? 0}`}
            target="_blank"
            key={userId}>
            {value}
          </Link>
        ),
    );

  const reportData = React.useMemo(() => {
    return leadSourceUserReports.map((report) => {
      return {
        label: report.name,
        values: [
          LinkProspectsLeadSource(report),
          ...LinksProspectsAssigneeLeadSource(report),
        ],
      };
    });
  }, [leadSourceUserReports, pool]);

  return reportData;
};

export default useLeadSourceUserReports;
