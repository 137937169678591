import gql from 'graphql-tag';

export default gql`
  fragment clientUserFragment on ClientUser {
    id
    clientId
    role
    roleText
    isActive
    canReceiveDailySummaryMail
  }
`;
