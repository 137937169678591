import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useTable} from 'react-table';
import {StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';
import {useHistory} from 'react-router-dom';
import useSearchParams from '../useSearchParams';
import {useProjectTelStatusCallReportsQuery} from 'api';
import {Download} from 'components/Ui/Icon';
import DownloadModal from './CallReportDownloadModal';
import {DownloadButton} from '../../style';

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const history = useHistory();
  const {searchParams} = useSearchParams();

  const {data: {projectTelStatusCallReports = []} = {}} =
    useProjectTelStatusCallReportsQuery({
      variables: {
        search: searchParams,
      },
      fetchPolicy: 'cache-and-network',
    });

  const totalCountPerStatus = (status: string) =>
    projectTelStatusCallReports.reduce(
      (total, report: any) => total + report[status],
      0,
    );

  const totalCountRowData = useMemo(() => {
    const callCount = totalCountPerStatus('call');
    const receptionCount = totalCountPerStatus('reception');
    const contactCount = totalCountPerStatus('contact');
    const documentCount = totalCountPerStatus('document');
    const otherCount = totalCountPerStatus('other');
    const appointCount = totalCountPerStatus('appoint');
    const callNgCount = totalCountPerStatus('callNg');
    const receptionNgCount = totalCountPerStatus('receptionNg');
    const contactNgCount = totalCountPerStatus('contactNg');

    return {
      isTotalCountRowData: true,
      callCount: callCount,
      receptionCount: receptionCount,
      contactCount: contactCount,
      documentCount: documentCount,
      otherCount: otherCount,
      appointCount: appointCount,
      callNgCount: callNgCount,
      receptionNgCount: receptionNgCount,
      contactNgCount: contactNgCount,
      totalCount:
        callCount +
        receptionCount +
        contactCount +
        documentCount +
        otherCount +
        appointCount +
        callNgCount +
        receptionNgCount +
        contactNgCount,
    };
  }, [projectTelStatusCallReports]);

  const data: any = useMemo(
    () =>
      projectTelStatusCallReports.map((report) => {
        return {
          projectName: report.projectName,
          projectUuid: report.projectUuid,
          projectGroupUuid: report.projectGroupUuid,
          callCount: report.call,
          receptionCount: report.reception,
          contactCount: report.contact,
          documentCount: report.document,
          otherCount: report.other,
          appointCount: report.appoint,
          callNgCount: report.callNg,
          receptionNgCount: report.receptionNg,
          contactNgCount: report.contactNg,
          totalCount: report.total,
        };
      }),
    [projectTelStatusCallReports],
  );

  data.unshift(totalCountRowData);

  const columns: any = useMemo(
    () => [
      {
        Header: '',
        id: 'projectName',
        accessor: (row: any) =>
          row.isTotalCountRowData ? (
            <TotalCell>
              <div>コール件数</div>
              <div>{row.totalCount}</div>
            </TotalCell>
          ) : (
            <ProjectNameCell>
              <ProjectName
                onClick={() => {
                  history.push(
                    `/list/groups/${row.projectGroupUuid}/projects/${row.projectUuid}/tel_reports/all/call`,
                  );
                }}>
                {row.projectName}
              </ProjectName>
              <div>{row.totalCount}</div>
            </ProjectNameCell>
          ),
      },
      {
        id: 'call',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('call')} />
            コール
          </>
        ),
        accessor: (row: any) => <CountCell>{row.callCount}</CountCell>,
      },
      {
        id: 'reception',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('reception')} />
            受付
          </>
        ),
        accessor: (row: any) => <CountCell>{row.receptionCount}</CountCell>,
      },
      {
        id: 'contact',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('contact')} />
            キーマン接触
          </>
        ),
        accessor: (row: any) => <CountCell>{row.contactCount}</CountCell>,
      },
      {
        id: 'document',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('document')} />
            資料送付
          </>
        ),
        accessor: (row: any) => <CountCell>{row.documentCount}</CountCell>,
      },
      {
        id: 'other',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('other')} />
            その他
          </>
        ),
        accessor: (row: any) => <CountCell>{row.otherCount}</CountCell>,
      },
      {
        id: 'appoint',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('appoint')} />
            アポ獲得
          </>
        ),
        accessor: (row: any) => <CountCell>{row.appointCount}</CountCell>,
      },
      {
        id: 'callNg',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('call_ng')} />
            コールNG
          </>
        ),
        accessor: (row: any) => <CountCell>{row.callNgCount}</CountCell>,
      },
      {
        id: 'receptionNg',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('reception_ng')} />
            受付NG
          </>
        ),
        accessor: (row: any) => <CountCell>{row.receptionNgCount}</CountCell>,
      },
      {
        id: 'contactNg',
        Header: (
          <>
            <StatusEclipse color={telCategoryColor('contact_ng')} />
            キーマン接触NG
          </>
        ),
        accessor: (row: any) => <CountCell>{row.contactNgCount}</CountCell>,
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <>
      <Title>カテゴリ別</Title>
      <DownloadButton onClick={() => setModalOpen(true)}>
        <Download />
        ダウンロード
      </DownloadButton>
      <TableWrapper>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <th {...column.getHeaderProps()} key={j}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps()} key={j}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>

      {isModalOpen && (
        <DownloadModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </>
  );
};

const Title = styled.div`
  margin-bottom: 17px;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #495058;
`;

const TableWrapper = styled.div`
  border: 1px solid #e1e6eb;
  height: 610px;
  overflow: scroll;
  position: relative;
  top: -40px;
`;

const Table = styled.table`
  width: 1560px;
  border-bottom: 1px solid #e1e6eb;

  thead {
    background: #ffffff;
    position: sticky;
    top: 0;
    z-index: 3;

    tr {
      th {
        &:first-child {
          border-right: double 3px #e1e6eb;
          background: #ffffff;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 3;
        }

        border-right: 1px solid #e1e6eb;
        height: 45px;
        width: 145px;
        font-weight: bold;
        font-size: 13px;

        &:first-child {
          width: 258px;
        }

        svg {
          margin-right: 6px;
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        border-bottom: double 3px #e1e6eb;
        background: #ffffff;
        position: sticky;
        top: 45px;
        z-index: 2;

        td:first-child {
          background: #ffffff;
          position: sticky;
          top: 45px;
          z-index: 2;
        }
      }

      &:nth-child(n + 3) {
        border-right: 1px solid #e1e6eb;
        border-top: 1px solid #e1e6eb;
      }

      td {
        &:first-child {
          border-right: double 3px #e1e6eb;
          background: #ffffff;
          position: sticky;
          left: 0;
          z-index: 1;
        }

        border-right: 1px solid #e1e6eb;
        height: 45px;
        font-weight: 500;
        font-size: 12px;
        color: #495058;
      }
    }
  }
`;

const ProjectName = styled.div`
  display: inline-block;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: #68b5fb;
  font-weight: 500;
  font-size: 13px;
`;

const CountCell = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  padding-right: 10px;
`;

const ProjectNameCell = styled.div`
  margin-left: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
`;

const TotalCell = styled.div`
  margin-left: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;

  div:first-child {
    font-weight: 500;
    font-size: 13px;
    color: #495058;
  }
`;
