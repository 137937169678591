import React from 'react';
import {AccountGroup, useDestroyAccountGroupMutation} from 'api';
import {DotMenuSide} from 'components/Ui/Icon';
import Dropdown from '../Dropdown';
import StageModal from './StageModal';
import TagModal from './TagModal';

interface Props {
  accountGroup: AccountGroup;
}

export default ({accountGroup}: Props) => {
  const [stageModalVisible, setStageModalVisible] = React.useState(false);
  const [tagModalVisible, setTagModalVisible] = React.useState(false);
  const [destroyAccountGroup] = useDestroyAccountGroupMutation({});

  return (
    <>
      <Dropdown
        disabled={accountGroup.status !== 'normal'}
        options={[
          {
            label: 'リードステージ一括変更',
            onClick: () => setStageModalVisible(true),
          },
          {
            label: 'リードタグ一括追加',
            onClick: () => setTagModalVisible(true),
          },
        ]}
        className="cursor-pointer bg-transparent text-primary text-white border-none text-sm hover:bg-[#E9F4FF] rounded-full flex items-center justify-center h-8 w-8 disabled:bg-c-bg disabled:text-c-light">
        <DotMenuSide
          color={accountGroup.status !== 'normal' ? '#899098' : '#68b5fb'}
        />
      </Dropdown>
      <StageModal
        accountGroup={accountGroup}
        visible={stageModalVisible}
        onClose={() => setStageModalVisible(false)}
      />
      <TagModal
        accountGroup={accountGroup}
        visible={tagModalVisible}
        onClose={() => setTagModalVisible(false)}
      />
    </>
  );
};
