import React from 'react';
import {usePoolQuery} from 'api';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import useCondition from '../../useCondition';

const OriginalLeadSourceFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {pool: {leadSources = []} = {}} = {}} = usePoolQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <Header title="初回リードソース" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={[
              {text: '未設定', value: '0', keyword: ''},
              ...leadSources.map((leadSource) => ({
                text: leadSource.name,
                value: leadSource.id,
                keyword: leadSource.name,
              })),
            ]}
            values={condition.originalLeadSources || []}
            onChange={(originalLeadSources) =>
              setCondition({originalLeadSources})
            }
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({originalLeadSources: []})} />
    </>
  );
};

export default OriginalLeadSourceFilter;
