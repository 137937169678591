import React from 'react';
import WorkflowFilters from './Workflow';
import Header from '../Header';
import Footer from './Footer';

const ListPage = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <Header title="検索条件" />
      <div className="p-6 bg-c-bg rounded-b h-full overflow-y-scroll flex-1 flex flex-col gap-2">
        <WorkflowFilters />
      </div>
      <Footer />
    </div>
  );
};

export default ListPage;
