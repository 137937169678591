import React from 'react';
import Lead from './Lead';
import Form from './Form';
import Company from './Company';
import Hubspot from './Hubspot';
import Salesforce from './Salesforce';
import Header from '../Header';
import Footer from './Footer';

const ListPage = () => (
  <div className="w-full h-full flex flex-col">
    <Header title="検索条件" />
    <div className="p-6 bg-c-bg rounded-b h-full overflow-y-scroll flex-1 flex flex-col gap-2">
      <Lead />
      <Form />
      <Company />
      <Hubspot />
      <Salesforce />
    </div>
    <Footer />
  </div>
);

export default ListPage;
