import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useClientAllProjectsQuery} from 'api';

const ProjectFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {clientAllProjects: {projects = []} = {}} = {}} =
    useClientAllProjectsQuery({
      variables: {
        search: {
          status: 'open',
        },
      },
    });

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.projectIds || []}
      filterOption
      optionFilterProp="label"
      onChange={(value) => setQuery({page: 1, projectIds: value})}>
      {projects.map((project) => (
        <Select.Option value={project.id} key={project.id} label={project.name}>
          {project.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProjectFilter;
