import gql from 'graphql-tag';
import preleadCustomizeItemSelectOptionFragment from './customizeItemSelectOption';

export default gql`
  fragment preleadCustomizeItemSelectFragment on PreleadCustomizeItemSelect {
    id
    preleadCustomizeItemSelectOptions {
      ...preleadCustomizeItemSelectOptionFragment
    }
    clientPreleadCustomizeItemSelectOptionIds
    clientPreleadCustomizeItemId
  }
  ${preleadCustomizeItemSelectOptionFragment}
`;
