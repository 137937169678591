import React, {useState} from 'react';
import Modal from 'react-modal';
import {Button} from 'components/antd';
import {Close} from 'components/Icon';
import {useCreateAllProjectsUserCallRateTelStatusDownloadMutation} from 'api';
import {Open} from 'components/Ui/Icon';
import moment from 'moment';
import useSearchParams from '../useSearchParams';
import {
  ModalHeader,
  ModalBody,
  SuccessBody,
  SuccessNote,
  OpenCsvButton,
  FileType,
} from '../../style';

type Props = {
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
};

export default ({isOpen, setModal}: Props) => {
  const [success, setResult] = useState(false);

  const {searchParams} = useSearchParams();

  const [
    createAllProjectsUserCallRateTelStatusDownload,
    {
      data: {
        createAllProjectsUserCallRateTelStatusDownload: {
          reportDownload = {},
        } = {},
      } = {},
      loading,
    },
  ] = useCreateAllProjectsUserCallRateTelStatusDownloadMutation({
    onCompleted: () => {
      setResult(true);
    },
  });

  const filename = `担当者別-対コール率-カテゴリ別-${moment().format(
    'YYYY-MM-DD',
  )}`;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
          zIndex: 100,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          borderRadius: '10px',
          width: '654px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
        },
      }}
      contentLabel="modal">
      <ModalHeader>
        <h2>csvダウンロード</h2>
        <Button onClick={() => setModal(false)}>
          <Close />
          閉じる
        </Button>
      </ModalHeader>

      <ModalBody>
        <SuccessBody>
          <SuccessNote>
            <p>
              ダウンロードを行う場合は、 <br />
              ダウンロードボタンをクリックしてください。
            </p>
          </SuccessNote>

          {!loading && success ? (
            <OpenCsvButton
              type="primary"
              onClick={() => {
                window.location.href = `/download/report_downloads/${reportDownload.uuid}/export_all_projects_user_call_rate_tel_status_report`;
              }}>
              <Open />
              CSVファイルをダウンロード
            </OpenCsvButton>
          ) : (
            <OpenCsvButton
              type="primary"
              loading={loading}
              onClick={() => {
                createAllProjectsUserCallRateTelStatusDownload({
                  variables: {
                    search: searchParams,
                  },
                });
              }}>
              <Open />
              ダウンロード
            </OpenCsvButton>
          )}

          <FileType>
            ファイル形式: CSV / ファイル名: {`${filename}.csv`}
          </FileType>
        </SuccessBody>
      </ModalBody>
    </Modal>
  );
};
