import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import moment from 'moment';
import {useDealReportLeadSourceDailyReportsQuery} from 'api';
import {StringParam, useQueryParams} from 'use-query-params';
import React from 'react';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();
  const days = [...Array(moment().daysInMonth()).keys()].map(
    (day) => `${day + 1}`,
  );
  const [query] = useQueryParams({
    month: StringParam,
  });

  const {data: {dealReportLeadSourceDailyReports = []} = {}} =
    useDealReportLeadSourceDailyReportsQuery({
      variables: {month: query.month},
      fetchPolicy: 'cache-and-network',
    });

  const reportData = React.useMemo(() => {
    return dealReportLeadSourceDailyReports.map((report) => {
      return {
        label: report.name,
        values: report.values,
        color: getColor(Number(report.id)),
        groupId: 1,
      };
    });
  }, [dealReportLeadSourceDailyReports]);

  return {
    axis: [...days],
    data: [...reportData],
  };
};

export default useChartData;
