import theme from '../theme';

const projectStatusBgColor = (status: string): string => {
  const backgroundColor: {[key: string]: string} =
    theme.status.project.background;
  return backgroundColor[status];
};

const projectStatusFontColor = (status: string): string => {
  const fontColor: {[key: string]: string} = theme.status.project.fontColor;
  return fontColor[status];
};

export {projectStatusBgColor, projectStatusFontColor};
