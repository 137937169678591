import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Airplane, TrashBadge} from 'components/Ui/Icon';
import {
  Activity,
  MailFormVersionUrlClickActivity,
  useDestroyMailFormVersionUrlClickActivityMutation,
  useCurrentUserQuery,
} from 'api';
import useProjectName from './useProjectName';

interface MailFormVersionUrlClick extends Activity {
  resource: MailFormVersionUrlClickActivity;
}

interface Props {
  activity: MailFormVersionUrlClick;
}

const MailFormVersionUrlClickActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  const [destroyMailFormVersionUrlClock] =
    useDestroyMailFormVersionUrlClickActivityMutation();

  return (
    <Summary className={currentUser?.role === 'admin' ? 'deletable' : ''}>
      <BadgeWrapper>
        <Airplane />
      </BadgeWrapper>
      <Body>
        {projectName && <Project>{projectName}</Project>}
        <Campaign>{activity.resource.campaign.name}</Campaign>
        <a href={activity.resource.mailFormVersionUrl.url} target="_blank">
          {activity.resource.mailFormVersionUrl.url}
        </a>
      </Body>
      <TimeStamp>{moment(activity.createdAt).format('MM/DD HH:mm')}</TimeStamp>
      {currentUser?.role === 'admin' && (
        <TrashBadgeWrapper
          onClick={() => {
            if (confirm('本当に削除しますか？')) {
              destroyMailFormVersionUrlClock({
                variables: {
                  uuid: activity.uuid,
                },
              });
            }
          }}>
          <TrashBadge />
        </TrashBadgeWrapper>
      )}
    </Summary>
  );
};

const TrashBadgeWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: #c90e19;
      }
    }
  }
`;

const Summary = styled.div`
  margin-left: -30px;
  display: flex;
  align-items: center;
  height: 46px;
  position: relative;
  span {
    line-height: 30px;
  }

  ${TrashBadgeWrapper} {
    display: none;
  }

  &:hover {
    &.deletable {
      background: #f3f5f7;
      border-radius: 4px;

      ${TrashBadgeWrapper} {
        display: flex;
        margin-right: 12px;
      }
    }
  }
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin: auto 20px auto 15px;
`;

const Body = styled.span`
  min-width: 0;
  max-width: 60%;
  margin-top: 4px;
  flex: auto;
  display: flex;
  flex-direction: column;
  * {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 4px;
  }
`;

const Project = styled.div`
  color: #899098;
`;

const Campaign = styled.div`
  color: #899098;
`;

const TimeStamp = styled.span`
  margin: auto 0 auto 0;
`;

export default MailFormVersionUrlClickActivityComponent;
