import React from 'react';
import moment from 'moment';
import {DotMenuSide} from 'components/Ui/Icon';
import {Link} from 'react-router-dom';
import {Form} from 'api';
import usePools from 'hooks/usePools';
import Menu from './ItemMenu';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {defaultPool: pool} = usePools();

  return (
    <div className="bg-white p-5 pr-0 flex rounded cursor-pointer">
      <div className="flex flex-col gap-1 flex-1">
        <h3 className="m-0 text-lg">
          <Link to={`/form/forms/${form.uuid}`}>{form.title}</Link>
        </h3>
        <ul className="text-c-light text-sm flex items-center gap-4">
          {form.status === 'open' && (
            <li className="w-fit flex items-center gap-1 text-xs bg-[#E5F9ED] text-[#4FCE82] h-5 px-2 rounded-sm">
              <span className="rounded-full w-[6px] h-[6px] bg-c-green" />
              {form.statusText}
            </li>
          )}
          {form.status !== 'open' && (
            <li className="w-fit flex items-center gap-1 text-xs bg-c-bg text-c-light h-5 px-2 rounded-sm">
              <span className="rounded-full w-[6px] h-[6px] bg-c-light" />
              {form.statusText}
            </li>
          )}
          <li className="w-fit inline-block">
            最終更新日時: {moment(form.updatedAt).format('YYYY/MM/DD HH:mm')}
          </li>
          <li className="w-fit inline-block">
            最終更新者:{' '}
            {form.user ? `${form.user.lastName} ${form.user.firstName}` : ''}
          </li>
        </ul>
      </div>
      <ul className="flex">
        <li className="w-24 flex flex-col items-center justify-center border-l border-c-border px-4">
          <h5 className="text-sm m-0">受信数</h5>
          <span className="text-xl text-c-primary font-bold w-full text-right">
            <a href={`/pools/${pool?.uuid}/prospects?formIds=${form.id}`}>
              {form.postCount.toLocaleString()}
            </a>
          </span>
        </li>
        <li className="w-24 flex flex-col items-center justify-center border-l border-c-border px-4">
          <Menu form={form} />
        </li>
      </ul>
    </div>
  );
};
