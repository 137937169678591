import React from 'react';

const Icon = ({color = '#BDC4CB', className = ''}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1 10.2C23.1 9.6 22.7 9.2 22.1 9.2H1.90002C1.30002 9.2 0.900024 9.6 0.900024 10.2V16C0.900024 16.6 1.30002 17 1.90002 17H22.2C22.8 17 23.2 16.6 23.2 16V10.2H23.1Z"
      fill={color}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16 0.899994V4.89999H20" fill="#899098" />
    <path
      d="M16 0.899994V4.89999H20"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 0.899994H10.1H6C4.9 0.899994 4 1.79999 4 2.89999V21.2C4 22.3 4.9 23.2 6 23.2H18C19.1 23.2 20 22.3 20 21.2V4.89999L16 0.899994Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.50004 12.6C9.50004 13.4 9.00004 14.2 7.90004 14.2H7.10004V15.1H6.10004V11H8.00004C9.00004 11 9.50004 11.8 9.50004 12.6ZM7.10004 11.9V13.2H7.90004C8.30004 13.2 8.60004 13 8.60004 12.5C8.60004 12.1 8.30004 11.8 7.90004 11.8H7.10004V11.9Z"
      fill="white"
    />
    <path
      d="M14.2 13.1C14.2 14.3 13.3 15.1 12 15.1H10.2V11H12C13.4 11 14.2 11.9 14.2 13.1ZM11.3 14.2H12.1C12.8 14.2 13.3 13.7 13.3 13.1C13.3 12.4 12.8 12 12.1 12H11.3V14.2Z"
      fill="white"
    />
    <path
      d="M18 11.9H15.9V12.8H17.7V13.7H15.9V15H14.9V11H18V11.9Z"
      fill="white"
    />
  </svg>
);

export default Icon;
