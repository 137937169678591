import gql from 'graphql-tag';

export default gql`
  fragment companyDownloadFragment on CompanyDownload {
    id
    uuid
    userId
    file
    filename
    status
    count
  }
`;
