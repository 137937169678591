import gql from 'graphql-tag';

export default gql`
  fragment projectWithPercentFragment on Project {
    id
    uuid
    serialNumber
    projectType
    name
    count
    percent
    status
    statusText
    preleadCreateStatus
  }
`;
