import gql from 'graphql-tag';

export default gql`
  fragment salesforceSettingFragment on SalesforceSetting {
    id
    clientId
    isActive
    salesforceClientId
    username
    instanceUrl
    leadStatus
    leadSource
    preleadUrlFieldName
    addAddress
    addTelephoneNumber
    addWebSite
    addEmployeeNumber
    addSales
    addCategory
    isPrivateKeyExist
    canSyncRegularly
    canUpdateSalesforceAccount
    canUpdateSalesforceLead
    canSalesforceTasksProspectsImport
    canUpdateLeadpadMailInfo
  }
`;
