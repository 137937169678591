import React from 'react';
import styled from 'styled-components';
import {Input} from 'components/antd';

type Props = {
  children?: React.ReactNode;
  type?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  error?: boolean;
  onChange?: any;
  onBlur?: any;
  ref?: any;
  autoFocus?: any;
};

export default ({error, children, ...props}: Props) => {
  return (
    <StyledInput error={error ? 1 : 0} {...props}>
      {children}
    </StyledInput>
  );
};

const StyledInput = styled(Input)<{error: number}>`
  &&& {
    border-radius: 4px;
    border: ${(props) =>
      props.error ? '1px solid #EA3F60' : '1px solid #afc1fd'};

    &:focus {
      border: ${(props) =>
        props.error ? '1px solid #EA3F60' : '1px solid #3864fb'};
    }
  }
`;
