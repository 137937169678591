import React, {SVGProps} from 'react';

const SvgGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M5.074 1.809h6.3a.86.86 0 01.875.875v6.3a.859.859 0 01-.875.875h-6.3a.86.86 0 01-.875-.875v-6.3c-.058-.467.35-.875.875-.875z" />
    <path d="M8.166 12.367H2.45a.797.797 0 01-.816-.816V5.834" />
  </svg>
);

export default SvgGroup;
