import React from 'react';
import CustomizeItemField from './CustomizeItemField';
import {CustomizeItemSelectSearchConditionType} from '../CustomizeItemSearchConditionType';
interface Props {
  prospectCustomizeItemSearchCondition: CustomizeItemSelectSearchConditionType;
  index: number;
}
import CustomizeItemFieldOptions from './CustomizeItemFieldOptions';
import Close from './Close';

export default ({prospectCustomizeItemSearchCondition, index}: Props) => {
  return (
    <>
      <CustomizeItemField
        prospectCustomizeItemSearchCondition={
          prospectCustomizeItemSearchCondition
        }
        index={index}
      />
      <CustomizeItemFieldOptions
        prospectCustomizeItemSearchCondition={
          prospectCustomizeItemSearchCondition
        }
        index={index}
      />
      <Close index={index} />
    </>
  );
};
