import React from 'react';
import styled from 'styled-components';
import {usePreleadContactsQuery, usePreleadWithClientQuery} from 'api';
import {Hubspot, Salesforce, Senses} from 'components/Ui/Icon';
import usePreleadContext from '../../usePreleadContext'

export default () => {
  const {preleadId} = usePreleadContext()

  const {data: {prelead = {}} = {}} = usePreleadWithClientQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const {data: {preleadContacts = []} = {}} = usePreleadContactsQuery({
    variables: {preleadUuid: prelead.uuid},
    skip: !prelead.uuid,
  });

  if (!prelead) return null;

  const {
    client = null,
    client: {
      hubspotSetting = null,
      salesforceSetting = null,
      sensesSetting = null,
    } = {},
  } = prelead;

  if (!client) return null;

  const sensesUrl = sensesSetting?.isSensesPartner
    ? 'https://senses-partner.mazrica.com/'
    : 'https://senses.mazrica.com/';

  return (
    <Container>
      {client.hubspotActive && (
        <HubspotLink active={!!prelead.hubspotDealId}>
          <Hubspot />
          {!!prelead.hubspotDealId && !!hubspotSetting.hubspotAccountId ? (
            <a
              href={`https://app.hubspot.com/contacts/${hubspotSetting.hubspotAccountId}/deal/${prelead.hubspotDealId}/`}
              target="_blank">
              HubSpot
            </a>
          ) : (
            <span>HubSpot</span>
          )}
        </HubspotLink>
      )}

      {client.salesforceActive && (
        <SalesforceLink active={preleadContacts.length > 0}>
          <Salesforce />
          {preleadContacts.length > 0 && preleadContacts[0].salesforceLeadId ? (
            <a
              href={`${salesforceSetting.instanceUrl}/lightning/r/Lead/${preleadContacts[0].salesforceLeadId}/view`}
              target="_blank">
              Salesforce
            </a>
          ) : (
            <span>Salesforce</span>
          )}
        </SalesforceLink>
      )}

      {client.sensesActive && sensesSetting.sensesDealTypeId && (
        <SensesLink active={!!prelead.sensesDealId}>
          <Senses />
          {prelead.sensesDealId ? (
            <a
              href={`${sensesUrl}r/deals?id=${prelead.sensesDealId}&show=opportunity`}
              target="_blank">
              Senses
            </a>
          ) : (
            <span>Senses</span>
          )}
        </SensesLink>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IntegrationLink = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  height: 18px;
  border-radius: 100px;
  border: 1px solid #e2e6ea;

  a,
  span {
    margin-left: 4px;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
    padding-right: 10px;
  }

  > svg {
    margin-left: 6px;
    width: 10px;
    height: 10px;
  }
`;

const ArrowDownWrapper = styled.div<{active?: boolean}>`
  svg {
    margin: auto 3px auto 2px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`;

const HubspotLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#FF7A59' : '#ffffff')};
  border: 1px solid #ff7a59;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
  }

  > svg {
    path {
      fill: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
    }
  }
  ${ArrowDownWrapper} {
    path {
      stroke: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
    }
  }
`;

const SensesLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#1A2F3A' : '#ffffff')};
  border: 1px solid #1a2f3a;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
  }

  > svg {
    path {
      fill: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
    }
  }

  ${ArrowDownWrapper} {
    path {
      stroke: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
    }
  }
`;

const SalesforceLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#68B5FB' : '#ffffff')};
  ${({active}) => active && 'cursor: pointer'};
  border: 1px solid #68b5fb;

  ${({active}) => !active && 'opacity: 0.4'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#68b5fb')};
  }

  > svg {
    path {
      fill: ${({active}) => (active ? '#ffffff' : '#68b5fb')};
    }
  }

  ${ArrowDownWrapper} {
    path {
      stroke: ${({active}) => (active ? '#ffffff' : '#68b5fb')};
    }
  }
`;
