import React from 'react';
import moment from 'moment';
import {MiitelCallProspectActivity, ProspectActivity, ProspectPool} from 'api';
import {Call} from 'components/Ui/Icon';
import MiitelCall from './MiitelCall';
import linkifyHtml from 'linkify-html';

interface CallActivity extends ProspectActivity {
  resource: MiitelCallProspectActivity;
}

interface Props {
  prospectActivity: CallActivity;
  prospectPool: ProspectPool;
}

export default ({prospectActivity, prospectPool}: Props) => {
  const {resource, user, action} = prospectActivity;
  const {stageText, commentHtml, clientProspectTelStatus} = resource;
  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <Call />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-4 min-h-[32px]">
          <h3 className="flex-1 text-c-primary font-bold">
            {action?.step?.name}
          </h3>
          <span className="text-c-light">
            {user?.lastName} {user?.firstName}
          </span>
          <time className="text-c-light">
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </div>
        <div className="border border-c-border rounded">
          <div className="p-4 flex items-center gap-4">
            {clientProspectTelStatus?.name && (
              <span className="text-[#27ae60] text-xs h-5 px-2 bg-[#e6f5ea] rounded-sm leading-5">
                {clientProspectTelStatus?.name}
              </span>
            )}
            <span className="text-[#2d9cdb] text-xs h-5 px-2 bg-[#dceeff] rounded-sm leading-5">
              {stageText}
            </span>
          </div>
          {commentHtml && (
            <div className="px-4 [&_p]:mb-0">
              <div
                dangerouslySetInnerHTML={{__html: convertUrlToLink(commentHtml)}}
              />
            </div>
          )}
          <div className="border-t border-solid border-c-border mt-4">
            <MiitelCall
              prospectActivity={prospectActivity}
              prospectPool={prospectPool}
            />
          </div>
        </div>
      </div>
    </>
  );
};
