import React from 'react';
import useSearchParams from './useSearchParams';
import moment from 'moment';
import {useTaskCountsQuery} from 'api';

const SummaryItem = ({
  label,
  count,
  active,
  onClick,
}: {
  label: string;
  count: string;
  active: boolean;
  onClick: () => void;
}) => (
  <div
    className={`${
      active ? 'text-[#495058]' : 'text-[#bdc4cb]'
    } cursor-pointer w-24 h-14 flex flex-col items-center justify-center border-l border-c-border [&:first-child]:border-none`}
    onClick={onClick}>
    <span className="text-xl font-bold">{count}</span>
    <span className="font-sm font-medium">{label}</span>
  </div>
);

export default () => {
  const {query, setQuery, searchParams} = useSearchParams();
  const {
    data: {
      taskCounts: {
        sum = 0,
        todayCount = 0,
        overdueCount = 0,
        upcomingCount = 0,
        completedCount = 0,
        iceboxCount = 0,
      } = {},
    } = {},
  } = useTaskCountsQuery({
    variables: {
      search: {
        ...searchParams,
        statuses: [],
        dueDate: null,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const today = moment().format('YYYY/MM/DD');
  const tomorrow = moment().add(1, 'day').format('YYYY/MM/DD');
  const yesterday = moment().subtract(1, 'day').format('YYYY/MM/DD');

  return (
    <div className="border-y border-c-border w-full flex py-2">
      <SummaryItem
        label="本日"
        count={todayCount.toLocaleString()}
        active={query.dueDate?.from === today && query.dueDate?.to === today}
        onClick={() =>
          setQuery({
            statuses: ['not_started', 'in_progress', 'expired'],
            dueDate: {
              from: today,
              to: today,
            },
            page: 1,
          })
        }
      />
      <SummaryItem
        label="期限切れ"
        count={overdueCount.toLocaleString()}
        active={query.dueDate?.to === yesterday}
        onClick={() =>
          setQuery({
            statuses: ['not_started', 'in_progress', 'expired'],
            dueDate: {
              to: yesterday,
            },
            page: 1,
          })
        }
      />
      <SummaryItem
        label="予定"
        count={upcomingCount.toLocaleString()}
        active={query.dueDate?.from === tomorrow}
        onClick={() =>
          setQuery({
            statuses: ['not_started', 'in_progress', 'expired'],
            dueDate: {
              from: tomorrow,
            },
            page: 1,
          })
        }
      />
      <SummaryItem
        label="完了"
        count={completedCount.toLocaleString()}
        active={query.statuses.includes('completed')}
        onClick={() =>
          setQuery({
            statuses: ['completed'],
            dueDate: {},
            page: 1,
          })
        }
      />
      <SummaryItem
        label="すべて"
        count={sum.toLocaleString()}
        active={
          (!query.dueDate || !(query.dueDate.from || query.dueDate.to)) &&
          (!query.statuses || query.statuses.length === 0)
        }
        onClick={() =>
          setQuery({
            dueDate: {},
            statuses: [],
            page: 1,
          })
        }
      />
      <SummaryItem
        label="保留"
        count={iceboxCount.toLocaleString()}
        active={query.statuses.includes('icebox')}
        onClick={() =>
          setQuery({
            statuses: ['icebox'],
            dueDate: {},
            page: 1,
          })
        }
      />
    </div>
  );
};
