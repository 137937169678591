import {NumberParam, useQueryParams} from 'use-query-params';

const searchParamsDef = {
  page: NumberParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  const setPage = (page: number) => setQuery({page});

  return {
    query,
    setQuery,
    page,
    setPage,
  };
};

export default useSearchParams;
