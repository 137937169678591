import gql from 'graphql-tag';
import projectGroupFragment from './projectGroup';

export default gql`
  fragment preleadTagFragment on PreleadTag {
    id
    clientId
    uuid
    name
    color
    projectGroup {
      ...projectGroupFragment
    }
  }
  ${projectGroupFragment}
`;
