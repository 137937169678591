import gql from 'graphql-tag';
import {userFragment} from '../../fragments';

export default gql`
  fragment assignUserActivityFragment on AssignUserActivity {
    id
    user {
      ...userFragment
    }
  }
  ${userFragment}
`;
