import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, AutomaticMailClickProspectActivity} from 'api';
import {AutomaticMail} from 'components/Ui/Icon';

interface AutomaticMailClickActivity extends ProspectActivity {
  resource: AutomaticMailClickProspectActivity;
}

interface Props {
  prospectActivity: AutomaticMailClickActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      automaticMailSend: {
        automaticMail: {stepAutomaticMail: {step = {}} = {}} = {},
      } = {},
      url,
    },
  } = prospectActivity;

  return (
    <>
      <AutomaticMail active={true} width="32" height="32" />
      <Content>
        <ContentHeader>
          <h3>{step.name}</h3>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </ContentHeader>
        <Wrapper>
          <a href={url} target="_blank">
            {url}
          </a>
        </Wrapper>
      </Content>
    </> 
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: #68b5fb;
    font-weight: bold;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  color: #899098;
  width: 100％;

  > a {
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
