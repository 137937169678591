import {useClientPreleadCustomizeItemsQuery} from 'api';
import {pick} from 'lodash';
import moment from 'moment';
import {useState} from 'react';
import {
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
  NumericObjectParam,
  decodeDelimitedArray,
  encodeDelimitedArray,
  ObjectParam,
} from 'use-query-params';

const PAGE_SIZE = 50;

const projectFilterParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(
      array?.map((el) => JSON.stringify(el)),
      '&=',
    ),

  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, '&=')?.map((el) => JSON.parse(el)),
};

const searchParamsDef = {
  page: NumberParam,
  projectGroupIds: DelimitedArrayParam,
  projectIds: DelimitedArrayParam,
  statuses: DelimitedArrayParam,
  preleadStatusIds: withDefault(DelimitedArrayParam, []),
  mailStatuses: DelimitedArrayParam,
  mailCounts: DelimitedArrayParam,
  mailTermFrom: StringParam,
  mailTermTo: StringParam,
  clickCounts: DelimitedArrayParam,
  clickTermFrom: StringParam,
  clickTermTo: StringParam,
  telStatusIds: withDefault(DelimitedArrayParam, []),
  telCounts: DelimitedArrayParam,
  telFrom: StringParam,
  telTo: StringParam,
  pressReleaseSince: StringParam,
  tagIds: withDefault(DelimitedArrayParam, []),
  listingMarkets: withDefault(DelimitedArrayParam, []),
  capitalFund: NumericObjectParam,
  mode: StringParam,
  projectMode: StringParam,
  projectFilters: withDefault(projectFilterParam, [null]),
  sortCategory: StringParam,
  sortOrder: StringParam,
  searchWord: StringParam,
  employeeNumber: NumericObjectParam,
  officesNumber: NumericObjectParam,
  accountClosingMonths: withDefault(DelimitedArrayParam, []),
  establishedYearMonthFrom: StringParam,
  establishedYearMonthTo: StringParam,
  categoryIds: ObjectParam,
  commentCounts: DelimitedArrayParam,
  sales: NumericObjectParam,
  remindFrom: StringParam,
  remindTo: StringParam,
  assigneeIds: DelimitedArrayParam,
  prefectureIds: DelimitedArrayParam,
  telUserIds: DelimitedArrayParam,
  mailCampaignIds: DelimitedArrayParam,
  mailCampaignOpenCounts: DelimitedArrayParam,
  mailCampaignClickCounts: DelimitedArrayParam,
  mailCampaignSendsCounts: DelimitedArrayParam,
  trackedAtFrom: StringParam,
  trackedAtTo: StringParam,
  taskDueDateFrom: StringParam,
  taskDueDateTo: StringParam,
  taskUserIds: DelimitedArrayParam,
  mailCampaignOpenedAtFrom: StringParam,
  mailCampaignOpenedAtTo: StringParam,
  mailCampaignClickedAtFrom: StringParam,
  mailCampaignClickedAtTo: StringParam,
};

const convertDate = (value: string) => {
  if (value === '1d') {
    return moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  if (value === '1w') {
    return moment().subtract(1, 'week').format('YYYY-MM-DD');
  }

  if (value === '2w') {
    return moment().subtract(2, 'week').format('YYYY-MM-DD');
  }

  if (value === '1m') {
    return moment().subtract(1, 'month').format('YYYY-MM-DD');
  }

  if (value === '3m') {
    return moment().subtract(3, 'month').format('YYYY-MM-DD');
  }

  return null;
};

const convertCustomizeItems = (params: any) =>
  Object.keys(params).reduce((prev, key) => {
    if (key.startsWith('c.')) {
      const value = params[key];
      const id = key.replace('c.', '');

      if (!value || value.length === 0) {
        return prev;
      }
      if (Array.isArray(value)) {
        return [
          ...prev,
          {
            id,
            values: value,
          },
        ];
      } else {
        return [
          ...prev,
          {
            id,
            ...params[key],
          },
        ];
      }
    }
    return prev;
  }, []);

const useSearchParams = () => {
  const [params, setParams] = useState(searchParamsDef);

  useClientPreleadCustomizeItemsQuery({
    onCompleted: ({clientPreleadCustomizeItems = []}) => {
      const customizeItemsParams = clientPreleadCustomizeItems.reduce(
        (prev, curr) => {
          if (curr.dataType === 'select') {
            prev[`c.${curr.id}`] = DelimitedArrayParam;
          } else {
            prev[`c.${curr.id}`] = ObjectParam;
          }
          return prev;
        },
        {} as any,
      );
      setParams({...searchParamsDef, ...customizeItemsParams});
    },
  });
  const [query, setQuery] = useQueryParams(params);
  const {page} = query;
  const setPage = (page: number) => setQuery({page});

  const customizeItemsKeys = Object.keys(query).filter((key) =>
    key.startsWith('c.'),
  );
  const customizeItemsQueries = pick(query, customizeItemsKeys);

  const filterParams = {
    projectGroupIds: query.projectGroupIds,
    projectIds: query.projectIds,
    preleadStatusIds: query.preleadStatusIds,
    mailStatuses: query.mailStatuses,
    mailCounts: query.mailCounts,
    mailTermFrom: query.mailTermFrom,
    mailTermTo: query.mailTermTo,
    clickCounts: query.clickCounts,
    clickTermFrom: query.clickTermFrom,
    clickTermTo: query.clickTermTo,
    telStatusIds: query.telStatusIds,
    telCounts: query.telCounts,
    telFrom: query.telFrom,
    telTo: query.telTo,
    pressReleaseSince: convertDate(query.pressReleaseSince),
    tagIds: query.tagIds,
    employeeNumber: query.employeeNumber,
    listingMarkets: query.listingMarkets,
    capitalFund: query.capitalFund,
    officesNumber: query.officesNumber,
    accountClosingMonths: query.accountClosingMonths,
    establishedYearMonthFrom: query.establishedYearMonthFrom,
    establishedYearMonthTo: query.establishedYearMonthTo,
    categoryIds: query.categoryIds,
    commentCounts: query.commentCounts,
    sales: query.sales,
    remindFrom: query.remindFrom,
    remindTo: query.remindTo,
    assigneeIds: query.assigneeIds,
    prefectureIds: query.prefectureIds,
    telUserIds: query.telUserIds,
    customizeItems: convertCustomizeItems(customizeItemsQueries),
    mailCampaignIds: query.mailCampaignIds,
    mailCampaignOpenCounts: query.mailCampaignOpenCounts,
    mailCampaignClickCounts: query.mailCampaignClickCounts,
    mailCampaignSendsCounts: query.mailCampaignSendsCounts,
    trackedAtFrom: query.trackedAtFrom,
    trackedAtTo: query.trackedAtTo,
    taskDueDateFrom: query.taskDueDateFrom,
    taskDueDateTo: query.taskDueDateTo,
    taskUserIds: query.taskUserIds,
    mailCampaignOpenedAtFrom: query.mailCampaignOpenedAtFrom,
    mailCampaignOpenedAtTo: query.mailCampaignOpenedAtTo,
    mailCampaignClickedAtFrom: query.mailCampaignClickedAtFrom,
    mailCampaignClickedAtTo: query.mailCampaignClickedAtTo,
  };

  const searchParams = {
    mode: query.mode,
    filter: filterParams,
    projectMode: query.projectMode,
    projectFilters: query.projectFilters,
    sortCategory: query.sortCategory,
    sortOrder: query.sortOrder,
    searchWord: query.searchWord,
  };

  return {
    query,
    setQuery,
    searchParams: searchParams,
    filterParams: filterParams,
    page,
    perPage: PAGE_SIZE,
    setPage,
  };
};

export default useSearchParams;
