import React from 'react';
import moment from 'moment';
import {ProspectActivity, ZoomPhoneWebhookCallProspectActivity} from 'api';
import Audio from './Audio';
import Transcription from './Transcription';

interface ActivityType extends ProspectActivity {
  resource: ZoomPhoneWebhookCallProspectActivity;
}

interface Props {
  prospectActivity: ActivityType;
}

const ZoomPhoneComponent = ({prospectActivity}: Props) => {
  const {
    zoomPhoneWebhookCall: {status, statusText, recordings},
  } = prospectActivity.resource;

  const displayReload = !recordings.find(
    (recording) =>
      recording.azureWebhookEvent?.azureTranscriptionFiles?.length > 0,
  );

  return (
    <>
      <div className="p-4 flex flex-col gap-2 items-start">
        <div className="flex items-center gap-2">
          <span className="text-xs bg-[#cfe6f8] text-[#2196f3] rounded-sm px-2 h-5 flex items-center justify-center">
            {statusText}
          </span>
          <div className="text-c-light">
            記録日時: {moment(prospectActivity.createdAt).format('MM/DD HH:mm')}
          </div>
        </div>
        {displayReload && (
          <div className="flex gap-1">
            {status === 'caller_ringing' && '発信中...'}
            {status === 'caller_connected' && '記録中...'}
            {status === 'caller_ended' && recordings?.length > 0 && '作成中...'}
          </div>
        )}
        {recordings?.map((recording) => (
          <div key={recording.id} className="w-full">
            {recording.azureWebhookEvent?.azureTranscriptionFiles?.map(
              (file) => <Transcription file={file} key={file.id} />,
            )}
            {recording.file && <Audio file={recording.file} />}
          </div>
        ))}
      </div>
    </>
  );
};

export default ZoomPhoneComponent;
