import React from 'react';
import {
  useSalesforceCampaignMemberFieldsQuery,
  SalesforceCampaignMemberField,
} from 'api';
import useSearchParams from '../../../../useSearchParams';
import {Listbox} from '@headlessui/react';
import ArrowDownIcon from './ArrowDownIcon';

type CustomizeItemFilterItem = {
  salesforceCampaignId: string;
  salesforceCampaignMemberFieldId: string;
  index: number;
};

export default ({
  salesforceCampaignId,
  salesforceCampaignMemberFieldId,
  index,
}: CustomizeItemFilterItem) => {
  const {data: {salesforceCampaignMemberFields = []} = {}} =
    useSalesforceCampaignMemberFieldsQuery({
      variables: {
        salesforceCampaignId: salesforceCampaignId,
      },
    });
  const {query, setQuery} = useSearchParams();

  const selectedFields = () => {
    return query.salesforceCampaignSearchConditions.map(
      (condition) => condition['salesforce_campaign_member_field_id'],
    );
  };

  const selectedItem = (id: string): SalesforceCampaignMemberField => {
    return salesforceCampaignMemberFields.find(
      (salesforceCampaignMemberField) =>
        salesforceCampaignMemberField.id === id,
    );
  };

  const customizeItems = React.useMemo(() => {
    return salesforceCampaignMemberFields.filter(
      (item) =>
        (item.fieldType === 'select' || item.fieldType === 'date') &&
        item.fieldName.endsWith('__c') &&
        !selectedFields().includes(item.id),
    );
  }, [salesforceCampaignMemberFields]);

  return (
    <div className="relative">
      <Listbox
        value={salesforceCampaignMemberFieldId ?? ''}
        onChange={(value) => {
          setQuery({
            ...query,
            salesforceCampaignSearchConditions: [
              ...query.salesforceCampaignSearchConditions.slice(0, index),
              {
                salesforce_campaign_member_field_id: value,
                field_type: selectedItem(value)?.fieldType,
              },
              ...query.salesforceCampaignSearchConditions.slice(index + 1),
            ],
          });
        }}>
        <Listbox.Button className="relative px-3 text-left focus:outline-none flex items-center border rounded w-80 h-8 bg-white border-[#d9d9d9] justify-between truncate">
          <span>{selectedItem(salesforceCampaignMemberFieldId)?.label}</span>
          <ArrowDownIcon />
        </Listbox.Button>
        <Listbox.Options className="absolute">
          {customizeItems.map((salesforceCampaignMemberField) => (
            <Listbox.Option
              className={({active}) =>
                `relative select-none py-2 px-3 cursor-pointer w-80 h-8 pr-10 truncate z-20 ${
                  active ? 'bg-gray-100' : 'bg-white'
                }`
              }
              key={salesforceCampaignMemberField.id}
              value={salesforceCampaignMemberField.id}>
              {salesforceCampaignMemberField.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
