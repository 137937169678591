import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';

const options = [
  {text: 'あり', value: 1},
  {text: 'なし', value: 0},
];

const IsDuplicateFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      allowClear
      value={query.isDuplicate}
      onChange={(value) => setQuery({page: 1, isDuplicate: value})}>
      {options.map((option) => (
        <Select.Option
          value={option.value}
          key={option.text}
          label={option.text}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default IsDuplicateFilter;
