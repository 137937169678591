import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Radio,
} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Step, useUpdateStepMutation} from 'api';
import useClientUser from 'hooks/useClientUser';

interface Props {
  step: Step;
}

export default ({step}: Props) => {
  const {isMember} = useClientUser();
  const [updateStep, {loading}] = useUpdateStepMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: step?.name || '',
      memo: step?.memo || '',
      scheduleType: step?.isImmediately ? 'isImmediately' : 'period',
      startDay: step?.startDay || 1,
      daysOfWeek: step?.daysOfWeek || [1, 2, 3, 4, 5],
      isImmediately: step?.isImmediately || false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      daysOfWeek: Yup.array().when('scheduleType', {
        is: (scheduleType: string) => scheduleType == 'period',
        then: (schema) =>
          schema.ensure().of(Yup.number()).min(1, '必須項目です'),
      }),
    }),
    onSubmit: (values) => {
      if (values.scheduleType === 'isImmediately') {
        values.isImmediately = true;
        values.startDay = null;
        values.daysOfWeek = [];
      } else {
        values.isImmediately = false;
      }
      delete values.scheduleType;
      updateStep({variables: {uuid: step.uuid, attributes: values}});
    },
  });

  return (
    <Container>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <Form.Item
          label="ステップ名"
          validateStatus={formik.errors.name && 'error'}
          help={formik.errors.name}>
          <Input {...formik.getFieldProps('name')} disabled={!isMember} />
        </Form.Item>
        <Form.Item label="実行タイミング">
          <Radio.Group
            {...formik.getFieldProps('scheduleType')}
            disabled={!isMember}>
            <Form.Item style={{marginBottom: '0.5rem'}}>
              <Radio value="period" />
              <InputNumber
                min={1}
                style={{width: '6rem', marginRight: '0.5rem'}}
                value={formik.values.startDay}
                onChange={(value) => formik.setFieldValue('startDay', value)}
                disabled={
                  formik.values.scheduleType === 'isImmediately' || !isMember
                }
              />
              日後に実行
            </Form.Item>
            <Form.Item style={{marginBottom: '0'}}>
              <Radio value="isImmediately" />
              即時実行
            </Form.Item>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="この曜日のみ実行します"
          validateStatus={formik.errors.daysOfWeek && 'error'}
          help={formik.errors.daysOfWeek}>
          <p>※実行タイミングがチェックのある曜日になります</p>
          <Checkbox.Group
            disabled={
              formik.values.scheduleType === 'isImmediately' || !isMember
            }
            options={[
              {label: '月', value: 1},
              {label: '火', value: 2},
              {label: '水', value: 3},
              {label: '木', value: 4},
              {label: '金', value: 5},
              {label: '土', value: 6},
              {label: '日', value: 0},
            ]}
            value={formik.values.daysOfWeek}
            onChange={(values) => formik.setFieldValue('daysOfWeek', values)}
          />
        </Form.Item>
        <Form.Item label="メモ">
          <Input.TextArea
            {...formik.getFieldProps('memo')}
            disabled={!isMember}
          />
        </Form.Item>
        <Actions>
          {isMember && (
            <Button
              type="primary"
              htmlType="submit"
              disabled={!formik.isValid || !formik.dirty}
              loading={loading}>
              保存
            </Button>
          )}
        </Actions>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item-label label {
    font-weight: bold;
  }

  p {
    color: #899098;
  }

  .ant-input-number {
    width: 46px !important;
    height: 35px !important;

    input {
      text-align: center;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
`;
