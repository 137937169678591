import React from 'react';
import Modal from './Modal';
import {AccountGroup, useCreateAccountGroupStageUpdateMutation} from 'api';
import {prospectStages} from 'helpers/prospectStages';

interface Props {
  visible: boolean;
  onClose: () => void;
  accountGroup: AccountGroup;
}

const SelectModal = ({visible, onClose, accountGroup}: Props) => {
  const [stage, setStage] = React.useState('');
  const [createAccountGroupStageUpdate, {loading}] =
    useCreateAccountGroupStageUpdateMutation({
      onCompleted: () => onClose(),
      refetchQueries: ['accountGroup'],
    });

  React.useEffect(() => {
    if (!visible) {
      setStage('');
    }
  }, [visible]);

  return (
    <Modal title="リードステージ一括変更" visible={visible} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <div className="flex">
          <div className="bg-c-bg h-8 flex items-center px-4 gap-2 rounded">
            <span className="text-sm">対象企業数:</span>
            <span className="text-base font-bold">
              {accountGroup?.accountCount?.toLocaleString()}
            </span>
          </div>
        </div>
        <select
          className="w-full form-select border-c-border rounded-sm focus:outline-none text-sm"
          value={stage}
          onChange={(e) => setStage(e.target.value)}>
          <option value="" />
          {prospectStages.map((stage) => (
            <option key={stage.name} value={stage.name}>
              {stage.displayName}
            </option>
          ))}
        </select>
        <div className="flex justify-end">
          <button
            disabled={!stage || loading}
            type="button"
            onClick={() =>
              createAccountGroupStageUpdate({
                variables: {uuid: accountGroup.uuid, stage},
              })
            }
            className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 w-32 disabled:bg-c-bg disabled:text-c-light">
            一括変更
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectModal;
