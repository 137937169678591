import React from 'react';
import styled from 'styled-components';
import {useWorkflowQuery, useUpdateStepPositionMutation} from 'api';
import AutomaticMailStepComponent from './Step/AutomaticMail';
import ManualMailStepComponent from './Step/ManualMail';
import CallStepComponent from './Step/Call';
import TaskStepComponent from './Step/Task';
import {Container, Draggable, DropResult} from 'react-smooth-dnd';
import {useParams} from 'react-router-dom';
import {Handle} from 'components/Ui/Icon';
import useClientUser from 'hooks/useClientUser';

const stepComponents: {[key: string]: any} = {
  'Step::AutomaticMail': AutomaticMailStepComponent,
  'Step::ManualMail': ManualMailStepComponent,
  'Step::Call': CallStepComponent,
  'Step::Task': TaskStepComponent,
};

export default () => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{workflowId: string}>();

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
  });

  const [steps, setSteps] = React.useState(workflow.steps);
  const [updateStepPosition] = useUpdateStepPositionMutation();

  const fixedSteps =
    workflow.status !== 'draft'
      ? steps
      : steps.filter((step) => step.position === 0);

  const draggableSteps =
    workflow.status === 'draft'
      ? steps.filter((step) => step.position !== 0)
      : [];

  const onDragEnd = (result: DropResult) => {
    const sourceIndex = result.removedIndex + fixedSteps.length;
    const destIndex = result.addedIndex + fixedSteps.length;

    const items = [...steps];
    const [removed] = items.splice(sourceIndex, 1);
    items.splice(destIndex, 0, removed);

    setSteps(items);
    updateStepPosition({
      variables: {id: removed.id, position: destIndex},
    });
  };

  React.useEffect(() => {
    setSteps(workflow.steps);
  }, [workflow]);

  return (
    <div className="flex flex-col">
      {fixedSteps.map((step, index) => {
        const StepComponent = stepComponents[step.steppableType];

        return (
          <div
            key={step.id}
            className="h-20 flex items-center gap-4 w-full mb-4">
            <span className="text-c-light font-bold">{index + 1}</span>
            <div className="h-20 flex items-center justify-between w-full border border-c-border rounded">
              <span className="opacity-0 flex items-center justify-center">
                {isMember ? <Handle /> : <div className="ml-4"></div>}
              </span>
              <StepComponent step={step} key={step.uuid} />
            </div>
          </div>
        );
      })}
      <Container onDrop={onDragEnd} dragHandleSelector=".stepListHandle">
        {draggableSteps.map((step, index) => {
          const StepComponent = stepComponents[step.steppableType];

          return (
            <Draggable key={step.id}>
              <div className="h-20 flex items-center gap-4 w-full mb-4">
                <span className="text-c-light font-bold">
                  {index + 1 + fixedSteps.length}
                </span>
                <div className="h-20 flex items-center justify-between w-full border border-c-border rounded">
                  <span className="flex items-center justify-center cursor-move stepListHandle">
                    {isMember ? <Handle /> : <div className="ml-4"></div>}
                  </span>
                  <StepComponent step={step} key={step.uuid} />
                </div>
              </div>
            </Draggable>
          );
        })}
      </Container>
    </div>
  );
};
