import React from 'react';
import {useParams} from 'react-router-dom';
import {PreleadProject} from 'api';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import useProjectPath from 'hooks/useProjectPath';

interface Props {
  row: any;
  value: any;
}

const NameCell = ({row, value}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const {projectId} = useParams<{
    projectId: string;
  }>();
  const {createPath} = useProjectPath();

  return (
    <CompanyName
      to={createPath(
        `projects/${projectId}/preleads/${preleadProject.uuid}/timeline${location.search}`,
      )}>
      {preleadProject.prelead?.companyStatus === 'closed' && (
        <span>【廃業】</span>
      )}
      {value}
    </CompanyName>
  );
};

export default NameCell;

const CompanyName = styled(Link)`
  flex: 1;

  > span {
    color: #ff0000;
  }
`;
