import React, {useState} from 'react';
import AddTelModal from './AddTelModal';
import {Plus} from 'components/Ui/Icon';

export default () => {
  const [addTelModalVisible, setAddTelModalVisible] = useState(false);

  return (
    <>
      <div
        className="w-5 h-5 rounded cursor-pointer invisible group-hover:visible hover:bg-[#D6EBFF] flex items-center justify-center"
        onClick={() => setAddTelModalVisible(true)}>
        <Plus className="h-4" color="#68B5FB" />
      </div>
      <AddTelModal
        visible={addTelModalVisible}
        onClose={() => setAddTelModalVisible(false)}
      />
    </>
  );
};
