import React from 'react';

const Icon = ({color = '#BDC4CB'}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33335 17.5H16.6667C17.5872 17.5 18.3334 16.7538 18.3334 15.8333V6.66667C18.3334 5.74619 17.5872 5 16.6667 5H9.16669L7.74741 2.87108C7.59285 2.63925 7.33266 2.5 7.05404 2.5H3.33335C2.41288 2.5 1.66669 3.24619 1.66669 4.16667V15.8333C1.66669 16.7538 2.41288 17.5 3.33335 17.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
