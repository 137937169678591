import gql from 'graphql-tag';

export default gql`
  fragment pageDownloadFragment on PageDownload {
    id
    uuid
    userId
    websiteId
    eventId
    file
    filename
    status
    count
  }
`;
