import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Action from './action';
import usePools from 'hooks/usePools';

export default () => {
  const {defaultPool} = usePools();

  return (
    <Switch>
      <Route
        path={`/pools/${defaultPool?.uuid}/prospect_report/action`}
        component={Action}
      />
    </Switch>
  );
};
