import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import {DotMenuSide} from 'components/Ui/Icon';
import {approachTypeMenu} from './index';

interface Props {
  approachTypeMenus: approachTypeMenu[];
}

export default ({approachTypeMenus}: Props) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex w-full justify-center bg-white px-2 py-1 text-sm focus:outline-none cursor-pointer">
        <DotMenuSide />
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-1 w-40 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          {approachTypeMenus.map((item) => (
            <Menu.Item key={item.key}>
              <button
                className="group flex w-full items-center py-2 px-2 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-4 &>svg]:mr-2"
                onClick={item.action}>
                {item.icon} {item.label}
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
