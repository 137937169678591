import React from 'react';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router-dom';
import {Modal} from 'components/antd';
import {Step} from 'api';
import StepForm from './StepForm';

interface Props {
  step: Step;
  visible: boolean;
}

const CallModalComponent = ({step, visible}: Props) => {
  const [formType, setFormType] = React.useState('step');
  const {poolId, workflowId} =
    useParams<{poolId: string; workflowId: string}>();
  const history = useHistory();
  const onClose = React.useCallback(
    () =>
      history.push(
        `/pools/${poolId}/workflows/${workflowId}${location.search}`,
      ),
    [poolId, workflowId],
  );

  return (
    <CallModal
      title={step?.name}
      visible={visible}
      onCancel={onClose}
      width={680}
      footer={null}
      maskClosable={false}>
      <Tabs>
        <TabItem
          active={formType === 'step'}
          onClick={() => setFormType('step')}>
          ステップ
        </TabItem>
      </Tabs>
      <Content>{formType === 'step' && <StepForm step={step} />}</Content>
    </CallModal>
  );
};

const CallModal = styled(Modal)`
  h3 {
    font-weight: bold;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: solid 1px #e1e6eb;
  padding: 12px 24px 0;
`;

const TabItem = styled.div<{active?: boolean}>`
  text-align: center;
  border-bottom: solid 3px;
  border-color: ${({active}) => (active ? '#495058' : 'transparent')};
  padding: 0;
  margin-right: 2rem;
  cursor: pointer;

  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
  color: ${({active}) => (active ? '#495058' : '#BDC4CB')};
  display: flex;
  width: 64px;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  padding: 24px;
`;

export default CallModalComponent;
