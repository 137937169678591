import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49984 9.16667C9.34079 9.16667 10.8332 7.67428 10.8332 5.83333C10.8332 3.99238 9.34079 2.5 7.49984 2.5C5.65889 2.5 4.1665 3.99238 4.1665 5.83333C4.1665 7.67428 5.65889 9.16667 7.49984 9.16667Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 17.5V14.1667C1.6665 13.2462 2.4127 12.5 3.33317 12.5H11.6665C12.587 12.5 13.3332 13.2462 13.3332 14.1667V17.5"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3335 2.5C14.0505 2.68358 14.686 3.10058 15.1398 3.68526C15.5937 4.26993 15.84 4.98902 15.84 5.72917C15.84 6.46931 15.5937 7.1884 15.1398 7.77307C14.686 8.35775 14.0505 8.77475 13.3335 8.95833"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8335 12.5H16.6668C17.5873 12.5 18.3335 13.2462 18.3335 14.1667V17.5"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
