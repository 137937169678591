import React from 'react';
import {useParams} from 'react-router-dom';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {useWorkflowsByStatusQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();

  const {data: {workflowsByStatus = []} = {}} = useWorkflowsByStatusQuery({
    variables: {poolUuid: poolId, status: ['open', 'closed']},
    fetchPolicy: 'cache-and-network',
    skip: !poolId,
  });

  if (
    !query.isTargetAllWorkflows &&
    (!query?.workflowIds || !query?.workflowIds.length)
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">ワークフロー</h4>
        <button
          onClick={() => setFilterType('workflow')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              workflowIds: [],
              workflowMailOpenedFromDate: '',
              workflowMailOpenedToDate: '',
              workflowMailClickedFromDate: '',
              workflowMailClickedToDate: '',
              isTargetAllWorkflows: false,
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap items-center">
          {workflowsByStatus
            .filter((workflow) => query.workflowIds.includes(workflow.id))
            .map((workflow) => (
              <span
                key={workflow.id}
                className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {workflow.name}
              </span>
            ))}
        </div>
      </div>
      <div>
        {(query.workflowMailOpenedFromDate ||
          query.workflowMailOpenedToDate) && (
          <>
            <span className="text-c-light">開封日：</span>
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {`${query.workflowMailOpenedFromDate} ~ ${query.workflowMailOpenedToDate}`}
            </span>
          </>
        )}
      </div>
      <div>
        {(query.workflowMailClickedFromDate ||
          query.workflowMailClickedToDate) && (
          <>
            <span className="text-c-light">クリック日：</span>
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {`${query.workflowMailClickedFromDate} ~ ${query.workflowMailClickedToDate}`}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
