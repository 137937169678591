import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import {useUserReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();

  const {data: {userReports = []} = {}} = useUserReportsQuery({});

  const userNames = userReports
    .filter((report) => !!report.values[0])
    .map((report) => report.name);

  const userValues = userReports
    .filter((report) => !!report.values[0])
    .map((report) => report.values)
    .flat();

  return {
    axis: userNames,
    data: [
      {
        label: 'リード数',
        color: getColor(5),
        values: userValues,
      },
    ],
  };
};

export default useChartData;
