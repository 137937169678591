import gql from 'graphql-tag';

export default gql`
  fragment cityFragment on City {
    id
    code
    name
    nameKana
    prefectureId
    prefectureTypeId
  }
`;
