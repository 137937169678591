import React from 'react';
import {Task} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;
  const {user} = task;

  return (
    <div className='text-sm truncate'>
        {user?.lastName} {user?.firstName}
    </div>
  );
};

export default Cell;
