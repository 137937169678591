import React from 'react';
import {useRecoilState} from 'recoil';
import selectCompanyPreleadProjectAtom from '../selectCompanyPreleadProjectAtom';
import {Tooltip} from 'components/antd';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const NayoseCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const [, setSelectCompanyPreleadProject] = useRecoilState(
    selectCompanyPreleadProjectAtom,
  );

  if (!preleadProject.prelead.companyId && !preleadProject.isMultiple) {
    return null;
  }

  return (
    <Tooltip
      placement="right"
      title={() =>
        preleadProject.isMultiple ? (
          <span
            style={{textDecoration: 'underline', cursor: 'pointer'}}
            onClick={() => setSelectCompanyPreleadProject(preleadProject.uuid)}>
            名寄せを行った結果、複数の候補が存在しています
          </span>
        ) : (
          '名寄せが完了しています'
        )
      }>
      <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 14.667A6.667 6.667 0 108 1.334a6.667 6.667 0 000 13.333z"
          fill={preleadProject.isMultiple ? '#FF8F00' : '#4FCE82'}
          stroke={preleadProject.isMultiple ? '#FF8F00' : '#4FCE82'}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.068 8l1.934 1.933L10.935 6"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Tooltip>
  );
};

export default NayoseCell;
