import React from 'react';
import moment from 'moment';
import {Prelead} from 'api';

interface Props {
  row: any;
}

export default ({row}: Props) => {
  const prelead: Prelead = row.original;

  return (
    <span>
      {prelead.clickUpdatedAt &&
        moment(prelead.clickUpdatedAt).format('YYYY年MM月DD日 HH時mm分')}
    </span>
  );
};
