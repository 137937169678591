import React from 'react';
import {
  useUpdateSalesforceLeadCheckboxFieldMutation,
  SalesforceLeadField,
  SalesforceLead,
} from 'api';

interface Props {
  field: SalesforceLeadField;
  salesforceLead: SalesforceLead;
}

const TextArea = ({field, salesforceLead}: Props) => {
  const value = salesforceLead.addition[`${field.fieldName}`];
  const [updateSalesforceLeadCheckboxField] =
    useUpdateSalesforceLeadCheckboxFieldMutation();

  return (
    <input
      className="cursor-pointer"
      type="checkbox"
      name={field.label}
      checked={value}
      disabled={!field.fieldName.endsWith('__c')}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        updateSalesforceLeadCheckboxField({
          variables: {
            id: salesforceLead.id,
            attributes: {
              fieldName: field?.fieldName,
              value: e.target.checked,
            },
          },
        });
      }}
    />
  );
};

export default TextArea;
