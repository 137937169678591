import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const ExistTelActivityFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="コールログ登録有無" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2 h-full">
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio h-5 w-5 border-c-border rounded-full text-c-primary"
              checked={query.isExistTelActivity === false}
              onChange={() => setQuery({...query, isExistTelActivity: false})}
            />
            <span className="ml-2">ログ登録なし</span>
          </label>
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio h-5 w-5 border-c-border rounded-full text-c-primary"
              checked={query.isExistTelActivity === true}
              onChange={() => setQuery({...query, isExistTelActivity: true})}
            />
            <span className="ml-2">ログ登録あり</span>
          </label>
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, isExistTelActivity: null})} />
    </>
  );
};

export default ExistTelActivityFilter;
