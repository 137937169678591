import React, {useMemo, useCallback} from 'react';
import styled from 'styled-components';
import {Form, Checkbox} from 'components/antd';
import {useFormik} from 'formik';
import {InputNumber} from 'components';
import useSearchCondition from '../useSearchCondition';
import _ from 'lodash';
import employeeRangeOptions from 'helpers/employeeRangeOptions';

export default () => {
  const {query, setQuery} = useSearchCondition();
  const {
    employeeRanges = [],
    capitalFundFrom = null,
    capitalFundTo = null,
    officesNumberFrom = null,
    officesNumberTo = null,
  } = query;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      employeeRanges,
      capitalFundFrom,
      capitalFundTo,
      officesNumberFrom,
      officesNumberTo,
    },
    onSubmit: (values) => {
      setQuery({...values, page: 1});
    },
  });

  const setEmployeeRange = useCallback(
    (e) => {
      const checked = e.target.checked;
      if (checked) {
        formik.setFieldValue('employeeRanges', [
          ...formik.values.employeeRanges,
          e.target.value,
        ]);
      } else {
        formik.setFieldValue(
          'employeeRanges',
          _.difference(formik.values.employeeRanges, [e.target.value]),
        );
      }
      formik.handleSubmit();
    },
    [formik.values.employeeRanges],
  );

  const filteredEmployeeRangeOptions = useMemo(
    () =>
      employeeRangeOptions.filter(
        (employeeRangeOption) => employeeRangeOption.value !== '0to100',
      ),
    [],
  );

  return (
    <FilterContainer>
      <StyledForm onFinish={formik.handleSubmit}>
        <Form.Item>
          <label>従業員数</label>
          <div className="wrapper">
            <div className="employeeWrapper">
              {filteredEmployeeRangeOptions.map((employeeRangeOption) => (
                <Checkbox
                  key={employeeRangeOption.value}
                  className="employeeRange"
                  value={employeeRangeOption.value}
                  checked={formik.values.employeeRanges.includes(
                    employeeRangeOption.value,
                  )}
                  onChange={setEmployeeRange}>
                  {employeeRangeOption.label}
                </Checkbox>
              ))}
            </div>
          </div>
        </Form.Item>
        <Form.Item>
          <label>事業所数</label>
          <div className="wrapper">
            <StyledInputNumber
              min={1}
              max={10000}
              name="officesNumberFrom"
              value={formik.values.officesNumberFrom}
              onChange={(value) =>
                formik.setFieldValue('officesNumberFrom', value)
              }
              onBlur={() => formik.handleSubmit()}
            />
            <span className="between strong">―</span>
            <StyledInputNumber
              min={1}
              max={10000}
              name="officesNumberTo"
              value={formik.values.officesNumberTo}
              onChange={(value) =>
                formik.setFieldValue('officesNumberTo', value)
              }
              onBlur={() => formik.handleSubmit()}
            />
          </div>
        </Form.Item>

        <Form.Item>
          <label>資本金</label>
          <div className="wrapper">
            <StyledInputNumber
              name="capitalFundFrom"
              min={1}
              max={100000000}
              value={formik.values.capitalFundFrom}
              onChange={(value) =>
                formik.setFieldValue('capitalFundFrom', value)
              }
              onBlur={() => formik.handleSubmit()}
            />
            <span className="unit">万</span>
            <span className="between">―</span>
            <StyledInputNumber
              name="capitalFundTo"
              min={1}
              max={100000000}
              value={formik.values.capitalFundTo}
              onChange={(value) => formik.setFieldValue('capitalFundTo', value)}
              onBlur={() => formik.handleSubmit()}
            />
            <span className="unit">万</span>
          </div>
        </Form.Item>
      </StyledForm>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled(Form)`
  width: 412px;
  padding: 1rem;
  border-right: solid 1px #e7eaec;
  label {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #899098;
    margin-bottom: 10px;
  }
  .wrapper {
    margin-left: 10px;
    margin-bottom: 10px;

    .unit {
      margin-left: 5px;
    }

    .between {
      margin: 0 5px;
      &.strong {
        margin: 0 13px;
      }
    }

    .employeeWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 400px;

      label {
        display: flex;
        width: 123px;
        margin-left: 0px;
      }
    }
  }
  .ant-input-number,
  input {
    width: 137px;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #222426;
  }

  .employeeRange {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 0px;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  border: 1px solid rgb(217, 217, 217) !important;
`;
