import {usePoolsQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';

const usePools = () => {
  const {poolId: poolUuid} = useParams<{poolId: string}>();
  const {data: {pools = []} = {}} = usePoolsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const defaultPool = React.useMemo(
    () => pools.find((pool) => pool.poolType === 'default'),
    [pools],
  );

  const selectedPool =
    pools.find((pool) => pool.uuid === poolUuid) || defaultPool;

  return {
    defaultPool,
    selectedPool,
    pools,
  };
};

export default usePools;
