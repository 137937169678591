import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';

const EstablishedYearMonthFilter = () => {
  const {query, setQuery} = useSearchParams();
  const format = 'YYYY-MM';

  return (
    <>
      <DatePicker
        placeholder="開始日"
        picker="month"
        value={
          query.establishedYearMonthFrom &&
          moment(query.establishedYearMonthFrom)
        }
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, establishedYearMonthFrom: value?.format(format)});
        }}
        className="w-36"
      />
      <span className="mx-1 mt-2">~</span>
      <DatePicker
        placeholder="終了日"
        picker="month"
        value={
          query.establishedYearMonthTo && moment(query.establishedYearMonthTo)
        }
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, establishedYearMonthTo: value?.format(format)});
        }}
        className="w-36"
      />
    </>
  );
};

export default EstablishedYearMonthFilter;
