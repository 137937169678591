import React from 'react';
import {MailCampaignBlock, MailCampaignBlockImage} from 'api';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockImage;
}

interface Props {
  block: Block;
}

const PlaceHolder = () => (
  <div
    style={{
      width: '100%',
      height: '160px',
      backgroundColor: '#F3F5F7',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 52.5L40 25L52.5 37.5"
        stroke="#BDC4CB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.5 7.5H12.5C9.73858 7.5 7.5 9.73858 7.5 12.5V47.5C7.5 50.2614 9.73858 52.5 12.5 52.5H47.5C50.2614 52.5 52.5 50.2614 52.5 47.5V12.5C52.5 9.73858 50.2614 7.5 47.5 7.5Z"
        stroke="#BDC4CB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 25C23.3211 25 25 23.3211 25 21.25C25 19.1789 23.3211 17.5 21.25 17.5C19.1789 17.5 17.5 19.1789 17.5 21.25C17.5 23.3211 19.1789 25 21.25 25Z"
        stroke="#BDC4CB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default ({block}: Props) => {
  const {image, imageScale, imageAlt} = block.blockable;

  const containerStyle = {
    backgroundColor: block.blockable?.bodyColor,
    paddingTop: block.blockable?.paddingTop || 0,
    paddingBottom: block.blockable?.paddingBottom || 0,
    paddingLeft: block.blockable?.paddingLeft || 0,
    paddingRight: block.blockable?.paddingRight || 0,
    display: 'flex',
    justifyContent: 'center',
  };

  const imageStyle = {
    width: `${imageScale * 100}%`,
    innerHeight: 'auto',
    maxWidth: '100%',
  };

  return (
    <div style={containerStyle}>
      {image ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img
            src={image}
            alt={imageAlt}
            style={imageStyle}
            draggable="false"
          />
        </div>
      ) : (
        <PlaceHolder />
      )}
    </div>
  );
};
