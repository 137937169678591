import Status from './Status';
import Title from './Title';
import Priority from './Priority';
import Assignee from './Assignee';
import Description from './Description';
import DueDate from './DueDate';
import TaskType from './TaskType';
import Target from './Target';

export default {
  Status,
  Title,
  Priority,
  Assignee,
  Description,
  DueDate,
  TaskType,
  Target,
};
