import React from 'react';

const Icon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.08333 29.75L26.9167 29.75C28.4815 29.75 29.75 28.4815 29.75 26.9167L29.75 22.8333C29.75 21.2685 28.4815 20 26.9167 20L7.08333 20C5.51853 20 4.25 21.2685 4.25 22.8333L4.25 26.9167C4.25 28.4815 5.51853 29.75 7.08333 29.75Z"
      stroke="#899098"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="28.75"
      y1="15.25"
      x2="5.25"
      y2="15.25"
      stroke="#899098"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <line
      x1="28.75"
      y1="10.25"
      x2="5.25"
      y2="10.25"
      stroke="#899098"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <line
      x1="28.75"
      y1="5.25"
      x2="5.25"
      y2="5.25"
      stroke="#899098"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default Icon;
