import React from 'react';
import {
  PreleadCustomizeItemText,
  ClientPreleadCustomizeItem,
  Prelead,
  useUpdatePreleadCustomizeItemTextMutation,
} from 'api';
import {Input as UIInput} from 'components/antd';
import styled from 'styled-components';

interface Props {
  prelead: Prelead;
  clientPreleadCustomizeItem: ClientPreleadCustomizeItem;
}

export default ({prelead, clientPreleadCustomizeItem}: Props) => {
  const customizeItem = prelead.customizeItemTexts.find(
    (item: PreleadCustomizeItemText) =>
      item.clientPreleadCustomizeItemId === clientPreleadCustomizeItem.id,
  );

  const [value, setValue] = React.useState(
    customizeItem ? customizeItem.value : '',
  );

  React.useEffect(() => {
    setValue(customizeItem ? customizeItem.value : '');
  }, [customizeItem]);

  const [updateCustomizeItem] = useUpdatePreleadCustomizeItemTextMutation();

  const update = (e: any) => {
    updateCustomizeItem({
      variables: {
        uuid: prelead.uuid,
        clientPreleadCustomizeItemId: clientPreleadCustomizeItem.id,
        value: e.target.value,
      },
    });
  };

  return (
    <Input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={update}
      onPressEnter={update}
      placeholder="未入力"
    />
  );
};

const Input = styled(UIInput)`
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
