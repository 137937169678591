import React from 'react';
import {ProspectPool} from 'api';
import Tab from './Tab';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  return (
    <div className="flex h-16 gap-6 border-b border-c-border bg-white px-8 pt-2">
      <Tab
        label="商談"
        count={prospectPool.salesforceDeals.length}
        to="/deals"
      />
      {prospectPool?.salesforceContact?.salesforceAccount && (
        <Tab label="取引先" to="/account" />
      )}
      {prospectPool?.salesforceContact && (
        <Tab label="取引先責任者" to="/contact" />
      )}
      {prospectPool?.salesforceLead && <Tab label="リード" to="/lead" />}
    </div>
  );
};
