import React from 'react';
import {Link, LinkProps, useLocation} from 'react-router-dom';

export const Tab: React.FC<LinkProps> = ({children, to, ...restProps}) => {
  const location = useLocation();
  const active = location.pathname.startsWith(to.toString());

  const queryString = () => {
    const searchParams = new URLSearchParams(location.search);
    const timeScale = searchParams.get('timeScale');
    if (timeScale) {
      searchParams.set('timeScale', 'day');
    }
    return searchParams.toString();
  };

  return (
    <Link
      to={`${to}?${queryString()}`}
      {...restProps}
      className={`flex h-14 items-center gap-1 border-b-[3px] bg-transparent ${
        active
          ? 'text-c-base border-[#495058]'
          : 'text-[#BDC4CB] border-transparent'
      }`}>
      {children}
    </Link>
  );
};
