import {useEffect, useMemo} from 'react';
import {FormikProps} from 'formik';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {
  Content,
  ContentMailTemplateAttributes,
  useUpdateContentMailTemplateMutation,
} from 'api';
import useDebounce from 'hooks/useDebounce';

export default (
  content: Content,
  formik: FormikProps<ContentMailTemplateAttributes>,
) => {
  const {id} = useParams<{id: string}>();
  const [update] = useUpdateContentMailTemplateMutation();

  const debouncedValue = useDebounce<ContentMailTemplateAttributes>(
    formik.values,
    1000,
  );

  useEffect(() => {
    update({
      variables: {id, attributes: debouncedValue},
    });
  }, [debouncedValue]);

  const saving = useMemo(
    () =>
      !_.isEqual(
        {
          title: content.mailTemplate?.title,
          body: content.mailTemplate?.body,
          bodyHtml: content.mailTemplate?.bodyHtml,
          isShare: content.mailTemplate?.isShare,
        },
        formik.values,
      ),
    [
      content.mailTemplate?.title,
      content.mailTemplate?.body,
      content.mailTemplate?.bodyHtml,
      content.mailTemplate?.isShare,
      formik.values,
    ],
  );

  return {saving};
};
