import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import {regions} from 'helpers/regions';
import {usePrefectureTypesQuery, usePrefectureTypesByCityIdsQuery} from 'api';
import {CheckIcon} from '@heroicons/react/20/solid';

const CityFilter = () => {
  const {query, setQuery} = useSearchParams();

  const [currentRegion, setCurrentRegion] = React.useState(regions[0]);

  const {data: {prefectureTypes = []} = {}} = usePrefectureTypesQuery({
    variables: {search: {prefectureTypeNames: currentRegion?.prefectureTypes}},
    fetchPolicy: 'cache-and-network',
  });

  const {data: {prefectureTypesByCityIds = []} = {}} =
    usePrefectureTypesByCityIdsQuery({
      variables: {
        cityIds: query.cityIds,
      },
    });

  const selectedRegionTypes = regions
    .filter((region) =>
      region.prefectureTypes.some((prefectureType) =>
        prefectureTypesByCityIds
          .map((prefectureType) => prefectureType.name)
          .includes(prefectureType),
      ),
    )
    .map((region) => region.regionType);

  return (
    <>
      <Header title="エリア" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-4 rounded flex gap-2 flex-1">
          <div className="w-44 overflow-y-scroll overflow-x-hidden relative py-2">
            <div className="w-44 absolute">
              {regions.map((region) => (
                <div
                  key={region.regionType}
                  onClick={() => setCurrentRegion(region)}
                  className={`cursor-pointer w-full h-8 flex items-center text-base px-4 ${
                    region.regionType === currentRegion.regionType
                      ? 'bg-c-bg'
                      : 'bg-transparent'
                  }`}>
                  <span className="flex-1">{region.name}</span>
                  {selectedRegionTypes.includes(region.regionType) && (
                    <CheckIcon className="w-4 mx-2" />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 overflow-y-scroll overflow-x-hidden relative py-2">
            <div className="absolute w-full">
              {currentRegion.prefectureTypes?.map(
                (prefectureTypeName: any) =>
                  prefectureTypes?.map(
                    (prefectureType) =>
                      prefectureTypeName === prefectureType.name && (
                        <div
                          key={prefectureType.name}
                          className="flex flex-col mb-8">
                          <label
                            key={prefectureType.name}
                            className="flex items-center text-base w-full px-2">
                            <input
                              type="checkbox"
                              value={prefectureType.name}
                              className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light"
                              checked={prefectureType.cities.every((city) =>
                                query.cityIds.includes(city.id),
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setQuery({
                                    ...query,
                                    cityIds: [
                                      ...query.cityIds,
                                      ...prefectureType.cities.map(
                                        (city) => city.id,
                                      ),
                                    ],
                                  });
                                } else {
                                  setQuery({
                                    ...query,
                                    cityIds: query.cityIds.filter(
                                      (item) =>
                                        !prefectureType.cities
                                          .map((city) => city.id)
                                          .includes(item),
                                    ),
                                  });
                                }
                              }}
                            />
                            <span className="ml-2">
                              {prefectureType.name}すべて
                            </span>
                          </label>
                          <div className="flex flex-wrap my-4 pt-4 border-t border-c-border">
                            {prefectureType.cities.map((city) => (
                              <label
                                key={city.id}
                                className="flex items-center text-base w-40 px-2">
                                <input
                                  type="checkbox"
                                  value={city.id}
                                  className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light"
                                  checked={query.cityIds.includes(city.id)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setQuery({
                                        ...query,
                                        cityIds: [...query.cityIds, city.id],
                                      });
                                    } else {
                                      setQuery({
                                        ...query,
                                        cityIds: query.cityIds.filter(
                                          (c) => c !== city.id,
                                        ),
                                      });
                                    }
                                  }}
                                />
                                <span className="ml-2">{city.name}</span>
                              </label>
                            ))}
                          </div>
                        </div>
                      ),
                  ),
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, cityIds: []})} />
    </>
  );
};

export default CityFilter;
