import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Row} from 'react-table';
import {MailCampaign} from 'api';
import moment from 'moment';

interface Props {
  row: Row<MailCampaign>;
}

const Cell = ({row}: Props) => {
  const mailCampaign = row.original;
  return (
    <Container>
      <Link to={`/mail_campaigns/${mailCampaign.uuid}`}>
        {mailCampaign.title}
      </Link>
      <BadgeWrapper>
        <UpdatedAt>
          最終更新 {moment(mailCampaign.updatedAt).format('YYYY/MM/DD')}
        </UpdatedAt>
      </BadgeWrapper>
    </Container>
  );
};

const BadgeWrapper = styled.div`
  display: flex;
  gap: 7px;
`;

const UpdatedAt = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #899098;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    font-size: 12px;
    color: #899098;
  }
`;

export default Cell;
