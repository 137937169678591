import gql from 'graphql-tag';

export default gql`
  fragment maintenanceProspectPoolFragment on MaintenanceProspectPool {
    id
    merge
    isTarget
    canMerge
  }
`;
