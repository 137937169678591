import React from 'react';
import {HubspotContact} from 'api';

interface Props {
  hubspotContact: HubspotContact;
}

export default ({hubspotContact}: Props) => (
  <>
    {hubspotContact.hubspotOwner?.lastName}{' '}
    {hubspotContact.hubspotOwner?.firstName}
  </>
);
