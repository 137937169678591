import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import useColor from '../../hooks/useColor';
import {ReportChart} from './types';

const barWidth = {
  thin: 18,
  normal: 36,
};

interface Props extends React.ComponentProps<'div'> {
  chart: ReportChart;
  legend?: boolean;
  barProps?: {
    barSize?: 'thin' | 'normal';
  };
}

export const Chart: React.FC<Props> = ({chart, legend, barProps, ...props}) => {
  const {getColor} = useColor();
  const data = React.useMemo(() => {
    const transformedData: any[] = [];

    chart.axis.forEach((axisValue, index) => {
      const entry: any = {name: axisValue};

      chart.data.forEach((dataItem) => {
        entry[dataItem.label] = dataItem.values[index];
      });

      transformedData.push(entry);
    });

    return transformedData;
  }, [chart]);

  const bars = React.useMemo(
    () =>
      chart.data
        ? chart.data.map((d, i) => ({
            dataKey: d.label,
            stackId: d.groupId,
            fill: d.color || getColor(i),
          }))
        : [],
    [chart.data],
  );

  return (
    <div {...props}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 32,
            right: 32,
            left: 0,
            bottom: 16,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" interval={0} />
          <YAxis allowDecimals={false} />
          <Tooltip wrapperStyle={{zIndex: 1000}} />
          {legend === false ? null : <Legend />}
          {bars.map((b, i) => (
            <Bar
              key={i}
              dataKey={b.dataKey}
              stackId={b.stackId}
              fill={b.fill}
              barSize={barWidth[barProps?.barSize ?? 'normal']}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
