import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {BounceMail, Cross, OptoutMail} from 'components/Ui/Icon';
import {Select, Input} from 'components/antd';
import {
  useCurrentClientQuery,
  usePreleadQuery,
  useUpdatePreleadMutation,
  useUpdatePreleadStatusOfPreleadMutation,
} from 'api';
import preleadCategoryColor from 'helpers/preleadCategoryColor';
import usePreleadContext from '../usePreleadContext';
import Tel from './Tel';
import Copy from './Copy';
import Menu from './Menu';
import useClientUser from 'hooks/useClientUser';
import SearchLink from 'components/Prelead/Header/SearchLink';

interface Props {
  onClose: () => void;
}

export default ({onClose}: Props) => {
  const {isMember} = useClientUser();
  const {preleadId} = usePreleadContext();
  if (!preleadId) return null;

  const escFunction = useCallback((event: any) => {
    if (event.keyCode === 27) {
      onClose();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const {data: {currentClient: {preleadStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const [updatePrelead] = useUpdatePreleadMutation();
  const [updatePreleadStatus] = useUpdatePreleadStatusOfPreleadMutation();
  const updateName = React.useCallback(
    (e: any) => {
      if (e.target.value) {
        updatePrelead({
          variables: {uuid: prelead?.uuid, attributes: {name: e.target.value}},
        });
      } else {
        alert('プレリード名は必須です。');
        setPreleadName(prelead.name);
      }
    },
    [prelead],
  );

  const [preleadName, setPreleadName] = useState(null);

  React.useEffect(() => {
    setPreleadName(prelead?.name);
  }, [prelead]);

  const handleChange = (e: any) => {
    setPreleadName(e.target.value);
  };

  const onChagngeUpdatePreleadStatus = (clientPreleadStatusId: string) => {
    if (!isMember) {
      alert(
        '企業マスターからステータス変更ができるのは、権限がメンバーまたはオーナーのユーザーとなります。',
      );
      return;
    }

    if (
      confirm(
        '営業リストに属している全てのプレリードのステータスが変更されます。プレリードステータスを変更しますか？',
      )
    ) {
      updatePreleadStatus({
        variables: {
          uuid: prelead.uuid,
          clientPreleadStatusId: clientPreleadStatusId,
        },
        refetchQueries: ['prelead'],
      });
    }
  };

  if (!prelead) return null;

  return (
    <>
      <Container>
        <MainSection>
          <div>
            <StatusSelect
              value={prelead.clientPreleadStatusId}
              dropdownMatchSelectWidth={false}
              dropdownClassName="prelead-status-dropdown"
              onChange={onChagngeUpdatePreleadStatus}
              optionLabelProp="label">
              <StatusSelect.Option
                value={null}
                key={'pending'}
                label={
                  <React.Fragment>
                    <StatusMark color={preleadCategoryColor('pending')} />
                  </React.Fragment>
                }>
                <StatusMark color={preleadCategoryColor('pending')} />
                &nbsp; 未
              </StatusSelect.Option>
              {preleadStatuses.map((preleadStatus) => (
                <StatusSelect.Option
                  value={preleadStatus.id}
                  key={preleadStatus.id}
                  label={
                    <React.Fragment>
                      <StatusMark
                        color={preleadCategoryColor(preleadStatus.category)}
                      />
                    </React.Fragment>
                  }>
                  <StatusMark
                    color={preleadCategoryColor(preleadStatus.category)}
                  />
                  &nbsp; {preleadStatus.name}
                </StatusSelect.Option>
              ))}
            </StatusSelect>
            <CompanyName
              key={preleadId}
              value={preleadName}
              onChange={handleChange}
              onBlur={updateName}
              onPressEnter={updateName}
            />
            {prelead.mailAddress && <Menu />}
          </div>

          <div>
            {prelead.isUnsubscribed && (
              <div className="mr-2">
                <Unsubscribed>
                  <OptoutMail />
                  配信停止
                </Unsubscribed>
              </div>
            )}
            {prelead.isBounced && (
              <div className="">
                <Bounced>
                  <BounceMail />
                  バウンス
                </Bounced>
              </div>
            )}
          </div>
        </MainSection>

        <CloseButton onClick={onClose}>
          <Cross />
        </CloseButton>
      </Container>
      <div className="max-h-[206px] overflow-y-scroll">
        <DetailSection>
          <DetailTable>
            <tbody>
              <Tel />
              {prelead.webSite && (
                <tr>
                  <th>URL</th>
                  <td>
                    <a href={prelead.webSite} target="_blank">
                      {prelead.webSite}
                    </a>
                    <Copy copyText={prelead.webSite} />
                  </td>
                </tr>
              )}
              {prelead.mailAddress && (
                <tr>
                  <th>メール</th>
                  <td>
                    {prelead.mailAddress}
                    <Copy copyText={prelead.mailAddress} />
                  </td>
                </tr>
              )}
              {prelead.address && (
                <tr>
                  <th>住所</th>
                  <td>{prelead.address}</td>
                </tr>
              )}
            </tbody>
          </DetailTable>
          <SearchLink prelead={prelead} />
        </DetailSection>
      </div>
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 22px;
`;

const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const CompanyName = styled(Input)`
  width: 460px;
  font-size: 18px;
  line-height: 24px;
  border-color: transparent;

  &:hover {
    border-color: #e2e6ea;
  }
`;

const CloseButton = styled.span`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 8px;

  svg {
    margin: 9px;
  }
`;

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

const StatusMark = styled.span<{color: string}>`
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid #bdc4cb'};
`;

const DetailTable = styled.table`
  th {
    height: 24px;
    text-align: left;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.15px;
    color: #899098;
    max-width: 160px;
  }

  tr {
    height: 24px;
  }

  td {
    height: 24px;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    a {
      max-width: 320px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ant-btn {
    display: flex;
    border: none;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  .copy {
    margin-left: 8px;
    cursor: pointer;
  }
`;

const DetailSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 22px 12px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const Unsubscribed = styled.div`
  color: #eb5757;
  font-size: 12px;
  background: #ffeeed;
  border-radius: 2px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  gap: 3px;

  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #eb5757;
    }
  }
`;

const Bounced = styled.div`
  color: #899098;
  font-size: 12px;
  background: #e1e6eb;
  border-radius: 2px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  gap: 3px;
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #899098;
    }
  }
`;
