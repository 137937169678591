import React from 'react';
import {Task} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;

  return (
    <Container>
      {task.priority === 'high' && <High>{task.priorityText}</High>}
      {task.priority === 'normal' && <Normal>{task.priorityText}</Normal>}
      {task.priority === 'low' && <Low>{task.priorityText}</Low>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Priority = styled.div`
  width: 25px;
  height: 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;

const High = styled(Priority)`
  background-color: #f9d2d2;
  color: #eb5757;
`;

const Normal = styled(Priority)`
  background-color: #fff5d1;
  color: #ffc600;
`;

const Low = styled(Priority)`
  background-color: #d5ebd1;
  color: #56a948;
`;

export default Cell;
