import {ProjectSearchCondition, useWebsiteByWebsiteTypeQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router';
import {
  NumberParam,
  BooleanParam,
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

const PAGE_SIZE = 50;

export const searchConditionParams = {
  page: NumberParam,
  subCategoryIds: DelimitedArrayParam,
  cityIds: DelimitedArrayParam,
  prefectureTypeIds: DelimitedArrayParam,
  employeeRanges: DelimitedArrayParam,
  capitalFundFrom: NumberParam,
  capitalFundTo: NumberParam,
  officesNumberFrom: NumberParam,
  officesNumberTo: NumberParam,
  markets: DelimitedArrayParam,
  companyTypes: DelimitedArrayParam,
  isUrl: BooleanParam,
  isTel: BooleanParam,
  isEmail: BooleanParam,
  isRepresentativePerson: BooleanParam,
  monthPressRelease: StringParam,
  countPressRelease: StringParam,
  salesRanges: DelimitedArrayParam,
  profitRateRanges: DelimitedArrayParam,
  profitUp3: BooleanParam,
  profitDown3: BooleanParam,
  facebook: BooleanParam,
  instagram: BooleanParam,
  youtube: BooleanParam,
  twitter: BooleanParam,
  line: BooleanParam,
  linkedin: BooleanParam,
  websiteId: StringParam,
  websiteIds: DelimitedArrayParam,
  pageKeywords: DelimitedArrayParam,
  pageNegativeKeywords: DelimitedArrayParam,
  accountClosingMonths: DelimitedArrayParam,
  establishedYearMonthFrom: StringParam,
  establishedYearMonthTo: StringParam,
  searchWord: StringParam,
  technologies: DelimitedArrayParam,
};

const useSearchCondition = () => {
  const {websiteType} = useParams<{
    websiteType: string;
  }>();
  const [query, setQuery] = useQueryParams(searchConditionParams);
  const {page} = query;
  const {data: {websiteByWebsiteType: website} = {}} =
    useWebsiteByWebsiteTypeQuery({
      variables: {
        websiteType: websiteType,
      },
      skip: !websiteType,
    });

  const resetQuery = (searchCondition: ProjectSearchCondition = {}) =>
    setQuery({
      subCategoryIds: searchCondition?.subCategoryIds || [],
      cityIds: searchCondition?.cityIds || [],
      prefectureTypeIds: searchCondition?.prefectureTypeIds || [],
      employeeRanges: searchCondition?.employeeRanges || [],
      capitalFundFrom: searchCondition?.capitalFundFrom || null,
      capitalFundTo: searchCondition?.capitalFundTo || null,
      officesNumberFrom: searchCondition?.officesNumberFrom || null,
      officesNumberTo: searchCondition?.officesNumberTo || null,
      markets: searchCondition?.markets || [],
      companyTypes: searchCondition?.companyTypes || [],
      isUrl: searchCondition?.isUrl || false,
      isTel: searchCondition?.isTel || false,
      isEmail: searchCondition?.isEmail || false,
      isRepresentativePerson: searchCondition?.isRepresentativePerson || false,
      monthPressRelease: searchCondition?.monthPressRelease || '',
      countPressRelease: searchCondition?.countPressRelease || '',
      salesRanges: searchCondition?.salesRanges || [],
      profitRateRanges: searchCondition?.profitRateRanges || [],
      profitUp3: searchCondition?.profitUp3 || false,
      profitDown3: searchCondition?.profitDown3 || false,
      facebook: searchCondition?.facebook || false,
      instagram: searchCondition?.instagram || false,
      youtube: searchCondition?.youtube || false,
      twitter: searchCondition?.twitter || false,
      line: searchCondition?.line || false,
      linkedin: searchCondition?.linkedin || false,
      websiteId: searchCondition?.websiteId || null,
      websiteIds: searchCondition?.websiteIds || [],
      pageKeywords: searchCondition?.pageKeywords || [],
      pageNegativeKeywords: searchCondition?.pageNegativeKeywords || [],
      accountClosingMonths: searchCondition?.accountClosingMonths || [],
      establishedYearMonthFrom:
        searchCondition?.establishedYearMonthFrom || null,
      establishedYearMonthTo: searchCondition?.establishedYearMonthTo || null,
      searchWord: searchCondition?.searchWord || '',
      technologies: searchCondition?.technologies || [],
      page: 1,
    });

  const baseSearchParams = React.useMemo(
    () => ({
      cityIds: query.cityIds,
      prefectureTypeIds: query.prefectureTypeIds,
      employeeRanges: query.employeeRanges,
      capitalFundFrom: query.capitalFundFrom,
      capitalFundTo: query.capitalFundTo,
      officesNumberFrom: query.officesNumberFrom,
      officesNumberTo: query.officesNumberTo,
      markets: query.markets,
      companyTypes: query.companyTypes,
      isUrl: query.isUrl,
      isTel: query.isTel,
      isEmail: query.isEmail,
      isRepresentativePerson: query.isRepresentativePerson,
      monthPressRelease: query.monthPressRelease,
      countPressRelease: query.countPressRelease,
      salesRanges: query.salesRanges,
      profitRateRanges: query.profitRateRanges,
      profitUp3: query.profitUp3,
      profitDown3: query.profitDown3,
      facebook: query.facebook,
      instagram: query.instagram,
      youtube: query.youtube,
      twitter: query.twitter,
      line: query.line,
      linkedin: query.linkedin,
      accountClosingMonths: query.accountClosingMonths,
      establishedYearMonthFrom: query.establishedYearMonthFrom,
      establishedYearMonthTo: query.establishedYearMonthTo,
      technologies: query.technologies,
      websiteIds: [],
      websiteId: null,
    }),
    [query],
  );

  const searchParams = React.useMemo(
    () => ({
      ...baseSearchParams,
      subCategoryIds: query.subCategoryIds,
      websiteIds: query.websiteIds,
      pageKeywords: query.pageKeywords,
      pageNegativeKeywords: query.pageNegativeKeywords,
    }),
    [query],
  );

  const mediaSearchParams = React.useMemo(
    () => ({
      ...baseSearchParams,
      subCategoryIds: query.subCategoryIds,
      searchWord: query.searchWord,
      websiteId: website?.id,
    }),
    [query, website],
  );

  const technologySearchParams = React.useMemo(
    () => ({
      ...baseSearchParams,
      technologies: query.technologies,
    }),
    [query],
  );

  const setPage = (page: number) => setQuery({page});

  return {
    query,
    setQuery,
    searchParams,
    mediaSearchParams,
    technologySearchParams,
    resetQuery,
    page,
    perPage: PAGE_SIZE,
    setPage,
  };
};

export default useSearchCondition;
