import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectTelStatusCallReportQuery} from 'api';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {
    data: {
      projectTelStatusCallReport: {
        call = {},
        reception = {},
        contact = {},
        appoint = {},
        document = {},
        callNg = {},
        receptionNg = {},
        contactNg = {},
      } = {},
      projectTelStatusCallReport = {},
    } = {},
  } = useProjectTelStatusCallReportQuery({
    variables: {
      projectUuid: projectId,
    },
    fetchPolicy: 'network-only',
  });

  const callCount = React.useMemo(() => {
    const totalCount = (object: any): any =>
      Object.values(object).reduce(
        (total: number, value: number) => total + value,
        0,
      );

    let count = 0;

    count += totalCount(call);
    count += totalCount(reception);
    count += totalCount(contact);
    count += totalCount(appoint);
    count += totalCount(document);
    count += totalCount(callNg);
    count += totalCount(receptionNg);
    count += totalCount(contactNg);

    return count;
  }, [projectTelStatusCallReport]);

  return (
    <Container>
      <div>
        <span>
          <em>{callCount && callCount.toLocaleString()}</em>件
        </span>
        <span>コール件数</span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 47px;
  background: #ffffff;
  border-right: 1px solid #e1e6eb;
  padding: 32px 28px;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;

    > span:nth-child(1) {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #495058;

      em {
        margin-right: 6px;
        font-style: normal;
        font-size: 20px;
        line-height: 43px;
      }
    }

    > span:nth-child(2) {
      font-weight: bold;
      font-size: 12px;
      color: #899098;
    }
  }
`;
