const prospectStages = [
  {
    id: 0,
    name: 'pool',
    displayName: 'プール',
    color: '#20B5FA',
    bg: 'rgba(129, 212, 250, 0.2)',
  },
  {
    id: 1,
    name: 'approach',
    displayName: 'アプローチ',
    color: '#3398DA',
    bg: '#DCEEFF',
  },
  {
    id: 3,
    name: 'deal',
    displayName: '商談',
    color: '#FF9900',
    bg: '#FAF2D2',
  },
  {id: 4, name: 'ng', displayName: 'NG', color: '#BF392B', bg: '#F9EBEB'},
  {id: 6, name: 'won', displayName: '受注', color: '#FF5656', bg: '#FFE2E2'},
  {id: 7, name: 'lost', displayName: '失注', color: '#8966EF', bg: '#F3EFFF'},
  {
    id: 8,
    name: 'recycle',
    displayName: 'リサイクル',
    color: '#BD30FF',
    bg: '#F7EAFF',
  },
  {id: 9, name: 'cancel', displayName: '解約', color: '#BF392B', bg: '#F9EBEB'},
  {
    id: 10,
    name: 'invalid',
    displayName: '無効',
    color: '#899098',
    bg: '#E1E6EB',
  },
];

export {prospectStages};
