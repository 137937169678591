import React from 'react';
import styled from 'styled-components';
import Menu from '../../Menu';
import PoolList from './PoolList';
import BodyHeader from './Header';

export default () => {
  return (
    <Body>
      <Menu />
      <BodyContainer>
        <BodyHeader />
        <PoolList />
      </BodyContainer>
    </Body>
  );
};

const Body = styled.div`
  height: calc(100vh - 60px);
  min-width: 774px;
  display: flex;
  background: #fff;
`;

const BodyContainer = styled.div`
  padding 0 30px;
  background: #f3f5f7;
  width: 100%;
  overflow-y: scroll;
`;
