import React from 'react';
import {useSalesforceLeadFieldQuery} from 'api';

interface Props {
  leadFieldId: string;
  value?: string;
}

export default ({leadFieldId, value}: Props) => {
  const {
    data: {
      salesforceLeadField: {salesforceLeadFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceLeadFieldQuery({
    variables: {leadFieldId},
    skip: !leadFieldId,
  });

  const selectedOption = salesforceLeadFieldSelectOptions?.find(
    (option) => option.id === value,
  );

  if (!selectedOption) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-wrap items-center gap-1">
      <span className="h-6 flex items-center justify-center text-sm border border-c-border rounded-sm bg-c-bg px-2">
        {selectedOption.label}
      </span>
    </div>
  );
};
