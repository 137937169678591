import React from 'react';
import Header from '../Header';
import {
  useProspectPoolQuery,
  useSalesforceApexClassesQuery,
  SalesforceApexClass,
} from 'api';
import useMergeModalContext from '../useMergeModalContext';
import {ChevronRightIcon} from '@heroicons/react/20/solid';
import Prospect from './Prospect';
import ReplaceIcon from './Replace';
import Alert from './Alert';

const MainPage = () => {
  const [error, setError] = React.useState(null);

  const {mergeFrom, mergeTo, setMergeFrom, setMergeTo, setPageType, onClose} =
    useMergeModalContext();

  const {data: {prospectPool: prospectPoolFrom} = {}} = useProspectPoolQuery({
    variables: {uuid: mergeFrom},
    skip: !mergeFrom,
  });

  const {data: {prospectPool: prospectPoolTo} = {}} = useProspectPoolQuery({
    variables: {uuid: mergeTo},
    skip: !mergeTo,
  });

  const {data: {salesforceApexClasses = []} = {}} =
    useSalesforceApexClassesQuery({
      variables: {
        methodType: 'merge',
      },
    });

  const salesforceLeadMergeAvailable = React.useMemo(() => {
    return !!salesforceApexClasses.find(
      (apexClass: SalesforceApexClass) =>
        apexClass.objectType == 'lead' && apexClass.methodType == 'merge',
    );
  }, [salesforceApexClasses]);

  const salesforceContactMergeAvailable = React.useMemo(() => {
    return !!salesforceApexClasses.find(
      (apexClass: SalesforceApexClass) =>
        apexClass.objectType == 'contact' && apexClass.methodType == 'merge',
    );
  }, [salesforceApexClasses]);

  React.useEffect(() => {
    if (prospectPoolFrom?.hubspotContact) {
      setError('Hubspotコンタクトからのマージはできません');
    } else if (
      prospectPoolFrom &&
      prospectPoolTo &&
      prospectPoolFrom?.uuid === prospectPoolTo?.uuid
    ) {
      setError('同じリード同士のマージはできません');
    } else if (
      prospectPoolTo?.salesforceLeadId &&
      prospectPoolFrom?.salesforceLeadId &&
      prospectPoolFrom?.salesforceLead?.salesforceLeadRecordType?.id !==
        prospectPoolTo?.salesforceLead?.salesforceLeadRecordType?.id
    ) {
      setError('違うレコードタイプを持つリードのマージはできません');
    } else if (
      prospectPoolTo &&
      prospectPoolFrom?.salesforceLeadId &&
      !prospectPoolTo?.salesforceLeadId &&
      !prospectPoolTo?.salesforceContactId
    ) {
      setError('Salesforceリードから通常リードへのマージはできません');
    } else if (
      prospectPoolFrom?.salesforceLeadId &&
      prospectPoolTo?.salesforceContactId
    ) {
      setError('Salesforceリードから取引先責任者へのマージはできません');
    } else if (
      prospectPoolTo &&
      prospectPoolFrom?.salesforceContactId &&
      !prospectPoolTo?.salesforceContactId &&
      !prospectPoolTo?.salesforceLeadId
    ) {
      setError('取引先責任者から通常リードへのマージはできません');
    } else if (
      prospectPoolFrom?.salesforceContactId &&
      prospectPoolTo?.salesforceLeadId
    ) {
      setError('取引先責任者からSalesforceリードへのマージはできません');
    } else if (
      prospectPoolFrom?.salesforceContactId &&
      prospectPoolTo?.salesforceContactId &&
      prospectPoolFrom?.salesforceContact?.salesforceContactRecordType
        ?.recordTypeId !==
        prospectPoolTo?.salesforceContact?.salesforceContactRecordType
          ?.recordTypeId
    ) {
      setError('違うレコードタイプを持つ取引先責任者のマージはできません');
    } else if (
      (!salesforceLeadMergeAvailable &&
        prospectPoolFrom?.salesforceLeadId &&
        prospectPoolTo?.salesforceLeadId) ||
      (!salesforceContactMergeAvailable &&
        prospectPoolFrom?.salesforceContactId &&
        prospectPoolTo?.salesforceContactId)
    ) {
      setError('Apexクラスが設定されていないためマージはできません');
    } else {
      setError(null);
    }
  }, [prospectPoolFrom, prospectPoolTo]);

  return (
    <div className="w-full h-full flex flex-col">
      <Header title="リード個別名寄せ" />
      <div className="p-6 bg-c-bg rounded-b h-full overflow-y-scroll flex-1 flex flex-col gap-4">
        {error && (
          <span className="bg-[#FBE6E6] text-[#EB5757] rounded leading-8 h-8 text-sm px-4 flex items-center gap-2">
            <Alert />
            {error}
          </span>
        )}
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold flex items-center gap-4">
            名寄せ元
            <button
              onClick={() => setPageType('search/from')}
              className="bg-white px-4 h-8 text-sm text-c-light flex items-center justify-center rounded border border-c-border cursor-pointer">
              リードを検索
              <ChevronRightIcon className="w-3 h-3 text-c-light" />
            </button>
          </h3>
          <Prospect
            prospectPool={prospectPoolFrom}
            onClear={() => setMergeFrom(null)}
          />
        </div>
        <div className="flex-1 flex items-center justify-center">
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.04995 25.3L16.0999 25.3L16.1 6.89999L29.9 6.89999L29.9 25.3L37.95 25.3L22.9999 40.25L8.04995 25.3Z"
              fill="#495058"
            />
          </svg>
          <button
            className="bg-transparent border-none cursor-pointer flex items-center"
            onClick={() => {
              const tmp = mergeFrom;
              setMergeFrom(mergeTo);
              setMergeTo(tmp);
            }}>
            <ReplaceIcon />
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold flex items-center gap-4">
            名寄せ先
            <button
              onClick={() => setPageType('search/to')}
              className="bg-white px-4 h-8 text-sm text-c-light flex items-center justify-center rounded border border-c-border cursor-pointer">
              リードを検索
              <ChevronRightIcon className="w-3 h-3 text-c-light" />
            </button>
          </h3>
          <Prospect
            prospectPool={prospectPoolTo}
            onClear={() => setMergeTo(null)}
          />
        </div>
      </div>
      <div className="flex items-center justify-end px-6 py-4 gap-4 bg-c-bg rounded-b">
        <button
          className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold"
          onClick={() => onClose()}>
          キャンセル
        </button>
        <button
          className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-c-primary border border-c-primary text-white font-bold disabled:opacity-50"
          disabled={!mergeTo || !mergeFrom || error}
          onClick={() => setPageType('confirm')}>
          確認画面へ
        </button>
      </div>
    </div>
  );
};

export default MainPage;
