import React, {useState} from 'react';
import {useUpdateTaskMutation, Task} from 'api';
import {Quill} from 'components/Ui';
import styled from 'styled-components';

interface Props {
  task: Task;
}

export default ({task}: Props) => {
  const [description, setDescription] = useState(task.description);
  const [descriptionHtml, setDescriptionHtml] = useState(task.descriptionHtml);
  const [updateTask] = useUpdateTaskMutation({
    variables: {uuid: task.uuid, attributes: {description, descriptionHtml}},
  });

  const ref = React.useRef(null);
  React.useEffect(() => {
    !descriptionHtml && ref.current?.editor.setText(description);
  }, []);

  return (
    <QuillWrapper onBlur={() => updateTask()}>
      <Quill
        ref={ref}
        theme="snow"
        defaultValue={descriptionHtml}
        onChange={(content, delta, source, editor) => {
          setDescription(editor.getText());
          setDescriptionHtml(content);
        }}
        modules={{
          toolbar: [
            [{header: [1, 2, 3, false]}],
            ['bold', 'italic', 'underline'],
            ['code-block'],
            ['link'],
          ],
        }}
        formats={[
          'header',
          'list',
          'bold',
          'italic',
          'underline',
          'strike',
          'color',
          'background',
          'code-block',
          'link',
        ]}
        bounds={'#quill-container'}
      />
    </QuillWrapper>
  );
};

const QuillWrapper = styled.div`
  border: 1px solid #e2e6ea;
  border-radius: 4px;
  .ql-container {
    border: none;
  }
  .ql-container.ql-snow {
    min-height: 50px;
    .ql-editor {
      min-height: 50px;
      overflow-y: scroll;
      resize: vertical;
    }
  }
`;
