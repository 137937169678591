import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input} from 'components/antd';
import {useCreateContactNgReasonTemplateMutation} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const New = ({visible, onClose}: Props) => {
  const [createContactNgReasonTemplate] =
    useCreateContactNgReasonTemplateMutation({
      refetchQueries: ['currentClient'],
      onCompleted: () => onClose(),
    });

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values, {resetForm}) => {
      createContactNgReasonTemplate({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
      resetForm();
    },
  });

  return (
    <Modal
      destroyOnClose
      visible={visible}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>新規作成</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="NG理由" required>
            <Input
              size="large"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!formik.isValid}>
              作成
            </Button>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default New;
