import gql from 'graphql-tag';

export default gql`
  fragment maintenanceContractFragment on MaintenanceContract {
    id
    canMergeProspect
    canMaintenanceProspects
    canMaintenanceRegularly
  }
`;
