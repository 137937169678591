import React from 'react';
import {Activity, ZoomPhoneWebhookCallActivity} from 'api';
import ZoomPhoneActivity from './ZoomPhoneActivity';
import TelActivity from './TelActivity';

interface ActivityType extends Activity {
  resource: ZoomPhoneWebhookCallActivity;
}

interface Props {
  activity: ActivityType;
}

const ZoomPhoneWebhookCallActivityComponent = ({activity}: Props) => {
  return activity.project === null ? (
    <ZoomPhoneActivity activity={activity} />
  ) : (
    <TelActivity activity={activity} />
  );
};

export default ZoomPhoneWebhookCallActivityComponent;
