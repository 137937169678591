import React from 'react';
import {Route, Switch, Redirect} from 'react-router';
import Contents from './contents';

export default () => (
  <Switch>
    <Route path={'/garage/contents'} component={Contents} />
    <Redirect to={`/garage/contents`} />
  </Switch>
);
