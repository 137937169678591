import React, {useState} from 'react';
import styled from 'styled-components';
import {ProspectPool} from 'api';
import ProspectTimelineItem from 'components/ProspectTimelineItem';
import NewModal from './NewModal';
import EditModal from './EditModal';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const [task, setTask] = useState(null);

  const [newTaskModalVisible, setNewTaskModalVisible] = useState(false);
  const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);

  const List = () => (
    <div>
      <Actions>
        <div className="ant-btn" onClick={() => setNewTaskModalVisible(true)}>
          ToDoを登録する
        </div>
      </Actions>
      <Timeline>
        {prospectPool?.taskActivities?.map((prospectActivity) => (
          <ProspectTimelineItem
            key={prospectActivity.uuid}
            prospectActivity={prospectActivity}
            setIsTaskEdit={setEditTaskModalVisible}
            setTask={setTask}
          />
        ))}
      </Timeline>
      <NewModal
        prospectPool={prospectPool}
        visible={newTaskModalVisible}
        onClose={() => setNewTaskModalVisible(false)}
      />
    </div>
  );

  return (
    <>
      <List />
      {editTaskModalVisible && (
        <EditModal
          visible={editTaskModalVisible}
          onClose={() => setEditTaskModalVisible(false)}
          task={task}
        />
      )}
    </>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Timeline = styled.div`
  margin: 15px 0;
`;
