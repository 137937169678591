import gql from 'graphql-tag';

export default gql`
  fragment salesforceLeadFragment on SalesforceLead {
    id
    leadId
    lastName
    firstName
    addition
    status
  }
`;
