import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useParams,
} from 'react-router-dom';
import Prelead from 'components/Prelead';
import Prospect from 'components/Prospect';
import {useProspectPoolQuery, useUpdateStageProspectPoolMutation} from 'api';

const PreleadComponent = ({onClose}: {onClose: () => void}) => {
  const {preleadId} = useParams<{preleadId: string}>();

  return <Prelead preleadId={preleadId} onClose={onClose} />;
};

const ProspectComponent = ({onClose}: {onClose: () => void}) => {
  const {prospectId} = useParams<{prospectId: string}>();
  const [updateStage] = useUpdateStageProspectPoolMutation();

  const {data: {prospectPool: {pool} = {}} = {}} = useProspectPoolQuery({
    variables: {uuid: prospectId},
    skip: !prospectId,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Prospect
      prospectId={prospectId}
      onClose={onClose}
      poolId={pool?.uuid}
      updateStage={updateStage}
    />
  );
};

export default () => {
  const {path} = useRouteMatch();
  const history = useHistory();

  const onClose = () => history.push(`/approaches${location.search}`);

  return (
    <Switch>
      <Route path={`${path}/prelead/:preleadId`}>
        <PreleadComponent onClose={onClose} />
      </Route>
      <Route path={`${path}/prospect/:prospectId`}>
        <ProspectComponent onClose={onClose} />{' '}
      </Route>
    </Switch>
  );
};
