import React from 'react';
import {formatDateTime} from './formatDateTime';
import styled from 'styled-components';
import {TelBadge, TrashBadge} from 'components/Ui/Icon';
import {
  Activity,
  TelStatusChangeActivity,
  useDestroyTelStatusActivityMutation,
} from 'api';
import telColors from 'helpers/telColor';
import telCategoryColors from 'helpers/telCategoryColor';
import useProjectName from './useProjectName';
import UserName from './UserName';

interface TelStatusChange extends Activity {
  resource: TelStatusChangeActivity;
}

interface Props {
  activity: TelStatusChange;
}

const TelStatusChangeActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);
  const {
    resource: {
      clientTelStatus,
      telStatus,
      telStatusText,
      callNgReason,
      receptionNgReason,
      contactNgReason,
    } = {},
  } = activity;

  const [destroyTelStatus] = useDestroyTelStatusActivityMutation();

  return (
    <>
      {projectName && <Project>{projectName}</Project>}
      <Summary className={'deletable'}>
        <BadgeWrapper>
          <TelBadge />
        </BadgeWrapper>
        <UserName activity={activity} />
        <StatusLabel
          color={
            clientTelStatus
              ? telCategoryColors(clientTelStatus.category)
              : telColors(telStatus)
          }>
          <span>{clientTelStatus?.name || telStatusText}</span>
          {(callNgReason || receptionNgReason || contactNgReason) && (
            <span>|</span>
          )}
          {callNgReason && <span>{callNgReason.reason}</span>}
          {receptionNgReason && <span>{receptionNgReason.reason}</span>}
          {contactNgReason && <span> {contactNgReason.reason}</span>}
        </StatusLabel>
        <TimeStamp>{formatDateTime(activity.createdAt)}</TimeStamp>
        <TrashBadgeWrapper
          onClick={() => {
            if (confirm('本当に削除しますか？')) {
              destroyTelStatus({
                variables: {
                  uuid: activity.uuid,
                },
              });
            }
          }}>
          <TrashBadge />
        </TrashBadgeWrapper>
      </Summary>
    </>
  );
};

const TrashBadgeWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: #c90e19;
      }
    }
  }
`;

const Summary = styled.div`
  margin-left: -30px;
  padding-left: 15px;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  height: 46px;
  position: relative;

  ${TrashBadgeWrapper} {
    display: none;
  }

  &:hover {
    &.deletable {
      background: #f3f5f7;
      border-radius: 4px;

      ${TrashBadgeWrapper} {
        display: flex;
        margin-right: 12px;
      }
    }
  }
`;

const Project = styled.div`
  margin-left: 33px;
  font-size: 10px;
  font-weight: 500;
  color: #899098;
  position: relative;
  top: 7px;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const StatusLabel = styled.span<{color: string}>`
  background-color: ${({color}) => color};
  border-radius: 20px;
  margin: 0 12px;
  padding: 4px 16px;
  color: white;
  font-size: 10px;
  height: 22px;

  > span:nth-child(2) {
    margin: 0 9px;
  }
`;

const TimeStamp = styled.span``;

export default TelStatusChangeActivityComponent;
