import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  if (!query?.priorities || !query.priorities.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">優先度</h4>
        <button
          onClick={() => setFilterType('priority')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setQuery({...query, priorities: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap gap-1">
        {[
          {value: '0', text: 'Low'},
          {value: '1', text: 'Middle'},
          {value: '2', text: 'High'},
        ]
          .filter((priority) => query.priorities.includes(priority.value))
          .map((priority) => (
            <span
              key={priority.value}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {priority.text}
            </span>
          ))}
      </div>
    </div>
  );
};
