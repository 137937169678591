import {DashboardItem, DashboardItemTypePrefix} from './types';
import React from 'react';

import Chart from './Chart';

type Props = {
  item: DashboardItem;
};

const chartComponents: {[keys in DashboardItemTypePrefix]?: React.FC<Props>} = {
  chart: Chart,
};

const DashboardItemComponent: React.FC<Props> = ({item}) => {
  const prefix = item.itemType.split('_')[0] as DashboardItemTypePrefix;
  const ChartComponent = chartComponents[prefix];
  return <ChartComponent item={item} />;
};

export default DashboardItemComponent;
