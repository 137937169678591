import gql from 'graphql-tag';

export default gql`
  fragment blockGroupFragment on BlockGroup {
    id
    uuid
    clientId
    name
    memo
    count
    createdAt
    updatedAt
  }
`;
