import React from 'react';
import {useApproachesByPreleadQuery} from 'api';
import ApproachItem from './Approach';
import usePreleadContext from '../usePreleadContext';

export default () => {
  const {preleadId} = usePreleadContext();

  const {data: {approachesByPrelead = []} = {}} = useApproachesByPreleadQuery({
    variables: {
      uuid: preleadId,
    },
  });

  return (
    <div className="flex flex-col">
      {approachesByPrelead.map((approach) => (
        <ApproachItem key={approach.uuid} approach={approach} />
      ))}
    </div>
  );
};
