import {useCurrentUserQuery, useProjectGroupsQuery, useProjectsQuery} from 'api';
import useClientUser from 'hooks/useClientUser';
import React from 'react';
import useSearchParams from './useSearchParams';

const useProjects = () => {
  const {query, searchParams} = useSearchParams();
  const {status, page = 1} = query;

  const {
    data: {currentUser = {}},
  } = useCurrentUserQuery();

  const {isAdmin} = useClientUser();
  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery({});

  const {
    data: {projects: {projects = [], pagination = {}} = {}} = {},
    loading,
  } = useProjectsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: page,
      search: searchParams,
    },
    skip: !status,
  });

  const projectGroup = React.useCallback(
    (projectGroupId) => projectGroups.find((projectGroup) => projectGroup.id === projectGroupId),
    [projectGroups],
  );

  const accessibleProjects = React.useMemo(
    () =>
      isAdmin
        ? projects
        : projects.filter(
            (project) =>
              !projectGroup(project.projectGroupId)?.isPrivate ||
              projectGroup(project.projectGroupId)?.users.some(
                (user) => user.id === currentUser.id,
              ),
          ),
    [isAdmin, projects, projectGroups, currentUser],
  );

  return {
    accessibleProjects,
    pagination,
    loading,
  };
};

export default useProjects;
