import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Airplane, TrashBadge} from 'components/Ui/Icon';
import {
  Activity,
  MailFormVersionPreleadProjectUrlClickActivity,
} from 'api';
import useProjectName from './useProjectName';

interface MailFormVersionPreleadProjectUrlClick extends Activity {
  resource: MailFormVersionPreleadProjectUrlClickActivity;
}

interface Props {
  activity: MailFormVersionPreleadProjectUrlClick;
}

const MailFormVersionPreleadProjectUrlClickActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  return (
    <Summary>
      <BadgeWrapper>
        <Airplane />
      </BadgeWrapper>
      <Body>
        {projectName && <Project>{projectName}</Project>}
        <a href={activity.resource.mailFormVersionUrl.url} target="_blank">
          {activity.resource.mailFormVersionUrl.url}
        </a>
      </Body>
      <TimeStamp>{moment(activity.createdAt).format('MM/DD HH:mm')}</TimeStamp>
    </Summary>
  );
};

const Summary = styled.div`
  margin-left: -30px;
  display: flex;
  align-items: center;
  height: 46px;
  position: relative;
  span {
    line-height: 30px;
  }
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin: auto 20px auto 15px;
`;

const Body = styled.span`
  min-width: 0;
  max-width: 70%;
  margin-top: 4px;
  flex: auto;
  display: flex;
  flex-direction: column;
  * {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 4px;
  }
`;

const Project = styled.div`
  color: #899098;
`;

const TimeStamp = styled.span`
  margin: auto 0 auto 0;
`;

export default MailFormVersionPreleadProjectUrlClickActivityComponent;
