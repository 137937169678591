import React from 'react';
import useCondition from '../../../useCondition';
import useFilterModalContext from '../../useFilterModalContext';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {
  useSalesforceLeadRecordTypesQuery,
  useSalesforceLeadFieldsQuery,
} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  const {data: {salesforceLeadRecordTypes = []} = {}} =
    useSalesforceLeadRecordTypesQuery();

  const {data: {salesforceLeadFields = []} = {}} = useSalesforceLeadFieldsQuery(
    {
      variables: {
        id: condition.salesforceLeadRecordTypeId,
      },
      skip: !condition.salesforceLeadRecordTypeId,
    },
  );

  const recordType = salesforceLeadRecordTypes.find(
    (recordType) => recordType.id === condition?.salesforceLeadRecordTypeId,
  );

  const statusOptions =
    salesforceLeadFields.find((leadField) => leadField.fieldName === 'Status')
      ?.salesforceLeadFieldSelectOptions || [];

  if (!condition?.salesforceLeadRecordTypeId) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">リード</h4>
        <button
          onClick={() => setFilterType('salesforceLead')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setCondition({
              salesforceLeadRecordTypeId: '',
              salesforceLeadFieldSelectStatusIds: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-c-light">レコードタイプ：</span>
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {recordType.name}
        </span>
      </div>
      {statusOptions.length > 0 && (
        <div className="flex gap-2">
          <span className="text-c-light h-6 flex items-center">
            リード状況：
          </span>
          <div className="flex-1 flex flex-wrap items-center gap-1">
            {statusOptions
              .filter((status) =>
                condition?.salesforceLeadFieldSelectStatusIds.includes(
                  status.id,
                ),
              )
              .map((status) => (
                <span
                  key={status.id}
                  className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                  {status.label}
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
