import React from 'react';
import styled from 'styled-components';
import {useParams, useHistory} from 'react-router';
import {Container, Draggable, DropResult} from 'react-smooth-dnd';
import {
  useMailCampaignQuery,
  useUpdateMailCampaignBlockPositionMutation,
} from 'api';
import Block from './Block';

export default () => {
  const {poolId, mailCampaignId} = useParams<{
    poolId: string;
    mailCampaignId: string;
  }>();
  const history = useHistory();

  const [blocks, setBlocks] = React.useState([]);

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const [updateMailCampaignBlockPosition] =
    useUpdateMailCampaignBlockPositionMutation();

  const onClick = () => history.push(`/mail_campaigns/${mailCampaignId}/mail`);

  const onDragStart = () =>
    history.push(`/mail_campaigns/${mailCampaignId}/mail`);

  const onDrop = ({removedIndex, addedIndex}: DropResult) => {
    if (
      removedIndex === addedIndex ||
      removedIndex === null ||
      addedIndex === null
    ) {
      return;
    }

    const result = [...blocks];
    const itemToAdd = result.splice(removedIndex, 1)[0];
    result.splice(addedIndex, 0, itemToAdd);
    setBlocks(result);

    if (mailCampaign) {
      updateMailCampaignBlockPosition({
        variables: {
          uuid: mailCampaign.uuid,
          from: removedIndex,
          to: addedIndex,
        },
      });
    }
  };

  React.useEffect(() => {
    if (mailCampaign) {
      setBlocks(mailCampaign.blocks);
    }
  }, [mailCampaign]);

  return (
    <Background
      style={{backgroundColor: mailCampaign?.backgroundColor}}
      onClick={onClick}>
      <Mail
        style={{
          backgroundColor: mailCampaign?.bodyColor,
          color: mailCampaign?.textColor,
        }}>
        <Container
          onDragStart={onDragStart}
          onDrop={onDrop}
          lockAxis="y"
          dragHandleSelector=".dragHandle">
          {blocks.map((block) => (
            <Draggable key={block.id}>
              <Block block={block} />
            </Draggable>
          ))}
        </Container>
      </Mail>
    </Background>
  );
};

const Background = styled.div`
  height: calc(100vh - 320px);
  background: #f3f5f7;
  border: 1px solid #bdc4cb;
  border-radius: 4px;
  flex: 1;
  overflow: auto;
`;

const Mail = styled.div`
  width: 550px;
  background-color: #fff;

  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: visible;
  }

  /* reset */
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  padding: 0;
  font-family: monospace, -apple-system, sans-serif;

  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  form,
  fieldset,
  legend,
  input,
  textarea,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
    color: inherit;
  }

  table {
    width: 100%;
  }

  table,
  td {
    border-collapse: collapse;
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    border: 0 none;
    height: auto;
    line-height: 100%;
    outline: none;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.67em;
  }

  a {
    text-decoration: underline;
  }

  a img {
    border: 0 none;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
`;
