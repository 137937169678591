import React from 'react';

const Icon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 57V3H51V57H57H37.5M3 57H22.5M22.5 57V40.5H37.5V57M22.5 57H37.5M34.5 25.5V31.5H42V25.5H34.5ZM18 25.5V31.5H25.5V25.5H18ZM18 12V18H25.5V12H18ZM34.5 12V18H42V12H34.5Z"
      strokeWidth="3"
    />
  </svg>
);

export default Icon;
