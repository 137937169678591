import gql from 'graphql-tag';
import collectedFormFragment from '../collectedForm';
import collectedFormUrlFragment from '../collectedFormUrl';

export default gql`
  fragment collectedFormProspectActivityFragment on CollectedFormProspectActivity {
    collectedForm {
      ...collectedFormFragment
      collectedFormUrl {
        ...collectedFormUrlFragment
      }
    }
  }
  ${collectedFormFragment}
  ${collectedFormUrlFragment}
`;
