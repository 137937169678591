import gql from 'graphql-tag';

export default gql`
  fragment clientPhaseProspectFragment on ClientPhaseProspect {
    id
    name
    position
    description
  }
`;
