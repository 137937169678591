import React from 'react';
import {Content} from 'api';
import styled from 'styled-components';
import {
  ContentForm,
  ContentMail,
  ContentDocument,
  ContentTelScript,
  ContentAudio,
} from 'components/Ui/Icon';

interface Props {
  row: any;
}

const contentableIcons: {[key: string]: any} = {
  'Content::MailForm': ContentForm,
  'Content::Document': ContentDocument,
  'Content::MailTemplate': ContentMail,
  'Content::TelScript': ContentTelScript,
  'Content::Audio': ContentAudio,
};

const NameCell = ({row}: Props) => {
  const content = row.original as Content;
  const ContentableIcon = contentableIcons[content.contentableType];

  return (
    <Container>
      <span>
        <ContentableIcon />
      </span>
      <div>{content.title}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }
`;

export default NameCell;
