import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16699 17.5002L13.3337 8.3335L17.5003 12.5002"
        stroke="#495058"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
        stroke="#495058"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08301 8.3335C7.77336 8.3335 8.33301 7.77385 8.33301 7.0835C8.33301 6.39314 7.77336 5.8335 7.08301 5.8335C6.39265 5.8335 5.83301 6.39314 5.83301 7.0835C5.83301 7.77385 6.39265 8.3335 7.08301 8.3335Z"
        stroke="#495058"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
