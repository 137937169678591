import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import usePreleadProject from 'hooks/usePreleadProject';
import {usePreleadProjectQuery} from 'api';
import {flatten} from 'lodash';
import Attachment from 'components/TimelineItem/Attachment';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();
  const {timeLineActivities} = usePreleadProject(preleadProjectId);

  const {data: {preleadProject: {prelead: {activities = []} = {}} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    });

  const attachments = React.useMemo(
    () =>
      flatten(
        timeLineActivities(activities)
          .map((activity) => activity.activityAttachments)
          .filter((activity) => activity),
      ),
    [activities],
  );

  if (!preleadProjectId) return null;

  return (
    <Container>
      <h2>ファイル</h2>
      {attachments.map((attachment) => (
        <Attachment key={attachment.uuid} attachment={attachment} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > h2 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 12px;
    color: #495058;
  }
`;
