import React from 'react';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  BooleanParam,
} from 'use-query-params';
import {useHistory} from 'react-router-dom';
import {useTable} from 'react-table';
import moment from 'moment';
import styled from 'styled-components';
import {Input, Pagination as UIPagination} from 'components/antd';
import Cell from './Cell';
import {useContentsQuery, Content as ContentType} from 'api';
import {Lense} from '../../../../../components/Ui/Icon';

const PAGE_SIZE = 10;

export default () => {
  const history = useHistory();
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    type: StringParam,
    archived: BooleanParam,
    searchWord: StringParam,
  });

  const {data: {contents: {contents = [], pagination = {}} = {}} = {}} =
    useContentsQuery({
      variables: {
        page: query.page,
        perPage: PAGE_SIZE,
        types: query.type ? [query.type] : [],
        archived: query.archived,
        searchWord: query.searchWord,
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Header: 'タイトル',
        accessor: 'title',
        Cell: Cell.Name,
      },
      {
        id: 'date',
        Header: '更新日',
        accessor: (row: any) => moment(row.updatedAt).format('YYYY.MM.DD'),
      },
      {
        id: 'project',
        Header: '営業リスト',
        accessor: 'project',
        Cell: Cell.Project,
      },
      {
        id: 'actions',
        Header: '',
        accessor: '',
        Cell: Cell.Actions,
      },
    ],
    [],
  );

  const [searchInput, setSearchInput] = React.useState(query.searchWord || '');

  const setSearchWord = (e: any) => {
    setQuery({
      page: 1,
      searchWord: e.target.value,
    });
  };

  React.useEffect(() => {
    setSearchInput(query.searchWord || '');
  }, [query.searchWord]);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<ContentType>({columns, data: contents});

  return (
    <Container>
      <ContentsTable {...getTableProps()} cellSpacing="0" cellPadding="0">
        <Input
          type="search"
          name="search"
          placeholder="検索"
          prefix={<Lense />}
          onBlur={setSearchWord}
          onChange={(e) => setSearchInput(e.target.value)}
          onPressEnter={setSearchWord}
          value={searchInput}
          className="flex items-center border rounded mb-5 h-10 md:w-48 bg-white border-bdc4cb focus:outline-none focus:bg-white"
        />
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // className: column.className,
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={i}
                onClick={() =>
                  history.push(`/garage/contents/${row.original.uuid}`)
                }>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </ContentsTable>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={PAGE_SIZE}
        onChange={(page) => setQuery({page})}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentsTable = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  display: block;
  overflow-x: scroll;
  white-space: nowrap;

  td {
    min-width: 50px;
    padding: 1rem;
    margin: 0px;
  }

  thead {
    tr {
      th {
        padding: 3px 1rem;
        font-size: 10px;
        color: #899098;
        text-align: left;

        &:first-child {
          padding: 3px 1rem 3px 0;
        }
      }
    }
  }

  tbody {
    font-size: 13px;

    tr {
      box-sizing: border-box;
      background: #fff;

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 800px;
        text-overflow: ellipsis;
        border-top: solid 1px #f3f5f7;
        border-bottom: solid 1px #f3f5f7;
        cursor: pointer;

        &:first-child {
          width: calc(100vw - 690px);
          border-radius: 4px 0 0 4px;
          border-left: solid 1px #f3f5f7;
          div {
            width: calc(100vw - 1000px);
          }
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
          border-right: solid 1px #f3f5f7;
        }

        &:last-child button,
        &:last-child a {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 20px;
            height: 20px;
            path {
              stroke: transparent;
            }
          }
        }
      }

      &:hover {
        background: #f9fcff;
        color: #68b5fb;

        td {
          border-color: #68b5fb;

          &:last-child svg {
            path {
              stroke: #68b5fb;
            }
          }
        }
      }
    }
  }
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: #495058;
      background: #e2e6ea;
      border-radius: 100px;
    }
  }
`;
