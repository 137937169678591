import React from 'react';

export const FilterModalContext = React.createContext<{
  filterType: string;
  setFilterType: (filterType: string) => void;
  onClose: () => void;
}>(null);

const useFilterModalContext = () => {
  const filterModalContext = React.useContext(FilterModalContext);

  return filterModalContext;
};

export default useFilterModalContext;
