import React from 'react';

function Icon() {
  return (
    <svg
      width="120"
      height="18"
      viewBox="0 0 120 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19473_273167)">
        <path
          d="M4.32509 16.875C4.26832 17.0637 4.16743 17.2363 4.03068 17.3785C3.89394 17.5208 3.72522 17.6286 3.5385 17.6932C2.99268 17.8976 2.41116 17.9903 1.82862 17.9659C1.34928 17.992 0.874673 17.8602 0.477842 17.5909C0.323786 17.4946 0.196182 17.3617 0.106621 17.2039C0.0170609 17.0462 -0.0316644 16.8687 -0.0351562 16.6875V16.4318C0.717195 13.7216 1.34986 11.0454 1.84573 8.42044C2.33957 6.08693 2.61999 3.71355 2.68356 1.32952C2.69311 1.13057 2.75712 0.93803 2.86865 0.772766C2.98017 0.607502 3.135 0.475843 3.31626 0.392042C3.7973 0.11119 4.34954 -0.0249823 4.90645 -2.21697e-05C5.44265 -0.0237688 5.97505 0.10011 6.44534 0.35797C6.61701 0.448712 6.76135 0.583346 6.86352 0.748109C6.96569 0.912873 7.02201 1.1018 7.02669 1.29545C6.9598 3.78721 6.67372 6.26832 6.17175 8.71023C5.71161 11.4645 5.09508 14.1904 4.32509 16.875ZM18.1068 16.6364V16.7386C18.1001 16.923 18.0442 17.1023 17.9451 17.258C17.8459 17.4138 17.707 17.5405 17.5425 17.625C17.0927 17.8889 16.5765 18.019 16.0549 18C15.4635 18.0303 14.8743 17.9069 14.345 17.642C14.1673 17.5648 14.0151 17.439 13.9062 17.2791C13.7972 17.1192 13.736 16.9318 13.7295 16.7386C13.4901 14.2841 13.131 11.7613 12.6351 9.17044C12.1393 6.57953 11.5408 4.05682 10.8398 1.56818V1.29545C10.8462 1.11487 10.8962 0.938497 10.9855 0.781245C11.0747 0.623993 11.2007 0.490489 11.3527 0.392042C11.7437 0.124036 12.2126 -0.00778735 12.6864 0.0170399C13.2702 -0.00242735 13.8518 0.0961673 14.3963 0.306836C14.5841 0.379462 14.7526 0.494029 14.889 0.641783C15.0254 0.789537 15.1259 0.96654 15.1829 1.15911C16.6348 6.21797 17.6192 11.3986 18.1239 16.6364H18.1068Z"
          fill="#418A46"
        />
        <path
          d="M37.6178 15.8353C37.6203 16.0259 37.5836 16.2149 37.5101 16.3907C37.4366 16.5666 37.3278 16.7257 37.1903 16.8581C36.9042 17.1274 36.5237 17.2742 36.1302 17.2672H22.4511C22.2587 17.2712 22.0675 17.2371 21.8884 17.1669C21.7094 17.0966 21.5461 16.9917 21.4081 16.8581C21.2706 16.7257 21.1618 16.5666 21.0883 16.3907C21.0148 16.2149 20.9781 16.0259 20.9806 15.8353V1.70465C20.9783 1.51151 21.0148 1.31986 21.0879 1.14097C21.161 0.962089 21.2692 0.799608 21.4062 0.663027C21.5432 0.526447 21.7062 0.418573 21.8857 0.345714C22.0651 0.272855 22.2574 0.236478 22.4511 0.238764H36.1302C36.3268 0.233675 36.5223 0.268876 36.7046 0.342177C36.887 0.415477 37.0523 0.525317 37.1903 0.6649C37.3299 0.799295 37.4399 0.961113 37.5135 1.14009C37.5871 1.31907 37.6226 1.51128 37.6178 1.70465V15.8353ZM33.326 12.8353V4.70466C33.326 4.5683 33.326 4.51719 33.1208 4.51719H25.4605C25.3237 4.51719 25.2553 4.51716 25.2553 4.70466V12.8353C25.2553 12.9546 25.2553 13.0229 25.4605 13.0229H33.1208C33.2576 13.0229 33.326 12.9546 33.326 12.8353Z"
          fill="#418A46"
        />
        <path
          d="M58.2899 8.96556C58.3105 9.47465 58.1398 9.97309 57.8112 10.3633C57.6908 10.5305 57.5349 10.6691 57.3546 10.7693C57.1743 10.8695 56.974 10.9287 56.7681 10.9428H42.6273C42.4226 10.9319 42.2228 10.8758 42.0424 10.7785C41.862 10.6812 41.7055 10.5453 41.5843 10.3804C41.2737 9.97372 41.1055 9.47668 41.1055 8.96556C41.1055 8.45444 41.2737 7.95745 41.5843 7.55081C41.7055 7.38592 41.862 7.24988 42.0424 7.15261C42.2228 7.05533 42.4226 6.99921 42.6273 6.98828H56.7852C56.99 6.99921 57.1898 7.05533 57.3702 7.15261C57.5506 7.24988 57.707 7.38592 57.8282 7.55081C58.1533 7.94872 58.318 8.45314 58.2899 8.96556Z"
          fill="#418A46"
        />
        <path
          d="M79.0142 7.07388C79.1095 8.52459 78.8934 9.97884 78.3804 11.3397C77.8674 12.7006 77.0692 13.9368 76.0391 14.9659C73.5038 16.9984 70.2898 17.9974 67.045 17.7614H65.1128C64.9202 17.7504 64.7338 17.6896 64.5719 17.585C64.41 17.4804 64.2782 17.3355 64.1895 17.1648C63.9249 16.6977 63.7947 16.1668 63.8133 15.6307C63.7821 15.0933 63.9131 14.559 64.1895 14.0966C64.2785 13.9288 64.4113 13.788 64.5737 13.689C64.7362 13.5901 64.9224 13.5365 65.1128 13.5341H67.045C69.2166 13.7152 71.3757 13.0628 73.0809 11.7102C73.6673 11.1081 74.122 10.3911 74.4164 9.60485C74.7107 8.81855 74.8382 7.97995 74.7908 7.14203V4.99434C74.7908 4.85797 74.7908 4.7898 74.5685 4.7898H66.0191C65.8994 4.7898 65.831 4.78979 65.831 4.99434V8.40343C65.8259 8.60132 65.7677 8.79424 65.6624 8.96211C65.5572 9.12998 65.4087 9.26667 65.2325 9.35797C64.76 9.62742 64.2204 9.75747 63.6765 9.73297C63.1352 9.75384 62.598 9.63023 62.1205 9.37498C61.9462 9.27864 61.8006 9.13802 61.6984 8.96736C61.5963 8.7967 61.5414 8.60213 61.5392 8.40343V1.92612C61.5367 1.73561 61.5734 1.54665 61.6469 1.37077C61.7204 1.1949 61.8292 1.03581 61.9666 0.903387C62.1041 0.76297 62.2693 0.652619 62.4518 0.579259C62.6343 0.505898 62.8301 0.471132 63.0268 0.47725H77.5951C77.7874 0.474995 77.9782 0.510838 78.1565 0.58264C78.3348 0.654442 78.4971 0.760819 78.6338 0.895584C78.7706 1.03035 78.8792 1.1908 78.9533 1.3677C79.0274 1.54461 79.0655 1.73441 79.0655 1.92612L79.0142 7.07388Z"
          fill="#418A46"
        />
        <path
          d="M99.1923 8.96556C99.2151 9.47795 99.0373 9.97914 98.6964 10.3633C98.5805 10.5309 98.4279 10.6702 98.2502 10.7706C98.0725 10.8709 97.8743 10.9298 97.6705 10.9428H83.5297C83.3244 10.9343 83.1237 10.8793 82.943 10.7818C82.7622 10.6843 82.6062 10.547 82.4867 10.3804C82.1761 9.97372 82.0078 9.47668 82.0078 8.96556C82.0078 8.45444 82.1761 7.95745 82.4867 7.55081C82.6062 7.38414 82.7622 7.24681 82.943 7.14933C83.1237 7.05185 83.3244 6.99677 83.5297 6.98828H97.6705C97.8753 6.99921 98.0751 7.05533 98.2555 7.15261C98.4358 7.24988 98.5923 7.38592 98.7135 7.55081C99.0447 7.94624 99.2155 8.45091 99.1923 8.96556Z"
          fill="#418A46"
        />
        <path
          d="M120.052 7.68796C120.075 9.32652 119.718 10.9482 119.009 12.4266C118.18 14.0399 116.856 15.3475 115.23 16.1596C112.937 17.2469 110.415 17.7673 107.878 17.6766H104.458C104.257 17.671 104.062 17.6129 103.891 17.5082C103.72 17.4035 103.579 17.2559 103.483 17.08C103.21 16.6104 103.079 16.0714 103.107 15.5289C103.077 14.9971 103.208 14.4685 103.483 14.0118C103.577 13.8356 103.718 13.6884 103.889 13.5862C104.061 13.4841 104.258 13.4308 104.458 13.4323H107.878C109.915 13.5852 111.948 13.0963 113.691 12.0346C114.396 11.4913 114.953 10.7809 115.311 9.96804C115.67 9.15522 115.818 8.2659 115.743 7.38116V4.89253C115.743 4.75617 115.743 4.68794 115.555 4.68794H109.16C109.112 4.68821 109.065 4.70302 109.025 4.73039C108.986 4.75777 108.955 4.79648 108.938 4.8414C108.592 5.73729 108.069 6.55459 107.399 7.24476C106.443 8.22949 105.273 8.98187 103.979 9.44364H103.74C103.507 9.4318 103.28 9.3728 103.071 9.27021C102.862 9.16763 102.677 9.02361 102.526 8.84704C102.077 8.36058 101.837 7.71793 101.859 7.05729C101.837 6.61832 101.919 6.18033 102.098 5.77888C102.256 5.46678 102.524 5.22392 102.851 5.09707C103.535 4.75867 104.123 4.25463 104.561 3.63114C104.963 3.1343 105.245 2.55066 105.381 1.92659C105.427 1.46534 105.647 1.03875 105.997 0.733439C106.401 0.467686 106.882 0.341862 107.365 0.375447H118.565C118.758 0.373657 118.95 0.410469 119.129 0.483646C119.308 0.556823 119.471 0.664892 119.608 0.801583C119.749 0.935131 119.862 1.09642 119.939 1.27532C120.015 1.45422 120.054 1.64692 120.052 1.84138V7.68796Z"
          fill="#418A46"
        />
      </g>
      <defs>
        <clipPath id="clip0_19473_273167">
          <rect width="120" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
