import {ArrowRightIcon} from '@heroicons/react/20/solid';
import moment from 'moment';
import React, {useEffect} from 'react';
import {DateParam, useQueryParams} from 'use-query-params';

export const MonthRangeInput: React.FC = () => {
  const [query, setQuery] = useQueryParams({
    dateFrom: DateParam,
    dateTo: DateParam,
  });

  useEffect(() => {
    setQuery({
      dateFrom: moment().subtract(1, 'year').startOf('month').toDate(),
      dateTo: moment().endOf('month').toDate(),
    });
  }, []);

  const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const from = moment(e.target.value).startOf('month').toDate();
    setQuery({
      dateFrom: from,
    });
  };

  const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const to = moment(e.target.value).endOf('month').toDate();
    setQuery({
      dateTo: to,
    });
  };

  return (
    <div className="flex items-center gap-4">
      <input
        type="month"
        onChange={onChangeFrom}
        value={moment(query.dateFrom).format('YYYY-MM')}
        className="border border-c-border rounded py-1 px-2"
      />
      <ArrowRightIcon className="w-4 h-4" />
      <input
        type="month"
        onChange={onChangeTo}
        value={moment(query.dateTo).format('YYYY-MM')}
        className="border border-c-border rounded py-1 px-2"
      />
    </div>
  );
};
