import gql from 'graphql-tag';
import manualMailFragment from '../manualMail';

export default gql`
  fragment manualMailSendProspectActivityFragment on ManualMailSendProspectActivity {
    id
    stage
    stageText
    hubspotEmailIdString
    hubspotContactIdString
    createdAt
    manualMail {
      ...manualMailFragment
      mailAttachments {
        uuid
        filename
        extension
      }
    }
  }
  ${manualMailFragment}
`;
