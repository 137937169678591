import React from 'react';
import {GridTable, Header} from 'components/Ui';
import styled from 'styled-components';
import Menu from './Menu';
import usePreleadTable from './usePreleadTable';
import Prelead from 'components/Prelead';
import {useParams, useHistory} from 'react-router';

export default () => {
  const tableData = usePreleadTable();
  const {preleadId} = useParams<{preleadId: string}>();
  const history = useHistory();
  const closeDetail = () => history.push(`/list/preleads${location.search}`);

  return (
    <Container>
      <Header>
        <h1>企業一覧</h1>
      </Header>
      <Menu />
      <Body>
        <GridTable {...tableData} />
      </Body>
      <Prelead onClose={() => closeDetail()} preleadId={preleadId} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'menu'
    'body';
  grid-template-rows: 70px 50px calc(100vh - 180px);
  padding-left: 32px;
  background: #f3f5f7;

  h1 {
    margin-top: 30px;
    font-weight: bold;
    font-size: 20px;
  }
`;

const Body = styled.div`
  width: calc(100vw - 49px);
  position: relative;
  display: flex;
`;
