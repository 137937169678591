import React from 'react';
import useChartData from './useChartData';
import useTableData from './useTableData';
import {VerticalBarChart} from '../../components/Chart';
import {Table} from '../../components/Table';

export default () => {
  const chart = useChartData();
  const table = useTableData();

  return (
    <div className="flex flex-col">
      <div className="border border-c-border">
        <h2 className="px-8 pt-4">2023年3月</h2>
        <VerticalBarChart
          chart={chart}
          legend={false}
          className="h-[240px]"
          yAxisProps={{width: 100}}
          barProps={{barSize: 'thin'}}
        />
        <div className="p-8 border-t border-c-border flex flex-col gap-2">
          <Table table={table} />
        </div>
      </div>
    </div>
  );
};
