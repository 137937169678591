import React, {SVGProps} from 'react';
import {useUpdateFormUsersMutation, Form, useActiveClientUsersQuery} from 'api';
import {XMarkIcon} from '@heroicons/react/20/solid';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [userId, setUserId] = React.useState<string>(undefined);
  const [update] = useUpdateFormUsersMutation({
    refetchQueries: ['form'],
  });
  const {data: {activeClientUsers = []} = {}} = useActiveClientUsersQuery();

  const userOptions = React.useMemo(() => {
    const userIds = form.users.map((user) => user.id);
    return activeClientUsers
      .filter((clientUser) => !userIds.includes(clientUser.user.id))
      .map((clientUser) => ({
        label: `${clientUser.user.lastName} ${clientUser.user.firstName}`,
        value: clientUser.user.id,
      }));
  }, [form, activeClientUsers]);

  const onAdd = React.useCallback(
    (id: string) => {
      const userIds = form.users.map((user) => user.id);
      update({
        variables: {
          uuid: form.uuid,
          userIds: [...userIds, id],
        },
        onCompleted: () => setUserId(undefined),
      });
    },
    [form],
  );

  const onDelete = React.useCallback(
    (id: string) => {
      const userIds = form.users
        .filter((user) => user.id !== id)
        .map((user) => user.id);
      update({
        variables: {
          uuid: form.uuid,
          userIds,
        },
      });
    },
    [form],
  );

  return (
    <div className="flex flex-col gap-12 w-[480px]">
      <div className="flex flex-col gap-1">
        <label className="text-c-base font-bold text-base">
          メール通知の送信先
        </label>
        <div className="flex items-center gap-2">
          <select
            className="form-select border-c-border rounded text-base w-full"
            onChange={(e) => setUserId(e.target.value)}>
            <option value={''} />
            {userOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button
            onClick={() => onAdd(userId)}
            disabled={!userId}
            className="h-10 text-white bg-c-primary rounded flex items-center justify-center w-16 cursor-pointer hover:opacity-90 transition-all disabled:bg-c-bg disabled:text-c-light">
            追加
          </button>
        </div>
        <div className="flex flex-col gap-2 mt-2 text-base">
          {form.users.map((user) => (
            <div key={user.id} className="flex items-center gap-4">
              <div>{user.email}</div>
              <div className="text-c-light flex-1">
                {user.lastName} {user.firstName}
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="bg-transparent rounded-full cursor-pointer group hover:bg-c-bg"
                  onClick={() => onDelete(user.id)}>
                  <XMarkIcon className="text-c-light w-3 h-3 group-hover:text-c-base" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
