import React from 'react';
import styled from 'styled-components';
import {getTagFontColor} from 'helpers/tagColors';
import {PreleadTag} from 'api';
import usePreleadTags from 'hooks/usePreleadTags';

interface Props {
  value: PreleadTag[];
}

export default ({value}: Props) => {
  const {filterPreleadTagsAccessible} = usePreleadTags();
  return (
    <Container>
      {filterPreleadTagsAccessible(value).map((tag) => (
        <ProjectTag key={tag.id} color={tag.color}>
          <span>{tag.name}</span>
        </ProjectTag>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const ProjectTag = styled.span<{color?: string}>`
  margin-right: 3px;
  padding: 1px 8px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;

  span {
    font-size: 9px;
    letter-spacing: 0.15px;
    color: ${({color}) => getTagFontColor(color)};
  }
`;
