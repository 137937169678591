import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {MailMessage, AutomaticMailSendMailMessage} from 'api';

interface AutomaticMailSend extends MailMessage {
  messageable: AutomaticMailSendMailMessage;
}

interface Props {
  mailMessage: AutomaticMailSend;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default ({mailMessage, open, setOpen}: Props) => {
  const {automaticMailSend} = mailMessage.messageable;
  const toggleOpen = React.useCallback(() => setOpen(!open), [open, setOpen]);

  return (
    <Container>
      <h4 onClick={toggleOpen}>
        <div>{automaticMailSend.automaticMail.from}</div>
        <span>{moment(mailMessage.sendAt).format('YYYY/MM/DD HH:mm')}</span>
      </h4>
      {open ? (
        <Message
          dangerouslySetInnerHTML={{__html: automaticMailSend.bodyHtml}}
        />
      ) : (
        <Snippet onClick={toggleOpen}>{automaticMailSend.bodyText}</Snippet>
      )}
    </Container>
  );
};

const Container = styled.div`
  border-bottom: solid 1px #e2e6ea;
  padding: 1rem 0;

  h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    div {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-size: 12px;
      color: #899098;
      font-weight: normal;
    }
  }
`;

const Message = styled.div`
  padding: 1rem 0;
  * {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
  img {
    height: auto !important;
  }
`;

const Snippet = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;
