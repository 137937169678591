import React from 'react';
import {NumberParam, useQueryParams, BooleanParam} from 'use-query-params';

const PAGE_SIZE = 50;

const searchParamsDef = {
  page: NumberParam,
  onlyApplicable: BooleanParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  React.useEffect(() => {
    setQuery({
      ...query,
    });
  }, []);

  return {
    query,
    setQuery,
    searchParams: {
      onlyApplicable: query.onlyApplicable,
    },
    page,
    perPage: PAGE_SIZE,
  };
};

export default useSearchParams;
