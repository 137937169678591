import React from 'react';
import styled from 'styled-components';
import {Checkbox} from 'components/antd';
import {useParams} from 'react-router-dom';
import {
  useMailCampaignQuery,
  useAllProjectsQuery,
  useUpdateMailCampaignPreleadSearchConditionMutation,
} from 'api';
import Title from '../Title';

export default () => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const {data: {allProjects: {projects = []} = {}} = {}} = useAllProjectsQuery({
    variables: {search: {status: 'open'}},
  });

  const [update] = useUpdateMailCampaignPreleadSearchConditionMutation({
    refetchQueries: ['preleadProjectsByMailCampaign'],
  });

  if (!mailCampaign) return null;

  return (
    <Container>
      <Title title="送信先設定" />
      <Checkbox.Group
        disabled={mailCampaign?.status !== 'draft'}
        onChange={(values: string[]) =>
          update({
            variables: {
              uuid: mailCampaignId,
              searchCondition: {
                projectIds: values,
                mailStatuses: mailCampaign.searchCondition?.mailStatuses,
                preleadStatusIds:
                  mailCampaign.searchCondition?.preleadStatusIds,
              },
            },
          })
        }
        value={mailCampaign.searchCondition?.projectIds}>
        {projects.map((project) => (
          <div key={project.id} className="[&>.ant-checkbox-wrapper]:!inline [&_.ant-checkbox]:!inline-block">
            <Checkbox value={project.uuid}>
              {project.name}
            </Checkbox>
          </div>
        ))}
      </Checkbox.Group>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 25px;

  .ant-checkbox-wrapper {
    font-size: 14px;
    margin: 2px 0;
    width: 100%;
  }
`;
