import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import {
  Button,
  Form,
  Select,
  Input,
  Modal,
  Popover,
  AutoComplete,
} from 'components/antd';
import * as Yup from 'yup';
import {prospectStages} from 'helpers/prospectStages';
import {Quill} from 'react-quill';
import {Quill as ReactQuill} from 'components/Ui';
import {
  ProspectPool,
  useUsersByClientAllQuery,
  useUploadMailImageMutation,
  useActionsByProspectPoolQuery,
  useCreateManualMailProspectActivityMutation,
  useProspectPoolQuery,
} from 'api';
import useMailTemplate from 'hooks/useMailTempate';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  prospectPool: ProspectPool;
  actionUuid: string;
};

export default ({isOpen, setIsOpen, prospectPool, actionUuid}: Props) => {
  const {templates, templateTitle} = useMailTemplate();
  const onClose = () => {
    formik.resetForm();
    setIsOpen(false);
  };
  const [areCcAndBccSelectable, setAreCcAndBccSelectable] = useState(false);
  const [createManualMailProspectActivity, {loading}] =
    useCreateManualMailProspectActivityMutation({
      onCompleted: onClose,
    });

  const currentStageValue = () => {
    return prospectStages.find((stage) => {
      return stage.name === prospectPool?.stage;
    });
  };

  const {data: {actionsByProspectPool = []} = {}} =
    useActionsByProspectPoolQuery({
      variables: {
        prospectPoolUuid: prospectPool.uuid,
        status: 'open',
      },
      fetchPolicy: 'cache-and-network',
    });

  const actionStatus = actionsByProspectPool.find(
    (action) => action.uuid === actionUuid,
  )?.status;

  const ref = useRef(null);

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const {data: {prospectPool: {prospect: {email} = {}} = {}} = {}} =
    useProspectPoolQuery({
      variables: {uuid: prospectPool.uuid},
      skip: !prospectPool,
      fetchPolicy: 'cache-and-network',
    });

  const insertToEditor = (url: string) => {
    const range = ref.current.editor.getSelection();
    ref.current.editor.insertEmbed(range.index, 'image', url);
  };

  const [uploadMailImage] = useUploadMailImageMutation({
    onCompleted: ({uploadMailImage: {imageUrl} = {}}) => {
      insertToEditor(imageUrl);
    },
  });

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadMailImage({variables: {image: file}});
      } else {
        alert('画像を選択して下さい。');
      }
    };
  };

  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = [
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
  ];
  Quill.register(fontSizeStyle, true);

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        handlers: {
          image: selectLocalImage,
        },
        container: [
          [
            {header: [1, 2, 3, false]},
            'bold',
            'italic',
            'underline',
            {align: ['', 'center']},
            {color: []},
            'link',
            'image',
            {size: fontSizeStyle.whitelist},
          ],
        ],
      },
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      actionUuid: actionUuid,
      fromEmail: '',
      fromName: '',
      to: email,
      stage: currentStageValue()?.id,
      cc: [],
      bcc: [],
      subject: '',
      bodyText: '',
      bodyHtml: '',
      files: [],
    },
    validationSchema: Yup.object().shape({
      fromEmail: Yup.string().email().required(),
      to: Yup.string().email().required(),
      subject: Yup.string().required(),
      bodyText: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const actionUuid = values.actionUuid;
      delete values.actionUuid;

      values.bodyHtml = values.bodyHtml.replace(
        /class="ql-align-center"/g,
        'style="text-align: center"',
      );

      createManualMailProspectActivity({
        variables: {
          uuid: prospectPool.uuid,
          actionUuid: actionUuid,
          attributes: values,
        },
      });
    },
  });

  const openManualMailActions = actionsByProspectPool.filter(
    (action) => action.actionableType === 'Action::ManualMail',
  );

  const setFrom = async (value: string) => {
    await formik.setFieldValue('fromEmail', value);

    const user = users.find((user) => user.email === value);
    if (!user) return;

    const name = [user.lastName, user.firstName];
    formik.setFieldValue('fromName', name.join(' '));
  };

  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const inputRef = useRef(null);

  const FromNameModal = () => (
    <FromNameContainer>
      <h3>差出人</h3>
      <div style={{marginBottom: 18}}>
        <label>Email</label>
        <div style={{margin: '6px 0'}}>{formik.values.fromEmail}</div>
        <label>差出人名</label>
        <Input
          name="fromName"
          defaultValue={formik.values.fromName}
          ref={inputRef}
        />
      </div>
      <div>
        <Button
          style={{marginRight: '10px'}}
          loading={loading}
          onClick={() => setPopoverVisible(false)}>
          キャンセル
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            const fromNameInput = inputRef?.current?.input;
            formik.setFieldValue('fromName', fromNameInput.value);
            setPopoverVisible(false);
          }}>
          更新
        </Button>
      </div>
    </FromNameContainer>
  );

  return (
    <Modal
      visible={isOpen}
      onCancel={() => onClose()}
      width={700}
      bodyStyle={{padding: 0}}
      footer={null}>
      <Container>
        <Header>
          <h4>Eメールの作成</h4>
        </Header>
        <Form onFinish={formik.handleSubmit}>
          <FieldGroup>
            <Field>
              <label>ステップ</label>
              <Select
                value={formik.values.actionUuid}
                disabled={actionStatus === 'completed'}
                onChange={(value) => formik.setFieldValue('actionUuid', value)}>
                <Select.Option value={null}>未選択</Select.Option>
                {openManualMailActions?.map((action) => (
                  <Select.Option value={action.uuid} key={action.uuid}>
                    {action.step.name}
                  </Select.Option>
                ))}
              </Select>
            </Field>

            <Field>
              <label>ステージ</label>
              <Select
                value={formik.values.stage}
                onChange={(value) => formik.setFieldValue('stage', value)}>
                {prospectStages.map((stage) => (
                  <Select.Option key={stage.id} value={stage.id}>
                    {stage.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          </FieldGroup>

          <FieldGroup>
            <div style={{padding: '0 15px'}}>
              {areCcAndBccSelectable ? (
                <>
                  <Field>
                    <label>Cc</label>
                    <Select
                      mode="tags"
                      bordered={false}
                      value={formik.values.cc}
                      onChange={(values: [string]) =>
                        formik.setFieldValue(
                          'cc',
                          values.filter((value) =>
                            Yup.string().trim().email().isValidSync(value),
                          ),
                        )
                      }>
                      {users.map((user) => (
                        <Select.Option key={user.uuid} value={user.email}>
                          {user.lastName} {user.firstName}
                          {`<${user.email}>`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Field>

                  <Field>
                    <label>Bcc</label>
                    <Select
                      mode="tags"
                      bordered={false}
                      value={formik.values.bcc}
                      onChange={(values: [string]) =>
                        formik.setFieldValue(
                          'bcc',
                          values.filter((value) =>
                            Yup.string().trim().email().isValidSync(value),
                          ),
                        )
                      }>
                      {users.map((user) => (
                        <Select.Option key={user.uuid} value={user.email}>
                          {user.lastName} {user.firstName}
                          {`<${user.email}>`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Field>
                </>
              ) : (
                <Field
                  onClick={() => setAreCcAndBccSelectable(true)}
                  style={{cursor: 'pointer'}}>
                  <label>Cc Bcc</label>
                </Field>
              )}

              <Field>
                <label>差出人</label>
                <AutoComplete
                  bordered={false}
                  options={users.map((user) => ({
                    label: `${user.lastName} ${user.firstName} <${user.email}>`,
                    value: user.email,
                  }))}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  value={formik.values.fromEmail}
                  onChange={(value) => setFrom(value)}
                />
                <Popover
                  trigger="click"
                  visible={isPopoverVisible}
                  onVisibleChange={(visible) => setPopoverVisible(visible)}
                  placement="bottomRight"
                  content={() => <FromNameModal />}>
                  <Button type="text" style={{color: '#68B5FB'}}>
                    差出人名を編集
                  </Button>
                </Popover>
              </Field>

              <Field>
                <label>件名</label>
                <Input
                  name="subject"
                  value={formik.values.subject}
                  bordered={false}
                  onChange={(e) =>
                    formik.setFieldValue('subject', e.target.value)
                  }
                />
              </Field>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '15px',
              }}>
              <label
                style={{
                  display: 'inline-block',
                  marginTop: '15px',
                  paddingLeft: '15px',
                }}>
                本文
              </label>
              <Select
                style={{
                  display: 'block',
                  margin: '0 15px 0 auto',
                  width: '360px',
                }}
                placeholder="メールテンプレート"
                optionFilterProp="children"
                showSearch
                onSelect={(value: any) => {
                  const template = templates.find(
                    (template) => template.uuid === value,
                  );
                  formik.setValues({
                    ...formik.values,
                    subject: template.mailTemplate.title,
                  });
                  template.mailTemplate.bodyHtml
                    ? (document.getElementsByClassName(
                        'ql-editor',
                      )[0].innerHTML = template.mailTemplate.bodyHtml)
                    : ref.current?.editor.setText(template.mailTemplate.body);
                }}>
                {templates.map((template) => (
                  <Select.Option key={template.uuid} value={template.uuid}>
                    {templateTitle(template)}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <ReactQuill
              ref={ref}
              theme="snow"
              placeholder="本文を記入してください。"
              onChange={(content, delta, source, editor) => {
                formik.setFieldValue('bodyHtml', content);
                formik.setFieldValue('bodyText', editor.getText());
              }}
              modules={modules}
              bounds={'#quill-container'}
            />
            {templates.map((template) => (
              <Select.Option key={template.uuid} value={template.uuid}>
                {template.mailTemplate.isShare
                  ? template.title
                  : `【${template.mailTemplate.user.lastName} ${template.mailTemplate.user.firstName}】${template.title} `}
              </Select.Option>
            ))}
          </FieldGroup>
          {formik.values.files.map((file) => (
            <File key={file.uid}>
              <FileName>{file.name}</FileName>
              <div
                onClick={() =>
                  formik.setFieldValue(
                    'files',
                    formik.values.files.filter((f) => f !== file),
                  )
                }></div>
            </File>
          ))}

          <Actions>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!formik.isValid}>
              送信
            </Button>
          </Actions>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  border: 1px solid #e2e6ea;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  label {
    color: #899098;
    font-weight: 500;
    font-size: 12px;
  }

  .ql-picker-label {
    padding-left: 10px;
  }

  .ql-toolbar {
    border: none;
    position: absolute;
    bottom: 40px;
    transform: translateY(100%);
  }
  .ql-snow {
    border: none;
    padding: 0;
  }
  .ql-editor {
    padding: 10px 15px;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }

  .ql-snow .ql-picker.ql-size {
    width: 50px;

    .ql-picker-label {
      padding-left: 0;
    }

    .ql-picker-options {
      width: 50px;
    }
  }
`;

const Header = styled.div`
  background: #f3f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 59px;
  padding-left: 11px;

  h4 {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 17px;
  margin-bottom: 14px;
`;

const Field = styled.div`
  flex: 1;
  min-height: 45px;
`;

const FieldGroup = styled.div`
  &:first-child {
    display: flex;
    padding: 8px 0 20px 16px;
    border-bottom: 1px solid #e1e6eb;

    label {
      display: block;
    }

    &&& {
      .ant-select {
        width: 210px;
      }
    }
  }

  &:nth-child(2) {
    ${Field} {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e1e6eb;
    }

    label {
      width: 48px;
    }

    &&& {
      .ant-select,
      .ant-input {
        width: 100%;
      }
    }
  }
`;

const File = styled.div`
  height: 30px;
  background: #f3f5f7;
  border-radius: 2px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    margin-right: 10px;
    width: 7px;
    height: 7px;
    path {
      stroke: #899098;
      stroke-width: 1.5px;
    }
  }
`;

const FileName = styled.div`
  width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: bold;
  font-size: 12px;
  color: #68b5fb;
`;

const FromNameContainer = styled.div`
  width: 315px;
  padding: 12px;

  h3 {
    font-weight: bold;
    font-size: 14px;
  }
  div:last-child {
    display: flex;
    justify-content: end;
  }
  label {
    color: #899098;
  }
`;
