import React from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'components/antd';
import {
  useCountDuplicatePreleadProjectsQuery,
  useDestroyDuplicatePreleadProjectsMutation,
} from 'api';
import {useParams} from 'react-router';
import {Trash} from 'components/Ui/Icon';
import {CubeLoading} from 'components/Ui/Icon';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const {projectId} = useParams<{projectId: string}>();

  const {
    data: {countDuplicatePreleadProjects} = {},
    loading,
  } = useCountDuplicatePreleadProjectsQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'cache-and-network',
    skip: !projectId,
  });

  const [
    destroyDuplicate,
    destroyDuplicateVariables,
  ] = useDestroyDuplicatePreleadProjectsMutation({
    variables: {uuid: projectId},
    onCompleted: () => setModalVisible(false),
    refetchQueries: ['project', 'preleadProjects', 'currentUser'],
  });

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      visible={isModalVisible}
      width={270}
      footer={null}
      onCancel={() => setModalVisible(false)}
      centered>
      <Content>
        {loading ? (
          <CubeLoading />
        ) : countDuplicatePreleadProjects > 0 ? (
          <>
            <p>
              重複している企業があります。
              <br />
              削除しますか？
            </p>
            <em>{countDuplicatePreleadProjects}件</em>
            <Button
              danger
              type="primary"
              onClick={() => destroyDuplicate()}
              loading={destroyDuplicateVariables.loading}
              icon={<Trash color="#fff" />}>
              削除
            </Button>
            <Button
              disabled={destroyDuplicateVariables.loading}
              onClick={() => setModalVisible(false)}>
              キャンセル
            </Button>
          </>
        ) : (
          <p>重複している企業はありません</p>
        )}
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  p {
    text-align: center;
  }

  em {
    font-size: 24px;
    font-weight: bold;
    color: #f46868;
    font-style: normal;
    margin-bottom: 1rem;
  }

  button {
    width: 180px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 16px;
      margin-right: 3px;
    }
  }
`;
