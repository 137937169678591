import React from 'react';
import styled from 'styled-components';
import {Project} from 'api';
import {Row} from 'react-table';
import moment from 'moment';
import {ClockTime} from 'components/Ui/Icon';

interface Props {
  value: string;
  row: Row<Project>;
}

export default ({row}: Props) => {
  const project = row.original;

  const reminderCount = React.useMemo(
    () => project.preleadProjectReminders.length,
    [project],
  );

  const isAlert = React.useMemo(
    () =>
      project.preleadProjectReminders.some((reminder) =>
        moment().isSame(moment(reminder.remindAt), 'day'),
      ),
    [project],
  );

  return (
    <Container isAlert={isAlert}>
      <ClockTime />
      <span>{reminderCount}</span>
    </Container>
  );
};

const Container = styled.div<{isAlert: boolean}>`
  margin-left: 10px;
  display: flex;
  align-items: center;

  > svg {
    path {
      ${({isAlert}) => isAlert && 'stroke: #EB5757'};
    }
  }

  > span {
    margin-left: 8px;
    ${({isAlert}) => isAlert && 'color: #EB5757'};
  }
`;
