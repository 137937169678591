import React from 'react';
import {Tooltip} from 'components/antd';
import {BlockGroupMember} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<BlockGroupMember>;
}

const NayoseCell = ({row}: Props) => {
  const blockMember: BlockGroupMember = row.original;

  if (!blockMember.companyId) {
    return null;
  }

  return (
    <Tooltip placement="right" title={'名寄せが完了しています'}>
      <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 14.667A6.667 6.667 0 108 1.334a6.667 6.667 0 000 13.333z"
          fill={'#4FCE82'}
          stroke={'#4FCE82'}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.068 8l1.934 1.933L10.935 6"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Tooltip>
  );
};

export default NayoseCell;
