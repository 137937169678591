import React from 'react';
import useCondition from '../../useCondition';
import MARKETS from 'helpers/markets';

export default () => {
  const {condition} = useCondition();

  if (!condition?.listingMarkets || !condition?.listingMarkets.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">上場区分</h4>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {MARKETS.filter((listingMarket) =>
          condition.listingMarkets?.includes(String(listingMarket.id)),
        ).map((listingMarket) => (
          <span
            key={listingMarket.id}
            className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {listingMarket.name}
          </span>
        ))}
      </div>
    </div>
  );
};
