import gql from 'graphql-tag';
import formBlockFieldCustomizeItemFragment from './blockFieldCustomizeItem';

export default gql`
  fragment formPostCustomizeItemNumberFragment on FormPostCustomizeItemNumber {
    id
    value
    formBlockFieldCustomizeItemId
    formBlockFieldCustomizeItem {
      ...formBlockFieldCustomizeItemFragment
    }
    createdAt
    updatedAt
  }
  ${formBlockFieldCustomizeItemFragment}
`;
