import React from 'react';
import useCondition from '../../../../useCondition';
import useFilterModalContext from '../../../useFilterModalContext';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {SalesforceContactRecordType} from 'api';
import SearchCondition from './SearchCondition';

interface Props {
  recordType: SalesforceContactRecordType;
}

export default ({recordType}: Props) => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  if (!recordType) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">取引先責任者</h4>
        <button
          onClick={() => setFilterType('salesforceContact')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setCondition({
              salesforceContactRecordTypeId: '',
              salesforceContactSearchConditions: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-c-light">レコードタイプ：</span>
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {recordType.name}
        </span>
      </div>
      {condition.salesforceContactSearchConditions.length > 0 &&
        condition.salesforceContactSearchConditions.map(
          (searchCondition, i) => (
            <SearchCondition key={i} searchCondition={searchCondition} />
          ),
        )}
    </div>
  );
};
