import React from 'react';

export const PreleadContext = React.createContext({
  preleadId: null,
});

const usePreleadContext = () => {
  const preleadContext = React.useContext(PreleadContext);

  return preleadContext;
};

export default usePreleadContext;
