import styled from 'styled-components';
import {Pagination} from 'components/antd';

export default styled(Pagination)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;

    a {
      color: #495058;
      background: #e2e6ea;
      border-radius: 5px;
    }
  }
`;
