import {NumberParam, StringParam} from 'use-query-params';

const blockMembersQueryParamsDef = {
  page: NumberParam,
  searchWord: StringParam,
};

const blockMembersSearchParams = (query: any) => {
  return {
    searchWord: query.searchWord,
  };
};

export {blockMembersQueryParamsDef, blockMembersSearchParams};
