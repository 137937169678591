import React from 'react';
import {useUsersByClientAllQuery, User} from 'api';
import useSearchParams from '../useSearchParams';
import Close from './Close';

export default () => {
  const {query, setQuery} = useSearchParams();
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  return (
    <div className="flex gap-2">
      {users
        .filter((user) => query.userIds.includes(user.id))
        .map((item: User) => {
          return (
            <div
              key={item.id}
              className="bg-[#F3F5F7] h-7 p-2 flex gap-1 items-center">
              <div className="text-[#495058]">{`${item?.lastName} ${item?.firstName}`}</div>
              <div
                className="flex items-center hover:cursor-pointer"
                onClick={() => {
                  setQuery({
                    userIds: query.userIds.filter(
                      (userId) => userId !== item.id,
                    ),
                  });
                }}>
                <Close />
              </div>
            </div>
          );
        })}
      {query.userIds?.length > 0 && (
        <div
          className="text-[#68B5FB] flex items-center font-bold hover:cursor-pointer"
          onClick={() => setQuery({userIds: []})}>
          All Clear
        </div>
      )}
    </div>
  );
};
