import React from 'react';
import moment from 'moment';
import {ProspectActivity, HubspotNoteProspectActivity} from 'api';
import {ProspectCommentBadge, Calendar, Hubspot} from 'components/Ui/Icon';

interface HubspotNoteActivity extends ProspectActivity {
  resource: HubspotNoteProspectActivity;
}

interface Props {
  prospectActivity: HubspotNoteActivity;
  setIsHubspotNoteEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHubspotNote: React.Dispatch<
    React.SetStateAction<HubspotNoteProspectActivity>
  >;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      commentHtml,
      createdAt,
      recordedAt,
      stageText,
      hubspotContactIdString,
    },
    user,
  } = prospectActivity;

  return (
    <>
      <ProspectCommentBadge />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-2 min-h-[32px]">
          {hubspotContactIdString && (
            <span className="bg-[#FFDBD5] h-5 px-2 text-xs text-[#FF7A59] rounded-sm flex items-center gap-1 [&>svg]:h-3 [&>svg]:w-auto">
              <Hubspot />
              コンタクト
            </span>
          )}
          <h3 className="flex-1 font-bold m-0">
            {prospectActivity?.action?.step?.name || 'コメント'}
          </h3>
          <span className="text-c-light">
            {user && [user.lastName, user.firstName].join(' ')}
          </span>
          <time className="text-c-light">
            {moment(createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </div>
        <div className="border border-c-border rounded">
          {commentHtml && (
            <div className="m-4">
              <div dangerouslySetInnerHTML={{__html: commentHtml}} />
            </div>
          )}
          <div className="m-4 flex items-center gap-4">
            {stageText && (
              <span className="bg-[#dceeff] text-xs text-[#2d9cdb] h-5 px-4 rounded-sm">
                {stageText}
              </span>
            )}
            {recordedAt && (
              <span className="flex items-center relative gap-1 [&>svg]:h-[18px]">
                <Calendar />
                <span>
                  {recordedAt && moment(recordedAt).format('YYYY/MM/DD HH:mm')}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
