import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignMailOpenActivityFragment on MailCampaignMailOpenActivity {
    id
    mailCampaignSend {
      id
      createdAt
      mailCampaign {
        id
        uuid
        title
        subject
        fromAddress
        fromName
        cc
        bcc
        sendAt
        isImmediately
        bodyHtml
        pool {
          uuid
        }
      }
    }
  }
`;
