import gql from 'graphql-tag';

export default gql`
  fragment automaticMailFragment on AutomaticMail {
    id
    bodyHtml
    bodyText
    from
    fromEmail
    fromName
    subject
    cc
    bcc
  }
`;
