import React from 'react';
import styled from 'styled-components';
import NgReasonReport from './NgReasonReport';
import StatusReport from './StatusReport';

export default () => {
  return (
    <Container>
      <StatusReport />
      <NgReasonReport />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
