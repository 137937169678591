import React from 'react';
import StatusSelect from './StatusSelect';

const RecordType = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-c-base font-bold">リード状況</div>
      <StatusSelect />
    </div>
  );
};

export default RecordType;
