import React from 'react';
import useSearchParams from '../../../useSearchParams';
import {InputNumber, Form} from 'components/antd';
import styled from 'styled-components';

interface Props {
  filter: string;
  value: {from: number; to: number};
}

const CustomizeItemNumberFilter = ({filter, value}: Props) => {
  const {setQuery} = useSearchParams();
  const [from, setFrom] = React.useState(value?.from);
  const [to, setTo] = React.useState(value?.to);

  React.useEffect(() => {
    setFrom(value?.from);
    setTo(value?.to);
  }, [value]);

  return (
    <Container>
      <Form.Item>
        <StyledInputNumber
          min={1}
          value={from}
          onChange={(value: number) => setFrom(value)}
          onBlur={() => from && setQuery({[filter]: {from, to: to || ''}})}
        />
        <span> 〜 </span>
        <StyledInputNumber
          min={1}
          value={to}
          onChange={(value: number) => setTo(value)}
          onBlur={() => to && setQuery({[filter]: {from: from || '', to}})}
        />
      </Form.Item>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  border: 1px solid rgb(217, 217, 217) !important;
  width: 175px;
`;

export default CustomizeItemNumberFilter;
