import {Action} from 'api';
import React from 'react';
import {Row} from 'react-table';
import {useRecoilState} from 'recoil';
import checkedActionIdsAtom from '../checkedActionIdsAtom';

interface Props {
  row: Row<Action>;
}

const CheckCell = ({row}: Props) => {
  const action = row.original;
  const [checkedActionIds, setCheckedActionIds] =
    useRecoilState(checkedActionIdsAtom);

  const checked = React.useMemo(
    () => checkedActionIds.includes(action.uuid),
    [checkedActionIds, action],
  );

  const checkAction = React.useCallback(() => {
    if (checkedActionIds.includes(action.uuid)) {
      setCheckedActionIds(checkedActionIds.filter((id) => id !== action.uuid));
    } else {
      setCheckedActionIds([...checkedActionIds, action.uuid]);
    }
  }, [checkedActionIds, action]);

  return <input type="checkbox" checked={checked} onChange={checkAction} />;
};

export default CheckCell;
