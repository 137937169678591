import gql from 'graphql-tag';

export default gql`
  fragment pressReleaseFragment on Prtime {
    id
    url
    title
    date
  }
`;
