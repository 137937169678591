import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {usePrefectureTypesByCityIdsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {prefectureTypesByCityIds = []} = {}} =
    usePrefectureTypesByCityIdsQuery({
      variables: {
        cityIds: query.cityIds,
      },
      skip: !query?.cityIds?.length,
    });

  // 都道府県内すべての市町村をを選択した場合まとめて都道府県名を表示
  const items = React.useMemo(() => {
    const queryCityIds = query.cityIds.filter((cityId) => cityId !== '');

    const prefectureTypes = prefectureTypesByCityIds.filter((prefectureType) =>
      prefectureType.cities.every((city) => queryCityIds.includes(city.id)),
    );

    const singleCities = queryCityIds.filter(
      (cityId) =>
        !prefectureTypes
          .map((prefectureType) => prefectureType.cities)
          .flat()
          .map((city) => city.id)
          .includes(cityId),
    );

    const cities: {
      id: string;
      name: string;
    }[] = [];

    singleCities.forEach((cityId) => {
      const city = prefectureTypesByCityIds
        .find((prefectureType) =>
          prefectureType.cities.find((city) => city.id === cityId),
        )
        ?.cities.find((city) => city.id === cityId);
      if (city) {
        cities.push(city);
      }
    });

    return [
      ...prefectureTypes.map(
        (prefectureType) => `${prefectureType.name}すべて`,
      ),
      ...cities.map((city) => city.name),
    ];
  }, [query, prefectureTypesByCityIds]);

  if (!query.cityIds || !query.cityIds.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">エリア</h4>
        <button
          onClick={() => setFilterType('city')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setQuery({...query, cityIds: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap items-center">
          {items.map((item, i) => (
            <span
              key={i}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2 mr-1">
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
