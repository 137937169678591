import gql from 'graphql-tag';

export default gql`
  fragment salesforceDealFieldFragment on SalesforceDealField {
    id
    fieldName
    fieldType
    label
    isDisplay
    isRequired
    referenceType
  }
`;
