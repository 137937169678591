import {useProjectWithSearchConditionQuery} from 'api';
import {ApiIntegrationConnection} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  TagBox,
  TagItem,
} from './styles';
import defaultTechnologies from '../search/technology/technologies';
import styled from 'styled-components';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {
    data: {
      project: {
        projectSearchCondition: {technologies = []},
      },
    } = {},
  } = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  if (technologies?.length === 0) return null;

  let parentTechnologies: string[] = [];
  defaultTechnologies.forEach((defaultTechnology) => {
    defaultTechnology.technologies.forEach((childTechnology) => {
      if (technologies?.includes(childTechnology.value))
        parentTechnologies.push(defaultTechnology.text);
    });
  });
  parentTechnologies = [...new Set(parentTechnologies)];

  const Tags = (parentTechnology: string) => {
    const defaultChildTechnologies = defaultTechnologies.find(
      (defaultTechnology) => defaultTechnology.text === parentTechnology,
    ).technologies;

    const childTechnologies = defaultChildTechnologies.filter(
      (childTechnology) => technologies?.includes(childTechnology.value),
    );

    if (defaultChildTechnologies.length === childTechnologies.length)
      return <TagItem>すべて</TagItem>;

    return childTechnologies.map((childTechnology) => (
      <TechnologyTagItem key={childTechnology.text}>
        {childTechnology.text}
      </TechnologyTagItem>
    ));
  };

  return (
    <Container>
      <Category>
        <CategoryTitle>
          <ApiIntegrationConnection />
          導入サービス
        </CategoryTitle>
        {parentTechnologies.map((parentTechnology) => (
          <ChildCategory key={parentTechnology}>
            <ChildCategoryTitleA>{parentTechnology}</ChildCategoryTitleA>
            <TagBox>{Tags(parentTechnology)}</TagBox>
          </ChildCategory>
        ))}
      </Category>
    </Container>
  );
};

const TechnologyTagItem = styled.span`
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #222426;
  border: solid 1px #e2e6ea;
`;
