import gql from 'graphql-tag';
import automaticMailActionFragment from './automaticMail';
import manualMailActionFragment from './manualMail';
import callActionFragment from './call';
import taskActionFragment from './task';

export default gql`
  fragment actionFragment on Action {
    id
    uuid
    status
    statusText
    startAt
    startDate
    dueDate
    stepId
    workflowProspectPoolId
    userId
    updatedAt
    createdAt
    completedAt
    actionableId
    actionableType
    actionable {
      ... on AutomaticMailAction {
        ...automaticMailActionFragment
      }
      ... on ManualMailAction {
        ...manualMailActionFragment
      }
      ... on CallAction {
        ...callActionFragment
      }
      ... on TaskAction {
        ...taskActionFragment
      }
    }
  }
  ${automaticMailActionFragment}
  ${manualMailActionFragment}
  ${callActionFragment}
  ${taskActionFragment}
`;
