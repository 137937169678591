import gql from 'graphql-tag';

export default gql`
  fragment reportCampaignCategoryFragment on ReportCampaignCategory {
    id
    campaignId
    categoryType
    clickRate
    countClick
    countUnsent
    countMail
    countSent
    countCannotRead
    countInvalid
    countShortage
    countNotfound
    countCannotSend
    countCharOver
    countUnknown
    countRecruit
    countCannotaccept
    countDuplicate
    countBlock
  }
`;
