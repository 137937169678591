import React from 'react';
import {
  ProspectCustomizeItemDate,
  PoolProspectCustomizeItem,
  Prospect,
  useUpdateProspectCustomizeItemDateMutation,
} from 'api';
import {DatePicker as UIDatePicker} from 'components/antd';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
  prospect: Prospect;
  poolProspectCustomizeItem: PoolProspectCustomizeItem;
}

export default ({prospect, poolProspectCustomizeItem}: Props) => {
  const customizeItem = prospect.customizeItemDates.find(
    (item: ProspectCustomizeItemDate) =>
      item.poolProspectCustomizeItemId === poolProspectCustomizeItem.id,
  );

  const [value, setValue] = React.useState(
    customizeItem ? moment(customizeItem.value) : null,
  );

  const [updateCustomizeItem] = useUpdateProspectCustomizeItemDateMutation({});

  const update = (value: Date) => {
    updateCustomizeItem({
      variables: {
        uuid: prospect.uuid,
        poolProspectCustomizeItemId: poolProspectCustomizeItem.id,
        value: value,
      },
    });
  };

  React.useEffect(() => {
    setValue(customizeItem ? moment(customizeItem.value) : null);
  }, [customizeItem]);

  React.useEffect(() => {
    update(value ? value.toDate() : null);
  }, [value]);

  return (
    <DatePicker
      allowClear
      onChange={setValue}
      placeholder="未入力"
      value={value}
      style={{width: '100%'}}
    />
  );
};

const DatePicker = styled(UIDatePicker)`
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
