import React, {useState} from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';
import {PlusOutlined} from '@ant-design/icons';
import Modal from './Modal';
import List from './List';

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <Container>
      <Header>
        <Title>リードソース</Title>
        <Button
          type="primary"
          style={{borderRadius: '4px'}}
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}>
          新規作成
        </Button>
      </Header>
      <List />
      <Modal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;
