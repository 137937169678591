import React from 'react';
import styled from 'styled-components';
import {Check} from 'components/Ui/Icon';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';

const SecondMenu = () => {
  const [pageType, setPageType] = useQueryParam(
    'pageType',
    withDefault(StringParam, 'recruit'),
  );

  return (
    <Container>
      <MenuGroup>
        <Menu
          onClick={() => setPageType('recruit')}
          current={pageType === 'recruit' ? 1 : 0}>
          求人（中途）
          {pageType === 'recruit' && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          onClick={() => setPageType('recruitnew')}
          current={pageType === 'recruitnew' ? 1 : 0}>
          求人（新卒）
          {pageType === 'recruitnew' && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          onClick={() => setPageType('parttime')}
          current={pageType === 'parttime' ? 1 : 0}>
          求人（アルバイト）
          {pageType === 'parttime' && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          onClick={() => setPageType('press')}
          current={pageType === 'press' ? 1 : 0}>
          プレスリリース
          {pageType === 'press' && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          onClick={() => setPageType('service')}
          current={pageType === 'service' ? 1 : 0}>
          サービス・プロダクト
          {pageType === 'service' && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
        <Menu
          onClick={() => setPageType('welfare')}
          current={pageType === 'welfare' ? 1 : 0}>
          介護・福祉
          {pageType === 'welfare' && (
            <SelectLabel>
              <Check />
            </SelectLabel>
          )}
        </Menu>
      </MenuGroup>
    </Container>
  );
};

export {SecondMenu};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  min-width: 300px;
  border-right: 1px solid #e2e6ea;

  a {
    text-align: left;
    width: 100%;
    height: 50px;
    background: transparent;
    font-size: 12px;
    color: #bdc4cb;
    box-shadow: none;
  }
`;

const MenuGroup = styled.div`
  overflow-y: scroll;
`;

const Menu = styled.span<{current?: number}>`
  &&& {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 14px;
    padding-left: 25px;
    color: #222426;
    white-space: pre;
    background: ${(props) => (props.current ? '#E2E6EA;' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }
  }
`;

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
  height: 20px;
`;
