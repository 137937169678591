import React, {useMemo} from 'react';
import {useProjectTelReportsByAreaQuery} from 'api';
import {useParams} from 'react-router-dom';
import Table from '../Table';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {projectTelReportsByArea = []} = {}} =
    useProjectTelReportsByAreaQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
    });

  const data: any = useMemo(
    () =>
      projectTelReportsByArea.map((report) => {
        return {
          name: report.prefectureName,
          callCount: report.callCount,
          contactCount: report.contactCount,
          contactCallRate: report.contactCallRate,
          documentCount: report.documentCount,
          documentCallRate: report.documentCallRate,
          appointCount: report.appointCount,
          appointCallRate: report.appointCallRate,
        };
      }),
    [projectTelReportsByArea],
  );

  return <Table data={data} />;
};
