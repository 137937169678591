const preleadStatusOptions: {[key: string]: string} = {
  pending: '未',
  approaching: 'アプローチ中',
  appoint: 'アポイント',
  lead: 'リード獲得',
  webinar: 'ウェビナー集客',
  download: '資料ダウンロード',
  interview: 'インタビュー訴求',
  recyclelead: 'リサイクルリード',
  inquiry: '問い合わせ',
  refusal: 'お断り',
  block: 'ブロック',
};

const mailStatusOptions: {[key: string]: string} = {
  unsent: '未送信',
  sent: '送信済み',
  mail: 'メール送信済み',
  invalid: '項目エラー',
  shortage: '項目不足',
  notfound: '見つからない',
  charover: '文字数オーバー',
  recruit: '(不適切)採用/商品フォーム',
  cannotaccept: '(不適切)営業お断り/その他',
  unknown: '送信不可',
  duplicate: '重複',
  block: 'ブロック',
  nourl: 'URLなし',
};

export {preleadStatusOptions, mailStatusOptions};
