import React from 'react';
import {useUpdateCollectedFormUrlMutation, CollectedFormUrl} from 'api';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const [update] = useUpdateCollectedFormUrlMutation();
  const [title, setTitle] = React.useState(collectedFormUrl.title || '');

  const updateTitle = () => {
    if (title) {
      update({
        variables: {
          collectedFormUrlUuid: collectedFormUrl.uuid,
          attributes: {title},
        },
      });
    } else {
      alert('タイトルは必須です');
    }
  };

  React.useEffect(() => {
    setTitle(collectedFormUrl?.title);
  }, [collectedFormUrl]);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      updateTitle();
    }
  };

  return (
    <input
      value={title || ''}
      onChange={(e) => setTitle(e.target.value)}
      onBlur={updateTitle}
      onKeyDown={onKeyDown}
      className="flex-1 text-xl font-bold border-transparent bg-transparent rounded"
    />
  );
};
