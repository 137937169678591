import React from 'react';
import {Input, Form} from 'components/antd';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {Folder, Note, Calendar, Cross} from 'components/Ui/Icon';
import {Button} from 'components/Ui';
const {TextArea} = Input;
import {useFormik} from 'formik';
import {useCreateBlockGroupMutation} from 'api';
import * as Yup from 'yup';
import moment from 'moment';

interface Props {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const validateSchema = Yup.object().shape({
  name: Yup.string().required('必須項目です'),
});

export default ({setModalVisible}: Props) => {
  const history = useHistory();

  const [createBlockGroup, {loading}] = useCreateBlockGroupMutation({
    refetchQueries: ['blockGroups'],
    onCompleted: () => {
      history.push(`/block/block_groups`);
      setModalVisible(false);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '新規ブロックグループ名',
      memo: '',
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      createBlockGroup({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
    },
  });

  const {values, isValid, handleChange, handleSubmit} = formik;

  return (
    <Container>
      <Header>
        <Title>ブロックグループ新規作成</Title>
        <CrossIconWrapper
          onClick={() => {
            history.push(`/block/block_groups`);
            setModalVisible(false);
          }}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <CustomForm onFinish={handleSubmit}>
        <Item>
          <Folder />
          <Name name="name" value={values.name} onChange={handleChange} />
        </Item>
        <ItemMemo>
          <Note />
          <Memo
            name="memo"
            value={values.memo}
            onChange={handleChange}
            autoSize={{minRows: 1, maxRows: 6}}
          />
        </ItemMemo>
        <ItemCreatedAt>
          <Calendar />
          {moment().format('YYYY年MM月DD日')}
        </ItemCreatedAt>
        <ButtonWrapper>
          <Button
            htmlType="submit"
            loading={loading}
            disabled={!isValid || loading}>
            保存
          </Button>
        </ButtonWrapper>
      </CustomForm>
    </Container>
  );
};

const Container = styled.div``;

const Header = styled.div`
  padding: 19px 0 19px 17px;
  background: #f3f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 9px;
  color: #899098;
  letter-spacing: 0.15px;
`;

const CrossIconWrapper = styled.div`
  margin-right: 18px;

  svg {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
`;
const CustomForm = styled(Form)`
  padding: 35px 50px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const ItemCreatedAt = styled(Item)`
  font-size: 12px;
  color: #899098;
`;

const Name = styled(Input)`
  width: 760px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: #e2e6ea;
  border-radius: 4px;

  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const Memo = styled(TextArea)`
  width: 760px;
  height: 130px !important;
  word-break: break-all;
  border-color: #e2e6ea;
  border-radius: 4px;

  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  button {
    margin-left: auto;
    width: 138px;
    height: 36px;
  }
`;
