import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {usePreleadMailCampaignsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {preleadMailCampaigns: {mailCampaigns = []} = {}} = {}} =
    usePreleadMailCampaignsQuery({});

  if (!query?.mailCampaignUuid) {
    return null;
  }

  const findMailCampaign = mailCampaigns.find(
    (mailCampaign) => mailCampaign.uuid === query.mailCampaignUuid,
  );

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">メール配信</h4>
        <button
          onClick={() => setFilterType('mailCampaign')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              mailCampaignUuid: '',
              firstOpenedFrom: '',
              firstOpenedTo: '',
              clickedFrom: '',
              clickedTo: '',
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap gap-1">
        {findMailCampaign && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {findMailCampaign.title}
          </span>
        )}
      </div>
      {(query.firstOpenedFrom || query.firstOpenedTo) && (
        <div>
          <span className="text-c-light">初回開封日：</span>
          {query.firstOpenedFrom && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.firstOpenedFrom}
            </span>
          )}
          <span className="text-c-light px-2">~</span>
          {query.firstOpenedTo && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.firstOpenedTo}
            </span>
          )}
        </div>
      )}
      {(query.clickedFrom || query.clickedTo) && (
        <div>
          <span className="text-c-light">最新クリック日：</span>
          {query.clickedFrom && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.clickedFrom}
            </span>
          )}
          <span className="text-c-light px-2">~</span>
          {query.clickedTo && (
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {query.clickedTo}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
