import React from 'react';
import {HubspotCompany, HubspotCompanyPropertyDefinition} from 'api';
import moment from 'moment';
import OwnerProperty from './Owner';

interface Props {
  propertyDefinition: HubspotCompanyPropertyDefinition;
  hubspotCompany: HubspotCompany;
}

const PropertyComponents: {[key: string]: any} = {
  hubspot_owner_id: OwnerProperty,
};

const Property = ({propertyDefinition, hubspotCompany}: Props) => {
  const PropertyComponent = PropertyComponents[propertyDefinition.name];

  if (PropertyComponent)
    return <PropertyComponent hubspotCompany={hubspotCompany} />;

  const value = hubspotCompany.properties[propertyDefinition.name];

  if (value === null || value === undefined) return null;

  if (propertyDefinition.propertyType === 'string') return value;
  if (propertyDefinition.propertyType === 'number')
    return Number(value).toLocaleString();
  if (propertyDefinition.propertyType === 'boolean')
    return value === 'true' ? 'はい' : 'いいえ';
  if (propertyDefinition.propertyType === 'date')
    return moment(value).format('YYYY/MM/DD');
  if (propertyDefinition.propertyType === 'datetime')
    return moment(value).format('YYYY/MM/DD HH:mm');
  if (propertyDefinition.propertyType === 'enumeration')
    return value
      .split(';')
      .map(
        (v: string) =>
          propertyDefinition.options.find((option) => option.value === v)
            ?.label,
      )
      .join(', ');

  return null;
};

export default Property;
