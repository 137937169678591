import React from 'react';
import {usePoolQuery} from 'api';
import useCondition from '../../useCondition';

export default () => {
  const {condition} = useCondition();
  const {data: {pool: {leadSources = []} = {}} = {}} = usePoolQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (
    !condition?.originalLeadSources ||
    !condition.originalLeadSources.length
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">初回リードソース</h4>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {condition?.originalLeadSources.find(
          (leadSource) => leadSource === '0',
        ) && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            未設定
          </span>
        )}
        {leadSources
          .filter((leadSource) =>
            condition.originalLeadSources.includes(leadSource.id),
          )
          .map((leadSource) => (
            <span
              key={leadSource.id}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {leadSource.name}
            </span>
          ))}
      </div>
    </div>
  );
};
