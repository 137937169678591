import {
  ReportGroupTable,
  ReportTableGroupData,
  ReportTableGroupHeader,
} from '../../components/Table';

const useTableData = (): ReportGroupTable => {
  const headers: ReportTableGroupHeader[] = [
    {
      header: '2024年',
      columns: [],
    },
    {
      header: '合計',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '1月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '2月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '3月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '4月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '5月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '6月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '7月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '8月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '9月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '10月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '11月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
    {
      header: '12月',
      columns: [
        '目標',
        '実績',
        {
          header: '達成率',
        },
      ],
    },
  ];

  const data: ReportTableGroupData[] = [
    {
      label: '合計',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
      cellProps: {
        style: {
          borderBottom: 'double 3px #899098',
        },
      },
    },
    {
      label: '逆営業',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
    {
      label: 'ウェビナー',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
    {
      label: 'オフラインイベント',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
    {
      label: 'アイミツSaaS',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
    {
      label: 'リファラル（VC）',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
    {
      label: '【SF】資料ダウンロード',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
    {
      label: 'LEADPADアカウント保有者',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
    {
      label: '掘り起こし',
      values: [
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
        [720, 687, '94%'],
      ],
    },
  ];

  return {
    data,
    headers,
  };
};

export default useTableData;
