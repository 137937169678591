import React from 'react';
import {
  SalesforceAccount,
  SalesforceAccountField,
  useUpdateSalesforceAccountFieldCheckboxValueMutation,
} from 'api';

interface Props {
  salesforceAccount: SalesforceAccount;
  field: SalesforceAccountField;
}

const CheckboxFieldValue = ({salesforceAccount, field}: Props) => {
  const value = salesforceAccount.addition[`${field.fieldName}`];
  const [erorrMessage, setErrorMessage] = React.useState('');
  const [updateFieldValue] =
    useUpdateSalesforceAccountFieldCheckboxValueMutation({
      onCompleted: (data) => {
        setErrorMessage(data.updateSalesforceAccountFieldCheckboxValue.error);
      },
    });
  return (
    <>
      <input
        className="cursor-pointer"
        type="checkbox"
        name={field.label}
        checked={value}
        disabled={!field.fieldName.endsWith('__c')}
        onChange={(e) =>
          updateFieldValue({
            variables: {
              salesforceAccountId: salesforceAccount.id,
              attributes: {
                fieldName: field.fieldName,
                value: e.target.checked,
              },
            },
          })
        }
      />
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </>
  );
};

export default CheckboxFieldValue;
