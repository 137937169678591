import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Form, Input} from 'components/antd';
import {SearchOutlined} from '@ant-design/icons';
import useSearchCondition from '../useSearchCondition';

export default ({pageKeywordsRef, pageNegativeKeywordsRef}: any) => {
  const {query, setQuery} = useSearchCondition();

  const handleChangeKeywords = React.useCallback(
    (e: any) =>
      setQuery({
        pageKeywords: e.currentTarget.value
          ?.replace(/[ |\u3000]+/g, ' ')
          .trim()
          .split(' '),
      }),
    [],
  );

  const handleChangeNegativeKeywords = React.useCallback(
    (e: any) =>
      setQuery({
        pageNegativeKeywords: e.currentTarget.value
          ?.replace(/[ |\u3000]+/g, ' ')
          .trim()
          .split(' '),
      }),
    [],
  );

  return (
    <KeywordSearch>
      <Form layout="vertical">
        <Form.Item label="キーワード">
          <Input
            name="pageKeywords"
            prefix={<SearchOutlined />}
            placeholder="キーワード"
            defaultValue={query.pageKeywords?.join(' ')}
            onPressEnter={handleChangeKeywords}
            onBlur={handleChangeKeywords}
            ref={pageKeywordsRef}
          />
        </Form.Item>
        <Form.Item label="除外キーワード">
          <Input
            name="pageNegativeKeywords"
            prefix={<SearchOutlined />}
            placeholder="除外キーワード"
            defaultValue={query.pageNegativeKeywords?.join(' ')}
            onPressEnter={handleChangeNegativeKeywords}
            onBlur={handleChangeNegativeKeywords}
            ref={pageNegativeKeywordsRef}
          />
        </Form.Item>
      </Form>
    </KeywordSearch>
  );
};

const KeywordSearch = styled.div`
  height: 80px;
  overflow: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  padding: 0 2rem;
  display: flex;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .ant-form-item {
    margin: 0;
    width: calc(50% - 1rem);
    max-width: 300px;
    margin-right: 1rem;
  }
`;
