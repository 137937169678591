import React from 'react';
import {Tooltip} from 'components/antd';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const DuplicateCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;

  if (!preleadProject.isDuplicate) {
    return null;
  }

  return (
    <Tooltip placement="right" title="重複している企業が存在しています">
      <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.468 12.313L6.777 2.67c.492-.893 1.956-.893 2.447 0l5.309 9.643c.43.782-.226 1.687-1.224 1.687H2.692c-.998 0-1.654-.905-1.224-1.687zM8 6v2.667M8 11.348v-.014"
          stroke="#F8D55C"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Tooltip>
  );
};

export default DuplicateCell;
