import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from 'components/antd';
import {useCurrentUserQuery} from 'api';

export default () => {
  const history = useHistory();
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  return (
    <Button.Group>
      <Button
        onClick={() => history.push(`/pools/${poolId}/prospects`)}
        className={
          history.location.pathname.startsWith(`/pools/${poolId}/prospects`)
            ? 'bg-[#e1e6eb] text-[#bdc4cb] border-[#bdc4cb]'
            : 'bg-white text-[#495058] border-[#bdc4cb]'
        }>
        リード
      </Button>
      <Button
        onClick={() => history.push(`/pools/${poolId}/accounts`)}
        className={
          history.location.pathname.startsWith(`/pools/${poolId}/accounts`)
            ? 'bg-[#e1e6eb] text-[#bdc4cb] border-[#bdc4cb]'
            : 'bg-white text-[#495058] border-[#bdc4cb]'
        }>
        企業
      </Button>
      <Button
        onClick={() => history.push(`/pools/${poolId}/account_groups`)}
        className={
          history.location.pathname.startsWith(
            `/pools/${poolId}/account_groups`,
          )
            ? 'bg-[#e1e6eb] text-[#bdc4cb] border-[#bdc4cb]'
            : 'bg-white text-[#495058] border-[#bdc4cb]'
        }>
        リスト
      </Button>
    </Button.Group>
  );
};
