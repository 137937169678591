import gql from 'graphql-tag';

export default gql`
  fragment letterSendActivityFragment on LetterSendActivity {
    sender
    senderPosition
    body
    note
    sendDate
    expectedArrivalDate
  }
`;
