/*eslint "no-console": "off"*/
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {ApolloProvider, ApolloClient, ApolloLink} from '@apollo/client';
import {InMemoryCache} from '@apollo/client/cache';
import {createUploadLink} from 'apollo-upload-client';
import {onError} from '@apollo/client/link/error';
import AppContainer from './views/client';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Modal from 'react-modal';
import {QueryParamProvider} from 'use-query-params';
import {ThemeProvider} from 'styled-components';
import theme from './theme';
import * as Sentry from '@sentry/react';
import {
  updateServiceWorker,
  registerServiceWorker,
} from 'helpers/serviceworker';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

const link = createUploadLink({
  uri: '/graphql',
  credentials: 'include',
}) as unknown as ApolloLink;

const mergedObjects = (
  existing: any,
  incoming: any,
  args: any,
  object_name: string,
) => {
  if (existing && args.page && args.scrollable) {
    if (existing[object_name].length < incoming.pagination.totalCount) {
      const existingObjects = existing ? existing[object_name] : [];
      const incomingObjects = incoming ? incoming[object_name] : [];
      let objects: Array<any> = [...existingObjects];

      for (let i = 0; i < incomingObjects.length; i++) {
        const current = incomingObjects[i] as any;
        const found = objects.findIndex((m) => m.__ref === current.__ref);

        if (found > -1) {
          objects[found] === current;
        } else {
          objects = [...objects, current];
        }
      }

      const capitalizeFirstChar = (str: string) => {
        if (!str || typeof str !== 'string') return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
      };

      const results: {[s: string]: any} = {};

      results['__typename'] = capitalizeFirstChar(object_name);
      results[object_name] = objects;
      results['pagination'] = incoming.pagination;

      return results;
    }
    return existing;
  } else {
    return incoming;
  }
};

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) => {
          const msg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
          console.log(msg);
          Sentry.captureMessage(msg);
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    link,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          blockGroups: {
            keyArgs: false,
            read(existing) {
              return existing;
            },
            merge(existing, incoming, {args}) {
              return mergedObjects(existing, incoming, args, 'blockGroups');
            },
          },
          projectGroups: {
            merge: false,
          },
          activities: {
            merge: false,
          },
          mailActivities: {
            merge: false,
          },
          telActivities: {
            merge: false,
          },
          preleadMasterColumns: {merge: false},
        },
      },
      BlockGroup: {
        fields: {blockGroupTags: {merge: false}},
      },
      Prelead: {
        fields: {
          telephoneNumbers: {merge: false},
          preleadFaxNumbers: {merge: false},
          mailAddresses: {merge: false},
          facebookUrls: {merge: false},
          twitterUrls: {merge: false},
          lineUrls: {merge: false},
          youtubeUrls: {merge: false},
          instagramUrls: {merge: false},
          linkedinUrls: {merge: false},
          preleadTags: {merge: false},
          activities: {merge: false},
          mailActivities: {merge: false},
          telActivities: {merge: false},
          customizeItemTexts: {merge: false},
          customizeItemNumbers: {merge: false},
          customizeItemDates: {merge: false},
          customizeItemSelects: {merge: false},
        },
      },
      ProjectSearchCondition: {
        fields: {
          categoryIds: {merge: false},
          cityIds: {merge: false},
          markets: {merge: false},
          companyTypes: {merge: false},
          salesRanges: {merge: false},
          profitRateRanges: {merge: false},
          parentCategories: {merge: false},
          childCategories: {merge: false},
          prefectures: {merge: false},
          cities: {merge: false},
        },
      },
      Client: {
        fields: {
          telStatuses: {merge: false},
          customizeItems: {merge: false},
        },
      },
      Project: {
        fields: {
          offeredClients: {merge: false},
          users: {merge: false},
        },
      },
      ContentMailForm: {
        fields: {
          urls: {merge: false},
        },
      },
      Workflow: {
        fields: {
          users: {merge: false},
          steps: {merge: false},
          searchCondition: {merge: false},
        },
      },
      ProspectPool: {
        fields: {
          prospectTags: {merge: false},
        },
      },
      PreleadCustomizeItemSelect: {
        fields: {
          preleadCustomizeItemSelectOptions: {merge: false},
        },
      },
      CollectedFormUrl: {
        fields: {
          users: {merge: false},
        },
      },
      ClientPreleadCustomizeItem: {
        fields: {
          selectOptions: {
            merge: false,
          },
        },
      },
    },
  }),
});

require('moment/locale/ja');
moment.locale('ja', {
  week: {
    dow: 1,
    doy: 4,
  },
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
});
Modal.setAppElement('#app');

const Client = () => {
  React.useEffect(() => {
    updateServiceWorker();
    registerServiceWorker();
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ApolloProvider client={client}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ThemeProvider theme={theme}>
              <AppContainer />
            </ThemeProvider>
          </QueryParamProvider>
        </Router>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
};

export default Client;

ReactDOM.render(<Client />, document.getElementById('app'));
