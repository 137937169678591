import React from 'react';

function Icon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M7.5 13.6875C10.2614 13.6875 12.5 11.4489 12.5 8.6875C12.5 5.92608 10.2614 3.6875 7.5 3.6875C4.73858 3.6875 2.5 5.92608 2.5 8.6875C2.5 11.4489 4.73858 13.6875 7.5 13.6875Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.1875 5.4375C0.25 4.5 0.25 3.0625 1.1875 2.125C2.125 1.1875 3.5625 1.1875 4.5 2.125L1.1875 5.4375Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 2.125C11.4375 1.1875 12.875 1.1875 13.8125 2.125C14.75 3.0625 14.75 4.5 13.8125 5.4375L10.5 2.125Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 6.1875V8.625L8.75 9.875"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 13.75L11 12.25"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 13.75L4 12.25"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
