import React from 'react';
import {useParams} from 'react-router-dom';
import {useProjectWithPreleadMasterSearchConditionQuery} from 'api';
import {Lense} from 'components/Ui/Icon';
import {
  Container,
  Category,
  CategoryTitle,
  Item,
  Title,
  TagBox,
  TagItem,
} from './styles';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {
    data: {project: {preleadMasterSearchCondition = null} = {}} = {},
  } = useProjectWithPreleadMasterSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  if (!preleadMasterSearchCondition) return null;

  return (
    <Container>
      {preleadMasterSearchCondition.searchWord && (
        <Category>
          <CategoryTitle>
            <Lense />
            社名・住所検索
          </CategoryTitle>
          <Item>
            <Title>入力ワード</Title>
            <TagBox>
              <TagItem>{preleadMasterSearchCondition.searchWord}</TagItem>
            </TagBox>
          </Item>
        </Category>
      )}
    </Container>
  );
};
