import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useCurrentUserQuery, useCurrentClientQuery} from 'api';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isAdmin} = useClientUser();
  const currentPath = (path: string) => location.pathname.indexOf(path) > -1;
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const {data: {currentClient: {currentLeadContract} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  return (
    <Container>
      <MenuGroup>
        <Caption>設定</Caption>
        {/*
        <Menu
          to="/settings/undefined"
          current={currentPath('/settings/undefined') ? 1 : 0}>
          基本設定
        </Menu>
        <Menu
          to="/settings/undefined"
          current={currentPath('/settings/undefined') ? 1 : 0}>
          プラン
        </Menu>
        <Menu
          to="/settings/undefined"
          current={currentPath('/settings/undefined') ? 1 : 0}>
          通知
        </Menu>
        */}
        {currentUser.role === 'admin' && (
          <Menu
            to="/settings/teams"
            current={currentPath('/settings/teams') ? 1 : 0}>
            チーム管理
          </Menu>
        )}
        <Menu
          to="/settings/users?active=0"
          current={currentPath('/settings/users') ? 1 : 0}>
          ユーザー管理
        </Menu>
        {isAdmin && currentLeadContract && (
          <Menu
            to="/settings/pools"
            current={currentPath('/settings/pools') ? 1 : 0}>
            リードプール一覧
          </Menu>
        )}
        {isAdmin && (
          <Menu
            to="/settings/status/prelead_status"
            current={currentPath('/settings/status') ? 1 : 0}>
            ステータス
          </Menu>
        )}
        {isAdmin && currentLeadContract && (
          <Menu
            to="/settings/phase"
            current={currentPath('/settings/phase') ? 1 : 0}>
            フェーズ
          </Menu>
        )}
        {isAdmin && (
          <Menu
            to="/settings/externals"
            current={currentPath('/settings/externals') ? 1 : 0}>
            外部連携
          </Menu>
        )}
        {currentUser.role === 'admin' && (
          <Menu
            to="/settings/score/company"
            current={currentPath('/settings/score') ? 1 : 0}>
            顧客スコア
          </Menu>
        )}
        {isAdmin && (
          <Menu
            to="/settings/share"
            current={currentPath('/settings/share') ? 1 : 0}>
            Share設定
          </Menu>
        )}
        {currentUser.role === 'admin' && (
          <Menu
            to="/settings/plan"
            current={currentPath('/settings/plan') ? 1 : 0}>
            プラン
          </Menu>
        )}
        <Caption>プロフィール</Caption>
        <Menu
          to="/settings/password"
          current={currentPath('/settings/password') ? 1 : 0}>
          パスワード変更
        </Menu>
        <Menu
          to="/settings/notifications"
          current={currentPath('/settings/notifications') ? 1 : 0}>
          通知設定
        </Menu>
        {currentUser.role === 'admin' && (
          <Menu
            to="/settings/mails"
            current={currentPath('/settings/mails') ? 1 : 0}>
            メール設定
          </Menu>
        )}
      </MenuGroup>
    </Container>
  );
};

const Container = styled.div`
  width: 220px;
  background: #f3f5f7;
`;

const MenuGroup = styled.div``;

const Caption = styled.div`
  font-size: 12px;
  color: #bdc4cb;
  padding: 20px 0 15px 25px;
`;

const Menu = styled(Link)<{current?: number}>`
  &&& {
    display: flex;
    padding: 15px 0 15px 25px;
    font-size: 14px;
    color: #222426;

    background: ${({current}) => (current ? '#fff;' : '')};
  }
`;
