import gql from 'graphql-tag';

export default gql`
  fragment catrsFragment on Catr {
    id
    date
    sales
    salesRate
    pretaxProfit
    pretaxProfitRate
    salesProfit
    salesProfitRate
    profit
    profitRate
    profitSurplus
    profitSurplusRate
    asset
    assetRate
  }
`;
