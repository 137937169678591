import React from 'react';
import {
  Container,
  PreleadCsvUploadModal,
  PreleadTelActivityCsvUploadModal,
  CreatePreleadModal,
} from 'components/Ui';
import {useParams, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import Menu from './Menu';
import {useProjectQuery, usePreleadProjectQuery} from 'api';
import Detail from './detail';
import GridTable from './GridTable';
import SelectCompanyModal from './SelectCompanyModal';
import useProjectPath from 'hooks/useProjectPath';
import Title from '../Title';
import Breadcrumb from './Breadcrumb';
import {ErrorBoundary} from 'react-error-boundary';
import * as Sentry from '@sentry/react';

export default () => {
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();

  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    onCompleted: () => {
      if (project?.status === 'deleted' || project === null) {
        history.push(createPath(`projects`));
      }
    },
  });

  const {data: {preleadProject = null} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    fetchPolicy: 'cache-and-network',
    skip: !preleadProjectId,
  });

  if (!project) {
    return null;
  }

  const [isCsvUploadModalVisible, setIsCsvUploadModalVisible] =
    React.useState(false);
  const [
    isCsvUploadWithInitializeDataModalVisible,
    setIsCsvUploadWithInitializeDataModalVisible,
  ] = React.useState(false);
  const [isPopoverVisible, setIsPopOverVisible] = React.useState(false);
  const [isCreatePreleadModalVisible, setIsCreatePreleadModalVisible] =
    React.useState(false);

  return (
    <>
      <Container page="projectGroups">
        <Header>
          <Breadcrumb />
        </Header>
        <Title
          setIsCsvUploadModalVisible={setIsCsvUploadModalVisible}
          setIsCsvUploadWithInitializeDataModalVisible={
            setIsCsvUploadWithInitializeDataModalVisible
          }
          setIsCreatePreleadModalVisible={setIsCreatePreleadModalVisible}
          setIsPopOverVisible={setIsPopOverVisible}
          isPopoverVisible={isPopoverVisible}
          project={project}
        />
        <Menu />
        <Body>
          <ErrorBoundary
            FallbackComponent={() => {
              return <div></div>;
            }}
            onError={(error: Error) => Sentry.captureException(error)}>
            <GridTable />
          </ErrorBoundary>
        </Body>
        {preleadProject !== null && <Detail />}
      </Container>
      <SelectCompanyModal />
      <PreleadCsvUploadModal
        project={project}
        visible={isCsvUploadModalVisible}
        onClose={() => setIsCsvUploadModalVisible(false)}
      />
      <PreleadTelActivityCsvUploadModal
        project={project}
        visible={isCsvUploadWithInitializeDataModalVisible}
        onClose={() => setIsCsvUploadWithInitializeDataModalVisible(false)}
      />
      <CreatePreleadModal
        project={project}
        visible={isCreatePreleadModalVisible}
        onClose={() => setIsCreatePreleadModalVisible(false)}
      />
    </>
  );
};

const Header = styled.div`
  span {
    color: #899098;
    font-weight: 500;
  }
  > span,
  a {
    &.name {
      color: #bdc4cb;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        color: #91ceff;
      }
    }
  }
`;

const Body = styled.div`
  width: calc(100vw - 49px);
  position: relative;
  display: flex;
`;
