import React from 'react';
import {useUpdateFormMutation, Form, useLeadSourcesQuery} from 'api';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery();

  const [update] = useUpdateFormMutation();

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    update({
      variables: {
        uuid: form.uuid,
        attributes: {
          leadSourceId: e.target.value,
        },
      },
      refetchQueries: ['form'],
    });

  return (
    <div className="flex flex-col gap-12 w-[480px]">
      <div className="flex flex-col gap-1">
        <label className="text-base text-c-base font-bold">リードソース</label>
        <p className="text-c-base text-sm m-0">
          フォームから問合せがあったリードに付与するリードソースの種類を設定できます。
        </p>
        <select
          value={form.leadSourceId || ''}
          onChange={onChange}
          className="form-select border-c-border rounded text-base w-full">
          <option value=""></option>
          {leadSources.map((leadSource) => (
            <option key={leadSource.id} value={leadSource.id}>
              {leadSource.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
