import React from 'react';
import {Task} from 'api';
import Status from './Status';
import TaskType from './TaskType';
import Priority from './Priority';
import Due from './Due';
import User from './User';
import EditModal from './EditModal';

interface Props {
  task: Task;
}

const Item = ({task}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div
        key={task.id}
        className="flex flex-col gap-2 border-b border-c-border py-4">
        <div
          className="text-lg font-bold truncate leading-none cursor-pointer"
          onClick={() => setIsOpen(true)}>
          {task.title}
        </div>
        <div className="flex items-center gap-2">
          <Status task={task} />
          <TaskType task={task} />
          <Priority task={task} />
          <Due task={task} />
          <User task={task} />
        </div>
      </div>
      <EditModal
        task={task}
        visible={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default Item;
