import {ProspectPool} from 'api';
import React from 'react';
import {History} from 'history';

export const SalesforceModalContext = React.createContext<{
  browserHistory: History<unknown>;
  prospectPool: ProspectPool;
  onClose: () => void;
  poolId: string;
}>(null);

const useSalesforceModalContext = () => {
  const modalContext = React.useContext(SalesforceModalContext);

  return modalContext;
};

export default useSalesforceModalContext;
