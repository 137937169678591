import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input} from 'components/antd';
import {
  useUpdateClientPhaseProspectMutation,
  ClientPhaseProspect,
  useDestroyClientPhaseProspectMutation,
  useClientPhaseProspectsQuery,
} from 'api';

interface Props {
  phase: ClientPhaseProspect;
  onClose: () => void;
}

const Update = ({phase, onClose}: Props) => {
  const [update] = useUpdateClientPhaseProspectMutation({
    onCompleted: () => onClose(),
    refetchQueries: ['clientPhaseProspects'],
  });
  const [destroy] = useDestroyClientPhaseProspectMutation({
    variables: {
      id: phase?.id,
    },
    onCompleted: ({destroyClientPhaseProspect: {error}}) => {
      error ? alert(error) : onClose();
    },
    refetchQueries: ['clientPhaseProspects'],
  });

  const {data: {clientPhaseProspects} = {}} = useClientPhaseProspectsQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: phase?.name,
      description: phase?.description,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('必須項目です')
        .notOneOf(
          clientPhaseProspects
            ?.filter((p) => p.id !== phase?.id)
            .map((p) => p.name) || [],
          '既に存在するフェーズ名です',
        ),
    }),
    onSubmit: (values) =>
      update({
        variables: {
          id: phase.id,
          attributes: {
            ...values,
          },
        },
      }),
  });

  return (
    <Modal
      destroyOnClose
      visible={Boolean(phase)}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2 className="pb-0">フェーズの編集・削除</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item
            label="フェーズ名"
            required
            validateStatus={formik.errors.name && 'error'}
            help={formik.errors.name}>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="フェーズの説明">
            <Input.TextArea
              name="description"
              autoSize={{minRows: 3}}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <div>
              <Button danger onClick={() => destroy()} size="large">
                削除
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{marginLeft: '8px'}}
                disabled={!formik.isValid}>
                更新
              </Button>
            </div>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Update;
