import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignBlockImageFragment on MailCampaignBlockImage {
    id
    bodyColor
    image
    imageAlt
    imageLink
    imageScale
    imageWidth
    imageHeight
    paddingTop
    paddingBottom
    paddingLeft
    paddingRight
    createdAt
    updatedAt
  }
`;
