import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignBlockUnsubscribeFragment on MailCampaignBlockUnsubscribe {
    textHtml
    bodyColor
    textColor
    paddingTop
    paddingBottom
    paddingLeft
    paddingRight
  }
`;
