import React from 'react';
import * as Sentry from '@sentry/react';
import {HubspotDealPropertyDefinition} from 'api';
import moment from 'moment';

interface Props {
  propertyDefinition: HubspotDealPropertyDefinition;
  value: any;
}

export const Value: React.FC<Props> = ({propertyDefinition, value}) => {
  try {
    if (['text', 'textarea'].includes(propertyDefinition.fieldTypeString)) {
      return <pre>{value}</pre>;
    }

    if (propertyDefinition.propertyTypeString === 'number')
      return Number(value).toLocaleString();
    if (propertyDefinition.propertyTypeString === 'boolean')
      return value === 'true' ? 'はい' : 'いいえ';
    if (propertyDefinition.propertyTypeString === 'date')
      return moment(value).format('YYYY/MM/DD');
    if (propertyDefinition.propertyTypeString === 'datetime')
      return moment(value).format('YYYY/MM/DD HH:mm');
    if (propertyDefinition.propertyTypeString === 'enumeration')
      return value
        .split(';')
        .map(
          (v: string) =>
            propertyDefinition.options.find((option) => option.value === v)
              ?.label,
        )
        .join(', ');

    return null;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};
