import {Button, Modal, Select} from 'antd';
import {
  Pool,
  PreleadContact,
  useConvertPreleadContactToProspectMutation,
  useLeadSourcesQuery,
} from 'api';
import React, {useState} from 'react';
import styled from 'styled-components';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  preleadContact: PreleadContact;
  pool: Pool;
}

export default ({
  isModalVisible,
  setIsModalVisible,
  preleadContact,
  pool,
}: Props) => {
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    variables: {uuid: pool?.uuid},
  });
  const [convertPreleadContactToProspect] =
    useConvertPreleadContactToProspectMutation({
      onCompleted: () => {
        handleCancel();
      },
    });
  const [leadSourceId, setLeadSourceId] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      centered
      width={330}
      footer={null}>
      <ModalContent>
        <h2>リードソースを選択</h2>
        <CustomSelect
          size="large"
          onChange={(value: string) => {
            setLeadSourceId(value);
          }}>
          {leadSources.map((leadSource) => (
            <Select.Option value={leadSource.id} key={leadSource.id}>
              {leadSource.name}
            </Select.Option>
          ))}
        </CustomSelect>
        <div>
          <Button
            type="primary"
            size="middle"
            htmlType="submit"
            disabled={!leadSourceId}
            onClick={() => {
              convertPreleadContactToProspect({
                variables: {
                  uuid: preleadContact.uuid,
                  leadSourceId: leadSourceId,
                },
              });
            }}>
            登録
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  div {
    display: flex;
    justify-content: end;
  }
`;

const CustomSelect = styled(Select)`
  margin-bottom: 1rem;
`;
