import React from 'react';
import styled from 'styled-components';
import {SalesforceAccount, useSalesforceAccountsQuery} from 'api';
import {Pagination} from 'components/antd';
import AccountSelectItem from './AccountSelectItem';
import {Pool} from 'api';

interface Props {
  keyword: string;
  pool: Pool;
  onClose: () => void;
  onSelect: (salesforceAccount?: SalesforceAccount) => void;
}

const AccountSelectComponent = ({keyword, pool, onClose, onSelect}: Props) => {
  const [page, setPage] = React.useState(1);

  const {
    data: {
      salesforceAccounts: {salesforceAccounts = [], pagination = {}} = {},
    } = {},
  } = useSalesforceAccountsQuery({
    variables: {uuid: pool?.uuid, page, perPage: 20, search: {keyword}},
  });

  return (
    <Container>
      <a href="#" onClick={onClose}>
        &#8249; 戻る
      </a>
      <h3>
        候補を選択 <em>{pagination.totalCount || 0}件</em>
      </h3>
      {salesforceAccounts.map((account) => (
        <AccountSelectItem
          key={account.id}
          salesforceAccount={account}
          onSelect={onSelect}
        />
      ))}
      <PaginationWrapper>
        <Pagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={20}
          onChange={(page) => setPage(page)}
        />
      </PaginationWrapper>
    </Container>
  );
};

const Container = styled.div`
  > a {
    font-size: 14px;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  > h3 {
    em {
      font-style: normal;
      color: #eb5757;
    }
    border-bottom: solid 1px #e1e6eb;
  }
`;

const PaginationWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
`;

export default AccountSelectComponent;
