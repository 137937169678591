import {PreleadProject, useAllPrefecturesQuery} from 'api';
import React from 'react';

interface Props {
  row: any;
}

const Prefecture = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const {data: {allPrefectures = []} = {}} = useAllPrefecturesQuery();
  const prefecture = allPrefectures.find(
    (prefecture) => prefecture.id === preleadProject.prelead.prefectureId,
  );

  return <>{prefecture?.name}</>;
};

export default Prefecture;
