import React from 'react';
import {useParams} from 'react-router-dom';
import useCondition from '../../../useCondition';
import {useHubspotDealPropertyDefinitionsQuery} from 'api';
import SelectOption from './SelectOption';
import MultiSelectOption from './MutltiSelectOption';

type MultiSelectSearchCondition = {
  deal_property_definition_checkbox_option_ids: string[];
  deal_property_definition_field_type: string;
  deal_property_definition_property_type: string;
  deal_property_definition_id: string;
};

type SelectSearchCondition = {
  deal_property_definition_select_option_id: string;
  deal_property_definition_field_type: string;
  deal_property_definition_property_type: string;
  deal_property_definition_id: string;
};

export type SearchCondition =
  | SelectSearchCondition
  | MultiSelectSearchCondition;

interface Props {
  searchCondition: SearchCondition;
}

export default ({searchCondition}: Props) => {
  const {condition} = useCondition();

  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {data: {hubspotDealPropertyDefinitions = []} = {}} =
    useHubspotDealPropertyDefinitionsQuery({
      variables: {
        poolId,
      },
      skip: !poolId,
    });

  const definition = searchCondition.deal_property_definition_id
    ? hubspotDealPropertyDefinitions.find(
        (definition) =>
          definition.id === searchCondition.deal_property_definition_id,
      )
    : null;

  return (
    <div className="flex gap-2">
      <span className="text-c-light h-6 flex items-center">
        {definition?.label}：
      </span>
      {searchCondition.deal_property_definition_field_type === 'select' && (
        <SelectOption
          definition={definition}
          value={
            (searchCondition as SelectSearchCondition)
              .deal_property_definition_select_option_id
          }
        />
      )}
      {searchCondition.deal_property_definition_field_type === 'checkbox' && (
        <MultiSelectOption
          definition={definition}
          values={
            (searchCondition as MultiSelectSearchCondition)
              .deal_property_definition_checkbox_option_ids
          }
        />
      )}
    </div>
  );
};
