import React from 'react';
import {Content} from 'api';
import Document from './Document';

interface Props {
  onClick: (text: string) => void;
  content: Content;
}

const contentableComponents: {[key: string]: any} = {
  'Content::Document': Document,
};

export default ({onClick, content}: Props) => {
  const ContentableComponent = contentableComponents[content.contentableType];

  return <ContentableComponent content={content} onClick={onClick} />;
};
