import React from 'react';

function Icon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33333 12.6666H2.66667C2.26667 12.6666 2 12.3999 2 11.9999V3.99992C2 3.59992 2.26667 3.33325 2.66667 3.33325H13.3333C13.7333 3.33325 14 3.59992 14 3.99992V7.26659"
        stroke="#899098"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 3.6665L8.00008 7.99984L2.66675 3.6665"
        stroke="#899098"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 14.2667C13.4666 14.2667 14.6666 13.0667 14.6666 11.6C14.6666 10.1333 13.4666 8.93335 11.9999 8.93335C10.5333 8.93335 9.33325 10.1333 9.33325 11.6C9.33325 13.0667 10.4666 14.2667 11.9999 14.2667Z"
        stroke="#899098"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0667 13.4667L13.8666 9.7334"
        stroke="#899098"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
