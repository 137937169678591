import gql from 'graphql-tag';

export default gql`
  fragment accountGroupFragment on AccountGroup {
    id
    uuid
    name
    status
    statusText
    accountCount
    prospectCount
    createdAt
    updatedAt
  }
`;
