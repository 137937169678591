import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Modal, Form} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useCreateSalesforceDealMutation,
  useSalesforceUsersQuery,
  useSalesforceOpportunityStagesQuery,
  ProspectPool,
} from 'api';
import {useHistory} from 'react-router-dom';
import {Contact} from './Contact';
import {Deal} from './Deal';
import {Tab} from './Tab';
import useProspectContext from '../../useProspectContext'

interface Props {
  prospectPool: ProspectPool;
  visible: boolean;
  onClose: () => void;
}

interface FormikValues {
  salesforceDeal: {
    name: string;
    salesforceUserId: string;
    salesforceOpportunityStageId: string;
    closeDate: moment.Moment;
    salesforceDealRecordTypeId: string;
  };
  salesforceContact: {
    salesforceContactRecordTypeId: string;
  };
}

const NewDealModalComponent = ({
  prospectPool = {},
  visible,
  onClose,
}: Props) => {
  const [tab, setTab] = React.useState('deal');

  const [dealAddition, setDealAddition] = React.useState({});
  const [contactAddition, setContactAddition] = React.useState({});

  const history = useHistory();
  const {poolId} = useProspectContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  const {data: {salesforceOpportunityStages = []} = {}} =
    useSalesforceOpportunityStagesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const [create, {loading, data}] = useCreateSalesforceDealMutation({
    onCompleted: (data) => {
      if (!data.createSalesforceDeal.error) {
        setContactAddition({});
        setDealAddition({});
        formik.resetForm();
        onClose();
        prospectPool = data?.createSalesforceDeal?.prospectPool;
        history.push(
          `/pools/${poolId}/prospects/${prospectPool?.uuid}/timeline`,
        );
      }
    },
    refetchQueries: ['prospectPools'],
  });

  const today = React.useMemo(() => moment(), []);

  const initialValues: FormikValues = {
    salesforceDeal: {
      name: '',
      salesforceUserId: salesforceUsers.length > 0 ? salesforceUsers[0].id : '',
      salesforceOpportunityStageId:
        salesforceOpportunityStages.length > 0
          ? salesforceOpportunityStages[0].id
          : '',
      closeDate: today,
      salesforceDealRecordTypeId: '',
    },
    salesforceContact: {
      salesforceContactRecordTypeId: '',
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      salesforceDeal: Yup.object().shape({
        name: Yup.string().required('必須項目です'),
        salesforceUserId: Yup.string().required('必須項目です'),
        salesforceOpportunityStageId: Yup.string().required('必須項目です'),
        closeDate: Yup.date().required('必須項目です'),
      }),
    }),
    onSubmit: (values) => {
      const attributes = {
        uuid: prospectPool.uuid,
        salesforceContactAttributes: {
          ...values.salesforceContact,
          addition: {...contactAddition},
        },
        salesforceDealAttributes: {
          ...values.salesforceDeal,
          addition: {...dealAddition},
        },
      };

      create({
        variables: {
          ...attributes,
        },
      });
    },
  });

  const onOk = React.useCallback(() => formik.submitForm(), []);

  const onCancel = React.useCallback(() => {
    setContactAddition({});
    setDealAddition({});
    formik.resetForm();
    onClose();
  }, []);

  return (
    <Modal
      title="商談を作成"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText="キャンセル"
      width={480}
      okText="登録"
      okButtonProps={{
        loading,
        disabled: !formik.isValid || !formik.dirty,
      }}
      maskClosable>
      {data?.createSalesforceDeal?.error && (
        <Error>{data?.createSalesforceDeal?.error}</Error>
      )}
      <Tab state={tab} setState={setTab} />
      <Form layout="vertical">
        {tab == 'deal' ? (
          <Deal
            formik={formik}
            addition={dealAddition}
            setAddition={setDealAddition}
          />
        ) : (
          <Contact
            formik={formik}
            addition={contactAddition}
            setAddition={setContactAddition}
          />
        )}
      </Form>
    </Modal>
  );
};

const Error = styled.div`
  color: red;
  margin-bottom: 8px;
`;

export default NewDealModalComponent;
