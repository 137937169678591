import gql from 'graphql-tag';

export default gql`
  fragment salesforceAccountFragment on SalesforceAccount {
    id
    name
    salesforceAccountId
    accountId
    website
    shippingState
    shippingCity
    shippingStreet
    billingState
    billingCity
    billingStreet
    phone
    addition
  }
`;
