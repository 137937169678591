import React from 'react';
import {Activity, MiitelCallPreleadActivity} from 'api';
import TelActivity from './TelActivity';
import MiitelCallActivityComponent from './MiitelCallActivity';

interface ActivityType extends Activity {
  resource: MiitelCallPreleadActivity;
}

interface Props {
  activity: ActivityType;
}

export default ({activity}: Props) => {
  const {resource} = activity;

  return resource.body || resource.clientTelStatus ? (
    <TelActivity activity={activity} />
  ) : (
    <MiitelCallActivityComponent activity={activity} />
  );
};
