import React from 'react';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
  error?: boolean;
};

export default ({error, children}: Props) => {
  return <Message error={error ? 1 : 0}>{children}</Message>;
};

const Message = styled.span<{error: number}>`
  color: ${(props) => (props.error ? '#EA3F60' : '#6E7489')};
`;
