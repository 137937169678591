import React from 'react';
import {Step, ManualMailStep} from 'api';

interface ManualMailStepType extends Step {
  steppable: ManualMailStep;
}

interface Props {
  step: ManualMailStepType;
}

const ManualMailStats = ({step}: Props) => {
  const {actionsCount, sentCount, openedCount, clickedCount} = step.steppable;
  const openRate = sentCount > 0 ? (openedCount / sentCount) * 100 : 0;
  const clickRate = sentCount > 0 ? (clickedCount / sentCount) * 100 : 0;
  const dealsCount = step.dealsCount;
  const dealRate = sentCount > 0 ? (dealsCount / sentCount) * 100 : 0;

  return (
    <ul className="flex gap-1 justify-evenly">
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">リード</span>
        <span className="text-base font-bold leading-none">
          {(actionsCount || 0).toLocaleString()}
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">送信</span>
        <span className="text-base font-bold leading-none">
          {(sentCount || 0).toLocaleString()}
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">開封率</span>
        <span className="text-base font-bold leading-none">
          {(openRate || 0).toFixed(1)} <span className="text-xs">%</span>
        </span>
        <span className="text-xs text-c-light leading-none">
          ( {openedCount.toLocaleString()}人 )
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">クリック率</span>
        <span className="text-base font-bold leading-none">
          {(clickRate || 0).toFixed(1)} <span className="text-xs">%</span>
        </span>
        <span className="text-xs text-c-light leading-none">
          ( {clickedCount.toLocaleString()}人 )
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">商談</span>
        <span className="text-base font-bold leading-none">
          {(dealsCount || 0).toLocaleString()}
        </span>
        <span className="text-xs text-c-light leading-none">
          ( {(dealRate || 0).toFixed(1)}% )
        </span>
      </li>
      <li className="border-r border-c-border" />
    </ul>
  );
};

export default ManualMailStats;
