import React, {useState} from 'react';
import styled from 'styled-components';
import {Button, Input} from 'components/antd';
import usePools from 'hooks/usePools';
import Modal from 'react-modal';
import {Cross} from 'components/Ui/Icon';
import {useCreateLeadSourceMutation} from 'api';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export default ({isModalVisible, setIsModalVisible}: Props) => {
  const [leadSourceName, setLeadSourceName] = useState('');
  const [error, setError] = useState('');
  const {selectedPool} = usePools();
  const [createLeadSource, {loading, data}] = useCreateLeadSourceMutation({
    variables: {uuid: selectedPool.uuid, name: leadSourceName},
    refetchQueries: ['leadSources'],
    onCompleted: (data) => {
      setError(data?.createLeadSource?.error);
      !data?.createLeadSource?.error && onClose();
    },
  });

  const onClose = () => {
    setIsModalVisible(false);
    setLeadSourceName('');
  }

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 100,
    },
    content: {
      top: '400px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '466px',
      height: '215px',
      padding: '0 30px 0 30px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => onClose()}
      style={customStyles}>
      <Header>
        <CrossIconWrapper onClick={() => {
          setIsModalVisible(false);
          setError('');
        }}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <Body>
        <Title>リードソースを新規作成</Title>
        <Input style={{marginBottom: 0}} onChange={(e) => setLeadSourceName(e.target.value)} />
        {error ? (
          <div style={{color: 'red', position: 'absolute'}}>
            {error}
          </div>
        ) : null}
        <ButtonWrapper>
          <Button onClick={() => onClose()}>キャンセル</Button>
          <Button
            type="primary"
            onClick={() => createLeadSource()}
            disabled={loading || !leadSourceName}>
            登録
          </Button>
        </ButtonWrapper>
      </Body>
    </Modal>
  );
};
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CrossIconWrapper = styled.div`
  margin-top: 13px;
  position: relative;
  right: -10px;

  svg {
    cursor: pointer;

    path {
      stroke-width: 2px;
    }
  }
`;

const Body = styled.div`
  .ant-input {
    margin-bottom: 27px;
    height: 39px;
    border-radius: 4px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 25px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
