import React from 'react';

function Icon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M25.2 1.8998C22.65 -0.650195 18.55 -0.650195 16.05 1.8998C14.9 3.0498 14.2 4.5998 14.15 6.2498V12.3998C14.15 12.6998 14.4 12.9998 14.75 12.9998H20.35C22.15 13.0998 23.95 12.3998 25.2 11.0998C27.75 8.5498 27.75 4.4498 25.2 1.8998Z"
          fill="#34362F"
        />
        <path
          d="M1.89999 6.8501C-0.650012 9.4001 -0.650012 13.5001 1.89999 16.0001C3.04999 17.1501 4.59999 17.8501 6.24999 17.9001H12.4C12.7 17.9001 12.95 17.6501 12.95 17.3501V11.7001C13.05 9.9001 12.35 8.1001 11.05 6.8501C8.49999 4.3501 4.44999 4.3501 1.89999 6.8501ZM6.84999 30.1001C9.39999 32.6001 13.5 32.6001 16 30.0501C17.15 28.9001 17.8 27.3501 17.85 25.7501V19.6501C17.85 19.3501 17.6 19.1001 17.3 19.1001H11.7C9.89999 19.0001 8.09999 19.7001 6.84999 21.0001C4.34999 23.5001 4.34999 27.5501 6.84999 30.1001ZM30.1 25.1501C32.65 22.6501 32.65 18.5501 30.1 16.0001C28.95 14.8501 27.4 14.1501 25.75 14.1001H19.65C19.35 14.1001 19.05 14.3501 19.05 14.6501V20.2501C18.95 22.0501 19.65 23.8501 20.95 25.1001C23.5 27.7001 27.55 27.7001 30.1 25.1501Z"
          fill="#F03748"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
