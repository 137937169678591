import React, {useState, useRef} from 'react';
import linkifyHtml from 'linkify-html';
import {XMarkIcon} from '@heroicons/react/24/outline';
import styled from 'styled-components';
import {Cross, AddPaperFile} from 'components/Ui/Icon';
import {
  Button,
  Form,
  Select,
  Input,
  Upload,
  Tooltip,
  Popover,
} from 'components/antd';
import {
  useCreateManualMailProspectActivityMutation,
  useUsersByClientAllQuery,
  useProspectPoolQuery,
  ProspectPool,
  useUploadMailImageMutation,
  Approach,
  Action,
  ApproachProspect,
} from 'api';
import {Dialog, Transition, Menu} from '@headlessui/react';
import {Quill as ReactQuill} from 'components/Ui';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import mailVariableOptions from 'helpers/mailVariableOptions';
import {useLocation} from 'react-router-dom';
import {prospectStages} from 'helpers/prospectStages';
import {ContentMail} from 'components/Ui/Icon';
import {ChevronDownIcon, CheckIcon} from '@heroicons/react/20/solid';
import useMailTemplate from 'hooks/useMailTempate';

interface Props {
  prospectPool: ProspectPool;
  approach: Approach;
  visible: boolean;
  onClose: () => void;
}

const MainModal = ({prospectPool, approach, visible, onClose}: Props) => {
  const {templates, templateTitle} = useMailTemplate();
  const isAction = approach?.approachableType === 'Action';

  const [areCcAndBccSelectable, setAreCcAndBccSelectable] = useState(false);
  const [variablePopoverVisible, setVariablePopoverVisible] =
    React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(true);

  const approachable = approach.approachable;

  const ref = useRef(null);

  const [createManualMailProspectActivity, {loading}] =
    useCreateManualMailProspectActivityMutation({
      onCompleted: onClose,
      refetchQueries: ['approachCounts', 'actionCounts', 'actions'],
    });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const {data: {prospectPool: {prospect: {email} = {}} = {}} = {}} =
    useProspectPoolQuery({
      variables: {uuid: prospectPool.uuid},
      skip: !prospectPool,
      fetchPolicy: 'cache-and-network',
    });

  const currentStageValue = () => {
    return prospectStages.find((stage) => {
      return stage.name === prospectPool?.stage;
    });
  };

  const [uploadMailImage] = useUploadMailImageMutation({
    onCompleted: ({uploadMailImage: {imageUrl} = {}}) => {
      insertToEditor(imageUrl);
    },
  });

  const location = useLocation<{actionUuid: string}>();

  const convertBodyHtml = (text: string) =>
    convertUrlToLink(convertClassToInlineStyle(text));

  const convertClassToInlineStyle = (text: string) =>
    text.replace(/class="ql-align-center"/g, 'style="text-align: center"');

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      actionUuid: isAction ? (approach?.approachable as Action).uuid : null,
      fromEmail: '',
      fromName: '',
      stage: currentStageValue()?.id,
      to: email,
      cc: [],
      bcc: [],
      subject: '',
      bodyText: '',
      bodyHtml: '',
      files: [],
    },
    validationSchema: Yup.object().shape({
      fromEmail: Yup.string().email().required(),
      to: Yup.string().email().required(),
      subject: Yup.string().required(),
      bodyText: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const actionUuid = values.actionUuid;
      delete values.actionUuid;

      values.bodyHtml = convertBodyHtml(values.bodyHtml);

      createManualMailProspectActivity({
        variables: {
          uuid: prospectPool.uuid,
          approachUuid: approach.uuid,
          actionUuid: actionUuid,
          attributes: values,
          isCompleted: isCompleted,
        },
      });
    },
  });

  React.useEffect(() => {
    if (location?.state?.actionUuid) {
      formik.setFieldValue('actionUuid', location?.state?.actionUuid);
    }
  }, [location?.state]);

  const insertToEditor = (url: string) => {
    const range = ref.current.editor.getSelection();
    ref.current.editor.insertEmbed(range.index, 'image', url);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadMailImage({variables: {image: file}});
      } else {
        alert('画像を選択して下さい。');
      }
    };
  };

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        handlers: {
          image: selectLocalImage,
        },
        container: [
          [
            {header: [1, 2, 3, false]},
            'bold',
            'italic',
            'underline',
            {align: ['', 'center']},
            {color: []},
            'link',
            'image',
          ],
        ],
      },
    };
  }, []);

  React.useEffect(() => {
    // 初回レンダリング時にformik.isValidがtrueになってしまうので、falseになるようにしている。
    (() => formik.validateForm())();
  }, []);

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="w-[700px] h-full flex flex-col gap-2 bg-white rounded">
                <div className="h-[71px] flex items-center pl-4 pr-6 gap-4 border-[#BDC4CB] border-b">
                  <h2 className="m-0 text-base flex-1 text-left flex items-center">
                    <div className="w-10 h-10 relative overflow-hidden rounded-full bg-[#F99985]">
                      <ContentMail
                        color="#FFFFFF"
                        className="absolute w-6 h-6 left-2 top-2"
                      />
                    </div>
                    <div className="ml-4">
                      {approach.approachableType === 'Action' &&
                        (approachable as Action).step.name}
                      {approach.approachableType === 'Approach::Prospect' &&
                        (approachable as ApproachProspect).approachGroupProspect
                          .title}
                    </div>
                  </h2>
                  <button
                    className="p-0 bg-transparent border-none cursor-pointer w-6 h-6 focus:outline-none"
                    onClick={onClose}>
                    <XMarkIcon className="h-6 w-6 text-c-lighter" />
                  </button>
                </div>
                <Form onFinish={formik.handleSubmit}>
                  <div className="text-left pt-2 pb-4 px-[40px] grid grid-cols-2 gap-4 border-[#BDC4CB] border-b">
                    <div>
                      <label className="block text-[#899098] mb-2">
                        ステージ
                      </label>
                      <Select
                        className="w-full"
                        value={formik.values.stage}
                        onChange={(value) =>
                          formik.setFieldValue('stage', value)
                        }>
                        {prospectStages.map((stage) => (
                          <Select.Option key={stage.id} value={stage.id}>
                            {stage.displayName}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <FieldGroup className="mx-10">
                    <div>
                      {areCcAndBccSelectable ? (
                        <>
                          <Field>
                            <label>Cc</label>
                            <Select
                              mode="tags"
                              bordered={false}
                              value={formik.values.cc}
                              onChange={(values: [string]) =>
                                formik.setFieldValue(
                                  'cc',
                                  values.filter((value) =>
                                    Yup.string()
                                      .trim()
                                      .email()
                                      .isValidSync(value),
                                  ),
                                )
                              }>
                              {users.map((user) => (
                                <Select.Option
                                  key={user.uuid}
                                  value={user.email}>
                                  {user.lastName} {user.firstName}
                                  {`<${user.email}>`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Field>

                          <Field>
                            <label>Bcc</label>
                            <Select
                              mode="tags"
                              bordered={false}
                              value={formik.values.bcc}
                              onChange={(values: [string]) =>
                                formik.setFieldValue(
                                  'bcc',
                                  values.filter((value) =>
                                    Yup.string()
                                      .trim()
                                      .email()
                                      .isValidSync(value),
                                  ),
                                )
                              }>
                              {users.map((user) => (
                                <Select.Option
                                  key={user.uuid}
                                  value={user.email}>
                                  {user.lastName} {user.firstName}
                                  {`<${user.email}>`}
                                </Select.Option>
                              ))}
                            </Select>
                          </Field>
                        </>
                      ) : (
                        <Field
                          onClick={() => setAreCcAndBccSelectable(true)}
                          style={{cursor: 'pointer'}}>
                          <label>Cc Bcc</label>
                        </Field>
                      )}

                      <Field>
                        <label>差出人</label>
                        <Select
                          bordered={false}
                          showSearch={true}
                          value={formik.values.fromEmail}
                          onSelect={(value: any) =>
                            formik.setFieldValue('fromEmail', value)
                          }>
                          {users.map((user) => (
                            <Select.Option key={user.uuid} value={user.email}>
                              {user.lastName} {user.firstName}
                              {`<${user.email}>`}
                            </Select.Option>
                          ))}
                        </Select>
                      </Field>

                      <Field>
                        <label>件名</label>
                        <Input
                          id="subject"
                          name="subject"
                          value={formik.values.subject}
                          bordered={false}
                          onChange={(e) =>
                            formik.setFieldValue('subject', e.target.value)
                          }
                          onKeyDown={(e) =>
                            e.key === 'Enter' && e.preventDefault()
                          }
                        />
                      </Field>
                    </div>
                  </FieldGroup>
                  <div className="text-left py-2 mx-10">
                    <label className="block text-[#899098] mb-2">
                      テンプレート
                    </label>
                    <Select
                      className="w-[300px]"
                      placeholder="テンプレート"
                      optionFilterProp="children"
                      showSearch
                      onSelect={(value: any) => {
                        const template = templates.find(
                          (template) => template.uuid === value,
                        );
                        formik.setValues({
                          ...formik.values,
                          subject: template.mailTemplate.title,
                        });
                        template.mailTemplate.bodyHtml
                          ? (document.getElementsByClassName(
                              'ql-editor',
                            )[0].innerHTML = template.mailTemplate.bodyHtml)
                          : ref.current?.editor.setText(
                              template.mailTemplate.body,
                            );
                      }}>
                      {templates.map((template) => (
                        <Select.Option
                          key={template.uuid}
                          value={template.uuid}>
                          {templateTitle(template)}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="relative">
                    <ExpansionReactQuill
                      ref={ref}
                      theme="snow"
                      value={formik.values.bodyHtml}
                      placeholder="本文を記入してください。"
                      onChange={(content, delta, source, editor) => {
                        formik.setFieldValue('bodyHtml', content);
                        formik.setFieldValue('bodyText', editor.getText());
                      }}
                      onBlur={() => {
                        const converted = convertUrlToLink(
                          formik.values.bodyHtml,
                        );
                        formik.setFieldValue('bodyHtml', converted);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.isComposing === false) {
                          const converted = convertUrlToLink(
                            formik.values.bodyHtml,
                          );
                          formik.setFieldValue('bodyHtml', converted);
                        }
                      }}
                      modules={modules}
                      bounds={'#quill-container'}
                    />
                    <div className="flex justify-end bottom-0 right-[40px] absolute z-10">
                      <Popover
                        content={
                          <Content>
                            {mailVariableOptions.map((option) => (
                              <PopoverSelectButtonWrapper key={option.code}>
                                <PopoverSelectButton
                                  onClick={() => {
                                    if (ref.current?.editor.getSelection()) {
                                      ref.current?.editor.insertText(
                                        ref.current?.editor.getSelection()
                                          .index,
                                        option.code,
                                      );
                                    } else {
                                      const subject: any =
                                        document.getElementById('subject');
                                      const value =
                                        subject.value.substr(
                                          0,
                                          subject.selectionStart,
                                        ) +
                                        option.code +
                                        subject.value.substr(
                                          subject.selectionStart,
                                        );
                                      formik.setFieldValue('subject', value);
                                    }
                                  }}>
                                  <span>{option.code}</span>
                                  <span>{option.title}</span>
                                </PopoverSelectButton>
                              </PopoverSelectButtonWrapper>
                            ))}
                          </Content>
                        }
                        trigger="click"
                        visible={variablePopoverVisible}
                        placement="topRight"
                        onVisibleChange={setVariablePopoverVisible}>
                        <PopoverDisplayButton>{`{ }`}</PopoverDisplayButton>
                      </Popover>
                      <Upload
                        name={'files'}
                        customRequest={() => false}
                        showUploadList={false}
                        onChange={(info) => {
                          const addedFile = info.file.originFileObj;
                          const upload_size_limit = 25000000; // 25MB

                          let fileSize = addedFile.size;
                          formik.values.files.forEach(
                            (file) => (fileSize += file.size),
                          );
                          if (fileSize > upload_size_limit) {
                            alert(
                              'ファイルの容量が25MBを超えています。\n合計25MBまでの添付が可能です。',
                            );
                            return null;
                          }

                          formik.setFieldValue('files', [
                            ...formik.values.files,
                            addedFile,
                          ]);
                        }}>
                        <Tooltip
                          title="ファイルを添付(25MBまで)"
                          color={'#222426'}
                          placement="top">
                          <Button
                            icon={<AddPaperFile />}
                            style={{marginRight: 2}}
                          />
                        </Tooltip>
                      </Upload>
                    </div>
                  </div>
                  {formik.values.files.map((file) => (
                    <File key={file.uid}>
                      <FileName>{file.name}</FileName>
                      <div
                        onClick={() =>
                          formik.setFieldValue(
                            'files',
                            formik.values.files.filter((f) => f !== file),
                          )
                        }>
                        <Cross />
                      </div>
                    </File>
                  ))}

                  <div className="flex justify-end px-[40px] pt-1 pb-4">
                    <div className="flex items-center justify-end">
                      <button
                        type="submit"
                        disabled={!formik.isValid || loading}
                        className={`bg-[#61CF8D] text-white text-base font-medium py-2 w-72 flex items-center justify-center border-none cursor-pointer disabled:bg-c-bg disabled:text-c-light ${
                          approach?.status === 'open' ? 'rounded-l' : 'rounded'
                        }`}>
                        {isCompleted
                          ? 'アプローチを完了してメール送信'
                          : 'メール送信のみ'}
                      </button>
                      {approach?.status === 'open' && (
                        <Menu
                          as="div"
                          className="relative inline-block text-left z-30">
                          <Menu.Button
                            disabled={!formik.isValid || loading}
                            className="bg-[#61CF8D] text-white text-base font-medium py-2 w-8 border-l border-white rounded-r cursor-pointer disabled:bg-c-bg disabled:text-c-light">
                            <ChevronDownIcon className="w-4 h-4 relative top-1" />
                          </Menu.Button>
                          <Transition
                            as={React.Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="absolute right-0 w-80 origin-top-right rounded-b bg-white shadow-lg focus:outline-none z-30">
                              <Menu.Item>
                                <div
                                  className={`hover:bg-c-bg py-4 pl-2 pr-4 w-full flex items-center gap-2 ${
                                    isCompleted ? 'bg-white' : 'bg-c-bg'
                                  }`}>
                                  <CheckIcon
                                    className={`w-4 h-4 ${
                                      isCompleted
                                        ? 'text-transparent'
                                        : 'text-[#61CF8D]'
                                    }`}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => setIsCompleted(false)}
                                    className="cursor-pointer bg-transparent text-left text-base text-medium w-full hover:bg-c-bg flex flex-col gap-2 leading-none">
                                    メール送信のみ
                                    <span className="text-xs">
                                      アプローチは完了せずアクションボードに残ります
                                    </span>
                                  </button>
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div
                                  className={`hover:bg-c-bg py-4 pl-2 pr-4 w-full flex items-center gap-2 ${
                                    isCompleted ? 'bg-c-bg' : 'bg-white'
                                  }`}>
                                  <CheckIcon
                                    className={`w-4 h-4 ${
                                      isCompleted
                                        ? 'text-[#61CF8D]'
                                        : 'text-transparent'
                                    }`}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => setIsCompleted(true)}
                                    className="cursor-pointer bg-transparent text-left text-base text-medium w-full hover:bg-c-bg flex flex-col gap-2 leading-none">
                                    アプローチを完了してメール送信
                                    <span className="text-xs">
                                      アプローチは完了しアクションボードから削除されます
                                    </span>
                                  </button>
                                </div>
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Field = styled.div`
  flex: 1;
  min-height: 52px;
`;

const FieldGroup = styled.div`
  .ql-picker-label {
    padding-left: 15px;
  }

  ${Field} {
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: 1px solid #e1e6eb;
  }

  label {
    width: 45px;
    color: #899098;
  }

  &&& {
    .ant-select,
    .ant-input {
      width: 100%;
    }
  }
`;

const File = styled.div`
  height: 30px;
  background: #f3f5f7;
  border-radius: 2px;
  margin: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    margin-right: 10px;
    width: 7px;
    height: 7px;
    path {
      stroke: #899098;
      stroke-width: 1.5px;
    }
  }
`;

const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: bold;
  font-size: 12px;
  color: #68b5fb;
`;

const PopoverDisplayButton = styled(Button)`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  color: #899098;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const PopoverSelectButtonWrapper = styled.div`
  display: flex;
  width: 235px;
  height: 30px;
  span:first-child {
    color: #899098;
    margin-right: 10px;
  }
  span:last-child {
    color: #495058;
  }
`;

const PopoverSelectButton = styled(Button)`
  padding: 0;
  padding-left: 20px;
  width: 235px;
  text-align: left;
  border: none;

  &:hover {
    background: #f1f8ff;
  }
`;

const ExpansionReactQuill = styled(ReactQuill)`
  position: relative;
  margin-bottom: 22px;

  .ql-container.ql-snow {
    border: none;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .ql-editor {
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
  }
  .ql-editor.ql-blank::before {
    left: 0;
  }
  .ql-editor ql-blank {
    top: -40px;
  }
  .ql-container {
    display: flex;
    flex-direction: column-reverse;
    height: 330px;
  }
  .editor-wrapper {
    position: relative;
  }
  .ql-toolbar {
    position: absolute;
    bottom: 30px;
    transform: translateY(100%);
    z-index: 9;
    margin: 0 40px;
    width: calc(100% - 80px);
  }
  .ql-snow .ql-formats {
    display: block;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    margin-top: -220%;
  }
  .ql-snow .ql-picker.ql-header {
    width: 90px;
    .ql-picker-label {
      padding-left: 0;
      text-align: left;
    }
  }
`;

export default MainModal;
