import React from 'react';
import {useProjectQuery} from 'api';
import {Setting} from 'components/Ui/Icon';
import {Popover, Button} from 'components/antd';
import {useHistory, useParams} from 'react-router';
import styled from 'styled-components';
import ProjectGroupSelect from './ProjectGroupSelect';
import useProjectPath from 'hooks/useProjectPath';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const history = useHistory();
  const {createPath} = useProjectPath();
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
  });
  const {isMember} = useClientUser();

  return (
    <Container>
      <Title>
        <h3>グループ</h3>
        {isMember && (
          <Popover
            content={() => (
              <ProjectGroupSelect
                project={project}
                onClose={() => {
                  setPopoverVisible(false);
                  history.push(createPath(`projects${location.search}`));
                }}
              />
            )}
            trigger="click"
            visible={isPopoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            placement="bottomRight">
            <Button type="text">
              <Setting />
            </Button>
          </Popover>
        )}
      </Title>
      <Name>{project?.projectGroup?.name || '未設定'}</Name>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #bdc4cb;
    flex: 1;
  }

  button {
    padding: 0;
    &:hover {
      background: transparent;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    stroke: #899098;
  }
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
