import gql from 'graphql-tag';

export default gql`
  fragment collectedFormFragment on CollectedForm {
    id
    payload
    url
    companyName
    lastName
    firstName
    email
    telephoneNumber
    section
    webSite
    poolId
    accountId
    companyId
    prospectId
    leadSourceId
    collectedFormUrlId
  }
`;
