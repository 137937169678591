import gql from 'graphql-tag';

export default gql`
  fragment collectedFormUrlFragment on CollectedFormUrl {
    id
    uuid
    poolId
    title
    url
    leadSourceId
    individualFormStatus
    invalidualFormStatusText
    sendAutoreplyMail
    autoreplyMailBody
    autoreplyMailBodyHtml
    autoreplyMailFromAddress
    autoreplyMailFromName
    autoreplyMailSubject
    autoreplyMailCc
    autoreplyMailBcc
    notificationMailNote
    collectedFormCount
  }
`;
