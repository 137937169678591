import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import {useMainCategoryReportsQuery} from 'api';
import React from 'react';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = ['業種', ''];
  const {data: {mainCategoryReports = []} = {}} = useMainCategoryReportsQuery(
    {},
  );

  const reportData = React.useMemo(() => {
    return mainCategoryReports.map((mainCategoryReport) => {
      return {
        label: mainCategoryReport.name,
        values: mainCategoryReport.values,
      };
    });
  }, [mainCategoryReports]);

  const data: ReportTableData[] = [...reportData].filter(Boolean);

  return {
    data,
    headers,
  };
};

export default useTableData;
