import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  title: string;
  to: string;
  active?: boolean;
}

export default ({title, to, active}: Props) => (
  <Link
    to={to}
    className={`${
      active
        ? 'border-[#495058] text-[#495058]'
        : 'border-transparent text-[#BDC4CB]'
    } inline-block border-b-2 p-0 text-base font-bold leading-[3rem]`}>
    {title}
  </Link>
);
