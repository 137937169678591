import React from 'react';
import {CollectedFormUrl} from 'api';
import UsersEdit from './Users';
import NoteEdit from './Note';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  return (
    <div className="flex flex-col gap-8 w-full max-w-xl">
      <UsersEdit collectedFormUrl={collectedFormUrl} />
      <NoteEdit collectedFormUrl={collectedFormUrl} />
    </div>
  );
};
