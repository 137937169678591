import React from 'react';
import styled from 'styled-components';
import {Button, Radio} from 'components/antd';
import {useProjectGroupsQuery, useAddProjectToProjectGroupMutation, Project} from 'api';

export default ({
  project,
  onClose,
}: {
  project: Project;
  onClose: () => void;
}) => {
  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery();
  const [addProjectToProjectGroup] = useAddProjectToProjectGroupMutation({
    onCompleted: onClose,
    refetchQueries: ['projectGroups'],
  });

  const [projectGroupId, setProjectGroupId] = React.useState(project?.projectGroup?.uuid || null);

  const onChange = React.useCallback((e: any) => setProjectGroupId(e.target.value), []);

  const onSubmit = React.useCallback(() => {
    addProjectToProjectGroup({variables: {uuid: project.uuid, projectGroupId}});
  }, [projectGroupId]);

  return (
    <Container>
      <h3>グループを設定</h3>
      <ScrollWrapper>
        <List>
          {projectGroups.map((projectGroup) => (
            <Radio
              key={projectGroup.uuid}
              value={projectGroup.uuid}
              checked={projectGroup.uuid === projectGroupId}
              onChange={onChange}>
              {projectGroup.name}
            </Radio>
          ))}
        </List>
      </ScrollWrapper>
      <Action>
        <Button type="primary" size="small" onClick={onSubmit}>
          設定
        </Button>
      </Action>
    </Container>
  );
};

const Container = styled.div`
  width: 240px;
  padding: 20px;

  h3 {
    color: #899098;
    font-size: 12px;
    font-weight: bold;
  }
`;

const ScrollWrapper = styled.div`
  max-height: 300px;
  overflow: scroll;
  border-bottom: solid 1px #e1e6eb;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;
