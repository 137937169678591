import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {useFormik, FormikErrors} from 'formik';
// @ts-ignore
import Encoding from 'encoding-japanese';
import Papa from 'papaparse';
import {
  Button,
  Upload,
  Modal,
  Select,
  Tooltip,
  Checkbox,
} from 'components/antd';
import {UploadOutlined} from '@ant-design/icons';
import {
  Project,
  useCurrentClientQuery,
  useImportPreleadProjectMutation,
  useUpdateProjectMutation,
  useClientPreleadCustomizeItemsQuery,
} from 'api';
import useContract from 'hooks/useContract';
import useProjectPath from 'hooks/useProjectPath';

interface Props {
  project: Project;
  visible: boolean;
  onClose: () => void;
}

const upload_size_limit = 10000000; // 10MB

const UploadModalComponent = ({project, visible, onClose}: Props) => {
  const history = useHistory();
  const {createPath} = useProjectPath();
  const [csv, setCsv] = React.useState(null);
  const editableFields = ['telephone_number', 'mail_address', 'address'];
  const [updateProject] = useUpdateProjectMutation({
    variables: {uuid: project.uuid, attributes: {projectType: 'upload'}},
  });

  const validate = (values: any) => {
    let errors: FormikErrors<{fields: string}> = {};

    if (!values.fields?.includes('company_name')) {
      errors.fields = 'Required';
    }

    return errors;
  };

  const handleClose = () => {
    setCsv(null);
    onClose();
  };

  const [importPreleadProjectCsv, {loading}] = useImportPreleadProjectMutation({
    onCompleted: () => {
      history.push(
        createPath(`projects/${project.uuid}/csv_summary?status=open`),
      );
      handleClose();
    },
    refetchQueries: ['project'],
  });

  const {data: {currentClient: {customizeItems = []} = {}} = {}} =
    useCurrentClientQuery();

  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery();

  const options = useMemo(() => {
    const opts = [
      {value: '', text: ''},
      {value: 'prelead_uuid', text: 'プレリードID'},
      {value: 'prelead_status', text: 'プレリードステータス'},
      {value: 'corp_number', text: '法人番号'},
      {value: 'company_name', text: '会社名'},
      {value: 'web_site', text: 'URL'},
      {value: 'address', text: '住所'},
      {value: 'mail_address', text: 'メールアドレス'},
      {value: 'telephone_number', text: '電話番号'},
      {value: 'fax_number', text: 'FAX'},
      {value: 'representative_person', text: '代表者名'},
      {value: 'employee_number', text: '従業員数'},
      {value: 'capital_fund', text: '資本金'},
      {value: 'user_name', text: '企業担当者'},
    ];

    customizeItems.forEach((customizeItem) =>
      opts.push({
        value: customizeItem.columnName,
        text: customizeItem.name,
      }),
    );

    clientPreleadCustomizeItems.forEach((customizeItem) =>
      opts.push({
        value: `clientPreleadCustomizeItemId_${customizeItem.id}`,
        text: customizeItem.name,
      }),
    );

    return opts;
  }, [customizeItems, clientPreleadCustomizeItems]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uuid: project.uuid,
      fields: [],
      clientPreleadCustomizeItemIds: [],
      isSavedFields: [],
    },
    validate,
    onSubmit: ({
      file,
      fields,
      clientPreleadCustomizeItemIds,
      isSavedFields,
    }: {
      file: File;
      fields: string[];
      clientPreleadCustomizeItemIds: string[];
      isSavedFields: boolean[];
    }) => {
      importPreleadProjectCsv({
        variables: {
          uuid: project.uuid,
          file,
          attributes: {
            rowCount: csv.length,
            fields,
            clientPreleadCustomizeItemIds,
            isSavedFields,
          },
        },
      });
    },
  });

  const {isProjectAddable} = useContract();

  return (
    <UploadModal
      destroyOnClose
      title={<Title>営業リストのアップロード</Title>}
      maskClosable={false}
      visible={visible}
      width={640}
      centered
      bodyStyle={{height: '70vh'}}
      footer={[
        <CancelButton size="large" key="cancel" onClick={handleClose}>
          キャンセル
        </CancelButton>,
        <OkButton
          size="large"
          key="submit"
          type="primary"
          disabled={!formik.isValid || !csv}
          onClick={() => {
            formik.handleSubmit(formik.values);
            if (!project.projectType) updateProject();
          }}
          loading={loading}>
          登録
        </OkButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <UploadDescription>
          <div>
            アップロードしたCSVの列の内容を、営業リストの項目に合わせて保存しましょう。
          </div>
          <div>1ファイル10MBまでのアップロードが可能です。</div>
          <div>保存対象にチェックを入れると企業データとして保存されます。</div>
        </UploadDescription>

        {isProjectAddable || project.status === 'open' ? (
          <Upload
            name={'file'}
            accept=".csv"
            showUploadList={false}
            customRequest={() => false}
            onChange={(info) => {
              const file = info.file.originFileObj;
              if (file.size > upload_size_limit) {
                alert(
                  'アップロードしようとしているファイルの容量が10MBを超えています。\n1ファイル10MBまでのアップロードが可能です。',
                );
                return null;
              }
              formik.setFieldValue('file', file);
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const codes = new Uint8Array(e.target.result);
                const encoding = Encoding.detect(codes);
                let unicodeString;
                if (encoding === 'UNICODE') {
                  unicodeString = new TextDecoder().decode(codes);
                } else {
                  unicodeString = Encoding.convert(codes, {
                    to: 'unicode',
                    from: encoding,
                    type: 'string',
                  });
                }
                Papa.parse(unicodeString, {
                  dynamicTyping: true,
                  skipEmptyLines: true,
                  complete: (result: any) => {
                    setCsv(result.data);
                    const size = result.data[0].length;
                    formik.setFieldValue('fields', new Array(size).fill(''));
                    formik.setFieldValue(
                      'clientPreleadCustomizeItemIds',
                      new Array(size).fill(''),
                    );
                    formik.setFieldValue(
                      'isSavedFields',
                      new Array(size).fill(true),
                    );
                  },
                });
              };
              reader.readAsArrayBuffer(file);
            }}>
            <UploadButton size="large">
              <UploadOutlined />
              Click to Upload
            </UploadButton>
          </Upload>
        ) : (
          <Tooltip
            title="営業リスト数が上限に達しました。"
            color={`#EB5757`}
            placement="top">
            <UploadButton size="large" disabled>
              <UploadOutlined />
              Click to Upload
            </UploadButton>
          </Tooltip>
        )}

        {csv && (
          <ListWrapper>
            <ListRow>
              <div className="column">CSVの項目</div>
              <div>システム項目</div>
              <div>上書き対象</div>
            </ListRow>
            {csv[0].map((header: string, index: number) => (
              <ListRow key={header}>
                <div className="column">{header}</div>
                <div className="value">
                  <Select
                    style={{width: '250px', height: '30px'}}
                    onChange={(value: string) => {
                      if (value.startsWith('clientPreleadCustomizeItemId_')) {
                        const clientPreleadCustomizeItemIds =
                          formik.values.clientPreleadCustomizeItemIds;
                        const id = value.split('_')[1];
                        clientPreleadCustomizeItemIds[index] = id;
                        formik.setFieldValue(
                          'clientPreleadCustomizeItemIds',
                          clientPreleadCustomizeItemIds,
                        );
                      } else {
                        if (!editableFields.includes(value)) {
                          const fields = formik.values.isSavedFields;
                          fields[index] = true;
                          formik.setFieldValue('isSavedFields', fields);
                        }
                        const fields = formik.values.fields;
                        fields[index] = value;
                        formik.setFieldValue('fields', fields);
                      }
                    }}>
                    {options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <Checkbox
                    checked={formik.values.isSavedFields[index]}
                    disabled={
                      !editableFields.includes(formik.values.fields[index])
                    }
                    onChange={(e) => {
                      const fields = formik.values.isSavedFields;
                      fields[index] = e.target.checked;
                      formik.setFieldValue('isSavedFields', fields);
                    }}
                  />
                </div>
              </ListRow>
            ))}
          </ListWrapper>
        )}
      </ModalContent>
    </UploadModal>
  );
};

const UploadModal = styled(Modal)`
  &&& {
    .ant-modal-body {
      overflow: scroll;
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    font-size: 14px;
  }

  .ant-upload {
    margin-bottom: 2rem;
  }
`;

const UploadDescription = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #495058;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 24px 24px 0 !important;
`;

const ListWrapper = styled.div`
  border: solid 1px #e2e6ea;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  width: 100%;
`;

const ListRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 49px;
  border-bottom: 1px solid #e2e6ea;
  justify-content: space-between;
  > div {
    &:first-child {
      width: 150px;
    }
  }
`;

const UploadButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const CancelButton = styled(Button)`
  &&& {
    border-radius: 4px;
  }
`;

const OkButton = styled(Button)`
  &&& {
    border: none;
    border-radius: 4px;
  }
`;

export default UploadModalComponent;
