import React from 'react';
import styled from 'styled-components';
import {Input, Modal} from 'components/antd';
import {Button, Radio} from 'components/Ui';
import {
  Project,
  useAddPreleadsToProjectMutation,
  useClientAllProjectsQuery,
  usePreleadsQuery,
  useProjectGroupsQuery,
} from 'api';
import {List} from 'components/Ui/Icon';
import useSearchParams from '../../useSearchParams';
import {useHistory} from 'react-router';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const [selectedProjectId, setSelectedProjectId] = React.useState(null);

  const [projectCount, setProjectCount] = React.useState(null);
  const {searchParams, page, perPage} = useSearchParams();
  const history = useHistory();
  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery();
  const {data: {clientAllProjects: {projects = []} = {}} = {}} =
    useClientAllProjectsQuery({
      variables: {
        search: {
          status: 'open',
        },
      },
    });

  const {data: {preleads: {pagination = {}} = {}} = {}} = usePreleadsQuery({
    variables: {
      search: searchParams,
      page,
      perPage,
    },
    onCompleted: () => setProjectCount(pagination.totalCount),
  });

  const handleClose = () => {
    setSelectedProjectId(null);
    setModalVisible(false);
  };

  const [addPreleadsToProject, {loading}] = useAddPreleadsToProjectMutation({
    refetchQueries: ['currentUser'],
    onCompleted: ({addPreleadsToProject: {project = {}} = {}}) => {
      const projectGroup = projectGroups.find(
        (projectGroup) => projectGroup.id === project.projectGroupId,
      );
      history.push(
        `/list/groups/${projectGroup?.uuid}/projects/${project.uuid}/project_summary`,
      );
    },
  });

  const handleSubmit = () => {
    addPreleadsToProject({
      variables: {
        uuid: selectedProjectId,
        count: projectCount,
        search: searchParams,
      },
    });
  };

  return (
    <ReflectModal
      destroyOnClose
      title={<Title>既存営業リストに追加</Title>}
      maskClosable={false}
      visible={isModalVisible}
      width={540}
      centered
      bodyStyle={{paddingTop: '24px', padding: 0}}
      footer={[
        <CustomButton
          key="submit"
          onClick={handleSubmit}
          loading={loading}
          disabled={
            !selectedProjectId || !projectCount || projectCount > 20000
          }>
          追加
        </CustomButton>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <div>
          <span>現在の営業リスト数:</span>
          <Count>{projects?.length}</Count>
        </div>

        <div>
          {projects?.map((project: Project) => (
            <div key={project.uuid}>
              <CustomRadio
                checked={selectedProjectId === project.uuid}
                onChange={() => setSelectedProjectId(project.uuid)}>
                <span>{project.name}</span>
                <span>{project.count.toLocaleString()}件</span>
              </CustomRadio>
            </div>
          ))}
        </div>

        <div>
          <Item>
            <List />
            <div>
              <NameInput
                type="number"
                name="count"
                placeholder="件数"
                value={projectCount}
                defaultValue={10}
                onChange={(e) =>
                  (parseInt(e.target.value) > 0 || e.target.value === '') &&
                  setProjectCount(parseInt(e.target.value) || null)
                }
              />
              <span>
                <span className="red">＊&nbsp;</span>上限20000件
              </span>
            </div>
          </Item>
        </div>
      </ModalContent>
    </ReflectModal>
  );
};

const ReflectModal = styled(Modal)`
  &&& {
    .ant-modal-header {
      border: none;
      padding: 16px 24px 13px 24px !important;
    }
    .ant-modal-body {
      padding: 0px 24px !important;
    }
  }
`;

const CustomButton = styled(Button)`
  width: 86px;
  height: 24px;

  span {
    font-weight: bold;
    font-size: 10px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #222426;
`;

const Count = styled.span`
  margin-left: 7px;
  font-weight: bold;
  font-size: 13px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  > *:first-child {
    margin: 6px 0 23px 0;
    display: flex;
    align-items: center;
    max-width: 200px;
    height: 30px;
    padding: 8px;
    background: #f3f5f7;
    border-radius: 4px;
    font-size: 10px;
  }

  > *:nth-child(2) {
    overflow: scroll;
    max-height: 260px;
    border-bottom: 1px solid #f3f5f7;
    padding-bottom: 16px;

    > div {
      margin-bottom: 11px;
      display: flex;
      align-items: center;
    }
  }

  > *:nth-child(3) {
    margin-top: 15px;
  }
`;

const CustomRadio = styled(Radio)`
  width: 360px;
  align-items: center;
  display: flex;

  > span:nth-child(2) {
    display: flex;
    flex: 1;

    > span:first-child {
      flex: 1;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  width: 100%;
  min-height: 26px;
  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }

  > div {
    flex: 1;

    > span {
      display: flex;
      margin-top: 5px;
      .red {
        color: #eb5757;
      }
    }

    span {
      font-size: 9px;
      line-height: 13px;
      color: #899098;
    }
  }
`;

const NameInput = styled(Input)`
  width: 420px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: #e2e6ea;
  border-radius: 4px;
  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;
