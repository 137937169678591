import gql from 'graphql-tag';

export default gql`
  fragment projectSearchConditionFragment on ProjectSearchCondition {
    id
    projectId
    categoryIds
    cityIds
    prefectureTypeIds
    employeeFrom
    employeeTo
    employeeUnknown
    employeeRanges
    capitalFundFrom
    capitalFundTo
    officesNumberFrom
    officesNumberTo
    markets
    companyTypes
    isUrl
    isTel
    isEmail
    isRepresentativePerson
    isCategory
    isCity
    isPrefectureType
    isCompanyAttribute
    isCompanyScale
    isExtra
    isPress
    isFiscal
    isSns
    websiteId
    websiteIds
    monthPressRelease
    countPressRelease
    salesRanges
    profitRateRanges
    profitUp3
    profitDown3
    facebook
    instagram
    youtube
    twitter
    line
    linkedin
    pageKeywords
    pageNegativeKeywords
    accountClosingMonths
    establishedYearMonthFrom
    establishedYearMonthTo
    searchWord
    company {
      uuid
      name
    }
    technologies
    subCategoryIds
  }
`;
