import gql from 'graphql-tag';

export default gql`
  fragment categoryFragment on Category {
    id
    uuid
    level
    name
    displayName
    ancestry
    count
    position
  }
`;
