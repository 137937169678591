import {
  NumberParam,
  DelimitedArrayParam,
  useQueryParams,
} from 'use-query-params';

export const searchConditionParams = {
  subCategoryIds: DelimitedArrayParam,
  employeeRanges: DelimitedArrayParam,
  capitalFundFrom: NumberParam,
  capitalFundTo: NumberParam,
};

const useSearchCondition = () => {
  const [query, setQuery] = useQueryParams(searchConditionParams);

  const resetQuery = () =>
    setQuery({
      subCategoryIds: [],
      employeeRanges: null,
      capitalFundFrom: null,
      capitalFundTo: null,
    });

  return {
    query,
    setQuery,
    resetQuery,
  };
};

export default useSearchCondition;
