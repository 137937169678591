import gql from 'graphql-tag';
import formBlockTextFragment from './blockText';
import formBlockButtonFragment from './blockButton';
import formBlockSubmitButtonFragment from './blockSubmitButton';
import formBlockPersonalInformationFragment from './blockPersonalInformation';
import formBlockFieldStandardFragment from './blockFieldStandard';
import formBlockFieldCustomizeItemFragment from './blockFieldCustomizeItem';
import formBlockFieldOtherFragment from './blockFieldOther';
import formBlockImageFragment from './blockImage';

export default gql`
  fragment formBlockFragment on FormBlock {
    id
    uuid
    blockType
    fieldType
    createdAt
    updatedAt
    formBlockable {
      ... on FormBlockText {
        ...formBlockTextFragment
      }
      ... on FormBlockButton {
        ...formBlockButtonFragment
      }
      ... on FormBlockSubmitButton {
        ...formBlockSubmitButtonFragment
      }
      ... on FormBlockPersonalInformation {
        ...formBlockPersonalInformationFragment
      }
      ... on FormBlockFieldStandard {
        ...formBlockFieldStandardFragment
      }
      ... on FormBlockFieldCustomizeItem {
        ...formBlockFieldCustomizeItemFragment
      }
      ... on FormBlockFieldOther {
        ...formBlockFieldOtherFragment
      }
      ... on FormBlockImage {
        ...formBlockImageFragment
      }
    }
  }
  ${formBlockTextFragment}
  ${formBlockButtonFragment}
  ${formBlockSubmitButtonFragment}
  ${formBlockPersonalInformationFragment}
  ${formBlockFieldStandardFragment}
  ${formBlockFieldCustomizeItemFragment}
  ${formBlockFieldOtherFragment}
  ${formBlockImageFragment}
`;
