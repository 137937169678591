import gql from 'graphql-tag';

export default gql`
  fragment contentMailFormVersionUrlFragment on ContentMailFormVersionUrl {
    id
    uuid
    url
    isConvertUrl
    createdAt
    updatedAt
  }
`;
