import React from 'react';

export default () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00017 12.2943C9.92403 12.2943 12.2943 9.92403 12.2943 7.00017C12.2943 4.07631 9.92403 1.70605 7.00017 1.70605C4.07631 1.70605 1.70605 4.07631 1.70605 7.00017C1.70605 9.92403 4.07631 12.2943 7.00017 12.2943Z"
      stroke="#4FCE82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.52954 7.0003L6.1766 8.76501L9.47072 5.2356"
      stroke="#4FCE82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
