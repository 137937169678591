import React from 'react';
import {Row} from 'react-table';
import moment from 'moment';
import {Prelead} from 'api';

interface Props {
  row: Row<Prelead>;
  value: any;
}

const CustomizeItemDateCell = ({row, value}: Props) => {
  const prelead: Prelead = row.original;
  const clientPreleadCustomizeItemId = value;

  const customizeItem = React.useMemo(
    () =>
      prelead.customizeItemDates.find(
        (item) =>
          item.clientPreleadCustomizeItemId === clientPreleadCustomizeItemId,
      ),
    [prelead, clientPreleadCustomizeItemId],
  );
  return customizeItem?.value
    ? moment(customizeItem?.value).format('YYYY年MM月DD日')
    : null;
};

export default CustomizeItemDateCell;
