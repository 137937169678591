import { PreleadProject, Project } from 'api';
import React from 'react';

export const ApproachModalContext = React.createContext<{
  preleadId: string | null;
  projectId: string | null;
  setProjectId: React.Dispatch<React.SetStateAction<string | null>>;
  project: Project | null;
  projects: Project[] | null;
  preleadProject: PreleadProject | null;
}>({
  preleadId: null,
  projectId: null,
  setProjectId: null,
  project: null,
  projects: [],
  preleadProject: null
});

const useApproachModalContext = () => {
  const approachModalContext = React.useContext(ApproachModalContext);

  return approachModalContext;
};

export default useApproachModalContext;

