import gql from 'graphql-tag';

export default gql`
  fragment workflowTemplateAutomaticMailFragment on WorkflowTemplateAutomaticMail {
    id
    bodyHtml
    bodyText
    subject
    createdAt
    updatedAt
    workflowTemplateStepId
  }
`;
