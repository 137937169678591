import telCategoryColor from 'helpers/telCategoryColor';

const telStatusCategoryOptions = [
  {
    text: 'コール',
    value: 'call',
    color: telCategoryColor('call'),
    isImmutable: false,
  },
  {
    text: '受付',
    value: 'reception',
    color: telCategoryColor('reception'),
    isImmutable: false,
  },
  {
    text: 'キーマン接触',
    value: 'contact',
    color: telCategoryColor('contact'),
    isImmutable: false,
  },
  {
    text: 'アポ獲得',
    value: 'appoint',
    color: telCategoryColor('appoint'),
    isImmutable: false,
  },
  {
    text: '資料送付',
    value: 'document',
    color: telCategoryColor('document'),
    isImmutable: false,
  },
  {
    text: 'その他',
    value: 'other',
    color: telCategoryColor('other'),
    isImmutable: false,
  },
  {
    text: 'コールNG',
    value: 'call_ng',
    color: telCategoryColor('call_ng'),
    isImmutable: true,
  },
  {
    text: '受付NG',
    value: 'reception_ng',
    color: telCategoryColor('reception_ng'),
    isImmutable: true,
  },
  {
    text: 'コンタクトNG',
    value: 'contact_ng',
    color: telCategoryColor('contact_ng'),
    isImmutable: true,
  },
];

export {telStatusCategoryOptions};
