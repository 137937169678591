import gql from 'graphql-tag';

export default gql`
  fragment companyCheckedStatusFragment on CompanyCheckedStatus {
    id
    companyId
    companyColumnType
    isChecked
    lastCheckedAt
    isConfirmed
    lastConfirmedAt
  }
`;
