import employeeRangeOptions from 'helpers/employeeRangeOptions';
import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import {useEmployeeReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();
  const {data: {employeeReports = []} = {}} = useEmployeeReportsQuery({});

  const employeeNames = employeeReports
    .filter((report) => !!report.values[0])
    .map(
      (report) =>
        employeeRangeOptions.find(
          (employeeRangeOption) => employeeRangeOption.value === report.name,
        ).label,
    );

  const employeeValues = employeeReports
    .filter((report) => !!report.values[0])
    .map((report) => report.values)
    .flat();

  return {
    axis: employeeNames,
    data: [
      {
        label: 'リード数',
        color: getColor(5),
        values: employeeValues,
      },
    ],
  };
};

export default useChartData;
