import React from 'react';

function Icon() {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00016 1.25L6.91683 4.16667M4.00016 8.75V1.25V8.75ZM4.00016 1.25L1.0835 4.16667L4.00016 1.25Z"
        stroke="#E1E6EB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
