import React from 'react';
import styled from 'styled-components';
import {ParentCategory} from './ParentCategory';
import technologies from './technologies';

interface Props {
  setActiveCategory: (category: string) => void;
}

const Content = ({setActiveCategory}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [categoryPositions, setCategoryPositions] = React.useState([]);
  React.useLayoutEffect(() => {
    const categoryPositions = Array.prototype.slice
      .call(ref.current?.getElementsByTagName('section'))
      .map((category: any) => ({
        id: category.id,
        top: category.offsetTop - ref.current?.offsetTop,
        bottom:
          category.offsetTop - ref.current?.offsetTop + category.clientHeight,
        height: category.clientHeight,
      }));
    setCategoryPositions(categoryPositions);
  }, []);

  const handleScroll = () => {
    const activeSection = categoryPositions.find((position: any) => {
      const scroll = ref.current?.scrollTop;
      return position.top <= scroll && position.bottom > scroll;
    });
    setActiveCategory(activeSection ? activeSection.id : 1);
  };

  return (
    <Container>
      <Classifications
        onScroll={handleScroll}
        ref={ref}
        style={
          categoryPositions.length > 0
            ? {
                paddingBottom: `calc(100vh - 140px - ${
                  categoryPositions[categoryPositions.length - 1].height
                }px)`,
              }
            : {}
        }>
        {technologies?.map((category: any) => {
          return (
            <ClassificationSection key={category.value} id={category.value}>
              <Wrapper>
                <Square />
                <Title>{category.text}</Title>
              </Wrapper>
              <ParentCategory key={category.value} category={category} />
            </ClassificationSection>
          );
        })}
      </Classifications>
    </Container>
  );
};

export {Content};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 400px;
  padding-left: 30px;
  border-right: 1px solid #f3f3f5;
`;

const Classifications = styled.div`
  overflow-y: scroll;
`;

const ClassificationSection = styled.section`
  overflow-y: scroll;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.15px;
  color: #222426;
  position: relative;
`;

const Square = styled.div`
  background: #222426;
  content: '';
  height: 18px;
  width: 4px;
  margin-right: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
