import React from 'react';
import styled from 'styled-components';
import linkifyHtml from 'linkify-html';
import {
  useMailCampaignQuery,
  useUpdateMailCampaignMutation,
  MailCampaignAttributes,
  useUploadMailImageMutation,
} from 'api';
import {useParams} from 'react-router';
import useDebounce from 'hooks/useDebounce';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Quill as ReactQuill} from 'components/Ui';
import {Quill} from 'react-quill';
import {Form} from 'components/antd';
import Code from './Code';
import TemplateSelect from './TemplateSelect';

export default () => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();
  const ref = React.useRef(null);

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  const [update] = useUpdateMailCampaignMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bodyHtml: mailCampaign?.bodyHtml || '',
      bodyPlainText: mailCampaign?.bodyPlainText || '',
    },
    validationSchema: Yup.object().shape({
      bodyHtml: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) => {
      update({
        variables: {
          uuid: mailCampaignId,
          attributes: values,
        },
      });
    },
  });

  const convertBodyHtml = (text: string) =>
    convertUrlToLink(convertClassToInlineStyle(text));

  const convertClassToInlineStyle = (text: string) =>
    text.replace(/class="ql-align-center"/g, 'style="text-align: center"');

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  const debouncedValue = useDebounce<MailCampaignAttributes>(
    formik.values,
    1000,
  );

  React.useEffect(() => {
    formik.submitForm();
  }, [debouncedValue]);

  const [uploadMailImage] = useUploadMailImageMutation({
    onCompleted: ({uploadMailImage: {imageUrl} = {}}) => {
      insertToEditor(imageUrl);
    },
  });

  const insertToEditor = (url: string) => {
    const range = ref.current.editor.getSelection();
    ref.current.editor.insertEmbed(range.index, 'image', url);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        uploadMailImage({variables: {image: file}});
      } else {
        alert('画像を選択して下さい。');
      }
    };
  };

  const fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = [
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
  ];
  Quill.register(fontSizeStyle, true);

  const modules = React.useMemo(() => {
    return {
      toolbar: {
        handlers: {
          image: selectLocalImage,
        },
        container: [
          [
            {header: [1, 2, 3, false]},
            'bold',
            'italic',
            'underline',
            {align: ['', 'center']},
            {color: []},
            'link',
            'image',
            {size: fontSizeStyle.whitelist},
          ],
        ],
      },
    };
  }, []);

  if (!mailCampaign) {
    return null;
  }

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item label="メール本文">
          <div className="border solid border-c-lighter rounded">
            <Body>
              <ReactQuill
                ref={ref}
                theme="snow"
                defaultValue={mailCampaign?.bodyHtml}
                placeholder="本文を記入してください"
                onChange={(content, delta, source, editor) => {
                  formik.setFieldValue('bodyHtml', content);
                  formik.setFieldValue('bodyPlainText', editor.getText());
                }}
                onBlur={() =>
                  formik.setFieldValue(
                    'bodyHtml',
                    convertBodyHtml(formik.values.bodyHtml),
                  )
                }
                modules={modules}
                bounds={'#quill-container'}
                readOnly={mailCampaign.status !== 'draft'}
              />
              <TemplateSelect
                style={{
                  position: 'absolute',
                  top: '-40px',
                  right: '0',
                  width: '360px',
                }}
                onSelect={(value: string) => {
                  if (value) {
                    document.getElementsByClassName('ql-editor')[0].innerHTML =
                      value;
                  }
                }}
              />
              <div id="quill-container" />
              <Actions>
                <Code
                  mailCampaign={mailCampaign}
                  onSelect={(value: string) => {
                    if (ref.current?.editor.getSelection()) {
                      ref.current?.editor.insertText(
                        ref.current?.editor.getSelection().index,
                        value,
                      );
                    }
                  }}
                />
              </Actions>
            </Body>
            <div
              className="rounded min-w-full h-12 flex"
              style={{backgroundColor: '#F3F5F7'}}>
              <div
                className="flex items-center text-base ml-4"
                style={{color: '#495058'}}>
                ※配信停止をご希望の方は、お手数ですが
                <a href="" rel="noopener noreferrer" target="_blank">
                  こちら
                </a>
                からお手続きください。
              </div>
            </div>
          </div>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 25px;
  label {
    font-size: 14px;
    color: #899098;
    font-weight: 500;
  }
`;

const Body = styled.div`
  padding: 8px 8px 40px 8px;
  position: relative;

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }

  .ql-snow .ql-picker.ql-size {
    width: 50px;

    .ql-picker-label {
      padding-left: 0;
    }

    .ql-picker-options {
      width: 50px;
    }
  }

  .ql-container.ql-snow {
    min-height: 150px;
    border: none;

    .ql-editor {
      min-height: 200px;
      overflow-y: scroll;
      resize: vertical;
      padding: 0;

      &::before {
        left: 0;
      }
    }
  }

  .ql-toolbar {
    position: absolute;
    bottom: 40px;
    width: 100%;
    transform: translateY(100%);
    border: none;
    background: transparent;
    padding-left: 0;
  }
`;

const Actions = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  gap: 8px;
`;
