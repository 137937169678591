import React from 'react';
import {HubspotDealPropertyDefinition} from 'api';
import {
  Boolean,
  Checkbox,
  Date,
  Number,
  Radio,
  Select,
  Text,
  TextArea,
} from './Item';

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
  propertyDefinition: HubspotDealPropertyDefinition;
}

export const DealPropertyItem: React.FC<Props> = ({
  addition,
  setAddition,
  propertyDefinition,
}) => {
  const updateAddition = (key: string, value: string) => {
    let newAddition: {[key: string]: any} = Object.assign({}, addition);
    newAddition[`${key}`] = value;
    setAddition(newAddition);
  };

  switch (propertyDefinition.fieldTypeString) {
    case 'booleancheckbox':
      return (
        <Boolean
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    case 'date':
      return (
        <Date
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    case 'number':
      return (
        <Number
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    case 'radio':
      return (
        <Radio
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    case 'select':
      return (
        <Select
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    case 'text':
      return (
        <Text
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    case 'textarea':
      return (
        <TextArea
          propertyDefinition={propertyDefinition}
          handleUpdate={updateAddition}
        />
      );
    default:
      return null;
  }
};
