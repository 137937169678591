import React from 'react';
import styled from 'styled-components';
import {SecondMenu} from './SecondMenu';
import {Content} from './Content';
import Count from '../Count';
import {useMainCategoriesQuery, useProjectQuery} from 'api';
import Action from './Action';
import FilterAction from '../FilterAction';
import Menu from '../Menu';
import {Breadcrumb, Header, Container} from 'components/Ui';
import {Link, useParams} from 'react-router-dom';
import CompanyInfo from '../CompanyInfo';
import {useQueryParam, StringParam} from 'use-query-params';
import useProjectPath from 'hooks/useProjectPath';

const Category = () => {
  const [activeMainCategory, setActiveMainCategory] = React.useState('');
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });
  const [specificCompanyId] = useQueryParam('specificCompanyId', StringParam);

  useMainCategoriesQuery({
    onCompleted: ({mainCategories = []} = {}) => {
      mainCategories.length && setActiveMainCategory('classification-0');
    },
  });

  return (
    <Container page="newProject">
      <Header padding={32}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={createPath('projects')}>営業リスト一覧</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={createPath(
                `projects/${project?.uuid}/search_condition?status=draft`,
              )}>
              {project?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="current">リスト条件の編集</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <ActionWrapper>
        {specificCompanyId && <CompanyInfo />}
        <Menu />
        <FilterAction />
        <Action />
      </ActionWrapper>
      <Body>
        <SecondMenu activeMainCategory={activeMainCategory} />
        <Content setActiveMainCategory={setActiveMainCategory} />
        <Count />
      </Body>
    </Container>
  );
};

export {Category};

const ActionWrapper = styled.div`
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  padding-left: 8px;
  background: #ffffff;
`;

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
`;
