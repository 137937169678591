import React from 'react';
import styled from 'styled-components';
import {Input} from 'components/antd';
import {useParams} from 'react-router';
import {useUpdateContentMutation, Content} from 'api';
import {LockPrivary, World} from 'components/Ui/Icon';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {id} = useParams<{id: string}>();
  const [updateContent] = useUpdateContentMutation();
  const onChangeTitle = React.useCallback(
    (e: any) =>
      updateContent({variables: {id, attributes: {title: e.target.value}}}),
    [],
  );

  return (
    <Title>
      {content.document.status === 'private' ? (
        <Private>
          <LockPrivary color="#899098" />
          非公開
        </Private>
      ) : (
        <Public>
          <World color="#4FCE82" />
          公開中
        </Public>
      )}
      <Input
        size="large"
        defaultValue={content?.title}
        onBlur={onChangeTitle}
      />
    </Title>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;

  input {
    font-size: 18px;
    font-weight: bold;
    width: 50%;
    border: none;
    background: transparent;
    flex: 1;
  }

  .ant-input:focus {
    border-color: none !important;
    outline: 0 !important;
    box-shadow: none !important;
  }
`;

const Private = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #899098;
  height: 28px;
  padding: 0 0.5rem;
  color: #899098;
  margin-right: 0.5rem;
  border-radius: 3px;

  svg {
    margin-right: 0.5rem;
  }
`;

const Public = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #4fce82;
  height: 28px;
  padding: 0 0.5rem;
  color: #4fce82;
  margin-right: 0.5rem;
  border-radius: 3px;

  svg {
    margin-right: 0.5rem;
  }
`;
