import gql from 'graphql-tag';

export default gql`
  fragment applyMultipleBlockGroupFragment on ApplyMultipleBlockGroup {
    id
    status
    rowCount
    currentRowNumber
    percent
  }
`;
