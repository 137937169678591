import gql from 'graphql-tag';

export default gql`
  fragment uploadHeaderFragment on UploadHeader {
    id
    projectId
    headerType
    name
    columnKey
    isDisplay
    position
  }
`;
