const customTheme = {
  baseColor: '#222426',
  themeColor: {
    project: '#8966EF',
    prelead: '#66CCEF',
    report: '#4FCE82',
    block: '#F88C5C',
    mail: '#5AA99A',
    garage: '#FFC600',
  },
  status: {
    project: {
      background: {
        open: '#DFF2E7',
        archived: '#EBD9DB',
        draft: '#E2E6EA',
      },
      fontColor: {
        open: '#4FCE82',
        archived: '#B55D62',
        draft: '#899098',
      },
    },
    prelead: {
      pending: '',
      approaching: '#68b5fb',
      appoint: '#27ae60',
      lead: '#27ae60',
      webinar: '#27ae60',
      download: '#27ae60',
      interview: '#27ae60',
      recyclelead: '#27ae60',
      inquiry: '#2f80ed',
      refusal: '#bdc4cb',
      block: '#c90e19',
      prospect: '#F2C94C',
    },
    response: {
      no_response: '',
      reply: '#2D9CDB',
      inquiry: '#2d9cdb',
      appoint: '#27ae60',
      refusal: '#EB5757',
    },
    tel: {
      pending: '',
      calling: '#bdc4cb',
      request_callback: '#bdc4cb',
      answering_machine: '#bdc4cb',
      unreachable: '#bdc4cb',
      called: '#bdc4cb',
      no_solicitors: '#2d9cdb',
      sending_documents: '#2d9cdb',
      no_call: '#2d9cdb',
      absence: '#2d9cdb',
      done_no_appointment: '#27ae60',
      done_appointment: '#27ae60',
      done_reschedule: '#27ae60',
      done_sent_documents: '#27ae60',
    },
    mail: {
      unsent: '',
      sent: '#8966EF',
      mail: '#8966EF',
      cannotsend: '#bdc4cb',
      cannotread: '#bdc4cb',
      invalid: '#bdc4cb',
      shortage: '#bdc4cb',
      notfound: '#bdc4cb',
      charover: '#bdc4cb',
      recruit: '#bdc4cb',
      cannotaccept: '#bdc4cb',
      unknown: '#bdc4cb',
      duplicate: '#bdc4cb',
      block: '#bdc4cb',
      nourl: '#bdc4cb',
    },
    preleadCategory: {
      pending: '',
      approaching: '#68b5fb',
      appoint: '#27ae60',
      inquiry: '#2f80ed',
      refusal: '#bdc4cb',
      block: '#c90e19',
      prospect: '#F2C94C',
    },
    telCategory: {
      call: '#bdc4cb',
      reception: '#66D7EF',
      contact: '#2d9cdb',
      appoint: '#27ae60',
      document: '#8966EF',
      other: '#3E3A39',
      call_ng: '#FFC75A',
      reception_ng: '#FF8F00',
      contact_ng: '#EB5757',
    },
    letter: {
      unsent: '',
      sent: '#27AE60',
    },
  },
};

export default customTheme;
