import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import Form from './Form';
import {useParams, useHistory} from 'react-router';
import {useTeamQuery, useUpdateTeamMutation} from 'api';

export default () => {
  const {teamId} = useParams<{teamId: string}>();
  const history = useHistory();
  const {data: {team} = {}} = useTeamQuery({
    variables: {uuid: teamId},
    fetchPolicy: 'cache-and-network',
  });
  const [updateTeam, {loading}] = useUpdateTeamMutation({
    onCompleted: () => history.push('/settings/teams'),
  });

  return (
    <Container>
      <Form team={team} onSubmit={updateTeam} loading={loading} />
    </Container>
  );
};

const Container = styled.div`
  padding: 28px 70px 45px;
  width: calc(100vw - 350px);
`;
