import React from 'react';

function Icon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.75 6C24.75 4.34325 23.4068 3 21.75 3C20.0932 3 18.75 4.34325 18.75 6C18.75 6.9405 18.75 13.3057 18.75 14.25C18.75 15.9067 20.0932 17.25 21.75 17.25C23.4068 17.25 24.75 15.9067 24.75 14.25C24.75 13.3057 24.75 6.9405 24.75 6Z"
        fill="#33D375"
      />
      <path
        d="M32.25 14.25C32.25 15.9068 30.9068 17.25 29.25 17.25C28.3537 17.25 26.25 17.25 26.25 17.25C26.25 17.25 26.25 15.0105 26.25 14.25C26.25 12.5932 27.5932 11.25 29.25 11.25C30.9068 11.25 32.25 12.5932 32.25 14.25Z"
        fill="#33D375"
      />
      <path
        d="M6 10.5C4.34325 10.5 3 11.8432 3 13.5C3 15.1568 4.34325 16.5 6 16.5C6.9405 16.5 13.3057 16.5 14.25 16.5C15.9067 16.5 17.25 15.1568 17.25 13.5C17.25 11.8432 15.9067 10.5 14.25 10.5C13.3057 10.5 6.9405 10.5 6 10.5Z"
        fill="#40C4FF"
      />
      <path
        d="M14.25 3C15.9068 3 17.25 4.34325 17.25 6C17.25 6.89625 17.25 9 17.25 9C17.25 9 15.0105 9 14.25 9C12.5932 9 11.25 7.65675 11.25 6C11.25 4.34325 12.5932 3 14.25 3Z"
        fill="#40C4FF"
      />
      <path
        d="M10.5 29.2545C10.5 30.909 11.8432 32.25 13.5 32.25C15.1568 32.25 16.5 30.909 16.5 29.2545C16.5 28.3155 16.5 21.9592 16.5 21.0165C16.5 19.362 15.1568 18.021 13.5 18.021C11.8432 18.021 10.5 19.362 10.5 21.0165C10.5 21.9592 10.5 28.3155 10.5 29.2545Z"
        fill="#E91E63"
      />
      <path
        d="M3 21.0165C3 19.362 4.34325 18.021 6 18.021C6.89625 18.021 9 18.021 9 18.021C9 18.021 9 20.2567 9 21.0165C9 22.671 7.65675 24.012 6 24.012C4.34325 24.012 3 22.671 3 21.0165Z"
        fill="#E91E63"
      />
      <path
        d="M29.25 24.75C30.9067 24.75 32.25 23.4068 32.25 21.75C32.25 20.0932 30.9067 18.75 29.25 18.75C28.3095 18.75 21.9442 18.75 21 18.75C19.3432 18.75 18 20.0932 18 21.75C18 23.4068 19.3432 24.75 21 24.75C21.9435 24.75 28.3095 24.75 29.25 24.75Z"
        fill="#FFC107"
      />
      <path
        d="M21 32.25C19.3432 32.25 18 30.9068 18 29.25C18 28.3537 18 26.25 18 26.25C18 26.25 20.2395 26.25 21 26.25C22.6568 26.25 24 27.5932 24 29.25C24 30.9068 22.6568 32.25 21 32.25Z"
        fill="#FFC107"
      />
    </svg>
  );
}

export default Icon;
