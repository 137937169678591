import React from 'react';
import moment from 'moment';
import {useQueryParam, NumberParam} from 'use-query-params';
import {Link, useParams} from 'react-router-dom';
import {useFormPostsQuery, FormPost} from 'api';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import Pagination from './Pagination';

const PER_PAGE = 50;

const columnHelper = createColumnHelper<FormPost>();

const columns = ({formId}: {formId: string}) => {
  return [
    columnHelper.accessor('createdAt', {
      header: () => '受信日',
      cell: ({getValue}) => (
        <div>
          <div>{moment(getValue()).format('YYYY/MM/DD')}</div>
          <div>{moment(getValue()).format('HH:mm')}</div>
        </div>
      ),
      size: 120,
    }),
    columnHelper.accessor('lastName', {
      header: () => '姓名',
      cell: ({row}) => (
        <Link
          to={`/form/forms/${formId}/posts/${row.original.uuid}`}>{`${row.original.lastName} ${row.original.firstName}`}</Link>
      ),
      size: 300,
    }),
    columnHelper.accessor('email', {
      header: () => 'メールアドレス',
      cell: ({getValue}) => getValue(),
    }),
  ];
};

const Table = () => {
  const {formId} = useParams<{
    formId: string;
  }>();
  const [page, setPage] = useQueryParam('page', NumberParam);

  const {data: {formPosts: {formPosts = [], pagination = {}} = {}} = {}} =
    useFormPostsQuery({
      variables: {uuid: formId, page: page || 1, perPage: PER_PAGE},
      fetchPolicy: 'cache-and-network',
    });

  const table = useReactTable({
    data: formPosts,
    columns: columns({formId}),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <table className="w-full mb-4">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="text-left border-b border-b-c-border"
                  style={{width: `${header.getSize()}px`}}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-b-c-border min-h-12 py-1 align-middle">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        pageSize={PER_PAGE}
        current={pagination.currentPage || 1}
        total={pagination.totalCount || 0}
        onChange={(page) => setPage(page)}
      />
    </>
  );
};

export default Table;
