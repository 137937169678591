import gql from 'graphql-tag';

export default gql`
  fragment preleadProjectReminderCreateActivityFragment on PreleadProjectReminderCreateActivity {
    preleadProjectReminderId
    preleadProjectReminder {
      memo
      remindAt
    }
  }
`;
