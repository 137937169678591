import React from 'react';
import styled from 'styled-components';
import {useParams, Link} from 'react-router-dom';
import {Breadcrumb} from 'components/antd';
import Title from './Title';
import {useMailCampaignQuery} from 'api';
import Prospects from './Prospects';
import Preleads from './Preleads';
import {RecoilRoot} from 'recoil';

export default () => {
  const {poolId, mailCampaignId} = useParams<{
    poolId: string;
    mailCampaignId: string;
  }>();

  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  if (!mailCampaign) return null;

  return (
    <Container>
      <RecoilRoot>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/mail_campaigns?target=${mailCampaign.target}`}>〈 一覧に戻る</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Title mailCampaign={mailCampaign} />
        {mailCampaign.target === 'prospects' && <Prospects />}
        {mailCampaign.target === 'preleads' && <Preleads />}
      </RecoilRoot>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow-y: scroll;
`;
