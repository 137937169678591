import React from 'react';
import {useRouteMatch, Route, useHistory} from 'react-router-dom';
import {ProspectPool, useApproachesByProspectPoolQuery} from 'api';
import Tab from './Tab';
import DropDown from './DropDown';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const history = useHistory();
  const {url, path} = useRouteMatch();
  const {data: {approachesByProspectPool = []} = {}} =
    useApproachesByProspectPoolQuery({
      variables: {
        uuid: prospectPool.uuid,
      },
    });

  return (
    <div className="flex justify-between border-b border-c-border pt-1">
      <Tab
        label="タイムライン"
        to={`${url}/timeline`}
        count={prospectPool?.prospectActivities?.length || 0}
      />
      <Tab
        label="TEL"
        to={`${url}/tel`}
        count={prospectPool?.callActivities?.length || 0}
      />
      <Tab
        label="メール"
        to={`${url}/mail`}
        count={prospectPool?.mailSendActivities?.length || 0}
      />
      <Tab
        label="ToDo"
        to={`${url}/task`}
        count={prospectPool?.taskActivities?.length || 0}
      />
      <Route path={`${path}/:tab`}>
        <DropDown
          onChange={(tab: string) =>
            history.push(`${url}/${tab}${location.search}`)
          }
        />
      </Route>
    </div>
  );
};
