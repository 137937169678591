import React from 'react';
import {Switch, Route} from 'react-router-dom';
import List from './List';
import Pool from './Pool';

export default () => {
  return (
    <Switch>
      <Route path="/settings/pools/:poolId" component={Pool} />
      <Route path="/settings/pools" component={List} />
    </Switch>
  );
};
