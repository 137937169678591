import {ProspectPool} from 'api';
import React from 'react';
import {Row} from 'react-table';
import moment from 'moment';

interface Props {
  row: Row<ProspectPool>;
}

const TrackedDate = ({row}: Props) => {
  const prospectPool = row.original;

  if (!prospectPool.trackedAt) {
    return <></>;
  }

  return <div>{moment(prospectPool.trackedAt).format('YYYY/MM/DD')}</div>;
};

export default TrackedDate;
