import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {useClientPhaseProspectsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {clientPhaseProspects = []} = {}} =
    useClientPhaseProspectsQuery();

  if (!query?.phases || !query.phases.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">フェーズ</h4>
        <button
          onClick={() => setFilterType('phase')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setQuery({...query, phases: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        {clientPhaseProspects
          .filter((phase) => query.phases.includes(phase.id))
          .map((phase) => (
            <span
              key={phase.name}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {phase.name}
            </span>
          ))}
      </div>
    </div>
  );
};
