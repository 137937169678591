import React from 'react';
import styled from 'styled-components';
import {usePreleadQuery} from 'api';
import moment from 'moment';
import usePreleadContext from '../../usePreleadContext';

export default () => {
  const {preleadId} = usePreleadContext();

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const isPressReleaseWithinOneWeek =
    prelead?.prLastPublishedAt &&
    moment(prelead.prLastPublishedAt).isAfter(moment().subtract(1, 'week'));

  return (
    <Container>
      {isPressReleaseWithinOneWeek && <span>一週間以内</span>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    color: #eb5757;
  }
`;
