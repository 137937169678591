import React from 'react';
import styled from 'styled-components';
import {useWebsitesQuery} from 'api';
import {
  Boxil,
  Doda,
  Entenshoku,
  FromA,
  Green,
  Jobken,
  MyNabi,
  MyNavi2021,
  MyNavi2022,
  MyNavi2024,
  MyNabiBaito,
  RikuNabi2021,
  RikuNabi2022,
  RikuNabi2024,
  RikuNabiNext,
  StartupDB,
  TownWork,
  Type,
  TypeWomen,
  Wamnet,
  Wantedly,
  OneCareer,
  PrTimes,
  Hellowork,
} from 'components/Ui/Icon';
import {useQueryParam, StringParam, withDefault} from 'use-query-params';
import {useHistory, useParams} from 'react-router';
import useSearchCondition from '../useSearchCondition';
import moment from 'moment';
import useProjectPath from 'hooks/useProjectPath';

const MediaLogo: {[key: string]: JSX.Element} = {
  rikunabi: <RikuNabiNext />,
  mynavi: <MyNabi />,
  green: <Green />,
  wantedly: <Wantedly />,
  entenshoku: <Entenshoku />,
  doda: <Doda />,
  type: <Type />,
  typewoman: <TypeWomen />,
  jobken: <Jobken />,
  mynavi2021: <MyNavi2021 />,
  mynavi2022: <MyNavi2022 />,
  mynavi2024: <MyNavi2024 />,
  rikunabi2021: <RikuNabi2021 />,
  rikunabi2022: <RikuNabi2022 />,
  rikunabi2024: <RikuNabi2024 />,
  mynavibaito: <MyNabiBaito />,
  townwork: <TownWork />,
  froma: <FromA />,
  boxil: <Boxil />,
  startupdb: <StartupDB />,
  wamnet: <Wamnet />,
  onecareer: <OneCareer />,
  prtimes: <PrTimes />,
  hellowork: <Hellowork />,
};

const Content = () => {
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {projectId} = useParams<{projectId: string}>();
  const [pageType] = useQueryParam(
    'pageType',
    withDefault(StringParam, 'recruit'),
  );
  const {resetQuery} = useSearchCondition();
  const {data: {websites = []} = {}, loading} = useWebsitesQuery({
    variables: {
      search: {
        pageTypes: [pageType],
      },
    },
  });

  const websitesFiltered = websites.filter(
    (website) =>
      ![
        'hellowork',
        'mynavi2021',
        'mynavi2022',
        'rikunabi2021',
        'rikunabi2022',
      ].includes(website.websiteType),
  );

  return loading ? null : (
    <Container>
      {websitesFiltered.map((website) => (
        <Media
          key={website.id}
          onClick={() => {
            history.push(
              createPath(
                `projects/${projectId}/search/website/single/${website.websiteType}`,
              ),
            );
            resetQuery();
          }}>
          {MediaLogo[website.websiteType]}
          <div>
            <span>企業数</span>
            <span>{website.uniqueCompanyCount.toLocaleString()}</span>
          </div>
          <div>
            <span>
              更新日：{moment(website.updatedAt).format('YYYY年MM月DD日')}
            </span>
          </div>
        </Media>
      ))}
    </Container>
  );
};
export {Content};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  padding: 30px;
`;

const Media = styled.div`
  margin-right: 24px;
  margin-bottom: 24px;
  width: 160px;
  height: 160px;
  background: #ffffff;
  border: 1px solid #e2e6ea;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;

  svg {
    width: 116px;
    height: 45px;
    margin: 17px auto 5px auto;
  }

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-left: auto;
    > span:nth-child(1) {
      margin-right: auto;
      font-weight: bold;
      font-size: 12px;
      color: #495058;
    }
    > span:nth-child(2) {
      font-weight: bold;
      font-size: 22px;
      color: #495058;
    }
  }

  > div:nth-child(3) {
    width: 100%;
    display: flex;
    align-items: center;
    > span {
      margin-left: auto;
      font-size: 10px;
      color: #899098;
    }
  }
`;
