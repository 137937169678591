import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useCurrentClientQuery, useProjectSummaryQuery} from 'api';
import preleadColor from 'helpers/preleadColor';
import preleadCategoryColor from 'helpers/preleadCategoryColor';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project: {preleadStatusCountObjects = []} = {}} = {}} =
    useProjectSummaryQuery({
      variables: {uuid: projectId},
    });
  const {data: {currentClient: {preleadStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const preleadStatusCount = React.useCallback(
    (id) =>
      preleadStatusCountObjects.find(
        (preleadStatusCountObject) => preleadStatusCountObject.id === id,
      )?.count,
    [preleadStatusCountObjects],
  );

  const preleadPendingStatusCount = React.useMemo(
    () =>
      preleadStatusCountObjects.find(
        (preleadStatusCountObject) => !preleadStatusCountObject.id,
      )?.count,
    [preleadStatusCountObjects],
  );

  return (
    <Container>
      <Title>
        <StatusMark />
        ステータス情報
      </Title>

      <Body>
        <Item>
          <Name>
            <StatusIcon color={preleadColor('pending')} />未
          </Name>
          <Value>{preleadPendingStatusCount?.toLocaleString() || 0}</Value>
        </Item>

        {preleadStatuses.map((preleadStatus) => (
          <Item key={preleadStatus.id}>
            <Name>
              <StatusIcon
                color={preleadCategoryColor(preleadStatus.category)}
              />
              {preleadStatus.name}
            </Name>
            <Value>
              {preleadStatusCount(preleadStatus.id)?.toLocaleString() || 0}
            </Value>
          </Item>
        ))}
      </Body>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: #bdc4cb;
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e2e6ea;
  svg {
    margin-right: 6px;
  }
`;

const Body = styled.div`
  width: 378px;
`;

const Item = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`;

const StatusIcon = styled.span<{color: string}>`
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid #bdc4cb'};
`;

const StatusMark = styled.span`
  border: 1.5px solid #bdc4cb;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  width: 14px;
  margin-right: 6px;
  margin-left: 3px;
  border-radius: 4px;
`;

const Name = styled.span`
  margin-right: 15px;
  width: 200px;
  font-weight: bold;
  font-size: 14px;
  color: #bdc4cb;
  padding: 0 4px;
`;

const Value = styled.span`
  margin-left: auto;
  text-align: right;
`;
