import React from 'react';
import classifications from 'helpers/classifications';
import {
  useMainCategoriesQuery,
  useUpdateClientAccountScoreSettingSubCategoriesMutation,
  useAccountScoreSettingQuery,
} from 'api';

export default () => {
  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery({});
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const selectedCategories = accountScoreSetting?.subCategoryIds ?? [];
  const [updateCategories] =
    useUpdateClientAccountScoreSettingSubCategoriesMutation({});
  return (
    <div className="p-6 bg-c-bg rounded-b h-full overflow-y-scroll flex-1 flex flex-col gap-2">
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b">
        <div className="bg-white p-4 rounded flex gap-2 flex-1">
          <div className="w-64 overflow-y-scroll overflow-x-hidden relative py-2">
            <div className="w-64 absolute">
              {classifications(mainCategories).map((classification) => (
                <div
                  key={classification.name}
                  onClick={() =>
                    document
                      .getElementById(classification.name)
                      .scrollIntoView()
                  }
                  className="cursor-pointer w-64 h-8 flex items-center text-base px-4">
                  <span className="flex-1">{classification.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 overflow-y-scroll overflow-x-hidden relative py-2">
            <div className="absolute w-full px-4 flex flex-col gap-8">
              {classifications(mainCategories).map((classification) => (
                <section key={classification.name} id={classification.name}>
                  <div className="flex items-center gap-2 mb-4">
                    <div className="w-1 h-5 bg-[#222426]" />
                    <h3 className="font-bold text-lg m-0">
                      {classification.name}
                    </h3>
                  </div>
                  {classification.categories.map((category) => (
                    <div key={category.id} className="mb-8">
                      <label className="flex items-center text-base w-full cursor-pointer">
                        <input
                          type="checkbox"
                          value={category.id}
                          checked={category.subCategories.every((sc) =>
                            selectedCategories.includes(sc.id),
                          )}
                          className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light cursor-pointer"
                          onChange={(e) => {
                            const arr = e.target.checked
                              ? Array.from(
                                  new Set([
                                    ...selectedCategories,
                                    ...category.subCategories.map(
                                      (sc) => sc.id,
                                    ),
                                  ]),
                                )
                              : selectedCategories.filter(
                                  (id) =>
                                    !category.subCategories.some(
                                      (sc) => sc.id === id,
                                    ),
                                );
                            updateCategories({
                              variables: {subCategoryIds: arr},
                            });
                          }}
                        />
                        <span className="ml-2">
                          {category.displayName}すべて
                        </span>
                      </label>
                      <div className="my-4 pt-4 border-t border-c-border">
                        {category.subCategories.map((subCategory) => (
                          <label
                            key={subCategory.id}
                            className="flex items-center text-base w-full cursor-pointer">
                            <input
                              type="checkbox"
                              value={subCategory.id}
                              className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light cursor-pointer"
                              checked={selectedCategories.includes(
                                subCategory.id,
                              )}
                              onChange={(e) => {
                                const arr = e.target.checked
                                  ? Array.from(
                                      new Set([
                                        ...selectedCategories,
                                        subCategory.id,
                                      ]),
                                    )
                                  : selectedCategories.filter(
                                      (id) => id !== subCategory.id,
                                    );
                                updateCategories({
                                  variables: {subCategoryIds: arr},
                                });
                              }}
                            />
                            <span className="ml-2">
                              {subCategory.displayName}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
