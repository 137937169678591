import React from 'react';

function Icon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 0C3.35693 0 0 3.35693 0 7.5C0 11.6431 3.35693 15 7.5 15C11.6431 15 15 11.6431 15 7.5C15 3.35693 11.6431 0 7.5 0ZM7.5 1.25C10.9521 1.25 13.75 4.04785 13.75 7.5C13.75 10.9521 10.9521 13.75 7.5 13.75C4.04785 13.75 1.25 10.9521 1.25 7.5C1.25 4.04785 4.04785 1.25 7.5 1.25ZM7.5 3.63281C7.38525 3.63281 7.29004 3.63037 7.1875 3.65234C7.08496 3.67432 6.98975 3.73047 6.91406 3.78906C6.83838 3.84766 6.78223 3.92822 6.73828 4.02344C6.69434 4.11865 6.67969 4.23096 6.67969 4.375C6.67969 4.5166 6.69434 4.62891 6.73828 4.72656C6.78223 4.82422 6.83838 4.90234 6.91406 4.96094C6.98975 5.01953 7.08496 5.05371 7.1875 5.07812C7.29004 5.10254 7.38525 5.11719 7.5 5.11719C7.61231 5.11719 7.73193 5.10254 7.83203 5.07812C7.93213 5.05371 8.01025 5.01953 8.08594 4.96094C8.16162 4.90234 8.21777 4.82422 8.26172 4.72656C8.30566 4.63135 8.33984 4.5166 8.33984 4.375C8.33984 4.23096 8.30566 4.11865 8.26172 4.02344C8.21777 3.92822 8.16162 3.84766 8.08594 3.78906C8.01025 3.73047 7.93213 3.67432 7.83203 3.65234C7.73193 3.63037 7.61231 3.63281 7.5 3.63281ZM6.73828 5.72266V11.3281H8.26172V5.72266H6.73828Z"
        fill="#BDC4CB"
      />
    </svg>
  );
}

export default Icon;
