import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Actions from './actions';
import Tasks from './tasks';

export default () => (
  <Switch>
    <Route
      path={[
        '/pools/:poolId/action/actions/:actionId/prospect',
        '/pools/:poolId/action/actions',
      ]}
      component={Actions}
    />
    <Route path="/pools/:poolId/action/tasks" component={Tasks} />
  </Switch>
);
