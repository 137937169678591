import React from 'react';
import styled from 'styled-components';
import {AutomaticMailSend, SendgridMailSend} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<AutomaticMailSend>;
}

const statusLabel = (sendgridMailSend: SendgridMailSend) => {
  if (sendgridMailSend?.isClicked) {
    return (
      <StatusClickedLabel>
        <span>クリック</span>
      </StatusClickedLabel>
    );
  } else if (sendgridMailSend?.isOpened) {
    return (
      <StatusOpenedLabel>
        <span>開封済</span>
      </StatusOpenedLabel>
    );
  } else if (sendgridMailSend?.isBounced) {
    return (
      <StatusBouncedLabel>
        <span>バウンス</span>
      </StatusBouncedLabel>
    );
  } else {
    return (
      <StatusLabel>
        <span>配信済</span>
      </StatusLabel>
    );
  }
};

export default ({row}: Props) => {
  const sendgridMailSend = row.original?.mailSend;

  return statusLabel(sendgridMailSend);
};

const StatusLabel = styled.div`
  width: 58px;
  height: 24px;
  vertical-align: middle;
  text-align: center;
  border-radius: 2px;
  background: #ddf3f4;

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #4ea9af;
  }
`;

const StatusClickedLabel = styled(StatusLabel)`
  background: #fcecd7;
  > span {
    color: #ff8f00;
  }
`;

const StatusOpenedLabel = styled(StatusLabel)`
  background: #ece6fd;
  > span {
    color: #8e76d6;
  }
`;

const StatusBouncedLabel = styled(StatusLabel)`
  background: #899098;
  > span {
    color: #ffffff;
  }
`;
