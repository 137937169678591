import {
  useQueryParams,
  withDefault,
  NumberParam,
  DelimitedArrayParam,
} from 'use-query-params';

const searchParamsDef = {
  hidedProspectTelStatusColumnIds: withDefault(DelimitedArrayParam, []),
  year: withDefault(NumberParam, new Date().getFullYear()),
  month: withDefault(NumberParam, new Date().getMonth() + 1),
  leadSourceIds: withDefault(DelimitedArrayParam, []),
  tagIds: withDefault(DelimitedArrayParam, []),
  userIds: withDefault(DelimitedArrayParam, []),
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);

  const searchParams = {
    hidedProspectTelStatusColumnIds: query.hidedProspectTelStatusColumnIds,
    year: query.year,
    month: query.month,
    leadSourceIds: query.leadSourceIds,
    tagIds: query.tagIds,
    userIds: query.userIds,
  };

  return {
    query,
    setQuery,
    searchParams: searchParams,
  };
};

export default useSearchParams;
