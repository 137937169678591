import React from 'react';
import styled from 'styled-components';
import {Radio} from 'components/antd';
import Conditions from './newConditions';
import Leads from './leads';
import {ProspectPool, Pagination as PaginationType} from 'api';
import {useRecoilValue} from 'recoil';
import totalCountAtom from '../../totalCountAtom';

interface Props {
  prospectPools: ProspectPool[];
  pagination: PaginationType;
  setPage: (page: number) => void;
  perPage: number;
}

export default ({prospectPools, pagination, setPage, perPage}: Props) => {
  const [type, setType] = React.useState('conditions');
  const count = useRecoilValue(totalCountAtom);

  return (
    <Container>
      <Radio.Group
        value={type}
        onChange={(e) => setType(e.target.value)}
        style={{marginBottom: 24}}>
        <Radio.Button value="conditions">リード条件</Radio.Button>
        <Radio.Button value="leads">送信先</Radio.Button>
      </Radio.Group>
      <TitleSection>
        <Title>{type === 'leads' ? '送信先' : 'リード条件'}</Title>
        <Count>
          送信先 <span>{count ? count.toLocaleString() : 0}</span>
        </Count>
      </TitleSection>
      {type === 'conditions' && <Conditions />}
      {type === 'leads' && (
        <Leads
          prospectPools={prospectPools}
          pagination={pagination}
          setPage={setPage}
          perPage={perPage}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 25px;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Count = styled.div`
  font-size: 14px;
  span {
    font-weight: bold;
    font-size: 20px;
    margin-left: 8px;
    margin-right: 8px;
  }
  div {
    font-size: 12px;
    font-weight: 500;
    color: #899098;
    display: flex;
    justify-content: end;
  }
`;
