import React from 'react';
import {FormBlock, FormPost, FormBlockFieldCustomizeItem} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldCustomizeItem;
}

interface Props {
  block: Block;
  post: FormPost;
}

export default ({block, post}: Props) => {
  const poolProspectCustomizeItem =
    block.formBlockable.poolProspectCustomizeItem;
  const label = poolProspectCustomizeItem.name;
  const value = post.formPostCustomizeItemSelects.find(
    (item) => item.formBlockFieldCustomizeItemId === block.formBlockable.id,
  )?.poolProspectCustomizeItemSelectOptions.map((item) => item.label).join(', ');

  return (
    <div className="w-full flex flex-col gap-1">
      <label className="text-c-light text-sm m-0">{label}</label>
      <p className="m-0 text-base">{value}</p>
    </div>
  );
};
