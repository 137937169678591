import React from 'react';
import EditIcon from './EditIcon';
// import TemplateIcon from './TemplateIcon';
import Modal from '../../../pools/detail/account_groups/AccountGroup/MenuButton/Modal';
import {useCreateFormMutation} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const NewModal = ({visible, onClose}: Props) => {
  const [createForm, {loading}] = useCreateFormMutation({
    variables: {title: '新規フォーム'},
    onCompleted: onClose,
    refetchQueries: ['forms'],
  });

  return (
    <Modal title="フォームを作成" visible={visible} onClose={onClose}>
      <div className="flex justify-center w-full">
        <button
          onClick={() => createForm()}
          disabled={loading}
          className="bg-white w-48 h-48 border border-c-border rounded-lg flex flex-col items-center justify-center gap-4 cursor-pointer">
          <h3 className="font-bold">新規作成</h3>
          <div className="bg-[#F4F0FF] text-c-purple h-20 w-20 rounded-lg flex items-center justify-center">
            <EditIcon />
          </div>
        </button>
        {/*}<div className="w-48 h-48 border border-c-border rounded-lg flex flex-col items-center justify-center gap-4">
          <h3 className="font-bold">テンプレートから作成</h3>
          <div className="bg-[#F4F0FF] text-c-purple h-20 w-20 rounded-lg flex items-center justify-center">
            <TemplateIcon />
          </div>
        </div>
          */}
      </div>
    </Modal>
  );
};

export default NewModal;
