import gql from 'graphql-tag';

export default gql`
  fragment salesforceTaskSubjectFragment on SalesforceTaskSubject {
    id
    label
    active
    taskType
  }
`;
