import React from 'react';
import styled from 'styled-components';
import {Modal, Form, Select} from 'components/antd';
import {
  useSalesforceDealRecordTypesQuery,
  useUpdateSalesforceDealFieldMutation,
  SalesforceDealField,
} from 'api';
import useProspectContext from '../../useProspectContext';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const SettingModalComponent = ({visible, onClose}: Props) => {
  const {poolId} = useProspectContext();

  const [selectedRecordTypeId, setSelectedRecordTypeId] = React.useState(null);

  const [updateSalesforceDealField] = useUpdateSalesforceDealFieldMutation();

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {poolId: poolId},
    });

  const onRecordTypeSelect = (id: string) => setSelectedRecordTypeId(id);

  const salesforceDealFields = React.useMemo(() => {
    if (!selectedRecordTypeId) {
      return [];
    }

    const selectedRecordType = salesforceDealRecordTypes.find(
      (recordType) => recordType.id === selectedRecordTypeId,
    );

    return selectedRecordType.salesforceDealFields;
  }, [selectedRecordTypeId, salesforceDealRecordTypes]);

  const Item = (field: SalesforceDealField, index: number) => {
    return (
      <Row key={index}>
        <div>{field.label}</div>
        <div>商談属性</div>
        <div>
          <input
            type="checkbox"
            checked={field.isDisplay}
            onChange={(e) => {
              updateSalesforceDealField({
                variables: {
                  id: field.id,
                  isDisplay: e.target.checked,
                },
              });
            }}
          />
        </div>
      </Row>
    );
  };

  return (
    <Modal
      title="Salesforce商談項目の設定"
      visible={visible}
      onCancel={onClose}
      width={'55vw'}
      footer={null}>
      <Form>
        <Form.Item>
          <div>レコードタイプ</div>
          <Select
            onChange={(value: string) => {
              onRecordTypeSelect(value);
            }}>
            {salesforceDealRecordTypes.map((recordType) => (
              <Select.Option key={recordType.id} value={recordType.id}>
                {recordType.name}
              </Select.Option>
            ))}
          </Select>

          <Content>
            <Row>
              <Title>項目名</Title>
              <Title>セクション</Title>
              <Title>表示</Title>
            </Row>
            {salesforceDealFields.map((field: SalesforceDealField, i: number) =>
              Item(field, i),
            )}
          </Content>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Content = styled.div`
  margin-top: 1.5rem;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
`;

const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  & > div:first-child {
    width: 64%;
  }
  & > div:nth-child(2) {
    width: 32%;
  }
  & > div:last-child {
    width: 4%;
    text-align: center;
    > input {
      display: inline-block;
      cursor: pointer;
    }
  }
`;

export default SettingModalComponent;
