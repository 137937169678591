import React from 'react';
import styled from 'styled-components';
import {Route, useHistory, useParams} from 'react-router-dom';
import {Breadcrumb} from 'components/antd';
import Title from '../Title';
import Tab from '../Tab';
import Prospect from 'components/Prospect';
import {useUpdateStageProspectPoolMutation} from 'api';
import Content from './Content';

export default () => {
  const history = useHistory();
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const prospectUuid = location.pathname.split('/prospects/')[1]?.split('/')[0];

  const [updateStage] = useUpdateStageProspectPoolMutation({
    refetchQueries: ['prospectPoolsStageCount'],
  });
  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>リード一覧</Breadcrumb.Item>
      </Breadcrumb>
      <Title title="リード一覧" />
      <Tab />
      <Main>
        <Content />
      </Main>
      <Route path="/pools/:poolId/prospects/:prospectId">
        <Prospect
          prospectId={prospectUuid}
          poolId={poolId}
          updateStage={updateStage}
          onClose={() =>
            history.push(`/pools/${poolId}/prospects${location.search}`)
          }
          onMove={(prospectId) =>
            history.push(`/pools/${poolId}/prospects/${prospectId}`)
          }
        />
      </Route>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Main = styled.div`
  display: flex;
  margin-top: 20px;
`;
