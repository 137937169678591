import React from 'react';

function Icon({color = '#BDC4CB'}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.92582 1.66663L5.95843 1.77637C6.59719 1.79948 7.16405 2.20284 7.40885 2.80846L8.30555 5.02673C8.51425 5.54295 8.45808 6.13193 8.15585 6.59691L7.007 8.36454C7.6872 9.34129 9.53733 11.6298 11.4963 12.9694L12.9571 12.0704C13.3283 11.8419 13.7728 11.774 14.1929 11.8817L17.0968 12.6264C17.8692 12.8245 18.3896 13.5651 18.3286 14.3793L18.1422 16.8654C18.0767 17.7378 17.3627 18.4224 16.5151 18.3239C5.32712 17.023 -1.23372 1.6666 2.92582 1.66663Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
