import React from 'react';
import {useUpdateFormMutation, Form, useLeadSourcesQuery} from 'api';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [update] = useUpdateFormMutation();

  const onChangeGoogleTagManagerId = (e: React.ChangeEvent<HTMLInputElement>) =>
    update({
      variables: {
        uuid: form.uuid,
        attributes: {
          googleTagManagerId: e.target.value,
        },
      },
      refetchQueries: ['form'],
    });

  const onChangeGoogleAnalyticsV4MeasurementId = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) =>
    update({
      variables: {
        uuid: form.uuid,
        attributes: {
          googleAnalyticsV4MeasurementId: e.target.value,
        },
      },
      refetchQueries: ['form'],
    });

  return (
    <div className="flex flex-col gap-6 w-[480px]">
      <h4 className="text-c-base font-bold text-base">
        Googleタグマネージャー/アナリティクス連携
      </h4>
      <div className="flex flex-col gap-1">
        <label className="text-c-base text-base">
          Googleタグマネージャーと連携
        </label>
        <p className="text-c-base text-sm m-0">Google Tag ManagerのIDを入力</p>
        <input
          type="text"
          className="form-input border-c-border rounded text-base w-full"
          defaultValue={form.googleTagManagerId}
          onChange={onChangeGoogleTagManagerId}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-c-base text-base">
          Googleアナリティクス4と連携
        </label>
        <p className="text-c-base text-sm m-0">
          Googleアナリティクス4の測定IDを入力
        </p>
        <input
          type="text"
          className="form-input border-c-border rounded text-base w-full"
          defaultValue={form.googleAnalyticsV4MeasurementId}
          onChange={onChangeGoogleAnalyticsV4MeasurementId}
        />
      </div>
    </div>
  );
};
