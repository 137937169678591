import React from 'react';
import {Prelead, useSubCategoriesQuery} from 'api';

interface Props {
  row: any;
}

const SubCategoryCell = ({row}: Props) => {
  const prelead: Prelead = row.original.prelead;
  const {data: {subCategories = []} = {}} = useSubCategoriesQuery();

  const targetSubCategories = React.useMemo(
    () =>
      subCategories
        .filter((subCategory) =>
          prelead.subCategoryIds?.includes(subCategory.id),
        )
        .map((subCategory) => subCategory.displayName)
        .join(', '),
    [subCategories],
  );

  return <span>{targetSubCategories}</span>;
};

export default SubCategoryCell;
