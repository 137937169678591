import React from 'react';
import {ProspectPool} from 'api';

interface Props {
  prospectPool: ProspectPool;
}

export const ProspectPoolLeadSourceComponent = ({prospectPool}: Props) => {
  if (!prospectPool.leadSource?.name) {
    return <></>;
  }
  return (
    <div className="text-sm text-c-light font-medium">
      {prospectPool.leadSource?.name}
    </div>
  );
};
