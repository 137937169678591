import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {useCurrentUserQuery} from 'api';

interface Props {
  newButtonText: string;
  onNewButtonClick: () => void;
}

export default ({newButtonText, onNewButtonClick}: Props) => {
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  const {
    params: {formType},
  } = useRouteMatch<{formType: string}>({
    path: '/form/:formType',
    sensitive: true,
  });

  return (
    <div>
      <h1 className="font-bold text-xl">フォーム</h1>
      <div className="mb-6 flex items-end justify-between">
        <ul className="font-bold flex flex-wrap -mb-px text-center">
          <li className="mr-2">
            <Link
              to="/form/forms"
              className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
                formType === 'forms'
                  ? 'text-c-base'
                  : 'text-c-lighter border-transparent'
              }`}>
              フォーム
            </Link>
          </li>
          <li className="mr-2">
            <Link
              to="/form/collected_form_urls"
              className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
                formType === 'collected_form_urls'
                  ? 'text-c-base'
                  : 'text-c-lighter border-transparent'
              }`}>
              タグ連携フォーム
            </Link>
          </li>
          <li className="mr-2">
            <Link
              to="/form/tracking_code"
              className={`inline-block px-4 py-3 border-b-2 rounded-t-lg ${
                formType === 'tracking_code'
                  ? 'text-c-base'
                  : 'text-c-lighter border-transparent'
              }`}>
              トラッキングコード
            </Link>
          </li>
        </ul>
        {newButtonText && onNewButtonClick && (
          <button
            onClick={onNewButtonClick}
            className="cursor-pointer bg-c-primary text-white border-none text-sm hover:opacity-50 rounded items-center justify-center h-9 px-6">
            {newButtonText}
          </button>
        )}
      </div>
    </div>
  );
};
