import React from 'react';
import styled from 'styled-components';
import {CustomizeItem, useCurrentClientQuery} from 'api';
import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'components/antd';
import Add from './Add';
import Edit from './Edit';
import {Cell, useTable} from 'react-table';
import {Calendar, HashTag, Text as TextIcon} from 'components/Ui/Icon';
import {sideMenuWidth} from 'helpers/sideMenu';

const dataTypeIcon: {[key: string]: JSX.Element} = {
  text: <TextIcon />,
  number: <HashTag />,
  date: <Calendar />,
};

export default () => {
  const [addModalVisible, setAddModalVisible] = React.useState(false);
  const [updateModalItem, setUpdateModalItem] = React.useState(null);

  const {data: {currentClient: {customizeItems = []} = {}} = {}} =
    useCurrentClientQuery({});

  const data = React.useMemo(() => customizeItems, [customizeItems]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: '項目名',
        accessor: 'name',
        Cell: ({value, row}: Cell<CustomizeItem>) => (
          <NameBox>
            {dataTypeIcon[row.original.dataType]}
            <span>{value}</span>
          </NameBox>
        ),
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <Container>
      <PageHeader>
        <div>
          <h1>カスタマイズ項目の設定</h1>
          <h3>
            プレリードにカスタマイズ項目を追加することができます。テキスト・数字・日付の形式をそれぞれ３つまで追加できます。
            <br />
            追加した項目は、プレリードマスターや営業リストで利用できます。
          </h3>
        </div>
        {customizeItems.length < 9 && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAddModalVisible(true)}>
            追加
          </Button>
        )}
      </PageHeader>
      <Body>
        <table {...getTableProps}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <th {...column.getHeaderProps()} key={j}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`row-${i}`}
                  onClick={() => setUpdateModalItem(row.original)}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps()} key={`cell-${j}`}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Body>

      <Add
        visible={addModalVisible}
        onClose={() => setAddModalVisible(false)}
      />
      <Edit
        customizeItem={updateModalItem}
        onClose={() => setUpdateModalItem(null)}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 45px 70px 90px;
  width: calc(100vw - (290px + ${sideMenuWidth}px));
  overflow: scroll;
`;

const PageHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;

  > div {
    flex: 1;
  }

  h1 {
    font-size: 14px;
    font-weight: 700;
  }
  h3 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;

const Body = styled.div`
  margin-top: 25px;

  table {
    width: 100%;

    thead {
      background: #f3f5f7;
      border-bottom: 1px solid #e1e6eb;

      th {
        padding: 12px 13px;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0.15px;

        color: #495058;
      }
    }

    tbody {
      tr {
        cursor: pointer;
        border-bottom: 1px solid #e1e6eb;
      }

      td {
        padding: 16px 13px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.15px;

        a {
          color: #222426;
        }
      }
    }
  }
`;

const NameBox = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
