import React, {useState} from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'components/antd';
import {
  useAddPreleadToBlockGroupsMutation,
  useAllBlockGroupsQuery,
  usePreleadProjectQuery,
} from 'api';
import {Checkbox} from 'components/Ui';
import {useParams} from 'react-router';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setIsModalVisible}: Props) => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const {
    data: {allBlockGroups: {blockGroups = []} = {}} = {},
  } = useAllBlockGroupsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [blockGroupIds, setBlockGroupIds] = useState([]);

  const handleBlockGroupIdsChange = React.useCallback(
    (e: any) => {
      const newBlockGroupIds = e.target.checked
        ? Array.from(new Set([...blockGroupIds, e.target.name]))
        : blockGroupIds.filter((val: string) => val !== e.target.name);
      setBlockGroupIds(newBlockGroupIds);
    },
    [blockGroupIds],
  );

  const {data: {preleadProject = null} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    fetchPolicy: 'cache-and-network',
    skip: !preleadProjectId,
  });

  const [
    addPreleadToBlockGroups,
    {loading},
  ] = useAddPreleadToBlockGroupsMutation({
    refetchQueries: ['projects', 'currentUser'],
    onCompleted: () => {
      setIsModalVisible(false);
      setBlockGroupIds([]);
    },
  });

  const handleRegister = () => {
    addPreleadToBlockGroups({
      variables: {
        uuid: preleadProject?.prelead.uuid,
        blockGroupIds: blockGroupIds,
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <RegisterModal
      title="ブロックグループ"
      visible={isModalVisible}
      closable={false}
      footer={[
        <DefaultButton key="back" onClick={handleCancel}>
          キャンセル
        </DefaultButton>,
        <RegisterButton key="submit" loading={loading} onClick={handleRegister}>
          登録
        </RegisterButton>,
      ]}>
      <Anotation>
        対象プレリードをブロックグループに追加することが可能です。
      </Anotation>
      <BlockGroupList>
        {blockGroups?.map((blockGroup) => (
          <BlockGroupBox key={blockGroup.uuid}>
            <Checkbox
              name={blockGroup.id}
              checked={blockGroupIds.includes(blockGroup.id)}
              onChange={handleBlockGroupIdsChange}>
              <BlockGroupName>{blockGroup.name}</BlockGroupName>
            </Checkbox>
          </BlockGroupBox>
        ))}
        <Space></Space>
      </BlockGroupList>
    </RegisterModal>
  );
};

const RegisterModal = styled(Modal)`
  .ant-modal-content {
    width: 450px;
    border-radius: 5px;
    padding: 18px 35px;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #899098;
  }

  .ant-modal-header {
    padding: 0;
    border: none;
  }

  .ant-modal-body {
    padding: 0;
    margin-bottom: 18px;
  }

  .ant-modal-footer {
    padding: 0;
    border: none;
  }
`;

const BlockGroupList = styled.div`
  margin-top: 10px;
  overflow: scroll;
  height: 100px;
`;

const BlockGroupBox = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const Space = styled.div`
  height: 20px;
`;

const BlockGroupName = styled.div`
  display: inline-block;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  color: #495058;
`;

const Anotation = styled.span`
  font-size: 10px;
  color: #899098;
`;

const DefaultButton = styled(Button)`
border-radius: 3px;
padding: 6px 23px;
`;

const RegisterButton = styled(DefaultButton)`
  background: #68b5fb;

  &:hover {
    background: #65aae8;
  }

  span {
    color: #fff;
  }
`;
