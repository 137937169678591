import gql from 'graphql-tag';

export default gql`
  fragment campaignBlockMemberFragment on CampaignBlockMember {
    id
    uuid
    name
    url
    tel
    address1
    note
    status
    statusText
  }
`;
