import gql from 'graphql-tag';

export default gql`
  fragment maintenanceRefreshFragment on MaintenanceRefresh {
    id
    status
    prospectPoolsCount
    currentCount
  }
`;
