import {Dialog} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import React from 'react';
import {useFormik} from 'formik';
import {KeyPerson, useUpdateKeyPersonMutation} from 'api';
import * as Yup from 'yup';

const InputField = ({label, ...props}: any) => (
  <div className="grid gap-2">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    <input
      className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
      {...props}
    />
  </div>
);

interface FormikValues {
  lastName: string;
  firstName: string;
  department: string;
  position: string;
  facebookUrl: string;
  linkedinUrl: string;
  xUrl: string;
  wantedlyUrl: string;
  eightUrl: string;
  telephoneNumber: string;
}

type Props = {
  isOpen: boolean;
  setIsOpen: any;
  keyPerson: KeyPerson;
};
export default function ProfileEditorModal({
  isOpen,
  setIsOpen,
  keyPerson,
}: Props) {
  const [updateKeyPerson, {loading}] = useUpdateKeyPersonMutation({});
  const initialValues: FormikValues = {
    lastName: keyPerson?.lastName,
    firstName: keyPerson?.firstName,
    department: keyPerson?.department,
    position: keyPerson?.position,
    facebookUrl: keyPerson?.facebookUrl,
    linkedinUrl: keyPerson?.linkedinUrl,
    xUrl: keyPerson?.xUrl,
    wantedlyUrl: keyPerson?.wantedlyUrl,
    eightUrl: keyPerson?.eightUrl,
    telephoneNumber: keyPerson?.telephoneNumber,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      lastName: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) => {
      updateKeyPerson({
        variables: {
          uuid: keyPerson?.uuid,
          attributes: {
            ...values,
          },
        },
      });
      setIsOpen(false);
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded-lg bg-white">
          <div className="flex items-center justify-between bg-[#F3F5F7] h-10 px-4">
            <Dialog.Title className="text-base font-semibold m-0" as="h1">
              キーマン情報の編集
            </Dialog.Title>
            <div
              onClick={() => setIsOpen(false)}
              className="text-gray-400 hover:bg-gray-100 hover:text-gray-500 flex items-center">
              <XMarkIcon className="h-6 w-6" />
            </div>
          </div>
          <div className="max-h-[calc(100vh-200px)] overflow-y-auto px-6 py-4">
            <div className="grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                <InputField
                  label="姓"
                  value={formik.values.lastName}
                  onChange={(e: any) =>
                    formik.setFieldValue('lastName', e.target.value)
                  }
                />
                <InputField
                  label="名"
                  value={formik.values.firstName}
                  onChange={(e: any) =>
                    formik.setFieldValue('firstName', e.target.value)
                  }
                />
              </div>
              <InputField
                label="部署名"
                value={formik.values.department}
                onChange={(e: any) =>
                  formik.setFieldValue('department', e.target.value)
                }
              />
              <InputField
                label="役職"
                value={formik.values.position}
                onChange={(e: any) =>
                  formik.setFieldValue('position', e.target.value)
                }
              />
              <InputField
                label="Facebookリンク"
                type="url"
                placeholder="https://www.facebook.com/yourusername"
                value={formik.values.facebookUrl}
                onChange={(e: any) =>
                  formik.setFieldValue('facebookUrl', e.target.value)
                }
              />
              <InputField
                label="LinkedInリンク"
                type="url"
                placeholder="https://www.linkedin.com/in/yourusername"
                value={formik.values.linkedinUrl}
                onChange={(e: any) =>
                  formik.setFieldValue('linkedinUrl', e.target.value)
                }
              />
              <InputField
                label="X (旧Twitter) リンク"
                type="url"
                placeholder="https://x.com/yourusername"
                value={formik.values.xUrl}
                onChange={(e: any) =>
                  formik.setFieldValue('xUrl', e.target.value)
                }
              />
              <InputField
                label="wantedlyリンク"
                type="url"
                placeholder="https://www.wantedly.com/users/12345678"
                value={formik.values.wantedlyUrl}
                onChange={(e: any) =>
                  formik.setFieldValue('wantedlyUrl', e.target.value)
                }
              />
              <InputField
                label="Eightリンク"
                type="url"
                placeholder="https://8card.net/p/abcd1234"
                value={formik.values.eightUrl}
                onChange={(e: any) =>
                  formik.setFieldValue('eightUrl', e.target.value)
                }
              />
              <InputField
                label="直通電話番号"
                type="tel"
                placeholder="03-0000-0000"
                value={formik.values.telephoneNumber}
                onChange={(e: any) =>
                  formik.setFieldValue('telephoneNumber', e.target.value)
                }
              />
              <div className="grid gap-2">
                <label className="text-sm font-medium text-gray-700">
                  備考
                </label>
                <textarea
                  className="h-24 w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="px-6 py-4 flex justify-end items-center">
            <button
              className="w-20 rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              disabled={!formik.dirty || !formik.isValid || loading}
              onClick={() => {
                formik.submitForm();
              }}>
              更新
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
