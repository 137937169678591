import React from 'react';

const Icon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="16.0535" height="16" rx="2" fill="#F3F5F7" />
    <path
      d="M5.09961 7.99992H10.9525M8.02603 5.08325V10.9166V5.08325Z"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
