import React from 'react';
import styled from 'styled-components';
import telCategoryColor from 'helpers/telCategoryColor';
import {StatusEclipse} from 'components/Ui';
import {PreleadProject, useCurrentClientQuery} from 'api';

interface Props {
  row: any;
}

const LatestTelCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const {data: {currentClient: {telStatuses = []} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-only',
    });

  const preleadTelStatus = telStatuses.find(
    (telStatus) => telStatus.id === preleadProject.prelead?.clientTelStatusId,
  );

  const color = telCategoryColor(preleadTelStatus?.category);
  const text = preleadTelStatus?.name || '未';

  return (
    <Cell>
      <StatusEclipse color={color} />
      {text}
    </Cell>
  );
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

export default LatestTelCell;
