import React from 'react';
import moment from 'moment';
import {ProspectPool} from 'api';
import {Tel, Mail, Task} from './Icon';

interface Props {
  prospectPool: ProspectPool;
}

export const LastActionComponent = ({prospectPool}: Props) => {
  const lastAction = prospectPool?.lastAction;

  const Icon = () => {
    switch (lastAction.actionableType) {
      case 'Action::AutomaticMail':
        return <Mail />;
      case 'Action::ManualMail':
        return <Mail />;
      case 'Action::Call':
        return <Tel />;
      case 'Action::Task':
        return <Task />;
    }
  };

  if (!lastAction) {
    return <></>;
  }
  return (
    <div className="bg-white flex items-center">
      <Icon />
      {lastAction.completedAt &&
        (moment().diff(moment(lastAction.completedAt), 'hours') < 24 ? (
          <span className="ml-1 block text-sm">
            {moment().diff(moment(lastAction.completedAt), 'hours')}時間前
          </span>
        ) : (
          <span className="ml-1 block text-sm">
            {moment().diff(moment(lastAction.completedAt), 'days')}日前
          </span>
        ))}
    </div>
  );
};
