import React, {useState} from 'react';
import styled from 'styled-components';
import {ProspectPool, useCreateCommentProspectActivityMutation} from 'api';
import {Quill} from 'components/Ui';
import {Cross} from 'components/Ui/Icon';
import {Button} from 'components/antd';

interface Props {
  setIsCommentNew: React.Dispatch<React.SetStateAction<boolean>>;
  prospectPool: ProspectPool;
}

export default ({setIsCommentNew, prospectPool}: Props) => {
  const [comment, setComment] = useState('');
  const [commentHtml, setCommentHtml] = useState('');
  const [createCommentProspectActivity, {loading}] =
    useCreateCommentProspectActivityMutation({
      variables: {
        prospectPoolUuid: prospectPool.uuid,
        comment,
        commentHtml,
      },
      onCompleted: () => onClose(),
      refetchQueries: ['prospectPool'],
    });
  const onClose = () => setIsCommentNew(false);

  return (
    <Container>
      <Header>
        <h4>コメントを登録</h4>
        <CloseButton onClick={onClose}>
          <Cross />
        </CloseButton>
      </Header>

      <Quill
        theme="snow"
        placeholder="コメント"
        onChange={(content, delta, source, editor) => {
          setComment(editor.getText());
          setCommentHtml(content);
        }}
        modules={{
          toolbar: [
            [{header: [1, 2, 3, false]}],
            ['bold', 'italic', 'underline'],
            ['code-block'],
            ['link'],
          ],
        }}
        bounds={'#quill-container'}
      />

      <Actions>
        <Button
          type="primary"
          loading={loading}
          disabled={!comment || !commentHtml}
          onClick={() => createCommentProspectActivity()}>
          登録
        </Button>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #e2e6ea;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  .ql-toolbar {
    border: none;
    position: absolute;
    left: 10px;
    bottom: 40px;
    transform: translateY(100%);
  }
  .ql-snow {
    border: none;
    padding: 0;
  }
  .ql-editor {
    padding: 10px 15px;
  }
`;

const Header = styled.div`
  background: #f3f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;

  h4 {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;

  svg {
    width: 8px;
    height: 8px;

    path {
      stroke: #495058;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 10px 15px;

  button {
    width: 113px;
  }
`;
