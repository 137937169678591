import gql from 'graphql-tag';
import workflowFragment from '../workflow';

export default gql`
  fragment applyWorkflowProspectActivityFragment on ApplyWorkflowProspectActivity {
    workflow {
      ...workflowFragment
    }
  }
  ${workflowFragment}
`;
