import React from 'react';
import {FormBlock, FormBlockFieldCustomizeItem} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldCustomizeItem;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;
  const {poolProspectCustomizeItem} = blockable;

  return (
    <div className="w-full">
      <div className="flex gap-1 mb-2 items-center">
        <label className="tracking-wide font-bold">
          {poolProspectCustomizeItem.name}
        </label>
        {blockable.isRequired && <span className="text-red-500">*</span>}
        <span className="text-xs h-[18px] px-1 border border-c-border leading-none rounded-sm bg-[#F5FAFE] text-c-lighter flex items-center">
          LEADPAD項目
        </span>
      </div>
      <div className="flex flex-col gap-1">
        {poolProspectCustomizeItem.selectOptions.map((option) => (
          <label key={option.id} className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 rounded"
              disabled
            />
            <span className="ml-2">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};
