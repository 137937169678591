import React from 'react';

const Icon = ({color = '#BDC4CB'}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1667 14.1667L18.3333 18.3333L14.1667 14.1667ZM16.25 8.95833C16.25 12.9854 12.9854 16.25 8.95833 16.25C4.93126 16.25 1.66667 12.9854 1.66667 8.95833C1.66667 4.93126 4.93126 1.66667 8.95833 1.66667C12.9854 1.66667 16.25 4.93126 16.25 8.95833Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
