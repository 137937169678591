import React from 'react';
import Main from './Main';
import Addtion from './Addtion';

export default () => {
  return (
    <>
      <Main />
      <Addtion />
    </>
  );
};
