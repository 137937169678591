import gql from 'graphql-tag';
import {
  userFragment,
  clientUserFragment,
  clientUserGoogleSettingFragment,
} from '../fragments';

export default gql`
  query currentUser {
    currentUser {
      ...userFragment
      clients {
        id
        uuid
        name
      }
      clientUser {
        ...clientUserFragment
        googleSetting {
          ...clientUserGoogleSettingFragment
        }
      }
    }
  }
  ${userFragment}
  ${clientUserFragment}
  ${clientUserGoogleSettingFragment}
`;
