import React from 'react';
import {Tab} from './Tab';

interface TabProps {
  label: string;
  to: string;
}

interface Props {
  tabs: TabProps[];
}

export const Tabs: React.FC<Props> = ({tabs}) => {
  return (
    <div className="flex h-16 gap-6 border-b border-c-border bg-white px-8 pt-2 rounded-t">
      {tabs.map((tab, index) => (
        <Tab key={index} to={tab.to}>
          {tab.label}
        </Tab>
      ))}
    </div>
  );
};
