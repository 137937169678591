import React from 'react';

function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E1E6EB" />
      <path
        d="M10.6328 12.6616C10.7191 12.0609 11.2121 11.61 11.8174 11.5668C13.109 11.4746 15.3573 11.3333 16.9997 11.3333C18.6421 11.3333 20.8904 11.4746 22.1819 11.5668C22.7872 11.61 23.2803 12.0609 23.3665 12.6616C23.499 13.5847 23.6663 14.957 23.6663 16C23.6663 17.0431 23.499 18.4153 23.3665 19.3384C23.2803 19.9391 22.7872 20.3901 22.1819 20.4332C20.8903 20.5253 18.6421 20.6666 16.9997 20.6666C15.3573 20.6666 13.109 20.5253 11.8174 20.4332C11.2121 20.3901 10.719 19.9391 10.6328 19.3384C10.5003 18.4153 10.333 17.0431 10.333 16C10.333 14.957 10.5003 13.5847 10.6328 12.6616Z"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.667 16.0001L15.667 18.3095V13.6907L19.667 16.0001Z"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.667 18.0001V13.6667L19.667 16.0001L15.667 18.0001Z"
        stroke="#EB5757"
      />
    </svg>
  );
}

export default Icon;
