import React from 'react';
import {useProjectWithSearchConditionQuery} from 'api';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
} from './styles';
import {Building} from 'components/Ui/Icon';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project: {projectSearchCondition} = {}} = {}} =
    useProjectWithSearchConditionQuery({
      variables: {uuid: projectId},
      skip: !projectId,
    });

  if (!projectSearchCondition) return null;

  return (
    <Container>
      <Category>
        <CategoryTitle>
          <Building />
          逆引き検索
        </CategoryTitle>
        <ChildCategory>
          <ChildCategoryTitleA style={{marginBottom: '10px'}}>
            会社名
          </ChildCategoryTitleA>
          {projectSearchCondition.company?.name}
        </ChildCategory>
      </Category>
    </Container>
  );
};
