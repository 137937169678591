import {ReportChart} from 'views/client/reports/components/Chart/types';

const useChartData = (): ReportChart => {
  return {
    axis: [
      '山田太郎',
      '田中花子',
      '佐藤次郎',
      '鈴木三郎',
      '高橋四郎',
      '中村十一郎',
    ],
    data: [
      {
        label: '未実施',
        color: '#0DC8F1',
        groupId: 1,
        values: [100, 80, 90, 70, 60, 50],
      },
      {
        label: '開始',
        color: '#2693FF',
        groupId: 1,
        values: [20, 30, 40, 50, 60, 70],
      },
      {
        label: '完了',
        color: '#61CF8D',
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80],
      },
      {
        label: '期限切れ',
        color: '#FF3700',
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80],
      },
      {
        label: '保留',
        color: '#899098',
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80],
      },
    ],
  };
};

export default useChartData;
