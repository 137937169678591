import React from 'react';

const Icon = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.830987 0L2.83251 0.0724294C3.25409 0.0876809 3.62821 0.353902 3.78979 0.753609L4.3816 2.21767C4.51935 2.55837 4.48228 2.9471 4.28281 3.25398L3.52457 4.42062C3.97349 5.06527 5.19458 6.57568 6.48752 7.4598L7.45161 6.86646C7.69664 6.71565 7.99001 6.67088 8.26726 6.74194L10.1838 7.23342C10.6936 7.36416 11.0371 7.853 10.9968 8.39034L10.8738 10.0312C10.8305 10.607 10.3593 11.0588 9.79989 10.9938C2.41584 10.1352 -1.91431 -1.64999e-05 0.830987 0Z"
      fill="#27AE60"
    />
  </svg>
);

export default Icon;
