import React from 'react';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import {useFormik, FormikErrors} from 'formik';
import * as Yup from 'yup';
import {Form, Input, Button, Alert, Divider} from 'components/antd';
import styled from 'styled-components';
import {useChangePasswordTokenMutation} from 'api';
import LeadpadIcon from '../../../auth/login/LeadpadIcon';
import LockIcon from '../../../auth/login/LockIcon';

interface FormValues {
  token: string;
  password: string;
  passwordConfirmation: string;
}

const validateSchema = Yup.object().shape({
  password: Yup.string()
    .required('必須項目です')
    .min(6, '6文字以上で入力してください'),
  passwordConfirmation: Yup.string().required('必須項目です'),
});

export default () => {
  const history = useHistory();
  const {token} = useParams<{token: string}>();

  const [changePasswordToken, {data, loading}] =
    useChangePasswordTokenMutation();

  const formik = useFormik({
    validationSchema: validateSchema,
    initialValues: {
      token: token,
      password: '',
      passwordConfirmation: '',
    },
    validate: (values: FormValues) => {
      let errors: FormikErrors<{passwordConfirmation: string}> = {};

      if (values.password !== values.passwordConfirmation) {
        errors.passwordConfirmation = 'パスワードが一致していません';
      }

      return errors;
    },
    onSubmit: async (values) => {
      const {data} = await changePasswordToken({
        variables: {
          token: token,
          password: {
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
          },
        },
      });
      if (!data?.changePasswordToken.error) {
        history.push('/forgot_password/complete');
      }
    },
  });

  return (
    <Form onFinish={formik.handleSubmit} className="bg-[#F3F5F7]">
      <Container>
        <Content>
          <Header>
            <LeadpadIcon />
            <p className="text-[26px] mb-2">パスワード再設定</p>
          </Header>
          <div className="w-[384px] mx-auto">
            {data &&
              data.changePasswordToken &&
              data.changePasswordToken.error && (
                <>
                  <Alert
                    message="Error"
                    description={
                      <span className="whitespace-pre-line">
                        {data.changePasswordToken.error.replace(/\\n/g, '\n')}
                      </span>
                    }
                    type="error"
                    showIcon
                  />
                  <Divider />
                </>
              )}
            <Form.Item
              className="mb-0"
              required
              validateStatus={
                formik.errors.password && formik.touched.password ? 'error' : ''
              }
              help={
                formik.errors.password && formik.touched.password
                  ? formik.errors.password
                  : ''
              }>
              <Input
                placeholder="新しいパスワード"
                className="w-full py-2.5 rounded"
                name="password"
                type="password"
                prefix={
                  <span className="mr-2 flex items-center">
                    <LockIcon />
                  </span>
                }
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>

            <Form.Item
              className="mb-0"
              required
              validateStatus={
                formik.errors.passwordConfirmation &&
                formik.touched.passwordConfirmation
                  ? 'error'
                  : ''
              }
              help={
                formik.errors.passwordConfirmation &&
                formik.touched.passwordConfirmation
                  ? formik.errors.passwordConfirmation
                  : ''
              }>
              <Input
                placeholder="新しいパスワード(確認)"
                className="w-full py-2.5 rounded"
                name="passwordConfirmation"
                type="password"
                prefix={
                  <span className="mr-2 flex items-center">
                    <LockIcon />
                  </span>
                }
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>

            <div>
              <Button
                htmlType="submit"
                type="primary"
                className="py-3 h-auto rounded"
                loading={loading}
                style={{width: '100%'}}
                disabled={!formik.isValid || loading}>
                変更
              </Button>
            </div>
          </div>
        </Content>
      </Container>
    </Form>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  &&& {
    margin-top: -100px;
    padding: 40px;
    box-sizing: content-box;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 40px;
`;
