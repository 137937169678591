import gql from 'graphql-tag';

export default gql`
  fragment transferedProjectFragment on TransferedProject {
    id
    status
    rowCount
    currentRowNumber
    percent
  }
`;
