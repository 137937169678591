import React from 'react';

function Icon() {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 1.5C8.45869 1.5 9.85764 2.07946 10.8891 3.11091C11.9205 4.14236 12.5 5.54131 12.5 7V12C12.5 13.4587 11.9205 14.8576 10.8891 15.8891C9.85764 16.9205 8.45869 17.5 7 17.5C5.54131 17.5 4.14236 16.9205 3.11091 15.8891C2.07946 14.8576 1.5 13.4587 1.5 12V7C1.50007 6.17506 1.6857 5.3607 2.04316 4.61723C2.40061 3.87375 2.92073 3.22021 3.565 2.705"
        stroke="#8966EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.41501V8.00001"
        stroke="#8966EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
