import React from 'react';
import Row from './Row';
import {Pagination as UIPagination} from 'components/antd';
import {useMaintenancesQuery} from 'api';
import useSearchParams from './useSearchParams';

export default () => {
  const {page, perPage, query, setQuery, searchParams} = useSearchParams();
  const {data: {maintenances: {maintenances = [], pagination = {}} = {}} = {}} =
    useMaintenancesQuery({
      variables: {
        page,
        perPage,
        search: searchParams,
      },
      fetchPolicy: 'cache-and-network',
    });

  return (
    <div>
      <div className="mb-2">
        <label className="flex items-center text-base">
          <input
            type="checkbox"
            checked={query.onlyApplicable}
            className="form-checkbox h-5 w-5 rounded border-c-border"
            onChange={(e) => {
              setQuery({
                page: 1,
                onlyApplicable: e.target.checked,
              });
            }}
          />
          <span className="ml-2">名寄せ可能なリードのみ対象</span>
        </label>
      </div>
      <table className="text-base table-fixed w-full border border-b-0 border-c-border font-normal border-separate rounded-t">
        <thead>
          <tr>
            <th className="border-b border-c-border px-8 py-2 w-[240px] font-normal text-left">
              名寄せ元
            </th>
            <th className="border-b border-c-border px-8 py-2 font-normal text-left">
              名寄せ先
            </th>
            <th className="border-b border-c-border px-4 py-2 w-[200px]" />
          </tr>
        </thead>
        <tbody>
          {maintenances?.map((maintenance) => (
            <Row
              key={maintenance.id}
              uuid={maintenance.uuid}
              targetCount={maintenance.maintenanceProspectPoolsTargetCount}
              prospectPool={maintenance.prospectPoolTo}
            />
          ))}
        </tbody>
      </table>
      <div className="flex justify-center border border-t-0 border-c-border p-2 rounded-b">
        <UIPagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination?.totalCount}
          current={pagination?.currentPage}
          pageSize={perPage}
          onChange={(page) => setQuery({page})}
        />
      </div>
    </div>
  );
};
