import React from 'react';
import {Tooltip} from 'components/antd';
import moment from 'moment';
import {Prelead} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const PublicInfoCell = ({row}: Props) => {
  const prelead: Prelead = row.original;

  const isPr =
    prelead.prLastPublishedAt &&
    moment(prelead.prLastPublishedAt).isAfter(moment().subtract(1, 'month'));

  const isNewPr =
    prelead.prLastPublishedAt &&
    moment(prelead.prLastPublishedAt).isAfter(moment().subtract(1, 'week'));

  if (!isPr) {
    return null;
  }

  return (
    <Tooltip
      placement="right"
      title={
        isNewPr
          ? '1週間以内にプレスリリースを公開しています'
          : '1ヶ月以内にプレスリリースを公開しています'
      }>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.6238 10.4395L12.4262 2.25348C12.1873 1.36345 11.0934 1.03555 10.4027 1.64688L9.01752 2.87277C7.47612 4.23696 5.63769 5.22423 3.64777 5.7564C1.98209 6.20186 0.99493 7.91565 1.44124 9.57812C1.88756 11.2406 3.601 12.2321 5.26669 11.7867C7.25658 11.2545 9.34332 11.192 11.3617 11.6042L13.1753 11.9746C14.0798 12.1594 14.8628 11.3295 14.6238 10.4395Z"
          stroke={isNewPr ? '#EB5757' : '#495058'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.14453 5.33398L7.66622 14.6673"
          stroke={isNewPr ? '#EB5757' : '#495058'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Tooltip>
  );
};

export default PublicInfoCell;
