import React from 'react';
import {Dialog, Transition} from '@headlessui/react';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const Modal = ({visible, onClose}: Props) => {
  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="relative w-full max-w-3xl h-[80vh] transform rounded bg-white text-left align-middle shadow-xl transition-all overflow-hidden">
                <div className="w-full h-full flex flex-col">
                  <Header onClose={onClose} />
                  <Content />
                  <Footer onClose={onClose} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
