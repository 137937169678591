import React from 'react';
import useChartData from './useChartData';
import useTableData from './useTableData';
import {BarChart} from '../../components/Chart';
import {Table} from '../../components/Table';

export default () => {
  const chart = useChartData();
  const table = useTableData();

  return (
    <div className="flex flex-col">
      <div className="border border-c-border">
        <BarChart chart={chart} legend={false} className="h-[540px]" />
        <div className="p-8 border-t border-c-border">
          <Table table={table} />
        </div>
      </div>
    </div>
  );
};
