import gql from 'graphql-tag';

export default gql`
  fragment workflowTemplateWorkflowFragment on WorkflowTemplateWorkflow {
    id
    uuid
    name
    target
    dueDay
    stepCount
    automaticMailStepCount
    manualMailStepCount
    callStepCount
    memo
    position
    workflowTemplateCategoryId
  }
`;
