import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();

  return {
    axis: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    data: [
      {
        label: '1 ~ 20人',
        color: getColor(0),
        groupId: 1,
        values: [100, 80, 90, 70, 60, 50, 40, 30, 20, 10, 5, 0],
      },
      {
        label: '21 ~ 50人',
        color: getColor(1),
        groupId: 1,
        values: [20, 30, 40, 50, 60, 70, 80, 90, 80, 70, 60, 50],
      },
      {
        label: '101 ~ 500人',
        color: getColor(2),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '501 ~ 1000人',
        color: getColor(3),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '1001 ~ 5000人',
        color: getColor(4),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '5001 ~ 10000人',
        color: getColor(5),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '10001人 ~',
        color: getColor(6),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '従業員数不明',
        color: getColor(7),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
    ],
  };
};

export default useChartData;
