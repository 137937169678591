import React from 'react';
import {Checkbox} from 'components/antd';
import _ from 'lodash';
import {City, PrefectureType} from 'api';
import styled from 'styled-components';
import useSearchCondition from '../../useSearchCondition';

type Props = {
  cities: City[];
  prefectureType: PrefectureType;
};

const INITIAL_PAGE_NUM = 1;

export default ({cities, prefectureType}: Props) => {
  const {query, setQuery} = useSearchCondition();
  const {cityIds = [], prefectureTypeIds = []} = query;
  const targetCityIds = cities.map((city) => city.id);
  const prefectureTypeId = prefectureType.id;
  const isPrefectureTypeChecked = () =>
    prefectureTypeIds.includes(prefectureTypeId);

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setQuery({
        prefectureTypeIds: [...prefectureTypeIds, prefectureTypeId],
        cityIds: _.difference(cityIds, targetCityIds),
        page: INITIAL_PAGE_NUM,
      });
    } else {
      setQuery({
        prefectureTypeIds: _.difference(prefectureTypeIds, [prefectureTypeId]),
        page: INITIAL_PAGE_NUM,
      });
    }
  };

  const handleCheck = (e: any) => {
    const id = e.target.name;

    if (e.target.checked) {
      if (_.difference(targetCityIds, cityIds).length === 1) {
        setQuery({
          prefectureTypeIds: [...prefectureTypeIds, prefectureTypeId],
          cityIds: _.difference(cityIds, targetCityIds),
          page: INITIAL_PAGE_NUM,
        });
      } else {
        setQuery({cityIds: [...cityIds, id], page: INITIAL_PAGE_NUM});
      }
    } else {
      if (isPrefectureTypeChecked()) {
        setQuery({
          cityIds: [...cityIds, ..._.difference(targetCityIds, [id])],
          prefectureTypeIds: _.difference(prefectureTypeIds, [
            prefectureTypeId,
          ]),
          page: INITIAL_PAGE_NUM,
        });
      } else {
        setQuery({
          cityIds: _.difference(cityIds, [id]),
          page: INITIAL_PAGE_NUM,
        });
      }
    }
  };

  return (
    <Container id={prefectureType.name}>
      <Checkbox checked={isPrefectureTypeChecked()} onChange={handleCheckAll}>
        {prefectureType.name}
      </Checkbox>
      <Cities>
        {cities.map((city) => (
          <Checkbox
            name={city.id}
            checked={cityIds.includes(city.id) || isPrefectureTypeChecked()}
            key={city.id}
            onChange={handleCheck}>
            {city.name}
          </Checkbox>
        ))}
      </Cities>
    </Container>
  );
};

const Container = styled.section`
  padding: 10px 0;
  &&& {
    .ant-checkbox-wrapper {
      width: 100%;
    }
  }
`;

const Cities = styled.div`
  padding: 10px 20px 20px;
  display: flex;
  flex-wrap: wrap;

  &&& {
    .ant-checkbox-wrapper {
      margin-left: 0px;
      width: 132px;
      margin: 5px 0;
    }
  }
`;
