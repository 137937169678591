import {useParams} from 'react-router-dom';
import {
  useMailCampaignQuery,
  MailCampaignProspectSearchConditionAttributes,
  useUpdateMailCampaignProspectSearchConditionMutation,
} from 'api';
import {useRecoilValue} from 'recoil';
import totalCountAtom from '../../../totalCountAtom';

export default () => {
  const count = useRecoilValue(totalCountAtom);

  const {mailCampaignId} = useParams<{
    mailCampaignId: string;
  }>();

  const {data: {mailCampaign: {searchCondition = {}, status} = {}} = {}} =
    useMailCampaignQuery({
      variables: {uuid: mailCampaignId},
      skip: !mailCampaignId,
    });

  const [update] = useUpdateMailCampaignProspectSearchConditionMutation({
    refetchQueries: ['prospectPoolsByMailCampaign'],
  });

  const isUpdatable = status === 'draft';

  const setCondition = (
    condition: MailCampaignProspectSearchConditionAttributes,
  ) =>
    update({
      variables: {
        uuid: mailCampaignId,
        searchCondition: {
          ...condition,
        },
      },
    });

  const clearCondition = () =>
    setCondition({
      users: [],
      leadSources: [],
      originalLeadSources: [],
      stages: [],
      ranks: [],
      tags: [],
      prospectCustomizeItemSearchConditions: [],
      collectedFormUrls: [],
      forms: [],
      cities: [],
      subCategories: [],
      listingMarkets: [],
      employeeNumber: {},
      capitalFund: {},
      excludedTags: [],
      hubspotDealPipelineId: null,
      hubspotDealPipelineStageIds: [],
      salesforceLeadRecordTypeId: null,
      salesforceContactRecordTypeId: null,
      salesforceLeadFieldSelectStatusIds: [],
      salesforceContactSearchConditions: [],
      inflowDateType: 'latest',
      inflowDateFrom: null,
      inflowDateTo: null,
      clientPhaseProspects: [],
    });

  const numberOfLeadFiltersInUse = [
    searchCondition.users?.length > 0,
    searchCondition.leadSources?.length > 0,
    searchCondition.originalLeadSources?.length > 0,
    searchCondition.stages?.length > 0,
    searchCondition.ranks?.length > 0,
    searchCondition.tags?.length > 0,
    searchCondition.excludedTags?.length > 0,
    searchCondition.prospectCustomizeItemSearchConditions?.length > 0,
    searchCondition.inflowDateFrom || searchCondition.inflowDateTo,
    searchCondition?.clientPhaseProspects?.length > 0,
  ].filter(Boolean).length;

  const numberOfFormFiltersInUse = [
    searchCondition.collectedFormUrls?.length > 0,
    searchCondition.forms?.length > 0,
  ].filter(Boolean).length;

  const numberOfCompanyFiltersInUse = [
    searchCondition.cities?.length > 0,
    searchCondition.subCategories?.length > 0,
    searchCondition.listingMarkets?.length > 0,
    searchCondition.employeeNumber?.from || searchCondition?.employeeNumber?.to,
    searchCondition.capitalFund?.from || searchCondition?.capitalFund?.to,
  ].filter(Boolean).length;

  const numberOfHubspotFiltersInUse = [
    searchCondition.hubspotDealPipelineId,
  ].filter(Boolean).length;

  const numberOfSalesforceFiltersInUse = [
    searchCondition.salesforceLeadRecordTypeId,
    searchCondition.salesforceContactRecordTypeId,
  ].filter(Boolean).length;

  const numberOfFiltersInUse =
    numberOfLeadFiltersInUse +
    numberOfFormFiltersInUse +
    numberOfCompanyFiltersInUse +
    numberOfHubspotFiltersInUse +
    numberOfSalesforceFiltersInUse;

  return {
    condition: searchCondition,
    count,
    isUpdatable,
    setCondition,
    clearCondition,
    numberOfFiltersInUse,
    numberOfLeadFiltersInUse,
    numberOfFormFiltersInUse,
    numberOfCompanyFiltersInUse,
    numberOfHubspotFiltersInUse,
    numberOfSalesforceFiltersInUse,
  };
};
