import gql from 'graphql-tag';

export default gql`
  fragment campaignFragment on Campaign {
    id
    uuid
    clientId
    teamId
    projectId
    name
    note
    status
    statusText
    limit
    limitDay
    isLimit
    isDailyLimit
    canSend
    isMail
    isSunday
    isMonday
    isTuesday
    isWednesday
    isThursday
    isFriday
    isSaturday
    categoryName1
    categoryName2
    categoryName3
    categoryName4
    categoryName5
    startAt
    endAt
  }
`;
