import React from 'react';
import {Prelead, useSubCategoriesQuery} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const SubCategoryCell = ({row}: Props) => {
  const prelead: Prelead = row.original;
  const {data: {subCategories = []} = {}} = useSubCategoriesQuery();

  const targetSubCategories = React.useMemo(
    () =>
      subCategories
        .filter((subCategory) =>
          prelead.subCategoryIds?.includes(subCategory.id),
        )
        .map((subCategory) => subCategory.name)
        .join(', '),
    [subCategories],
  );

  return <span>{targetSubCategories}</span>;
};

export default SubCategoryCell;
