import React from 'react';
import styled from 'styled-components';
import {Modal} from 'components/antd';
import {useCreateMailCampaignMutation} from 'api';
import UserIcon from './User';
import BuildingIcon from './Building';
import {useHistory} from 'react-router';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const NewModalComponent = ({visible, setVisible}: Props) => {
  const history = useHistory();
  const onClose = React.useCallback(() => {
    setVisible(false);
  }, []);

  const [create, {loading}] = useCreateMailCampaignMutation({
    refetchQueries: ['mailCampaigns'],
    onCompleted: (data) => {
      onClose();
      history.push(
        `/mail_campaigns/${data.createMailCampaign.mailCampaign.uuid}`,
      );
    },
  });

  const onClick = (target: string) =>
    !loading &&
    create({
      variables: {
        attributes: {
          title: '新規メール',
          target,
        },
      },
    });

  return (
    <NewModal
      title={null}
      visible={visible}
      onCancel={onClose}
      maskClosable
      footer={null}>
      <h3>メール送信先を選択</h3>
      <Buttons>
        <Button onClick={() => onClick('prospects')}>
          <h3>リード情報</h3>
          <p>リード情報のアドレスに送信</p>
          <UserIcon />
        </Button>

        <Button onClick={() => onClick('preleads')}>
          <h3>営業リスト</h3>
          <p>営業リストの企業アドレスに送信</p>
          <BuildingIcon />
        </Button>
      </Buttons>
    </NewModal>
  );
};

const NewModal = styled(Modal)`
  .ant-modal-body {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    margin: 1rem 1rem 2rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.div`
  width: 230px;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #e1e6eb;
  cursor: pointer;

  h3 {
    margin: 0 0 0.5rem;
  }

  svg {
    margin-top: 1rem;

    path {
      stroke: #899098 !important;
    }
  }

  &:hover {
    border-color: #68b5fb;
    box-shadow: 0px 0px 10px #68b5fb;

    h3,
    p {
      color: #68b5fb;
    }

    svg {
      path {
        stroke: #68b5fb !important;
      }
    }
  }
`;

export default NewModalComponent;
