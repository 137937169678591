import React from 'react';
import List from './List';
import {Select} from 'components/antd';
import Stats from './Stats';

export default () => {
  return (
    <div>
      <Stats />
      <Select style={{width: '220px', margin: '20px 0'}} disabled />
      <List />
    </div>
  );
};
