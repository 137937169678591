import gql from 'graphql-tag';
import {taskFragment, userFragment} from '../../fragments';

export default gql`
  fragment salesforceTaskTodoProspectActivityFragment on SalesforceTaskTodoProspectActivity {
    id
    task {
      ...taskFragment
      user {
        ... on User {
          ...userFragment
        }
      }
    }
  }
  ${taskFragment}
  ${userFragment}
`;
