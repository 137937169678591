import React from 'react';
import {useUpdateTaskMutation, Task} from 'api';
import {Select} from 'components/antd';
import {taskPriorities} from 'helpers/task';

interface Props {
  task: Task;
}

export default ({task}: Props) => {
  const [updateTask] = useUpdateTaskMutation();

  return (
    <Select
      value={task.priorityText}
      onChange={(value) =>
        updateTask({
          variables: {uuid: task.uuid, attributes: {priority: value}},
        })
      }>
      {taskPriorities.map((priority) => (
        <Select.Option key={priority.value} value={priority.value}>
          {priority.name}
        </Select.Option>
      ))}
    </Select>
  );
};
