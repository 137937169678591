import React from 'react';
import {useParams} from 'react-router-dom';
import {
  SalesforceDealRecordType,
  useSalesforceDealRecordTypesQuery,
  useUpdateWorkflowSearchConditionMutation,
  useWorkflowQuery,
} from 'api';
import useClientUser from 'hooks/useClientUser';

export default ({setSalesforceDealSearchConditions}: any) => {
  const {isMember} = useClientUser();
  const {poolId, workflowId} = useParams<{
    poolId: string;
    workflowId: string;
  }>();

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({variables: {poolId: poolId}});

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <div className="flex">
      <div className="font-medium text-base leading-8 tracking-[.15px] text-[#495058] pr-2 w-28">
        レコードタイプ
      </div>
      <div className="z-30 w-[40%]">
        <select
          disabled={!isConditionUpdatable || !isMember}
          value={searchCondition['salesforceDealRecordTypeId']}
          onChange={(e) => {
            setSalesforceDealSearchConditions([]);
            updateWorkflowSearchCondition({
              variables: {
                uuid: workflowId,
                attributes: {
                  salesforceDealRecordTypeId: e.target.value,
                  salesforceDealSearchConditions: [],
                },
              },
            });
          }}
          className="form-select border-c-border rounded text-base w-full">
          <option value={null} />
          {salesforceDealRecordTypes.map(
            (salesforceDealRecordType: SalesforceDealRecordType) => {
              return (
                <option
                  key={salesforceDealRecordType.id}
                  value={salesforceDealRecordType.id}>
                  {salesforceDealRecordType.name}
                </option>
              );
            },
          )}
        </select>
      </div>
    </div>
  );
};
