import React from 'react';
import {Task, TaskPrelead, TaskProspect} from 'api';
import {Row} from 'react-table';
import {Link} from 'react-router-dom';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;

  if (task.taskableType === 'Task::Prospect') {
    const taskable = task.taskable as TaskProspect;

    return (
      <div className="flex items-center gap-2">
        <div className="border border-c-border rounded-sm h-5 px-1 text-xs w-max min-w-max">
          <span>リード</span>
        </div>
        <Link
          to={`/tasks/${task.uuid}/prospect/${taskable.prospectPool.uuid}/task${location.search}`}>
          <h4 className="flex items-center truncate font-medium m-0 text-c-primary">
            {task.title}
          </h4>
        </Link>
      </div>
    );
  }

  if (task.taskableType === 'Task::Prelead') {
    const taskable = task.taskable as TaskPrelead;

    return (
      <div className="flex items-center gap-2">
        <div className="border border-c-border rounded-sm h-5 px-1 text-xs w-max min-w-max">
          <span>企業</span>
        </div>
        <Link
          to={`/tasks/${task.uuid}/prelead/${taskable.prelead.uuid}/task${location.search}`}>
          <h4 className="flex items-center truncate font-medium m-0 text-c-primary">
            {task.title}
          </h4>
        </Link>
      </div>
    );
  }
};
export default Cell;
