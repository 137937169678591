import React from 'react';
import {Filter} from 'components/Ui/Icon';
import Modal from './Modal';
import List from './List';
import useCondition from './useCondition';

export default () => {
  const [visible, setVisible] = React.useState(false);
  const {numberOfFiltersInUse, isUpdatable} = useCondition();

  return (
    <>
      <div className="flex flex-col gap-4 my-6">
        {isUpdatable && (
          <button
            onClick={() => setVisible(true)}
            className="relative border border-c-border rounded w-9 h-9 bg-white text-c-lighti cursor-pointer flex items-center justify-center">
            <Filter color={numberOfFiltersInUse > 0 ? '#68B5FB' : '#BDC4CB'} />
            {numberOfFiltersInUse > 0 && (
              <span className="absolute right-[-5px] top-[-5px] rounded bg-c-primary min-w-[14px] h-[14px] flex items-center justify-center text-xs text-white">
                {numberOfFiltersInUse}
              </span>
            )}
          </button>
        )}
        <List />
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
