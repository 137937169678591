import React from 'react';
import {Row} from 'react-table';
import {ActionDate} from 'components/Cell';
import {Action} from 'api';

interface Props {
  row: Row<Action>;
}

export default ({row}: Props) => {
  const action = row.original;

  if (!action) return null;

  return <ActionDate action={action} />;
};
