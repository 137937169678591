import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useUsersByClientAllQuery} from 'api';

const TaskUserFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.taskUserIds || []}
      filterOption
      optionFilterProp="children"
      onChange={(values) => setQuery({page: 1, taskUserIds: values})}>
      {users.map((user) => (
        <Select.Option value={user.id} key={user.id}>
          {`${user.lastName ? user.lastName : ''}`}{' '}
          {`${user.firstName ? user.firstName : ''}`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TaskUserFilter;
