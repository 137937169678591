import gql from 'graphql-tag';

export default gql`
  fragment sensesDomainSettingFragment on SensesDomainSetting {
    dealTypes {
      id
      name
      phases {
        id
        name
        status
      }
      products {
        id
        name
      }
      probabilities {
        id
        name
      }
      channels {
        id
        name
      }
      endPhaseReasons {
        id
        name
      }
      actionsPurposes {
        id
        name
        pattern
        patternName
      }
      dealCustomItems {
        id
        itemKey
        itemType
        name
        orderNum
      }
    }
    users {
      id
      name
      email
    }
    roles {
      id
      name
    }
  }
`;
