import React from 'react';
import {
  useZoomPhoneCallerPhoneNumbersQuery,
  useToggleZoomPhoneCallerPhoneNumberMutation,
  useImportZoomPhoneCallerPhoneNumbersMutation,
} from 'api';

export default () => {
  const {data: {zoomPhoneCallerPhoneNumbers = []} = {}} =
    useZoomPhoneCallerPhoneNumbersQuery();

  const [importNumbers, {loading}] =
    useImportZoomPhoneCallerPhoneNumbersMutation({
      refetchQueries: ['zoomPhoneCallerPhoneNumbers'],
      variables: {input: {}},
    });
  const [toggle] = useToggleZoomPhoneCallerPhoneNumberMutation();

  return (
    <>
      <button
        className="bg-white h-8 px-4 border border-c-border rounded flex flex-col items-center justify-center cursor-pointer disabled:opacity-50"
        disabled={loading}
        onClick={() => importNumbers()}>
        電話番号をインポート
      </button>

      <table className="w-full text-sm text-left text-gray-500 mt-4">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              電話番号
            </th>
            <th scope="col" className="px-6 py-3">
              名前
            </th>
            <th scope="col" className="px-6 py-3">
              連携
            </th>
          </tr>
        </thead>
        <tbody>
          {zoomPhoneCallerPhoneNumbers.map((phoneNumber) => (
            <tr
              key={phoneNumber.id}
              className="bg-white border-b border-c-border">
              <td className="px-6 py-4 w-48">{phoneNumber.phoneNumber}</td>
              <td className="px-6 py-4">{phoneNumber.assignee}</td>
              <td className="px-6 py-4 w-16">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value={phoneNumber.id}
                    checked={phoneNumber.active}
                    className="sr-only peer"
                    onClick={() => toggle({variables: {id: phoneNumber.id}})}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-c-primary"></div>
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
