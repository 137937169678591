import React from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {ZoomPhoneBadge} from 'components/Ui/Icon';
import {
  Activity,
  ZoomPhoneWebhookCallActivity,
  useActivityLazyQuery,
  useUpdateZoomPhoneWebhookCallActivityMutation,
  ZoomPhoneWebhookCallActivityAttributes,
} from 'api';
import Audio from './Audio';
import Transcription from './Transcription';
import UpdateModal from './UpdateModal';

interface ActivityType extends Activity {
  resource: ZoomPhoneWebhookCallActivity;
}

interface Props {
  activity: ActivityType;
}

const ZoomPhoneComponent = ({activity}: Props) => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const [isOpen, setIsOpen] = React.useState(false);
  const {
    zoomPhoneWebhookCall: {
      status,
      statusText,
      callerName,
      calleeName,
      recordings,
    },
  } = activity.resource;

  const [getActivity, {loading}] = useActivityLazyQuery({
    variables: {uuid: activity.uuid},
    fetchPolicy: 'network-only',
  });

  const [updateActivity] = useUpdateZoomPhoneWebhookCallActivityMutation();

  const displayReload = !recordings.find(
    (recording) =>
      recording.azureWebhookEvent?.azureTranscriptionFiles?.length > 0,
  );

  const displayTelButton = activity.project === null;

  const closeModal = () => setIsOpen(false);
  const handleUpdate = (values: ZoomPhoneWebhookCallActivityAttributes) =>
    updateActivity({
      variables: {
        resourceId: activity.resource.id,
        preleadProjectId,
        attributes: values,
      },
    });

  return (
    <>
      <div className="mt-1 flex items-center w-full h-12 px-4">
        <div className="z-10 flex w-8 h-auto mr-4">
          <ZoomPhoneBadge />
        </div>
        <span className="flex flex-1 items-center">
          {callerName || calleeName}
        </span>
        {displayTelButton && (
          <button
            onClick={() => setIsOpen(true)}
            className="cursor-pointer bg-white border border-c-primary text-sm text-c-primary hover:opacity-50 px-4 h-8 rounded inline-flex items-center gap-1">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className="w-4"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 5V19M5 12H19"
                className="stroke-c-primary"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"></path>
            </svg>
            <span>TELログを登録</span>
          </button>
        )}
      </div>
      <div className="pt-2 pb-4 px-4 flex flex-col gap-2 items-start">
        <div className="flex items-center gap-2">
          <span className="text-sm bg-[#cfe6f8] text-[#2196f3] rounded-sm px-2 h-5 flex items-center justify-center">
            {statusText}
          </span>
          <div className="text-c-light">
            記録日時: {moment(activity.createdAt).format('MM/DD HH:mm')}
          </div>
        </div>
        {displayReload && (
          <div className="flex gap-1">
            {status === 'caller_ringing' && '発信中...'}
            {status === 'caller_connected' && '記録中...'}
            {status === 'caller_ended' && recordings?.length > 0 && '作成中...'}
            <button
              disabled={loading}
              onClick={() => getActivity()}
              className="cursor-pointer bg-white border border-c-border text-sm hover:opacity-50 rounded-sm inline-flex items-center disabled:bg-c-bg">
              更新
            </button>
          </div>
        )}
        {recordings?.map((recording) => (
          <div key={recording.id}>
            {recording.azureWebhookEvent?.azureTranscriptionFiles?.map(
              (file) => <Transcription file={file} key={file.id} />,
            )}
            {recording.file && <Audio file={recording.file} />}
          </div>
        ))}
      </div>
      <UpdateModal
        isOpen={isOpen}
        closeModal={closeModal}
        onSubmit={handleUpdate}
      />
    </>
  );
};

export default ZoomPhoneComponent;
