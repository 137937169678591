import React from 'react';
import {
  PreleadCustomizeItemNumber,
  ClientPreleadCustomizeItem,
  Prelead,
  useUpdatePreleadCustomizeItemNumberMutation,
} from 'api';
import {Input as UIInput} from 'components/antd';
import styled from 'styled-components';

interface Props {
  prelead: Prelead;
  clientPreleadCustomizeItem: ClientPreleadCustomizeItem;
}

export default ({prelead, clientPreleadCustomizeItem}: Props) => {
  const customizeItem = prelead.customizeItemNumbers.find(
    (item: PreleadCustomizeItemNumber) =>
      item.clientPreleadCustomizeItemId === clientPreleadCustomizeItem.id,
  );

  const [value, setValue] = React.useState(
    customizeItem ? customizeItem.value : null,
  );

  React.useEffect(() => {
    setValue(customizeItem ? customizeItem.value : null);
  }, [customizeItem]);

  const [updateCustomizeItem] = useUpdatePreleadCustomizeItemNumberMutation();

  const update = (e: any) => {
    updateCustomizeItem({
      variables: {
        uuid: prelead.uuid,
        clientPreleadCustomizeItemId: clientPreleadCustomizeItem.id,
        value: e.target.value ? Number(e.target.value) : null,
      },
    });
  };

  return (
    <Input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={update}
      onPressEnter={update}
      type="number"
      placeholder="未入力"
    />
  );
};

const Input = styled(UIInput)`
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
