import React from 'react';
import {Workflow} from 'api';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const totalDays = React.useMemo(() => {
    return workflow.steps.reduce((acc, step) => {
      if (step.isImmediately) return acc;
      if (!step.startDay) return acc;
      acc += step.startDay;
      return acc;
    }, 0);
  }, [workflow]);

  return (
    <>
      <span className="text-xs text-c-lighter">ワークフロー</span>
      <div>
        <span className="text-xl font-bold mr-1">{workflow.steps.length}</span>
        <span className="text-xs">ステップ</span>
      </div>
      <div>
        <span className="text-xl font-bold mr-1">{totalDays}</span>
        <span className="text-xs">日</span>
      </div>
    </>
  );
};
