import React from 'react';
import styled from 'styled-components';
import {Tooltip} from 'components/antd';
import moment from 'moment';
import {CalendarCheckedCircle} from 'components/Ui/Icon';
import {useParams} from 'react-router-dom';
import {Step, useWorkflowQuery} from 'api';

interface Props {
  step: Step;
}

export default ({step}: Props) => {
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {data: {workflow: {steps = []} = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
  });

  const displayDate = (step: Step) => {
    if (step.isImmediately) return `即時実行`;
    if (step.startDate) return moment(step.startDate).format('YYYY/MM/DD');
    if (step.startDay === 0) return '当日';

    let day = 0;
    let cantDisplay = false;
    steps.some((s) => {
      if (s.position > step.position) return true;
      if (s.startDate) {
        cantDisplay = true;
        return true;
      }

      day += s.startDay;
    });

    if (cantDisplay) return 'ー';
    return day + '日目';
  };

  const targetDay = (days: number[]) => {
    const day = ['月', '火', '水', '木', '金', '土', '日'];
    return days.map((index) => day[index - 1]).join(', ');
  };

  return (
    <div className="mx-4 flex items-center gap-2 text-base w-24">
      <div className="flex flex-col items-center leading-none gap-1">
        {displayDate(step)}
        {!step.isImmediately && step.startHour !== null && (
          <span className="text-c-light text-sm">
            {String(step.startHour).padStart(2, '0') + ':00'}
          </span>
        )}
      </div>
      {!step.isImmediately && (
        <Tooltip placement="top" title={targetDay(step.daysOfWeek)}>
          <span className="[&>svg]:mb-[-3px]">
            <CalendarCheckedCircle />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
