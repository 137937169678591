import React, {useEffect} from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import Filter from './Filter';
import useSearchParams from './useSearchParams';
import Table from './Table';
import Summary from './Summary';
import Detail from './Detail';
import Tab from 'components/Action/Tab';
import {StringParam, useQueryParam} from 'use-query-params';
import {useCurrentUserQuery, useUsersByClientAllQuery} from 'api';

export default () => {
  const {path} = useRouteMatch();
  const [assigneesNone] = useQueryParam('assigneesNone', StringParam);
  const {setQuery} = useSearchParams();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  useEffect(() => {
    if (assigneesNone) {
      return null;
    }

    setQuery({
      page: 1,
      assignees: [users.find((user) => user.id === currentUser.id)?.id],
    });
  }, [users, currentUser, assigneesNone]);

  return (
    <div className="p-8 h-[calc(100vh-60px)] overflow-scroll bg-c-bg">
      <h1 className="text-2xl font-bold mb-4">アクション</h1>
      <div className="w-full p-5 bg-white rounded flex flex-col gap-2">
        <Tab />
        <Filter />
        <Summary />
        <Table />
      </div>
      <Switch>
        <Route path={`${path}/:taskId`}>
          <Detail />
        </Route>
      </Switch>
    </div>
  );
};
