import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {ProspectActivity, MailCampaignMailOpenProspectActivity} from 'api';
import {MailCampaign} from 'components/Ui/Icon';

interface MailCampaignMailOpenActivity extends ProspectActivity {
  resource: MailCampaignMailOpenProspectActivity;
}

interface Props {
  prospectActivity: MailCampaignMailOpenActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    resource: {
      mailCampaignSend: {mailCampaign},
    },
  } = prospectActivity;

  return (
    <>
      <MailCampaign />
      <Content>
        <ContentHeader>
          <h3>
            <Link target="_blank" to={`/mail_campaigns/${mailCampaign.uuid}`}>
              {mailCampaign.title}
            </Link>
          </h3>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </ContentHeader>
        <Wrapper>開封</Wrapper>
      </Content>
    </>
  );
};
const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: #68b5fb;
    font-weight: bold;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const Wrapper = styled.div`
  color: #899098;
`;
