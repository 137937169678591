import gql from 'graphql-tag';

export default gql`
  fragment commentProspectActivityFragment on CommentProspectActivity {
    id
    comment
    commentHtml
    stage
    stageText
    hubspotNoteIdString
    hubspotContactIdString
    recordedAt
    createdAt
  }
`;
