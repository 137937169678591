import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {usePreleadQuery, useProspectPoolsByPreleadQuery} from 'api';
import Tab from './Tab';
import usePreleadContext from '../usePreleadContext';

export default () => {
  const {preleadId} = usePreleadContext();
  const {url} = useRouteMatch();

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    fetchPolicy: 'cache-only',
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const {data: {prospectPoolsByPrelead = []} = {}} =
    useProspectPoolsByPreleadQuery({
      variables: {uuid: preleadId},
      skip: !preleadId,
      fetchPolicy: 'cache-and-network',
    });

  return (
    <div className="flex justify-between border-b border-c-border pt-1 px-[22px]">
      <Tab
        label="タイムライン"
        to={`${url}/timeline`}
        count={prelead.activities?.length || 0}
      />
      <Tab label="詳細" to={`${url}`} count={null} exact />
      <Tab
        label="リード"
        to={`${url}/leads`}
        count={prospectPoolsByPrelead?.length}
      />
      <Tab
        label="TEL"
        to={`${url}/tel`}
        count={prelead.telActivities?.length || 0}
      />
      <Tab label="ToDo" to={`${url}/task`} count={prelead.tasks?.length || 0} />
      <Tab
        label="アプローチ"
        to={`${url}/approach`}
        count={prelead.approaches?.length || 0}
      />
      <Tab
        label="メール"
        to={`${url}/mail`}
        count={prelead.mailActivities?.length || 0}
      />
      {/*
      <Route path={`${path}/:tab`}>
        <DropDown
          onChange={(tab: string) =>
            history.push(`${url}/${tab}${location.search}`)
          }
        />
      </Route>
      */}
    </div>
  );
};
