import React from 'react';
import {useQueryParams, StringParam} from 'use-query-params';
import SumTable from './Sum/Table';
import UserTable from './User/Table';

export default () => {
  const [query] = useQueryParams({
    column: StringParam,
  });

  return <>{query.column === 'user' ? <UserTable /> : <SumTable />}</>;
};
