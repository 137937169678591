import {useProjectWithSearchConditionQuery} from 'api';
import {Pen} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  TagBox,
  TagItem,
} from './styles';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = {}} = {}} = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });
  const {projectSearchCondition} = project;
  if (!projectSearchCondition) return null;

  const profitRateOptions = [
    {label: '0 〜 50%', value: '0to50'},
    {label: '50% 〜 100%', value: '50to100'},
    {label: '100% 〜 200%', value: '100to200'},
    {label: '200%以上', value: '200to'},
    {label: '0 〜 -50%', value: '-50to0'},
    {label: '-50% 〜 -100%', value: '-100to-50'},
    {label: '-100% 〜 -200%', value: '-200to-100'},
    {label: '-200%以下', value: 'to-200'},
  ];

  const salesOptions = [
    {label: '0 〜 10億', value: '0to10'},
    {label: '10億 〜 50億', value: '10to50'},
    {label: '50億 〜 100億', value: '50to100'},
    {label: '100億 〜 500億', value: '100to500'},
    {label: '500億 〜 1000億', value: '500to1000'},
    {label: '1000億以上', value: '1000to'},
  ];

  return (
    <Container>
      {project.isExtra && (
        <Category>
          <CategoryTitle>
            <Pen />
            その他
          </CategoryTitle>

          {project.isPress && (
            <ChildCategory>
              <ChildCategoryTitleA>プレスリリース</ChildCategoryTitleA>
              <TagBox>
                {projectSearchCondition.monthPressRelease && (
                  <TagItem>
                    {`掲載期間 ${projectSearchCondition.monthPressRelease}ヶ月以内`}
                  </TagItem>
                )}
                {projectSearchCondition.countPressRelease && (
                  <TagItem>
                    {`掲載回数 ${projectSearchCondition.countPressRelease}回以上`}
                  </TagItem>
                )}
              </TagBox>
            </ChildCategory>
          )}

          {project.isFiscal && (
            <ChildCategory>
              <ChildCategoryTitleA>決算</ChildCategoryTitleA>
              <TagBox>
                {projectSearchCondition.salesRanges?.map((salesRange) => (
                  <TagItem key={salesRange}>
                    売上
                    {
                      salesOptions.find(
                        (salesOption) => salesOption.value === salesRange,
                      ).label
                    }
                  </TagItem>
                ))}
                {projectSearchCondition.profitUp3 && (
                  <TagItem>純利益三期連続UP</TagItem>
                )}
                {projectSearchCondition.profitDown3 && (
                  <TagItem>純利益三期連続DOWN</TagItem>
                )}
                {projectSearchCondition.profitRateRanges?.map(
                  (profitRateRange) => (
                    <TagItem key={profitRateRange}>
                      純利益前期比較
                      {
                        profitRateOptions.find(
                          (profitRateOption) =>
                            profitRateOption.value === profitRateRange,
                        ).label
                      }
                    </TagItem>
                  ),
                )}
              </TagBox>
            </ChildCategory>
          )}
        </Category>
      )}
    </Container>
  );
};
