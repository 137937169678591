import gql from 'graphql-tag';

export default gql`
  fragment salesforceDealPreleadContactFragment on SalesforceDealPreleadContact {
    id
    accountName
    contactEmail
    contactFirstName
    contactLastName
    contactSection
    contactSectionPosition
    contactTelephoneNumber
    name
    closeDate
    salesforceOpportunityId
    salesforceOpportunityIdString
    preleadContactId
    createdAt
    updatedAt
    salesforceUserId
    salesforceOpportunityStageId
  }
`;