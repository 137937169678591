import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input} from 'components/antd';
import {
  useClientPhaseProspectsQuery,
  useCreateClientPhaseProspectMutation,
} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const New = ({visible, onClose}: Props) => {
  const [create] = useCreateClientPhaseProspectMutation({
    refetchQueries: ['clientPhaseProspects'],
    onCompleted: () => onClose(),
  });

  const {data: {clientPhaseProspects} = {}} = useClientPhaseProspectsQuery();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('必須項目です')
        .notOneOf(
          clientPhaseProspects?.map((phase) => phase.name) || [],
          '既に存在するフェーズ名です',
        ),
    }),
    onSubmit: (values, {resetForm}) => {
      create({
        variables: {
          attributes: {
            ...values,
          },
        },
      });
      resetForm();
    },
  });

  return (
    <Modal
      destroyOnClose
      visible={visible}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>フェーズの新規作成</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item
            label="フェーズ名"
            required
            validateStatus={formik.errors.name && 'error'}
            help={formik.errors.name}>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="フェーズの説明">
            <Input.TextArea
              name="description"
              autoSize={{minRows: 3}}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!formik.isValid}>
              作成
            </Button>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default New;
