import React from 'react';
import styled from 'styled-components';
import mailColor from 'helpers/mailColor';
import {StatusEclipse} from 'components/Ui';
import {Prelead} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const MailCell = ({row}: Props) => {
  const prelead: Prelead = row.original;
  const color = mailColor(prelead.mailStatus);
  const text = prelead.mailStatusText;

  return (
    <Cell>
      <StatusEclipse color={color} />
      {text}
    </Cell>
  );
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

export default MailCell;
