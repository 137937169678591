import React from 'react';
import styled from 'styled-components';
import {Prelead} from 'api';
import {Row} from 'react-table';
import moment from 'moment';

interface Props {
  row: Row<Prelead>;
}

export default ({row}: Props) => {
  const prelead: Prelead = row.original;

  return (
    <RemindAt
      isToday={
        prelead.remindAt && moment().isSame(moment(prelead.remindAt), 'day')
      }>
      {prelead.remindAt && moment(prelead.remindAt).format('MM/DD(ddd) HH:mm')}
    </RemindAt>
  );
};

const RemindAt = styled.span<{isToday: boolean}>`
  ${({isToday}) => isToday && 'color: #EB5757'};
`;
