import React from 'react';
import {
  useWorkflowQuery,
  AutomaticMailStep,
  ManualMailStep,
  CallStep,
} from 'api';
import {useParams} from 'react-router-dom';
import OverallIcon from './OverallIcon';
import AutomaticMailIcon from './AutomaticMailIcon';
import ManualMailIcon from './ManualMailIcon';
import TelIcon from './TelIcon';

const Stats = () => {
  const {workflowId} = useParams<{workflowId: string}>();

  const {data: {workflow} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
    skip: !workflowId,
  });

  const stats = React.useMemo(() => {
    if (!workflow) {
      return {};
    }
    const total =
      workflow.prospectsStatusOpenCount + workflow.prospectsStatusClosedCount;
    const open = workflow.prospectsStatusOpenCount;
    const closed = workflow.prospectsStatusClosedCount;
    const deal = workflow.steps?.reduce(
      (acc, step) => acc + step.dealsCount,
      0,
    );
    const dealRate = deal && total ? (deal / total) * 100 : 0;
    const sent = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep).automaticMailSendsSentCount;
      }
      return acc;
    }, 0);
    const openCount = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep)
          .automaticMailSendsOpenedCount;
      }
      return acc;
    }, 0);
    const clickCount = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep)
          .automaticMailSendsClickedCount;
      }
      return acc;
    }, 0);
    const bounceCount = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::AutomaticMail') {
        acc += (step.steppable as AutomaticMailStep)
          .automaticMailSendsBouncedCount;
      }
      return acc;
    }, 0);
    const manual = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::ManualMail') {
        acc += (step.steppable as ManualMailStep).sentCount;
      }
      return acc;
    }, 0);

    const openRate = openCount && sent ? (openCount / sent) * 100 : 0;
    const clickRate = clickCount && sent ? (clickCount / sent) * 100 : 0;
    const bounceRate = bounceCount && sent ? (bounceCount / sent) * 100 : 0;

    const call = workflow.steps.reduce((acc, step) => {
      if (step.steppableType === 'Step::Call') {
        acc += (step.steppable as CallStep).calledCount;
      }
      return acc;
    }, 0);

    return {
      total,
      open,
      closed,
      deal,
      dealRate,
      sent,
      openCount,
      clickCount,
      bounceCount,
      openRate,
      clickRate,
      bounceRate,
      call,
      manual,
    };
  }, [workflow]);

  return (
    <div className="my-4 grid grid-cols-7 gap-4">
      <div className="bg-white rounded-lg p-4 col-span-2">
        <h3 className="flex items-center gap-1 text-[#8966EF] mb-2">
          <OverallIcon />
          全体
        </h3>
        <ul className="flex gap-4 justify-evenly">
          <li className="flex flex-col items-center gap-2">
            <span className="text-xl font-bold leading-none">
              {(stats.total || 0).toLocaleString()}
            </span>
            <span className="text-xs">リード数</span>
          </li>
          <li className="border-r border-c-border" />
          <li className="flex flex-col items-center gap-2">
            <span className="text-xl font-bold leading-none">
              {(stats.deal || 0).toLocaleString()}
            </span>
            <span className="text-xs">商談</span>
          </li>
        </ul>
      </div>
      <div className="bg-white rounded-lg p-4 col-span-3">
        <h3 className="flex items-center gap-1 text-[#88C9D8] mb-2">
          <AutomaticMailIcon />
          自動メール
        </h3>
        <ul className="flex gap-4 justify-evenly">
          <li className="flex flex-col items-center gap-2">
            <span className="text-xl font-bold leading-none">
              {(stats.sent || 0).toLocaleString()}
            </span>
            <span className="text-xs">送信</span>
          </li>
          <li className="border-r border-c-border" />
          <li className="flex flex-col items-center gap-2">
            <span className="text-xl font-bold leading-none">
              {(stats.openRate || 0).toFixed(1)}{' '}
              <span className="text-sm font-normal">%</span>
            </span>
            <span className="text-xs">開封率</span>
          </li>
          <li className="border-r border-c-border" />
          <li className="flex flex-col items-center gap-2">
            <span className="text-xl font-bold leading-none">
              {(stats.clickRate || 0).toFixed(1)}{' '}
              <span className="text-sm font-normal">%</span>
            </span>
            <span className="text-xs">クリック率</span>
          </li>
        </ul>
      </div>
      <div className="bg-white rounded-lg p-4">
        <h3 className="flex items-center gap-1 text-[#8DD888] mb-2">
          <TelIcon />
          TEL
        </h3>
        <ul className="flex gap-4 justify-around">
          <li className="flex flex-col items-center gap-2">
            <span className="text-xl font-bold leading-none">
              {(stats.call || 0).toLocaleString()}
            </span>
            <span className="text-xs">コール</span>
          </li>
        </ul>
      </div>
      <div className="bg-white rounded-lg p-4">
        <h3 className="flex items-center gap-1 text-[#F99985] mb-2">
          <ManualMailIcon />
          手動メール
        </h3>
        <ul className="flex gap-4 justify-around">
          <li className="flex flex-col items-center gap-2">
            <span className="text-xl font-bold leading-none">
              {(stats.manual || 0).toLocaleString()}
            </span>
            <span className="text-xs">送信</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Stats;
