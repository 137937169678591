import {AccountSignal} from 'api';
import React from 'react';
import Prtime from './Prtime';
import JobListingPage from './JobListingPage';
import Tracking from './Tracking';

const accountSignalComponents: {[key: string]: any} = {
  Prtime: Prtime,
  JobListingPage: JobListingPage,
  Tracking: Tracking,
};

interface Props {
  signal: AccountSignal;
}

export default ({signal}: Props) => {
  const AccountSignalComponent =
    accountSignalComponents[signal.signalable.__typename];
  return (
    <>
      <AccountSignalComponent signal={signal} />
    </>
  );
};
