import { LeadSource, useDestroyLeadSourceMutation } from 'api';
import React from 'react';
import styled from 'styled-components';
import {Button, Modal} from 'components/antd';
import { Trash } from 'components/Ui/Icon';

interface Props {
  leadSource: LeadSource;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
  onClose: () => void;
}

const DestroyConfirmation = ({
  leadSource,
  isModalVisible,
  setIsModalVisible,
  onClose,
}: Props) => {
  const [destroyLeadSource] = useDestroyLeadSourceMutation({
    variables: {id: leadSource?.id},
    refetchQueries: ['leadSources'],
    onCompleted: () => {
      setIsModalVisible(false);
      onClose();
    },
  });

  return (
    <DangerModal
      destroyOnClose
      visible={isModalVisible}
      width={480}
      centered
      footer={null}
      onCancel={() => setIsModalVisible(false)}>
      <ModalContent>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <TrashStyle>
            <Trash />
          </TrashStyle>
        </div>
        <h2>リードソース「{`${leadSource.name}`}」を<br />削除しますか？</h2>
        <div className="confirmation">この操作は取り消せません。</div>
        <Actions>
          <Button size="large" onClick={() => setIsModalVisible(false)}>
            キャンセル
          </Button>
          <Button
            type="primary"
            danger
            onClick={() => destroyLeadSource()}
            size="large">
            削除
          </Button>
        </Actions>
      </ModalContent>
    </DangerModal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
  }

  .confirmation {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin: 20px 0px 24px 0px;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }

  .prospectPoolsCount {
    color: #899098;
    background: #f3f5f7;
    border-radius: 4px;
    line-height: 20px;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;

  .ant-btn-lg {
    width: 130px;
`;

const TrashStyle = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    background: #EB5757;
    border-radius: 50%;

  svg {
    width: 30px;
    height: 30px;
    margin: auto;
    path {
      stroke:#FFFFFF;

    }
`;

const DangerModal = styled(Modal)`
  .ant-modal-body {
    border-top: solid 8px;
    border-color: #eb5757;
  }
`;
export default DestroyConfirmation;
