import React from 'react';

const Icon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="9.41177" fill="#F4F0FF" />
    <path
      d="M39.1667 22.5H28.1667C26.1416 22.5 24.5 24.1416 24.5 26.1667V51.8333C24.5 53.8584 26.1416 55.5 28.1667 55.5H53.8333C55.8584 55.5 57.5 53.8584 57.5 51.8333V40.8333"
      stroke="#8966EF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.4167 38.0833L51.0834 23.4167C52.6021 21.8979 55.0646 21.8979 56.5834 23.4167C58.1021 24.9355 58.1021 27.3979 56.5834 28.9167L41.9167 43.5833L33.6667 46.3333L36.4167 38.0833Z"
      stroke="#8966EF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
