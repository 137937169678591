import React, {useState} from 'react';
import {
  useUpdateCollectedFormUrlMutation,
  CollectedFormUrl,
  useUsersByClientAllQuery,
} from 'api';
import {Select} from 'antd';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const [updateCollectedFormUrl] = useUpdateCollectedFormUrlMutation();

  const [emails, setEmails] = useState(collectedFormUrl.autoreplyMailCc);

  React.useEffect(() => {
    updateCollectedFormUrl({
      variables: {
        collectedFormUrlUuid: collectedFormUrl.uuid,
        attributes: {
          autoreplyMailCc: emails,
        },
      },
    });
  }, [emails]);

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-1 w-full">
        <label className="text-c-base font-bold">CC</label>
        <div className="flex items-center justify-center">
          <Select
            mode="multiple"
            bordered={false}
            showArrow={false}
            value={emails}
            className="form-select border-c-border rounded text-base w-full min-h-[40px] py-0 pr-0 disabled:bg-c-bg disabled:text-c-light [&>.ant-select-selector]:!cursor-pointer"
            onChange={(value) => setEmails(value)}
          >
            {users.map((user) => (
              <Select.Option key={user.uuid} value={user.email}>
                {user.lastName} {user.firstName}
                {`<${user.email}>`}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};
