import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import {useDealReportUserDailyReportsQuery} from 'api';
import {StringParam, useQueryParams} from 'use-query-params';
import React from 'react';
import useMonthAxis from '../../hooks/useMonthAxis';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();
  const [query] = useQueryParams({month: StringParam});
  const axis = useMonthAxis(query.month);
  const {data: {dealReportUserDailyReports = []} = {}} =
    useDealReportUserDailyReportsQuery({variables: {month: query.month}});
  const data = React.useMemo(() => {
    return dealReportUserDailyReports.map((report, index) => {
      return {
        label: report.name,
        color: getColor(index),
        groupId: 1,
        values: report.values,
      };
    });
  }, [dealReportUserDailyReports]);

  return {
    axis: [...axis],
    data: [...data],
  };
};

export default useChartData;
