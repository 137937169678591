import gql from 'graphql-tag';

export default gql`
  fragment contentFragment on Content {
    id
    uuid
    title
    memo
    contentableId
    contentableType
    archivedAt
    createdAt
    updatedAt
  }
`;
