import gql from 'graphql-tag';
import mailCampaignBlockHeaderFragment from './blockHeader';
import mailCampaignBlockTitleFragment from './blockTitle';
import mailCampaignBlockTextFragment from './blockText';
import mailCampaignBlockImageFragment from './blockImage';
import mailCampaignBlockButtonFragment from './blockButton';
import mailCampaignBlockDividerFragment from './blockDivider';
import mailCampaignBlockFooterFragment from './blockFooter';
import mailCampaignBlockUnsubscribeFragment from './blockUnsubscribe';

export default gql`
  fragment mailCampaignBlockFragment on MailCampaignBlock {
    id
    uuid
    blockableId
    blockableType
    createdAt
    updatedAt
    blockable {
      ... on MailCampaignBlockHeader {
        ...mailCampaignBlockHeaderFragment
      }
      ... on MailCampaignBlockTitle {
        ...mailCampaignBlockTitleFragment
      }
      ... on MailCampaignBlockText {
        ...mailCampaignBlockTextFragment
      }
      ... on MailCampaignBlockImage {
        ...mailCampaignBlockImageFragment
      }
      ... on MailCampaignBlockButton {
        ...mailCampaignBlockButtonFragment
      }
      ... on MailCampaignBlockDivider {
        ...mailCampaignBlockDividerFragment
      }
      ... on MailCampaignBlockFooter {
        ...mailCampaignBlockFooterFragment
      }
      ... on MailCampaignBlockUnsubscribe {
        ...mailCampaignBlockUnsubscribeFragment
      }
    }
  }
  ${mailCampaignBlockHeaderFragment}
  ${mailCampaignBlockTitleFragment}
  ${mailCampaignBlockTextFragment}
  ${mailCampaignBlockImageFragment}
  ${mailCampaignBlockButtonFragment}
  ${mailCampaignBlockDividerFragment}
  ${mailCampaignBlockFooterFragment}
  ${mailCampaignBlockUnsubscribeFragment}
`;
