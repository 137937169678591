import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Button, Input} from 'components/antd';
import {ProjectGroup, useDeleteProjectGroupMutation} from 'api';
import {useHistory} from 'react-router';

type Props = {
  projectGroup: ProjectGroup;
  isOpen: boolean;
  setModal: (isOpen: boolean) => void;
};

export default ({projectGroup, isOpen, setModal}: Props) => {
  const history = useHistory();
  const [text, setText] = React.useState('');
  const [deleteProjectGroup, {loading}] = useDeleteProjectGroupMutation({
    refetchQueries: ['projectGroups', 'currentUser'],
    onCompleted: () => {
      setModal(false);
      history.push(`/list/groups${location.search}`);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setModal(false);
      }}
      style={{
        overlay: {
          background: 'rgba(13, 24, 59, 0.6)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0',
          borderRadius: '10px',
          width: '518px',
          boxShadow: '0px 0px 20px rgba(73, 80, 88, 0.26)',
          overflowY: 'scroll',
        },
      }}
      contentLabel="modal">
      <ModalHeader>
        <h2>グループを削除しますか？</h2>
      </ModalHeader>
      <ModalBody>
        <span>
          所属している営業リスト、プレリード情報はすべて削除されます。
          削除確定するのには、下に「削除」を入力します。
        </span>
        <Input
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="削除"
        />
        <Button
          type="primary"
          danger
          disabled={text != '削除'}
          loading={loading}
          onClick={() =>
            deleteProjectGroup({
              variables: {
                uuid: projectGroup.uuid,
              },
            })
          }>
          削除
        </Button>
      </ModalBody>
    </Modal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  padding: 2rem 3rem 0;
  position: relative;

  h2 {
    font-weight: bold;
    font-size: 17.5px;
    color: #eb5757;
  }

  button {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    box-shadow: none;
    border: none;
    display: flex;
    svg {
      margin: auto 0;
      height: 16px;
      width: 25px;
      path {
        fill: #bdc4cb;
      }
    }

    span {
      margin: auto 0;
      color: #bdc4cb;
    }
  }
`;

const ModalBody = styled.div`
  padding: 0rem 3rem 2rem;
  display: flex;
  flex-direction: column;

  > * {
    &:nth-child(1) {
      color: #eb5757;
    }
    &:nth-child(2) {
      margin-top: 19px;

      .ant-input {
        height: 40px;
      }
    }
    &:nth-child(3) {
      margin-left: auto;
      margin-top: 31px;
      width: 128px;
    }
  }
`;
