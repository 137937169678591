import React from 'react';
import styled from 'styled-components';
import {ProspectPool, useUpdateAccountMutation} from 'api';
import toJaNum from 'helpers/toJaNum';
import MARKETS from 'helpers/markets';
import {Facebook, Twitter, Linkedin, Instagram, Youtube, Line} from './SnsIcon';
import Name from './Name';
import WebSite from './WebSite';
import Address from './Address';
import TelephoneNumber from './TelephoneNumber';
import CorpNumber from './CorpNumber';
import EmployeeNumber from './EmployeeNumber';
import EstablishedYearMonth from './EstablishedYearMonth';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {prospect} = prospectPool || {};
  const {account} = prospect || {};

  if (!account) return null;

  const [updateAccount] = useUpdateAccountMutation({});

  return (
    <Container>
      <Name account={account} />

      {account.company?.facebookUrls.map((facebookUrl, i) => (
        <SnsIconWrapper key={i} href={facebookUrl} target="_blank">
          <Facebook />
        </SnsIconWrapper>
      ))}
      {account.company?.twitterUrls.map((twitterUrl, i) => (
        <SnsIconWrapper key={i} href={twitterUrl} target="_blank">
          <Twitter />
        </SnsIconWrapper>
      ))}
      {account.company?.linkedinUrls.map((linkedinUrl, i) => (
        <SnsIconWrapper key={i} href={linkedinUrl} target="_blank">
          <Linkedin />
        </SnsIconWrapper>
      ))}
      {account.company?.instagramUrls.map((instagramUrl, i) => (
        <SnsIconWrapper key={i} href={instagramUrl} target="_blank">
          <Instagram />
        </SnsIconWrapper>
      ))}
      {account.company?.youtubeUrls.map((youtubeUrl, i) => (
        <SnsIconWrapper key={i} href={youtubeUrl} target="_blank">
          <Youtube />
        </SnsIconWrapper>
      ))}
      {account.company?.lineUrls.map((lineUrl, i) => (
        <SnsIconWrapper key={i} href={lineUrl} target="_blank">
          <Line />
        </SnsIconWrapper>
      ))}

      <TagBox>
        {account.company?.subCategories?.map((category) => (
          <TagItem key={category.id}>{category.displayName}</TagItem>
        ))}
      </TagBox>
      <Item>
        <Label>ウェブサイト</Label>
        <WebSite account={account} />
      </Item>
      <Item>
        <Label>郵便番号</Label>
        <Value>{account.zipCode}</Value>
      </Item>
      <AddressItem>
        <Label>住所</Label>
        <Address account={account} />
      </AddressItem>
      <Item>
        <Label>代表電話</Label>
        <TelephoneNumber account={account} />
      </Item>
      <Item>
        <Label>代表FAX</Label>
        <Value>{account.faxNumber}</Value>
      </Item>
      <Item>
        <Label>代表メール</Label>
        <Value>{account.mailAddress}</Value>
      </Item>
      <Item>
        <Label>代表者名</Label>
        <Value>{account.representativePerson}</Value>
      </Item>
      <Item>
        <Label>法人番号</Label>
        <CorpNumber account={account} />
      </Item>
      <Item>
        <Label>従業員数</Label>
        <EmployeeNumber account={account} />
      </Item>
      <Item>
        <Label>資本金</Label>
        <Value>
          {account.capitalFund &&
            `${toJaNum(prospectPool.prospect?.account?.capitalFund)}円`}
        </Value>
      </Item>
      <Item>
        <Label>設立年月</Label>
        <EstablishedYearMonth account={account} />
      </Item>
      <Item>
        <Label>上場区分</Label>
        <select
          className="w-60 h-7 rounded hover:border hover:border-[#E2E6EA] focus:border focus:border-[#E2E6EA]"
          onChange={(e) =>
            updateAccount({
              variables: {
                id: account.id,
                attributes: {
                  listingMarket: e.target.value,
                },
              },
            })
          }
          value={account.listingMarket}>
          {MARKETS.map((market, index) => {
            return (
              <option key={index} value={market.value}>
                {market.name}
              </option>
            );
          })}
        </select>
      </Item>
      <Item>
        <Label>売上</Label>
        <Value>
          {account.company?.sales && `${toJaNum(account.company.sales)}円`}
        </Value>
      </Item>
      <Item>
        <Label>決算月</Label>
        <Value>
          {account.accountClosingMonth && `${account.accountClosingMonth}月`}
        </Value>
      </Item>
      <Item>
        <Label>事業所数</Label>
        <Value>{account.company?.officesNumber}</Value>
      </Item>
      <Item>
        <Label>国内支店・拠点数</Label>
        <Value>{account.officesDomesticBranchesNumber}</Value>
      </Item>
      <Item>
        <Label>海外支店・拠点数</Label>
        <Value>{account.officesForeignBranchesNumber}</Value>
      </Item>
      <Item>
        <Label>国内店舗数</Label>
        <Value>{account.officesDomesticStoresNumber}</Value>
      </Item>
      <Item>
        <Label>海外店舗数</Label>
        <Value>{account.officesForeignStoresNumber}</Value>
      </Item>
      <Item>
        <Label>国内工場数</Label>
        <Value>{account.officesDomesticFactoriesNumber}</Value>
      </Item>
      <Item>
        <Label>海外工場数</Label>
        <Value>{account.officesForeignFactoriesNumber}</Value>
      </Item>
    </Container>
  );
};
const SnsIconWrapper = styled.a`
  cursor: pointer;
  margin-right: 4px;
`;

const Container = styled.div``;

const TagBox = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const TagItem = styled.span`
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #495058;
`;

const Item = styled.div`
  margin-bottom: 5px;
  display: flex;
  height: 35px;
  align-items: center;
  font-size: 12px;
  &:hover {
    background: #f5faff;
  }
`;

const AddressItem = styled.div`
  margin-bottom: 5px;
  display: flex;
  height: 45px;
  align-items: center;
  font-size: 12px;
  &:hover {
    background: #f5faff;
  }
`;

const Label = styled.span`
  margin-right: 10px;
  width: 114px;
  font-weight: 700;
  font-size: 12px;
  color: #bdc4cb;
`;

const Value = styled.span`
  display: inline-block;
  word-break: break-all;
  width: 150px;
`;
