import React from 'react';
import {useHistory, useParams, Switch, Route, Redirect} from 'react-router-dom';
import Title from './Title';
import Tab from './Tab';
import {useCollectedFormUrlQuery} from 'api';
import EditPage from './edit';
import AutoreplyPage from './autoreply';
import FieldsPage from './fields';
import NotificationPage from './notification';

export default () => {
  const history = useHistory();

  const {collectedFormUrlId} = useParams<{
    collectedFormUrlId: string;
  }>();

  const {data: {collectedFormUrl} = {}} = useCollectedFormUrlQuery({
    variables: {
      uuid: collectedFormUrlId,
    },
  });

  if (!collectedFormUrl) return null;

  return (
    <>
      <a onClick={() => history.push(`/form/collected_form_urls`)}>
        〈 タグ連携フォーム一覧に戻る
      </a>
      <div className="my-4 flex items-center justify-between">
        <Title collectedFormUrl={collectedFormUrl} />
      </div>
      <div className="flex flex-col bg-white rounded">
        <Tab />
        <div className="flex gap-8 p-8">
          <Switch>
            <Route path="/form/collected_form_urls/:collectedFormUrlId/edit">
              <EditPage collectedFormUrl={collectedFormUrl} />
            </Route>
            <Route path="/form/collected_form_urls/:collectedFormUrlId/fields">
              <FieldsPage collectedFormUrl={collectedFormUrl} />
            </Route>
            <Route path="/form/collected_form_urls/:collectedFormUrlId/autoreply">
              <AutoreplyPage collectedFormUrl={collectedFormUrl} />
            </Route>
            <Route path="/form/collected_form_urls/:collectedFormUrlId/notification">
              <NotificationPage collectedFormUrl={collectedFormUrl} />
            </Route>
            <Redirect
              to={`/form/collected_form_urls/${collectedFormUrlId}/edit`}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
