import React from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import useProjectPath from 'hooks/useProjectPath';
import useSearchCondition from './useSearchCondition';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const currentPath = (path: string) => location.pathname.indexOf(path) > -1;
  const {createPath} = useProjectPath();
  const {searchParams} = useSearchCondition();
  const {subCategoryIds, websiteIds, technologies} = searchParams;

  return (
    <Container>
      <Tab
        current={currentPath('/search/category') ? 1 : 0}
        to={createPath(
          `projects/${projectId}/search/category${location.search}`,
        )}>
        <span>
          業種 <span>{subCategoryIds?.length || 0}</span>
        </span>
      </Tab>
      <Tab
        current={currentPath('/search/website') ? 1 : 0}
        to={createPath(
          `projects/${projectId}/search/website/multiple${location.search}`,
        )}>
        <span>
          メディア
          {currentPath('single/') ? (
            <span>1</span>
          ) : (
            <span>{websiteIds?.length || 0}</span>
          )}
        </span>
      </Tab>
      <Tab
        current={currentPath('/search/technology') ? 1 : 0}
        to={createPath(
          `projects/${projectId}/search/technology${location.search}`,
        )}>
        <span>
          導入サービス <span>{technologies?.length || 0}</span>
        </span>
      </Tab>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
`;

const Tab = styled(Link)<{current?: number}>`
  height: 34px;
  display: flex;
  cursor: pointer;
  background: ${({current}) => (current ? '#EEF6FD' : '#fff')};
  border-radius: 4px;
  border-right: none;
  border-left: none;
  position: relative;
  align-items: center;

  &:nth-of-type(2) {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;

    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 34px;
      background-color: #e1e6eb;
      position: absolute;
      top: -2px;
      left: -10px;
    }
  }

  span {
    display: flex;
    align-items: center;
    margin: auto;
    padding: 6px 8px 6px 12px;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: ${({current}) => (current ? '#68B5FB' : '#BDC4CB')};

    span {
      height: 20px;
      margin-left: 4px;
      background: ${({current}) => (current ? '#68B5FB' : '#E1E6EB')};
      border-radius: 10px;
      padding: 3px 7px;
      min-width: 26px;
      display: flex;
      vertical-align: middle;
      justify-content: center;
      color: ${({current}) => (current ? '#fff' : '#BDC4CB')};
    }
  }
`;
