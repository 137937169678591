import gql from 'graphql-tag';
import prospectPoolFragment from '../prospectPool';
import accountPoolFragment from '../accountPool';
import prospectFragment from '../prospect/prospect';
import accountFragment from '../account';

export default gql`
  fragment taskProspectFragment on TaskProspect {
    id
    createdAt
    prospectPool {
      ...prospectPoolFragment
    }
    accountPool {
      ...accountPoolFragment
    }
    prospect {
      ...prospectFragment
    }
    account {
      ...accountFragment
    }
  }
  ${prospectPoolFragment}
  ${accountPoolFragment}
  ${prospectFragment}
  ${accountFragment}
`;
