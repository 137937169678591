import React from 'react';
import {Row} from 'react-table';
import {Prelead, useUsersByClientAllQuery} from 'api';

interface Props {
  row: Row<Prelead>;
}

const UserCell = ({row}: Props) => {
  const prelead: Prelead = row.original;

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const user = React.useMemo(
    () => users.find((user) => user.id === prelead.userId),
    [users, prelead.userId],
  );

  return (
    <div>
      {user?.lastName} {user?.firstName}
    </div>
  );
};

export default UserCell;
