import React from 'react';
import moment from 'moment';
import {ProspectActivity, HubspotDealCreateProspectActivity} from 'api';
import {LaunchLink} from 'components/Ui/Icon';

interface HubspotDealCreateActivity extends ProspectActivity {
  resource: HubspotDealCreateProspectActivity;
}

interface Props {
  prospectActivity: HubspotDealCreateActivity;
}

export default ({prospectActivity}: Props) => {
  const {resource: {hubspotDeal = {}} = {}} = prospectActivity;

  return (
    <>
      <span className="h-[10px] w-[10px] bg-[#c4c4c4] rounded-full ml-[10px] mr-[15px] z-10" />
      <div className="flex flex-1 w-[455px] mt-[-4px] ml-[3px]">
        <div className="flex items-center flex-1">
          <span className="mr-1">取引</span>
          <a
            href={prospectActivity.resource.hubspotUrl}
            target="_blank"
            className="flex items-center mr-1">
            <span className="flex-1 m-0 mr-1 font-medium max-w-[225px] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {hubspotDeal.dealname}
            </span>{' '}
            <LaunchLink width="14" height="14" />
          </a>
          <span>を作成</span>
        </div>
        <time className="text-c-light">
          {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
        </time>
      </div>
    </>
  );
};
