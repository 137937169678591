import React from 'react';
import LeadSourceItems from './LeadSourceItems';
import TagItems from './TagItems';
import UserItems from './UserItems';

export default () => {
  return (
    <div className="pt-1 flex flex-col gap-2 mb-5">
      <LeadSourceItems />
      <TagItems />
      <UserItems />
    </div>
  );
};
