import React from 'react';
import styled from 'styled-components';
import {Prelead} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const TelCountCell = ({row}: Props) => {
  const prelead: Prelead = row.original;

  return <Cell>{prelead.telCount}</Cell>;
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

export default TelCountCell;
