import gql from 'graphql-tag';
import {
  automaticMailFragment,
  automaticMailSendFragment,
} from '../../fragments';

export default gql`
  fragment automaticMailSendMailMessageFragment on AutomaticMailSendMailMessage {
    id
    updatedAt
    createdAt
    automaticMailSend {
      ...automaticMailSendFragment
      automaticMail {
        ...automaticMailFragment
      }
    }
  }
  ${automaticMailFragment}
  ${automaticMailSendFragment}
`;
