import React from 'react';
import {useParams, useHistory, Switch, Route, Redirect} from 'react-router-dom';
import {useFormQuery} from 'api';
import Tab from './Tab';
import FormPage from './form';
import DonePage from './done';
import PostsPage from './posts';
import EditPage from './edit';
import AutoreplyPage from './autoreply';
import PublishPage from './publish';
import Header from './Header';

export default () => {
  const history = useHistory();
  const {formId} = useParams<{
    formId: string;
  }>();

  const {data: {form} = {}} = useFormQuery({
    variables: {
      uuid: formId,
    },
  });

  if (!form) return null;

  return (
    <>
      <a onClick={() => history.push(`/form/forms`)}>〈 フォーム一覧に戻る</a>
      <Header form={form} />
      <div className="flex flex-col bg-white rounded">
        <Tab />
        <div className="flex gap-8 p-8">
          <Switch>
            <Route path="/form/forms/:formId/posts">
              <PostsPage form={form} />
            </Route>
            <Route path="/form/forms/:formId/form">
              <FormPage form={form} />
            </Route>
            <Route path="/form/forms/:formId/done">
              <DonePage form={form} />
            </Route>
            <Route path="/form/forms/:formId/edit">
              <EditPage form={form} />
            </Route>
            <Route path="/form/forms/:formId/autoreply">
              <AutoreplyPage form={form} />
            </Route>
            <Route path="/form/forms/:formId/publish">
              <PublishPage form={form} />
            </Route>
            <Redirect to={`/form/forms/${formId}/form`} />
          </Switch>
        </div>
      </div>
    </>
  );
};
