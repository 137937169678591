import React from 'react';
import {
  NumberParam,
  DelimitedArrayParam,
  withDefault,
  StringParam,
  useQueryParams,
  encodeDelimitedArray,
  decodeDelimitedArray,
} from 'use-query-params';

const PAGE_SIZE = 20;

const DelimitedCommaArrayParam = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | string[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ','),
};

const searchParamsDef = {
  page: NumberParam,
  userIds: DelimitedArrayParam,
  status: withDefault(StringParam, 'open'),
  approachTypes: withDefault(DelimitedCommaArrayParam, []),
  priorities: withDefault(DelimitedCommaArrayParam, []),
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  const searchParams = {
    userIds: query.userIds,
    status: query.status,
    approachTypes: query.approachTypes,
    priorities: query.priorities,
  };

  React.useEffect(() => {
    setQuery({
      ...query,
    });
  }, []);

  return {
    searchParams,
    query,
    setQuery,
    page,
    perPage: PAGE_SIZE,
  };
};

export default useSearchParams;
