import React from 'react';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';

interface Props {
  name?: string;
  options: {value: string; text: string; keyword?: string}[];
  value: string;
  onChange: (value: string) => void;
  filter?: boolean;
  disabled?: boolean;
}

const CheckboxGroup = ({
  name,
  options,
  value,
  onChange,
  filter,
  disabled,
}: Props) => {
  const [keyword, setKeyword] = React.useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    if (checked) {
      onChange(value);
    }
  };

  const filteredOptions =
    keyword === ''
      ? options
      : options.filter((option) =>
          (option.keyword || option.text)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(keyword.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <div className="flex flex-col gap-2">
      {filter && (
        <div
          className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2 ${
            disabled && 'bg-c-bg'
          }`}>
          <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
          <input
            className="h-8 text-sm border-c-border flex-1 outline-none"
            onChange={(e) => setKeyword(e.target.value)}
            disabled={disabled}
          />
        </div>
      )}
      {filteredOptions.map((option) => (
        <label key={option.value} className="flex items-center text-base">
          <input
            name={name}
            type="radio"
            value={option.value}
            className="form-radio h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light"
            checked={value === option.value}
            onChange={handleChange}
            disabled={disabled}
          />
          <span className="ml-2">{option.text}</span>
        </label>
      ))}
    </div>
  );
};

export default CheckboxGroup;
