import React from 'react';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery} from 'api';
import DealRecordType from './DealRecordType';
import DealSearchCondition from './DealSearchCondition';

export default () => {
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {data: {workflow: {searchCondition = {}} = {}} = {}} = useWorkflowQuery(
    {
      variables: {uuid: workflowId},
    },
  );

  const [salesforceDealSearchConditions, setSalesforceDealSearchConditions] =
    React.useState([]);

  React.useEffect(() => {
    setSalesforceDealSearchConditions(
      searchCondition?.salesforceDealSearchConditions || [],
    );
  }, [searchCondition.salesforceDealSearchConditions]);

  return (
    <>
      <DealRecordType
        setSalesforceDealSearchConditions={setSalesforceDealSearchConditions}
      />
      <DealSearchCondition
        setSalesforceDealSearchConditions={setSalesforceDealSearchConditions}
        salesforceDealSearchConditions={salesforceDealSearchConditions}
      />
    </>
  );
};
