import React, {useState} from 'react';
import styled from 'styled-components';
import {ArrowDown, Mail, Tel} from 'components/Ui/Icon';
import {PreleadContact, usePreleadContactsQuery, usePreleadQuery} from 'api';
import EditModal from './EditModal';
import _ from 'lodash';
import Integration from './Integration';
import usePreleadContext from '../../usePreleadContext'

export default () => {
  const {preleadId} = usePreleadContext()

  if (!preleadId) return null;
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const {data: {preleadContacts = []} = {}} = usePreleadContactsQuery({
    variables: {preleadUuid: preleadId},
    skip: !preleadId,
  });
  if (preleadContacts.length === 0) return null;

  const Contact = (preleadContact: PreleadContact) => {
    if (!preleadContact) return null;
    return (
      <ContactCard key={preleadContact.uuid}>
        <div>
          <NameBox
            onClick={() => {
              setSelectedContact(preleadContact);
              setEditModalVisible(true);
            }}>
            <span>
              {preleadContact.lastName}&nbsp;
              {preleadContact.firstName}
            </span>
            <ArrowDown />
          </NameBox>
        </div>
        <div>
          <div>
            <span>
              {_.compact([
                preleadContact.section,
                preleadContact.sectionPosition,
              ]).join(' | ')}
            </span>
          </div>
          <div>
            {preleadContact.email && (
              <>
                <Mail />
                <span>{preleadContact.email}</span>
              </>
            )}
            {preleadContact.telephoneNumber && (
              <>
                <Tel />
                <span>{preleadContact.telephoneNumber}</span>
              </>
            )}
          </div>
        </div>
        <div>
          <Integration preleadContact={preleadContact} />
        </div>
      </ContactCard>
    );
  };

  return (
    <>
      {prelead && (
        <Container>
          {preleadContacts?.length > 0 && (
            <ContactSection>
              {showAll
                ? preleadContacts?.map((preleadContact) =>
                    Contact(preleadContact),
                  )
                : preleadContacts
                    ?.slice(0, 2)
                    ?.map((preleadContact) => Contact(preleadContact))}
            </ContactSection>
          )}

          {preleadContacts?.length > 2 && (
            <DisplaySection>
              {showAll ? (
                <DisplayButton onClick={() => setShowAll(false)}>
                  少なく表示
                </DisplayButton>
              ) : (
                <>
                  <span>連絡先他&nbsp;{preleadContacts?.length - 2}人</span>
                  <DisplayButton onClick={() => setShowAll(true)}>
                    さらに表示
                  </DisplayButton>
                </>
              )}
            </DisplaySection>
          )}
          <EditModal
            isModalVisible={isEditModalVisible}
            setModalVisible={setEditModalVisible}
            preleadContact={selectedContact}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
`;

const ContactSection = styled.div`
  margin-top: -10px;
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #f3f5f7;

  > div {
    margin-bottom: 7px;

    &:first-child {
      display: flex;
    }

    &:nth-child(2) {
      div {
        display: flex;
        align-items: center;
        span {
          margin-right: 9px;
          font-size: 9px;
          color: #899098;
        }
        svg {
          margin-right: 3px;
          width: 17px;
          height: 13px;
        }
      }
    }

    &:nth-child(3) {
    }
  }
`;

const NameBox = styled.div`
  color: #495058;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #68b5fb;
    svg {
      path {
        stroke: #68b5fb;
      }
    }
  }
  span {
    font-size: 12px;
  }
  svg {
    margin-left: 5px;
    width: 14px;
    height: 14px;
  }
`;

const DisplaySection = styled.div`
  margin-top: 5px;
  font-size: 12px;

  > span:first-child {
    color: #899098;
    margin-right: 10px;
  }
`;

const DisplayButton = styled.span`
  font-size: 12px;
  color: #68b5fb !important;
  cursor: pointer;
`;
