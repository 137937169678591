import React from 'react';
import styled from 'styled-components';
import StatusEclipse from 'components/Ui/StatusEclipse';
import telCategoryColor from 'helpers/telCategoryColor';
import {Route, Switch, useHistory} from 'react-router';
import CallNg from './CallNg';
import ReceptionNg from './ReceptionNg';
import ContactNg from './ContactNg';

export default () => {
  const history = useHistory();

  return (
    <Container>
      <PageHeader>
        <div>
          <h3>
            プレリードのNG理由テンプレートを編集、新規追加することができます。
            <br />
            登録したテンプレートは営業リスト作成時に営業リストに登録されます。
          </h3>
        </div>
      </PageHeader>

      <Tabs>
        <Tab
          active={location.pathname.endsWith(`call_ng`)}
          onClick={() =>
            history.push(
              `/settings/status/tel_status/ng_reason_template/call_ng`,
            )
          }>
          <StatusEclipse color={telCategoryColor('call_ng')} />
          <span>コールNG</span>
        </Tab>
        <Tab
          active={location.pathname.endsWith(`reception_ng`)}
          onClick={() =>
            history.push(
              `/settings/status/tel_status/ng_reason_template/reception_ng`,
            )
          }>
          <StatusEclipse color={telCategoryColor('reception_ng')} />
          <span>受付NG</span>
        </Tab>
        <Tab
          active={location.pathname.endsWith(`contact_ng`)}
          onClick={() =>
            history.push(
              `/settings/status/tel_status/ng_reason_template/contact_ng`,
            )
          }>
          <StatusEclipse color={telCategoryColor('contact_ng')} />
          <span>キーマン接触NG</span>
        </Tab>
      </Tabs>
      <Switch>
        <Route
          path="/settings/status/tel_status/ng_reason_template/call_ng"
          component={CallNg}
        />
        <Route
          path="/settings/status/tel_status/ng_reason_template/reception_ng"
          component={ReceptionNg}
        />
        <Route
          path="/settings/status/tel_status/ng_reason_template/contact_ng"
          component={ContactNg}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div``;

const PageHeader = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: flex-end;

  > div {
    flex: 1;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled.div<{active?: boolean}>`
  display: flex;
  align-items: center;
  margin-right: 16px;
  padding-bottom: 12px;
  border-bottom: ${({active}) => (active ? 'solid 3px #68b5fb' : '')};
  color: ${({active}) => (active ? '#495058' : '#bdc4cb')};

  &:hover {
    cursor: pointer;
  }

  span {
    margin-left: 6px;
    font-weight: bold;
    font-size: 12px;
    color: #899098;
  }
`;
