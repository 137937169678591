import gql from 'graphql-tag';
import automaticMailFragment from '../automaticMail';

export default gql`
  fragment automaticMailStepFragment on AutomaticMailStep {
    id
    automaticMailSendsCount
    automaticMailSendsReadyCount
    automaticMailSendsSentCount
    automaticMailSendsCanceledCount
    automaticMailSendsOpenedCount
    automaticMailSendsClickedCount
    automaticMailSendsRepliedCount
    automaticMailSendsBouncedCount
    isContinuedByEvent
    eventToContinue
    automaticMail {
      ...automaticMailFragment
      mailAttachments {
        uuid
        filename
        extension
        size
      }
    }
  }
  ${automaticMailFragment}
`;
