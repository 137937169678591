import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import MARKETS from 'helpers/markets';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  if (!query?.listingMarkets || !query?.listingMarkets.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">上場区分</h4>
        <button
          onClick={() => setFilterType('listingMarkets')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setQuery({...query, listingMarkets: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap items-center">
          {query.listingMarkets?.includes('none') && (
            <span className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              未設定
            </span>
          )}
          {MARKETS.filter(
            (market) => query.listingMarkets?.includes(market.value),
          ).map((market) => (
            <span
              key={market.value}
              className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {market.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
