const MARKETS = [
  {name: '未上場', value: 'nothing', id: 0},
  {name: '東証プライム市場', value: 'tosyoprime', id: 13},
  {name: '東証スタンダード市場', value: 'tosyostandard', id: 14},
  {name: '東証グロース市場', value: 'tosyogrowth', id: 15},
  {name: 'TOKYO PRO Market', value: 'tokyopro', id: 7},
  {name: '名証プレミア市場', value: 'meishopremiere', id: 16},
  {name: '名証メイン市場', value: 'meishomain', id: 17},
  {name: '名証ネクスト市場', value: 'meishonext', id: 18},
  {name: '福岡証券取引所', value: 'fukuoka', id: 8},
  {name: 'Q-Board', value: 'qboard', id: 9},
  {name: '札幌証券取引所', value: 'sapporo', id: 11},
  {name: 'アンビシャス', value: 'ambitious', id: 10},
];

export default MARKETS;
