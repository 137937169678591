import {ArrowRightIcon} from '@heroicons/react/20/solid';
import React, {useEffect} from 'react';
import {DateParam, useQueryParams} from 'use-query-params';

export const DayRangeInput: React.FC = () => {
  const [query, setQuery] = useQueryParams({
    dateFrom: DateParam,
    dateTo: DateParam,
  });

  useEffect(() => {
    setQuery({
      dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      dateTo: new Date(new Date().setMonth(new Date().getMonth() + 1, 0)),
    });
  }, []);

  const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({
      dateFrom: new Date(e.target.value),
    });
  };

  const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({
      dateTo: new Date(e.target.value),
    });
  };

  return (
    <div className="flex items-center gap-4">
      <input
        type="date"
        onChange={onChangeFrom}
        value={query.dateFrom?.toISOString().split('T')[0]}
        className="border border-c-border rounded py-1 px-2"
      />
      <ArrowRightIcon className="w-4 h-4" />
      <input
        type="date"
        onChange={onChangeTo}
        value={query.dateTo?.toISOString().split('T')[0]}
        className="border border-c-border rounded py-1 px-2"
      />
    </div>
  );
};
