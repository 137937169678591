import React from 'react';
import {useCurrentClientQuery, useUpdateSalesforceSettingMutation} from 'api';
import {message, Checkbox} from 'components/antd';
export default () => {
  const [updateSalesforceSetting] = useUpdateSalesforceSettingMutation({
    onCompleted: () => {
      message.success('更新しました');
    },
  });

  const {
    data: {
      currentClient = {},
      currentClient: {salesforceSetting = {}} = {},
    } = {},
  } = useCurrentClientQuery({});

  return (
    <>
      <div className="text-[#222426] text-base font-[400] mb-6">
        <label>
          LEADPADとSalesforceを同期する際の、同期対象のオブジェクトを選択できます。
        </label>
      </div>
      <div>
        <Checkbox
          checked={salesforceSetting?.canUpdateSalesforceLead || false}
          onChange={(e) => {
            updateSalesforceSetting({
              variables: {
                clientId: currentClient.id,
                attributes: {canUpdateSalesforceLead: e.target.checked},
              },
            });
          }}>
          リード
        </Checkbox>
      </div>
    </>
  );
};
