import React from 'react';
import {useParams} from 'react-router';
import {useProjectProgressQuery} from 'api';
import OpenAction from './OpenAction';
import DraftAction from './DraftAction';
import ArchivedAction from './ArchivedAction';
import {ImportProgressBar} from 'components/Ui';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project: project = null} = {}, refetch: refetchProject} =
    useProjectProgressQuery({
      variables: {uuid: projectId},
      skip: !projectId,
    });
  if (!project) return null;

  const {
    importPreleadProjectCopy,
    importTransferedProject,
    lastApplyMultipleBlockGroup,
    lastExcludeDuplicatePreleadProject,
  } = project;

  if (
    project.preleadCreateStatus === 'progress' ||
    project.preleadCreateStatus === 'failed'
  ) {
    return (
      <ImportProgressBar percent={project.percent} refetch={refetchProject} />
    );
  }

  if (
    importPreleadProjectCopy &&
    importPreleadProjectCopy.status !== 'success'
  ) {
    return (
      <ImportProgressBar
        percent={importPreleadProjectCopy.percent}
        refetch={refetchProject}
      />
    );
  }

  if (importTransferedProject && importTransferedProject.status !== 'success') {
    return (
      <ImportProgressBar
        percent={importTransferedProject.percent}
        refetch={refetchProject}
      />
    );
  }

  if (
    lastApplyMultipleBlockGroup &&
    lastApplyMultipleBlockGroup.status !== 'success'
  ) {
    return (
      <ImportProgressBar
        percent={lastApplyMultipleBlockGroup.percent}
        refetch={refetchProject}
      />
    );
  }

  if (
    lastExcludeDuplicatePreleadProject &&
    lastExcludeDuplicatePreleadProject.status !== 'success'
  ) {
    return (
      <ImportProgressBar
        percent={lastExcludeDuplicatePreleadProject.percent}
        refetch={refetchProject}
      />
    );
  }

  if (project.status === 'draft') {
    return <DraftAction project={project} />;
  }

  if (project.status === 'open') {
    return <OpenAction project={project} />;
  }

  if (project.status === 'archived') {
    return <ArchivedAction project={project} />;
  }

  if (project.status === 'deleted') {
    return null;
  }
};
