import React from 'react';
import {useCreateMailCampaignBlockMutation, useMailCampaignQuery} from 'api';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import BlockHeader from './Header';
import BlockTitle from './Title';
import BlockText from './Text';
import BlockImage from './Image';
import BlockButton from './Button';
import BlockDivider from './Divider';
import BlockSocial from './Social';
import BlockVideo from './Video';
import BlockFooter from './Footer';

const iconComponents: {[key: string]: any} = {
  header: BlockHeader,
  title: BlockTitle,
  text: BlockText,
  image: BlockImage,
  button: BlockButton,
  divider: BlockDivider,
  social: BlockSocial,
  video: BlockVideo,
  footer: BlockFooter,
};

interface Props {
  type: string;
  text: string;
}

export default ({type, text}: Props) => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();
  const IconComponent = iconComponents[type];

  const [create] = useCreateMailCampaignBlockMutation({
    variables: {
      uuid: mailCampaignId,
      type: `Block${type.charAt(0).toUpperCase() + type.slice(1)}`,
    },
  });
  const {data: {mailCampaign = null} = {}} = useMailCampaignQuery({
    variables: {uuid: mailCampaignId},
    skip: !mailCampaignId,
  });

  return (
    <Button
      onClick={() => {
        if (mailCampaign.status === 'draft') {
          create();
        }
      }}
      draft={mailCampaign.status === 'draft'}>
      <IconComponent />
      <p>{text}</p>
    </Button>
  );
};

const Button = styled.div<{draft?: boolean}>`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  cursor: ${({draft}) => (draft ? 'pointer' : 'not-allowed')};

  p {
    margin: 0 0 0.5rem;
  }

  svg {
    margin-bottom: 0.5rem;

    path {
      stroke: #899098 !important;
    }
  }

  &:hover {
    border: 1px solid #68b5fb;
    box-shadow: 0px 0px 4px 1px #68b5fb;
  }
`;
