import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useSalesforceDealRecordTypeQuery,
  useUpdateWorkflowSalesforceDealSelectSearchConditionMutation,
  useWorkflowQuery,
  SalesforceDealField,
} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  deal_field_id: string;
};

const DealField: React.FC<{condition: SelectSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const [updateWorkflowSalesforceDealSelectSearchCondition] =
    useUpdateWorkflowSalesforceDealSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const {
    data: {salesforceDealRecordType: {salesforceDealFields = []} = {}} = {},
  } = useSalesforceDealRecordTypeQuery({
    variables: {
      salesforceDealRecordTypeId: searchCondition.salesforceDealRecordTypeId,
    },
    skip: !searchCondition.salesforceDealRecordTypeId,
  });

  const dealFieldOptions = () => {
    return salesforceDealFields
      .filter(
        (item: SalesforceDealField) =>
          item.fieldType === 'select' && item.fieldName.endsWith('__c'),
      )
      .map((item: SalesforceDealField) => {
        return {
          value: item.id,
          label: item.label,
          fieldtype: item.fieldType,
        };
      });
  };

  const alreadyAdded = (value: string): boolean => {
    const fields = searchCondition?.salesforceDealSearchConditions?.map(
      (item: SelectSearchCondition) => item.deal_field_id,
    );
    return fields.includes(value);
  };

  return (
    <Select
      style={{width: 200}}
      className="mr-2"
      disabled={!isConditionUpdatable || !isMember}
      value={condition.deal_field_id || ''}
      onChange={(value: string) => {
        updateWorkflowSalesforceDealSelectSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              dealFieldId: value,
              fieldType: 'select',
              fieldSelectOptionId: null,
            },
          },
          refetchQueries: ['workflow'],
        });
      }}>
      {dealFieldOptions().map((option) => (
        <Select.Option
          key={option.value}
          value={option.value}
          hidden={alreadyAdded(option.value)}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DealField;
