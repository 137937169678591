import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="10" cy="10" r="10" fill="#8DD888" />
    <g clipPath="url(#clip0_28125_109864)">
      <path
        d="M6.92983 6.42102L8.15564 6.46911C8.41383 6.47924 8.64296 6.656 8.74192 6.9214L9.10437 7.8935C9.18873 8.11972 9.16602 8.37782 9.04386 8.58159L8.57948 9.3562C8.85442 9.78424 9.60227 10.7871 10.3941 11.3742L10.9846 10.9802C11.1346 10.8801 11.3143 10.8503 11.4841 10.8975L12.6579 11.2238C12.9701 11.3106 13.1804 11.6352 13.1558 11.992L13.0804 13.0815C13.054 13.4638 12.7653 13.7638 12.4227 13.7206C7.90046 13.1505 5.2485 6.42101 6.92983 6.42102Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_28125_109864">
        <rect width="8" height="8" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
