import React from 'react';
import styled from 'styled-components';
import useSearchCondition from '../../useSearchCondition';
import {regions} from 'helpers/regions';
import {usePrefectureTypesQuery} from 'api';
import {Check} from 'components/Ui/Icon';

export default ({
  currentRegion,
  setCurrentRegion,
}: {
  currentRegion: string;
  setCurrentRegion: (region: string) => void;
}) => {
  const {query} = useSearchCondition();
  const {cityIds = [], prefectureTypeIds = []} = query;
  const {data: {prefectureTypes = []} = {}} = usePrefectureTypesQuery({
    variables: {
      search: {
        cityIds: cityIds,
        prefectureTypeIds: prefectureTypeIds,
      },
    },
  });

  const prefectureTypeNames = React.useMemo(() => {
    return prefectureTypes?.map((p) => p.name);
  }, [prefectureTypes]);

  const isSelected = (region: {prefectureTypes: string[]}) => {
    for (const prefectureType of region.prefectureTypes) {
      if (prefectureTypeNames?.includes(prefectureType)) return true;
    }

    return false;
  };

  return (
    <Container>
      <Title>エリア</Title>
      <MenuGroup>
        {regions.map((region) => (
          <Menu
            key={region.regionType}
            onClick={() => setCurrentRegion(region.regionType)}
            current={region.regionType === currentRegion ? 1 : 0}>
            {region.name}
            {isSelected(region) && (
              <SelectLabel>
                <Check />
              </SelectLabel>
            )}
          </Menu>
        ))}
      </MenuGroup>
    </Container>
  );
};

const Container = styled.div`
  width: 180px;
  height: 624px;
  border-right: solid 1px #e2e6ea;
  padding: 24px 0 24px 40px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
  color: #899098;
  margin-bottom: 10px;
`;

const MenuGroup = styled.div`
  overflow-y: scroll;
`;

const Menu = styled.div<{current?: number}>`
  &&& {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222426;
    white-space: pre;
    padding: 0 0 0 25px;
    height: 50px;
    background: ${(props) => (props.current ? '#E2E6EA;' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }

    svg {
      position: relative;
      top: 2px;
    }
  }
`;

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
`;
