import React from 'react';
import {Workflow, CallStep} from 'api';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => {
  const count = React.useMemo(
    () =>
      workflow.steps.reduce((acc, step) => {
        if (step.steppableType === 'Step::Call') {
          acc += (step.steppable as CallStep).calledCount;
        }
        return acc;
      }, 0),
    [workflow],
  );

  return (
    <>
      <span className="text-xs text-c-lighter">TEL</span>
      <span className="text-sm text-c-light">コール</span>
      <div className="text-xl font-bold">{(count || 0).toLocaleString()}</div>
    </>
  );
};
