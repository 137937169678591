import gql from 'graphql-tag';

export default gql`
  fragment projectSummaryFragment on Project {
    telPendingCount
    telCallCount
    telReceptionCount
    telContactCount
    mailCount
    mailStatusMailCount
    mailStatusSentCount
    mailStatusUnsentCount
    mailStatusNotfoundCount
    mailStatusCharoverCount
    mailStatusInvalidCount
    mailStatusShortageCount
    mailStatusRecruitCount
    mailStatusCannotacceptCount
    statusPendingCount
    statusApproachingCount
    statusAppointCount
    statusLeadCount
    statusWebinarCount
    statusDownloadCount
    statusInterviewCount
    statusRecycleleadCount
    statusInquiryCount
    statusRefusalCount
    statusBlockCount
    preleadStatusCountObjects {
      id
      count
    }
    blockGroups {
      ...blockGroupFragment
    }
    blockGroupLastAppliedAt
  }
`;
