import React from 'react';
import {useRecoilState} from 'recoil';
import checkedPreleadsAtom from '../checkedPreleadsAtom';
import {Prelead} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const CheckCell = ({row}: Props) => {
  const prelead: Prelead = row.original;
  const [checkedPreleads, setCheckedPreleads] = useRecoilState(
    checkedPreleadsAtom,
  );

  const checked = React.useMemo(() => checkedPreleads.includes(prelead.uuid), [
    checkedPreleads,
  ]);

  const checkPreleadProject = React.useCallback(() => {
    if (checkedPreleads.includes(prelead.uuid)) {
      setCheckedPreleads(checkedPreleads.filter((id) => id !== prelead.uuid));
    } else {
      setCheckedPreleads([...checkedPreleads, prelead.uuid]);
    }
  }, [checkedPreleads]);

  return (
    <input type="checkbox" checked={checked} onChange={checkPreleadProject} />
  );
};

export default CheckCell;
