import gql from 'graphql-tag';
import {
  callNgReasonFragment,
  contactNgReasonFragment,
  receptionNgReasonFragment,
  telStatusFragment,
} from '..';

export default gql`
  fragment miitelCallPreleadActivityFragment on MiitelCallPreleadActivity {
    id
    miitelSequenceId
    body
    bodyPlainText
    clientTelStatus {
      ...telStatusFragment
    }
    callNgReason {
      ...callNgReasonFragment
    }
    receptionNgReason {
      ...receptionNgReasonFragment
    }
    contactNgReason {
      ...contactNgReasonFragment
    }
  }

  ${telStatusFragment}
  ${callNgReasonFragment}
  ${receptionNgReasonFragment}
  ${contactNgReasonFragment}
`;
