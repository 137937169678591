import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import MARKETS from 'helpers/markets';

const ListingMarketsFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      mode="multiple"
      allowClear
      value={query.listingMarkets || []}
      onChange={(value: Array<string>) => setQuery({listingMarkets: value})}>
      {MARKETS.map((market) => (
        <Select.Option value={market.value} key={market.value}>
          {market.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ListingMarketsFilter;
