import React from 'react';
import moment from 'moment';
import Salesforce from '../Main/Salesforce';
import HubSpot from '../Main/HubSpot';
import {ProspectPool} from 'api';
import {Link, useParams} from 'react-router-dom';

interface Props {
  prospectPool: ProspectPool;
}

const ProspectInfo = ({prospectPool}: Props) => {
  const {poolId} = useParams<{
    workflowId: string;
    poolId: string;
  }>();

  return (
    <>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">名前</div>
        <div className="w-3/4 flex items-center gap-2">
          {prospectPool?.prospect && (
            <Link
              target="_blank"
              to={`/pools/${poolId}/prospects/${prospectPool?.uuid}/timeline`}>
              {[
                prospectPool?.prospect?.lastName,
                prospectPool?.prospect?.firstName,
              ]
                .filter((v) => !!v)
                .join(' ')}
            </Link>
          )}
          {prospectPool?.salesforceContactId && (
            <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a]">
              <Salesforce />
              取引先責任者
            </span>
          )}
          {prospectPool?.salesforceLeadId && (
            <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a]">
              <Salesforce />
              リード
            </span>
          )}
          {prospectPool.hubspotContact && (
            <span className="px-2 h-5 text-xs flex items-center gap-1 rounded-sm text-[#ff7a59] bg-[#ff7a594d]">
              <HubSpot />
              コンタクト
            </span>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">会社名</div>
        <div className="w-3/4">{prospectPool.prospect.account.name}</div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">URL</div>
        <div className="w-3/4">
          <a href={prospectPool.prospect.account.webSite} target="_blank">
            {prospectPool.prospect.account.webSite}
          </a>
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">メールアドレス</div>
        <div className="w-3/4">{prospectPool.prospect.email}</div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">電話番号</div>
        <div className="w-3/4">{prospectPool.prospect.telephoneNumber}</div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">リードソース</div>
        <div className="w-3/4 flex items-center gap-8 text-sm">
          {prospectPool.leadSource?.name && (
            <div className="flex items-center gap-2">
              最新
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {prospectPool.leadSource?.name}
              </span>
            </div>
          )}
          {prospectPool.originalLeadSource?.name && (
            <div className="flex items-center gap-2 text-sm">
              初回
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {prospectPool.originalLeadSource?.name}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/4 text-c-light">流入日</div>
        <div className="w-3/4 flex items-center gap-8 text-sm">
          {prospectPool.latestInflowDate && (
            <div className="flex items-center gap-2">
              最新
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {moment(prospectPool.latestInflowDate).format('YYYY/MM/DD')}
              </span>
            </div>
          )}
          {prospectPool.originalInflowDate && (
            <div className="flex items-center gap-2 text-sm">
              初回
              <span className="h-5 leading-5 text-xs px-2 bg-c-bg">
                {moment(prospectPool.originalInflowDate).format('YYYY/MM/DD')}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProspectInfo;
