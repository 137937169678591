import React from 'react';
import {Breadcrumb} from './Breadcrumb';
import {Tabs} from './Tabs';
import {Search} from './Search';
import {ReportSearchType} from './Search/types';

type TabProps = {
  label: string;
  to: string;
};

interface Props {
  title: string;
  tabs: TabProps[];
  children: React.ReactNode;
  search?: Array<ReportSearchType>;
}

export const Layout: React.FC<Props> = ({title, tabs, children, search}) => (
  <div className="p-8 bg-c-bg h-[calc(100vh-60px)] overflow-scroll">
    <Breadcrumb title={title} />
    <h1 className="my-4 text-xl font-bold">{title}</h1>
    <div className="w-full border border-c-border rounded">
      <Tabs tabs={tabs} />
      <Search search={search} />
      <div className="p-8 bg-white">{children}</div>
    </div>
  </div>
);
