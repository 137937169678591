import React from 'react';
import {ProspectPool} from 'api';
import Deal from './Deal';
import Company from './Company';
import Contact from './Contact';

interface Props {
  prospectPool: ProspectPool;
}

const Tab = ({
  label,
  active,
  onClick,
}: {
  label: string;
  active: boolean;
  onClick: () => void;
}) => (
  <div
    onClick={onClick}
    className={`w-full flex items-center justify-center h-10 cursor-pointer [&:nth-child(n+2)]:border-l border-c-border ${
      active ? 'bg-none' : 'bg-c-bg-light border-b border-c-border'
    }`}>
    <span
      className={`block text-c-base text-sm leading-4 ${
        active ? 'font-bold' : 'font-medium'
      }`}>
      {label}
    </span>
  </div>
);

export default ({prospectPool}: Props) => {
  const [active, setActive] = React.useState('deal');

  return (
    <>
      <div className="mb-8 overflow-y-scroll max-h-[50vh] flex flex-col">
        <div className="grid grid-cols-3 mb-4 border-t border-x border-c-border rounded-t">
          <Tab
            label="取引"
            active={active === 'deal'}
            onClick={() => setActive('deal')}
          />
          <Tab
            label="会社"
            active={active === 'company'}
            onClick={() => setActive('company')}
          />
          <Tab
            label="コンタクト"
            active={active === 'contact'}
            onClick={() => setActive('contact')}
          />
        </div>
        <div className="flex-1 overflow-scroll">
          {active === 'deal' && <Deal prospectPool={prospectPool} />}
          {active === 'company' && <Company prospectPool={prospectPool} />}
          {active === 'contact' && <Contact prospectPool={prospectPool} />}
        </div>
      </div>
    </>
  );
};
