import React from 'react';
import {Step, TaskStep} from 'api';

interface TaskStepType extends Step {
  steppable: TaskStep;
}

interface Props {
  step: TaskStepType;
}

const TaskStats = ({step}: Props) => {
  return (
    <ul className="flex gap-2 justify-evenly">
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-2 w-16">
        <span className="text-xs">商談</span>
        <span className="text-base font-bold leading-none">
          {(step.dealsCount || 0).toLocaleString()}
        </span>
      </li>
      <li className="border-r border-c-border" />
    </ul>
  );
};

export default TaskStats;
