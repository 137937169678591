import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Select} from 'components/antd';
import {
  usePreleadProjectQuery,
  useAssignUserPreleadProjectMutation,
  useProjectGroupByProjectUuidQuery,
} from 'api';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const {data: {preleadProject = {}} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    skip: !preleadProjectId,
  });

  const {data: {projectGroupByProjectUuid: projectGroup = null} = {}} =
    useProjectGroupByProjectUuidQuery({
      variables: {uuid: preleadProject?.project?.uuid},
      skip: !preleadProject?.project?.uuid,
    });

  const [assignUser] = useAssignUserPreleadProjectMutation();

  return (
    <Container>
      <Select
        value={preleadProject?.user?.uuid || ''}
        showSearch
        optionFilterProp="children"
        onChange={(value) =>
          assignUser({variables: {uuid: preleadProjectId, userUuid: value}})
        }>
        <Select.Option value="">担当者なし</Select.Option>
        {projectGroup?.users.map((user) => (
          <Select.Option value={user.uuid} key={user.uuid}>
            {user.lastName} {user.firstName}
          </Select.Option>
        ))}
      </Select>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 200px;

  .ant-select {
    width: 100%;
    margin-top: -7px;
  }
`;
