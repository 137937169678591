import gql from 'graphql-tag';

export default gql`
  fragment companyFragment on Company {
    id
    uuid
    status
    statusText
    prefectureId
    cityId
    name
    address
    webSite
    contactUrl
    tel
    fax
    zipCode
    corpNumber
    telephoneNumber
    officesNumber
    employeeNumber
    employeeRange
    faxNumber
    listingMarketText
    updatedAt
    abstract
    averageAge
    averageSalary
    bstatus
    capitalFund
    categoryMain
    categorySub
    companyType
    contactInfo
    domain
    accountClosingMonth
    establishedYearMonth
    headline
    hiragana
    image
    jobListingsLastPublishedAt
    keyword
    listingMarket
    mailAddress
    prLastPublishedAt
    prtimesUrl
    publishedAt
    representativePerson
    sales
    salesRange
    shortName
    subdomain
    checkedMemo
    facebookUrls
    instagramUrls
    youtubeUrls
    twitterUrls
    lineUrls
    linkedinUrls
    officesDomesticBranchesNumber
    officesDomesticFactoriesNumber
    officesDomesticStoresNumber
    officesForeignBranchesNumber
    officesForeignFactoriesNumber
    officesForeignStoresNumber
    checkedCount
    shouldUpdateAddress
    sameNameCount
    isUnsubscribed
  }
`;
