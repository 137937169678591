import gql from 'graphql-tag';
import zoomPhoneWebhookCallFragment from '../zoomPhone/webhookCall';
import zoomPhoneWebhookEventRecordingFragment from '../zoomPhone/webhookEventRecording';
import {prospectTelStatusFragment} from '..';

export default gql`
  fragment zoomPhoneWebhookCallProspectActivityFragment on ZoomPhoneWebhookCallProspectActivity {
    id
    telStatus
    telStatusText
    stage
    stageText
    recordedAt
    comment
    commentHtml
    zoomPhoneWebhookCall {
      ...zoomPhoneWebhookCallFragment
      recordings {
        ...zoomPhoneWebhookEventRecordingFragment
      }
    }
    clientProspectTelStatus {
      ...prospectTelStatusFragment
    }
  }
  ${zoomPhoneWebhookCallFragment}
  ${zoomPhoneWebhookEventRecordingFragment}
`;
