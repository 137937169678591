import React from 'react';
import {Form, CollectedFormUrl} from './Condition';
import useCondition from '../useCondition'

const FormFilters = () => {
  const {numberOfFormFiltersInUse} = useCondition();

  if (numberOfFormFiltersInUse === 0) {
    return null;
  }

  return (
    <div className="p-6 pb-2 bg-white rounded flex items-start gap-4 border border-c-border">
      <h3 className="m-0 font-bold text-base w-20">フォーム</h3>
      <div className="flex-1 flex flex-col gap-4">
        <Form />
        <CollectedFormUrl />
      </div>
    </div>
  );
};

export default FormFilters;
