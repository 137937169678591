import React from 'react';
import {Approach} from 'api';
import {Row} from 'react-table';
import moment from 'moment';

interface Props {
  row: Row<Approach>;
}

const Cell = ({row}: Props) => {
  const approach = row.original;

  return (
    <div className="text-sm text-c-light">
      {approach.startDate && moment(approach.startDate).format('MM/DD')}
      {` `}-{` `}
      {approach.dueDate && moment(approach.dueDate).format('MM/DD')}
    </div>
  );
};

export default Cell;
