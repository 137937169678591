import React from 'react';
import {Link} from 'react-router-dom';
import {Route, Switch} from 'react-router-dom';
import styled from 'styled-components';
import {useParams} from 'react-router';
import Content from './Content';
import Versions from './versions';
import {useContentMailFormQuery} from 'api';
import {Header, Container, Breadcrumb} from 'components/Ui';

export default () => {
  const {id} = useParams<{id: string}>();

  const {data: {content = null} = {}} = useContentMailFormQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container page="garage">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to="/garage/contents">コンテンツ一覧</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="current">{content?.title}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Main>
        {content && (
          <Switch>
            <Route path="/garage/contents/:id/mail_form/versions/:version">
              <Versions content={content} />
            </Route>
            <Route path="/garage/contents/:id/mail_form">
              <Content content={content} />
            </Route>
          </Switch>
        )}
      </Main>
    </Container>
  );
};

const Main = styled.div`
  grid-area: main;
  padding: 32px;
  background-color: #f3f5f7;
  overflow: auto;
  height: calc(100vh - 130px);
  position: relative;
`;
