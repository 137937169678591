import React from 'react';
import styled from 'styled-components';
import {Tooltip} from 'components/antd';
import {Activity, PreleadProject} from 'api';

interface Props {
  row: any;
}

const commentActivityTypes = [
  'Activity::Comment',
  'Activity::TelComment',
  'Activity::TelStatusAndComment',
  'Activity::MailComment',
  'Activity::ResponseStatusAndComment',
];

const CommentCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const count = preleadProject.prelead?.activities?.filter(
    (activity: Activity) =>
      commentActivityTypes.includes(activity.resourceType) &&
      activity.preleadProjectId === preleadProject.id,
  ).length;

  if (!count) {
    return null;
  }

  return (
    <Tooltip placement="right" title="コメントがあります">
      <Cell>
        <svg
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 2.333v10.529c0 .297.36.446.569.236l2.57-2.57a.667.667 0 01.47-.195h7.058C12.403 10.333 13 9.736 13 9V2.333C13 1.597 12.403 1 11.667 1H2.333C1.597 1 1 1.597 1 2.333z"
            stroke="#495058"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {count}
      </Cell>
    </Tooltip>
  );
};

const Cell = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

export default CommentCell;
