import React, {Fragment} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Dialog, Transition} from '@headlessui/react';
import {prospectStages} from 'helpers/prospectStages';
import {Quill, QuillWrapper} from 'components/Ui';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {
  ProspectPool,
  ProspectActivity,
  useCurrentClientQuery,
  ZoomPhoneWebhookCallProspectActivity,
  useReupdateZoomPhoneWebhookCallProspectActivityMutation,
} from 'api';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  prospectPool: ProspectPool;
  prospectActivity: ZoomPhoneWebhookCallActivity;
}
interface ZoomPhoneWebhookCallActivity extends ProspectActivity {
  resource: ZoomPhoneWebhookCallProspectActivity;
}

const ReUpdateModal = ({
  isOpen,
  closeModal,
  prospectPool,
  prospectActivity,
}: Props) => {
  const {resource} = prospectActivity;

  const {data: {currentClient: {prospectTelStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  const currentStageValue = () => {
    return prospectStages.find((stage) => {
      return stage.name === prospectPool?.stage;
    });
  };

  const [reupdateZoomPhoneWebhookCallProspectActivity, {loading}] =
    useReupdateZoomPhoneWebhookCallProspectActivityMutation({
      onCompleted: () => closeModal(),
      refetchQueries: ['prospectPool'],
    });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientProspectTelStatusId: resource?.clientProspectTelStatus?.id || null,
      stage: currentStageValue()?.id,
      comment: resource?.comment,
      commentHtml: resource?.commentHtml,
    },
    validationSchema: Yup.object().shape({
      stage: Yup.number(),
      commentHtml: Yup.string(),
    }),
    onSubmit: ({...values}) =>
      reupdateZoomPhoneWebhookCallProspectActivity({
        variables: {
          resourceId: prospectActivity.resourceId,
          attributes: {
            comment: values.comment,
            commentHtml: values.commentHtml,
            stage: Number(values.stage),
            clientProspectTelStatusId: values.clientProspectTelStatusId,
          },
        },
      }),
  });

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (resource.commentHtml) {
      formik.setFieldValue('commentHtml', resource.commentHtml);
    } else {
      ref.current?.editor.setText(resource.comment);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-xl transform rounded bg-white text-left align-middle transition-all">
                <Dialog.Title
                  as="h4"
                  className="h-10 flex items-center px-4 bg-c-bg text-c-base">
                  ログの編集
                </Dialog.Title>
                <form className="p-4" onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-c-light text-sm font-bold mb-2">
                      ステップ
                    </label>
                    <div className="inline-block relative w-full">
                      <select
                        value={prospectActivity.action?.step?.name}
                        className="block appearance-none w-full bg-white border border-c-border px-4 py-2 pr-8 rounded leading-tight focus:outline-none text-sm disabled:bg-c-bg disabled:text-c-light"
                        disabled>
                        <option value={prospectActivity.action?.step?.name}>
                          {prospectActivity.action?.step?.name}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-6 grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        TELステータス
                      </label>
                      <div className="inline-block relative w-full">
                        <select
                          className="block form-select appearance-none w-full bg-white border border-c-border px-4 py-2 pr-8 rounded leading-tight focus:outline-none text-sm"
                          {...formik.getFieldProps(
                            'clientProspectTelStatusId',
                          )}>
                          <option value="">未選択</option>
                          {prospectTelStatuses.map((telStatus) => (
                            <option key={telStatus.id} value={telStatus.id}>
                              {telStatus.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        ステージ
                      </label>
                      <div className="inline-block relative w-full">
                        <select
                          className="block form-select appearance-none w-full bg-white border border-c-border px-4 py-2 pr-8 rounded leading-tight text-sm"
                          {...formik.getFieldProps('stage')}>
                          {prospectStages.map((stage) => (
                            <option key={stage.id} value={stage.id}>
                              {stage.displayName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6 grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        日時
                      </label>
                      <div className="inline-block relative w-full">
                        <input
                          type="text"
                          disabled
                          value={moment(prospectActivity.createdAt).format(
                            'YYYY-MM-DD HH:mm',
                          )}
                          className="block form-input appearance-none w-full bg-white border-c-border px-4 py-2 pr-8 rounded leading-tight focus:outline-none disabled:bg-c-bg disabled:text-c-light text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-c-light text-sm font-bold mb-2">
                        ネクストアクション日
                      </label>
                      <div className="inline-block relative w-full">
                        <input
                          type="date"
                          disabled
                          className="block form-input appearance-none w-full bg-white border-c-border px-4 py-2 rounded leading-tight focus:outline-none text-sm disabled:bg-c-bg disabled:text-c-light"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-16">
                    <QuillContainer>
                      <QuillWrapper
                        style={{
                          borderRight: 'none',
                          borderBottom: 'none',
                          borderLeft: 'none',
                          borderRadius: 0,
                        }}>
                        <Quill
                          placeholder="コメント"
                          theme="snow"
                          ref={ref}
                          value={formik.values.commentHtml}
                          onChange={(content, delta, source, editor) => {
                            formik.setFieldValue('commentHtml', content);
                            formik.setFieldValue('comment', editor.getText());
                          }}
                          modules={{
                            toolbar: [
                              {header: [1, 2, 3, false]},
                              'bold',
                              'italic',
                              'underline',
                              'code-block',
                              'link',
                            ],
                          }}
                          bounds={'#quill-container'}
                        />
                        <div id="quill-container" />
                      </QuillWrapper>
                    </QuillContainer>
                  </div>
                  <div className="mb-4 flex justify-end relative">
                    <button
                      type="submit"
                      disabled={!formik.isValid || loading}
                      className="inline-flex items-center justify-center h-10 px-6 font-medium text-white bg-c-primary rounded hover:opacity-75 cursor-pointer text-sm">
                      登録
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const QuillContainer = styled.div`
  .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
  }

  .ql-editor {
    max-height: 300px;
  }
`;

export default ReUpdateModal;
