import React from 'react';
import {
  usePoolProspectCustomizeItemsQuery,
  PoolProspectCustomizeItem,
} from 'api';
import useSearchParams from '../../../../useSearchParams';
import {Listbox} from '@headlessui/react';
import {ArrowDownIcon} from './Icon';

type CustomizeItemFilterItem = {
  customizeItemId: string;
  index: number;
};

export default ({customizeItemId, index}: CustomizeItemFilterItem) => {
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});

  const customizeItems = React.useMemo(() => {
    return poolProspectCustomizeItems.filter(
      (item) => item.dataType === 'select' || item.dataType === 'date',
    );
  }, [poolProspectCustomizeItems]);

  const {query, setQuery} = useSearchParams();

  const selectedItem = (id: string): PoolProspectCustomizeItem => {
    return poolProspectCustomizeItems.find(
      (poolProspectCustomizeItem) => poolProspectCustomizeItem.id === id,
    );
  };

  return (
    <div className="relative">
      <Listbox
        value={customizeItemId}
        onChange={(value) => {
          setQuery({
            ...query,
            prospectCustomizeItemSearchConditions: [
              ...query.prospectCustomizeItemSearchConditions.slice(0, index),
              {
                customize_item_id: value,
                data_type: selectedItem(value)?.dataType,
              },
              ...query.prospectCustomizeItemSearchConditions.slice(index + 1),
            ],
          });
        }}>
        <Listbox.Button className="relative px-3 text-left focus:outline-none flex items-center border rounded w-80 h-8 bg-white border-[#d9d9d9] justify-between truncate">
          <span>{selectedItem(customizeItemId)?.name}</span>
          <ArrowDownIcon />
        </Listbox.Button>
        <Listbox.Options className="absolute">
          {customizeItems.map((poolProspectCustomizeItem) => (
            <Listbox.Option
              className={({active}) =>
                `relative select-none py-2 px-3 cursor-pointer w-80 h-8 pr-10 truncate z-20 ${
                  active ? 'bg-gray-100' : 'bg-white'
                }`
              }
              key={poolProspectCustomizeItem.id}
              value={poolProspectCustomizeItem.id}>
              {poolProspectCustomizeItem.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
