import {Checkbox} from 'components/antd';
import {useCompanyQuery, useSearchCountQuery} from 'api';
import {Loading} from 'components/Icon';
import {Users} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import useSearchCondition from '../useSearchCondition';
import employeeRangeOptions from 'helpers/employeeRangeOptions';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryItem,
  Count,
} from './styles';

export default () => {
  const {query, setQuery} = useSearchCondition();
  const {companyId} = useParams<{companyId: string}>();

  const {data: {company = {}} = {}} = useCompanyQuery({
    variables: {uuid: companyId},
    skip: !companyId,
    onCompleted: () =>
      setQuery({
        employeeRanges: [company.employeeRange],
      }),
  });

  const {data: {searchCount = 0} = {}, loading} = useSearchCountQuery({
    variables: {
      search: {
        employeeRanges: query.employeeRanges,
      },
    },
    skip: !company,
  });

  const onCheck = (e: any) => {
    const checked = e.target.checked;
    if (checked) {
      setQuery({
        employeeRanges: [company.employeeRange],
      });
    } else {
      setQuery({
        employeeRanges: null,
      });
    }
  };

  if (!company.employeeNumber) return null;

  if (company.employeeNumber) {
    return (
      <Container>
        <Category>
          <CategoryTitle>
            <Users />
            従業員
          </CategoryTitle>

          <ChildCategory>
            <ChildCategoryItem>
              {
                employeeRangeOptions.find(
                  (employeeRangeOption) =>
                    employeeRangeOption.value === company.employeeRange,
                ).label
              }
            </ChildCategoryItem>
          </ChildCategory>
        </Category>

        <Count>
          <span>検索件数</span>
          {loading ? (
            <Loading />
          ) : (
            <em>{`${searchCount?.toLocaleString()}件`}</em>
          )}
          <Checkbox checked={!!query.employeeRanges} onChange={onCheck} />
        </Count>
      </Container>
    );
  }
};
