import theme from '../theme';

const themeColor = (path: string) => {
  const themeName: string = getThemeName(path);
  const themeColors: {[key: string]: string} = theme.themeColor;
  return themeColors[themeName];
};

const getThemeName = (path: string): string => {
  switch (path) {
    case path.startsWith('/prelead') && path: {
      return 'prelead';
    }
    case path.startsWith('/project') && path: {
      return 'project';
    }
    case path.startsWith('/report') && path: {
      return 'report';
    }
    case path.startsWith('/mail') && path: {
      return 'mail';
    }
    case path.startsWith('/block') && path: {
      return 'block';
    }
    case path.startsWith('/garage') && path: {
      return 'garage';
    }
    default: {
      return 'prelead';
    }
  }
};

export default themeColor;
