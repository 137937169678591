import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6668 12.5193C15.6946 13.0729 13.0002 13.9348 10.0002 12.6059C8.75016 11.9138 5.66683 10.8583 3.3335 12.5193M3.3335 18.277V2.49697C5.66683 0.835905 8.75016 1.89145 10.0002 2.58356C13.0002 3.91241 15.6946 3.05054 16.6668 2.49685V12.4633"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
