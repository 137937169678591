import gql from 'graphql-tag';
import automaticMailSendMailThreadFragment from './automaticMailSend';
import manualMailMailThreadFragment from './manualMail';
import manualMailActionMailThreadFragment from './manualMailAction';
import gmailImportMailThreadFragment from './gmailImport';

export default gql`
  fragment mailThreadFragment on MailThread {
    id
    uuid
    threadUpdatedAt
    updatedAt
    createdAt
    threadable {
      ... on AutomaticMailSendMailThread {
        ...automaticMailSendMailThreadFragment
      }
      ... on ManualMailMailThread {
        ...manualMailMailThreadFragment
      }
      ... on ManualMailActionMailThread {
        ...manualMailActionMailThreadFragment
      }
      ... on GmailImportMailThread {
        ...gmailImportMailThreadFragment
      }
    }
  }
  ${automaticMailSendMailThreadFragment}
  ${manualMailMailThreadFragment}
  ${manualMailActionMailThreadFragment}
  ${gmailImportMailThreadFragment}
`;
