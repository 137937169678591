import React from 'react';

export default () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19247 10.0043L5.50558 2.16941C5.90522 1.44353 7.09461 1.44353 7.49426 2.16941L11.8074 10.0043C12.1572 10.6399 11.624 11.375 10.813 11.375H2.18679C1.37587 11.375 0.842595 10.6399 1.19247 10.0043Z"
      stroke="#EB5757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 4.875V7.04167"
      stroke="#EB5757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 9.21881V9.20825"
      stroke="#EB5757"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
