import {
  useProjectQuery,
  useProjectTelStatusPreleadDailyReportsQuery,
} from 'api';
import {StatusEclipse} from 'components/Ui';
import {ArrowRight} from 'components/Ui/Icon';
import telCategoryColor from 'helpers/telCategoryColor';
import {telStatusCategoryHash} from 'helpers/telStatusCategoryHash';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import useSearchParams from '../useSearchParams';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query} = useSearchParams();
  const [displayCategories, setDisplayCategories] = React.useState([]);
  const ngTelStatusCategories = Object.keys(telStatusCategoryHash).filter(
    (category) => category.includes('ng'),
  );
  const {data: {projectTelStatusPreleadDailyReports = []} = {}} =
    useProjectTelStatusPreleadDailyReportsQuery({
      variables: {
        uuid: projectId,
        search: query,
      },
    });

  const {
    data: {
      project: {
        callNgReasons = [],
        receptionNgReasons = [],
        contactNgReasons = [],
      } = {},
    } = {},
  } = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const totalCount = (object: any): any => {
    const value = Object.values(object).reduce(
      (total: number, value: number) => total + value,
      0,
    );
    if (!value) return null;

    return value;
  };

  const toggle = React.useCallback(
    (category: string) => {
      if (displayCategories.includes(category)) {
        setDisplayCategories(
          displayCategories.filter(
            (displayCategory) => displayCategory != category,
          ),
        );
      } else {
        setDisplayCategories([...displayCategories, category]);
      }
    },
    [displayCategories],
  );

  const totalCategoryCountOfMonth = React.useCallback(
    (category: string) =>
      projectTelStatusPreleadDailyReports.reduce(
        (total: number, report: any) => total + totalCount(report[category]),
        0,
      ),
    [projectTelStatusPreleadDailyReports],
  );

  const totalAllCategoryCountOfMonth = React.useMemo(
    () =>
      ngTelStatusCategories.reduce(
        (total: number, category: string) =>
          total + totalCategoryCountOfMonth(_.camelCase(category)),
        0,
      ),
    [projectTelStatusPreleadDailyReports],
  );

  const totalAllCategoryCountOfDay = (report: any) =>
    ngTelStatusCategories.reduce(
      (total: number, category: string) =>
        total + totalCount(report[_.camelCase(category)]),
      0,
    );

  const totalStatusCountOfMonth = React.useCallback(
    (category: string, reasonId) =>
      projectTelStatusPreleadDailyReports.reduce(
        (total: number, report: any) =>
          report[category][reasonId]
            ? total + report[category][reasonId]
            : total,
        0,
      ),
    [projectTelStatusPreleadDailyReports],
  );

  if (projectTelStatusPreleadDailyReports.length < 1) return null;

  return (
    <Container>
      <div>
        <h2>NG理由別</h2>
      </div>

      <Table>
        <div className="column far-left">
          <div className="far-top">
            <span>
              {query.date
                ? moment(query.date).format('YYYY年MM月')
                : moment().format('YYYY年MM月')}
            </span>
            <span></span>
            <span className="total-count">
              <span>プレリード件数</span>
              <span>{totalAllCategoryCountOfMonth}</span>
            </span>
          </div>

          <div className="category openable" onClick={() => toggle('call_ng')}>
            <Open category={'call_ng'} displayCategories={displayCategories}>
              <ArrowRight />
            </Open>
            <StatusEclipse color={telCategoryColor('call_ng')} />
            <span>{telStatusCategoryHash['call_ng']}</span>
            <span>{totalCategoryCountOfMonth(_.camelCase('call_ng'))}</span>
          </div>
          {displayCategories.includes('call_ng') &&
            callNgReasons.map((ngReason) => (
              <div key={ngReason.id}>
                <span>{ngReason.reason}</span>
                <span>
                  {totalStatusCountOfMonth(_.camelCase('call_ng'), ngReason.id)}
                </span>
              </div>
            ))}

          <div
            className="category openable"
            onClick={() => toggle('reception_ng')}>
            <Open
              category={'reception_ng'}
              displayCategories={displayCategories}>
              <ArrowRight />
            </Open>
            <StatusEclipse color={telCategoryColor('reception_ng')} />
            <span>{telStatusCategoryHash['reception_ng']}</span>
            <span>
              {totalCategoryCountOfMonth(_.camelCase('reception_ng'))}
            </span>
          </div>
          {displayCategories.includes('reception_ng') &&
            receptionNgReasons.map((ngReason) => (
              <div key={ngReason.id}>
                <span>{ngReason.reason}</span>
                <span>
                  {totalStatusCountOfMonth(
                    _.camelCase('reception_ng'),
                    ngReason.id,
                  )}
                </span>
              </div>
            ))}

          <div
            className="category openable"
            onClick={() => toggle('contact_ng')}>
            <Open category={'contact_ng'} displayCategories={displayCategories}>
              <ArrowRight />
            </Open>
            <StatusEclipse color={telCategoryColor('contact_ng')} />
            <span>{telStatusCategoryHash['contact_ng']}</span>
            <span>{totalCategoryCountOfMonth(_.camelCase('contact_ng'))}</span>
          </div>
          {displayCategories.includes('contact_ng') &&
            contactNgReasons.map((ngReason) => (
              <div key={ngReason.id}>
                <span>{ngReason.reason}</span>
                <span>
                  {totalStatusCountOfMonth(
                    _.camelCase('contact_ng'),
                    ngReason.id,
                  )}
                </span>
              </div>
            ))}
        </div>

        {projectTelStatusPreleadDailyReports.map((report: any) => (
          <div
            className={
              'column ' +
              ((moment(report.countDate).weekday() === 5 ||
                moment(report.countDate).weekday() === 6) &&
                'weekend')
            }
            key={report.countDate}>
            <div
              className={
                'far-top ' +
                (moment(report.countDate).isSame(new Date(), 'day') && 'today')
              }>
              <span>{moment(report.countDate).format('DD')}</span>
              <span>{moment(report.countDate).format('ddd')}</span>
              <span>{totalAllCategoryCountOfDay(report)}</span>
            </div>

            <div className="category">
              <span>
                {report[_.camelCase('call_ng')] &&
                  totalCount(report[_.camelCase('call_ng')])}
              </span>
            </div>
            {displayCategories.includes('call_ng') &&
              callNgReasons.map((ngReason) => (
                <div key={ngReason.id}>
                  <span>
                    {report[_.camelCase('call_ng')] &&
                      report[_.camelCase('call_ng')][ngReason.id]}
                  </span>
                </div>
              ))}

            <div className="category">
              <span>
                {report[_.camelCase('reception_ng')] &&
                  totalCount(report[_.camelCase('reception_ng')])}
              </span>
            </div>
            {displayCategories.includes('reception_ng') &&
              receptionNgReasons.map((ngReason) => (
                <div key={ngReason.id}>
                  <span>
                    {report[_.camelCase('reception_ng')] &&
                      report[_.camelCase('reception_ng')][ngReason.id]}
                  </span>
                </div>
              ))}

            <div className="category">
              <span>
                {report[_.camelCase('contact_ng')] &&
                  totalCount(report[_.camelCase('contact_ng')])}
              </span>
            </div>
            {displayCategories.includes('contact_ng') &&
              contactNgReasons.map((ngReason) => (
                <div key={ngReason.id}>
                  <span>
                    {report[_.camelCase('contact_ng')] &&
                      report[_.camelCase('contact_ng')][ngReason.id]}
                  </span>
                </div>
              ))}
          </div>
        ))}
      </Table>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    > h2 {
      margin: auto auto auto 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: #495058;
    }

    > div {
      position: relative;
      margin: auto 0 auto auto;
      display: flex;
    }
  }
`;

const Table = styled.div`
  display: flex;
  border: 1px solid #e1e6eb;
  border-top: none;
  overflow: scroll;
  min-height: 150px;
  max-height: 600px;
  width: 969px;

  .column {
    display: flex;
    flex-direction: column;

    > div {
      width: 50px;
      min-height: 43px;
      border-top: 1px solid #e1e6eb;
      border-right: 1px solid #e1e6eb;

      display: flex;
      align-items: center;

      &:first-child {
        > span {
          margin: 0 auto;
        }

        &.far-top {
          top: 0;
          z-index: 3;
          position: sticky;
          min-height: 80px;
          background: #ffffff;
          border-bottom: 1px solid #e1e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          > span {
            margin: auto;
            height: 18px;
          }

          &.today {
            border-top: 2px solid #495058;

            span {
              color: #495058;
              font-weight: bold;
            }
          }
        }
      }

      &:nth-child(2) {
        border-top: none;
      }

      > span {
        margin: 0 9px 0 auto;
        color: #899098;
      }

      &.category {
        > span {
          color: #495058;
          font-weight: bold;
        }
      }
    }

    &:last-child {
      > div {
        border-right: none;
      }
    }

    &.far-left {
      left: 0;
      z-index: 10;
      position: sticky;
      background: #ffffff;

      > div {
        width: 200px;
        display: flex;
        align-items: center;
        background: #fff;
        padding: 0 13px 0 40px;

        &:first-child {
          padding: 0 13px;

          .total-count {
            margin: auto 0;
            display: flex;
            width: 100%;

            > span {
              &:first-child {
                margin-right: auto;
              }
              &:last-child {
                margin-left: auto;
                font-weight: bold;
                color: #495058;
              }
            }
          }
        }

        &.category {
          padding: 0 13px;

          svg {
            margin-right: 5px;
          }
          span {
            font-weight: bold;
          }
        }

        &.openable {
          cursor: pointer;
        }

        > span {
          margin: 0;
          font-size: 13px;

          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    &.weekend {
      background: #fbfcfd !important;

      .far-top {
        background: #fbfcfd !important;
      }
    }
  }
`;

const Open = styled.div<{category: string; displayCategories: any}>`
  display: flex;
  width: 13px;
  height: 13px;
  > svg {
    ${({category, displayCategories}) =>
      displayCategories.includes(category) && 'transform: rotate(90deg);'}

    width: 13px;
    height: 13px;
    fill: #bdc4cb;
    path {
      width: 13px;
      height: 13px;
    }
  }
`;
