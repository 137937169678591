import gql from 'graphql-tag';
import manualMailFragment from '../manualMail';

export default gql`
  fragment manualMailBounceProspectActivityFragment on ManualMailBounceProspectActivity {
    id
    manualMail {
      ...manualMailFragment
      action {
        step {
          name
        }
      }
    }
  }
  ${manualMailFragment}
`;
