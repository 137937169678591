import React from 'react';

const Icon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8329 16.9997L31.1663 10.3886V23.6108L22.8329 16.9997ZM22.8329 16.9997V10.3886C22.8329 8.56296 21.3406 7.08301 19.4997 7.08301H6.16634C4.3254 7.08301 2.83301 8.56296 2.83301 10.3886V23.6108C2.83301 25.4364 4.3254 26.9163 6.16634 26.9163H19.4997C21.3406 26.9163 22.8329 25.4364 22.8329 23.6108V16.9997Z"
      stroke="#899098"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
