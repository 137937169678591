import React from 'react';
import styled from 'styled-components';
import {Sort} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import SortPopover from './SortPopover';
import useSearchParams from '../useSearchParams';

export default () => {
  const {query} = useSearchParams();

  return (
    <SortPopoverButton
      content={<SortPopover />}
      placement="bottomLeft"
      trigger="click"
      className={query.sortOrder ? 'active' : ''}>
      <Sort color={query.sortOrder ? '#68b5fb' : '#BDC4CB'} />
      <span>並び替え</span>
    </SortPopoverButton>
  );
};

const SortPopoverButton = styled(Popover)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 17px;
    width: 27px;
    margin-right: 5px;
  }

  &.active {
    span {
      color: #68b5fb;
    }
  }
`;
