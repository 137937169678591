import React from 'react';
import styled from 'styled-components';
import {PreleadProject, useUsersByClientAllQuery} from 'api';

interface Props {
  row: any;
}

const UserCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {},
      },
    });

  const user = users.find((user) => user.id === preleadProject.userId);

  return <UserName>{user && `${user.lastName} ${user.firstName}`}</UserName>;
};
export default UserCell;

const UserName = styled.div`
  color: #222426;
  flex: 1;
`;
