import React from 'react';
import moment from 'moment';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

export default ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;

  return (
    <span>
      {preleadProject.mailUpdatedAt &&
        moment(preleadProject.mailUpdatedAt).format('YYYY年MM月DD日 HH時mm分')}
    </span>
  );
};
