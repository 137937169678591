import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#50E65F" />
      <g clipPath="url(#clip0_18633_180060)">
        <path
          d="M9.62793 8.73682L11.7731 8.82098C12.2249 8.8387 12.6259 9.14804 12.7991 9.61248L13.4334 11.3137C13.581 11.7095 13.5413 12.1612 13.3275 12.5178L12.5148 13.8734C12.996 14.6224 14.3047 16.3775 15.6904 17.4048L16.7237 16.7154C16.9863 16.5401 17.3008 16.4881 17.5979 16.5707L19.652 17.1418C20.1984 17.2937 20.5665 17.8617 20.5233 18.486L20.3915 20.3926C20.3452 21.0617 19.8401 21.5867 19.2405 21.5111C11.3265 20.5135 6.68561 8.7368 9.62793 8.73682Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18633_180060">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
