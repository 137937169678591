import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {ContentDocument} from 'components/Ui/Icon';
import {Upload} from 'components/antd';
import {useUploadContentDocumentMutation} from 'api';

export default () => {
  const history = useHistory();

  const [uploadDocument, {loading}] = useUploadContentDocumentMutation({
    onCompleted: ({uploadContentDocument: {content = {}}}) => {
      history.push(`/garage/contents/${content.uuid}/document`);
    },
  });

  return (
    <Container>
      <Upload
        accept=".pdf"
        showUploadList={false}
        disabled={loading}
        customRequest={() => false}
        onChange={(info) => {
          const file = info.file.originFileObj;
          uploadDocument({variables: {file}});
        }}>
        <ContentDocument color="#68B5FB" className="active" />
        <ContentDocument color="#899098" className="inactive" />
        <p>
          ドキュメント
          <br />
          {'　'}
        </p>
      </Upload>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-upload {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
