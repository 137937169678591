import {
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import useUserHeaders from './useUserHeaders';
import useUserSumReport from './useUserSumReport';
import useLeadSourceUserReports from './useLeadSourceUserReports';

const useTableData = () => {
  const userSumReport = useUserSumReport();
  const leadSourceUserReports = useLeadSourceUserReports();
  const headers: ReportTableHeader[] = useUserHeaders();

  const data: ReportTableData[] = [
    userSumReport,
    ...leadSourceUserReports,
  ].filter(Boolean);
  return {data, headers};
};

export default useTableData;
