import React from 'react';

function Icon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4507 2.03839H5.54443V19.9569H16.4507V2.03839Z"
        fill="#8966EF"
      />
      <path
        d="M5.54443 17.2314V19.9614H16.4553V5.11536L5.54443 17.2314Z"
        fill="#6D57C9"
      />
      <path
        d="M8.66221 3.98767H7.10547V5.54441H8.66221V3.98767Z"
        fill="#BFACF6"
      />
      <path
        d="M11.776 3.98767H10.2192V5.54441H11.776V3.98767Z"
        fill="#BFACF6"
      />
      <path
        d="M14.8937 3.98767H13.3369V5.54441H14.8937V3.98767Z"
        fill="#BFACF6"
      />
      <path
        d="M8.66221 7.10571H7.10547V8.66245H8.66221V7.10571Z"
        fill="#BFACF6"
      />
      <path
        d="M11.776 7.10571H10.2192V8.66245H11.776V7.10571Z"
        fill="#BFACF6"
      />
      <path
        d="M14.8937 7.10571H13.3369V8.66245H14.8937V7.10571Z"
        fill="#BFACF6"
      />
      <path
        d="M8.66221 10.2192H7.10547V11.776H8.66221V10.2192Z"
        fill="#BFACF6"
      />
      <path d="M11.776 10.2192H10.2192V11.776H11.776V10.2192Z" fill="#BFACF6" />
      <path
        d="M14.8937 10.2192H13.3369V11.776H14.8937V10.2192Z"
        fill="#BFACF6"
      />
      <path
        d="M8.66221 13.3373H7.10547V14.8941H8.66221V13.3373Z"
        fill="#BFACF6"
      />
      <path
        d="M11.776 13.3373H10.2192V14.8941H11.776V13.3373Z"
        fill="#BFACF6"
      />
      <path
        d="M14.8937 13.3373H13.3369V14.8941H14.8937V13.3373Z"
        fill="#BFACF6"
      />
      <path
        d="M13.3369 16.8434H8.66211V19.9614H13.3369V16.8434Z"
        fill="#BFACF6"
      />
      <path
        d="M11.3925 16.8434H10.6118V19.9614H11.3925V16.8434Z"
        fill="#8966EF"
      />
    </svg>
  );
}

export default Icon;
