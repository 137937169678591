import gql from 'graphql-tag';

export default gql`
  fragment salesforceDealFieldSelectOptionFragment on SalesforceDealFieldSelectOption {
    id
    isActive
    isDefault
    label
    value
  }
`;
