import React from 'react';
import styled from 'styled-components';
import {Button, Input, message} from 'components/antd';
import {useParams} from 'react-router';
import {
  useUpdateContentMutation,
  Content,
  ContentMailTemplateAttributes,
  useCurrentUserQuery,
  useSendContentTemplateTestMailMutation,
} from 'api';
import {FormikProps} from 'formik';
import useAutoSave from './useAutoSave';
import {Check, LoadingProcess} from 'components/Ui/Icon';

interface Props {
  content: Content;
  formik: FormikProps<ContentMailTemplateAttributes>;
}

export default ({formik, content}: Props) => {
  const {id} = useParams<{id: string}>();
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  if (!currentUser) return null;
  const [updateContent] = useUpdateContentMutation();
  const onChangeTitle = React.useCallback(
    (e: any) =>
      updateContent({variables: {id, attributes: {title: e.target.value}}}),
    [],
  );

  const onClickSendMail = React.useCallback(() => {
    confirm(
      `${currentUser.email}にテストメールを送信します。\nよろしいですか？`,
    ) && sendTestMail({variables: {attributes: formik.values}});
  }, [formik.values]);

  const [sendTestMail, {loading: sendTestMailLoading}] =
    useSendContentTemplateTestMailMutation({
      onCompleted: () => message.info('確認メールを送信しました'),
    });

  const {saving} = useAutoSave(content, formik);

  return (
    <Title>
      <Input
        size="large"
        defaultValue={content?.title}
        onBlur={onChangeTitle}
      />
      <Button
        loading={sendTestMailLoading}
        disabled={
          !formik.isValid ||
          formik.values.body.length <= 1 ||
          sendTestMailLoading
        }
        onClick={onClickSendMail}>
        テストメールを送信
      </Button>
      <SaveStatus>
        {saving ? (
          <>
            保存中...
            <LoadingProcess className="rotating" />
          </>
        ) : (
          formik.dirty && (
            <>
              自動保存しました <Check />
            </>
          )
        )}
      </SaveStatus>
    </Title>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;

  input {
    font-size: 18px;
    font-weight: bold;
    width: 50%;
    border: none;
    background: transparent;
    flex: 1;
  }

  .ant-input:focus {
    border-color: none !important;
    outline: 0 !important;
    box-shadow: none !important;
  }

  ul {
    list-style: none;
    overflow: hidden;
    font-weight: bold;
    font-size: 12px;
    color: #899098;

    li {
      width: 100px;
      text-align: center;
      float: left;
    }
  }
`;

const SaveStatus = styled.span`
  margin: 0 1rem;
  color: #899098;

  svg {
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .rotating {
    animation: rotating 2s linear infinite;
  }
`;
