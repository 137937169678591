import gql from 'graphql-tag';

export default gql`
  fragment teamFragment on Team {
    id
    uuid
    clientId
    name
  }
`;
