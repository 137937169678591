import React, {useState} from 'react';
import moment from 'moment';
import {TelBadge, Edit} from 'components/Ui/Icon';
import {Activity, ZoomPhoneWebhookCallActivity} from 'api';
import {Quill, QuillWrapper} from 'components/Ui';
import telColors from 'helpers/telColor';
import telCategoryColors from 'helpers/telCategoryColor';
import {useUpdateZoomPhoneWebhookCallActivityMutation} from 'api';
import useProjectName from '../useProjectName';
import Attachment from '../Attachment';
import UserName from '../UserName';
import {StatusLabel} from '../Styles';
import ZoomPhone from './ZoomPhone';
import linkifyHtml from 'linkify-html';

interface ActivityType extends Activity {
  resource: ZoomPhoneWebhookCallActivity;
}

interface Props {
  activity: ActivityType;
}

const TelActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  const [onEdit, setOnEdit] = useState(false);

  const {
    resource: {
      clientTelStatus,
      telStatus,
      telStatusText,
      callNgReason,
      receptionNgReason,
      contactNgReason,
    } = {},
  } = activity;

  const [comment, setComment] = React.useState(activity.resource.body);
  const [bodyPlainText, setBodyPlainText] = React.useState(
    activity.resource.bodyPlainText,
  );

  const [updateActivity] = useUpdateZoomPhoneWebhookCallActivityMutation({
    onCompleted: () => {
      setOnEdit(false);
    },
  });
  const updateComment = () => {
    updateActivity({
      variables: {
        resourceId: activity.resourceId,
        attributes: {body: comment, bodyPlainText: bodyPlainText},
      },
    });
  };

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <div className="ml-[-30px] relative border border-solid border-c-border rounded-t bg-white">
        {projectName && (
          <div className="px-4 py-2 text-sm text-c-light">{projectName}</div>
        )}
        <div className="mt-1 flex items-center w-full h-12 px-4">
          <div className="z-10 flex w-8 h-auto mr-4">
            <TelBadge />
          </div>
          <UserName activity={activity} />
          <StatusLabel
            color={
              clientTelStatus
                ? telCategoryColors(clientTelStatus.category)
                : telColors(telStatus)
            }>
            <span>{clientTelStatus?.name || telStatusText}</span>
            {(callNgReason || receptionNgReason || contactNgReason) && (
              <span>|</span>
            )}
            {callNgReason && <span>{callNgReason.reason}</span>}
            {receptionNgReason && <span>{receptionNgReason.reason}</span>}
            {contactNgReason && <span> {contactNgReason.reason}</span>}
          </StatusLabel>
          <span className="ml-auto">
            {moment(activity.createdAt).format('MM/DD HH:mm')}
          </span>
        </div>
        <div className="px-4 pb-4">
          {onEdit ? (
            <div className="my-2">
              <QuillWrapper>
                <Quill
                  placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
                  theme="snow"
                  value={comment}
                  onChange={(content, delta, source, editor) => {
                    setComment(content);
                    setBodyPlainText(editor.getText());
                  }}
                  modules={{
                    toolbar: [
                      [{header: [1, 2, 3, false]}],
                      ['bold', 'italic', 'underline'],
                      ['code-block'],
                      ['link'],
                    ],
                  }}
                  formats={[
                    'header',
                    'list',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'color',
                    'background',
                    'code-block',
                    'link',
                  ]}
                  bounds={`#quill-container-${activity.uuid}`}
                />
                <div id={`quill-container-${activity.uuid}`} />
                <div className="flex flex-row-reverse justify-start gap-2 mr-2">
                  <button
                    disabled={
                      !comment.replace(/<\/?[^>]+(>|$)/g, '') && !status
                    }
                    onClick={updateComment}
                    className="cursor-pointer bg-white border border-c-border text-sm hover:opacity-50 rounded-sm inline-flex items-center justify-center disabled:bg-c-bg h-8 px-2">
                    更新
                  </button>
                  <button
                    onClick={() => {
                      setComment(activity.resource.body);
                      setOnEdit(false);
                    }}
                    className="cursor-pointer bg-white border border-c-border text-sm hover:opacity-50 rounded-sm inline-flex items-center justify-center disabled:bg-c-bg h-8 px-2">
                    キャンセル
                  </button>
                </div>
                <div />
              </QuillWrapper>
            </div>
          ) : (
            <React.Fragment>
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: convertUrlToLink(activity.resource.body || ''),
                }}
              />
              {activity.activityAttachments.map((attachment) => (
                <Attachment key={attachment.uuid} attachment={attachment} />
              ))}
              記録日時: {moment(activity.createdAt).format('MM/DD HH:mm')}
              <div className="mt-5 flex">
                <Edit
                  onClick={() => setOnEdit(!onEdit)}
                  className="cursor-pointer"
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="border-t border-solid border-c-border">
          <ZoomPhone activity={activity} />
        </div>
      </div>
    </>
  );
};

export default TelActivityComponent;
