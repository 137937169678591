import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useWorkflowQuery} from 'api';
import {Button} from 'components/antd';
import DealField from './DealField';
import DealSelectOption from './DealSelectOption';
import Close from './Close';
import useClientUser from 'hooks/useClientUser';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  deal_field_id: string;
};

type Props = {
  salesforceDealSearchConditions: SelectSearchCondition[];
  setSalesforceDealSearchConditions: React.Dispatch<
    React.SetStateAction<SelectSearchCondition[]>
  >;
};

export default ({
  salesforceDealSearchConditions,
  setSalesforceDealSearchConditions,
}: Props) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <>
      <div className="flex mt-2">
        <Label>条件項目</Label>
        <div className="flex flex-col gap-1">
          {salesforceDealSearchConditions.map(
            (condition: SelectSearchCondition, index: number) => {
              return (
                <div className="flex" key={index}>
                  <DealField condition={condition} />
                  <DealSelectOption condition={condition} />
                  {isConditionUpdatable && isMember && (
                    <Close
                      salesforceDealSearchConditions={
                        salesforceDealSearchConditions
                      }
                      setSalesforceDealSearchConditions={
                        setSalesforceDealSearchConditions
                      }
                      index={index}
                    />
                  )}
                </div>
              );
            },
          )}
          <AddButton
            disabled={
              !searchCondition.salesforceDealRecordTypeId ||
              !isConditionUpdatable ||
              !isMember
            }
            onClick={() =>
              setSalesforceDealSearchConditions([
                ...salesforceDealSearchConditions,
                {} as SelectSearchCondition,
              ])
            }>
            + 条件を追加
          </AddButton>
        </div>
      </div>
    </>
  );
};

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: #495058;
  padding-right: 8px;
  width: 110px;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
  width: 110px;
`;
