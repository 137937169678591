import gql from 'graphql-tag';

export default gql`
  fragment projectDownloadFragment on ProjectDownload {
    id
    uuid
    file
    filename
    createdAt
  }
`;
