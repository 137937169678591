import gql from 'graphql-tag';

export default gql`
  fragment hubspotSettingFragment on HubspotSetting {
    id
    clientId
    hubspotAccountId
    apiKey
    pipeline
    stage
    isActive
    canImportRegularly
  }
`;
