import React from 'react';
import SlideIn from '../SlideIn';
import Back from '../Back';
import DisplaySetting from './DisplaySetting';
import Footer from '../Footer';

const DealSetting = () => {
  return (
    <SlideIn path="/deals/settings">
      <div className="flex h-full w-full flex-col">
        <div className="relative w-full flex-1 overflow-y-auto px-8 py-6">
          <Back label="Salesforce商談項目の設定" />
          <div className="mt-4 w-full rounded bg-white p-4">
            <DisplaySetting />
          </div>
        </div>
        <Footer />
      </div>
    </SlideIn>
  );
};

export default DealSetting;
