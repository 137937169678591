import {NumberParam, StringParam, useQueryParams} from 'use-query-params';

const PAGE_SIZE = 20;

const searchParamsDef = {
  page: NumberParam,
  searchWord: StringParam,
  accountGroupId: StringParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  const searchParams = {
    searchWord: query.searchWord,
    accountGroupId: query.accountGroupId,
  };

  const setPage = (page: number) => setQuery({page});

  let numberOfFiltersInUse = 0;
  if (query.accountGroupId) numberOfFiltersInUse++;

  return {
    query,
    setQuery,
    searchParams,
    page,
    perPage: PAGE_SIZE,
    setPage,
    numberOfFiltersInUse,
  };
};

export default useSearchParams;
