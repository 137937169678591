import React from 'react';
import {
  NumberParam,
  DelimitedArrayParam,
  withDefault,
  StringParam,
  useQueryParams,
} from 'use-query-params';

const PAGE_SIZE = 20;

const searchParamsDef = {
  assignees: DelimitedArrayParam,
  status: withDefault(StringParam, 'open'),
  types: withDefault(DelimitedArrayParam, []),
  page: NumberParam,
  workflowIds: DelimitedArrayParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  const searchParams = {
    assignees: query.assignees,
    status: query.status,
    types: query.types,
    workflowIds: query.workflowIds,
  };

  const setPage = (page: number) => setQuery({page});

  React.useEffect(() => {
    setQuery(query);
  }, []);

  let numberOfFiltersInUse = 0;
  if (query.assignees?.length > 0) numberOfFiltersInUse++;
  if (query.types?.length > 0) numberOfFiltersInUse++;
  if (query.workflowIds?.length > 0) numberOfFiltersInUse++;

  return {
    query,
    setQuery,
    page,
    perPage: PAGE_SIZE,
    setPage,
    searchParams,
    numberOfFiltersInUse,
  };
};

export default useSearchParams;
