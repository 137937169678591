import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {UserAddCircle} from 'components/Ui/Icon';
import {Activity, AssignUserActivity} from 'api';
import useProjectName from './useProjectName';
import UserName from './UserName';

interface AssignUser extends Activity {
  resource: AssignUserActivity;
}

interface Props {
  activity: AssignUser;
}

const AssignUserActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);
  const {resource: {user} = {}} = activity;

  return (
    <>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <UserAddCircle />
        </BadgeWrapper>
        <UserName activity={activity} />
        <StatusLabel color="#5887B2">
          <span> 担当者割り当て</span>
        </StatusLabel>
        <Assignee>
          {user ? `${user?.lastName} ${user?.firstName}` : '担当者なし'}
        </Assignee>
        <TimeStamp>
          {moment(activity.createdAt).format('MM/DD HH:mm')}
        </TimeStamp>
      </Summary>
    </>
  );
};

const Summary = styled.div`
  margin-left: -30px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 46px;
  position: relative;
`;

const Project = styled.div`
  margin-left: 33px;
  font-size: 10px;
  font-weight: 500;
  color: #899098;
  position: relative;
  top: 7px;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const StatusLabel = styled.span<{color: string}>`
  background-color: ${({color}) => color};
  border-radius: 20px;
  margin: 0 12px;
  padding: 4px 16px;
  color: white;
  font-size: 10px;
  height: 22px;

  > span:nth-child(2) {
    margin: 0 9px;
  }
`;

const Assignee = styled.span`
  margin-right: 12px;
`;
const TimeStamp = styled.span``;

export default AssignUserActivityComponent;
