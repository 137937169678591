import React from 'react';
import {HubspotDeal, HubspotDealPropertyDefinition} from 'api';
import PipelineProperty from './Pipeline';
import StageProperty from './Stage';
import OwnerProperty from './Owner';
import {Value} from './Value';

const PropertyComponents: {[key: string]: any} = {
  pipeline: PipelineProperty,
  dealstage: StageProperty,
  hubspot_owner_id: OwnerProperty,
};

interface Props {
  propertyDefinition: HubspotDealPropertyDefinition;
  hubspotDeal: HubspotDeal;
}

const Property = ({propertyDefinition, hubspotDeal}: Props) => {
  const PropertyComponent = PropertyComponents[propertyDefinition.name];
  if (PropertyComponent) return <PropertyComponent hubspotDeal={hubspotDeal} />;

  const value = hubspotDeal.properties[propertyDefinition.name];
  if (value === null || value === undefined) return null;

  return <Value propertyDefinition={propertyDefinition} value={value} />;
};

export default Property;
