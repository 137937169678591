import gql from 'graphql-tag';

export default gql`
  fragment googleSettingFragment on GoogleSetting {
    id
    googleClientId
    googleClientSecret
    isActive
  }
`;
