import React from 'react';

function Icon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 10V14"
        stroke="#8966EF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 6H4C3.44772 6 3 6.44772 3 7V17C3 17.5523 3.44772 18 4 18H17C17.5523 18 18 17.5523 18 17V7C18 6.44772 17.5523 6 17 6Z"
        stroke="#8966EF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2222 15L13 12H8L10.7778 9"
        stroke="#8966EF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
