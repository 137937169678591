import React from 'react';

export default () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.83333 9.16658V5.83323C4.83333 3.53204 6.69882 1.66656 9 1.66656C11.3012 1.66656 13.1667 3.53204 13.1667 5.83323V9.16658M3.16667 9.16667H14.8333C15.7538 9.16667 16.5 9.91286 16.5 10.8333V16.6667C16.5 17.5871 15.7538 18.3333 14.8333 18.3333H3.16667C2.24619 18.3333 1.5 17.5871 1.5 16.6667V10.8333C1.5 9.91286 2.24619 9.16667 3.16667 9.16667Z"
        stroke="#899098"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
