import React from 'react';
import {Prelead} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const MailCountCell = ({row}: Props) => {
  const prelead: Prelead = row.original;

  return <>{prelead.mailCount}</>;
};

export default MailCountCell;
