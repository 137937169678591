import React from 'react';
import styled from 'styled-components';
import {Action} from './Action';
import {Menu} from './Menu';

export default () => {
  return (
    <Container>
      <Menu />
      <Action />
    </Container>
  );
};

const Container = styled.div`
  border-bottom: 1px solid #e1e6eb;
  font-weight: bold;
  color: #bdc4cb;
  display: flex;
  flex-direction: column;
`;
