import React from 'react';

function Icon() {
  return (
    <svg
      width="64"
      height="42"
      viewBox="0 0 64 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.1485 6.50031C27.7485 7.05031 27.0485 8.40032 27.2485 10.0003C28.2985 17.2003 29.1985 22.6503 29.2985 22.2503C29.3985 21.9503 29.7985 18.6503 30.1985 14.8503C30.6485 11.0503 31.1985 7.50031 31.4985 7.00031C31.9485 6.15031 31.8985 6.00031 31.1485 6.05031C30.6485 6.05031 29.7485 6.25031 29.1485 6.50031Z"
        fill="#1B2F3A"
      />
      <path
        d="M34.2987 7.15026C33.1987 7.95026 32.6487 10.6503 32.2487 17.0003C31.9487 22.4003 31.9987 22.3503 34.9987 14.3003C36.0987 11.3003 37.3487 8.45026 37.7487 8.00026C38.4487 7.20026 38.4487 7.10026 37.3987 6.80026C35.7487 6.40026 35.2987 6.40026 34.2987 7.15026Z"
        fill="#1B2F3A"
      />
      <path
        d="M23.9989 7.90009C21.6489 9.05009 21.5989 10.2501 23.4989 14.9001C25.3989 19.6501 27.6489 23.8001 27.2989 22.0001C26.4989 17.8001 25.4489 9.20009 25.6489 8.20009C25.7489 7.55009 25.8489 7.00009 25.7989 7.00009C25.7989 7.00009 24.9489 7.40009 23.9989 7.90009Z"
        fill="#1B2F3A"
      />
      <path
        d="M39.6 9.09998C38.45 10.6 34.5 20.25 34.5 21.7C34.5 21.95 35.9 20.15 37.65 17.6C39.35 15.1 41.5 12.25 42.4 11.3C43.95 9.64998 44 9.54998 43.05 9.04998C41.75 8.29998 40.25 8.34998 39.6 9.09998Z"
        fill="#1B2F3A"
      />
      <path
        d="M17.7995 11.0004C16.3495 12.8004 16.4495 13.3004 18.9995 16.7504C21.1995 19.7004 25.9995 25.2004 25.9995 24.7504C25.9995 24.6004 25.3995 23.3004 24.6995 21.8504C22.4995 17.5504 19.9995 11.4504 19.9995 10.4504C19.9995 9.10036 19.2995 9.25036 17.7995 11.0004Z"
        fill="#1B2F3A"
      />
      <path
        d="M43.9004 12.85C42.7004 14.2 37.1504 21.9 36.2504 23.5C36.0504 23.9 36.9504 23.2 38.3004 21.9C41.7004 18.65 47.6004 14 48.4004 14C49.3504 14 49.1004 13.15 47.9004 12.3C46.3004 11.15 45.3504 11.3 43.9004 12.85Z"
        fill="#1B2F3A"
      />
      <path
        d="M13.6 14.7501C13 15.5001 12.5 16.5501 12.5 17.0001C12.5 18.1001 15.65 20.9501 20.85 24.5501C24.55 27.1001 24.7 27.1501 23.35 25.5001C17.95 18.8001 15.45 15.4501 15.1 14.5001L14.7 13.3501L13.6 14.7501Z"
        fill="#1B2F3A"
      />
      <path
        d="M45.9514 18.1001C40.8014 22.3001 38.0014 24.7001 38.2014 24.8501C38.2514 24.9501 41.1514 23.6501 44.6014 22.0001C48.0514 20.3501 51.3514 19.0001 51.9514 19.0001C52.5514 19.0001 53.0014 18.7001 53.0014 18.3501C53.0014 17.4501 50.9014 15.5001 49.9514 15.5001C49.5514 15.5001 47.7514 16.7001 45.9514 18.1001Z"
        fill="#1B2F3A"
      />
      <path
        d="M9.79807 20.1004C9.14807 22.6004 9.44807 23.4504 11.3981 24.4504C13.7981 25.6504 22.5981 29.0504 22.7981 28.8504C22.8981 28.7504 20.7481 27.1004 18.0981 25.1504C15.3981 23.1504 12.6481 21.0004 11.9481 20.3004C10.4481 18.7004 10.1981 18.7004 9.79807 20.1004Z"
        fill="#1B2F3A"
      />
      <path
        d="M45.1491 23.8503C41.7991 25.4503 39.0991 26.8003 39.1491 26.8503C39.2491 26.9003 40.1491 26.7503 41.2491 26.5003C45.1491 25.6003 54.0991 24.4503 54.8491 24.7003C55.4491 24.9503 55.5491 24.7503 55.2491 23.6503C54.3991 20.1503 52.9991 20.2003 45.1491 23.8503Z"
        fill="#1B2F3A"
      />
      <path
        d="M7.7002 25.7004C7.3002 27.4504 7.9002 29.0004 9.2502 29.5004C10.5002 29.9504 19.1002 31.0004 21.3502 30.9504C22.2502 30.9504 20.6502 30.1004 16.6002 28.5004C13.2002 27.2004 9.9002 25.7504 9.2502 25.3004C8.0502 24.5004 8.0002 24.5504 7.7002 25.7004Z"
        fill="#1B2F3A"
      />
      <path
        d="M46.25 27.5504C40.15 28.6504 33.5 30.4504 33.5 31.0004C33.5 31.8504 41.9 33.2504 49.8 33.8004L54.9 34.1504L55.75 32.8004C56.8 31.2004 56.5 28.1004 55.15 27.1504C54.05 26.3004 52.95 26.4004 46.25 27.5504Z"
        fill="#1B2F3A"
      />
      <path
        d="M7 31.6002C7 33.0002 8.1 35.4002 8.85 35.7002C9.5 35.9502 14.4 35.3502 21.25 34.2002C22.8 33.9002 23 33.7502 22.25 33.4502C21.7 33.2502 20.25 33.0502 19 33.0002C16.3 32.9502 9.1 31.8002 7.9 31.3002C7.25 31.0002 7 31.1002 7 31.6002Z"
        fill="#1B2F3A"
      />
    </svg>
  );
}

export default Icon;
