import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useWorkflowQuery,
  useHubspotDealPropertyDefinitionsQuery,
  HubspotDealPropertyDefinition,
  useUpdateWorkflowHubspotDealSelectSearchConditionMutation,
  useUpdateWorkflowHubspotDealMultiSelectSearchConditionMutation,
} from 'api';
import {Select} from 'components/antd';
import {SearchCondition} from './hubspotDealTypes';
import useClientUser from 'hooks/useClientUser';

const DealField: React.FC<{condition: SearchCondition}> = ({condition}) => {
  const {isMember} = useClientUser();
  const {poolId, workflowId} =
    useParams<{poolId: string; workflowId: string}>();

  const [updateWorkflowHubspotDealSelectSearchCondition] =
    useUpdateWorkflowHubspotDealSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const [updateWorkflowHubspotDealMultiSelectSearchCondition] =
    useUpdateWorkflowHubspotDealMultiSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const {data: {hubspotDealPropertyDefinitions = []} = {}} =
    useHubspotDealPropertyDefinitionsQuery({
      variables: {poolId: poolId},
    });

  const dealFieldOptions = () => {
    return hubspotDealPropertyDefinitions
      .filter(
        (item: HubspotDealPropertyDefinition) =>
          ['checkbox', 'select'].includes(item.fieldTypeString) &&
          item.propertyTypeString === 'enumeration' &&
          item.options.length !== 0,
      )
      .map((item: HubspotDealPropertyDefinition) => {
        return {
          value: item.id,
          label: item.label,
          fieldtype: item.fieldTypeString,
          propertyTypeString: item.propertyTypeString,
        };
      });
  };

  const alreadyAdded = (value: string): boolean => {
    if (!searchCondition?.hubspotDealSearchConditions) {
      return false;
    }
    const fields = searchCondition?.hubspotDealSearchConditions?.map(
      (item) => item.deal_property_definition_id,
    );
    return fields.includes(value);
  };

  const searchField = (value: string) => {
    const dealField = hubspotDealPropertyDefinitions.find(
      (item) => item.id === value,
    );
    return dealField;
  };

  const updateWorkflowHubspotDealSearchCondition = (value: string) => {
    const dealField = searchField(value);
    if (
      dealField.fieldTypeString === 'select' &&
      dealField.propertyTypeString === 'enumeration'
    ) {
      return updateWorkflowHubspotDealSelectSearchCondition({
        variables: {
          uuid: workflowId,
          attributes: {
            dealPropertyDefinitionId: value,
            dealPropertyDefinitionFieldType: searchField(value).fieldTypeString,
            dealPropertyDefinitionPropertyType:
              searchField(value).propertyTypeString,
            dealPropertyDefinitionSelectOptionId: null,
          },
        },
        refetchQueries: ['workflow'],
      });
    } else if (
      dealField.fieldTypeString === 'checkbox' &&
      dealField.propertyTypeString === 'enumeration'
    ) {
      return updateWorkflowHubspotDealMultiSelectSearchCondition({
        variables: {
          uuid: workflowId,
          attributes: {
            dealPropertyDefinitionId: value,
            dealPropertyDefinitionFieldType: searchField(value).fieldTypeString,
            dealPropertyDefinitionPropertyType:
              searchField(value).propertyTypeString,
            dealPropertyDefinitionCheckboxOptionIds: [],
          },
        },
        refetchQueries: ['workflow'],
      });
    }
  };

  return (
    <Select
      style={{width: 280}}
      className="mr-2"
      showSearch
      optionFilterProp="children"
      disabled={!isConditionUpdatable || !isMember}
      value={condition.deal_property_definition_id || ''}
      showArrow={false}
      onChange={(value: string) => {
        updateWorkflowHubspotDealSearchCondition(value);
      }}>
      {dealFieldOptions().map((option) => (
        <Select.Option
          key={option.value}
          value={option.value}
          hidden={alreadyAdded(option.value)}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DealField;
