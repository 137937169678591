import React from 'react';
import {Select} from 'antd';
import useSearchParams from '../../useSearchParams';
import {useProjectGroupsQuery} from 'api';

const ProjectGroupFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.projectGroupIds || []}
      filterOption
      optionFilterProp="label"
      onChange={(value) => setQuery({page: 1, projectGroupIds: value})}>
      {projectGroups.map((projectGroup) => (
        <Select.Option value={projectGroup.id} key={projectGroup.id} label={projectGroup.name}>
          {projectGroup.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProjectGroupFilter;
