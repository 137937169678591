import React from 'react';
import {
  useSalesforceDealFieldQuery,
  SalesforceDealFieldSelectOption,
} from 'api';

interface Props {
  dealFieldId: string;
  values?: string[];
}

export default ({dealFieldId, values}: Props) => {
  const {
    data: {
      salesforceDealField: {salesforceDealFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceDealFieldQuery({
    variables: {dealFieldId},
    skip: !dealFieldId,
  });

  const selectedOptions = (): SalesforceDealFieldSelectOption[] => {
    return salesforceDealFieldSelectOptions?.filter(
      (option) => values?.includes(option.id),
    );
  };

  if (selectedOptions()?.length === 0) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-wrap items-center gap-1">
      {selectedOptions().map((selectedOption) => {
        return (
          <span
            className="h-6 flex items-center justify-center text-sm border border-c-border rounded-sm bg-c-bg px-2"
            key={selectedOption.id}>
            {selectedOption.label}
          </span>
        );
      })}
    </div>
  );
};
