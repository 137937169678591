import React, {useState} from 'react';
import {TelBadge, Edit, Trash} from 'components/Ui/Icon';
import {Activity, TelStatusAndCommentActivity} from 'api';
import {Button} from 'components/antd';
import {Quill, QuillWrapper} from 'components/Ui';
import telColors from 'helpers/telColor';
import telCategoryColors from 'helpers/telCategoryColor';
import {
  useUpdateTelStatusAndCommentActivityMutation,
  useDestroyTelStatusAndCommentActivityMutation,
} from 'api';
import useProjectName from './useProjectName';
import {useLocation} from 'react-router';
import Attachment from './Attachment';
import UserName from './UserName';
import {
  Container,
  Project,
  Summary,
  BadgeWrapper,
  MenuButtonWrapper,
  StatusLabel,
  TimeStamp,
  EditorWrapper,
  CommentBody,
  CommentEditBox,
} from './Styles';
import {formatDateTime} from './formatDateTime';
import linkifyHtml from 'linkify-html';

interface TelStatusAndComment extends Activity {
  resource: TelStatusAndCommentActivity;
}

interface Props {
  activity: TelStatusAndComment;
}

const TelStatusAndCommentActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  const [onEdit, setOnEdit] = useState(false);

  const {
    resource: {
      clientTelStatus,
      telStatus,
      telStatusText,
      callNgReason,
      receptionNgReason,
      contactNgReason,
    } = {},
  } = activity;

  const [comment, setComment] = React.useState(activity.resource.body);
  const [bodyPlainText, setBodyPlainText] = React.useState(
    activity.resource.bodyPlainText,
  );

  const [updateTelStatusAndCommentActivity] =
    useUpdateTelStatusAndCommentActivityMutation({
      onCompleted: () => {
        setOnEdit(false);
      },
    });
  const updateComment = () => {
    updateTelStatusAndCommentActivity({
      variables: {
        resourceId: activity.resourceId,
        attributes: {body: comment, bodyPlainText: bodyPlainText},
      },
    });
  };

  const [destroy] = useDestroyTelStatusAndCommentActivityMutation();

  const [destroyMaster] = useDestroyTelStatusAndCommentActivityMutation({
    refetchQueries: ['preleads'],
  });

  const location = useLocation();

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <Container>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <TelBadge />
        </BadgeWrapper>
        <UserName activity={activity} />
        <StatusLabel
          color={
            clientTelStatus
              ? telCategoryColors(clientTelStatus.category)
              : telColors(telStatus)
          }>
          <span>{clientTelStatus?.name || telStatusText}</span>
          {(callNgReason || receptionNgReason || contactNgReason) && (
            <span>|</span>
          )}
          {callNgReason && <span>{callNgReason.reason}</span>}
          {receptionNgReason && <span>{receptionNgReason.reason}</span>}
          {contactNgReason && <span> {contactNgReason.reason}</span>}
        </StatusLabel>
        <TimeStamp>{formatDateTime(activity.createdAt)}</TimeStamp>
      </Summary>
      {onEdit ? (
        <EditorWrapper>
          <QuillWrapper>
            <Quill
              placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
              theme="snow"
              value={comment}
              onChange={(content, delta, source, editor) => {
                setComment(content);
                setBodyPlainText(editor.getText());
              }}
              modules={{
                toolbar: [
                  [{header: [1, 2, 3, false]}],
                  ['bold', 'italic', 'underline'],
                  ['code-block'],
                  ['link'],
                ],
              }}
              formats={[
                'header',
                'list',
                'bold',
                'italic',
                'underline',
                'strike',
                'color',
                'background',
                'code-block',
                'link',
              ]}
              bounds={`#quill-container-${activity.uuid}`}
            />
            <div id={`quill-container-${activity.uuid}`} />
            <MenuButtonWrapper>
              <Button
                disabled={!comment.replace(/<\/?[^>]+(>|$)/g, '') && !status}
                onClick={updateComment}>
                更新
              </Button>
              <Button
                onClick={() => {
                  setComment(activity.resource.body);
                  setOnEdit(false);
                }}>
                キャンセル
              </Button>
            </MenuButtonWrapper>
            <div />
          </QuillWrapper>
        </EditorWrapper>
      ) : (
        <React.Fragment>
          <CommentBody
            dangerouslySetInnerHTML={{
              __html: convertUrlToLink(activity.resource.body || ''),
            }}
          />
          {activity.activityAttachments.map((attachment) => (
            <Attachment key={attachment.uuid} attachment={attachment} />
          ))}
          記録日時: {formatDateTime(activity.resource.recordedAt)}
          <CommentEditBox>
            <div onClick={() => setOnEdit(!onEdit)}>
              <Edit />
            </div>
            {activity.preleadProjectId && (
              <div
                onClick={() => {
                  if (confirm('本当に削除しますか？')) {
                    if (location.pathname.indexOf('project') !== -1) {
                      destroy({
                        variables: {
                          uuid: activity.uuid,
                        },
                      });
                    } else {
                      destroyMaster({
                        variables: {
                          uuid: activity.uuid,
                        },
                      });
                    }
                  }
                }}>
                <Trash />
              </div>
            )}
          </CommentEditBox>
        </React.Fragment>
      )}
    </Container>
  );
};

export default TelStatusAndCommentActivityComponent;
