import React from 'react';
import {
  FormBlock,
  FormBlockFieldOther,
  useCreateFormBlockFieldOtherOptionMutation,
  useDestroyFormBlockFieldOtherOptionMutation,
} from 'api';
import {Popover, Transition} from '@headlessui/react';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;
  const [label, setLabel] = React.useState('');
  const [create] = useCreateFormBlockFieldOtherOptionMutation();
  const [destroy] = useDestroyFormBlockFieldOtherOptionMutation();

  const onAdd = () => {
    create({
      variables: {
        uuid: block.uuid,
        label: label,
      },
      refetchQueries: ['form'],
    });
    setLabel('');
  };

  const onDelete = (id: string) =>
    destroy({
      variables: {
        id,
      },
      refetchQueries: ['form'],
    });

  return (
    <div className="flex flex-col gap-2">
      {blockable.formBlockFieldOtherOptions.map(
        (option) =>
          option.status === 'open' && (
            <div
              key={option.id}
              className="flex items-center px-4 w-full h-10 border border-c-border rounded justify-between">
              {option.label}
              <button
                className="bg-transparent cursor-pointer"
                onClick={() => onDelete(option.id)}>
                X
              </button>
            </div>
          ),
      )}

      <Popover className="relative">
        <Popover.Button className="bg-transparent text-c-light cursor-pointer text-sm">
          + 選択肢を追加
        </Popover.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1">
          <Popover.Panel className="absolute left-0 z-10 mt-3 w-[360px] bg-white rounded-lg p-2 shadow-lg border-c-border border">
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={label}
                className="form-input rounded border border-c-border text-base flex-1"
                onChange={(e) => setLabel(e.target.value)}
              />
              <button
                disabled={!label}
                className="h-10 px-2 bg-c-primary rounded text-white disabled:bg-c-bg disabled:text-c-light"
                onClick={onAdd}>
                追加
              </button>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};
