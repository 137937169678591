import React from 'react';
import {useFormContext} from 'react-hook-form';

interface Option {
  value: string;
  label: string;
}

interface Props {
  title: string;
  options: Option[];
}

const CheckboxSection = ({title, options}: Props) => {
  const {setValue, watch} = useFormContext();
  const [checked, setChecked] = React.useState(false);

  const {fields} = watch();

  const toggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);

    const all = options.map((option) => option.value);

    const fieldValues = fields?.filter((v: string) => !all.includes(v));
    if (e.target.checked) {
      setValue('fields', fieldValues?.concat(all));
    } else {
      setValue('fields', fieldValues);
    }
  };

  React.useEffect(() => {
    const all = options.map((option) => option.value);

    if (all.every((v) => fields?.includes(v))) {
      setChecked(true);
    }
    if (all.every((v) => !fields?.includes(v))) {
      setChecked(false);
    }
  }, [fields]);

  return (
    <div className="flex flex-col gap-4">
      <label className="flex items-center w-[fit-content]">
        <input
          type="checkbox"
          checked={checked}
          className="form-checkbox h-5 w-5 rounded border-c-border"
          onChange={toggleAll}
        />
        <span className="ml-2 text-base">{title}</span>
      </label>
      <div className="grid grid-cols-3 gap-3 ml-6">
        {options.map((option) => (
          <label
            key={option.value}
            className="flex items-center w-[fit-content]">
            <input
              type="checkbox"
              checked={
                option.value === fields?.find((v: string) => v === option.value)
              }
              className="form-checkbox h-5 w-5 rounded border-c-border"
              onChange={(e) => {
                if (e.target.checked) {
                  setValue('fields', fields?.concat(option.value));
                } else {
                  setValue(
                    'fields',
                    fields?.filter((v: string) => v !== option.value),
                  );
                }
              }}
            />
            <span className="ml-2 text-sm">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default CheckboxSection;
