import gql from 'graphql-tag';
import {
  preleadProjectFragment,
  projectFragment,
  projectGroupFragment,
} from '..';

export default gql`
  fragment convertPreleadContactToProspectProspectActivityFragment on ConvertPreleadContactToProspectProspectActivity {
    preleadProject {
      ...preleadProjectFragment
      project {
        ...projectFragment
        projectGroup {
          ...projectGroupFragment
        }
      }
    }
  }
  ${preleadProjectFragment}
  ${projectFragment}
  ${projectGroupFragment}
`;
