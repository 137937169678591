import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useCurrentClientQuery} from 'api';

const StatusesFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {currentClient: {preleadTags = []} = {}} = {}} =
    useCurrentClientQuery({fetchPolicy: 'cache-and-network'});

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      optionFilterProp="title"
      value={query.tagIds || []}
      onChange={(value: Array<string>) => setQuery({tagIds: value})}>
      {preleadTags?.map((tag) => (
        <TagSelect.Option
          title={tag.name}
          value={tag.uuid}
          key={tag.uuid}
          label={
            <React.Fragment>
              <TagRectangle color={tag.color} />
              &nbsp; {tag.name}
            </React.Fragment>
          }>
          <TagRectangle color={tag.color} />
          &nbsp; {tag.name}
        </TagSelect.Option>
      ))}
    </Select>
  );
};

const TagSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

const TagRectangle = styled.span<{color?: string}>`
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${({color}) => (color ? `${color}` : 'white')};
`;

export default StatusesFilter;
