import React from 'react';
import {
  HubspotDealPropertyDefinition,
  HubspotPropertyDefinitionOption,
} from 'api';

interface Props {
  propertyDefinition: HubspotDealPropertyDefinition;
  handleUpdate: Function;
}

export const Select: React.FC<Props> = ({propertyDefinition, handleUpdate}) => {
  const options = propertyDefinition.options || [];

  if (options.length === 0) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 gap-6 mb-4">
      <label className="block">
        <span>{propertyDefinition.labelText}</span>
        <select
          className="form-select text-sm block w-full mt-1 rounded border-c-border shadow-sm"
          onChange={(e) => {
            const value = e.target.value;
            handleUpdate(propertyDefinition.name, value);
          }}>
          <option value="">選択してください</option>
          {options.map((option: HubspotPropertyDefinitionOption, i: number) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};
