import React, {useState} from 'react';
import styled from 'styled-components';
import {
  useIntegratePreleadToHubspotMutation,
  useIntegratePreleadToSalesforceMutation,
  useIntegratePreleadToSensesMutation,
  usePreleadContactsQuery,
  usePreleadProjectQuery,
  useProjectWithClientQuery,
} from 'api';
import {
  ArrowDown,
  Hubspot,
  Refresh,
  Salesforce,
  Senses,
} from 'components/Ui/Icon';
import {message, Popover} from 'components/antd';
import {useParams} from 'react-router';

export default () => {
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();

  const [isHubspotPopoverVisible, setHubspotPopoverVisible] = useState(false);
  const [isSalesforcePopoverVisible, setSalesforcePopoverVisible] =
    useState(false);
  const [isSensesPopoverVisible, setSensesPopoverVisible] = useState(false);

  const {data: {preleadProject: {prelead = {}} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
    });

  const {data: {preleadContacts = []} = {}} = usePreleadContactsQuery({
    variables: {preleadUuid: prelead.uuid},
    skip: !prelead.uuid,
  });

  const {data: {project} = {}} = useProjectWithClientQuery({
    variables: {uuid: projectId},
  });

  const [integratePreleadToHubspot, {loading: loadingHubspot}] =
    useIntegratePreleadToHubspotMutation({
      refetchQueries: ['currentUser', 'preleadProject'],
      onCompleted: ({integratePreleadToHubspot: {success}}) => {
        if (success) {
          message.success('リード情報を同期しました。');
        } else {
          message.error('リード情報の同期に失敗しました。');
        }
      },
    });

  const [integratePreleadToSalesforce, {loading: loadingSalesforce}] =
    useIntegratePreleadToSalesforceMutation({
      refetchQueries: ['currentUser', 'preleadContacts', 'preleadProject'],
      onCompleted: ({integratePreleadToSalesforce: {success}}) => {
        if (success) {
          message.success('リード情報を同期しました。');
        } else {
          message.error('リード情報の同期に失敗しました。');
        }
      },
    });

  const [integratePreleadToSenses, {loading: loadingSenses}] =
    useIntegratePreleadToSensesMutation({
      refetchQueries: ['currentUser', 'preleadProject'],
      onCompleted: ({integratePreleadToSenses: {success}}) => {
        if (success) {
          message.success('リード情報を同期しました。');
        } else {
          message.error('リード情報の同期に失敗しました。');
        }
      },
    });

  const handleIntegrateHubspot = () => {
    if (!loadingHubspot) {
      integratePreleadToHubspot({
        variables: {
          preleadProjectUuid: preleadProjectId,
        },
      });
    }
    setHubspotPopoverVisible(false);
  };

  const handleIntegrateSalesforce = () => {
    if (!loadingSalesforce) {
      integratePreleadToSalesforce({
        variables: {
          preleadProjectUuid: preleadProjectId,
        },
      });
    }
    setSalesforcePopoverVisible(false);
  };

  const handleIntegrateSenses = () => {
    if (!loadingSenses) {
      integratePreleadToSenses({
        variables: {
          preleadProjectUuid: preleadProjectId,
        },
      });
    }
    setSensesPopoverVisible(false);
  };

  if (!project) return null;

  const {
    client = null,
    client: {
      hubspotSetting = null,
      salesforceSetting = null,
      sensesSetting = null,
    } = {},
  } = project;

  if (!client) return null;

  const sensesUrl = sensesSetting?.isSensesPartner
    ? 'https://senses-partner.mazrica.com/'
    : 'https://senses.mazrica.com/';

  return (
    <Container>
      {client.hubspotActive && (
        <HubspotLink active={!!prelead.hubspotDealId}>
          <Hubspot />
          {!!prelead.hubspotDealId && !!hubspotSetting.hubspotAccountId ? (
            <a
              href={`https://app.hubspot.com/contacts/${hubspotSetting.hubspotAccountId}/deal/${prelead.hubspotDealId}/`}
              target="_blank">
              HubSpot
            </a>
          ) : (
            <span>HubSpot</span>
          )}
          <Popover
            visible={isHubspotPopoverVisible}
            onVisibleChange={() =>
              setHubspotPopoverVisible(!isHubspotPopoverVisible)
            }
            content={
              <PopoverBox>
                <div>
                  <Refresh />
                  <span onClick={handleIntegrateHubspot}>
                    リード情報を同期する
                  </span>
                </div>
              </PopoverBox>
            }
            placement="bottomLeft"
            trigger="click">
            <ArrowDownWrapper active={!!prelead.hubspotDealId}>
              <ArrowDown />
            </ArrowDownWrapper>
          </Popover>
        </HubspotLink>
      )}

      {client.salesforceActive && (
        <SalesforceLink active={preleadContacts.length > 0}>
          <Salesforce />
          {preleadContacts.length > 0 && preleadContacts[0].salesforceLeadId ? (
            <a
              href={`${salesforceSetting.instanceUrl}/lightning/r/Lead/${preleadContacts[0].salesforceLeadId}/view`}
              target="_blank">
              Salesforce
            </a>
          ) : (
            <span>Salesforce</span>
          )}
          <Popover
            visible={isSalesforcePopoverVisible}
            onVisibleChange={() =>
              setSalesforcePopoverVisible(!isSalesforcePopoverVisible)
            }
            content={
              <PopoverBox>
                <div>
                  <Refresh />
                  <span onClick={handleIntegrateSalesforce}>
                    リード情報を同期する
                  </span>
                </div>
              </PopoverBox>
            }
            placement="bottomLeft"
            trigger="click">
            {preleadContacts.length > 0 && (
              <ArrowDownWrapper active={preleadContacts.length > 0}>
                <ArrowDown />
              </ArrowDownWrapper>
            )}
          </Popover>
        </SalesforceLink>
      )}

      {client.sensesActive && sensesSetting.sensesDealTypeId && (
        <SensesLink active={!!prelead.sensesDealId}>
          <Senses />
          {prelead.sensesDealId ? (
            <a
              href={`${sensesUrl}r/deals?id=${prelead.sensesDealId}&show=opportunity`}
              target="_blank">
              Senses
            </a>
          ) : (
            <span>Senses</span>
          )}
          <Popover
            visible={isSensesPopoverVisible}
            onVisibleChange={() =>
              setSensesPopoverVisible(!isSensesPopoverVisible)
            }
            content={
              <PopoverBox>
                <div>
                  <Refresh />
                  <span onClick={handleIntegrateSenses}>
                    リード情報を同期する
                  </span>
                </div>
              </PopoverBox>
            }
            placement="bottomLeft"
            trigger="click">
            <ArrowDownWrapper active={!!prelead.sensesDealId}>
              <ArrowDown />
            </ArrowDownWrapper>
          </Popover>
        </SensesLink>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IntegrationLink = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  height: 18px;
  border-radius: 100px;
  border: 1px solid #e2e6ea;

  a,
  span {
    margin-left: 4px;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
  }

  > svg {
    margin-left: 6px;
    width: 10px;
    height: 10px;
  }
`;

const ArrowDownWrapper = styled.div<{active?: boolean}>`
  svg {
    margin: auto 3px auto 2px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`;

const HubspotLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#FF7A59' : '#ffffff')};
  border: 1px solid #ff7a59;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
  }

  > svg {
    path {
      fill: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
    }
  }
  ${ArrowDownWrapper} {
    path {
      stroke: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
    }
  }
`;

const SensesLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#1A2F3A' : '#ffffff')};
  border: 1px solid #1a2f3a;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
  }

  > svg {
    path {
      fill: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
    }
  }

  ${ArrowDownWrapper} {
    path {
      stroke: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
    }
  }
`;

const SalesforceLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#68B5FB' : '#ffffff')};
  ${({active}) => active && 'cursor: pointer'};
  border: 1px solid #68b5fb;

  ${({active}) => !active && 'opacity: 0.4'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#68b5fb')};
  }

  > svg {
    path {
      fill: ${({active}) => (active ? '#ffffff' : '#68b5fb')};
    }
  }

  ${ArrowDownWrapper} {
    path {
      stroke: ${({active}) => (active ? '#ffffff' : '#68b5fb')};
    }
  }
`;

const PopoverBox = styled.div`
  display: flex;
  width: 174px;
  height: 44px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  div {
    margin: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
    }

    span {
      font-weight: 500;
      margin-left: 4px;
      font-size: 12px;
      line-height: 17px;
      color: #495058;

      &:hover {
        color: #000000;
      }
    }
  }
`;
