import React from 'react';
import useCondition from '../../../useCondition';
import Header from '../../Header';
import Footer from '../Footer';
import {useSalesforceLeadRecordTypesQuery} from 'api';
import ConditionField from './ConditionField';
import StatusField from './StatusField';
import OwnerField from './OwnerField';

const SalesforceLeadFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {salesforceLeadRecordTypes = []} = {}} =
    useSalesforceLeadRecordTypesQuery();

  const updateCondition = (params: Parameters<typeof setCondition>[0]) => {
    const searchCondition = {...condition, ...params};
    const hasSalesforceLead = Boolean(
      searchCondition.salesforceLeadRecordTypeId ||
        searchCondition.salesforceLeadFieldSelectStatusIds?.length > 0 ||
        searchCondition.salesforceLeadOwnerIds?.length > 0 ||
        searchCondition.salesforceLeadSearchConditions?.length > 0,
    );
    setCondition({
      ...params,
      hasSalesforceLead,
    });
  };

  return (
    <>
      <Header title="Salesforceリード" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">レコードタイプ</div>
            <select
              className="form-select border-c-border rounded text-base w-full"
              onChange={(e) =>
                updateCondition({
                  salesforceLeadRecordTypeId: e.target.value,
                  salesforceLeadFieldSelectStatusIds: [],
                  salesforceLeadSearchConditions: [],
                })
              }
              value={condition?.salesforceLeadRecordTypeId}>
              <option value="" />
              {salesforceLeadRecordTypes.map((recordType) => (
                <option value={recordType.id} key={recordType.id}>
                  {recordType.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">リード状況</div>
            <StatusField
              recordTypeId={condition?.salesforceLeadRecordTypeId}
              onChange={(ids: string[]) =>
                updateCondition({
                  salesforceLeadFieldSelectStatusIds: ids,
                })
              }
              values={condition?.salesforceLeadFieldSelectStatusIds || []}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">所有者</div>
            <OwnerField
              onChange={(ownerIds: string[]) =>
                updateCondition({
                  salesforceLeadOwnerIds: ownerIds,
                })
              }
              values={condition?.salesforceLeadOwnerIds || []}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">条件項目</div>
            {condition.salesforceLeadSearchConditions?.map(
              (searchCondition, index) => (
                <ConditionField
                  key={index}
                  searchCondition={searchCondition}
                  setSearchCondition={(searchCondition) => {
                    const searchConditions = [
                      ...condition.salesforceLeadSearchConditions,
                    ];
                    searchConditions[index] = searchCondition;
                    updateCondition({
                      salesforceLeadSearchConditions: searchConditions,
                    });
                  }}
                  onDelete={() => {
                    const searchConditions = [
                      ...condition.salesforceLeadSearchConditions,
                    ];
                    searchConditions.splice(index, 1);
                    updateCondition({
                      salesforceLeadSearchConditions: searchConditions,
                    });
                  }}
                  index={index}
                />
              ),
            )}
            <button
              className="cursor-pointer w-32 h-8 flex items-center justify-center rounded bg-white border border-c-border text-light disabled:bg-c-bg disabled:text-c-light disabled:cursor-not-allowed"
              disabled={!condition.salesforceLeadRecordTypeId}
              onClick={() =>
                updateCondition({
                  salesforceLeadSearchConditions: [
                    ...(condition.salesforceLeadSearchConditions || []),
                    {},
                  ],
                })
              }>
              + 条件を追加
            </button>
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setCondition({
            hasSalesforceLead: false,
            salesforceLeadRecordTypeId: '',
            salesforceLeadFieldSelectStatusIds: [],
            salesforceLeadOwnerIds: [],
            salesforceLeadSearchConditions: [],
          })
        }
      />
    </>
  );
};

export default SalesforceLeadFilter;
