import React from 'react';
import {ProspectPool} from 'api';
import ProspectTimelineItem from 'components/ProspectTimelineItem';
import New from './New';
import Edit from './Edit';
import {useLocation} from 'react-router-dom';
import {Refresh} from 'components/Ui/Icon';

interface Props {
  prospectPool: ProspectPool;
  loading: boolean;
  refetch: () => void;
}

export default ({prospectPool, loading, refetch}: Props) => {
  const [isCallNew, setIsCallNew] = React.useState(false);
  const [isCallEdit, setIsCallEdit] = React.useState(false);
  const [call, setCall] = React.useState(null);
  const location = useLocation<{
    isNew: boolean;
  }>();

  React.useEffect(() => {
    if (location.state?.isNew) {
      setIsCallNew(true);
    }
  }, [location.state]);

  const List = () => (
    <div>
      <div className="flex items-center justify-end gap-2">
        <button
          onClick={() => refetch()}
          disabled={loading}
          className="ant-btn">
          <Refresh spin={loading} />
        </button>
        <div className="ant-btn" onClick={() => setIsCallNew(true)}>
          ＋ TELのログを登録
        </div>
      </div>
      <div className="my-4">
        {prospectPool?.callActivities?.map((prospectActivity) => (
          <ProspectTimelineItem
            key={prospectActivity.uuid}
            prospectActivity={prospectActivity}
            setIsCallEdit={setIsCallEdit}
            setCall={setCall}
            prospectPool={prospectPool}
          />
        ))}
      </div>
    </div>
  );

  return isCallEdit ? (
    <Edit setIsCallEdit={setIsCallEdit} call={call} />
  ) : isCallNew ? (
    <New setIsCallNew={setIsCallNew} prospectPool={prospectPool} />
  ) : (
    <List />
  );
};
