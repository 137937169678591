import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectMailStatusCountReportsQuery} from 'api';
import {Table, ArrowDown} from 'components/Ui/Icon';
import Chart from './Chart';
import Detail from './Detail';

export default React.memo(() => {
  const [showTable, setShowTable] = React.useState(false);
  const {projectId} = useParams<{projectId: string}>();
  const {
    data: {
      projectMailStatusSummaryReport = {},
      projectMailStatusCountReports = [],
    } = {},
  } = useProjectMailStatusCountReportsQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'network-only',
  });

  const toggleTable = React.useCallback(
    () => setShowTable(!showTable),
    [showTable],
  );

  const mailCount = projectMailStatusSummaryReport?.mailCount || 0;
  const clickCount = projectMailStatusSummaryReport?.clickCount || 0;
  const clickRate = mailCount ? (clickCount / mailCount) * 100 : 0;

  return (
    <Container>
      <h2>
        フォーム送信内訳
        <div>
          <div>
            <em>
              {mailCount.toLocaleString()} <small>社</small>
            </em>
            <small>(送信済み)</small>
          </div>
          <div>
            <div>
              <em>{clickRate.toFixed(1)}</em> % |{' '}
              <span>
                {clickCount.toLocaleString()} <small>社</small>
              </span>
            </div>
            <small>(クリック率 | 社)</small>
          </div>
        </div>
      </h2>
      <Chart projectMailStatusSummary={projectMailStatusSummaryReport} />
      <ToggleButton open={showTable} onClick={toggleTable}>
        <Table />
        {showTable ? '表を閉じる' : '表を見る'}
        <ArrowDown />
      </ToggleButton>
      {showTable && (
        <Detail projectMailStatusCountReports={projectMailStatusCountReports} />
      )}
    </Container>
  );
});

const Container = styled.div`
  width: 560px;
  border: solid 1px #e1e6eb;
  border-radius: 4px;
  padding: 3rem;

  h2 {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    > div {
      font-size: 18px;
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > small {
          font-size: 10px;
          color: #899098;
        }
      }

      em {
        font-size: 30px;
        font-style: normal;

        small {
          font-size: 18px;
        }
      }

      span {
        small {
          font-size: 10px;
        }
      }
    }
  }

  canvas {
    margin: 1rem 2rem;
  }
`;

const ToggleButton = styled.span<{open: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg:first-child {
    margin-right: 4px;
  }

  svg:last-child {
    margin-left: 4px;
    width: 1rem;
    transform: rotate(${({open}) => (open ? '180deg' : 'none')});
  }
`;
