import React from 'react';

const Icon = ({color = '#BDC4CB'}) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9997 18.3333C15.7143 18.3333 19.5363 14.6024 19.5363 9.99999C19.5363 5.39762 15.7143 1.66666 10.9997 1.66666C6.28509 1.66666 2.46313 5.39762 2.46313 9.99999C2.46313 14.6024 6.28509 18.3333 10.9997 18.3333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.31689 10H19.5364"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9998 1.83493C13.112 3.93916 14.4144 6.82106 14.4144 9.99993C14.4144 13.1788 13.112 16.0607 10.9998 18.1648"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9997 1.83493C8.88745 3.93916 7.58508 6.82106 7.58508 9.99993C7.58508 13.1788 8.88745 16.0607 10.9997 18.1648"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
