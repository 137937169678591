import React from 'react';
import {useUpdateFormMutation, Form} from 'api';
import AutoreplySubjectPage from './AutoreplyMailSubject';
import AutoreplyFromAddressPage from './AutoreplyMailFromAddress';
import AutoreplyFromNamePage from './AutoreplyMailFromName';
import AutoreplyBodyPage from './AutoreplyMailBody';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [updateForm] = useUpdateFormMutation({
    refetchQueries: ['form'],
  });

  return (
    <div className="flex flex-col gap-4 w-full">
      <label className="flex items-center text-base w-[fit-content] cursor-pointer">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 rounded border-c-border cursor-pointer"
          checked={form.sendAutoreplyMail}
          onChange={(e) =>
            updateForm({
              variables: {
                uuid: form.uuid,
                attributes: {
                  sendAutoreplyMail: e.target.checked,
                },
              },
            })
          }
        />
        <span className="ml-2">自動返信メールON</span>
      </label>
      <div className="border rounded border-c-border bg-c-bg flex justify-center">
        <div className="bg-white w-[720px] flex flex-col gap-8 pt-8 px-16 pb-24">
          <AutoreplyFromAddressPage form={form} />
          <AutoreplyFromNamePage form={form} />
          <AutoreplySubjectPage form={form} />
          <AutoreplyBodyPage form={form} />
        </div>
      </div>
    </div>
  );
};
