import React from 'react';
import moment from 'moment';
import {ProspectActivity, HubspotCallProspectActivity} from 'api';
import {Call} from 'components/Ui/Icon';
import {Calendar, Hubspot} from 'components/Ui/Icon';
import linkifyHtml from 'linkify-html';

interface HubspotCallActivity extends ProspectActivity {
  resource: HubspotCallProspectActivity;
}

interface Props {
  prospectActivity: HubspotCallActivity;
}

export default ({prospectActivity}: Props) => {
  const {resource, user, action, createdAt} = prospectActivity;
  const {
    stageText,
    recordedAt,
    commentHtml,
    hubspotContactIdString,
    clientProspectTelStatus,
  } = resource;

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <Call />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-2 min-h-[32px]">
          {hubspotContactIdString && (
            <span className="bg-[#FFDBD5] h-5 px-2 text-xs text-[#FF7A59] rounded-sm flex items-center gap-1 [&>svg]:h-3 [&>svg]:w-auto">
              <Hubspot />
              コンタクト
            </span>
          )}
          <h3 className="flex-1 font-bold m-0">{action?.step?.name}</h3>
          <span className="text-c-light">
            {user && [user.lastName, user.firstName].join(' ')}
          </span>
          <time className="text-c-light">
            {moment(createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </div>
        <div className="border border-c-border rounded">
          {commentHtml && (
            <div className="m-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: convertUrlToLink(commentHtml),
                }}
              />
            </div>
          )}
          <div className="m-4 flex items-center gap-4">
            {clientProspectTelStatus?.name && (
              <span className="bg-[#e6f5ea] text-xs text-[#27ae60] h-5 px-4 rounded-sm">
                {clientProspectTelStatus?.name}
              </span>
            )}
            {stageText && (
              <span className="bg-[#dceeff] text-xs text-[#2d9cdb] h-5 px-4 rounded-sm">
                {stageText}
              </span>
            )}
            {recordedAt && (
              <span className="flex items-center relative gap-1 [&>svg]:h-[18px]">
                <Calendar />
                <span>
                  {recordedAt && moment(recordedAt).format('YYYY/MM/DD HH:mm')}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
