import React from 'react';
import {useUpdateTaskMutation, Task} from 'api';
import {DatePicker} from 'components/antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';

interface Props {
  task: Task;
}

export default ({task}: Props) => {
  const [updateTask] = useUpdateTaskMutation();

  return (
    <DatePicker
      showTime={{format: 'HH:00'}}
      value={task.remindAt ? moment(task.remindAt) : null}
      format="YYYY/MM/DD HH:00"
      locale={locale}
      onChange={(date) =>
        updateTask({
          variables: {uuid: task.uuid, attributes: {remindAt: date}},
        })
      }
    />
  );
};
