import React from 'react';

const Icon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2791 13.0486L15.532 2.81602C15.2333 1.70349 13.866 1.29362 13.0026 2.05778L11.2712 3.59014C9.34441 5.29537 7.04638 6.52946 4.55898 7.19467C2.47688 7.7515 1.24293 9.89374 1.80082 11.9718C2.35872 14.0499 4.50052 15.2893 6.58263 14.7325C9.07 14.0672 11.6784 13.9892 14.2013 14.5045L16.4684 14.9675C17.599 15.1984 18.5777 14.1611 18.2791 13.0486Z"
      stroke="#FF74A6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.43115 6.66666L9.58326 18.3333"
      stroke="#FF74A6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
