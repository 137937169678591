import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import {useWorkflowQuery, useUpdateWorkflowSearchConditionMutation} from 'api';
import {Checkbox} from 'components/antd';
import CategoryModal from './CategoryModal';
import AreaModal from './AreaModal';
import MARKETS from 'helpers/markets';
import {PlusSquare, Cross} from 'components/Ui/Icon';
import {InputNumber} from 'components';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();
  const {isMember} = useClientUser();

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subCategories:
        searchCondition?.subCategories.map((subCategory) => subCategory.id) ||
        [],
      listingMarkets: searchCondition?.listingMarkets || [],
      employeeNumber: searchCondition?.employeeNumber || {},
      capitalFund: searchCondition?.capitalFund || {},
      cities: searchCondition?.cities.map((city) => city.id) || [],
    },
    onSubmit: (values) =>
      updateWorkflowSearchCondition({
        variables: {uuid: workflowId, attributes: values},
      }),
  });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const [isCategoryModalVisible, setIsCategoryModalVisible] =
    React.useState(false);

  const [isAreaModalVisible, setIsAreaModalVisible] = React.useState(false);

  return (
    <Container>
      <Item>
        <Label>エリア</Label>
        <Wrapper>
          {searchCondition?.cities.map((city) => (
            <SubCategory key={city.id}>
              {city.name}
              {isConditionUpdatable && isMember && (
                <CrossIconWrapper
                  onClick={() => {
                    updateWorkflowSearchCondition({
                      variables: {
                        uuid: workflowId,
                        attributes: {
                          cities: formik.values.cities.filter(
                            (cityId) => cityId !== city.id,
                          ),
                        },
                      },
                    });
                  }}>
                  <Cross />
                </CrossIconWrapper>
              )}
            </SubCategory>
          ))}
          {isConditionUpdatable && isMember ? (
            <PlusIconWrapper
              onClick={() => setIsAreaModalVisible(!isAreaModalVisible)}>
              <PlusSquare />
            </PlusIconWrapper>
          ) : (
            <div />
          )}
        </Wrapper>
      </Item>

      <Item>
        <Label>業種</Label>
        <Wrapper>
          {searchCondition?.subCategories.map((subCategory) => (
            <SubCategory key={subCategory.id}>
              {subCategory.displayName}
              {isConditionUpdatable && isMember && (
                <CrossIconWrapper
                  onClick={() => {
                    updateWorkflowSearchCondition({
                      variables: {
                        uuid: workflowId,
                        attributes: {
                          subCategories: formik.values.subCategories.filter(
                            (subCategoryId) => subCategoryId !== subCategory.id,
                          ),
                        },
                      },
                    });
                  }}>
                  <Cross />
                </CrossIconWrapper>
              )}
            </SubCategory>
          ))}
          {isConditionUpdatable && isMember ? (
            <PlusIconWrapper
              onClick={() =>
                setIsCategoryModalVisible(!isCategoryModalVisible)
              }>
              <PlusSquare />
            </PlusIconWrapper>
          ) : (
            <div />
          )}
        </Wrapper>
      </Item>

      <Item>
        <Label>上場区分</Label>
        <CheckboxGroup
          value={formik.values.listingMarkets}
          disabled={!isConditionUpdatable || !isMember}
          onChange={(values: string[]) =>
            updateWorkflowSearchCondition({
              variables: {
                uuid: workflowId,
                attributes: {
                  listingMarkets: values,
                },
              },
            })
          }>
          {MARKETS.map((listingMarket) => (
            <Checkbox value={String(listingMarket.id)} key={listingMarket.id}>
              {listingMarket.name}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </Item>

      <Item>
        <Label style={{paddingTop: '6px'}}>従業員数</Label>
        <StyledInputNumberWrapper>
          <StyledInputNumber
            min={1}
            value={formik.values.employeeNumber?.from}
            disabled={!isConditionUpdatable || !isMember}
            onChange={(value) =>
              formik.setFieldValue('employeeNumber.from', value)
            }
            onBlur={() => formik.handleSubmit()}
          />
          <span style={{margin: '0 5px'}}>人 〜</span>
          <StyledInputNumber
            min={1}
            value={formik.values.employeeNumber?.to}
            disabled={!isConditionUpdatable || !isMember}
            onChange={(value) =>
              formik.setFieldValue('employeeNumber.to', value)
            }
            onBlur={() => formik.handleSubmit()}
          />
          <span style={{margin: '0 5px'}}>人</span>
        </StyledInputNumberWrapper>
      </Item>

      <Item>
        <Label style={{paddingTop: '6px'}}>資本金</Label>
        <StyledInputNumberWrapper>
          <StyledInputNumber
            min={1}
            value={formik.values.capitalFund?.from}
            disabled={!isConditionUpdatable || !isMember}
            onChange={(value) =>
              formik.setFieldValue('capitalFund.from', value)
            }
            onBlur={() => formik.handleSubmit()}
          />
          <span style={{margin: '0 5px'}}>万円 〜</span>
          <StyledInputNumber
            min={1}
            value={formik.values.capitalFund?.to}
            disabled={!isConditionUpdatable || !isMember}
            onChange={(value) => formik.setFieldValue('capitalFund.to', value)}
            onBlur={() => formik.handleSubmit()}
          />
          <span style={{margin: '0 5px'}}>万円</span>
        </StyledInputNumberWrapper>
      </Item>

      <CategoryModal
        isModalVisible={isCategoryModalVisible}
        setIsModalVisible={setIsCategoryModalVisible}
      />
      <AreaModal
        isModalVisible={isAreaModalVisible}
        setIsModalVisible={setIsAreaModalVisible}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 34px;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Label = styled.div`
  width: 115px;
  font-weight: 500;
  font-size: 14px;

  span {
    color: #ff7a59;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;

  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
`;

const Wrapper = styled.div`
  flex: 1;

  > div:nth-last-child(2) {
    margin-right: 13px;
  }
`;

const SubCategory = styled.div`
  display: inline-flex;
  background: #f3f5f7;
  border-radius: 2px;
  line-height: 27px;
  padding: 0 9px;
  margin: 0 3px 3px 0;
`;

const CrossIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 9px;

  svg {
    width: 6px;
    height: 6px;

    path {
      stroke-width: 3;
    }
  }
`;

const PlusIconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 3px;
`;

const StyledInputNumberWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInputNumber = styled(InputNumber)`
  border: 1px solid rgb(217, 217, 217) !important;
  width: 70px;
`;
