import React from 'react';
import styled from 'styled-components';
import {useActionsQuery, useActionsLazyQuery} from 'api';
import useSearchParams from '../../../views/client/pools/detail/action/actions/useSearchParams';
import {useHistory} from 'react-router-dom';
import ArrowLeftIcon from './ArrowLeftIcon';
import useProspectContext from '../useProspectContext'

export default () => {
  const histroy = useHistory();
  const {poolId} = useProspectContext();
  const {page, perPage, searchParams, setPage} = useSearchParams();
  const {data: {actions: {actions = [], pagination = {}} = {}} = {}} =
    useActionsQuery({
      variables: {
        uuid: poolId,
        search: searchParams,
        page,
        perPage,
      },
      fetchPolicy: 'cache-and-network',
    });
  const actionUuid = location.pathname.split('/actions/')[1].split('/')[0];
  const currentActionIndex = actions.findIndex(
    (action) => action.uuid === actionUuid,
  );

  const [getPreviousPageActions] = useActionsLazyQuery({
    variables: {
      uuid: poolId,
      search: searchParams,
      page: pagination.previousPage,
      perPage,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setPage(pagination.previousPage);
      const lastActionIndex = data.actions.actions.length - 1;
      histroy.push(
        `/pools/${poolId}/action/actions/${data.actions.actions[lastActionIndex].uuid}/prospect/timeline${location.search}`,
      );
    },
  });

  const goToBack = () => {
    if (currentActionIndex === 0 && pagination.hasPreviousPage) {
      getPreviousPageActions();
    } else if (currentActionIndex > 0) {
      histroy.push(
        `/pools/${poolId}/action/actions/${
          actions[currentActionIndex - 1].uuid
        }/prospect/timeline${location.search}`,
      );
    }
  };

  const [getNextPageActions] = useActionsLazyQuery({
    variables: {
      uuid: poolId,
      search: searchParams,
      page: pagination.nextPage,
      perPage,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setPage(pagination.nextPage);
      histroy.push(
        `/pools/${poolId}/action/actions/${data.actions.actions[0].uuid}/prospect/timeline${location.search}`,
      );
    },
  });

  const goToNext = () => {
    if (actions.length - 1 === currentActionIndex && pagination.hasNextPage) {
      getNextPageActions();
    } else if (currentActionIndex < actions.length - 1) {
      histroy.push(
        `/pools/${poolId}/action/actions/${
          actions[currentActionIndex + 1].uuid
        }/prospect/timeline${location.search}`,
      );
    }
  };

  return (
    <Container>
      {currentActionIndex > 0 || pagination.hasPreviousPage ? (
        <IconWrapper onClick={goToBack}>
          <ArrowLeftIcon />
        </IconWrapper>
      ) : (
        <Space />
      )}
      <div>
        {(
          perPage * (pagination.currentPage - 1) +
          currentActionIndex +
          1
        ).toLocaleString()}{' '}
        / {pagination.totalCount?.toLocaleString()}
      </div>
      {currentActionIndex < actions.length - 1 || pagination.hasNextPage ? (
        <IconWrapper onClick={goToNext}>
          <ArrowLeftIcon />
        </IconWrapper>
      ) : (
        <Space />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  height: 26px;

  &:last-child {
    svg {
      margin-left: auto;
      transform: rotate(180deg);
    }
  }

  &:hover {
    svg {
      circle {
        fill: #68b5fb;
      }
      path {
        stroke: #fff;
      }
    }
  }
`;

const Space = styled.div`
  width: 26px;
`;
