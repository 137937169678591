import React from 'react';
import useCondition from '../../../useCondition';
import {usePoolProspectCustomizeItemsQuery} from 'api';
import {XMarkIcon} from '@heroicons/react/20/solid';
import Select from './Select';

type CustomizeItemSelectSearchConditionType = {
  customize_item_id?: string;
  data_type?: string;
  values?: string[];
};

type CustomizeItemDateSearchConditionType = {
  customize_item_id?: string;
  data_type?: string;
  from?: string;
  to?: string;
};

type CustomizeItemSearchConditionType =
  | CustomizeItemSelectSearchConditionType
  | CustomizeItemDateSearchConditionType;

interface Props {
  searchCondition: CustomizeItemSearchConditionType;
  setSearchCondition: (condition: CustomizeItemSearchConditionType) => void;
  onDelete: () => void;
}

const CustomizeItemField = ({
  searchCondition,
  setSearchCondition,
  onDelete,
}: Props) => {
  const {condition} = useCondition();

  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});

  const customizeItems = poolProspectCustomizeItems.filter(
    (item) =>
      (item.dataType === 'select' || item.dataType === 'date') &&
      item.isDisplay,
  );

  const filteredCustomizeItems = customizeItems.filter(
    (item) =>
      item.id === searchCondition.customize_item_id ||
      !condition.prospectCustomizeItemSearchConditions
        .map(
          (condition: CustomizeItemSearchConditionType) =>
            condition.customize_item_id,
        )
        .includes(item.id),
  );

  const selectedItem = searchCondition.customize_item_id
    ? poolProspectCustomizeItems.find(
        (item) => item.id === searchCondition.customize_item_id,
      )
    : null;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const customizeItemId = e.target.value;
    const customizeItem = poolProspectCustomizeItems.find(
      (item) => item.id === customizeItemId,
    );

    if (!customizeItem) {
      setSearchCondition({
        customize_item_id: '',
        data_type: '',
        values: [],
        from: '',
        to: '',
      });
      return;
    }

    if (customizeItem.dataType === 'select') {
      setSearchCondition({
        customize_item_id: customizeItemId,
        data_type: customizeItem.dataType,
        values: [],
      });
    }

    if (customizeItem.dataType === 'date') {
      setSearchCondition({
        customize_item_id: customizeItemId,
        data_type: customizeItem.dataType,
        from: '',
        to: '',
      });
    }
  };

  return (
    <div className="flex gap-2">
      <select
        className="form-select border-c-border rounded text-base w-64 h-[44px]"
        onChange={onChange}
        value={searchCondition.customize_item_id}>
        <option value="" />
        {filteredCustomizeItems.map((item) => (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {searchCondition.data_type === 'select' && (
        <Select
          customizeItem={selectedItem}
          values={
            (searchCondition as CustomizeItemSelectSearchConditionType).values
          }
          onChange={(values) =>
            setSearchCondition({
              ...searchCondition,
              values,
            })
          }
        />
      )}
      {searchCondition.data_type === 'date' && (
        <div className="flex gap-2 items-center">
          <input
            type="date"
            className="form-input border-c-border rounded text-base w-40 h-[44px]"
            value={
              (searchCondition as CustomizeItemDateSearchConditionType).from
            }
            onChange={(e) =>
              setSearchCondition({
                ...searchCondition,
                from: e.target.value,
              })
            }
          />
          <span className="text-c-light">~</span>
          <input
            type="date"
            className="form-input border-c-border rounded text-base w-40 h-[44px]"
            value={(searchCondition as CustomizeItemDateSearchConditionType).to}
            onChange={(e) =>
              setSearchCondition({
                ...searchCondition,
                to: e.target.value,
              })
            }
          />
        </div>
      )}
      <button
        onClick={onDelete}
        className="bg-white px-0 py-6 h-5 w-5 flex items-center justify-center rounded-full cursor-pointer">
        <XMarkIcon className="w-5 h-5 text-c-light" />
      </button>
    </div>
  );
};

export default CustomizeItemField;
