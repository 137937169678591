import React from 'react';
import Table from './Table';
import {Filter} from 'components/Ui/Icon';
import useSearchParams from './useSearchParams';
import Search from './Search';

interface Props {
  toggleFilter: () => void;
}

export default ({toggleFilter}: Props) => {
  const {numberOfFiltersInUse} = useSearchParams();
  const isFiltered = numberOfFiltersInUse > 0;

  return (
    <div className="flex-1 flex flex-col p-5 bg-white rounded">
      <div className="flex items-center mb-2 gap-2">
        <button
          onClick={toggleFilter}
          className={`cursor-pointer [&>svg]:relative [&>svg]:top-[2px] bg-transparent ${
            isFiltered && '[&>svg>path]:stroke-c-primary'
          }`}>
          <Filter />
        </button>
        {isFiltered && (
          <span className="font-medium text-xs leading-6 h-6 px-3 text-c-primary bg-[#e6f3ff] rounded-xl">
            {numberOfFiltersInUse}
          </span>
        )}
        <Search />
      </div>
      <Table />
    </div>
  );
};
