import React from 'react';
import {AccountPool} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<AccountPool>;
}

const StatsCell = ({row}: Props) => {
  const {prospectPools} = row.original;

  const workflowCount = React.useMemo(
    () =>
      prospectPools.filter(
        (prospectPool) => prospectPool.workflowProspectPoolId,
      ).length,
    [prospectPools],
  );

  return (
    <div className="flex px-4 items-center h-full border-l border-c-border">
      <div className="flex flex-col items-center gap-1 w-20">
        <div className="text-xs text-c-light">リード</div>
        <div className="text-lg font-bold">
          {prospectPools.length.toLocaleString()}
        </div>
      </div>
      <div className="flex flex-col items-center gap-1 w-20">
        <div className="text-xs text-c-light">ワークフロー</div>
        <div className="text-lg font-bold">
          {workflowCount.toLocaleString()}
        </div>
      </div>
    </div>
  );
};

export default StatsCell;
