import React from 'react';

const Icon = ({color = '#BDC4CB', className = ''}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 15.6C10.2 15.6 8.79999 14.2 8.79999 12.4V4.8C8.79999 3 10.2 1.6 12 1.6C13.8 1.6 15.2 3 15.2 4.8V12.5C15.2 14.2 13.8 15.6 12 15.6Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.79999 12.6C5.79999 16 8.59999 18.8 12 18.8C15.4 18.8 18.2 16 18.2 12.6"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12 18.7V22.4"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M14.1 22.4H9.89996H14.1Z" fill="#899098" />
    <path
      d="M14.1 22.4H9.89996"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default Icon;
