import React from 'react';
import {useHistory} from 'react-router';
import {ContentMail} from 'components/Ui/Icon';
import {useCreateContentMailTemplateMutation} from 'api';

export default () => {
  const history = useHistory();

  const [createContentMailTemplate] = useCreateContentMailTemplateMutation({
    onCompleted: ({createContentMailTemplate: {content = {}}}) => {
      history.push(`/garage/contents/${content.uuid}/mail_template`);
    },
  });

  return (
    <div onClick={() => createContentMailTemplate()}>
      <ContentMail color="#68B5FB" className="active" />
      <ContentMail color="#899098" className="inactive" />
      <p>メールテンプレート</p>
    </div>
  );
};
