import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Project, useWebsiteQuery} from 'api';
import {Link} from 'react-router-dom';
import {Row} from 'react-table';
import useProjectPath from 'hooks/useProjectPath';

interface Props {
  value: number;
  row: Row<Project>;
}

export default ({value, row}: Props) => {
  const project = row.original;
  const {createPath} = useProjectPath();

  const {projectSearchCondition = {}} = project;
  const websiteId = project.projectSearchCondition?.websiteId;

  const {data: {website = null} = {}} = useWebsiteQuery({
    variables: {id: websiteId},
    skip: !websiteId,
  });

  const pathToList: string = useMemo(() => {
    if (projectSearchCondition?.company) {
      return createPath(
        `projects/${project.uuid}/search/category?specificCompanyId=${projectSearchCondition?.company.uuid}`,
      );
    }
    if (website) {
      return createPath(
        `projects/${project.uuid}/search/website/single/${website.websiteType}`,
      );
    }
    if (projectSearchCondition?.technologies?.length > 0) {
      return createPath(`projects/${project.uuid}/search/technology`);
    } else if (projectSearchCondition?.websiteIds?.length > 0) {
      return createPath(`projects/${project.uuid}/search/website/multiple`);
    } else {
      return createPath(`projects/${project.uuid}/search/category`);
    }
  }, [projectSearchCondition, website]);

  return (
    <Container>
      {project.status === 'open' ? (
        <Link to={createPath(`projects/${project.uuid}/preleads`)}>
          <span>{value.toLocaleString()}</span>
        </Link>
      ) : project.projectType === 'search' && project.status !== 'archived' ? (
        <Link to={pathToList}>
          <span>{value.toLocaleString()}</span>
        </Link>
      ) : (
        <span>{value.toLocaleString()}</span>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 80px;
  text-align: right;

  span {
    margin-right: 30px;
    font-weight: bold;
    font-size: 14px;
    color: #495058;
  }

  a {
    span {
      color: #68b5fb;
    }
  }
`;
