import gql from 'graphql-tag';

export default gql`
  fragment contentTelScriptFragment on ContentTelScript {
    id
    uuid
    file
    fileName
    fileContentType
    fileSize
    memo
    createdAt
    updatedAt
  }
`;
