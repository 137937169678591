import gql from 'graphql-tag';

export default gql`
  fragment prospectTelStatusCategoryFragment on ProspectTelStatusCategory {
    id
    name
    color
    position
  }
`;
