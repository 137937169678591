import React from 'react';

function Icon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66665 5.00033H11.3333H4.66665ZM4.66665 8.33366H11.3333H4.66665ZM4.66665 11.667H7.16665H4.66665ZM2.99998 18.3337H13C13.9205 18.3337 14.6666 17.5875 14.6666 16.667V3.33366C14.6666 2.41318 13.9205 1.66699 13 1.66699H2.99998C2.0795 1.66699 1.33331 2.41318 1.33331 3.33366V16.667C1.33331 17.5875 2.0795 18.3337 2.99998 18.3337Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
