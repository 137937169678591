import React from 'react';
import moment from 'moment';
import {Activity, MiitelCallActivity, useCurrentClientQuery} from 'api';
import {Miitel} from 'components/Ui/Icon';
import UpdateModal from './UpdateModal';
import useProjectName from '../useProjectName';

interface CallActivity extends Activity {
  resource: MiitelCallActivity;
}

interface Props {
  activity: CallActivity;
}

const MiitelCallComponent = ({activity}: Props) => {
  const {resource} = activity;
  const {projectName} = useProjectName(activity);

  const {data: {currentClient: {miitelSetting = {}} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const [isOpen, setIsOpen] = React.useState(false);
  const closeModal = () => setIsOpen(false);

  const isDisplayButton = !resource.clientTelStatus && !resource.body;

  return (
    <>
      <div className="my-2 flex items-center w-full h-12 px-4">
        <div className="z-10 flex w-8 h-auto mr-4">
          <Miitel />
        </div>
        <div className="text-c-base gap-1">
          <span className="flex flex-1 items-center">{projectName}</span>
          <span className="flex flex-1 items-center">
            {activity.user?.lastName} {activity.user?.firstName}
          </span>
        </div>

        {isDisplayButton && (
          <div className="flex flex-1 justify-end">
            <button
              onClick={() => setIsOpen(true)}
              className="cursor-pointer bg-white border border-c-primary text-sm text-c-primary hover:opacity-50 px-4 h-8 rounded inline-flex items-center gap-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="w-4"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 5V19M5 12H19"
                  className="stroke-c-primary"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>
              </svg>
              <span>ログ</span>
            </button>
          </div>
        )}
      </div>
      <div className="p-4 mx-4 border-c-border border-t">
        <div className="flex items-center gap-2">
          <a
            href={`https://${miitelSetting.miitelCompanyId}.miitel.jp/app/calls/${resource.miitelSequenceId}`}
            target="_blank">
            {resource.miitelSequenceId}
          </a>
          <div className="text-c-lightflex flex flex-1 justify-end">
            {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
          </div>
        </div>
      </div>
      <UpdateModal
        isOpen={isOpen}
        closeModal={closeModal}
        activity={activity}
      />
    </>
  );
};

export default MiitelCallComponent;
