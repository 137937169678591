import React from 'react';
import styled from 'styled-components';
import {List} from 'components/Ui/Icon';
import {useProjectQuery} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  if (!project) return null;

  return (
    <Container>
      <div>
        <List />
      </div>
      <div>
        <Label>プレリード件数</Label>
        <Count>{project.count.toLocaleString()}</Count>
      </div>
    </Container>
  );
};

const Container = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 12px;

    svg {
      path {
        stroke: ${({disabled}) => (disabled ? '#bdc4cb' : '#68b4fb')};
      }
    }
  }
`;

const Label = styled.span`
  display: block;
  transform: scale(0.7);
  transform-origin: left;
  font-weight: bold;
  font-size: 10px;
  color: #bdc4cb;
`;

const Count = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: #495058;
`;
