import React from 'react';
import {AccountPool, AccountGroup} from 'api';

interface Props {
  row: any;
}

const NameCell = ({row}: Props) => {
  const {account} = row.original;

  return (
    <div className="flex flex-col gap-1 justify-center pl-4 h-full border-l border-c-border">
      {account.accountGroups?.map((accountGroup: AccountGroup) => (
        <div className="text-sm text-c-light" key={accountGroup.uuid}>
          {accountGroup.name}
        </div>
      ))}
    </div>
  );
};

export default NameCell;
