import React from 'react';
import {Breadcrumb, Header, Container} from 'components/Ui';
import {Link, Route, Switch} from 'react-router-dom';
import Search from './Search';
import AllReport from './AllReport';
import DailyReport from './DailyReport';
import WeeklyReport from './WeeklyReport';
import WeeklyHistoryReport from './WeeklyHistoryReport';
import MonthlyReport from './MonthlyReport';
import ProjectReport from './ProjectReport';
import UserReport from './UserReport';
import ReportTypeTabs from '../ReportTypeTabs';
import {Body, Main, MainTabs, MainTabItem} from '../style';

export default () => {
  return (
    <Container page="report">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <span className="current">レポート</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>

      <Body>
        <ReportTypeTabs />
        <Search />
        <Main>
          <MainTabs>
            <MainTabItem active={location.pathname.includes('report/call/all')}>
              <Link to={`/report/call/all/call${location.search}`}>全体</Link>
            </MainTabItem>
            <MainTabItem
              active={location.pathname.includes('report/call/monthly')}>
              <Link to={`/report/call/monthly/call${location.search}`}>
                月次
              </Link>
            </MainTabItem>
            <MainTabItem
              active={location.pathname.includes('report/call/weekly/')}>
              <Link to={`/report/call/weekly/call${location.search}`}>
                週次
              </Link>
            </MainTabItem>
            <MainTabItem
              active={location.pathname.includes('report/call/daily')}>
              <Link to={`/report/call/daily/call${location.search}`}>日次</Link>
            </MainTabItem>
            <MainTabItem
              active={location.pathname.includes('report/call/weekly_history')}>
              <Link to={`/report/call/weekly_history/call${location.search}`}>
                週次遷移
              </Link>
            </MainTabItem>
            <MainTabItem
              active={location.pathname.includes('report/call/project')}>
              <Link to={`/report/call/project/call${location.search}`}>
                営業リスト別
              </Link>
            </MainTabItem>
            <MainTabItem
              active={location.pathname.includes('report/call/user')}>
              <Link to={`/report/call/user/call${location.search}`}>
                担当者別
              </Link>
            </MainTabItem>
          </MainTabs>
          <Switch>
            <Route path="/report/call/project" component={ProjectReport} />
            <Route path="/report/call/all" component={AllReport} />
            <Route path="/report/call/monthly" component={MonthlyReport} />
            <Route
              path="/report/call/weekly_history"
              component={WeeklyHistoryReport}
            />
            <Route path="/report/call/weekly" component={WeeklyReport} />
            <Route path="/report/call/daily" component={DailyReport} />
            <Route path="/report/call/user" component={UserReport} />
          </Switch>
        </Main>
      </Body>
    </Container>
  );
};
