import React, {useMemo} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {Website} from 'api';
import {Checkbox} from 'components/Ui';
import useSearchCondition from '../useSearchCondition';

type Props = {
  title: string;
  pageType: string;
  websites: Website[];
};

export default ({title, pageType, websites}: Props) => {
  const {query, setQuery} = useSearchCondition();
  const websiteIds = React.useMemo(
    () => websites.map((website: Website) => website.id),
    [websites],
  );

  const checked = useMemo(() => {
    return _.difference(websiteIds, query.websiteIds).length === 0;
  }, [websiteIds, query]);

  const handleCheckAll = (e: any) =>
    setQuery({
      websiteIds: [
        ...new Set(
          e.target.checked
            ? [...(query.websiteIds || []), ...websiteIds]
            : query.websiteIds?.filter(
                (id: string) => !websiteIds.includes(id),
              ),
        ),
      ],
    });

  const handleCheckWebsite = (e: any) =>
    setQuery({
      websiteIds: e.target.checked
        ? [...(query.websiteIds || []), e.target.value]
        : query.websiteIds?.filter((websiteId) => websiteId !== e.target.value),
    });

  return (
    <Container id={pageType}>
      <PageTypeSection>
        <PageTypeCheckBox checked={checked} onChange={handleCheckAll}>
          {title} すべて
        </PageTypeCheckBox>
      </PageTypeSection>
      <WebsiteSection>
        {websites.map((website: Website) => (
          <WebsiteItem key={website.id}>
            <WebsiteCheckBox
              value={website.id}
              checked={query.websiteIds?.includes(website.id)}
              onChange={handleCheckWebsite}>
              {website.name}
            </WebsiteCheckBox>
          </WebsiteItem>
        ))}
      </WebsiteSection>
    </Container>
  );
};

const Container = styled.section`
  padding-top: 20px;
  padding-bottom: 40px;
  width: 400px;
`;

const PageTypeSection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px solid #e7e8eb;
`;

const PageTypeCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  min-width: 300px;
`;

const WebsiteSection = styled.div`
  margin-top: 10px;
  margin-left: 22px;
  display: flex;
  flex-wrap: wrap;

  &&& {
    .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
`;

const WebsiteItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const WebsiteCheckBox = styled(Checkbox)`
  &&& {
    display: flex;
    align-items: center;
    min-width: 300px;
    margin: 5px 0;
    font-size: 12px;
    flex-basis: 45%;
  }
`;
