import React from 'react';
import styled from 'styled-components';
import {Modal} from 'components/antd';
import {AutomaticMail, ManualMail, StepCall, StepTask } from 'components/Ui/Icon';
import Form from './Form';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const NewStepModalComponent = ({visible, onClose}: Props) => {
  const [stepType, setStepType] = React.useState('automaticMail');

  return (
    <NewModal
      title="ステップを追加"
      visible={visible}
      onCancel={onClose}
      width={580}
      footer={null}
      maskClosable={false}>
      <Tabs>
        <TabItem
          active={stepType === 'automaticMail'}
          onClick={() => setStepType('automaticMail')}>
          <AutomaticMail
            active={stepType === 'automaticMail'}
            width="40"
            height="40"
          />
          自動メール
        </TabItem>
        <TabItem
          active={stepType === 'manualMail'}
          onClick={() => setStepType('manualMail')}>
          <ManualMail active={stepType === 'manualMail'} />
          手動メール
        </TabItem>
        <TabItem
          active={stepType === 'call'}
          onClick={() => setStepType('call')}>
          <StepCall active={stepType === 'call'} />
          電話
        </TabItem>
        <TabItem
          active={stepType === 'task'}
          onClick={() => setStepType('task')}>
          <StepTask active={stepType === 'task'} />
          タスク
        </TabItem>
      </Tabs>
      <Content>
        <Form stepType={stepType} onFinish={onClose} />
      </Content>
    </NewModal>
  );
};

const NewModal = styled(Modal)`
  h3 {
    font-weight: bold;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: solid 1px #e1e6eb;
  padding: 12px 24px 0;
`;

const TabItem = styled.div<{active?: boolean}>`
  text-align: center;
  border-bottom: solid 3px;
  border-color: ${({active}) => (active ? '#495058' : 'transparent')};
  padding: 0;
  margin-right: 2rem;
  cursor: pointer;

  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
  color: ${({active}) => (active ? '#495058' : '#BDC4CB')};
  display: flex;
  width: 64px;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  padding: 24px;
`;

export default NewStepModalComponent;
