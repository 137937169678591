import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {Listbox} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';

interface Props {
  onChange: (value: string) => void;
}

const options = [
  {label: 'アプローチ', value: 'approach'},
  {label: 'Webトラッキング', value: 'tracking'},
  {label: 'ワークフロー', value: 'workflow'},
];

export default ({onChange}: Props) => {
  const {
    params: {tab},
  } = useRouteMatch<{tab: string}>();

  const currentTab = options.find((option) => option.value === tab);
  const active = !!currentTab;

  return (
    <div className="relative">
      <Listbox value={currentTab?.value || ''} onChange={onChange}>
        <Listbox.Button
          className={`relative px-3 text-left focus:outline-none flex items-center gap-2 h-9 bg-white justify-between truncate border-b-[3px] ${
            active ? 'border-[#68B5FB]' : 'border-transparent'
          }`}>
          <span
            className={`text-sm font-bold ${
              active ? 'text-[#68B5FB]' : 'text-[#BDC4CB]'
            }`}>
            {currentTab?.label || `他${options.length}件`}
          </span>
          <ChevronDownIcon
            className="h-4 w-4 text-[#BDC4CB]"
            aria-hidden="true"
          />
        </Listbox.Button>
        <Listbox.Options className="absolute">
          {options.map((option) => (
            <Listbox.Option
              className={({active}) =>
                `relative select-none py-2 px-3 cursor-pointer shadow-lg h-8 pr-10 truncate z-20 ${
                  active ? 'bg-gray-100' : 'bg-white'
                }`
              }
              key={option.value}
              value={option.value}>
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
