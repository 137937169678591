import gql from 'graphql-tag';

export default gql`
  fragment preleadSnsFragment on Prelead {
    facebookUrls
    instagramUrls
    youtubeUrls
    twitterUrls
    lineUrls
    linkedinUrls
  }
`;
