import React from 'react';
import styled from 'styled-components';
import {Modal, Input, Button, Form} from 'components/antd';
import * as Yup from 'yup';
// import {message} from 'components/antd';
import {useFormik} from 'formik';
import {
  PreleadContact,
  useDestroyPreleadContactMutation,
  useUpdatePreleadContactMutation,
} from 'api';

const validateSchema = Yup.object().shape({
  lastName: Yup.string().required('必須項目です'),
});

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  preleadContact: PreleadContact;
}

export default ({isModalVisible, setModalVisible, preleadContact}: Props) => {
  const [updatePreleadContact, {loading: updateLoading}] =
    useUpdatePreleadContactMutation({
      refetchQueries: ['preleadContacts'],
      onCompleted: () => {
        resetForm();
        setModalVisible(false);
      },
    });

  const [destroyPreleadContact, {loading: destroyLoading}] =
    useDestroyPreleadContactMutation({
      refetchQueries: ['preleadContacts', 'preleadProject'],
      onCompleted: () => {
        resetForm();
        setModalVisible(false);
      },
    });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: preleadContact?.firstName || null,
      lastName: preleadContact?.lastName || null,
      section: preleadContact?.section || null,
      sectionPosition: preleadContact?.sectionPosition || null,
      email: preleadContact?.email || null,
      telephoneNumber: preleadContact?.telephoneNumber || null,
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      updatePreleadContact({
        variables: {
          uuid: preleadContact.uuid,
          attributes: values,
        },
      });
    },
  });

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleDestroy = () =>
    destroyPreleadContact({
      variables: {
        uuid: preleadContact.uuid,
      },
    });

  return (
    <AddModal
      title="連絡先を編集・削除"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <DeleteButton
          key={'delete'}
          onClick={handleDestroy}
          loading={updateLoading || destroyLoading}
          disabled={updateLoading || destroyLoading}>
          削除
        </DeleteButton>,
        <SaveButton
          key={'submit'}
          loading={updateLoading || destroyLoading}
          disabled={
            !isValid ||
            updateLoading ||
            destroyLoading ||
            (preleadContact?.salesforceLeadId && !values.firstName)
          }
          onClick={() => handleSubmit()}>
          更新
        </SaveButton>,
      ]}
      style={{
        // top: '30px',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-59%, 23%)',
      }}>
      <FormSection>
        <Form
          labelCol={{span: 4}}
          wrapperCol={{span: 12}}
          onFinish={handleSubmit}>
          <NameBox>
            <Form.Item
              label="姓"
              required
              validateStatus={
                errors.lastName && touched.lastName ? 'error' : ''
              }
              // help={errors.lastName && touched.lastName ? errors.lastName : ''}
            >
              <Input
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              label="名"
              required={!!preleadContact?.salesforceLeadId}
              validateStatus={
                errors.firstName && touched.firstName ? 'error' : ''
              }
              // help={errors.firstName && touched.firstName ? errors.firstName : ''}
            >
              <Input
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
          </NameBox>

          <Form.Item label="メールアドレス">
            <Input
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>

          <Form.Item label="電話番号">
            <Input
              name="telephoneNumber"
              value={values.telephoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>

          <Form.Item label="部署名">
            <Input
              name="section"
              value={values.section}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>

          <Form.Item label="役職">
            <Input
              name="sectionPosition"
              value={values.sectionPosition}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
        </Form>
      </FormSection>
    </AddModal>
  );
};

const AddModal = styled(Modal)`
  .ant-modal-content {
    width: 330px;
    border-radius: 5px;
    padding: 14px 15px 17px 15px;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 14px;
    color: #495058;
  }

  .ant-modal-header {
    padding: 0;
    border: none;
  }

  .ant-modal-body {
    padding: 0;
    margin-bottom: 18px;
  }

  .ant-modal-footer {
    padding: 0;
    border: none;
  }
`;

const DefaultButton = styled(Button)`
  height: 26px;
  border-radius: 3px;
  padding: 6px 23px;
  font-weight: bold;
  font-size: 10px;
`;

const DeleteButton = styled(DefaultButton)`
  border-color: #c90e19;
  &:hover,
  active {
    border-color: #c90e19;
  }

  span {
    color: #c90e19;
  }
`;

const SaveButton = styled(DefaultButton)`
  background: #68b5fb;

  &:hover {
    background: #65aae8;
  }

  span {
    color: #fff;
  }
`;

const FormSection = styled.div`
  form {
    .ant-form-item {
      margin: 0;
      display: block;
      // height: 95px;

      .ant-form-item-label {
        display: inline;
        label {
          font-size: 10px;
          color: #899098;
        }
      }
      input {
        width: 301px;
        height: 32px;

        background: #ffffff;
        border: 1px solid #bdc4cb;
        border-radius: 2px;

        &:focus {
          background: #f3f5f7;
          border: 1px solid #68b4fb;
        }
      }
    }
  }
`;

const NameBox = styled.div`
  display: flex;
  > div:last-child {
    margin-left: auto;
  }
  input {
    width: 144px !important;
    height: 32px;
  }
`;
