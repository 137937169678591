import React from 'react';
import {prospectStages} from 'helpers/prospectStages';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';

const StageFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="ステージ" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            options={prospectStages.map((stage) => ({
              text: stage.displayName,
              value: stage.name,
            }))}
            values={query.stages || []}
            onChange={(stages) => setQuery({...query, stages})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, stages: []})} />
    </>
  );
};

export default StageFilter;
