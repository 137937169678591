import gql from 'graphql-tag';

export default gql`
  fragment preleadStatusFragment on PreleadStatus {
    id
    clientId
    name
    category
    position
    isImmutable
    description
  }
`;
