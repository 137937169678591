import gql from 'graphql-tag';

export default gql`
  fragment userFragment on User {
    id
    uuid
    clientId
    email
    firstName
    lastName
    userName
    role
    confirmedAt
    active
  }
`;
