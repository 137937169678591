import React from 'react';
import DetailModal from '../DetailModal';
import Maintenances from './Maintenances';
import {useLatestMaintenanceRefreshQuery} from 'api';

export default () => {
  const {data: {latestMaintenanceRefresh = null} = {}} =
    useLatestMaintenanceRefreshQuery({
      fetchPolicy: 'cache-and-network',
    });

  const canShowMaintenances =
    !latestMaintenanceRefresh || latestMaintenanceRefresh?.status === 'success';

  return (
    <div className="flex flex-col gap-5">
      <h2 className="font-bold text-lg flex justify-between items-baseline leading-none m-0">
        重複リード一覧
      </h2>
      {canShowMaintenances && <Maintenances />}
      <DetailModal />
    </div>
  );
};
