import React, {useState} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {Button} from 'components/antd';
import {
  useWorkflowQuery,
  useUpdateWorkflowMutation,
  usePrefectureTypesByCityIdsQuery,
  usePrefectureTypesQuery,
  City,
  PrefectureType,
} from 'api';
import {Cross} from 'components/Ui/Icon';
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import {regions} from 'helpers/regions';
import Regions from './Regions';
import Prefectures from './Prefectures';
import PrefectureCities from './PrefectureCities';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setIsModalVisible}: Props) => {
  const [currentRegion, setCurrentRegion] = useState(regions[0]);
  const [currentPrefectureType, setCurrentPrefectureType] =
    useState<PrefectureType>();
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  const [selectedPrefectureTypeIds, setSelectedPrefectureTypeIds] = useState(
    [],
  );
  const [isSet, setIsSet] = useState(false);
  const [
    alreadySelectedPrefectureTypeIds,
    setAlreadySelectedPrefectureTypeIds,
  ] = useState([]);

  const {workflowId} = useParams<{workflowId: string}>();
  const {
    data: {
      workflow: {
        searchCondition: {
          subCategories,
          cities: alreadySelectedCities = [],
        } = {},
        searchCondition: originalSearchCondition = {},
      } = {},
    } = {},
  } = useWorkflowQuery({
    variables: {uuid: workflowId},
    fetchPolicy: 'cache-and-network',
    onCompleted: ({workflow: {searchCondition: {cities} = {}} = {}} = {}) =>
      cities?.length > 0 && setSelectedCityIds(cities.map((city) => city.id)),
  });

  const {data: {prefectureTypesByCityIds = []} = {}} =
    usePrefectureTypesByCityIdsQuery({
      variables: {
        cityIds: selectedCityIds,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: ({prefectureTypesByCityIds = []} = {}) => {
        setSelectedPrefectureTypes(prefectureTypesByCityIds);
        setAlreadySelectedPrefectureTypes(prefectureTypesByCityIds);
        Object.keys(currentRegion).length == 0 &&
          setCurrentRegion(regions.find((region) => isRegionSelected(region)));
      },
    });

  const {data: {prefectureTypes = []} = {}} = usePrefectureTypesQuery({
    variables: {search: {prefectureTypeNames: currentRegion?.prefectureTypes}},
    fetchPolicy: 'cache-and-network',
    onCompleted: ({prefectureTypes = []} = {}) =>
      prefectureTypes?.length > 0 &&
      setCurrentPrefectureType(
        prefectureTypes.find(
          (prefectureType) =>
            prefectureType.name === currentRegion?.prefectureTypes[0],
        ),
      ),
  });

  const [updateWorkflow] = useUpdateWorkflowMutation({
    refetchQueries: ['workflowProspectPoolCountsByStatus'],
  });

  const closeModalOnUpdate = () => {
    setAlreadySelectedPrefectureTypeIds(selectedPrefectureTypeIds);
    setIsModalVisible(false);
  };

  const closeModal = () => {
    setSelectedCityIds(alreadySelectedCities.map((city) => city.id));
    setSelectedPrefectureTypeIds(alreadySelectedPrefectureTypeIds);
    setIsModalVisible(false);
  };

  const setSelectedPrefectureTypes = (
    prefectureTypesByCityIds: PrefectureType[],
  ) => {
    const targetPrefectureTypeIds: string[] = [];
    for (const prefectureType of prefectureTypesByCityIds) {
      if (
        _.difference(
          prefectureType.cities.map((city) => city.id),
          selectedCityIds,
        ).length === 0
      )
        targetPrefectureTypeIds.push(prefectureType.id);
    }
    setSelectedPrefectureTypeIds([
      ...selectedPrefectureTypeIds,
      ...targetPrefectureTypeIds,
    ]);
  };

  const setAlreadySelectedPrefectureTypes = (
    prefectureTypesByCityIds: PrefectureType[],
  ) => {
    const targetPrefectureTypeIds: string[] = [];
    for (const prefectureType of prefectureTypesByCityIds) {
      if (
        _.difference(
          prefectureType.cities.map((city) => city.id),
          selectedCityIds,
        ).length === 0
      )
        targetPrefectureTypeIds.push(prefectureType.id);
    }
    if (!isSet) {
      setAlreadySelectedPrefectureTypeIds([
        ...selectedPrefectureTypeIds,
        ...targetPrefectureTypeIds,
      ]);
      setIsSet(true);
    }
  };

  const isRegionSelected = (region: {prefectureTypes: string[]}) => {
    for (const prefectureType of region.prefectureTypes) {
      if (prefectureTypesByCityIds.map((p) => p.name).includes(prefectureType))
        return true;
    }
    return false;
  };

  const handleCheckOnPrefectureType = (
    e: any,
    prefectureType: PrefectureType,
  ) => {
    if (e.target.checked) {
      setSelectedCityIds([
        ...selectedCityIds,
        ...prefectureType.cities.map((city: City) => city.id),
      ]);
      setSelectedPrefectureTypeIds([
        ...selectedPrefectureTypeIds,
        prefectureType.id,
      ]);
    } else {
      setSelectedCityIds(
        _.difference(
          selectedCityIds,
          prefectureType.cities.map((city: City) => city.id),
        ),
      );
      setSelectedPrefectureTypeIds(
        _.difference(selectedPrefectureTypeIds, [prefectureType.id]),
      );
    }
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => closeModal()}
      style={customStyles}>
      <Header>
        <div>エリア</div>
        <CrossIconWrapper onClick={() => closeModal()}>
          <Cross />
        </CrossIconWrapper>
      </Header>

      <Container>
        <Regions
          currentRegion={currentRegion}
          setCurrentRegion={setCurrentRegion}
          isRegionSelected={isRegionSelected}
        />

        <Prefectures
          currentRegion={currentRegion}
          currentPrefectureType={currentPrefectureType}
          prefectureTypes={prefectureTypes}
          selectedPrefectureTypeIds={selectedPrefectureTypeIds}
          setSelectedPrefectureTypeIds={setSelectedPrefectureTypeIds}
          selectedCityIds={selectedCityIds}
          setSelectedCityIds={setSelectedCityIds}
          handleCheckOnPrefectureType={handleCheckOnPrefectureType}
        />

        <PrefectureCities
          currentRegion={currentRegion}
          setCurrentPrefectureType={setCurrentPrefectureType}
          prefectureTypes={prefectureTypes}
          selectedPrefectureTypeIds={selectedPrefectureTypeIds}
          setSelectedPrefectureTypeIds={setSelectedPrefectureTypeIds}
          selectedCityIds={selectedCityIds}
          setSelectedCityIds={setSelectedCityIds}
          handleCheckOnPrefectureType={handleCheckOnPrefectureType}
        />
      </Container>

      <Action>
        <Button
          style={{width: '110px', borderRadius: '4px'}}
          type="primary"
          onClick={() => {
            const searchCondition = {...originalSearchCondition};
            delete searchCondition.__typename;
            updateWorkflow({
              variables: {
                uuid: workflowId,
                attributes: {
                  searchCondition: {
                    ...searchCondition,
                    subCategories: subCategories.map(
                      (subCategory) => subCategory.id,
                    ),
                    cities: selectedCityIds,
                  },
                },
              },
            });
            closeModalOnUpdate();
          }}>
          適用
        </Button>
      </Action>
    </Modal>
  );
};

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '95vh',
    padding: '25px 30px',
    borderRadius: '4px',
    overflow: 'hidden',
  },
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  font-weight: bold;
  font-size: 18px;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: -15px;
  right: -10px;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const Container = styled.div`
  height: calc(100vh - 220px);
  display: flex;

  .ant-radio-group {
    display: flex;
    flex-direction: row;
  }
  .ant-radio-wrapper {
    width: 100px;
    margin: 10px 10px 20px 10px;
  }
  .ant-checkbox-wrapper {
    width: 120px;
    margin: 5px 10px;
  }
`;
