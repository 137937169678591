import React from 'react';
import {HubspotDealPropertyDefinition} from 'api';

interface Props {
  propertyDefinition: HubspotDealPropertyDefinition;
  handleUpdate: Function;
}

export const Text: React.FC<Props> = ({propertyDefinition, handleUpdate}) => {
  return (
    <div className="grid grid-cols-1 gap-6 mb-4">
      <label className="block">
        <span>{propertyDefinition.labelText}</span>
        <input
          type="text"
          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
          placeholder=""
          onChange={(e) => {
            const value = e.target.value;
            handleUpdate(propertyDefinition.name, value);
          }}
        />
      </label>
    </div>
  );
};
