import React from 'react';
import styled from 'styled-components';
import {
  Home,
  User,
  Users,
  Tel,
  World,
  Parliament,
  Location,
  Facebook,
  Flag,
  Twitter,
  Line,
  YouTube,
  LinkedIn,
  Instagram,
  Mail,
  Offices,
  Zipcode,
  Calendar,
  Chart,
  BarChart,
  Residence,
  Fax,
  BuildingDomestic,
  Store,
  Factory,
  BuildingOverseas,
  StoreOverseas,
  FactoryOverseas,
} from 'components/Ui/Icon';
import {SubCategory, usePreleadQuery} from 'api';
import toJaNum from 'helpers/toJaNum';
import MARKETS from 'helpers/markets';
import usePreleadContext from '../usePreleadContext'

const establishedYearMonthText = (establishedYearMonth: string) => {
  if (!establishedYearMonth) return '';

  const date_text = `${establishedYearMonth.replace('-', '年')}月`;

  return date_text;
};

export default () => {
  const {preleadId} = usePreleadContext()

  if (!preleadId) return null;

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    fetchPolicy: 'cache-and-network',
    skip: !preleadId,
  });

  if (!prelead) return null;

  return (
    <Container>
      <Item>
        <Home />
        <Name>社名</Name>
        <Value>{prelead.name}</Value>
      </Item>
      <Item>
        <IconWrapper>
          <Zipcode />
        </IconWrapper>
        <Name>郵便番号</Name>
        <Value>{prelead.zipCode}</Value>
      </Item>
      <Item>
        <Location />
        <Name>住所</Name>
        <Value>{prelead.address}</Value>
      </Item>
      <Item>
        <Tel />
        <Name>代表電話</Name>
        <Value>{prelead.telephoneNumber}</Value>
      </Item>
      {prelead.telephoneNumbers?.map((telephoneNumber) => (
        <Item key={telephoneNumber.name}>
          <Tel />
          <Name>{telephoneNumber.name}</Name>
          <Value>{telephoneNumber.telephoneNumber}</Value>
        </Item>
      ))}
      <Item>
        <Fax />
        <Name>代表FAX</Name>
        <Value>{prelead.faxNumber}</Value>
      </Item>
      {prelead.preleadFaxNumbers?.map((faxNumber) => (
        <Item key={faxNumber.name}>
          <Fax />
          <Name>{faxNumber.name}</Name>
          <Value>{faxNumber.faxNumber}</Value>
        </Item>
      ))}
      <Item>
        <Mail />
        <Name>代表メール</Name>
        <Value>{prelead.mailAddress}</Value>
      </Item>
      {prelead.mailAddresses?.map((mailAddress) => (
        <Item key={mailAddress.name}>
          <Mail />
          <Name>{mailAddress.name}</Name>
          <Value>{mailAddress?.mail}</Value>
        </Item>
      ))}
      <Item>
        <World />
        <Name>ウェブサイト</Name>
        <Value>
          <a href={prelead.webSite} target="_blank">
            {prelead.webSite}
          </a>
        </Value>
      </Item>
      <Item>
        <User />
        <Name>代表者名</Name>
        <Value>{prelead.representativePerson}</Value>
      </Item>
      <Item>
        <Users />
        <Name>従業員数</Name>
        <Value>
          {prelead.employeeNumber &&
            `${prelead.employeeNumber?.toLocaleString()}人`}
        </Value>
      </Item>
      <Item>
        <Parliament />
        <Name>資本金</Name>
        <Value>
          {prelead.capitalFund && `${toJaNum(prelead.capitalFund)}円`}
        </Value>
      </Item>
      <Item>
        <Offices />
        <Name>事務所数</Name>
        <Value>{prelead.officesNumber}</Value>
      </Item>
      <Item>
        <BuildingDomestic />
        <Name>国内支店・拠点数</Name>
        <Value>
          {prelead.officesDomesticBranchesNumber &&
            prelead.officesDomesticBranchesNumber}
        </Value>{' '}
      </Item>
      <Item>
        <BuildingOverseas />
        <Name>海外支店・拠点数</Name>
        <Value>
          {prelead.officesForeignBranchesNumber &&
            prelead.officesForeignBranchesNumber}
        </Value>
      </Item>
      <Item>
        <Store />
        <Name>国内店舗数</Name>
        <Value>
          {prelead.officesDomesticStoresNumber &&
            prelead.officesDomesticStoresNumber}
        </Value>
      </Item>
      <Item>
        <StoreOverseas />
        <Name>海外店舗数</Name>
        <Value>
          {prelead.officesForeignStoresNumber &&
            prelead.officesForeignStoresNumber}
        </Value>
      </Item>
      <Item>
        <Factory />
        <Name>国内工場数</Name>
        <Value>
          {prelead.officesDomesticFactoriesNumber &&
            prelead.officesDomesticFactoriesNumber}
        </Value>
      </Item>
      <Item>
        <FactoryOverseas />
        <Name>海外工場数</Name>
        <Value>
          {prelead.officesForeignFactoriesNumber &&
            prelead.officesForeignFactoriesNumber}
        </Value>
      </Item>
      <Item>
        <Calendar />
        <Name>決算月</Name>
        <Value>
          {prelead.accountClosingMonth && `${prelead.accountClosingMonth}月`}
        </Value>
      </Item>
      <Item>
        <Chart />
        <Name>上場区分</Name>
        <Value>
          {
            MARKETS.find(
              (marketEnum) => marketEnum.value === prelead.listingMarket,
            )?.name
          }
        </Value>
      </Item>
      <Item>
        <BarChart />
        <Name>売上</Name>
        <Value>
          {prelead.sales && `${toJaNum(prelead.sales?.toString())}円`}
        </Value>
      </Item>
      <Item>
        <Flag />
        <Name>設立年月</Name>
        <Value>{establishedYearMonthText(prelead.establishedYearMonth)}</Value>
      </Item>
      <Item>
        <Residence />
        <Name>業種</Name>
      </Item>
      <TagBox>
        {prelead.subCategories?.map((subCategory: SubCategory) => (
          <TagItem key={subCategory.id}>{subCategory.displayName}</TagItem>
        ))}
      </TagBox>

      <SnsBox>
        {prelead.facebookUrls?.map((facebookUrl, i) => (
          <SnsLink key={i} href={facebookUrl} target="_blank">
            <Facebook />
          </SnsLink>
        ))}
        {prelead.twitterUrls?.map((twitterUrl, i) => (
          <SnsLink key={i} href={twitterUrl} target="_blank">
            <Twitter />
          </SnsLink>
        ))}
        {prelead.lineUrls?.map((lineUrl, i) => (
          <SnsLink key={i} href={lineUrl} target="_blank">
            <Line />
          </SnsLink>
        ))}
        {prelead.youtubeUrls?.map((youtubeUrl, i) => (
          <SnsLink key={i} href={youtubeUrl} target="_blank">
            <YouTube />
          </SnsLink>
        ))}
        {prelead.linkedinUrls?.map((linkedinUrl, i) => (
          <SnsLink key={i} href={linkedinUrl} target="_blank">
            <LinkedIn />
          </SnsLink>
        ))}
        {prelead.instagramUrls?.map((instagramUrl, i) => (
          <SnsLink key={i} href={instagramUrl} target="_blank">
            <Instagram />
          </SnsLink>
        ))}
      </SnsBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  &:nth-child(1) {
    margin-top: 0;
  }
  margin-top: 15px;
  display: flex;
  svg {
    width: 22px;
    margin-right: 25px;
  }
`;

const Name = styled.span`
  margin-right: 10px;
  width: 114px;
  font-weight: bold;
  color: #bdc4cb;
`;

const Value = styled.span`
  display: inline-block;
  word-break: break-all;
  width: 150px;
`;

const TagBox = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
`;

const TagItem = styled.span`
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #495058;
`;

const SnsBox = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  svg {
    margin-right: 20px;
    vertical-align: bottom;
  }
`;

const SnsLink = styled.a`
  margin: 5px 0;
`;

const IconWrapper = styled.div`
  margin-top: 3px;
`;
