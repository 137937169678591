import gql from 'graphql-tag';
import {
  manualMailFragment,
  stepFragment,
  workflowFragment,
} from '../../fragments';

export default gql`
  fragment manualMailActionMailThreadFragment on ManualMailActionMailThread {
    id
    updatedAt
    createdAt
    manualMail {
      ...manualMailFragment
    }
    step {
      ...stepFragment
    }
    workflow {
      ...workflowFragment
    }
  }
  ${manualMailFragment}
  ${stepFragment}
  ${workflowFragment}
`;
