import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99967 18.3337H14.9997C15.9202 18.3337 16.6663 17.5875 16.6663 16.667V8.19068C16.6663 7.74865 16.4908 7.32473 16.1782 7.01217L11.3212 2.15515C11.0086 1.84258 10.5847 1.66699 10.1427 1.66699H4.99967C4.0792 1.66699 3.33301 2.41318 3.33301 3.33366V16.667C3.33301 17.5875 4.0792 18.3337 4.99967 18.3337Z"
        stroke="#CB4F40"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1244 11.5652C11.7387 11.2616 11.3721 10.9496 11.1279 10.7054C10.8105 10.388 10.5277 10.0804 10.282 9.78746C10.6653 8.60297 10.8334 7.99223 10.8334 7.66668C10.8334 6.28363 10.3337 6 9.58336 6C9.01328 6 8.33336 6.29621 8.33336 7.70656C8.33336 8.32832 8.67395 9.08313 9.34898 9.96039C9.18379 10.4645 8.98969 11.046 8.7716 11.7015C8.6666 12.0161 8.5527 12.3074 8.43223 12.5768C8.33418 12.6203 8.23895 12.6646 8.14699 12.7106C7.81578 12.8763 7.50125 13.0252 7.20949 13.1635C5.87891 13.7934 5 14.2101 5 15.0328C5 15.6302 5.64902 16 6.25 16C7.02473 16 8.19457 14.9652 9.04906 13.2221C9.93609 12.8721 11.0388 12.6129 11.9092 12.4506C12.6066 12.9869 13.3769 13.5 13.75 13.5C14.7831 13.5 15 12.9027 15 12.4018C15 11.4166 13.8745 11.4166 13.3333 11.4166C13.1653 11.4167 12.7145 11.4663 12.1244 11.5652ZM6.25 15.1667C6.01195 15.1667 5.85082 15.0544 5.83332 15.0328C5.83332 14.7374 6.71426 14.3199 7.56633 13.9163C7.62043 13.8907 7.67539 13.865 7.73113 13.8386C7.10531 14.746 6.48641 15.1667 6.25 15.1667ZM9.16668 7.70656C9.16668 6.83336 9.4377 6.83336 9.58336 6.83336C9.87797 6.83336 10 6.83336 10 7.66668C10 7.84246 9.88285 8.28191 9.6684 8.96797C9.34121 8.46422 9.16668 8.0325 9.16668 7.70656ZM9.48609 12.1849C9.51215 12.1125 9.53738 12.0393 9.5618 11.9652C9.71641 11.5013 9.85559 11.0846 9.97969 10.7095C10.1526 10.8999 10.339 11.0948 10.5388 11.2946C10.6169 11.3727 10.8106 11.5485 11.0686 11.7686C10.555 11.8805 10.0086 12.0193 9.48609 12.1849ZM14.1667 12.4018C14.1667 12.589 14.1667 12.6667 13.7801 12.6691C13.6666 12.6447 13.4041 12.4901 13.0802 12.2696C13.1978 12.2566 13.2845 12.25 13.3333 12.25C13.949 12.25 14.1236 12.3102 14.1667 12.4018Z"
        fill="#CB4F40"
      />
    </svg>
  );
}

export default Icon;
