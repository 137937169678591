import React from 'react';
import styled from 'styled-components';
import CategoryReport from './categoryReport';
import {useParams, Route, Switch, Link} from 'react-router-dom';
import useProjectPath from 'hooks/useProjectPath';
import AreaReport from './areaReport';
import EmployeeRangeReport from './employeeRangeReport';
import CapitalFundReport from './capitalFundReport';
import OfficeNumberReport from './officeNumberReport';
import EstablishedYearReport from './establishedYearReport';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();

  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('category')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/company_profile/category`,
            )}>
            業種別
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('area')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/company_profile/area`,
            )}>
            エリア別
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('employee_range')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/company_profile/employee_range`,
            )}>
            従業員数別
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('capital_fund_range')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/company_profile/capital_fund_range`,
            )}>
            資本金別
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('office_number_range')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/company_profile/office_number_range`,
            )}>
            事業所数別
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('established_year_range')}>
          <Link
            to={createPath(
              `projects/${projectId}/form_mail_reports/company_profile/established_year_range`,
            )}>
            設立年数別
          </Link>
        </TabItem>
      </Tabs>

      <Switch>
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/company_profile/category',
          )}
          component={CategoryReport}
        />
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/company_profile/area',
          )}
          component={AreaReport}
        />
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/company_profile/employee_range',
          )}
          component={EmployeeRangeReport}
        />
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/company_profile/capital_fund_range',
          )}
          component={CapitalFundReport}
        />
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/company_profile/office_number_range',
          )}
          component={OfficeNumberReport}
        />
        <Route
          path={createPath(
            'projects/:projectId/form_mail_reports/company_profile/established_year_range',
          )}
          component={EstablishedYearReport}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  padding: 30px;
  width: 950px;
  height: 80vh;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? '#222426' : '#BDC4CB')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid #495058' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;
