import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M3 19V1H17V19H19H12.5M1 19H7.5M7.5 19V13.5H12.5V19M7.5 19H12.5M11.5 8.5V10.5H14V8.5H11.5ZM6 8.5V10.5H8.5V8.5H6ZM6 4V6H8.5V4H6ZM11.5 4V6H14V4H11.5Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
        />
        <ellipse cx="16" cy="3.99902" rx="6" ry="6" fill="white" />
        <path
          d="M16.0003 7.33366C17.8413 7.33366 19.3337 5.84127 19.3337 4.00033C19.3337 2.15938 17.8413 0.666992 16.0003 0.666992C14.1594 0.666992 12.667 2.15938 12.667 4.00033C12.667 5.84127 14.1594 7.33366 16.0003 7.33366Z"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 4H19.3333"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 0.734375C16.8248 1.57607 17.3333 2.72883 17.3333 4.00037C17.3333 5.27191 16.8248 6.42467 16 7.26634"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0003 0.734375C15.1755 1.57607 14.667 2.72883 14.667 4.00037C14.667 5.27191 15.1755 6.42467 16.0003 7.26634"
          stroke="#BDC4CB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
