import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowSearchConditionMutation,
  useWorkflowQuery,
  useSalesforceLeadRecordTypesQuery,
  SalesforceLeadRecordType,
} from 'api';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {workflowId} = useParams<{
    workflowId: string;
  }>();

  const {isMember} = useClientUser();
  const {data: {salesforceLeadRecordTypes = []} = {}} =
    useSalesforceLeadRecordTypesQuery({});

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <div className="flex">
      <div className="font-medium text-base leading-8 tracking-[.15px] text-[#495058] pr-2 w-28">
        レコードタイプ
      </div>
      <div className="z-40 w-[40%]">
        <select
          disabled={!isConditionUpdatable || !isMember}
          value={searchCondition['salesforceLeadRecordTypeId']}
          onChange={(e) =>
            updateWorkflowSearchCondition({
              variables: {
                uuid: workflowId,
                attributes: {
                  salesforceLeadRecordTypeId: e.target.value,
                  salesforceLeadFieldSelectStatusIds: [],
                  salesforceLeadSearchConditions: [],
                },
              },
            })
          }
          className="form-select border-c-border rounded text-base w-full">
          <option value={null} />
          {salesforceLeadRecordTypes.map(
            (salesforceLeadRecordType: SalesforceLeadRecordType) => {
              return (
                <option
                  key={salesforceLeadRecordType.id}
                  value={salesforceLeadRecordType.id}>
                  {salesforceLeadRecordType.name}
                </option>
              );
            },
          )}
        </select>
      </div>
    </div>
  );
};
