import React, {Fragment} from 'react';
import {Dialog, Transition, Listbox} from '@headlessui/react';
import telCategoryColor from 'helpers/telCategoryColor';
import {Quill, QuillWrapper, StatusEclipse} from 'components/Ui';
import {
  Activity,
  MiitelCallPreleadActivity,
  useProjectQuery,
  useUpdateMiitelCallPreleadActivityMutation,
} from 'api';
import ReceptionNgModal from 'components/Prelead/Tel/ReceptionNgModal';
import ContactNgModal from 'components/Prelead/Tel/ContactNgModal';
import CallNgModal from 'components/Prelead/Tel/CallNgModal';
import {useParams} from 'react-router-dom';
import _ from 'lodash';

interface ActivityType extends Activity {
  resource: MiitelCallPreleadActivity;
}
interface Props {
  isOpen: boolean;
  closeModal: () => void;
  activity: ActivityType;
}

const UpdateModalPreleadProject = ({isOpen, closeModal, activity}: Props) => {
  const {projectId} = useParams<{
    projectId: string;
  }>();

  const {data: {project = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const {data: {project: {telStatuses = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: project.uuid},
    skip: !project,
  });

  const [comment, setComment] = React.useState('');
  const [bodyPlainText, setBodyPlainText] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const telStatus = React.useMemo(
    () => telStatuses.find((telStatus) => telStatus.id === status),
    [status],
  );
  const [ngCallModalVisible, setCallNgModalVisible] = React.useState(false);
  const [callNgReason, setCallNgReason] = React.useState(null);
  const [ngReceptionModalVisible, setReceptionNgModalVisible] =
    React.useState(false);
  const [receptionNgReason, setReceptionNgReason] = React.useState(null);
  const [ngContactModalVisible, setContactNgModalVisible] =
    React.useState(false);
  const [contactNgReason, setContactNgReason] = React.useState(null);

  const [updateActivity] = useUpdateMiitelCallPreleadActivityMutation({
    variables: {
      resourceId: activity.resource.id,
      projectUuid: project.uuid,
      attributes: {
        body: comment === `<p><br></p>` ? '' : comment,
        bodyPlainText: bodyPlainText,
        clientTelStatusId: status,
        projectCallNgReasonId: callNgReason?.id,
        projectReceptionNgReasonId: receptionNgReason?.id,
        projectContactNgReasonId: contactNgReason?.id,
      },
    },
  });

  const handleSubmit = () => {
    updateActivity();
    closeModal();
  };

  React.useEffect(() => {
    if (!status) return;

    const telStatus = telStatuses.find((telStatus) => telStatus.id === status);
    if (!telStatus) return;

    if (telStatus?.category === 'call_ng') {
      setCallNgModalVisible(true);
    } else if (telStatus?.category === 'reception_ng') {
      setReceptionNgModalVisible(true);
    } else if (telStatus?.category === 'contact_ng') {
      setContactNgModalVisible(true);
    }

    setCallNgReason(null);
    setReceptionNgReason(null);
    setContactNgReason(null);
  }, [status]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-xl transform rounded bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h4"
                  className="h-10 flex items-center px-4 bg-c-bg text-c-base">
                  ログを登録
                </Dialog.Title>
                <div className="pt-4 px-6">
                  <div className="mb-4">
                    <label className="text-sm text-c-light">
                      コールステータス
                    </label>
                    <Listbox
                      value={status}
                      onChange={(value) => setStatus(value)}>
                      <div className="relative mt-1 flex-1 z-10">
                        <Listbox.Button className="relative bg-white w-full h-8 cursor-default rounded pl-3 pr-10 text-left focus:outline-none flex items-center border border-c-border">
                          <span className="flex items-center gap-1 flex-1">
                            {telStatus && (
                              <StatusEclipse
                                color={telCategoryColor(telStatus?.category)}
                              />
                            )}
                            &nbsp; {telStatus?.name}
                            {callNgReason && `  |  ${callNgReason.reason}`}
                            {receptionNgReason &&
                              `  |  ${receptionNgReason.reason}`}
                            {contactNgReason &&
                              `  |  ${contactNgReason.reason}`}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-5 h-5">
                              <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0">
                          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {telStatuses?.map((telStatus) => (
                              <Listbox.Option
                                key={telStatus.id}
                                className={({active}) =>
                                  `relative cursor-default select-none py-2 px-3 ${
                                    active ? 'bg-gray-100' : 'bg-white'
                                  }`
                                }
                                value={telStatus.id}>
                                <StatusEclipse
                                  color={telCategoryColor(telStatus.category)}
                                />
                                &nbsp; {telStatus.name}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  <label className="text-sm text-c-light">コメント</label>
                  <QuillWrapper>
                    <Quill
                      placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
                      theme="snow"
                      value={comment}
                      onChange={(content, delta, source, editor) => {
                        setComment(content);
                        setBodyPlainText(editor.getText());
                      }}
                      modules={{
                        toolbar: [
                          [{header: [1, 2, 3, false]}],
                          ['bold', 'italic', 'underline'],
                          ['code-block'],
                          ['link'],
                        ],
                      }}
                      formats={[
                        'header',
                        'list',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'color',
                        'background',
                        'code-block',
                        'link',
                      ]}
                      bounds={'#quill-container'}
                    />
                    <div id="quill-container" />
                  </QuillWrapper>
                </div>
                <div className="flex justify-end items-center p-4">
                  <button
                    disabled={
                      !comment.replace(/<\/?[^>]+(>|$)/g, '') && !status
                    }
                    onClick={handleSubmit}
                    className="cursor-pointer bg-c-primary text-white border-none border text-sm hover:opacity-50 rounded-sm inline-flex items-center justify-center disabled:bg-c-bg disabled:text-c-light disabled:cursor-not-allowed h-8 w-28">
                    登録
                  </button>
                </div>
                {ngCallModalVisible && (
                  <CallNgModal
                    projectId={project?.uuid}
                    isModalVisible={ngCallModalVisible}
                    setModalVisible={setCallNgModalVisible}
                    ngReason={callNgReason}
                    setNgReason={setCallNgReason}
                  />
                )}
                {ngReceptionModalVisible && (
                  <ReceptionNgModal
                    projectId={project?.uuid}
                    isModalVisible={ngReceptionModalVisible}
                    setModalVisible={setReceptionNgModalVisible}
                    ngReason={receptionNgReason}
                    setNgReason={setReceptionNgReason}
                  />
                )}
                {ngContactModalVisible && (
                  <ContactNgModal
                    projectId={project?.uuid}
                    isModalVisible={ngContactModalVisible}
                    setModalVisible={setContactNgModalVisible}
                    ngReason={contactNgReason}
                    setNgReason={setContactNgReason}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateModalPreleadProject;
