import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import {MailCampaign, useMailCampaignsByProjectQuery} from 'api';
import useSearchParams from '../../useSearchParams';
import {useParams} from 'react-router';

const MailCampaignsFilter = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query, setQuery} = useSearchParams();

  const {data: {mailCampaignsByProject: {mailCampaigns = []} = {}} = {}} =
    useMailCampaignsByProjectQuery({variables: {uuid: projectId}});

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.mailCampaignIds}
      onChange={(value) => setQuery({page: 1, mailCampaignIds: value})}>
      {mailCampaigns.map((option: MailCampaign) => (
        <StatusSelect.Option
          value={option.id}
          key={option.id}
          label={<React.Fragment>&nbsp; {option.title}</React.Fragment>}>
          &nbsp; {option.title}
        </StatusSelect.Option>
      ))}
    </Select>
  );
};

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

export default MailCampaignsFilter;
