import gql from 'graphql-tag';
import mainCategoryFragment from '../category/mainCategory';
import subCategoryFragment from '../category/subCategory';

export default gql`
  fragment preleadMasterSearchConditionFragment on PreleadMasterSearchCondition {
    id
    projectId
    mode
    filter {
      projectGroupIds
      projectIds
      statuses
      preleadStatusIds
      mailStatuses
      mailCounts
      mailTerm {
        from
        to
      }
      telStatusIds
      telCounts
      telFrom
      telTo
      clickCounts
      clickTerm {
        from
        to
      }
      pressReleaseSince
      tagIds
      capitalFund {
        from
        to
      }
      accountClosingMonths
      listingMarkets
      officesNumber {
        from
        to
      }
      establishedYearMonth {
        from
        to
      }
      mainCategory {
        ...mainCategoryFragment
      }
      subCategories {
        ...subCategoryFragment
      }
      employeeNumber {
        from
        to
      }
      commentCounts
      sales {
        from
        to
      }
      taskDueDateFrom
      taskDueDateTo
      remindFrom
      remindTo
      mailCampaignOpenCounts
      mailCampaignClickCounts
      mailCampaignSendsCounts
      trackedAtFrom
      trackedAtTo
      taskUserIds
    }
    projectMode
    projectFilters
    searchWord
    sortCategory
    sortOrder
    createdAt
    updatedAt
  }
  ${mainCategoryFragment}
  ${subCategoryFragment}
`;
