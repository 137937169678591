import React from 'react';
import styled from 'styled-components';
import {useRecoilValue} from 'recoil';
import {Radio, Button} from 'components/antd';
import selectCompanyPreleadProjectAtom from '../selectCompanyPreleadProjectAtom';
import {
  usePreleadProjectCompaniesQuery,
  useSelectCompanyPreleadProjectMutation,
} from 'api';

interface Props {
  onCompleted: () => void;
}

export default ({onCompleted}: Props) => {
  const preleadProjectUuid = useRecoilValue(selectCompanyPreleadProjectAtom);
  const [companyUuid, setCompanyUuid] = React.useState(null);

  const {
    data: {preleadProjectCompanies = []} = {},
  } = usePreleadProjectCompaniesQuery({
    variables: {
      uuid: preleadProjectUuid,
    },
  });

  const [selectCompany, {loading}] = useSelectCompanyPreleadProjectMutation({
    variables: {uuid: preleadProjectUuid, companyUuid},
    onCompleted,
  });

  return (
    <Container>
      <Count>
        複数の企業が、<em>{preleadProjectCompanies?.length || 0}件</em>{' '}
        あります。
      </Count>
      <Header>
        <h2>企業を選択</h2>
        <Button
          type="primary"
          size="large"
          disabled={!companyUuid || loading}
          onClick={() => selectCompany()}>
          名寄せする
        </Button>
      </Header>
      <List>
        {preleadProjectCompanies.map((company) => (
          <Item key={company.uuid}>
            <Radio
              checked={company.uuid === companyUuid}
              onChange={() =>
                setCompanyUuid(
                  company.uuid === companyUuid ? null : company.uuid,
                )
              }
            />
            <div>{company.name}</div>
            <div>{company.telephoneNumber}</div>
            <div>{company.address}</div>
            <div>{company.webSite}</div>
          </Item>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`;

const Count = styled.div`
  background: #f3f5f7;
  font-size: 14px;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;

  em {
    font-size: 24px;
    font-style: normal;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
`;

const List = styled.div`
  width: 100%;
  overflow: scroll;
  max-height: 50vh;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-top: solid 1px #e1e6eb;

  &:last-child {
    border-bottom: solid 1px #e1e6eb;
  }

  > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > * {
    &:nth-child(1) {
      width: 30px;
      text-align: center;
    }
    &:nth-child(2) {
      width: 300px;
    }
    &:nth-child(3) {
      width: 100px;
    }
    &:nth-child(4) {
      flex: 1;
    }
    &:nth-child(5) {
      width: 200px;
    }
  }
`;
