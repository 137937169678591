import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import Compnay from './Company';
import Csvfile from './Csvfile';
import Jobs from './Jobs';
import PressRelease from './PressRelease';
import Fiscal from './Fiscal';
import {StringParam, useQueryParam} from 'use-query-params';
import CompanyEdit from './Company/CompanyEdit';
import Attachment from './Attachment';

export default () => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();
  if (!preleadProjectId) return null;

  const [information, setInformation] = useQueryParam(
    'information',
    StringParam,
  );
  if (!information) return null;

  if (information === 'company_edit') {
    return (
      <Container>
        <CompanyEdit onClose={() => setInformation('company')} />
      </Container>
    );
  }

  return (
    <Container>
      <ContentWrapper>
        {information === 'company' && <Compnay />}
        {information === 'csvfile' && <Csvfile />}
        {information === 'jobs' && <Jobs />}
        {information === 'pressrelease' && <PressRelease />}
        {information === 'fiscal' && <Fiscal />}
        {information === 'attachment' && <Attachment />}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #e2e6ea;
`;

const ContentWrapper = styled.div`
  overflow: scroll;
  padding: 20px 30px 0 30px;
`;
