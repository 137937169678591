import moment from 'moment';
import {
  StringParam,
  DelimitedArrayParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const searchParamsDef = {
  projectGroupIds: withDefault(DelimitedArrayParam, []),
  projectIds: withDefault(DelimitedArrayParam, []),
  userIds: withDefault(DelimitedArrayParam, []),
  requestClientId: StringParam,
  offeredProjectIds: withDefault(DelimitedArrayParam, []),
  range: withDefault(StringParam, 'month'),
  date: withDefault(StringParam, moment().format('YYYY-MM-DD')),
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);

  const searchParams = {
    projectGroupIds: [...query.projectGroupIds],
    projectIds: [...query.projectIds, ...query.offeredProjectIds],
    userIds: [...query.userIds],
    requestClientId: query.requestClientId,
    range: query.range,
    date: query.date,
  };

  return {
    query,
    searchParams,
    setQuery,
  };
};

export default useSearchParams;
