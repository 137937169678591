import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import useFilterModalContext from '../useFilterModalContext';
import useCondition from '../../useCondition';
import {SalesforceLead, SalesforceContact} from './Condition';
import {useCurrentClientQuery, useSalesforceContactRecordTypesQuery} from 'api';

type Option = {
  label: string;
  value: string;
  isUnselected: boolean;
};

const SalesforceFilters = () => {
  const {setFilterType} = useFilterModalContext();
  const {condition} = useCondition();

  const {data: {currentClient: {salesforceSetting = {}} = {}} = {}} =
    useCurrentClientQuery();

  const {data: {salesforceContactRecordTypes = []} = {}} =
    useSalesforceContactRecordTypesQuery();

  if (!salesforceSetting?.isActive) {
    return null;
  }

  const recordType = salesforceContactRecordTypes.find(
    (recordType) => recordType.id === condition?.salesforceContactRecordTypeId,
  );

  const options: Option[] = [
    {
      label: 'リード',
      value: 'salesforceLead',
      isUnselected: !condition?.salesforceLeadRecordTypeId,
    },
    {
      label: '取引先責任者',
      value: 'salesforceContact',
      isUnselected: !recordType,
    },
  ];

  const unselectedOptions = options.filter((option) => option.isUnselected);

  return (
    <div className="p-6 bg-white rounded flex items-start gap-4">
      <h3 className="m-0 font-bold text-base w-20">Salesforce</h3>
      <div className="flex-1 flex flex-col gap-4">
        <SalesforceLead />
        <SalesforceContact
          recordType={recordType}
        />
        <Menu as="div" className="relative text-left inline-block">
          <Menu.Button className="cursor-pointer bg-white border border-c-border rounded text-sm flex items-center justify-center h-7 px-4">
            + 検索条件を追加
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute left-0 border border-c-border w-[10rem] rounded bg-white shadow-lg focus:outline-none z-10">
              <div className="px-1 py-1 ">
                {unselectedOptions.map((option, index) => (
                  <Menu.Item key={index}>
                    <button
                      onClick={() => setFilterType(option.value)}
                      className="cursor-pointer bg-white text-left p-2 w-full hover:bg-[#F1F8FF] hover:text-c-primary">
                      {option.label}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default SalesforceFilters;
