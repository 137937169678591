import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Project, useCreatePreleadMutation} from 'api';
import {Cross} from 'components/Ui/Icon';

interface Props {
  project: Project;
  visible: boolean;
  onClose: () => void;
}

const CreatePreleadModal = ({project, visible, onClose}: Props) => {
  const [createPrelead, {loading}] = useCreatePreleadMutation({});

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      name: null,
      webSite: null,
      mailAddress: null,
      telephoneNumber: null,
      corpNumber: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values, {resetForm}) => {
      createPrelead({
        refetchQueries: ['preleadProjects'],
        variables: {
          projectUuid: project.uuid,
          attributes: {
            ...values,
          },
        },
      }).then(() => {
        resetForm();
        onClose();
      });
    },
  });

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" static onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-lg transform rounded bg-white text-left align-middle transition-all py-10 px-14">
                <form onSubmit={formik.handleSubmit}>
                  <Dialog.Title
                    as="h1"
                    className="h-10 flex items-center px-4 text-c-base rounded-t font-bold text-[18px]">
                    企業を個別登録
                  </Dialog.Title>
                  <div
                    className="flex justify-end cursor-pointer relative top-[-34px]"
                    onClick={() => onClose()}>
                    <Cross />
                  </div>
                  <div className="p-4 flex flex-col gap-4 mb-4">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block">
                        <span>
                          会社名
                          <span className="inline-block ml-1 text-[#ff4d4f] leading-none">
                            *
                          </span>
                        </span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('name')}
                        />
                      </label>
                      <label className="block">
                        <span>URL</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('webSite')}
                        />
                      </label>
                      <label className="block">
                        <span>代表電話</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('telephoneNumber')}
                        />
                      </label>
                      <label className="block">
                        <span>代表メール</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('mailAddress')}
                        />
                      </label>
                      <label className="block">
                        <span>法人番号</span>
                        <input
                          type="text"
                          className="form-input text-sm mt-1 block w-full rounded border-c-border shadow-sm"
                          placeholder=""
                          {...formik.getFieldProps('corpNumber')}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-4 gap-2">
                    <button
                      type="submit"
                      disabled={loading || !formik.isValid}
                      className="text-white bg-c-primary hover:opacity-50 rounded-sm py-2 px-4 cursor-pointer disabled:bg-c-bg disabled:text-c-light">
                      登録
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreatePreleadModal;
