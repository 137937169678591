import React from 'react';
import TelIcon from './Tel';
import MailIcon from './Mail';
import CommentIcon from './Comment';
import Tab from './Tab';

interface Props {
  current: string;
  onClick: (id: string) => void;
}

export default ({current, onClick}: Props) => (
  <div className="flex justify-around border-b border-c-border pt-1 px-4">
    <Tab
      active={current === 'call' || !current}
      onClick={() => onClick('call')}>
      <TelIcon />
      TEL
    </Tab>
    <Tab
      active={current === 'manual_mail'}
      onClick={() => onClick('manual_mail')}>
      <MailIcon />
      メール
    </Tab>
    <Tab active={current === 'comment'} onClick={() => onClick('comment')}>
      <CommentIcon />
      コメント
    </Tab>
  </div>
);
