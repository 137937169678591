import React from 'react';
import {useParams} from 'react-router-dom';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useProjectWithCampaignsQuery, CampaignUrl} from 'api';

const ClickedCampaignUrlIdsFilter = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query, setQuery} = useSearchParams();

  const {
    data: {project: {campaigns = []} = {}} = {},
  } = useProjectWithCampaignsQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const campaignUrls = React.useMemo(() => {
    const urls: CampaignUrl[] = [];
    campaigns.forEach((campaign) => {
      campaign.campaignUrls.forEach((campaignUrl) => urls.push(campaignUrl));
    });
    return urls;
  }, [campaigns]);

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.clickedCampaignUrlIds || []}
      onChange={(value) => setQuery({page: 1, clickedCampaignUrlIds: value})}>
      {campaignUrls.map((campaignUrl) => (
        <Select.Option
          value={campaignUrl.id}
          key={campaignUrl.id}
          label={campaignUrl.url}>
          {campaignUrl.url}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ClickedCampaignUrlIdsFilter;
