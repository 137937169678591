import React from 'react';
import {Task} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';
import {Route, Link, useParams, useHistory} from 'react-router-dom';
import Prospect from 'components/Prospect';
import {useUpdateStageProspectPoolMutation} from 'api';
import {LaunchLink} from 'components/Ui/Icon';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const history = useHistory();
  const task = row.original;
  const {prospect, account, prospectPool} = row.original;
  const {poolId} = useParams<{
    poolId: string;
  }>();
  const [updateStage] = useUpdateStageProspectPoolMutation();

  return (
    <Container>
      <Name>
        <h4>
          <Link
            to={{
              pathname: `/pools/${poolId}/action/tasks/${task.uuid}/prospect/${
                task.taskType === 'other' ? 'task' : task.taskType
              }`,
              search: location.search,
              state: {isNew: true},
            }}>
            {prospect.lastName} {prospect.firstName}
          </Link>
        </h4>

        <div className="flex">
          <span>{account.name}</span>
          {account.webSite && (
            <a href={account.webSite} target="_blank">
              <LaunchLink width="12" height="12" />
            </a>
          )}
        </div>
      </Name>
      <Route path={`/pools/:poolId/action/tasks/${task.uuid}/prospect`}>
        <Prospect
          prospectId={prospectPool.uuid}
          poolId={poolId}
          updateStage={updateStage}
          onClose={() =>
            history.push(`/pools/${poolId}/action/tasks${location.search}`)
          }
        />
      </Route>
    </Container>
  );
};

const Container = styled.div`
  font-size: 12px;
`;

const Name = styled.div`
  width: 100%;
  h4 {
    font-size: 14px;
    font-weight: medium;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .flex {
    display: flex;

    span {
      font-size: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 8px;
    }

    svg {
      vertical-align: text-top;
    }
  }
`;
export default Cell;
