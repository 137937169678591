import React from 'react';
import {useRecoilState} from 'recoil';
import checkedPreleadProjectsAtom from '../checkedPreleadProjectsAtom';
import {Tag} from 'components/Ui/Icon';
import styled from 'styled-components';
import AddTagModal from './AddTagModal';

export default () => {
  const [checkedPreleadProjects] = useRecoilState(checkedPreleadProjectsAtom);
  const [modalVisible, setModalVisible] = React.useState(false);

  if (checkedPreleadProjects.length < 1) return null;

  return (
    <>
      <Action onClick={() => setModalVisible(true)}>
        <Tag />
        <span>タグ</span>
      </Action>
      {modalVisible && (
        <AddTagModal
          isModalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )}
    </>
  );
};

const Action = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #68b5fb;

  svg {
    margin-right: 5px;
    position: relative;
    top: -1px;
    > path {
      stroke: #68b5fb;
    }
  }

  span {
    font-size: 12px;
  }
`;
