import React from 'react';
import {AccountGroup, AccountPool, User} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<AccountPool>;
}

const UserCell = ({row}: Props) => {
  const accountPool: AccountPool = row.original;
  const contactPerson: User = accountPool.user;

  return (
    <div className="flex flex-col gap-1 justify-center pl-4 h-full border-l border-c-border">
      <div className="text-sm text-c-light">
        {contactPerson?.lastName} {contactPerson?.firstName}
      </div>
    </div>
  );
};

export default UserCell;
