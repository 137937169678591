import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  useProjectQuery,
  useUpdateProjectColumnMutation,
  useUpdateProjectColumnPositionMutation,
} from 'api';
import {Switch} from 'components/antd';
import {columnDef} from '../usePreleadTable';
import {HorizontalThreeLines} from 'components/Ui/Icon';

export default () => {
  const [columns, setColumns] = React.useState([]);
  const {projectId} = useParams<{projectId: string}>();

  const {data: {project: {projectColumns = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });

  const [updateProjectColumn] = useUpdateProjectColumnMutation();
  const [updateProjectColumnPosition] =
    useUpdateProjectColumnPositionMutation();

  const fixedColumn = columns.filter(
    (column) => columnDef[column.dataType]?.sticky,
  );
  const draggableColumn = columns.filter(
    (column) =>
      columnDef[column.dataType] && !columnDef[column.dataType]?.sticky,
  );

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (!result.source || !result.destination) {
        return;
      }

      const sourceIndex = result.source.index + fixedColumn.length;
      const destIndex = result.destination.index + fixedColumn.length;

      const items = [...columns];
      const [removed] = items.splice(sourceIndex, 1);
      items.splice(destIndex, 0, removed);

      setColumns(items);
      updateProjectColumnPosition({
        variables: {id: removed.id, position: destIndex},
      });
    },
    [columns, fixedColumn],
  );

  React.useEffect(() => {
    setColumns(projectColumns);
  }, [projectColumns]);

  return (
    <Container>
      <h4>表示項目</h4>
      <Columns>
        {fixedColumn.map((column) => (
          <ColumnItem key={column.id}>
            <label>
              <div />
              {column.title}
            </label>
            <Switch
              size="small"
              checked={column.isDisplay}
              disabled={column.dataType === 'prelead_uuid'}
              onChange={(checked) =>
                updateProjectColumn({
                  variables: {
                    id: column.id,
                    attributes: {isDisplay: checked},
                  },
                })
              }
            />
          </ColumnItem>
        ))}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {draggableColumn.map((column, index) => (
                  <Draggable
                    key={column.id}
                    draggableId={column.id}
                    index={index}>
                    {(provided, snapshot) => (
                      <ColumnItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={snapshot.isDragging ? 'dragging' : ''}>
                        <label>
                          <div {...provided.dragHandleProps}>
                            <HorizontalThreeLines />
                          </div>
                          {column.title}
                        </label>
                        <Switch
                          size="small"
                          checked={column.isDisplay}
                          onChange={(checked) =>
                            updateProjectColumn({
                              variables: {
                                id: column.id,
                                attributes: {isDisplay: checked},
                              },
                            })
                          }
                        />
                      </ColumnItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Columns>
    </Container>
  );
};

const Container = styled.div`
  width: 300px;
  font-size: 12px;
  padding: 1.5rem;

  h4 {
    font-size: 12px;
    font-weight: bold;
    color: #bdc4cb;
  }
`;

const Columns = styled.div`
  margin-top: 1rem;
  max-height: 60vh;
  overflow: scroll;
`;

const ColumnItem = styled.div`
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;

  > label {
    margin: 0 !important;
    font-weight: bold;
    display: flex;
    align-items: center;

    div {
      width: 20px;

      svg:hover {
        cursor: grab;
      }
    }
  }
`;
