import React from 'react';
import {Button} from '../Button';
import {ButtonGroup} from '../ButtonGroup';
import {StringParam, useQueryParams} from 'use-query-params';
import {MonthInput} from './MonthInput';
import {MonthRangeInput} from './MonthRangeInput';
import moment from 'moment';
import {useCurrentUserQuery} from 'api';

export const TimeScaleSearch: React.FC = () => {
  const [query, setQuery] = useQueryParams({
    timeScale: StringParam,
    month: StringParam,
  });
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});

  const targetMonth = query.month ?? moment().local().format('YYYY-MM');

  return (
    <>
      <ButtonGroup>
        {currentUser?.role === 'admin' && (
          <>
            <Button
              active={query.timeScale === 'month'}
              onClick={() => setQuery({timeScale: 'month'})}>
              月
            </Button>
            <Button
              active={query.timeScale === 'week'}
              onClick={() =>
                setQuery(
                  {
                    timeScale: 'week',
                    month: targetMonth,
                  },
                  'replace',
                )
              }>
              週
            </Button>
          </>
        )}
        <Button
          active={query.timeScale === 'day'}
          onClick={() =>
            setQuery(
              {
                timeScale: 'day',
                month: targetMonth,
              },
              'replace',
            )
          }>
          日
        </Button>
      </ButtonGroup>
      {query.timeScale === 'month' && <MonthRangeInput />}
      {query.timeScale === 'week' && <MonthInput />}
      {query.timeScale === 'day' && <MonthInput />}
    </>
  );
};
