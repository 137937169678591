import React from 'react';
import {Select} from 'components/antd';
import {
  usePoolProspectCustomizeItemsQuery,
  useUpdateWorkflowSearchConditionMutation,
  useWorkflowQuery,
} from 'api';
import {useParams} from 'react-router-dom';
import {CustomizeItemSearchConditionType} from '../CustomizeItemSearchConditionType';

interface Props {
  prospectCustomizeItemSearchCondition: CustomizeItemSearchConditionType;
  index: number;
}

export default ({prospectCustomizeItemSearchCondition, index}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});
  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const alreadySelectedItemIds = (): string[] => {
    return (
      searchCondition?.prospectCustomizeItemSearchConditions
        ?.map((condition) => condition['customize_item_id'])
        ?.filter((item) => item) || []
    );
  };

  const filteredCustomizeItems = React.useMemo(() => {
    return (
      poolProspectCustomizeItems.filter(
        (customizeItem) =>
          (customizeItem.dataType === 'select' ||
            customizeItem.dataType === `date`) &&
          customizeItem.isDisplay,
      ) || []
    );
  }, [poolProspectCustomizeItems]);

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const customizeItemfieldType = (id: string) => {
    return poolProspectCustomizeItems.find((item) => item.id === id).dataType;
  };

  const [update] = useUpdateWorkflowSearchConditionMutation({
    refetchQueries: ['workflowProspectPoolCountsByStatus'],
  });
  return (
    <Select
      style={{width: 200}}
      disabled={!isConditionUpdatable}
      className="mr-2"
      onChange={(value) => {
        const oldSearchCondition =
          searchCondition.prospectCustomizeItemSearchConditions;
        update({
          variables: {
            uuid: workflowId,
            attributes: {
              prospectCustomizeItemSearchConditions: [
                ...oldSearchCondition.slice(0, index),
                {
                  customize_item_id: value,
                  data_type: customizeItemfieldType(value),
                },
                ...oldSearchCondition.slice(index + 1),
              ],
            },
          },
        });
      }}
      value={prospectCustomizeItemSearchCondition?.customize_item_id || ''}>
      {filteredCustomizeItems?.map((filteredCustomizeItem) => {
        return (
          <Select.Option
            key={filteredCustomizeItem.id}
            hidden={alreadySelectedItemIds().includes(filteredCustomizeItem.id)}
            value={filteredCustomizeItem.id}>
            {filteredCustomizeItem.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};
