import React, {FC} from 'react';

type GridProps = {
  columns: number;
};

const Grid: FC<GridProps> = ({children, columns}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        gap: 24,
        margin: '16px 32px',
        maxWidth: '100%',
      }}>
      {children}
    </div>
  );
};

export default Grid;
