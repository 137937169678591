import React from 'react';
import {useParams} from 'react-router-dom';
import {useProjectWithPreleadMasterSearchConditionQuery} from 'api';
import {Sort} from 'components/Ui/Icon';
import {
  Container,
  Category,
  CategoryTitle,
  Item,
  Title,
  TagBox,
  TagItem,
} from './styles';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project: {preleadMasterSearchCondition = null} = {}} = {}} =
    useProjectWithPreleadMasterSearchConditionQuery({
      variables: {uuid: projectId},
      skip: !projectId,
      fetchPolicy: 'cache-and-network',
    });

  const sortOrders: any = {
    asc: '少ない順（昇順）',
    desc: '多い順（降順）',
  };

  const sortCategories: any = {
    activity_updated_at: '最終更新日',
    click_count: 'フォーム送信クリック回数',
    mail_count: 'フォーム送信数',
    mail_updated_at: 'フォーム送信期間',
    tel_count: 'TEL件数',
    tel_updated_at: 'TEL更新日',
    capital_fund: '資本金',
    offices_number: '事業所数',
  };

  if (!preleadMasterSearchCondition) return null;

  return (
    <Container>
      {preleadMasterSearchCondition.sortCategory &&
        preleadMasterSearchCondition.sortOrder && (
          <Category>
            <CategoryTitle>
              <Sort />
              並び替え条件
            </CategoryTitle>
            <Item>
              <Title>
                {sortCategories[preleadMasterSearchCondition.sortCategory]}
              </Title>
              <TagBox>
                <TagItem>
                  {sortOrders[preleadMasterSearchCondition.sortOrder]}
                </TagItem>
              </TagBox>
            </Item>
          </Category>
        )}
    </Container>
  );
};
