import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  Modal,
  Input,
  Checkbox,
  Form,
  DatePicker,
  Select,
} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useCreateSalesforceDealSalesforceContactMutation,
  useSalesforceDealRecordTypesQuery,
  useSalesforceUsersQuery,
  useSalesforceOpportunityStagesQuery,
  ProspectPool,
  SalesforceDealField,
} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';
import useProspectContext from '../../useProspectContext'

interface Props {
  prospectPool: ProspectPool;
  visible: boolean;
  onClose: () => void;
}

interface FormikValues {
  name: string;
  salesforceUserId: string;
  salesforceOpportunityStageId: string;
  closeDate: moment.Moment;
  salesforceDealRecordTypeId: string;
  addition: {[key: string]: any};
}

const NewDealModalComponent = ({
  prospectPool = {},
  visible,
  onClose,
}: Props) => {
  const [selectedRecordType, setSelectedRecordType] = React.useState(null);

  const {poolId} = useProspectContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  const {data: {salesforceOpportunityStages = []} = {}} =
    useSalesforceOpportunityStagesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const [create, {loading, data}] =
    useCreateSalesforceDealSalesforceContactMutation({
      onCompleted: (data) => {
        if (!data.createSalesforceDealSalesforceContact.error) {
          formik.resetForm();
          onClose();
        }
      },
      refetchQueries: ['prospectPool'],
    });

  const today = React.useMemo(() => moment(), []);

  const initialValues: FormikValues = {
    name: '',
    salesforceUserId: salesforceUsers.length > 0 ? salesforceUsers[0].id : '',
    salesforceOpportunityStageId:
      salesforceOpportunityStages.length > 0
        ? salesforceOpportunityStages[0].id
        : '',
    closeDate: today,
    salesforceDealRecordTypeId: '',
    addition: {},
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      salesforceUserId: Yup.string().required('必須項目です'),
      salesforceOpportunityStageId: Yup.string().required('必須項目です'),
      closeDate: Yup.date().required('必須項目です'),
    }),
    onSubmit: (values) => {
      const copy = Object.assign({}, values);
      create({variables: {uuid: prospectPool.uuid, attributes: copy}});
    },
  });

  const onOk = React.useCallback(() => formik.submitForm(), []);

  const onCancel = React.useCallback(() => {
    formik.resetForm();
    onClose();
  }, []);

  const onRecordTypeSelect = (id: string) => {
    const selectedRecordType = salesforceDealRecordTypes.find(
      (recordType) => recordType.id === id,
    );
    formik.setFieldValue('addition', {});
    setSelectedRecordType(selectedRecordType);
  };

  const salesforceDealFields = React.useMemo(() => {
    if (!selectedRecordType) {
      return [];
    }
    const visibleFields = selectedRecordType?.salesforceDealFields.filter(
      (salesforceDealField: SalesforceDealField) =>
        salesforceDealField.isDisplay === true,
    );

    return visibleFields;
  }, [selectedRecordType]);

  const dealFieldItem = (field: SalesforceDealField, index: number) => {
    return (
      <Form.Item key={index} label={field.label} required={field.isRequired}>
        {field?.fieldType === 'checkbox' ? (
          <Checkbox
            checked={formik.values.addition[`${field.fieldName}`] || false}
            onChange={(e) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = e.target.checked;
              formik.setFieldValue('addition', addition);
            }}
          />
        ) : field?.fieldType === 'date' ? (
          <Input
            type="date"
            value={formik.values.addition[`${field.fieldName}`]}
            style={{width: '200px'}}
            onChange={(e) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = e.target.value;
              formik.setFieldValue('addition', addition);
            }}
          />
        ) : field?.fieldType === 'select' ? (
          <Select
            value={formik.values.addition[`${field.fieldName}`]}
            onChange={(value) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = value;
              formik.setFieldValue('addition', addition);
            }}
            showSearch
            optionFilterProp="children">
            {field.salesforceDealFieldSelectOptions.map(
              (option) =>
                option?.isActive && (
                  <Select.Option key={option.id} value={option.value}>
                    {option.label}
                  </Select.Option>
                ),
            )}
          </Select>
        ) : field?.fieldType === 'reference' &&
          field?.referenceType === 'user' ? (
          <Select
            value={formik.values.addition[`${field.fieldName}`]}
            onChange={(value) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = value;
              formik.setFieldValue('addition', addition);
            }}
            showSearch
            optionFilterProp="children">
            {salesforceUsers.map((user) => (
              <Select.Option key={user.id} value={user.userId}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input
            value={formik.values.addition[`${field.fieldName}`]}
            onChange={(e) => {
              let addition = formik.values.addition;
              addition[`${field.fieldName}`] = e.target.value;
              formik.setFieldValue('addition', addition);
            }}
          />
        )}
      </Form.Item>
    );
  };

  return (
    <Modal
      title="商談を作成"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText="キャンセル"
      width={480}
      okText="登録"
      okButtonProps={{
        loading,
        disabled: !formik.isValid || !formik.dirty,
      }}
      maskClosable>
      {data?.createSalesforceDealSalesforceContact?.error && (
        <Error>{data?.createSalesforceDealSalesforceContact?.error}</Error>
      )}
      <Form layout="vertical">
        <Form.Item
          label="商談名"
          required
          validateStatus={formik.errors.name && 'error'}
          help={formik.errors.name}>
          <Input {...formik.getFieldProps('name')} />
        </Form.Item>

        <Form.Item
          label="所有者"
          required
          validateStatus={formik.errors.salesforceUserId && 'error'}
          help={formik.errors.salesforceUserId}>
          <Select
            value={formik.values.salesforceUserId}
            showSearch
            optionFilterProp="children"
            onChange={(value) =>
              formik.setFieldValue('salesforceUserId', value)
            }>
            {salesforceUsers.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="フェーズ"
          required
          validateStatus={formik.errors.salesforceOpportunityStageId && 'error'}
          help={formik.errors.salesforceOpportunityStageId}>
          <Select
            value={formik.values.salesforceOpportunityStageId}
            onChange={(value) =>
              formik.setFieldValue('salesforceOpportunityStageId', value)
            }>
            {salesforceOpportunityStages.map((stage) => (
              <Select.Option key={stage.id} value={stage.id}>
                {stage.masterLabel}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="完了予定日"
          required
          validateStatus={formik.errors.closeDate && 'error'}
          help={formik.errors.closeDate}>
          <DatePicker
            value={formik.values.closeDate}
            locale={locale}
            style={{width: '200px'}}
            onChange={(date) => formik.setFieldValue('closeDate', date)}
          />
        </Form.Item>

        {salesforceDealRecordTypes.length > 0 && (
          <Form.Item label="レコードタイプ">
            <Select
              onChange={(value: string) => {
                formik.setFieldValue('salesforceDealRecordTypeId', value);
                onRecordTypeSelect(value);
              }}>
              {salesforceDealRecordTypes.map((recordType) => (
                <Select.Option key={recordType.id} value={recordType.id}>
                  {recordType.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {salesforceDealFields.map((field: SalesforceDealField, i: number) =>
          dealFieldItem(field, i),
        )}
      </Form>
    </Modal>
  );
};

const Error = styled.div`
  color: red;
  margin-bottom: 8px;
`;

export default NewDealModalComponent;
