import React from 'react';
import {TelBadge} from 'components/Ui/Icon';
import {Activity, MiitelCallActivity} from 'api';
import telCategoryColors from 'helpers/telCategoryColor';
import useProjectName from '../useProjectName';
import {StatusLabel} from '../Styles';
import MiitelCall from './MiitelCall';
import linkifyHtml from 'linkify-html';

interface ActivityType extends Activity {
  resource: MiitelCallActivity;
}

interface Props {
  activity: ActivityType;
}

const TelActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  const {
    resource: {
      clientTelStatus,
      callNgReason,
      receptionNgReason,
      contactNgReason,
      body,
    } = {},
  } = activity;

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <div className="ml-[-30px] relative border border-solid border-c-border rounded-t bg-white">
        {projectName && (
          <div className="px-4 py-2 text-sm text-c-light">{projectName}</div>
        )}
        <div className="mt-1 flex items-center w-full h-12 px-4">
          <div className="z-10 flex w-8 h-auto mr-4">
            <TelBadge />
          </div>
          <StatusLabel color={telCategoryColors(clientTelStatus?.category)}>
            <span>{clientTelStatus?.name}</span>
            {(callNgReason || receptionNgReason || contactNgReason) && (
              <span>|</span>
            )}
            {callNgReason && <span>{callNgReason.reason}</span>}
            {receptionNgReason && <span>{receptionNgReason.reason}</span>}
            {contactNgReason && <span> {contactNgReason.reason}</span>}
          </StatusLabel>
        </div>
        {body && (
          <div className="px-4 pb-4 text-c-base [&_p]:mb-0">
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: convertUrlToLink(activity.resource.body || ''),
              }}
            />
          </div>
        )}
        <div className="border-t border-solid border-c-border">
          <MiitelCall activity={activity} />
        </div>
      </div>
    </>
  );
};

export default TelActivityComponent;
