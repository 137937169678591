import React from 'react';
import {useMainCategoriesQuery} from 'api';

interface Props {
  row: any;
}

const MainCategoryCell = ({row}: Props) => {
  const prelead = row.original.prelead;
  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery();

  const targetMainCategories = React.useMemo(
    () =>
      mainCategories
        .filter((mainCategory) =>
          prelead.mainCategoryIds?.includes(mainCategory.id),
        )
        .map((mainCategory) => mainCategory.displayName)
        .join(', '),
    [mainCategories],
  );

  return <span>{targetMainCategories}</span>;
};

export default MainCategoryCell;
