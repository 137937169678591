import React from 'react';
import styled from 'styled-components';
import {Action} from 'api';
import {Row} from 'react-table';
import {prospectStages} from 'helpers/prospectStages';

interface Props {
  row: Row<Action>;
}

const StageCell = ({row}: Props) => {
  const {
    workflowProspectPool: {prospectPool},
  } = row.original;

  const stage = prospectStages.find(
    (stage) => stage.name == prospectPool?.stage,
  );

  return (
    <Stage color={stage.color} bg={stage.bg}>
      {stage.displayName}
    </Stage>
  );
};

const Stage = styled.span<{color: string; bg: string}>`
  width: 80px;
  height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: ${({color}) => color};
  background-color: ${({bg}) => bg};
`;

export default StageCell;
