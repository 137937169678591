import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input} from 'components/antd';
import {
  useUpdateProspectTelStatusCategoryMutation,
  ProspectTelStatusCategory,
  useCheckProspectTelStatusCategoryDeletableQuery,
  useDestroyProspectTelStatusCategoryMutation,
} from 'api';
import {telStatusCategoryColors} from 'helpers/telStatusCategoryColors';
import {CheckIcon} from '@heroicons/react/20/solid';

interface Props {
  category: ProspectTelStatusCategory;
  onClose: () => void;
}

const Update = ({category, onClose}: Props) => {
  const [updateProspectTelStatusCategory] =
    useUpdateProspectTelStatusCategoryMutation({
      onCompleted: () => onClose(),
    });

  const [destroyProspectTelStatusCategory] =
    useDestroyProspectTelStatusCategoryMutation({
      variables: {
        id: category?.id,
      },
      onCompleted: () => onClose(),
      refetchQueries: ['prospectTelStatusCategories'],
    });

  const {data: {checkProspectTelStatusCategoryDeletable = false} = {}} =
    useCheckProspectTelStatusCategoryDeletableQuery({
      variables: {
        id: category?.id,
      },
      skip: !category,
    });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name,
      color: category?.color,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) =>
      updateProspectTelStatusCategory({
        variables: {
          id: category.id,
          attributes: {
            ...values,
          },
        },
      }),
  });

  return (
    <Modal
      destroyOnClose
      visible={Boolean(category)}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2 className="pb-0">リードTELステータス種別</h2>
        {!checkProspectTelStatusCategoryDeletable && (
          <p className="text-[#C90E19]">
            ※このステータスは使用されているため削除できません。
          </p>
        )}
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="種別名" required>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="種別の色を選択">
            {telStatusCategoryColors.map((color, index) => (
              <div
                key={index}
                className="w-[34px] h-[34px] rounded-md cursor-pointer inline-block mr-4 mb-2 relative"
                style={{
                  backgroundColor: color,
                }}
                onClick={() => {
                  formik.setFieldValue('color', color);
                }}>
                {formik.values.color === color && (
                  <CheckIcon className="w-6 h-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white" />
                )}
              </div>
            ))}
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <div>
              <Button
                danger
                onClick={() => {
                  destroyProspectTelStatusCategory();
                }}
                disabled={!checkProspectTelStatusCategoryDeletable}
                size="large">
                削除
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{marginLeft: '8px'}}
                disabled={!formik.isValid}>
                保存
              </Button>
            </div>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Update;
