import React from 'react';
import useCondition from '../../../useCondition';
import {useSalesforceDealRecordTypeQuery} from 'api';
import SelectOption from './SelectOption';
import MultiSelectOption from './MultiSelectOption';

export type SelectSearchCondition = {
  field_select_option_id?: string;
  field_type: string;
  deal_field_id: string;
};

export type MultiSelectSearchCondition = {
  values?: string[];
  field_type: string;
  deal_field_id: string;
};

export type CheckboxSearchCondition = {
  value?: string;
  field_type: string;
  deal_field_id: string;
};

type SearchCondition = SelectSearchCondition | CheckboxSearchCondition;

interface Props {
  searchCondition: SearchCondition;
}

export default ({searchCondition}: Props) => {
  const {condition} = useCondition();

  const {
    data: {salesforceDealRecordType: {salesforceDealFields = []} = {}} = {},
  } = useSalesforceDealRecordTypeQuery({
    variables: {
      salesforceDealRecordTypeId: condition.salesforceDealRecordTypeId,
    },
    skip: !condition.salesforceDealRecordTypeId,
  });

  const dealField = salesforceDealFields.find(
    (field) => field.id === searchCondition.deal_field_id,
  );

  if (!dealField) {
    return null;
  }

  return (
    <div className="flex gap-2">
      <span className="text-c-light h-6 flex items-center">
        {dealField.label}：
      </span>
      {searchCondition.field_type === 'select' && (
        <SelectOption
          dealFieldId={searchCondition.deal_field_id}
          value={
            (searchCondition as SelectSearchCondition).field_select_option_id
          }
        />
      )}
      {searchCondition?.field_type === 'multi_select' && (
        <MultiSelectOption
          dealFieldId={searchCondition.deal_field_id}
          values={(searchCondition as MultiSelectSearchCondition)?.values}
        />
      )}
    </div>
  );
};
