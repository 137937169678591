import React from 'react';
import {useParams} from 'react-router-dom';
import useCondition from '../../../useCondition';
import {
  useSalesforceLeadRecordTypesQuery,
  useSalesforceLeadFieldsQuery,
  useSalesforceUsersQuery,
} from 'api';
import SearchCondition from './SearchCondition';

export default () => {
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {condition} = useCondition();

  const {data: {salesforceLeadRecordTypes = []} = {}} =
    useSalesforceLeadRecordTypesQuery();

  const {data: {salesforceLeadFields = []} = {}} = useSalesforceLeadFieldsQuery(
    {
      variables: {
        id: condition.salesforceLeadRecordTypeId,
      },
      skip: !condition.salesforceLeadRecordTypeId,
    },
  );

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {
      poolId: poolId,
    },
    skip: !poolId,
  });

  const recordType = salesforceLeadRecordTypes.find(
    (recordType) => recordType.id === condition?.salesforceLeadRecordTypeId,
  );

  const statusOptions =
    salesforceLeadFields.find((leadField) => leadField.fieldName === 'Status')
      ?.salesforceLeadFieldSelectOptions || [];

  const owners =
    salesforceUsers
      .filter((owner) => condition?.salesforceLeadOwnerIds?.includes(owner.id))
      .map((user) => ({
        id: user.id,
        label: user.name,
      })) || [];

  if (
    !condition.hasSalesforceLead &&
    !condition?.salesforceLeadRecordTypeId &&
    !(condition?.salesforceLeadOwnerIds?.length > 0) &&
    !(condition?.salesforceLeadFieldSelectStatusIds?.length > 0) &&
    !(condition?.salesforceLeadSearchConditions?.length > 0)
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">リード</h4>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-c-light">レコードタイプ：</span>
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          {recordType?.name}
        </span>
      </div>
      {statusOptions.length > 0 && (
        <div className="flex gap-2">
          <span className="text-c-light h-6 flex items-center">
            リード状況：
          </span>
          <div className="flex-1 flex flex-wrap items-center gap-1">
            {statusOptions
              .filter((status) =>
                condition?.salesforceLeadFieldSelectStatusIds?.includes(
                  status.id,
                ),
              )
              .map((status) => (
                <span
                  key={status.id}
                  className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                  {status.label}
                </span>
              ))}
          </div>
        </div>
      )}
      {owners.length > 0 && (
        <div className="flex gap-2">
          <span className="text-c-light h-6 flex items-center">所有者：</span>
          <div className="flex-1 flex flex-wrap items-center gap-1">
            {owners.map((owner) => (
              <span
                key={owner.id}
                className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {owner.label}
              </span>
            ))}
          </div>
        </div>
      )}
      {condition.salesforceLeadSearchConditions?.length > 0 &&
        condition.salesforceLeadSearchConditions?.map((searchCondition, i) => (
          <SearchCondition key={i} searchCondition={searchCondition} />
        ))}
    </div>
  );
};
