import React, {useCallback, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {ArrowRightEllipse} from 'components/Ui/Icon';
import {usePreleadProjectQuery, usePreleadProjectsQuery} from 'api';
import useSearchParams from '../useSearchParams';
import styled from 'styled-components';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const history = useHistory();
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();
  const {createPath} = useProjectPath();
  const [direction, setDirection] = useState('');

  const {searchParams, page = 1, perPage, setPage} = useSearchParams();

  const {data: {preleadProjects: {preleadProjects = []} = {}} = {}} =
    usePreleadProjectsQuery({
      variables: {
        uuid: projectId,
        search: searchParams,
        page,
        perPage,
      },
      onCompleted: () => {
        // ページ移動した場合
        const isExisted = preleadProjects.some(
          (item) => item.uuid === preleadProjectId,
        );
        if (isExisted) return;

        if (direction === 'back') {
          goToDetail(preleadProjects[perPage - 1]);
        } else if (direction === 'next') {
          goToDetail(preleadProjects[0]);
        }
      },
    });

  const preleadProjectIndex = preleadProjects.findIndex(
    (item) => item.uuid === preleadProjectId,
  );

  const nextPreleadProjectUuid = preleadProjects[preleadProjectIndex + 1]?.uuid;

  const {data: {preleadProject = {}} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    skip: !preleadProjectId,
  });

  usePreleadProjectQuery({
    variables: {uuid: nextPreleadProjectUuid},
    skip: !nextPreleadProjectUuid,
  });

  const goToDetail = (preleadProject: any) => {
    let lastPath;

    switch (window.location.pathname.split('/').pop()) {
      case 'timeline':
        lastPath = 'timeline';
        break;
      case 'mail':
        lastPath = 'mail';
        break;
      case 'new':
        lastPath = 'mail/new';
        break;
      case 'tel':
        lastPath = 'tel';
        break;
      case 'letter':
        lastPath = 'letter';
        break;
      case 'leads':
        lastPath = 'leads';
        break;
    }

    if (preleadProject) {
      history.push(
        createPath(
          `projects/${projectId}/preleads/${preleadProject.uuid}${
            lastPath ? '/' + lastPath : ''
          }${location.search}`,
        ),
      );
    }
  };

  const goToNext = useCallback(() => {
    setDirection('next');

    if (preleadProjectIndex < perPage - 1) {
      const target = preleadProjects[preleadProjectIndex + 1];
      if (!target) return;
      goToDetail(target);
    } else {
      setPage(page + 1);
    }
  }, [preleadProjectIndex, preleadProjects]);

  const goToBack = useCallback(() => {
    setDirection('back');

    if (preleadProjectIndex === 0) {
      if (page === 1) return;
      setPage(page - 1);
    } else {
      const target = preleadProjects[preleadProjectIndex - 1];
      goToDetail(target);
    }
  }, [preleadProjectIndex, preleadProjects]);

  if (preleadProject?.uuid !== preleadProjectId) return null;

  return (
    <Container>
      <div onClick={goToBack}>
        <ArrowRightEllipse />
      </div>

      <div onClick={goToNext}>
        <ArrowRightEllipse />
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-top: auto;
  display: flex;
  padding: 0px 27px 7px 27px;
  width: 100%;
  > div {
    cursor: pointer;
    &:last-child {
      margin-left: auto;
      transform: rotate(180deg);
    }
  }
`;
