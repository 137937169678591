import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import options from 'helpers/countOptions';

const CommentCountsFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.commentCounts || []}
      onChange={(value) => setQuery({page: 1, commentCounts: value})}>
      {options.map((option) => (
        <Select.Option
          value={option.value}
          key={option.value}
          label={option.text}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CommentCountsFilter;
