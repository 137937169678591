import gql from 'graphql-tag';
import salesforceContactFragment from '../salesforce/salesforceContact';

export default gql`
  fragment salesforceContactLeadCreateProspectActivityFragment on SalesforceContactLeadCreateProspectActivity {
    salesforceContact {
      ...salesforceContactFragment
    }
  }
  ${salesforceContactFragment}
`;
