import React from 'react';
import {Plus, Setting} from 'components/Ui/Icon';
import {ProspectPool, useHubspotContactByProspectPoolQuery} from 'api';
import DealItem from './Item';
import NewDealModal from './NewDealModal';
import DealPropertyModal from './DealPropertyModal';
import useClientUser from 'hooks/useClientUser';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {isMember} = useClientUser();
  const [isNewModalVisible, setIsNewModalVisible] = React.useState(false);
  const [isPropertyModalVisible, setIsPropertyModalVisible] =
    React.useState(false);

  const {data: {hubspotContactByProspectPool} = {}} =
    useHubspotContactByProspectPoolQuery({
      variables: {uuid: prospectPool.uuid},
    });

  return (
    <>
      <div className="flex flex-col gap-2 justify-start">
        {hubspotContactByProspectPool?.hubspotDeals.map((deal) => (
          <DealItem key={deal.id} hubspotDeal={deal} />
        ))}
        <div className="flex gap-1">
          <button
            onClick={() => setIsNewModalVisible(true)}
            className="cursor-pointer bg-white border border-c-border text-sm text-c-text hover:opacity-50 px-4 h-8 rounded inline-flex items-center gap-1">
            <Plus className="w-4 h-4" />
            取引を追加
          </button>
          {isMember && (
            <button
              onClick={() => setIsPropertyModalVisible(true)}
              className="cursor-pointer bg-white border border-c-border text-sm text-c-text hover:opacity-50 h-8 w-8 rounded inline-flex items-center justify-center gap-1">
              <Setting />
            </button>
          )}
        </div>
      </div>
      <NewDealModal
        isOpen={isNewModalVisible}
        closeModal={() => setIsNewModalVisible(false)}
        prospectPool={prospectPool}
      />
      <DealPropertyModal
        isOpen={isPropertyModalVisible}
        closeModal={() => setIsPropertyModalVisible(false)}
      />
    </>
  );
};
