import React from 'react';
import {
  CsvFile,
  Link,
  PhotoImagePicture,
  PdfFile,
  WordFile,
  ExcelFile,
} from '../Ui/Icon';

export default ({extension}: {extension: string}) => {
  switch (extension) {
    case 'csv':
      return <CsvFile />;
    case 'jpg':
      return <PhotoImagePicture />;
    case 'pdf':
      return <PdfFile />;
    case 'docs':
      return <WordFile />;
    case 'xlsx':
      return <ExcelFile />;
    default:
      return <Link />;
  }
};
