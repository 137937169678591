import gql from 'graphql-tag';

export default gql`
  fragment campaignMemberFragment on CampaignMember {
    id
    uuid
    memberNumber
    status
    statusText
    sendDatetimeText
    isDuplicate
    note
    name
    tel
    email
    address1
    category1
    category2
  }
`;
