import React from 'react';
import {ProspectPool} from 'api';
import {Row} from 'react-table';
import {LastAction} from 'components/Cell';

interface Props {
  row: Row<ProspectPool>;
}

export default ({row}: Props) => {
  const {lastAction} = row.original;

  if (!lastAction) return null;

  return <LastAction lastAction={lastAction} />;
};
