import React from 'react';
import Menu from '../Menu';
import WebPush from './WebPush';
import Email from './Email';
import {useCurrentClientQuery} from 'api';

export default () => {
  const {data: {currentClient: {currentTrackingContract = {}} = {}} = {}} =
    useCurrentClientQuery();

  return (
    <div className="h-[calc(100vh-60px)] flex bg-white">
      <Menu />
      <div className="flex-1 flex flex-col p-12 gap-8">
        <h1 className="text-xl font-bold">通知設定</h1>
        <div className="bg-white min-h-[480px] flex flex-col gap-8">
          {currentTrackingContract?.isActive && <WebPush />}
          <Email />
        </div>
      </div>
    </div>
  );
};
