import {Dialog, Transition} from '@headlessui/react';
import {Fragment} from 'react';
import React from 'react';
import {useDestroyAccountGroupAccountMutation, AccountGroupAccount} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
  accountGroupAccount: AccountGroupAccount;
}

const DeleteIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#EB5757" />
      <path
        d="M25.3333 22.9998V20.6664C25.3333 19.3778 26.378 18.3331 27.6667 18.3331H32.3333C33.622 18.3331 34.6667 19.3778 34.6667 20.6664V22.9998M19.5 22.9998H40.5H19.5ZM21.8333 22.9998V39.3331C21.8333 40.6218 22.878 41.6664 24.1667 41.6664H35.8333C37.122 41.6664 38.1667 40.6218 38.1667 39.3331V22.9998H21.8333Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3333 28.8331V35.8331"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6667 28.8331V35.8331"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function MyModal({
  visible,
  onClose,
  accountGroupAccount,
}: Props) {
  const [destroyAccountGroupAccount, {loading}] =
    useDestroyAccountGroupAccountMutation({});
  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-[380px] h-[289px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex items-center justify-center">
                  <div>
                    <div className="flex items-center justify-center">
                      <DeleteIcon />
                    </div>
                    <div className="w-72 h-6 text-lg font-bold text-center">
                      この企業を削除しますか？
                    </div>
                    <div className="w-72 h-6 text-xs font-bold text-center">
                      この操作は取り消せません。
                    </div>
                    <div className="flex gap-5 mt-10">
                      <div
                        className="w-32 h-9 text-[#899098] text-center leading-9 border border-[#BDC4CB] rounded cursor-pointer"
                        onClick={onClose}>
                        キャンセル
                      </div>
                      <button
                        disabled={loading}
                        className="w-32 h-9 bg-[#EB5757] text-white font-bold text-center leading-9 rounded cursor-pointer focus:outline-none"
                        onClick={() => {
                          destroyAccountGroupAccount({
                            refetchQueries: ['accountGroupAccounts'],
                            variables: {
                              id: accountGroupAccount.id,
                            },
                            onCompleted: onClose,
                          });
                        }}>
                        削除
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
