import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {MailBadge, TrashBadge} from 'components/Ui/Icon';
import {
  Activity,
  ResponseStatusChangeActivity,
  useDestroyMailStatusActivityMutation,
} from 'api';
import responseColor from 'helpers/responseColor';
import useProjectName from './useProjectName';
import {useParams} from 'react-router-dom';
import UserName from './UserName';

interface ResponseStatusChange extends Activity {
  resource: ResponseStatusChangeActivity;
}

interface Props {
  activity: ResponseStatusChange;
}

const ResponseStatusChangeActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  const {projectId} = useParams<{projectId: string}>();

  const [destroyMailStatus] = useDestroyMailStatusActivityMutation();

  const isCurrentProjectActivity = projectId === activity.project?.uuid;

  return (
    <>
      {projectName && <Project>{projectName}</Project>}
      <Summary className={isCurrentProjectActivity ? 'deletable' : ''}>
        <BadgeWrapper>
          <MailBadge />
        </BadgeWrapper>
        <UserName activity={activity} />
        <StatusLabel color={responseColor(activity.resource.responseStatus)}>
          {activity.resource.responseStatusText}
        </StatusLabel>
        <TimeStamp>
          {moment(activity.createdAt).format('MM/DD HH:mm')}
        </TimeStamp>
        {isCurrentProjectActivity && (
          <TrashBadgeWrapper
            onClick={() => {
              if (confirm('本当に削除しますか？')) {
                destroyMailStatus({
                  variables: {
                    uuid: activity.uuid,
                  },
                });
              }
            }}>
            <TrashBadge />
          </TrashBadgeWrapper>
        )}
      </Summary>
    </>
  );
};

const TrashBadgeWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: #c90e19;
      }
    }
  }
`;

const Summary = styled.div`
  margin-left: -30px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 46px;
  position: relative;

  ${TrashBadgeWrapper} {
    display: none;
  }

  &:hover {
    &.deletable {
      background: #f3f5f7;
      border-radius: 4px;

      ${TrashBadgeWrapper} {
        display: flex;
        margin-right: 12px;
      }
    }
  }
`;

const Project = styled.div`
  margin-left: 33px;
  font-size: 10px;
  color: #899098;
  position: relative;
  top: 7px;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const StatusLabel = styled.span<{color: string}>`
  background-color: ${({color}) => color};
  border-radius: 20px;
  margin: 0 12px;
  padding: 4px 16px;
  color: white;
  font-size: 10px;
  height: 22px;
`;

const TimeStamp = styled.span``;

export default ResponseStatusChangeActivityComponent;
