import React from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  ContentMail,
  ContentTelScript,
  ContentAudio,
  Check,
} from 'components/Ui/Icon';
import {useParams} from 'react-router';
import MailFormContent from './MailForm';
import AudioContent from './Audio';
import TelScriptContent from './TelScript';
import {
  useProjectWithContentsQuery,
  useUpdateProjectContentsMutation,
  ProjectContentsAttributes,
} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
}

export default ({visible, onClose}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const [contentType, setContentType] = React.useState('');

  const {
    data: {
      project: {
        contentMailFormVersion = null,
        contentAudio = null,
        contentTelScript = null,
      } = {},
    } = {},
  } = useProjectWithContentsQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'cache-and-network',
  });

  const [updateProjectContents] = useUpdateProjectContentsMutation();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values: ProjectContentsAttributes) => {
    updateProjectContents({variables: {uuid: projectId, attributes: values}});
    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mailFormVersionUuid: contentMailFormVersion?.uuid || '',
      audioUuid: contentAudio?.uuid || '',
      telScriptUuid: contentTelScript?.uuid || '',
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: handleSubmit,
  });

  return (
    <ContentModal
      destroyOnClose
      maskClosable={false}
      visible={visible}
      width={690}
      centered
      bodyStyle={{paddingTop: '24px', padding: 0}}
      footer={[
        <Button
          type="primary"
          key="submit"
          disabled={!formik.isValid || !formik.dirty}
          onClick={() => handleSubmit(formik.values)}>
          設定
        </Button>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <ContentTypeColumn>
          <h3>コンテンツ</h3>
          <ContentTypeItem
            active={contentType === 'mailForm'}
            onClick={() => setContentType('mailForm')}>
            <ContentMail />
            <span>フォーム送信</span>
            {formik.values.mailFormVersionUuid && <Check color="#68B5FB" />}
          </ContentTypeItem>
          <ContentTypeItem
            active={contentType === 'audio'}
            onClick={() => setContentType('audio')}>
            <ContentAudio />
            <span>音声ファイル</span>
            {formik.values.audioUuid && <Check color="#68B5FB" />}
          </ContentTypeItem>
          <ContentTypeItem
            active={contentType === 'telScript'}
            onClick={() => setContentType('telScript')}>
            <ContentTelScript />
            <span>TELスクリプト</span>
            {formik.values.telScriptUuid && <Check color="#68B5FB" />}
          </ContentTypeItem>
        </ContentTypeColumn>
        <ContentColumn>
          {contentType === 'mailForm' && (
            <MailFormContent
              contentMailFormVersion={contentMailFormVersion}
              formik={formik}
            />
          )}
          {contentType === 'audio' && (
            <AudioContent contentAudio={contentAudio} formik={formik} />
          )}
          {contentType === 'telScript' && (
            <TelScriptContent
              contentTelScript={contentTelScript}
              formik={formik}
            />
          )}
        </ContentColumn>
      </ModalContent>
    </ContentModal>
  );
};

const ContentModal = styled(Modal)`
  &&& {
    .ant-modal-footer {
      border: none;
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  padding: 2rem;
`;

const ContentTypeColumn = styled.div`
  width: 200px;
  border-right: solid 1px #bdc4cb;
  min-height: 240px;

  h3 {
    font-size: 12px;
    font-weight: bold;
    color: #899098;
    margin-bottom: 1rem;
    padding-left: 10px;
  }
`;

const ContentColumn = styled.div`
  padding-left: 2rem;
  flex: 1;
`;

const ContentTypeItem = styled.div<{active?: boolean}>`
  padding: 0 10px;
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    flex: 1;
  }

  svg:first-child {
    width: 20px;
    height: auto;
    margin-right: 0.5rem;
  }

  svg:nth-child(3) {
    width: 1rem;
    height: auto;
    margin-right: 1rem;
  }

  background: ${({active}) => (active ? '#EAF2F9' : '#fff')};
  color: ${({active}) => (active ? '#68B5FB' : 'inherit')};
  font-weight: ${({active}) => (active ? 'bold' : 'normal')};

  &:hover {
    background: #eaf2f9;
  }
`;
