import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useWorkflowQuery,
  useSalesforceContactRecordTypeByRecordTypeIdQuery,
  SalesforceContactField,
  useUpdateWorkflowSalesforceContactSelectSearchConditionMutation,
} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  contact_field_id: string;
};

const ContactField: React.FC<{condition: SelectSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const [updateWorkflowSalesforceContactSelectSearchCondition] =
    useUpdateWorkflowSalesforceContactSelectSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const {
    data: {
      salesforceContactRecordTypeByRecordTypeId: {
        salesforceContactFields = [],
      } = {},
    } = {},
  } = useSalesforceContactRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceContactRecordTypeId:
        searchCondition?.salesforceContactRecordTypeId,
    },
    skip: !searchCondition.salesforceContactRecordTypeId,
  });

  const contactFieldOptions = () => {
    return salesforceContactFields
      .filter(
        (item: SalesforceContactField) =>
          item.fieldType === 'select' && item.fieldName.endsWith('__c'),
      )
      .map((item: SalesforceContactField) => {
        return {
          value: item.id,
          label: item.label,
          fieldtype: item.fieldType,
        };
      });
  };

  const alreadyAdded = (value: string): boolean => {
    const fields =
      searchCondition?.salesforceContactSearchConditions?.map(
        (item: SelectSearchCondition) => item.contact_field_id,
      ) || [];
    return fields.includes(value);
  };

  return (
    <Select
      style={{width: 200}}
      className="mr-2"
      disabled={!isConditionUpdatable || !isMember}
      value={condition.contact_field_id || ''}
      onChange={(value: string) => {
        updateWorkflowSalesforceContactSelectSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              contactFieldId: value,
              fieldType: 'select',
              fieldSelectOptionId: null,
            },
          },
          refetchQueries: ['workflow'],
        });
      }}>
      {contactFieldOptions().map((option) => (
        <Select.Option
          key={option.value}
          value={option.value}
          hidden={alreadyAdded(option.value)}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ContactField;
