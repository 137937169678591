import React from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {Form, FormBlockFieldStandard} from 'api';
import {
  Bars3BottomLeftIcon,
  HashtagIcon,
  TagIcon,
} from '@heroicons/react/24/outline';

const standardFieldBlocks = [
  {
    blockType: 'standardField',
    standardFieldType: 'name',
    label: '姓名',
    icon: <Bars3BottomLeftIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'account_name',
    label: '会社名',
    icon: <Bars3BottomLeftIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'email',
    label: 'メールアドレス',
    icon: <Bars3BottomLeftIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'telephone_number',
    label: '携帯電話番号',
    icon: <HashtagIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'section',
    label: '部署名',
    icon: <Bars3BottomLeftIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'section_position',
    label: '役職名',
    icon: <TagIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'web_site',
    label: 'ウェブサイトURL',
    icon: <Bars3BottomLeftIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'address',
    label: '住所',
    icon: <HashtagIcon />,
  },
  {
    blockType: 'standardField',
    standardFieldType: 'account_telephone_number',
    label: '代表電話',
    icon: <TagIcon />,
  },
];

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const fieldTypes = React.useMemo(
    () =>
      form?.formPages
        ? form.formPages
            .map((page) => page.formBlocks)
            .flat()
            .filter((block) => block.fieldType === 'standard')
            .map(
              (block) =>
                (block.formBlockable as FormBlockFieldStandard)
                  .standardFieldType,
            )
        : [],
    [form],
  );

  const blocks = React.useMemo(
    () =>
      standardFieldBlocks.map((block) => ({
        ...block,
        disabled: fieldTypes.includes(block.standardFieldType),
      })),
    [fieldTypes],
  );

  return (
    <div className="my-4">
      <h4 className="font-bold text-base">標準項目</h4>
      <Container
        groupName="formBlocks"
        behaviour="copy"
        getChildPayload={(i) => standardFieldBlocks[i]}>
        {blocks.map((b) =>
          b.disabled ? (
            <div
              key={b.standardFieldType}
              className="draggable-item flex px-4 h-10 mt-1 border border-c-border rounded-sm items-center bg-white text-c-lighter gap-3">
              <span className="w-3">{b.icon}</span>
              <span>{b.label}</span>
            </div>
          ) : (
            <Draggable key={b.standardFieldType}>
              <div className="draggable-item cursor-move flex px-4 h-10 mt-1 border border-c-border rounded-sm items-center bg-white gap-3">
                <span className="w-3">{b.icon}</span>
                <span>{b.label}</span>
              </div>
            </Draggable>
          ),
        )}
      </Container>
    </div>
  );
};
