import React from 'react';
import {Form, useUpdateFormMutation, useCurrentClientQuery} from 'api';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import debounce from 'helpers/debounce';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {data: {currentClient: {currentTrackingContract = {}} = {}} = {}} =
    useCurrentClientQuery();
  const [updateForm] = useUpdateFormMutation({refetchQueries: ['form']});

  const {
    register,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        redirectUrl: Yup.string()
          .url('不正なURLです。https://xxx.yyy.zzzの形式で入力してください。')
          .optional()
          .nullable(),
        enableRedirectUrlTracking: Yup.boolean(),
      }),
    ),
    defaultValues: {
      redirectUrl: form.redirectUrl,
      enableRedirectUrlTracking: form.enableRedirectUrlTracking,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: any) => {
    updateForm({
      variables: {
        uuid: form.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <div className="flex flex-col gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          外部リンクを追加
        </label>
        <input
          className="form-input text-base block w-full border border-c-border rounded"
          {...register('redirectUrl')}
        />
        {errors?.redirectUrl && (
          <div className="text-red-500">{errors.redirectUrl.message}</div>
        )}
      </div>
      {currentTrackingContract?.isActive && (
        <div className="flex flex-col gap-1">
          <label className="flex items-center text-base">
            <input
              type="checkbox"
              disabled={!form.redirectUrl}
              className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-gray-100"
              {...register('enableRedirectUrlTracking')}
            />
            <span className="ml-2">トラッキングを有効にする</span>
          </label>
        </div>
      )}
    </>
  );
};
