import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M17.5789 2H2.42105L1 6.82759C1.47368 9.72414 5.26316 9.72414 5.73684 6.82759C6.21053 9.72414 9.52632 9.72414 10 6.82759C10.4737 9.72414 14.2632 9.72414 14.7368 6.82759C15.2105 9.72414 18.5263 9.72414 19 6.82759L17.5789 2Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
        />
        <path
          d="M2 9V18H8V13.2629H12V18H18V9"
          stroke="#BDC4CB"
          strokeWidth="1.5"
        />
        <g clipPath="url(#clip1)">
          <ellipse cx="16.123" cy="3.99902" rx="6" ry="6" fill="white" />
          <g clipPath="url(#clip2)">
            <path
              d="M16.1234 7.33366C17.9643 7.33366 19.4567 5.84127 19.4567 4.00033C19.4567 2.15938 17.9643 0.666992 16.1234 0.666992C14.2824 0.666992 12.79 2.15938 12.79 4.00033C12.79 5.84127 14.2824 7.33366 16.1234 7.33366Z"
              stroke="#BDC4CB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.123 4H19.4564"
              stroke="#BDC4CB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.123 0.734375C16.9478 1.57607 17.4564 2.72883 17.4564 4.00037C17.4564 5.27191 16.9478 6.42467 16.123 7.26634"
              stroke="#BDC4CB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.1234 0.734375C15.2986 1.57607 14.79 2.72883 14.79 4.00037C14.79 5.27191 15.2986 6.42467 16.1234 7.26634"
              stroke="#BDC4CB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="13"
            height="13"
            fill="white"
            transform="translate(10.123 -3)"
          />
        </clipPath>
        <clipPath id="clip2">
          <rect
            width="8"
            height="8"
            fill="white"
            transform="translate(12.123)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
