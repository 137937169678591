import React from 'react';

function Icon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.45 1.875H14.55C15.45 1.875 16.125 2.475 16.125 3.3V14.775C16.125 15.525 15.45 16.2 14.55 16.2H3.45C2.55 16.2 1.875 15.6 1.875 14.775V3.3C1.875 2.475 2.55 1.875 3.45 1.875Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99922 8.55019C9.99333 8.55019 10.7992 7.74431 10.7992 6.75019C10.7992 5.75608 9.99333 4.9502 8.99922 4.9502C8.00511 4.9502 7.19922 5.75608 7.19922 6.75019C7.19922 7.74431 8.00511 8.55019 8.99922 8.55019Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39941 13.0506V11.2506C5.39941 10.7256 5.77441 10.3506 6.29941 10.3506H11.6994C12.2244 10.3506 12.5994 10.7256 12.5994 11.2506V13.0506"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
