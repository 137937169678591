import React, {useState} from 'react';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {User, useUsersByClientAllQuery} from 'api';

interface Props {
  user: User | null;
  onChange: (value: any) => void;
}

export const UserSelector = ({user, onChange}: Props) => {
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [userName, setUserName] = useState('');

  const filteredUsers =
    userName === ''
      ? users
      : users?.filter((user) => {
          return `${user?.lastName} ${user?.firstName}`
            ?.toLowerCase()
            ?.includes(userName?.toLowerCase());
        });

  return (
    <Combobox
      value={user}
      onChange={(user) => {
        setUserName('');
        onChange(user);
      }}>
      <Combobox.Button as={React.Fragment}>
        <div
          className="relative mt-1 h-[2.5rem]"
          onClick={() => inputRef.current?.focus()}>
          <Combobox.Input
            onChange={(e) => setUserName(e.target.value)}
            onFocus={() => buttonRef.current?.click()}
            value={userName}
            displayValue={(user: User) =>
              user &&
              `${[user?.lastName, user?.firstName].filter(Boolean).join(' ')}`
            }
            ref={inputRef}
            className="relative w-full cursor-pointer rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap "
          />
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-40">
              {filteredUsers.map((user) => (
                <Combobox.Option
                  key={user.id}
                  className={({active}) =>
                    `relative cursor-pointer select-none py-2 px-4 ${
                      active ? 'bg-c-bg' : 'white'
                    }`
                  }
                  value={user}>
                  <div className="flex items-center gap-2">
                    <span className="truncate flex-1">
                      {user.lastName} {user.firstName}
                    </span>
                  </div>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox.Button>
    </Combobox>
  );
};
