import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import {Link} from 'react-router-dom';
import {Check} from 'components/Ui/Icon';

export default () => (
  <Container>
    <Menu />
    <Content>
      <Title>プラン</Title>
      <Body>
        <Link to="/settings/plan">戻る</Link>
        <PlanTable>
          <tbody>
            <tr>
              <td></td>
              <td>
                <div className="plan-name">Starter</div>
                <div className="plan-price current">¥50,000 / 月</div>
              </td>
              <td>
                <div className="plan-name">Basic</div>
                <div className="plan-price">¥100,000 / 月</div>
              </td>
            </tr>
            <tr className="change-plan-row">
              <td></td>
              <td></td>
              <td>
                <ChangePlanButton
                  type="button"
                  onClick={() => console.log('clicked!')}>
                  <span>今すぐ変更</span>
                </ChangePlanButton>
              </td>
            </tr>
            <tr className="user">
              <th>ユーザー</th>
              <td>3人</td>
              <td>5人</td>
            </tr>
            <tr>
              <th>自動送信メール</th>
              <td>5,000通</td>
              <td>10,000通</td>
            </tr>
            <tr>
              <th>Salesforce連携</th>
              <td></td>
              <td>
                <Check color="#495058" />
              </td>
            </tr>
            <tr>
              <th>ダウンロード</th>
              <td></td>
              <td>
                <Check color="#495058" />
              </td>
            </tr>
          </tbody>
        </PlanTable>
      </Body>
    </Content>
  </Container>
);

const Container = styled.div`
  display: flex;
  background: #fff;
  height: calc(100vh - 60px);
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 290px);
  height: fit-content;
  min-height: calc(100vh - 145px);
  padding: 45px 45px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.15px;
  color: #495058;
`;

const Body = styled.div`
  padding 36px 44px;
`;

const PlanTable = styled.table`
  margin: 44px auto 0 auto;
  width: 90%;
  table-layout: fixed;

  tbody {
    &:last-child {
      height: ;
    }
  }

  th {
    text-align: left;
    border-right: 1px solid #e1e6eb;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #495058;
    height: 40px;
    font-weight: normal;
  }

  td {
    border-right: 1px solid #e1e6eb;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #495058;
  }

  .change-plan-row {
    border-bottom: 1px solid #e1e6eb;
  }

  .plan-name {
    padding-top: 32px;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.15px;
    color: #495058;
  }

  .plan-price {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.15px;
    color: #495058;
  }

  .current {
    color: #4fce82;
  }

  .user {
    height: 128px;
  }
`;

const ChangePlanButton = styled.button`
  display: flex;
  width: 90%;
  margin: 12px auto 32px auto;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  border: none;
  outline: 0;
  background: #68b5fb;
  border-radius: 4px;

  span {
    display: block
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
  }
`;
