import {
  MainCategory,
  useCompanyQuery,
  useSearchCountQuery,
} from 'api';
import {Residence} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  TagBox,
  TagItem,
  Count,
} from './styles';
import useSearchCondition from '../useSearchCondition';
import {Checkbox} from 'components/antd';
import {Loading} from 'components/Icon';

export default () => {
  const {query, setQuery} = useSearchCondition();
  const {companyId} = useParams<{companyId: string}>();
  const {data: {company = null} = {}} = useCompanyQuery({
    variables: {uuid: companyId},
    skip: !companyId,
    onCompleted: () =>
      setQuery({
        subCategoryIds: company.subCategories.map(
          (subCategory) => subCategory.id,
        ),
      }),
  });

  const {data: {searchCount = 0} = {}, loading} = useSearchCountQuery({
    variables: {
      search: {
        subCategoryIds: query.subCategoryIds,
      },
    },
    skip: !company,
  });

  if (!company) return null;

  const Tags = (mainCategory: MainCategory) => {
    const subCategories = company.subCategories.filter(
      (subCategory) => subCategory.mainCategoryId === mainCategory.id,
    );
    return subCategories.map((subCategory) => (
      <TagItem key={subCategory.id}>{subCategory.displayName}</TagItem>
    ));
  };

  const onCheck = (e: any) => {
    const checked = e.target.checked;
    if (checked) {
      setQuery({
        subCategoryIds: company.subCategories.map(
          (subCategory) => subCategory.id,
        ),
      });
    } else {
      setQuery({
        subCategoryIds: [],
      });
    }
  };

  if (!company.mainCategories) return null;

  if (company.mainCategories) {
    return (
      <Container>
        <Category>
          <CategoryTitle>
            <Residence />
            業種
          </CategoryTitle>
          {company.mainCategories.map((mainCategory) => (
            <ChildCategory key={mainCategory.id}>
              <ChildCategoryTitleA>
                {mainCategory.displayName}
              </ChildCategoryTitleA>
              <TagBox>{Tags(mainCategory)}</TagBox>
            </ChildCategory>
          ))}
        </Category>

        <Count>
          <span>検索件数</span>
          {loading ? (
            <Loading />
          ) : (
            <em>{`${searchCount?.toLocaleString()}件`}</em>
          )}
          <Checkbox
            checked={query.subCategoryIds && query.subCategoryIds.length > 0}
            onChange={onCheck}
          />
        </Count>
      </Container>
    );
  }
};
