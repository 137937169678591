import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import RadioButtonGroup from './RadioButtonGroup';
import useCondition from '../../useCondition';

const MailAddressFilter = () => {
  const {condition, setCondition} = useCondition();

  return (
    <>
      <Header title="メールアドレス" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <RadioButtonGroup
            name="isEmail"
            options={[
              {text: 'あり', value: 'true'},
              {text: 'なし', value: 'false'},
            ]}
            value={
              condition.isEmail === null
                ? ''
                : condition.isEmail
                ? 'true'
                : 'false'
            }
            onChange={(value) =>
              setCondition({isEmail: value === 'true' ? true : false})
            }
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({isEmail: null})} />
    </>
  );
};

export default MailAddressFilter;
