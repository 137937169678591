import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';

const PressReleaseSinceFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      allowClear
      value={query.pressReleaseSince}
      onChange={(value) => setQuery({page: 1, pressReleaseSince: value})}
      style={{width: '100%'}}>
      <Select.Option value="1d">前日</Select.Option>
      <Select.Option value="1w">一週間以内</Select.Option>
    </Select>
  );
};

export default PressReleaseSinceFilter;
