import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {
  useProjectWithPreleadMasterSearchConditionQuery,
  useSearchProjectGroupsByIdsQuery,
  useSearchProjectsByIdsQuery,
  useCurrentClientQuery,
  useTelStatusesByIdsQuery,
  useUsersByClientAllQuery,
} from 'api';
import {Filter} from 'components/Ui/Icon';
import moment from 'moment';
import {preleadStatusOptions, mailStatusOptions} from './statuses';
import {StatusEclipse} from 'components/Ui';
import preleadColor from 'helpers/preleadColor';
import mailColor from 'helpers/mailColor';
import telCategoryColor from 'helpers/telCategoryColor';
import {
  Container,
  Category,
  CategoryTitle,
  SearchConditions,
  Title,
  TagBox,
  TagItem,
} from './styles';
import preleadCategoryColor from 'helpers/preleadCategoryColor';
import DISPLAY_MARKETS from 'helpers/displayMarkets';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {
    data: {
      project: {
        preleadMasterSearchCondition,
        preleadMasterSearchCondition: {mode, createdAt} = {},
        preleadMasterSearchCondition: {
          filter: {
            projectGroupIds,
            projectIds,
            statuses,
            preleadStatusIds,
            mailStatuses,
            mailCounts,
            mailTerm,
            telStatusIds,
            telCounts,
            telFrom,
            telTo,
            clickCounts,
            clickTerm,
            pressReleaseSince,
            tagIds = [],
            capitalFund,
            accountClosingMonths,
            listingMarkets,
            officesNumber,
            establishedYearMonth,
            mainCategory,
            subCategories,
            employeeNumber,
            commentCounts,
            sales,
            taskDueDateFrom,
            taskDueDateTo,
            remindFrom,
            remindTo,
            mailCampaignOpenCounts,
            mailCampaignClickCounts,
            mailCampaignSendsCounts,
            trackedAtFrom,
            trackedAtTo,
            taskUserIds,
          },
        } = {},
      } = {},
    } = {},
  } = useProjectWithPreleadMasterSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  const {data: {searchProjectGroupsByIds = []} = {}} =
    useSearchProjectGroupsByIdsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        ids: projectGroupIds,
      },
      skip: !projectGroupIds,
    });

  const {data: {searchProjectsByIds: {projects: projects = []} = {}} = {}} =
    useSearchProjectsByIdsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        ids: projectIds,
      },
      skip: !projectIds,
    });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });
  const taskUsers = users.filter((user) => taskUserIds?.includes(user.id));

  const {
    data: {
      currentClient: {
        preleadTags = [],
        preleadStatuses = [],
        customizeItems = [],
      } = {},
    } = {},
  } = useCurrentClientQuery();

  const {data: {telStatusesByIds = []} = {}} = useTelStatusesByIdsQuery({
    variables: {
      ids: telStatusIds,
    },
    skip: !telStatusIds,
  });

  const telStatus = React.useCallback(
    (telStatusId: number): any =>
      telStatusesByIds.find(
        (telStatus) => Number(telStatus.id) === telStatusId,
      ),
    [telStatusesByIds],
  );

  const preleadStatus = React.useCallback(
    (preleadStatusId: number): any =>
      preleadStatuses.find(
        (preleadStatus) => Number(preleadStatus.id) === preleadStatusId,
      ),
    [preleadStatuses],
  );

  const pressReleaseSinceText = () => {
    switch (moment(pressReleaseSince).format('YYYY-MM-DD')) {
      case moment(createdAt).subtract(1, 'day').format('YYYY-MM-DD'):
        return '前日';
      case moment(createdAt).subtract(1, 'week').format('YYYY-MM-DD'):
        return '1週間以内';
    }
  };

  const customizeItemTitle = (columnName: string) => {
    const customizeItem = customizeItems.find(
      (item) => item.columnName === columnName,
    );
    if (customizeItem) return customizeItem.name;
    return '';
  };

  const upperCaseMode = mode?.toUpperCase();

  const usedTags = tagIds?.map((uuid) =>
    preleadTags.find((tag) => tag.uuid === uuid),
  );

  if (!preleadMasterSearchCondition) return null;

  return (
    <Container>
      {(projectGroupIds?.length > 0 ||
        projectIds?.length > 0 ||
        statuses?.length > 0 ||
        preleadStatusIds?.length > 0 ||
        mailStatuses?.length > 0 ||
        mailCounts?.length > 0 ||
        mailTerm ||
        telStatusIds?.length > 0 ||
        telCounts?.length > 0 ||
        telFrom ||
        telTo ||
        clickCounts?.length > 0 ||
        clickTerm ||
        pressReleaseSince ||
        usedTags?.length > 0 ||
        capitalFund ||
        accountClosingMonths?.length > 0 ||
        listingMarkets?.length > 0 ||
        officesNumber ||
        establishedYearMonth ||
        mainCategory ||
        subCategories?.length > 0 ||
        employeeNumber ||
        commentCounts?.length > 0 ||
        sales ||
        taskDueDateFrom ||
        taskDueDateTo ||
        remindFrom ||
        remindTo ||
        mailCampaignOpenCounts ||
        mailCampaignClickCounts ||
        mailCampaignSendsCounts ||
        trackedAtFrom ||
        trackedAtTo ||
        taskUserIds?.length > 0) && (
        <Category>
          <CategoryTitle>
            <Filter />
            項目フィルタ条件
          </CategoryTitle>

          <SearchConditions>
            {projectGroupIds?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>グループ
                </Title>
                <TagBox>
                  {searchProjectGroupsByIds.map((projectGroup: any) => (
                    <TagItem key={projectGroup.id}>{projectGroup.name}</TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {projects?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>営業リスト
                </Title>
                <TagBox>
                  {projects.map((project: any) => (
                    <TagItem key={project.id}>{project.name}</TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {statuses?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>プレリードステータス
                </Title>
                <TagBox>
                  {statuses.map((status) => (
                    <TagItem key={status}>
                      <StatusEclipse color={preleadColor(status)} />
                      {preleadStatusOptions[status]}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {preleadStatusIds?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>プレリードステータス
                </Title>
                <TagBox>
                  {preleadStatusIds.map((preleadStatusId) => (
                    <TagItem key={preleadStatusId}>
                      <StatusEclipse
                        color={preleadCategoryColor(
                          preleadStatus(preleadStatusId)?.category,
                        )}
                      />
                      {preleadStatusId === 0
                        ? '未'
                        : preleadStatus(preleadStatusId)?.name}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {mailStatuses?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>フォーム送信ステータス
                </Title>
                <TagBox>
                  {mailStatuses.map((mailStatus) => (
                    <TagItem key={mailStatus}>
                      <StatusEclipse color={mailColor(mailStatus)} />
                      {mailStatusOptions[mailStatus]}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {mailCounts?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>フォーム送信数
                </Title>
                <TagBox>
                  {mailCounts.map((mailCount, index) => (
                    <TagItem key={index}>
                      {mailCount}
                      {mailCount === 5 ? '回以上' : '回'}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {(mailTerm?.from || mailTerm?.to) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>フォーム送信期間
                </Title>
                <TagBox>
                  <TagItem>
                    {mailTerm.from &&
                      moment(mailTerm.from).format('YYYY年MM月DD日')}
                    {' 〜 '}
                    {mailTerm.to &&
                      moment(mailTerm.to).format('YYYY年MM月DD日')}
                  </TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {telStatusIds?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>TELステータス
                </Title>
                <TagBox>
                  {telStatusIds.map((telStatusId) => (
                    <TagItem key={telStatusId}>
                      <StatusEclipse
                        color={telCategoryColor(
                          telStatus(telStatusId)?.category,
                        )}
                      />
                      {telStatusId === 0 ? '未' : telStatus(telStatusId)?.name}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {telCounts?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>TEL件数
                </Title>
                <TagBox>
                  {telCounts.map((telCount, index) => (
                    <TagItem key={index}>
                      {telCount}
                      {telCount === 5 ? '回以上' : '回'}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {telFrom && telTo && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>TEL更新日
                </Title>
                <TagBox>
                  <TagItem>
                    {moment(telFrom).format('YYYY年MM月DD日')}
                    {' 〜 '}
                    {moment(telTo).format('YYYY年MM月DD日')}
                  </TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {clickCounts?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>フォーム送信クリック回数
                </Title>
                <TagBox>
                  {clickCounts.map((clickCount, index) => (
                    <TagItem key={index}>
                      {clickCount}
                      {clickCount === 5 ? '回以上' : '回'}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {(clickTerm?.from || clickTerm?.to) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>フォーム送信クリック日時
                </Title>
                <TagBox>
                  <TagItem>
                    {clickTerm.from &&
                      moment(clickTerm.from).format('YYYY年MM月DD日')}
                    {' 〜 '}
                    {clickTerm.to &&
                      moment(clickTerm.to).format('YYYY年MM月DD日')}
                  </TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {commentCounts?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>コメント回数
                </Title>
                <TagBox>
                  {commentCounts.map((commentCount) => (
                    <TagItem key={commentCount}>
                      {commentCount}
                      {commentCount === '5' ? '回以上' : '回'}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {pressReleaseSince && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>プレスリリース公開
                </Title>
                <TagBox>
                  <TagItem>{pressReleaseSinceText()}</TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {(taskDueDateFrom || taskDueDateTo) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>ToDo最新更新日付
                </Title>
                <TagBox>
                  <TagItem>
                    {taskDueDateFrom
                      ? moment(taskDueDateFrom).format('YYYY年MM月DD日')
                      : ''}
                    {' 〜 '}
                    {taskDueDateTo
                      ? moment(taskDueDateTo).format('YYYY年MM月DD日')
                      : ''}
                  </TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {taskUsers?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>ToDo最新更新担当者
                </Title>
                <TagBox>
                  {taskUsers.map((taskUser: any) => (
                    <TagItem key={taskUser.id}>
                      {taskUser.lastName ? taskUser.lastName : ''}{' '}
                      {taskUser.firstName ? taskUser.firstName : ''}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {usedTags?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>タグ
                </Title>
                <TagBox>
                  {usedTags.map((tag, index) => (
                    <TagItem key={index}>
                      <TagRectangle color={tag.color} />
                      {tag.name}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {(capitalFund?.from || capitalFund?.to) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>資本金
                </Title>
                <TagBox style={{paddingTop: 5}}>
                  {capitalFund.from && `${capitalFund.from}万`} 〜{' '}
                  {capitalFund.to && `${capitalFund.to}万`}
                </TagBox>
              </SearchCondition>
            )}

            {accountClosingMonths?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>決算月
                </Title>
                <TagBox>
                  {accountClosingMonths
                    .map(Number)
                    .sort((a, b) => a - b)
                    .map((month) => (
                      <TagItem key={month}>{month}月</TagItem>
                    ))}
                </TagBox>
              </SearchCondition>
            )}

            {listingMarkets?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>上場区分
                </Title>
                <TagBox>
                  {listingMarkets.map((market) => (
                    <TagItem key={market}>
                      {
                        DISPLAY_MARKETS.find(
                          (marketEnum) => marketEnum.value === market,
                        ).name
                      }
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {(sales?.from || sales?.to) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>売上
                </Title>
                <TagBox style={{paddingTop: 5}}>
                  {sales.from && `${sales.from}万`} 〜{' '}
                  {sales.to && `${sales.to}万`}
                </TagBox>
              </SearchCondition>
            )}

            {(officesNumber?.from || officesNumber?.to) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>事業所数
                </Title>
                <TagBox style={{paddingTop: 5}}>
                  {officesNumber.from && officesNumber.from} 〜{' '}
                  {officesNumber.to && officesNumber.to}
                </TagBox>
              </SearchCondition>
            )}

            {(establishedYearMonth?.from || establishedYearMonth?.to) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>設立年月
                </Title>
                <TagBox>
                  <TagItem>
                    {establishedYearMonth.from &&
                      moment(establishedYearMonth.from).format('YYYY年MM月')}
                    {' 〜 '}
                    {establishedYearMonth.to &&
                      moment(establishedYearMonth.to).format('YYYY年MM月')}
                  </TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {mainCategory && (
              <SearchCondition>
                <Title>業種メイン</Title>
                <TagBox key={mainCategory.id}>
                  <TagItem>{mainCategory.displayName}</TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {subCategories?.length > 0 && (
              <SearchCondition>
                <Title>業種サブ</Title>
                <TagBox>
                  {subCategories.map((subCategory) => (
                    <TagItem key={subCategory.id}>
                      {subCategory.displayName}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {(employeeNumber?.to || employeeNumber?.from) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>従業員数
                </Title>
                <TagBox style={{paddingTop: 5}}>
                  {employeeNumber.from && `${employeeNumber.from}人`} 〜{' '}
                  {employeeNumber.to && `${employeeNumber.to}人`}
                </TagBox>
              </SearchCondition>
            )}

            {(remindFrom || remindTo) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>最終リマインド
                </Title>
                <TagBox>
                  <TagItem>
                    {remindFrom
                      ? moment(remindFrom).format('YYYY年MM月DD日')
                      : ''}
                    {' 〜 '}
                    {remindTo ? moment(remindTo).format('YYYY年MM月DD日') : ''}
                  </TagItem>
                </TagBox>
              </SearchCondition>
            )}

            {mailCampaignOpenCounts?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>メールキャンペーン開封数
                </Title>
                <TagBox>
                  {mailCampaignOpenCounts.map((openCount, index) => (
                    <TagItem key={index}>
                      {openCount}
                      {openCount === '5' ? '回以上' : '回'}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {mailCampaignClickCounts?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>メールキャンペーンクリック数
                </Title>
                <TagBox>
                  {mailCampaignClickCounts.map((clickCount, index) => (
                    <TagItem key={index}>
                      {clickCount}
                      {clickCount === '5' ? '回以上' : '回'}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {mailCampaignSendsCounts?.length > 0 && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>メールキャンペーン回数
                </Title>
                <TagBox>
                  {mailCampaignSendsCounts.map((sendCount, index) => (
                    <TagItem key={index}>
                      {sendCount}
                      {sendCount === '5' ? '回以上' : '回'}
                    </TagItem>
                  ))}
                </TagBox>
              </SearchCondition>
            )}

            {(trackedAtFrom || trackedAtTo) && (
              <SearchCondition>
                <Title>
                  <span>{upperCaseMode}：</span>最新Webサイト訪問期間
                </Title>
                <TagBox>
                  <TagItem>
                    {trackedAtFrom
                      ? moment(trackedAtFrom).format('YYYY年MM月DD日')
                      : ''}
                    {' 〜 '}
                    {trackedAtTo
                      ? moment(trackedAtTo).format('YYYY年MM月DD日')
                      : ''}
                  </TagItem>
                </TagBox>
              </SearchCondition>
            )}
          </SearchConditions>
        </Category>
      )}
    </Container>
  );
};

const SearchCondition = styled.div`
  display: flex;
  margin-bottom: 10px;

  &:first-child {
    ${Title} {
      span {
        display: none;
      }
    }
  }
`;

const TagRectangle = styled.span<{color?: string}>`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${({color}) => (color ? `${color}` : 'white')};
`;
