import React, {useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {Input, Select, Tag} from 'components/antd';
import {Lense} from 'components/Ui/Icon';
import useSearchParams from './useSearchParams';
import {useCurrentClientQuery} from 'api';
import {getTagFontColor} from 'helpers/tagColors';
import useProjectGroups from 'hooks/useProjectGroups';

export default () => {
  const {query, setQuery} = useSearchParams();
  const {accessibleProjectGroups} = useProjectGroups();

  const {data: {currentClient: {projectTags = []} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const searchProjects = (e: any) => {
    setQuery({
      searchWord: e.target.value,
      page: 1,
    });
  };

  const tagLabel = ({value, closable, onClose}: any) => {
    const tag = projectTags.find((tag) => tag.uuid === value);
    if (!tag) return null;
    return (
      <CustomTag
        tagcolor={tag.color}
        color={tag.color}
        closable={closable}
        onClose={onClose}
        style={{marginRight: 3}}>
        {tag.name}
      </CustomTag>
    );
  };

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container>
      <GlobalStyle />
      <SearchInput
        type="search"
        name="search"
        placeholder="キーワード検索"
        prefix={<Lense />}
        onPressEnter={(e) => searchProjects(e)}
        defaultValue={query.searchWord}
      />

      <TagSelect
        mode="multiple"
        allowClear
        placeholder="タグ検索"
        value={query.tagIds}
        tagRender={tagLabel}
        dropdownClassName="tag-select-dropdown"
        optionFilterProp="label"
        onChange={(value: Array<string>) => setQuery({tagIds: value})}
        optionLabelProp="title">
        {projectTags?.map((tag) => (
          <TagSelect.Option value={tag.uuid} key={tag.uuid} label={tag.name}>
            <React.Fragment>
              <TagRectangle color={tag.color} />
              &nbsp; {tag.name}
            </React.Fragment>
          </TagSelect.Option>
        ))}
      </TagSelect>

      {location.pathname.startsWith('/list/projects') && (
        <Select
          style={{minWidth: '330px'}}
          mode="multiple"
          allowClear
          placeholder="グループ"
          value={query.projectGroupIds}
          onChange={(value: Array<string>) =>
            setQuery({projectGroupIds: value})
          }>
          {accessibleProjectGroups?.map((projectGroup) => (
            <Select.Option value={projectGroup.id} key={projectGroup.id}>
              {projectGroup.name}
            </Select.Option>
          ))}
        </Select>
      )}
      <div className="w-36 ml-3">
        <input
          type={isFocused ? 'date' : 'text'}
          placeholder="開始日"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={query.fromDate}
          onChange={(e: any) => {
            setQuery({fromDate: e.target.value});
          }}
          className="block form-input appearance-none w-full bg-white border-none px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-lighter disabled:bg-c-bg disabled:text-c-light"
        />
      </div>
      <div className="mx-1 mt-1.5 text-c-lighter">→</div>
      <div className="w-36">
        <input
          type={isFocused ? 'date' : 'text'}
          placeholder="終了日"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={query.toDate}
          onChange={(e: any) => {
            setQuery({toDate: e.target.value});
          }}
          className="block form-input appearance-none w-full bg-white border-none px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-lighter disabled:bg-c-bg disabled:text-c-light"
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 16.5px;
  display: flex;

  .ant-input-affix-wrapper,
  .ant-select-selector {
    border: none !important;
    height: 34px;
    border-radius: 4px !important;
    overflow-y: hidden !important;
  }
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
`;

const SearchInput = styled(Input)`
  display: flex;
  align-items: center;
  margin-right: 11px;
  width: 200px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  box-sizing: border-box;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: #bdc4cb;
    }
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;

const TagSelect = styled(Select)`
  margin-right: 11px;
  min-width: 330px;
  background: #ffffff !important;
  border-radius: 4px;

  .ant-select-selector {
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      padding: 1px 0;
    }

    .ant-select-selection-item {
      border-radius: 4px;

      .ant-select-selection-item-content {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const CustomTag = styled(Tag)<{tagcolor?: string}>`
  margin-right: 5px !important;
  color: ${({tagcolor}) => getTagFontColor(tagcolor)} !important;
  svg {
    fill: ${({tagcolor}) => getTagFontColor(tagcolor)} !important;
  }
`;

const TagRectangle = styled.span<{color?: string}>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${({color}) => (color ? `${color}` : 'white')};
`;

const GlobalStyle = createGlobalStyle`

  .tag-select-dropdown {
    width: 480px;
    height: calc(32px * 6) + 4 * 2;
    background: #ecf0f4 !important;

    .ant-select-item-option-content{
      display: flex;
      align-items: center;
    }
  }
`;
