import React from 'react';
import {Breadcrumb} from 'components/antd';
import Header from './Header';
import Tab from './Tabs';
import Summary from './Summary';
import Lead from './Lead';
import Contact from './Contact';
import {Route, Switch, useRouteMatch, useParams, Link} from 'react-router-dom';

export default () => {
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {path} = useRouteMatch();

  return (
    <div className="p-8 bg-c-bg overflow-y-auto h-[calc(100vh-60px)]">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/pools/${poolId}/accounts`}>〈 一覧に戻る</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Header />
      <Tab />
      <Switch>
        <Route path={`${path}/lead`}>
          <Lead />
        </Route>
        <Route path={`${path}/contact`}>
          <Contact />
        </Route>
        <Route path={`${path}/deal`} />
        <Route path={`${path}/signal`} />
        <Route path={`${path}`}>
          <Summary />
        </Route>
      </Switch>
    </div>
  );
};
