import React from 'react';
import {Transition} from '@headlessui/react';
import {useLocation} from 'react-router';

interface Props {
  path: string;
}

const SlideIn: React.FC<Props> = ({path, children}) => {
  const location = useLocation();
  const show = location.pathname.startsWith(path);

  return (
    <Transition
      show={show}
      enter="transition-all duration-150"
      enterFrom="left-full"
      enterTo="left-0"
      leave="transition-all duration-150"
      leaveFrom="left-0"
      leaveTo="left-full"
      className="absolute top-0 h-full w-full"
      as="div"
    >
      <div className="relative h-full w-full flex-1 bg-c-bg">{children}</div>
    </Transition>
  );
};

export default SlideIn;
