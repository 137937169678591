import React from 'react';
import useCondition from '../../../useCondition';
import {useSalesforceContactRecordTypeByRecordTypeIdQuery} from 'api';
import SelectOption from './SelectOption';
import MultiSelectOption from './MutltiSelectOption';

type SelectSearchCondition = {
  field_select_option_id: string;
  field_type: string;
  contact_field_id: string;
  index: number;
};

type MultiSelectSearchCondition = {
  values: string[];
  field_type: string;
  contact_field_id: string;
  index: number;
};

type SearchCondition = SelectSearchCondition | MultiSelectSearchCondition;

interface Props {
  searchCondition: SearchCondition;
}

export default ({searchCondition}: Props) => {
  const {condition} = useCondition();

  const {
    data: {
      salesforceContactRecordTypeByRecordTypeId: {
        salesforceContactFields = [],
      } = {},
    } = {},
  } = useSalesforceContactRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceContactRecordTypeId: condition?.salesforceContactRecordTypeId,
    },
    skip: !condition.salesforceContactRecordTypeId,
  });

  const contactField = salesforceContactFields.find(
    (field) => field.id === searchCondition.contact_field_id,
  );

  if (!contactField) {
    return null;
  }

  return (
    <div className="flex gap-2">
      <span className="text-c-light h-6 flex items-center">
        {contactField.label}：
      </span>
      {searchCondition.field_type === 'select' && (
        <SelectOption
          contactFieldId={searchCondition.contact_field_id}
          value={
            (searchCondition as SelectSearchCondition).field_select_option_id
          }
        />
      )}
      {searchCondition.field_type === 'multi_select' && (
        <MultiSelectOption
          contactFieldId={searchCondition.contact_field_id}
          values={(searchCondition as MultiSelectSearchCondition).values}
        />
      )}
    </div>
  );
};
