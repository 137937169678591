import React from 'react';
import styled from 'styled-components';
import {Modal} from 'components/antd';
import {WorkflowNew, WorkflowTemplate} from 'components/Ui/Icon';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setNewModal: (newModal: boolean) => void;
  setTemplateModal: (templateModal: boolean) => void;
}

const SelectModalComponent = ({
  visible,
  setVisible,
  setNewModal,
  setTemplateModal,
}: Props) => {
  return (
    <SelectModal
      title={null}
      footer={null}
      visible={visible}
      width={510}
      onCancel={() => setVisible(false)}>
      <div>
        <h3>ワークフローを作成</h3>
        <div style={{marginBottom: 15}}>
          ワークフローの作成方法を選んでください
        </div>
        <ContentWrapper>
          <Content onClick={() => setNewModal(true)}>
            <span>新規作成</span>
            <WorkflowNew />
          </Content>
          <Content onClick={() => setTemplateModal(true)}>
            <span>テンプレートから作成</span>
            <WorkflowTemplate />
          </Content>
        </ContentWrapper>
      </div>
    </SelectModal>
  );
};

const SelectModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h3 {
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
  }
  div {
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;

  div:first-child {
    margin-right: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  border: 1px solid #e1e6eb;
  cursor: pointer;

  span {
    margin-bottom: 10px;
  }
`;

export default SelectModalComponent;
