import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #e1e6eb;
`;

const Category = styled.div``;

const Count = styled.span`
  margin: auto 0 auto auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-left: 1px solid #f3f5f7;
  width: 120px;

  * {
    margin: auto;
    margin-bottom: 5px;
  }

  span {
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #bdc4cb;
  }
  em {
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #222426;
  }
`;

const CategoryTitle = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #bdc4cb;

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 5px;
    right: 3px;
  }
`;

const ChildCategory = styled.div`
  margin-top: 15px;
`;

const ChildCategoryTitleA = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: #bdc4cb;
`;

const ChildCategoryTitleB = styled.span`
  display: inline-block;
  width: 150px;
  font-weight: bold;
  font-size: 12px;
  color: #bdc4cb;
`;

const ChildCategoryItem = styled.span`
  font-size: 12px;
`;

const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagItem = styled.span`
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #495058;
`;

export {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  ChildCategoryTitleB,
  ChildCategoryItem,
  TagBox,
  TagItem,
  Count,
};
