import React from 'react';
import {MailCampaignBlock, MailCampaignBlockDivider} from 'api';

interface Block extends MailCampaignBlock {
  blockable: MailCampaignBlockDivider;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const style = {
    backgroundColor: block.blockable?.bodyColor,
    paddingTop: block.blockable?.paddingTop || 0,
    paddingBottom: block.blockable?.paddingBottom || 0,
    paddingLeft: block.blockable?.paddingLeft || 0,
    paddingRight: block.blockable?.paddingRight || 0,
  };

  const dividerStyle = {
    borderTop: `${block.blockable?.lineWidth}px solid ${block.blockable?.lineColor}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  };

  return (
    <div style={style}>
      <hr style={dividerStyle} />
    </div>
  );
};
