import React from 'react';
import {Activity, TelCommentActivity} from 'api';
import {Tooltip} from 'antd';
import {Comment as CommentIcon, Tel as TelIcon} from './Icon';
import moment from 'moment';

interface Props {
  activity: Activity;
}

const TelCommentActivityComponent = ({activity}: Props) => {
  const {bodyPlainText} = activity.resource as TelCommentActivity;

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="w-6 h-6 bg-[#F3F5F7] rounded-full flex justify-center items-center min-w-[24px]">
        <TelIcon />
      </div>
      <div></div>
      <div className="flex flex-col gap-1">
        <div className="text-xs">
          {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
        </div>
        <div className="text-xs">
          {activity.project ? activity.project.name : '(削除済み)'}
        </div>
        <div className="text-sm">
          {activity.user?.lastName} {activity.user?.firstName}
        </div>
        {bodyPlainText && (
          <Tooltip placement="top" title={bodyPlainText}>
            <div className="flex flex-row gap-[6px] text-sm w-36">
              <div className="flex items-center justify-center align-middle">
                <CommentIcon />
              </div>
              <div className="truncate">{bodyPlainText}</div>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default TelCommentActivityComponent;
