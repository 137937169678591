import React from 'react';
import styled from 'styled-components';
import NgReasonReport from './NgReasonReport';
import StatusReport from './StatusReport';

export default () => {
  return (
    <Container>
      <StatusReport />
      <NgReasonReport />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  padding: 0px 39px 47px 39px;

  > h2 {
    margin-top: 46px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #495058;
  }
`;
