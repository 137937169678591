import React from 'react';
import {Transition} from '@headlessui/react';
import LeadSourceFilter from './LeadSource';
import OriginalLeadSource from './OriginalLeadSource';
import StageFilter from './Stage';
import FormFilter from './Form';
import Header from '../Header';
import useFilterModalContext from '../useFilterModalContext';
import WorkflowFilter from './Workflow';
import UnsubscribeFilter from './Unsubscribe';
import TagFilter from './Tag';
import ExcludedTagFilter from './ExcludedTag';
import AssigneeFilter from './Assignee';
import CollectedFormUrlFilter from './CollectedFormUrl';
import MailCampaignFilter from './MailCampaign';
import StatusFilter from './Status';
import CustomizeItemFilter from './CustomizeItem';
import TrackedAtFilter from './TrackedAt';
import TelStatusFilter from './TelStatus';
import BounceFilter from './Bounce';
import LastTelActivityFilter from './LastTelActivity';
import InflowFDateilter from './InflowDate';
import PresenceTelNumberFilter from './PresenceTelNumber';
import SubCategoryFilter from './SubCategory';
import ListingMarketsFilter from './ListingMarkets';
import EmployeeNumberFilter from './EmployeeNumber';
import ExistTelActivityFilter from './ExistTelActivity';
import SalesforceCampaignFilter from './SalesforceCampaign';
import SalesforceDealFilter from './SalesforceDeal';
import SalesforceContactFilter from './SalesforceContact';
import SalesforceLeadFilter from './SalesforceLead';
import PhaseFilter from './Phase';
import CapitalFundFilter from './CapitalFund';
import CityFilter from './City';
import EstablishedDateFilter from './EstablishedDate';
import HasCompanyFilter from './HasCompany';

const filterComponents: {[key: string]: any} = {
  leadSource: LeadSourceFilter,
  originalLeadSource: OriginalLeadSource,
  stage: StageFilter,
  form: FormFilter,
  workflow: WorkflowFilter,
  unsubscribe: UnsubscribeFilter,
  tag: TagFilter,
  excludedTag: ExcludedTagFilter,
  assignee: AssigneeFilter,
  collectedFormUrl: CollectedFormUrlFilter,
  mailCampaign: MailCampaignFilter,
  status: StatusFilter,
  customizeItem: CustomizeItemFilter,
  trackedAt: TrackedAtFilter,
  inflowDate: InflowFDateilter,
  telStatus: TelStatusFilter,
  bounce: BounceFilter,
  lastTelActivity: LastTelActivityFilter,
  presenceTelNumber: PresenceTelNumberFilter,
  subCategory: SubCategoryFilter,
  listingMarkets: ListingMarketsFilter,
  employeeNumber: EmployeeNumberFilter,
  existTelActivity: ExistTelActivityFilter,
  salesforceCampaign: SalesforceCampaignFilter,
  salesforceDeal: SalesforceDealFilter,
  salesforceContact: SalesforceContactFilter,
  salesforceLead: SalesforceLeadFilter,
  phase: PhaseFilter,
  capitalFund: CapitalFundFilter,
  city: CityFilter,
  establishedDate: EstablishedDateFilter,
  hasCompany: HasCompanyFilter,
};

const SkeletonFilter = () => (
  <>
    <Header title="" />
    <div className="bg-c-bg flex-1 rounded-b" />
  </>
);

const Filter = () => {
  const {filterType} = useFilterModalContext();
  const FilterComponent = filterComponents[filterType];

  return (
    <Transition
      show={!!FilterComponent}
      enter="transition-all duration-150"
      enterFrom="left-full"
      enterTo="left-0"
      leave="transition-all duration-150"
      leaveFrom="left-0"
      leaveTo="left-full"
      className="w-full h-full flex flex-col absolute top-0"
      as="div">
      {FilterComponent ? <FilterComponent /> : <SkeletonFilter />}
    </Transition>
  );
};

export default Filter;
