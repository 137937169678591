import React, {useState} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {AccountGroup} from 'api';
import {DotMenuSide} from 'components/Ui/Icon';
import {Popover} from 'antd';
import {useDestroyAccountGroupMutation} from 'api';

interface Props {
  accountGroup: AccountGroup;
}

export default ({accountGroup}: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [destroyAccountGroup] = useDestroyAccountGroupMutation({});

  const handleClick = () => {
    if (confirm('削除してもよろしいでしょうか？')) {
      destroyAccountGroup({
        variables: {id: accountGroup.id},
        refetchQueries: ['accountGroups'],
      });
    }
  };

  return (
    <div className="bg-white p-5 pr-0 flex rounded cursor-pointer">
      <div className="flex flex-col gap-1 flex-1">
        <h3 className="m-0 text-lg">
          <Link to={`account_groups/${accountGroup.uuid}`}>
            {accountGroup.name}
          </Link>
        </h3>
        <ul className="text-c-light text-sm">
          <li className="w-fit inline-block mr-4">
            最終更新日時:{' '}
            {moment(accountGroup.updatedAt).format('YYYY/MM/DD HH:mm')}
          </li>
          <li className="w-fit inline-block">
            最終更新者: {accountGroup.user?.lastName}{' '}
            {accountGroup.user?.firstName}
          </li>
        </ul>
      </div>
      <ul className="flex">
        <li className="w-24 flex flex-col items-center justify-center border-l border-c-border px-4">
          <h5 className="text-sm m-0">企業数</h5>
          <span className="text-xl text-c-primary font-bold w-full text-right">
            {accountGroup.accountCount.toLocaleString()}
          </span>
        </li>
        {/*
          <li className="w-24 flex flex-col items-center justify-center border-l border-c-border px-4">
          <h5 className="text-sm m-0">リード数</h5>
          <span className="text-xl text-c-primary font-bold w-full text-right">
          {accountGroup.prospectCount.toLocaleString()}
          </span>
          </li>
        */}
        <Popover
          placement="left"
          content={
            <div
              className="w-[104px] h-12 hover:bg-[#f5f5f5] flex items-center justify-center hover:cursor-pointer"
              onClick={() => handleClick()}>
              削除
            </div>
          }
          trigger="click"
          onVisibleChange={(visible) => setIsPopoverVisible(visible)}
          visible={isPopoverVisible}>
          <li className="w-16 flex flex-col items-center justify-center border-l border-c-border px-4">
            <DotMenuSide />
          </li>
        </Popover>
      </ul>
    </div>
  );
};
