import React from 'react';
import {Activity, ZoomPhoneWebhookCallActivity} from 'api';
import telCategoryColor from 'helpers/telCategoryColor';
import {Tooltip} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import {
  Tel as TelIcon,
  Comment as CommentIcon,
  ZoomPhone as ZoomPhoneIcon,
} from './Icon';

interface Props {
  activity: Activity;
}

const ZoomPhoneWebhookCallActivityComponent = ({activity}: Props) => {
  const {
    clientTelStatus = {},
    bodyPlainText,
    callNgReason,
    receptionNgReason,
    contactNgReason,
    zoomPhoneWebhookCall,
  } = activity.resource as ZoomPhoneWebhookCallActivity;

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="w-6 h-6 bg-[#F3F5F7] rounded-full flex justify-center items-center">
        <TelIcon />
      </div>
      <div></div>
      <div className="flex flex-col gap-1">
        <div className="text-xs">
          {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
        </div>
        {activity.project && (
          <div className="text-xs">{activity.project.name}</div>
        )}
        <div className="flex gap-2">
          <div>
            <ZoomPhoneIcon />
          </div>
          <div className="text-sm">
            {zoomPhoneWebhookCall.callerName || zoomPhoneWebhookCall.calleeName}
          </div>
        </div>
        {clientTelStatus && (
          <TelStatusName
            background={telCategoryColor(clientTelStatus?.category)}
            className={`rounded-[20px] text-white font-bold justify-center text-[10px] truncate p-1 w-36 h-5 flex items-center`}>
            <span>{clientTelStatus.name}</span>
            {(callNgReason || receptionNgReason || contactNgReason) && (
              <span>|</span>
            )}
            {callNgReason && <span>{callNgReason.reason}</span>}
            {receptionNgReason && <span>{receptionNgReason.reason}</span>}
            {contactNgReason && <span> {contactNgReason.reason}</span>}
          </TelStatusName>
        )}
        {bodyPlainText && (
          <Tooltip placement="top" title={bodyPlainText}>
            <div className="flex flex-row gap-[6px] text-sm w-36">
              <div className="flex items-center justify-center align-middle">
                <CommentIcon />
              </div>
              <div className="truncate">{bodyPlainText}</div>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ZoomPhoneWebhookCallActivityComponent;

const TelStatusName = styled.div<{background: string}>`
  background: ${(props) => props.background};
`;
