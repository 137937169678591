import React from 'react';
import {useUpdateFormMutation, Form} from 'api';
import useDebounce from 'hooks/useDebounce';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const [updateForm] = useUpdateFormMutation();

  const [value, setValue] = React.useState(form.autoreplyMailFromAddress || '');
  const debouncedValue = useDebounce<string>(value, 500);

  React.useEffect(() => {
    debouncedValue !== form.autoreplyMailFromAddress &&
      updateForm({
        variables: {
          uuid: form.uuid,
          attributes: {
            autoreplyMailFromAddress: debouncedValue,
          },
        },
      });
  }, [form, debouncedValue]);

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-1 w-full">
        <label className="text-c-base font-bold">差出人メールアドレス</label>
        <div className="flex items-center">
          <input
            className="form-input border-c-border rounded text-base w-full disabled:bg-c-bg disabled:text-c-light"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        {form.sendAutoreplyMail && !value ? (
          <div className="text-red-500">
            自動返信メールを送信する場合は、差出人メールアドレスを入力してください。
          </div>
        ) : null}
      </div>
    </div>
  );
};
