import React from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';
import Modal from 'react-modal';
import {Cross} from 'components/Ui/Icon';
import {useUnsubscribeAutomaticMailMutation, ProspectPool} from 'api';

interface Props {
  prospectPool: ProspectPool;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export default ({prospectPool, isModalVisible, setIsModalVisible}: Props) => {
  const [unsubscribeAutomaticMail, {loading}] =
    useUnsubscribeAutomaticMailMutation({
      variables: {
        uuid: prospectPool.uuid,
      },
      onCompleted: () => {
        setIsModalVisible(false);
      },
    });

  const onClose = () => {
    setIsModalVisible(false);
  };

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 100,
    },
    content: {
      top: '400px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '245px',
      padding: '0',
      borderRadius: '5px',
      overflow: 'hidden',
    },
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => onClose()}
      style={customStyles}>
      <Header>
        <CrossIconWrapper onClick={() => setIsModalVisible(false)}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <Body>
        <div>このリードを配信停止しますか？</div>
        <div>メール配信、ワークフローの自動メール配信対象外となります。</div>
        <div>
          ※同一のメールアドレスが登録されているリード全てに配信停止が適用されます。
        </div>
      </Body>
      <Footer>
        <Button type="ghost" onClick={() => setIsModalVisible(false)}>
          キャンセル
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => unsubscribeAutomaticMail()}
          disabled={loading}>
          配信停止
        </Button>
      </Footer>
    </Modal>
  );
};

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 30px;
`;
const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: 15px;
  right: 20px;
`;

const Body = styled.div`
  text-align: center;
  margin-bottom: 32px;

  > div:first-child {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  > div:nth-child(2) {
    font-weight: 500;
    font-size: 14px;
  }
`;
