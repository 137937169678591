import React from 'react';
import {Switch} from '@headlessui/react';
import {
  useCreateWebpushSubscriptionMutation,
  useDestroyWebpushSubscriptionMutation,
} from 'api';
import {
  subscribeWebPush,
  unsubscribeWebPush,
  getWebPushSubscription,
} from 'helpers/serviceworker';

export default () => {
  const [enabled, setEnabled] = React.useState(false);

  const updateEnabled = () =>
    getWebPushSubscription()?.then((subscription) =>
      setEnabled(!!subscription),
    );

  const [create] = useCreateWebpushSubscriptionMutation({
    onCompleted: updateEnabled,
  });

  const [destroy] = useDestroyWebpushSubscriptionMutation({
    onCompleted: updateEnabled,
  });

  const subscribe = () =>
    subscribeWebPush()?.then(
      (subscription) =>
        subscription &&
        create({
          variables: {
            attributes: {
              endpoint: subscription.endpoint,
              p256dh: subscription.keys.p256dh,
              auth: subscription.keys.auth,
            },
          },
        }),
    );

  const unsubscribe = () =>
    unsubscribeWebPush()?.then(
      (subscription) =>
        subscription &&
        destroy({
          variables: {
            endpoint: subscription.endpoint,
          },
        }),
    );

  React.useEffect(() => {
    updateEnabled();
  }, []);

  return (
    <div>
      <h2 className="font-bold text-lg text-[#222426]">通知設定</h2>
      <div className="flex justify-between items-center py-3 border-y border-c-border">
        <div className="flex flex-col gap-1">
          <h3 className="text-[#495058] font-bold">ブラウザ通知</h3>
          <p className="text-[#899098] text-sm">
            Webサイトトラッキングに関する通知を受け取ることができます。
          </p>
        </div>
        <Switch
          checked={enabled}
          onChange={enabled ? unsubscribe : subscribe}
          className={`p-0 ${enabled ? 'bg-c-primary' : 'bg-c-bg'}
          relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}>
          <span
            aria-hidden="true"
            className={`${enabled ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
    </div>
  );
};
