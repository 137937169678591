import React from 'react';

const Icon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 5.25L13.5 7.5M4.5 10.5L2.25 12.75L4.5 10.5ZM4.5 15L2.25 12.75L4.5 15ZM15.75 12.75H12H7.5H2.25H15.75ZM15.75 5.25H10.5H6H2.25H15.75ZM15.75 5.25L13.5 3L15.75 5.25Z"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
