import React from 'react';
import FilterAction from './FilterAction';
import SearchAction from './SearchAction';
import NewProjectAction from './NewProjectAction';
import SortAction from './SortAction';
import BlockGroupAction from './BlockGroupAction';
import ColumnAction from './ColumnAction';
import Actions from './Actions';
import useClientUser from 'hooks/useClientUser';
import {useCurrentClientQuery} from 'api';

export default () => {
  const {isAdmin} = useClientUser();
  const {data: {currentClient: {domain} = {}} = {}} = useCurrentClientQuery();

  return (
    <div className="border-b border-c-border font-bold text-[#bdc4cb] flex justify-end">
      <div className="flex items-center gap-4 px-2">
        <ColumnAction />
        <SortAction />
        <FilterAction />
        {domain !== 'zeal-c' && <BlockGroupAction />}
        <NewProjectAction />
        {isAdmin && <Actions />}
        <SearchAction />
      </div>
    </div>
  );
};
