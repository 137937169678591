import React, {Fragment} from 'react';
import {useParams} from 'react-router-dom';
import {
  useSalesforceLeadFieldsQuery,
  SalesforceLeadFieldSelectOption,
  useUpdateWorkflowSearchConditionMutation,
  useWorkflowQuery,
  useSalesforceLeadRecordTypesQuery,
  SalesforceLeadRecordType,
} from 'api';
import {Listbox, Transition} from '@headlessui/react';
import Close from '../Close';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {mailCampaignId} = useParams<{mailCampaignId: string}>();
  const {workflowId} = useParams<{
    workflowId: string;
  }>();

  const {isMember} = useClientUser();

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const {data: {salesforceLeadFields = []} = {}} = useSalesforceLeadFieldsQuery(
    {
      variables: {
        id: searchCondition['salesforceLeadRecordTypeId'],
      },
      skip: !searchCondition['salesforceLeadRecordTypeId'],
    },
  );

  const leadStatusOptions = React.useMemo(() => {
    return (
      salesforceLeadFields.find((leadField) => leadField.fieldName === 'Status')
        ?.salesforceLeadFieldSelectOptions || []
    );
  }, [salesforceLeadFields]);

  const selectedItems = () => {
    return leadStatusOptions.filter((leadStatusOption) =>
      (searchCondition?.salesforceLeadFieldSelectStatusIds || []).includes(
        leadStatusOption.id,
      ),
    );
  };

  return (
    <div className="flex h-10 leading-10">
      <div className="w-28 font-medium text-base">リード 状況</div>
      <div className="z-20 min-w-[40%] w-auto min-h-9 h-auto">
        <Listbox
          value={searchCondition?.salesforceLeadFieldSelectStatusIds || []}
          disabled={
            !isConditionUpdatable ||
            !searchCondition?.salesforceLeadRecordTypeId ||
            !isMember
          }
          multiple
          onChange={(values) =>
            updateWorkflowSearchCondition({
              variables: {
                uuid: workflowId,
                attributes: {
                  salesforceLeadFieldSelectStatusIds: values,
                },
              },
            })
          }>
          <div className="relative flex-1 w-full h-full">
            <Listbox.Button
              className={`relative px-3 pr-10 text-left focus:outline-none flex items-center border  rounded w-full h-full
            } ${
              !isConditionUpdatable ||
              !searchCondition?.salesforceLeadRecordTypeId
                ? 'cursor-not-allowed text-[#bfbfbf]'
                : 'curros-pointer bg-white border-[#d9d9d9]'
            }`}>
              <div className="flex gap-1 items-center">
                {selectedItems().map((selectedItem) => {
                  return (
                    <div
                      key={selectedItem.id}
                      className="bg-[#f5f5f5] border border-[#f0f0f0] px-1 h-6 flex gap-1 items-center">
                      <div> {selectedItem.label}</div>
                      {isConditionUpdatable && (
                        <div
                          className="flex items-center"
                          onClick={() => {
                            updateWorkflowSearchCondition({
                              variables: {
                                uuid: workflowId,
                                attributes: {
                                  salesforceLeadFieldSelectStatusIds:
                                    searchCondition?.salesforceLeadFieldSelectStatusIds.filter(
                                      (salesforceLeadFieldSelectStatusId) =>
                                        salesforceLeadFieldSelectStatusId !==
                                        selectedItem.id,
                                    ),
                                },
                              },
                            });
                          }}>
                          <Close />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <span
                className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2
              `}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5">
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                {leadStatusOptions
                  .filter(
                    (leadStatusOption: SalesforceLeadFieldSelectOption) =>
                      !(
                        searchCondition?.salesforceLeadFieldSelectStatusIds ||
                        []
                      ).includes(leadStatusOption.id),
                  )
                  .map((leadStatusOption) => (
                    <Listbox.Option
                      key={leadStatusOption.id}
                      className={({active}) =>
                        `relative select-none py-2 px-3 cursor-pointer ${
                          active ? 'bg-gray-100' : 'bg-white'
                        }`
                      }
                      value={leadStatusOption.id}>
                      {`${leadStatusOption?.label}`}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};
