import {defaultStatuses} from 'helpers/task';
import React from 'react';
import {
  NumberParam,
  ArrayParam,
  DelimitedArrayParam,
  useQueryParams,
  StringParam,
  ObjectParam,
  withDefault,
} from 'use-query-params';

const PAGE_SIZE = 20;

const searchParamsDef = {
  assignee: withDefault(StringParam, 'current_user'),
  assignees: DelimitedArrayParam,
  dueDate: ObjectParam,
  statuses: withDefault(ArrayParam, []),
  priorities: ArrayParam,
  types: ArrayParam,
  page: NumberParam,
  orderOfDueDate: withDefault(StringParam, 'asc'),
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;

  const searchParams = {
    assignee: query.assignee,
    assignees: query.assignees,
    dueDate: query.dueDate,
    statuses: query.statuses,
    priorities: query.priorities,
    types: query.types,
  };

  React.useEffect(() => {
    setQuery({
      ...query,
      statuses: query.statuses.length === 0 ? defaultStatuses : query.statuses,
    });
  }, []);

  let numberOfFiltersInUse = 0;
  if (query.assignees?.length > 0) numberOfFiltersInUse++;
  if (query.dueDate?.from || query.dueDate?.to) numberOfFiltersInUse++;
  if (query.statuses?.length > 0) numberOfFiltersInUse++;
  if (query.priorities?.length > 0) numberOfFiltersInUse++;
  if (query.types?.length > 0) numberOfFiltersInUse++;

  return {
    query,
    setQuery,
    page,
    perPage: PAGE_SIZE,
    searchParams,
    numberOfFiltersInUse,
  };
};

export default useSearchParams;
