import gql from 'graphql-tag';

export default gql`
  fragment blockMemberFragment on BlockMember {
    id
    uuid
    name
    url
    domain
  }
`;
