import React from 'react';
import {useParams, Link} from 'react-router-dom';
import {Form} from 'api';
import TextBlock from './Text';
import ButtonBlock from './Button';
import PersonalInformatinBlock from './PersonalInformation';
import SubmitButtonBlock from './SubmitButton';
import StandardFieldBlock from './StandardField';
import CustomizeItemFieldBlock from './CustomizeItemField';
import OtherFieldBlock from './OtherField';

interface Props {
  form: Form;
}

const blockComponents: {[key: string]: any} = {
  FormBlockText: TextBlock,
  FormBlockButton: ButtonBlock,
  FormBlockPersonalInformation: PersonalInformatinBlock,
  FormBlockSubmitButton: SubmitButtonBlock,
  FormBlockFieldStandard: StandardFieldBlock,
  FormBlockFieldCustomizeItem: CustomizeItemFieldBlock,
  FormBlockFieldOther: OtherFieldBlock,
};

export default ({form}: Props) => {
  const {blockId} = useParams<{blockId: string}>();
  const formBlock = React.useMemo(
    () =>
      form
        ? form.formPages
            .flatMap((page) => page.formBlocks)
            .find((block) => block.uuid === blockId)
        : null,
    [form, blockId],
  );

  if (!formBlock) return null;

  const BlockComponent = blockComponents[formBlock.formBlockable.__typename];

  if (!BlockComponent) return null;

  return (
    <div className="py-4 flex flex-col gap-4">
      <Link to={`/form/forms/${form.uuid}/form/blocks`} className="text-base">
        〈 戻る
      </Link>
      <BlockComponent block={formBlock} key={formBlock.id} />
    </div>
  );
};
