import React from 'react';
import useCondition from '../../../useCondition';
import Header from '../../Header';
import Footer from '../Footer';
import CustomizeItemField from './CustomizeItemField';

const CustomizeItemFilter = () => {
  const {condition, setCondition} = useCondition();

  return (
    <>
      <Header title="カスタマイズ項目" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {condition?.prospectCustomizeItemSearchConditions?.map(
              (customizeItemSearchCondition, index) => (
                <CustomizeItemField
                  key={index}
                  searchCondition={customizeItemSearchCondition}
                  setSearchCondition={(customizeItemSearchCondition) => {
                    const customizeItemSearchConditions = [
                      ...condition.prospectCustomizeItemSearchConditions,
                    ];
                    customizeItemSearchConditions[index] =
                      customizeItemSearchCondition;
                    setCondition({
                      prospectCustomizeItemSearchConditions:
                        customizeItemSearchConditions,
                    });
                  }}
                  onDelete={() => {
                    const customizeItemSearchConditions = [
                      ...condition.prospectCustomizeItemSearchConditions,
                    ];
                    customizeItemSearchConditions.splice(index, 1);
                    setCondition({
                      prospectCustomizeItemSearchConditions:
                        customizeItemSearchConditions,
                    });
                  }}
                />
              ),
            )}
            <button
              className="cursor-pointer w-32 h-8 flex items-center justify-center rounded bg-white border border-c-border text-light"
              onClick={() =>
                setCondition({
                  prospectCustomizeItemSearchConditions: [
                    ...(condition.prospectCustomizeItemSearchConditions || []),
                    {},
                  ],
                })
              }>
              + 条件を追加
            </button>
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setCondition({prospectCustomizeItemSearchConditions: []})
        }
      />
    </>
  );
};

export default CustomizeItemFilter;
