import Name from './Name';
import Stage from './Stage';
import LeadSource from './LeadSource';
import LastAction from './LastAction';

export default {
  Name,
  Stage,
  LeadSource,
  LastAction,
};
