import React from 'react';
import {useClientPhaseProspectsQuery} from 'api';
import useCondition from '../../useCondition';

export default () => {
  const {condition} = useCondition();
  const {data: {clientPhaseProspects = []} = {}} = useClientPhaseProspectsQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  if (
    !condition?.clientPhaseProspects ||
    !condition.clientPhaseProspects.length
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">フェーズ</h4>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {clientPhaseProspects
          .filter((clientPhaseProspect) =>
            condition.clientPhaseProspects.includes(clientPhaseProspect.id),
          )
          .map((clientPhaseProspect) => (
            <span
              key={clientPhaseProspect.id}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {clientPhaseProspect.name}
            </span>
          ))}
      </div>
    </div>
  );
};
