import React from 'react';
import {useProspectTagsQuery, ProspectTag} from 'api';
import useSearchParams from '../useSearchParams';
import Close from './Close';

export default () => {
  const {query, setQuery} = useSearchParams();
  const {data: {prospectTags = []} = {}} = useProspectTagsQuery({});

  return (
    <div className="flex gap-2">
      {prospectTags
        .filter((prospectTag) => query.tagIds.includes(prospectTag.id))
        .map((item: ProspectTag) => {
          return (
            <div
              key={item.id}
              className="bg-[#F3F5F7] h-7 p-2 flex gap-1 items-center">
              <div className="text-[#495058]">{item.name}</div>
              <div
                className="flex items-center hover:cursor-pointer"
                onClick={() => {
                  setQuery({
                    tagIds: query.tagIds.filter((tagId) => tagId !== item.id),
                  });
                }}>
                <Close />
              </div>
            </div>
          );
        })}
      {query.tagIds?.length > 0 && (
        <div
          className="text-[#68B5FB] flex items-center font-bold hover:cursor-pointer"
          onClick={() => setQuery({tagIds: []})}>
          All Clear
        </div>
      )}
    </div>
  );
};
