import React from 'react';
import {SalesforceLeadRecordType} from 'api';
import RecordType from './RecordType';
import Row from './Row';

export default () => {
  const [selectedRecordType, setSelectedRecordType] =
    React.useState<SalesforceLeadRecordType>(null);

  return (
    <div>
      <RecordType
        selectedRecordType={selectedRecordType}
        setSelectedRecordType={setSelectedRecordType}
      />
      {selectedRecordType && (
        <div className="mt-2">
          <div className={'bg-white p-3 focus:outline-none'}>
            <div>
              <div className="flex border-b border-b-[#F3F5F7]">
                <div className="text-[#899098] w-96 px-6 text-sm pb-2">
                  LEADPAD
                </div>
                <div className="text-[#899098] w-96 px-6 text-sm pb-2">
                  Salesforce
                </div>
                <div className="text-[#899098] w-72 px-6 text-sm pb-2">
                  企業情報更新設定
                </div>
              </div>
              <Row salesforceLeadRecordType={selectedRecordType} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
