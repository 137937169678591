import {ProspectPool} from 'api';
import React from 'react';
import {Link} from 'react-router-dom';
import {LaunchLink} from 'components/Ui/Icon';
import Copy from './Copy';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const account = prospectPool.prospect.account;
  return (
    <div className="flex">
      <div>
        <Link
          to={`/pools/${prospectPool.pool.uuid}/accounts/${prospectPool.accountPool.uuid}`}
          target="_blank">
          {account.name}
        </Link>
      </div>
      {account.webSite && (
        <>
          <a href={account.webSite} target="_blank">
            <LaunchLink width="14" height="14" />
          </a>
          <Copy copyText={account.webSite} />
        </>
      )}
    </div>
  );
};
