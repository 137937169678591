import React from 'react';
import {useParams, Redirect} from 'react-router-dom';
import {useContentQuery} from 'api';

export default () => {
  const [type, setType] = React.useState(null);
  const {id} = useParams<{id: string}>();
  const {data: {content = null} = {}} = useContentQuery({
    variables: {
      id,
    },
  });

  React.useEffect(() => {
    if (content?.contentableType === 'Content::MailForm') {
      setType('mail_form');
    } else if (content?.contentableType === 'Content::MailTemplate') {
      setType('mail_template');
    } else if (content?.contentableType === 'Content::Document') {
      setType('document');
    } else if (content?.contentableType === 'Content::TelScript') {
      setType('tel_script');
    } else if (content?.contentableType === 'Content::Audio') {
      setType('audio');
    }
  }, [content]);

  if (!type) {
    return null;
  }

  return <Redirect to={`/garage/contents/${content.uuid}/${type}`} />;
};
