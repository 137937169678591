import React from 'react';
import styled from 'styled-components';
import {Route, Switch} from 'react-router-dom';
import Detail from './detail';
import {useCurrentUserQuery, useCurrentClientQuery} from 'api';

export default () => {
  const {
    data: {currentUser = {}},
  } = useCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });
  const {data: {currentClient: {currentLeadContract} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  if (currentUser.role !== 'admin' && !currentLeadContract) {
    return (
      <P>
        お客様のアカウントではこちらのページにアクセスできません。
        <br />
        お手数ですが、担当者までお問い合わせください。
      </P>
    );
  }

  return (
    <Switch>
      <Route path="/pools/:poolId" component={Detail} />
    </Switch>
  );
};


const P = styled.p`
  display: grid;
  place-items: center;
  height: 100vh;
  font-size: 20px;
  text-align: center;
`;
