import React from 'react';
import {
  HubspotDealPropertyDefinition,
  useHubspotDealPropertyDefinitionInputtablesQuery,
} from 'api';
import {DealPropertyItem} from './DealPropertyItem';
import useProspectContext from '../../../useProspectContext'

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
}

export const Addition = ({addition, setAddition}: Props) => {
  const {poolId} = useProspectContext();
  const {data: {hubspotDealPropertyDefinitionInputtables = []} = {}} =
    useHubspotDealPropertyDefinitionInputtablesQuery({
      fetchPolicy: 'network-only',
      variables: {poolId},
      skip: !poolId,
    });

  return (
    <div>
      {hubspotDealPropertyDefinitionInputtables.map(
        (property: HubspotDealPropertyDefinition, i: number) => (
          <DealPropertyItem
            key={i}
            addition={addition}
            setAddition={setAddition}
            propertyDefinition={property}
          />
        ),
      )}
    </div>
  );
};
