import gql from 'graphql-tag';
import prospectCustomizeItemSelectOptionFragment from './customizeItemSelectOption';

export default gql`
  fragment prospectCustomizeItemSelectFragment on ProspectCustomizeItemSelect {
    id
    prospectCustomizeItemSelectOptions {
      ...prospectCustomizeItemSelectOptionFragment
    }
    poolProspectCustomizeItemSelectOptionIds
    poolProspectCustomizeItemId
  }
  ${prospectCustomizeItemSelectOptionFragment}
`;
