import { Popover } from "antd";
import { useCopyContentMailTemplateMutation } from "api";
import { Copy, DotMenu } from "components/Ui/Icon";
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import styled from "styled-components";

interface Props {
  id: string;
}

const Action = ({id}: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const history = useHistory();
  const [copyContentMailTemplate] = useCopyContentMailTemplateMutation({
    variables: {uuid: id},
    onCompleted: () => {
      history.push('/garage/contents?type=mail_template');
    }
  });

  const Content = () => (
    <Container>
      <ActionWrapper
        onClick={() => {
          confirm('コピーしてもよろしいですか？')
            ? copyContentMailTemplate()
            : null;
          setIsPopoverVisible(false);
        }}>
        <Copy />
        コピー
      </ActionWrapper>
    </Container>
  );

  return (
    <Popover
      placement="left"
      content={<Content />}
      trigger="click"
      onVisibleChange={(visible) => setIsPopoverVisible(visible)}
      visible={isPopoverVisible}>
      <IconWrapper>
        <DotMenu />
      </IconWrapper>
    </Popover>
  );
}

const Container = styled.div`
  width: 150px;
  cursor: pointer;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-left: 18px;
  width: 100%;
  height: 60px;

  &:hover {
    background-color: #f5faff;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  svg {
    transform: rotate(90deg);
    position: relative;
    top: 3px;
    left: 12px;
  }
`;

export default Action;
