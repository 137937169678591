import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useUpdateWorkflowSalesforceLeadCheckboxSearchConditionMutation,
  useWorkflowQuery,
} from 'api';
import {Select} from 'components/antd';
import useClientUser from 'hooks/useClientUser';
import {CheckboxSearchCondition} from './Types';

const LeadCheckboxOption: React.FC<{condition: CheckboxSearchCondition}> = ({
  condition,
}) => {
  const {isMember} = useClientUser();
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();

  const [updateWorkflowSalesforceLeadCheckboxSearchCondition] =
    useUpdateWorkflowSalesforceLeadCheckboxSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const {data: {workflow = {}} = {}} = useWorkflowQuery({
    variables: {uuid: workflowId},
  });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  const leadFieldSelectOptions = [
    {
      label: 'あり',
      value: 'true',
    },
    {
      label: 'なし',
      value: 'false',
    },
  ];

  return (
    <Select
      options={leadFieldSelectOptions}
      disabled={!isConditionUpdatable || !condition.lead_field_id || !isMember}
      style={{width: 200}}
      value={condition.value ?? ''}
      onChange={(value: string) => {
        updateWorkflowSalesforceLeadCheckboxSearchCondition({
          variables: {
            uuid: workflowId,
            attributes: {
              leadFieldId: condition.lead_field_id,
              fieldType: condition.field_type,
              value: value,
            },
          },
        });
      }}
    />
  );
};

export default LeadCheckboxOption;
