import React from 'react';
import styled from 'styled-components';
import {ProspectPool} from 'api';
import {Row} from 'react-table';
import {prospectStages} from 'helpers/prospectStages';

interface Props {
  row: Row<ProspectPool>;
}

const StageCell = ({row}: Props) => {
  const prospect = row.original;

  const stage = prospectStages.find((stage) => stage.name == prospect.stage);

  return (
    <Stage color={stage.color} bg={stage.bg}>
      {stage.displayName}
    </Stage>
  );
};

const Stage = styled.span<{color: string; bg: string}>`
  width: 80px;
  height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: ${({color}) => color};
  background-color: ${({bg}) => bg};
`;

export default StageCell;
