import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Route, Switch, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import projectRoutePaths from 'helpers/projectRoutePaths';
import useProjectPath from 'hooks/useProjectPath';
import Search from './Search';
import CallReport from './callReport';
import PreleadReport from './preleadReport';
import ConversionRateReport from './conversionRateReport';
import CallRateReport from './callRateReport';
import AssignedCalledRateReport from './assignedCalledRateReport';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();
  const location = useLocation();

  return (
    <Container>
      <Tabs>
        <TabItem active={location.pathname.endsWith('/call')}>
          <Link
            to={createPath(
              `projects/${projectId}/tel_reports/user/call${location.search}`,
            )}>
            コール数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/prelead')}>
          <Link
            to={createPath(
              `projects/${projectId}/tel_reports/user/prelead${location.search}`,
            )}>
            プレリード数
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/conversion_rate')}>
          <Link
            to={createPath(
              `projects/${projectId}/tel_reports/user/conversion_rate${location.search}`,
            )}>
            転換率
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/call_rate')}>
          <Link
            to={createPath(
              `projects/${projectId}/tel_reports/user/call_rate${location.search}`,
            )}>
            対コール率
          </Link>
        </TabItem>
        <TabItem active={location.pathname.endsWith('/assigned_called_rate')}>
          <Link
            to={createPath(
              `projects/${projectId}/tel_reports/user/assigned_called_rate${location.search}`,
            )}>
            消化率
          </Link>
        </TabItem>
      </Tabs>

      <Body>
        {location.pathname.indexOf('assigned_called_rate') === -1 && <Search />}
        <Main>
          <Switch>
            <Route
              path={projectRoutePaths(
                'projects/:projectId/tel_reports/user/call',
              )}
              component={CallReport}
            />
            <Route
              path={projectRoutePaths(
                'projects/:projectId/tel_reports/user/prelead',
              )}
              component={PreleadReport}
            />
            <Route
              path={projectRoutePaths(
                'projects/:projectId/tel_reports/user/conversion_rate',
              )}
              component={ConversionRateReport}
            />
            <Route
              path={projectRoutePaths(
                'projects/:projectId/tel_reports/user/call_rate',
              )}
              component={CallRateReport}
            />
            <Route
              path={projectRoutePaths(
                'projects/:projectId/tel_reports/user/assigned_called_rate',
              )}
              component={AssignedCalledRateReport}
            />
          </Switch>
        </Main>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100vw - 125px);
  background: #ffffff;
  padding: 30px;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 43px;
`;

const TabItem = styled.div<{active?: boolean}>`
  margin-right: 27px;

  a {
    color: ${({active}) => (active ? '#222426' : '#BDC4CB')};
    font-weight: ${({active}) => (active ? 'bold' : '')};
    border-bottom: ${({active}) => (active ? '3px solid #495058' : '')};
    padding-bottom: 12px;
    font-size: 14px;
  }
`;

const Body = styled.div`
  grid-area: body;
  display: flex;
`;

const Main = styled.div`
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-top: none;
  border-left: none;
  border-radius: 0px 4px 4px 4px;
  width: 77%;

  > div:last-child {
    padding: 31px 40px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-top: 1px solid #e1e6eb;
    border-radius: 0px 4px 4px 4px;
  }
`;
