import React from 'react';
import styled from 'styled-components';
import {useEmbeddableContentsQuery} from 'api';
import Item from './Item';

interface Props {
  onClick: (text: string) => void;
}

export default ({onClick}: Props) => {
  const {data: {embeddableContents = []} = {}} = useEmbeddableContentsQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <h3>コンテンツを追加</h3>
      {embeddableContents.map((content) => (
        <Item key={content.id} content={content} onClick={onClick} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
  width: 400px;

  h3 {
    font-weight: bold;
  }
`;
