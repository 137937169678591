import React from 'react';
import {AzureTranscriptionFile} from 'api';

const Transcription = ({file}: {file: AzureTranscriptionFile}) => {
  const [showAll, setShowAll] = React.useState(false);

  if (file.azureRecognizedPhrases.length < 5) {
    return (
      <>
        {file.azureRecognizedPhrases?.map((phrase) =>
          phrase.azureRecognizedPhraseNBests?.map((nBest) => (
            <div
              key={nBest.id}>{`${phrase.speaker}: ${nBest.displayText}`}</div>
          )),
        )}
      </>
    );
  }

  return (
    <>
      {file.azureRecognizedPhrases
        ?.slice(0, showAll ? file.azureRecognizedPhrases.length : 4)
        .map((phrase) =>
          phrase.azureRecognizedPhraseNBests?.map((nBest) => (
            <div
              key={nBest.id}>{`${phrase.speaker}: ${nBest.displayText}`}</div>
          )),
        )}
      {showAll ? (
        <span
          onClick={() => setShowAll(false)}
          className="text-c-primary cursor-pointer flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-3 h-auto">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
          少なく表示
        </span>
      ) : (
        <span
          onClick={() => setShowAll(true)}
          className="text-c-primary cursor-pointer flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-3 h-auto rotate-180">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
          すべて表示
        </span>
      )}
    </>
  );
};

export default Transcription;
