import React from 'react';
import moment from 'moment';
import {
  Workflow,
  Step,
  useApplyWorkflowProspectPoolMutation,
  ProspectPool,
} from 'api';
import StepTypeIcon from 'components/Workflow/Icon/StepTypeIcon';

interface Props {
  workflow: Workflow;
  prospectPool: ProspectPool;
  onApply: () => void;
}

export default ({workflow, prospectPool, onApply}: Props) => {
  const [open, setOpen] = React.useState(false);

  const [applyWorkflow] = useApplyWorkflowProspectPoolMutation({
    variables: {uuid: prospectPool.uuid, workflowUuid: workflow.uuid},
  });

  const displayDate = (step: Step) => {
    if (step.isImmediately) return `即時実行`;
    if (step.startDate) return moment(step.startDate).format('YYYY/MM/DD');
    if (step.startDay === 0) return '当日';

    let day = 0;
    let cantDisplay = false;
    workflow.steps.some((s) => {
      if (s.position > step.position) return true;
      if (s.startDate) {
        cantDisplay = true;
        return true;
      }

      day += s.startDay;
    });

    if (cantDisplay) return 'ー';
    return day + '日目';
  };

  const apply = () => {
    if (confirm('このワークフローを適用しますか？')) {
      applyWorkflow();
      onApply();
    }
  };

  return (
    <div
      key={workflow.uuid}
      className="border border-c-border rounded px-7 py-4 w-full flex flex-col items-start gap-3">
      <h3 className="font-bold m-0">{workflow.name}</h3>
      <span className="bg-c-bg text-c-light h-6 flex items-center px-4 rounded text-sm">
        {workflow.targetText}
      </span>
      <div className="flex justify-between w-full">
        <div className="flex flex-col gap-1">
          <h4 className="text-c-light text-sm m-0">ワークフロー</h4>
          <p className="text-sm leading-4">
            <strong className="text-lg font-bold mr-1 leading-5">
              {workflow.steps.length}
            </strong>
            ステップ
          </p>
        </div>
        <div>
          <h4 className="text-c-light text-sm m-0">実行</h4>
          <p className="text-sm leading-5">
            <strong className="text-lg font-bold mr-1">
              {workflow?.actionsStatusOpenCount?.toLocaleString()}
            </strong>
          </p>
        </div>
        <div>
          <h4 className="text-c-light text-sm m-0">完了</h4>
          <p className="text-sm leading-5">
            <strong className="text-lg font-bold mr-1 leading-5">
              {workflow?.actionsStatusCompletedCount?.toLocaleString()}
            </strong>
          </p>
        </div>
        <div>
          <h4 className="text-c-light text-sm m-0">キャンセル</h4>
          <p className="text-sm leading-5">
            <strong className="text-lg font-bold mr-1 leading-5">
              {workflow?.actionsStatusCanceledCount?.toLocaleString()}
            </strong>
          </p>
        </div>
        <div>
          <h4 className="text-c-light text-sm m-0">合計</h4>
          <p className="text-sm leading-5">
            <strong className="text-lg font-bold mr-1 leading-5">
              {(
                workflow?.actionsStatusOpenCount +
                workflow?.actionsStatusCompletedCount +
                workflow?.actionsStatusCanceledCount
              ).toLocaleString()}
            </strong>
          </p>
        </div>
      </div>
      <table
        className={`table-auto border border-c-border rounded w-full overflow-hidden border-separate border-spacing-0 ${
          open ? '' : 'hidden'
        }`}>
        <tbody>
          {workflow.steps.map((step, index) => (
            <tr key={step.uuid}>
              <td
                className={`px-4 py-2 align-middle text-center w-6 text-c-light  ${
                  index < workflow.steps.length - 1
                    ? 'border-b border-b-c-border'
                    : ''
                }`}>
                {step.position + 1}
              </td>
              <td
                className={`px-4 py-2 align-middle text-center w-24 ${
                  index < workflow.steps.length - 1
                    ? 'border-b border-b-c-border'
                    : ''
                }`}>
                {displayDate(step)}
              </td>
              <td
                className={`px-4 py-2 align-middle text-center w-8 ${
                  index < workflow.steps.length - 1
                    ? 'border-b border-b-c-border'
                    : ''
                }`}>
                <div>
                  <StepTypeIcon step={step} active width="32" height="32" />
                </div>
              </td>
              <td
                className={`px-4 py-2 align-middle ${
                  index < workflow.steps.length - 1
                    ? 'border-b border-b-c-border'
                    : ''
                }`}>
                <div className="text-base font-bold">{step.name}</div>
                {step.memo && (
                  <div className="text-sm text-c-light">{step.memo}</div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between w-full">
        <button
          className="text-c-primary cursor-pointer bg-white"
          onClick={() => setOpen(!open)}>
          {open ? (
            <>
              ステップ詳細を閉じる{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-3 h-auto rotate-180">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </>
          ) : (
            <>
              ステップ詳細を確認{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-3 h-auto">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </>
          )}
        </button>
        <button
          onClick={apply}
          className="transition-all bg-c-primary cursor-pointer text-white h-8 rounded px-4 flex items-center justify-center hover:opacity-50 ">
          このワークフローを適用
        </button>
      </div>
    </div>
  );
};
