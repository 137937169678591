import gql from 'graphql-tag';

export default gql`
  fragment telCommentActivityFragment on TelCommentActivity {
    id
    body
    bodyPlainText
    recordedAt
  }
`;
