import gql from 'graphql-tag';

export default gql`
  fragment salesforceAccountPreleadFragment on SalesforceAccountPrelead {
    id
    accountName
    salesforceAccountIdString
    poolId
    createdAt
    updatedAt
    salesforceAccountId
    salesforceUserId
  }
`;
