import gql from 'graphql-tag';

export default gql`
  fragment manualMailStepFragment on ManualMailStep {
    id
    actionsCount
    sentCount
    openedCount
    clickedCount
    bouncedCount
  }
`;
