import React from 'react';
import styled from 'styled-components';
import telCategoryColor from 'helpers/telCategoryColor';
import {StatusEclipse} from 'components/Ui';
import {Prelead, useCurrentClientQuery} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const TelCell = ({row}: Props) => {
  const prelead: Prelead = row.original;
  const {data: {currentClient: {telStatuses = []} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-only',
    });

  const preleadTelStatus = telStatuses.find(
    (telStatus) => telStatus.id === prelead.clientTelStatusId,
  );

  const color = telCategoryColor(preleadTelStatus?.category);
  const text = preleadTelStatus?.name || '未';

  return (
    <Cell>
      <StatusEclipse color={color} />
      {text}
    </Cell>
  );
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

export default TelCell;
