import {useProjectWithSearchConditionQuery} from 'api';
import {Note} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  TagBox,
  TagItem,
} from './styles';
import moment from 'moment';
import DISPLAY_MARKETS from 'helpers/displayMarkets';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = {}} = {}} = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });
  const {projectSearchCondition} = project;
  if (!projectSearchCondition) return null;

  const COMPANY_TYPES = [
    {name: '株式会社', value: 'inc'},
    {name: '有限会社', value: 'limited'},
    {name: '合同会社', value: 'limitedliability'},
    {name: '合資会社', value: 'limitedpartnership'},
    {name: '合名会社', value: 'generalpartnership'},
    {name: '弁護士法人', value: 'legalprofessional'},
    {name: '税理士法人', value: 'taxaccountant'},
    {name: '監査法人', value: 'audit'},
    {name: '特許業務法人', value: 'patentprofessional'},
    {name: '土地家屋調査士法人', value: 'landandhousesurveyor'},
    {name: '司法書士法人', value: 'judicialscrivener'},
    {name: '社会保険労務士法人', value: 'laborandsocialsecurityattorney'},
    {name: '行政書士法人', value: 'administrativescrivener'},
    {name: '特定目的会社', value: 'specificpurpose'},
    {name: '一般社団法人', value: 'generalincorporatedassociation'},
    {name: '一般財団法人', value: 'generalincorporatedfoundation'},
    {name: '相互会社', value: 'mutual'},
    {name: '医療法人', value: 'medical'},
    {name: '学校法人', value: 'school'},
    {name: 'その他', value: 'other'},
  ];

  return (
    <Container>
      {project.isCompanyAttribute && (
        <Category>
          <CategoryTitle>
            <Note />
            企業情報
          </CategoryTitle>

          {(projectSearchCondition.isUrl ||
            projectSearchCondition.isTel ||
            projectSearchCondition.isEmail ||
            projectSearchCondition.isRepresentativePerson) && (
            <ChildCategory>
              <ChildCategoryTitleA>連絡先</ChildCategoryTitleA>
              <TagBox>
                {projectSearchCondition.isUrl && <TagItem>URLあり</TagItem>}
                {projectSearchCondition.isTel && (
                  <TagItem>電話番号あり</TagItem>
                )}
                {projectSearchCondition.isEmail && (
                  <TagItem>メールアドレスあり</TagItem>
                )}

                {projectSearchCondition.isRepresentativePerson && (
                  <TagItem>代表者名あり</TagItem>
                )}
              </TagBox>
            </ChildCategory>
          )}

          {(projectSearchCondition.establishedYearMonthFrom ||
            projectSearchCondition.establishedYearMonthTo) && (
            <ChildCategory>
              <ChildCategoryTitleA>設立年月</ChildCategoryTitleA>
              <TagBox>
                <TagItem>
                  {moment(
                    projectSearchCondition.establishedYearMonthFrom,
                  ).format('YYYY年MM月')}
                  {' 〜 '}
                  {moment(projectSearchCondition.establishedYearMonthTo).format(
                    'YYYY年MM月',
                  )}
                </TagItem>
              </TagBox>
            </ChildCategory>
          )}

          {projectSearchCondition.accountClosingMonths &&
            projectSearchCondition.accountClosingMonths.length !== 0 && (
              <ChildCategory>
                <ChildCategoryTitleA>決算月</ChildCategoryTitleA>
                <TagBox>
                  {projectSearchCondition.accountClosingMonths
                    .map(Number)
                    .sort((a, b) => a - b)
                    .map((month) => (
                      <TagItem key={month}>{month}月</TagItem>
                    ))}
                </TagBox>
              </ChildCategory>
            )}

          {(projectSearchCondition.twitter ||
            projectSearchCondition.facebook ||
            projectSearchCondition.youtube ||
            projectSearchCondition.instagram ||
            projectSearchCondition.line ||
            projectSearchCondition.linkedin) && (
            <ChildCategory>
              <ChildCategoryTitleA>SNS</ChildCategoryTitleA>
              <TagBox>
                {projectSearchCondition.twitter && <TagItem>Twitter</TagItem>}
                {projectSearchCondition.facebook && <TagItem>Facebook</TagItem>}
                {projectSearchCondition.youtube && <TagItem>YouTube</TagItem>}
                {projectSearchCondition.instagram && (
                  <TagItem>Instagram</TagItem>
                )}
                {projectSearchCondition.line && <TagItem>Line</TagItem>}
                {projectSearchCondition.linkedin && <TagItem>LinkedIn</TagItem>}
              </TagBox>
            </ChildCategory>
          )}

          {projectSearchCondition.markets &&
            projectSearchCondition.markets.length !== 0 && (
              <ChildCategory>
                <ChildCategoryTitleA>上場区分</ChildCategoryTitleA>
                <TagBox>
                  {projectSearchCondition.markets.map((market) => (
                    <TagItem key={market}>
                      {
                        DISPLAY_MARKETS.find(
                          (marketEnum) => marketEnum.value === market,
                        ).name
                      }
                    </TagItem>
                  ))}
                </TagBox>
              </ChildCategory>
            )}

          {projectSearchCondition.companyTypes &&
            projectSearchCondition.companyTypes.length !== 0 && (
              <ChildCategory>
                <ChildCategoryTitleA>会社種別</ChildCategoryTitleA>
                <TagBox>
                  {projectSearchCondition.companyTypes.map((companyType) => (
                    <TagItem key={companyType}>
                      {
                        COMPANY_TYPES.find(
                          (companyTypeEnum) =>
                            companyTypeEnum.value === companyType,
                        ).name
                      }
                    </TagItem>
                  ))}
                </TagBox>
              </ChildCategory>
            )}
        </Category>
      )}
    </Container>
  );
};
