const technologies = [
  {
    value: 'ecommerce',
    text: 'EC/ネットショップ',
    technologies: [
      {
        value: 'base',
        text: 'Base',
      },
      {
        value: 'sapCommerceCloud',
        text: 'SAP (Commerce Cloud)',
      },
      {
        value: 'salesforceCommerceCloud',
        text: 'Salesforce (Commerce Cloud)',
      },
      {
        value: 'shopify',
        text: 'Shopify',
      },
      {
        value: 'yahooEcommerce',
        text: 'Yahoo! Stores',
      },
    ],
  },
  {
    value: 'affiliate_programs',
    text: 'アフィリエイト',
    technologies: [
      {
        value: 'a8Net',
        text: 'A8.net',
      },
      {
        value: 'amazonAssociates',
        text: 'Amazon アソシエイト・プログラム',
      },
      {
        value: 'rakuten',
        text: '楽天Rebates',
      },
    ],
  },
  {
    value: 'payment_processors',
    text: 'オンライン決済',
    technologies: [
      {
        value: 'amazonPay',
        text: 'Amazon Pay',
      },
      {
        value: 'americanExpress',
        text: 'American Express',
      },
      {
        value: 'applePay',
        text: 'Apple Pay',
      },
      {
        value: 'googlePay',
        text: 'Google Pay',
      },
      {
        value: 'paypal',
        text: 'Paypal',
      },
      {
        value: 'square',
        text: 'Square',
      },
      {
        value: 'visa',
        text: 'Visa',
      },
    ],
  },
  {
    value: 'analytics',
    text: 'アナリティクス',
    technologies: [
      {
        value: 'facebookPixel',
        text: 'Facebook ピクセル',
      },
      {
        value: 'googleAdsConversionTracking',
        text: 'Google コンバージョン トラッキング',
      },
      {
        value: 'googleAnalytics',
        text: 'Google アナリティクス',
      },
      {
        value: 'googleAnalyticsEnhancedEcommerce',
        text: 'Google アナリティクス 拡張eコマースプラグイン',
      },
      {
        value: 'linkedinInsightTag',
        text: 'LinkedIn Insight タグ',
      },
      {
        value: 'pinterestConversionTag',
        text: 'Pinterest コンバージョンタグ',
      },
      {
        value: 'tiktokPixel',
        text: 'TikTok ピクセル',
      },
      {
        value: 'twitterAnalytics',
        text: 'Twitterアナリティクス',
      },
    ],
  },
  {
    value: 'tag_managers',
    text: 'タグマネージャー',
    technologies: [
      {
        value: 'googleTagManager',
        text: 'Google タグマネージャー',
      },
    ],
  },
  {
    value: 'ab_testing',
    text: 'A/Bテスト',
    technologies: [
      {
        value: 'googleOptimize',
        text: 'Google オプティマイズ',
      },
    ],
  },
  {
    value: 'marketing_automation',
    text: 'マーケティングオートメーション',
    technologies: [
      {
        value: 'eloqua',
        text: 'Oracle Marketing (Eloqua)',
      },
      {
        value: 'hubspot',
        text: 'HubSpot',
      },
      {
        value: 'mailchimp',
        text: 'MailChimp',
      },
      {
        value: 'marketo',
        text: 'Marketo',
      },
      {
        value: 'pardot',
        text: 'Pardot (Salesforce)',
      },
      {
        value: 'adEbis',
        text: 'AD EBiS',
      },
    ],
  },
  {
    value: 'crm',
    text: 'CRM',
    technologies: [
      {
        value: 'freshworksCrm',
        text: 'Freshworks',
      },
      {
        value: 'sap',
        text: 'SAP',
      },
      {
        value: 'salesforce',
        text: 'Salesforce',
      },
      {
        value: 'pipedrive',
        text: 'Pipedrive',
      },
    ],
  },
  {
    value: 'advertising',
    text: '広告',
    technologies: [
      {
        value: 'amazonAdvertising',
        text: 'Amazon広告',
      },
      {
        value: 'googleAdsense',
        text: 'Googleアドセンス',
      },
      {
        value: 'googleAds',
        text: 'Google広告',
      },
      {
        value: 'microsoftAdvertising',
        text: 'Microsoft Advertising',
      },
      {
        value: 'pinterestAds',
        text: 'Pinterest広告',
      },
      {
        value: 'twitterAds',
        text: 'Twitter広告',
      },
      {
        value: 'yahooAdvertising',
        text: 'Yahoo!広告',
      },
    ],
  },
  {
    value: 'live_chat',
    text: 'チャット/顧客サポート',
    technologies: [
      {
        value: 'intercom',
        text: 'Intercom',
      },
      {
        value: 'salesforceServiceCloud',
        text: 'Salesforce (Service Cloud)',
      },
      {
        value: 'zendesk',
        text: 'Zendesk',
      },
    ],
  },
];

export default technologies;
