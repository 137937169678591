import gql from 'graphql-tag';

export default gql`
  fragment salesforceOpportunityStageFragment on SalesforceOpportunityStage {
    id
    opportunityStageId
    masterLabel
    apiName
  }
`;
