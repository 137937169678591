import React from 'react';
import {FormBlock, FormBlockFieldOther} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
}

const dataTypes: {[key: string]: any} = {
  text: 'text',
  number: 'number',
  date: 'date',
};

export default ({block}: Props) => {
  const blockable = block.formBlockable;
  const dataType = dataTypes[blockable.dataType];

  if (!dataType) return null;

  return (
    <div className="w-full">
      <div className="flex gap-1 mb-2 items-center">
        <label className="tracking-wide font-bold">{blockable.title}</label>
        {blockable.isRequired && <span className="text-red-500">*</span>}
        <span className="text-xs h-[18px] px-1 border border-c-border leading-none rounded-sm bg-[#F5FAFE] text-c-lighter flex items-center">
          その他の項目
        </span>
      </div>
      <input
        className="form-input block w-full border rounded placeholder-c-lighter"
        type="text"
        placeholder={blockable.placeholder}
        disabled
        onFocus={(e) => (e.target.type = dataType)}
        onBlur={(e) => (e.target.type = 'text')}
      />
      {blockable.helpText && (
        <div className="text-c-light text-sm whitespace-pre-wrap">
          {blockable.helpText}
        </div>
      )}
    </div>
  );
};
