import React from 'react';
import {Task} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;

  if (task.priority === 'high') {
    return <span className="text-[#eb5757]">High</span>;
  }

  if (task.priority === 'normal') {
    return <span className="text-[#ffc600]">Middle</span>;
  }

  if (task.priority === 'low') {
    return <span className="text-[#d5ebd1]">Low</span>;
  }

  return '';
};

export default Cell;
