import gql from 'graphql-tag';

export default gql`
  fragment leadContractFragment on LeadContract {
    id
    startDate
    expireDate
    sfaSynchronizable
    automaticMailSendsLimit
  }
`;
