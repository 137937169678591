import gql from 'graphql-tag';
import automaticMailSendFragment from '../automaticMailSend';
import automaticMailFragment from '../automaticMail';

export default gql`
  fragment automaticMailOpenProspectActivityFragment on AutomaticMailOpenProspectActivity {
    id
    automaticMailSend {
      ...automaticMailSendFragment
      automaticMail {
        ...automaticMailFragment
        stepAutomaticMail {
          step {
            name
          }
        }
      }
    }
  }
  ${automaticMailSendFragment}
  ${automaticMailFragment}
`;
