import gql from 'graphql-tag';

export default gql`
  fragment salesforceCampaignMemberFieldFragment on SalesforceCampaignMemberField {
    id
    fieldName
    fieldType
    label
    isRequired
  }
`;
