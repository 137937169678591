import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Popover} from 'components/antd';
import {
  PreleadProject,
  Activity,
  MailClickReportActivity,
  MailFormVersionUrlClickActivity,
} from 'api';
import {Calendar, LaunchLink} from 'components/Ui/Icon';

interface Props {
  row: any;
}

interface MailClickReport extends Activity {
  resource: MailClickReportActivity;
}

interface MailFormVersionUrlClick extends Activity {
  resource: MailFormVersionUrlClickActivity;
}

const ClickCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;

  const mailClickReportActivities = React.useMemo(
    () =>
      preleadProject.prelead.activities
        .filter(
          (activity) =>
            activity.resourceType === 'Activity::MailClickReport' &&
            activity.preleadProjectId === preleadProject.id,
        )
        .reverse(),
    [preleadProject],
  );

  const mailFormVersionUrlClickActivities = React.useMemo(
    () =>
      preleadProject.prelead.activities
        .filter(
          (activity) =>
            activity.resourceType === 'Activity::MailFormVersionUrlClick' &&
            activity.preleadProjectId === preleadProject.id,
        )
        .reverse(),
    [preleadProject],
  );

  const showPopover = React.useMemo(
    () =>
      preleadProject.prelead.activities
        .map((activity) => activity.preleadProjectId)
        .includes(preleadProject.id) &&
      (mailClickReportActivities.length > 0 ||
        mailFormVersionUrlClickActivities.length > 0),
    [
      preleadProject,
      mailClickReportActivities,
      mailFormVersionUrlClickActivities,
    ],
  );

  return (
    <Popover
      trigger="hover"
      placement="bottom"
      content={
        showPopover && (
          <TableWrapper>
            <Table>
              <tbody>
                <tr>
                  <ClickedDateTh>
                    <Calendar />
                    クリック日時
                  </ClickedDateTh>
                  <ClickedUrlTh>
                    <LaunchLink />
                    クリックURL
                  </ClickedUrlTh>
                </tr>

                {mailClickReportActivities.map(
                  (activity: MailClickReport, index) => (
                    <tr key={index}>
                      <ClickedDateTd>
                        {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
                      </ClickedDateTd>
                      <ClickedUrlTd>
                        <Url
                          href={activity.resource.campaignUrl.url}
                          target="_blank">
                          {activity.resource.campaignUrl.url}
                        </Url>
                      </ClickedUrlTd>
                    </tr>
                  ),
                )}
                {mailFormVersionUrlClickActivities.map(
                  (activity: MailFormVersionUrlClick, index) => (
                    <tr key={index}>
                      <ClickedDateTd>
                        {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
                      </ClickedDateTd>
                      <ClickedUrlTd>
                        <Url
                          href={activity.resource.mailFormVersionUrl.url}
                          target="_blank">
                          {activity.resource.mailFormVersionUrl.url}
                        </Url>
                      </ClickedUrlTd>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </TableWrapper>
        )
      }>
      {preleadProject.clickCount}
    </Popover>
  );
};

const TableWrapper = styled.div`
  width: 530px;
  padding: 16px 0 16px 0;
  max-height: 530px;
  overflow-y: scroll;
`;

const Table = styled.table`
  width: 470px;
  margin-left: 30px;
`;

const Th = styled.th`
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e6ea;

  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.15px;
  color: #bdc4cb;

  svg {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    top: 3px;
  }
`;

const ClickedDateTh = styled(Th)`
  border-right: 1px solid #e2e6ea;
  width: 180px;
  padding-left: 18px;
`;

const ClickedUrlTh = styled(Th)`
  padding-left: 25px;
`;

const Td = styled.td`
  padding: 14px 0 0 25px;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #495058;
`;

const ClickedDateTd = styled(Td)`
  padding-left: 20px;
  border-right: 1px solid #e2e6ea;
`;

const ClickedUrlTd = styled(Td)``;

const Url = styled.a`
  display: inline-block;
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #222426;
  position: relative;
  top: 2px;
`;

export default ClickCell;
