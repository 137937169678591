import React, {useState} from 'react';
import styled from 'styled-components';
import {Form} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {InputNumber} from 'components';

const SalesFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {sales} = query;
  const [from, setFrom] = useState(sales?.from);
  const [to, setTo] = useState(sales?.to);

  return (
    <Container>
      <Form.Item>
        <StyledInputNumber
          min={1}
          value={from}
          onChange={(value) => setFrom(value)}
          onBlur={() => setQuery({sales: {from: from, to: to}})}
        />
        <span> 万 〜 </span>
        <StyledInputNumber
          min={1}
          value={to}
          onChange={(value) => setTo(value)}
          onBlur={() => setQuery({sales: {from: from, to: to}})}
        />
        <span> 万</span>
      </Form.Item>
    </Container>
  );
};

const Container = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  border: 1px solid rgb(217, 217, 217) !important;
  width: 120px;
`;

export default SalesFilter;
