import gql from 'graphql-tag';
import automaticMailStepFragment from './automaticMail';
import manualMailStepFragment from './manualMail';
import callStepFragment from './call';
import taskStepFragment from './task';

export default gql`
  fragment stepFragment on Step {
    id
    uuid
    name
    memo
    status
    startDate
    startDay
    startHour
    daysOfWeek
    steppableId
    steppableType
    updatedAt
    createdAt
    position
    isImmediately
    dealsCount
    steppable {
      ... on AutomaticMailStep {
        ...automaticMailStepFragment
      }
      ... on ManualMailStep {
        ...manualMailStepFragment
      }
      ... on CallStep {
        ...callStepFragment
      }
      ... on TaskStep {
        ...taskStepFragment
      }
    }
  }
  ${automaticMailStepFragment}
  ${manualMailStepFragment}
  ${callStepFragment}
  ${taskStepFragment}
`;
