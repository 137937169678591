import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Select, Form, Input} from 'components/antd';
import {
  useUpdateCustomizeItemMutation,
  useDestroyCustomizeItemMutation,
  CustomizeItem,
} from 'api';
import {Calendar, HashTag, Text as TextIcon} from 'components/Ui/Icon';

const customizeItemsOptions = [
  {text: 'テキスト', value: 'text', icon: TextIcon},
  {text: '数字', value: 'number', icon: HashTag},
  {text: '日付', value: 'date', icon: Calendar},
];

interface Props {
  customizeItem: CustomizeItem;
  onClose: () => void;
}

const Edit = ({customizeItem, onClose}: Props) => {
  const [updateCustomizeItem] = useUpdateCustomizeItemMutation({
    onCompleted: () => onClose(),
  });

  const [destroyCustomizeItem] = useDestroyCustomizeItemMutation({
    variables: {id: customizeItem?.id},
    refetchQueries: ['currentClient'],
    onCompleted: onClose,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: customizeItem?.name,
      dataType: customizeItem?.dataType,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
      dataType: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) =>
      updateCustomizeItem({
        variables: {
          id: customizeItem.id,
          attributes: {
            ...values,
          },
        },
      }),
  });

  return (
    <Modal
      destroyOnClose
      visible={Boolean(customizeItem)}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>項目の編集・削除</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="項目名" required>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="データ形式" required>
            <Select
              value={formik.values.dataType}
              onSelect={(value: any) => formik.setFieldValue('dataType', value)}
              dropdownMatchSelectWidth={false}
              dropdownClassName="customize-item-dropdown"
              size="large"
              optionLabelProp="label"
              disabled>
              {customizeItemsOptions.map((option) => {
                return (
                  <Select.Option
                    value={option.value}
                    key={option.value}
                    label={
                      <>
                        <option.icon />
                        &nbsp; {option.text}
                      </>
                    }>
                    <option.icon />
                    &nbsp; {option.text}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <div>
              <Button
                danger
                onClick={() => {
                  if (
                    confirm(
                      'プレリードにおける当該項目の情報が全て削除されます。\n削除してよろしいですか？',
                    )
                  )
                    destroyCustomizeItem();
                }}
                size="large">
                削除
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{marginLeft: '8px'}}
                disabled={!formik.isValid}>
                更新
              </Button>
            </div>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Edit;
