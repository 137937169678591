import React from 'react';
import {useCreateMiitelCallActivityMutation, MiitelSetting} from 'api';

// MiiTel公式ドキュメント
// https://support.miitel.jp/hc/ja/articles/360030159052-JavaScript-%E3%82%A6%E3%82%A3%E3%82%B8%E3%82%A7%E3%83%83%E3%83%88

const useMiitel = (uuid: string, miitelSetting: MiitelSetting) => {
  const [createMiitelCallActivity] = useCreateMiitelCallActivityMutation();

  const [miitelSequenceId, setMiitelSequenceId] = React.useState('');

  const createActivity = (e: {eventName: string; sequenceId: string}) => {
    // 一回の発信で複数回createMiitelCallProspectActivityが実行されないようにしています。
    if (miitelSequenceId !== e.sequenceId) {
      setMiitelSequenceId(e.sequenceId);
      createMiitelCallActivity({
        variables: {
          preleadProjectUuid: uuid,
          miitelSequenceId: e.sequenceId,
        },
      });
    }
  };

  React.useEffect(() => {
    if (miitelSetting?.isAvailable && miitelSetting?.isActive && uuid) {
      const body = document.getElementsByTagName('body')[0];
      const scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.src = 'https://api.miitel.jp/static/widget/v1.js';
      scriptTag.id = 'miitel-widget-script';
      body.appendChild(scriptTag);
      // @ts-ignore
      window.miitelWidget =
        // @ts-ignore
        window.miitelWidget ||
        // @ts-ignore
        function (k, v) {
          // @ts-ignore
          miitelWidget.conf = miitelWidget.conf || {};
          // @ts-ignore
          miitelWidget.conf[k] = v;
        };

      // @ts-ignore
      miitelWidget('company_id', miitelSetting.miitelCompanyId);
      // @ts-ignore
      miitelWidget('access_key', miitelSetting.accessKey);

      if (location.pathname.startsWith('/list/')) {
        // @ts-ignore
        miitelWidget('onReceiveSequenceId', createActivity);
      }
    }

    return () => {
      document.getElementById('miitelPhoneIFrameButton')?.remove();
      document.getElementById('miitelPhoneIFrameOuter')?.remove();
      document.getElementById('miitel-widget-script')?.remove();
    };
  }, [miitelSetting, uuid]);
};

const miitelCall = (telephoneNumber: string) => {
  if (confirm(`「${telephoneNumber}」に発信してよろしいですか？`)) {
    // @ts-ignore
    miitelWidget.call(telephoneNumber);
  }
};

export {useMiitel, miitelCall};
