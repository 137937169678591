import React from 'react';

export default () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_48161_59942)">
        <path
          d="M48.3627 32.5461C48.0586 32.5461 48.0586 32.8503 48.3627 32.5461C48.0586 32.8503 48.0586 32.5461 48.3627 32.5461Z"
          fill="#8966EF"
        />
        <path
          d="M36.5004 51.1003C36.5004 53.2295 34.9796 54.7503 32.8504 54.7503C32.8504 54.7503 32.8504 54.7503 32.5462 54.7503C32.5462 54.7503 32.5463 54.7503 32.2421 54.7503H25.8546C25.8546 54.7503 25.8546 54.7503 25.5504 54.7503C25.5504 54.7503 25.5504 54.7503 25.2462 54.7503C23.1171 54.7503 21.5962 53.2295 21.5962 51.1003C21.5962 51.1003 21.5962 51.1003 21.5962 50.7961C21.5962 50.7961 21.5962 50.7961 21.5962 50.492V34.3711V25.5503H20.3796H18.2504C12.4712 25.5503 7.60458 30.1128 7.30042 35.892C7.30042 36.1961 7.30042 36.1961 7.30042 36.5003V38.6295V53.2295V56.5753V57.1836C7.30042 57.4878 7.30042 58.0961 7.30042 58.4003V58.7045C7.30042 64.7878 12.1671 69.6545 18.2504 69.6545C18.5546 69.6545 18.8587 69.6545 19.1629 69.6545C19.4671 69.6545 19.7712 69.6545 20.0754 69.6545H39.8463C45.9296 69.6545 50.7963 64.7878 50.7963 58.7045V47.7545H39.8463C38.0213 47.4503 36.5004 48.9711 36.5004 51.1003Z"
          fill="#8966EF"
        />
        <path
          d="M49.275 32.2412C48.9709 32.2412 48.9709 32.5454 48.6667 32.5454C48.9709 32.2412 49.275 32.2412 49.275 32.2412Z"
          fill="#3E3A39"
        />
        <path
          d="M64.4834 17.0333C64.4834 10.9499 59.6167 6.08325 53.5334 6.08325C53.2292 6.08325 52.925 6.08325 52.6209 6.08325C52.3167 6.08325 52.0125 6.08325 51.7084 6.08325H34.0667C33.7625 6.08325 33.4583 6.08325 33.1542 6.08325C32.85 6.08325 32.5458 6.08325 32.2417 6.08325C26.1583 6.08325 21.2917 10.9499 21.2917 17.0333C21.2917 17.3374 21.2917 17.6416 21.2917 17.9458C21.2917 18.2499 21.2917 18.5541 21.2917 18.8583V27.6791V36.4999V52.6208C21.2917 52.6208 21.2917 52.6208 21.2917 52.9249C21.2917 52.9249 21.2917 52.9249 21.2917 53.2291C21.2917 55.3583 22.8125 56.8791 24.9417 56.8791C24.9417 56.8791 24.9417 56.8791 25.2458 56.8791C25.2458 56.8791 25.2458 56.8791 25.55 56.8791H31.9375C31.9375 56.8791 31.9375 56.8791 32.2417 56.8791C32.2417 56.8791 32.2417 56.8791 32.5459 56.8791C34.675 56.8791 36.1958 55.3583 36.1958 53.2291C36.1958 51.0999 37.7167 49.5791 39.8458 49.5791H50.7959H52.3167H53.5334C59.6167 49.5791 64.4834 44.7124 64.4834 38.6291C64.4834 38.3249 64.4834 38.0208 64.4834 37.7166C64.4834 37.4124 64.4834 37.1083 64.4834 36.8041V19.1624C64.4834 18.8583 64.4834 18.5541 64.4834 18.2499C64.4834 17.6416 64.4834 17.3374 64.4834 17.0333ZM49.8833 31.6333C49.8833 32.8499 49.275 34.0666 48.3625 34.6749C48.0583 34.6749 48.0584 34.9791 47.7542 34.9791C47.7542 34.9791 47.7542 34.9791 47.45 34.9791H47.1459C47.1459 34.9791 47.1458 34.9791 46.8417 34.9791C46.5375 34.9791 46.5375 34.9791 46.2334 34.9791H38.9333C36.8042 34.9791 35.2833 33.4583 35.2833 31.3291V27.9833V24.3333C35.2833 22.2041 36.8042 20.6833 38.9333 20.6833H46.2334C48.3625 20.6833 49.8833 22.2041 49.8833 24.3333V31.6333Z"
          fill="#3E3A39"
        />
      </g>
      <defs>
        <clipPath id="clip0_48161_59942">
          <rect width="73" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
