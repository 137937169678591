import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_55455_3571)">
      <path
        d="M13.3372 6.87321C14.2558 6.87321 15.0005 6.11809 15.0005 5.1866C15.0005 4.25512 14.2558 3.5 13.3372 3.5C12.4185 3.5 11.6738 4.25512 11.6738 5.1866C11.6738 6.11809 12.4185 6.87321 13.3372 6.87321Z"
        fill="#21BDDB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60188 10.0249C6.50203 9.9591 6.21784 9.57272 5.43183 7.66934C5.38276 7.54992 5.3371 7.44694 5.294 7.35867L5.13313 6.96579L3.75571 3.59302H1L2.37785 6.96579L3.75571 10.3407L5.02816 13.4561C5.05249 13.5149 5.11991 13.5426 5.17794 13.5179C5.20568 13.5062 5.22744 13.4842 5.23896 13.4561L6.62364 10.095C6.63388 10.0694 6.62449 10.04 6.60188 10.0249Z"
        fill="#282828"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.764 10.0249C11.6641 9.9591 11.3799 9.57272 10.5939 7.66934C10.5449 7.54992 10.4988 7.44694 10.4557 7.35824L10.2957 6.96579L8.91782 3.59302H6.16211L7.53996 6.96579L8.91782 10.3407L10.1903 13.4561C10.2146 13.5149 10.282 13.5426 10.34 13.5179C10.3678 13.5062 10.3895 13.4842 10.4011 13.4561L11.7857 10.0954C11.7956 10.0694 11.7866 10.0404 11.764 10.0249Z"
        fill="#282828"
      />
    </g>
    <defs>
      <clipPath id="clip0_55455_3571">
        <rect
          width="14"
          height="10.027"
          fill="white"
          transform="translate(1 3.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
