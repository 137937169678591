import gql from 'graphql-tag';

export default gql`
  fragment customizeItemFragment on CustomizeItem {
    id
    clientId
    name
    columnName
    dataType
  }
`;
