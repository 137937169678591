import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input} from 'components/antd';
import {
  useUpdateCallNgReasonTemplateMutation,
  useDestroyCallNgReasonTemplateMutation,
  CallNgReasonTemplate,
} from 'api';

interface Props {
  ngTemplate: CallNgReasonTemplate;
  onClose: () => void;
}

const Update = ({ngTemplate, onClose}: Props) => {
  const [updateCallNgReasonTemplate] = useUpdateCallNgReasonTemplateMutation({
    onCompleted: () => onClose(),
  });

  const [destroyCallNgReasonTemplate] = useDestroyCallNgReasonTemplateMutation({
    variables: {id: ngTemplate?.id},
    refetchQueries: ['currentClient'],
    onCompleted: ({destroyCallNgReasonTemplate: {error}}) => {
      error ? alert(error) : onClose();
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: ngTemplate?.reason,
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) =>
      updateCallNgReasonTemplate({
        variables: {
          id: ngTemplate.id,
          attributes: {
            ...values,
          },
        },
      }),
  });

  return (
    <Modal
      destroyOnClose
      visible={Boolean(ngTemplate)}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>NG理由の編集・削除</h2>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="NG理由名" required>
            <Input
              size="large"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <div>
              <Button
                danger
                onClick={() => destroyCallNgReasonTemplate()}
                size="large">
                削除
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{marginLeft: '8px'}}
                disabled={!formik.isValid}>
                更新
              </Button>
            </div>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Update;
