import React from 'react';
import styled from 'styled-components';
import {Form} from 'components/antd';
import {
  ProspectPool,
  useSalesforceContactRecordTypeQuery,
  useUpdateSalesforceContactFieldMutation,
} from 'api';

interface Props {
  prospectPool: ProspectPool;
}

const DisplaySettingComponent = ({prospectPool}: Props) => {
  const [update] = useUpdateSalesforceContactFieldMutation();
  const {data: {salesforceContactRecordType = {}} = {}} =
    useSalesforceContactRecordTypeQuery({
      variables: {
        salesforceContactId: prospectPool.salesforceContactId,
      },
      skip: !prospectPool.salesforceContactId,
    });

  return (
    <Form>
      <Form.Item>
        <Content>
          <Row>
            <Title>項目名</Title>
            <Title>表示</Title>
          </Row>
          {salesforceContactRecordType?.salesforceContactFields?.map(
            (field, i) => (
              <Row key={i}>
                <div>{field.label}</div>
                <div>
                  <input
                    type="checkbox"
                    checked={field.isDisplay}
                    onChange={(e) =>
                      update({
                        variables: {id: field.id, isDisplay: e.target.checked},
                      })
                    }
                  />
                </div>
              </Row>
            ),
          )}
        </Content>
      </Form.Item>
    </Form>
  );
};

const Content = styled.div`
  margin-top: 1.5rem;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.15px;
`;

const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  & > div:first-child {
    width: 64%;
  }
  & > div:nth-child(2) {
    width: 32%;
  }
  & > div:last-child {
    width: 4%;
    text-align: center;
    > input {
      display: inline-block;
      cursor: pointer;
    }
  }
`;

export default DisplaySettingComponent;
