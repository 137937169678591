import gql from 'graphql-tag';

export default gql`
  fragment JobListingPageFragment on JobListingPage {
    id
    url
    websiteId
    websiteName
    companyId
    title
    date
  }
`;
