import React from 'react';
import {useAllFormsQuery} from 'api';
import useSearchParams from '../../../useSearchParams';
import CheckboxGroup from './CheckboxGroup';
import Header from '../Header';
import Footer from './Footer';

const FormFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {allForms = []} = {}} = useAllFormsQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <Header title="フォーム" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={allForms.map((form) => ({
              text: form.title,
              value: form.id,
              keyword: form.title,
            }))}
            values={query.formIds || []}
            onChange={(formIds) => setQuery({...query, formIds})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, formIds: []})} />
    </>
  );
};

export default FormFilter;
