import styled from 'styled-components';
import {Breadcrumb} from 'components/antd';

export default styled(Breadcrumb)`
  padding: 24px 0 24px 0px;
  margin-right: 25px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #bdc4cb;
  span {
    .current {
      color: #899098;
    }
  }

  a {
    color: #bdc4cb;
    font-weight: 500px;
    font-size: 12px;
    &:hover {
      color: #68b5fb;
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 25px;
    color: #bdc4cb;
  }
`;
