import React from 'react';
import moment from 'moment';
import {
  ProspectPool,
  useAllTrackingUrlsQuery,
  useTrackingCountsByProspectQuery,
} from 'api';
import useProspectContext from '../useProspectContext';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const [period, setPeriod] = React.useState<string>('');
  const [from, setFrom] = React.useState<string>('');
  const [to, setTo] = React.useState<string>('');

  const {poolId} = useProspectContext();
  const {data: {allTrackingUrls = []} = {}} = useAllTrackingUrlsQuery({
    variables: {
      uuid: poolId,
    },
  });

  const {data: {trackingCountsByProspect = []} = {}} =
    useTrackingCountsByProspectQuery({
      variables: {
        uuid: prospectPool.prospect.uuid,
        from: from || null,
        to: to || null,
      },
    });

  const onChangePeriod = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPeriod(e.target.value as any);
    setFrom('');
    setTo('');

    if (e.target.value === 'lastOneWeek') {
      setFrom(moment().subtract(1, 'week').format('YYYY-MM-DD'));
      setTo(moment().format('YYYY-MM-DD'));
    }

    if (e.target.value === 'lastOneMonth') {
      setFrom(moment().subtract(1, 'month').format('YYYY-MM-DD'));
      setTo(moment().format('YYYY-MM-DD'));
    }

    if (e.target.value === 'lastThreeMonths') {
      setFrom(moment().subtract(3, 'month').format('YYYY-MM-DD'));
      setTo(moment().format('YYYY-MM-DD'));
    }

    if (e.target.value === 'lastSizMonths') {
      setFrom(moment().subtract(6, 'month').format('YYYY-MM-DD'));
      setTo(moment().format('YYYY-MM-DD'));
    }
  };

  const data = allTrackingUrls
    .map((trackingUrl) => {
      const trackingCount = trackingCountsByProspect.find(
        (trackingCount) => trackingCount.trackingUrlId === trackingUrl.id,
      );

      if (!trackingCount) return null;

      return {
        title: trackingUrl.title,
        url: trackingUrl.url,
        trackedAt: trackingCount.latestTrackedAt,
        count: trackingCount.count,
        trackingUrlId: trackingCount.trackingUrlId,
      };
    })
    .filter(Boolean);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-1 text-sm">
        <span className="text-c-light">期間：</span>
        <select
          onChange={onChangePeriod}
          value={period}
          className="w-32 h-8 mr-4 form-select border-c-border rounded-sm text-c-light text-sm focus:outline-none flex items-center px-3 py-0">
          <option value=""></option>
          <option value="lastOneWeek">過去1週間</option>
          <option value="lastOneMonth">過去1ヶ月間</option>
          <option value="lastThreeMonths">過去3ヶ月間</option>
          <option value="lastSizMonths">過去6ヶ月間</option>
        </select>
        <input
          type="date"
          value={from}
          className="w-32 h-8 form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
          onChange={(e) => {
            setFrom(e.target.value);
            setPeriod('');
          }}
        />
        <span className="text-c-light">〜</span>
        <input
          type="date"
          value={to}
          className="w-32 h-8 form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
          onChange={(e) => {
            setTo(e.target.value);
            setPeriod('');
          }}
        />
      </div>
      <table className="w-full text-sm">
        <thead className="text-c-light text-left">
          <tr>
            <th className="border-b border-c-border">タイトル</th>
            <th className="w-32 border-b border-c-border">最終訪問日時</th>
            <th className="w-16 border-b border-c-border text-right pr-2">
              訪問回数
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr key={d.trackingUrlId}>
              <td className="border-b border-c-border py-2 pr-2">
                <a href={d.url} target="_blank">
                  {d.title}
                </a>
              </td>
              <td className="border-b border-c-border py-2">
                {moment(d.trackedAt).format('YYYY/MM/DD')}
                <br />
                {moment(d.trackedAt).format('HH:mm')}
              </td>
              <td className="text-right border-b border-c-border py-2 pr-2">
                {d.count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
