import React from 'react';
import {useParams} from 'react-router-dom';
import DeleteAction from './DeleteAction';
import BlockAction from './BlockAction';
import FilterAction from './FilterAction';
import SortAction from './SortAction';
import ColumnAction from './ColumnAction';
import SearchAction from './SearchAction';
import ContentAction from './ContentAction';
import useProject from 'hooks/useProject';
import AddTagAction from './AddTagAction';
import ActionDropDown from './ActionDropDown';

export const Action = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {isOwnProject} = useProject(projectId);

  return (
    <div className="flex items-center justify-end gap-x-4 px-2">
      <AddTagAction />
      {isOwnProject && <DeleteAction />}
      <BlockAction />
      <FilterAction />
      <SortAction />
      <ColumnAction />
      <ContentAction />
      <ActionDropDown />
      <SearchAction />
    </div>
  );
};
