import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import mailColor from 'helpers/mailColor';
import {StatusEclipse} from 'components/Ui';

const options = [
  {text: '未送信', value: 'unsent'},
  {text: '送信済み', value: 'sent'},
  {text: 'メール送信済み', value: 'mail'},
  {text: '項目エラー', value: 'invalid'},
  {text: '項目不足', value: 'shortage'},
  {text: '見つからない', value: 'notfound'},
  {text: '文字数オーバー', value: 'charover'},
  {text: '(不適切)採用/商品フォーム', value: 'recruit'},
  {text: '(不適切)営業お断り/その他', value: 'cannotaccept'},
  {text: '送信不可', value: 'unknown'},
  {text: '重複', value: 'duplicate'},
  {text: 'ブロック', value: 'block'},
  {text: 'URLなし', value: 'nourl'},
];

const MailStatusesFilter = ({
  filter,
  onChange,
}: {
  filter: any;
  onChange: (key: string, value: any) => void;
}) => {
  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={filter.status_values || []}
      onChange={(value) => onChange('status_values', value)}>
      {options.map((option) => (
        <StatusSelect.Option
          value={option.value}
          key={option.value}
          label={
            <React.Fragment>
              <StatusEclipse color={mailColor(option.value)} />
              &nbsp; {option.text}
            </React.Fragment>
          }>
          <StatusEclipse color={mailColor(option.value)} />
          &nbsp; {option.text}
        </StatusSelect.Option>
      ))}
    </Select>
  );
};

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

export default MailStatusesFilter;
