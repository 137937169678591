import React from 'react';
import {Task} from 'api';

interface Props {
  task: Task;
}

const Priority = ({task}: Props) => (
  <div className="flex items-center text-xs gap-1">
    <span className="text-c-lighter">担当者:</span>
    <span className="text-c-base">
      {task.user?.lastName} {task.user?.firstName}
    </span>
  </div>
);

export default Priority;
