import gql from 'graphql-tag';

export default gql`
  fragment salesforceTaskStatusFragment on SalesforceTaskStatus {
    id
    masterLabel
    isClosed
    taskStatus
  }
`;
