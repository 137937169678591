import React from 'react';

export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00002 1.75L11.0834 5.83333M7.00002 12.25V1.75V12.25ZM7.00002 1.75L2.91669 5.83333L7.00002 1.75Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
