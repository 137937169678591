import React from 'react';
import moment from 'moment';
import {ProspectPool} from 'api';
import Salesforce from '../Table/Salesforce';
import HubSpot from '../Table/HubSpot';

type Props = {
  prospectPool: ProspectPool;
};

export default ({prospectPool}: Props) => {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="block min-w-full pt-2">
          <div className="overflow-hidden">
            <table className="min-w-full border text-sm border-[#bec3cb] border-collapse">
              <tbody className="[&_td]:border-[#e0e6ec] [&_td]:border-l [&_td]:border-b [&_td]:px-2 [&_td]:py-2 [&_td]:whitespace-nowrap">
                <tr>
                  <td className="w-24 text-[#899098] !border-l-0">名前</td>
                  <td colSpan={2}>
                    <div className="flex items-end">
                      {prospectPool?.prospect?.lastName}{' '}
                      {prospectPool?.prospect?.firstName}
                      {prospectPool?.salesforceContactId && (
                        <span className="px-2 h-5 leading-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a] mx-2">
                          <Salesforce />
                          取引先責任者
                        </span>
                      )}
                      {prospectPool?.salesforceLeadId && (
                        <span className="px-2 h-5 leading-5 text-xs flex items-center gap-1 rounded-sm text-[#45a0dae5] bg-[#039be51a] mx-2">
                          <Salesforce />
                          リード
                        </span>
                      )}
                      {prospectPool?.hubspotContact && (
                        <span className="px-2 h-5 leading-5 text-xs flex items-center gap-1 rounded-sm text-[#ff7a59] bg-[#ff7a594d] mx-2">
                          <HubSpot />
                          コンタクト
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-[#899098] !border-l-0">会社名</td>
                  <td colSpan={2}>{prospectPool?.prospect?.account?.name}</td>
                </tr>
                <tr>
                  <td className="text-[#899098] !border-l-0">メールアドレス</td>
                  <td colSpan={2}>{prospectPool?.prospect?.email}</td>
                </tr>
                <tr>
                  <td className="text-[#899098] !border-l-0">電話番号</td>
                  <td colSpan={2}>{prospectPool?.prospect?.telephoneNumber}</td>
                </tr>
                <tr>
                  <td className="text-[#899098] !border-l-0">リードソース</td>
                  <td>
                    {prospectPool?.leadSource?.name && (
                      <div className="flex items-center gap-2">
                        <span className="text-[#899098]">最新</span>
                        <span className="h-5 leading-5 px-2">
                          {prospectPool.leadSource.name}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    {prospectPool?.originalLeadSource?.name && (
                      <div className="flex items-center gap-2 text-sm">
                        <span className="text-[#899098]">初回</span>
                        <span className="h-5 leading-5 px-2">
                          {prospectPool.originalLeadSource.name}
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="text-[#899098] !border-l-0">流入日</td>
                  <td>
                    {prospectPool?.latestInflowDate && (
                      <div className="flex items-center gap-2">
                        <span className="text-[#899098]">最新</span>
                        <span className="h-5 leading-5 px-2">
                          {moment(prospectPool.latestInflowDate).format(
                            'YYYY/MM/DD',
                          )}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    {prospectPool?.originalInflowDate && (
                      <div className="flex items-center gap-2 text-sm">
                        <span className="text-[#899098]">初回</span>
                        <span className="h-5 leading-5 px-2">
                          {moment(prospectPool.originalInflowDate).format(
                            'YYYY/MM/DD',
                          )}
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="text-[#899098] !border-l-0 !border-b-0">
                    リード担当者
                  </td>
                  <td colSpan={2} className="!border-b-0">
                    {prospectPool?.user?.lastName}{' '}
                    {prospectPool?.user?.firstName}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
