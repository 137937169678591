import React, {SVGProps} from 'react';
import {Link} from 'react-router-dom';
import {CollectedFormUrl} from 'api';
import usePools from 'hooks/usePools';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

const Handle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="6"
    height="18"
    viewBox="0 0 6 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2ZM1 6C1.55228 6 2 5.55228 2 5C2 4.44772 1.55228 4 1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6ZM2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9ZM1 14C1.55228 14 2 13.5523 2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14ZM2 17C2 17.5523 1.55228 18 1 18C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16C1.55228 16 2 16.4477 2 17ZM5 2C5.55228 2 6 1.55228 6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2ZM6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5ZM5 10C5.55228 10 6 9.55229 6 9C6 8.44771 5.55228 8 5 8C4.44772 8 4 8.44771 4 9C4 9.55229 4.44772 10 5 10ZM6 13C6 13.5523 5.55228 14 5 14C4.44772 14 4 13.5523 4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13ZM5 18C5.55228 18 6 17.5523 6 17C6 16.4477 5.55228 16 5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18Z"
      fill="currentColor"
    />
  </svg>
);

export default ({collectedFormUrl}: Props) => {
  const {defaultPool} = usePools();
  return (
    <div className="bg-white pl-4 pr-0 py-2 flex items-center gap-4 rounded cursor-pointer mb-2">
      <Handle className="text-c-lighter cursor-move dragHandle" />
      <div className="flex flex-col flex-1">
        <h3 className="m-0">
          <Link to={`/form/collected_form_urls/${collectedFormUrl.uuid}`}>
            {collectedFormUrl.title}
          </Link>
        </h3>
        <ul className="text-c-light text-sm flex items-center gap-4">
          <li className="w-fit inline-block">
            <a
              href={collectedFormUrl.url}
              className="break-all"
              target="_blank">
              {collectedFormUrl.url}
            </a>
          </li>
        </ul>
      </div>
      <ul className="flex">
        <li className="w-24 flex flex-col items-center justify-center border-l border-c-border px-4">
          <h5 className="text-sm m-0">受信数</h5>
          <span className="text-xl text-c-primary font-bold w-full text-right">
            <Link
              to={`/pools/${defaultPool?.uuid}/prospects/?collectedFormUrlIds=${collectedFormUrl.id}`}>
              {collectedFormUrl.collectedFormCount}
            </Link>
          </span>
        </li>
      </ul>
    </div>
  );
};
