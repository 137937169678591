import React from 'react';
import styled from 'styled-components';
import {SecondMenu} from './SecondMenu';
import {Content} from './Content';
import {useProjectQuery} from 'api';
import {Breadcrumb, Header, Container} from 'components/Ui';
import {Link, useParams} from 'react-router-dom';
import Menu from '../Menu';
import WebsiteMenu from '../WebsiteMenu';
import CompanyInfo from '../CompanyInfo';
import {useQueryParam, StringParam} from 'use-query-params';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {createPath} = useProjectPath();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });
  const [specificCompanyId] = useQueryParam('specificCompanyId', StringParam);

  return (
    <Container page="website">
      <Header padding={32}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={createPath('projects')}>営業リスト一覧</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={createPath(
                `projects/${project?.uuid}/search_condition?status=draft`,
              )}>
              {project?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="current">メディア一覧</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <ActionWrapper>
        {specificCompanyId && <CompanyInfo />}
        <Menu />
      </ActionWrapper>
      <WebsiteMenu />
      <Body>
        <SecondMenu />
        <Content />
      </Body>
    </Container>
  );
};

const ActionWrapper = styled.div`
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  padding-left: 8px;
  background: #fff;
`;

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
`;
