import React from 'react';
import {AccountGroup} from 'api';

interface Props {
  accountGroup: AccountGroup;
}

export default ({accountGroup}: Props) => {
  const total = React.useMemo(() => {
    if (accountGroup.processingImportAccountGroup) {
      return accountGroup.processingImportAccountGroup.rowCount;
    }
    if (accountGroup.processingAccountGroupStageUpdate) {
      return accountGroup.processingAccountGroupStageUpdate.totalCount;
    }
    if (accountGroup.processingAccountGroupTagUpdate) {
      return accountGroup.processingAccountGroupTagUpdate.totalCount;
    }
    return 0;
  }, [accountGroup]);

  const percent = React.useMemo(() => {
    if (accountGroup.processingImportAccountGroup) {
      return accountGroup.processingImportAccountGroup.percent;
    }
    if (accountGroup.processingAccountGroupStageUpdate) {
      return accountGroup.processingAccountGroupStageUpdate.percent;
    }
    if (accountGroup.processingAccountGroupTagUpdate) {
      return accountGroup.processingAccountGroupTagUpdate.percent;
    }
    return 0;
  }, [accountGroup]);

  const message = React.useMemo(() => {
    if (accountGroup.status === 'importing') {
      return `${total?.toLocaleString() || '0'}件 インポート中…`;
    }
    if (accountGroup.status === 'updating_stage') {
      return `${total?.toLocaleString() || '0'}件 一括処理中…`;
    }
    if (accountGroup.status === 'updating_tag') {
      return `${total?.toLocaleString() || '0'}件 一括処理中…`;
    }
  }, [accountGroup]);

  return (
    <div className="w-48">
      <div className="mb-1 text-sm font-bold text-c-purple">{message}</div>
      <div className="w-full bg-c-bg rounded-full h-1.5">
        <div
          className="bg-c-purple h-1.5 rounded-full"
          style={{width: `${percent || 0}%`}}
        />
      </div>
    </div>
  );
};
