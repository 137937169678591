import gql from 'graphql-tag';
import telephoneNumberFragment from '../telephoneNumber';
import preleadFaxNumberFragment from '../preleadFaxNumber';
import mailAddressFragment from '../mailAddress';
import preleadTagFragment from '../preleadTag';
import preleadCustomizeItemTextFragment from './customizeItemText';
import preleadCustomizeItemNumberFragment from './customizeItemNumber';
import preleadCustomizeItemDateFragment from './customizeItemDate';
import preleadCustomizeItemSelectFragment from './customizeItemSelect';
import projectGroupCustomizeItemTextFragment from './projectGroupCustomizeItemText';
import projectGroupCustomizeItemNumberFragment from './projectGroupCustomizeItemNumber';
import projectGroupCustomizeItemDateFragment from './projectGroupCustomizeItemDate';
import projectGroupCustomizeItemSelectFragment from './projectGroupCustomizeItemSelect';

export default gql`
  fragment preleadFragment on Prelead {
    id
    uuid
    userId
    remindAt
    lastProjectId
    name
    webSite
    tel
    telephoneNumber
    faxNumber
    employeeNumber
    officesNumber
    address
    zipCode
    representativePerson
    capitalFund
    mailAddress
    facebookUrls
    instagramUrls
    youtubeUrls
    twitterUrls
    lineUrls
    linkedinUrls
    prLastPublishedAt
    telephoneNumbers {
      ...telephoneNumberFragment
    }
    preleadFaxNumbers {
      ...preleadFaxNumberFragment
    }
    mailAddresses {
      ...mailAddressFragment
    }
    accountClosingMonth
    establishedYearMonth
    hubspotCompanyId
    hubspotDealId
    sensesCustomerId
    sensesDealId
    companyId
    corpNumber
    sales
    listingMarket
    preleadTags {
      ...preleadTagFragment
    }
    listingMarketText
    status
    clientPreleadStatusId
    mailStatus
    mailStatusText
    mailCount
    responseStatus
    responseStatusText
    clickCount
    clickUpdatedAt
    telCount
    campaignCount
    activityUpdatedAt
    officesDomesticBranchesNumber
    officesDomesticFactoriesNumber
    officesDomesticStoresNumber
    officesForeignBranchesNumber
    officesForeignFactoriesNumber
    officesForeignStoresNumber
    commentCount
    mainCategoryIds
    subCategoryIds
    isUnsubscribed
    prefectureId
    clientTelStatusId
    companyStatus
    telUserId
    isBounced
    lastProjectId
    telUpdatedAt
    activityMailCampaignMailOpensCount
    activityMailCampaignMailClicksCount
    customizeItemTexts {
      ...preleadCustomizeItemTextFragment
    }
    customizeItemNumbers {
      ...preleadCustomizeItemNumberFragment
    }
    customizeItemDates {
      ...preleadCustomizeItemDateFragment
    }
    customizeItemSelects {
      ...preleadCustomizeItemSelectFragment
    }
    projectGroupCustomizeItemTexts {
      ...preleadProjectGroupCustomizeItemTextFragment
    }
    projectGroupCustomizeItemNumbers {
      ...preleadProjectGroupCustomizeItemNumberFragment
    }
    projectGroupCustomizeItemDates {
      ...preleadProjectGroupCustomizeItemDateFragment
    }
    projectGroupCustomizeItemSelects {
      ...preleadProjectGroupCustomizeItemSelectFragment
    }
  }
  ${telephoneNumberFragment}
  ${preleadFaxNumberFragment}
  ${mailAddressFragment}
  ${preleadTagFragment}
  ${preleadCustomizeItemTextFragment}
  ${preleadCustomizeItemNumberFragment}
  ${preleadCustomizeItemDateFragment}
  ${preleadCustomizeItemSelectFragment}
  ${projectGroupCustomizeItemTextFragment}
  ${projectGroupCustomizeItemNumberFragment}
  ${projectGroupCustomizeItemDateFragment}
  ${projectGroupCustomizeItemSelectFragment}
`;
