import gql from 'graphql-tag';

export default gql`
  fragment salesforceLeadFieldFragment on SalesforceLeadField {
    id
    fieldName
    fieldType
    label
    isDisplay
  }
`;
