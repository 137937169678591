import React from 'react';

const Icon = ({color = '#BDC4CB'}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M7 14C4.58734 14 2.625 12.0377 2.625 9.625V3.5C2.625 3.17743 2.88637 2.9167 3.2083 2.9167C3.53033 2.9167 3.7917 3.17743 3.7917 3.5V9.625C3.7917 11.3937 5.23077 12.8333 7 12.8333C8.76923 12.8333 10.2083 11.3937 10.2083 9.625V3.2083C10.2083 2.0825 9.2925 1.1667 8.1667 1.1667C7.0408 1.1667 6.125 2.0825 6.125 3.2083V9.0417C6.125 9.52406 6.51753 9.9167 7 9.9167C7.48247 9.9167 7.875 9.52406 7.875 9.0417V3.5C7.875 3.17743 8.13637 2.9167 8.4583 2.9167C8.78033 2.9167 9.0417 3.17743 9.0417 3.5V9.0417C9.0417 10.1675 8.12579 11.0833 7 11.0833C5.87421 11.0833 4.9583 10.1675 4.9583 9.0417V3.2083C4.9583 1.43971 6.39737 0 8.1667 0C9.93593 0 11.375 1.43971 11.375 3.2083V9.625C11.375 12.0377 9.41266 14 7 14Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
