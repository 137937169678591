import React from 'react';
import {useUsersByClientAllQuery, User} from 'api';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import useCondition from '../../useCondition';

const LeadSourceFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const formatUserName = (user:User) => {
    return [user.lastName, user.firstName].filter(Boolean).join(' ');
  }

  return (
    <>
      <Header title="担当者" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={users.map((user) => ({
              text: formatUserName(user),
              value: user.id,
              keyword: formatUserName(user),
            }))}
            values={condition.users || []}
            onChange={(users) => setCondition({users})}
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({users: []})} />
    </>
  );
};

export default LeadSourceFilter;
