import React from 'react';
import {useParams} from 'react-router-dom';
import {useTable} from 'react-table';
import Pagination from '../../Pagination';
import {
  useAccountGroupAccountsQuery,
  AccountGroupAccount,
  AccountGroup,
} from 'api';
// @ts-ignore
import useSearchParams from './useSearchParams';
import MenuCell from './Cell/MenuCell';

interface Props {
  accountGroup: AccountGroup;
}

export default ({accountGroup}: Props) => {
  const {accountGroupId} = useParams<{accountGroupId: string}>();
  const {page, perPage, setQuery, searchWord} = useSearchParams();
  const {
    data: {
      accountGroupAccounts: {accountGroupAccounts = [], pagination = {}} = {},
    } = {},
    startPolling,
    stopPolling,
  } = useAccountGroupAccountsQuery({
    variables: {
      uuid: accountGroupId,
      page: page,
      perPage: perPage,
      searchWord: searchWord,
    },
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (accountGroup?.status === 'importing') {
      startPolling(3000);
    } else {
      stopPolling();
    }
  }, [accountGroup, startPolling, stopPolling]);

  const columns: any = React.useMemo(
    () => [
      {
        id: 'name',
        Header: '企業名',
        accessor: (row: AccountGroupAccount) => (
          <div className="text-c-base font-bold">{row.account?.name}</div>
        ),
      },
      {
        id: 'website',
        Header: 'URL',
        accessor: (row: AccountGroupAccount) =>
          row.account?.webSite ? (
            <a href={row.account?.webSite} target="_blank">
              {row.account?.webSite}
            </a>
          ) : null,
        style: {width: '20%'},
      },
      {
        id: 'address',
        Header: '住所',
        accessor: (row: AccountGroupAccount) => <>{row.account?.address}</>,
        style: {width: '20%'},
      },
      {
        id: 'tel',
        Header: '電話番号',
        accessor: (row: AccountGroupAccount) => (
          <>{row.account?.telephoneNumber}</>
        ),
        style: {width: '20%'},
      },
      {
        id: 'menu',
        Header: '',
        accessor: (row: AccountGroupAccount) => (
          <MenuCell accountGroupAccount={row} />
        ),
        style: {width: '10%'},
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<AccountGroupAccount>({columns, data: accountGroupAccounts || []});

  return (
    <div>
      <table
        {...getTableProps()}
        className="table-fixed text-sm text-c-light w-full border-separate">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    className="py-2 pr-4 text-left border-b border-c-border"
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-2 pr-4 text-left border-b border-c-border"
                      key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center">
        <Pagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={perPage}
          onChange={(page) => setQuery({page})}
        />
      </div>
    </div>
  );
};
