import gql from 'graphql-tag';

export default gql`
  fragment campaignDownloadFragment on CampaignDownload {
    id
    uuid
    campaignId
    file
    filename
    fields
  }
`;
