import React, {useMemo} from 'react';
import {useProjectTelReportsByCategoryQuery} from 'api';
import {useParams} from 'react-router-dom';
import Table from '../Table';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {projectTelReportsByCategory = []} = {}} =
    useProjectTelReportsByCategoryQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
    });

  const data: any = useMemo(
    () =>
      projectTelReportsByCategory.map((report) => {
        return {
          name: report.categoryName,
          callCount: report.callCount,
          contactCount: report.contactCount,
          contactCallRate: report.contactCallRate,
          documentCount: report.documentCount,
          documentCallRate: report.documentCallRate,
          appointCount: report.appointCount,
          appointCallRate: report.appointCallRate,
        };
      }),
    [projectTelReportsByCategory],
  );

  return <Table data={data} />;
};
