import React from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {useParams, useHistory} from 'react-router-dom';
import {
  Form,
  useUpdateFormBlockPositionMutation,
  useCreateFormBlockButtonMutation,
  useCreateFormBlockTextMutation,
  useCreateFormBlockImageMutation,
} from 'api';
import Block from './Block';
import Toolbox from './Toolbox';
import FormStyleContainer from 'components/FormStyleContainer';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const history = useHistory();
  const {formId} = useParams<{
    formId: string;
  }>();

  const [createFormBlockButton] = useCreateFormBlockButtonMutation({
    refetchQueries: ['form'],
  });

  const [createFormBlockText] = useCreateFormBlockTextMutation({
    refetchQueries: ['form'],
  });

  const [createFormBlockImage] = useCreateFormBlockImageMutation({
    refetchQueries: ['form'],
  });

  const [updateFormBlockPosition] = useUpdateFormBlockPositionMutation({
    refetchQueries: ['form'],
  });

  const formPage = React.useMemo(
    () => form?.formPages?.find((page) => page.slug === 'done'),
    [form],
  );

  const formBlocks = React.useMemo(
    () => formPage?.formBlocks || [],
    [formPage],
  );

  const [blocks, setBlocks] = React.useState(formBlocks);

  const onDragStart = React.useCallback(() => {
    history.push(`/form/forms/${formId}/done/blocks`);
  }, [history, formId]);

  const onDrop = React.useCallback(
    (dropResult: any) => {
      const {addedIndex, removedIndex, payload} = dropResult;

      if (addedIndex === null) return;

      // text
      if (payload?.blockType === 'text') {
        createFormBlockText({
          variables: {
            uuid: formId,
            slug: formPage?.slug,
            position: addedIndex,
          },
        });
        return;
      }

      // button
      if (payload?.blockType === 'button') {
        createFormBlockButton({
          variables: {
            uuid: formId,
            slug: formPage?.slug,
            position: addedIndex,
          },
        });
        return;
      }

      // image
      if (payload?.blockType === 'image') {
        createFormBlockImage({
          variables: {
            uuid: formId,
            slug: formPage?.slug,
            position: addedIndex,
          },
        });
        return;
      }

      // reorder
      if (removedIndex === addedIndex) return;
      if (removedIndex === null) return;

      const result = [...blocks];
      const itemToAdd = result.splice(removedIndex, 1)[0];
      result.splice(addedIndex, 0, itemToAdd);
      setBlocks(result);

      updateFormBlockPosition({
        variables: {
          uuid: formId,
          slug: formPage?.slug,
          from: removedIndex,
          to: addedIndex,
        },
      });
    },
    [blocks],
  );

  React.useEffect(() => {
    setBlocks(formBlocks);
  }, [formBlocks]);

  return (
    <>
      <Toolbox form={form} />
      <div className="flex-1">
        <div className="w-full rounded border border-c-border flex items-center justify-center p-12">
          <FormStyleContainer
            form={form}
            className="w-[480px] flex flex-col gap-8">
            <Container
              groupName="formBlocks"
              lockAxis="y"
              onDragStart={onDragStart}
              dragHandleSelector=".dragHandle"
              onDrop={onDrop}>
              {blocks?.map((block) => (
                <Draggable key={block.uuid} className="mb-4">
                  <Block block={block} />
                </Draggable>
              ))}
            </Container>
          </FormStyleContainer>
        </div>
      </div>
    </>
  );
};
