import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import useCondition from '../../useCondition';
import MARKETS from 'helpers/markets';

const ListingMarketFilter = () => {
  const {condition, setCondition} = useCondition();

  return (
    <>
      <Header title="上場区分" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            options={MARKETS.map((listingMarket) => ({
              text: listingMarket.name,
              value: String(listingMarket.id),
            }))}
            values={condition.listingMarkets || []}
            onChange={(listingMarkets) => setCondition({listingMarkets})}
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({listingMarkets: []})} />
    </>
  );
};

export default ListingMarketFilter;
