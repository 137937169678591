import React from 'react';
import {useUpdateTaskMutation, useUsersByClientAllQuery, Task} from 'api';
import {Select} from 'components/antd';

interface Props {
  task: Task;
}

export default ({task}: Props) => {
  const [updateTask] = useUpdateTaskMutation();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  return (
    <Select
      value={task.userId}
      onChange={(value) =>
        updateTask({
          variables: {uuid: task.uuid, attributes: {userId: value}},
        })
      }>
      {users.map((user) => (
        <Select.Option key={user.uuid} value={user.id}>
          {user.lastName} {user.firstName}
        </Select.Option>
      ))}
    </Select>
  );
};
