import React from 'react';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  FormBlock,
  FormBlockFieldStandard,
  FormBlockFieldStandardAttributes,
  useUpdateFormBlockFieldStandardMutation,
} from 'api';
import {useForm} from 'react-hook-form';
import debounce from 'helpers/debounce';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldStandard;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const [update] = useUpdateFormBlockFieldStandardMutation();

  const {register, watch, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        isRequired: Yup.boolean().required(),
      }),
    ),
    defaultValues: {
      label:
        block.formBlockable.label || block.formBlockable.standardFieldTypeText,
      labelFirstName: block.formBlockable.labelFirstName || '名',
      labelLastName: block.formBlockable.labelLastName || '姓',
      isRequired: block.formBlockable.isRequired,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: FormBlockFieldStandardAttributes) => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-bold">
        {block.formBlockable.standardFieldTypeText}の編集
      </h2>
      {block.formBlockable.standardFieldType === 'name' ? (
        <div className="flex flex-col w-full gap-1">
          <label className="tracking-wide font-medium text-base text-c-light">
            タイトル
          </label>
          <div className="flex gap-4">
            <input
              type="text"
              placeholder="姓"
              className="form-input rounded border-c-border text-base"
              {...register('labelLastName')}
            />
            <input
              type="text"
              placeholder="名"
              className="form-input rounded border-c-border text-base"
              {...register('labelFirstName')}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full gap-1">
          <label className="tracking-wide font-medium text-base text-c-light">
            タイトル
          </label>
          <input
            type="text"
            className="form-input rounded border-c-border text-base"
            {...register('label')}
          />
        </div>
      )}
      <div className="flex flex-col w-full gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          チェック項目
        </label>
        <label className="flex items-center text-base">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 rounded border-c-border"
            {...register('isRequired')}
          />
          <span className="ml-2">この項目を必須にする</span>
        </label>
      </div>
    </div>
  );
};
