import React from 'react';
import {useOpenWorkflowsQuery} from 'api';
import useSearchParams from '../../../useSearchParams';
import CheckboxGroup from './CheckboxGroup';
import Header from '../Header';
import Footer from './Footer';
import usePools from 'hooks/usePools';

const WorkflowFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {defaultPool} = usePools();

  const {data: {openWorkflows = []} = {}} = useOpenWorkflowsQuery({
    variables: {poolUuid: defaultPool.uuid},
    fetchPolicy: 'cache-and-network',
    skip: !defaultPool,
  });

  return (
    <>
      <Header title="ワークフロー" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={openWorkflows.map((workflow) => ({
              text: workflow.name,
              value: workflow.id,
              keyword: workflow.name,
            }))}
            values={query.workflowIds || []}
            onChange={(workflowIds) => setQuery({...query, workflowIds})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, workflowIds: []})} />
    </>
  );
};

export default WorkflowFilter;
