import React from 'react';
import {useUpdateCollectedFormUrlMutation, CollectedFormUrl} from 'api';
import AutoreplySubjectPage from './AutoreplyMailSubject';
import AutoreplyFromAddressPage from './AutoreplyMailFromAddress';
import AutoreplyFromNamePage from './AutoreplyMailFromName';
import AutoreplyBodyPage from './AutoreplyMailBody';
import AutoreplyMailBcc from './AutoreplyMailBcc';
import AutoreplyMailCc from './AutoreplyMailCc';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const [updateCollectedFormUrl] = useUpdateCollectedFormUrlMutation();

  return (
    <div className="flex flex-col gap-4 w-full">
      <label className="flex items-center text-base w-[fit-content] cursor-pointer">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 rounded border-c-border  cursor-pointer"
          checked={collectedFormUrl.sendAutoreplyMail}
          onChange={(e) =>
            updateCollectedFormUrl({
              variables: {
                collectedFormUrlUuid: collectedFormUrl.uuid,
                attributes: {
                  sendAutoreplyMail: e.target.checked,
                },
              },
            })
          }
        />
        <span className="ml-2">自動返信メールON</span>
      </label>
      <div className="border rounded border-c-border bg-c-bg flex justify-center">
        <div className="bg-white w-[720px] flex flex-col gap-8 pt-8 px-16 pb-24">
          <AutoreplySubjectPage collectedFormUrl={collectedFormUrl} />
          <AutoreplyFromAddressPage collectedFormUrl={collectedFormUrl} />
          <AutoreplyMailCc collectedFormUrl={collectedFormUrl} />
          <AutoreplyMailBcc collectedFormUrl={collectedFormUrl} />
          <AutoreplyFromNamePage collectedFormUrl={collectedFormUrl} />
          <AutoreplyBodyPage collectedFormUrl={collectedFormUrl} />
        </div>
      </div>
    </div>
  );
};
