import React from 'react';
import styled from 'styled-components';
import {
  useLeadSourcesQuery,
  useWorkflowQuery,
  useUpdateWorkflowSearchConditionMutation,
} from 'api';
import {useParams} from 'react-router-dom';
import {Checkbox} from 'components/antd';

export default () => {
  const {poolId, workflowId} = useParams<{
    poolId: string;
    workflowId: string;
  }>();

  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({
    variables: {uuid: poolId},
    fetchPolicy: 'cache-and-network',
    skip: !poolId,
  });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <Item>
      <Label>リードソース</Label>
      <CheckboxGroup
        value={searchCondition?.leadSources || []}
        disabled={!isConditionUpdatable}
        onChange={(values: string[]) =>
          updateWorkflowSearchCondition({
            variables: {
              uuid: workflowId,
              attributes: {
                leadSources: values,
              },
            },
          })
        }>
        <Checkbox value="0">なし</Checkbox>
        {leadSources.map((leadSource) => (
          <Checkbox value={leadSource.id} key={leadSource.id}>
            {leadSource.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Label = styled.div`
  width: 115px;
  font-weight: 500;
  font-size: 14px;
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;

  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-checkbox {
    top: 0;
  }
`;
