import React, {useState} from 'react';
import styled from 'styled-components';
import {Input, Modal, Select} from 'components/antd';
import {Button} from 'components/Ui';
import {
  useCreateProjectByPreleadMasterMutation,
  usePreleadsQuery,
  useProjectGroupsQuery,
} from 'api';
import {List, Folder, Note} from 'components/Ui/Icon';
import useSearchParams from '../../useSearchParams';
import {useHistory} from 'react-router';
import {Quill, QuillWrapper} from 'components/Ui';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const [projectGroupId, setProjectGroupId] = useState(null);
  const [projectName, setProjectName] = useState('新規営業リスト名');
  const [projectMemo, setProjectMemo] = useState(null);
  const [projectMemoHtml, setProjectMemoHtml] = useState(
    '<p>業種:</p><p>エリア:</p><p>規模:</p>',
  );
  const [projectCount, setProjectCount] = useState(null);
  const {searchParams, page, perPage} = useSearchParams();
  const history = useHistory();
  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery();

  const {data: {preleads: {pagination = {}} = {}} = {}} = usePreleadsQuery({
    variables: {
      search: searchParams,
      page,
      perPage,
    },
    onCompleted: () => setProjectCount(pagination.totalCount),
  });

  const handleClose = () => {
    setProjectName('');
    setProjectMemo(null);
    setProjectMemoHtml(null);
    setProjectCount(null);
    setModalVisible(false);
  };

  const [createProject, {loading}] = useCreateProjectByPreleadMasterMutation({
    refetchQueries: ['currentUser'],
    onCompleted: ({createProjectByPreleadMaster: {project = {}} = {}}) => {
      const projectGroup = projectGroups.find(
        (projectGroup) => projectGroup.id === project.projectGroupId,
      );
      history.push(
        `/list/groups/${projectGroup?.uuid}/projects/${project.uuid}/master_search_condition`,
      );
    },
  });

  const handleSubmit = () => {
    createProject({
      variables: {
        attributes: {
          name: projectName,
          memo: projectMemo,
          memoHtml: projectMemoHtml,
          projectGroupId: projectGroupId,
        },
        count: projectCount,
        search: searchParams,
      },
    });
  };

  return (
    <ReflectModal
      destroyOnClose
      title={<Title>新規営業リストを作成</Title>}
      maskClosable={false}
      visible={isModalVisible}
      width={540}
      centered
      bodyStyle={{paddingTop: '24px', padding: 0, overflow: 'scroll'}}
      footer={null}
      onCancel={handleClose}>
      <ModalContent>
        <div>フィルタリングされている状態で営業リストが作成できます。</div>

        <div>
          <span>現在のフィルタリング件数:</span>
          <Count>{pagination.totalCount?.toLocaleString()}</Count>
        </div>

        <div>
          <h3>グループ選択</h3>
          <ItemSelect>
            <Select value={projectGroupId} onChange={setProjectGroupId}>
              {projectGroups?.map((projectGroup) => (
                <Select.Option
                  value={projectGroup.id}
                  key={projectGroup.id}
                  label={
                    <React.Fragment>&nbsp; {projectGroup.name}</React.Fragment>
                  }>
                  &nbsp; {projectGroup.name}
                </Select.Option>
              ))}
            </Select>
          </ItemSelect>
        </div>

        <div>
          <h3>営業リスト作成</h3>
          <Item>
            <Folder />
            <NameInput
              name="name"
              placeholder="新規営業リスト名"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Item>
          <ItemMemo>
            <Note />
            <QuillWrapper>
              <Quill
                theme="snow"
                value={projectMemoHtml}
                onChange={(content, delta, source, editor) => {
                  setProjectMemoHtml(content);
                  setProjectMemo(editor.getText());
                }}
                modules={{
                  toolbar: [
                    [{header: [1, 2, 3, false]}],
                    ['bold', 'italic', 'underline'],
                    ['code-block'],
                    ['link'],
                  ],
                }}
                formats={[
                  'header',
                  'list',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'color',
                  'background',
                  'code-block',
                  'link',
                ]}
                bounds={'#quill-container'}
              />
              <div id="quill-container" />
            </QuillWrapper>
          </ItemMemo>
          <Item>
            <List />
            <div>
              <NameInput
                type="number"
                name="count"
                placeholder="件数"
                value={projectCount}
                defaultValue={10}
                onChange={(e) =>
                  (parseInt(e.target.value) > 0 || e.target.value === '') &&
                  setProjectCount(parseInt(e.target.value) || null)
                }
              />
              <span>
                <span className="red">＊&nbsp;</span>上限20000件
              </span>
            </div>
          </Item>
          <ButtonWrapper>
            <CustomButton
              key="submit"
              onClick={handleSubmit}
              loading={loading}
              disabled={
                !projectGroupId ||
                !projectName ||
                !projectCount ||
                projectCount > 20000
              }>
              作成
            </CustomButton>
          </ButtonWrapper>
        </div>
      </ModalContent>
    </ReflectModal>
  );
};

const ReflectModal = styled(Modal)`
  &&& {
    .ant-modal-header {
      border: none;
      padding: 16px 24px 13px 24px !important;
    }
    .ant-modal-body {
      padding: 0px 24px !important;
      height: 526px;
    }
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const CustomButton = styled(Button)`
  width: 86px;
  height: 28px;

  span {
    font-weight: bold;
    font-size: 10px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #222426;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  > *:first-child {
  }

  > *:nth-child(2) {
    margin: 6px 0 6px 0;
    display: flex;
    align-items: center;
    max-width: 200px;
    height: 30px;
    padding: 8px;
    background: #f3f5f7;
    border-radius: 4px;
    font-size: 10px;
  }

  > *:nth-child(3) {
    margin-top: 20px;
  }

  > *:nth-child(4) {
    margin-top: 20px;
    height: 220px;

    > div {
      margin-bottom: 17px;
    }

    > div:last-child {
      margin-bottom: 0px;
    }
  }

  > * {
    > h3 {
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.15px;
      color: #899098;
    }
  }
`;

const Count = styled.span`
  margin-left: 7px;
  font-weight: bold;
  font-size: 13px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 26px;
  > svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
  > div {
    flex: 1;

    > span {
      display: flex;
      margin-top: 5px;
      .red {
        color: #eb5757;
      }
    }

    span {
      font-size: 9px;
      line-height: 13px;
      color: #899098;
    }
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const ItemSelect = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 26px;
  > div {
    flex: 1;
    .ant-select-selector {
      display: flex;
      align-items: center;
      font-size: 14px;
      height: 40px !important;
      border-color: #e2e6ea !important;
      border-radius: 4px !important;
    }
  }
`;

const NameInput = styled(Input)`
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: #e2e6ea;
  border-radius: 4px;
  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;
