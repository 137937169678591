import gql from 'graphql-tag';
import {prospectTelStatusFragment} from '..';

export default gql`
  fragment miitelCallProspectActivityFragment on MiitelCallProspectActivity {
    id
    miitelSequenceId
    stage
    stageText
    recordedAt
    comment
    commentHtml
    clientProspectTelStatus {
      ...prospectTelStatusFragment
    }
  }

  ${prospectTelStatusFragment}
`;
