import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input, Select} from 'components/antd';
import {
  useUpdateProspectTelStatusMutation,
  ProspectTelStatus,
  useCheckCurrentClientProspectTelStatusDeletableQuery,
  useDestroyProspectTelStatusMutation,
  useProspectTelStatusCategoriesQuery,
} from 'api';

interface Props {
  prospectTelStatus: ProspectTelStatus;
  onClose: () => void;
}

const Update = ({prospectTelStatus, onClose}: Props) => {
  const [updateProspectTelStatus] = useUpdateProspectTelStatusMutation({
    onCompleted: () => onClose(),
    refetchQueries: ['currentClient'],
  });
  const [destroyProspectTelStatus] = useDestroyProspectTelStatusMutation({
    variables: {
      id: prospectTelStatus?.id,
    },
    onCompleted: () => onClose(),
    refetchQueries: ['currentClient'],
  });
  const {data: {checkCurrentClientProspectTelStatusDeletable = false} = {}} =
    useCheckCurrentClientProspectTelStatusDeletableQuery({
      variables: {
        id: prospectTelStatus?.id,
      },
      skip: !prospectTelStatus,
    });
  const {data: {prospectTelStatusCategories = []} = {}} =
    useProspectTelStatusCategoriesQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: prospectTelStatus?.name,
      description: prospectTelStatus?.description,
      clientProspectTelStatusCategoryId:
        prospectTelStatus?.prospectTelStatusCategory?.id || null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
    }),
    onSubmit: (values) =>
      updateProspectTelStatus({
        variables: {
          id: prospectTelStatus.id,
          attributes: {
            ...values,
          },
        },
      }),
  });

  return (
    <Modal
      destroyOnClose
      visible={Boolean(prospectTelStatus)}
      width={480}
      centered
      transitionName=""
      maskTransitionName=""
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2 className="pb-0">ステータスの編集・削除</h2>
        {!checkCurrentClientProspectTelStatusDeletable && (
          <p className="text-[#C90E19]">
            ※このステータスは使用されているため削除できません。
          </p>
        )}
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item label="種別">
            <Select
              value={formik.values.clientProspectTelStatusCategoryId}
              onChange={(e) =>
                formik.setFieldValue('clientProspectTelStatusCategoryId', e)
              }
              dropdownMatchSelectWidth={false}
              size="large">
              <Select.Option
                value={null}
                key="null"
                label="未選択"
                style={{height: '24px'}}>
                <div className="flex items-center text-[#bfbfbf]">
                  <span
                    className="w-[14px] h-[14px] rounded-full inline-block mr-2"
                    style={{
                      backgroundColor: '#bfbfbf',
                    }}
                  />
                  未選択
                </div>
              </Select.Option>
              {prospectTelStatusCategories.map((category) => (
                <Select.Option
                  value={category.id}
                  key={category.id}
                  label={category.name}
                  style={{height: '24px'}}>
                  <div className="flex items-center">
                    <span
                      className="w-[14px] h-[14px] rounded-full inline-block mr-2"
                      style={{
                        backgroundColor: category.color,
                      }}
                    />
                    {category.name}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="ステータス名" required>
            <Input
              size="large"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item label="ステータスの説明">
            <Input.TextArea
              name="description"
              autoSize={{minRows: 3}}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <div>
              <Button
                danger
                onClick={() => {
                  destroyProspectTelStatus();
                }}
                disabled={!checkCurrentClientProspectTelStatusDeletable}
                size="large">
                削除
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{marginLeft: '8px'}}
                disabled={!formik.isValid}>
                更新
              </Button>
            </div>
          </Actions>
        </Form>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Update;
