import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.3319 7.42804L14.5437 1.5H13.3087L8.78327 6.64724L5.16883 1.5H1L6.46574 9.28354L1 15.5H2.2351L7.01406 10.0643L10.8312 15.5H15L9.3319 7.42804ZM7.64026 9.35211L7.08647 8.57705L2.68013 2.40978H4.57718L8.13314 7.38696L8.68693 8.16202L13.3093 14.6316H11.4122L7.64026 9.35211Z"
      fill="black"
    />
  </svg>
);

export default Icon;
