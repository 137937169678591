import React from 'react';
import {
  useUpdateSalesforceLeadFieldValueMutation,
  SalesforceLeadField,
  SalesforceLead,
} from 'api';

interface Props {
  field: SalesforceLeadField;
  salesforceLead: SalesforceLead;
}

const Input = ({field, salesforceLead}: Props) => {
  const value = salesforceLead.addition[`${field.fieldName}`];
  const [erorrMessage, setErrorMessage] = React.useState('');
  const [updateFieldValue] = useUpdateSalesforceLeadFieldValueMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceLeadFieldValue.error);
    },
  });

  return (
    <>
      <input
        defaultValue={value}
        className="w-max"
        onBlur={(e) => {
          updateFieldValue({
            variables: {
              salesforceLeadId: salesforceLead.id,
              attributes: {fieldName: field.fieldName, value: e.target.value},
            },
          });
        }}
      />
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </>
  );
};

export default Input;
