import React from 'react';
import {Route, Switch, Redirect, useRouteMatch} from 'react-router-dom';
import Timeline from './Timeline';
import Workflow from './Workflow';
import Task from './Task';
import Tel from './Tel';
import Mail from './Mail';
import MailNew from './MailNew';
import Tracking from './Tracking';
import Approach from './Approach';
import {ProspectPool} from 'api';
import ContentTabs from './ContentTabs';

interface Props {
  prospectPool: ProspectPool;
  loading: boolean;
  refetch: () => void;
}

export default ({prospectPool, loading, refetch}: Props) => {
  const {path, url} = useRouteMatch();

  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    ref.current.scrollIntoView();
  }, [prospectPool?.prospectActivities?.length]);

  return (
    <div className="flex-1 flex flex-col h-0 ">
      <ContentTabs prospectPool={prospectPool} />
      <div ref={ref} className="flex-1 h-0 overflow-y-scroll py-4">
        <Switch>
          <Route path={`${path}/timeline`}>
            <Timeline
              prospectPool={prospectPool}
              loading={loading}
              refetch={refetch}
            />
          </Route>
          <Route path={`${path}/workflow`}>
            <Workflow prospectPool={prospectPool} />
          </Route>
          <Route path={`${path}/task`}>
            <Task prospectPool={prospectPool} />
          </Route>
          <Route path={`${path}/tel`}>
            <Tel
              prospectPool={prospectPool}
              loading={loading}
              refetch={refetch}
            />
          </Route>
          <Route path={`${path}/mail`}>
            <Mail prospectPool={prospectPool} />
          </Route>
          <Route path={`${path}/mail_new`}>
            <MailNew prospectPool={prospectPool} />
          </Route>
          <Route path={`${path}/tracking`}>
            <Tracking prospectPool={prospectPool} />
          </Route>
          <Route path={`${path}/approach`}>
            <Approach prospectPool={prospectPool} />
          </Route>
          <Redirect to={`${url}/timeline${location.search}`} />
        </Switch>
      </div>
    </div>
  );
};
