import React from 'react';
import {Tab} from '@headlessui/react';
import {MergeProspect} from 'api';
import IsTargetMergeProspectPool from './IsTargetMergeProspectPool';
import IsNotTargetMergeProspectPool from './IsNotTargetMergeProspectPool';

interface Props {
  mergeProspect: MergeProspect;
}

export default ({mergeProspect}: Props) => {
  const mergeProspectProspectPools =
    mergeProspect?.mergeProspectProspectPools || [];
  const isTargetMergeProspectPools = React.useMemo(() => {
    return mergeProspectProspectPools.filter(
      (item) => item.isTarget && item.merge === 'from',
    );
  }, [mergeProspectProspectPools]);

  const isNotTargetMergeProspectPools = React.useMemo(() => {
    return mergeProspectProspectPools.filter(
      (item) => !item.isTarget && item.merge === 'from',
    );
  }, [mergeProspectProspectPools]);

  return (
    <Tab.Group>
      <Tab.List className={'rounded-md border-[#E1E6EB] border w-[240px] flex'}>
        <Tab
          className={({selected}) =>
            `w-[120px] h-[30px] flex gap-[2px] items-center justify-center cursor-pointer ${
              selected ? 'bg-white font-bold' : 'bg-[#E1E6EB] text-[#BDC4CB]'
            }`
          }>
          <div className="mr-[1px]">対象</div>
          <div className="bg-[#EEF0F2] rounded-full w-4 h-4 leading-4">
            {isTargetMergeProspectPools.length}
          </div>
        </Tab>
        <Tab
          className={({selected}) =>
            `w-[120px] h-[30px] flex gap-[2px] items-center justify-center cursor-pointer ${
              selected ? 'bg-white font-bold' : 'bg-[#E1E6EB] text-[#BDC4CB]'
            }`
          }>
          <div className="mr-[1px]">対象外</div>
          <div className="bg-[#EEF0F2] rounded-full w-4 h-4 leading-4">
            {isNotTargetMergeProspectPools.length}
          </div>
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          {isTargetMergeProspectPools.map(
            (isTargetMergeProspectPool, index) => (
              <IsTargetMergeProspectPool
                mergeProspectProspectPool={isTargetMergeProspectPool}
                key={index}
              />
            ),
          )}
        </Tab.Panel>
        <Tab.Panel>
          {isNotTargetMergeProspectPools.map(
            (isNotTargetMergeProspectPool, index) => (
              <IsNotTargetMergeProspectPool
                mergeProspectProspectPool={isNotTargetMergeProspectPool}
                key={index}
              />
            ),
          )}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
