import React from "react";
import {Route, Switch} from 'react-router';
import Plan from './Plan'
import PlanList from './PlanList'

export default () => {
  return (
    <Switch>
      <Route exact path="/settings/plan" component={Plan} />
      <Route exact path="/settings/plan/change" component={PlanList} />
    </Switch>
  )
}