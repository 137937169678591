import React from 'react';

const Icon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
    <path d="M22.2005 10.8H7.86719V12.8H22.2005V10.8Z" fill="#899098" />
    <path
      d="M7.86719 16.8001H14.0672V21.7334H16.0672V16.8001H22.2005V14.7334H7.86719V16.8001Z"
      fill="#899098"
    />
  </svg>
);

export default Icon;
