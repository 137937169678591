import React from 'react';
import {useUsersByClientAllQuery} from 'api';
import useCondition from '../../useCondition';

export default () => {
  const {condition} = useCondition();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  if (!condition?.users || !condition.users.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">担当者</h4>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {users
          .filter((user) => condition.users.includes(user.id))
          .map((user) => (
            <span
              key={user.id}
              className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {[user.lastName, user.firstName].filter(Boolean).join(' ')}
            </span>
          ))}
      </div>
    </div>
  );
};
