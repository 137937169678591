import React from 'react';

export default () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.20001 12.6493L10.0585 9.79083L12.9169 12.6493"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0583 18.2024L10.0583 10.3621"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5663 15.4804L17.7079 18.3389L14.8494 15.4804"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6807 17.4401L17.6807 9.59973"
      stroke="#BDC4CB"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.40002"
      y="2.39999"
      width="23.2"
      height="23.2"
      rx="3.2"
      stroke="#BDC4CB"
      strokeWidth="1.5"
    />
  </svg>
);
