import React from 'react';
import {AutomaticMailSend} from 'api';
import {Row} from 'react-table';
import moment from 'moment';

interface Props {
  row: Row<AutomaticMailSend>;
}

export default ({row}: Props) => {
  const sendAt = row.original.sendAt;

  return <span>{moment(sendAt).format('YYYY/MM/DD HH:mm')}</span>;
};
