import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  ProspectActivity,
  CollectedFormProspectActivity,
  useCollectedFormUrlFieldsQuery,
} from 'api';
import {CollectedForm} from 'components/Ui/Icon';
import Field from './Field';

interface CollectedFormActivity extends ProspectActivity {
  resource: CollectedFormProspectActivity;
}

interface Props {
  prospectActivity: CollectedFormActivity;
}

export default ({prospectActivity}: Props) => {
  const {resource: {collectedForm = {}} = {}, createdAt} = prospectActivity;

  const {data: {collectedFormUrlFields = []} = {}} =
    useCollectedFormUrlFieldsQuery({
      variables: {uuid: collectedForm.collectedFormUrl?.uuid},
      skip: !collectedForm.collectedFormUrl?.uuid,
    });

  return (
    <>
      <CollectedForm />
      <Content>
        <ContentHeader>
          <h3>
            <a href={collectedForm.url} target="_blank">
              {collectedForm.collectedFormUrlId
                ? collectedForm.collectedFormUrl?.title
                : collectedForm.url}
            </a>
          </h3>
          <time>{moment(createdAt).format('YYYY/MM/DD HH:mm')}</time>
        </ContentHeader>
        <ContentBox>
          {collectedFormUrlFields.length === 0
            ? Object.keys(collectedForm.payload).map((key) => (
                <Field
                  key={key}
                  label={key}
                  value={collectedForm.payload[key]}
                />
              ))
            : collectedFormUrlFields.map((collectedFormUrlField) => (
                <Field
                  key={collectedFormUrlField.id}
                  label={
                    collectedFormUrlField.label ||
                    collectedFormUrlField.fieldTypeText ||
                    collectedFormUrlField.name
                  }
                  value={collectedForm.payload[collectedFormUrlField.name]}
                />
              ))}
        </ContentBox>
      </Content>
    </>
  );
};

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    font-weight: bold;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const ContentBox = styled.div`
  border: solid 1px #e2e6ea;
  border-radius: 4px;
  padding: 15px;
`;
