import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, TaskCommentProspectActivity} from 'api';
import {StepTask, Calendar, Edit, DotMenuSide} from 'components/Ui/Icon';
import {Menu, Dropdown} from 'components/antd';
import linkifyHtml from 'linkify-html';

interface TaskCommentActivity extends ProspectActivity {
  resource: TaskCommentProspectActivity;
}

interface Props {
  prospectActivity: TaskCommentActivity;
  setIsTaskCommentEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setTaskComment: React.Dispatch<
    React.SetStateAction<TaskCommentProspectActivity>
  >;
}

export default ({
  prospectActivity,
  setTaskComment,
  setIsTaskCommentEdit,
}: Props) => {
  const {
    resource: {commentHtml, createdAt, recordedAt, stageText},
    user,
  } = prospectActivity;

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <StepTask active={true} width="15" height="15" />
      <Content>
        <ContentHeader>
          <h3>{prospectActivity?.action?.step?.name || 'コメント'}</h3>
          <span>{user && [user.lastName, user.firstName].join(' ')}</span>
          <time>{moment(createdAt).format('YYYY/MM/DD HH:mm')}</time>
          <MenuContainer>
            <Dropdown
              overlay={
                <Menu>
                  <MenuItem
                    key="0"
                    onClick={() => {
                      setTaskComment(prospectActivity.resource);
                      setIsTaskCommentEdit(true);
                    }}>
                    <Edit />
                    編集
                  </MenuItem>
                </Menu>
              }
              trigger={['click']}>
              <span>
                <DotMenuSide />
              </span>
            </Dropdown>
          </MenuContainer>
        </ContentHeader>
        <ContentBox>
          {commentHtml && (
            <div
              dangerouslySetInnerHTML={{__html: convertUrlToLink(commentHtml)}}
            />
          )}
          <ContentMeta>
            {stageText && <Stage>{stageText}</Stage>}
            {recordedAt && (
              <RecordedAt>
                <Calendar />
                <span>
                  {recordedAt && moment(recordedAt).format('YYYY/MM/DD HH:mm')}
                </span>
              </RecordedAt>
            )}
          </ContentMeta>
        </ContentBox>
      </Content>
    </>
  );
};

const ContentMeta = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 15px;
    display: flex;
    align-items: center;
    position: relative;
    svg {
      height: 18px;
      width: auto;
      margin-right: 4px;
    }
  }
`;

const Stage = styled.span`
  background: #dceeff;
  font-size: 10px;
  color: #2d9cdb;
  height: 20px;
  padding: 0 18px;
  border-radius: 2px;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;
  h3 {
    flex: 1;
    margin: 0;
    font-weight: bold;
  }
  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const ContentBox = styled.div`
  border: solid 1px #e2e6ea;
  border-radius: 4px;
  padding: 15px;
`;

const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
    svg {
      width: 15px;
      height: auto;
      margin-right: 0.5rem;
    }
    &:hover {
      color: #68b5fb;
      svg {
        path {
          stroke: #68b5fb;
        }
      }
    }
  }
`;

const RecordedAt = styled.span`
  svg {
    position: relative;
    top: -1px;
  }
`;
