import React from 'react';
import styled from 'styled-components';
import responseColor from 'helpers/responseColor';
import {StatusEclipse} from 'components/Ui';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const ResponseCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const color = responseColor(preleadProject.responseStatus);
  const text = preleadProject.responseStatusText;

  return (
    <Cell>
      <StatusEclipse color={color} />
      {text}
    </Cell>
  );
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

export default ResponseCell;
