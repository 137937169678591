import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_55455_2466)">
      <path
        opacity="0.99"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01473 5.50884C5.70588 5.413 6.91642 6.12441 7.64636 7.64272C8.21586 9.39323 7.76416 10.8095 6.29128 11.8924C4.96004 12.6302 3.65916 12.5817 2.38863 11.7477C1.01657 10.5763 0.661354 9.14765 1.32264 7.46188C1.89249 6.35444 2.78974 5.70342 4.01473 5.50884ZM4.26768 7.24488C5.26503 7.24922 5.88547 7.73169 6.12866 8.69158C6.18901 9.69848 5.73732 10.3433 4.77358 10.6265C3.7083 10.73 3.04016 10.2656 2.76806 9.23409C2.7153 8.14834 3.21542 7.48503 4.26768 7.24488Z"
        fill="#0053FF"
      />
      <path
        opacity="0.98"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79603 5.50867H13.265V7.20854H14.9995V10.6806H13.265V12.4167H9.79603V10.6806H8.09766V7.20854H9.79603V5.50867ZM9.83216 7.24471H13.2289V10.6444H9.83216V7.24471Z"
        fill="#0053FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_55455_2466">
        <rect
          width="14"
          height="6.91667"
          fill="white"
          transform="translate(1 5.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
