import React from 'react';
import {Approach, ApproachPrelead, ApproachPreleadProject} from 'api';

interface Props {
  approach: Approach;
}

const Name = ({approach}: Props) => {
  const approachable = approach.approachable;

  if (approach.approachableType === 'Approach::Prelead') {
    return (
      <h4 className="text-base font-medium leading-none">
        {(approachable as ApproachPrelead).approachGroupPrelead.title}
      </h4>
    );
  }

  if (approach.approachableType === 'Approach::PreleadProject') {
    return (
      <h4 className="text-base font-medium leading-none">
        {
          (approachable as ApproachPreleadProject).approachGroupPreleadProject
            .title
        }
      </h4>
    );
  }

  return null;
};

export default Name;
