import React from 'react';
import {Form, Select} from 'components/antd';
import {SalesforceDealField} from 'api';

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
  field: SalesforceDealField;
}

export const SelectItem = ({addition, setAddition, field}: Props) => {
  return (
    <Form.Item label={field.label} required={field.isRequired}>
      <Select
        value={addition[`${field.fieldName}`]}
        onChange={(value) => {
          let newAddition: {[key: string]: any} = Object.assign({}, addition);
          newAddition[`${field.fieldName}`] = value;
          setAddition(newAddition);
        }}
        showSearch
        optionFilterProp="children">
        {field.salesforceDealFieldSelectOptions.map(
          (option) =>
            option?.isActive && (
              <Select.Option key={option.id} value={option.value}>
                {option.label}
              </Select.Option>
            ),
        )}
      </Select>
    </Form.Item>
  );
};
