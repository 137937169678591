import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {useAllFormsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {allForms = []} = {}} = useAllFormsQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (!query?.formIds || !query.formIds.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">フォーム</h4>
        <button
          onClick={() => setFilterType('form')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setQuery({...query, formIds: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap items-center">
          {allForms
            .filter((form) => query.formIds.includes(form.id))
            .map((form) => (
              <span
                key={form.id}
                className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {form.title}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
