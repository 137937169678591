import React from 'react';

export default () => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_39206_189745)">
        <path
          d="M10.5128 4.7392V3.09506C10.7301 2.99352 10.914 2.83235 11.0433 2.63032C11.1725 2.42829 11.2417 2.1937 11.2428 1.95388V1.91615C11.2428 1.2169 10.6759 0.650036 9.97674 0.650036H9.93896C9.23971 0.650036 8.67285 1.2169 8.67285 1.91615V1.95388C8.67395 2.1937 8.74315 2.42829 8.87239 2.63032C9.00163 2.83235 9.18559 2.99352 9.40286 3.09506V4.7392C8.77841 4.83483 8.1903 5.09376 7.6981 5.48978L3.18896 1.97761C3.22111 1.86154 3.23788 1.74219 3.23978 1.62239C3.24033 1.34021 3.15718 1.06422 3.00085 0.829307C2.84453 0.594397 2.62204 0.411126 2.36154 0.302678C2.10104 0.19423 1.81423 0.165476 1.53739 0.220053C1.26054 0.27463 1.0061 0.410087 0.806256 0.609289C0.606406 0.808491 0.470125 1.06249 0.41465 1.33915C0.359175 1.61582 0.386999 1.90272 0.494602 2.16357C0.602205 2.42442 0.784753 2.6475 1.01915 2.80459C1.25356 2.96168 1.52928 3.04572 1.81145 3.04608C2.0583 3.04492 2.30053 2.97902 2.51394 2.85496L6.95415 6.31012C6.13783 7.5433 6.15967 9.15049 7.00915 10.3611L5.65867 11.712C5.54946 11.6771 5.43578 11.6585 5.32116 11.657C4.6744 11.6575 4.15044 12.182 4.15061 12.8288C4.15078 13.4755 4.67507 13.9998 5.32178 13.9999C5.96854 14.0001 6.49305 13.4761 6.49361 12.8294C6.49209 12.7148 6.47353 12.6011 6.43856 12.4919L7.77455 11.1554C8.96829 12.0744 10.6078 12.1536 11.8846 11.3539C13.1613 10.5542 13.8056 9.04445 13.4997 7.56938C13.1937 6.09425 12.0022 4.96526 10.5128 4.73914V4.7392ZM9.95902 10.1466C9.71221 10.1533 9.46656 10.1104 9.23659 10.0205C9.00662 9.93067 8.79698 9.79563 8.62006 9.62341C8.44314 9.45118 8.30252 9.24526 8.2065 9.01778C8.11048 8.79031 8.06101 8.54591 8.06101 8.299C8.06101 8.0521 8.11048 7.80769 8.2065 7.58022C8.30252 7.35275 8.44314 7.14682 8.62006 6.9746C8.79698 6.80237 9.00662 6.66734 9.23659 6.57747C9.46656 6.48761 9.71221 6.44473 9.95902 6.45137C10.9537 6.4862 11.7421 7.30236 11.7427 8.29766C11.7431 9.29286 10.9555 10.1099 9.96086 10.1457"
          fill="#FF7A59"
        />
      </g>
      <defs>
        <clipPath id="clip0_39206_189745">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
