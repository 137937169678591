import React from 'react';

function Icon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F3F5F7" />
      <path
        d="M13.1919 16.8083L16.8669 13.1333"
        stroke="#BDBDBD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0917 15.8751L19.2 14.7667C19.725 14.2417 20.0167 13.5417 20.0167 12.7834C20.0167 12.0251 19.725 11.3251 19.2 10.8001C18.675 10.2751 17.975 9.9834 17.2167 9.9834C16.4583 9.9834 15.7583 10.2751 15.2333 10.8001L14.125 11.9084"
        stroke="#BDBDBD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1671 17.8586L14.8254 19.2002C14.3004 19.7252 13.6004 20.0169 12.8421 20.0169C12.0837 20.0169 11.3837 19.7252 10.8587 19.2002C9.75038 18.0919 9.75038 16.2836 10.8587 15.1752L11.967 14.0669"
        stroke="#BDBDBD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
