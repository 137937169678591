import React, {useMemo} from 'react';
import {useProjectTelReportsByEmployeeRangeQuery} from 'api';
import {useParams} from 'react-router-dom';
import Table from '../Table';
import employeeRangeOptions from 'helpers/employeeRangeOptions';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {projectTelReportsByEmployeeRange = []} = {}} =
    useProjectTelReportsByEmployeeRangeQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
    });

  const data: any = useMemo(
    () =>
      projectTelReportsByEmployeeRange.map((report) => {
        return {
          name: employeeRangeOptions.find(
            (employeeRangeOption) =>
              employeeRangeOption.value === report.employeeRange,
          ).label,
          callCount: report.callCount,
          contactCount: report.contactCount,
          contactCallRate: report.contactCallRate,
          documentCount: report.documentCount,
          documentCallRate: report.documentCallRate,
          appointCount: report.appointCount,
          appointCallRate: report.appointCallRate,
        };
      }),
    [projectTelReportsByEmployeeRange],
  );

  return <Table data={data} />;
};
