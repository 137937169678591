import React from 'react';
import {useParams} from 'react-router-dom';
import useCondition from '../../../useCondition';
import Header from '../../Header';
import Footer from '../Footer';
import {useSalesforceDealRecordTypesQuery} from 'api';
import ConditionField from './ConditionField';

const SalesforceDealFilter = () => {
  const {condition, setCondition} = useCondition();

  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const updateCondition = (params: Parameters<typeof setCondition>[0]) => {
    const searchCondition = {...condition, ...params};
    const hasSalesforceDeal = Boolean(
      searchCondition.salesforceDealRecordTypeId ||
        searchCondition.salesforceDealSearchConditions?.length > 0,
    );
    setCondition({
      ...params,
      hasSalesforceDeal,
    });
  };

  return (
    <>
      <Header title="Salesforce商談" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">レコードタイプ</div>
            <select
              className="form-select border-c-border rounded text-base w-full"
              onChange={(e) =>
                updateCondition({
                  salesforceDealRecordTypeId: e.target.value,
                  salesforceDealSearchConditions: [],
                })
              }
              value={condition?.salesforceDealRecordTypeId}>
              <option value="" />
              {salesforceDealRecordTypes.map((recordType) => (
                <option value={recordType.id} key={recordType.id}>
                  {recordType.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">条件項目</div>
            {condition.salesforceDealSearchConditions?.map(
              (searchCondition, index) => (
                <ConditionField
                  key={searchCondition.id}
                  searchCondition={searchCondition}
                  setSearchCondition={(searchCondition) => {
                    const searchConditions = [
                      ...condition.salesforceDealSearchConditions,
                    ];
                    searchConditions[index] = searchCondition;
                    updateCondition({
                      salesforceDealSearchConditions: searchConditions,
                    });
                  }}
                  onDelete={() => {
                    const searchConditions = [
                      ...condition.salesforceDealSearchConditions,
                    ];
                    searchConditions.splice(index, 1);
                    updateCondition({
                      salesforceDealSearchConditions: searchConditions,
                    });
                  }}
                />
              ),
            )}
            <button
              className="cursor-pointer w-32 h-8 flex items-center justify-center rounded bg-white border border-c-border text-light disabled:bg-c-bg disabled:text-c-light disabled:cursor-not-allowed"
              disabled={!condition.salesforceDealRecordTypeId}
              onClick={() =>
                updateCondition({
                  salesforceDealSearchConditions: [
                    ...(condition.salesforceDealSearchConditions || []),
                    {},
                  ],
                })
              }>
              + 条件を追加
            </button>
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setCondition({
            hasSalesforceDeal: false,
            salesforceDealRecordTypeId: '',
            salesforceDealSearchConditions: [],
          })
        }
      />
    </>
  );
};

export default SalesforceDealFilter;
