import React from 'react';
import styled from 'styled-components';
import telCategoryColor from 'helpers/telCategoryColor';
import telColor from 'helpers/telColor';
import {StatusEclipse} from 'components/Ui';
import {PreleadProject, useCurrentClientQuery} from 'api';

interface Props {
  row: any;
}

const TelCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const {data: {currentClient: {telStatuses = []} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-only',
    });

  const preleadProjectTelStatus = telStatuses.find(
    (telStatus) => telStatus.id === preleadProject.clientTelStatusId,
  );

  const color = preleadProjectTelStatus
    ? telCategoryColor(preleadProjectTelStatus.category)
    : telColor(preleadProject.telStatus);
  const text = preleadProjectTelStatus?.name || '未';

  return (
    <Cell>
      <StatusEclipse color={color} />
      {text}
    </Cell>
  );
};

const Cell = styled.div`
  svg {
    margin-right: 5px;
  }
`;

export default TelCell;
