import React from 'react';
import styled from 'styled-components';
import {Select} from 'components/antd';
import StatusesFilter from './Statuses';
import MailStatusesFilter from './MailStatuses';
import MailCountsFilter from './MailCounts';
import MailTermFilter from './MailTerm';
import TelStatusesFilter from './TelStatuses';
import LatestTelStatusesFilter from './LatestTelStatuses';
import MailCampaignFilter from './MailCampaign';
import TelCountsFilter from './TelCounts';
import TelTermFilter from './TelTerm';
import MailCampaignOpenCountFilter from './MailCampaignOpenCount';
import MailCampaignClickCountFilter from './MailCampaignClickCount';
import MailCampaignSendsCountFilter from './MailCampaignCount';
import ClickCountsFilter from './ClickCounts';
import ClickTerm from './ClickTerm';
import ClickedCampaignUrlIdsFilter from './ClickedCampaignUrlIds';
import ClickedCampaignIdsFilter from './ClickedCampaignIds';
import ResponseStatusesFilter from './ResponseStatuses';
import ClickedMailFormVersionUrllIdsFilter from './ClickedMailFormVertionUrlIds';
import PressReleaseSinceFilter from './PressReleaseSince';
import TagIdsFilter from './TagIds';
import CapitalFundFilter from './CapitalFund';
import OfficesNumberFilter from './OfficesNumber';
import ListingMarketsFilter from './ListingMarkets';
import EmployeeNumberFilter from './EmployeeNumber';
import AccountClosingMonthsFilter from './AccountClosingMonths';
import CategoryFilter from './Category';
import EstablishedDateFilter from './EstablishedDate';
import CommentCountsFilter from './CommentCounts';
import IsDuplicateFilter from './IsDuplicate';
import Remind from './Remind';
import AssigneesFilter from './Assignees';
import SerialNumberFilter from './SerialNumber';
import CustomizeItemFilter from './CustomizeItem';
import useSearchParams from '../../useSearchParams';
import PrefecturesFilter from './Prefectures';
import LatestTelUserFilter from './LatestTelUser';
import IsUnsubscribedFilter from './IsUnsubscribed';
import IsMultipleFilter from './IsMultiple';
import JobListingsSinceFilter from './JobListingsSince';
import LatestTelTermFilter from './LatestTelTerm';

const filterComponents: {[key: string]: any} = {
  preleadStatusIds: StatusesFilter,
  mailStatuses: MailStatusesFilter,
  mailCounts: MailCountsFilter,
  mailTerm: MailTermFilter,
  telCounts: TelCountsFilter,
  telStatusIds: TelStatusesFilter,
  latestTelStatusIds: LatestTelStatusesFilter,
  mailCampaignIds: MailCampaignFilter,
  telTerm: TelTermFilter,
  clickCounts: ClickCountsFilter,
  clickTerm: ClickTerm,
  clickedCampaignUrlIds: ClickedCampaignUrlIdsFilter,
  clickedCampaignIds: ClickedCampaignIdsFilter,
  responseStatuses: ResponseStatusesFilter,
  clickedMailFormVersionUrlIds: ClickedMailFormVersionUrllIdsFilter,
  pressReleaseSince: PressReleaseSinceFilter,
  tagIds: TagIdsFilter,
  capitalFund: CapitalFundFilter,
  officesNumber: OfficesNumberFilter,
  listingMarkets: ListingMarketsFilter,
  employeeNumber: EmployeeNumberFilter,
  accountClosingMonths: AccountClosingMonthsFilter,
  categoryIds: CategoryFilter,
  establishedDate: EstablishedDateFilter,
  commentCounts: CommentCountsFilter,
  isDuplicate: IsDuplicateFilter,
  remind: Remind,
  assigneeIds: AssigneesFilter,
  serialNumber: SerialNumberFilter,
  prefectureIds: PrefecturesFilter,
  mailCampaignOpenCounts: MailCampaignOpenCountFilter,
  mailCampaignClickCounts: MailCampaignClickCountFilter,
  mailCampaignSendsCounts: MailCampaignSendsCountFilter,
  latestTelUserIds: LatestTelUserFilter,
  latestTelTerm: LatestTelTermFilter,
  isUnsubscribed: IsUnsubscribedFilter,
  isMultiple: IsMultipleFilter,
  jobListingsSince: JobListingsSinceFilter,
};

const Filter = ({filter}: {filter: string}) => {
  if (!filter) {
    return <BlankFilter />;
  }

  if (filter.startsWith('c.')) {
    return <CustomizeItemFilter filter={filter} />;
  }

  const FilterComponent = filterComponents[filter];
  return <FilterComponent />;
};

export default ({
  filter,
  onChange,
  availableOptions,
}: {
  filter: string;
  onChange: (filter: string) => void;
  availableOptions: {text: string; value: string}[];
}) => {
  const {query, setQuery} = useSearchParams();

  return (
    <Container>
      <Select
        value={filter}
        onChange={onChange}
        placeholder="選択してください"
        dropdownMatchSelectWidth>
        {availableOptions.map((option) => (
          <Select.Option
            value={option.value}
            key={option.value}
            label={option.text}>
            {option.text}
          </Select.Option>
        ))}
      </Select>
      <Filter filter={filter} />
      <svg
        onClick={() => onChange(null)}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.916016 9.08366L9.08268 0.916992M0.916016 0.916992L9.08268 9.08366L0.916016 0.916992Z"
          stroke="#BDC4CB"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  font-size: 12px;
  align-items: flex-start;
  margin-bottom: 12px;

  > *:first-child {
    width: 180px;
    margin-right: 1rem;
  }

  > *:nth-child(2) {
    flex: 1;
  }

  > svg:last-child {
    height: 32px;
    margin: 0 0.5rem;
    cursor: pointer;
  }
`;

const BlankFilter = styled.div`
  height: 32px;
  background: #f5f5f5;
  border-radius: 2px;
`;
