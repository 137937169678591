import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import SubCategory from './SubCategory';
import EmployeeNumber from './EmployeeNumber';
import ListingMarkets from './ListingMarkets';
import CapitalFund from './CapitalFund';
import EstablishedDate from './EstablishedDate';
import City from './City';
import HasCompany from './HasCompany';

type Option = {
  label: string;
  value: string;
  isUnselected: boolean;
};

const ActionFilters = () => {
  const {setFilterType} = useFilterModalContext();
  const {query} = useSearchParams();

  const options: Option[] = [
    {
      label: 'エリア',
      value: 'city',
      isUnselected: !query?.cityIds || !query.cityIds.length,
    },
    {
      label: '業種',
      value: 'subCategory',
      isUnselected: !query?.subCategories || !query.subCategories.length,
    },
    {
      label: '上場区分',
      value: 'listingMarkets',
      isUnselected: !query?.listingMarkets || !query.listingMarkets.length,
    },
    {
      label: '従業員数',
      value: 'employeeNumber',
      isUnselected: !query?.employeeNumber?.from && !query?.employeeNumber?.to,
    },
    {
      label: '資本金',
      value: 'capitalFund',
      isUnselected: !query?.capitalFund?.from && !query?.capitalFund?.to,
    },
    {
      label: '設立年月',
      value: 'establishedDate',
      isUnselected:
        !query?.establishedDate?.from && !query?.establishedDate?.to,
    },
    {
      label: '企業名寄せ',
      value: 'hasCompany',
      isUnselected: query?.hasCompany === null,
    },
  ];

  const unselectedOptions = options.filter((option) => option.isUnselected);

  return (
    <div className="p-6 bg-white rounded flex items-start gap-4">
      <h3 className="m-0 font-bold text-base w-20">企業情報</h3>
      <div className="flex-1 flex flex-col gap-4">
        <SubCategory />
        <ListingMarkets />
        <EmployeeNumber />
        <CapitalFund />
        <City />
        <EstablishedDate />
        <HasCompany />
        <Menu as="div" className="relative text-left inline-block">
          <Menu.Button className="cursor-pointer bg-white border border-c-border rounded text-sm flex items-center justify-center h-7 px-4">
            + 検索条件を追加
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute left-0 border border-c-border w-[10rem] rounded bg-white shadow-lg focus:outline-none z-10">
              <div className="px-1 py-1 ">
                {unselectedOptions.map((option, index) => (
                  <Menu.Item key={index}>
                    <button
                      onClick={() => setFilterType(option.value)}
                      className="cursor-pointer bg-white text-left p-2 w-full hover:bg-[#F1F8FF] hover:text-c-primary">
                      {option.label}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default ActionFilters;
