import React from 'react';
import useSearchParams from '../../../../useSearchParams';
import {ArrowRightIcon, XMarkIcon} from '@heroicons/react/20/solid';
import useFilterModalContext from '../../useFilterModalContext';
import {useCurrentUserQuery} from 'api';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();
  const options = [
    {label: '有効', value: 'active'},
    {label: 'アーカイブ', value: 'archived'},
    {label: 'クローズ', value: 'closed'},
  ];

  if (!query?.statuses || !query.statuses.length) {
    return null;
  }

  return (
    <>
      {currentUser.role === 'admin' && (
        <div className="pb-4 border-b border-c-border flex flex-col gap-2">
          <div className="flex items-center gap-1">
            <h4 className="m-0 font-bold">ステータス</h4>
            <button
              onClick={() => setFilterType('status')}
              className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
              <ArrowRightIcon className="w-3 h-3 text-c-light" />
            </button>
            <button
              onClick={() => setQuery({...query, statuses: ['active']})}
              className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
              <XMarkIcon className="w-3 h-3 text-c-light" />
            </button>
          </div>
          <div className="flex gap-1">
            {options.map(
              (option) =>
                query.statuses.includes(option.value) && (
                  <span
                    key={option.value}
                    className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                    {option.label}
                  </span>
                ),
            )}
          </div>
        </div>
      )}
    </>
  );
};
