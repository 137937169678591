import gql from 'graphql-tag';

export default gql`
  fragment clientPreleadCustomizeItemFragment on ClientPreleadCustomizeItem {
    id
    name
    dataType
    dataTypeText
    position
    isDisplay
    isDeleted
    selectOptions {
      id
      label
    }
  }
`;
