import React from 'react';

const Icon = ({color = '#BDC4CB', className = ''}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2H5C3.9 2 3 2.9 3 4V8"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.69997 11.2L5.59997 11.3C5.99997 11.3 6.29997 11.6 6.49997 11.9L6.99997 13.2C7.09997 13.5 7.09997 13.9 6.89997 14.2L6.19997 15.3C6.59997 15.9 7.69997 17.3 8.89997 18.1L9.79997 17.5C9.99997 17.4 10.3 17.3 10.6 17.4L12.4 17.9C12.9 18 13.2 18.5 13.2 19L13 20.5C13 21 12.5 21.5 12 21.4C5.09997 20.6 1.09997 11.2 3.69997 11.2Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 8H16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 12H16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
