import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();

  return {
    axis: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    data: [
      {
        label: '製造・機械',
        color: getColor(0),
        groupId: 1,
        values: [100, 80, 90, 70, 60, 50, 40, 30, 20, 10, 5, 0],
      },
      {
        label: '金融・不動産',
        color: getColor(1),
        groupId: 1,
        values: [20, 30, 40, 50, 60, 70, 80, 90, 80, 70, 60, 50],
      },
      {
        label: '建設・工事',
        color: getColor(2),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '運輸・物流',
        color: getColor(3),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '電気製品',
        color: getColor(4),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '自動車・乗り物',
        color: getColor(5),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '食品',
        color: getColor(6),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '商社',
        color: getColor(7),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
      {
        label: '小売・アパレル・生活用品',
        color: getColor(8),
        groupId: 1,
        values: [40, 30, 20, 50, 60, 80, 45, 100, 90, 10, 20, 5],
      },
    ],
  };
};

export default useChartData;
