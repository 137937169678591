import React, {useState} from 'react';
import {Input, Form, Switch} from 'components/antd';
import Modal from 'react-modal';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {Button} from 'components/Ui';
import {Button as AntdButton, Dropdown, Menu} from 'components/antd';
import {useCreateProjectGroupMutation, User} from 'api';
import AddMemberModal from './AddMemberModal';
import {Trash, UserMaru} from 'components/Ui/Icon';
import {useUsersByClientAllQuery} from 'api';

const {TextArea} = Input;

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '630px',
    minHeight: '700px',
    padding: '0',
  },
};

export default () => {
  const history = useHistory();
  const isOpen = location.pathname.endsWith('/new');
  const closeModal = () => history.push(`/list/groups${location.search}`);

  const [createProjectGroup, {loading}] = useCreateProjectGroupMutation({
    refetchQueries: ['projectGroups'],
    onCompleted: closeModal,
  });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const [name, setName] = useState('新規グループ名');
  const [memo, setMemo] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [isPrivate, setPrivate] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [assignedUsers, setAssignedUsers] = React.useState<User[]>([]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal">
        <CustomForm>
          <Header>新規グループの作成</Header>
          <div className="flex border-b border-[#dadce0] mb-5">
            <TabItem
              active={tabIndex === 0}
              onClick={() => {
                setTabIndex(0);
              }}
              className="cursor-pointer">
              <div>
                <span>グループ</span>
              </div>
            </TabItem>
            <TabItem
              active={tabIndex === 1}
              onClick={() => {
                setTabIndex(1);
              }}
              className="cursor-pointer">
              <div>
                <span>メンバー</span>
                <Count>{assignedUsers.length}</Count>
              </div>
            </TabItem>
          </div>
          {tabIndex === 0 && (
            <>
              <div className="h-[500px]">
                <Anotation>
                  グループは、営業リストと参加メンバーをまとめる場所です。特定のトピックやプレリード情報に基づいてグループを作ると良いでしょう。
                </Anotation>

                <Item>
                  <div>グループ名</div>
                  <NameInput
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Item>
                <Item>
                  <div>説明（任意）</div>
                  <MemoInput
                    name="memo"
                    autoSize={{minRows: 1, maxRows: 6}}
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                  />
                </Item>

                <Item>
                  <div>プライベート設定</div>
                  <PrivateSetting>
                    <span>
                      プライベートに設定すると、招待されたメンバーのみ営業リストに参加できるようになります。
                    </span>
                    <Switch
                      size="small"
                      checked={isPrivate}
                      onChange={setPrivate}
                    />
                  </PrivateSetting>
                </Item>

                {assignedUsers.length === 0 && (
                  <div className="border border-[#EB5757] text-[#EB5757] py-3 px-5 mt-4">
                    「メンバー」タブから、追加したいメンバーを登録してください。
                    <br />
                    メンバーを登録すると、グループを作成することができます。
                  </div>
                )}
              </div>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <div className="h-[500px]">
                <Dropdown
                  placement="bottomRight"
                  trigger={['click']}
                  className="mb-4"
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          setAssignedUsers(users);
                        }}>
                        <div className="text-base text-medium leading-none py-4">
                          メンバー全員を追加
                          <span className="text-[12px] text-[#899098] block pt-2">
                            メンバー全員を一括で登録できます
                          </span>
                        </div>
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setIsAddMemberModalOpen(true);
                        }}>
                        <div className="text-base text-medium leading-none py-4">
                          個別で追加
                          <span className="text-[12px] text-[#899098] block pt-2">
                            メンバーを個別に登録できます
                          </span>
                        </div>
                      </Menu.Item>
                    </Menu>
                  }>
                  <AntdButton
                    type="ghost"
                    className="flex items-center h-[40px] text-[#68B5FB]">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex">
                      <path
                        d="M26.8333 16.6666H25.1666V19.1666H22.6666V20.8333H25.1666V23.3333H26.8333V20.8333H29.3333V19.1666H26.8333V16.6666ZM14.3333 16.6666C14.3333 18.5666 15.7666 19.9999 17.6666 19.9999C19.5666 19.9999 21 18.5666 21 16.6666C21 14.7666 19.5666 13.3333 17.6666 13.3333C15.7666 13.3333 14.3333 14.7666 14.3333 16.6666ZM19.3333 16.6666C19.3333 17.6483 18.6483 18.3333 17.6666 18.3333C16.685 18.3333 16 17.6483 16 16.6666C16 15.6849 16.685 14.9999 17.6666 14.9999C18.6483 14.9999 19.3333 15.6849 19.3333 16.6666ZM14.3333 24.9999C14.3333 23.6216 15.455 22.4999 16.8333 22.4999H18.5C19.8783 22.4999 21 23.6216 21 24.9999V25.8333H22.6666V24.9999C22.6666 22.7024 20.7975 20.8333 18.5 20.8333H16.8333C14.5358 20.8333 12.6666 22.7024 12.6666 24.9999V25.8333H14.3333V24.9999Z"
                        fill="#68B5FB"
                      />
                    </svg>
                    メンバーを追加
                  </AntdButton>
                </Dropdown>

                <List>
                  {assignedUsers?.map((user) => (
                    <UserItem key={user.id}>
                      <UserMaru />
                      <span>
                        {user.lastName} {user.firstName}
                      </span>
                      <AntdButton
                        type="text"
                        onClick={() => {
                          setAssignedUsers(
                            assignedUsers.filter((u) => u.id !== user.id),
                          );
                        }}>
                        <Trash />
                      </AntdButton>
                    </UserItem>
                  ))}
                </List>
              </div>
            </>
          )}

          <div className="mt-auto">
            <ButtonWrapper>
              <Button
                htmlType="submit"
                loading={loading}
                disabled={!name || assignedUsers.length === 0 || loading}
                onClick={() =>
                  createProjectGroup({
                    variables: {
                      attributes: {
                        name: name,
                        memo: memo,
                        isPrivate: isPrivate,
                        userIds: assignedUsers.map((user) => user.id),
                      },
                    },
                  })
                }>
                保存
              </Button>
            </ButtonWrapper>
          </div>
        </CustomForm>
      </Modal>
      <AddMemberModal
        isOpen={isAddMemberModalOpen}
        setModal={setIsAddMemberModalOpen}
        users={users}
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
      />
    </>
  );
};

const CustomForm = styled(Form)`
  padding: 35px 50px;
`;

const Header = styled.h2`
  margin-bottom: 17px;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #495058;
`;

const Anotation = styled.span``;

const Item = styled.div`
  margin-top: 28px;
  width: 100%;
  min-height: 26px;

  > div {
    &:first-child {
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 13px;
      line-height: 19px;
      color: #495058;
    }
  }
`;

const NameInput = styled(Input)`
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: #e2e6ea;
  border-radius: 4px;

  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const MemoInput = styled(TextArea)`
  height: 85px !important;
  word-break: break-all;
  border-color: #e2e6ea;
  border-radius: 4px;
  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const PrivateSetting = styled.div`
  display: flex;

  > span {
    width: 85%;
    font-size: 13px;
    line-height: 19px;
    color: #495058;
  }

  > button {
    margin-left: auto;
    margin-bottom: auto;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  button {
    margin-left: auto;
    width: 138px;
    height: 36px;
  }
`;

const TabItem = styled.div<{active?: boolean}>`
  flex: 1;
  text-align: center;
  border-bottom: ${({active}) => (active ? '3px solid #495058' : '')};
  div {
    font-size: 12px;
    font-weight: bold;
    line-height: 35px;
    color: ${({active}) => (active ? '#222426' : '#BDC4CB')};
  }
`;

const Count = styled.span`
  margin-left: 4px;
  padding: 3px 7px;
  background: #e2e6ea;
  border-radius: 100px;
  font-weight: bold;
  font-size: 8px;
  text-align: center;
  color: #899098;
`;

const List = styled.div`
  overflow: scroll;
  height: 460px;

  > * {
    // margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const UserItem = styled.div<{added?: boolean}>`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 100%;

  &:hover {
    background: #f9f9f9;
  }

  > * {
    &:nth-child(1) {
      width: 30px;
      height: 30px;
      overflow: initial;
      circle {
        fill: none;
        stroke: #bdc4cb;
      }
    }
    &:nth-child(2) {
      flex: 1;
      margin-left: 13px;
    }
    &:nth-child(3) {
      margin-left: auto;
    }
  }
`;
