import React from 'react';
import useCondition from '../../../useCondition';
import useFilterModalContext from '../../useFilterModalContext';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {usePrefectureTypesByCityIdsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {condition, setCondition} = useCondition();

  const {data: {prefectureTypesByCityIds = []} = {}} =
    usePrefectureTypesByCityIdsQuery({
      variables: {
        cityIds: condition.cities.map((city) => city.id),
      },
      skip: !condition?.cities?.length,
    });

  // 都道府県内すべての市町村をを選択した場合に、表示をまとめる
  const items = React.useMemo(() => {
    const prefectureTypes = prefectureTypesByCityIds.filter((prefectureType) =>
      prefectureType.cities.every((city) =>
        condition.cities.map((city) => city.id).includes(city.id),
      ),
    );

    const cities = condition?.cities.filter(
      (city) =>
        !prefectureTypes
          .map((prefectureType) => prefectureType.id)
          .includes(city.prefectureTypeId),
    );

    return [
      ...prefectureTypes.map(
        (prefectureType) => `${prefectureType.name}すべて`,
      ),
      ...cities.map((city) => city.name),
    ];
  }, [condition, prefectureTypesByCityIds]);

  if (!condition?.cities || !condition?.cities.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">エリア</h4>
        <button
          onClick={() => setFilterType('city')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setCondition({cities: []})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {items.map((item, i) => (
          <span
            key={i}
            className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};
