import React, {useState} from 'react';
import {Step, useDestroyStepMutation} from 'api';
import {DotMenu, Trash} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import styled from 'styled-components';
import useClientUser from 'hooks/useClientUser';

interface Props {
  step: Step;
}
const Action = ({step}: Props) => {
  const {isMember} = useClientUser();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [destroyStep] = useDestroyStepMutation({
    variables: {uuid: step.uuid},
    refetchQueries: ['workflow'],
  });

  const Content = () => (
    <Container
      onClick={() => {
        destroyStep();
        setIsPopoverVisible(false);
      }}>
      <Trash />
      <div>削除</div>
    </Container>
  );

  return (
    <>
      {isMember && (
        <Popover
          placement="left"
          content={<Content />}
          trigger="click"
          onVisibleChange={(visible) => setIsPopoverVisible(visible)}
          visible={isPopoverVisible}>
          <IconWrapper>
            <DotMenu />
          </IconWrapper>
        </Popover>
      )}
    </>
  );
};

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 16px;
  svg {
    transform: rotate(90deg);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-right: 18px;
  padding-left: 18px;
  height: 60px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export default Action;
