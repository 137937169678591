import React, {useState} from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Modal, Form, Input} from 'components/antd';
import {
  useUpdateLeadSourceMutation,
  LeadSource,
  Pool,
  useProspectPoolsCountByLeadSourceQuery,
  useDestroyLeadSourceMutation,
} from 'api';
import DestroyConfirmation from './DestroyConfirmation';

interface Props {
  leadSource: LeadSource;
  selectedPool: Pool;
  onClose: () => void;
}

const Edit = ({leadSource, selectedPool, onClose}: Props) => {
  const [updateLeadSource] = useUpdateLeadSourceMutation({
    onCompleted: () => onClose(),
  });

  const [destroyLeadSource] = useDestroyLeadSourceMutation({
    variables: {id: leadSource?.id},
    refetchQueries: ['leadSources'],
    onCompleted: () => {
      onClose();
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: leadSource?.id,
      name: leadSource?.name,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('必須項目です'),
    }),
    onSubmit: (name) =>
      updateLeadSource({
        variables: {
          id: leadSource?.id,
          ...name,
        },
      }),
  });

  const {
    data: {prospectPoolsCountByLeadSource: {prospectPoolsCount} = {}} = {},
  } = useProspectPoolsCountByLeadSourceQuery({
    variables: {
      leadSourceId: leadSource?.id,
      poolId: selectedPool?.id,
    },
    skip: !leadSource,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const destroyConfirmation = () => {
    if (prospectPoolsCount === 0) {
      destroyLeadSource();
    } else {
      setIsModalVisible(true);
    }
  };

  return (
    <Modal
      destroyOnClose
      visible={Boolean(leadSource)}
      width={480}
      centered
      footer={null}
      onCancel={onClose}>
      <ModalContent>
        <h2>リードソースを編集・削除</h2>
        <span className="prospectPoolsCount">{`連携リード : ${
          prospectPoolsCount && prospectPoolsCount.toLocaleString()
        }件`}</span>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Form.Item required>
            <Input
              name="name"
              size="large"
              value={formik.values.name}
              onChange={formik.handleChange}></Input>
          </Form.Item>
          <Actions>
            <Button size="large" onClick={onClose}>
              キャンセル
            </Button>
            <div>
              <Button
                danger
                onClick={() => destroyConfirmation()}
                size="large"
                disabled={leadSource?.leadSourceType !== 'manual'}>
                削除
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{marginLeft: '8px'}}
                disabled={!formik.isValid}>
                更新
              </Button>
            </div>
          </Actions>
        </Form>
        <DestroyConfirmation
          leadSource={leadSource}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  form {
    margin-top: 1rem;
  }

  label {
    font-weight: bold;
  }

  .prospectPoolsCount {
    color: #899098;
    background: #f3f5f7;
    border-radius: 4px;
    line-height: 20px;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Edit;
