import React from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import Modal from '../../../pools/detail/account_groups/AccountGroup/MenuButton/Modal';
import {
  useCreateCollectedFormUrlMutation,
  CollectedFormUrlAttributes,
} from 'api';
import * as Yup from 'yup';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const NewModal = ({visible, onClose}: Props) => {
  const history = useHistory();
  const [create, {loading}] = useCreateCollectedFormUrlMutation({
    onCompleted: ({createCollectedFormUrl}) => {
      if (createCollectedFormUrl.error) {
        alert(createCollectedFormUrl.error);
      } else {
        history.push(
          `/form/collected_form_urls/${createCollectedFormUrl.collectedFormUrl.uuid}`,
        );
      }
    },
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('必須項目です'),
    url: Yup.string().url().required('必須項目です'),
  });

  const onSubmit = React.useCallback(
    (values: CollectedFormUrlAttributes) =>
      create({
        variables: {
          attributes: values,
        },
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: {isValid},
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      url: '',
    },
    mode: 'onChange',
  });

  return (
    <Modal title="タグ連携フォームを作成" visible={visible} onClose={onClose}>
      <form
        className="flex flex-col gap-4 w-full"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-1">
          <label className="text-c-light">タイトル</label>
          <input
            {...register('title')}
            className="form-input border-c-border rounded text-base w-full"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-c-light">URL</label>
          <div className="flex items-center">
            <input
              className="form-input border-c-border rounded text-base w-full"
              {...register('url')}
            />
          </div>
        </div>
        <button
          className="bg-c-primary text-white hover:opacity-90 focus:outline-none leading-none font-bold px-4 rounded cursor-pointer transition-all disabled:bg-c-bg disabled:text-c-light flex items-center justify-center h-10"
          type="submit"
          disabled={!isValid || loading}>
          作成
        </button>
      </form>
    </Modal>
  );
};

export default NewModal;
