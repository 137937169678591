import gql from 'graphql-tag';

export default gql`
  fragment eventFragment on Event {
    id
    eventType
    name
    fields
    startAt
    draftCount
    progressCount
    doneCount
    nothingCount
  }
`;
