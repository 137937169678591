import gql from 'graphql-tag';

export default gql`
  fragment subCategoryFragment on SubCategory {
    id
    uuid
    name
    displayName
    count
    position
    mainCategoryId
  }
`;
