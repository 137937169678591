import React from 'react';
import styled from 'styled-components';
import {Popover} from 'components/antd';
import ContentPopover from './ContentPopover';
import {Content} from 'components/Ui/Icon';

export default () => {
  return (
    <PopoverButton
      content={<ContentPopover />}
      placement="bottom"
      trigger="click">
      <Content />
      <span>コンテンツ表示</span>
    </PopoverButton>
  );
};

const PopoverButton = styled(Popover)`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 17px;
    width: 27px;
  }
`;
