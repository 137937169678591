import gql from 'graphql-tag';
import taskProspectActivityFragment from './task';
import callProspectActivityFragment from './call';
import miitelCallProspectActivityFragment from './miitelCall';
import stageChangeProspectActivityFragment from './stageChange';
import rankChangeProspectActivityFragment from './rankChange';
import manualMailSendProspectActivityFragment from './manualMailSend';
import manualMailCommentProspectActivityFragment from './manualMailComment';
import manualMailGmailSendProspectActivityFragment from './manualMailGmailSend';
import automaticMailSendProspectActivityFragment from './automaticMailSend';
import automaticMailOpenProspectActivityFragment from './automaticMailOpen';
import automaticMailClickProspectActivityFragment from './automaticMailClick';
import automaticMailBounceProspectActivityFragment from './automaticMailBounce';
import salesforceDealProspectActivityFragment from './salesforceDeal';
import applyWorkflowProspectActivityFragment from './applyWorkflow';
import userFragment from '../user';
import commentProspectActivityFragment from './comment';
import mailCommentProspectActivityFragment from './mailComment';
import manualMailOpenProspectActivityFragment from './manualMailOpen';
import manualMailClickProspectActivityFragment from './manualMailClick';
import manualMailBounceProspectActivityFragment from './manualMailBounce';
import collectedFormProspectActivityFragment from './collectedForm';
import convertPreleadContactToProspectProspectActivityFragment from './convertPreleadContactToProspect';
import salesforceContactLeadCreateProspectActivityFragment from './salesforceContactLeadCreate';
import salesforceLeadCreateProspectActivityFragment from './salesforceLeadCreate';
import salesforceEventCreateProspectActivityFragment from './salesforceEventCreate';
import salesforceTaskProspectActivityFragment from './salesforceTask';
import salesforceTaskCallProspectActivityFragment from './salesforceTaskCall';
import salesforceTaskEmailProspectActivityFragment from './salesforceTaskEmail';
import salesforceTaskTodoProspectActivityFragment from './salesforceTaskTodo';
import stopWorkflowProspectActivityFragment from './stopWorkflow';
import unsubscribeAutomaticMailProspectActivityFragment from './unsubscribeAutomaticMail';
import mailCampaignMailSendProspectActivityFragment from './mailCampaignMailSend';
import mailCampaignMailOpenProspectActivityFragment from './mailCampaignMailOpen';
import mailCampaignMailClickProspectActivityFragment from './mailCampaignMailClick';
import mailCampaignMailBounceProspectActivityFragment from './mailCampaignMailBounce';
import unsubscribeMailCampaignProspectActivityFragment from './unsubscribeMailCampaign';
import taskCommentProspectActivityFragment from './taskComment';
import hubspotContactCreateProspectActivityFragment from './hubspotContactCreate';
import hubspotDealCreateProspectActivityFragment from './hubspotDealCreate';
import hubspotNoteProspectActivityFragment from './hubspotNote';
import hubspotCallProspectActivityFragment from './hubspotCall';
import hubspotEmailProspectActivityFragment from './hubspotEmail';
import zoomPhoneWebhookCallProspectActivityFragment from './zoomPhoneWebhookCall';
import formPostProspectActivityFragment from './formPost';
import preleadProjectCreateProspectActivityFragment from './preleadProjectCreate';
import clientPhaseProspectChangeProspectActivityFragment from './clientPhaseProspectChange';

export default gql`
  fragment prospectActivityFragment on ProspectActivity {
    id
    uuid
    createdAt
    user {
      ...userFragment
    }
    resourceId
    resourceType
    resource {
      ... on TaskProspectActivity {
        ...taskProspectActivityFragment
      }
      ... on CallProspectActivity {
        ...callProspectActivityFragment
      }
      ... on MiitelCallProspectActivity {
        ...miitelCallProspectActivityFragment
      }
      ... on StageChangeProspectActivity {
        ...stageChangeProspectActivityFragment
      }
      ... on RankChangeProspectActivity {
        ...rankChangeProspectActivityFragment
      }
      ... on ManualMailSendProspectActivity {
        ...manualMailSendProspectActivityFragment
      }
      ... on ManualMailCommentProspectActivity {
        ...manualMailCommentProspectActivityFragment
      }
      ... on ManualMailGmailSendProspectActivity {
        ...manualMailGmailSendProspectActivityFragment
      }
      ... on AutomaticMailSendProspectActivity {
        ...automaticMailSendProspectActivityFragment
      }
      ... on AutomaticMailOpenProspectActivity {
        ...automaticMailOpenProspectActivityFragment
      }
      ... on AutomaticMailClickProspectActivity {
        ...automaticMailClickProspectActivityFragment
      }
      ... on AutomaticMailBounceProspectActivity {
        ...automaticMailBounceProspectActivityFragment
      }
      ... on SalesforceDealProspectActivity {
        ...salesforceDealProspectActivityFragment
      }
      ... on ApplyWorkflowProspectActivity {
        ...applyWorkflowProspectActivityFragment
      }
      ... on CommentProspectActivity {
        ...commentProspectActivityFragment
      }
      ... on MailCommentProspectActivity {
        ...mailCommentProspectActivityFragment
      }
      ... on ManualMailOpenProspectActivity {
        ...manualMailOpenProspectActivityFragment
      }
      ... on ManualMailClickProspectActivity {
        ...manualMailClickProspectActivityFragment
      }
      ... on ManualMailBounceProspectActivity {
        ...manualMailBounceProspectActivityFragment
      }
      ... on CollectedFormProspectActivity {
        ...collectedFormProspectActivityFragment
      }
      ... on ConvertPreleadContactToProspectProspectActivity {
        ...convertPreleadContactToProspectProspectActivityFragment
      }
      ... on SalesforceContactLeadCreateProspectActivity {
        ...salesforceContactLeadCreateProspectActivityFragment
      }
      ... on SalesforceLeadCreateProspectActivity {
        ...salesforceLeadCreateProspectActivityFragment
      }
      ... on SalesforceEventCreateProspectActivity {
        ...salesforceEventCreateProspectActivityFragment
      }
      ... on SalesforceTaskProspectActivity {
        ...salesforceTaskProspectActivityFragment
      }
      ... on SalesforceTaskCallProspectActivity {
        ...salesforceTaskCallProspectActivityFragment
      }
      ... on SalesforceTaskEmailProspectActivity {
        ...salesforceTaskEmailProspectActivityFragment
      }
      ... on SalesforceTaskTodoProspectActivity {
        ...salesforceTaskTodoProspectActivityFragment
      }
      ... on StopWorkflowProspectActivity {
        ...stopWorkflowProspectActivityFragment
      }
      ... on UnsubscribeAutomaticMailProspectActivity {
        ...unsubscribeAutomaticMailProspectActivityFragment
      }
      ... on SubscribeAutomaticMailProspectActivity {
        ...subscribeAutomaticMailProspectActivityFragment
      }
      ... on MailCampaignMailSendProspectActivity {
        ...mailCampaignMailSendProspectActivityFragment
      }
      ... on MailCampaignMailOpenProspectActivity {
        ...mailCampaignMailOpenProspectActivityFragment
      }
      ... on MailCampaignMailClickProspectActivity {
        ...mailCampaignMailClickProspectActivityFragment
      }
      ... on MailCampaignMailBounceProspectActivity {
        ...mailCampaignMailBounceProspectActivityFragment
      }
      ... on UnsubscribeMailCampaignProspectActivity {
        ...unsubscribeMailCampaignProspectActivityFragment
      }
      ... on TaskCommentProspectActivity {
        ...taskCommentProspectActivityFragment
      }
      ... on HubspotContactCreateProspectActivity {
        ...hubspotContactCreateProspectActivityFragment
      }
      ... on HubspotDealCreateProspectActivity {
        ...hubspotDealCreateProspectActivityFragment
      }
      ... on HubspotNoteProspectActivity {
        ...hubspotNoteProspectActivityFragment
      }
      ... on HubspotCallProspectActivity {
        ...hubspotCallProspectActivityFragment
      }
      ... on HubspotEmailProspectActivity {
        ...hubspotEmailProspectActivityFragment
      }
      ... on ZoomPhoneWebhookCallProspectActivity {
        ...zoomPhoneWebhookCallProspectActivityFragment
      }
      ... on FormPostProspectActivity {
        ...formPostProspectActivityFragment
      }
      ... on PreleadProjectCreateProspectActivity {
        ...preleadProjectCreateProspectActivityFragment
      }
      ... on ClientPhaseProspectChangeProspectActivity {
        ...clientPhaseProspectChangeProspectActivityFragment
      }
    }
  }
  ${taskProspectActivityFragment}
  ${callProspectActivityFragment}
  ${miitelCallProspectActivityFragment}
  ${stageChangeProspectActivityFragment}
  ${rankChangeProspectActivityFragment}
  ${manualMailSendProspectActivityFragment}
  ${manualMailCommentProspectActivityFragment}
  ${manualMailGmailSendProspectActivityFragment}
  ${automaticMailSendProspectActivityFragment}
  ${automaticMailOpenProspectActivityFragment}
  ${automaticMailClickProspectActivityFragment}
  ${automaticMailBounceProspectActivityFragment}
  ${salesforceDealProspectActivityFragment}
  ${applyWorkflowProspectActivityFragment}
  ${userFragment}
  ${commentProspectActivityFragment}
  ${mailCommentProspectActivityFragment}
  ${manualMailOpenProspectActivityFragment}
  ${manualMailClickProspectActivityFragment}
  ${manualMailBounceProspectActivityFragment}
  ${collectedFormProspectActivityFragment}
  ${convertPreleadContactToProspectProspectActivityFragment}
  ${salesforceLeadCreateProspectActivityFragment}
  ${salesforceContactLeadCreateProspectActivityFragment}
  ${salesforceTaskProspectActivityFragment}
  ${salesforceTaskCallProspectActivityFragment}
  ${salesforceTaskEmailProspectActivityFragment}
  ${salesforceTaskTodoProspectActivityFragment}
  ${stopWorkflowProspectActivityFragment}
  ${unsubscribeAutomaticMailProspectActivityFragment}
  ${mailCampaignMailSendProspectActivityFragment}
  ${mailCampaignMailOpenProspectActivityFragment}
  ${mailCampaignMailClickProspectActivityFragment}
  ${mailCampaignMailBounceProspectActivityFragment}
  ${unsubscribeMailCampaignProspectActivityFragment}
  ${taskCommentProspectActivityFragment}
  ${hubspotContactCreateProspectActivityFragment}
  ${hubspotDealCreateProspectActivityFragment}
  ${hubspotNoteProspectActivityFragment}
  ${hubspotCallProspectActivityFragment}
  ${hubspotEmailProspectActivityFragment}
  ${zoomPhoneWebhookCallProspectActivityFragment}
  ${formPostProspectActivityFragment}
  ${preleadProjectCreateProspectActivityFragment}
  ${clientPhaseProspectChangeProspectActivityFragment}
`;
