import React from 'react';
import {Form, DatePicker, Select} from 'components/antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import {useUsersByClientAllQuery} from 'api';

interface Props {
  formik: any;
}

export const DealFormItem = ({formik}: Props) => {
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  return (
    <>
      <Form.Item
        label="商談作成者"
        validateStatus={formik.errors.deal?.userId && 'error'}
        help={formik.errors.deal?.userId}>
        <Select
          value={formik.values.deal?.userId}
          showSearch
          optionFilterProp="children"
          onChange={(value) => formik.setFieldValue('deal.userId', value)}>
          {users.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user?.lastName} {user?.firstName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        required
        label="商談作成日"
        validateStatus={formik.errors.deal?.date && 'error'}
        help={formik.errors.deal?.date}>
        <DatePicker
          value={formik.values.deal?.date}
          locale={locale}
          style={{width: '200px'}}
          onChange={(date) => formik.setFieldValue('deal.date', date)}
        />
      </Form.Item>
    </>
  );
};
