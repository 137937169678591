import React from 'react';
import {ProspectPool, useCurrentUserQuery, useCurrentClientQuery} from 'api';
import {useParams, Link} from 'react-router-dom';
import {Check, Alert, Bounce, Unsubscribed} from './Icon';
import {UserIcon} from '@heroicons/react/20/solid';
import Menu from './Menu';
import CheckProspectPool from './CheckProspectPool';
import useClientUser from 'hooks/useClientUser';

interface Props {
  prospectPool: ProspectPool;
}

const Tooltip = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => (
  <span className="relative group flex items-center">
    <span
      className="
      text-sm
      font-normal
      absolute
      -top-10
      left-1/2
      -translate-x-1/2
      whitespace-nowrap
      rounded
      bg-[rgba(0,0,0,0.8)]
      px-2
      py-1
      text-white
      before:content-['']
      before:absolute
      before:-translate-x-1/2
      before:left-1/2
      before:top-full
      before:border-4
      before:border-transparent
      before:border-t-black
      opacity-0
      group-hover:opacity-80
      transition
      pointer-events-none">
      {title}
    </span>
    {children}
  </span>
);

const TableItemHeader: React.FC<Props> = ({prospectPool}) => {
  const {poolId} = useParams<{poolId: string}>();
  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  const {isAdmin} = useClientUser();

  const {data: {currentClient: {currentMaintenanceContract = {}} = {}} = {}} =
    useCurrentClientQuery({});

  return (
    <div className="flex py-4 bg-[#FBFCFD] border-b border-c-border justify-between">
      <div className="flex items-center">
        {(currentUser?.role === 'admin' ||
          (isAdmin && currentMaintenanceContract?.canMergeProspect)) && (
          <CheckProspectPool prospectPool={prospectPool} />
        )}

        <div className="text-lg font-bold px-4">
          <Link
            className="text-c-base"
            to={`/pools/${poolId}/prospects/${prospectPool.uuid}/timeline${location.search}`}>
            {prospectPool.prospect.lastName} {prospectPool.prospect.firstName}
          </Link>
        </div>
        <div className="text-lg font-bold px-4 border-l border-c-border flex items-center gap-2">
          {prospectPool.prospect.account.companyId ? (
            <Tooltip title="名寄せ完了">
              <Check />
            </Tooltip>
          ) : (
            <Tooltip title="名寄せできませんでした">
              <Alert />
            </Tooltip>
          )}
          <span>{prospectPool.prospect.account.name}</span>
          <div className="flex items-center gap-1 text-c-light text-sm font-normal">
            <UserIcon className="w-4 h-4" />
            {
              prospectPool.accountPool.prospectPools.filter(
                (value) => value.status === 'active',
              ).length
            }
          </div>
          {prospectPool.isBounced && <Bounce />}
          {prospectPool.isUnsubscribed && <Unsubscribed />}
        </div>
      </div>
      <div className="flex items-center">
        <div className="text-sm px-4 text-c-light">
          {prospectPool.user?.lastName} {prospectPool.user?.firstName}
        </div>
        <Menu prospectPool={prospectPool} />
      </div>
    </div>
  );
};

export default TableItemHeader;
