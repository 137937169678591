import React from 'react';
import {useHistory} from 'react-router-dom';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {Dialog, Transition} from '@headlessui/react';
import {ProspectPool, Approach} from 'api';
import Tabs from './Tabs';
import TelForm from './TelForm';
import MailForm from './MailForm';
import CommentForm from './CommentForm';
import TaskForm from './TaskForm';

const FormComponents: {[key: string]: any} = {
  call: TelForm,
  manual_mail: MailForm,
  comment: CommentForm,
  task: TaskForm,
};

interface Props {
  prospectPool: ProspectPool;
  approach: Approach;
  visible: boolean;
  onClose: () => void;
  defaultFormType: string;
}

const Modal = ({
  prospectPool,
  approach,
  visible,
  onClose,
  defaultFormType,
}: Props) => {
  const history = useHistory();
  const [formType, setFormType] = React.useState<string>('call');

  React.useEffect(() => {
    setFormType(defaultFormType || 'call');
  }, [visible, defaultFormType]);

  const FormComponent = formType ? FormComponents[formType] : TelForm;

  return (
    <Transition appear show={visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="relative w-full max-w-lg transform rounded bg-white text-left align-middle shadow-xl transition-all">
                <div className="w-full h-full flex flex-col gap-2">
                  <div className="h-12 flex items-center px-6 gap-4 bg-c-bg rounded-t">
                    <h2 className="m-0 text-base flex-1">アプローチの記録</h2>
                    <button
                      className="p-0 bg-transparent border-none cursor-pointer w-6 h-6 focus:outline-none"
                      onClick={onClose}>
                      <XMarkIcon className="h-6 w-6 text-c-lighter" />
                    </button>
                  </div>
                  <Tabs current={formType} onClick={setFormType} />
                  <FormComponent
                    prospectPool={prospectPool}
                    approach={approach}
                    onCompleted={() => {
                      onClose();
                      history.push(
                        location.pathname.replace(/approach$/, 'timeline') +
                          location.search,
                      );
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
