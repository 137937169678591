import React from 'react';
import {Link} from 'react-router-dom';

interface Props {
  title: string;
}

export const Breadcrumb: React.FC<Props> = ({title}) => (
  <ol className="inline-flex items-center mb-3 space-x-1 text-xs">
    <li>
      <div className="flex items-center">
        <Link to="/reports" className="ms-1 font-medium text-c-light">
          レポート
        </Link>
      </div>
    </li>
    <li aria-current="page">
      <div className="flex items-center">
        <svg
          className="rtl:rotate-180 w-2 h-2 mx-1 text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 9 4-4-4-4"
          />
        </svg>
        <span className="text-c-light font-bold">{title}</span>
      </div>
    </li>
  </ol>
);
