import React, {useState} from 'react';
import {ProspectPool, useCurrentUserQuery} from 'api';
import ScoreCircle from 'components/ScoreCircle';
import {Popover, Transition} from '@headlessui/react';

const ScoreItem = ({title, score}: {title: string; score: number}) => (
  <div className="flex justify-between items-center w-full !mr-0">
    <h4 className="text-xs">{title}</h4>
    <div className="flex items-center gap-2 !mr-0">
      <div className="flex gap-[1px] items-center">
        <span className="bg-score-green-foreground h-[6px] w-4 rounded-l-full" />
        <span
          className={`${score >= 2 ? 'bg-score-green-foreground' : 'bg-gray-200'} h-[6px] w-4`}
        />
        <span
          className={`${score == 3 ? 'bg-score-green-foreground' : 'bg-gray-200'} h-[6px] w-4 rounded-r-full`}
        />
      </div>
      <span className="font-bold text-xs w-4">{score}</span>
    </div>
  </div>
);

const Score = ({prospectPool}: {prospectPool: ProspectPool}) => {
  const score = prospectPool?.score;

  const [isShowing, setIsShowing] = useState(false);

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  if (currentUser?.role !== 'admin') {
    return null;
  }

  return (
    <div>
      <Popover className="relative">
        <div
          onMouseEnter={() => setIsShowing(true)}
          onMouseLeave={() => setIsShowing(false)}>
          <ScoreCircle score={80} />
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isShowing}>
          <Popover.Panel className="absolute left-0 z-10 mt-2 w-[200px] bg-white rounded-lg p-3 shadow-lg border-c-border border">
            <div className="absolute w-3 h-3 rotate-45 bg-white shadow-[-2px_-2px_4px_rgba(0,0,0,0.08)] rounded-sm -top-1 left-[27px] transform -translate-x-1/2" />
            <div className="flex flex-col">
              <ScoreItem title="業種" score={1} />
              <ScoreItem title="役職" score={score?.positionScore} />
              <ScoreItem title="決算" score={3} />
              <ScoreItem title="従業員数" score={2} />
              <ScoreItem title="資本金" score={1} />
              <ScoreItem title="事業内容" score={1} />
              <ScoreItem title="エリア" score={3} />
              <ScoreItem title="求人・メディア" score={2} />
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};

export default Score;
