import React from 'react';

function Icon() {
  return (
    <svg
      width="7"
      height="5"
      viewBox="0 0 7 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="7" height="1" rx="0.5" fill="#C4C4C4" />
      <rect y="2" width="7" height="1" rx="0.5" fill="#C4C4C4" />
      <rect y="4" width="7" height="1" rx="0.5" fill="#C4C4C4" />
    </svg>
  );
}

export default Icon;
