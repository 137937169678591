import React from 'react';
import styled from 'styled-components';
import {Cross, Folder, Note} from 'components/Ui/Icon';
import {
  usePreleadsQuery,
  useCreateBlockGroupByPreleadMasterMutation,
  useCreateBlockGroupMembersByPreleadMasterMutation,
  useAllBlockGroupsQuery,
  BlockGroup,
} from 'api';
import {useFormik} from 'formik';
import {Input, Form} from 'components/antd';
import {Button, Radio} from 'components/Ui';
const {TextArea} = Input;
import useSearchParams from '../../useSearchParams';
import {useHistory} from 'react-router';

interface Props {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({setModalVisible}: Props) => {
  const [selectedBlockGroupId, setSelectedBlockGroupId] = React.useState(null);

  const history = useHistory();

  const [
    createBlockGroupByPreleadMaster,
  ] = useCreateBlockGroupByPreleadMasterMutation({
    onCompleted: ({createBlockGroupByPreleadMaster: {blockGroup}}) => {
      setModalVisible(false);
      history.push(`/block/block_groups/${blockGroup.uuid}`);
    },
  });

  const [
    createBlockGroupMembersByPreleadMaster,
  ] = useCreateBlockGroupMembersByPreleadMasterMutation({
    onCompleted: ({createBlockGroupMembersByPreleadMaster: {blockGroup}}) => {
      setModalVisible(false);
      history.push(`/block/block_groups/${blockGroup.uuid}`);
    },
  });

  const {
    data: {allBlockGroups: {blockGroups = []} = {}} = {},
  } = useAllBlockGroupsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {searchParams, page, perPage} = useSearchParams();

  const {data: {preleads: {pagination = {}} = {}} = {}} = usePreleadsQuery({
    variables: {
      search: searchParams,
      page,
      perPage,
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      memo: '',
    },
    onSubmit: (values) => {
      if (selectedBlockGroupId) {
        createBlockGroupMembersByPreleadMaster({
          variables: {
            uuid: selectedBlockGroupId,
            count: pagination.totalCount,
            search: searchParams,
          },
        });
      } else {
        createBlockGroupByPreleadMaster({
          variables: {
            attributes: {
              ...values,
            },
            count: pagination.totalCount,
            search: searchParams,
          },
        });
      }
    },
  });

  const {values, handleChange, handleSubmit} = formik;

  return (
    <Container>
      <Header>
        ブロックグループを作成
        <CrossIconWrapper onClick={() => setModalVisible(false)}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <Description>
        フィルタリングされている状態でブロックグループが作成できます。
        <CountBox>
          <span>現在のフィルタリング件数:</span>
          <Count>{pagination.totalCount?.toLocaleString()}</Count>
        </CountBox>
      </Description>

      <BlockGroupList>
        {blockGroups?.map((blockGroup: BlockGroup) => (
          <BlockGroupBox key={blockGroup.uuid}>
            <CustomRadio
              checked={selectedBlockGroupId === blockGroup.uuid}
              onChange={() => setSelectedBlockGroupId(blockGroup.uuid)}>
              <BlockGroupName bold={selectedBlockGroupId === blockGroup.uuid}>
                {blockGroup.name}
              </BlockGroupName>
              <BlockGroupCount bold={selectedBlockGroupId === blockGroup.uuid}>
                {blockGroup.count.toLocaleString()}件
              </BlockGroupCount>
            </CustomRadio>
          </BlockGroupBox>
        ))}
      </BlockGroupList>

      <NewBlockGroup>
        <CustomRadio
          checked={!selectedBlockGroupId}
          onChange={() => setSelectedBlockGroupId(null)}>
          <BlockGroupName bold={!selectedBlockGroupId}>
            新規ブロックグループを作成
          </BlockGroupName>
        </CustomRadio>
        <CustomForm onFinish={handleSubmit}>
          <Item>
            <Folder />
            <Name
              name="name"
              placeholder="新規ブロックグループ名"
              value={values.name}
              onChange={handleChange}
            />
          </Item>
          <ItemMemo>
            <Note />
            <Memo name="memo" value={values.memo} onChange={handleChange} />
          </ItemMemo>

          <ButtonWrapper>
            <Button
              htmlType="submit"
              disabled={!selectedBlockGroupId && !values.name}>
              保存
            </Button>
          </ButtonWrapper>
        </CustomForm>
      </NewBlockGroup>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 30px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  margin-top: 5px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.15px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const CrossIconWrapper = styled.div`
  margin-right: 14px;
  cursor: pointer;
`;

const Description = styled.div`
  font-size: 13px;
  letter-spacing: 0.15px;
`;

const CountBox = styled.div`
  display: inline-block;
  background: #f3f5f7;
  border-radius: 4px;
  padding: 8px;
  margin-top: 5px;

  font-size: 10px;
  letter-spacing: 0.15px;
  color: #495058;
`;

const Count = styled.span`
  margin-left: 7px;
  font-weight: bold;
  font-size: 13px;
`;

const BlockGroupName = styled.div<{bold?: boolean}>`
  display: inline-block;
  width: 200px;
  font-weight: ${({bold}) => (bold ? 'bold' : '')};
`;

const BlockGroupList = styled.div`
  width: 90%;
  margin-top: 20px;
  overflow-y: scroll;
  height: 160px;
  border-bottom: 1px solid #f3f5f7;
  padding-bottom: 16px;

  ${BlockGroupName} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const BlockGroupBox = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const CustomRadio = styled(Radio)`
  width: 280px;
`;

const BlockGroupCount = styled.div<{bold?: boolean}>`
  display: inline-block;
  float: right;
  font-weight: ${({bold}) => (bold ? 'bold' : '')};
`;

const NewBlockGroup = styled.div`
  margin-top: 20px;
`;

const CustomForm = styled(Form)`
  padding: 15px 50px 0 25px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const ItemMemo = styled(Item)`
  align-items: start;
`;

const Name = styled(Input)`
  width: 420px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: #e2e6ea;
  border-radius: 4px;

  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const Memo = styled(TextArea)`
  width: 420px;
  height: 75px !important;
  word-break: break-all;
  border-color: #e2e6ea;
  border-radius: 4px;

  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;

const ButtonWrapper = styled.div`
  display: flex;

  button {
    margin-left: auto;
    width: 86px;
    height: 27px;
  }
`;
