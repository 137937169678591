import React from 'react';
import {Switch, Route} from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../Menu';
import List from './List';

export default () => {
  return (
    <Body>
      <Menu />
      <Switch>
        <Route path="/settings/customize_item" component={List} />
      </Switch>
    </Body>
  );
};

const Body = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  background: #fff;
`;
