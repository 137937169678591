import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {
  useProspectPoolQuery,
  UpdateStageProspectPoolMutationFn,
  usePoolQuery,
  useCurrentClientQuery,
  useUpdateStageProspectPoolMutation,
} from 'api';
import Header from './Header';
import Content from './Content';
import RightContent from './RightContent';
import LeftContent from './LeftContent';
import SideTab from './SideTab';
import {StringParam, useQueryParam} from 'use-query-params';
import {useMiitel} from './useMiitel';
import CustomizeItemHeader from './CustomizeItemHeader';
import SalesforceHeader from './SalesforceHeader';
import HubspotHeader from './HubspotHeader';
import {ProspectContext} from './useProspectContext';

interface Props {
  prospectId: string;
  poolId: string;
  updateStage?: UpdateStageProspectPoolMutationFn;
  onClose: () => void;
  onMove?: (prospectPoolUuid: string) => void;
}

export default ({prospectId, poolId, updateStage, onClose, onMove}: Props) => {
  const [_, setInformation] = useQueryParam('information', StringParam);

  const [_updateStage] = useUpdateStageProspectPoolMutation();

  const handleUpdateStage = updateStage || _updateStage;

  const escFunction = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setInformation(null);
      onClose();
    }
  };

  const {
    data: {prospectPool} = {},
    loading,
    refetch,
  } = useProspectPoolQuery({
    variables: {uuid: prospectId},
    skip: !prospectId,
    fetchPolicy: 'cache-and-network',
  });

  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const {data: {currentClient: {miitelSetting = {}} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const handleClick = () => {
    setInformation(null);
    onClose();
  };

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useMiitel(prospectPool?.uuid, miitelSetting);

  if (!prospectPool) return null;

  return (
    <ProspectContext.Provider value={{prospectId, poolId, onMove}}>
      <Flex>
        <Container>
          <Group>
            <CustomButton onClick={handleClick}> ×</CustomButton>
          </Group>
          <Flex>
            <LeftContent prospectPool={prospectPool} />
            <SideTab />
          </Flex>
          <Box>
            <Header prospectPool={prospectPool} />
            <CustomizeItemHeader prospectPool={prospectPool} />
            {pool?.poolSalesforceSetting?.isActive && (
              <SalesforceHeader prospectPool={prospectPool} />
            )}
            {pool?.poolHubspotSetting?.isActive && (
              <HubspotHeader prospectPool={prospectPool} />
            )}
            <Content
              prospectPool={prospectPool}
              refetch={refetch}
              loading={loading}
            />
          </Box>
          <RightContent
            prospectPool={prospectPool}
            updateStage={handleUpdateStage}
          />
        </Container>
        <GlobalStyle />
      </Flex>
    </ProspectContext.Provider>
  );
};

const GlobalStyle = createGlobalStyle`
  #miitelPhoneIFrameButton {
    height: 40px !important;
  }
`;

const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  align-items: flex-start;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 550px;
  border-left: 1px solid #e2e6ea;
  padding: 15px 16px 15px 22px;
  background: #fff;
`;

const Flex = styled.div`
  display: flex;
  background: #fff;
`;

const Group = styled.div`
  display: inline-block;
  height: 30px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background-color: transparent;
  cursor: pointer;
`;

const CustomButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: #bdc4cb;
  border-bottom: 1px solid #f3f5f7;
`;
