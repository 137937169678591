import React from 'react';
import {FormBlock, FormBlockText} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockText;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;

  if (blockable.textHtml) {
    return (
      <div
        className="[&_p]:m-0"
        dangerouslySetInnerHTML={{__html: blockable.textHtml}}
      />
    );
  }

  return <div className="whitespace-pre-wrap">{blockable.text}</div>;
};
