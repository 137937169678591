export const regions = [
  {
    regionType: 'hokkai',
    name: '北海道',
    prefectureTypes: ['北海道'],
  },
  {
    regionType: 'tohoku',
    name: '東北',
    prefectureTypes: [
      '青森県',
      '岩手県',
      '秋田県',
      '宮城県',
      '山形県',
      '福島県',
    ],
  },
  {
    regionType: 'kanto',
    name: '関東',
    prefectureTypes: [
      '東京都【23区】',
      '東京都【23区】以外',
      '神奈川県',
      '千葉県',
      '埼玉県',
      '茨城県',
      '栃木県',
      '群馬県',
    ],
  },
  {
    regionType: 'tyubu',
    name: '中部',
    prefectureTypes: [
      '山梨県',
      '長野県',
      '新潟県',
      '富山県',
      '石川県',
      '福井県',
      '愛知県',
      '岐阜県',
      '静岡県',
      '三重県',
    ],
  },
  {
    regionType: 'kinki',
    name: '近畿',
    prefectureTypes: [
      '大阪府',
      '京都府',
      '兵庫県',
      '滋賀県',
      '奈良県',
      '和歌山県',
    ],
  },
  {
    regionType: 'tyugoku',
    name: '中国',
    prefectureTypes: ['広島県', '岡山県', '鳥取県', '島根県', '山口県'],
  },
  {
    regionType: 'shikoku',
    name: '四国',
    prefectureTypes: ['愛媛県', '香川県', '徳島県', '高知県'],
  },
  {
    regionType: 'kusyu',
    name: '九州・沖縄',
    prefectureTypes: [
      '福岡県',
      '佐賀県',
      '長崎県',
      '熊本県',
      '大分県',
      '宮崎県',
      '鹿児島県',
      '沖縄県',
    ],
  },
];
