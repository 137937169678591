import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignMailSendActivityFragment on MailCampaignMailSendActivity {
    id
    mailCampaignSend {
      id
      bodyHtml
      createdAt
    }
    mailCampaign {
      id
      uuid
      title
      subject
      fromAddress
      fromName
      cc
      bcc
      sendAt
      isImmediately
      bodyHtml
    }
  }
`;
