import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7716 13.8332C15.1835 13.8332 15.5211 14.1857 15.5211 14.6158C15.5211 15.046 15.1835 15.3984 14.7716 15.3984C14.3596 15.3984 14.0221 15.046 14.0221 14.6158C14.0221 14.1857 14.3596 13.8332 14.7716 13.8332ZM14.7716 13.7316C14.3024 13.7316 13.9248 14.1259 13.9248 14.6158C13.9248 15.1057 14.3024 15.5 14.7716 15.5C15.2407 15.5 15.6184 15.1057 15.6184 14.6158C15.6184 14.1259 15.2407 13.7316 14.7716 13.7316Z"
      fill="#0A66C1"
    />
    <path
      d="M14.9027 14.6756C15.0286 14.6756 15.1316 14.568 15.1316 14.4366C15.1316 14.4366 15.1316 14.4247 15.1316 14.4187C15.1316 14.2395 15.0286 14.1558 14.8169 14.1558H14.4736V15.0938H14.6052V14.6875H14.7654L15.0114 15.0938H15.1488L14.8856 14.6756H14.9027ZM14.7483 14.58H14.5995V14.2634H14.7883C14.8856 14.2634 15 14.2813 15 14.4127C15 14.568 14.8856 14.58 14.7483 14.58Z"
      fill="#0A66C1"
    />
    <path
      d="M11.5332 13.4987H9.70231V10.5056C9.70231 9.78867 9.69087 8.8746 8.75255 8.8746C7.81424 8.8746 7.65404 9.65126 7.65404 10.4578V13.5047H5.82317V7.34519H7.57966V8.18756H7.60254C7.96299 7.54832 8.62668 7.16596 9.33614 7.18986C11.1899 7.18986 11.5332 8.46238 11.5332 10.1232V13.5047V13.4987ZM3.75201 6.49685C3.1627 6.49685 2.68782 6.00098 2.68782 5.38563C2.68782 4.77028 3.1627 4.27442 3.75201 4.27442C4.34132 4.27442 4.8162 4.77028 4.8162 5.38563C4.8162 6.00098 4.34132 6.49685 3.75201 6.49685ZM4.66744 13.4987H2.83658V7.33922H4.66744V13.4987ZM12.4429 2.50006H1.90971C1.41194 2.50006 1.00572 2.91229 1 3.43205V14.4785C1.00572 14.9982 1.41194 15.4164 1.90971 15.4104H12.4429C12.9406 15.4164 13.3526 14.9982 13.3583 14.4785V3.43205C13.3526 2.91229 12.9406 2.49409 12.4429 2.50006Z"
      fill="#0A66C1"
    />
  </svg>
);

export default Icon;
