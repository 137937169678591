import {AccountSignal, Tracking} from 'api';
import moment from 'moment';
import React from 'react';

interface TrackingSignal extends AccountSignal {
  signalable: Tracking;
}

interface Props {
  signal: TrackingSignal;
}

export default ({signal}: Props) => {
  const {
    signalable: {prospect, url},
  } = signal;

  return (
    <>
      <span className="text-gray-700">
        {moment(signal.date).format('YYYY/MM/DD')}
      </span>
      <span className="px-2.5 py-1 bg-c-bg rounded text-sm leading-[17px] text-c-base whitespace-nowrap">
        Web訪問
      </span>
      <span className="truncate w-[615px]">
        <a href={url} target="_blank">
          {`${prospect.lastName}${prospect.firstName}がWeb訪問しました`}
        </a>
      </span>
    </>
  );
};
