import React from 'react';
import {FormBlock, FormPost} from 'api';
import StandardFieldBlock from './StandardField';
import CustomizeItemFieldBlock from './CustomizeItemField';
import OtherFieldBlock from './OtherField';

const blockComponents: {[key: string]: any} = {
  FormBlockFieldStandard: StandardFieldBlock,
  FormBlockFieldCustomizeItem: CustomizeItemFieldBlock,
  FormBlockFieldOther: OtherFieldBlock,
};

interface Props {
  block: FormBlock;
  post: FormPost;
}

export default ({block, post}: Props) => {
  const BlockComponent = blockComponents[block.formBlockable.__typename];

  if (!post || !BlockComponent) return null;

  return <BlockComponent block={block} post={post} />;
};
