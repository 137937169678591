import React from 'react';
import {Route, Switch} from 'react-router';
import {RecoilRoot} from 'recoil';
import BlockGroups from './blockGroups';
import BlockMembers from './blockGroups/detail/blockMembers';

export default () => {
  return (
    <Switch>
      <Route
        path={[
          '/block/block_groups/:blockGroupId/block_members/:blockGroupMemberId',
          '/block/block_groups/:blockGroupId/block_members',
        ]}>
        <RecoilRoot>
          <BlockMembers />
        </RecoilRoot>
      </Route>
      <Route
        path={[
          '/block/block_groups/:blockGroupId/new',
          '/block/block_groups/:blockGroupId',
          '/block/block_groups',
        ]}
        component={BlockGroups}
      />
    </Switch>
  );
};
