import React, {useState} from 'react';
import {Modal, Popover} from 'components/antd';
import styled from 'styled-components';
import {DotMenuSide, Trash} from 'components/Ui/Icon';
import {
  ProjectGroupPreleadCustomizeItem,
  useProjectGroupPreleadCustomizeItemsQuery,
  useDestroyProjectGroupPreleadCustomizeItemMutation,
} from 'api';
import {useParams} from 'react-router';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export default ({visible, setVisible}: Props) => {
  const {projectId} = useParams<{
    projectId: string;
  }>();

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const {data: {projectGroupPreleadCustomizeItems = []} = {}} =
    useProjectGroupPreleadCustomizeItemsQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
    });

  const [destroyProjectGroupPreleadCustomizeItem] =
    useDestroyProjectGroupPreleadCustomizeItemMutation();

  const [selectedItem, setSelectedItem] = useState('');

  const Content = (id: string) => {
    return (
      <PopoverContent>
        <PopoverItem
          onClick={() => {
            setIsPopoverVisible(false);
            destroyProjectGroupPreleadCustomizeItem({
              variables: {id: id},
              refetchQueries: ['projectGroupPreleadCustomizeItems', 'project'],
            });
          }}>
          <TrashWrapper>
            <Trash color="#495058" />
          </TrashWrapper>
          <div>削除</div>
        </PopoverItem>
      </PopoverContent>
    );
  };

  return (
    <Modal
      title={'項目の設定'}
      visible={visible}
      onCancel={() => setVisible(false)}
      okText={'保存'}
      cancelText={'キャンセル'}
      footer={null}
      width={500}>
      <HeaderContentWrapper>
        <Gap width={24} />
        <ItemName>
          <Bold>項目名</Bold>
        </ItemName>
        <Gap width={120} />
        <DataType>
          <Bold>データ形式</Bold>
        </DataType>
        <Gap width={20} />
      </HeaderContentWrapper>
      {projectGroupPreleadCustomizeItems
        .filter(
          (item: ProjectGroupPreleadCustomizeItem) => item.isDeleted === false,
        )
        .map((item: any) => (
          <ContentWrapper key={item.id}>
            <Gap width={26} />
            <ItemName>{item.name}</ItemName>
            <Gap width={122} />
            <DataType>{item.dataTypeText}</DataType>
            <Gap width={20} />
            <Popover
              placement="bottomLeft"
              content={Content(item.id)}
              trigger="click"
              visible={isPopoverVisible && selectedItem === item.id}
              onVisibleChange={(e) => {
                setIsPopoverVisible(e);
                setSelectedItem(item.id);
              }}>
              <div style={{cursor: 'pointer', display: 'flex'}}>
                <DotMenuSide
                  color={
                    isPopoverVisible && selectedItem === item.id
                      ? '#68b5fb'
                      : '#899098'
                  }
                />
              </div>
            </Popover>
          </ContentWrapper>
        ))}
    </Modal>
  );
};

const Bold = styled.span`
  font-weight: 700;
`;

const ItemName = styled.div`
  width: 160px;
  height: 26px;
`;

const DataType = styled.div`
  width: 72px;
`;

const Gap = styled.div<{width: number}>`
  width: ${({width}) => `${width}px`};
  height: 100%;
  margin-bottom: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const PopoverContent = styled.div`
  .ant-popover-placement-bottomLeft {
    padding-top: 0;
  }
`;

const PopoverItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 86px;
  height: 43px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: #f3f5f7;
  }
`;

const TrashWrapper = styled.div`
  padding-top: 5px;
`;
