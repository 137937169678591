import React from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';
import Modal from 'react-modal';
import {Cross} from 'components/Ui/Icon';
import {useArchiveProspectPoolMutation, ProspectPool} from 'api';

interface Props {
  prospectPool: ProspectPool;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export default ({prospectPool, isModalVisible, setIsModalVisible}: Props) => {
  const [archiveProspectPool, {loading}] = useArchiveProspectPoolMutation({
    variables: {
      uuid: prospectPool.uuid,
    },
    onCompleted: () => {
      setIsModalVisible(false);
    },
  });

  const onClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => onClose()}
      style={customStyles}>
      <Header>
        <CrossIconWrapper onClick={() => setIsModalVisible(false)}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <Body>
        <div>リード をアーカイブしますか？</div>
        <div>ワークフローの適用対象外となります。</div>
        <div>適用中のワークフローは、停止となります。</div>
      </Body>
      <Footer>
        <Button type="ghost" onClick={() => setIsModalVisible(false)}>
          キャンセル
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => archiveProspectPool()}
          disabled={loading}>
          アーカイブ
        </Button>
      </Footer>
    </Modal>
  );
};

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
  },
  content: {
    top: '400px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '466px',
    height: '215px',
    padding: '0',
    borderRadius: '5px',
    overflow: 'hidden',
  },
};

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 30px;
`;
const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: 15px;
  right: 20px;
`;

const Body = styled.div`
  text-align: center;
  margin-bottom: 40px;

  > div:first-child {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  > div:nth-child(2) {
    font-weight: 500;
    font-size: 14px;
  }
`;
