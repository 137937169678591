import React, {useState} from 'react';
import {Modal, Form, Input, Select} from 'components/antd';
import {useCreatePoolProspectCustomizeItemMutation} from 'api';
import useProspectContext from '../useProspectContext'

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export default ({visible, setVisible}: Props) => {
  const [name, setName] = useState('');
  const [dataType, setDataType] = useState('text');
  const [create, {loading}] = useCreatePoolProspectCustomizeItemMutation({
    onCompleted: () => {
      setName('');
      setDataType('text');
      setVisible(false);
    },
    refetchQueries: ['poolProspectCustomizeItems'],
  });

  const {poolId} = useProspectContext()

  return (
    <Modal
      title={'項目の追加'}
      visible={visible}
      onCancel={() => setVisible(false)}
      okText={'追加'}
      cancelText={'キャンセル'}
      okButtonProps={{
        disabled: !name || !dataType || loading,
        onClick: () =>
          create({variables: {poolId: poolId, attributes: {name, dataType}}}),
      }}
      width={500}>
      <Form layout="vertical" initialValues={{dataType: 'text'}}>
        <Form.Item name="name" label="項目名" rules={[{required: true}]}>
          <Input value={name} onChange={(e: any) => setName(e.target.value)} />
        </Form.Item>
        <Form.Item
          name="dataType"
          label="データ形式"
          rules={[{required: true}]}>
          <Select onChange={(value: string) => setDataType(value)}>
            <Select.Option value="text">テキスト</Select.Option>
            <Select.Option value="number">数値</Select.Option>
            <Select.Option value="date">日付</Select.Option>
            <Select.Option value="select">セレクト</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
