import gql from 'graphql-tag';

export default gql`
  fragment clientDownloadMailCampaignProspectResultFragment on ClientDownloadMailCampaignProspectResult {
    id
    file
    filename
    count
    createdAt
  }
`;
