import React from 'react';
import styled from 'styled-components';
import {Form, Input, Select} from 'components/antd';
import {Button} from 'components/antd';
import {useFormik} from 'formik';
import {usePreleadQuery, useUpdatePreleadMutation} from 'api';
import * as Yup from 'yup';
import Cleave from 'cleave.js/react';
import {
  Home,
  User,
  Users,
  Tel,
  World,
  Parliament,
  Location,
  Mail,
  Facebook,
  Twitter,
  Line,
  YouTube,
  Instagram,
  LinkedIn,
  Zipcode,
  MinusCircle,
  Flag,
  Offices,
  Calendar,
  Chart,
  BarChart,
  Fax,
  BuildingDomestic,
  BuildingOverseas,
  FactoryOverseas,
  Factory,
  StoreOverseas,
  Store,
} from 'components/Ui/Icon';
import MARKETS from 'helpers/markets';
import usePreleadContext from './usePreleadContext'

const validateSchema = Yup.object().shape({
  name: Yup.string().required('必須項目です'),
  address: Yup.string().nullable(),
  zipCode: Yup.string().nullable(),
  telephoneNumber: Yup.string().nullable(),
  faxNumber: Yup.string().nullable(),
  webSite: Yup.string().nullable(),
  representativePerson: Yup.string().nullable(),
  employeeNumber: Yup.number().nullable(),
  capitalFund: Yup.number().nullable(),
  mailAddress: Yup.string().nullable(),
  officesNumber: Yup.number().nullable(),
  establishedYearMonth: Yup.string()
    .max(7)
    .matches(/^[0-9]{4}-[0-9]{2}/, {
      message: 'YYYY-MMの形式で入力してください。例：1990年1月 → 1990-01',
    }),
  telephoneNumbers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        telephoneNumber: Yup.string().required(),
      }),
    )
    .nullable(),
  preleadFaxNumbers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        faxNumber: Yup.string().required(),
      }),
    )
    .nullable(),
  mailAddresses: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        mail: Yup.string().required(),
      }),
    )
    .nullable(),
  facebookUrls: Yup.array()
    .of(Yup.string().url('次のように記述してください https://facebook.com/***'))
    .compact()
    .nullable(),
  instagramUrls: Yup.array()
    .of(
      Yup.string().url('次のように記述してください https://instagram.com/***'),
    )
    .compact()
    .nullable(),
  youtubeUrls: Yup.array()
    .of(Yup.string().url('次のように記述してください https://youtube.com/***'))
    .compact()
    .nullable(),
  twitterUrls: Yup.array()
    .of(Yup.string().url('次のように記述してください https://twitter.com/***'))
    .compact()
    .nullable(),
  lineUrls: Yup.array()
    .of(Yup.string().url('次のように記述してください https://line.me/***'))
    .compact()
    .nullable(),
  linkedinUrls: Yup.array()
    .of(
      Yup.string().url(
        '次のように記述してください https://www.linkedin.com/***',
      ),
    )
    .compact()
    .nullable(),
  officesDomesticBranchesNumber: Yup.number().nullable(),
  officesForeignBranchesNumber: Yup.number().nullable(),
  officesDomesticStoresNumber: Yup.number().nullable(),
  officesForeignStoresNumber: Yup.number().nullable(),
  officesDomesticFactoriesNumber: Yup.number().nullable(),
  officesForeignFactoriesNumber: Yup.number().nullable(),
});

interface Props {
  onClose: () => void;
}

export default ({onClose}: Props) => {
  const {preleadId} = usePreleadContext()

  const {data: {prelead = null} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  if (!prelead) return null;

  const [updatePrelead] = useUpdatePreleadMutation({
    onCompleted: onClose,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: prelead.name,
      address: prelead.address,
      zipCode: prelead.zipCode,
      telephoneNumber: prelead.telephoneNumber,
      faxNumber: prelead.faxNumber,
      webSite: prelead.webSite,
      representativePerson: prelead.representativePerson,
      employeeNumber: prelead.employeeNumber,
      capitalFund: prelead.capitalFund,
      mailAddress: prelead.mailAddress,
      officesNumber: prelead.officesNumber,
      establishedYearMonth: prelead.establishedYearMonth || '',
      telephoneNumbers:
        prelead.telephoneNumbers.map((telephoneNumber) => {
          return {name: telephoneNumber.name, telephoneNumber: telephoneNumber.telephoneNumber};
        }) || [],
      preleadFaxNumbers:
        prelead.preleadFaxNumbers.map((faxNumber) => {
          return {name: faxNumber.name, faxNumber: faxNumber.faxNumber};
        }) || [],
      mailAddresses:
        prelead.mailAddresses.map((mailAddress) => {
          return {name: mailAddress.name, mail: mailAddress.mail};
        }) || [],
      facebookUrls: prelead.facebookUrls || [],
      instagramUrls: prelead.instagramUrls || [],
      youtubeUrls: prelead.youtubeUrls || [],
      twitterUrls: prelead.twitterUrls || [],
      lineUrls: prelead.lineUrls || [],
      linkedinUrls: prelead.linkedinUrls || [],
      sales: prelead.sales,
      accountClosingMonth: prelead.accountClosingMonth,
      listingMarket: prelead.listingMarket,
      officesDomesticBranchesNumber: prelead.officesDomesticBranchesNumber,
      officesForeignBranchesNumber: prelead.officesForeignBranchesNumber,
      officesDomesticStoresNumber: prelead.officesDomesticStoresNumber,
      officesForeignStoresNumber: prelead.officesForeignStoresNumber,
      officesDomesticFactoriesNumber: prelead.officesDomesticFactoriesNumber,
      officesForeignFactoriesNumber: prelead.officesForeignFactoriesNumber,
    },
    validationSchema: validateSchema,
    onSubmit: (values) =>
      updatePrelead({
        variables: {uuid: prelead.uuid, attributes: values},
      }),
  });

  const Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    dirty,
  } = formik;

  return (
    <Container>
      <h2>企業情報</h2>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item validateStatus={errors.name && 'error'} help={errors.name}>
          <Label>
            <Home />
            社名
          </Label>
          <Input
            size="large"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item>
          <Label>
            <Zipcode />
            郵便番号
          </Label>
          <Input
            size="large"
            name="zipCode"
            value={values.zipCode}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item>
          <Label>
            <Location />
            住所
          </Label>
          <Input
            size="large"
            name="address"
            value={values.address}
            onChange={handleChange}
          />
        </Form.Item>
        <Label>
          <Tel />
          電話番号
        </Label>
        <FlexMultiInput>
          <Input size="large" value="代表電話" disabled />
          <Input
            size="large"
            name="telephoneNumber"
            value={values.telephoneNumber}
            onChange={handleChange}
          />
        </FlexMultiInput>
        {values.telephoneNumbers?.map((telephoneNumber, index) => (
          <FlexMultiInput
            key={index}
            validateStatus={
              errors.telephoneNumbers &&
              errors.telephoneNumbers[index] &&
              'error'
            }
            help={
              errors.telephoneNumbers &&
              errors.telephoneNumbers[index] &&
              'ラベルと電話番号を入力してください'
            }>
            <Input
              size="large"
              placeholder="ラベル"
              name={`telephoneNumbers[${index}].name`}
              value={telephoneNumber.name}
              onChange={handleChange}
            />
            <Input
              size="large"
              placeholder="電話番号"
              name={`telephoneNumbers[${index}].telephoneNumber`}
              value={telephoneNumber.telephoneNumber}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'telephoneNumbers',
                  values.telephoneNumbers.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexMultiInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('telephoneNumbers', [
                ...values.telephoneNumbers,
                {},
              ])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Label>
          <Fax />
          FAX番号
        </Label>
        <FlexMultiInput>
          <Input size="large" value="代表FAX" disabled />
          <Input
            size="large"
            name="faxNumber"
            value={values.faxNumber}
            onChange={handleChange}
          />
        </FlexMultiInput>
        {values.preleadFaxNumbers?.map((faxNumber, index) => (
          <FlexMultiInput
            key={index}
            validateStatus={
              errors.preleadFaxNumbers &&
              errors.preleadFaxNumbers[index] &&
              'error'
            }
            help={
              errors.preleadFaxNumbers &&
              errors.preleadFaxNumbers[index] &&
              'ラベルとFAX番号を入力してください'
            }>
            <Input
              size="large"
              placeholder="ラベル"
              name={`preleadFaxNumbers[${index}].name`}
              value={faxNumber.name}
              onChange={handleChange}
            />
            <Input
              size="large"
              placeholder="FAX番号"
              name={`preleadFaxNumbers[${index}].faxNumber`}
              value={faxNumber.faxNumber}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'preleadFaxNumbers',
                  values.preleadFaxNumbers.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexMultiInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('preleadFaxNumbers', [
                ...values.preleadFaxNumbers,
                {},
              ])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Label>
          <Mail />
          メールアドレス
        </Label>
        <FlexMultiInput>
          <Input size="large" value="代表メール" disabled />
          <Input
            size="large"
            name="mailAddress"
            value={values.mailAddress}
            onChange={handleChange}
          />
        </FlexMultiInput>
        {values.mailAddresses?.map((mailAddress, index) => (
          <FlexMultiInput
            key={index}
            validateStatus={
              errors.mailAddresses && errors.mailAddresses[index] && 'error'
            }
            help={
              errors.mailAddresses &&
              errors.mailAddresses[index] &&
              'ラベルとメールアドレスを入力してください'
            }>
            <Input
              size="large"
              placeholder="ラベル"
              name={`mailAddresses[${index}].name`}
              value={mailAddress.name}
              onChange={handleChange}
            />
            <Input
              size="large"
              placeholder="メールアドレス"
              name={`mailAddresses[${index}].mail`}
              value={mailAddress.mail}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'mailAddresses',
                  values.mailAddresses.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexMultiInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('mailAddresses', [...values.mailAddresses, {}])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Form.Item>
          <Label>
            <World />
            ウェブサイト
          </Label>
          <Input
            size="large"
            name="webSite"
            value={values.webSite}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item>
          <Label>
            <User />
            代表者名
          </Label>
          <Input
            size="large"
            name="representativePerson"
            value={values.representativePerson}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item>
          <Label>
            <Users />
            従業員数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="employeeNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue('employeeNumber', Number(e.target.rawValue))
            }
            onBlur={handleBlur}
            value={values.employeeNumber}
          />
        </Form.Item>
        <Form.Item>
          <Label>
            <Parliament />
            資本金
          </Label>
          <Cleave
            options={{numeral: true}}
            name="capitalFund"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue('capitalFund', Number(e.target.rawValue))
            }
            onBlur={handleBlur}
            value={values.capitalFund}
          />
        </Form.Item>
        <Form.Item>
          <Label>
            <Offices />
            事務所数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="officesNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue('officesNumber', Number(e.target.rawValue))
            }
            onBlur={handleBlur}
            value={values.officesNumber}
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          validateStatus={errors.officesDomesticBranchesNumber && 'error'}
          help={errors.officesDomesticBranchesNumber}>
          <Label>
            <BuildingDomestic />
            国内支店・拠点数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="officesDomesticBranchesNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue(
                'officesDomesticBranchesNumber',
                Number(e.target.rawValue),
              )
            }
            onBlur={handleBlur}
            value={values.officesDomesticBranchesNumber}
          />
        </Form.Item>
        <Form.Item
          validateStatus={errors.officesForeignBranchesNumber && 'error'}
          help={errors.officesForeignBranchesNumber}>
          <Label>
            <BuildingOverseas />
            海外支店・拠点数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="officesForeignBranchesNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue(
                'officesForeignBranchesNumber',
                Number(e.target.rawValue),
              )
            }
            onBlur={handleBlur}
            value={values.officesForeignBranchesNumber}
          />
        </Form.Item>
        <Form.Item
          validateStatus={errors.officesDomesticStoresNumber && 'error'}
          help={errors.officesDomesticStoresNumber}>
          <Label>
            <Store />
            国内店舗数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="officesDomesticStoresNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue(
                'officesDomesticStoresNumber',
                Number(e.target.rawValue),
              )
            }
            onBlur={handleBlur}
            value={values.officesDomesticStoresNumber}
          />
        </Form.Item>
        <Form.Item
          validateStatus={errors.officesForeignStoresNumber && 'error'}
          help={errors.officesForeignStoresNumber}>
          <Label>
            <StoreOverseas />
            海外店舗数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="officesForeignStoresNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue(
                'officesForeignStoresNumber',
                Number(e.target.rawValue),
              )
            }
            onBlur={handleBlur}
            value={values.officesForeignStoresNumber}
          />
        </Form.Item>
        <Form.Item
          validateStatus={errors.officesDomesticFactoriesNumber && 'error'}
          help={errors.officesDomesticFactoriesNumber}>
          <Label>
            <Factory />
            国内工場数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="officesDomesticFactoriesNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue(
                'officesDomesticFactoriesNumber',
                Number(e.target.rawValue),
              )
            }
            onBlur={handleBlur}
            value={values.officesDomesticFactoriesNumber}
          />
        </Form.Item>
        <Form.Item
          validateStatus={errors.officesForeignFactoriesNumber && 'error'}
          help={errors.officesForeignFactoriesNumber}>
          <Label>
            <FactoryOverseas />
            海外工場数
          </Label>
          <Cleave
            options={{numeral: true}}
            name="officesForeignFactoriesNumber"
            className="ant-input ant-input-lg"
            onChange={(e) =>
              setFieldValue(
                'officesForeignFactoriesNumber',
                Number(e.target.rawValue),
              )
            }
            onBlur={handleBlur}
            value={values.officesForeignFactoriesNumber}
          />
        </Form.Item>
        <Form.Item>
          <Label>
            <Calendar />
            決算月
          </Label>
          <Select
            value={values.accountClosingMonth}
            onChange={(e) => setFieldValue('accountClosingMonth', e)}>
            {Months.map((month) => (
              <Select.Option key={month} value={month}>
                {month ? `${month}月` : null}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Label>
            <Chart />
            上場区分
          </Label>
          <Select
            value={values.listingMarket}
            onChange={(e) => setFieldValue('listingMarket', e)}>
            {MARKETS.map((market) => (
              <Select.Option key={market.value} value={market.value}>
                {market.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Label>
            <BarChart />
            売上
          </Label>
          <Cleave
            options={{numeral: true}}
            name="sales"
            className="ant-input ant-input-lg"
            onChange={(e) => setFieldValue('sales', Number(e.target.rawValue))}
            onBlur={handleBlur}
            value={values.sales}
          />
        </Form.Item>
        <Form.Item
          validateStatus={errors.establishedYearMonth && 'error'}
          help={errors.establishedYearMonth}>
          <Label>
            <Flag />
            設立年月
          </Label>
          <Input
            name="establishedYearMonth"
            className="ant-input ant-input-lg"
            onChange={handleChange}
            value={values.establishedYearMonth}
            placeholder="1990-01"
            maxLength={7}
          />
        </Form.Item>
        <Label>
          <Facebook />
          Facebook
        </Label>
        {values.facebookUrls?.map((facebookUrl, index) => (
          <FlexInput
            key={index}
            validateStatus={
              errors.facebookUrls && errors.facebookUrls[index] && 'error'
            }
            help={errors.facebookUrls && errors.facebookUrls[index]}>
            <Input
              size="large"
              name={`facebookUrls[${index}]`}
              value={facebookUrl}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'facebookUrls',
                  values.facebookUrls.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('facebookUrls', [...values.facebookUrls, null])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Label>
          <Twitter />
          Twitter
        </Label>
        {values.twitterUrls?.map((twitterUrl, index) => (
          <FlexInput
            key={index}
            validateStatus={
              errors.twitterUrls && errors.twitterUrls[index] && 'error'
            }
            help={errors.twitterUrls && errors.twitterUrls[index]}>
            <Input
              size="large"
              name={`twitterUrls[${index}]`}
              value={twitterUrl}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'twitterUrls',
                  values.twitterUrls.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('twitterUrls', [...values.twitterUrls, null])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Label>
          <Line />
          Line
        </Label>
        {values.lineUrls?.map((lineUrl, index) => (
          <FlexInput
            key={index}
            validateStatus={
              errors.lineUrls && errors.lineUrls[index] && 'error'
            }
            help={errors.lineUrls && errors.lineUrls[index]}>
            <Input
              size="large"
              name={`lineUrls[${index}]`}
              value={lineUrl}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'lineUrls',
                  values.lineUrls.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('lineUrls', [...values.lineUrls, null])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Label>
          <YouTube />
          YouTube
        </Label>
        {values.youtubeUrls?.map((youtubeUrl, index) => (
          <FlexInput
            key={index}
            validateStatus={
              errors.youtubeUrls && errors.youtubeUrls[index] && 'error'
            }
            help={errors.youtubeUrls && errors.youtubeUrls[index]}>
            <Input
              size="large"
              name={`youtubeUrls[${index}]`}
              value={youtubeUrl}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'youtubeUrls',
                  values.youtubeUrls.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('youtubeUrls', [...values.youtubeUrls, null])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Label>
          <Instagram />
          Instagram
        </Label>
        {values.instagramUrls?.map((instagramUrl, index) => (
          <FlexInput
            key={index}
            validateStatus={
              errors.instagramUrls && errors.instagramUrls[index] && 'error'
            }
            help={errors.instagramUrls && errors.instagramUrls[index]}>
            <Input
              size="large"
              name={`instagramUrls[${index}]`}
              value={instagramUrl}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'instagramUrls',
                  values.instagramUrls.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('instagramUrls', [...values.instagramUrls, null])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Label>
          <LinkedIn />
          Linkedin
        </Label>
        {values.linkedinUrls?.map((linkedinUrl, index) => (
          <FlexInput
            key={index}
            validateStatus={
              errors.linkedinUrls && errors.linkedinUrls[index] && 'error'
            }
            help={errors.linkedinUrls && errors.linkedinUrls[index]}>
            <Input
              size="large"
              name={`linkedinUrls[${index}]`}
              value={linkedinUrl}
              onChange={handleChange}
            />
            <span
              onClick={() =>
                setFieldValue(
                  'linkedinUrls',
                  values.linkedinUrls.filter((_, i) => i !== index),
                )
              }>
              <MinusCircle />
            </span>
          </FlexInput>
        ))}
        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={() =>
              setFieldValue('linkedinUrls', [...values.linkedinUrls, null])
            }>
            + 追加
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{marginRight: '8px'}}
            disabled={dirty && !isValid}>
            保存
          </Button>
          <Button htmlType="button" onClick={onClose}>
            キャンセル
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  height: 100v;
  overflow: scroll;

  > h2 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 12px;
    color: #495058;
  }
`;

const Label = styled.label`
  color: #bdc4cb;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  svg {
    margin-right: 4px;
    top: -1px;
    position: relative;
    height: 22px;

    path {
      stroke: #bdc4cb;
    }
  }
`;

const FlexMultiInput = styled(Form.Item)`
  &&& {
    margin-bottom: 8px;

    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;

      input:first-of-type {
        width: 100px;
        margin-right: 8px;
      }

      input:nth-of-type(2) {
        flex: 1;
      }

      svg {
        margin-left: 8px;
        cursor: pointer;
        stroke: #bdc4cb;

        &:hover {
          stroke: #c90e19;
        }
      }
    }
  }
`;

const FlexInput = styled(Form.Item)`
  &&& {
    margin-bottom: 8px;

    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;

      input:first-of-type {
        flex: 1;
        margin-right: 8px;
      }

      svg {
        margin-left: 8px;
        cursor: pointer;
        stroke: #bdc4cb;

        &:hover {
          stroke: #c90e19;
        }
      }
    }
  }
`;
