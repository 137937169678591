import React from 'react';
import {XMarkIcon} from '@heroicons/react/24/outline';

interface Props {
  onClose: () => void;
}

const Header = ({onClose}: Props) => {
  return (
    <div className="h-12 flex items-center px-6 gap-4 bg-white">
      <h2 className="m-0 text-lg flex-1">名寄せ実行</h2>
      <button
        className="p-0 bg-transparent border-none cursor-pointer w-6 h-6"
        onClick={() => onClose()}>
        <XMarkIcon className="h-6 w-6 text-c-lighter" />
      </button>
    </div>
  );
};

export default Header;
