import React from 'react';
import {useTable} from 'react-table';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';
import Cell from './Cell';
import {useApproachesQuery, Approach} from 'api';
import useSearchParams from './useSearchParams';

export default () => {
  const {searchParams} = useSearchParams();
  const {page, perPage, setQuery} = useSearchParams();
  const {data: {approaches: {approaches = [], pagination = {}} = {}} = {}} =
    useApproachesQuery({
      variables: {
        page,
        perPage,
        search: searchParams,
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns: any = React.useMemo(
    () => [
      {
        id: 'approachType',
        Header: '',
        Cell: Cell.ApproachType,
        style: {
          width: '64px',
        },
      },
      {
        id: 'target',
        Header: '対象',
        Cell: Cell.Target,
      },
      {
        id: 'action',
        Header: 'アクション名',
        Cell: Cell.Action,
      },
      {
        id: 'priority',
        Header: '優先度',
        Cell: Cell.Priority,
        style: {
          width: '80px',
        },
      },
      {
        id: 'due',
        Header: '日付',
        Cell: Cell.DueDate,
        style: {
          width: '130px',
        },
      },
      {
        id: 'lastProspectActivity',
        Header: '履歴',
        Cell: Cell.LastProspectActivity,
        style: {
          width: '160px',
        },
      },
      {
        id: 'asignee',
        Header: 'アプローチ担当者',
        Cell: Cell.Assignee,
        style: {
          width: '160px',
        },
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<Approach>({columns, data: approaches || []});

  return (
    <Container>
      <ContentsTable {...getTableProps()} cellSpacing="0" cellPadding="0">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // className: column.className,
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </ContentsTable>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={perPage}
        onChange={(page) => setQuery({page})}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentsTable = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 0.5rem 1rem;
    margin: 0px;
  }

  thead {
    tr {
      th {
        padding: 8px 1rem;
        font-size: 10px;
        color: #899098;
        text-align: left;
        border-bottom: solid 1px #e1e6eb;

        &:first-child {
          padding: 3px 1rem 3px 0;
        }
      }
    }
  }

  tbody {
    font-size: 13px;

    tr {
      box-sizing: border-box;
      background: #fff;

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: solid 1px #e1e6eb;
      }
      td:first-child {
        padding: 0;
      }
    }
  }
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: #495058;
      background: #e2e6ea;
      border-radius: 100px;
    }
  }
`;
