import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../../components/Table/types';
import {
  useProspectReportProspectPoolUserDailyCallReportsQuery,
  useProspectReportProspectPoolUserDailyCallSumReportQuery,
} from 'api';
import {StringParam, useQueryParams} from 'use-query-params';
import useMonthHeaders from '../../../hooks/useMonthHeaders';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {data: {prospectReportProspectPoolUserDailyCallReports = []} = {}} =
    useProspectReportProspectPoolUserDailyCallReportsQuery({
      variables: {month: query.month},
      skip: !query.month,
      fetchPolicy: 'cache-and-network',
    });

  const {data: {prospectReportProspectPoolUserDailyCallSumReport = null} = {}} =
    useProspectReportProspectPoolUserDailyCallSumReportQuery({
      variables: {month: query.month},
      skip: !query.month,
      fetchPolicy: 'cache-and-network',
    });

  const headers: ReportTableHeader[] = useMonthHeaders(query.month);
  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const callData = () => {
    const allReports = [
      prospectReportProspectPoolUserDailyCallSumReport,
      ...prospectReportProspectPoolUserDailyCallReports,
    ].filter(Boolean);
    return allReports.map((report) => {
      const reportValuesSum = sum(report.values);
      return {
        label: report.name,
        values: [reportValuesSum, ...report.values],
        color: report.color,
        subRows: report.prospectTelStatuses?.map((subReport) => {
          const subReportValuesSum = sum(subReport.values);
          return {
            label: subReport?.name,
            values: [subReportValuesSum, ...subReport.values],
          };
        }),
      };
    });
  };

  const data: ReportTableData[] = [...callData()];

  return {
    data,
    headers,
  };
};

export default useTableData;
