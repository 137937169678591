import React from 'react';
import {ActivityAttachment} from 'api';
import Pdf from './Pdf';
import Audio from './Audio';

interface Props {
  attachment: ActivityAttachment;
}

const attachmentComponent: {[key: string]: any} = {
  'application/pdf': Pdf,
  'audio/mpeg': Audio,
};

export default ({attachment}: Props) => {
  const Attachment = attachmentComponent[attachment.fileContentType];

  return <Attachment attachment={attachment} />;
};
