import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
// @ts-ignore
import Arrow from './arrow.svg';

type Props = {
  to: string;
  className?: string;
  children?: React.ReactNode;
};

export default ({children, ...props}: Props) => {
  return (
    <StyledLink {...props}>
      <Arrow />
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  &&& {
    color: #0d183b;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }
`;
