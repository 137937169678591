import gql from 'graphql-tag';

export default gql`
  fragment sensesSettingFragment on SensesSetting {
    id
    clientId
    isActive
    isSensesPartner
    apiKey
    sensesDealTypeId
    sensesPhaseId
    sensesProductId
    sensesChannelId
    sensesProbabilityId
    sensesUserId
    sensesDealCustomItemId
    sensesMailSendPurposeId
    sensesCommentPurposeId
    sensesMailCommentPurposeId
    sensesTelCommentPurposeId
  }
`;
