import gql from 'graphql-tag';

export default gql`
  fragment workflowTemplateStepFragment on WorkflowTemplateStep {
    id
    uuid
    name
    memo
    startDate
    startDay
    startHour
    daysOfWeek
    position
    isImmediately
    stepType
    createdAt
    updatedAt
    workflowTemplateWorkflowId
  }
`;
