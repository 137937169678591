import React from 'react';
import {useParams} from 'react-router-dom';
import {usePoolQuery, ProspectTag} from 'api';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroupColor from './CheckboxGroupColor';

const TagFilter = () => {
  const {poolId} = useParams<{poolId: string}>();
  const {query, setQuery} = useSearchParams();

  const {data: {pool: {prospectTags = []} = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
    fetchPolicy: 'cache-and-network',
  });

  const filteredProspectTags = (): ProspectTag[] => {
    return prospectTags?.filter((tag) => !query.tagIds?.includes(tag.id));
  };

  return (
    <>
      <Header title="タグ（含まない）" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroupColor
            filter
            options={filteredProspectTags().map((tag) => ({
              text: tag.name,
              value: tag.id,
              keyword: tag.name,
              color: tag.color,
            }))}
            values={query.excludedTagIds || []}
            onChange={(excludedTagIds) => setQuery({...query, excludedTagIds})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, excludedTagIds: []})} />
    </>
  );
};

export default TagFilter;
