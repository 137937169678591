import React from 'react';
import styled from 'styled-components';
import Menu from '../../Menu';
import {ZoomPhone} from 'components/Ui/Icon';
import {
  useCurrentClientQuery,
  useRevokeZoomPhoneIntegrationMutation,
} from 'api';
import CircleCloseIcon from '../CircleCloseIcon';
import PhoneNumberTable from './PhoneNumberTable';

export default () => {
  const {data: {currentClient: {zoomPhoneActive} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  const [revoke, {loading}] = useRevokeZoomPhoneIntegrationMutation({
    refetchQueries: ['currentClient'],
  });

  return (
    <Body>
      <Menu />
      <Content>
        <TitleSection>
          <ZoomPhone />
          <TitleWrapper>
            <Title>Zoom Phone</Title>
            <TitleNote>Zoom Phone連携の設定を行います。</TitleNote>
          </TitleWrapper>
          {zoomPhoneActive ? (
            <UnavailableButton
              onClick={() =>
                confirm('連携を解除してよろしいですか？') && revoke()
              }
              disabled={loading}>
              <CircleCloseIcon />
              連携を解除する
            </UnavailableButton>
          ) : (
            <AvailableButton href="/integrations/zoom/redirect">
              連携する
            </AvailableButton>
          )}
        </TitleSection>
        {zoomPhoneActive && <PhoneNumberTable />}
      </Content>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Content = styled.div`
  background: #fff;
  width: calc(100vw - 290px);
  height: fit-content;
  min-height: calc(100vh - 145px);
  padding: 45px 45px;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;

  padding-bottom: 22px;
`;

const TitleWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
`;

const TitleNote = styled.div`
  margin-top: 5px;
  font-size: 14px;
`;

const AvailableButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #169e34;
  color: #fff;
  height: 39px;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  margin: 0 0 0 auto;
  cursor: pointer;
`;

const UnavailableButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0 0 0 auto;
  background-color: transparent;

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #69717a;
  }

  cursor: pointer;
`;
