import gql from 'graphql-tag';

export default gql`
  fragment mailFormVersionUrlClickActivityFragment on MailFormVersionUrlClickActivity {
    mailFormVersionUrl {
      url
    }
    campaign {
      name
    }
  }
`;
