import React from 'react';
import moment from 'moment';
import {Approach} from 'api';

interface Props {
  approach: Approach;
}

const Status = ({approach}: Props) => {
  if (approach.status === 'open') {
    const isExpired = moment().isAfter(moment(approach.dueDate), 'day');
    if (isExpired) {
      return (
        <div className="flex items-center rounded-sm text-[#EB5757] bg-[#FDECEC] h-5 text-xs px-2">
          期限切れ
        </div>
      );
    } else {
      return (
        <div className="flex items-center rounded-sm text-[#0DC8F1] bg-[#DCF9FF] h-5 text-xs px-2">
          開始
        </div>
      );
    }
  }

  if (approach.status === 'completed') {
    return (
      <div className="flex items-center rounded-sm text-[#4FCE82] bg-[#DDF4E6] h-5 text-xs px-2">
        完了
      </div>
    );
  }

  if (approach.status === 'canceled') {
    return (
      <div className="flex items-center rounded-sm text-[#899098] bg-[#EAEEF2] h-5 text-xs px-2">
        キャンセル
      </div>
    );
  }

  return null;
};

export default Status;
