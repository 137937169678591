import React from 'react';
import {Link, useParams} from 'react-router-dom';
import useProjectPath from 'hooks/useProjectPath';
import {
  usePreleadProjectQuery,
  useProjectQuery,
  useProspectPoolsByPreleadProjectQuery,
} from 'api';
import usePreleadProject from 'hooks/usePreleadProject';
import usePreleadTasks from 'hooks/usePreleadTasks';

const Tab = ({
  to,
  active,
  title,
  count,
}: {
  to: string;
  active: boolean;
  title: string;
  count?: number;
}) => (
  <div
    className={`flex items-center justify-center ${
      active ? 'border-b-2 border-c-primary' : ''
    }`}>
    <Link
      className={`flex items-center gap-1 text-xs font-bold h-9 ${
        active ? 'text-c-primary' : 'text-c-lighter'
      }`}
      to={to}>
      {title}
      {count ? (
        <span
          className={`ml-1 px-1 py-[2px] leading-tight rounded-full font-bold text-[8px] text-center ${
            active ? 'bg-[#E1F1FF] text-c-primary' : 'bg-c-bg text-c-light'
          } `}>
          {count}
        </span>
      ) : (
        ''
      )}
    </Link>
  </div>
);

export const TabComponent = () => {
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();

  const {
    timeLineActivities,
    timeLineMailActivities,
    timeLineTelActivities,
    timeLineLetterActivities,
  } = usePreleadProject(preleadProjectId);

  const {
    data: {
      preleadProject: {
        prelead: {
          activities = [],
          mailActivities = [],
          telActivities = [],
          letterActivities = [],
        } = {},
      } = {},
    } = {},
  } = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    skip: !preleadProjectId,
  });

  const {data: {prospectPoolsByPreleadProject = []} = {}} =
    useProspectPoolsByPreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    });

  const {createPath} = useProjectPath();

  const {
    data: {project: {client: {currentPreleadContract = {}} = {}} = {}} = {},
  } = useProjectQuery({
    variables: {uuid: projectId},
  });

  const {preleadTasksAccessible} = usePreleadTasks();

  return (
    <div className="flex justify-evenly border-b border-c-border">
      <Tab
        to={createPath(
          `projects/${projectId}/preleads/${preleadProjectId}/timeline${location.search}`,
        )}
        active={location.pathname.endsWith('/timeline')}
        title="タイムライン"
        count={timeLineActivities(activities)?.length}
      />
      <Tab
        to={createPath(
          `projects/${projectId}/preleads/${preleadProjectId}${location.search}`,
        )}
        active={location.pathname.split('/').pop() === preleadProjectId}
        title="詳細"
      />
      {currentPreleadContract?.canUseLead && (
        <Tab
          to={createPath(
            `projects/${projectId}/preleads/${preleadProjectId}/leads${location.search}`,
          )}
          active={location.pathname.endsWith('/leads')}
          title="リード"
          count={prospectPoolsByPreleadProject?.length}
        />
      )}
      <Tab
        to={createPath(
          `projects/${projectId}/preleads/${preleadProjectId}/tel${location.search}`,
        )}
        active={location.pathname.endsWith('/tel')}
        title="Tel"
        count={timeLineTelActivities(telActivities)?.length}
      />
      <Tab
        to={createPath(
          `projects/${projectId}/preleads/${preleadProjectId}/mail${location.search}`,
        )}
        active={
          location.pathname.endsWith('/mail') ||
          location.pathname.endsWith('/mail/new')
        }
        title="メール"
        count={timeLineMailActivities(mailActivities)?.length}
      />
      <Tab
        to={createPath(
          `projects/${projectId}/preleads/${preleadProjectId}/letter${location.search}`,
        )}
        active={location.pathname.endsWith('/letter')}
        title="手紙"
        count={timeLineLetterActivities(letterActivities)?.length}
      />
      <Tab
        to={createPath(
          `projects/${projectId}/preleads/${preleadProjectId}/task${location.search}`,
        )}
        active={location.pathname.endsWith('/task')}
        title="ToDo"
        count={preleadTasksAccessible?.length}
      />
    </div>
  );
};
