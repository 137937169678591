import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Table from './Table';
import {Form} from 'api';
import Post from './postId';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  return (
    <div className="flex-1">
      <Table />
      <Switch>
        <Route path="/form/forms/:formId/posts/:postId">
          <Post form={form} />
        </Route>
      </Switch>
    </div>
  );
};
