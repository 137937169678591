import {Project} from 'api';
import moment from 'moment';
import React from 'react';
import {Row} from 'react-table';

interface Props {
  row: Row<Project>;
}
export default ({row}: Props) => {
  const project = row.original;

  return (
    <>
      {project.mailCampaignSentAt && (
        <div className="text-c-light">
          <div>{moment(project.mailCampaignSentAt).format('YYYY/MM/DD')}</div>
          <div>{moment(project.mailCampaignSentAt).format('HH:mm')}</div>
        </div>
      )}
    </>
  );
};
