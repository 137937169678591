import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {PlusSquare} from 'components/Ui/Icon';
import {usePreleadProjectQuery} from 'api';
import {Popover} from 'components/antd';
import Select from './Select';
import {getTagFontColor} from 'helpers/tagColors';
import useClientUser from 'hooks/useClientUser';
import usePreleadTags from 'hooks/usePreleadTags';

export default () => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const {isMember} = useClientUser();
  const {filterPreleadTagsAccessible} = usePreleadTags();

  const {data: {preleadProject: {prelead = null} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    });

  return (
    <>
      {prelead && (
        <Container>
          {isMember && (
            <AddTag>
              <Popover
                content={() => <Select prelead={prelead} />}
                trigger="click"
                visible={isPopoverVisible}
                onVisibleChange={(visible) => setPopoverVisible(visible)}
                placement="rightTop">
                <div>
                  <PlusSquare />
                </div>
              </Popover>
            </AddTag>
          )}
          <PreleadTagsWrapper>
            {filterPreleadTagsAccessible(prelead.preleadTags)?.map((tag) => (
              <PreleadTag key={tag.id} color={tag.color}>
                {tag.name}
              </PreleadTag>
            ))}
          </PreleadTagsWrapper>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AddTag = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
`;

const PreleadTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PreleadTag = styled.span<{color?: string}>`
  margin-right: 6px;
  display: inline-block;
  padding: 1px 8px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;

  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  color: ${({color}) => getTagFontColor(color)};
`;
