import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import icon from './check.svg';

type Props = {
  style?: any;
  className?: string;
};
export default ({...props}: Props) => {
  return <Icon {...props} />;
};

const Icon = styled(icon)`
  width: 40px;
  height: 40px;
`;
