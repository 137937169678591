import React from 'react';
import SeasonFilter from './SeasonFilter';
import LeadSourceFilter from './LeadSourceFilter';
import TagFilter from './TagFilter';
import UserFilter from './UserFilter';

export default () => {
  return (
    <div className={`flex gap-2 items-center h-14`}>
      <SeasonFilter />
      <LeadSourceFilter />
      <TagFilter />
      <UserFilter />
    </div>
  );
};
