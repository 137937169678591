import React from 'react';
import {AutomaticMailSend, useUpdateStageProspectPoolMutation} from 'api';
import {Row} from 'react-table';
import {Link, useParams, useHistory, Route} from 'react-router-dom';
import Prospect from 'components/Prospect';

interface Props {
  row: Row<AutomaticMailSend>;
}
export default ({row}: Props) => {
  const automaticMailSend = row.original;
  const prospectPool = row.original.prospectPool;
  const history = useHistory();
  const [updateStage] = useUpdateStageProspectPoolMutation();
  const {poolId, workflowId} = useParams<{
    poolId: string;
    workflowId: string;
  }>();

  return (
    <div>
      <Link
        to={{
          pathname: `/pools/${poolId}/workflows/${workflowId}/automatic_mail_sends/${automaticMailSend.uuid}/prospect/timeline`,
          search: location.search,
        }}>
        {prospectPool.prospect.lastName} {prospectPool.prospect.firstName}
      </Link>
      <Route
        path={`/pools/:poolId/workflows/:workflowId/automatic_mail_sends/${automaticMailSend.uuid}/prospect`}>
        <Prospect
          prospectId={prospectPool.uuid}
          poolId={poolId}
          updateStage={updateStage}
          onClose={() =>
            history.push(
              `/pools/${poolId}/workflows/${workflowId}/automatic_mail_sends${location.search}`,
            )
          }
        />
      </Route>
    </div>
  );
};
