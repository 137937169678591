import React, {HTMLAttributes} from 'react';
import {Handle as HandleIcon} from 'components/Ui/Icon';
import {useDraggable} from '@dnd-kit/core';

export type Props = HTMLAttributes<HTMLDivElement> & {
  id: string;
};

const Handle = ({id}: Props) => {
  const {attributes, listeners, setNodeRef, isDragging} = useDraggable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      className="absolute left-0 flex items-center"
      style={{cursor: isDragging ? 'grabbing' : 'grab'}}
      {...listeners}
      {...attributes}>
      <HandleIcon />
    </div>
  );
};

export default Handle;
