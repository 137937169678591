import React from 'react';
import moment from 'moment';
import {useForm} from 'react-hook-form';
import telCategoryColor from 'helpers/telCategoryColor';
import {ChevronDownIcon, CheckIcon} from '@heroicons/react/20/solid';
import {Menu, Transition, Listbox} from '@headlessui/react';
import {Quill, QuillWrapper, StatusEclipse} from 'components/Ui';
import {
  ApproachPrelead,
  Approach,
  useProjectQuery,
  useCreateTelCommentActivityMutation,
  ApproachPreleadProject,
} from 'api';
import ReceptionNgModal from '../../Tel/ReceptionNgModal';
import ContactNgModal from '../../Tel/ContactNgModal';
import CallNgModal from '../../Tel/CallNgModal';
import useApproachModalContext from './useApproachModalContext';
import ProjectSelect from './ProjectSelect';

interface Props {
  approach: Approach;
  onCompleted: () => void;
}

const TelForm = ({approach, onCompleted}: Props) => {
  const {preleadProject, project} = useApproachModalContext();

  const [ngCallModalVisible, setCallNgModalVisible] = React.useState(false);
  const [callNgReason, setCallNgReason] = React.useState(null);
  const [ngReceptionModalVisible, setReceptionNgModalVisible] =
    React.useState(false);
  const [receptionNgReason, setReceptionNgReason] = React.useState(null);
  const [ngContactModalVisible, setContactNgModalVisible] =
    React.useState(false);
  const [contactNgReason, setContactNgReason] = React.useState(null);

  const {data: {project: {telStatuses = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: project?.uuid},
    skip: !project,
  });

  const dateTimeFormat = 'YYYY-MM-DD HH:mm';

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: {isSubmitted},
  } = useForm({
    defaultValues: {
      recordedAt: moment().startOf('hour').format(dateTimeFormat).toString(),
      body: '',
      bodyPlainText: '',
      isCompleted: false,
      telStatusId: null,
    },
    mode: 'onChange',
  });

  const [create] = useCreateTelCommentActivityMutation({
    refetchQueries: ['approaches', 'approachCounts'],
    onCompleted: onCompleted,
  });

  const {isCompleted, telStatusId, body} = watch();

  const onSubmit = (values: any) => {
    const {isCompleted, body, bodyPlainText, recordedAt} = values;

    create({
      variables: {
        uuid: preleadProject?.uuid,
        approachUuid: approach?.uuid,
        isCompleted: isCompleted,
        telCommentInput: {
          body: body === `<p><br></p>` ? '' : body,
          bodyPlainText: bodyPlainText,
          recordedAt: recordedAt,
        },
        telStatusChangeInput: {
          clientTelStatusId: telStatusId,
          projectCallNgReasonId: callNgReason?.id,
          projectReceptionNgReasonId: receptionNgReason?.id,
          projectContactNgReasonId: contactNgReason?.id,
        },
      },
    });
  };

  const telStatus = React.useMemo(
    () =>
      telStatusId
        ? telStatuses.find((telStatus) => telStatus.id === telStatusId)
        : null,
    [telStatusId, telStatuses],
  );

  React.useEffect(() => {
    if (!telStatus) return;

    if (telStatus?.category === 'call_ng') {
      setCallNgModalVisible(true);
    } else if (telStatus?.category === 'reception_ng') {
      setReceptionNgModalVisible(true);
    } else if (telStatus?.category === 'contact_ng') {
      setContactNgModalVisible(true);
    }

    setCallNgReason(null);
    setReceptionNgReason(null);
    setContactNgReason(null);
  }, [telStatusId, telStatuses]);

  return (
    <form className="p-6 flex flex-col gap-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-1">
        <label className="text-c-light">アプローチ名</label>
        <span className="text-base">
          {approach?.approachableType === 'Approach::Prelead' &&
            (approach?.approachable as ApproachPrelead)?.approachGroupPrelead
              ?.title}
          {approach?.approachableType === 'Approach::PreleadProject' &&
            (approach?.approachable as ApproachPreleadProject)
              ?.approachGroupPreleadProject?.title}
        </span>
      </div>
      <div className="w-full flex flex-col gap-1">
        <label className="text-c-light">
          <span className="text-red-500">＊&nbsp;</span>営業リスト
        </label>
        <ProjectSelect />
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex flex-col gap-1">
          <label className="text-c-light">TELステータス</label>
          <Listbox
            value={telStatusId}
            disabled={!project}
            onChange={(v) => setValue('telStatusId', v)}>
            <div className="relative flex-1">
              <Listbox.Button className="form-select border-c-border rounded text-sm w-full disabled:bg-c-bg">
                <span className="flex items-center gap-2 flex-1">
                  {telStatus ? (
                    <>
                      <StatusEclipse
                        color={telCategoryColor(telStatus.category)}
                      />
                      <span className="flex-1 truncate text-left">
                        {telStatus?.name}
                        {callNgReason && `  |  ${callNgReason.reason}`}
                        {receptionNgReason &&
                          `  |  ${receptionNgReason.reason}`}
                        {contactNgReason && `  |  ${contactNgReason.reason}`}
                      </span>
                    </>
                  ) : (
                    '未選択'
                  )}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5">
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </Listbox.Button>
              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="z-10 absolute mt-1 max-h-60 text-sm w-full overflow-auto rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {telStatuses?.map((status) => (
                    <Listbox.Option
                      key={status.id}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 px-3 ${
                          active ? 'bg-gray-100' : 'bg-white'
                        }`
                      }
                      value={status.id}>
                      <StatusEclipse
                        color={telCategoryColor(status.category)}
                      />
                      &nbsp; {status.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-c-light">日時</label>
          <input
            type="datetime-local"
            {...register('recordedAt')}
            className="form-input border-c-border rounded-sm text-c-light text-sm focus:outline-none"
          />
        </div>
      </div>
      <div>
        <QuillWrapper
          style={{
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: 0,
          }}
          className="
            mb-8
            [&_.ql-toolbar]:absolute
            [&_.ql-toolbar]:w-full
            [&_.ql-toolbar]:bottom-0
            [&_.ql-toolbar]:left-0
            [&_.ql-toolbar]:translate-y-full
            [&_.ql-editor]:max-h-80
        ">
          <Quill
            placeholder="コメント"
            theme="snow"
            onChange={(content, delta, source, editor) => {
              setValue('body', content);
              setValue('bodyPlainText', editor.getText());
            }}
            modules={{
              toolbar: [
                {header: [1, 2, 3, false]},
                'bold',
                'italic',
                'underline',
                'code-block',
                'link',
              ],
            }}
            bounds={'#quill-container'}
          />
          <div id="quill-container" />
        </QuillWrapper>
      </div>
      <div className="flex items-center justify-end">
        <button
          type="submit"
          disabled={!preleadProject || !(body || telStatusId) || isSubmitted}
          className={`bg-[#61CF8D] text-white text-base font-medium py-2 w-72 flex items-center justify-center border-none cursor-pointer disabled:bg-c-bg disabled:text-c-light ${
            approach?.status === 'open' ? 'rounded-l' : 'rounded'
          }`}>
          {isCompleted
            ? 'アプローチを完了して登録'
            : 'アプローチを完了しないでログのみ登録'}
        </button>
        {approach?.status === 'open' && (
          <Menu as="div" className="relative inline-block text-left z-30">
            <Menu.Button
              disabled={!preleadProject || !(body || telStatusId)}
              className="bg-[#61CF8D] text-white text-base font-medium py-2 w-8 border-l border-white rounded-r cursor-pointer disabled:bg-c-bg disabled:text-c-light">
              <ChevronDownIcon className="w-4 h-4 relative top-1" />
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute right-0 w-80 origin-top-right rounded-b bg-white shadow-lg focus:outline-none z-30">
                <Menu.Item>
                  <div
                    className={`hover:bg-c-bg py-4 pl-2 pr-4 w-full flex items-center gap-2 ${
                      isCompleted ? 'bg-white' : 'bg-c-bg'
                    }`}>
                    <CheckIcon
                      className={`w-4 h-4 ${
                        isCompleted ? 'text-transparent' : 'text-[#61CF8D]'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setValue('isCompleted', false)}
                      className="cursor-pointer bg-transparent text-left text-base text-medium w-full hover:bg-c-bg flex flex-col gap-2 leading-none">
                      アプローチを完了しないでログのみ登録
                      <span className="text-xs">
                        アプローチは完了せずアプローチ一覧に残ります
                      </span>
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className={`hover:bg-c-bg py-4 pl-2 pr-4 w-full flex items-center gap-2 ${
                      isCompleted ? 'bg-c-bg' : 'bg-white'
                    }`}>
                    <CheckIcon
                      className={`w-4 h-4 ${
                        isCompleted ? 'text-[#61CF8D]' : 'text-transparent'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setValue('isCompleted', true)}
                      className="cursor-pointer bg-transparent text-left text-base text-medium w-full hover:bg-c-bg flex flex-col gap-2 leading-none">
                      アプローチを完了して登録
                      <span className="text-xs">
                        アプローチは完了しアプローチ一覧から削除されます。
                      </span>
                    </button>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
      {ngCallModalVisible && (
        <CallNgModal
          projectId={project?.uuid}
          isModalVisible={ngCallModalVisible}
          setModalVisible={setCallNgModalVisible}
          ngReason={callNgReason}
          setNgReason={setCallNgReason}
        />
      )}

      {ngReceptionModalVisible && (
        <ReceptionNgModal
          projectId={project?.uuid}
          isModalVisible={ngReceptionModalVisible}
          setModalVisible={setReceptionNgModalVisible}
          ngReason={receptionNgReason}
          setNgReason={setReceptionNgReason}
        />
      )}

      {ngContactModalVisible && (
        <ContactNgModal
          projectId={project?.uuid}
          isModalVisible={ngContactModalVisible}
          setModalVisible={setContactNgModalVisible}
          ngReason={contactNgReason}
          setNgReason={setContactNgReason}
        />
      )}
    </form>
  );
};

export default TelForm;
