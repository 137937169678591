import React from 'react';
import {usePoolProspectCustomizeItemsQuery} from 'api';
import useSearchParams from '../../../../useSearchParams';
import {Listbox} from '@headlessui/react';
import {ArrowDownIcon} from './Icon';

type CustomizeItemFilterItem = {
  customizeItemId: string;
  customizeItemValue: string[];
  index: number;
};

export default ({
  customizeItemId,
  customizeItemValue,
  index,
}: CustomizeItemFilterItem) => {
  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});

  const customizeItems = React.useMemo(() => {
    return poolProspectCustomizeItems.filter(
      (item) => item.dataType === 'select',
    );
  }, [poolProspectCustomizeItems]);

  const customizeItemOptions = () => {
    return (
      customizeItems.find((item) => item.id === customizeItemId)
        ?.selectOptions || []
    );
  };

  const {query, setQuery} = useSearchParams();

  const selectedItemNames = (): string[] => {
    return (
      (
        customizeItems.find((item) => item.id === customizeItemId)
          ?.selectOptions || []
      )
        ?.filter((option) => customizeItemValue?.includes(option.id))
        ?.map((option) => option.label) || []
    );
  };

  if (!customizeItemId) {
    return <></>;
  }

  return (
    <div className="relative">
      <Listbox
        value={customizeItemValue || []}
        disabled={!customizeItemId}
        multiple
        onChange={(values) => {
          setQuery({
            ...query,
            prospectCustomizeItemSearchConditions: [
              ...query.prospectCustomizeItemSearchConditions.slice(0, index),
              {
                customize_item_id: customizeItemId,
                data_type: 'select',
                values: values,
              },
              ...query.prospectCustomizeItemSearchConditions.slice(index + 1),
            ],
          });
        }}>
        <Listbox.Button className="relative px-3 text-left focus:outline-none flex items-center border rounded w-80 min-h-8 h-full bg-white border-[#d9d9d9] justify-between py-1">
          <div className="flex gap-1 min-h-8 w-auto flex-wrap">
            {selectedItemNames().map((name, index) => {
              return (
                <div key={index} className="bg-[#F3F5F7] px-1">
                  {name}
                </div>
              );
            })}
          </div>
          <ArrowDownIcon />
        </Listbox.Button>
        <Listbox.Options className="absolute">
          {customizeItemOptions().map((customizeItemOption) => (
            <Listbox.Option
              className={({active}) =>
                `relative select-none py-2 px-3 cursor-pointer w-80 h-8 pr-10 z-20 truncate ${
                  active ? 'bg-gray-100' : 'bg-white'
                }`
              }
              key={customizeItemOption.id}
              value={customizeItemOption.id}>
              {customizeItemOption.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
