import React from 'react';
import styled from 'styled-components';
import preleadCategoryColor from 'helpers/preleadCategoryColor';
import preleadColor from 'helpers/preleadColor';
import {Prelead, useCurrentClientQuery} from 'api';

interface Props {
  row: any;
}

const PreleadStatus = ({row}: Props) => {
  const prelead: Prelead = row.original.preleadProject.prelead;
  const {data: {currentClient: {preleadStatuses = []} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-only',
    });
  const preleadStatus = preleadStatuses.find(
    (preleadStatus) => preleadStatus.id === prelead?.clientPreleadStatusId,
  );

  const color = preleadStatus
    ? preleadCategoryColor(preleadStatus.category)
    : preleadColor(prelead?.status);

  return <StatusMark color={color} />;
};

const StatusMark = styled.span<{color: string}>`
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid #bdc4cb'};
`;

export default PreleadStatus;
