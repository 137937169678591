import React from 'react';
import styled from 'styled-components';
import {useCompanyQuery} from 'api';
import useJobListing from 'hooks/useJobListing';

type Props = {
  companyId: string;
};

export default ({companyId}: Props) => {
  const {data: {company: {crawlerJobListingPages = []} = {}} = {}} =
    useCompanyQuery({
      variables: {uuid: companyId},
      skip: !companyId,
    });

  const {aggregatedJobListingsByMedia} = useJobListing(crawlerJobListingPages);

  return (
    <Container>
      {aggregatedJobListingsByMedia?.map((media) => {
        if (media.jobInformations?.length > 0) {
          return (
            <Media key={media.id}>
              <MediaName>{media.name}</MediaName>
              {media.jobInformations.map((info: any) => (
                <JobTitle key={info.id} href={info.url} target="_blank">
                  {info.title || info.url}
                </JobTitle>
              ))}
              <BorderBottom />
            </Media>
          );
        }
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Media = styled.div`
  padding-bottom: 10px;
`;

const MediaName = styled.div`
  padding-bottom: 15px;
  font-family: Noto Sans JP;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.15px;
  color: #222426;
`;

const JobTitle = styled.a`
  display: block;
  padding-left: 7px;
  margin-bottom: 8px;
  font-family: Noto Sans JP;
  font-size: 11px;
  letter-spacing: 0.15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #222426;
`;

const BorderBottom = styled.div`
  border-bottom: 0.5px solid #bdc4cb;
  margin-bottom: 20px;
  padding-top: 20px;
`;
