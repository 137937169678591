import {Action} from 'api';
import React from 'react';
import {Row} from 'react-table';

interface Props {
  row: Row<Action>;
}

const CheckCell = ({row}: Props) => {
  const action = row.original;

  return <input type="checkbox" checked={false} onChange={console.log} />;
};

export default CheckCell;
