import React from 'react';
import {useQueryParam, StringParam, BooleanParam} from 'use-query-params';
import styled from 'styled-components';

export default () => {
  const [type] = useQueryParam('type', StringParam);
  const [archived] = useQueryParam('archived', BooleanParam);

  const title = React.useMemo(() => {
    if (type === 'mail_form') {
      return 'フォームテンプレート';
    } else if (type === 'mail_template') {
      return 'メールテンプレート';
    } else if (type === 'document') {
      return 'ドキュメント';
    } else if (type === 'website') {
      return 'URL';
    } else if (archived) {
      return 'アーカイブ';
    } else {
      return 'ALL';
    }
  }, [type]);

  return (
    <Container>
      <h1>{title}</h1>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 1.5rem;

  h1 {
    font-size: 18px;
  }
`;
