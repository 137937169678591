import React from 'react';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router';
import {useProjectQuery} from 'api';
import {Switch, Route} from 'react-router-dom';
import {Category} from './category';
import WebsiteMultiple from './websiteMultiple';
import WebsiteSingle from './websiteSingle';
import {Technology} from './technology';
import WebsitePage from './websiteSingle/websitePage';
import useSearchCondition from './useSearchCondition';
import projectRoutePaths from 'helpers/projectRoutePaths';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {resetQuery} = useSearchCondition();
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (project?.status === 'deleted' || project === null) {
        history.push(createPath(`projects`));
      } else {
        resetQuery(project.projectSearchCondition);
      }
    },
  });

  if (!project) return null;

  return (
    <>
      <Switch>
        <Route
          path={projectRoutePaths('projects/:projectId/search/category')}
          component={Category}
        />
        <Route
          path={projectRoutePaths(
            'projects/:projectId/search/website/multiple',
          )}
          component={WebsiteMultiple}
        />
        <Route
          path={projectRoutePaths(
            'projects/:projectId/search/website/single/:websiteType',
          )}
          component={WebsitePage}
        />
        <Route
          path={projectRoutePaths('projects/:projectId/search/website/single')}
          component={WebsiteSingle}
        />
        <Route
          path={projectRoutePaths('projects/:projectId/search/technology')}
          component={Technology}
        />
      </Switch>
    </>
  );
};
