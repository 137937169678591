import {ProspectPool, useActivitiesByProspectPoolQuery} from 'api';
import React from 'react';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {data: {activitiesByProspectPool: {prelead = {}} = {}} = {}} =
    useActivitiesByProspectPoolQuery({
      variables: {
        uuid: prospectPool.uuid,
      },
    });

  return (
    <div className="pb-3">
      <h3 className="text-sm leading-4 text-gray-400">企業最終担当者</h3>
      {prelead?.user
        ? [prelead.user.lastName, prelead.user.firstName]
            .filter(Boolean)
            .join(' ')
        : '-'}
    </div>
  );
};
