import React from 'react';
import {MailCampaignSend, useMailCampaignSendsSearchQuery} from 'api';
import useSearchParams from './useSearchParams';
import moment from 'moment';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import Pagination from './Pagination';
import Cell from './Cell';

const columnHelper = createColumnHelper<MailCampaignSend>();

const columns = () => {
  return [
    columnHelper.accessor('preleadProject', {
      id: 'preleadStatus',
      header: () => (
        <span className="inline-block align-middle border border-c-lighter h-4 w-4 rounded-[4px]" />
      ),
      cell: Cell.PreleadStatus,
      size: 30,
      meta: {align: 'center'},
    }),
    columnHelper.accessor('preleadProject', {
      id: 'prelead',
      header: () => '会社名',
      cell: ({getValue}) => getValue().prelead.name,
      size: 160,
      meta: {align: 'left'},
    }),
    columnHelper.accessor('preleadProject', {
      id: 'companyId',
      header: () => '名寄せ',
      cell: Cell.Nayose,
      size: 40,
      meta: {align: 'center'},
    }),
    columnHelper.accessor('mailCampaign', {
      header: () => 'メール配信-営業リスト',
      cell: ({getValue}) => <>{getValue()?.title}</>,
      size: 300,
      meta: {align: 'left'},
    }),
    columnHelper.accessor('clickedAt', {
      header: () => '最新クリック日時',
      cell: ({getValue}) =>
        getValue() && moment(getValue()).format('YYYY/MM/DD HH:mm'),
      size: 100,
      meta: {align: 'center'},
    }),
    columnHelper.accessor('firstOpenedAt', {
      header: () => '初回開封日時',
      cell: ({getValue}) =>
        getValue() && moment(getValue()).format('YYYY/MM/DD HH:mm'),
      size: 100,
      meta: {align: 'center'},
    }),
    columnHelper.accessor('activityMailCampaignMailClickCount', {
      header: () => 'クリック回数',
      cell: ({getValue}) => getValue().toLocaleString(),
      size: 60,
      meta: {align: 'right'},
    }),
  ];
};

const Table = () => {
  const {page, perPage, searchParams, query, setPage} = useSearchParams();

  const {
    data: {
      mailCampaignSendsSearch: {mailCampaignSends = [], pagination = {}} = {},
    } = {},
  } = useMailCampaignSendsSearchQuery({
    variables: {search: searchParams, page: page || 1, perPage: perPage},
    skip: !query.mailCampaignUuid,
    fetchPolicy: 'cache-and-network',
  });

  const table = useReactTable({
    data: mailCampaignSends,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <table className="w-full bg-white mb-4">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="text-center border border-c-border py-4 px-2 truncate"
                  style={{width: `${header.getSize()}px`}}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border border-c-border p-2 max-w-0 truncate"
                  style={{width: `${cell.column.getSize()}px`}}
                  align={(cell.column.columnDef.meta as any)?.align}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        pageSize={perPage}
        current={pagination.currentPage || 1}
        total={pagination.totalCount || 0}
        onChange={(page) => setPage(page)}
      />
    </>
  );
};

export default Table;
