import gql from 'graphql-tag';

export default gql`
  fragment contentDocumentUrlFragment on ContentDocumentUrl {
    id
    uuid
    url
    createdAt
    updatedAt
  }
`;
