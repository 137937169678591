import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useHistory, useParams} from 'react-router';
import {Breadcrumb, Container} from 'components/Ui';
import {useProjectQuery, useUpdateProjectSearchConditionMutation} from 'api';
import Search from './Search';
import Companies from './Companies';
import {Button} from 'components/antd';
import {Check} from 'components/Icon';
import {companiesQueryParamsDef} from './companiesQueryParams';
import {useQueryParams} from 'use-query-params';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const [companyId, setCompanyId] = useState();
  const [query] = useQueryParams(companiesQueryParamsDef);
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    onCompleted: () => {
      if (project?.status === 'deleted' || project === null) {
        history.push(createPath(`projects`));
      }
    },
  });
  const [updateProjectSearchCondition] =
    useUpdateProjectSearchConditionMutation({
      variables: {
        uuid: projectId,
        search: {companyId: companyId},
      },
    });

  if (!project) return null;

  return (
    <Container page="newProject">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={createPath('projects')}>営業リスト一覧</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={createPath(`projects/${project?.uuid}?status=draft`)}>
              {project?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="current">企業情報検索</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <ActionWrapper>
        <ConfirmButton
          type="primary"
          disabled={!query.uuid}
          onClick={() => {
            history.push(
              createPath(
                `projects/${project.uuid}/specific_companies/${query.uuid}`,
              ),
            );
            updateProjectSearchCondition();
          }}>
          <Check /> 選択する
        </ConfirmButton>
      </ActionWrapper>
      <Body>
        <Search />
        <Companies setCompanyId={setCompanyId} />
      </Body>
    </Container>
  );
};

const Header = styled.div`
  padding-left: 32px;
  border-bottom: 1px solid #e1e6eb;
`;

const ActionWrapper = styled.div`
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-left: 20px;
  background: #fff;
`;

const ConfirmButton = styled(Button)`
  margin-right: 21px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
    width: auto;
    height: auto;
    path {
      fill: #fff;
    }
  }
`;

const Body = styled.div`
  background: #f3f5f7;
  overflow: scroll;
`;
