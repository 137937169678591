import React from 'react';
import useSearchParams from '../../../useSearchParams';
import {useClientPreleadCustomizeItemsQuery} from 'api';
import CustomizeItemTextFilter from './Text';
import CustomizeItemNumberFilter from './Number';
import CustomizeItemDateFilter from './Date';
import CustomizeItemSelectFilter from './Select';

interface Props {
  filter: string;
}

const filterComponents: {[key: string]: any} = {
  text: CustomizeItemTextFilter,
  number: CustomizeItemNumberFilter,
  date: CustomizeItemDateFilter,
  select: CustomizeItemSelectFilter,
};

const CustomizeItemFilter = ({filter}: Props) => {
  const {query} = useSearchParams();
  const id = filter.split('.')[1];
  const value = (query as any)[filter];

  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery();

  const FilterComponent = React.useMemo(() => {
    const item = clientPreleadCustomizeItems.find((item) => item.id === id);
    return filterComponents[item.dataType];
  }, [clientPreleadCustomizeItems, id]);

  return <FilterComponent filter={filter} id={id} value={value} />;
};

export default CustomizeItemFilter;
