import React from 'react';
import styled from 'styled-components';
import {Tabs} from 'components/antd';
import Button from './Button';
import Config from './Config';

export default () => {
  const [tab, setTab] = React.useState('add');

  return (
    <Container>
      <Tabs defaultActiveKey={tab} onChange={setTab}>
        <Tabs.TabPane tab="コンテンツを追加" key="add" />
        <Tabs.TabPane tab="全体" key="config" />
      </Tabs>
      {tab === 'add' && (
        <Buttons>
          <Button type="header" text="ヘッダー" />
          <Button type="title" text="見出し" />
          <Button type="text" text="テキスト" />
          <Button type="image" text="画像" />
          <Button type="button" text="ボタン" />
          <Button type="divider" text="区切り線" />
          <Button type="footer" text="フッター" />
        </Buttons>
      )}
      {tab === 'config' && <Config />}
    </Container>
  );
};

const Container = styled.div`
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 50%;
    justify-content: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
