import {useProjectWithSearchConditionQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import Area from './Area';
import Category from './Category';
import CompanyAttribute from './CompanyAttribute';
import CompanyScale from './CompanyScale';
import Extra from './Extra';
import Media from './Media';
import SpecificCompany from './SpecificCompany';
import Technology from './Technology';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = {}} = {}} = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'network-only',
  });
  const {projectSearchCondition} = project;
  if (!projectSearchCondition) return null;

  return (
    <Container>
      {projectSearchCondition.company && <SpecificCompany />}
      <Category />
      <CompanyAttribute />
      <Area />
      <CompanyScale />
      <Extra />
      <Media />
      <Technology />
    </Container>
  );
};

const Container = styled.div``;
