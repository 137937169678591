import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {ProspectPool, useAssignUserProspectPoolMutation} from 'api';
import {Cross} from 'components/Ui/Icon';
import {UserSelector} from './UserSelector';

interface Props {
  prospectPool: ProspectPool;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export default ({prospectPool, isModalVisible, setIsModalVisible}: Props) => {
  const [updateUser] = useAssignUserProspectPoolMutation({});

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '400px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '466px',
      minHeight: '200px',
      padding: '30px 40px',
      borderRadius: '10px',
      overflow: 'visible',
    },
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => closeModal()}
      style={customStyles}>
      <Header>
        <div>担当者を変更</div>
        <CrossIconWrapper onClick={() => closeModal()}>
          <Cross />
        </CrossIconWrapper>
      </Header>
      <UserSelector
        user={prospectPool?.user}
        onChange={(user) =>
          updateUser({
            variables: {
              uuid: prospectPool?.uuid,
              userUuid: user?.uuid,
            },
          })
        }
      />
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 20px;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: -5px;
`;
