import React from 'react';
import {Form} from 'api';
import RedirectUrlInput from './RedirectUrl';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  return (
    <div className="py-4 flex flex-col gap-4">
      <h2 className="text-lg font-bold">別ページへリダイレクト設定</h2>
      <RedirectUrlInput form={form} />
    </div>
  );
};
