import React from 'react';
import styled from 'styled-components';
import {PrefectureType, City} from 'api';
import {Check} from 'components/Ui/Icon';
import useSearchCondition from '../../useSearchCondition';
import {Checkbox} from 'antd';
import {regions} from 'helpers/regions';
import _ from 'lodash';

export default ({
  prefectureTypes,
  prefectureTypeNames,
  currentPrefectureType,
}: {
  prefectureTypes: PrefectureType[];
  prefectureTypeNames: string[];
  currentPrefectureType: string;
}) => {
  const {query, setQuery} = useSearchCondition();
  const {cityIds = [], prefectureTypeIds = []} = query;

  const isSelected = React.useCallback(
    (cities: City[]) => {
      const cityIds = cities.map((city) => city.id);
      return (
        cityIds.some((cityId) => query.cityIds.includes(cityId)) ||
        query.prefectureTypeIds.includes(cities[0].prefectureTypeId)
      );
    },
    [query],
  );

  const handleClickMenu = (prefectureName: string) => {
    const scrollContainer = document.getElementById(
      'area-filter-modal-content',
    );
    const section = document.getElementById(prefectureName);
    const top = section.offsetTop - scrollContainer.offsetTop;
    scrollContainer.scrollTop = top;
  };

  const regionPrefectureTypeIds: string[] = [];
  let regionCityIds: string[] = [];
  prefectureTypes?.forEach((prefectureType) => {
    regionPrefectureTypeIds.push(prefectureType.id);
    regionCityIds = regionCityIds.concat(
      prefectureType.cities.map((city) => city.id),
    );
  });

  const region = regions.find((region) =>
    region.prefectureTypes.includes(currentPrefectureType),
  );

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setQuery({
        prefectureTypeIds: _.union(prefectureTypeIds, regionPrefectureTypeIds),
        cityIds: _.difference(cityIds, regionCityIds),
      });
    } else {
      setQuery({
        prefectureTypeIds: _.difference(
          prefectureTypeIds,
          regionPrefectureTypeIds,
        ),
      });
    }
  };

  const handleCheck = (e: any, prefectureType: any) => {
    if (e.target.checked) {
      setQuery({
        prefectureTypeIds: [...prefectureTypeIds, prefectureType.id],
        cityIds: _.difference(cityIds, prefectureType.cities),
      });
    } else {
      setQuery({
        prefectureTypeIds: _.difference(prefectureTypeIds, [prefectureType.id]),
      });
    }
  };

  const isPrefectureTypeChecked = (prefectureTypeId: string) =>
    prefectureTypeIds.includes(prefectureTypeId);

  const isRegionTypeChecked = () => {
    if (prefectureTypeIds.length === 0 || regionPrefectureTypeIds.length === 0)
      return false;
    return (
      _.union(prefectureTypeIds, regionPrefectureTypeIds).length ===
      prefectureTypeIds.length
    );
  };

  return (
    <Container>
      {region && (
        <>
          <Checkbox
            checked={isRegionTypeChecked()}
            onChange={handleCheckAll}
            style={{marginTop: '26px', fontSize: '14px', width: '100%'}}>
            {region.name}すべて
          </Checkbox>
          <hr />
        </>
      )}
      <MenuGroup>
        {prefectureTypeNames?.map((prefectureTypeName) =>
          prefectureTypes?.map((prefectureType) => {
            if (prefectureTypeName === prefectureType.name) {
              return (
                <Menu
                  key={prefectureTypeName}
                  onClick={() => handleClickMenu(prefectureTypeName)}
                  current={prefectureTypeName === currentPrefectureType ? 1 : 0}
                  id={`prefecture-${prefectureTypeName}`}>
                  <Checkbox
                    name={prefectureType.id}
                    checked={isPrefectureTypeChecked(prefectureType.id)}
                    key={prefectureType.id}
                    onChange={(e) => handleCheck(e, prefectureType)}
                    style={{width: '16px', margin: '0 12px 0 0'}}
                  />
                  {prefectureTypeName}
                  {isSelected(prefectureType.cities) && (
                    <SelectLabel>
                      <Check />
                    </SelectLabel>
                  )}
                </Menu>
              );
            }
          }),
        )}
      </MenuGroup>
    </Container>
  );
};

const Container = styled.div`
  width: 207px;
  border-right: solid 1px #e2e6ea;

  hr {
    width: 80%;
    border: 1px solid #e2e6ea;
  }
`;

const MenuGroup = styled.div`
  overflow-y: scroll;
`;

const Menu = styled.div<{current?: number}>`
  &&& {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222426;
    white-space: pre;
    padding: 0 0 0 25px;
    height: 50px;
    background: ${(props) => (props.current ? '#E2E6EA;' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }

    svg {
      position: relative;
      top: 2px;
    }
  }
`;

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
`;
