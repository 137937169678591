import React from 'react';
import {ProspectPool} from 'api';
import {Row} from 'react-table';
import {LastProspectActivity} from 'components/Cell';

interface Props {
  row: Row<ProspectPool>;
}

export default ({row}: Props) => {
  const {lastProspectActivity} = row.original.workflowProspectPool.prospectPool;

  if (!lastProspectActivity) return null;

  return <LastProspectActivity lastProspectActivity={lastProspectActivity} />;
};
