import React from 'react';
import styled from 'styled-components';
import {useCurrentUserQuery} from 'api';
import Dashboard from './dashboard';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  if (currentUser?.role === 'admin') {
    return <Dashboard />;
  }
  return <Container />;
};

const Container = styled.div`
  height: calc(100vh - 60px);
  background: #f3f5f7;
`;
