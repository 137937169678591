import React from 'react';
import {AutomaticMailSend} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<AutomaticMailSend>;
}
export default ({row}: Props) => {
  const account = row.original.prospectPool.prospect.account;

  return <span>{account.name}</span>;
};
