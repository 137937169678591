import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import Total from './total';
// import useSearchParams from '../hooks/useSearchParams';

export default () => {
  // const {dateFrom, dateTo} = useSearchParams();

  return (
    <Layout
      title="フォーム分析"
      tabs={[
        {
          label: '合計',
          to: '/reports/form/total',
        },
      ]}
      search={['timeScale', 'formSelect']}>
      <Switch>
        <Route path="/reports/form/total" component={Total} />
        <Redirect to="/reports/form/total" />
      </Switch>
    </Layout>
  );
};
