import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAccountPoolQuery} from 'api';
import toJaNum from 'helpers/toJaNum';
import MARKETS from 'helpers/markets';

export default () => {
  const {accountId} = useParams<{accountId: string}>();
  const {data: {accountPool: {account = {}} = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const [isShowAll, setIsShowAll] = useState(false);
  const establishedYearMonthText = (establishedYearMonth: string) => {
    if (!establishedYearMonth) return '';

    const date_text = `${establishedYearMonth.replace('-', '年')}月`;

    return date_text;
  };

  return (
    <div className="bg-white border border-c-border rounded">
      <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
        企業情報
      </h2>
      <div className="p-4 flex flex-col gap-8">
        <div>
          <dl className="text-sm">
            <dt className="text-c-light">事業概要</dt>
            <dd className="mb-2">{account.abstract}</dd>
            <dt className="text-c-light">郵便番号</dt>
            <dd className="mb-2">{account.zipCode}</dd>
            <dt className="text-c-light">住所</dt>
            <dd className="mb-2">{account.address}</dd>
            <dt className="text-c-light">代表電話</dt>
            <dd className="mb-2">{account.telephoneNumber}</dd>
            <dt className="text-c-light">代表FAX</dt>
            <dd className="mb-2">{account.faxNumber}</dd>
            <dt className="text-c-light">代表メール</dt>
            <dd className="mb-2">{account.mailAddress}</dd>
            <dt className="text-c-light">代表者</dt>
            <dd className="mb-2">{account.representativePerson}</dd>
            <dt className="text-c-light">法人番号</dt>
            <dd className="mb-2">{account.corpNumber}</dd>
            {isShowAll && (
              <>
                <dt className="text-c-light">従業員数</dt>
                <dd className="mb-2">
                  {account.employeeNumber &&
                    `${account.employeeNumber?.toLocaleString()}人`}
                </dd>
                <dt className="text-c-light">資本金</dt>
                <dd className="mb-2">
                  {account.capitalFund && `${toJaNum(account.capitalFund)}円`}
                </dd>
                <dt className="text-c-light">設立年月</dt>
                <dd className="mb-2">
                  {establishedYearMonthText(account.establishedYearMonth)}
                </dd>
                <dt className="text-c-light">上場区分</dt>
                <dd className="mb-2">
                  {
                    MARKETS.find(
                      (marketEnum) =>
                        marketEnum.value === account.listingMarket,
                    )?.name
                  }
                </dd>
                <dt className="text-c-light">売上</dt>
                <dd className="mb-2">
                  {account.company?.sales &&
                    `${toJaNum(account.company.sales)}円`}
                </dd>
                <dt className="text-c-light">決算月</dt>
                <dd className="mb-2">
                  {account.accountClosingMonth &&
                    `${account.accountClosingMonth}月`}
                </dd>
                <dt className="text-c-light">事業所数</dt>
                <dd className="mb-2">{account.company?.officesNumber}</dd>
                <dt className="text-c-light">国内支店・拠点数</dt>
                <dd className="mb-2">
                  {account.officesDomesticBranchesNumber}
                </dd>
                <dt className="text-c-light">海外支店・拠点数</dt>
                <dd className="mb-2">{account.officesForeignBranchesNumber}</dd>
                <dt className="text-c-light">国内店舗数</dt>
                <dd className="mb-2">{account.officesDomesticStoresNumber}</dd>
                <dt className="text-c-light">海外店舗数</dt>
                <dd className="mb-2">{account.officesForeignStoresNumber}</dd>
                <dt className="text-c-light">国内工場数</dt>
                <dd className="mb-2">
                  {account.officesDomesticFactoriesNumber}
                </dd>
                <dt className="text-c-light">海外工場数</dt>
                <dd className="mb-2">
                  {account.officesForeignFactoriesNumber}
                </dd>
              </>
            )}
          </dl>
          {!isShowAll && (
            <div className="text-center py-2">
              <span
                className="cursor-pointer text-blue-400 hover:text-blue-500"
                onClick={() => setIsShowAll(true)}>
                すべて表示する
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
