import React from 'react';
import {
  FormBlock,
  FormBlockPersonalInformation,
  useUpdateFormBlockPersonalInformationMutation,
} from 'api';
import ReactQuill from 'react-quill';
import debounce from 'helpers/debounce';

interface Block extends FormBlock {
  formBlockable: FormBlockPersonalInformation;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const [textHtml, setTextHtml] = React.useState<string>(
    block.formBlockable.textHtml,
  );

  const [update] = useUpdateFormBlockPersonalInformationMutation();

  const onSubmit = debounce((data: any) => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    onSubmit({textHtml});
  }, [textHtml]);

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-lg font-bold">個人情報への同意の編集</h2>
      <label className="tracking-wide font-medium text-base text-c-light">
        テキスト
      </label>
      <div
        className="
        relative
        [&_a]:text-c-primary
        [&_.ql-toolbar]:border-t-none
        [&_.ql-toolbar]:border-x-none
        [&_.ql-toolbar]:border-c-border
        [&_.ql-toolbar]:bg-white
        [&_.ql-toolbar]:rounded-t
        [&_.ql-container]:min-h-[100px]
        [&_.ql-container]:border-c-border
        [&_.ql-container]:bg-white
        [&_.ql-editor]:min-h-[100px]
        [&_.ql-editor]:rounded-b
        [&_.ql-editor_a]:no-underline
      ">
        <ReactQuill
          theme="snow"
          value={textHtml}
          placeholder="本文を記入してください"
          onChange={(value) => setTextHtml(value || '')}
          modules={{
            toolbar: {
              container: [['bold', 'italic', 'underline', 'link']],
            },
          }}
        />
      </div>
    </div>
  );
};
