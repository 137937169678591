import React from 'react';
import {useQueryParam, NumberParam} from 'use-query-params';
import {Link} from 'react-router-dom';
import {useMyTeamsQuery, Team} from 'api';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import useClientUser from 'hooks/useClientUser';

const columnHelper = createColumnHelper<Team>();

const Table = () => {
  const {isAdmin} = useClientUser();
  const [page] = useQueryParam('page', NumberParam);

  const {data: {myTeams: {teams = []} = {}} = {}} = useMyTeamsQuery({
    variables: {page: page || 1},
    fetchPolicy: 'cache-and-network',
  });

  const data = React.useMemo(() => teams, [teams]);

  const columns = [
    columnHelper.accessor('name', {
      header: () => '名称',
      cell: (info) =>
        isAdmin ? (
          <Link to={`/settings/teams/${info.row.original.uuid}/base`}>
            {info.getValue()}
          </Link>
        ) : (
          info.getValue()
        ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
