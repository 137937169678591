import React from 'react';
import styled from 'styled-components';
import {ProjectMailStatusCount} from 'api';
import {StatusEclipse} from 'components/Ui';
import mailColor from 'helpers/mailColor';

interface Props {
  projectMailStatusCountReports: ProjectMailStatusCount[];
}

export default React.memo(({projectMailStatusCountReports}: Props) => (
  <Table>
    <thead>
      <tr>
        <th>ステータス</th>
        <th>件数</th>
      </tr>
    </thead>
    <tbody>
      {projectMailStatusCountReports
        .filter((mailStatusCount) => mailStatusCount.mailStatus !== 'unsent')
        .map((mailStatusCount) => (
          <tr key={mailStatusCount.mailStatus}>
            <td>
              <StatusEclipse color={mailColor(mailStatusCount.mailStatus)} />
              <span>{mailStatusCount.mailStatusText}</span>
            </td>
            <td>{mailStatusCount.count.toLocaleString()}</td>
          </tr>
        ))}
    </tbody>
  </Table>
));

const Table = styled.table`
  width: 100%;
  border: 1px solid #e2e6ea;
  margin-top: 5px;

  th {
    font-weight: bold;
    text-align: left;
    background: #f9fafc;
    border-bottom: 1px solid #e2e6ea;
    height: 42px;
    vertical-align: middle;
    padding: 0 10px;

    &:not(:first-child) {
      text-align: right;
      width: 80px;

      svg {
        margin-right: 5px;
      }
    }
  }

  td {
    text-align: left;
    border-bottom: 1px solid #e2e6ea;
    height: 30px;
    vertical-align: middle;
    padding: 0 10px;

    &:not(:first-child) {
      text-align: right;
    }

    svg {
      margin-right: 3px;
    }
  }
`;
