import React from 'react';
import {useSalesforceContactFieldQuery} from 'api';
import useSearchParams from '../../../../../useSearchParams';
import {Listbox} from '@headlessui/react';
import ArrowDownIcon from './ArrowDownIcon';

type CustomizeItemFilterItem = {
  salesforceContactFieldId: string;
  customizeItemValues: string[];
  index: number;
};

export default ({
  salesforceContactFieldId,
  customizeItemValues,
  index,
}: CustomizeItemFilterItem) => {
  const {query, setQuery} = useSearchParams();
  const {data: {salesforceContactField = {}} = {}} =
    useSalesforceContactFieldQuery({
      variables: {contactFieldId: salesforceContactFieldId},
      skip: !salesforceContactFieldId,
    });

  const customizeItemOptions = () => {
    return salesforceContactField?.salesforceContactFieldSelectOptions || [];
  };

  const selectedItemNames = (): string[] => {
    return (salesforceContactField?.salesforceContactFieldSelectOptions || [])
      ?.filter((option) => customizeItemValues?.includes(option.id))
      ?.map((option) => option?.label);
  };

  if (!salesforceContactFieldId) {
    return <></>;
  }

  return (
    <div className="relative">
      <Listbox
        value={customizeItemValues}
        disabled={!salesforceContactField}
        multiple
        onChange={(values) => {
          setQuery({
            ...query,
            salesforceContactSearchConditions: [
              ...query.salesforceContactSearchConditions.slice(0, index),
              {
                salesforce_contact_field_id: salesforceContactFieldId,
                field_type: 'multi_select',
                values: values,
              },
              ...query.salesforceContactSearchConditions.slice(index + 1),
            ],
          });
        }}>
        <Listbox.Button className="relative px-3 text-left focus:outline-none flex items-center border rounded w-80 min-h-8 h-full bg-white border-[#d9d9d9] justify-between py-1">
          <div className="flex gap-1 min-h-8 w-auto flex-wrap">
            {selectedItemNames()?.map((name, index) => {
              return (
                <div key={index} className="bg-[#F3F5F7] px-1">
                  {name}
                </div>
              );
            })}
          </div>
          <ArrowDownIcon />
        </Listbox.Button>
        <Listbox.Options className="absolute">
          {customizeItemOptions().map((customizeItemOption) => (
            <Listbox.Option
              className={({active}) =>
                `relative select-none py-2 px-3 cursor-pointer w-80 h-8 pr-10 z-20 truncate ${
                  active ? 'bg-gray-100' : 'bg-white'
                }`
              }
              key={customizeItemOption.id}
              value={customizeItemOption.id}>
              {customizeItemOption.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
