import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Header from './Header';
import Summary from './Summary';
import Table from './Table';
import {useLatestMaintenanceRefreshQuery} from 'api';

export default () => {
  const {data: {latestMaintenanceRefresh = null} = {}} =
    useLatestMaintenanceRefreshQuery({
      fetchPolicy: 'cache-and-network',
    });

  const canShowSummary =
    !latestMaintenanceRefresh || latestMaintenanceRefresh?.status === 'success';

  return (
    <div className="p-8 bg-c-bg overflow-y-scroll">
      <h1 className="my-4 flex text-xl font-bold">メンテナンス</h1>
      <div className="flex flex-col bg-white rounded p-8 gap-8">
        <Switch>
          <Route path="/maintenance">
            <div>
              <Header />
              {canShowSummary && <Summary />}
            </div>
            <Table />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
