import gql from 'graphql-tag';

export default gql`
  fragment mainCategoryFragment on MainCategory {
    id
    uuid
    name
    displayName
    count
    position
  }
`;
