import React, {useRef} from 'react';
import useCondition from '../../useCondition';
import Header from '../Header';
import Footer from './Footer';

const EmplyeeNumberFilter = () => {
  const {condition, setCondition} = useCondition();
  const refFrom = useRef(null);
  const refTo = useRef(null);

  const onChange = (from: string, to: string) =>
    setCondition({
      employeeNumber: {
        from: from ? Number(from) : undefined,
        to: to ? Number(to) : undefined,
      },
    });

  return (
    <>
      <Header title="従業員数" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <div className="text-c-base font-bold">従業員数</div>
          <div className="flex items-center">
            <input
              min={1}
              type="number"
              defaultValue={condition?.employeeNumber?.from}
              onBlur={(e: any) =>
                onChange(e.target.value, condition?.employeeNumber?.to)
              }
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light relative"
              ref={refFrom}
            />
            <span className="ml-1">人</span>
            <span className="mx-2">~</span>
            <input
              min={1}
              type="number"
              defaultValue={condition?.employeeNumber?.to}
              onBlur={(e: any) =>
                onChange(condition?.employeeNumber?.from, e.target.value)
              }
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
              ref={refTo}
            />
            <span className="ml-1">人</span>
          </div>
        </div>
      </div>
      <Footer
        onClear={() => {
          setCondition({
            employeeNumber: {},
          });
          refFrom.current.value = '';
          refTo.current.value = '';
        }}
      />
    </>
  );
};

export default EmplyeeNumberFilter;
