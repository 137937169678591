import React from 'react';
import {
  HubspotDealPropertyDefinition,
  useHubspotDealPropertyDefinitionEnumCheckboxOptionsQuery,
} from 'api';

interface Props {
  definition: HubspotDealPropertyDefinition;
  values: string[];
}

export default ({definition, values}: Props) => {
  const {data: {hubspotDealPropertyDefinitionEnumCheckboxOptions = []} = {}} =
    useHubspotDealPropertyDefinitionEnumCheckboxOptionsQuery({
      variables: {
        hubpostDealPropertyDefinitionId: definition?.id,
      },
      skip: !definition?.id,
    });

  return (
    <div className="flex-1 flex flex-wrap items-center gap-1">
      {hubspotDealPropertyDefinitionEnumCheckboxOptions
        ?.filter((option) => values?.includes(option.id))
        .map((option) => (
          <span
            key={option.id}
            className="h-6 flex items-center justify-center text-sm border border-c-border rounded-sm bg-c-bg px-2">
            {option.label}
          </span>
        ))}
    </div>
  );
};
