import React from 'react';
import styled from 'styled-components';
import {InputNumber} from 'components/antd';

type Props = {
  name?: string;
  defaultValue?: number;
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  formatter?: (value: number | string) => string;
  parser?: (value: string) => number;
  error?: boolean;
  onChange?: (value: number) => void;
  onBlur?: any;
  ref?: any;
  autoFocus?: any;
};

export default ({error, ...props}: Props) => {
  return <StyledInput error={error ? 1 : 0} {...props} />;
};

const StyledInput = styled(InputNumber)<{error: number}>`
  &&& {
    border-radius: 4px;
    border: ${(props) =>
      props.error ? '1px solid #EA3F60' : '1px solid #afc1fd'};

    &:focus {
      border: ${(props) =>
        props.error ? '1px solid #EA3F60' : '1px solid #3864fb'};
    }
  }
`;
