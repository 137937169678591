export const telStatusCategoryColors = [
  '#F37474',
  '#E5613D',
  '#E8C374',
  '#5CB66A',
  '#47938A',
  '#4795F0',
  '#4861E4',
  '#5943FF',
  '#F9B9B9',
  '#F2B09E',
  '#F3E1B9',
  '#ADDAAE',
  '#A3C9C4',
  '#A3CAF8',
  '#A4B0F2',
  '#ACA1FF',
];
