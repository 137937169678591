import React from 'react';
import {useProspectReportProspectPoolUserDailyAllMonthReportsQuery} from 'api';
import {useReactTable, getCoreRowModel} from '@tanstack/react-table';
import columns from './ColumnDef';
import useSearchParams from '../useSearchParams';
import {VisibilityState} from '@tanstack/react-table';

const TableDef = () => {
  const {query} = useSearchParams();
  const {data: {prospectReportProspectPoolUserDailyAllMonthReports = []} = {}} =
    useProspectReportProspectPoolUserDailyAllMonthReportsQuery({
      variables: {
        year: query.year,
        month: query.month,
        searchCondition: {
          leadSourceIds: query.leadSourceIds,
          tagIds: query.tagIds,
          userIds: query.userIds,
        },
      },
    });

  const columnVisibilityObject = (): VisibilityState => {
    const result: VisibilityState = {};
    for (const prospectTelStatusColumnId of query.hidedProspectTelStatusColumnIds) {
      result[prospectTelStatusColumnId] = false;
    }
    return result;
  };

  const data = React.useMemo(
    () => prospectReportProspectPoolUserDailyAllMonthReports,
    [prospectReportProspectPoolUserDailyAllMonthReports],
  );

  const table = useReactTable({
    data,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true,
    state: {
      columnVisibility: columnVisibilityObject(),
    },
  });
  return table;
};

export default TableDef;
