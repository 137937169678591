import React from 'react';

const Icon = ({
  width = '40',
  height = '40',
  active,
}: {
  width: string;
  height: string;
  active: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="15"
      cy="15"
      r="15"
      fill={active ? 'rgba(249, 153, 133, 1)' : 'rgba(249, 153, 133, .3)'}
    />
    <path
      d="M19.6667 10.9165H10.3333C10.0112 10.9165 9.75 11.1777 9.75 11.4998V18.4998C9.75 18.822 10.0112 19.0832 10.3333 19.0832H19.6667C19.9888 19.0832 20.25 18.822 20.25 18.4998V11.4998C20.25 11.1777 19.9888 10.9165 19.6667 10.9165Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6663 11.2085L14.9997 15.5835L10.333 11.2085"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
