import React from 'react';
import {ProspectPool, ProspectActivity, MiitelCallProspectActivity} from 'api';
import CallActivity from './CallActivity';
import MiitelCallActivityComponent from './MiitelCallActivity';

interface MiitelCallActivity extends ProspectActivity {
  resource: MiitelCallProspectActivity;
}

interface Props {
  prospectPool: ProspectPool;
  prospectActivity: MiitelCallActivity;
}

export default ({prospectActivity, prospectPool}: Props) => {
  return prospectActivity.resource.stage ? (
    <CallActivity
      prospectActivity={prospectActivity}
      prospectPool={prospectPool}
    />
  ) : (
    <MiitelCallActivityComponent
      prospectActivity={prospectActivity}
      prospectPool={prospectPool}
    />
  );
};
