import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none">
      <path
        d="M10.6457 3.49967C10.2589 3.49967 9.89025 3.57434 9.54959 3.7053C9.13513 3.05634 8.41034 2.62467 7.58317 2.62467C6.96863 2.62467 6.41125 2.86413 5.99446 3.25234C5.48755 2.68913 4.75488 2.33301 3.93734 2.33301C2.40696 2.33301 1.1665 3.57347 1.1665 5.10384C1.1665 5.33484 1.198 5.55826 1.25109 5.77263C0.668046 6.22647 0.291504 6.93288 0.291504 7.72884C0.291504 9.09792 1.40159 10.208 2.77067 10.208C2.89113 10.208 3.00867 10.1966 3.12475 10.18C3.50771 11.0547 4.3798 11.6663 5.39567 11.6663C6.31792 11.6663 7.12117 11.1615 7.54817 10.4142C7.74534 10.4685 7.95213 10.4997 8.1665 10.4997C8.93067 10.4997 9.60675 10.1307 10.0326 9.56313C10.2306 9.60338 10.4357 9.62467 10.6457 9.62467C12.337 9.62467 13.7082 8.25355 13.7082 6.56217C13.7082 4.8708 12.337 3.49967 10.6457 3.49967Z"
        fill="#45A0DA"
      />
    </svg>
  );
};
