import React from 'react';

export default () => {
  return (
    <div className="pl-5 pr-9">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_40263_157787)">
          <path
            d="M11.8339 17.3311C11.8339 17.6842 11.6917 18.023 11.4385 18.2731C11.1853 18.5233 10.8416 18.6645 10.4828 18.6658H7.7705C7.41171 18.6645 7.06807 18.5233 6.81484 18.2731C6.56161 18.023 6.41943 17.6842 6.41943 17.3311C6.41943 17.3311 6.41943 17.2859 6.41943 17.2609C6.41943 17.2358 6.41943 17.2107 6.41943 17.1856V7.99805H5.09896C4.05749 7.99628 3.05478 8.38668 2.29641 9.08921C1.53804 9.79175 1.08146 10.7532 1.02027 11.7764C1.01277 11.8515 1.01277 11.9271 1.02027 12.0022V19.4386C1.02027 19.5841 1.02027 19.7246 1.02027 19.8651C1.02027 19.9102 1.02027 19.9554 1.02027 20.0006C1.02027 21.0652 1.44999 22.0862 2.21489 22.839C2.97979 23.5918 4.01722 24.0148 5.09896 24.0148H5.45075H5.83312H13.2104C14.2852 24.0148 15.3164 23.5974 16.0802 22.8533C16.8439 22.1091 17.2784 21.0984 17.2891 20.0407V16.0265H13.2104C12.8525 16.0211 12.5069 16.1552 12.249 16.3996C11.9911 16.644 11.8419 16.9789 11.8339 17.3311Z"
            fill="#8966EF"
          />
          <path
            d="M22.6728 3.99957C22.6728 2.93493 22.2431 1.9139 21.4782 1.16109C20.7133 0.408274 19.6759 -0.0146484 18.5941 -0.0146484C18.4667 -0.0146484 18.3443 -0.0146484 18.2169 -0.0146484C18.0894 -0.0146484 17.967 -0.0146484 17.8396 -0.0146484H11.2168C11.0894 -0.0146484 10.967 -0.0146484 10.8446 -0.0146484C10.7223 -0.0146484 10.5948 -0.0146484 10.4674 -0.0146484C9.38562 -0.0146484 8.34819 0.408274 7.58329 1.16109C6.81839 1.9139 6.38867 2.93493 6.38867 3.99957C6.38867 4.12501 6.38867 4.25045 6.38867 4.37088C6.38867 4.49131 6.38867 4.61675 6.38867 4.7422V17.1863C6.38867 17.2114 6.38867 17.2364 6.38867 17.2615C6.38867 17.2866 6.38867 17.3067 6.38867 17.3318C6.38867 17.6849 6.53085 18.0236 6.78408 18.2738C7.03731 18.524 7.38094 18.6652 7.73973 18.6665H10.4521C10.8109 18.6652 11.1545 18.524 11.4077 18.2738C11.6609 18.0236 11.8031 17.6849 11.8031 17.3318C11.8045 16.9787 11.9479 16.6405 12.2021 16.3912C12.4563 16.142 12.8005 16.0021 13.1593 16.0021H18.6094C19.6912 16.0021 20.7286 15.5791 21.4935 14.8263C22.2584 14.0735 22.6881 13.0525 22.6881 11.9879C22.6881 11.8624 22.6881 11.737 22.6881 11.6165C22.6881 11.4961 22.6881 11.3707 22.6881 11.2452V4.72213C22.6881 4.59668 22.6881 4.47124 22.6881 4.35081C22.6881 4.23038 22.6728 4.12501 22.6728 3.99957ZM17.2533 9.33346C17.2537 9.55279 17.199 9.76882 17.094 9.96231C16.9891 10.1558 16.8371 10.3207 16.6517 10.4424C16.5899 10.4824 16.5252 10.5176 16.4579 10.5478L16.3865 10.5779L16.2234 10.628H16.152C16.0706 10.6354 15.9887 10.6354 15.9073 10.628H13.195C12.8353 10.628 12.4904 10.4874 12.236 10.2371C11.9817 9.9868 11.8388 9.64731 11.8388 9.29332V6.67906C11.8388 6.32507 11.9817 5.98557 12.236 5.73526C12.4904 5.48495 12.8353 5.34433 13.195 5.34433H15.9073C16.2661 5.34566 16.6097 5.48686 16.863 5.73703C17.1162 5.9872 17.2584 6.32593 17.2584 6.67906L17.2533 9.33346Z"
            fill="#3E3A39"
          />
        </g>
        <defs>
          <clipPath id="clip0_40263_157787">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
