import React from 'react';
import Table from './Table';

export default () => {
  return (
    <div className="p-8 bg-c-bg h-[calc(100vh_-_60px)] overflow-scroll">
      <h1 className="my-4 flex text-xl font-bold">ダウンロード履歴</h1>
      <div className="flex flex-col bg-white rounded p-8">
        <div className="flex flex-col gap-1">
          <Table />
        </div>
      </div>
    </div>
  );
};
