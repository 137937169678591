import React from 'react';
import {useParams} from 'react-router-dom';
import {useWorkflowsByStatusQuery} from 'api';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import styled from 'styled-components';

const WorkflowFilter = () => {
  const {poolId} = useParams<{poolId: string}>();
  const {query, setQuery} = useSearchParams();

  const isDisable = !query.isTargetAllWorkflows && !query.workflowIds.length;

  const {data: {workflowsByStatus = []} = {}} = useWorkflowsByStatusQuery({
    variables: {poolUuid: poolId, status: ['open', 'closed']},
    fetchPolicy: 'cache-and-network',
    skip: !poolId,
  });

  const resetWorkflowQuery = () => {
    setQuery({
      ...query,
      isTargetAllWorkflows: !query.isTargetAllWorkflows,
      workflowIds: [],
      workflowMailClickedFromDate: '',
      workflowMailClickedToDate: '',
      workflowMailOpenedFromDate: '',
      workflowMailOpenedToDate: '',
    });
  };

  return (
    <>
      <Header title="ワークフロー" />
      <div className="px-6 py-4 bg-c-bg flex-1 flex flex-col gap-4 rounded-b">
        <div className="h-full bg-white p-6 rounded flex flex-col gap-2">
          <div className="text-c-base font-bold">ワークフロー</div>
          <div>
            <div className="mb-1">
              <label className="flex items-center">
                <input
                  type="radio"
                  checked={query.isTargetAllWorkflows}
                  onChange={resetWorkflowQuery}
                  className="form-radio h-5 w-5 border-c-border rounded-full text-c-primary"
                />
                <span className="ml-2">全て</span>
              </label>
            </div>
            <div>
              <label className="flex items-center">
                <input
                  type="radio"
                  checked={!query.isTargetAllWorkflows}
                  onChange={resetWorkflowQuery}
                  className="form-radio h-5 w-5 border-c-border rounded-full text-c-primary"
                />
                <span className="ml-2">ワークフロー指定</span>
              </label>
            </div>
          </div>

          <div className="h-[16rem] overflow-y-scroll p-1">
            <CheckboxGroup
              filter
              options={workflowsByStatus.map((workflow) => ({
                text:
                  workflow.status === 'closed'
                    ? `（終了）${workflow.name}`
                    : workflow.name,
                value: workflow.id,
                keyword: workflow.name,
              }))}
              values={query.workflowIds || []}
              onChange={(workflowIds) => setQuery({...query, workflowIds})}
              disabled={query.isTargetAllWorkflows}
            />
          </div>
          <div className="flex flex-col border-t border-c-border">
            <div className="mt-2 text-c-base font-bold">開封日</div>
            <CustomInput>
              <input
                type="date"
                disabled={isDisable}
                value={query.workflowMailOpenedFromDate}
                onChange={(e: any) =>
                  setQuery({
                    workflowMailOpenedFromDate: e.target.value,
                  })
                }
                className="form-input appearance-none w-[90px] bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light relative"
              />

              <span className="mx-1">~</span>
              <input
                type="date"
                disabled={isDisable}
                value={query.workflowMailOpenedToDate}
                onChange={(e: any) =>
                  setQuery({
                    workflowMailOpenedToDate: e.target.value,
                  })
                }
                className="form-input appearance-none w-[90px] bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
              />
            </CustomInput>
          </div>
          <div className="flex flex-col border-t border-c-border">
            <div className="mt-2 text-c-base font-bold">クリック日</div>
            <CustomInput>
              <input
                type="date"
                disabled={isDisable}
                value={query.workflowMailClickedFromDate}
                onChange={(e: any) =>
                  setQuery({
                    workflowMailClickedFromDate: e.target.value,
                  })
                }
                className="form-input appearance-none w-[90px] bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light relative"
              />
              <span className="mx-1">~</span>
              <input
                type="date"
                disabled={isDisable}
                value={query.workflowMailClickedToDate}
                onChange={(e: any) =>
                  setQuery({
                    workflowMailClickedToDate: e.target.value,
                  })
                }
                className="form-input appearance-none w-[90px] bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
              />
            </CustomInput>
          </div>
        </div>
      </div>

      <Footer
        onClear={() =>
          setQuery({
            ...query,
            workflowIds: [],
            workflowMailOpenedFromDate: '',
            workflowMailOpenedToDate: '',
            workflowMailClickedFromDate: '',
            workflowMailClickedToDate: '',
            isTargetAllWorkflows: false,
          })
        }
      />
    </>
  );
};

const CustomInput = styled.div`
  /* カレンダーアイコンを非表示にし、inputをクリックでカレンダーを表示させる */
  input[type='date'] {
    position: relative;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export default WorkflowFilter;
