import React from 'react';
import styled from 'styled-components';
import {Breadcrumb, Header, Container} from 'components/Ui';
import NewButton from './NewButton';
import BlockGroupList from './BlockGroupList';

export default () => {
  return (
    <Container page="block">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <span className="current">ブロックグループ一覧</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Body>
        <BlockGroupList />
      </Body>
      <NewButton />
    </Container>
  );
};

const Body = styled.div`
  margin: auto;
  background: #f3f5f7;
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 130px);
`;
