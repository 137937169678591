import React from 'react';
import {Step, AutomaticMailStep} from 'api';

interface AutomaticMailStepType extends Step {
  steppable: AutomaticMailStep;
}

interface Props {
  step: AutomaticMailStepType;
}

const AutomaticMailStats = ({step}: Props) => {
  const automaticMailStep = step.steppable;
  const leadCount = automaticMailStep.automaticMailSendsCount;
  const sentCount = automaticMailStep.automaticMailSendsSentCount;
  const dealsCount = step.dealsCount;
  const openCount = automaticMailStep.automaticMailSendsOpenedCount;
  const openRate = sentCount > 0 ? (openCount / sentCount) * 100 : 0;
  const clickCount = automaticMailStep.automaticMailSendsClickedCount;
  const clickRate = sentCount > 0 ? (clickCount / sentCount) * 100 : 0;
  const dealRate = sentCount > 0 ? (dealsCount / sentCount) * 100 : 0;

  return (
    <ul className="flex gap-1 justify-evenly">
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">リード</span>
        <span className="text-base font-bold leading-none">
          {(leadCount || 0).toLocaleString()}
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">送信</span>
        <span className="text-base font-bold leading-none">
          {(sentCount || 0).toLocaleString()}
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">開封率</span>
        <span className="text-base font-bold leading-none">
          {(openRate || 0).toFixed(1)} <span className="text-xs">%</span>
        </span>
        <span className="text-xs text-c-light leading-none">
          ( {openCount.toLocaleString()}人 )
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">クリック率</span>
        <span className="text-base font-bold leading-none">
          {(clickRate || 0).toFixed(1)} <span className="text-xs">%</span>
        </span>
        <span className="text-xs text-c-light leading-none">
          ( {clickCount.toLocaleString()}人 )
        </span>
      </li>
      <li className="border-r border-c-border" />
      <li className="flex flex-col items-center gap-1 w-16">
        <span className="text-xs">商談</span>
        <span className="text-base font-bold leading-none">
          {(dealsCount || 0).toLocaleString()}
        </span>
        <span className="text-xs text-c-light leading-none">
          ( {(dealRate || 0).toFixed(1)}% )
        </span>
      </li>
      <li className="border-r border-c-border" />
    </ul>
  );
};

export default AutomaticMailStats;
