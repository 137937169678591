import {Checkbox} from 'components/antd';
import {useCompanyQuery, useSearchCountQuery} from 'api';
import {Loading} from 'components/Icon';
import {Parliament} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import useSearchCondition from '../useSearchCondition';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryItem,
  Count,
} from './styles';

export default () => {
  const {query, setQuery} = useSearchCondition();
  const {companyId} = useParams<{companyId: string}>();

  const capitalFundToString = (capitalFund: number) =>
    capitalFund < 10000000
      ? '1000万円未満'
      : capitalFund < 300000000
      ? '1000万円以上 ~ 3億円未満'
      : capitalFund < 1000000000
      ? '3億円以上 ~ 10億円未満'
      : capitalFund < 5000000000
      ? '10億円以上 ~ 50億円未満'
      : capitalFund < 10000000000
      ? '50億円以上 ~ 100億円未満'
      : capitalFund > 10000000000 && '100億円以上';

  const capitalFundFrom = (capitalFund: number) =>
    !capitalFund
      ? null
      : capitalFund < 10000000
      ? 0
      : capitalFund < 300000000
      ? 1000
      : capitalFund < 1000000000
      ? 30000
      : capitalFund < 5000000000
      ? 100000
      : capitalFund < 10000000000
      ? 500000
      : capitalFund > 10000000000 && 1000000;

  const capitalFundTo = (capitalFund: number) =>
    !capitalFund
      ? null
      : capitalFund < 10000000
      ? 1000
      : capitalFund < 300000000
      ? 30000
      : capitalFund < 1000000000
      ? 100000
      : capitalFund < 5000000000
      ? 500000
      : capitalFund < 10000000000
      ? 1000000
      : capitalFund > 10000000000 && null;

  const {data: {company = null} = {}} = useCompanyQuery({
    variables: {uuid: companyId},
    skip: !companyId,
    onCompleted: () =>
      setQuery({
        capitalFundFrom: capitalFundFrom(company.capitalFund),
        capitalFundTo: capitalFundTo(company.capitalFund),
      }),
  });

  const {data: {searchCount = 0} = {}, loading} = useSearchCountQuery({
    variables: {
      search: {
        capitalFundFrom: query.capitalFundFrom,
        capitalFundTo: query.capitalFundTo,
      },
    },
    skip: !company,
  });

  const onCheck = (e: any) => {
    const checked = e.target.checked;
    if (checked) {
      setQuery({
        capitalFundFrom: capitalFundFrom(company.capitalFund),
        capitalFundTo: capitalFundTo(company.capitalFund),
      });
    } else {
      setQuery({
        capitalFundFrom: null,
        capitalFundTo: null,
      });
    }
  };

  if (!company.capitalFund) return null;

  return (
    <Container>
      <Category>
        <CategoryTitle>
          <Parliament />
          資本金
        </CategoryTitle>

        <ChildCategory>
          <ChildCategoryItem>
            {capitalFundToString(company.capitalFund)}
          </ChildCategoryItem>
        </ChildCategory>
      </Category>

      <Count>
        <span>検索件数</span>
        {loading ? (
          <Loading />
        ) : (
          <em>{`${searchCount?.toLocaleString()}件`}</em>
        )}
        <Checkbox
          checked={!!query.capitalFundFrom || !!query.capitalFundTo}
          onChange={onCheck}
        />
      </Count>
    </Container>
  );
};
