import React, {useState} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import {Select, Button} from 'components/antd';
import {
  useUsersByClientAllQuery,
  useAddUsersToWorkflowMutation,
  useWorkflowQuery,
} from 'api';
import {useParams} from 'react-router-dom';
import {Cross} from 'components/Ui/Icon';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export default ({isModalVisible, setIsModalVisible}: Props) => {
  const {workflowId} = useParams<{workflowId: string}>();
  const [assignedUsers, setAssignedUsers] = useState([]);

  const [addUsersToWorkflow, {loading}] = useAddUsersToWorkflowMutation({
    variables: {uuid: workflowId, userIds: assignedUsers},
    onCompleted: () => closeModal(),
  });

  const {data: {workflow: {users: usersAlreadyAssigned = []} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const usersNotYetAssigned = users.filter(
    (user) =>
      !usersAlreadyAssigned.some(
        (userAlreadyAssigned) => userAlreadyAssigned.id === user.id,
      ),
  );

  const customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '400px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '466px',
      minHeight: '200px',
      padding: '30px 40px',
      borderRadius: '10px',
      overflow: 'hidden',
    },
  };

  const closeModal = () => {
    setAssignedUsers([]);
    setIsModalVisible(false);
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => closeModal()}
      style={customStyles}>
      <Header>
        <div>ワークフロー担当者を追加</div>
        <CrossIconWrapper onClick={() => closeModal()}>
          <Cross />
        </CrossIconWrapper>
      </Header>

      <Body>
        <Select
          mode="multiple"
          optionFilterProp="children"
          style={{width: '100%'}}
          value={assignedUsers || []}
          onChange={(value) => setAssignedUsers(value)}>
          {usersNotYetAssigned.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.lastName} {user.firstName}
            </Select.Option>
          ))}
        </Select>
      </Body>
      <Action>
        <Button
          style={{width: '120px'}}
          type="primary"
          disabled={assignedUsers.length === 0}
          loading={loading}
          onClick={() => addUsersToWorkflow()}>
          追加
        </Button>
      </Action>
    </Modal>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 20px;
`;

const Body = styled.div`
  .ant-select-selector {
    min-height: 39px;
    border-radius: 4px;
  }
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: -5px;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`;
