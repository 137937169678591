import React from 'react';
import {AutomaticMailSend} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<AutomaticMailSend>;
}
export default ({row}: Props) => {
  const prospect = row.original.prospectPool.prospect;

  return <span>{prospect.email}</span>;
};
