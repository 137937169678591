import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import icon from './arrowdown.svg';

export default () => {
  return <Icon />;
};

const Icon = styled(icon)`
  width: 40px;
  height: 40px;
`;
