import React from 'react';
import {ProspectPool} from 'api';
import NewDeal from './NewDeal';
import DealSetting from './DealSetting';
import AccountSetting from './AccountSetting';
import ContactSetting from './ContactSetting';
import LeadSetting from './LeadSetting';

interface Props {
  prospectPool: ProspectPool;
}

const SlideInPages: React.FC<Props> = ({prospectPool}) => {
  return (
    <>
      <NewDeal prospectPool={prospectPool} />
      <DealSetting />
      <AccountSetting prospectPool={prospectPool} />
      <LeadSetting prospectPool={prospectPool} />
      <ContactSetting prospectPool={prospectPool} />
    </>
  );
};

export default SlideInPages;
