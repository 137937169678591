import React from 'react';
import {Task} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';
import moment from 'moment';
import {Alert} from 'components/Ui/Icon';
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;
  const isExpired = moment().isAfter(moment(task.dueDate), 'day');

  return (
    <div
      className={`text-sm flex items-center gap-1 ${
        isExpired ? 'text-[#EB5757]' : ''
      }`}>
      {task.dueDate && moment(task.dueDate).format('YYYY/MM/DD')}
      {isExpired && <ExclamationTriangleIcon className="w-4 h-4" />}
    </div>
  );
};

export default Cell;
