import React from 'react';
import {Cog6ToothIcon, ArrowPathIcon} from '@heroicons/react/24/outline';
import SettingModal from '../SettingModal';
import {
  useRefreshMaintenanceMutation,
  useLatestMaintenanceRefreshQuery,
} from 'api';
import Progress from './Progress';

export default () => {
  const [visible, setVisible] = React.useState(false);
  const [refreshMaintenance] = useRefreshMaintenanceMutation({
    refetchQueries: ['latestMaintenanceRefresh'],
  });

  const {data: {latestMaintenanceRefresh = null} = {}} =
    useLatestMaintenanceRefreshQuery({
      fetchPolicy: 'cache-and-network',
    });

  const canShowRefreshMaintenance =
    !latestMaintenanceRefresh || latestMaintenanceRefresh?.status === 'success';

  return (
    <div className="mb-4">
      <div className="flex justify-between">
        <h2 className="font-bold text-lg flex justify-between items-center leading-none m-0">
          重複判定結果
        </h2>
        <div className="flex items-center gap-2">
          {canShowRefreshMaintenance ? (
            <button
              className="cursor-pointer px-4 h-8 flex items-center justify-center gap-2 rounded text-c-light border border-c-border shadow-sm bg-white"
              onClick={() => refreshMaintenance()}>
              <ArrowPathIcon className="w-4 h-4" />
              重複判定を実行
            </button>
          ) : (
            <Progress />
          )}
          <button
            onClick={() => setVisible(true)}
            className="cursor-pointer w-8 h-8 flex items-center justify-center gap-2 rounded text-c-light border border-c-border shadow-sm bg-white">
            <Cog6ToothIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
      <SettingModal visible={visible} onClose={() => setVisible(false)} />
    </div>
  );
};
