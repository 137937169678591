import React from 'react';
import {
  SalesforceDealField,
  SalesforceDeal,
  useUpdateSalesforceDealFieldValueMutation,
} from 'api';

interface Props {
  salesforceDeal: SalesforceDeal;
  field: SalesforceDealField;
}

const CheckboxFieldValue = ({salesforceDeal, field}: Props) => {
  const value = salesforceDeal.addition[`${field.fieldName}`];
  const [erorrMessage, setErrorMessage] = React.useState('');
  const [updateFieldValue] = useUpdateSalesforceDealFieldValueMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceDealFieldValue.error);
    },
  });

  if (!field.fieldName.endsWith('__c')) {
    return <div>{value}</div>;
  }

  return (
    <>
      <input
        name={field.label}
        defaultValue={value}
        onBlur={(e) =>
          updateFieldValue({
            variables: {
              salesforceDealId: salesforceDeal.id,
              attributes: {
                fieldName: field.fieldName,
                value: e.target.value,
              },
            },
          })
        }
      />
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </>
  );
};

export default CheckboxFieldValue;
