import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3332 2.5H1.6665V7.5H18.3332V2.5Z"
        stroke="#BDC4CB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 7.5V16.6667C3.3335 17.1269 3.7066 17.5 4.16683 17.5H15.8335C16.2937 17.5 16.6668 17.1269 16.6668 16.6667V7.5"
        stroke="#BDC4CB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 10.8333H11.6668"
        stroke="#BDC4CB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
