import gql from 'graphql-tag';

export default gql`
  fragment salesforceContactFieldSelectOptionFragment on SalesforceContactFieldSelectOption {
    id
    isActive
    isDefault
    label
    value
  }
`;
