import React from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router';
import {Project, useDeleteProjectMutation, useOpenProjectMutation} from 'api';
import {CircleAlert, DotMenu, Download, List, Trash} from 'components/Ui/Icon';
import {Popover, message, Tooltip} from 'components/antd';
import DownloadModal from './DownloadModal';
import useContract from 'hooks/useContract';
import useProject from 'hooks/useProject';
import useProjectPath from 'hooks/useProjectPath';
import useClientUser from 'hooks/useClientUser';

interface Props {
  project: Project;
}

export default ({project}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const {createPath} = useProjectPath();
  const [isDownloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const {isPreleadDownloadable, isProjectAddable} = useContract();
  const {isOwnProject} = useProject(project.uuid);
  const {isMember, isAdmin} = useClientUser();

  const [open] = useOpenProjectMutation({
    refetchQueries: ['projects'],
    onCompleted: () => history.push(createPath(`projects${location.search}`)),
  });

  const [deleteProject] = useDeleteProjectMutation({
    refetchQueries: ['projects'],
    onCompleted: () => {
      history.push(createPath(`projects${location.search}`));
      message.info('営業リストを削除しました');
    },
  });

  const handleDeleteProject = () => {
    deleteProject({
      variables: {
        uuid: project.uuid,
      },
    });
  };

  const makeOpen = React.useCallback(() => {
    open({
      variables: {
        uuid: project.uuid,
      },
    });
  }, []);

  if (!project) return null;

  return (
    <Container>
      <ActionBox>
        <div>
          <List />
        </div>

        <div>
          <Label>プレリード件数</Label>
          <Count>{project.count.toLocaleString()}</Count>
        </div>
      </ActionBox>

      {isOwnProject && isMember && (
        <Popover
          content={
            <PopoverMenu>
              {isPreleadDownloadable && isAdmin && (
                <MenuItem onClick={() => setDownloadModalOpen(true)}>
                  <div>
                    <Download />
                    <span>ダウンロード</span>
                  </div>
                </MenuItem>
              )}

              {isProjectAddable ? (
                <OpenButton
                  onClick={() => {
                    if (confirm('営業リストをOpenしてよろしいですか？')) {
                      makeOpen();
                    }
                  }}>
                  <div>
                    <CircleAlert />
                    <span>Open</span>
                  </div>
                </OpenButton>
              ) : (
                <Tooltip
                  title="営業リスト数が上限に達しました。"
                  color={`#EB5757`}
                  placement="top">
                  <OpenButton disabled>
                    <div>
                      <CircleAlert />
                      <span>Open</span>
                    </div>
                  </OpenButton>
                </Tooltip>
              )}

              <DeleteProjectButton
                onClick={() => {
                  if (confirm('営業リストを削除してよろしいですか？')) {
                    handleDeleteProject();
                  }
                }}>
                <div>
                  <Trash />
                  <span>営業リストを削除する</span>
                </div>
              </DeleteProjectButton>
            </PopoverMenu>
          }
          placement="bottomLeft"
          trigger="hover">
          <MenuWrapper>
            <DotMenu />
          </MenuWrapper>
        </Popover>
      )}

      {isDownloadModalOpen && (
        <DownloadModal
          project={project}
          isOpen={isDownloadModalOpen}
          setModal={setDownloadModalOpen}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const ActionBox = styled.div`
  margin-right: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 8px 15px;
  width: 150px;
  height: 44px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;

  > div:first-child {
    margin-right: 12px;
    display: flex;
    align-items: center;
  }
`;

const Count = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: #495058;
`;

const Label = styled.span`
  display: block;
  transform: scale(0.7);
  transform-origin: left;
  font-weight: bold;
  font-size: 10px;
  color: #bdc4cb;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const PopoverMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #bdc4cb;
`;

const MenuItem = styled.div<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  padding: 5px;
  height: 45px;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};

  div {
    display: flex;
    width: 100%;
    height: 100%;

    svg {
      margin: auto 13px auto 10px;
    }
    span {
      margin: auto 0;
      font-weight: bold;
      font-size: 12px;
      color: #495058;
    }

    &:hover {
      background: #f1f8ff;
      border-radius: 4px;
    }
  }
`;

const OpenButton = styled(MenuItem)`
  border-top: 1px solid #e1e6eb;

  div {
    svg {
      path {
        stroke: ${({disabled}) => (disabled ? '#bdc4cb' : '#38ca73')};
      }
    }
    span {
      color: ${({disabled}) => (disabled ? '#bdc4cb' : '#38ca73')};
    }

    &:hover {
      background: ${({disabled}) => (disabled ? '#f1f8ff' : '#d9f4e4')};
    }
  }
`;

const DeleteProjectButton = styled(MenuItem)`
  border-top: 1px solid #e1e6eb;

  div {
    svg {
      path {
        stroke: #bdc4cb;
      }
    }
    span {
      color: #495058;
    }
  }
`;
