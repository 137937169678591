import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {MailThread, GmailImportMailThread} from 'api';
import Messages from './Messages';

interface GmailImport extends MailThread {
  threadable: GmailImportMailThread;
}

interface Props {
  mailThread: GmailImport;
}

export default ({mailThread}: Props) => {
  const history = useHistory();
  const {
    threadable: {gmailMessage},
    mailMessages,
  } = mailThread;

  return (
    <Container>
      <a onClick={history.goBack}>&lt; 戻る</a>
      <h2>{gmailMessage.subject}</h2>
      <Messages mailMessages={mailMessages} />
    </Container>
  );
};

const Container = styled.div`
  h2 {
    font-weight: 500;
    font-size: 16px;
    margin-top: 1rem;
  }
`;
