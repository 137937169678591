import {useProjectQuery} from 'api';
import {
  UploadFile,
  Calendar,
  Clock,
  Mail,
  Note,
  Speaker,
} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
  });

  if (!project) return null;

  return (
    <Container>
      <Body>
        <Item>
          <UploadFile />
          <Name style={{width: 90}}>ファイル名</Name>
          <ValueWrapper>
            {project.importCsvs?.map((importCsv, key) => (
              <Value
                key={key}
                style={{display: 'block', float: 'right', marginBottom: 10}}>
                {importCsv.filename}
              </Value>
            ))}
          </ValueWrapper>
        </Item>
        <Item>
          <Calendar />
          <Name>CSV合計</Name>
          <Value>{project.count.toLocaleString()}</Value>
        </Item>
        <Item>
          <Note />
          <Name>企業名寄特定</Name>
          <Value>{project.countFixed.toLocaleString()}</Value>
        </Item>
        <Item>
          <Speaker />
          <Name>名寄候補複数</Name>
          <Value>{project.countMultiple.toLocaleString()}</Value>
        </Item>
        <Item>
          <Clock />
          <Name>重複企業件数</Name>
          <Value>{project.countDuplicate.toLocaleString()}</Value>
        </Item>
        <Item>
          <Mail />
          <Name>URLあり</Name>
          <Value>{project.countUrl.toLocaleString()}</Value>
        </Item>
      </Body>
    </Container>
  );
};

const Container = styled.div``;

const Body = styled.div`
  width: 378px;
`;

const Item = styled.div`
  margin-top: 15px;
  display: flex;
  svg {
    margin-right: 15px;
  }
`;

const Name = styled.span`
  margin-right: 15px;
  width: 200px;
  font-weight: bold;
  font-size: 14px;
  color: #bdc4cb;
`;

const Value = styled.span`
  margin-left: auto;
  font-size: 14px;
`;

const ValueWrapper = styled.div`
  width: 250px;
`;
