import React from 'react';
import useSearchParams from '../../../../useSearchParams';

type CustomizeItemFilterItem = {
  customizeItemId: string;
  from: string;
  to: string;
  index: number;
};

export default ({
  customizeItemId,
  from,
  to,
  index,
}: CustomizeItemFilterItem) => {
  const {query, setQuery} = useSearchParams();

  if (!customizeItemId) {
    return <></>;
  }

  return (
    <div className="flex gap-3 items-center">
      <input
        className="p-2"
        type="date"
        value={from ?? ''}
        onChange={(e) => {
          setQuery({
            ...query,
            prospectCustomizeItemSearchConditions: [
              ...query.prospectCustomizeItemSearchConditions.slice(0, index),
              {
                customize_item_id: customizeItemId,
                data_type: 'date',
                from: e.target.value,
                to: query.prospectCustomizeItemSearchConditions[index].to,
              },
              ...query.prospectCustomizeItemSearchConditions.slice(index + 1),
            ],
          });
        }}
      />
      <span> ~ </span>
      <input
        className="p-2"
        type="date"
        value={to ?? ''}
        min={from}
        onChange={(e) => {
          setQuery({
            ...query,
            prospectCustomizeItemSearchConditions: [
              ...query.prospectCustomizeItemSearchConditions.slice(0, index),
              {
                customize_item_id: customizeItemId,
                data_type: 'date',
                from: query.prospectCustomizeItemSearchConditions[index].from,
                to: e.target.value,
              },
              ...query.prospectCustomizeItemSearchConditions.slice(index + 1),
            ],
          });
        }}
      />
    </div>
  );
};
