import React from 'react';
import styled from 'styled-components';
import {Task} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;

  return (
    <Container>
      <div>{task.description}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default Cell;
