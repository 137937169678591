import gql from 'graphql-tag';

export default gql`
  fragment activityAttachmentFragment on ActivityAttachment {
    uuid
    file
    fileName
    fileContentType
    fileSize
    createdAt
  }
`;
