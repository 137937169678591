import React from 'react';
import {
  useSalesforceLeadRecordTypeByRecordTypeIdQuery,
  SalesforceLeadField,
} from 'api';
import useSearchParams from '../../../../../useSearchParams';
import {Listbox} from '@headlessui/react';
import ArrowDownIcon from './ArrowDownIcon';

type CustomizeItemFilterItem = {
  leadFieldId: string;
  index: number;
};

export default ({leadFieldId, index}: CustomizeItemFilterItem) => {
  const {query, setQuery} = useSearchParams();
  const {
    data: {
      salesforceLeadRecordTypeByRecordTypeId: {salesforceLeadFields = []} = {},
    } = {},
  } = useSalesforceLeadRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceLeadRecordTypeId: query.salesforceLeadRecordTypeId,
    },
    skip: !query.salesforceLeadRecordTypeId,
  });

  const selectedFields = () => {
    return query.salesforceLeadSearchConditions.map(
      (condition) => condition['lead_field_id'],
    );
  };

  const selectedItem = (id: string): SalesforceLeadField => {
    return salesforceLeadFields.find(
      (salesforceLeadField) => salesforceLeadField.id === id,
    );
  };

  const customizeItems = React.useMemo(() => {
    return salesforceLeadFields.filter(
      (item) =>
        (item.fieldType === 'select' || item.fieldType === 'checkbox') &&
        item.fieldName.endsWith('__c') &&
        !selectedFields().includes(item.id),
    );
  }, [salesforceLeadFields]);

  return (
    <div className="relative">
      <Listbox
        value={leadFieldId ?? ''}
        onChange={(value) => {
          setQuery({
            ...query,
            salesforceLeadSearchConditions: [
              ...query.salesforceLeadSearchConditions.slice(0, index),
              {
                lead_field_id: value,
                field_type: selectedItem(value)?.fieldType,
              },
              ...query.salesforceLeadSearchConditions.slice(index + 1),
            ],
          });
        }}>
        <Listbox.Button className="relative px-3 text-left focus:outline-none flex items-center border rounded w-80 h-8 bg-white border-[#d9d9d9] justify-between truncate">
          <span>{selectedItem(leadFieldId)?.label}</span>
          <ArrowDownIcon />
        </Listbox.Button>
        <Listbox.Options className="absolute">
          {customizeItems.map((salesforceLeadField) => (
            <Listbox.Option
              className={({active}) =>
                `relative select-none py-2 px-3 cursor-pointer w-80 h-8 pr-10 truncate z-20 ${
                  active ? 'bg-gray-100' : 'bg-white'
                }`
              }
              key={salesforceLeadField.id}
              value={salesforceLeadField.id}>
              {salesforceLeadField.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};
