import React from 'react';
import {useParams} from 'react-router-dom';
import {useStepQuery} from 'api';
import AutomaticMailForm from './AutomaticMail';
import ManualMailForm from './ManualMail';
import CallForm from './Call';
import TaskForm from './Task';

const stepComponents: {[key: string]: any} = {
  'Step::AutomaticMail': AutomaticMailForm,
  'Step::ManualMail': ManualMailForm,
  'Step::Call': CallForm,
  'Step::Task': TaskForm,
};

const StepModalComponent = () => {
  const {stepId} = useParams<{stepId: string}>();

  const {data: {step = null} = {}} = useStepQuery({
    variables: {uuid: stepId},
    skip: !stepId,
  });

  const visible = React.useMemo(() => !!step, [step]);
  const StepComponent = React.useMemo(
    () => (step ? stepComponents[step.steppableType] : null),
    [step],
  );

  return StepComponent ? <StepComponent step={step} visible={visible} /> : null;
};

export default StepModalComponent;
