import {NumberParam, StringParam, useQueryParams} from 'use-query-params';

const PAGE_SIZE = 50;
const searchParamsDef = {
  page: NumberParam,
  mailCampaignUuid: StringParam,
  firstOpenedFrom: StringParam,
  firstOpenedTo: StringParam,
  clickedFrom: StringParam,
  clickedTo: StringParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {page} = query;
  const searchParams = {
    mailCampaignUuid: query.mailCampaignUuid,
    firstOpenedFrom: query.firstOpenedFrom,
    firstOpenedTo: query.firstOpenedTo,
    clickedFrom: query.clickedFrom,
    clickedTo: query.clickedTo,
  };
  const setPage = (page: number) => setQuery({page});

  let numberOfFiltersInUse = 0;
  if (query.mailCampaignUuid) numberOfFiltersInUse++;
  if (query.firstOpenedFrom || query.firstOpenedTo) numberOfFiltersInUse++;
  if (query.clickedFrom || query.clickedTo) numberOfFiltersInUse++;

  return {
    query,
    setQuery,
    searchParams: searchParams,
    page,
    perPage: PAGE_SIZE,
    setPage,
    numberOfFiltersInUse,
  };
};

export default useSearchParams;
