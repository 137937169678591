import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Dialog, Transition, Listbox} from '@headlessui/react';
import telCategoryColor from 'helpers/telCategoryColor';
import {Quill, QuillWrapper, StatusEclipse} from 'components/Ui';
import {useParams} from 'react-router-dom';
import {
  Activity,
  useGetPreleadProjectsQuery,
  usePreleadQuery,
  useProjectQuery,
  useUpdateZoomPhoneWebhookCallPreleadActivityMutation,
  ZoomPhoneWebhookCallActivity,
} from 'api';
import ReceptionNgModal from 'components/Prelead/Tel/ReceptionNgModal';
import ContactNgModal from 'components/Prelead/Tel/ContactNgModal';
import CallNgModal from 'components/Prelead/Tel/CallNgModal';
import _ from 'lodash';

interface ActivityType extends Activity {
  resource: ZoomPhoneWebhookCallActivity;
}

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  activity: ActivityType;
}

const UpdatePreleadModal = ({isOpen, closeModal, activity}: Props) => {
  const {preleadId} = useParams<{
    preleadId: string;
  }>();

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const [projectId, setProjectId] = useState(prelead?.lastProjectId);

  const {data: {prelead: {projects = []} = {}} = {}} =
    useGetPreleadProjectsQuery({
      variables: {uuid: preleadId},
      skip: !preleadId,
    });

  const uniqProjects = useMemo(() => _.uniq(projects), [projects]);
  const project = uniqProjects.find((project) => project.id === projectId);

  const {data: {project: {telStatuses = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: project?.uuid},
    skip: !project,
  });

  const [comment, setComment] = useState('');
  const [bodyPlainText, setBodyPlainText] = useState(null);
  const [status, setStatus] = useState(null);
  const telStatus = useMemo(
    () => telStatuses.find((telStatus) => telStatus.id === status),
    [status],
  );
  const [ngCallModalVisible, setCallNgModalVisible] = useState(false);
  const [callNgReason, setCallNgReason] = useState(null);
  const [ngReceptionModalVisible, setReceptionNgModalVisible] = useState(false);
  const [receptionNgReason, setReceptionNgReason] = useState(null);
  const [ngContactModalVisible, setContactNgModalVisible] = useState(false);
  const [contactNgReason, setContactNgReason] = useState(null);

  const [updatePreleadActivity] =
    useUpdateZoomPhoneWebhookCallPreleadActivityMutation({
      variables: {
        resourceId: activity.resource.id,
        preleadUuid: prelead.uuid,
        projectUuid: project?.uuid,
        attributes: {
          body: comment === `<p><br></p>` ? '' : comment,
          bodyPlainText: bodyPlainText,
          clientTelStatusId: status,
          projectCallNgReasonId: callNgReason?.id,
          projectReceptionNgReasonId: receptionNgReason?.id,
          projectContactNgReasonId: contactNgReason?.id,
        },
      },
    });

  const handleSubmit = () => {
    updatePreleadActivity();
    closeModal();
  };

  useEffect(() => {
    if (!status) return;

    const telStatus = telStatuses.find((telStatus) => telStatus.id === status);
    if (!telStatus) return;

    if (telStatus?.category === 'call_ng') {
      setCallNgModalVisible(true);
    } else if (telStatus?.category === 'reception_ng') {
      setReceptionNgModalVisible(true);
    } else if (telStatus?.category === 'contact_ng') {
      setContactNgModalVisible(true);
    }

    setCallNgReason(null);
    setReceptionNgReason(null);
    setContactNgReason(null);
  }, [status]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-xl transform rounded bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h4"
                  className="h-10 flex items-center px-4 bg-c-bg text-c-base">
                  TELログを登録
                </Dialog.Title>
                <div className="p-4">
                  <Listbox
                    value={projectId}
                    onChange={(value) => setProjectId(value)}>
                    <div className="relative mt-1 flex-1 mb-4">
                      <Listbox.Button className="relative bg-white w-full h-8 cursor-default rounded pl-3 pr-10 text-left focus:outline-none flex items-center border border-c-border">
                        <span className="flex items-center gap-1 flex-1">
                          {project && project.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5">
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          {uniqProjects.map((project) => (
                            <Listbox.Option
                              key={project.uuid}
                              className={({active}) =>
                                `relative cursor-default select-none py-2 px-3 ${
                                  active ? 'bg-gray-100' : 'bg-white'
                                }`
                              }
                              value={project.id}>
                              {project.name}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                  <QuillWrapper>
                    <Quill
                      placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
                      theme="snow"
                      value={comment}
                      onChange={(content, delta, source, editor) => {
                        setComment(content);
                        setBodyPlainText(editor.getText());
                      }}
                      modules={{
                        toolbar: [
                          [{header: [1, 2, 3, false]}],
                          ['bold', 'italic', 'underline'],
                          ['code-block'],
                          ['link'],
                        ],
                      }}
                      formats={[
                        'header',
                        'list',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'color',
                        'background',
                        'code-block',
                        'link',
                      ]}
                      bounds={'#quill-container'}
                    />
                    <div id="quill-container" />
                    <div className="mt-2">
                      <Listbox
                        value={status}
                        onChange={(value) => setStatus(value)}>
                        <div className="relative mt-1 flex-1">
                          <Listbox.Button className="relative bg-white w-full h-8 cursor-default rounded pl-3 pr-10 text-left focus:outline-none flex items-center border border-c-border">
                            <span className="flex items-center gap-1 flex-1">
                              {telStatus && (
                                <StatusEclipse
                                  color={telCategoryColor(telStatus?.category)}
                                />
                              )}
                              &nbsp; {telStatus?.name}
                              {callNgReason && `  |  ${callNgReason.reason}`}
                              {receptionNgReason &&
                                `  |  ${receptionNgReason.reason}`}
                              {contactNgReason &&
                                `  |  ${contactNgReason.reason}`}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-5 h-5">
                                <path
                                  fillRule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {telStatuses?.map((telStatus) => (
                                <Listbox.Option
                                  key={telStatus.id}
                                  className={({active}) =>
                                    `relative cursor-default select-none py-2 px-3 ${
                                      active ? 'bg-gray-100' : 'bg-white'
                                    }`
                                  }
                                  value={telStatus.id}>
                                  <StatusEclipse
                                    color={telCategoryColor(telStatus.category)}
                                  />
                                  &nbsp; {telStatus.name}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                      <button
                        disabled={
                          !comment.replace(/<\/?[^>]+(>|$)/g, '') && !status
                        }
                        onClick={handleSubmit}
                        className="cursor-pointer bg-white border border-c-border text-sm hover:opacity-50 rounded-sm inline-flex items-center justify-center disabled:bg-c-bg h-8 w-16">
                        登録
                      </button>
                    </div>
                  </QuillWrapper>
                </div>
                {ngCallModalVisible && (
                  <CallNgModal
                    projectId={project?.uuid}
                    isModalVisible={ngCallModalVisible}
                    setModalVisible={setCallNgModalVisible}
                    ngReason={callNgReason}
                    setNgReason={setCallNgReason}
                  />
                )}
                {ngReceptionModalVisible && (
                  <ReceptionNgModal
                    projectId={project?.uuid}
                    isModalVisible={ngReceptionModalVisible}
                    setModalVisible={setReceptionNgModalVisible}
                    ngReason={receptionNgReason}
                    setNgReason={setReceptionNgReason}
                  />
                )}
                {ngContactModalVisible && (
                  <ContactNgModal
                    projectId={project?.uuid}
                    isModalVisible={ngContactModalVisible}
                    setModalVisible={setContactNgModalVisible}
                    ngReason={contactNgReason}
                    setNgReason={setContactNgReason}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdatePreleadModal;
