import gql from 'graphql-tag';

export default gql`
  fragment prospectCreateActivityFragment on ProspectCreateActivity {
    id
    prospectPool {
      uuid
      pool {
        uuid
      }
      prospect {
        lastName
        firstName
      }
    }
  }
`;
