import gql from 'graphql-tag';

export default gql`
  fragment formBlockFieldStandardFragment on FormBlockFieldStandard {
    id
    standardFieldType
    isRequired
    standardFieldTypeText
    label
    labelFirstName
    labelLastName
    createdAt
    updatedAt
  }
`;
