import React from 'react';
import moment from 'moment';
import {Input, Form, DatePicker, Select} from 'components/antd';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  useCreateSalesforceDealSalesforceContactMutation,
  useSalesforceDealRecordTypesQuery,
  useSalesforceUsersQuery,
  useSalesforceOpportunityStagesQuery,
  ProspectPool,
  SalesforceDealField,
  useCurrentUserQuery,
} from 'api';
import locale from 'antd/es/date-picker/locale/ja_JP';
import useSalesforceModalContext from '../../../useSalesforceModalContext';
import Back from '../../Back';
import Footer from '../../Footer';
import {useHistory} from 'react-router-dom';
import DealFieldItem from './DealFieldItem';
import {DealFormItem} from '../DealFormItem';

interface Props {
  prospectPool: ProspectPool;
}

export interface FormikValues {
  attributes: {
    name: string;
    salesforceUserId: string;
    salesforceOpportunityStageId: string;
    closeDate: moment.Moment;
    salesforceDealRecordTypeId: string;
    addition: {[key: string]: any};
  };
  deal: {
    userId: string;
    date: moment.Moment;
  };
}

const DealToContactForm = ({prospectPool = {}}: Props) => {
  const history = useHistory();
  const [selectedRecordType, setSelectedRecordType] = React.useState(null);

  const {poolId} = useSalesforceModalContext();

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId: poolId},
    skip: !poolId,
  });

  const {data: {currentUser = null} = {}} = useCurrentUserQuery({});

  const {data: {salesforceOpportunityStages = []} = {}} =
    useSalesforceOpportunityStagesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {poolId: poolId},
      skip: !poolId,
    });

  const [create, {loading}] = useCreateSalesforceDealSalesforceContactMutation({
    onCompleted: (data) => {
      if (!data.createSalesforceDealSalesforceContact.error) {
        formik.resetForm();
      }
      history.goBack();
    },
    refetchQueries: ['prospectPool'],
  });

  const today = React.useMemo(() => moment(), []);

  const initialValues: FormikValues = {
    attributes: {
      name: '',
      salesforceUserId: salesforceUsers.length > 0 ? salesforceUsers[0].id : '',
      salesforceOpportunityStageId:
        salesforceOpportunityStages.length > 0
          ? salesforceOpportunityStages[0].id
          : '',
      closeDate: today,
      salesforceDealRecordTypeId: '',
      addition: {},
    },
    deal: {
      userId: currentUser?.id ?? '',
      date: today,
    },
  };

  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      attributes: Yup.object().shape({
        name: Yup.string().required('必須項目です'),
        salesforceUserId: Yup.string().required('必須項目です'),
        salesforceOpportunityStageId: Yup.string().required('必須項目です'),
        closeDate: Yup.date().required('必須項目です'),
      }),
      deal: Yup.object().shape({
        date: Yup.date().required('必須項目です'),
      }),
    }),
    onSubmit: (values) => {
      const copy = Object.assign({}, values.attributes);
      create({
        variables: {
          uuid: prospectPool.uuid,
          attributes: copy,
          dealAttributes: {
            userId: values.deal.userId,
            date: values.deal.date,
          },
        },
      });
    },
  });

  const onRecordTypeSelect = (id: string) => {
    const selectedRecordType = salesforceDealRecordTypes.find(
      (recordType) => recordType.id === id,
    );
    formik.setFieldValue('attributes.addition', {});
    setSelectedRecordType(selectedRecordType);
  };

  const salesforceDealFields = React.useMemo(() => {
    if (!selectedRecordType) {
      return [];
    }
    const visibleFields = selectedRecordType?.salesforceDealFields.filter(
      (salesforceDealField: SalesforceDealField) =>
        salesforceDealField.isDisplay === true,
    );

    return visibleFields;
  }, [selectedRecordType]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="relative w-full flex-1 overflow-y-auto px-8 py-6">
        <Back label="商談" />
        <div className="mt-4 w-full rounded bg-white p-4">
          <Form layout="vertical">
            {salesforceDealRecordTypes.length > 0 && (
              <>
                <Form.Item label="レコードタイプ">
                  <Select
                    onChange={(value: string) => {
                      formik.setFieldValue('salesforceDealRecordTypeId', value);
                      onRecordTypeSelect(value);
                    }}>
                    {salesforceDealRecordTypes.map((recordType) => (
                      <Select.Option key={recordType.id} value={recordType.id}>
                        {recordType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <hr className="my-8 border-b border-c-border" />
              </>
            )}

            <Form.Item
              label="商談名"
              required
              validateStatus={formik.errors.attributes?.name && 'error'}
              help={formik.errors.attributes?.name}>
              <Input {...formik.getFieldProps('attributes.name')} />
            </Form.Item>

            <Form.Item
              label="所有者"
              required
              validateStatus={
                formik.errors.attributes?.salesforceUserId && 'error'
              }
              help={formik.errors.attributes?.salesforceUserId}>
              <Select
                value={formik.values.attributes?.salesforceUserId}
                showSearch
                optionFilterProp="children"
                onChange={(value) =>
                  formik.setFieldValue('attributes.salesforceUserId', value)
                }>
                {salesforceUsers.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <DealFormItem formik={formik} />

            <Form.Item
              label="フェーズ"
              required
              validateStatus={
                formik.errors.attributes?.salesforceOpportunityStageId &&
                'error'
              }
              help={formik.errors.attributes?.salesforceOpportunityStageId}>
              <Select
                value={formik.values.attributes?.salesforceOpportunityStageId}
                onChange={(value) =>
                  formik.setFieldValue(
                    'attributes.salesforceOpportunityStageId',
                    value,
                  )
                }>
                {salesforceOpportunityStages.map((stage) => (
                  <Select.Option key={stage.id} value={stage.id}>
                    {stage.masterLabel}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="完了予定日"
              required
              validateStatus={formik.errors.attributes?.closeDate && 'error'}
              help={formik.errors.attributes?.closeDate}>
              <DatePicker
                value={formik.values.attributes?.closeDate}
                locale={locale}
                style={{width: '200px'}}
                onChange={(date) =>
                  formik.setFieldValue('attributes.closeDate', date)
                }
              />
            </Form.Item>

            {salesforceDealFields.map(
              (field: SalesforceDealField, i: number) => (
                <DealFieldItem key={i} field={field} formik={formik} />
              ),
            )}
          </Form>
        </div>
      </div>
      <Footer
        onClick={() => formik.submitForm()}
        label="登録"
        disabled={loading || !formik.isValid || !formik.dirty}
      />
    </div>
  );
};

export default DealToContactForm;
