import {useCurrentClientQuery} from 'api';
import {miitelCall} from '../../useMiitel';
import React from 'react';

interface Props {
  telephoneNumber: string;
}

export default ({telephoneNumber}: Props) => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="w-[84px]">
      <a
        onClick={() =>
          currentClient.miitelSetting?.isAvailable &&
          currentClient.miitelSetting?.isActive &&
          miitelCall(telephoneNumber)
        }
        href={
          currentClient.miitelSetting?.isAvailable &&
          currentClient.miitelSetting?.isActive
            ? null
            : currentClient.zoomPhoneActive
            ? `zoomphonecall:${telephoneNumber}`
            : `tel:${telephoneNumber}`
        }>
        {telephoneNumber}
      </a>
    </div>
  );
};
