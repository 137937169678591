import React from 'react';
import {Approach} from 'api';
import {Row} from 'react-table';
import {
  PhoneIcon,
  EnvelopeIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';

interface Props {
  row: Row<Approach>;
}

const Cell = ({row}: Props) => {
  const approach = row.original;

  switch (approach.approachType) {
    case 'call':
      return (
        <div className="flex">
          <div className="flex items-center gap-1 rounded-sm text-[#0DC8F1] bg-[#DCF9FF] h-5 text-xs px-2">
            <PhoneIcon className="w-3" />
            {approach.approachTypeText}
          </div>
        </div>
      );
    case 'manual_mail':
      return (
        <div className="flex">
          <div className="flex items-center gap-1 rounded-sm text-[#F99985] bg-[#FFEEEB] h-5 text-xs px-2">
            <EnvelopeIcon className="w-3" />
            {approach.approachTypeText}
          </div>
        </div>
      );
    case 'task':
      return (
        <div className="flex">
          <div className="flex items-center gap-1 rounded-sm text-[#FFC600] bg-[#FEF5D6] h-5 text-xs px-2">
            <DocumentCheckIcon className="w-3" />
            {approach.approachTypeText}
          </div>
        </div>
      );
  }

  return null;
};

export default Cell;
