import {usePreleadQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';
import Tel from './Tel';
import Copy from '../Copy';
import Add from './Add';

export default () => {
  const {preleadId} = useParams<{
    preleadId: string;
  }>();

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    fetchPolicy: 'cache-only',
  });

  return (
    <tr className="hover:bg-[#F4F9FD] w-40 rounded group">
      <th>代表電話</th>
      <td>
        <Tel telephoneNumber={prelead.telephoneNumber} />
        <Copy copyText={prelead.telephoneNumber} />
        <Add />
      </td>
    </tr>
  );
};
