import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {
  useSalesforceDealRecordTypesQuery,
  useSalesforceDealRecordTypeQuery,
  SalesforceDealField,
  useSalesforceUsersQuery,
} from 'api';
import {useParams} from 'react-router-dom';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();

  const {data: {salesforceDealRecordTypes = []} = {}} =
    useSalesforceDealRecordTypesQuery({
      variables: {
        poolId: poolId,
      },
    });

  const {data: {salesforceUsers = []} = {}} = useSalesforceUsersQuery({
    variables: {poolId},
  });

  const {
    data: {salesforceDealRecordType: {salesforceDealFields = []} = {}} = {},
  } = useSalesforceDealRecordTypeQuery({
    variables: {salesforceDealRecordTypeId: query.salesforceDealRecordTypeId},
    skip: !query.salesforceDealRecordTypeId,
  });

  if (!query?.salesforceDealRecordTypeId && !query?.salesforceDealUserId) {
    return null;
  }

  const targetSalesforceDealRecordType = () => {
    return salesforceDealRecordTypes.find(
      (salesforceDealRecordType) =>
        salesforceDealRecordType.id === query.salesforceDealRecordTypeId,
    );
  };

  const targetSalesforceUser = () => {
    return salesforceUsers.find(
      (salesforceUser) => salesforceUser.id === query.salesforceDealUserId,
    );
  };

  const customizeItemById = (id: string): SalesforceDealField => {
    return salesforceDealFields.find((field) => field.id === id);
  };

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">商談</h4>
        <button
          onClick={() => setFilterType('salesforceDeal')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              salesforceDealRecordTypeId: '',
              salesforceDealUserId: '',
              salesforceDealSearchConditions: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      {targetSalesforceDealRecordType() && (
        <div className="flex gap-1">
          <div className="flex items-center gap-2">
            <span className="text-c-light">レコードタイプ：</span>
            <span className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {targetSalesforceDealRecordType()?.name}
            </span>
          </div>
        </div>
      )}
      {targetSalesforceUser() && (
        <div className="flex gap-1">
          <div className="flex items-center gap-2">
            <span className="text-c-light">所有者：</span>
            <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
              {[
                targetSalesforceUser()?.lastName,
                targetSalesforceUser()?.firstName,
              ]
                .filter(Boolean)
                .join(' ')}
            </span>
          </div>
        </div>
      )}
      <div className="flex flex-wrap flex-col gap-2">
        {query?.salesforceDealSearchConditions.map((item, index) => {
          return (
            <div key={index} className="flex gap-3">
              {customizeItemById(item['salesforce_deal_field_id'])
                ?.fieldType === 'multi_select' && (
                <>
                  <div className="text-[#899098] text-sm min-w-28 text-center truncate">
                    {item['values']?.length > 0 &&
                      `${
                        customizeItemById(item['salesforce_deal_field_id'])
                          ?.label
                      }:`}
                  </div>
                  <div className="flex flex-wrap gap-1">
                    {item['values']?.length > 0 &&
                      item['values'].map((option: string, index: number) => {
                        return (
                          <div
                            key={index}
                            className="min-w-16 h-5 bg-[#F3F5F7] border border-[#E2E6EA] text-center truncate text-sm px-2 rounded-sm">
                            {
                              customizeItemById(
                                item['salesforce_deal_field_id'],
                              )?.salesforceDealFieldSelectOptions?.find(
                                (item) => item.id === option,
                              )?.label
                            }
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
              {customizeItemById(item['salesforce_deal_field_id'])
                ?.fieldType === 'select' && (
                <>
                  <div className="text-[#899098] text-sm min-w-28 text-center truncate">
                    {item['field_select_option_id'] &&
                      `${
                        customizeItemById(item['salesforce_deal_field_id'])
                          ?.label
                      }:`}
                  </div>
                  <div className="flex flex-wrap gap-1">
                    {
                      <div
                        key={index}
                        className="min-w-16 h-5 bg-[#F3F5F7] border border-[#E2E6EA] text-center truncate text-sm px-2 rounded-sm">
                        {
                          customizeItemById(
                            item['salesforce_deal_field_id'],
                          )?.salesforceDealFieldSelectOptions?.find(
                            (option) =>
                              option.id === item['field_select_option_id'],
                          )?.label
                        }
                      </div>
                    }
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
