import React from 'react';
import {Prelead} from 'api';
import {Row} from 'react-table';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

interface Props {
  row: Row<Prelead>;
  value: any;
}

const NameCell = ({row, value}: Props) => {
  const prelead: Prelead = row.original;

  return (
    <CompanyName
      to={`/list/preleads/${prelead.uuid}/timeline${location.search}`}>
      {value}
    </CompanyName>
  );
};

export default NameCell;

const CompanyName = styled(Link)`
  flex: 1;
`;
