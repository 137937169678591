import gql from 'graphql-tag';

export default gql`
  fragment salesforceDealFragment on SalesforceDeal {
    id
    accountName
    contactFirstName
    contactLastName
    name
    closeDate
    salesforceOpportunityId
    salesforceOpportunityIdString
    prospectPoolId
    createdAt
    updatedAt
    salesforceUserId
    salesforceOpportunityStageId
    salesforceContactId
    addition
  }
`;
