import React from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {useParams, useHistory} from 'react-router-dom';
import {
  Form,
  useCreateFormBlockFieldStandardMutation,
  useCreateFormBlockFieldCustomizeItemMutation,
  useCreateFormBlockFieldOtherMutation,
  useUpdateFormBlockPositionMutation,
} from 'api';
import Block from './Block';
import Toolbox from './Toolbox';
import FormStyleContainer from 'components/FormStyleContainer';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const history = useHistory();
  const {formId} = useParams<{
    formId: string;
  }>();

  const [createFormBlockFieldStandard] =
    useCreateFormBlockFieldStandardMutation({refetchQueries: ['form']});

  const [createFormBlockFieldCustomizeItem] =
    useCreateFormBlockFieldCustomizeItemMutation({refetchQueries: ['form']});

  const [createFormBlockFieldOther] = useCreateFormBlockFieldOtherMutation({
    refetchQueries: ['form'],
  });

  const [updateFormBlockPosition] = useUpdateFormBlockPositionMutation({
    refetchQueries: ['form'],
  });

  const formPage = React.useMemo(
    () => form?.formPages?.find((page) => page.slug === '1'),
    [form],
  );

  const formBlocks = React.useMemo(
    () => formPage?.formBlocks || [],
    [formPage],
  );

  const [blocks, setBlocks] = React.useState(formBlocks);

  const onDragStart = React.useCallback(() => {
    history.push(`/form/forms/${formId}/form/blocks`);
  }, [history, formId]);

  const onDrop = React.useCallback(
    (dropResult) => {
      const {addedIndex, removedIndex, payload} = dropResult;

      if (addedIndex === null) return;

      // standardField
      if (payload?.blockType === 'standardField') {
        createFormBlockFieldStandard({
          variables: {
            uuid: formId,
            slug: formPage?.slug,
            standardFieldType: payload.standardFieldType,
            position: addedIndex,
          },
        });
        return;
      }

      // customizeItemField
      if (payload?.blockType === 'customizeItemField') {
        createFormBlockFieldCustomizeItem({
          variables: {
            uuid: formId,
            slug: formPage?.slug,
            poolProspectCustomizeItemId: payload.poolProspectCustomizeItemId,
            position: addedIndex,
          },
        });
        return;
      }

      // otherField
      if (payload?.blockType === 'otherField') {
        createFormBlockFieldOther({
          variables: {
            uuid: formId,
            title: payload.defaultTitle,
            slug: formPage?.slug,
            position: addedIndex,
            dataType: payload.dataType,
            fieldType: payload.fieldType,
          },
        });
      }

      // reorder
      if (removedIndex === addedIndex) return;
      if (removedIndex === null) return;

      const result = [...blocks];
      const itemToAdd = result.splice(removedIndex, 1)[0];
      result.splice(addedIndex, 0, itemToAdd);
      setBlocks(result);

      updateFormBlockPosition({
        variables: {
          uuid: formId,
          slug: formPage?.slug,
          from: removedIndex,
          to: addedIndex,
        },
      });
    },
    [blocks],
  );

  React.useEffect(() => {
    setBlocks(formBlocks);
  }, [formBlocks]);

  return (
    <>
      <Toolbox form={form} />
      <div className="flex-1">
        <div className="w-full rounded border border-c-border flex items-center justify-center p-12">
          <FormStyleContainer
            form={form}
            className="w-[480px] flex flex-col gap-8">
            <div className="flex flex-col items-center gap-4">
              {form.formLogoFile && (
                <img
                  src={form.formLogoFile}
                  className="max-w-full mb-4"
                  style={{
                    width: `${form.formLogoWidth * form.formLogoScale}px`,
                  }}
                />
              )}
              <h1 className="leading-none text-lg font-bold text-center">
                {form.formTitle}
              </h1>
            </div>
            <Container
              groupName="formBlocks"
              lockAxis="y"
              onDragStart={onDragStart}
              dragHandleSelector=".dragHandle"
              onDrop={onDrop}>
              {blocks?.map((block) => (
                <Draggable key={block.uuid} className="mb-4">
                  <Block block={block} form={form} />
                </Draggable>
              ))}
            </Container>
          </FormStyleContainer>
        </div>
      </div>
    </>
  );
};
