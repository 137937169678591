import React from 'react';
import {useClientPhaseProspectsQuery} from 'api';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import useCondition from '../../useCondition';

const ClientPhaseProspectsFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {clientPhaseProspects = []} = {}} = useClientPhaseProspectsQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  return (
    <>
      <Header title="フェーズ" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={[
              ...clientPhaseProspects.map((clientPhaseProspect) => ({
                text: clientPhaseProspect.name,
                value: clientPhaseProspect.id,
                keyword: clientPhaseProspect.name,
              })),
            ]}
            values={condition.clientPhaseProspects || []}
            onChange={(clientPhaseProspects) =>
              setCondition({clientPhaseProspects})
            }
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({clientPhaseProspects: []})} />
    </>
  );
};

export default ClientPhaseProspectsFilter;
