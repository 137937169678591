import React from 'react';
import styled from 'styled-components';
import Figure from './Figure';
import Table from './Table';

export default () => {
  return (
    <Container>
      <Figure />
      <Table />
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 1047px;
  > div {
    margin-top: 25px;

    &:last-child {
      margin-top: 65px;
    }
  }
`;
