import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {
  ArrowDown,
  Mail,
  PlusSquare,
  Tel,
  Edit,
  Login,
} from 'components/Ui/Icon';
import {Menu, Dropdown} from 'components/antd';
import AddModal from './AddModal';
import {
  PreleadContact,
  useCurrentUserQuery,
  useCurrentClientQuery,
  usePreleadContactsQuery,
  usePreleadProjectQuery,
} from 'api';
import EditModal from './EditModal';
import _ from 'lodash';
import Integration from './Integration';
import useProjectGroups from 'hooks/useProjectGroups';
import NewDealModal from './NewDeal';
import {Link} from 'react-router-dom';
import useProject from 'hooks/useProject';
import useClientUser from 'hooks/useClientUser';
import ConvertModal from './ConvertModal';

export default () => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isNewDealModalVisible, setIsNewDealModalVisible] =
    React.useState(false);
  const [showAll, setShowAll] = useState(false);
  const {isProjectGroupAccessible} = useProjectGroups();

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();
  const {data: {currentClient: {currentLeadContract} = {}} = {}} =
    useCurrentClientQuery();
  const {data: {preleadProject: {prelead = null, project = null} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
      skip: !preleadProjectId,
    });

  const pool = project?.projectGroup?.pool;

  const {data: {preleadContacts = []} = {}} = usePreleadContactsQuery({
    variables: {preleadUuid: prelead?.uuid},
    skip: !prelead,
  });

  const url = pool?.poolSalesforceSetting.instanceUrl?.replace(
    'my.salesforce.com',
    'lightning.force.com',
  );

  const {isOwnProject} = useProject(project?.uuid);
  const {isAdmin} = useClientUser();

  const accessiblePreleadContacts = React.useMemo(
    () =>
      isOwnProject
        ? preleadContacts.filter(
            (preleadContact) =>
              isAdmin ||
              isProjectGroupAccessible(preleadContact.project?.projectGroup),
          )
        : preleadContacts.filter(
            (preleadContact) =>
              preleadContact.project.projectGroupId === project.projectGroup.id,
          ),
    [preleadContacts, isAdmin],
  );

  const Contact = (preleadContact: PreleadContact) => {
    if (!preleadContact) return null;
    return (
      <ContactCard key={preleadContact.uuid}>
        <div>
          <NameBox>
            <Dropdown
              overlay={
                <Menu>
                  <MenuItem
                    key="0"
                    onClick={() => {
                      setSelectedContact(preleadContact);
                      setEditModalVisible(true);
                    }}>
                    <Edit />
                    編集
                  </MenuItem>
                  <MenuItem
                    key="1"
                    onClick={() => {
                      setSelectedContact(preleadContact);
                      setIsNewDealModalVisible(true);
                    }}>
                    <Edit />
                    商談の作成
                  </MenuItem>
                  {(currentUser.role === 'admin' || currentLeadContract) &&
                    (preleadContact?.prospectPool ? (
                      <MenuItem key="2">
                        <Link
                          to={`/pools/${pool?.uuid}/prospects/${preleadContact?.prospectPool.uuid}`}>
                          <span style={{color: '#68B4FB'}}>リードへ遷移</span>
                        </Link>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key="2"
                        onClick={() => {
                          setSelectedContact(preleadContact);
                          setIsConvertModalVisible(true);
                        }}>
                        <Login />
                        リードとして登録
                      </MenuItem>
                    ))}
                </Menu>
              }
              trigger={['click']}>
              <span>
                {preleadContact.lastName}&nbsp;
                {preleadContact.firstName}
                <ArrowDown />
              </span>
            </Dropdown>
          </NameBox>
        </div>
        <div>
          <div>
            <span>
              {_.compact([
                preleadContact.section,
                preleadContact.sectionPosition,
              ]).join(' | ')}
            </span>
          </div>
          <div>
            {preleadContact.email && (
              <>
                <Mail />
                <span>{preleadContact.email}</span>
              </>
            )}
            {preleadContact.telephoneNumber && (
              <>
                <Tel />
                <span>{preleadContact.telephoneNumber}</span>
              </>
            )}
          </div>
        </div>
        {preleadContact.salesforceDealPreleadContacts.map(
          (salesforceDealPreleadContact) => (
            <ToSalesforce key={salesforceDealPreleadContact.id}>
              <Label>
                <span>商談</span>
              </Label>
              <LinkToDeal
                href={`${url}/lightning/r/Opportunity/${salesforceDealPreleadContact.salesforceOpportunityIdString}/view`}
                target="_blank">
                {salesforceDealPreleadContact?.name}
              </LinkToDeal>
            </ToSalesforce>
          ),
        )}
        <div>
          <Integration preleadContact={preleadContact} />
        </div>
      </ContactCard>
    );
  };

  return (
    <Container>
      {preleadContacts?.length > 0 && (
        <ContactSection>
          {showAll
            ? accessiblePreleadContacts?.map((preleadContact) =>
                Contact(preleadContact),
              )
            : accessiblePreleadContacts
                ?.slice(0, 2)
                ?.map((preleadContact) => Contact(preleadContact))}
        </ContactSection>
      )}

      {accessiblePreleadContacts?.length > 2 && (
        <DisplaySection>
          {showAll ? (
            <DisplayButton onClick={() => setShowAll(false)}>
              少なく表示
            </DisplayButton>
          ) : (
            <>
              <span>
                連絡先他&nbsp;{accessiblePreleadContacts?.length - 2}人
              </span>
              <DisplayButton onClick={() => setShowAll(true)}>
                さらに表示
              </DisplayButton>
            </>
          )}
        </DisplaySection>
      )}

      <AddButton hasMargin={accessiblePreleadContacts?.length > 0}>
        <div onClick={() => setAddModalVisible(true)}>
          <PlusSquare />
        </div>
      </AddButton>

      <AddModal
        isModalVisible={isAddModalVisible}
        setModalVisible={setAddModalVisible}
      />
      <EditModal
        isModalVisible={isEditModalVisible}
        setModalVisible={setEditModalVisible}
        preleadContact={selectedContact}
      />
      <NewDealModal
        visible={isNewDealModalVisible}
        onClose={() => setIsNewDealModalVisible(false)}
        preleadContact={selectedContact}
      />
      <ConvertModal
        isModalVisible={isConvertModalVisible}
        setIsModalVisible={setIsConvertModalVisible}
        preleadContact={selectedContact}
        pool={pool}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const AddButton = styled.div<{hasMargin?: boolean}>`
  margin-top: ${({hasMargin}) => (hasMargin ? '10' : '0')}px;
  div {
    height: 16px;
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
`;

const ContactSection = styled.div`
  margin-top: -10px;
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #f3f5f7;

  > div {
    margin-bottom: 7px;

    &:first-child {
      display: flex;
    }

    &:nth-child(2) {
      div {
        display: flex;
        align-items: center;
        span {
          margin-right: 9px;
          font-size: 9px;
          color: #899098;
        }
        svg {
          margin-right: 3px;
          width: 17px;
          height: 13px;
        }
      }
    }

    &:nth-child(3) {
    }
  }
`;

const NameBox = styled.div`
  color: #495058;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #68b5fb;
    svg {
      path {
        stroke: #68b5fb;
      }
    }
  }
  span {
    font-size: 12px;
  }
  svg {
    margin-left: 5px;
    width: 14px;
    height: 14px;
  }
`;

const DisplaySection = styled.div`
  margin-top: 5px;
  font-size: 12px;

  > span:first-child {
    color: #899098;
    margin-right: 10px;
  }
`;

const DisplayButton = styled.span`
  font-size: 12px;
  color: #68b5fb !important;
  cursor: pointer;
`;

const MenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    svg {
      width: 15px;
      height: auto;
      margin-right: 0.5rem;
    }
  }
`;

const ToSalesforce = styled.div`
  display: flex;
`;

const Label = styled.div`
  position: relative;

  width: 42px;
  height: 22px;

  background: #f3f5f7;
  border-radius: 2px;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #899098;
  }
`;

const LinkToDeal = styled.a`
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #68b5fb;
`;
