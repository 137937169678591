import {Tooltip} from 'antd';
import {UserGroupAccounts} from 'components/Ui/Icon';
import React, {useState} from 'react';
import styled from 'styled-components';
import AddAssigneeModal from './AddAssigneeModal';

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Tooltip placement="top" title={'担当者一括変更'}>
        <Action onClick={() => setIsModalVisible(true)}>
          <UserGroupAccounts />
        </Action>
      </Tooltip>
      <AddAssigneeModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #68b5fb;
  width: 34px;
  height: 34px;
  &:hover {
    background: #e9f4ff;
    border-radius: 50%;
  }

  svg {
    path {
      stroke: #68b5fb;
    }
  }
`;
