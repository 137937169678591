import React from 'react';
import styled from 'styled-components';
import {useCurrentClientQuery} from 'api';
import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'components/antd';
import StatusEclipse from 'components/Ui/StatusEclipse';
import telCategoryColor from 'helpers/telCategoryColor';
import New from './New';
import Update from './Update';

export default () => {
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [updateModalItem, setUpdateModalItem] = React.useState(null);

  const {data: {currentClient: {callNgReasonTemplates = []} = {}} = {}} =
    useCurrentClientQuery({});

  return (
    <Container>
      <ButtonWrapper>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setNewModalVisible(true)}>
          新規作成
        </Button>
      </ButtonWrapper>

      <List>
        {callNgReasonTemplates.map((callNgReasonTemplate) => (
          <div
            key={callNgReasonTemplate.id}
            onClick={() => setUpdateModalItem(callNgReasonTemplate)}>
            <div>
              <StatusEclipse color={telCategoryColor('call_ng')} />
              <span>{callNgReasonTemplate.reason}</span>
            </div>
          </div>
        ))}
      </List>

      <New
        visible={newModalVisible}
        onClose={() => setNewModalVisible(false)}
      />
      <Update
        ngTemplate={updateModalItem}
        onClose={() => setUpdateModalItem(null)}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: -53px;
  right: 0px;
`;

const List = styled.div`
  > div {
    height: 48px;
    box-shadow: 0 0 1px #444b52;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-left: solid 3px #fff;
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;

      span {
        margin-left: 8px;
      }
    }
  }
`;
