import React from 'react';
import {SalesforceContactField, SalesforceDealField} from 'api';
import {Checkbox, Form} from 'components/antd';

interface Props {
  addition: {[key: string]: any};
  setAddition: Function;
  field: SalesforceContactField | SalesforceDealField;
}

export const CheckboxItem = ({addition, setAddition, field}: Props) => {
  return (
    <Form.Item label={field.label} required={field.isRequired}>
      <Checkbox
        checked={addition[`${field.fieldName}`] || false}
        onChange={(e) => {
          let newAddition: {[key: string]: any} = Object.assign({}, addition);
          newAddition[`${field.fieldName}`] = e.target.checked;
          setAddition(newAddition);
        }}
      />
    </Form.Item>
  );
};
