import React from 'react';
import {ZoomPhoneBadge} from 'components/Ui/Icon';
import {
  ProspectActivity,
  ProspectPool,
  ZoomPhoneWebhookCallProspectActivity,
  useProspectActivityLazyQuery,
} from 'api';
import {Refresh} from 'components/Ui/Icon';
import ZoomPhone from './ZoomPhone';
import UpdateModal from './UpdateModal';

interface ActivityType extends ProspectActivity {
  resource: ZoomPhoneWebhookCallProspectActivity;
}

interface Props {
  prospectActivity: ActivityType;
  prospectPool: ProspectPool;
}

const ZoomPhoneActivity = ({prospectActivity, prospectPool}: Props) => {
  const {
    zoomPhoneWebhookCall: {callerName, calleeName},
  } = prospectActivity.resource;

  const [getProspectActivity, {loading}] = useProspectActivityLazyQuery({
    variables: {uuid: prospectActivity.uuid},
    fetchPolicy: 'network-only',
  });

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="relative border border-solid border-c-border rounded bg-white w-full z-10 min-h-[200px]">
      <div className="flex items-center w-full h-16 px-4 border-b border-c-border gap-3">
        <div className="z-10 flex w-8 h-auto">
          <ZoomPhoneBadge />
        </div>
        <span className="flex flex-1 items-center">
          {callerName || calleeName}
        </span>
        <button
          onClick={() => getProspectActivity()}
          disabled={loading}
          className="cursor-pointer bg-white border border-c-border hover:opacity-50 w-8 h-8 rounded inline-flex items-center justify-center">
          <Refresh spin={loading} />
        </button>
        <button
          onClick={() => setIsOpen(true)}
          className="cursor-pointer bg-white border border-c-primary text-sm text-c-primary hover:opacity-50 px-4 h-8 rounded inline-flex items-center gap-1">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="w-4"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 5V19M5 12H19"
              className="stroke-c-primary"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"></path>
          </svg>
          <span>ログ</span>
        </button>
      </div>
      <ZoomPhone prospectActivity={prospectActivity} />
      <UpdateModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        prospectPool={prospectPool}
        prospectActivity={prospectActivity}
      />
    </div>
  );
};

export default ZoomPhoneActivity;
