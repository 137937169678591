import React from 'react';
import {useParams} from 'react-router-dom';
import {useTable, useBlockLayout, useResizeColumns} from 'react-table';
import {useProjectQuery, usePreleadProjectsQuery, PreleadProject} from 'api';
import Header from './Header';
import Cell from './Cell';
import useSearchParams from './useSearchParams';
import {useRecoilState} from 'recoil';
import checkedPreleadProjectsAtom from './checkedPreleadProjectsAtom';
import moment from 'moment';
import toJaNum from 'helpers/toJaNum';

const baseColumns = [
  {
    id: 'check',
    accessor: 'check',
    width: 36,
    Header: Header.Check,
    Cell: Cell.Check,
    align: 'center',
    sticky: true,
  },
];

const isUrl = (str: string | null) => /^https?:\/\//.test(str);

export const columnDef: {[key: string]: any} = {
  prelead_uuid: {
    accessor: 'preleadUuid',
    sticky: true,
  },
  serial_number: {
    accessor: 'serialNumber',
    width: 56,
    sticky: true,
  },
  status: {
    accessor: 'statusText',
    width: 36,
    Cell: Cell.Status,
    align: 'center',
    sticky: true,
  },
  user: {
    accessor: 'user',
    width: 100,
    Cell: Cell.User,
    sticky: true,
  },
  company_name: {
    accessor: 'prelead.name',
    Cell: Cell.Name,
    sticky: true,
  },
  remind: {
    Cell: Cell.Remind,
  },
  latest_remind: {
    Cell: Cell.LatestRemind,
  },
  tag: {
    accessor: 'prelead.preleadTags',
    Cell: Cell.Tag,
  },
  nayose: {
    accessor: 'prelead.companyId',
    width: 56,
    Cell: Cell.Nayose,
    align: 'center',
  },
  duplicate: {
    accessor: 'isDuplicate',
    width: 44,
    Cell: Cell.Duplicate,
    align: 'center',
  },
  comment: {
    accessor: 'activityCount',
    width: 72,
    // Cell: Cell.Comment,
    align: 'center',
  },
  alert: {
    accessor: 'prelead.prLastPublishedAt',
    width: 72,
    Cell: Cell.Alert,
    align: 'center',
  },
  click: {
    accessor: 'clickCount',
    width: 72,
    // Cell: Cell.Click,
    align: 'right',
  },
  click_updated_at: {
    Cell: Cell.ClickUpdatedAt,
    width: 170,
  },
  mail_campaign: {
    accessor: 'mailCampaign',
    Cell: Cell.MailCampaign,
    align: 'right',
  },
  activity_mail_campaign_mail_opens_count: {
    accessor: 'activityMailCampaignMailOpensCount',
    Cell: Cell.ActivityMailCampaignMailOpensCount,
    align: 'right',
  },
  activity_mail_campaign_mail_clicks_count: {
    accessor: 'activityMailCampaignMailClicksCount',
    Cell: Cell.ActivityMailCampaignMailClicksCount,
    align: 'right',
  },
  mail_status: {
    accessor: 'mailStatusText',
    // Cell: Cell.Mail,
  },
  mail_updated_at: {
    Cell: Cell.MailUpdatedAt,
    width: 170,
  },
  response_status: {
    accessor: 'responseStatus',
    Cell: Cell.Response,
  },
  latest_tel_status: {
    Cell: Cell.LatestTel,
  },
  tel_status: {
    Cell: Cell.Tel,
  },
  latest_tel_user: {
    Cell: Cell.LatestTelUser,
  },
  tel_count: {
    accessor: (row: PreleadProject) => row.telCount?.toLocaleString(),
    width: 92,
    align: 'right',
  },
  web_site: {
    accessor: 'prelead.webSite',
    Cell: Cell.WebSite,
  },
  mail_address: {
    accessor: 'prelead.mailAddress',
  },
  telephone_number: {
    accessor: 'prelead.telephoneNumber',
  },
  fax_number: {
    accessor: 'prelead.faxNumber',
  },
  representative_person: {
    accessor: 'prelead.representativePerson',
  },
  offices_number: {
    accessor: 'prelead.officesNumber',
    align: 'right',
  },
  corp_number: {
    accessor: 'prelead.corpNumber',
  },
  employee_number: {
    accessor: (row: PreleadProject) =>
      row.prelead.employeeNumber?.toLocaleString(),
    align: 'right',
  },
  capital_fund: {
    accessor: (row: PreleadProject) =>
      row.prelead.capitalFund && `${toJaNum(row.prelead.capitalFund)}円`,
    align: 'right',
  },
  zip_code: {
    accessor: 'prelead.zipCode',
  },
  prefecture: {
    Cell: Cell.Prefecture,
  },
  address: {
    accessor: 'prelead.address',
  },
  account_closing_month: {
    accessor: (row: any) =>
      row.prelead.accountClosingMonth && `${row.prelead.accountClosingMonth}月`,
    width: 72,
  },
  listing_market: {
    accessor: 'prelead.listingMarketText',
    width: 96,
  },
  sales: {
    accessor: (row: any) =>
      row.prelead.sales && `${toJaNum(row.prelead.sales)}円`,
    align: 'right',
  },
  established_year_month: {
    accessor: (row: any) =>
      row.prelead.establishedYearMonth &&
      moment(row.prelead.establishedYearMonth).format('YYYY年MM月'),
  },
  parent_categories: {
    Cell: Cell.MainCategory,
  },
  child_categories: {
    Cell: Cell.SubCategory,
  },
  tel_updated_at: {
    Cell: Cell.TelUpdatedAt,
  },
  latest_tel_updated_at: {
    Cell: Cell.LatestTelUpdatedAt,
  },
  user_of_latest_tel_activity: {
    Cell: Cell.UserOfLatestTelActivity,
  },
  latest_tel_comment: {
    Cell: Cell.LatestTelComment,
  },
  latest_tel_comment_recorded_at: {
    Cell: Cell.LatestTelCommentRecordedAt,
  },
  client_prelead_customize_item_text: {
    Cell: Cell.CustomizeItemText,
  },
  client_prelead_customize_item_number: {
    Cell: Cell.CustomizeItemNumber,
    align: 'right',
  },
  client_prelead_customize_item_date: {
    Cell: Cell.CustomizeItemDate,
  },
  client_prelead_customize_item_select: {
    Cell: Cell.CustomizeItemSelect,
  },
  project_group_prelead_customize_item_text: {
    Cell: Cell.ProjectGroupCustomizeItemText,
  },
  project_group_prelead_customize_item_number: {
    Cell: Cell.ProjectGroupCustomizeItemNumber,
    align: 'right',
  },
  project_group_prelead_customize_item_date: {
    Cell: Cell.ProjectGroupCustomizeItemDate,
  },
  project_group_prelead_customize_item_select: {
    Cell: Cell.ProjectGroupCustomizeItemSelect,
  },
  default: {},
};

const usePreleadTable = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {searchParams, page, perPage, setPage} = useSearchParams();
  const [loading, setLoading] = React.useState(true);

  const {data: {project} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });

  const [, setCheckedPreleadProjects] = useRecoilState(
    checkedPreleadProjectsAtom,
  );

  const {
    data: {preleadProjects: {preleadProjects = [], pagination = {}} = {}} = {},
  } = usePreleadProjectsQuery({
    variables: {
      uuid: projectId,
      search: searchParams,
      page,
      perPage,
    },
    fetchPolicy: 'cache-and-network',
    skip: !project,
    onCompleted: () => {
      setCheckedPreleadProjects([]);
      setLoading(false);
    },
  });

  const paginationData = {
    total: pagination.totalCount,
    current: pagination.currentPage,
    pageSize: perPage,
    onChange: (page: number) => {
      setLoading(true);
      setPage(page);
    },
  };

  const defaultColumn = {
    minWidth: 30,
    width: 150,
    maxWidth: 400,
  };

  const columns = React.useMemo(() => {
    const projectColumns =
      project?.projectColumns
        ?.filter((projectColumn) => columnDef[projectColumn.dataType])
        .map((projectColumn) => {
          const column = columnDef[projectColumn.dataType];

          if (projectColumn.dataColumnKey) {
            return {
              ...column,
              id: projectColumn.id,
              Header: projectColumn.title ?? '',
              accessor: `data.${projectColumn.dataColumnKey}`,
              Cell: ({value}: any) =>
                isUrl(value) ? (
                  <a href={value} target="_blank">
                    {value}
                  </a>
                ) : (
                  <span>{value}</span>
                ),
            };
          }

          if (projectColumn.clientPreleadCustomizeItemId) {
            return {
              ...column,
              id: projectColumn.id,
              Header: projectColumn.title ?? '',
              accessor: () => projectColumn.clientPreleadCustomizeItemId,
            };
          }
          if (projectColumn.projectGroupPreleadCustomizeItemId) {
            return {
              ...column,
              id: projectColumn.id,
              Header: projectColumn.title ?? '',
              accessor: () => projectColumn.projectGroupPreleadCustomizeItemId,
            };
          }

          return {
            ...column,
            id: projectColumn.id,
            Header: projectColumn.title ?? '',
            accessor: column?.accessor,
          };
        }) || [];

    return [...baseColumns, ...projectColumns];
  }, [project?.projectColumns]);

  const {setHiddenColumns, ...restProps} = useTable(
    {
      columns,
      defaultColumn,
      data: preleadProjects,
    },
    useBlockLayout,
    useResizeColumns,
  );

  React.useEffect(() => {
    const hiddenColumnIds = project?.projectColumns
      ?.filter((projectColumn) => !projectColumn.isDisplay)
      .map((projectColumn) => projectColumn.id);

    if (hiddenColumnIds) {
      setHiddenColumns(hiddenColumnIds);
    }
  }, [project?.projectColumns]);

  return {
    setHiddenColumns,
    ...restProps,
    ...paginationData,
    loading,
  };
};

export default usePreleadTable;
