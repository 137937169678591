import {ProspectPool} from 'api';
import React from 'react';
import {Row} from 'react-table';
import moment from 'moment';

interface Props {
  row: Row<ProspectPool>;
}

const LatestInflowDate = ({row}: Props) => {
  const prospectPool = row.original;

  if (!prospectPool.latestInflowDate) {
    return <></>;
  }

  return (
    <div>{moment(prospectPool.latestInflowDate).format('YYYY/MM/DD')}</div>
  );
};

export default LatestInflowDate;
