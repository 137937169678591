import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import {useClientPhaseProspectsQuery} from 'api';

const PhaseFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {clientPhaseProspects = []} = {}} =
    useClientPhaseProspectsQuery();

  return (
    <>
      <Header title="フェーズ" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            options={clientPhaseProspects.map((phase) => ({
              text: phase.name,
              value: phase.id,
            }))}
            values={query.phases || []}
            onChange={(phases) => setQuery({...query, phases})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, phases: []})} />
    </>
  );
};

export default PhaseFilter;
