import React from 'react';
import {Content, ContentMailForm, ContentMailFormVersion} from 'api';
import {flatten} from 'lodash';

interface Props {
  row: any;
}

const ProjectCell = ({row}: Props) => {
  const content = row.original as Content;

  if (content.contentableType === 'Content::MailForm') {
    const contentable = content.contentable as ContentMailForm;

    const projects = flatten(
      contentable?.versions?.map(
        (version: ContentMailFormVersion) => version.projects,
      ),
    );
    return <span>{projects?.length} リスト</span>;
  }

  return <span>-</span>;
};

export default ProjectCell;
