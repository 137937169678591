import React, {useMemo} from 'react';
import {useProjectFormMailReportsByOfficeNumberRangeQuery} from 'api';
import {useParams} from 'react-router-dom';
import Table from '../Table';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {projectFormMailReportsByOfficeNumberRange = []} = {}} =
    useProjectFormMailReportsByOfficeNumberRangeQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
    });

  const data: any = useMemo(
    () =>
      projectFormMailReportsByOfficeNumberRange.map((report) => {
        return {
          name: report.officeNumberRange,
          mailCount: report.mailCount,
          clickCount: report.clickCount,
          clickRate: report.clickRate,
          replyCount: report.replyCount,
          replyRate: report.replyRate,
          appointCount: report.appointCount,
          appointRate: report.appointRate,
        };
      }),
    [projectFormMailReportsByOfficeNumberRange],
  );

  return <Table data={data} />;
};
