import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Input} from 'components/antd';
import {useParams} from 'react-router';
import {Radio, StatusEclipse} from 'components/Ui';
import telCategoryColor from 'helpers/telCategoryColor';
import {
  useCreateCallNgReasonMutation,
  CallNgReason,
  useProjectQuery,
} from 'api';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  ngReason: CallNgReason;
  setNgReason: React.Dispatch<React.SetStateAction<CallNgReason>>;
}

export default ({
  isModalVisible,
  setModalVisible,
  ngReason,
  setNgReason,
}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const [onCreate, setOnCreate] = React.useState(false);
  const [reason, setReason] = React.useState(null);
  const {data: {project: {callNgReasons = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const handleClose = () => {
    setModalVisible(false);
  };

  const [createCallNgReason, {loading}] = useCreateCallNgReasonMutation({
    refetchQueries: ['project'],
    onCompleted: ({createCallNgReason: {ngReason}}) => {
      setNgReason(ngReason);
      handleClose();
    },
  });

  const onSubmit = () => {
    if (onCreate) {
      createCallNgReason({
        variables: {
          uuid: projectId,
          attributes: {
            reason: reason,
          },
        },
      });
    } else {
      handleClose();
    }
  };

  return (
    <NgReasonModal
      destroyOnClose
      title={
        <Title>
          <StatusEclipse color={telCategoryColor('call_ng')} />
          コールNG理由を選択
        </Title>
      }
      maskClosable={false}
      visible={isModalVisible}
      width={425}
      centered
      bodyStyle={{paddingTop: '24px', padding: 0}}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={(onCreate && !reason) || (!onCreate && !ngReason)}
          onClick={onSubmit}>
          選択
        </Button>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <List>
          {callNgReasons?.map((callNgReason: CallNgReason) => (
            <div key={callNgReason.id}>
              <CustomRadio
                checked={ngReason?.id === callNgReason.id}
                onChange={() => {
                  setOnCreate(false);
                  setNgReason(callNgReason);
                }}>
                <span>{callNgReason.reason}</span>
              </CustomRadio>
            </div>
          ))}
        </List>

        <NewNgReason>
          <CustomRadio
            checked={onCreate}
            onChange={() => {
              setNgReason(null);
              setOnCreate(true);
            }}>
            <span>コールNG理由を新規作成</span>
          </CustomRadio>

          <Item>
            <Name
              name="name"
              value={reason}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
            />
          </Item>
        </NewNgReason>
      </ModalContent>
    </NgReasonModal>
  );
};

const NgReasonModal = styled(Modal)`
  &&& {
    .ant-modal-header {
      border: none;
      padding: 16px 24px 13px 24px !important;
    }
    .ant-modal-body {
      padding: 0px 24px !important;
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: #222426;

  > svg {
    margin-right: 5px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.div`
  width: 90%;
  overflow-y: scroll;
  height: 160px;
  border-bottom: 1px solid #f3f5f7;
  padding-bottom: 16px;

  > div {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
`;

const CustomRadio = styled(Radio)`
  width: 280px;

  > span:last-child {
    display: inline-block;
    width: 200px;
  }
`;

const NewNgReason = styled.div`
  > label {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  width: 100%;
  min-height: 26px;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 18px;
  }
`;

const Name = styled(Input)`
  width: 420px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-color: #e2e6ea;
  border-radius: 4px;

  &:hover {
    border: 1px solid #68b4fb;
    box-shadow: 0px 0px 4px rgba(104, 180, 251, 0.7);
  }
`;
