import React, {useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {
  ProspectPool,
  useSubscribeAutomaticMailMutation,
  useUnbounceMutation,
} from 'api';
import {EllipsisHorizontalIcon} from '@heroicons/react/24/outline';
import {
  UnsubscribeProspectPoolModal,
  ArchiveProspectPoolModal,
  UnarchiveProspectPoolModal,
} from 'components/Ui';
import useClientUser from 'hooks/useClientUser';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {isMember} = useClientUser();
  const [isUnsubscribeModalVisible, setIsUnsubscribeModalVisible] =
    useState(false);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [isUnarchiveModalVisible, setIsUnarchiveModalVisible] = useState(false);
  const [subscribeAutomaticMail] = useSubscribeAutomaticMailMutation({
    variables: {
      uuid: prospectPool.uuid,
    },
  });
  const [unbounce] = useUnbounceMutation({
    variables: {uuid: prospectPool.uuid},
  });

  return (
    <>
      <Menu as="div" className="relative inline-block text-left px-4">
        <Menu.Button className="inline-flex w-full justify-center bg-transparent px-2 py-1 text-sm focus:outline-none cursor-pointer">
          <EllipsisHorizontalIcon className="w-4 h-4 text-c-light" />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-1 w-48 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {isMember &&
              (prospectPool.status === 'archived' ? (
                <Menu.Item>
                  <button
                    className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                    onClick={() => {
                      setIsUnarchiveModalVisible(true);
                    }}>
                    アーカイブを復元
                  </button>
                </Menu.Item>
              ) : (
                <Menu.Item>
                  <button
                    className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                    onClick={() => {
                      setIsArchiveModalVisible(true);
                    }}>
                    アーカイブ
                  </button>
                </Menu.Item>
              ))}
            {prospectPool.prospect.email &&
              (prospectPool.isUnsubscribed ? (
                <Menu.Item>
                  <button
                    className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                    onClick={() => {
                      subscribeAutomaticMail();
                    }}>
                    配信停止を解除
                  </button>
                </Menu.Item>
              ) : (
                <Menu.Item>
                  <button
                    className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                    onClick={() => {
                      setIsUnsubscribeModalVisible(true);
                    }}>
                    配信停止
                  </button>
                </Menu.Item>
              ))}
            {prospectPool.prospect.email && prospectPool.isBounced && (
              <Menu.Item>
                <button
                  className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                  onClick={() => {
                    if (confirm('バウンスを解除しますか？')) {
                      unbounce();
                    }
                  }}>
                  バウンスを解除
                </button>
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
      <UnsubscribeProspectPoolModal
        prospectPool={prospectPool}
        isModalVisible={isUnsubscribeModalVisible}
        setIsModalVisible={setIsUnsubscribeModalVisible}
      />
      <ArchiveProspectPoolModal
        prospectPool={prospectPool}
        isModalVisible={isArchiveModalVisible}
        setIsModalVisible={setIsArchiveModalVisible}
      />
      <UnarchiveProspectPoolModal
        prospectPool={prospectPool}
        isModalVisible={isUnarchiveModalVisible}
        setIsModalVisible={setIsUnarchiveModalVisible}
      />
    </>
  );
};
