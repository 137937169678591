import React from 'react';
import {Workflow} from 'api';
import WorkflowStats from './Workflow';
import TotalStats from './Total';
import AutomaticMailStats from './AutomaticMail';
import CallStats from './Call';

interface Props {
  workflow: Workflow;
}

export default ({workflow}: Props) => (
  <ul className="w-full flex gap-8">
    <li className="flex flex-col gap-2 leading-none w-16">
      <WorkflowStats workflow={workflow} />
    </li>
    <li className="border-r border-c-border" />
    <li className="flex flex-col gap-2 leading-none">
      <TotalStats workflow={workflow} />
    </li>
    <li className="border-r border-c-border" />
    <li className="flex flex-col gap-2 leading-none">
      <AutomaticMailStats workflow={workflow} />
    </li>
    <li className="border-r border-c-border" />
    <li className="flex flex-col gap-2 leading-none w-16">
      <CallStats workflow={workflow} />
    </li>
  </ul>
);
