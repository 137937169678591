import React from 'react';
import {
  Prelead,
  ProjectGroupPreleadCustomizeItem,
  PreleadProjectGroupCustomizeItemDate,
  useUpdateProjectGroupPreleadCustomizeItemDateMutation,
} from 'api';
import {DatePicker as UIDatePicker} from 'components/antd';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
  prelead: Prelead;
  projectGroupPreleadCustomizeItem: ProjectGroupPreleadCustomizeItem;
}

export default ({prelead, projectGroupPreleadCustomizeItem}: Props) => {
  const customizeItem = prelead.projectGroupCustomizeItemDates.find(
    (item: PreleadProjectGroupCustomizeItemDate) =>
      item.projectGroupPreleadCustomizeItemId ===
      projectGroupPreleadCustomizeItem.id,
  );

  const [value, setValue] = React.useState(
    customizeItem ? moment(customizeItem.value) : null,
  );

  const [updateCustomizeItem] =
    useUpdateProjectGroupPreleadCustomizeItemDateMutation();

  const update = (value: Date) => {
    updateCustomizeItem({
      variables: {
        uuid: prelead.uuid,
        projectGroupPreleadCustomizeItemId: projectGroupPreleadCustomizeItem.id,
        value,
      },
    });
  };

  React.useEffect(() => {
    setValue(customizeItem ? moment(customizeItem.value) : null);
  }, [customizeItem]);

  React.useEffect(() => {
    update(value ? value.toDate() : null);
  }, [value]);

  return (
    <DatePicker
      allowClear
      onChange={setValue}
      placeholder="未入力"
      value={value}
      style={{width: '100%'}}
    />
  );
};

const DatePicker = styled(UIDatePicker)`
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
