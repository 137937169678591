import React from 'react';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useParams} from 'react-router';
import themeColor from 'helpers/themeColor';
import MessageForm from './MessageForm';
import DetailForm from './DetailForm';
import Panel from './Panel';
import Title from './Title';
import {
  Content,
  useCreateContentMailFormVersionMutation,
  ContentMailFormAttributes,
} from 'api';
import {message} from 'components/antd';

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('必須項目です'),
  message1: Yup.string().required('必須項目です'),
  companyName: Yup.string().required('必須項目です'),
  companyHiragana: Yup.string().required('必須項目です'),
  companyKana: Yup.string().required('必須項目です'),
  lastName: Yup.string().required('必須項目です'),
  firstName: Yup.string().required('必須項目です'),
  lastNameKana: Yup.string().required('必須項目です'),
  firstNameKana: Yup.string().required('必須項目です'),
  lastNameKana2: Yup.string().required('必須項目です'),
  firstNameKana2: Yup.string().required('必須項目です'),
  email: Yup.string()
    .email('正しいメールアドレスを入力してください')
    .required('必須項目です'),
  tel1: Yup.string().required('必須項目です'),
  tel2: Yup.string().required('必須項目です'),
  tel3: Yup.string().required('必須項目です'),
  zipcode1: Yup.string().required('必須項目です'),
  zipcode2: Yup.string().required('必須項目です'),
  state: Yup.string().required('必須項目です'),
  city: Yup.string().required('必須項目です'),
  street: Yup.string().required('必須項目です'),
  url: Yup.string().required('必須項目です'),
});

export const initialValues = (content: Content) => ({
  // meessage form
  title: content?.mailForm?.title || '',
  message1: content?.mailForm?.message1 || '',
  message2: content?.mailForm?.message2 || '',
  messageMail: content?.mailForm?.messageMail || '',
  // detail form
  companyName: content?.mailForm?.companyName || '',
  companyHiragana: content?.mailForm?.companyHiragana || '',
  companyKana: content?.mailForm?.companyKana || '',
  lastName: content?.mailForm?.lastName || '',
  firstName: content?.mailForm?.firstName || '',
  lastNameKana: content?.mailForm?.lastNameKana || '',
  firstNameKana: content?.mailForm?.firstNameKana || '',
  lastNameKana2: content?.mailForm?.lastNameKana2 || '',
  firstNameKana2: content?.mailForm?.firstNameKana2 || '',
  section: content?.mailForm?.section || '',
  sectionPosition: content?.mailForm?.sectionPosition || '',
  email: content?.mailForm?.email || '',
  tel1: content?.mailForm?.tel1 || '',
  tel2: content?.mailForm?.tel2 || '',
  tel3: content?.mailForm?.tel3 || '',
  zipcode1: content?.mailForm?.zipcode1 || '',
  zipcode2: content?.mailForm?.zipcode2 || '',
  state: content?.mailForm?.state || '',
  city: content?.mailForm?.city || '',
  street: content?.mailForm?.street || '',
  building: content?.mailForm?.building || '',
  url: content?.mailForm?.url || '',
});

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {id} = useParams<{id: string}>();
  const location = useLocation();
  const [createVersion] = useCreateContentMailFormVersionMutation({
    onCompleted: () => {
      message.success('確定しました');
    },
  });

  const _content = React.useMemo(() => content, []);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: initialValues(_content),
    validationSchema,
    onSubmit: (values: ContentMailFormAttributes) =>
      createVersion({variables: {id, attributes: values}}),
  });

  return (
    <Container>
      <Title content={content} formik={formik} />
      <MailForm>
        <Menu>
          <MenuItem
            active={
              !location.pathname.startsWith(
                `/garage/contents/${id}/mail_form/detail`,
              )
            }>
            <Link to={`/garage/contents/${id}/mail_form${location.search}`}>
              フォーム送信本文
            </Link>
          </MenuItem>
          <MenuItem
            active={
              !!location.pathname.startsWith(
                `/garage/contents/${id}/mail_form/detail`,
              )
            }>
            <Link
              to={`/garage/contents/${id}/mail_form/detail${location.search}`}>
              送信項目
            </Link>
          </MenuItem>
        </Menu>
        <Content>
          <Form>
            <Switch>
              <Route path="/garage/contents/:id/mail_form/detail">
                <DetailForm formik={formik} />
              </Route>
              <Route>
                <MessageForm formik={formik} />
              </Route>
            </Switch>
          </Form>
          <Panel content={content} />
        </Content>
      </MailForm>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const MailForm = styled.div`
  background: #fff;
  margin-top: 1.5rem;
  border-radius: 4px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  border-bottom: solid 1px #e2e6ea;
`;

const MenuItem = styled.div<{active?: boolean}>`
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 3px;
  border-color: ${({active}) =>
    active ? themeColor('/garage') : 'transparent'};

  a {
    font-size: 14px;
    font-weight: ${({active}) => (active ? 700 : 400)};
    margin: 0 1rem;
    color: #495058;
  }
`;

const Content = styled.div`
  display: flex;
`;

const Form = styled.div`
  flex: 1;
`;
