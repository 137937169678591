import React from 'react';

type Props = {
  color?: string;
  width?: number;
  disabled?: boolean;
  className?: string;
  style?: any;
};

function Icon({...props}: Props) {
  const color = props.color ? props.color : '';
  const strokeColor = props.color ? '' : '#bdc4cb';

  return (
    <svg
      {...props}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="5"
        cy="5"
        // borderがあると線がはみ出るので調整
        r={strokeColor ? 4.6 : 5}
        fill={color}
        stroke={strokeColor}
      />
    </svg>
  );
}

export default Icon;
