import React from 'react';
import styled from 'styled-components';
import {ProspectPool, PoolSalesforceSetting} from 'api';
import {prospectStages} from 'helpers/prospectStages';
import SalesforceIcon from './SalesforceIcon';
import HubspotIcon from './HubspotIcon';

interface Props {
  prospectPool: ProspectPool;
}

export const ProspectPoolNameComponent = ({prospectPool}: Props) => {
  const stage = prospectStages.find(
    (stage) => stage.name == prospectPool?.stage,
  );

  const pool = prospectPool?.pool;
  let url = `/pools/${pool?.uuid}/prospects/${prospectPool?.uuid}/timeline`;
  const leadName =
    `${prospectPool.prospect?.lastName || ''} ${prospectPool.prospect?.firstName || ''}`.trim();
  if (leadName !== '') {
    url += `?searchWord=${encodeURIComponent(leadName)}`;
  }

  const salesforceSetting: PoolSalesforceSetting =
    prospectPool.pool.poolSalesforceSetting;
  const baseSalesforceUrl = salesforceSetting?.instanceUrl;

  return (
    <div className="flex items-center gap-2">
      <a
        className="text-[#68B5FB] text-base mr-2 font-medium"
        href={url}
        target="_blank">
        {prospectPool.prospect?.lastName} {prospectPool.prospect?.firstName}
      </a>
      <Stage color={stage.color} bg={stage.bg}>
        {stage.displayName}
      </Stage>
      {prospectPool?.salesforceContact && (
        <SalesforceLabel
          target="_blank"
          href={`${baseSalesforceUrl}/lightning/r/Contact/${prospectPool.salesforceContact.contactId}/view`}>
          <SalesforceIcon />
          取引先責任者
        </SalesforceLabel>
      )}
      {prospectPool?.salesforceLead && (
        <SalesforceLabel
          target="_blank"
          href={`${baseSalesforceUrl}/lightning/r/Lead/${prospectPool.salesforceLead.leadId}/view`}>
          <SalesforceIcon />
          リード
        </SalesforceLabel>
      )}
      {prospectPool?.hubspotContact && (
        <HubspotLabel
          target="_blank"
          href={`${prospectPool.hubspotContact.hubspotUrl}`}>
          <HubspotIcon />
          コンタクト
        </HubspotLabel>
      )}
    </div>
  );
};

const Stage = styled.span<{color: string; bg: string}>`
  padding: 6px 5px;
  font-size: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: ${({color}) => color};
  background-color: ${({bg}) => bg};
`;

const SalesforceLabel = styled.a`
  padding: 6px 5px;
  font-size: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: #45a0dae5;
  background-color: #039be51a;
`;

const HubspotLabel = styled.a`
  padding: 6px 5px;
  font-size: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: #ff7a59;
  background-color: #ff7a594d;
`;
