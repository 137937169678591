import React from 'react';
import {useParams} from 'react-router-dom';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useProjectWithContentsQuery} from 'api';

const ClickedMailFormVersionUrlIdsFilter = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query, setQuery} = useSearchParams();

  const {
    data: {project: {contentMailFormVersion: {urls = []} = {}} = {}} = {},
  } = useProjectWithContentsQuery({
    variables: {uuid: projectId},
  });

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.clickedMailFormVersionUrlIds || []}
      onChange={(value) =>
        setQuery({page: 1, clickedMailFormVersionUrlIds: value})
      }>
      {urls.map((url) => (
        <Select.Option value={url.id} key={url.id} label={url.url}>
          {url.url}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ClickedMailFormVersionUrlIdsFilter;
