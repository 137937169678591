import React from 'react';

const LinkIcon = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.53271 1H11.0423V3.5"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5403 6.5V9.5C10.5403 10.0523 10.0909 10.5 9.53645 10.5H2.50968C1.95529 10.5 1.50586 10.0523 1.50586 9.5V2.5C1.50586 1.94771 1.95529 1.5 2.50968 1.5H5.52116"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5249 5.5L10.7912 1.25"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
