import gql from 'graphql-tag';

export default gql`
  fragment zoomPhoneCallerPhoneNumberFragment on ZoomPhoneCallerPhoneNumber {
    id
    phoneNumber
    tel
    active
    assignee
  }
`;
