import React from 'react';

const Icon = ({
  width = '40',
  height = '40',
  active,
}: {
  width: string;
  height: string;
  active: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="16"
      cy="16"
      r="16"
      fill={active ? 'rgba(136, 201, 216, 1)' : 'rgba(136, 201, 216, .3)'}
    />
    <path
      d="M20.6667 11.9165H11.3333C11.0112 11.9165 10.75 12.1777 10.75 12.4998V19.4998C10.75 19.822 11.0112 20.0832 11.3333 20.0832H20.6667C20.9888 20.0832 21.25 19.822 21.25 19.4998V12.4998C21.25 12.1777 20.9888 11.9165 20.6667 11.9165Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6663 12.2085L15.9997 16.5835L11.333 12.2085"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
