import React from 'react';
import styled from 'styled-components';

interface Props {
  workflowCount: number;
  notWorkflowCount: number;
}
const CountTooltip = ({workflowCount, notWorkflowCount}: Props) => {
  return (
    <TooltipContainer>
      <p>ワークフロー</p>
      <CountBlock>
        <Circle color="green" />
        <div>適用中</div>
        <div className="count">{workflowCount.toLocaleString()}</div>
      </CountBlock>
      <CountBlock>
        <Circle color="grey" />
        <div>放置</div>
        <div className="count">{notWorkflowCount.toLocaleString()}</div>
      </CountBlock>
    </TooltipContainer>
  );
};

export {CountTooltip};

const TooltipContainer = styled.div`
  padding: 8px 14px;
  width: 122px;

  .count {
    margin-left: auto;
  }
`;

const CountBlock = styled.div<{current?: boolean}>`
  color: ${({current}) => (current ? '#68B5FB' : '')};
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: #68b5fb;
  }
`;

const Circle = styled.div<{color: string}>`
  background-color: #4fce82;
  background-color: ${({color}) => (color === 'green' ? '#4fce82' : '#BDC4CB')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
`;
