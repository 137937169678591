import React from 'react';
import {Action} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';
import {Link, useParams} from 'react-router-dom';
import {LaunchLink} from 'components/Ui/Icon';

interface Props {
  row: Row<Action>;
}

const NameCell = ({row}: Props) => {
  const action = row.original;
  const workflowProspectPoolId = action?.workflowProspectPool.id;
  const {workflowProspectPool: {prospectPool} = {}} = action;
  const {poolId} = useParams<{
    poolId: string;
  }>();

  return (
    <Container>
      <Name>
        <h4>
          <Link
            to={{
              pathname: `/pools/${poolId}/action/actions/${action.uuid}/prospect/workflow`,
              search: location.search,
              state: {
                workflowprospectPoolId: workflowProspectPoolId,
              },
            }}>
            {prospectPool.prospect.lastName} {prospectPool.prospect.firstName}
          </Link>
        </h4>
        <div className="flex">
          <span>{prospectPool.prospect.account.name}</span>
          {prospectPool.prospect.account.webSite && (
            <a href={prospectPool.prospect.account.webSite} target="_blank">
              <LaunchLink width="12" height="12" />
            </a>
          )}
        </div>
      </Name>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Name = styled.div`
  width: 100%;
  h4 {
    font-size: 14px;
    font-weight: medium;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .flex {
    display: flex;

    span {
      font-size: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 8px;
    }

    svg {
      vertical-align: text-top;
    }
  }
`;

export default NameCell;
