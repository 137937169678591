import React from 'react';
import {ProspectPool, useApproachesByProspectPoolQuery} from 'api';
import ApproachItem from './Approach';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {data: {approachesByProspectPool = []} = {}} =
    useApproachesByProspectPoolQuery({
      variables: {
        uuid: prospectPool.uuid,
      },
      fetchPolicy: 'cache-and-network',
    });

  return (
    <div className="flex flex-col">
      {approachesByProspectPool.map((approach) => (
        <ApproachItem
          key={approach.uuid}
          approach={approach}
          prospectPool={prospectPool}
        />
      ))}
    </div>
  );
};
