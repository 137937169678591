import React from 'react';
import {useRecoilState} from 'recoil';
import {BlockGroupMember} from 'api';
import checkedBlockMembersAtom from '../checkedBlockMembersAtom';
import {Row} from 'react-table';

interface Props {
  row?: Row<BlockGroupMember>;
  value?: any;
}

const CheckCell = ({row}: Props) => {
  const blockMember: BlockGroupMember = row.original;
  const [checkedBlockMemberIds, setCheckedBlockMemberIds] = useRecoilState(
    checkedBlockMembersAtom,
  );

  const checked = React.useMemo(
    () => checkedBlockMemberIds.includes(blockMember.uuid),
    [checkedBlockMemberIds],
  );

  const checkBlockMember = React.useCallback(() => {
    if (checkedBlockMemberIds.includes(blockMember.uuid)) {
      setCheckedBlockMemberIds(
        checkedBlockMemberIds.filter((id) => id !== blockMember.uuid),
      );
    } else {
      setCheckedBlockMemberIds([...checkedBlockMemberIds, blockMember.uuid]);
    }
  }, [checkedBlockMemberIds]);

  return (
    <input type="checkbox" checked={checked} onChange={checkBlockMember} />
  );
};

export default CheckCell;
