import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {UserMaru} from 'components/Ui/Icon';
import {Activity, PreleadContactCreateActivity} from 'api';
import useProjectName from './useProjectName';
import UserName from './UserName';

interface PreleadContactCreate extends Activity {
  resource: PreleadContactCreateActivity;
}

interface Props {
  activity: PreleadContactCreate;
}

const PreleadContactCreateActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  return (
    <>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <UserMaru />
        </BadgeWrapper>
        <UserNameWrapper>
          <UserName activity={activity} />
        </UserNameWrapper>
        <StatusLabel color="#108429">連絡先登録</StatusLabel>
        <Name>
          [{activity.resource.preleadContactLastName}&nbsp;
          {activity.resource.preleadContactFirstName}]&nbsp;様
        </Name>
        <span>{moment(activity.createdAt).format('MM/DD HH:mm')}</span>
      </Summary>
    </>
  );
};

const Summary = styled.div`
  margin-left: -15px;
  display: flex;
  align-items: center;
  width: 480px;
  height: 30px;
`;

const Project = styled.div`
  margin-left: 33px;
  font-size: 10px;
  color: #899098;
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;

const UserNameWrapper = styled.div`
  margin-right: 15px;
`;

const Name = styled.span`
  margin-right: 12px;
  font-weight: 700;
`;

const StatusLabel = styled.span<{color: string}>`
  margin-right: 12px;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid #bdc4cb'};
  color: ${({color}) => (color ? 'white' : '#bdc4cb')};
  border-radius: 20px;
  padding: 4px 16px;
  font-size: 10px;
  height: 22px;
`;

export default PreleadContactCreateActivityComponent;
