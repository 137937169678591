import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';

export default () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <DatePicker
        placeholder="開始日"
        value={query.mailTermFrom && moment(query.mailTermFrom)}
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, mailTermFrom: value?.format()});
        }}
        className="w-36"
      />
      <span className="mx-1 mt-2">~</span>
      <DatePicker
        placeholder="終了日"
        value={query.mailTermTo && moment(query.mailTermTo)}
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, mailTermTo: value?.format()});
        }}
        className="w-36"
      />
    </>
  );
};
