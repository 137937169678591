import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import {usePoolProspectCustomizeItemsQuery} from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({});

  const customizeItemById = (id: string) => {
    return poolProspectCustomizeItems.find(
      (poolProspectCustomizeItem) => poolProspectCustomizeItem.id === id,
    );
  };

  if (
    !query?.prospectCustomizeItemSearchConditions ||
    !query.prospectCustomizeItemSearchConditions.length
  ) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">カスタマイズ項目</h4>
        <button
          onClick={() => setFilterType('customizeItem')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({...query, prospectCustomizeItemSearchConditions: []})
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap flex-col gap-2">
          {query.prospectCustomizeItemSearchConditions.map((item, index) => {
            return (
              <div key={index} className="flex gap-3">
                {customizeItemById(item['customize_item_id'])?.dataType ===
                  'select' && (
                  <>
                    <div className="text-[#899098] text-sm min-w-28 text-center truncate">
                      {item['values']?.length > 0 &&
                        `${
                          customizeItemById(item['customize_item_id'])?.name
                        }:`}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {item['values']?.length > 0 &&
                        item['values'].map((option: string, index: number) => {
                          return (
                            <div
                              key={index}
                              className="min-w-16 h-5 bg-[#F3F5F7] border border-[#E2E6EA] text-center truncate text-sm px-2 rounded-sm">
                              {
                                customizeItemById(
                                  item['customize_item_id'],
                                )?.selectOptions?.find(
                                  (item) => item.id === option,
                                )?.label
                              }
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
                {customizeItemById(item['customize_item_id'])?.dataType ===
                  'date' && (
                  <>
                    <div className="text-[#899098] text-sm min-w-28 text-center truncate">
                      {(item['from'] || item['to']) &&
                        `${
                          customizeItemById(item['customize_item_id'])?.name
                        }:`}
                    </div>
                    <div className="flex flex-wrap gap-1 items-center">
                      <div>{item['from']}</div>
                      <div> ~ </div>
                      <div>{item['to']}</div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
