import React from 'react';

interface Props {
  pageSize: number;
  total: number;
  current: number;
  onChange: (page: number) => void;
}

const PageButton = ({
  page,
  onClick,
  current,
}: {
  page: number;
  current: boolean;
  onClick: () => void;
}) => (
  <button
    aria-current="page"
    onClick={onClick}
    className={`z-10 inline-flex items-center justify-center w-9 h-9 rounded-full bg-white font-medium cursor-pointer hover:bg-c-bg ${
      current ? 'bg-c-bg' : ''
    }`}>
    {page}
  </button>
);

export default ({pageSize, total, current, onChange}: Props) => {
  const firstPage = 1;
  const currentPage = current || 1;
  const lastPage = Math.ceil(total / pageSize);
  const centerPages = React.useMemo(() => {
    const pages = [];
    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
      if (i > firstPage && i < lastPage) {
        pages.push(i);
      }
    }
    return pages;
  }, [current, firstPage, lastPage]);

  const prevPage = React.useMemo(() => {
    if (currentPage > firstPage) {
      return currentPage - 1;
    }
    return null;
  }, [currentPage, firstPage]);

  const nextPage = React.useMemo(() => {
    if (currentPage < lastPage) {
      return currentPage + 1;
    }
    return null;
  }, [currentPage, lastPage]);

  const handlePageClick = (page: number) => {
    if (page !== currentPage) {
      onChange(page);
    }
  };

  const showLastPage = React.useMemo(() => {
    if (lastPage > 1) {
      return true;
    }
    return false;
  }, [lastPage]);

  const showPrevDots = () => {
    return centerPages[0] > firstPage + 1;
  };

  const showNextDots = () => {
    return centerPages[centerPages.length - 1] < lastPage - 1;
  };

  return (
    <div className="flex justify-center">
      <nav aria-label="Pagination">
        <ul className="inline-flex items-center -space-x-px text-sm gap-1">
          <li>
            <button
              onClick={() => prevPage && handlePageClick(prevPage)}
              className={`cursor-pointer bg-white inline-flex items-center justify-center w-9 h-9 rounded-full font-medium hover:bg-c-bg ${
                prevPage ? 'text-c-base' : 'text-c-lighter'
              }`}>
              <span className="sr-only">Previous</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
          <li>
            <PageButton
              page={firstPage}
              onClick={() => handlePageClick(firstPage)}
              current={currentPage === firstPage}
            />
          </li>
          {showPrevDots() && (
            <li>
              <span className="text-gray-500 inline-flex items-center justify-center">
                ...
              </span>
            </li>
          )}
          {centerPages.map((page) => (
            <li key={page}>
              <PageButton
                page={page}
                onClick={() => handlePageClick(page)}
                current={currentPage === page}
              />
            </li>
          ))}
          {showNextDots() && (
            <li>
              <span className="text-gray-500 inline-flex items-center justify-center">
                ...
              </span>
            </li>
          )}
          {showLastPage && (
            <li>
              <PageButton
                page={lastPage}
                onClick={() => handlePageClick(lastPage)}
                current={currentPage === lastPage}
              />
            </li>
          )}
          <li>
            <button
              onClick={() => nextPage && handlePageClick(nextPage)}
              className={`cursor-pointer bg-white inline-flex items-center justify-center w-9 h-9 rounded-full font-medium hover:bg-c-bg ${
                nextPage ? 'text-c-base' : 'text-c-lighter'
              }`}>
              <span className="sr-only">Next</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};
