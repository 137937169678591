import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60px"
      height="60px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <g transform="translate(28.333333333333336,28.333333333333336)">
        <rect x="-15" y="-15" width="30" height="30" fill="#8966ef">
          <animateTransform
            attributeName="transform"
            type="scale"
            repeatCount="indefinite"
            dur="2s"
            keyTimes="0;1"
            values="1.25;1"
            begin="-0.6s"></animateTransform>
        </rect>
      </g>
      <g transform="translate(71.66666666666667,28.333333333333336)">
        <rect x="-15" y="-15" width="30" height="30" fill="#a084f2">
          <animateTransform
            attributeName="transform"
            type="scale"
            repeatCount="indefinite"
            dur="2s"
            keyTimes="0;1"
            values="1.25;1"
            begin="-0.4s"></animateTransform>
        </rect>
      </g>
      <g transform="translate(28.333333333333336,71.66666666666667)">
        <rect x="-15" y="-15" width="30" height="30" fill="#dbd1fa">
          <animateTransform
            attributeName="transform"
            type="scale"
            repeatCount="indefinite"
            dur="2s"
            keyTimes="0;1"
            values="1.25;1"
            begin="0s"></animateTransform>
        </rect>
      </g>
      <g transform="translate(71.66666666666667,71.66666666666667)">
        <rect x="-15" y="-15" width="30" height="30" fill="#c4b2f7">
          <animateTransform
            attributeName="transform"
            type="scale"
            repeatCount="indefinite"
            dur="2s"
            keyTimes="0;1"
            values="1.25;1"
            begin="-0.2s"></animateTransform>
        </rect>
      </g>
    </svg>
  );
}

export default Icon;
