import React from 'react';
import useCondition from '../../useCondition';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import {
  useSalesforceLeadRecordTypesQuery,
  useSalesforceLeadFieldsQuery,
} from 'api';

const SalesforceLeadFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {salesforceLeadRecordTypes = []} = {}} =
    useSalesforceLeadRecordTypesQuery();

  const {data: {salesforceLeadFields = []} = {}} = useSalesforceLeadFieldsQuery(
    {
      variables: {
        id: condition.salesforceLeadRecordTypeId,
      },
      skip: !condition.salesforceLeadRecordTypeId,
    },
  );

  const onChangeRecordType = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCondition({
      salesforceLeadRecordTypeId: e.target.value,
      salesforceLeadFieldSelectStatusIds: [],
    });

  const onChangeStatuses = (statuses: string[]) =>
    setCondition({
      salesforceLeadFieldSelectStatusIds: statuses,
    });

  const statusOptions =
    salesforceLeadFields.find((leadField) => leadField.fieldName === 'Status')
      ?.salesforceLeadFieldSelectOptions || [];

  return (
    <>
      <Header title="Salesforceリード" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-c-base font-bold">レコードタイプ</div>
            <select
              className="form-select border-c-border rounded text-base w-full"
              onChange={onChangeRecordType}
              value={condition?.salesforceLeadRecordTypeId}>
              <option value="" />
              {salesforceLeadRecordTypes.map((recordType) => (
                <option value={recordType.id} key={recordType.id}>
                  {recordType.name}
                </option>
              ))}
            </select>
          </div>
          {condition?.salesforceLeadRecordTypeId && (
            <div className="flex flex-col gap-2">
              <div className="text-c-base font-bold">リード状況</div>
              <CheckboxGroup
                options={statusOptions.map((status) => ({
                  text: status.label,
                  value: status.id,
                }))}
                values={condition.salesforceLeadFieldSelectStatusIds || []}
                onChange={onChangeStatuses}
              />
            </div>
          )}
        </div>
      </div>
      <Footer
        onClear={() =>
          setCondition({
            salesforceLeadRecordTypeId: '',
            salesforceLeadFieldSelectStatusIds: [],
          })
        }
      />
    </>
  );
};

export default SalesforceLeadFilter;
