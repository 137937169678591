import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="10.1787" cy="10" r="10" fill="#F99985" />
    <g clipPath="url(#clip0_28125_109870)">
      <path
        d="M13.2898 7.27783H7.0676C6.85282 7.27783 6.67871 7.45194 6.67871 7.66672V12.3334C6.67871 12.5482 6.85282 12.7223 7.0676 12.7223H13.2898C13.5046 12.7223 13.6787 12.5482 13.6787 12.3334V7.66672C13.6787 7.45194 13.5046 7.27783 13.2898 7.27783Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2901 7.47229L10.179 10.389L7.06787 7.47229"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_28125_109870">
        <rect
          width="9.33333"
          height="9.33333"
          fill="white"
          transform="translate(5.51221 5.33337)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
