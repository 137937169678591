import React from 'react';
import {FormBlock, FormPost, FormBlockFieldOther} from 'api';
import TextField from './Text';
import NumberField from './Number';
import DateField from './Date';
import SelectField from './Select';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
  post: FormPost;
}

const otherFieldComponents: {[key: string]: any} = {
  text: TextField,
  number: NumberField,
  date: DateField,
  select: SelectField,
};

export default ({block, post}: Props) => {
  const blockable = block.formBlockable;
  const FieldComponent = otherFieldComponents[blockable.dataType];

  if (!FieldComponent) return null;

  return <FieldComponent block={block} post={post} />;
};
