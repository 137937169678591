import React from 'react';
import styled from 'styled-components';
import {Input, Form} from 'components/antd';
import {FormikProps} from 'formik';
import {ContentMailFormAttributes} from 'api';

interface Props {
  formik: FormikProps<ContentMailFormAttributes>;
}

export default ({formik}: Props) => (
  <Container>
    <Form labelCol={{span: 4}} wrapperCol={{span: 20}} layout="horizontal">
      <Form.Item
        label="会社名"
        required
        validateStatus={
          formik.errors.companyName && formik.touched.companyName ? 'error' : ''
        }
        help={
          formik.errors.companyName && formik.touched.companyName
            ? formik.errors.companyName
            : null
        }>
        <Input
          type="text"
          name="companyName"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="会社名(かな)"
        required
        validateStatus={
          formik.errors.companyHiragana && formik.touched.companyHiragana
            ? 'error'
            : ''
        }
        help={
          formik.errors.companyHiragana && formik.touched.companyHiragana
            ? formik.errors.companyHiragana
            : null
        }>
        <Input
          type="text"
          name="companyHiragana"
          value={formik.values.companyHiragana}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="会社名(カナ)"
        required
        validateStatus={
          formik.errors.companyKana && formik.touched.companyKana ? 'error' : ''
        }
        help={
          formik.errors.companyKana && formik.touched.companyKana
            ? formik.errors.companyKana
            : null
        }>
        <Input
          type="text"
          name="companyKana"
          value={formik.values.companyKana}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="名前(姓)"
        required
        validateStatus={
          formik.errors.lastName && formik.touched.lastName ? 'error' : ''
        }
        help={
          formik.errors.lastName && formik.touched.lastName
            ? formik.errors.lastName
            : null
        }>
        <Input
          type="text"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="名前(名)"
        required
        validateStatus={
          formik.errors.firstName && formik.touched.firstName ? 'error' : ''
        }
        help={
          formik.errors.firstName && formik.touched.firstName
            ? formik.errors.firstName
            : null
        }>
        <Input
          type="text"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="名前(姓)(かな)"
        required
        validateStatus={
          formik.errors.lastNameKana && formik.touched.lastNameKana
            ? 'error'
            : ''
        }
        help={
          formik.errors.lastNameKana && formik.touched.lastNameKana
            ? formik.errors.lastNameKana
            : null
        }>
        <Input
          type="text"
          name="lastNameKana"
          value={formik.values.lastNameKana}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="名前(名)(かな)"
        required
        validateStatus={
          formik.errors.firstNameKana && formik.touched.firstNameKana
            ? 'error'
            : ''
        }
        help={
          formik.errors.firstNameKana && formik.touched.firstNameKana
            ? formik.errors.firstNameKana
            : null
        }>
        <Input
          type="text"
          name="firstNameKana"
          value={formik.values.firstNameKana}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="名前(姓)(カナ)"
        required
        validateStatus={
          formik.errors.lastNameKana2 && formik.touched.lastNameKana2
            ? 'error'
            : ''
        }
        help={
          formik.errors.lastNameKana2 && formik.touched.lastNameKana2
            ? formik.errors.lastNameKana2
            : null
        }>
        <Input
          type="text"
          name="lastNameKana2"
          value={formik.values.lastNameKana2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="名前(名)(カナ)"
        required
        validateStatus={
          formik.errors.firstNameKana2 && formik.touched.firstNameKana2
            ? 'error'
            : ''
        }
        help={
          formik.errors.firstNameKana2 && formik.touched.firstNameKana2
            ? formik.errors.firstNameKana2
            : null
        }>
        <Input
          type="text"
          name="firstNameKana2"
          value={formik.values.firstNameKana2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="部署"
        validateStatus={
          formik.errors.section && formik.touched.section ? 'error' : ''
        }
        help={
          formik.errors.section && formik.touched.section
            ? formik.errors.section
            : null
        }>
        <Input
          type="text"
          name="section"
          value={formik.values.section}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="役職"
        validateStatus={
          formik.errors.sectionPosition && formik.touched.sectionPosition
            ? 'error'
            : ''
        }
        help={
          formik.errors.sectionPosition && formik.touched.sectionPosition
            ? formik.errors.sectionPosition
            : null
        }>
        <Input
          type="text"
          name="sectionPosition"
          value={formik.values.sectionPosition}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="メールアドレス"
        required
        validateStatus={
          formik.errors.email && formik.touched.email ? 'error' : ''
        }
        help={
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null
        }>
        <Input
          type="text"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <FormGroupItem
        label="TEL"
        required
        validateStatus={
          (formik.errors.tel1 && formik.touched.tel1) ||
          (formik.errors.tel2 && formik.touched.tel2) ||
          (formik.errors.tel3 && formik.touched.tel3)
            ? 'error'
            : ''
        }
        help={
          (formik.errors.tel1 && formik.touched.tel1) ||
          (formik.errors.tel2 && formik.touched.tel2) ||
          (formik.errors.tel3 && formik.touched.tel3)
            ? formik.errors.tel1 || formik.errors.tel2 || formik.errors.tel3
            : null
        }>
        <Form.Item>
          <Input
            type="text"
            name="tel1"
            value={formik.values.tel1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <em>-</em>
        <Form.Item>
          <Input
            type="text"
            name="tel2"
            value={formik.values.tel2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <em>-</em>
        <Form.Item>
          <Input
            type="text"
            name="tel3"
            value={formik.values.tel3}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </FormGroupItem>
      <FormGroupItem
        label="郵便番号"
        required
        validateStatus={
          (formik.errors.zipcode1 && formik.touched.zipcode1) ||
          (formik.errors.zipcode2 && formik.touched.zipcode2)
            ? 'error'
            : ''
        }
        help={
          (formik.errors.zipcode1 && formik.touched.zipcode1) ||
          (formik.errors.zipcode2 && formik.touched.zipcode2)
            ? formik.errors.zipcode1 || formik.errors.zipcode2
            : null
        }>
        <Form.Item>
          <Input
            type="text"
            name="zipcode1"
            value={formik.values.zipcode1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
        <em>-</em>
        <Form.Item>
          <Input
            type="text"
            name="zipcode2"
            value={formik.values.zipcode2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Item>
      </FormGroupItem>
      <Form.Item
        label="都道府県"
        required
        validateStatus={
          formik.errors.state && formik.touched.state ? 'error' : ''
        }
        help={
          formik.errors.state && formik.touched.state
            ? formik.errors.state
            : null
        }>
        <Input
          type="text"
          name="state"
          value={formik.values.state}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="市区町村"
        required
        validateStatus={
          formik.errors.city && formik.touched.city ? 'error' : ''
        }
        help={
          formik.errors.city && formik.touched.city ? formik.errors.city : null
        }>
        <Input
          type="text"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="番地"
        required
        validateStatus={
          formik.errors.street && formik.touched.street ? 'error' : ''
        }
        help={
          formik.errors.street && formik.touched.street
            ? formik.errors.street
            : null
        }>
        <Input
          type="text"
          name="street"
          value={formik.values.street}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="ビル"
        validateStatus={
          formik.errors.building && formik.touched.building ? 'error' : ''
        }
        help={
          formik.errors.building && formik.touched.building
            ? formik.errors.building
            : null
        }>
        <Input
          type="text"
          name="building"
          value={formik.values.building}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="URL"
        required
        validateStatus={formik.errors.url && formik.touched.url ? 'error' : ''}
        help={
          formik.errors.url && formik.touched.url ? formik.errors.url : null
        }>
        <Input
          type="text"
          name="url"
          value={formik.values.url}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>
    </Form>
  </Container>
);

const Container = styled.div`
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  label {
    font-size: 13px;
    color: #899098;
    font-weight: bold;
  }
`;

const FormGroupItem = styled(Form.Item)`
  &&& {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;

      .ant-form-item {
        margin-bottom: 0;
        width: 150px;
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      em {
        width: 1rem;
        display: inline-block;
        text-align: center;
        font-size: 1.2rem;
        line-height: 0.5;
        margin-right: 0.5rem;
        color: #899098;
        font-style: normal;
      }
    }
  }
`;
