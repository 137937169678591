import React from 'react';
import styled from 'styled-components';
import {Project} from 'api';
import {Row} from 'react-table';
import {
  projectStatusBgColor,
  projectStatusFontColor,
} from 'helpers/projectStatusColor';

interface Props {
  value: string;
  row: Row<Project>;
}

export default ({value, row}: Props) => {
  const project = row.original;

  return (
    <Container>
      <Eclipse status={value}>
        <span>{project.statusText}</span>
      </Eclipse>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;

const Eclipse = styled.span<{status?: string}>`
  padding: 2px 8px;
  border-radius: 100px;
  background: ${({status}) => projectStatusBgColor(status)};

  span {
    font-weight: bold;
    font-size: 8px;
    text-align: center;
    color: ${({status}) => projectStatusFontColor(status)};
  }
`;
