import React from 'react';
import moment from 'moment';
import {
  ProspectActivity,
  CallProspectActivity,
  useDestroyCallProspectActivityMutation,
} from 'api';
import {Call, Trash} from 'components/Ui/Icon';
import {Calendar, DotMenuSide, Edit, Hubspot} from 'components/Ui/Icon';
import {Menu, Transition} from '@headlessui/react';
import linkifyHtml from 'linkify-html';

interface CallActivity extends ProspectActivity {
  resource: CallProspectActivity;
}

interface Props {
  prospectActivity: CallActivity;
  setIsCallEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setCall: React.Dispatch<React.SetStateAction<CallProspectActivity>>;
}

export default ({prospectActivity, setIsCallEdit, setCall}: Props) => {
  const {resource, user, action, createdAt} = prospectActivity;
  const {
    stageText,
    recordedAt,
    commentHtml,
    hubspotContactIdString,
    clientProspectTelStatus,
  } = resource;

  const [destroyCallProspectActivity] = useDestroyCallProspectActivityMutation({
    variables: {id: resource.id},
    refetchQueries: ['prospectPool'],
  });

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <>
      <Call />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-2 min-h-[32px]">
          {hubspotContactIdString && (
            <span className="bg-[#FFDBD5] h-5 px-2 text-xs text-[#FF7A59] rounded-sm flex items-center gap-1 [&>svg]:h-3 [&>svg]:w-auto">
              <Hubspot />
              コンタクト
            </span>
          )}
          <h3 className="flex-1 font-bold m-0">{action?.step?.name}</h3>
          <span className="text-c-light">
            {user && [user.lastName, user.firstName].join(' ')}
          </span>
          <time className="text-c-light">
            {moment(createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex w-full justify-center bg-white px-2 py-1 text-sm focus:outline-none cursor-pointer">
              <DotMenuSide />
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute right-1 w-20 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  <button
                    className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                    onClick={() => {
                      setIsCallEdit(true);
                      setCall(resource);
                    }}>
                    <Edit />
                    編集
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="group flex w-full items-center py-2 px-4 text-sm bg-white hover:bg-[#E9F4FF] cursor-pointer gap-1 [&>svg]:w-3"
                    onClick={() => {
                      if (confirm('本当に削除しますか？')) {
                        destroyCallProspectActivity();
                      }
                    }}>
                    <Trash />
                    削除
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="border border-c-border rounded">
          {commentHtml && (
            <div className="m-4 [&_p]:mb-0">
              <div
                dangerouslySetInnerHTML={{
                  __html: convertUrlToLink(commentHtml),
                }}
              />
            </div>
          )}
          <div className="m-4 flex items-center gap-4">
            {clientProspectTelStatus?.name && (
              <span className="bg-[#e6f5ea] text-xs text-[#27ae60] h-5 px-4 rounded-sm">
                {clientProspectTelStatus.name}
              </span>
            )}
            {stageText && (
              <span className="bg-[#dceeff] text-xs text-[#2d9cdb] h-5 px-4 rounded-sm">
                {stageText}
              </span>
            )}
            {recordedAt && (
              <span className="flex items-center relative gap-1 [&>svg]:h-[18px]">
                <Calendar />
                <span>
                  {recordedAt && moment(recordedAt).format('YYYY/MM/DD HH:mm')}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
