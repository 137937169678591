import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import LeadSource from './leadSource';

export default () => {
  return (
    <Layout
      title="リード登録履歴"
      tabs={[
        {
          label: '最新リードソース別',
          to: '/reports/lead_registration/lead_source',
        },
        {
          label: '業種別',
          to: '/reports/lead_registration/category',
        },
        {
          label: '従業員数',
          to: '/reports/lead_registration/employee',
        },
        {
          label: 'タグ別',
          to: '/reports/lead_registration/tag',
        },
      ]}
      search={['timeScale']}>
      <Switch>
        <Route
          path="/reports/lead_registration/lead_source"
          component={LeadSource}
        />
        <Redirect to="/reports/lead_registration/lead_source" />
      </Switch>
    </Layout>
  );
};
