import gql from 'graphql-tag';

export default gql`
  fragment salesforceAccountLeadpadFieldFragment on SalesforceAccountLeadpadField {
    id
    leadpadField
    leadpadFieldText
    fieldType
    position
  }
`;
