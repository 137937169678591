import React from 'react';
import {useParams} from 'react-router-dom';

const useProjectPath = () => {
  const {projectGroupId, requestClientId, offeredClientId} = useParams<{
    projectGroupId: string;
    requestClientId: string;
    offeredClientId: string;
  }>();

  const createPath = React.useCallback(
    (path: string) => {
      if (location.pathname.includes('group')) {
        return `/list/groups/${projectGroupId}/${path}`;
      } else if (location.pathname.includes('request')) {
        return `/list/share/request/${requestClientId}/${path}`;
      } else if (location.pathname.includes('offered')) {
        return `/list/share/offered/${offeredClientId}/${path}`;
      } else {
        return `/list/${path}`;
      }
    },
    [projectGroupId, requestClientId, offeredClientId],
  );

  return {
    createPath,
  };
};

export default useProjectPath;
