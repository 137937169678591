import gql from 'graphql-tag';
import manualMailFragment from '../manualMail';

export default gql`
  fragment manualMailActionMailMessageFragment on ManualMailActionMailMessage {
    id
    updatedAt
    createdAt
    manualMail {
      ...manualMailFragment
    }
  }
  ${manualMailFragment}
`;
