import React from 'react';
import {flexRender} from '@tanstack/react-table';
import tableDef from './TableDef';

const ReportTable = () => {
  const table = tableDef();
  return (
    <table>
      <thead className="sticky top-0">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className="p-0">
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} className="border-b border-1 border-gray-300">
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={`text-right border-r border-1 border-gray-300 p-2 ${
                  row.index === 0 && 'bg-[#F0F2F4]'
                }`}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ReportTable;
