import React from 'react';
import {useHistory} from 'react-router';
import {Button} from 'components/antd';
import styled from 'styled-components';

export default () => {
  const history = useHistory();
  return (
    <Container>
      <Content>
        <h1>Welcome to LEADPAD!</h1>
        <p>LEADPADへのサインアップが完了しました。</p>

        <Button block type="primary" onClick={() => history.push('/')}>
          ログイン画面へ
        </Button>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Content = styled.div`
  &&& {
    width: 500px;
    margin-top: -100px;
    padding: 40px;
  }
`;
