import React from 'react';
import {FormBlock, FormBlockFieldStandard} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldStandard;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const blockable = block.formBlockable;

  return (
    <div className="w-full grid grid-cols-2 gap-4">
      <div className="w-full">
        <div className="flex gap-1 mb-2 items-center">
          <label className="tracking-wide font-bold">
            {blockable.labelLastName || '姓'}
          </label>
          {blockable.isRequired && <span className="text-red-500">*</span>}
          <span className="text-xs h-[18px] px-1 border border-c-border leading-none rounded-sm bg-[#F5FAFE] text-c-lighter flex items-center">
            LEADPAD項目
          </span>
        </div>
        <input
          className="form-input block w-full border rounded"
          type="text"
          disabled
        />
      </div>
      <div className="w-full">
        <div className="flex gap-1 mb-2 items-center">
          <label className="tracking-wide font-bold">
            {blockable.labelFirstName || '名'}
          </label>
          {blockable.isRequired && <span className="text-red-500">*</span>}
          <span className="text-xs h-[18px] px-1 border border-c-border leading-none rounded-sm bg-[#F5FAFE] text-c-lighter flex items-center">
            LEADPAD項目
          </span>
        </div>
        <input
          className="form-input block w-full border rounded"
          type="text"
          disabled
        />
      </div>
    </div>
  );
};
