import React from 'react';
import moment from 'moment';
import {ProspectPool} from 'api';

type Props = {
  prospectPool: ProspectPool;
};

const LeadSource: React.FC<Props> = ({prospectPool}) => {
  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="flex flex-col">
        <div className="text-sm text-c-light truncate">最新リードソース</div>
        <div className="text-sm truncate">
          {prospectPool.leadSource ? prospectPool.leadSource?.name : '-'}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-sm text-c-light truncate">最新流入日</div>
        <div className="text-sm truncate">
          {prospectPool.latestInflowDate
            ? moment(prospectPool.latestInflowDate).format('YYYY/MM/DD')
            : '-'}
        </div>
      </div>
    </div>
  );
};

export default LeadSource;
