import gql from 'graphql-tag';

export default gql`
  fragment campaignReportFragment on CampaignReport {
    id
    clickRate
    clickRateText
    totalCount
    countUnsent
    countMail
    countSent
    countCannotRead
    countInvalid
    countShortage
    countNotfound
    countCannotSend
    countCharOver
    countUnknown
    countRecruit
    countCannotaccept
    countDuplicate
    countBlock
  }
`;
