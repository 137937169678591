import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import Content from './Content';
import Breadcrumbs from './Breadcrumbs';
import {useContentAudioQuery} from 'api';
import {Container, Header} from 'components/Ui';

export default () => {
  const {id} = useParams<{id: string}>();

  const {data: {content = null} = {}} = useContentAudioQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container page="garage">
      <Header>
        <Breadcrumbs />
      </Header>
      <Main>{content && <Content content={content} />}</Main>
    </Container>
  );
};

const Main = styled.div`
  grid-area: main;
  padding: 32px;
  background-color: #f3f5f7;
  overflow: auto;
  height: calc(100vh - 130px);
  position: relative;
`;
