import React from 'react';
import {Activity, usePreleadProjectQuery} from 'api';
import useProject from './useProject';
import useProjectGroups from './useProjectGroups';
import useClientUser from './useClientUser';

const usePreleadProject = (uuid: string) => {
  const {data: {preleadProject: {project = {}} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: uuid},
      skip: !uuid,
    });

  const {isProjectGroupAccessible} = useProjectGroups();

  const {isOwnProject} = useProject(project.uuid);
  const {isAdmin} = useClientUser();

  const timeLineActivities = React.useCallback(
    (activities: Activity[]) =>
      isOwnProject
        ? activities.filter(
            (activity) =>
              isAdmin ||
              isProjectGroupAccessible(activity.project?.projectGroup),
          )
        : activities.filter(
            (activity) =>
              activity.resourceType === 'Activity::PreleadCompanyUpdate' ||
              (!activity.project &&
                activity.resourceType === 'Activity:::ZoomPhoneWebhookCall') ||
              activity.project?.projectGroupId === project?.projectGroup.id,
          ),
    [isOwnProject, isAdmin, project],
  );

  const timeLineMailActivities = React.useCallback(
    (mailActivities: Activity[]) =>
      isOwnProject
        ? mailActivities.filter(
            (activity) =>
              isAdmin ||
              isProjectGroupAccessible(activity.project?.projectGroup),
          )
        : mailActivities.filter(
            (activity) =>
              activity.project?.projectGroupId === project?.projectGroup.id,
          ),
    [isOwnProject, isAdmin, project],
  );

  const timeLineTelActivities = React.useCallback(
    (telActivities: Activity[]) =>
      isOwnProject
        ? telActivities.filter(
            (activity) =>
              isAdmin ||
              isProjectGroupAccessible(activity.project?.projectGroup),
          )
        : telActivities.filter(
            (activity) =>
              activity.project?.projectGroupId === project?.projectGroup.id,
          ),
    [isOwnProject, isAdmin, project],
  );

  const timeLineLetterActivities = React.useCallback(
    (letterActivities: Activity[]) =>
      isOwnProject
        ? letterActivities.filter(
            (activity) =>
              isAdmin ||
              isProjectGroupAccessible(activity.project?.projectGroup),
          )
        : letterActivities.filter(
            (activity) =>
              activity.project?.projectGroupId === project?.projectGroup.id,
          ),
    [isOwnProject, isAdmin, project],
  );

  return {
    isOwnProject,
    timeLineActivities,
    timeLineMailActivities,
    timeLineTelActivities,
    timeLineLetterActivities,
  };
};

export default usePreleadProject;
