import React from 'react';
import {ProspectActivity, Task, CallProspectActivity, ProspectPool} from 'api';
import TaskActivity from './Task';
import CallActivity from './Call';
import MiitelCallActivity from './MiitelCall';
import StageChangeActivity from './StageChange';
import RankChangeActivity from './RankChange';
import styled from 'styled-components';
import ManualMailSendActivity from './ManualMailSend';
import ManualMailCommentActivity from './ManualMailComment';
import ManualMailGmailSendActivity from './ManualMailGmailSend';
import AutomaticMailSendActivity from './AutomaticMailSend';
import AutomaticMailOpenActivity from './AutomaticMailOpen';
import AutomaticMailClickActivity from './AutomaticMailClick';
import AutomaticMailBounceActivity from './AutomaticMailBounce';
import SalesforceDealActivity from './SalesforceDeal';
import ApplyWorkflowActivity from './ApplyWorkflow';
import CommentActivity from './Comment';
import MailCommentActivity from './MailComment';
import ManualMailOpenActivity from './ManualMailOpen';
import ManualMailClickActivity from './ManualMailClick';
import ManualMailBounceActivity from './ManualMailBounce';
import CollectedFormActivity from './CollectedForm';
import ConvertPreleadContactToProspectActivity from './ConvertPreleadContactToProspect';
import SalesforceContactLeadCreateActivity from './SalesforceContactLeadCreate';
import SalesforceLeadCreateActivity from './SalesforceLeadCreate';
import SalesforceEventCreateActivity from './SalesforceEventCreate';
import SalesforceTaskActivity from './SalesforceTask';
import SalesforceTaskCallActivity from './SalesforceTaskCall';
import SalesforceTaskEmailActivity from './SalesforceTaskEmail';
import SalesforceTaskTodoActivity from './SalesforceTaskTodo';
import StopWorkflowActivity from './StopWorkflow';
import UnsubscribeAutomaticMailActivity from './UnsubscribeAutomaticMail';
import SubscribeAutomaticMailActivity from './SubscribeAutomaticMail';
import MailCampaignMailSendActivity from './MailCampaignMailSend';
import MailCampaignMailOpenActivity from './MailCampaignMailOpen';
import MailCampaignMailBounceActivity from './MailCampaignMailBounce';
import MailCampaignMailClickActivity from './MailCampaignMailClick';
import UnsubscribeMailCampaignActivity from './UnsubscribeMailCampaign';
import TaskCommentActivity from './TaskComment';
import HubspotContactCreateActivity from './HubspotContactCreate';
import HubspotDealCreateActivity from './HubspotDealCreate';
import HubspotNoteActivity from './HubspotNote';
import HubspotCallActivity from './HubspotCall';
import HubspotEmailActivity from './HubspotEmail';
import ZoomPhoneWebhookCallActivity from './ZoomPhoneWebhookCall';
import FormPostActivity from './FormPost';
import PreleadProjectCreateActivity from './PreleadProjectCreate';
import ClientPhaseProspectChangeActivity from './ClientPhaseProspectChange';

const prospectActivityComponents: {[key: string]: any} = {
  TaskProspectActivity: TaskActivity,
  CallProspectActivity: CallActivity,
  MiitelCallProspectActivity: MiitelCallActivity,
  StageChangeProspectActivity: StageChangeActivity,
  RankChangeProspectActivity: RankChangeActivity,
  ManualMailSendProspectActivity: ManualMailSendActivity,
  ManualMailCommentProspectActivity: ManualMailCommentActivity,
  ManualMailGmailSendProspectActivity: ManualMailGmailSendActivity,
  AutomaticMailSendProspectActivity: AutomaticMailSendActivity,
  AutomaticMailOpenProspectActivity: AutomaticMailOpenActivity,
  AutomaticMailClickProspectActivity: AutomaticMailClickActivity,
  AutomaticMailBounceProspectActivity: AutomaticMailBounceActivity,
  SalesforceDealProspectActivity: SalesforceDealActivity,
  ApplyWorkflowProspectActivity: ApplyWorkflowActivity,
  CommentProspectActivity: CommentActivity,
  MailCommentProspectActivity: MailCommentActivity,
  ManualMailOpenProspectActivity: ManualMailOpenActivity,
  ManualMailClickProspectActivity: ManualMailClickActivity,
  ManualMailBounceProspectActivity: ManualMailBounceActivity,
  CollectedFormProspectActivity: CollectedFormActivity,
  ConvertPreleadContactToProspectProspectActivity:
    ConvertPreleadContactToProspectActivity,
  SalesforceContactLeadCreateProspectActivity:
    SalesforceContactLeadCreateActivity,
  SalesforceLeadCreateProspectActivity: SalesforceLeadCreateActivity,
  SalesforceEventCreateProspectActivity: SalesforceEventCreateActivity,
  SalesforceTaskProspectActivity: SalesforceTaskActivity,
  SalesforceTaskCallProspectActivity: SalesforceTaskCallActivity,
  SalesforceTaskEmailProspectActivity: SalesforceTaskEmailActivity,
  SalesforceTaskTodoProspectActivity: SalesforceTaskTodoActivity,
  StopWorkflowProspectActivity: StopWorkflowActivity,
  UnsubscribeAutomaticMailProspectActivity: UnsubscribeAutomaticMailActivity,
  SubscribeAutomaticMailProspectActivity: SubscribeAutomaticMailActivity,
  MailCampaignMailSendProspectActivity: MailCampaignMailSendActivity,
  MailCampaignMailOpenProspectActivity: MailCampaignMailOpenActivity,
  MailCampaignMailBounceProspectActivity: MailCampaignMailBounceActivity,
  MailCampaignMailClickProspectActivity: MailCampaignMailClickActivity,
  UnsubscribeMailCampaignProspectActivity: UnsubscribeMailCampaignActivity,
  TaskCommentProspectActivity: TaskCommentActivity,
  HubspotContactCreateProspectActivity: HubspotContactCreateActivity,
  HubspotDealCreateProspectActivity: HubspotDealCreateActivity,
  HubspotNoteProspectActivity: HubspotNoteActivity,
  HubspotCallProspectActivity: HubspotCallActivity,
  HubspotEmailProspectActivity: HubspotEmailActivity,
  ZoomPhoneWebhookCallProspectActivity: ZoomPhoneWebhookCallActivity,
  FormPostProspectActivity: FormPostActivity,
  PreleadProjectCreateProspectActivity: PreleadProjectCreateActivity,
  ClientPhaseProspectChangeProspectActivity: ClientPhaseProspectChangeActivity,
};

interface Props {
  prospectActivity: ProspectActivity;
  prospectPool: ProspectPool;
  setIsTaskEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setTask?: React.Dispatch<React.SetStateAction<Task>>;
  setIsCallEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setCall?: React.Dispatch<React.SetStateAction<CallProspectActivity>>;
  setIsCommentEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setComment?: React.Dispatch<React.SetStateAction<ProspectActivity>>;
  setIsTaskCommentEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setTaskComment?: React.Dispatch<React.SetStateAction<ProspectActivity>>;
}

const ProspectTimelineItem = ({
  prospectActivity,
  prospectPool,
  setIsTaskEdit,
  setTask,
  setIsCallEdit,
  setCall,
  setIsCommentEdit,
  setComment,
  setIsTaskCommentEdit,
  setTaskComment,
}: Props) => {
  const ProspectActivityComponent =
    prospectActivityComponents[prospectActivity.resource.__typename];

  return (
    <Container>
      <ProspectActivityComponent
        prospectActivity={prospectActivity}
        prospectPool={prospectPool}
        setIsTaskEdit={setIsTaskEdit}
        setTask={setTask}
        setIsCallEdit={setIsCallEdit}
        setCall={setCall}
        setIsCommentEdit={setIsCommentEdit}
        setComment={setComment}
        setIsTaskCommentEdit={setIsTaskCommentEdit}
        setTaskComment={setTaskComment}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-bottom: 30px;
  position: relative;
  word-break: break-word;

  & > svg {
    z-index: 2;
    height: 30px;
    width: 30px;
  }

  &:not(:last-of-type):before {
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 14.5px;
    display: block;
    width: 1px;
    content: '';
    z-index: 1;
    background-color: #e1e6eb;
  }
`;

export default ProspectTimelineItem;
