import React from 'react';
import {Row} from 'react-table';
import {Prelead} from 'api';

interface Props {
  row: Row<Prelead>;
  value: any;
}

const isUrl = (str: string | null) => /^https?:\/\//.test(str);

const CustomizeItemTextCell = ({row, value}: Props) => {
  const prelead: Prelead = row.original;
  const clientPreleadCustomizeItemId = value;

  const customizeItem = React.useMemo(
    () =>
      prelead.customizeItemTexts.find(
        (item) =>
          item.clientPreleadCustomizeItemId === clientPreleadCustomizeItemId,
      ),
    [prelead, clientPreleadCustomizeItemId],
  );

  return isUrl(customizeItem?.value) ? (
    <a href={customizeItem.value} target="_blank">
      {customizeItem.value}
    </a>
  ) : (
    customizeItem?.value || null
  );
};

export default CustomizeItemTextCell;
