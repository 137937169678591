import React from 'react';
import {useLeadSourcesQuery, LeadSource} from 'api';
import useSearchParams from '../useSearchParams';
import Close from './Close';

export default () => {
  const {query, setQuery} = useSearchParams();
  const {data: {leadSources = []} = {}} = useLeadSourcesQuery({});

  return (
    <div className="flex gap-2">
      {leadSources
        .filter((leadSource) => query.leadSourceIds.includes(leadSource.id))
        .map((item: LeadSource) => {
          return (
            <div
              key={item.id}
              className="bg-[#F3F5F7] h-7 p-2 flex gap-1 items-center">
              <div className="text-[#495058]">{item.name}</div>
              <div
                className="flex items-center hover:cursor-pointer"
                onClick={() => {
                  setQuery({
                    leadSourceIds: query.leadSourceIds.filter(
                      (leadSourceId) => leadSourceId !== item.id,
                    ),
                  });
                }}>
                <Close />
              </div>
            </div>
          );
        })}
      {query.leadSourceIds?.length > 0 && (
        <div
          className="text-[#68B5FB] flex items-center font-bold hover:cursor-pointer"
          onClick={() => setQuery({leadSourceIds: []})}>
          All Clear
        </div>
      )}
    </div>
  );
};
