import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  if (!query.capitalFund?.from && !query.capitalFund?.to) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">資本金</h4>
        <button
          onClick={() => setFilterType('capitalFund')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() => setQuery({...query, capitalFund: {}})}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div className="flex gap-1">
        <div className="flex flex-wrap items-center">
          <div>
            {query.capitalFund?.from && (
              <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {query.capitalFund.from}万円
              </span>
            )}
            <span className="text-c-light px-2">~</span>
            {query.capitalFund?.to && (
              <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {query.capitalFund.to}万円
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
