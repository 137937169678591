import React from 'react';
import {Input} from 'components/antd';
import Table from './Table';
import {Lense} from 'components/Ui/Icon';
import Count from './Count';

export default () => {
  return (
    <div>
      <div className="flex justify-end items-end mb-2">
        {/* <Input
          type="search"
          name="search"
          placeholder="検索"
          prefix={<Lense />}
          className="flex items-center border rounded h-8 w-48 bg-white border-c-border focus:outline-none focus:bg-white"
        /> */}
        <div className="flex items-end gap-4">
          <Count />
          {/* <button className="bg-c-primary rounded h-9 text-white font-bold px-4">
            新規リードを追加
          </button> */}
        </div>
      </div>
      <Table />
    </div>
  );
};
