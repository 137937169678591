import React from 'react';

function Icon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E1E6EB" />
      <path
        d="M18 9.33325H20V11.9999H18.6667C17.9303 11.9999 17.3333 12.5969 17.3333 13.3333V14.6666H20L19.3043 17.3333H17.2174V22.6666H14.4348V17.3333H12V14.693H14.6667V12.6666C14.6667 10.8256 16.1591 9.33325 18 9.33325Z"
        fill="#4371B6"
      />
    </svg>
  );
}

export default Icon;
