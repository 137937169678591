import React from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';

type Props = {
  children?: React.ReactNode;
  htmlType?: 'submit' | 'button';
  type?: 'primary' | 'ghost' | 'dashed';
  buttonType?: 'default' | 'outlined';
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactNode;
  style?: any;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export default ({buttonType, children, ...props}: Props) => {
  if (buttonType === 'default') {
    return <DefaultButton {...props}>{children}</DefaultButton>;
  } else if (buttonType === 'outlined') {
    return <OutlinedButton {...props}>{children}</OutlinedButton>;
  }
  return <StyledButton {...props}>{children}</StyledButton>;
};

const StyledButton = styled(Button)`
  &&& {
    background: #4f74d1;
    border-radius: 8px;
    color: #fff;
    line-height: 30px;
    height: 40px;
    padding: 0 32px;
    border: none;

    &:hover {
      color: #fff;
      background: #4f74d1;
    }
    &:disabled {
      color: #6e7489;
      background: #f3f3f5;
    }
  }
`;

const DefaultButton = styled(Button)`
  background: #fff;
  border: none;
  line-height: 30px;
  height: 40px;
  padding: 0 25px;
  color: #4f74d1;
`;

const OutlinedButton = styled(StyledButton)`
  &&& {
    background: #fff;
    border: 1px solid #b9c7ed;
    color: #b9c7ed;

    &:hover {
      color: #b9c7ed;
      background: rgba(235, 240, 255, 0.8);
    }
  }
`;
