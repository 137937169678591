import React from 'react';
import {PreleadProject} from 'api';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
  row: any;
  value: any;
}

const NameCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;

  return (
    <RemindAt
      isToday={
        preleadProject.reminder &&
        moment().isSame(moment(preleadProject.reminder.remindAt), 'day')
      }>
      {preleadProject.reminder &&
        moment(preleadProject.reminder.remindAt).format('MM/DD(ddd) HH:mm')}
    </RemindAt>
  );
};

export default NameCell;

const RemindAt = styled.span<{isToday: boolean}>`
  ${({isToday}) => isToday && 'color: #EB5757'};
`;
