import React from 'react';
import styled from 'styled-components';
import {Check} from 'components/Ui/Icon';
import {regions} from 'helpers/regions';

interface Props {
  currentRegion: any;
  setCurrentRegion: (region: any) => void;
  isRegionSelected: (region: any) => boolean;
}

export default ({currentRegion, setCurrentRegion, isRegionSelected}: Props) => (
  <MenuGroup>
    {regions.map((region) => (
      <Menu
        key={region.regionType}
        onClick={() => setCurrentRegion(region)}
        current={region.regionType === currentRegion.regionType ? 1 : 0}>
        {region.name}
        {isRegionSelected(region) && (
          <SelectLabel>
            <Check />
          </SelectLabel>
        )}
      </Menu>
    ))}
  </MenuGroup>
);

const SelectLabel = styled.span`
  margin: 0 20px 0 auto;
`;

const MenuGroup = styled.div`
  width: 200px;
  border-right: 1px solid #e2e6ea;
  overflow-y: scroll;
`;

const Menu = styled.div<{current?: number}>`
  &&& {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222426;
    white-space: pre;
    padding: 0 0 0 25px;
    height: 50px;
    background: ${(props) => (props.current ? '#E2E6EA' : '')};
    &:last-chld {
      margin-bottom: 50px;
    }
    svg {
      position: relative;
      top: 2px;
    }
  }
`;
