import React from 'react';
import {ProspectPool, useCurrentUserQuery, usePoolQuery} from 'api';
import {Salesforce} from 'components/Ui/Icon';
import SalesforceModal, {SalesforceModalTabs} from './Modal';
import useProspectContext from '../../useProspectContext';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {poolId} = useProspectContext();
  const {data: {currentUser} = {}} = useCurrentUserQuery();
  const {data: {pool = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
  });

  const [modalVisible, setModalVisible] = React.useState<SalesforceModalTabs>();

  const onClick = (tab?: SalesforceModalTabs) => setModalVisible(tab);

  if (!prospectPool) return null;
  if (currentUser?.role !== 'admin') return null;
  if (!pool?.poolSalesforceSetting?.isActive) return null;

  return (
    <>
      <div className="flex w-max cursor-pointer items-center gap-2 [&>svg]:w-4">
        <Salesforce />
        <div className="flex items-center gap-1">
          <button
            onClick={() => onClick('deals')}
            className="flex h-5 cursor-pointer items-center gap-1 rounded-sm border-none bg-[rgba(3,155,229,0.1)] px-1 text-xs font-medium text-[#45A0DA]">
            商談
            <span className="flex h-3 items-center rounded-full bg-[#C7E0EF] px-1 text-xs font-bold text-[#45A0DA]">
              {prospectPool.salesforceDeals?.length || 0}
            </span>
          </button>
          {prospectPool?.salesforceContact?.salesforceAccount && (
            <button
              onClick={() => onClick('account')}
              className="flex h-5 cursor-pointer items-center gap-1 rounded-sm border-none bg-[rgba(3,155,229,0.1)] px-1 text-xs font-medium text-[#45A0DA]">
              取引先
            </button>
          )}
          {prospectPool?.salesforceContact && (
            <button
              onClick={() => onClick('contact')}
              className="flex h-5 cursor-pointer items-center gap-1 rounded-sm border-none bg-[rgba(3,155,229,0.1)] px-1 text-xs font-medium text-[#45A0DA]">
              取引先責任者
            </button>
          )}
          {prospectPool?.salesforceLead && (
            <button
              onClick={() => onClick('lead')}
              className="flex h-5 cursor-pointer items-center gap-1 rounded-sm border-none bg-[rgba(3,155,229,0.1)] px-1 text-xs font-medium text-[#45A0DA]">
              リード
            </button>
          )}
        </div>
      </div>
      <SalesforceModal
        poolId={poolId}
        prospectPool={prospectPool}
        visible={!!modalVisible}
        onClose={() => setModalVisible(undefined)}
        defaultTab={modalVisible}
      />
    </>
  );
};
