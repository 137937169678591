import React from 'react';
import useCondition from '../../useCondition';
import Header from '../Header';
import Footer from './Footer';

const CapitalFundFilter = () => {
  const {condition, setCondition} = useCondition();

  const onChange = (from: string, to: string) =>
    setCondition({
      capitalFund: {
        from: from ? Number(from) : undefined,
        to: to ? Number(to) : undefined,
      },
    });

  return (
    <>
      <Header title="資本金" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <div className="text-c-base font-bold">資本金</div>
          <div className="flex items-center">
            <input
              min={1}
              type="number"
              defaultValue={condition?.capitalFund?.from}
              onBlur={(e: any) =>
                onChange(e.target.value, condition?.capitalFund?.to)
              }
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light relative"
            />
            <span className="ml-1">万円</span>
            <span className="mx-2">~</span>
            <input
              min={1}
              type="number"
              defaultValue={condition?.capitalFund?.to}
              onBlur={(e: any) =>
                onChange(condition?.capitalFund?.from, e.target.value)
              }
              className="form-input appearance-none w-24 bg-white border-c-lighter px-2 py-2 rounded leading-normal focus:outline-none text-sm text-c-light disabled:bg-c-bg disabled:text-c-light"
            />
            <span className="ml-1">万円</span>
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setCondition({
            capitalFund: {},
          })
        }
      />
    </>
  );
};

export default CapitalFundFilter;
