import {useAccountPoolQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';
import Signalable from './Signalable';

export default () => {
  const {accountId} = useParams<{
    accountId: string;
  }>();

  const {data: {accountPool: {recentAccountSignals = []} = {}} = {}} =
    useAccountPoolQuery({variables: {uuid: accountId}});

  return (
    <div className="bg-white border border-c-border rounded">
      <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
        シグナル
      </h2>
      <div className="p-4 flex flex-col gap-3">
        {recentAccountSignals.map((signal) => (
          <div key={signal.id} className="flex items-center gap-4">
            <Signalable signal={signal} />
          </div>
        ))}
      </div>
    </div>
  );
};
