import gql from 'graphql-tag';

export default gql`
  fragment responseStatusAndCommentActivityFragment on ResponseStatusAndCommentActivity {
    id
    responseStatus
    responseStatusText
    body
    bodyPlainText
  }
`;
