import React from 'react';
import styled from 'styled-components';
import {
  ProspectPool,
  usePoolProspectCustomizeItemsQuery,
  PoolProspectCustomizeItem,
} from 'api';
import Item from './Item';
import NewModal from './NewModal';
import {ArrowDown} from 'components/Ui/Icon';
import EditModal from './EditModal';
import {Plus, Setting as SettingIcon} from 'components/Ui/Icon';
import useClientUser from 'hooks/useClientUser';
import useProspectContext from '../useProspectContext'

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {poolId} = useProspectContext()
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const {isAdmin} = useClientUser();

  const {data: {poolProspectCustomizeItems = []} = {}} =
    usePoolProspectCustomizeItemsQuery({
      variables: {poolId: poolId},
    });

  const filteredPoolProspectCustomizeItems: PoolProspectCustomizeItem[] =
    React.useMemo(() => {
      const filteredCustomizeItems = showMore
        ? poolProspectCustomizeItems
        : poolProspectCustomizeItems.slice(0, 5);
      return filteredCustomizeItems;
    }, [poolProspectCustomizeItems, showMore]);

  return (
    <>
      <Contents>
        <Items>
          {filteredPoolProspectCustomizeItems
            .filter(
              (item) => item.isDisplay === true && item.isDeleted === false,
            )
            .map((item) => (
              <Item
                prospect={prospectPool?.prospect}
                poolProspectCustomizeItem={item}
                key={item.id}
              />
            ))}
        </Items>

        {isAdmin && (
          <CustomItemOptions>
            <PlusButton size="middle" onClick={() => setNewModalVisible(true)}>
              <Plus />
              項目を追加
            </PlusButton>
            <Setting size="middle" onClick={() => setEditModalVisible(true)}>
              <SettingIcon />
            </Setting>
          </CustomItemOptions>
        )}
        {poolProspectCustomizeItems.length > 5 && (
          <ShowMore onClick={() => setShowMore(!showMore)} showMore={showMore}>
            <ArrowDown color={'#68b5fb'} />
            <span>{showMore ? '一部の項目を表示' : 'すべての項目を表示'}</span>
          </ShowMore>
        )}
      </Contents>
      {newModalVisible && (
        <NewModal visible={newModalVisible} setVisible={setNewModalVisible} />
      )}
      {editModalVisible && (
        <EditModal
          visible={editModalVisible}
          setVisible={setEditModalVisible}
        />
      )}
    </>
  );
};

const ShowMore = styled.div<{showMore: boolean}>`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  > span {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #68b5fb;
  }
  > svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    transform: rotate(${({showMore}) => (showMore ? '180deg' : '0deg')});
  }
`;

const Contents = styled.div`
  margin-bottom: 2rem;
  overflow-y: scroll;
  max-height: 50vh;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const CustomItemOptions = styled.div`
  display: flex;
  gap: 5px;
`;

const Setting = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg {
    height: 1rem;
    width: auto;
  }
`;

const PlusButton = styled.div`
  width: 100px;
  height: 32px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #899098;
  cursor: pointer;

  & > span {
    margin-left: 2px;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
  }

  & > svg {
    height: 1rem;
    width: auto;
  }

  & > svg > path {
    stroke-width: 2;
  }
`;
