import React from 'react';
import {Approach} from 'api';
import moment from 'moment';

interface Props {
  approach: Approach;
}

const DueDate = ({approach}: Props) => {
  return (
    <div className="text-sm text-c-light">
      {approach.startDate && moment(approach.startDate).format('MM/DD')}
      {` `}-{` `}
      {approach.dueDate && moment(approach.dueDate).format('MM/DD')}
    </div>
  );
};

export default ({approach}: Props) => (
  <div className="text-xs text-c-light flex items-center gap-1">
    日付:
    <DueDate approach={approach} />
  </div>
);
