import React from 'react';
import styled from 'styled-components';
import {Button, Popover, Tooltip} from 'components/antd';
import NewProject from './NewProject';
import useContract from 'hooks/useContract';
import {Note, Plus} from 'components/Ui/Icon';
import AddProject from './AddProject';

interface Props {
  setPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewProjectPopover = ({setPopoverVisible}: Props) => {
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [addModalVisible, setAddModalVisible] = React.useState(false);

  const {isProjectAddable} = useContract();

  return (
    <PopoverContent>
      {isProjectAddable ? (
        <ModalButton
          type="text"
          className={false ? 'active' : ''}
          onClick={() => {
            setNewModalVisible(true);
            setPopoverVisible(false);
          }}>
          <Note color={false ? '#68b5fb' : '#BDC4CB'} />
          <span>新規作成</span>
        </ModalButton>
      ) : (
        <Tooltip
          title="営業リスト数が上限に達しました。"
          color={`#EB5757`}
          placement="top">
          <ModalButton type="text" disabled>
            <Note color={'#BDC4CB'} />
            <span>新規作成</span>
          </ModalButton>
        </Tooltip>
      )}

      <ModalButton
        type="text"
        className={false ? 'active' : ''}
        onClick={() => {
          setAddModalVisible(true);
          setPopoverVisible(false);
        }}>
        <Plus color={false ? '#68b5fb' : '#BDC4CB'} />
        <span>追加</span>
      </ModalButton>

      {newModalVisible && (
        <NewProject
          isModalVisible={newModalVisible}
          setModalVisible={setNewModalVisible}
        />
      )}

      {addModalVisible && (
        <AddProject
          isModalVisible={addModalVisible}
          setModalVisible={setAddModalVisible}
        />
      )}
    </PopoverContent>
  );
};

export default () => {
  const [popoverVisible, setPopoverVisible] = React.useState(false);

  return (
    <PopoverButton
      content={<NewProjectPopover setPopoverVisible={setPopoverVisible} />}
      placement="bottom"
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={setPopoverVisible}
      className={false ? 'active' : ''}>
      <Note color={false ? '#BDC4CB' : '#68b5fb'} />
      <span>営業リストを作成</span>
    </PopoverButton>
  );
};

const ModalButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  svg {
    height: 17px;
    width: 27px;
    margin-right: 5px;
  }

  span {
    justify-content: flex-start;
    font-weight: bold;
    font-size: 12px;
    color: #bdc4cb;
  }

  &:hover {
    span {
      color: #68b5fb;
    }
    path {
      stroke: #68b5fb;
    }
  }

  &.active {
    span {
      color: #68b5fb;
    }
  }
`;

const PopoverButton = styled(Popover)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 165px;
  height: 32px;
  font-weight: bold;
  color: #68b5fb;
  border: 1.5px solid #68b5fb;
  box-sizing: border-box;
  border-radius: 100px;

  svg {
    height: 17px;
    margin-right: 5px;
  }

  &.active {
    span {
      color: #68b5fb;
    }
  }
`;

const PopoverContent = styled.div`
  background: white;
  width: 165px;
  height: 85px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;
