import React from 'react';
import {Prelead, useAllPrefecturesQuery} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<Prelead>;
}

const PrefectureCell = ({row}: Props) => {
  const prelead: Prelead = row.original;
  const {data: {allPrefectures = []} = {}} = useAllPrefecturesQuery();
  const prefecture = allPrefectures.find(
    (prefecture) => prefecture.id === prelead.prefectureId,
  );

  return <>{prefecture?.name}</>;
};

export default PrefectureCell;
