import React, {useState} from 'react';
import {CopyToolTip} from './CopyToolTip';

interface Props {
  copyText: string;
}

export default ({copyText}: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const copyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <div
      onClick={() => {
        setIsTooltipVisible(true);
        copyTextToClipboard(copyText);
        setTimeout(() => {
          setIsTooltipVisible(false);
        }, 2000);
      }}>
      <CopyToolTip isVisible={isTooltipVisible} />
    </div>
  );
};
