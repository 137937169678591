import React from 'react';
import {Salesforce} from 'components/Ui/Icon';

export default () => {
  return (
    <div className="flex gap-3 items-center justify-start">
      <Salesforce />
      <div className="text-xl font-bold">Salesforce</div>
    </div>
  );
};
