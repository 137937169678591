import React from 'react';
import {
  Form,
  usePoolQuery,
  useUpdateFormProspectTagsMutation,
  useCreateProspectTagMutation,
} from 'api';
import {Combobox} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/20/solid';
import usePools from 'hooks/usePools';
import {getTagFontColor, tagColors} from 'helpers/tagColors';

interface Props {
  form: Form;
}

const getRandomColor = (): string => {
  const colors = tagColors.map((color) => color.hex);
  const color = colors[Math.floor(Math.random() * colors.length)];
  return color;
};

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

export default ({form}: Props) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {defaultPool: pool} = usePools();

  const [tags, setTags] = React.useState(
    form.prospectTags.map((t) => ({id: t.id, name: t.name})),
  );
  const [tagName, setTagName] = React.useState('');
  const {data: {pool: {prospectTags = []} = {}} = {}} = usePoolQuery({
    variables: {uuid: pool?.uuid},
    skip: !pool,
  });
  const [update] = useUpdateFormProspectTagsMutation({
    onCompleted: ({
      updateFormProspectTags: {form: {prospectTags = []} = {}} = {},
    }) => {
      setTagName('');
      setTags(prospectTags.map((t) => ({id: t.id, name: t.name})));
    },
  });
  const [create] = useCreateProspectTagMutation({
    refetchQueries: ['pool'],
    onCompleted: ({createProspectTag: {prospectTag} = {}}) =>
      update({
        variables: {
          uuid: form.uuid,
          prospectTagIds: [...tags.map((t) => t.id), prospectTag.id],
        },
      }),
  });

  const randomColor = React.useMemo(() => getRandomColor(), [prospectTags]);

  const selectedTags = React.useMemo(
    () =>
      tags
        .map((tag) =>
          prospectTags.find((prospectTag) => prospectTag.id === tag.id),
        )
        .filter((tag) => tag),
    [tags, prospectTags],
  );

  const newTag = React.useMemo(
    () => tagName && !prospectTags.find((t) => t.name === tagName),
    [tagName, prospectTags],
  );

  const filteredTags = React.useMemo(
    () =>
      prospectTags
        ?.filter(
          (prospectTag) =>
            tags.map((t) => t.name).indexOf(prospectTag.name) === -1,
        )
        ?.filter((prospectTag) =>
          kanaToHira(prospectTag.name).includes(kanaToHira(tagName)),
        ),
    [prospectTags, tagName, tags],
  );

  const onSelectTag = React.useCallback(
    (name: string) => {
      if (!name) return;

      const tag = prospectTags.find((t) => t.name === name);

      if (!tag) {
        create({
          variables: {
            attributes: {
              name,
              color: randomColor,
              poolId: pool.uuid,
            },
          },
        });
      } else {
        update({
          variables: {
            uuid: form.uuid,
            prospectTagIds: [...tags.map((t) => t.id), tag.id],
          },
        });
      }
    },
    [prospectTags, randomColor, tags],
  );

  const onChange = React.useCallback(
    (tagIds: string[]) => {
      update({
        variables: {
          uuid: form.uuid,
          prospectTagIds: tagIds,
        },
      });
    },
    [form.uuid, update],
  );

  return (
    <div className="flex flex-col gap-12 w-[480px]">
      <div className="flex flex-col gap-1">
        <label className="text-c-base text-base font-bold">タグを選択</label>
        <p className="text-c-base text-sm m-0">
          フォームから問合せがあったリードに付与するタグの種類を設定できます
        </p>
        <Combobox value={''} onChange={onSelectTag}>
          <div className="relative" onClick={() => inputRef.current?.focus()}>
            <div className="relative w-full min-h-[2.5rem] cursor-default rounded-sm bg-white p-2 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap">
              {selectedTags.map((tag) => (
                <span
                  key={tag.name}
                  className="truncate inline-flex items-center justify-center pl-2 pr-1 py-1 text-sm font-medium leading-4 rounded-sm"
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    backgroundColor: tag.color || 'black',
                    color: getTagFontColor(tag.color),
                  }}>
                  {tag.name}
                  <XMarkIcon
                    className="w-4 h-4 ml-2 cursor-pointer hover:bg-[rgba(255,255,255,.3)] rounded-sm"
                    onClick={() =>
                      onChange(
                        tags.map((t) => t.id).filter((id) => id !== tag.id),
                      )
                    }
                  />
                </span>
              ))}
              <Combobox.Input
                className="flex-1 outline-none border-transparent focus:border-transparent focus:ring-0"
                onChange={(e) => setTagName(e.target.value)}
                onFocus={() => buttonRef.current?.click()}
                value={tagName}
                ref={inputRef}
              />
              <Combobox.Button className="invisible" ref={buttonRef} />
            </div>
            {(newTag || filteredTags.length > 0) && (
              <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-sm bg-white text-sm shadow">
                {filteredTags.map((tag) => (
                  <Combobox.Option
                    key={tag.id}
                    value={tag.name}
                    className="flex items-center gap-2 p-2 hover:bg-c-bg cursor-pointer">
                    <span
                      className="h-3 w-3 rounded-sm bg text-sm"
                      style={{backgroundColor: tag.color || 'black'}}
                    />
                    <span className="truncate flex-1">{tag.name}</span>
                  </Combobox.Option>
                ))}
                {newTag && (
                  <Combobox.Option
                    value={tagName}
                    className="flex items-center gap-2 hover:bg-c-bg cursor-pointer p-2">
                    <span
                      className="h-3 w-3 rounded-sm bg text-sm"
                      style={{backgroundColor: randomColor}}
                    />
                    <span className="truncate">{tagName}</span>
                    <span className="text-xs text-c-light">を新規作成</span>
                  </Combobox.Option>
                )}
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      </div>
    </div>
  );
};
