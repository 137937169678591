import Status from './Status';
import Title from './Title';
import Prospect from './Prospect';
import Priority from './Priority';
import Assignee from './Assignee';
import Description from './Description';
import DueDate from './DueDate';

export default {
  Status,
  Title,
  Prospect,
  Priority,
  Assignee,
  Description,
  DueDate,
};
