import React from 'react';
import {useSalesforceContactFieldQuery} from 'api';

interface Props {
  contactFieldId: string;
  values: string[];
}

export default ({contactFieldId, values}: Props) => {
  const {
    data: {
      salesforceContactField: {salesforceContactFieldSelectOptions = []} = {},
    } = {},
  } = useSalesforceContactFieldQuery({
    variables: {contactFieldId},
    skip: !contactFieldId,
  });

  if (!salesforceContactFieldSelectOptions?.length) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-wrap items-center gap-1">
      {salesforceContactFieldSelectOptions
        .filter((option) => values.includes(option.id))
        .map((option) => (
          <span
            key={option.id}
            className="h-6 flex items-center justify-center text-sm border border-c-border rounded-sm bg-c-bg px-2">
            {option.label}
          </span>
        ))}
    </div>
  );
};
