import gql from 'graphql-tag';

export default gql`
  fragment accountSignalFragment on AccountSignal {
    id
    signalableClass
    date
    signalable {
      ... on Prtime {
        id
        url
        title
      }
      ... on JobListingPage {
        id
        url
        title
      }
      ... on Tracking {
        id
        url
        prospect {
          id
          lastName
          firstName
        }
      }
    }
  }
`;
