import React from 'react';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';
import {Pagination as PaginationType, PreleadProject} from 'api';
import Filter from './Filter';
import Title from '../Title';
import Table from './Table';

interface Props {
  preleadProjects: PreleadProject[];
  pagination: PaginationType;
  setPage: (page: number) => void;
  perPage: number;
}

export default ({preleadProjects, pagination, setPage, perPage}: Props) => {
  return (
    <Container>
      <Title title="条件" />
      <Content>
        <Filter />
        <TableWrapper>
          <Table preleadProjects={preleadProjects} />
          <Pagination
            size="small"
            showSizeChanger={false}
            showQuickJumper={false}
            total={pagination.totalCount}
            current={pagination.currentPage}
            pageSize={perPage}
            onChange={(page) => setPage(page)}
          />
        </TableWrapper>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 25px;
`;

const Content = styled.div`
  display: flex;
  gap: 2rem;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: #495058;
      background: #e2e6ea;
      border-radius: 100px;
    }
  }
`;
