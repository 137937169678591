import {ProspectPool} from 'api';
import React from 'react';
import {Row} from 'react-table';

interface Props {
  row: Row<ProspectPool>;
}

const Status = ({row}: Props) => {
  const prospectPool = row.original;

  return (
    <span className="px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]">
      {prospectPool.stageText}
    </span>
  );
};

export default Status;
