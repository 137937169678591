import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import useMonthHeaders from '../../hooks/useMonthHeaders';
import {useQueryParams, StringParam} from 'use-query-params';
import {
  useCollectedFormReportsQuery,
  useCollectedFormSumReportQuery,
} from 'api';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const headers: ReportTableHeader[] = [...useMonthHeaders(query.month)];
  const {data: {collectedFormSumReport = null} = {}} =
    useCollectedFormSumReportQuery({
      variables: {month: query.month},
      fetchPolicy: 'cache-and-network',
    });
  const {data: {collectedFormReports = []} = {}} = useCollectedFormReportsQuery(
    {
      variables: {month: query.month},
      fetchPolicy: 'cache-and-network',
    },
  );
  const sum = (values: number[]) =>
    values.filter(Boolean).reduce((acc, cur) => acc + cur, null);

  const data: ReportTableData[] = [
    collectedFormSumReport,
    ...collectedFormReports,
  ]
    .filter(Boolean)
    .map((report) => {
      return {
        label: report.name,
        values: [sum(report.values), ...report.values],
      };
    });

  return {
    data,
    headers,
  };
};

export default useTableData;
