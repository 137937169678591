import gql from 'graphql-tag';

export default gql`
  fragment mailFormSendActivityFragment on MailFormSendActivity {
    body
    campaign {
      name
    }
    contentMailFormVersion {
      id
      uuid
      version
      mailForm {
        content {
          uuid
          title
        }
      }
    }
  }
`;
