import gql from 'graphql-tag';
import gmailThreadFragment from '../gmail/thread';
import gmailMessageFragment from '../gmail/message';

export default gql`
  fragment gmailImportMailThreadFragment on GmailImportMailThread {
    id
    members
    updatedAt
    createdAt
    gmailThread {
      ...gmailThreadFragment
    }
    gmailMessage {
      ...gmailMessageFragment
    }
  }
  ${gmailThreadFragment}
  ${gmailMessageFragment}
`;
