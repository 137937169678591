import gql from 'graphql-tag';

export default gql`
  fragment websiteFragment on Website {
    id
    websiteType
    name
    pageType
    pageTypeText
    fields
    position
    uniqueCompanyCount
    createdAt
    updatedAt
  }
`;
