import gql from 'graphql-tag';

export default gql`
  fragment websitePageCountFragment on Website {
    draftCount
    progressCount
    doneCount
    nothingCount
    draftCrawlerPageCount
    progressCrawlerPageCount
    doneCrawlerPageCount
    nothingCrawlerPageCount
  }
`;
