import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import {useCurrentClientQuery} from 'api';

const TelStatusFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {data: {currentClient: {prospectTelStatuses = []} = {}} = {}} =
    useCurrentClientQuery();

  return (
    <>
      <Header title="最新TELステータス" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={[
              {text: '未選択', value: 'none'},
              ...prospectTelStatuses.map((prospectTelStatus) => ({
                text: prospectTelStatus.name,
                value: prospectTelStatus.id,
                keyword: prospectTelStatus.name,
              })),
            ]}
            values={query.telStatuses || []}
            onChange={(telStatuses) => setQuery({...query, telStatuses})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, telStatuses: []})} />
    </>
  );
};

export default TelStatusFilter;
