import React from 'react';
import {useUsersByClientQuery, useCurrentClientQuery} from 'api';

export default () => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();
  const {data: {usersByClient: {pagination = {}} = {}} = {}} =
    useUsersByClientQuery({
      variables: {
        search: {
          page: 1,
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  return (
    <>
      {currentClient?.currentPreleadContract?.userLimit && (
        <div className="text-base">
          ユーザー数：{pagination.totalCount}/
          {currentClient?.currentPreleadContract?.userLimit}
        </div>
      )}
    </>
  );
};
