import React, {useRef, useMemo, useState} from 'react';
import {useSalesforceCampaignsQuery, SalesforceCampaign} from 'api';
import useSearchParams from '../../../../useSearchParams';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';

const CampaignSelect = () => {
  const {query, setQuery} = useSearchParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [campaignName, setCampaignName] = useState('');

  const {data: {salesforceCampaigns = []} = {}} = useSalesforceCampaignsQuery(
    {},
  );

  const selectedCampaign = useMemo(() => {
    if (!query.salesforceCampaignId) {
      return null;
    }
    return salesforceCampaigns.find(
      (campaign) => campaign.id === query.salesforceCampaignId,
    );
  }, [query.salesforceCampaignId]);

  const filteredCampaigns = () => {
    if (campaignName === '') return salesforceCampaigns;
    return salesforceCampaigns?.filter((campaign) => {
      return `${campaign?.name}`
        ?.toLowerCase()
        ?.includes(campaignName?.toLowerCase());
    });
  };

  return (
    <>
      <div className="text-c-base font-bold">キャンペーン</div>
      <Combobox
        value={selectedCampaign}
        onChange={(campaign) => {
          setQuery({
            ...query,
            salesforceCampaignId: campaign.id,
            salesforceCampaignSearchConditions: [],
          });
        }}>
        <Combobox.Button as={React.Fragment}>
          <div
            className="relative mt-1 h-[2.5rem]"
            onClick={() => inputRef.current?.focus()}>
            <Combobox.Input
              onChange={(e) => setCampaignName(e.target.value)}
              onFocus={() => buttonRef.current?.click()}
              onClick={() => setCampaignName('')}
              value={campaignName}
              displayValue={(campaign: SalesforceCampaign) =>
                campaign && `${campaign.name}`
              }
              ref={inputRef}
              className="relative w-full cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap"
            />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
            <Transition
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                <Combobox.Option
                  className={({active}) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? 'bg-c-bg' : 'white'
                    }`
                  }
                  value={(): any => {
                    setQuery({...query, salesforceCampaignId: ''});
                  }}>
                  <div className="flex items-center gap-2">
                    <span className="truncate flex-1 h-5" />
                  </div>
                </Combobox.Option>
                {filteredCampaigns().map((campaign) => (
                  <Combobox.Option
                    key={campaign.id}
                    className={({active}) =>
                      `relative cursor-default select-none py-2 px-4 ${
                        active ? 'bg-c-bg' : 'white'
                      }`
                    }
                    value={campaign}>
                    <div className="flex items-center gap-2">
                      <span className="truncate flex-1">{campaign.name}</span>
                    </div>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox.Button>
      </Combobox>
    </>
  );
};

export default CampaignSelect;
