import React from 'react';
import {Row} from 'react-table';
import {PreleadProject} from 'api';

interface Props {
  row: Row<PreleadProject>;
  value: any;
}

const CustomizeItemNumberCell = ({row, value}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const clientPreleadCustomizeItemId = value;

  const customizeItem = React.useMemo(
    () =>
      preleadProject.prelead.customizeItemNumbers.find(
        (item) =>
          item.clientPreleadCustomizeItemId === clientPreleadCustomizeItemId,
      ),
    [preleadProject, clientPreleadCustomizeItemId],
  );

  return customizeItem?.value?.toLocaleString() || null;
};

export default CustomizeItemNumberCell;
