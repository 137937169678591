import React from 'react';
import useGlobalMenu from 'hooks/useGlobalMenu';
import usePools from 'hooks/usePools';
import {useHistory, Link} from 'react-router-dom';
import useClientUser from 'hooks/useClientUser';
import {useCurrentClientQuery, useCurrentUserQuery} from 'api';

export default () => {
  const {menuCategory} = useGlobalMenu();
  const {defaultPool} = usePools();
  const history = useHistory();
  const {isAdmin, isMember} = useClientUser();
  const {canListTask, canListApproach} = useClientUser();

  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  const {
    data: {
      currentClient: {
        currentPreleadContract = {},
        currentMaintenanceContract = {},
        domain,
      } = {},
    } = {},
  } = useCurrentClientQuery();

  const isUserRoleCanListPreleadMaster =
    currentPreleadContract?.userRoleCanListPreleadMaster === 'member'
      ? isMember
      : isAdmin;

  const active =
    'font-bold text-[#495058] bg-[#FAFBFB] border-b-4 border-[#8966EF]';
  const inActive =
    'group-hover:text-[#495058] group-hover:bg-[#FAFBFB] group-hover:border-b-4 group-hover:border-[#8966EF] text-[#BDC4CB]';

  return (
    <div className="flex leading-[60px] h-16 relative">
      <div className="group">
        <div
          className={`w-[108px] text-center font-bold ${
            menuCategory === 'action' ? active : inActive
          }`}
          onClick={() => {
            history.push(`/tasks`);
          }}>
          アクション
        </div>
        <div
          className={`invisible group-hover:visible flex flex-col bg-white drop-shadow-lg z-30 w-48 absolute top-16`}>
          {canListTask && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to={`/tasks`}>
              アクション - ToDo
            </Link>
          )}
          {canListApproach && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to="/approaches">
              アクション - アプローチ
            </Link>
          )}
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/pools/${defaultPool?.uuid}/action/actions`}>
            アクション - ワークフロー
          </Link>
          <div className="border-t-2 border-[#E1E6EB] pb-2 mt-2 mx-5"></div>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/pools/${defaultPool?.uuid}/workflows`}>
            ワークフロー設定
          </Link>
        </div>
      </div>
      <div className="group">
        <div
          className={`w-[108px] text-center font-bold ${
            menuCategory === 'prospect' ? active : inActive
          }`}
          onClick={() => {
            history.push(`/pools/${defaultPool?.uuid}/prospects`);
          }}>
          リード
        </div>
        <div
          className={`invisible group-hover:visible group-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-30 w-32 absolute top-16`}>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/pools/${defaultPool?.uuid}/prospects`}>
            リード
          </Link>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/pools/${defaultPool?.uuid}/accounts`}>
            リード企業
          </Link>
        </div>
      </div>
      <div className="group">
        <div
          className={`w-[108px] text-center font-bold ${
            menuCategory === 'project' ? active : inActive
          }`}
          onClick={() => history.push('/list/projects?status=open')}>
          企業
        </div>
        <div
          className={`invisible group-hover:visible group-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-30 w-40 absolute top-16`}>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={'/list/projects?status=open'}>
            営業リスト
          </Link>
          {isUserRoleCanListPreleadMaster && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to={`/list/preleads`}>
              企業 - マスター
            </Link>
          )}
          {isUserRoleCanListPreleadMaster && currentUser?.role === 'admin' && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to={`/list/mail_campaign_sends`}>
              企業 - メール配信実績
            </Link>
          )}
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/list/groups`}>
            グループ
          </Link>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/report/call/all/call`}>
            レポート
          </Link>
          <div className="border-t-2 border-[#E1E6EB] pb-2 mt-2 mx-5"></div>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/pools/${defaultPool?.uuid}/account_groups`}>
            リスト
          </Link>
          {domain !== 'zeal-c' && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to={`/block/block_groups`}>
              ブロック
            </Link>
          )}
        </div>
      </div>
      <div className="group">
        <div
          className={`w-[108px] text-center font-bold ${
            menuCategory === 'campaign' ? active : inActive
          }`}
          onClick={() => {
            history.push(`/mail_campaigns`);
          }}>
          メール
        </div>
        <div
          className={`invisible group-hover:visible group-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-30 w-60 absolute top-16`}>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={'/mail_campaigns'}>
            メール配信-リード
          </Link>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/mail_campaigns?target=preleads`}>
            メール配信-営業リスト
          </Link>
          <div className="border-t-2 border-[#E1E6EB] pb-2 mt-2 mx-5"></div>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/garage/contents`}>
            コンテンツ
          </Link>
        </div>
      </div>
      <div className="group">
        <div
          className={`w-[108px] text-center font-bold ${
            menuCategory === 'form' ? active : inActive
          }`}
          onClick={() => {
            history.push(`/form/forms`);
          }}>
          フォーム
        </div>
        <div
          className={`invisible group-hover:visible group-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-30 w-40 absolute top-16`}>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/form/forms`}>
            フォーム
          </Link>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/form/collected_form_urls`}>
            タグ連携フォーム
          </Link>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/form/tracking_code`}>
            トラッキングコード
          </Link>
        </div>
      </div>
      <div className="group">
        <div
          className={`w-[108px] text-center font-bold ${
            menuCategory === 'reports' ? active : inActive
          }`}
          onClick={() => {
            history.push(`/reports`);
          }}>
          レポート
        </div>
      </div>
      <div className="group">
        <div
          className={`w-[108px] text-center font-bold ${
            menuCategory === 'setting' ? active : inActive
          }`}
          onClick={() => {
            history.push(`/settings/users?active=0`);
          }}>
          設定
        </div>
        <div
          className={`invisible group-hover:visible group-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-30 w-60 absolute top-16`}>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={'/settings/users?active=0'}>
            ユーザー管理
          </Link>
          {isAdmin && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to={`/settings/status/prelead_status`}>
              ステータス管理
            </Link>
          )}
          {isAdmin && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to={'/settings/externals'}>
              外部連携
            </Link>
          )}
          {isAdmin && (
            <Link
              className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
              to={`/settings/share`}>
              Share設定
            </Link>
          )}
          {(currentUser?.role === 'admin' ||
            (isAdmin &&
              currentMaintenanceContract?.canMaintenanceProspects)) && (
            <>
              <div className="border-t-2 border-[#E1E6EB] pb-2 mt-2 mx-5"></div>
              <div className="px-5 h-12 align-middle text-[#222426] flex items-center">
                メンテナンス
              </div>
              <Link
                className="px-10 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
                to={`/maintenance?onlyApplicable=1`}>
                重複リード
              </Link>
            </>
          )}
          <div className="border-t-2 border-[#E1E6EB] pb-2 mt-2 mx-5"></div>
          <Link
            className="px-5 hover:bg-[#F9F6FF] h-12 align-middle text-[#222426] hover:text-[#8966EF] hover:font-bold flex items-center"
            to={`/downloads`}>
            ダウンロード履歴
          </Link>
        </div>
      </div>
    </div>
  );
};
