import gql from 'graphql-tag';

export default gql`
  fragment formFragment on Form {
    id
    uuid
    title
    status
    statusText
    layoutType
    formTitle
    formLogoFile
    formLogoFileIdentifier
    formLogoScale
    formLogoWidth
    formFaviconFile
    formFontSize
    formTextColor
    formInputBorderColor
    leadSourceId
    sendAutoreplyMail
    autoreplyMailBody
    autoreplyMailBodyHtml
    autoreplyMailFromAddress
    autoreplyMailFromName
    autoreplyMailSubject
    postCount
    iframeHeight
    redirectUrl
    enableRedirectUrlTracking
    googleTagManagerId
    googleAnalyticsV4MeasurementId
    createdAt
    updatedAt
  }
`;
