import React from 'react';
import Assignee from './Assignee';
import Contact from './Contact';
import Integration from './Integration';
import PressRelease from './PressRelease';
import {Item} from './Styles';
import CustomizeItems from './CustomizeItems';
import Reminder from './Reminder';
import usePreleadContext from '../usePreleadContext';
import Tag from './Tag';

export default () => {
  const {preleadId} = usePreleadContext();

  if (!preleadId) return null;

  return (
    <div className="flex flex-col">
      <Item>
        <div>
          <span>担当者</span>
        </div>
        <div>
          <Assignee />
        </div>
      </Item>
      <Item>
        <div>
          <span>プレスリリース</span>
        </div>
        <div>
          <PressRelease />
        </div>
      </Item>
      <Item>
        <div>
          <span>API</span>
        </div>
        <div>
          <Integration />
        </div>
      </Item>
      <Item>
        <div>
          <span>タグ</span>
        </div>
        <div>
          <Tag />
        </div>
      </Item>
      <Item>
        <div>
          <span>連絡先</span>
        </div>
        <div>
          <Contact />
        </div>
      </Item>
      <Item>
        <div>
          <span>最新リマインド</span>
        </div>
        <div>
          <Reminder />
        </div>
      </Item>
      <CustomizeItems />
    </div>
  );
};
