import React from 'react';

function Icon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66732 4.99984H11.334H4.66732ZM4.66732 8.33317H11.334H4.66732ZM4.66732 11.6665H7.16732H4.66732ZM3.00065 18.3332H13.0006C13.9211 18.3332 14.6673 17.587 14.6673 16.6665V3.33317C14.6673 2.4127 13.9211 1.6665 13.0006 1.6665H3.00065C2.08018 1.6665 1.33398 2.4127 1.33398 3.33317V16.6665C1.33398 17.587 2.08018 18.3332 3.00065 18.3332Z"
        stroke="#899098"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
