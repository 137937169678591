import React from 'react';
import {Activity, MailCampaignMailSendActivity} from 'api';
import moment from 'moment';
import {Mail as MailIcon} from './Icon';
import {Link} from 'react-router-dom';

interface Props {
  activity: Activity;
}

const MailCampaignMailSendActivityComponent = ({activity}: Props) => {
  const {mailCampaign = {}} = activity.resource as MailCampaignMailSendActivity;

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="w-6 h-6 bg-[#F3F5F7] rounded-full flex justify-center items-center min-w-[24px]">
        <MailIcon />
      </div>
      <div></div>
      <div className="flex flex-col gap-1">
        <div className="text-xs">
          {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
        </div>
        <div className="text-xs">
          {activity.project ? activity.project.name : '(削除済み)'}
        </div>
        <div className="text-xs">
          <Link
            to={`/mail_campaigns/${mailCampaign.uuid}/mail`}
            target="_blank">
            {mailCampaign.title}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MailCampaignMailSendActivityComponent;
