import gql from 'graphql-tag';

export default gql`
  fragment salesforceTaskEmailProspectActivityFragment on SalesforceTaskEmailProspectActivity {
    id
    activityDate
    description
    salesforceTaskIdString
    subject
    taskSubtype
  }
`;
