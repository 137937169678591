import React from 'react';
import {MailThread} from 'api';
import GmailImport from './GmailImport';
import ManualMail from './ManualMail';
import ManualMailAction from './ManualMailAction';
import AutomaticMailSend from './AutomaticMailSend';

const mailThreadComponents: {[key: string]: any} = {
  GmailImportMailThread: GmailImport,
  ManualMailMailThread: ManualMail,
  ManualMailActionMailThread: ManualMailAction,
  AutomaticMailSendMailThread: AutomaticMailSend,
};

interface Props {
  mailThread: MailThread;
}

export default ({mailThread}: Props) => {
  const MailThreadComponent =
    mailThreadComponents[mailThread.threadable.__typename];

  return <MailThreadComponent mailThread={mailThread} />;
};
