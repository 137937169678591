import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.95 6.27624V4.96401C17.95 4.01437 17.1714 3.23739 16.2199 3.23739H8.43439L6.96381 1.03595C6.79945 0.794224 6.53128 0.647461 6.24581 0.647461H2.37903C1.42747 0.647461 0.648926 1.42444 0.648926 2.37408V14.4604C0.648926 15.4101 1.42747 16.187 2.37903 16.187H8.27868"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8618 17.3525C17.3366 17.3525 19.3428 15.3503 19.3428 12.8805C19.3428 10.4107 17.3366 8.40857 14.8618 8.40857C12.3871 8.40857 10.3809 10.4107 10.3809 12.8805C10.3809 15.3503 12.3871 17.3525 14.8618 17.3525Z"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7217 16.0058L18.002 9.73816"
        stroke="#BDC4CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
