import React from 'react';

const Icon = () => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 4.38617L8.70947 3.08666L10.0001 2.0083V4.38617Z"
      fill="#BDC4CB"
    />
    <path
      d="M1.29219 3.08666L0 4.38768V2.0083L1.29219 3.08666Z"
      fill="#BDC4CB"
    />
    <path
      d="M10 5.56778V6.76428C10 7.05358 9.75781 7.28836 9.45938 7.28836H0.540625C0.242168 7.28836 0 7.0536 0 6.76428V5.56778L1.93279 3.62305L4.14062 5.46627C4.37031 5.65714 4.67498 5.76316 5 5.76316C5.32502 5.76316 5.63125 5.65714 5.86094 5.46627L8.06717 3.62305L10 5.56778Z"
      fill="#BDC4CB"
    />
    <path
      d="M10 0.522529V1.04657L5.28434 4.98447C5.13277 5.11169 4.86717 5.11169 4.71561 4.98447L0 1.04657V0.522529C0 0.233264 0.242148 0 0.540625 0H9.45938C9.75781 0 10 0.233264 10 0.522529Z"
      fill="#BDC4CB"
    />
  </svg>
);

export default Icon;
