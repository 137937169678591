import React from 'react';

const Icon = ({color = '#BDC4CB'}: {color?: string}) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 10.5H10.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.24994 6.75012L7.24994 1.75011C7.66414 1.3359 8.33574 1.3359 8.74994 1.75011C9.16414 2.16433 9.16414 2.8359 8.74994 3.25011L3.74994 8.25012L1.74994 8.75012L2.24994 6.75012Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
