import gql from 'graphql-tag';

export default gql`
  fragment telStatusFragment on TelStatus {
    id
    clientId
    name
    category
    position
    isImmutable
    description
  }
`;
