import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';

const EstablishedDateFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {establishedDate} = query;
  const {RangePicker} = DatePicker;
  const format = 'YYYY-MM';

  return (
    <RangePicker
      format={format}
      placeholder={['1990-01', '2021-12']}
      picker="month"
      value={
        establishedDate?.from && establishedDate?.to
          ? [
              moment(establishedDate.from, format),
              moment(establishedDate.to, format),
            ]
          : null
      }
      onChange={(value) => {
        if (value) {
          setQuery({
            establishedDate: {
              from: value[0].format(format),
              to: value[1].format(format),
            },
          });
        } else {
          setQuery({
            establishedDate: null,
          });
        }
      }}
    />
  );
};

export default EstablishedDateFilter;
