import gql from 'graphql-tag';
import ClientDownloadProspectFragment from './downloadProspect';
import ClientDownloadProjectFragment from './downloadProject';
import ClientDownloadMailCampaignFragment from './downloadMailCampaign';
import ClientDownloadMailCampaignProspectResultFragment from './downloadMailCampaignProspectResult';

export default gql`
  fragment clientDownloadFragment on ClientDownload {
    id
    uuid
    status
    statusText
    createdAt
    downloadableId
    downloadableType
    downloadable {
      ... on ClientDownloadProspect {
        ...clientDownloadProspectFragment
      }
      ... on ClientDownloadProject {
        ...clientDownloadProjectFragment
      }
      ... on ClientDownloadMailCampaign {
        ...clientDownloadMailCampaignFragment
      }
      ... on ClientDownloadMailCampaignProspectResult {
        ...clientDownloadMailCampaignProspectResultFragment
      }
    }
  }
  ${ClientDownloadProspectFragment}
  ${ClientDownloadProjectFragment}
  ${ClientDownloadMailCampaignFragment}
  ${ClientDownloadMailCampaignProspectResultFragment}
`;
