import React from 'react';
import {Transition} from '@headlessui/react';
import Header from '../Header';
import useMergeModalContext from '../useMergeModalContext';
import useProspectContext from '../../../useProspectContext';
import Table from './Table';
import {useMergeProspectPoolMutation} from 'api';

const Confirm = () => {
  const {pageType, setPageType, mergeFrom, mergeTo, onClose} =
    useMergeModalContext();
  const {onMove} = useProspectContext();

  const [completed, setCompleted] = React.useState(false);

  const [merge, {loading}] = useMergeProspectPoolMutation({
    variables: {
      fromUuid: mergeFrom,
      toUuid: mergeTo,
    },
    onCompleted: () => {
      setCompleted(true);
    },
    refetchQueries: ['prospectPoolsStageCount'],
  });

  const show = pageType === 'confirm' || false;

  return (
    <Transition
      show={show}
      enter="transition-all duration-150"
      enterFrom="left-full"
      enterTo="left-0"
      leave="transition-all duration-150"
      leaveFrom="left-0"
      leaveTo="left-full"
      className="w-full h-full flex flex-col absolute top-0 bg-c-bg"
      as="div">
      {completed ? (
        <>
          <Header title="リード個別名寄せ完了" back={false} />
          <div className="p-6 bg-c-bg rounded-b h-full flex-1 flex flex-col gap-8 justify-center items-center">
            <h3>名寄せが完了しました</h3>
            {onMove ? (
              <button
                onClick={() => {
                  if (onMove) onMove(mergeTo);
                  onClose();
                }}
                className="cursor-pointer px-6 h-10 flex items-center justify-center rounded bg-c-primary border border-c-primary text-white font-bold">
                名寄せ先のリードを表示する
              </button>
            ) : (
              <button
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer px-6 h-10 flex items-center justify-center rounded bg-c-primary border border-c-primary text-white font-bold">
                閉じる
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          <Header title="リードの個別名寄せ内容確認" />
          <div className="p-6 bg-c-bg rounded-b h-full overflow-y-scroll flex-1 flex flex-col gap-4">
            <div className="bg-white flex-1 rounded flex flex-col gap-4 p-6">
              <ul className="text-sm list-disc list-inside">
                <li>名寄せ元のリード情報は削除されます</li>
                <li>名寄せ元のSalesforceデータも削除されます</li>
              </ul>
              <Table />
            </div>
          </div>
          <div className="flex items-center justify-between bg-c-bg rounded-b px-6 py-4">
            <button
              className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold"
              disabled={loading}
              onClick={() => setPageType(null)}>
              戻る
            </button>
            <button
              className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-c-primary border border-c-primary text-white font-bold disabled:opacity-50"
              disabled={loading}
              onClick={() =>
                confirm('名寄せ処理を実行してよろしいですか?') && merge()
              }>
              名寄せ処理実行
            </button>
          </div>
        </>
      )}
    </Transition>
  );
};

export default Confirm;
