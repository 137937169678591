import {ReportChart} from '../../../components/Chart';
import {StringParam, useQueryParams} from 'use-query-params';
import useWeekAxis from '../../../hooks/useWeekAxis';
import {useProspectReportProspectPoolUserWeeklyCallReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {data: {prospectReportProspectPoolUserWeeklyCallReports = []} = {}} =
    useProspectReportProspectPoolUserWeeklyCallReportsQuery({
      variables: {month: query.month},
      skip: !query.month,
    });
  const axis = useWeekAxis(query.month);

  const data = () => {
    return prospectReportProspectPoolUserWeeklyCallReports.map((report) => {
      return {
        label: report.name,
        color: report.color,
        groupId: 'main',
        values: report.values,
      };
    });
  };

  return {
    axis,
    data: data(),
  };
};

export default useChartData;
