import React from 'react';
import {Activity, TelStatusChangeActivity} from 'api';
import telCategoryColor from 'helpers/telCategoryColor';
import styled from 'styled-components';
import moment from 'moment';
import {Tel as TelIcon} from './Icon';

interface Props {
  activity: Activity;
}

const TelStatusAndCommentActivityComponent = ({activity}: Props) => {
  const {
    clientTelStatus = {},
    callNgReason,
    receptionNgReason,
    contactNgReason,
  } = activity.resource as TelStatusChangeActivity;

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="w-6 h-6 bg-[#F3F5F7] rounded-full flex justify-center items-center">
        <TelIcon />
      </div>
      <div></div>
      <div className="flex flex-col gap-1">
        <div className="text-xs">
          {moment(activity.createdAt).format('YYYY/MM/DD HH:mm')}
        </div>
        <div className="text-xs">
          {activity.project ? activity.project.name : '(削除済み)'}
        </div>
        <div className="text-sm">
          {activity.user?.lastName} {activity.user?.firstName}
        </div>
        {clientTelStatus && (
          <TelStatusName
            background={telCategoryColor(clientTelStatus?.category)}
            className={`rounded-[20px] text-white font-bold justify-center text-[10px] truncate p-1 w-36 h-5 flex items-center`}>
            <span>{clientTelStatus.name}</span>
            {(callNgReason || receptionNgReason || contactNgReason) && (
              <span>|</span>
            )}
            {callNgReason && <span>{callNgReason.reason}</span>}
            {receptionNgReason && <span>{receptionNgReason.reason}</span>}
            {contactNgReason && <span> {contactNgReason.reason}</span>}
          </TelStatusName>
        )}
      </div>
    </div>
  );
};

export default TelStatusAndCommentActivityComponent;

const TelStatusName = styled.div<{background: string}>`
  background: ${(props) => props.background};
`;
