import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import Total from './total';
import LeadSource from './leadSource';
// import useSearchParams from '../hooks/useSearchParams';

export default () => {
  // const {dateFrom, dateTo} = useSearchParams();

  return (
    <Layout
      title="メンテナンス履歴"
      tabs={[
        {
          label: '合計',
          to: '/reports/maintenance/total',
        },
        {
          label: '最新リードソース別',
          to: '/reports/maintenance/lead_source',
        },
      ]}
      search={['timeScale']}>
      <Switch>
        <Route path="/reports/maintenance/total" component={Total} />
        <Route path="/reports/maintenance/lead_source" component={LeadSource} />
        <Redirect to="/reports/maintenance/total" />
      </Switch>
    </Layout>
  );
};
