import gql from 'graphql-tag';

export default gql`
  fragment blockGroupTagFragment on BlockGroupTag {
    id
    clientId
    uuid
    name
    color
  }
`;
