import React from 'react';
import useFilterModalContext from '../useFilterModalContext';
import useSearchParams from '../../../useSearchParams';

const Footer = () => {
  const {setQuery} = useSearchParams();
  const {onClose} = useFilterModalContext();

  const clearQuery = () => {
    setQuery({}, 'replace');
  };

  return (
    <div className="flex items-center justify-end px-6 py-4 gap-4 bg-c-bg rounded-b">
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded border-none bg-transparent text-c-primary font-bold"
        onClick={clearQuery}>
        全ての条件をクリア
      </button>
      <button
        className="cursor-pointer w-32 h-10 flex items-center justify-center rounded bg-white border border-c-border text-light font-bold"
        onClick={() => onClose()}>
        閉じる
      </button>
    </div>
  );
};

export default Footer;
