import gql from 'graphql-tag';

export default gql`
  fragment poolHubspotSettingFragment on PoolHubspotSetting {
    id
    hubspotAccountId
    pipeline
    stage
    isActive
  }
`;
