import React from 'react';
import {usePoolQuery} from 'api';
import {getTagFontColor} from 'helpers/tagColors';
import useCondition from '../../useCondition';

export default () => {
  const {condition} = useCondition();
  const {data: {pool: {prospectTags = []} = {}} = {}} = usePoolQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (!condition?.excludedTags || !condition.excludedTags.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">タグ（含まない）</h4>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        {prospectTags
          .filter((tag) => condition.excludedTags.includes(tag.id))
          .map((tag) => (
            <span
              color={tag.color}
              key={tag.id}
              className="flex px-[7px] py-[3px] rounded-sm"
              style={{background: tag.color}}>
              <span
                className="text-sm font-bold leading-4 tracking-[0.15px]"
                style={{color: getTagFontColor(tag.color)}}>
                {tag.name}
              </span>
            </span>
          ))}
      </div>
    </div>
  );
};
