import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {ProspectActivity, Task, TaskProspectActivity} from 'api';
import {
  TaskTel,
  TaskMail,
  TaskOther,
  UserMaru,
  Calendar,
  DotMenuSide,
  Edit,
} from 'components/Ui/Icon';
import {Menu, Dropdown} from 'components/antd';
import TaskStatusIcon from 'components/Task/Icon/TaskStatusIcon';
import {taskPriorities} from 'helpers/task';

interface SalesforceTaskTodoActivity extends ProspectActivity {
  resource: TaskProspectActivity;
}

interface Props {
  prospectActivity: SalesforceTaskTodoActivity;
  setIsTaskEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setTask: React.Dispatch<React.SetStateAction<Task>>;
}

export default ({prospectActivity, setIsTaskEdit, setTask}: Props) => {
  const {resource, user} = prospectActivity;
  const task = resource.task;

  const actionIcon = () => {
    switch (task?.taskType) {
      case 'tel':
        return <TaskTel />;
      case 'mail':
        return <TaskMail />;
      case 'other':
        return <TaskOther />;
    }
  };

  return (
    <>
      {actionIcon()}
      <Content>
        <ContentHeader>
          <h3
            onClick={() => {
              setIsTaskEdit(true);
              setTask(task);
            }}>
            {task?.title}
          </h3>
          <span>
            {user?.lastName} {user?.firstName}
          </span>
          <time>
            {moment(prospectActivity.createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
          <MenuContainer>
            <Dropdown
              overlay={
                <Menu>
                  <MenuItem
                    key="0"
                    onClick={() => {
                      setIsTaskEdit(true);
                      setTask(task);
                    }}>
                    <Edit />
                    編集
                  </MenuItem>
                  {/*<MenuItem key="1">*/}
                  {/*  <Trash />*/}
                  {/*  削除*/}
                  {/*</MenuItem>*/}
                </Menu>
              }
              trigger={['click']}>
              <span>
                <DotMenuSide />
              </span>
            </Dropdown>
          </MenuContainer>
        </ContentHeader>
        <ContentBox>
          <TaskDescription>
            {task.descriptionHtml ? (
              <div dangerouslySetInnerHTML={{__html: task.descriptionHtml}} />
            ) : (
              <p>{task.description}</p>
            )}
          </TaskDescription>
          <ContentMeta>
            <Status>
              <TaskStatusIcon status={task.status} /> {task.statusText}
            </Status>
            <Priority
              color={
                taskPriorities.find(
                  (priority) => priority.value === task.priority,
                ).color
              }>
              優先度: {task.priorityText}
            </Priority>
            {task.user && (
              <span>
                <UserMaru />
                <span>
                  {task.user.lastName} {task.user?.firstName}
                </span>
              </span>
            )}
            {task.dueDate && (
              <Due>
                <Calendar />
                <span>{moment(task.dueDate).format('YYYY/MM/DD')}</span>
              </Due>
            )}
          </ContentMeta>
        </ContentBox>
      </Content>
    </>
  );
};

const TaskDescription = styled.div`
  p {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 455px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;

  h3 {
    flex: 1;
    margin: 0;
    color: #68b5fb;
    font-weight: bold;
    cursor: pointer;
  }

  span,
  time {
    color: #899098;
    margin-left: 15px;
  }
`;

const ContentBox = styled.div`
  border: solid 1px #e2e6ea;
  border-radius: 4px;
  padding: 15px;
`;

const ContentMeta = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 15px;
    max-width: 100px;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      height: 18px;
      width: auto;
      margin-right: 4px;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
  }
`;

const Status = styled.span`
  font-size: 10px;
  color: #899098;
  height: 20px;
  border: 1px solid #e1e6eb;
  border-radius: 10px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const Priority = styled.span<{color?: string}>`
  font-size: 10px;
  height: 20px;
  border-radius: 10px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: ${({color}) => color};
`;

const Due = styled.span`
  svg {
    position: relative;
    top: -2px;
  }
`;

const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }
    svg {
      width: 15px;
      heighht: auto;
      margin-right: 0.5rem;
    }
    &:hover {
      color: #68b5fb;
      svg {
        path {
          stroke: #68b5fb;
        }
      }
    }
  }
`;
