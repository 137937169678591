import React from 'react';
import styled from 'styled-components';
import {Button} from 'components/antd';

export default () => {
  return (
    <Container>
      <Title>リードプール一覧</Title>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 10px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.h1`
  margin-top: 1rem;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
`;
