import gql from 'graphql-tag';

export default gql`
  fragment templateFragment on Template {
    id
    uuid
    clientId
    teamId
    name
    description
    city
    street
    building
    companyName
    companyKana
    email
    firstName
    lastName
    firstNameKana
    lastNameKana
    firstNameKana2
    lastNameKana2
    message1
    message2
    message3
    messageMail
    section
    sectionPosition
    state
    tel1
    tel2
    tel3
    title
    url
    zipcode1
    zipcode2
    defaultText
    defaultTextArea
    companyHiragana
    zipcodeFull
    addressFull
    emailAccount
    emailDomain
    isConvertUrl
  }
`;
