import React from 'react';
import styled from 'styled-components';
import {useQueryParam, NumberParam} from 'use-query-params';
import {Button} from 'components';
import {Company, Pagination as PaginationType} from 'api';
import {Close} from 'components/Icon';
import Modal from 'react-modal';
import {useTable} from 'react-table';
import {Pagination} from 'components/antd';

interface Props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  companies: Company[];
  pagination: PaginationType;
}

export default (props: Props) => {
  const {open, setOpen, companies, pagination} = props;
  const [, setPage] = useQueryParam('page', NumberParam);

  const data = React.useMemo(
    () =>
      companies.map((company) => {
        return {
          key: company.id,
          name: company.name,
          address: company.address,
          telephoneNumber: company.telephoneNumber,
          webSite: company.webSite,
        };
      }),
    [companies],
  );

  const columns: any = React.useMemo(
    () => [
      {Header: '会社名', accessor: 'name'},
      {Header: '住所', accessor: 'address'},
      {Header: '電話番号', accessor: 'telephoneNumber'},
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  const customStyles = {
    overlay: {
      background: 'rgba(13, 24, 59, 0.6)',
      zIndex: 10,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '960px',
      height: 'calc(100% - 100px)',
      padding: '0',
      borderRadius: '8px',
    },
  };

  return (
    <Modal isOpen={open} style={customStyles}>
      <ModalContent>
        <ModalHeader>
          <p>リストプレビュー</p>
          <Button
            buttonType="default"
            onClick={() => {
              setPage(null);
              setOpen(false);
            }}>
            <CloseIcon />
            <CloseText>閉じる</CloseText>
          </Button>
        </ModalHeader>
        <ModalBody>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, j) => (
                    <th {...column.getHeaderProps()} key={j}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, j) => {
                      return (
                        <td {...cell.getCellProps()} key={j}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationContainer>
            <UIPagination
              size="small"
              showSizeChanger={false}
              showQuickJumper={false}
              total={pagination.totalCount}
              current={pagination.currentPage}
              pageSize={50}
              onChange={(page) => {
                setPage(page);
              }}
            />
          </PaginationContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const PaginationContainer = styled.div`
  &&& {
    justify-content: center;
    position: sticky;
    bottom: 0;
    background-color: white;
    margin-bottom: 0;
    padding: 15px 0;
    border-top: 1px solid #e1e6eb;
  }
`;

const UIPagination = styled(Pagination)`
  &&& {
    li {
      margin: 0 10px;
    }
    .ant-pagination-item-active {
      border-radius: 16px;
      background-color: #e2e6ea;
      border: 0;

      a {
        color: #495058;
      }
    }
    .ant-pagination-item-link {
      color: #bdc4cb;
    }

    // 「…」にマウスオーバーした際のアイコン
    .anticon-double-right,
    .anticon-double-left {
      margin-top: 6px;
      margin-right: 10px;
    }
  }
`;

const CloseIcon = styled(Close)`
  width: 25px;
  height: 25px;
  vertical-align: middle;

  path {
    fill: #bdc4cb;
  }
`;

const CloseText = styled.span`
  color: #bdc4cb;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  padding-top: 20px;
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;

  p {
    color: #0d183b;
    font-size: 18px;
    line-height: 26px;
    padding-left: 40px;
  }

  button {
    margin-left: auto;
  }
`;

const ModalBody = styled.div`
  table {
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    padding: 8px 40px;
  }

  th {
    color: #bdc4cb;
    font-weight: normal;
  }

  th:nth-child(1) {
    width: 30%;
  }

  th:nth-child(2) {
    width: 50%;
  }

  th:nth-child(3) {
    width: 20%;
  }

  tbody tr {
    height: 55px;
  }

  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #e1e6eb;
  }

  td:nth-child(1) {
    border-right: 1px solid #e1e6eb;
  }

  td:nth-child(3) {
    border-left: 1px solid #e1e6eb;
  }

  div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;
