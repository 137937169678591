import {TelephoneNumber, useDestroyPreleadTelephoneNumberMutation} from 'api';
import { Trash } from 'components/Ui/Icon';
import React from 'react';

interface Props {
  telephoneNumber: TelephoneNumber;
}

export default ({telephoneNumber}: Props) => {
  const [destroyPreleadTelephoneNumber] =
    useDestroyPreleadTelephoneNumberMutation({
      variables: {id: telephoneNumber.id},
    });
    
  return (
    <div
      className="w-5 h-5 rounded cursor-pointer invisible group-hover:visible hover:bg-[#FAD9D9] flex items-center justify-center"
      onClick={() =>
        confirm('削除してよろしいですか？') && destroyPreleadTelephoneNumber()
      }>
      <Trash color="#EB5757" width="12" height="12" />
    </div>
  );
};
