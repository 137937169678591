import React from 'react';

export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6462 3.50065C10.2594 3.50065 9.89074 3.57532 9.55008 3.70628C9.13562 3.05732 8.41083 2.62565 7.58366 2.62565C6.96912 2.62565 6.41174 2.86511 5.99495 3.25332C5.48803 2.69011 4.75537 2.33398 3.93783 2.33398C2.40745 2.33398 1.16699 3.57444 1.16699 5.10482C1.16699 5.33582 1.19849 5.55923 1.25158 5.77361C0.668534 6.22744 0.291992 6.93386 0.291992 7.72982C0.291992 9.0989 1.40208 10.209 2.77116 10.209C2.89162 10.209 3.00916 10.1976 3.12524 10.181C3.5082 11.0557 4.38028 11.6673 5.39616 11.6673C6.31841 11.6673 7.12166 11.1624 7.54866 10.4152C7.74583 10.4694 7.95262 10.5007 8.16699 10.5007C8.93116 10.5007 9.60724 10.1317 10.0331 9.56411C10.2311 9.60436 10.4362 9.62565 10.6462 9.62565C12.3375 9.62565 13.7087 8.25453 13.7087 6.56315C13.7087 4.87178 12.3375 3.50065 10.6462 3.50065Z"
        fill="#45A0DA"
      />
    </svg>
  );
};
