import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment} from 'react';
import Header from './Header';
import {useMergeProspectQuery} from 'api';
import Prospect from './Prospect';
import Action from './Action';
import TabGroupMergeProspect from './TabGroupMergeProspect';
import {MergeProspectContext} from '../MergeProspectContext';

interface Props {
  onClose: () => void;
}

export default function Modal({onClose}: Props) {
  const {mergeProspectUuid} = React.useContext(MergeProspectContext);
  const {data: {mergeProspect = {}} = {}} = useMergeProspectQuery({
    variables: {uuid: mergeProspectUuid},
    skip: !mergeProspectUuid,
  });
  const prospectPool = mergeProspect?.prospectPool;

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="flex flex-col relative w-full max-w-3xl h-[96vh] transform rounded bg-white text-left align-middle shadow-xl transition-all overflow-hidden">
                  <Header onClose={onClose} />
                  <div className="px-6 pt-6 bg-c-bg rounded-b full overflow-y-scroll flex-1 flex flex-col gap-4">
                    {prospectPool && (
                      <div className="flex flex-col">
                        <div className="flex-1 overflow-y-scroll bg-white flex flex-col p-5">
                          <h1 className="w-full text-base font-normal">
                            名寄せ先
                          </h1>
                          <Prospect prospectPool={prospectPool} />
                        </div>
                      </div>
                    )}
                    <div className="flex-1 overflow-y-scroll bg-white p-5">
                      <h1 className="w-full text-base font-normal">名寄せ元</h1>
                      <TabGroupMergeProspect mergeProspect={mergeProspect} />
                    </div>
                  </div>
                  <Action onClose={onClose} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
