import React from 'react';
import {useUpdateCollectedFormUrlMutation, CollectedFormUrl} from 'api';
import useDebounce from 'hooks/useDebounce';

interface Props {
  collectedFormUrl: CollectedFormUrl;
}

export default ({collectedFormUrl}: Props) => {
  const [updateCollectedFormUrl, {data}] = useUpdateCollectedFormUrlMutation();

  const [value, setValue] = React.useState(collectedFormUrl.url || '');
  const debouncedValue = useDebounce<string>(value, 500);

  React.useEffect(() => {
    debouncedValue !== collectedFormUrl.url &&
      updateCollectedFormUrl({
        variables: {
          collectedFormUrlUuid: collectedFormUrl.uuid,
          attributes: {
            url: debouncedValue,
          },
        },
      });
  }, [collectedFormUrl, debouncedValue]);

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-1 w-full max-w-xl">
        <label className="text-c-base font-bold">URL</label>
        <div className="flex items-center">
          <input
            className="form-input border-c-border rounded text-base w-full"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        {!value || data?.updateCollectedFormUrl?.error ? (
          <div className="text-red-500">
            必須項目です。https://xxx.yyy.zzzの形式で入力してください。
          </div>
        ) : null}
        {data?.updateCollectedFormUrl?.error ? (
          <div className="text-red-500">
            {data?.updateCollectedFormUrl?.error}
          </div>
        ) : null}
      </div>
    </div>
  );
};
