import React from 'react';
import {usePoolQuery} from 'api';
import useCondition from '../../useCondition';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroupColor from './CheckboxGroupColor';

const TagFilter = () => {
  const {condition, setCondition} = useCondition();

  const {data: {pool: {prospectTags = []} = {}} = {}} = usePoolQuery({
    fetchPolicy: 'cache-and-network',
  });

  const options = prospectTags
    .filter((tag) => !condition.excludedTags?.includes(tag.id))
    .map((tag) => ({
      text: tag.name,
      value: tag.id,
      keyword: tag.name,
      color: tag.color,
    }));

  return (
    <>
      <Header title="タグ" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroupColor
            filter
            options={options}
            values={condition.tags || []}
            onChange={(tags) => setCondition({tags})}
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({tags: []})} />
    </>
  );
};

export default TagFilter;
