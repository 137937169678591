import React from 'react';
import {useImportAccountGroupMutation, ImportAccountGroupAttributes} from 'api';
import {useParams} from 'react-router-dom';
import CSVUploadModal from './CSVUploadModal';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const UploadModalComponent = ({visible, onClose}: Props) => {
  const {accountGroupId} = useParams<{
    accountGroupId: string;
  }>();

  const options = [
    {value: 'name', text: '会社名', required: true},
    {value: 'telephone_number', text: '電話番号'},
    {value: 'email', text: 'メールアドレス'},
    {value: 'address', text: '住所'},
    {value: 'web_site', text: 'URL'},
    {value: 'corp_number', text: '法人番号'},
  ];

  const [importAccountGroup, {loading}] = useImportAccountGroupMutation({
    refetchQueries: ['accountGroup'],
    onCompleted: onClose,
  });

  const onSubmit = React.useCallback((values: ImportAccountGroupAttributes) => {
    importAccountGroup({variables: {uuid: accountGroupId, attributes: values}});
  }, []);

  return (
    <CSVUploadModal
      visible={visible}
      onClose={onClose}
      options={options}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export default UploadModalComponent;
