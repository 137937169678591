import React from 'react';
import {useRecoilState} from 'recoil';
import checkedPreleadProjectsAtom from '../checkedPreleadProjectsAtom';
import {Trash} from 'components/Ui/Icon';
import styled from 'styled-components';
import {useDestroyPreleadProjectsMutation} from 'api';
import {useHistory, useParams} from 'react-router-dom';
import useProjectPath from 'hooks/useProjectPath';

export default () => {
  const [checkedPreleadProjects, setCheckedPreleadProjects] = useRecoilState(
    checkedPreleadProjectsAtom,
  );

  const [destroyPreleadProjects, {loading}] = useDestroyPreleadProjectsMutation(
    {
      refetchQueries: ['projects', 'preleadProjects', 'projectSummary'],
      onCompleted: () => {
        setCheckedPreleadProjects([]);
        history.push(
          createPath(`projects/${projectId}/preleads${location.search}`),
        );
      },
    },
  );

  const history = useHistory();
  const {createPath} = useProjectPath();
  const {projectId} = useParams<{projectId: string}>();

  const handleClick = () => {
    if (
      !loading &&
      checkedPreleadProjects.length > 0 &&
      confirm('削除してよろしいですか?')
    ) {
      destroyPreleadProjects({
        variables: {
          uuids: checkedPreleadProjects,
        },
      });
    }
  };

  return checkedPreleadProjects.length > 0 ? (
    <Action onClick={handleClick}>
      <Trash color="#f46868" />
      <span>削除</span>
    </Action>
  ) : null;
};

const Action = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #f46868;

  svg {
    margin-right: 5px;
    position: relative;
    top: -1px;
  }

  span {
    font-size: 12px;
  }
`;
