import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();

  if (!query?.establishedDate?.from && !query?.establishedDate?.to) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">設立年月</h4>
        <button
          onClick={() => setFilterType('establishedDate')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              establishedDate: {},
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div>
        {query.establishedDate.from && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {`${query.establishedDate.from}`}
          </span>
        )}
        <span className="text-c-light px-2">~</span>
        {query.establishedDate.to && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {`${query.establishedDate.to}`}
          </span>
        )}
      </div>
    </div>
  );
};
