import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import LeadSource from './leadSource';
import User from './user';
import LeadSourceQuota from './leadSourceQuota';
import {useCurrentUserQuery} from 'api';

type Tab = {
  label: string;
  to: string;
};

const notImplementedTabs: Tab[] = [
  {
    label: '担当者別',
    to: '/reports/deal/user',
  },
  {
    label: '業種別',
    to: '/reports/deal/category',
  },
  {
    label: '従業員数',
    to: '/reports/deal/employee',
  },
  {
    label: 'タグ別',
    to: '/reports/deal/tag',
  },
];

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  return (
    <Layout
      title="商談作成履歴"
      tabs={[
        {
          label: '最新リードソース別',
          to: '/reports/deal/lead_source',
        },
        ...(currentUser?.role === 'admin' ? notImplementedTabs : []),
      ]}
      search={['timeScale']}>
      <Switch>
        <Route path="/reports/deal/lead_source" component={LeadSource} />
        <Route path="/reports/deal/user" component={User} />
        <Route
          path="/reports/deal/lead_source_quota"
          component={LeadSourceQuota}
        />
        <Redirect to="/reports/deal/lead_source" />
      </Switch>
    </Layout>
  );
};
