import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import MARKETS from 'helpers/markets';

const ListingMarketsFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="上場区分" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            filter
            options={MARKETS.map((market) => ({
              text: market.name,
              value: market.value,
              keyword: market.name,
            }))}
            values={query.listingMarkets || []}
            onChange={(listingMarkets) => setQuery({...query, listingMarkets})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, listingMarkets: []})} />
    </>
  );
};

export default ListingMarketsFilter;
