import React from 'react';
import styled from 'styled-components';
import {regions} from 'helpers/regions';
import {usePrefectureTypesQuery} from 'api';
import Regions from './Regions';
import Prefectures from './Prefectures';
import PrefectureCities from './PrefectureCities';

export default () => {
  const [currentRegion, setCurrentRegion] = React.useState('');
  const [currentPrefectureType, setCurrentPrefectureType] = React.useState('');

  const regionConfig = regions.find(
    (region) => region.regionType === currentRegion,
  );

  const {data: {prefectureTypes = []} = {}} = usePrefectureTypesQuery({
    variables: {search: {prefectureTypeNames: regionConfig?.prefectureTypes}},
    onCompleted: ({prefectureTypes = []} = {}) =>
      prefectureTypes?.length > 0 &&
      setCurrentPrefectureType(
        prefectureTypes.find(
          (prefectureType) =>
            prefectureType.name === regionConfig?.prefectureTypes[0],
        ).name,
      ),
  });

  return (
    <Container>
      <Regions
        currentRegion={currentRegion}
        setCurrentRegion={setCurrentRegion}
      />
      <Prefectures
        prefectureTypes={prefectureTypes}
        prefectureTypeNames={regionConfig?.prefectureTypes}
        currentPrefectureType={currentPrefectureType}
      />
      <PrefectureCities
        prefectureTypes={prefectureTypes}
        prefectureTypeNames={regionConfig?.prefectureTypes}
        setCurrentPrefectureType={setCurrentPrefectureType}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 840px;
  height: 640px;
  display: flex;

  .ant-radio-group {
    display: flex;
    flex-direction: row;
  }

  .ant-radio-wrapper {
    width: 100px;
    margin: 10px 10px 20px 10px;
  }

  .ant-checkbox-wrapper {
    width: 120px;
    margin: 5px 10px;
  }
`;
