import React from 'react';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {
  HubspotDealPropertyDefinition,
  useHubspotDealPropertyDefinitionEnumCheckboxOptionsQuery,
} from 'api';

interface Props {
  definition: HubspotDealPropertyDefinition;
  values: string[];
  onChange: (value: string[]) => void;
}

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

export default ({definition, values, onChange}: Props) => {
  const [keyword, setKeyword] = React.useState('');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const {data: {hubspotDealPropertyDefinitionEnumCheckboxOptions = []} = {}} =
    useHubspotDealPropertyDefinitionEnumCheckboxOptionsQuery({
      variables: {
        hubpostDealPropertyDefinitionId: definition?.id,
      },
      skip: !definition?.id,
    });

  const filteredOptions =
    hubspotDealPropertyDefinitionEnumCheckboxOptions
      ?.filter((option) => !values?.includes(option.id))
      ?.filter((option) =>
        kanaToHira(option.label)?.includes(kanaToHira(keyword)),
      ) || [];

  const selectedOptions =
    hubspotDealPropertyDefinitionEnumCheckboxOptions?.filter((option) =>
      values?.includes(option.id),
    ) || [];

  return (
    <Combobox value={values} onChange={onChange} multiple>
      <div
        className="relative flex-1"
        onClick={() => inputRef.current?.focus()}>
        <div className="relative w-full min-h-[2.5rem] cursor-default rounded bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap">
          {selectedOptions.map((option) => (
            <span
              key={option.id}
              className="truncate inline-flex items-center justify-center pl-2 pr-1 py-1 text-sm font-medium leading-4 rounded-sm bg-c-bg border border-c-border"
              onClick={(e) => e.stopPropagation()}>
              {option.label}
              <XMarkIcon
                className="w-4 h-4 ml-2 cursor-pointer hover:bg-[rgba(255,255,255,.3)] rounded-sm"
                onClick={() =>
                  onChange(values.filter((value) => value !== option.id))
                }
              />
            </span>
          ))}
          <Combobox.Input
            className="flex-1 outline-none border-transparent focus:border-transparent focus:ring-0"
            onChange={(e) => setKeyword(e.target.value)}
            onFocus={() => buttonRef.current?.click()}
            value={keyword}
            ref={inputRef}
          />
          <Combobox.Button className="hidden" ref={buttonRef} />
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </div>
        {filteredOptions.length > 0 && (
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Combobox.Options className="absolute mt-1 max-h-60 w-full z-10 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.id}
                  className={({active}) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? 'bg-c-bg' : 'white'
                    }`
                  }
                  value={option.id}>
                  <div className="flex items-center gap-2">
                    <span className="h-3 w-3 rounded-sm bg" />
                    <span className="truncate flex-1">{option.label}</span>
                  </div>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        )}
      </div>
    </Combobox>
  );
};
