import React from 'react';

function Icon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.0439 16.2484V10.6114C36.7888 10.2632 37.4195 9.71066 37.8626 9.01799C38.3057 8.32531 38.543 7.52103 38.5468 6.69876V6.56942C38.5468 4.17197 36.6033 2.22845 34.206 2.22845H34.0765C31.679 2.22845 29.7355 4.17197 29.7355 6.56942V6.69876C29.7393 7.52103 29.9765 8.32531 30.4196 9.01799C30.8628 9.71066 31.4935 10.2632 32.2384 10.6114V16.2484C30.0974 16.5763 28.0811 17.4641 26.3935 18.8218L10.9336 6.78015C11.0438 6.38219 11.1013 5.97297 11.1078 5.56222C11.1097 4.59477 10.8246 3.6485 10.2887 2.84309C9.75269 2.03769 8.98988 1.40933 8.09674 1.03751C7.2036 0.665687 6.22025 0.567102 5.27107 0.754224C4.32189 0.941346 3.44953 1.40577 2.76433 2.08875C2.07913 2.77173 1.61188 3.64258 1.42168 4.59114C1.23148 5.53971 1.32688 6.52338 1.6958 7.41772C2.06473 8.31206 2.69061 9.0769 3.49427 9.61549C4.29794 10.1541 5.24328 10.4422 6.21073 10.4435C7.05707 10.4395 7.88755 10.2135 8.61926 9.78818L23.8428 21.6344C21.044 25.8625 21.1189 31.3729 24.0314 35.5236L19.4012 40.1551C19.0267 40.0355 18.637 39.9719 18.244 39.9665C16.0265 39.9685 14.2301 41.7668 14.2307 43.9843C14.2313 46.2016 16.0288 47.9989 18.2461 47.9995C20.4636 48.0001 22.2619 46.2037 22.2638 43.9862C22.2586 43.5933 22.195 43.2034 22.0751 42.8292L26.6556 38.2469C30.7484 41.3977 36.3696 41.6692 40.7471 38.9274C45.1243 36.1855 47.3334 31.0093 46.2846 25.9519C45.2357 20.8943 41.1506 17.0235 36.0439 16.2482V16.2484ZM34.1452 34.7882C33.299 34.811 32.4568 34.664 31.6683 34.3559C30.8799 34.0478 30.1611 33.5848 29.5545 32.9943C28.9479 32.4038 28.4658 31.6978 28.1366 30.9179C27.8074 30.138 27.6378 29.3 27.6378 28.4535C27.6378 27.6069 27.8074 26.769 28.1366 25.9891C28.4658 25.2092 28.9479 24.5031 29.5545 23.9127C30.1611 23.3222 30.8799 22.8592 31.6683 22.5511C32.4568 22.243 33.299 22.096 34.1452 22.1187C37.5554 22.2381 40.2588 25.0364 40.2607 28.4489C40.2622 31.861 37.5617 34.6621 34.1516 34.785"
        fill="#FF7A59"
      />
    </svg>
  );
}

export default Icon;
