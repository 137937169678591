import React from 'react';
import LeadRecordType from './LeadRecordType';
import LeadStatus from './LeadStatus';
import LeadOwner from './LeadOwner';
import {useWorkflowQuery} from 'api';
import {useParams} from 'react-router-dom';
import LeadSearchCondition from './LeadSearchCondition';

export default () => {
  const {workflowId} = useParams<{poolId: string; workflowId: string}>();
  const {data: {workflow: {searchCondition = {}} = {}} = {}} = useWorkflowQuery(
    {
      variables: {uuid: workflowId},
    },
  );

  const [salesforceLeadSearchConditions, setSalesforceLeadSearchConditions] =
    React.useState([]);

  React.useEffect(() => {
    setSalesforceLeadSearchConditions(
      searchCondition?.salesforceLeadSearchConditions || [],
    );
  }, [searchCondition.salesforceLeadSearchConditions]);

  return (
    <div className="flex flex-col gap-2">
      <LeadRecordType />
      <LeadStatus />
      <LeadOwner />
      <LeadSearchCondition
        setSalesforceLeadSearchConditions={setSalesforceLeadSearchConditions}
        salesforceLeadSearchConditions={salesforceLeadSearchConditions}
      />
    </div>
  );
};
