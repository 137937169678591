import React from 'react';
import {AutomaticMail, ManualMail, StepCall, Sms, StepTask} from 'components/Ui/Icon';
import {Step} from 'api';

type Props = {
  step: Step,
  active?: boolean,
  width?: string,
  height?: string
};

const iconComponents: {[key: string]: any} = {
  'Step::AutomaticMail': AutomaticMail,
  'Step::ManualMail': ManualMail,
  'Step::Call': StepCall,
  'Step::Task': StepTask,
  'Step::Sms': Sms,
};

const Icon = ({step, active = true, width, height}: Props) => {
  const IconComponent = iconComponents[step.steppableType];

  return <IconComponent active={active} width={width} height={height}/>;
};

export default Icon;
