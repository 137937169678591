import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';

const useTableData = (): ReportTable => {
  const headers: ReportTableHeader[] = [
    '2024年',
    '合計',
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  const data: ReportTableData[] = [
    {
      label: '合計',
      values: [687, 34, 22, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
      cellProps: {
        style: {
          borderBottom: 'double 3px #899098',
        },
      },
    },
    {
      label: '1 ~ 20人',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
    {
      label: '21 ~ 50人',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
    {
      label: '101 ~ 500人',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
    {
      label: '501 ~ 1000人',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
    {
      label: '1001 ~ 5000人',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
    {
      label: '5001 ~ 10000人',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
    {
      label: '10001人 ~',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
    {
      label: '従業員数不明',
      values: [287, 87, 87, 67, 32, 47, 23, 36, 67, 32, 47, 23, 36],
    },
  ];

  return {
    data,
    headers,
  };
};

export default useTableData;
