import React from 'react';
import useCondition from '../../useCondition';
import moment from 'moment';

export default () => {
  const {condition} = useCondition();
  const dateFormat = 'YYYY/MM/DD';

  if (!condition?.trackedAtFrom && !condition?.trackedAtTo) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">最新Webサイト訪問期間</h4>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
          <>
            {condition?.trackedAtFrom &&
              moment(condition.trackedAtFrom).format(dateFormat)}
            ~
            {condition?.trackedAtTo &&
              moment(condition.trackedAtTo).format(dateFormat)}
          </>
        </span>
      </div>
    </div>
  );
};
