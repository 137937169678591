import React from 'react';
import {Eye} from 'components/Ui/Icon';
import {Popover} from '@headlessui/react';
import {Switch} from 'components/antd';
import {ProspectTelStatus, useCurrentClientQuery} from 'api';
import useSearchParams from './useSearchParams';

export default () => {
  const {data: {currentClient: {prospectTelStatuses = []} = {}} = {}} =
    useCurrentClientQuery({});
  const {query, setQuery} = useSearchParams();

  const prospectTelStatusWithNotSelected = React.useMemo(() => {
    return [{id: 'notSelected', name: '未選択'}, ...prospectTelStatuses];
  }, [prospectTelStatuses]);

  const setHideProspectTelStatusIds = (
    value: boolean,
    prospectTelStatus: ProspectTelStatus,
  ) => {
    setQuery({
      hidedProspectTelStatusColumnIds: value
        ? query.hidedProspectTelStatusColumnIds.filter(
            (id) => id !== prospectTelStatus.id,
          )
        : [...query.hidedProspectTelStatusColumnIds, prospectTelStatus.id],
    });
  };

  return (
    <div className="flex justify-end items-center container">
      <div className="flex justify-end w-full">
        <div className="mb-4">
          <Popover className="relative">
            <Popover.Button
              className={`bg-white ${
                open && 'text-#[#68B5FB]'
              } "fill-[#68B5FB]" hover:cursor-pointer`}>
              <Eye />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-10 shadow-lg rounded-md">
              <div className="w-56 bg-white">
                <div className="mt-4 max-h-[60vh] overflow-scroll px-8 py-7">
                  <h4 className="text-sm text-[#BDC4CB]">コールステータス</h4>
                  {prospectTelStatusWithNotSelected.map(
                    (prospectTelStatus: ProspectTelStatus, index: number) => {
                      return (
                        <div
                          key={index}
                          className="flex h-8 justify-between items-center">
                          <div className="flex items-center">
                            {prospectTelStatus.name}
                          </div>
                          <div>
                            <Switch
                              checked={
                                !query.hidedProspectTelStatusColumnIds.includes(
                                  prospectTelStatus.id,
                                )
                              }
                              onChange={(value) =>
                                setHideProspectTelStatusIds(
                                  value,
                                  prospectTelStatus,
                                )
                              }
                            />
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    </div>
  );
};
