import gql from 'graphql-tag';

export default gql`
  fragment preleadMasterColumnFragment on PreleadMasterColumn {
    id
    title
    dataType
    isDisplay
    position
    clientPreleadCustomizeItemId
  }
`;
