import React from 'react';

const Icon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="10" cy="10" r="10" fill="#88C9D8" />
    <path
      d="M12.9167 7.44788H7.08333C6.88198 7.44788 6.71875 7.61111 6.71875 7.81246V12.1875C6.71875 12.3888 6.88198 12.552 7.08333 12.552H12.9167C13.118 12.552 13.2812 12.3888 13.2812 12.1875V7.81246C13.2812 7.61111 13.118 7.44788 12.9167 7.44788Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9168 7.63025L10.0002 10.3646L7.0835 7.63025"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
