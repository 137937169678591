import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import useSearchParams from '../../../useSearchParams';
import BASE_CLASSIFICATIONS from 'helpers/classifications';
import {useMainCategoriesQuery} from 'api';
import {CheckIcon} from '@heroicons/react/20/solid';

const SubCategoryFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {mainCategories = []} = {}} = useMainCategoriesQuery({});

  const classifications = React.useMemo(() => {
    return BASE_CLASSIFICATIONS(mainCategories);
  }, [mainCategories]);

  const selectedClassifications = React.useMemo(
    () =>
      classifications.filter((classification) =>
        classification.categories.some((category) =>
          category.subCategories.some((subCategory) =>
            query.subCategories
              .map((subCategory) => subCategory.id)
              .includes(subCategory.id),
          ),
        ),
      ),
    [classifications, query],
  );

  return (
    <>
      <Header title="業種" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b">
        <div className="bg-white p-4 rounded flex gap-2 flex-1">
          <div className="w-64 overflow-y-scroll overflow-x-hidden relative py-2">
            <div className="w-64 absolute">
              {classifications.map((classification) => (
                <div
                  key={classification.name}
                  onClick={() =>
                    document
                      .getElementById(classification.name)
                      .scrollIntoView()
                  }
                  className="cursor-pointer w-64 h-8 flex items-center text-base px-4">
                  <span className="flex-1">{classification.name}</span>
                  {selectedClassifications.includes(classification) && (
                    <CheckIcon className="w-4 mx-2" />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 overflow-y-scroll overflow-x-hidden relative py-2">
            <div className="absolute w-full px-4 flex flex-col gap-8">
              {classifications.map((classification) => (
                <section key={classification.name} id={classification.name}>
                  <div className="flex items-center gap-2 mb-4">
                    <div className="w-1 h-5 bg-[#222426]" />
                    <h3 className="font-bold text-lg m-0">
                      {classification.name}
                    </h3>
                  </div>
                  {classification.categories.map((category) => (
                    <div key={category.id} className="mb-8">
                      <label className="flex items-center text-base w-full">
                        <input
                          type="checkbox"
                          value={category.id}
                          className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light"
                          checked={category.subCategories.every((subCategory) =>
                            query.subCategories.includes(subCategory.id),
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setQuery({
                                subCategories: [
                                  ...query.subCategories,
                                  ...category.subCategories.map(
                                    (subCategory) => subCategory.id,
                                  ),
                                ],
                              });
                            } else {
                              setQuery({
                                subCategories: query.subCategories.filter(
                                  (id) =>
                                    !category.subCategories
                                      .map((subCategory) => subCategory.id)
                                      .includes(id),
                                ),
                              });
                            }
                          }}
                        />
                        <span className="ml-2">
                          {category.displayName}すべて
                        </span>
                      </label>
                      <div className="my-4 pt-4 border-t border-c-border">
                        {category.subCategories.map((subCategory) => (
                          <label
                            key={subCategory.id}
                            className="flex items-center text-base w-full">
                            <input
                              type="checkbox"
                              value={subCategory.id}
                              className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light"
                              checked={query.subCategories.includes(
                                subCategory.id,
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setQuery({
                                    subCategories: [
                                      ...query.subCategories,
                                      subCategory.id,
                                    ],
                                  });
                                } else {
                                  setQuery({
                                    subCategories: query.subCategories.filter(
                                      (id) => id !== subCategory.id,
                                    ),
                                  });
                                }
                              }}
                            />
                            <span className="ml-2">
                              {subCategory.displayName}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer onClear={() => setQuery({subCategories: []})} />
    </>
  );
};

export default SubCategoryFilter;
