import React from 'react';
import {useTable} from 'react-table';
import styled from 'styled-components';

type Props = {columns: any[]; data: any[]};

export default ({columns, data}: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({columns, data});

  return (
    <Table {...getTableProps()} cellSpacing="0" cellPadding="0">
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, j) => {
              return (
                <th
                  {...column.getHeaderProps([
                    {
                      // className: column.className,
                      // @ts-ignore
                      style: column.style,
                    },
                  ])}
                  key={j}>
                  {column.render('Header')}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map((cell, j) => {
                return (
                  <td {...cell.getCellProps()} key={j}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 14px 10px;
    margin: 0px;
  }

  thead {
    tr {
      background: #f9fafc;
      height: 37px;

      th {
        // position: sticky;
        // top: 0;
        // z-index: 5;
        border-top: 1px solid #e2e6ea;
        border-bottom: 1px solid #e2e6ea;
        background: #f9fafc;
        padding: 11px;
        font-weight: bold;
        font-size: 12px;
        line-height: 17px;
        color: #495058;

        &:first-child {
          border-left: 1px solid #e2e6ea;
          border-radius: 4px 0 0 0;
        }
        &:last-child {
          border-right: 1px solid #e2e6ea;
          border-radius: 0 4px 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      background: #ffffff;
      box-sizing: border-box;

      &:hover {
        background: #f8f7f7;
      }

      td {
        border-bottom: 1px solid #e2e6ea;

        white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;

        &:first-child {
          border-left: 1px solid #e2e6ea;
        }
        &:last-child {
          border-right: 1px solid #e2e6ea;
        }
      }
    }
  }
`;
