import React from 'react';
import {useRecoilState} from 'recoil';
import filterOptionsAtom from './Filter/filterOptionsAtom';

const useFilterAcitve = (filterParams: any) => {
  const [filterOptions] = useRecoilState(filterOptionsAtom);
  const filterActive = React.useMemo(() => {
    const currentFilters = Object.entries(filterParams)
      .filter((entry) => (Array.isArray(entry[1]) ? entry[1].length : entry[1]))
      .map((entry) => {
        if (['telFrom', 'telTo'].includes(entry[0])) return 'telTerm';
        if (['taskDueDateFrom', 'taskDueDateTo'].includes(entry[0]))
          return 'taskDueDate';
        if (['mailTermFrom', 'mailTermTo'].includes(entry[0]))
          return 'mailTerm';
        if (['clickTermFrom', 'clickTermTo'].includes(entry[0]))
          return 'clickTerm';
        if (
          ['establishedYearMonthFrom', 'establishedYearMonthTo'].includes(
            entry[0],
          )
        )
          return 'establishedYearMonth';
        if (['remindFrom', 'remindTo'].includes(entry[0])) return 'remind';
        if (['trackedAtFrom', 'trackedAtTo'].includes(entry[0]))
          return 'trackedAt';
        if (
          ['mailCampaignOpenedAtFrom', 'mailCampaignOpenedAtTo'].includes(
            entry[0],
          )
        )
          return 'mailCampaignOpenedAt';
        if (
          ['mailCampaignClickedAtFrom', 'mailCampaignClickedAtTo'].includes(
            entry[0],
          )
        )
          return 'mailCampaignClickedAt';
        return entry[0];
      });

    return filterOptions
      .map((option: any) => option.value)
      .some((value: any) => currentFilters.includes(value));
  }, [filterParams]);

  return {
    filterActive,
  };
};

export default useFilterAcitve;
