import React from 'react';
import moment from 'moment';
import {ProspectActivity, MailCampaignMailSendProspectActivity} from 'api';
import {MailCampaign, Hubspot} from 'components/Ui/Icon';
import {Link} from 'react-router-dom';

interface MailCampaignMailSendActivity extends ProspectActivity {
  resource: MailCampaignMailSendProspectActivity;
}

interface Props {
  prospectActivity: MailCampaignMailSendActivity;
}

export default ({prospectActivity}: Props) => {
  const {
    createdAt,
    resource: {
      hubspotContactIdString,
      mailCampaignSend: {bodyHtml, subject, to, mailCampaign} = {},
    },
  } = prospectActivity;

  const [showAll, setShowAll] = React.useState(false);

  return (
    <>
      <MailCampaign />
      <div className="w-full ml-[10px]">
        <div className="flex items-center gap-2 min-h-[32px]">
          {hubspotContactIdString && (
            <span className="bg-[#FFDBD5] h-5 px-2 text-xs text-[#FF7A59] rounded-sm flex items-center gap-1 [&>svg]:h-3 [&>svg]:w-auto">
              <Hubspot />
              コンタクト
            </span>
          )}
          <h3 className="flex-1 font-bold m-0">
            <Link target="_blank" to={`/mail_campaigns/${mailCampaign.uuid}`}>
              {mailCampaign.title}
            </Link>
          </h3>
          <time className="text-c-light">
            {moment(createdAt).format('YYYY/MM/DD HH:mm')}
          </time>
        </div>
        <div className="border border-c-border rounded p-4">
          <div className="font-medium text-base mb-4">
            {subject || 'タイトルなし'}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <button
              className="text-[#68b5fb] font-medium text-base cursor-pointer bg-transparent p-0"
              onClick={() => setShowAll(!showAll)}>
              {showAll ? '閉じる' : '内容を表示'}
            </button>
          </div>

          {showAll && (
            <div className="mt-4 [&_p]:m-0 [&_img]:max-w-full">
              <div className="border-y border-y-c-border py-2 mb-4">
                <div className="mt-2">
                  <span className="inline-block text-c-light w-12 float-left">
                    From
                  </span>
                  <span className="block ml-12 min-h-[1.5rem]">
                    {mailCampaign.fromName} &lt;{mailCampaign.fromAddress}&gt;
                  </span>
                </div>
                <div className="mt-2">
                  <span className="inline-block text-c-light w-12 float-left">
                    To
                  </span>
                  <span className="block ml-12 min-h-[1.5rem]">{to}</span>
                </div>
                <div className="mt-2">
                  <span className="inline-block text-c-light w-12 float-left">
                    Cc
                  </span>
                  <span className="block ml-12 min-h-[1.5rem]">
                    {mailCampaign?.cc?.join(', ')}
                  </span>
                </div>
                <div className="mt-2">
                  <span className="inline-block text-c-light w-12 float-left">
                    Bcc
                  </span>
                  <span className="block ml-12 min-h-[1.5rem]">
                    {mailCampaign?.bcc?.join(', ')}
                  </span>
                </div>
              </div>
              <iframe
                sandbox="allow-same-origin"
                referrerPolicy="no-referrer"
                srcDoc={bodyHtml}
                className="overflow-scroll h-[500px] w-full border border-[#e2e6ea]"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
