import React from 'react';
import Select from './Select';
import Date from './Date';
import {
  CustomizeItemSearchConditionType,
  CustomizeItemDateSearchConditionType,
} from '../CustomizeItemSearchConditionType';

interface Props {
  prospectCustomizeItemSearchCondition: CustomizeItemSearchConditionType;
  index: number;
}

export default ({prospectCustomizeItemSearchCondition, index}: Props) => {
  const data_type = prospectCustomizeItemSearchCondition.data_type;
  if (data_type !== 'select' && data_type !== 'date') {
    return <></>;
  }
  if (data_type === 'select') {
    return (
      <Select
        prospectCustomizeItemSearchCondition={
          prospectCustomizeItemSearchCondition
        }
        index={index}
      />
    );
  }
  if (data_type === 'date') {
    return (
      <Date
        prospectCustomizeItemDateSearchCondition={
          prospectCustomizeItemSearchCondition as CustomizeItemDateSearchConditionType
        }
        index={index}
      />
    );
  }
};
