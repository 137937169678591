import React from 'react';
import {Combobox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {useProspectMailCampaignsQuery} from 'api';
import useSearchParams from '../../../../useSearchParams';

const MailCampaignSelect = () => {
  const {query, setQuery} = useSearchParams();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = React.useState('');

  React.useEffect(() => {
    setKeyword('');
  }, [query.mailCampaignId]);

  const {data: {prospectMailCampaigns: {mailCampaigns = []} = {}} = {}} =
    useProspectMailCampaignsQuery({});

  const filteredMailCampaigns =
    keyword === '' ||
    keyword ===
      mailCampaigns.find((mc) => mc.uuid === query.mailCampaignId)?.title
      ? mailCampaigns
      : mailCampaigns?.filter((mailCampaign) => {
          return `${mailCampaign?.title}`
            ?.toLowerCase()
            ?.includes(keyword?.toLowerCase());
        });

  return (
    <Combobox
      value={query.mailCampaignId}
      disabled={query.isTargetAllMailCampaigns}
      onChange={(object: any) => {
        setQuery({...query, mailCampaignId: object.uuid});
      }}>
      <Combobox.Button as={React.Fragment}>
        <div
          className="relative mt-1 h-[2.5rem]"
          onClick={() => inputRef.current?.focus()}>
          <Combobox.Input
            onChange={(e) => setKeyword(e.target.value)}
            onFocus={() => buttonRef.current?.click()}
            value={keyword}
            displayValue={() =>
              filteredMailCampaigns.find(
                (mc) => mc.uuid === query.mailCampaignId,
              )?.title
            }
            ref={inputRef}
            className="relative w-full cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap"
          />
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              <Combobox.Option
                className={({active}) =>
                  `relative cursor-default select-none py-2 px-4 ${
                    active ? 'bg-c-bg' : 'white'
                  }`
                }
                value={(): any => {
                  setQuery({
                    ...query,
                    mailCampaignId: null,
                    mailCampaignUrlIds: [],
                  });
                }}>
                <div className="flex items-center gap-2">
                  <span className="truncate flex-1 h-5" />
                </div>
              </Combobox.Option>
              {filteredMailCampaigns.map((filteredMailCampaign) => (
                <Combobox.Option
                  key={filteredMailCampaign.id}
                  className={({active}) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? 'bg-c-bg' : 'white'
                    }`
                  }
                  value={filteredMailCampaign}>
                  <div className="flex items-center gap-2">
                    <span className="truncate flex-1">
                      {filteredMailCampaign.title}
                    </span>
                  </div>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox.Button>
    </Combobox>
  );
};

export default MailCampaignSelect;
