import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import {useUsersByClientAllQuery} from 'api';

const LatestTelUserFilter = () => {
  const {query, setQuery} = useSearchParams();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
    });

  const options = React.useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        text: `${user.lastName} ${user.firstName}`,
      })),
    [users],
  );

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.latestTelUserIds || []}
      onChange={(value) => setQuery({page: 1, latestTelUserIds: value})}>
      <Select.Option value="none">TEL更新ユーザーなし</Select.Option>
      {options.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LatestTelUserFilter;
