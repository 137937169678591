import React from 'react';
import {StringParam, useQueryParams} from 'use-query-params';
import Daily from './Daily';
import Weekly from './Weekly';

const Status = () => {
  const [query] = useQueryParams({
    timeScale: StringParam,
  });
  const MappingComponent: any = {
    day: <Daily />,
    week: <Weekly />,
  };
  return MappingComponent[query.timeScale] ?? <></>;
};

export default Status;
