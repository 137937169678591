import React from 'react';
import {Select} from 'components/antd';
import {useParams} from 'react-router-dom';
import useSearchParams from '../../useSearchParams';
import {useProjectGroupByProjectUuidQuery} from 'api';

const AssigneesFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {projectId} = useParams<{projectId: string}>();

  const {data: {projectGroupByProjectUuid: projectGroup = null} = {}} =
    useProjectGroupByProjectUuidQuery({
      variables: {uuid: projectId},
    });

  const options = React.useMemo(
    () =>
      projectGroup
        ? projectGroup.users.map((user) => ({
            value: user.id,
            text: `${user.lastName} ${user.firstName}`,
          }))
        : [],
    [projectGroup],
  );

  return (
    <Select
      dropdownMatchSelectWidth={false}
      mode="multiple"
      allowClear
      value={query.assigneeIds || []}
      onChange={(value) => setQuery({page: 1, assigneeIds: value})}>
      <Select.Option value="none">担当者なし</Select.Option>
      {options.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AssigneesFilter;
