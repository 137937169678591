import moment from 'moment';

export const formatDateTime = (dateTime: string) => {
  if (!dateTime) {
    return;
  }

  const date = new Date();
  const year = date.getFullYear();

  if (String(year) === moment(dateTime).format('YYYY')) {
    return moment(dateTime).format('MM/DD HH:mm');
  } else {
    return moment(dateTime).format('YYYY/MM/DD HH:mm');
  }
};
