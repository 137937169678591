import {ReportChart} from '../../components/Chart';
import useColor from '../../hooks/useColor';
import {useLeadSourceReportsQuery} from 'api';

const useChartData = (): ReportChart => {
  const {getColor} = useColor();

  const {data: {leadSourceReports = []} = {}} = useLeadSourceReportsQuery({});

  const leadSourceNames = leadSourceReports
    .filter((report) => !!report.values[0])
    .map((report) => report.name);

  const leadSourceValues = leadSourceReports
    .map((report) => report.values)
    .flat()
    .filter(Boolean);

  return {
    axis: leadSourceNames,
    data: [
      {
        label: 'リード数',
        color: getColor(5),
        values: leadSourceValues,
      },
    ],
  };
};

export default useChartData;
