import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Activity, UserMailActivity} from 'api';
import {Airplane} from 'components/Ui/Icon';
import useProjectName from './useProjectName';
import ExtensionIcon from '../File/ExtensionIcon';
import linkifyHtml from 'linkify-html';

interface UserMail extends Activity {
  resource: UserMailActivity;
}

interface Props {
  activity: UserMail;
}

const LetterSendActivityComponent = ({activity}: Props) => {
  const {projectName} = useProjectName(activity);

  const convertUrlToLink = (text: string) =>
    linkifyHtml(text, {
      defaultProtocol: 'https',
      rel: 'noopener noreferrer',
      target: '_blank',
    });

  return (
    <Container>
      {projectName && <Project>{projectName}</Project>}
      <Summary>
        <BadgeWrapper>
          <Airplane />
        </BadgeWrapper>
        <Body>メールを送信しました</Body>
        <TimeStamp>
          {moment(activity.createdAt).format('MM/DD HH:mm')}
        </TimeStamp>
      </Summary>
      <Content>
        <dl>
          <dt>差出人</dt>
          <dd>{activity.resource.from}</dd>
          <dt>宛先</dt>
          <dd>{activity.resource.to.join(', ')}</dd>
          <dt>件名</dt>
          <dd>{activity.resource.title}</dd>
          {activity.resource.cc?.length > 0 && (
            <>
              <dt>Cc</dt>
              <dd>{activity.resource.cc?.join(', ')}</dd>
            </>
          )}
        </dl>
        <dl>
          <dt>本文</dt>
          {activity.resource.bodyHtml ? (
            <dd
              dangerouslySetInnerHTML={{
                __html: convertUrlToLink(activity.resource.bodyHtml),
              }}
            />
          ) : (
            <dd>{activity.resource.body}</dd>
          )}
        </dl>

        {activity.resource.mailAttachments.length > 0 && (
          <FilesWrapper>
            <div>
              {activity.resource.mailAttachments.length}個の添付ファイル
            </div>
            {activity.resource.mailAttachments.map((mailAttachment) => (
              <File key={mailAttachment.uuid}>
                <ExtensionIcon extension={mailAttachment.extension} />
                <span
                  onClick={() =>
                    (window.location.href = `/user_mail_attachments/${mailAttachment.uuid}/export`)
                  }>
                  {mailAttachment.filename}
                </span>
              </File>
            ))}
          </FilesWrapper>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  margin-left: -30px;
  position: relative;
  padding: 9px 20px 10px;
  border: 1px solid #e2e6ea;
  border-radius: 4px;
  background: #ffffff;
`;

const Summary = styled.div`
  margin-left: -3px;
  margin-top: 5px;
  display: flex;
  width: 480px;
  height: 30px;

  span {
    line-height: 30px;
  }
`;

const BadgeWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

const Body = styled.span`
  min-width: 0;
  max-width: 100%;
  margin-top: 4px;
  flex: auto;
`;

const TimeStamp = styled.span`
  margin-left: auto;
`;

const Project = styled.div`
  color: #899098;
`;

const Content = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  dd > * {
    margin-bottom: 0;
  }

  dl:first-of-type {
    line-height: 2;

    dt {
      float: left;
      font-weight: bold;
      color: #bcc3ca;
    }

    dd {
      margin-left: 80px;
      color: #495058;
    }
  }

  dl:last-of-type {
    dt {
      font-weight: bold;
      color: #bcc3ca;
      margin-bottom: 0.5rem;
    }

    dd {
      color: #495058;
      white-space: pre-wrap;
      margin-bottom: 1rem;
    }
  }
`;

const FilesWrapper = styled.div`
  border-top: 1px solid #e1e6eb;
  padding-top: 8px;

  div:first-child {
    font-size: 12px;
    color: #899098;
  }
`;

const File = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  span {
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    color: #68b5fb;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;

export default LetterSendActivityComponent;
