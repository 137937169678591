import {TelephoneNumber, usePreleadQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';
import Tel from './Tel';
import Copy from '../Copy';
import Add from './Add';
import Edit from './Edit';
import Divider from './Divider';
import Delete from './Delete';

export default () => {
  const {preleadId} = useParams<{
    preleadId: string;
  }>();

  const {data: {prelead = {}} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    fetchPolicy: 'cache-only',
  });
  return (
    <>
      {prelead.telephoneNumbers?.map(
        (telephoneNumber: TelephoneNumber, index) => (
          <tr className="hover:bg-[#F4F9FD] w-40 rounded group" key={index}>
            <th>{telephoneNumber.name}</th>
            <td key={index}>
              <Tel telephoneNumber={telephoneNumber.telephoneNumber} />
              <Copy copyText={telephoneNumber.telephoneNumber} />
              <Edit telephoneNumber={telephoneNumber.telephoneNumber} />
              <Delete telephoneNumber={telephoneNumber} />
              <Divider />
              <Add />
            </td>
          </tr>
        ),
      )}
    </>
  );
};
