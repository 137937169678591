import React from 'react';
import styled from 'styled-components';
import {
  PreleadProject,
  ProjectGroupPreleadCustomizeItem,
  useUpdateProjectGroupPreleadCustomizeItemNameMutation,
} from 'api';
import TextItem from './Text';
import NumberItem from './Number';
import DateItem from './Date';
import SelectItem from './Select';
import {Input as UIInput} from 'components/antd';
import HashTag from './Icon/HashTag';
import Text from './Icon/Text';
import Calendar from './Icon/Calendar';
import Tag from './Icon/Tag';

const itemComponents: {[key: string]: any} = {
  text: TextItem,
  number: NumberItem,
  date: DateItem,
  select: SelectItem,
};

const itemIcons: {[key: string]: any} = {
  text: Text,
  number: HashTag,
  date: Calendar,
  select: Tag,
};

interface Props {
  preleadProject: PreleadProject;
  projectGroupPreleadCustomizeItem: ProjectGroupPreleadCustomizeItem;
}

export default ({preleadProject, projectGroupPreleadCustomizeItem}: Props) => {
  const ItemComponent = React.useMemo(
    () => itemComponents[projectGroupPreleadCustomizeItem.dataType],
    [projectGroupPreleadCustomizeItem],
  );

  const ItemIcon = React.useMemo(
    () => itemIcons[projectGroupPreleadCustomizeItem.dataType],
    [projectGroupPreleadCustomizeItem],
  );

  const [value, setValue] = React.useState(
    projectGroupPreleadCustomizeItem.name,
  );
  const [updateName] = useUpdateProjectGroupPreleadCustomizeItemNameMutation();

  const update = (e: any) => {
    value.trim() !== '' &&
      updateName({
        variables: {
          name: value,
          id: projectGroupPreleadCustomizeItem.id,
          projectId: preleadProject.projectId,
        },
      });
  };

  return (
    <Container>
      <ItemIcon />
      <div>
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={update}
          onBlur={update}
          placeholder="未入力"
        />
      </div>
      <div>
        <ItemComponent
          prelead={preleadProject.prelead}
          projectGroupPreleadCustomizeItem={projectGroupPreleadCustomizeItem}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #495058;
  min-height: 24px;
  width: 100%;

  & > div:first-child {
    width: 180px;
  }
  & > div:last-child {
    flex: 1;
  }
`;

const Input = styled(UIInput)`
  border-color: transparent;
  background-color: transparent;
  border-radius: 4px;
  width: 180px;

  &:hover {
    border: 1px solid #bdc4cb;
  }
`;
