import gql from 'graphql-tag';

export default gql`
  fragment salesforceTaskCallProspectActivityFragment on SalesforceTaskCallProspectActivity {
    id
    activityDate
    description
    salesforceTaskIdString
    subject
    taskSubtype
  }
`;
