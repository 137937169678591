import React from 'react';
import {prospectStages} from 'helpers/prospectStages';
import Header from '../Header';
import Footer from './Footer';
import CheckboxGroup from './CheckboxGroup';
import useCondition from '../../useCondition';

const StageFilter = () => {
  const {condition, setCondition} = useCondition();

  return (
    <>
      <Header title="ステージ" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            options={prospectStages.map((stage) => ({
              text: stage.displayName,
              value: stage.name,
            }))}
            values={condition.stages || []}
            onChange={(stages) => setCondition({stages})}
          />
        </div>
      </div>
      <Footer onClear={() => setCondition({stages: []})} />
    </>
  );
};

export default StageFilter;
