import React from 'react';
import useSearchParams from '../../../useSearchParams';
import {Input} from 'components/antd';

interface Props {
  filter: string;
  value: {value: string};
}

const CustomizeItemTextFilter = ({filter, value}: Props) => {
  const {setQuery} = useSearchParams();
  const [text, setText] = React.useState(value?.value);

  React.useEffect(() => {
    setText(value?.value);
  }, [value]);

  const onUpdate = React.useCallback(
    () => setQuery({[filter]: {value: text}}),
    [filter, text],
  );

  return (
    <Input
      value={text}
      onChange={(e) => setText(e.target.value)}
      onBlur={onUpdate}
      onPressEnter={onUpdate}
    />
  );
};

export default CustomizeItemTextFilter;
