import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {
  useWorkflowQuery,
  usePoolQuery,
  useUpdateWorkflowSearchConditionMutation,
} from 'api';
import {Checkbox} from 'components/antd';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {isMember} = useClientUser();
  const {poolId, workflowId} = useParams<{
    poolId: string;
    workflowId: string;
  }>();

  const {data: {pool: {prospectTags = []} = {}} = {}} = usePoolQuery({
    variables: {uuid: poolId},
    fetchPolicy: 'cache-and-network',
  });

  const {data: {workflow = {}, workflow: {searchCondition = {}} = {}} = {}} =
    useWorkflowQuery({
      variables: {uuid: workflowId},
      fetchPolicy: 'cache-and-network',
    });

  const [updateWorkflowSearchCondition] =
    useUpdateWorkflowSearchConditionMutation({
      refetchQueries: ['workflowProspectPoolCountsByStatus'],
    });

  const isConditionUpdatable = React.useMemo(
    () => workflow?.status === 'draft',
    [workflow],
  );

  return (
    <Item>
      <Label />
      <CheckboxGroup
        value={searchCondition?.excludedTags}
        disabled={!isConditionUpdatable || !isMember}
        onChange={(values: string[]) =>
          updateWorkflowSearchCondition({
            variables: {
              uuid: workflowId,
              attributes: {
                excludedTags: values,
              },
            },
          })
        }>
        除外する
        {prospectTags.map((tag) => (
          <Checkbox
            value={tag.id}
            key={tag.id}
            disabled={
              !!searchCondition?.tags?.find((value) => value === tag.id)
            }>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <TagColorSquare color={tag.color} />
              <span>{tag.name}</span>
            </div>
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Label = styled.div`
  width: 115px;
  font-weight: 500;
  font-size: 14px;
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;

  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-checkbox {
    top: 0;
  }
`;

const TagColorSquare = styled.span<{color: string}>`
  width: 14px;
  height: 14px;
  background: ${({color}) => color};
  border-radius: 2px;
  margin-right: 5px;
`;
