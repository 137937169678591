import React from 'react';
import {Task, TaskPrelead, TaskProspect} from 'api';
import {Row} from 'react-table';
import {Link} from 'react-router-dom';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;

  if (task.taskableType === 'Task::Prospect') {
    const taskable = task.taskable as TaskProspect;

    return (
      <Link
        className="text-sm flex gap-2 truncate"
        to={`/tasks/${task.uuid}/prospect/${taskable.prospectPool.uuid}${location.search}`}>
        {taskable.prospect.lastName} {taskable.prospect.firstName}
        <span className="text-c-lighter">{taskable.account?.name}</span>
      </Link>
    );
  }

  if (task.taskableType === 'Task::Prelead') {
    const taskable = task.taskable as TaskPrelead;

    return (
      <Link
        className="text-sm"
        to={`/tasks/${task.uuid}/prelead/${taskable.prelead.uuid}${location.search}`}>
        {taskable.prelead.name}
      </Link>
    );
  }

  return null;
};

export default Cell;
