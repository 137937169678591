import React from 'react';
import {Route, Switch} from 'react-router-dom';
import List from './List';
import MailCampaign from './MailCampaign';

export default () => (
  <Switch>
    <Route path="/mail_campaigns/:mailCampaignId" component={MailCampaign} />
    <Route path="/mail_campaigns" component={List} />
  </Switch>
);
