import React from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {
  usePoolMaintenanceSettingQuery,
  useUpdatePoolMaintenanceSettingMutation,
} from 'api';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const Modal = ({visible, onClose}: Props) => {
  const {data: {poolMaintenanceSetting = {}} = {}} =
    usePoolMaintenanceSettingQuery();

  const [updatePoolMaintenanceSetting] =
    useUpdatePoolMaintenanceSettingMutation();

  return (
    <Transition appear show={!!visible} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="flex flex-col relative w-[540px] transform rounded bg-white text-left align-middle shadow-xl transition-all overflow-hidden">
                <button
                  className="absolute right-4 top-4 p-0 bg-transparent border-none cursor-pointer w-6 h-6"
                  onClick={() => onClose()}>
                  <XMarkIcon className="h-6 w-6 text-c-lighter" />
                </button>
                <div className="px-8 py-8">
                  <h1 className="text-xl font-bold">重複判定設定</h1>
                  <p className="text-sm">
                    重複判定の項目を選択してください。選択した項目はAND条件で判定されます。
                    保存後、「重複判定を実行」ボタンをクリックしてください。
                  </p>
                  <div className="flex flex-col gap-2 my-8">
                    <label className="flex items-center text-base cursor-pointer max-w-max">
                      <input
                        type="checkbox"
                        defaultChecked={poolMaintenanceSetting?.useEmail}
                        className="form-checkbox h-5 w-5 rounded border-c-border cursor-pointer"
                        onChange={(e) =>
                          updatePoolMaintenanceSetting({
                            variables: {
                              id: poolMaintenanceSetting.id,
                              attributes: {
                                useEmail: e.target.checked,
                              },
                            },
                          })
                        }
                      />
                      <span className="ml-2">メールアドレス</span>
                    </label>
                    <label className="flex items-center text-base cursor-pointer max-w-max">
                      <input
                        type="checkbox"
                        defaultChecked={poolMaintenanceSetting?.useFullName}
                        className="form-checkbox h-5 w-5 rounded border-c-border cursor-pointer"
                        onChange={(e) =>
                          updatePoolMaintenanceSetting({
                            variables: {
                              id: poolMaintenanceSetting.id,
                              attributes: {
                                useFullName: e.target.checked,
                              },
                            },
                          })
                        }
                      />
                      <span className="ml-2">姓名</span>
                    </label>
                    <label className="flex items-center text-base cursor-pointer max-w-max">
                      <input
                        type="checkbox"
                        defaultChecked={poolMaintenanceSetting?.useTel}
                        className="form-checkbox h-5 w-5 rounded border-c-border cursor-pointer"
                        onChange={(e) =>
                          updatePoolMaintenanceSetting({
                            variables: {
                              id: poolMaintenanceSetting.id,
                              attributes: {
                                useTel: e.target.checked,
                              },
                            },
                          })
                        }
                      />
                      <span className="ml-2">電話番号</span>
                    </label>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
