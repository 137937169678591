import React from 'react';
import styled from 'styled-components';
import {Input, Modal, DatePicker, Form, Button} from 'components/antd';
import {
  usePreleadProjectsQuery,
  useCreateLetterSendActivitiesMutation,
  LetterSendActivityAttributes,
} from 'api';
import {useFormik} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {useParams} from 'react-router';
import useSearchParams from '../useSearchParams';

interface Props {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ({isModalVisible, setModalVisible}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {searchParams, page, perPage} = useSearchParams();

  const {
    data: {
      preleadProjects: {pagination: preleadProjectsPagination = {}} = {},
    } = {},
  } = usePreleadProjectsQuery({
    variables: {
      uuid: projectId,
      search: searchParams,
      page,
      perPage,
    },
    fetchPolicy: 'cache-only',
  });

  const [createLetterSendActivities, {loading}] =
    useCreateLetterSendActivitiesMutation({
      onCompleted: () => setModalVisible(false),
    });

  const handleClose = () => {
    !loading && setModalVisible(false);
  };

  const onSubmit = React.useCallback(
    (values: LetterSendActivityAttributes) =>
      createLetterSendActivities({
        variables: {
          uuid: projectId,
          search: searchParams,
          attributes: values,
        },
      }),
    [projectId, searchParams],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sendDate: null,
      expectedArrivalDate: null,
      sender: '',
      senderPosition: '',
      body: '',
      note: '',
    },
    validationSchema: Yup.object().shape({
      sendDate: Yup.date().required('必須項目です'),
      expectedArrivalDate: Yup.date().nullable(),
      sender: Yup.string().required('必須項目です'),
      senderPosition: Yup.string().nullable(),
      body: Yup.string().required('必須項目です'),
      note: Yup.string().nullable(),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <LetterModal
      destroyOnClose
      title={<Title>手紙情報を追加</Title>}
      maskClosable={false}
      visible={isModalVisible}
      width={800}
      centered
      bodyStyle={{paddingTop: '24px', padding: 0}}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={!formik.isValid}
          onClick={() => onSubmit(formik.values)}>
          保存
        </Button>,
      ]}
      onCancel={handleClose}>
      <ModalContent>
        <div>フィルタリングされている状態で手紙情報を記録できます。</div>

        <CountBox>
          <span>現在のフィルタリング件数:</span>
          <Count>
            {preleadProjectsPagination.totalCount?.toLocaleString()}
          </Count>
        </CountBox>
        <Form
          onFinish={formik.handleSubmit}
          labelCol={{span: 4}}
          wrapperCol={{span: 20}}>
          <Form.Item
            label="送付日"
            required
            validateStatus={
              formik.errors.sendDate && formik.touched.sendDate && 'error'
            }
            help={
              formik.errors.sendDate &&
              formik.touched.sendDate &&
              formik.errors.sendDate
            }>
            <DatePicker
              disabled={loading}
              value={
                formik.values.sendDate &&
                moment(formik.values.sendDate, 'YYYY-MM-DD')
              }
              onChange={(_: any, value: string) =>
                formik.setFieldValue('sendDate', value)
              }
            />
          </Form.Item>
          <Form.Item
            label="到着予定日"
            validateStatus={
              formik.errors.expectedArrivalDate &&
              formik.touched.expectedArrivalDate &&
              'error'
            }
            help={
              formik.errors.expectedArrivalDate &&
              formik.touched.expectedArrivalDate &&
              formik.errors.expectedArrivalDate
            }>
            <DatePicker
              disabled={loading}
              value={
                formik.values.expectedArrivalDate &&
                moment(formik.values.expectedArrivalDate, 'YYYY-MM-DD')
              }
              onChange={(_: any, value: string) =>
                formik.setFieldValue('expectedArrivalDate', value)
              }
            />
          </Form.Item>
          <Form.Item
            label="差出人"
            required
            validateStatus={
              formik.errors.sender && formik.touched.sender ? 'error' : ''
            }
            help={
              formik.errors.sender && formik.touched.sender
                ? formik.errors.sender
                : null
            }>
            <Input
              type="text"
              name="sender"
              disabled={loading}
              style={{width: '40%'}}
              value={formik.values.sender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
          <Form.Item
            label="差出人の役職"
            validateStatus={
              formik.errors.senderPosition && formik.touched.senderPosition
                ? 'error'
                : ''
            }
            help={
              formik.errors.senderPosition && formik.touched.senderPosition
                ? formik.errors.senderPosition
                : null
            }>
            <Input
              type="text"
              name="senderPosition"
              disabled={loading}
              style={{width: '40%'}}
              value={formik.values.senderPosition}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
          <Form.Item
            label="内容"
            required
            validateStatus={
              formik.errors.body && formik.touched.body ? 'error' : ''
            }
            help={
              formik.errors.body && formik.touched.body
                ? formik.errors.body
                : null
            }>
            <Input.TextArea
              name="body"
              disabled={loading}
              style={{height: '180px'}}
              value={formik.values.body}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
          <Form.Item
            label="備考"
            validateStatus={
              formik.errors.note && formik.touched.note ? 'error' : ''
            }
            help={
              formik.errors.note && formik.touched.note
                ? formik.errors.note
                : null
            }>
            <Input.TextArea
              name="note"
              placeholder="封筒、文字の色など"
              disabled={loading}
              style={{height: '80px'}}
              value={formik.values.note}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Form>
      </ModalContent>
    </LetterModal>
  );
};

const LetterModal = styled(Modal)`
  &&& {
    .ant-modal-header {
      border: none;
      padding: 16px 24px 13px 24px !important;
    }
    .ant-modal-body {
      padding: 0px 24px !important;
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #222426;
`;

const CountBox = styled.div`
  margin: 6px 0 23px 0;
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 30px;
  padding: 8px;
  background: #f3f5f7;
  border-radius: 4px;
  font-size: 10px;
`;

const Count = styled.span`
  margin-left: 7px;
  font-weight: bold;
  font-size: 13px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
