import {DashboardItem} from './components/DashboardItem/types';
import React from 'react';

export const useDashboardItems = (): DashboardItem[] => {
  const items = React.useMemo(
    (): DashboardItem[] => [
      {
        id: '1',
        itemType: 'chart_deal',
        options: {
          variant: 'single',
          timeScale: 'month',
          chartType: 'lead_source',
        },
      },
      {
        id: '2',
        itemType: 'chart_deal',
        options: {
          variant: 'double',
          timeScale: 'month',
          chartType: 'lead_source',
          size: 12,
        },
      },
      {
        id: '3',
        itemType: 'chart_deal',
        options: {
          variant: 'single',
          timeScale: 'month',
          chartType: 'lead_source',
        },
      },
      {
        id: '4',
        itemType: 'chart_todo',
        options: {
          variant: 'single',
          timeScale: 'month',
          chartType: 'status',
        },
      },
      {
        id: '5',
        itemType: 'chart_todo',
        options: {
          variant: 'single',
          timeScale: 'month',
          chartType: 'user',
        },
      },
      {
        id: '6',
        itemType: 'chart_deal',
        options: {
          variant: 'single',
          timeScale: 'month',
          chartType: 'lead_source',
        },
      },
    ],
    [],
  );

  return items;
};
