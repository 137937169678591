import React from 'react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import {XMarkIcon, ArrowRightIcon} from '@heroicons/react/20/solid';
import { useUsersByClientAllQuery } from 'api';

export default () => {
  const {setFilterType} = useFilterModalContext();
  const {query, setQuery} = useSearchParams();
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  if (!query?.telUpdatedDateFrom && !query?.telUpdatedDateTo && !query.lastTelUserIds.length) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">最新コールログ</h4>
        <button
          onClick={() => setFilterType('lastTelActivity')}
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <ArrowRightIcon className="w-3 h-3 text-c-light" />
        </button>
        <button
          onClick={() =>
            setQuery({
              ...query,
              telUpdatedDateFrom: '',
              telUpdatedDateTo: '',
              lastTelUserIds: [],
            })
          }
          className="p-0 h-5 w-5 flex items-center justify-center rounded-full border border-c-lighter cursor-pointer">
          <XMarkIcon className="w-3 h-3 text-c-light" />
        </button>
      </div>
      <div>
        <span className="text-c-light">期間：</span>
        {query.telUpdatedDateFrom && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {query.telUpdatedDateFrom}
          </span>
        )}
        <span className="text-c-light px-2">~</span>
        {query.telUpdatedDateTo && (
          <span className="text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
            {query.telUpdatedDateTo}
          </span>
        )}
      </div>
      {query.lastTelUserIds.length !== 0 && (
        <div className="flex flex-wrap items-center">
          <span className="text-c-light">コール担当者：</span>
          {users
            .filter((user) => query.lastTelUserIds.includes(user.id))
            .map((user) => (
              <span
                key={user.id}
                className="mr-1.5 mb-1.5 text-sm inline-block border border-c-border rounded-sm bg-c-bg px-2">
                {`${user?.lastName} ${user?.firstName}`}
              </span>
            ))}
        </div>
      )}
    </div>
  );
};
