import {DashboardItem, DashboardItemType} from '../types';
import React from 'react';

import DealChart from './Deal';
import TodoChart from './Todo';

type Props = {
  item: DashboardItem;
};

const chartComponents: {[keys in DashboardItemType]?: React.FC<Props>} = {
  chart_deal: DealChart,
  chart_todo: TodoChart,
};

const DashboardItemChart: React.FC<Props> = ({item}) => {
  const ChartComponent = chartComponents[item.itemType];
  return <ChartComponent item={item} />;
};

export default DashboardItemChart;
