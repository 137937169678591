import React from 'react';
import {
  useSalesforceAccountRecordTypesQuery,
  SalesforceAccountRecordType,
} from 'api';
import {Listbox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';

interface Props {
  selectedRecordType: SalesforceAccountRecordType;
  setSelectedRecordType: (recordType: SalesforceAccountRecordType) => void;
}

export default ({selectedRecordType, setSelectedRecordType}: Props) => {
  const {data: {salesforceAccountRecordTypes = []} = {}} =
    useSalesforceAccountRecordTypesQuery({});

  return (
    <div>
      <div className="text-[#222426] w-96 px-6 text-base">
        レコードタイプ
        <Listbox
          value={selectedRecordType}
          onChange={(value: any) => {
            setSelectedRecordType(value);
          }}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm min-h-[37.59px]">
              <span className="block truncate" />
              {selectedRecordType?.name}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20 cursor-pointer">
                {salesforceAccountRecordTypes.map((recordType) => (
                  <Listbox.Option
                    key={recordType.id}
                    className={({active}) =>
                      `min-h-[37.59px]  relative cursor-pointer select-none p-3 ${
                        active ? 'bg-gray-100' : 'bg-white'
                      }`
                    }
                    value={recordType}>
                    {({selected}) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}>
                          {recordType.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};
