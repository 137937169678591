import React from 'react';

export const MergeModalContext = React.createContext<{
  onClose: () => void;
  pageType: string;
  setPageType: React.Dispatch<React.SetStateAction<string>>;
  mergeFrom: string;
  setMergeFrom: React.Dispatch<React.SetStateAction<string>>;
  mergeTo: string;
  setMergeTo: React.Dispatch<React.SetStateAction<string>>;
}>(null);

const useMergeModalContext = () => {
  const mergeModalContext = React.useContext(MergeModalContext);

  return mergeModalContext;
};

export default useMergeModalContext;
