import {StringParam, useQueryParams} from 'use-query-params';

const searchParamsDef = {
  searchWord: StringParam,
};

const useSearchParams = () => {
  const [query, setQuery] = useQueryParams(searchParamsDef);
  const {searchWord} = query;

  return {
    query,
    setQuery,
    searchWord,
  };
};

export default useSearchParams;
