import {usePreleadQuery} from 'api';
import moment from 'moment';
import React, {useState} from 'react';
import styled from 'styled-components';
import AddPopover from './AddPopover';
import {Button} from 'components/antd';
import {PenWithLine} from 'components/Ui/Icon';
import usePreleadContext from '../../usePreleadContext'

export default () => {
  const {preleadId} = usePreleadContext()

  const {data: {prelead: {preleadProjectReminder} = {}} = {}} = usePreleadQuery(
    {
      variables: {uuid: preleadId},
      skip: !preleadId,
    },
  );

  const [isAddModalVisible, setAddModalVisible] = useState(false);

  return (
    <Container>
      {preleadProjectReminder && (
        <Content>
          <span
            className={
              moment().isSame(moment(preleadProjectReminder.remindAt), 'day')
                ? 'today'
                : ''
            }>
            {moment(preleadProjectReminder.remindAt).format('MM/DD(ddd) HH:mm')}
          </span>
          <span>{preleadProjectReminder.memo}</span>
          <Button type="text" onClick={() => setAddModalVisible(true)}>
            <PenWithLine />
          </Button>
        </Content>
      )}

      <AddPopover
        isModalVisible={isAddModalVisible}
        setModalVisible={setAddModalVisible}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px;

  > span {
    font-size: 12px;
    line-height: 17px;
    color: #495058;
    white-space: break-spaces;

    &.today {
      color: #eb5757;
    }
  }

  > button {
    display: none;
    position: absolute;
    right: 0px;
    top: -2px;
  }

  &:hover {
    background: #f7f7f7;
    border-radius: 4px;

    > button {
      display: inline-block;
    }
  }
`;
