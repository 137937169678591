import gql from 'graphql-tag';

export default gql`
  fragment manualMailCommentProspectActivityFragment on ManualMailCommentProspectActivity {
    id
    stage
    stageText
    recordedAt
    comment
    commentHtml
  }
`;
