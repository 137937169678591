import {GridTable} from 'components/Ui';
import React from 'react';
import styled from 'styled-components';
import Count from './Count';
import useWebsitePageTable from './useWebsitePageTable';
import {Breadcrumb, Container} from 'components/Ui';
import {Link, useParams} from 'react-router-dom';
import {useProjectQuery, useWebsiteByWebsiteTypeQuery} from 'api';
import FilterAction from '../../FilterAction';
import Action from './Action';
import Menu from '../../Menu';
import useProjectPath from 'hooks/useProjectPath';
import {sideMenuWidth} from 'helpers/sideMenu';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {websiteType} = useParams<{
    websiteType: string;
  }>();
  const {createPath} = useProjectPath();
  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });
  const {data: {websiteByWebsiteType: website} = {}} =
    useWebsiteByWebsiteTypeQuery({
      variables: {
        websiteType: websiteType,
      },
    });

  const tableData = useWebsitePageTable();

  return (
    <Container page="newProject">
      <Header>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={createPath('/projects')}>営業リスト一覧</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={createPath(
                `projects/${project?.uuid}/search_condition?status=draft`,
              )}>
              {project?.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={createPath(
                `projects/${project?.uuid}/search/website/single`,
              )}>
              メディア一覧
            </Link>
          </Breadcrumb.Item>
          {website && (
            <Breadcrumb.Item>
              <span className="current">{website.name}</span>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </Header>
      <ActionWrapper>
        <Menu />
        <FilterAction />
        <Action />
      </ActionWrapper>
      <Body>
        <GridTable {...tableData} />
        <Count />
      </Body>
    </Container>
  );
};

const Header = styled.div`
  padding-left: 32px;
  border-bottom: 1px solid #e1e6eb;
`;

const ActionWrapper = styled.div`
  border-bottom: 1px solid #e1e6eb;
  display: flex;
  padding-left: 8px;
  background: #fff;
`;

const Body = styled.div`
  display: flex;
  width: calc(100vw - ${sideMenuWidth}px);
  background: #f3f5f7;
`;
