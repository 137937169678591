import React from 'react';
import {Task} from 'api';
import styled from 'styled-components';
import {Row} from 'react-table';
import {TaskTel, TaskMail, TaskOther} from 'components/Ui/Icon';
import TaskStatusIcon from 'components/Task/Icon/TaskStatusIcon';

interface Props {
  row: Row<Task>;
}

const Cell = ({row}: Props) => {
  const task = row.original;

  const actionIcon = () => {
    switch (task.taskType) {
      case 'tel':
        return <TaskTel />;
      case 'mail':
        return <TaskMail />;
      case 'other':
        return <TaskOther />;
    }
  };

  return (
    <Container>
      <TaskStatusIcon status={task.status} />
      {actionIcon()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  svg:first-child {
    margin-right: 10px;
  }
`;

export default Cell;
