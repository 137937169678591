import React from 'react';
import useSearchParams from '../../../../../useSearchParams';
import PlusIcon from './PlusIcon';
import CustomizeItemField from './CustomizeItemField';
import Close from './Close';
import CustomizeItemSelectOption from './CustomizeItemSelectOption';
import CustomizeItemMultiSelectOption from './CustomizeItemMultiSelectOption';

const FormFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="text-c-base font-bold">条件項目</div>
        <div className="flex flex-col gap-5">
          {query?.salesforceDealSearchConditions?.map(
            (customizeItem: any, index: number) => {
              return (
                <div key={index} className="flex gap-3">
                  <CustomizeItemField
                    salesforceDealFieldId={
                      customizeItem['salesforce_deal_field_id']
                    }
                    index={index}
                  />
                  {customizeItem['field_type'] === 'select' && (
                    <CustomizeItemSelectOption
                      salesforceDealFieldId={
                        customizeItem['salesforce_deal_field_id']
                      }
                      customizeItemValue={
                        customizeItem['field_select_option_id']
                      }
                      index={index}
                    />
                  )}
                  {customizeItem['field_type'] === 'multi_select' && (
                    <CustomizeItemMultiSelectOption
                      salesforceDealFieldId={
                        customizeItem['salesforce_deal_field_id']
                      }
                      customizeItemValues={customizeItem['values'] || []}
                      index={index}
                    />
                  )}
                  <Close index={index} />
                </div>
              );
            },
          )}
        </div>
        <button
          className="h-7 w-28 text-center rounded bg-white border border-[#E2E6EA] flex justify-center items-center"
          disabled={!query.salesforceDealRecordTypeId}
          onClick={() => {
            setQuery({
              ...query,
              salesforceDealSearchConditions: [
                ...query.salesforceDealSearchConditions,
                {},
              ],
            });
          }}>
          <PlusIcon /> <span className="text-[#899098]">条件を追加</span>
        </button>
      </div>
    </>
  );
};

export default FormFilter;
