import React from 'react';
import useCondition from '../../../useCondition';
import SearchCondition from './SearchCondition';

export default () => {
  const {condition} = useCondition();

  if (!(condition.hubspotDealSearchConditions?.length > 0)) {
    return null;
  }

  return (
    <div className="pb-4 border-b border-c-border flex flex-col gap-2 last-of-type:border-none">
      <div className="flex items-center gap-1">
        <h4 className="m-0 font-bold">取引</h4>
      </div>
      {condition.hubspotDealSearchConditions?.length > 0 &&
        condition.hubspotDealSearchConditions?.map((searchCondition, i) => (
          <SearchCondition key={i} searchCondition={searchCondition} />
        ))}
    </div>
  );
};
