import React from 'react';
import styled from 'styled-components';
import {Form, Button, Input} from 'components/antd';
import {FormikProps} from 'formik';
import {
  useCreateContentMailFormMutation,
  Content,
  ContentMailFormAttributes,
} from 'api';
import {useHistory} from 'react-router-dom';

interface Props {
  content: Content;
  formik: FormikProps<ContentMailFormAttributes>;
}

export default ({formik, content}: Props) => {
  const history = useHistory();
  const [title, setTitle] = React.useState(`(コピー) ${content?.title}`);
  const [create, {loading}] = useCreateContentMailFormMutation({
    variables: {title: title, attributes: formik.values},
    onCompleted: ({createContentMailForm: {content}}) =>
      history.push(`/garage/contents/${content.uuid}/mail_form`),
  });

  return (
    <Container>
      <h3>コピーして新規作成</h3>
      <Form layout="vertical" onFinish={() => create()}>
        <Form.Item label="コンテンツ名">
          <Input
            size="large"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>
        <Action>
          <Button
            type="primary"
            loading={loading}
            disabled={title?.length === 0}
            htmlType="submit">
            コピー
          </Button>
        </Action>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  background: white;
  width: 360px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    margin-left: 10px;
  }
`;
