import React, {useState} from 'react';
import styled from 'styled-components';
import BasicInformation from './BasicInformation';
import Jobs from './Jobs';
import PressRelease from './PressRelease';
import Fiscal from './Fiscal';

type Props = {
  companyId: string;
  height: string;
};

export default ({companyId, height}: Props) => {
  if (!companyId) return null;

  const [information, setInformation] = useState('basicInformation');

  return (
    <Container height={height}>
      <Tabs>
        <TabItem
          active={information === 'basicInformation'}
          onClick={() => {
            setInformation('basicInformation');
          }}>
          企業情報
        </TabItem>
        <TabItem
          active={information === 'jobs'}
          onClick={() => {
            setInformation('jobs');
          }}>
          求人情報
        </TabItem>
        <TabItem
          active={information === 'pressrelease'}
          onClick={() => {
            setInformation('pressrelease');
          }}>
          プレスリリース
        </TabItem>
        <TabItem
          active={information === 'fiscal'}
          onClick={() => {
            setInformation('fiscal');
          }}>
          決算
        </TabItem>
      </Tabs>
      <ContentWrapper>
        {information === 'basicInformation' && (
          <BasicInformation companyId={companyId} />
        )}
        {information === 'jobs' && <Jobs companyId={companyId} />}
        {information === 'pressrelease' && (
          <PressRelease companyId={companyId} />
        )}
        {information === 'fiscal' && <Fiscal companyId={companyId} />}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div<{height?: string}>`
  width: 400px;
  height: ${({height}) => (height ? height : '')};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

const Tabs = styled.div`
  display: flex;
  height: 37px;
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
`;

const TabItem = styled.div<{active?: boolean}>`
  flex-grow: 1;
  text-align: center;
  background: ${({active}) => (active ? '#ffffff' : '#f3f5f7')};

  cursor: pointer;
  line-height: 37px;
  font-size: 12px;
  font-weight: bold;
  color: #bdc4cb;
`;

const ContentWrapper = styled.div`
  padding: 32px 30px 0 30px;
  height: 650px;
`;
