import React from 'react';
import styled from 'styled-components';
import Filter from './Filter';
import Content from './Content';
import useSearchParams from './useSearchParams';
import {useCurrentUserQuery, useUsersByClientAllQuery} from 'api';
import {RecoilRoot} from 'recoil';

export default () => {
  const [filterVisible, setFilterVisible] = React.useState(false);

  const toggleFilter = () => setFilterVisible(!filterVisible);

  const {setQuery} = useSearchParams();

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  React.useEffect(() => {
    setQuery({
      page: 1,
      assignees: [users.find((user) => user.id === currentUser.id)?.id],
    });
  }, [users, currentUser]);

  return (
    <Container>
      <h1 className="text-2xl font-bold mb-4">アクション</h1>
      <Main>
        <RecoilRoot>
          <Content toggleFilter={toggleFilter} />
        </RecoilRoot>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow: scroll;
`;

const Main = styled.div`
  display: flex;
`;
