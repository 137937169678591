import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useParams} from 'react-router-dom';
import {useCreateAccountGroupMutation} from 'api';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const NewModal = ({isOpen, closeModal}: Props) => {
  const {poolId} = useParams<{poolId: string}>();
  const [name, setName] = React.useState('');

  const [create, {loading}] = useCreateAccountGroupMutation({
    refetchQueries: ['accountGroups'],
    onCompleted: () => {
      closeModal();
    },
  });

  const onClick = () => {
    create({
      variables: {
        uuid: poolId,
        name: name,
      },
    });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-xl transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h4"
                  className="flex items-center px-8 py-2 bg-c-bg text-c-base rounded-t-lg">
                  リストを新規作成
                </Dialog.Title>
                <form className="px-8 pt-6 pb-8">
                  <div className="mb-4">
                    <label className="block text-c-light text-sm mb-2">
                      リスト名
                    </label>
                    <input
                      className="block py-2 px-4 text-sm border border-c-border rounded bg-white w-full focus:outline-none"
                      type="text"
                      placeholder="新規リスト名"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center justify-end">
                    <button
                      disabled={loading || !name?.length}
                      className="bg-c-primary hover:opacity-50 rounded text-white font-bold py-2 px-4 w-24 disabled:bg-c-bg disabled:text-c-light transition-all"
                      type="button"
                      onClick={() => onClick()}>
                      作成
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewModal;
