import React from 'react';
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/20/solid';
import {Cog6ToothIcon} from '@heroicons/react/24/outline';

type Props = {
  title: string;
  subTitle?: string;
  tags?: string[];
  children: React.ReactNode;
};

const DashboardItemContainer = ({
  title,
  subTitle,
  tags = [],
  children,
}: Props) => {
  return (
    <>
      <div className="mb-4 flex flex-col gap-2">
        <div className="flex items-center gap-2 text-lg relative">
          <h3 className="m-0 text-lg leading-6">{title}</h3>
          {subTitle && (
            <>
              <div className="text-c-lighter leading-6">|</div>
              <div className="leading-6">{subTitle}</div>
            </>
          )}
          <ArrowTopRightOnSquareIcon className="w-4 h-4 text-c-light" />
          <div className="flex-1" />
          <div className="text-c-light border border-c-border text-sm px-2 rounded-sm">
            目標
          </div>
          <Cog6ToothIcon className="w-4 h-4 text-c-light" />
        </div>
        {tags.length > 0 && (
          <div className="flex items-cener gap-2">
            {tags.map((tag) => (
              <div key={tag} className="bg-c-bg px-2 text-xs rounded-sm">
                <span className="text-c-light">{tag}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {children}
    </>
  );
};

export default DashboardItemContainer;
