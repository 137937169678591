import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';

const TelTermFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {telTerm} = query;
  const {RangePicker} = DatePicker;
  const format = 'YYYY-MM-DD';

  return (
    <RangePicker
      format={format}
      placeholder={['開始日', '終了日']}
      value={
        telTerm?.from && telTerm?.to
          ? [moment(telTerm.from, format), moment(telTerm.to, format)]
          : null
      }
      onChange={(value) => {
        if (value) {
          setQuery({
            telTerm: {
              from: value[0].format(format),
              to: value[1].format(format),
            },
          });
        } else {
          setQuery({
            telTerm: null,
          });
        }
      }}
    />
  );
};

export default TelTermFilter;
