import React from 'react';
import styled from 'styled-components';
import {Switch} from 'components/antd';
import {
  ContentMailFormUrl,
  ContentMailFormVersionUrl,
  useUpdateContentMailFormUrlMutation,
} from 'api';

interface Props {
  url: ContentMailFormUrl | ContentMailFormVersionUrl;
}

export default ({url}: Props) => {
  const [update] = useUpdateContentMailFormUrlMutation();
  const onChange = React.useCallback(
    (checked: boolean) =>
      update({
        variables: {id: url.uuid, attributes: {isConvertUrl: checked}},
      }),
    [],
  );

  return (
    <Container>
      <Content>
        <a href={url.url} target="_blank">
          {url.url}
        </a>
      </Content>
      <Switch size="small" checked={url.isConvertUrl} onChange={onChange} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-top: solid 1px #e1e6eb;
`;

const Content = styled.div`
  flex: 1;
  overflow-wrap: anywhere;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
`;
