import React from 'react';
import {useRecoilState} from 'recoil';
import checkedPreleadProjectsAtom from '../checkedPreleadProjectsAtom';
import {PreleadProject} from 'api';

interface Props {
  row: any;
}

const CheckCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const [checkedPreleadProjects, setCheckedPreleadProjects] = useRecoilState(
    checkedPreleadProjectsAtom,
  );

  const checked = React.useMemo(
    () => checkedPreleadProjects.includes(preleadProject.uuid),
    [checkedPreleadProjects],
  );

  const checkPreleadProject = React.useCallback(() => {
    if (checkedPreleadProjects.includes(preleadProject.uuid)) {
      setCheckedPreleadProjects(
        checkedPreleadProjects.filter((id) => id !== preleadProject.uuid),
      );
    } else {
      setCheckedPreleadProjects([
        ...checkedPreleadProjects,
        preleadProject.uuid,
      ]);
    }
  }, [checkedPreleadProjects]);

  return (
    <input type="checkbox" checked={checked} onChange={checkPreleadProject} />
  );
};

export default CheckCell;
