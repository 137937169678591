import React, {useEffect, useState, useMemo} from 'react';
import {Quill, QuillWrapper} from 'components/Ui';
import {Button} from 'components/antd';
import styled from 'styled-components';
import _ from 'lodash';
import {
  usePreleadQuery,
  useGetPreleadProjectsQuery,
  useCreateCommentActivityMutation,
} from 'api';
import TimelineItem from 'components/TimelineItem';
import {SelectProject} from '../SelectProject';
import usePreleadContext from '../usePreleadContext';
import {Refresh} from 'components/Ui/Icon';

export default () => {
  const {preleadId} = usePreleadContext();
  const [preleadProjectId, setPreleadProjectId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [comment, setComment] = useState('');
  const [bodyPlainText, setBodyPlainText] = useState(null);

  if (!preleadId) return null;

  const {
    data: {prelead = {}} = {},
    refetch,
    loading: loadingPrelead,
  } = usePreleadQuery({
    variables: {uuid: preleadId},
    fetchPolicy: 'cache-and-network',
    skip: !preleadId,
  });

  const [createCommentActivity, {loading}] = useCreateCommentActivityMutation({
    onCompleted: () => {
      setComment('');
      setBodyPlainText(null);
      refetch();
    },
  });

  const addComment = () =>
    createCommentActivity({
      variables: {
        uuid: preleadProjectId,
        attributes: {body: comment, bodyPlainText: bodyPlainText},
      },
    });

  const {data: {prelead: {preleadProjects = [], projects = []} = {}} = {}} =
    useGetPreleadProjectsQuery({
      variables: {uuid: preleadId},
      skip: !preleadId,
    });

  const uniqProjects = useMemo(() => _.uniq(projects), [projects]);

  const handleSelectProject = (selectedProjectId: string) => {
    if (!selectedProjectId) return;

    const preleadProject = preleadProjects.find(
      (preleadProject) => preleadProject.projectId === selectedProjectId,
    );

    if (!preleadProject) return;

    setPreleadProjectId(preleadProject.uuid);
    setProjectId(selectedProjectId);
  };

  useEffect(() => {
    if (prelead?.lastProjectId) {
      handleSelectProject(prelead.lastProjectId);
    }
  }, [prelead?.id, preleadProjects]);

  if (!preleadId || !prelead) return null;

  return (
    <Container>
      <Actions>
        <Button
          onClick={() => refetch()}
          icon={<Refresh spin={loadingPrelead} />}
          disabled={loadingPrelead}
        />
      </Actions>
      <SelectProject
        setProjectId={handleSelectProject}
        projects={uniqProjects}
        value={projectId}
      />
      <QuillWrapper>
        <Quill
          placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
          theme="snow"
          value={comment}
          onChange={(content, delta, source, editor) => {
            setComment(content);
            setBodyPlainText(editor.getText());
          }}
          modules={{
            toolbar: [
              [{header: [1, 2, 3, false]}],
              ['bold', 'italic', 'underline'],
              ['code-block'],
              ['link'],
            ],
          }}
          formats={[
            'header',
            'list',
            'bold',
            'italic',
            'underline',
            'strike',
            'color',
            'background',
            'code-block',
            'link',
          ]}
          bounds={'#quill-container'}
        />
        <div id="quill-container" />
        <div>
          <div />
          <Button
            disabled={
              !preleadProjectId ||
              !comment.replace(/<\/?[^>]+(>|$)/g, '') ||
              loading
            }
            onClick={addComment}>
            登録
          </Button>
        </div>
      </QuillWrapper>
      {prelead.activities?.map((activity) => (
        <TimelineItem key={activity.uuid} activity={activity} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
