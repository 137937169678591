import React from 'react';

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0427 0H0V2.37805H17.0427V0Z" fill="currentColor" />
    <path
      d="M0 7.13456H7.37195V13.0004H9.75V7.13456H17.0427V4.67725H0V7.13456Z"
      fill="currentColor"
    />
  </svg>
);
