import preleadCategoryColor from 'helpers/preleadCategoryColor';

const preleadStatusCategoryOptions = [
  {
    text: 'アプローチ中',
    value: 'approaching',
    color: preleadCategoryColor('approaching'),
    isImmutable: false,
  },
  {
    text: 'アポイント',
    value: 'appoint',
    color: preleadCategoryColor('appoint'),
    isImmutable: false,
  },
  {
    text: '問い合わせ',
    value: 'inquiry',
    color: preleadCategoryColor('inquiry'),
    isImmutable: false,
  },
  {
    text: 'お断り',
    value: 'refusal',
    color: preleadCategoryColor('refusal'),
    isImmutable: false,
  },
  {
    text: 'ブロック',
    value: 'block',
    color: preleadCategoryColor('block'),
    isImmutable: true,
  },
  {
    text: 'リード',
    value: 'prospect',
    color: preleadCategoryColor('prospect'),
    isImmutable: true,
  },
];

export {preleadStatusCategoryOptions};
