import React from 'react';

const Icon = () => (
  <svg
    width="34"
    height="28"
    viewBox="0 0 34 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.25"
      y="1.25"
      width="31.5"
      height="11.5"
      rx="5.75"
      stroke="#899098"
      strokeWidth="2.5"
    />
    <g clipPath="url(#clip0_32468_176275)">
      <path
        d="M29.6145 16.665L17.249 7.75586L18.7818 22.9193L22.8084 17.3849L29.6145 16.665Z"
        fill="white"
        stroke="#899098"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4365 18.4727L27.6865 25.8339"
        stroke="#899098"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_32468_176275">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(15.6582 5) rotate(15)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
