import React from 'react';
import useCondition from '../../../useCondition';
import {useSalesforceLeadRecordTypeByRecordTypeIdQuery} from 'api';
import {XMarkIcon} from '@heroicons/react/20/solid';
import SelectOption from './SelectOption';

export type SelectSearchCondition = {
  field_select_option_id?: string;
  field_type: string;
  lead_field_id: string;
};

export type CheckboxSearchCondition = {
  value?: string;
  field_type: string;
  lead_field_id: string;
};

type SearchCondition = SelectSearchCondition | CheckboxSearchCondition;

interface Props {
  searchCondition: SearchCondition;
  setSearchCondition: (condition: SearchCondition) => void;
  onDelete: () => void;
  index: number;
}

const SalesforceLeadConditionField = ({
  searchCondition,
  setSearchCondition,
  onDelete,
}: Props) => {
  const {condition} = useCondition();

  const {
    data: {
      salesforceLeadRecordTypeByRecordTypeId: {salesforceLeadFields = []} = {},
    } = {},
  } = useSalesforceLeadRecordTypeByRecordTypeIdQuery({
    variables: {
      salesforceLeadRecordTypeId: condition?.salesforceLeadRecordTypeId,
    },
    skip: !condition.salesforceLeadRecordTypeId,
  });

  const targetFields = salesforceLeadFields
    .filter(
      (item) =>
        (item.fieldType === 'select' || item.fieldType === 'checkbox') &&
        item.fieldName.endsWith('__c'),
    )
    .filter(
      (item) =>
        !condition.salesforceLeadSearchConditions?.some(
          (cond) =>
            cond.lead_field_id === item.id &&
            searchCondition.lead_field_id !== item.id,
        ),
    );

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const targetField = targetFields.find((item) => item.id === e.target.value);

    if (!targetField) {
      setSearchCondition({
        field_select_option_id: '',
        field_type: '',
        lead_field_id: '',
      });
      return;
    }

    if (targetField.fieldType === 'select') {
      setSearchCondition({
        field_select_option_id: '',
        field_type: targetField.fieldType,
        lead_field_id: targetField.id,
      });
    }

    if (targetField.fieldType === 'checkbox') {
      setSearchCondition({
        value: '',
        field_type: targetField.fieldType,
        lead_field_id: targetField.id,
      });
    }
  };

  return (
    <div className="flex gap-2">
      <select
        className="form-select border-c-border rounded text-base w-64 h-[44px]"
        onChange={onChange}
        value={searchCondition.lead_field_id}>
        <option value="" />
        {targetFields.map((field) => (
          <option value={field.id} key={field.id}>
            {field.label}
          </option>
        ))}
      </select>
      {searchCondition.field_type === 'select' && (
        <SelectOption
          fieldId={searchCondition.lead_field_id}
          value={
            (searchCondition as SelectSearchCondition).field_select_option_id
          }
          onChange={(value) =>
            setSearchCondition({
              ...searchCondition,
              field_select_option_id: value,
            })
          }
        />
      )}
      {searchCondition.field_type === 'checkbox' && (
        <select
          className="form-select border-c-border rounded text-base flex-1 h-[44px]"
          onChange={(e) =>
            setSearchCondition({...searchCondition, value: e.target.value})
          }
          value={(searchCondition as CheckboxSearchCondition).value}>
          <option value="" />
          {[
            {
              label: 'あり',
              value: 'true',
            },
            {
              label: 'なし',
              value: 'false',
            },
          ].map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
      <button
        onClick={onDelete}
        className="bg-white px-0 py-6 h-5 w-5 flex items-center justify-center rounded-full cursor-pointer">
        <XMarkIcon className="w-5 h-5 text-c-light" />
      </button>
    </div>
  );
};

export default SalesforceLeadConditionField;
