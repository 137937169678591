import React from 'react';
import {Route, Switch, Redirect} from 'react-router';
import Forms from './forms';
import CollectedFormUrls from './collected_form_urls';
import TrackingCode from './tracking_code';

export default () => (
  <Switch>
    <Route path={'/form/forms'} component={Forms} />
    <Route path={'/form/collected_form_urls'} component={CollectedFormUrls} />
    <Route path={'/form/tracking_code'} component={TrackingCode} />
    <Redirect to={`/form/collected_form_urls`} />
  </Switch>
);
