import {
  City,
  useProjectWithSearchConditionQuery,
  usePrefectureTypesQuery,
} from 'api';
import {Map} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleA,
  TagBox,
  TagItem,
} from './styles';
import {regions} from 'helpers/regions';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = {}} = {}} = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });
  const {projectSearchCondition} = project;
  if (!projectSearchCondition) return null;

  const {data: {prefectureTypes = []} = {}} = usePrefectureTypesQuery({
    variables: {
      search: {
        prefectureTypeIds: [
          ...projectSearchCondition?.prefectureTypeIds,
          ...new Set(
            projectSearchCondition?.cities?.map(
              (city) => city.prefectureTypeId,
            ),
          ),
        ],
      },
    },
  });

  const allPrefecturetypeNames = [].concat(
    ...regions?.map((region) => region.prefectureTypes),
  );

  return (
    <Container>
      {(project.isCity || project.isPrefectureType) && (
        <Category>
          <CategoryTitle>
            <Map />
            エリア
          </CategoryTitle>
          {allPrefecturetypeNames.map((prefectureTypeName) =>
            prefectureTypes?.map((prefectureType) => {
              if (prefectureTypeName === prefectureType.name) {
                return (
                  <ChildCategory key={prefectureType.id}>
                    <ChildCategoryTitleA>
                      {prefectureType.name}
                    </ChildCategoryTitleA>
                    <TagBox>
                      {projectSearchCondition.cities.map(
                        (city: City) =>
                          prefectureType.id === city.prefectureTypeId && (
                            <TagItem key={city.id}>{city.name}</TagItem>
                          ),
                      )}
                    </TagBox>
                  </ChildCategory>
                );
              }
            }),
          )}
        </Category>
      )}
    </Container>
  );
};
