import React from 'react';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  FormBlock,
  FormBlockFieldOther,
  useUpdateFormBlockFieldOtherMutation,
} from 'api';
import {useForm} from 'react-hook-form';
import debounce from 'helpers/debounce';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const [update] = useUpdateFormBlockFieldOtherMutation();

  const {register, watch, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().required(),
        placeholder: Yup.string(),
        helpText: Yup.string(),
        isRequired: Yup.boolean().required(),
      }),
    ),
    defaultValues: {
      title: block.formBlockable.title,
      placeholder: block.formBlockable.placeholder || '',
      helpText: block.formBlockable.helpText || '',
      isRequired: block.formBlockable.isRequired,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: any) => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-bold">{block.formBlockable.title}の編集</h2>
      <div className="flex flex-col w-full gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          タイトル
        </label>
        <input
          type="text"
          className="form-input rounded border-c-border text-base"
          {...register('title')}
        />
      </div>
      <div className="flex flex-col w-full gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          入力補助文言
        </label>
        <input
          type="text"
          className="form-input rounded border-c-border text-base"
          {...register('placeholder')}
        />
      </div>
      <div className="flex flex-col w-full gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          補足説明
        </label>
        <textarea
          className="form-textarea rounded border-c-border text-base"
          {...register('helpText')}
        />
      </div>
      <div className="flex flex-col w-full gap-1">
        <label className="tracking-wide font-medium text-base text-c-light">
          チェック項目
        </label>
        <label className="flex items-center text-base">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 rounded border-c-border"
            {...register('isRequired')}
          />
          <span className="ml-2">この項目を必須にする</span>
        </label>
      </div>
    </div>
  );
};
