import {
  useCurrentClientQuery,
  useCurrentUserQuery,
  useProjectProgressQuery,
  useProjectSummaryQuery,
} from 'api';
import {Setting} from 'components/Ui/Icon';
import useClientUser from 'hooks/useClientUser';
import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import ReflectModal from './ReflectModal';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const [isModalVisible, setModalVisible] = React.useState(false);
  const {isMember} = useClientUser();
  const {
    data: {
      project: {blockGroups = [], blockGroupLastAppliedAt = null} = {},
    } = {},
  } = useProjectSummaryQuery({
    variables: {uuid: projectId},
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      project,
      project: {
        importPreleadProjectCopy = {},
        importTransferedProject = {},
        lastApplyMultipleBlockGroup = {},
      } = {},
    } = {},
  } = useProjectProgressQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const isCreating = React.useMemo(
    () =>
      project?.preleadCreateStatus === 'progress' ||
      (importPreleadProjectCopy &&
        importPreleadProjectCopy.status !== 'success') ||
      (importTransferedProject &&
        importTransferedProject.status !== 'success') ||
      (lastApplyMultipleBlockGroup &&
        lastApplyMultipleBlockGroup.status !== 'success'),
    [project, lastApplyMultipleBlockGroup, lastApplyMultipleBlockGroup],
  );

  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();

  if (!project) return null;

  return (
    <Container>
      <TitleBox>
        <Title>ブロックグループ</Title>
        {project.status === 'open' &&
          !isCreating && isMember &&
          (currentClient.canApplyBlockGroup || currentUser.role === 'admin' ? (
            <AddButton onClick={() => setModalVisible(true)}>
              <Setting />
            </AddButton>
          ) : null)}
        <ReflectModal
          visible={isModalVisible}
          onClose={() => setModalVisible(false)}
        />
      </TitleBox>

      {blockGroups.length > 0 && (
        <>
          <RecordBox>
            <UpdatedAt>
              {blockGroupLastAppliedAt &&
                moment(blockGroupLastAppliedAt).format('YYYY/MM/DD HH:mm')}
            </UpdatedAt>
            <CountBadge>{blockGroups.length}</CountBadge>
          </RecordBox>

          <ItemContainer>
            {blockGroups.map((blockGroup) => (
              <Item key={blockGroup.uuid}>{blockGroup.name}</Item>
            ))}
          </ItemContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div``;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #bdc4cb;
`;

const AddButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    stroke: #899098;
  }
`;

const RecordBox = styled.div`
  margin-top: 13px;
  display: flex;
  align-items: center;
`;

const UpdatedAt = styled.span`
  margin-left: 8px;
  font-size: 10px;
  line-height: 14px;
  color: #899098;
`;

const CountBadge = styled.span`
  margin-left: 10px;
  background: #ff7d7d;
  color: white;
  font-size: 9px;
  border-radius: 20px;
  height: 13px;
  width: 19px;
  line-height: 12px;
  text-align: center;
`;

const ItemContainer = styled.div`
  margin-top: 9px;
`;

const Item = styled.span`
  margin: 0 6px 6px 0;
  display: inline-block;
  background: #ff7d7d;
  font-size: 9px;
  color: white;
  border-radius: 2px;
  padding: 0px 7px;
`;
