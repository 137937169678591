import React from 'react';
import {Filter} from 'components/Ui/Icon';
import Modal from './Modal';
import useSearchParams from '../useSearchParams';

export default () => {
  const [visible, setVisible] = React.useState(false);
  const {query} = useSearchParams();

  const numberOfApproachFilersInUse = [
    query.assignees?.length > 0,
    query.types?.length > 0,
    query.workflowIds?.length > 0,
  ].filter(Boolean).length;

  const numberOfFiltersInUse = numberOfApproachFilersInUse;

  return (
    <>
      <div className="flex items-center gap-4">
        <button
          onClick={() => setVisible(true)}
          className="relative border border-c-border rounded w-9 h-9 bg-white text-c-lighti cursor-pointer flex items-center justify-center">
          <Filter color={numberOfFiltersInUse > 0 ? '#68B5FB' : '#BDC4CB'} />
          {numberOfFiltersInUse > 0 && (
            <span className="absolute right-[-5px] top-[-5px] rounded bg-c-primary min-w-[14px] h-[14px] flex items-center justify-center text-xs text-white">
              {numberOfFiltersInUse}
            </span>
          )}
        </button>
        {numberOfApproachFilersInUse > 0 && (
          <div className="flex items-center gap-1">
            <span className="text-sm">ワークフロー</span>
            <span className="text-xs bg-[#DDEFFF] text-c-primary rounded-full h-4 min-w-[16px] flex items-center justify-center">
              {numberOfApproachFilersInUse}
            </span>
          </div>
        )}
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
