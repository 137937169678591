import React from 'react';
import {FormBlock, FormPost, FormBlockFieldOther} from 'api';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldOther;
}

interface Props {
  block: Block;
  post: FormPost;
}

export default ({block, post}: Props) => {
  const blockable = block.formBlockable;
  const label = blockable.title;
  const value = post.formPostOtherSelects
    .find((item) => item.formBlockFieldOtherId === block.formBlockable.id)
    ?.formBlockFieldOtherOptions.map((option) => option.label)
    .join(', ');

  return (
    <div className="w-full flex flex-col gap-1">
      <label className="text-c-light text-sm m-0">{label}</label>
      <p className="m-0 text-base">{value}</p>
    </div>
  );
};
