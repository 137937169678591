import gql from 'graphql-tag';

export default gql`
  fragment projectColumnFragment on ProjectColumn {
    id
    title
    dataType
    dataTypeText
    isDisplay
    dataColumnKey
    csvHeaderName
    clientPreleadCustomizeItemId
    projectGroupPreleadCustomizeItemId
  }
`;
