import React from 'react';
import {Prelead, PreleadProject} from 'api';
import styled from 'styled-components';
import moment from 'moment';
import {Row} from 'react-table';

interface Props {
  row: Row<PreleadProject>;
}

const LatestRemindCell = ({row}: Props) => {
  const prelead: Prelead = row.original.prelead;

  return (
    <RemindAt
      isToday={
        prelead.remindAt && moment().isSame(moment(prelead.remindAt), 'day')
      }>
      {prelead.remindAt && moment(prelead.remindAt).format('MM/DD(ddd) HH:mm')}
    </RemindAt>
  );
};

export default LatestRemindCell;

const RemindAt = styled.span<{isToday: boolean}>`
  ${({isToday}) => isToday && 'color: #EB5757'};
`;
