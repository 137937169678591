import React from 'react';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  FormBlock,
  FormBlockFieldCustomizeItem,
  FormBlockFieldCustomizeItemAttributes,
  useUpdateFormBlockFieldCustomizeItemMutation,
} from 'api';
import {useForm} from 'react-hook-form';
import debounce from 'helpers/debounce';

interface Block extends FormBlock {
  formBlockable: FormBlockFieldCustomizeItem;
}

interface Props {
  block: Block;
}

export default ({block}: Props) => {
  const [update] = useUpdateFormBlockFieldCustomizeItemMutation();

  const {register, watch, handleSubmit} = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        isRequired: Yup.boolean().required(),
      }),
    ),
    defaultValues: {
      isRequired: block.formBlockable.isRequired,
    },
    mode: 'onChange',
  });

  const onSubmit = debounce((data: FormBlockFieldCustomizeItemAttributes) => {
    update({
      variables: {
        uuid: block.uuid,
        attributes: data,
      },
    });
  }, 500);

  React.useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-lg font-bold">
        {block.formBlockable.poolProspectCustomizeItem.name}の編集
      </h2>
      <label className="tracking-wide font-medium text-base text-c-light">
        チェック項目
      </label>
      <label className="flex items-center text-base">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 rounded border-c-border"
          {...register('isRequired')}
        />
        <span className="ml-2">この項目を必須にする</span>
      </label>
    </div>
  );
};
