import React from 'react';
import {Task} from 'api';
import {
  PhoneIcon,
  EnvelopeIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';

interface Props {
  task: Task;
}

const TaskType = ({task}: Props) => {
  switch (task.taskType) {
    case 'tel':
      return (
        <div className="flex items-center gap-1 rounded-sm text-[#0DC8F1] bg-[#DCF9FF] h-5 text-xs px-2">
          <PhoneIcon className="w-3" />
          {task.taskTypeText}
        </div>
      );
    case 'mail':
      return (
        <div className="flex items-center gap-1 rounded-sm text-[#2693FF] bg-[#D7EBFF] h-5 text-xs px-2">
          <EnvelopeIcon className="w-3" />
          {task.taskTypeText}
        </div>
      );
    case 'other':
      return (
        <div className="flex items-center gap-1 rounded-sm text-[#8966EF] bg-[#dbd1fa] h-5 text-xs px-2">
          <DocumentTextIcon className="w-3" />
          {task.taskTypeText}
        </div>
      );
  }
};

export default TaskType;
