import gql from 'graphql-tag';
import formPostFragment from '../form/post';

export default gql`
  fragment formPostProspectActivityFragment on FormPostProspectActivity {
    formPost {
      ...formPostFragment
    }
  }
  ${formPostFragment}
`;
