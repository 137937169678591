import gql from 'graphql-tag';

export default gql`
  fragment mailCommentProspectActivityFragment on MailCommentProspectActivity {
    id
    comment
    commentHtml
    createdAt
  }
`;
