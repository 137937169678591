import gql from 'graphql-tag';

export default gql`
  fragment prospectTagFragment on ProspectTag {
    id
    uuid
    name
    color
    poolId
  }
`;
