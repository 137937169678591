import React from 'react';
import styled from 'styled-components';
import {useTaskQuery} from 'api';
import {useParams} from 'react-router-dom';
import Status from './Status';
import Priority from './Priority';
import Assignee from './Assignee';
import Title from './Title';
import DueDate from './DueDate';
import RemindAt from './RemindAt';
import Description from './Description';

export default () => {
  const {taskId} = useParams<{taskId: string}>();
  const {data: {task} = {}} = useTaskQuery({
    variables: {uuid: taskId},
    skip: !taskId,
  });

  if (!task) return null;

  return (
    <Container>
      <h3>ToDo詳細</h3>

      <Item>
        <div>ステータス</div>
        <div>
          <Status task={task} />
        </div>
      </Item>
      <Item>
        <div>優先度</div>
        <div>
          <Priority task={task} />
        </div>
      </Item>
      <Item>
        <div>タイトル</div>
        <div>
          <Title task={task} />
        </div>
      </Item>
      <Item>
        <div>担当者</div>
        <div>
          <Assignee task={task} />
        </div>
      </Item>
      <Item>
        <div>期日</div>
        <div>
          <DueDate task={task} />
        </div>
      </Item>
      <Item>
        <div>リマインド</div>
        <div>
          <RemindAt task={task} />
        </div>
      </Item>
      <Item>
        <div>リード名</div>
        <div style={{marginLeft: '11px'}}>
          {[task.prospect.lastName, task.prospect.firstName].join(' ')}
        </div>
      </Item>
      <Item>
        <div>企業名</div>
        <div style={{marginLeft: '11px'}}>{task.account.name}</div>
      </Item>
      <Item>
        <div>説明</div>
        <div>
          <Description task={task} />
        </div>
      </Item>
    </Container>
  );
};

const Container = styled.div`
  padding: 30px;
  overflow: scroll;
  padding: 15px 30px;

  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 1rem;
  }

  &&& {
    .ant-select-selector,
    .ant-input,
    .ant-picker {
      border: 1px solid transparent;
      border-radius: 4px;

      &:hover {
        border: 1px solid #bdc4cb;
      }
    }

    .ant-select-arrow,
    .ant-picker-suffix {
      display: none;
    }

    .ant-select,
    .ant-input,
    .ant-picker {
      width: 100%;
    }

    textarea {
      resize: none;

      &:hover,
      &:focus {
        resize: vertical;
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  min-height: 32px;

  > div:first-child {
    color: #899098;
    width: 90px;
  }
  > div:last-child {
    flex: 1;
  }
`;
