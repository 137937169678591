import {Account, useUpdateAccountMutation} from 'api';
import React, {useEffect, useRef, useState} from 'react';

interface Props {
  account: Account;
}

export default ({account}: Props) => {
  const [updateAccount] = useUpdateAccountMutation({});
  const [employeeNumber, setEmployeeNumber] = useState(account.employeeNumber);
  const ref = useRef(null);
  useEffect(() => {
    ref.current.value = account.employeeNumber;
  }, [account]);

  return (
    <input
      type="number"
      defaultValue={employeeNumber || ''}
      className="w-56 h-7 rounded hover:border hover:border-[#E2E6EA] focus:border focus:border-[#E2E6EA]"
      onChange={(e) => {
        isNaN(parseInt(e.target.value))
          ? setEmployeeNumber(null)
          : setEmployeeNumber(parseInt(e.target.value));
      }}
      onBlur={(e) =>
        updateAccount({
          variables: {
            id: account.id,
            attributes: {
              employeeNumber: parseInt(e.target.value),
            },
          },
        })
      }
      ref={ref}
    />
  );
};
