import React, {SVGProps} from 'react';
import {
  CollectedFormUrlField,
  useUpdateCollectedFormUrlFieldByIdMutation,
  useDestroyCollectedFormUrlFieldByIdMutation,
  useCollectedFormUrlFieldsQuery,
  CollectedFormUrlFieldAttributes,
} from 'api';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Draggable} from 'react-smooth-dnd';

interface Props {
  collectedFormUrlField: CollectedFormUrlField;
}

const fieldTypes: {[key: string]: string} = {
  full_name: '姓名',
  last_name: '姓',
  first_name: '名',
  email: 'メールアドレス',
  telephone_number: '電話番号',
  section: '部署名',
  section_position: '役職名',
  company_name: '会社名',
  web_site: 'URL',
  address: '住所',
};

const Handle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="6"
    height="18"
    viewBox="0 0 6 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2ZM1 6C1.55228 6 2 5.55228 2 5C2 4.44772 1.55228 4 1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6ZM2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9ZM1 14C1.55228 14 2 13.5523 2 13C2 12.4477 1.55228 12 1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14ZM2 17C2 17.5523 1.55228 18 1 18C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16C1.55228 16 2 16.4477 2 17ZM5 2C5.55228 2 6 1.55228 6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2ZM6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5ZM5 10C5.55228 10 6 9.55229 6 9C6 8.44771 5.55228 8 5 8C4.44772 8 4 8.44771 4 9C4 9.55229 4.44772 10 5 10ZM6 13C6 13.5523 5.55228 14 5 14C4.44772 14 4 13.5523 4 13C4 12.4477 4.44772 12 5 12C5.55228 12 6 12.4477 6 13ZM5 18C5.55228 18 6 17.5523 6 17C6 16.4477 5.55228 16 5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18Z"
      fill="currentColor"
    />
  </svg>
);

const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3 6H21M5 6V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V6M8 6V4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></path>
    <path
      d="M14 11V17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></path>
    <path
      d="M10 11V17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"></path>
  </svg>
);

export default ({collectedFormUrlField}: Props) => {
  const {collectedFormUrlId} = useParams<{collectedFormUrlId: string}>();
  const {data: {collectedFormUrlFields = []} = {}} =
    useCollectedFormUrlFieldsQuery({
      variables: {uuid: collectedFormUrlId},
    });

  const [updateCollectedFormUrlField] =
    useUpdateCollectedFormUrlFieldByIdMutation({
      refetchQueries: ['collectedFormUrlFields'],
    });

  const [destroyCollectedFormUrlField] =
    useDestroyCollectedFormUrlFieldByIdMutation({
      variables: {id: collectedFormUrlField.id},
      refetchQueries: ['collectedFormUrlFields'],
    });

  const validationSchema = Yup.object().shape({
    label: Yup.string(),
    name: Yup.string(),
    fieldType: Yup.string(),
  });

  const {register, handleSubmit, watch} = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      label: collectedFormUrlField.label || '',
      name: collectedFormUrlField.name || '',
      fieldType: collectedFormUrlField.fieldType || '',
    },
    mode: 'onChange',
  });

  const filteredTypes = React.useMemo(() => {
    const usedTypes = collectedFormUrlFields
      .filter((field) => field.id !== collectedFormUrlField.id)
      .map((field) => field.fieldType);
    return Object.keys(fieldTypes).filter((type) => !usedTypes.includes(type));
  }, [collectedFormUrlFields]);

  const onSubmit = React.useCallback(
    (data: CollectedFormUrlFieldAttributes) => {
      updateCollectedFormUrlField({
        variables: {
          id: collectedFormUrlField.id,
          attributes: data,
        },
      });
    },
    [collectedFormUrlField],
  );

  const {label, name, fieldType} = watch();

  React.useEffect(() => {
    if (
      label === collectedFormUrlField.label &&
      name === collectedFormUrlField.name &&
      fieldType === collectedFormUrlField.fieldType
    ) {
      return;
    }

    handleSubmit(onSubmit)();
  }, [label, name, fieldType]);

  return (
    <Draggable
      key={collectedFormUrlField.id}
      className="flex items-center"
      render={() => (
        <div>
          <div className="flex items-center hover:bg-c-bg group">
            <div className="w-9 p-2 flex items-center justify-center cursor-move dragHandle">
              <Handle className="text-c-lighter group-hover:text-c-light" />
            </div>
            <div className="grid grid-cols-3 w-full">
              <div className="p-2">
                <input
                  className="form-input border-c-border rounded text-base w-full"
                  {...register('label')}
                />
              </div>
              <div className="p-2">
                <input
                  className="form-input border-c-border rounded text-base w-full"
                  {...register('name')}
                />
              </div>
              <div className="p-2">
                <select
                  {...register('fieldType')}
                  className="form-select border-c-border rounded text-base w-full">
                  <option value={''} />
                  {filteredTypes.map((fieldType) => (
                    <option key={fieldType} value={fieldType}>
                      {fieldTypes[fieldType]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-12 p-2 flex items-center justify-center">
              <Trash
                className="w-5 cursor-pointer text-transparent group-hover:text-c-light"
                onClick={() => destroyCollectedFormUrlField()}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};
