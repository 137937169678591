import React from 'react';

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="w-5"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 6.75H8.25C7.42157 6.75 6.75 7.42157 6.75 8.25V15C6.75 15.8284 7.42157 16.5 8.25 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V8.25C16.5 7.42157 15.8284 6.75 15 6.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 11.25H3C2.17157 11.25 1.5 10.5784 1.5 9.75V3C1.5 2.17157 2.17157 1.5 3 1.5H9.75C10.5784 1.5 11.25 2.17157 11.25 3V3.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
