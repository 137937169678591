import gql from 'graphql-tag';
import azureRecognizedPhraseNBestFragment from './recognizedPhraseNBest';

export default gql`
  fragment azureRecognizedPhraseFragment on AzureRecognizedPhrase {
    id
    speaker
    azureRecognizedPhraseNBests {
      ...azureRecognizedPhraseNBestFragment
    }
  }
  ${azureRecognizedPhraseNBestFragment}
`;
