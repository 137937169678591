import React from 'react';

function Icon() {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 10.25L12.5 1.91669C13.3284 1.22634 14.6716 1.22634 15.5 1.91669C16.3284 2.60705 16.3284 3.72634 15.5 4.41669L5.5 12.75L1.5 13.5834L2.5 10.25Z"
        stroke="#BDC4CB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
