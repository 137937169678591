import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import {Button} from 'components/antd';
import {usePreleadProjectQuery} from 'api';
import TimelineItem from 'components/TimelineItem';
import usePreleadProject from 'hooks/usePreleadProject';
import {Refresh} from 'components/Ui/Icon';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();

  const {timeLineLetterActivities} = usePreleadProject(preleadProjectId);

  const {
    data: {preleadProject: {prelead: {letterActivities = []} = {}} = {}} = {},
    refetch,
    loading,
  } = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    skip: !preleadProjectId,
    notifyOnNetworkStatusChange: true,
  });

  if (!preleadProjectId) return null;

  return (
    <Container>
      <Actions>
        <Button
          onClick={() => refetch()}
          icon={<Refresh spin={loading} />}
          disabled={loading}
        />
      </Actions>
      {timeLineLetterActivities(letterActivities).map((activity) => (
        <TimelineItem key={activity.uuid} activity={activity} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > div:first-child {
    padding-top: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
