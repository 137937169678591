import {useAllProjectsQuery, useCurrentUserQuery, useProjectGroupsQuery} from 'api';
import useClientUser from 'hooks/useClientUser';
import _ from 'lodash';
import React from 'react';
import useSearchParams from './useSearchParams';

const useAllProjects = () => {
  const {searchParams} = useSearchParams();

  const {
    data: {currentUser = {}},
  } = useCurrentUserQuery();

  const {isAdmin} = useClientUser();
  const {data: {projectGroups = []} = {}} = useProjectGroupsQuery({});

  const {data: {allProjects: {projects = []} = {}} = {}, loading} =
    useAllProjectsQuery({
      variables: {
        search: _.omit(searchParams, 'status'),
      },
    });

  const projectGroup = React.useCallback(
    (projectGroupId) => projectGroups.find((projectGroup) => projectGroup.id === projectGroupId),
    [projectGroups],
  );

  const accessibleProjects = React.useMemo(
    () =>
      isAdmin
        ? projects
        : projects.filter(
            (project) =>
              !projectGroup(project.projectGroupId)?.isPrivate ||
              projectGroup(project.projectGroupId)?.users.some(
                (user) => user.id === currentUser.id,
              ),
          ),
    [isAdmin, currentUser, projectGroups, projects],
  );

  const openProjectsCount = React.useMemo(
    () =>
      loading
        ? null
        : accessibleProjects.filter((project) => project.status === 'open')
            .length,
    [accessibleProjects],
  );

  const draftProjectsCount = React.useMemo(
    () =>
      loading
        ? null
        : accessibleProjects.filter((project) => project.status === 'draft')
            .length,
    [accessibleProjects],
  );

  const archivedProjectsCount = React.useMemo(
    () =>
      loading
        ? null
        : accessibleProjects.filter((project) => project.status === 'archived')
            .length,
    [accessibleProjects],
  );

  return {
    openProjectsCount,
    draftProjectsCount,
    archivedProjectsCount,
  };
};

export default useAllProjects;
