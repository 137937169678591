import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {useProjectQuery, useProjectTelStatusReportQuery} from 'api';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {
    data: {
      projectTelStatusReport: {
        call = {},
        reception = {},
        contact = {},
        appoint = {},
        document = {},
        callNg = {},
        receptionNg = {},
        contactNg = {},
      } = {},
      projectTelStatusReport = {},
    } = {},
  } = useProjectTelStatusReportQuery({
    variables: {
      projectUuid: projectId,
    },
  });

  const {data: {project: {count = 0} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const preleadCount = React.useMemo(() => {
    const totalCount = (object: any): any =>
      Object.values(object).reduce(
        (total: number, value: number) => total + value,
        0,
      );

    let count = 0;

    count += totalCount(call);
    count += totalCount(reception);
    count += totalCount(contact);
    count += totalCount(appoint);
    count += totalCount(document);
    count += totalCount(callNg);
    count += totalCount(receptionNg);
    count += totalCount(contactNg);

    return count;
  }, [projectTelStatusReport]);

  return (
    <Container>
      <div>
        <span>
          <em>{preleadCount && preleadCount.toLocaleString()}</em>
          社 (消化率 {(( preleadCount && preleadCount / count) * 100)?.toFixed(1)}%)
        </span>
        <span>アプローチ企業数</span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 47px;
  background: #ffffff;
  padding: 32px 28px;
  margin-left: 40px;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;

    > span:nth-child(1) {
      width: 200px;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #495058;

      em {
        margin-right: 6px;
        font-style: normal;
        font-size: 20px;
        line-height: 43px;
      }
    }

    > span:nth-child(2) {
      font-weight: bold;
      font-size: 12px;
      color: #899098;
    }
  }
`;
