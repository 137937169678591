import React from 'react';
import {MiitelCallProspectActivity, ProspectActivity, ProspectPool} from 'api';
import MiitelCall from './MiitelCall';

interface ActivityType extends ProspectActivity {
  resource: MiitelCallProspectActivity;
}

interface Props {
  prospectActivity: ActivityType;
  prospectPool: ProspectPool;
}

const MiitelCallActivity = ({prospectActivity, prospectPool}: Props) => {
  return (
    <div className="relative border border-solid border-c-border rounded bg-white w-full z-10">
      <MiitelCall
        prospectActivity={prospectActivity}
        prospectPool={prospectPool}
      />
    </div>
  );
};

export default MiitelCallActivity;
