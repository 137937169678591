import gql from 'graphql-tag';

export default gql`
  fragment contentMailFormVersionFragment on ContentMailFormVersion {
    id
    uuid
    version
    companyName
    companyKana
    companyHiragana
    firstName
    lastName
    firstNameKana
    lastNameKana
    firstNameKana2
    lastNameKana2
    section
    sectionPosition
    email
    tel1
    tel2
    tel3
    zipcode1
    zipcode2
    state
    city
    street
    building
    url
    title
    message1
    message2
    messageMail
    createdAt
    updatedAt
  }
`;
