import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import usePools from 'hooks/usePools';

export default () => {
  const {pools} = usePools();

  return (
    <Container>
      {pools.map((pool) => (
        <Item key={pool.id}>
          <PoolName to={`/settings/pools/${pool.uuid}/lead_sources`}>
            {pool.name}
          </PoolName>
          <MemberLabel>15 members</MemberLabel>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const Item = styled.div`
  margin-bottom: 5px;
  padding: 9px 20px 9px 13px;
  display: flex;
  align-items: center;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
`;

const PoolName = styled(Link)`
  margin-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MemberLabel = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #495058;
`;
