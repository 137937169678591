import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {Form} from 'api';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const match = useRouteMatch<{page: string; tab: string}>({
    path: '/form/forms/:formId/:page/:tab',
  });

  const tab = match?.params?.tab || 'blocks';
  const page = match?.params?.page || 'done';

  return (
    <ul className="font-bold flex flex-wrap -mb-px text-center border-b border-c-border">
      <li className="w-1/2">
        <Link
          to={`/form/forms/${form.uuid}/${page}/settings`}
          className={`inline-block pb-3 border-b-2 w-full ${
            tab === 'settings'
              ? 'text-c-base border-c-base'
              : 'text-c-lighter border-transparent'
          }`}>
          設定
        </Link>
      </li>
      <li className="w-1/2">
        <Link
          to={`/form/forms/${form.uuid}/${page}/blocks`}
          className={`inline-block pb-3 border-b-2 w-full ${
            tab === 'blocks'
              ? 'text-c-base border-c-base'
              : 'text-c-lighter border-transparent'
          }`}>
          項目
        </Link>
      </li>
    </ul>
  );
};
