import gql from 'graphql-tag';
import prospectTelStatusCategoryFragment from './prospectTelStatusCategory';

export default gql`
  fragment prospectTelStatusFragment on ProspectTelStatus {
    id
    clientId
    name
    description
    position
    prospectTelStatusCategory {
      ...prospectTelStatusCategoryFragment
    }
  }
  ${prospectTelStatusCategoryFragment}
`;
