import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import List from './List';
import Thread from './Thread';
import {ProspectPool} from 'api';
interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {path} = useRouteMatch();
  const [page, setPage] = React.useState(1);

  if (!prospectPool) return null;

  return (
    <Switch>
      <Route path={`${path}/threads/:threadId`}>
        <Thread />
      </Route>
      <Route>
        <List prospectPool={prospectPool} page={page} setPage={setPage} />
      </Route>
    </Switch>
  );
};
