import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useProjectTagReportsQuery} from 'api';
import {useTable} from 'react-table';
import {useParams} from 'react-router';

export default () => {
  const {projectId} = useParams<{projectId: string}>();

  const {data: {projectTagReports = []} = {}} = useProjectTagReportsQuery({
    variables: {
      uuid: projectId,
    },
  });

  const data: any = useMemo(
    () =>
      projectTagReports.map((report) => {
        return {
          tagName: report.preleadTag.name,
          tagCount: report.tagCount,
          tagRate: report.tagRate,
        };
      }),
    [projectTagReports],
  );

  const columns: any = useMemo(
    () => [
      {
        id: 'tagName',
        accessor: (row: any) => (
          <TagNameCell>
            <div>{row.tagName}</div>
          </TagNameCell>
        ),
      },
      {
        id: 'preleads',
        Header: 'プレリード数',
        accessor: (row: any) => <CountCell>{row.tagCount}</CountCell>,
      },
      {
        id: 'rate',
        Header: '構成率',
        accessor: (row: any) => <CountCell>{row.tagRate}%</CountCell>,
      },
    ],
    [],
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});

  return (
    <Container>
      <Title>タグ別</Title>
      <TableWrapper>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => (
                  <th {...column.getHeaderProps()} key={j}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td {...cell.getCellProps()} key={j}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 1195px !important;
  height: 443px;
`;

const Title = styled.div`
  margin-bottom: 17px;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #495058;
`;

const TableWrapper = styled.div`
  border-left: 1px solid #e1e6eb;
  max-height: 400px;
  overflow-y: scroll;
  width: 1100px;
`;

const Table = styled.table`
  border-bottom: 1px solid #e1e6eb;

  thead {
    background: #ffffff;
    position: sticky;
    top: 0;
    z-index: 3;

    tr {
      border-top: 1px solid #e1e6eb;
      border-bottom: 1px solid #e1e6eb;

      th {
        border-right: 1px solid #e1e6eb;
        height: 45px;
        width: 180px;
        font-weight: bold;
        font-size: 13px;

        &:first-child {
          background: #ffffff;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 3;
          width: 512px;
        }

        svg {
          margin-right: 6px;
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid #e1e6eb;

      &:nth-child(n + 3) {
        border-right: 1px solid #e1e6eb;
        border-top: 1px solid #e1e6eb;
      }

      td {
        border-right: 1px solid #e1e6eb;
        height: 45px;
        font-weight: 500;
        font-size: 12px;
        color: #495058;

        &:first-child {
          background: #ffffff;
          position: sticky;
          left: 0;
          z-index: 1;
        }
      }
    }
  }
`;

const CountCell = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  padding-right: 10px;
`;

const TagNameCell = styled.div`
  margin-left: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;

  div:first-child {
    display: inline-block;
    width: 480px;
    font-weight: 700;
    font-size: 13px;
    color: #495058;
  }
`;
