import React from 'react';
import {useCurrentClientQuery, Form, useUpdateFormMutation} from 'api';
import Title from './Title';
import {LaunchLink} from 'components/Ui/Icon';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon, CheckIcon} from '@heroicons/react/20/solid';

interface Props {
  form: Form;
}

export default ({form}: Props) => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();
  const [update] = useUpdateFormMutation();

  const publicUrl = `${location.protocol}//${location.host.replace(
    `${currentClient?.domain}.`,
    'app.',
  )}/forms/${form?.uuid}`;

  if (!form) return null;

  return (
    <div className="my-4 flex items-center justify-between gap-4">
      {form.status === 'open' && (
        <span className="w-fit flex items-center gap-2 text-sm bg-[#E5F9ED] text-c-green h-8 px-4 rounded">
          <span className="rounded-full w-[6px] h-[6px] bg-c-green" />
          {form.statusText}
        </span>
      )}
      {form.status !== 'open' && (
        <span className="w-fit flex items-center gap-2 text-sm bg-c-border text-c-light h-8 px-4 rounded">
          <span className="rounded-full w-[6px] h-[6px] bg-c-light" />
          {form.statusText}
        </span>
      )}
      <Title form={form} />
      <div className="flex items-center gap-4">
        {form?.status === 'open' && (
          <a href={publicUrl} target="_blank">
            <LaunchLink />
          </a>
        )}
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button
              className={`inline-flex w-full justify-center cursor-pointer rounded px-4 py-2 text-sm font-medium hover:bg-opacity-90 focus:outline-none ${
                form.status === 'open'
                  ? 'text-white bg-c-primary'
                  : 'text-c-primary bg-transparent border border-c-primary'
              }`}>
              {form.status === 'open' ? '公開中' : '非公開'}
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                {form.status !== 'open' && (
                  <Menu.Item>
                    {({active}) => (
                      <button
                        onClick={() =>
                          confirm('公開にしてよろしいですか？') &&
                          update({
                            variables: {
                              uuid: form.uuid,
                              attributes: {status: 'open'},
                            },
                          })
                        }
                        className={`${
                          active
                            ? 'bg-[#EDF6FF] text-c-primary'
                            : 'text-c-base bg-white'
                        } cursor-pointer group flex w-full items-center rounded px-2 py-2 text-sm gap-2`}>
                        <CheckIcon
                          className={`w-4 h-4 ${
                            active ? 'text-c-primary' : 'text-transparent'
                          }`}
                        />
                        公開
                      </button>
                    )}
                  </Menu.Item>
                )}
                {form.status === 'open' && (
                  <Menu.Item>
                    {({active}) => (
                      <button
                        onClick={() =>
                          confirm('非公開にしてよろしいですか？') &&
                          update({
                            variables: {
                              uuid: form.uuid,
                              attributes: {status: 'closed'},
                            },
                          })
                        }
                        className={`${
                          active
                            ? 'bg-[#EDF6FF] text-c-primary'
                            : 'text-c-base bg-white'
                        } cursor-pointer group flex w-full items-center rounded px-2 py-2 text-sm gap-2`}>
                        <CheckIcon
                          className={`w-4 h-4 ${
                            active ? 'text-c-primary' : 'text-transparent'
                          }`}
                        />
                        非公開
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
