import React from 'react';
import styled from 'styled-components';
import {Input} from 'components/antd';
import {useCreatePreleadTagMutation, useProjectQuery} from 'api';
import {useParams} from 'react-router';
import {tagColors} from 'helpers/tagColors';
import useProject from 'hooks/useProject';
import usePreleadTags from 'hooks/usePreleadTags';

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

interface Props {
  tagIds: any;
  setTagIds: React.Dispatch<React.SetStateAction<any[]>>;
}

export default ({tagIds, setTagIds}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const [tagName, setTagName] = React.useState('');
  const {isOwnProject} = useProject(projectId);
  const {preleadTagsAccessible} = usePreleadTags();

  const {data: {project: {projectGroup = {}} = {}} = {}, refetch} =
    useProjectQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
    });

  const [createPreleadTag] = useCreatePreleadTagMutation({
    refetchQueries: ['project'],
    onCompleted: ({createPreleadTag: {preleadTag = {}} = {}}) => {
      setTagName('');
      setTagIds([preleadTag.id, ...tagIds]);
    },
  });

  const getRandomColor = (): string => {
    const colors = tagColors.map((color) => color.hex);
    const color = colors[Math.floor(Math.random() * colors.length)];
    return color;
  };
  const randomColor = React.useMemo(
    () => getRandomColor(),
    [preleadTagsAccessible],
  );

  const filteredTags = React.useMemo(
    () =>
      preleadTagsAccessible?.filter((tag) =>
        kanaToHira(tag.name).includes(kanaToHira(tagName)),
      ),
    [preleadTagsAccessible, tagName],
  );

  const alreadyNameExist: boolean = React.useMemo(
    () => preleadTagsAccessible?.some((tag) => tag.name === tagName),
    [preleadTagsAccessible, tagName],
  );

  const alreadyAdded = React.useCallback(
    (tagId: string) => tagIds.includes(tagId),
    [tagIds],
  );

  const onCreate = () => {
    if (!!tagName && !alreadyNameExist) {
      createPreleadTag({
        variables: {
          attributes: {
            name: tagName,
            color: randomColor,
            projectGroupId: projectGroup.id,
          },
        },
      });
    }
  };

  return (
    <Container>
      <InputBox>
        <InputWrapper>
          <TagInput
            type="search"
            name="search"
            bordered={false}
            placeholder={tagName}
            defaultValue={''}
            value={tagName}
            onChange={(e: any) => setTagName(e.target.value)}
          />
        </InputWrapper>
      </InputBox>

      <ScrollWrapper>
        <List>
          {filteredTags?.map((tag) => (
            <Tag
              key={tag.id}
              onClick={() => {
                if (tagIds.includes(tag.id)) {
                  setTagIds(tagIds.filter((tagId: any) => tagId !== tag.id));
                } else {
                  setTagIds([tag.id, ...tagIds]);
                }
                setTagName('');
              }}
              added={alreadyAdded(tag.id)}>
              <div>
                <TagRectangle color={tag.color} />
                <TagName>{tag.name}</TagName>
              </div>
            </Tag>
          ))}
        </List>
      </ScrollWrapper>
      {isOwnProject && tagName && !alreadyNameExist && (
        <NewTag>
          <h3>新規作成</h3>
          <Tag onClick={onCreate}>
            <div>
              <TagRectangle color={randomColor} />
              <TagName>{tagName}</TagName>
            </div>
          </Tag>
        </NewTag>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 18px 12px;
`;

const ScrollWrapper = styled.div`
  margin-top: 20px;
  max-height: 200px;
  overflow: scroll;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NewTag = styled.div`
  border-top: solid 1px #e1e6eb;

  h3 {
    margin-top: 11px;
    margin-bottom: 0px;
    padding: 0px 11px;
    font-size: 10px;
    line-height: 14px;

    color: #899098;
  }
`;

const TagRectangle = styled.span<{color?: string}>`
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${({color}) => (color ? `${color}` : 'white')};
`;

const TagName = styled.div`
  font-size: 14px;
  color: #495058;
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Tag = styled.div<{added?: boolean}>`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: #f2f7fa;
  }

  background: ${({added}) => (added ? '#f2f7fa' : '#fff')};

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    padding: 0px 11px;

    ${TagName} {
      margin-left: 7px;
    }

    > button {
      margin-left: auto;
      padding: 0;
      svg {
        transform: rotate(90deg);
        width: 1rem;
        height: 1rem;
        stroke: #899098;
        visibility: visible;
      }
    }
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 11px;
  width: 100%;
  min-height: 34px;
  padding: 6px;
  background: #ffffff;
  border: 1px solid #e1e6eb;
  box-sizing: border-box;
  border-radius: 2px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  width: auto;
  background: transparent;
  font-size: inherit;
  line-height: 20px;
  flex: 1 1 60px;
  min-width: 60px;
  margin: 0px 6px 6px 0px;
`;

const TagInput = styled(Input)`
  border: none;
  padding: 0px;
  .ant-input {
    background: #ffffff;
    &::placeholder {
      font-size: 10px;
      color: #bdc4cb;
    }
  }

  &:focus-within {
    background-color: white;
  }
  .ant-input:focus-within {
    color: black;
    background: white;
  }
`;
