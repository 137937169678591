import React from 'react';
import {PreleadProject, useUsersByClientAllQuery} from 'api';
import {Row} from 'react-table';

interface Props {
  row: Row<PreleadProject>;
}

const LatestTelUserCell = ({row}: Props) => {
  const preleadProject: PreleadProject = row.original;
  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {},
      },
    });

  const user = users.find(
    (user) => user.id === preleadProject.prelead.telUserId,
  );

  return <div>{user && `${user.lastName} ${user.firstName}`}</div>;
};
export default LatestTelUserCell;
