import React from 'react';

const Icon = ({
  width = '40',
  height = '40',
  active,
}: {
  width: string;
  height: string;
  active: boolean;
}) => (
  <div
    style={{
      height: `${height}px`,
      width: `${width}px`,
      borderRadius: `${Number(height) / 2}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: active ? 'rgba(72,97,228,1)' : 'rgba(72,97,228,.3)',
    }}>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 16.4999C13.1421 16.4999 16.5 13.142 16.5 8.99988C16.5 4.85774 13.1421 1.49988 9 1.49988C4.85786 1.49988 1.5 4.85774 1.5 8.99988C1.5 10.9971 2.28071 12.8121 3.55359 14.1561L2.04511 15.8778C1.83271 16.1202 2.00486 16.4999 2.32716 16.4999H9Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default Icon;
