import {
  ContentMailTemplate,
  useContentAvailableMailTemplatesQuery,
  useContentPersonalMailTemplatesQuery,
  useContentSharedMailTemplatesQuery,
} from 'api';

const useMailTemplate = () => {
  const {data: {contentAvailableMailTemplates = []} = {}} =
    useContentAvailableMailTemplatesQuery({fetchPolicy: 'cache-and-network'});

  const templates = contentAvailableMailTemplates;

  const templateTitle = (content: any) => {
    const mailTemplate: ContentMailTemplate = content.mailTemplate;
    return mailTemplate.isShare
      ? content.title
      : `【${mailTemplate.user.lastName} ${mailTemplate.user.firstName}】${content.title} `;
  };

  const {data: {contentSharedMailTemplates: sharedTemplates = []} = {}} =
    useContentSharedMailTemplatesQuery({fetchPolicy: 'cache-and-network'});
  const {data: {contentPersonalMailTemplates: personalTemplates = []} = {}} =
    useContentPersonalMailTemplatesQuery({fetchPolicy: 'cache-and-network'});

  return {templates, templateTitle, sharedTemplates, personalTemplates};
};
export default useMailTemplate;
