import React from 'react';
import {useParams} from 'react-router-dom';
import Pagination from '../../Pagination';
import {useProspectPoolsQuery} from 'api';
// @ts-ignore
import useSearchParams from '../useSearchParams';
import TableItem from './Item';

export default () => {
  const topElement =
    window.document.getElementsByClassName('ant-breadcrumb')[0];
  const {poolId} = useParams<{poolId: string}>();
  const {page, perPage, searchParams, setQuery} = useSearchParams();
  const {
    data: {prospectPools: {prospectPools = [], pagination = {}} = {}} = {},
  } = useProspectPoolsQuery({
    variables: {
      uuid: poolId,
      page: page,
      perPage: perPage,
      search: searchParams,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <div className="flex flex-col items-center my-4">
        {prospectPools.map((prospectPool) => (
          <TableItem key={prospectPool.uuid} prospectPool={prospectPool} />
        ))}
      </div>
      <div className="flex justify-center">
        <Pagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={perPage}
          onChange={(page) => {
            setQuery({page});
            topElement.scrollIntoView({behavior: 'smooth'});
          }}
        />
      </div>
    </>
  );
};
