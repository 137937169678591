import React from 'react';
import {ProspectPool} from 'api';
import {Popover} from 'components/antd';
import styled from 'styled-components';
import {getTagFontColor} from 'helpers/tagColors';
import {PlusSquare} from 'components/Ui/Icon';
import TagSelect from './TagSelect';
import useClientUser from 'hooks/useClientUser';

interface Props {
  prospectPool: ProspectPool;
}

export default ({prospectPool}: Props) => {
  const {isMember} = useClientUser();
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const prospectTags = prospectPool?.prospectTags || [];

  return (
    <div className="flex">
      {prospectTags.length > 0 ? (
        <Tags>
          {prospectTags.map((tag) => (
            <Tag color={tag.color} key={tag.id}>
              <span>{tag.name}</span>
            </Tag>
          ))}
        </Tags>
      ) : (
        <Name>未設定</Name>
      )}
      {isMember && (
        <PopoverWrapper>
          <Popover
            content={() => <TagSelect prospectPool={prospectPool} />}
            trigger="click"
            visible={isPopoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            placement="bottomLeft">
            <Add onClick={() => setPopoverVisible(true)}>
              <PlusSquare />
            </Add>
          </Popover>
        </PopoverWrapper>
      )}
    </div>
  );
};

const PopoverWrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 28px;
  line-height: 28px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 12em;
  overflow-y: auto;
`;

const Tag = styled.span<{color?: string}>`
  margin-right: 6px;
  margin-bottom: 5px;
  display: flex;
  padding: 3px 7px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;
  span {
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: ${({color}) => getTagFontColor(color)};
  }
`;

const Add = styled.div`
  cursor: pointer;
  margin-top: 6px;

  > svg {
    width: 24px;
    height: 24px;
  }
`;
