import React from 'react';
import useSearchParams from '../../../useSearchParams';
import CheckboxGroup from './CheckboxGroup';
import Header from '../Header';
import Footer from './Footer';

const options = [
  {
    value: 'call',
    text: '電話',
  },
  {
    value: 'manual_mail',
    text: 'メール',
  },
  {
    value: 'task',
    text: 'タスク',
  },
];

const ApproachTypeFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="アクション種別" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <CheckboxGroup
            options={options.map((approachType) => ({
              text: approachType.text,
              value: approachType.value,
              keyword: approachType.text,
            }))}
            values={query.approachTypes || []}
            onChange={(approachTypes) => setQuery({...query, approachTypes})}
          />
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, approachTypes: []})} />
    </>
  );
};

export default ApproachTypeFilter;
