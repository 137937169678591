import React from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Plus} from 'components/Ui/Icon';
import InviteModal from '../InviteModal';

export default () => {
  const history = useHistory();
  const {teamId} = useParams<{teamId: string}>();
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <TabWrapper>
      <Tabs>
        <Tab
          active={location.pathname.endsWith('/base')}
          onClick={() => history.push(`/settings/teams/${teamId}/base`)}>
          <span>基本情報</span>
        </Tab>
        <Tab
          active={location.pathname.endsWith('/users')}
          onClick={() => history.push(`/settings/teams/${teamId}/users`)}>
          <span>ユーザー</span>
        </Tab>
      </Tabs>
      <TabButton type="button" onClick={() => setModalOpen(true)}>
        <Plus />
        <span>招待</span>
      </TabButton>
      {isModalOpen && (
        <InviteModal isOpen={isModalOpen} setModal={setModalOpen} />
      )}
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 0.5px solid #bdc4cb;
  margin-bottom: 30px;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div<{active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  span {
    display: block;
    color: ${({active}) => (active ? '#495058' : '#bdc4cb')};
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0.15px;
    padding: 12px 13px;
    border-bottom: ${({active}) => (active ? 'solid 3px #68b5fb' : '')};
  }

  &:hover {
    cursor: pointer;
  }
`;

const TabButton = styled.button`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 6px 13px;
  border: none;
  outline: 0;
  background: #68b5fb;
  border-radius: 4px;
  margin-top: 10px;
  svg {
    margin-right: 7px;
    > path {
      stroke: #fff;
    }
  }
  span {
    font-size: 12px;
    line-height: 12px;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
  }
`;
