import {useProjectWithSearchConditionQuery} from 'api';
import {World} from 'components/Ui/Icon';
import React from 'react';
import {useParams} from 'react-router';
import {
  Container,
  Category,
  CategoryTitle,
  ChildCategory,
  ChildCategoryTitleB,
  ChildCategoryItem,
  ChildCategoryTitleA,
  TagBox,
  TagItem,
} from './styles';
import employeeRangeOptions from 'helpers/employeeRangeOptions';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const {data: {project = {}} = {}} = useProjectWithSearchConditionQuery({
    variables: {uuid: projectId},
    skip: !projectId,
    fetchPolicy: 'cache-only',
  });
  const {projectSearchCondition} = project;
  if (!projectSearchCondition) return null;

  return (
    <Container>
      {project.isCompanyScale && (
        <Category>
          <CategoryTitle>
            <World />
            規模
          </CategoryTitle>

          {(projectSearchCondition.employeeUnknown ||
            projectSearchCondition.employeeFrom ||
            projectSearchCondition.employeeTo) && (
            <ChildCategory>
              <ChildCategoryTitleB>従業員数</ChildCategoryTitleB>
              <ChildCategoryItem>
                {projectSearchCondition.employeeUnknown
                  ? '不明'
                  : `${
                      projectSearchCondition.employeeFrom
                        ? `${projectSearchCondition.employeeFrom}名`
                        : ''
                    } 〜 ${
                      projectSearchCondition.employeeTo
                        ? `${projectSearchCondition.employeeTo}名`
                        : ''
                    }`}
              </ChildCategoryItem>
            </ChildCategory>
          )}

          {projectSearchCondition.employeeRanges &&
            projectSearchCondition.employeeRanges.length > 0 && (
              <ChildCategory>
                <ChildCategoryTitleA>従業員数</ChildCategoryTitleA>
                <TagBox>
                  {projectSearchCondition.employeeRanges.map(
                    (employeeRange) => (
                      <TagItem key={employeeRange}>
                        {
                          employeeRangeOptions.find(
                            (employeeRangeOption) =>
                              employeeRangeOption.value === employeeRange,
                          ).label
                        }
                      </TagItem>
                    ),
                  )}
                </TagBox>
              </ChildCategory>
            )}

          {(projectSearchCondition.officesNumberFrom ||
            projectSearchCondition.officesNumberTo) && (
            <ChildCategory>
              <ChildCategoryTitleB>事業所数</ChildCategoryTitleB>
              <ChildCategoryItem>
                {projectSearchCondition.officesNumberFrom &&
                  projectSearchCondition.officesNumberFrom}{' '}
                〜{' '}
                {projectSearchCondition.officesNumberTo &&
                  projectSearchCondition.officesNumberTo}
              </ChildCategoryItem>
            </ChildCategory>
          )}

          {(projectSearchCondition.capitalFundFrom ||
            projectSearchCondition.capitalFundTo) && (
            <ChildCategory>
              <ChildCategoryTitleB>資本金</ChildCategoryTitleB>
              <ChildCategoryItem>
                {projectSearchCondition.capitalFundFrom &&
                  `${projectSearchCondition.capitalFundFrom}万`}{' '}
                〜{' '}
                {projectSearchCondition.capitalFundTo &&
                  `${projectSearchCondition.capitalFundTo}万`}
              </ChildCategoryItem>
            </ChildCategory>
          )}
        </Category>
      )}
    </Container>
  );
};
