import React, {useMemo} from 'react';
import styled from 'styled-components';
import {PreleadContact, useCurrentClientQuery} from 'api';

interface Props {
  preleadContact: PreleadContact;
}

export default ({preleadContact}: Props) => {
  const {
    data: {
      currentClient = {},
      currentClient: {
        hubspotSetting = {},
        sensesSetting = {},
        salesforceSetting = {},
      } = {},
    } = {},
  } = useCurrentClientQuery();

  const sensesUrl = useMemo(
    () =>
      sensesSetting?.isSensesPartner
        ? 'https://senses-partner.mazrica.com/'
        : 'https://senses.mazrica.com/',
    [sensesSetting],
  );

  return (
    <Container>
      {currentClient.hubspotActive && (
        <HubspotLink active={!!preleadContact.hubspotContactId}>
          {!!preleadContact.hubspotContactId &&
          !!hubspotSetting.hubspotAccountId ? (
            <a
              href={`https://app.hubspot.com/contacts/${hubspotSetting.hubspotAccountId}/contact/${preleadContact.hubspotContactId}/`}
              target="_blank">
              HubSpot
            </a>
          ) : (
            <span>HubSpot</span>
          )}
        </HubspotLink>
      )}
      {currentClient.salesforceActive && (
        <SalesforceLink active={!!preleadContact.salesforceLeadId}>
          {preleadContact.salesforceLeadId ? (
            <a
              href={`${salesforceSetting.instanceUrl}/lightning/r/Lead/${preleadContact.salesforceLeadId}/view`}
              target="_blank">
              Salesforce
            </a>
          ) : (
            <span>Salesforce</span>
          )}
        </SalesforceLink>
      )}

      {currentClient.sensesActive && sensesSetting.sensesDealTypeId && (
        <SensesLink active={!!preleadContact.sensesContactId}>
          {preleadContact.sensesContactId ? (
            <a
              href={`${sensesUrl}contact/${preleadContact.sensesContactId}`}
              target="_blank">
              Senses
            </a>
          ) : (
            <span>Senses</span>
          )}
        </SensesLink>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    cursor: pointer;
  }
`;

const IntegrationLink = styled.div`
  margin-right: 5px;
  display: flex;
  height: 18px;
  background: #ffffff;
  border: 1px solid #68b5fb;
  border-radius: 100px;

  a,
  span {
    margin: auto;
    padding: 0px 8px;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
    color: #68b5fb;
  }
`;

const SalesforceLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#68B5FB' : '#ffffff')};
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#68b5fb')};
  }
`;

const HubspotLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#FF7A59' : '#ffffff')};
  border: 1px solid #ff7a59;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#FF7A59')};
  }
`;

const SensesLink = styled(IntegrationLink)<{active?: boolean}>`
  background: ${({active}) => (active ? '#1A2F3A' : '#ffffff')};
  border: 1px solid #1a2f3a;
  ${({active}) => active && 'cursor: pointer'};

  a,
  span {
    color: ${({active}) => (active ? '#ffffff' : '#1A2F3A')};
  }
`;
