import {useEffect, useMemo} from 'react';
import {FormikProps} from 'formik';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {
  Content,
  ContentMailFormAttributes,
  useUpdateContentMailFormMutation,
} from 'api';
import {initialValues} from '../Content';
import useDebounce from 'hooks/useDebounce';

export default (
  content: Content,
  formik: FormikProps<ContentMailFormAttributes>,
) => {
  const {id} = useParams<{id: string}>();
  const [updateMailForm] = useUpdateContentMailFormMutation();

  const debouncedValue = useDebounce<ContentMailFormAttributes>(
    formik.values,
    1000,
  );

  useEffect(() => {
    updateMailForm({
      variables: {id, attributes: debouncedValue},
    });
  }, [debouncedValue]);

  const saving = useMemo(
    () => !_.isEqual(initialValues(content), formik.values),
    [content, formik.values],
  );

  return {saving};
};
