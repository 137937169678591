import React from 'react';
import {Switch} from '@headlessui/react';
import {useCurrentUserQuery, useUpdateClientUserMutation} from 'api';

export default () => {
  const [enabled, setEnabled] = React.useState(false);
  const {data: {currentUser: {clientUser = {}} = {}} = {}} =
    useCurrentUserQuery({});

  const [update] = useUpdateClientUserMutation();

  React.useEffect(() => {
    setEnabled(clientUser?.canReceiveDailySummaryMail);
  }, [clientUser]);

  return (
    <div>
      <h2 className="font-bold text-lg text-[#222426]">メール通知</h2>
      <div className="flex justify-between items-center py-3 border-y border-c-border">
        <div className="flex flex-col gap-1">
          <h3 className="text-[#495058] font-bold">デイリーサマリーレポート</h3>
          <p className="text-[#899098] text-sm">
            前日のキャンペーンメール送信履歴に関するメールを受け取ります。
          </p>
        </div>
        <Switch
          checked={enabled}
          onChange={(checked) => {
            update({
              variables: {
                id: clientUser?.id,
                attributes: {
                  canReceiveDailySummaryMail: checked,
                },
              },
            });
            setEnabled(checked);
          }}
          className={`p-0 ${enabled ? 'bg-c-primary' : 'bg-c-bg'}
          relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}>
          <span
            aria-hidden="true"
            className={`${enabled ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
    </div>
  );
};
