import React from 'react';
import styled from 'styled-components';
import {Action} from 'api';
import moment from 'moment';
import {Alert} from './Icon';

interface Props {
  action: Action;
}

const ActionDate = ({action}: Props) => {
  const startDate = action.startDate && moment(action.startDate).format('M/D');
  const dueDate = action.dueDate && moment(action.dueDate).format('M/D');
  let isOver = false;
  let color: string;

  if (action.dueDate && moment().isAfter(moment(action.dueDate))) {
    isOver = true;
    color = '#EB5757';
  } else {
    color = '';
  }

  return (
    <Container color={color}>
      <div>{`${startDate} - ${dueDate}`}</div>
      {isOver && <Alert />}
    </Container>
  );
};

const Container = styled.div<{color: string}>`
  display: flex;

  > div {
    margin: auto 0;
    color: ${({color}) => `${color}`};
  }

  svg {
    margin-left: 5px;
    color: ${({color}) => `${color}`};
  }
`;

export {ActionDate};
