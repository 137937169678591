import React from 'react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {Breadcrumb} from 'components/Ui';
import {useContentQuery} from 'api';

export default () => {
  const {id} = useParams<{id: string}>();

  const {data: {content = null} = {}} = useContentQuery({
    variables: {
      id,
    },
  });

  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item>
        <Link to="/garage/contents">コンテンツ一覧</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span className="current">{content?.title}</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};
