import React from 'react';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Input, Button, Alert, Divider} from 'components/antd';
import styled from 'styled-components';
// @ts-ignore
import CURRENT_USER from 'queries/currentUser';
import {useSigninClientMutation} from 'api';
import LeadpadIcon from './LeadpadIcon';
import LockIcon from './LockIcon';
import Mail from 'components/Icon/Mail';

const validateSchema = Yup.object().shape({
  email: Yup.string().trim().required('必須項目です'),
  password: Yup.string().trim().required('必須項目です'),
});
export default () => {
  const [signin, {data, loading}] = useSigninClientMutation({
    update: (cache, {data}) => {
      if (!data.signinClient.error) {
        cache.writeQuery({
          query: CURRENT_USER,
          data: {currentUser: data.signinClient.user},
        });
      }
    },
  });

  const formik = useFormik({
    validationSchema: validateSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      signin({variables: values});
    },
  });

  return (
    <Form onFinish={formik.handleSubmit} className="bg-[#F3F5F7]">
      <Container>
        <Content>
          <Header>
            <LeadpadIcon />
            <p className="text-[26px]">ログイン</p>
          </Header>
          {data && data.signinClient && data.signinClient.error && (
            <>
              <Alert
                message="Error"
                description={data.signinClient.error}
                type="error"
                showIcon
              />
              <Divider />
            </>
          )}

          <Form.Item
            required
            className="mb-0"
            validateStatus={
              formik.errors.email && formik.touched.email ? 'error' : ''
            }
            help={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : ''
            }>
            <Input
              className="w-full py-2.5 rounded"
              placeholder="メールアドレス"
              name="email"
              prefix={
                <span className="mr-2 flex items-center">
                  <Mail />
                </span>
              }
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <Form.Item
            required
            className="mb-2"
            validateStatus={
              formik.errors.password && formik.touched.password ? 'error' : ''
            }
            help={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : ''
            }>
            <Input
              className="w-full py-2.5 rounded"
              placeholder="パスワード"
              name="password"
              prefix={
                <span className="mr-2 flex items-center">
                  <LockIcon />
                </span>
              }
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>

          <div className="mb-5">
            <Button
              htmlType="submit"
              type="primary"
              className="py-3 h-auto rounded"
              loading={loading}
              style={{width: '100%'}}
              disabled={!formik.isValid || loading}>
              ログイン
            </Button>
          </div>
          <div className="text-center py-2 text-[14px]">
            <Link to={`/forgot_password`}>パスワードを忘れた方はこちら</Link>
          </div>
        </Content>
      </Container>
    </Form>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  &&& {
    width: 384px;
    margin-top: -100px;
    padding: 40px;
    box-sizing: content-box;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 40px;
`;
