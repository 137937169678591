import React, {ComponentProps} from 'react';

import {Cell, Header, flexRender} from '@tanstack/react-table';

import {ReportGroupTable, ReportGroupTableData} from './types';

import useTable from './useGroupTable';

type Props = {
  table: ReportGroupTable;
};

export const GroupTable = ({table}: Props) => {
  const reactTable = useTable(table);
  const getColumnHeaderClass = (
    header: Header<ReportGroupTableData, unknown>,
  ): string => {
    const baseClasses = [
      'bg-c-bg',
      'text-sm',
      'font-bold',
      'text-c-base',
      'border-y',
      'border-y-[#BDC4CB]',
    ];
    if (header.column.id === 'label') {
      return [
        ...baseClasses,
        'sticky',
        'text-left',
        'left-0',
        'p-3',
        'w-60',
        'min-w-[15rem]',
        'z-10',
        'border-r',
        'border-r-c-border',
      ].join(' ');
    }

    if (
      header.column.id.startsWith('column-') ||
      header.column.id === 'subColumn-last'
    ) {
      return [
        ...baseClasses,
        'py-3',
        'px-1',
        'min-w-[48px]',
        'border-r',
        'border-r-c-border',
      ].join(' ');
    }

    return [...baseClasses, 'py-3', 'px-1', 'min-w-[48px]', 'text-center'].join(
      ' ',
    );
  };

  const getCellClass = (cell: Cell<ReportGroupTableData, unknown>): string => {
    let baseClasses = [
      'py-3',
      'px-1',
      'bg-white',
      'text-sm',
      'text-c-base',
      'border-t',
      'border-t-c-border',
    ];

    if (cell.column.id === 'label') {
      return [
        ...baseClasses,
        'sticky',
        'left-0',
        'w-48',
        'min-w-[12rem]',
        'border-r',
        'border-r-c-border',
      ].join(' ');
    }

    if (
      cell.column.id.startsWith('column-') ||
      cell.column.id === 'subColumn-2'
    ) {
      return [
        ...baseClasses,
        'text-right',
        'border-r',
        'border-r-c-border',
      ].join(' ');
    }

    return [...baseClasses, 'text-right'].join(' ');
  };

  const getColumnHeaderProps = (
    header: Header<ReportGroupTableData, unknown>,
  ): ComponentProps<'th'> => {
    if (header.column.id === 'subColumn-last') {
      return {
        style: {
          color: '#fff',
          backgroundColor: '#4795F0',
          borderColor: '#4795F0',
        },
        colSpan: header.colSpan,
        className: getColumnHeaderClass(header),
      };
    }

    return {
      colSpan: header.colSpan,
      className: getColumnHeaderClass(header),
    };
  };

  const getCellProps = (
    cell: Cell<ReportGroupTableData, unknown>,
  ): ComponentProps<'td'> => {
    if (cell.column.id === 'subColumn-last') {
      return {
        style: {
          ...cell.row.original.cellProps?.style,
          color: '#5598F2',
          backgroundColor: '#ECF6FF',
          borderLeft: '1px solid #4795F0',
          borderRight: '1px solid #4795F0',
        },
        className: getCellClass(cell),
      };
    }

    return {
      style: cell.row.original.cellProps?.style,
      className: getCellClass(cell),
    };
  };

  if (table === null) {
    return null;
  }

  return (
    <div className="w-full overflow-auto">
      <table className="border-separate border-spacing-0 min-w-full">
        <thead className="sticky top-0">
          {reactTable.getHeaderGroups().map((headerGroup, i) => (
            <tr key={i}>
              {headerGroup.headers.map((header, j) => (
                <th key={j} {...getColumnHeaderProps(header)}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {reactTable.getRowModel().rows.map((row, i) => (
            <tr key={i} className="border-b border-1 border-gray-300">
              {row.getVisibleCells().map((cell, j) => (
                <td key={j} {...getCellProps(cell)}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
