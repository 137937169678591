import gql from 'graphql-tag';

export default gql`
  fragment projectGroupFragment on ProjectGroup {
    id
    uuid
    name
    memo
    projectGroupType
    imageUrl
    isDisplay
    isPrivate
  }
`;
