import React from 'react';
import {useProjectQuery} from 'api';
import {Setting} from 'components/Ui/Icon';
import {Popover, Button} from 'components/antd';
import {useParams} from 'react-router';
import styled from 'styled-components';
import TagSelect from './TagSelect';
import {getTagFontColor} from 'helpers/tagColors';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {projectId} = useParams<{projectId: string}>();
  const [isPopoverVisible, setPopoverVisible] = React.useState(false);
  const {isMember} = useClientUser();
  const {data: {project = {}, project: {projectTags = []} = {}} = {}} =
    useProjectQuery({
      variables: {uuid: projectId},
      fetchPolicy: 'cache-and-network',
      skip: !projectId,
    });

  return (
    <Container>
      <Title>
        <h3>タグ</h3>
        {isMember && (
          <Popover
            content={() => <TagSelect project={project} />}
            trigger="click"
            visible={isPopoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            placement="bottomRight">
            <Button type="text">
              <Setting />
            </Button>
          </Popover>
        )}
      </Title>

      {projectTags.length > 0 ? (
        <Tags>
          {projectTags.map((tag) => (
            <Tag color={tag.color} key={tag.id}>
              <span>{tag.name}</span>
            </Tag>
          ))}
        </Tags>
      ) : (
        <Name>未設定</Name>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #bdc4cb;
    flex: 1;
  }

  button {
    padding: 0;
    &:hover {
      background: transparent;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    stroke: #899098;
  }
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Tag = styled.span<{color?: string}>`
  margin-right: 6px;
  margin-bottom: 5px;
  display: flex;
  padding: 1px 8px;
  background: ${({color}) => (color ? `${color}` : 'black')};
  border-radius: 2px;
  span {
    font-size: 9px;
    font-weight: bold;
    line-height: 13px;
    color: ${({color}) => getTagFontColor(color)};
  }
`;
