import gql from 'graphql-tag';

export default gql`
  fragment hubspotDealPipelineFragment on HubspotDealPipeline {
    id
    hubspotId
    label
    stages {
      id
      label
    }
  }
`;
