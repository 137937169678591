import React from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {Dropdown} from '../Dropdown';

export default () => {
  const [formId, setFormId] = useQueryParam('formId', StringParam);
  const dropdownItems = [
    {label: 'すべて', value: undefined},
    {label: '【LP】資料ダウンロードフォーム', value: '1'},
    {label: '一括配信用事例集ダウンロードフォーム', value: '2'},
    {label: 'IT導入補助金', value: '3'},
    {label: 'DX EXPO_Day1(2023/7/11)_Salesforce導入', value: '4'},
  ];

  return <Dropdown value={formId} items={dropdownItems} onSelect={setFormId} />;
};
