import gql from 'graphql-tag';

export default gql`
  fragment mailClickReportActivityFragment on MailClickReportActivity {
    campaign {
      name
    }
    campaignUrl {
      url
    }
    project {
      name
      uuid
    }
  }
`;
