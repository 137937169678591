import React from 'react';
import {DatePicker} from 'components/antd';
import useSearchParams from '../../useSearchParams';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';

const TaskDueDateFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {taskDueDate} = query;
  const {RangePicker} = DatePicker;
  const format = 'YYYY-MM-DD';

  return (
    <>
      <DatePicker
        placeholder="開始日"
        value={query.taskDueDateFrom && moment(query.taskDueDateFrom)}
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, taskDueDateFrom: value?.format()});
        }}
        className="w-36"
      />
      <span className="mx-1 mt-2">~</span>
      <DatePicker
        placeholder="終了日"
        value={query.taskDueDateTo && moment(query.taskDueDateTo)}
        locale={locale}
        onChange={(value) => {
          setQuery({page: 1, taskDueDateTo: value?.format()});
        }}
        className="w-36"
      />
    </>
  );
};

export default TaskDueDateFilter;
