import gql from 'graphql-tag';
import azureWebhookEventFragment from '../azure/webhookEvent';

export default gql`
  fragment zoomPhoneWebhookEventRecordingFragment on ZoomPhoneWebhookEventRecording {
    id
    file
    azureWebhookEvent {
      ...azureWebhookEventFragment
    }
  }
  ${azureWebhookEventFragment}
`;
