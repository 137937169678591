import gql from 'graphql-tag';

export default gql`
  fragment miitelSettingFragment on MiitelSetting {
    id
    miitelCompanyId
    accessKey
    isActive
    isAvailable
  }
`;
