import Icon from './Icon';
import Name from './Name';
import Stage from './Stage';
import Workflow from './Workflow';
import Assignee from './Assignee';
import LastAction from './LastAction';
import Menu from './Menu';
import ActionDate from './ActionDate';
import LastProspectActivity from './LastProspectActivity';
import Check from './Check'

export default {
  Check,
  Icon,
  Name,
  Stage,
  Assignee,
  Workflow,
  LastAction,
  LastProspectActivity,
  Menu,
  ActionDate,
};
