import {Button} from 'components/antd';
import {Quill, QuillWrapper} from 'components/Ui';
import React from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import {useCreateCommentActivityMutation, usePreleadProjectQuery} from 'api';
import TimelineItem from 'components/TimelineItem';
import usePreleadProject from 'hooks/usePreleadProject';
import {Refresh} from 'components/Ui/Icon';

export default () => {
  const {preleadProjectId} = useParams<{
    preleadProjectId: string;
  }>();
  const {timeLineActivities} = usePreleadProject(preleadProjectId);
  const [comment, setComment] = React.useState('');
  const [bodyPlainText, setBodyPlainText] = React.useState(null);
  const [createCommentActivity, {loading}] = useCreateCommentActivityMutation({
    onCompleted: () => {
      setComment('');
      setBodyPlainText(null);
    },
  });
  const addComment = () =>
    createCommentActivity({
      variables: {
        uuid: preleadProjectId,
        attributes: {body: comment, bodyPlainText: bodyPlainText},
      },
    });

  const {
    data: {preleadProject: {prelead: {activities = []} = {}} = {}} = {},
    refetch,
    loading: loadingPreleadProject,
  } = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    skip: !preleadProjectId,
    notifyOnNetworkStatusChange: true,
  });

  if (!preleadProjectId) return null;

  return (
    <Container>
      <Actions>
        <Button
          onClick={() => refetch()}
          icon={<Refresh spin={loadingPreleadProject} />}
          disabled={loadingPreleadProject}
        />
      </Actions>
      <QuillWrapper>
        <Quill
          placeholder="XX様とお話。〇〇様へ繋いでいただき、折返しご連絡を依頼。メールもご確認いただけるようにお願いしました。"
          theme="snow"
          value={comment}
          onChange={(content, delta, source, editor) => {
            setComment(content);
            setBodyPlainText(editor.getText());
          }}
          modules={{
            toolbar: [
              [{header: [1, 2, 3, false]}],
              ['bold', 'italic', 'underline'],
              ['code-block'],
              ['link'],
            ],
          }}
          formats={[
            'header',
            'list',
            'bold',
            'italic',
            'underline',
            'strike',
            'color',
            'background',
            'code-block',
            'link',
          ]}
          bounds={'#quill-container'}
        />
        <div id="quill-container" />
        <div>
          <div />
          <Button
            disabled={!comment.replace(/<\/?[^>]+(>|$)/g, '') || loading}
            onClick={addComment}>
            登録
          </Button>
        </div>
      </QuillWrapper>
      {timeLineActivities(activities).map((activity) => (
        <TimelineItem key={activity.uuid} activity={activity} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
