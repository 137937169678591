import useClientUser from 'hooks/useClientUser';
import usePools from 'hooks/usePools';
import React from 'react';
import {Link} from 'react-router-dom';

export default () => {
  const {defaultPool} = usePools();
  const {canListApproach, canListTask} = useClientUser();

  return (
    <div className="mb-6 flex items-end justify-between border-b border-c-border text-c-base">
      <ul className="font-bold flex flex-wrap -mb-px text-center text-base">
        {canListTask && (
          <li className="mr-2">
            <Link
              to="/tasks"
              className={`inline-block px-4 py-3 border-b-4 rounded-t-lg hover:text-current ${
                location.pathname.startsWith('/tasks')
                  ? 'text-c-base'
                  : 'text-c-lighter border-transparent'
              }`}>
              ToDo
            </Link>
          </li>
        )}
        {canListApproach && (
          <li className="mr-2">
            <Link
              to="/approaches"
              className={`inline-block px-4 py-3 border-b-4 rounded-t-lg hover:text-current ${
                location.pathname.startsWith('/approach')
                  ? 'text-c-base'
                  : 'text-c-lighter border-transparent'
              }`}>
              アプローチ
            </Link>
          </li>
        )}
        <li className="mr-2">
          <Link
            to={`/pools/${defaultPool?.uuid}/action/actions`}
            className={`inline-block px-4 py-3 border-b-4 rounded-t-lg hover:text-current ${
              location.pathname.startsWith('/pools')
                ? 'text-c-base'
                : 'text-c-lighter border-transparent'
            }`}>
            ワークフロー
          </Link>
        </li>
      </ul>
    </div>
  );
};
