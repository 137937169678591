import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useMailCampaignBlockQuery} from 'api';
import BlockText from './Text';
import BlockHeader from './Header';
import BlockImage from './Image';
import BlockFooter from './Footer';
import BlockTitle from './Title';
import BlockButton from './Button';
import BlockDivider from './Divider';

const blockComponents: {[key: string]: any} = {
  MailCampaignBlockText: BlockText,
  MailCampaignBlockHeader: BlockHeader,
  MailCampaignBlockImage: BlockImage,
  MailCampaignBlockFooter: BlockFooter,
  MailCampaignBlockTitle: BlockTitle,
  MailCampaignBlockButton: BlockButton,
  MailCampaignBlockDivider: BlockDivider,
};

export default () => {
  const {mailCampaignId, blockId} = useParams<{
    mailCampaignId: string;
    blockId: string;
  }>();

  const {data: {mailCampaignBlock = null} = {}} = useMailCampaignBlockQuery({
    variables: {uuid: blockId},
    skip: !blockId,
  });

  if (!mailCampaignBlock) return null;

  const BlockComponent =
    blockComponents[mailCampaignBlock.blockable.__typename];

  if (!BlockComponent) return null;

  return (
    <>
      <Link to={`/mail_campaigns/${mailCampaignId}/mail${location.search}`}>
        〈 戻る
      </Link>
      <div className='my-4'>
        <BlockComponent block={mailCampaignBlock} key={mailCampaignBlock.id} />
      </div>
    </>
  );
};
