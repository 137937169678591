import React from 'react';
import Prospect from './Prospect';
import SettingButtons from './SettingButtons';
import {MaintenanceProspectPool} from 'api';

type Props = {
  maintenanceProspectPools: MaintenanceProspectPool[];
  handleUpdateMaintenanceProspectPool: (prospectPoolToId: string) => void;
  updateMaintenanceProspectPoolToLoading: boolean;
};

const Prospects = ({
  maintenanceProspectPools,
  handleUpdateMaintenanceProspectPool,
  updateMaintenanceProspectPoolToLoading,
}: Props) => {
  return (
    <>
      {maintenanceProspectPools.map((maintenanceProspectPool) => (
        <div key={maintenanceProspectPool.id} className="mb-2">
          <Prospect prospectPool={maintenanceProspectPool.prospectPool} />
          <SettingButtons
            prospectPool={maintenanceProspectPool.prospectPool}
            handleUpdateMaintenanceProspectPool={
              handleUpdateMaintenanceProspectPool
            }
            updateMaintenanceProspectPoolToLoading={
              updateMaintenanceProspectPoolToLoading
            }
            maintenanceProspectPool={maintenanceProspectPool}
          />
        </div>
      ))}
    </>
  );
};

export default Prospects;
