import React from 'react';
import {Route} from 'react-router-dom';
import StepForm from './StepModal';
import Summary from './Summary';
import StepList from './StepList';
import NewStepModal from './NewStepModal';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const [newStepModalVisible, setNewStepModalVisible] = React.useState(false);
  const {isMember} = useClientUser();

  return (
    <>
      {isMember && (
        <button
          type="button"
          onClick={() => setNewStepModalVisible(true)}
          className="h-9 px-4 rounded flex items-center jusitify-center absolute top-5 right-8 text-c-primary border border-c-primary bg-transparent cursor-pointer">
          + ステップを追加
        </button>
      )}
      <div className="flex flex-col">
        <Summary />
        <StepList />
        <Route
          path="/pools/:poolId/workflows/:workflowId/steps/:stepId"
          component={StepForm}
        />
      </div>
      <NewStepModal
        visible={newStepModalVisible}
        onClose={() => setNewStepModalVisible(false)}
      />
    </>
  );
};
