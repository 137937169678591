import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';
import {useWorkflowsQuery, useUpdateWorkflowPositionMutation} from 'api';
import useSearchParams from './useSearchParams';
import {Container, Draggable, DropResult} from 'react-smooth-dnd';
import Workflow from './Workflow';
import {Handle} from 'components/Ui/Icon';
import useClientUser from 'hooks/useClientUser';

export default () => {
  const {poolId} = useParams<{poolId: string}>();
  const {perPage, setQuery, query} = useSearchParams();
  const {isMember} = useClientUser();

  const {data: {workflows: {workflows, pagination = {}} = {}} = {}} =
    useWorkflowsQuery({
      variables: {
        uuid: poolId,
        page: query.page,
        perPage,
        status: query.status,
      },
      skip: !poolId,
      fetchPolicy: 'cache-and-network',
    });

  const [targetWorkflows, setTargetWorkflows] = React.useState(workflows);
  const [updateWorkflowPosition] = useUpdateWorkflowPositionMutation();

  const onDragEnd = (result: DropResult) => {
    const sourceIndex = result.removedIndex;
    const destIndex = result.addedIndex;

    const items = [...targetWorkflows];
    const [removed] = items.splice(sourceIndex, 1);
    items.splice(destIndex, 0, removed);

    setTargetWorkflows(items);
    updateWorkflowPosition({
      variables: {
        id: removed.id,
        position: destIndex,
      },
      refetchQueries: ['workflows'],
    });
  };

  const isOpen = query.status === 'open' || query.status === undefined;

  React.useEffect(() => {
    setTargetWorkflows(workflows);
  }, [workflows]);

  return (
    <div className="flex flex-col items-center [&>*]:w-full">
      <Container onDrop={onDragEnd} dragHandleSelector=".workflowListHandle">
        {workflows?.map((workflow) => (
          <Draggable key={workflow.id}>
            <div className="w-full flex py-4 pr-4 bg-white rounded mb-4">
              {isOpen && isMember ? (
                <div className="workflowListHandle cursor-move px-2">
                  <Handle />
                </div>
              ) : (
                <div className="px-2" />
              )}
              <Workflow workflow={workflow} />
            </div>
          </Draggable>
        ))}
      </Container>
      {!isOpen && (
        <Pagination
          size="small"
          showSizeChanger={false}
          showQuickJumper={false}
          total={pagination.totalCount}
          current={pagination.currentPage}
          pageSize={perPage}
          onChange={(page) => setQuery({page})}
        />
      )}
    </div>
  );
};

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: #495058;
      background: #e2e6ea;
      border-radius: 100px;
    }
  }
`;
