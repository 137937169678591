import {
  ReportTable,
  ReportTableData,
  ReportTableHeader,
} from '../../components/Table/types';
import {StringParam, useQueryParams} from 'use-query-params';
import {
  useDealReportLeadSourceDailyReportsQuery,
  useDealReportLeadSourceDailySumReportQuery,
} from 'api';
import useMonthHeaders from '../../hooks/useMonthHeaders';

const useTableData = (): ReportTable => {
  const [query] = useQueryParams({
    month: StringParam,
  });
  const {data: {dealReportLeadSourceDailySumReport = null} = {}} =
    useDealReportLeadSourceDailySumReportQuery({
      variables: {month: query.month},
    });
  const {data: {dealReportLeadSourceDailyReports = []} = {}} =
    useDealReportLeadSourceDailyReportsQuery({variables: {month: query.month}});
  const headers: ReportTableHeader[] = useMonthHeaders(query.month);

  const reportData: ReportTableData[] = [
    dealReportLeadSourceDailySumReport,
    ...dealReportLeadSourceDailyReports,
  ]
    .filter(Boolean)
    .map((report) => {
      const total = report.values.filter(Boolean).reduce((sum, element) => {
        return sum + element;
      }, null);
      return {
        label: report.name,
        values: [total, ...report.values],
      };
    });

  return {
    data: reportData,
    headers,
  };
};

export default useTableData;
