import React from 'react';
import styled from 'styled-components';
import {Button, Modal} from 'components/antd';
import {
  Content,
  ContentAudio,
  ContentDocument,
  ContentTelScript,
  useArchiveContentMutation,
  useUnarchiveContentMutation,
} from 'api';
import {ContentArchive, ContentUnarchive, Download} from 'components/Ui/Icon';

interface Props {
  row: any;
}

const ActionsCell = ({row}: Props) => {
  const content = row.original as Content;

  const [archiveModalVisible, setArchiveModalVisible] = React.useState(false);
  const [archive, archiveProps] = useArchiveContentMutation({
    variables: {id: content.uuid},
    refetchQueries: ['contents'],
  });

  const [unarchive, unarchiveProps] = useUnarchiveContentMutation({
    variables: {id: content.uuid},
    refetchQueries: ['contents'],
  });

  const downloadFile = React.useMemo(() => {
    if (
      !['Content::Document', 'Content::TelScript', 'Content::Audio'].includes(
        content.contentableType,
      )
    ) {
      return null;
    }

    const downloadContent = content.contentable as
      | ContentDocument
      | ContentTelScript
      | ContentAudio;
    const extention = downloadContent.fileName.split('.').slice(-1)[0];
    const fileName = `${content.title}.${extention}`;

    return {
      url: downloadContent.file,
      name: fileName,
    };
  }, [content]);

  return (
    <Container>
      {downloadFile && (
        <Button
          type="link"
          icon={<Download />}
          target="_blank"
          download={downloadFile?.name}
          href={downloadFile?.url}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      {content.archivedAt ? (
        <Button
          type="link"
          loading={unarchiveProps.loading}
          icon={<ContentUnarchive />}
          onClick={(e) => {
            e.stopPropagation();
            unarchive();
          }}
        />
      ) : (
        <>
          <Button
            type="link"
            loading={archiveProps.loading}
            icon={<ContentArchive />}
            onClick={(e) => {
              e.stopPropagation();
              setArchiveModalVisible(true);
            }}
          />
          <ArchiveModal
            visible={archiveModalVisible}
            onOk={(e) => {
              e.stopPropagation();
              archive();
              setArchiveModalVisible(false);
            }}
            onCancel={(e) => {
              e.stopPropagation();
              setArchiveModalVisible(false);
            }}
            okText="アーカイブ"
            cancelText="キャンセル"
            okButtonProps={{danger: true}}>
            <h2>このファイルをアーカイブにしますか？</h2>
            <p>
              ファイルをアーカイブすると、全員に対してアーカイブされます。
              <br />
              誰もファイルを見る事ができません。
            </p>
            <p>
              アーカイブした後でも、ファイルの内容を見ることができます。また、今後必要になった時には、いつでもファイルを復元できます。
            </p>
          </ArchiveModal>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ArchiveModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    padding: 1rem;
  }

  .ant-modal-body {
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 2rem;
    }
  }

  .ant-modal-footer {
    border: none;
  }
`;

export default ActionsCell;
