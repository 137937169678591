import React from 'react';
import styled from 'styled-components';
import {Checkbox} from 'components/Ui';

type Props = {
  technology: any;
  checked: boolean;
  onSelect: (checked: boolean, selectedTechnlogy: string) => Promise<void>;
};
const ChildCategory = ({technology, checked, onSelect}: Props) => {
  return (
    <Container>
      <CategoryCheckBox
        checked={checked}
        className="category"
        onChange={(e) => onSelect(e.target.checked, technology.value)}>
        {technology.text}
      </CategoryCheckBox>
    </Container>
  );
};

export {ChildCategory};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CategoryCheckBox = styled(Checkbox)`
  display: flex;
  align-items: center;
  min-width: 300px;
  .ant-checkbox {
    top: 0em;
  }
`;
