import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from '../components/Layout';
import CallStatus from './status';
import {useCurrentUserQuery} from 'api';
import User from './user';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  const adminOnlyTabs = [
    {
      label: 'コール担当者別',
      to: '/reports/call/user',
    },
  ];
  return (
    <Layout
      title="リードコール分析"
      tabs={[
        {
          label: 'ステータス別',
          to: '/reports/call/status',
        },
        ...(currentUser?.role === 'admin' ? adminOnlyTabs : []),
      ]}
      search={['timeScale']}>
      <Switch>
        <Route path="/reports/call/status" component={CallStatus} />
        <Route path="/reports/call/user" component={User} />
        <Redirect to="/reports/call/status" />
      </Switch>
    </Layout>
  );
};
